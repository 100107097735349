import { RoleNames, Actor } from 'core';

export function isSystemAdmin (actor: Actor | null): boolean {
  return !!actor && RoleNames.sysAdmin === actor.roleName;
}

export function isGoJekAccountManager (actor: Actor | null): boolean {
  return !!actor && RoleNames.goJekAccountManager === actor.roleName;
}

export function isFbAgencyManager (actor: Actor | null): boolean {
  return !!actor && RoleNames.fbAgencyManager === actor.roleName;
}

export function isGuest (actor: Actor | null): boolean {
  return !!actor && RoleNames.guest === actor.roleName;
}

import { validateUrl } from 'utils/ValidateUtils';
import i18n from 'i18next';
import _ from 'lodash';
import { AdLogo, AdLogoType } from 'core/adLogo/AdLogo';
const mimeDB = require('mime-db');

export interface AdLogoFormModel {
  validateForm (adLogoData): any;
}

export type AdLogoFormProps = {
  readonly model: AdLogoFormModel;
};

export class DefaultAdLogoFormModel implements AdLogoFormModel {

  validateImage = (fileData) => {
    if (!fileData || (!fileData.file && !fileData.url)) {
      return i18n.t('formValidate.labels.emptyError');
    }

    if (!fileData.file) {
      return;
    }
    const validTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    const file = fileData.file;
    if (validTypes.indexOf(file.type) === -1) {
      const extensions = _.get(mimeDB[file.type], 'extensions', ['Unknown']);
      return i18n.t('adLogoForm.labels.typeErrorHint', { type: extensions[0] });
    }

    const validWitdh = 50;
    const validHeight = 50;
    const width = fileData.width;
    const height = fileData.height;
    if (width !== validWitdh || height !== validHeight) {
      return i18n.t('adLogoForm.labels.sizeErrorHint', { size1: `${width} x ${height}`, size2: `${validWitdh} x ${validHeight}` });
    }
  }

  validateForm = (adLogoData: AdLogo) => {
    return _.omitBy(
      {
        link: adLogoData.type === AdLogoType.CUSTOM ? validateUrl(adLogoData.link) : undefined,
        image: adLogoData.type === AdLogoType.CUSTOM ? this.validateImage(adLogoData.image) : undefined
      }, _.isUndefined);
  }
}

import React from 'react';
import videoPlaceholder from 'assets/video-placeholder.svg';
import i18n from 'i18n';
import cx from 'classnames/bind';
import styles from './thirdPartyVideoPreview.module.scss';

export const ThirdPartyVideoPreview: React.FunctionComponent<{url: string, canClick: boolean}> = (props) => {
  const openUrl = () => {
    props.canClick && window.open(props.url, '_blank');
  };
  const className = cx.bind(styles);
  const mainClass = className('thirdPartyVideoPreview', {
    canClick: props.canClick
  });
  return (
    <div className={mainClass} onClick={openUrl}>
      <img src={videoPlaceholder} alt={'preview area'}/>
      <span>{i18n.t('thirdPartyVideoPreview.labels.thirdParty')}</span>
    </div>
  );
};

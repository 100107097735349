/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import TagsInput from 'react-tagsinput-special';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faPlus } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames/bind';
import _ from 'lodash';
import 'react-tagsinput/react-tagsinput.css';
import styles from './tags.module.scss';
import { SelectOptions } from '../commonType';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import i18n from 'i18n';

const cx = classNames.bind(styles);

function defaultRenderTag (disableReason, props) {
  const {
    tag,
    key,
    onRemove,
    classNameRemove,
    getTagDisplayValue,
    disabled,
    className,
    ...other
  } = props;

  const clickCallback = e => {
    onRemove(key);
  };
  const tagLabel = _.get(tag, 'label', typeof tag === 'string' ? tag : _.get(tag, 'value', ''));
  const tagClassName = disabled ? `${className} disabled` : className;
  const removeButtonClass = cx(classNameRemove, {
    disabled: disabled
  });
  return (
    <span key={key} className={tagClassName} {...other}>
      {getTagDisplayValue(tagLabel)}
      {
        disabled && disableReason ?
          <IconWithTooltip
            className={removeButtonClass}
            icon={faTimesCircle}
            tooltipProps={{
              id: `tagDisableTip-${key}`,
              tooltip: i18n.t(disableReason)
            }}
          /> :
          <a className={removeButtonClass} onClick={disabled ? undefined : clickCallback}>
            <FontAwesomeIcon icon={faTimesCircle} />
          </a>
      }
    </span>
  );
}

function defaultRenderInput (props) {
  let { onChange, value, addTag, ...other } = props;
  return (
    <input type='text' onChange={onChange} value={value} {...other} />
  );
}

function emptyInput (props) {
  return null;
}

const defaultProps = {
  value: [],
  onChange: _.noop,
  operate: '',
  addAction: _.noop,
  showPlus: false,
  disableInput: false,
  disabled: false
};

type TagsProps = {
  value: SelectOptions[];
  onChange: (tags: any[], changed: any[], changedIndexes: number[]) => void;
  disableInput?: boolean;
  operate?: any;
  addAction?: any;
  showPlus?: boolean;
  disabled?: boolean;
  disableReason?: string;
};

const Tags: React.FunctionComponent<
  TagsProps & React.HTMLAttributes<HTMLDivElement>
> = ({
  value,
  onChange: handleChange,
  operate,
  addAction,
  showPlus,
  placeholder,
  className: parentClassName,
  disableInput,
  disabled,
  disableReason
}) => {
  const inputClass = classNames({
    'react-tagsinput': true,
    [operate]: true,
    disabled
  });
  const containerClass = cx(parentClassName, {
    tagContainer: true
  });
  const needShowPlus = showPlus && !disabled;
  const buttonClass = cx('limitaionPlus', {
    showPlus: needShowPlus,
    noshowPlus: !needShowPlus
  });
  const renderInput = disableInput ? emptyInput : defaultRenderInput;
  const clickFun = (e) => {
    if (typeof e.target.className !== 'object' && e.target.className !== 'react-tagsinput-tag') {
      addAction(e);
    }
  };
  return (
    <div className={containerClass} onClick={disabled ? undefined : clickFun}>
      <TagsInput
        value={value}
        onChange={handleChange}
        renderTag={_.partial(defaultRenderTag, disableReason)}
        className={inputClass}
        inputProps={{ placeholder }}
        renderInput={renderInput}
        addOnBlur
        disabled={disabled}
      />
      <Button variant='link' className={buttonClass} onClick={addAction}>
        <FontAwesomeIcon icon={faPlus} />
      </Button>
    </div>
  );
};

Tags.defaultProps = defaultProps;

export default Tags;

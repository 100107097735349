interface ListenerType<EventType> {

  (event: EventType): void;
}

export class UpdateEventListener<EventType> {
  nextKey: number;
  listeners: Map<number, ListenerType<EventType>>;

  constructor () {
    this.nextKey = 1;
    this.listeners = new Map<number, ListenerType<EventType>>();
  }

  get count (): number {
    return this.listeners.size;
  }

  add (listener: ListenerType<EventType>): number {
    const key = this.nextKey;
    this.listeners.set(key, listener);
    this.nextKey = key + 1;
    return key;
  }

  remove (key: number): void {
    this.listeners.delete(key);
  }
}

export class FireableUpdateEventListener<EventType> extends UpdateEventListener<EventType> {
  fireEvent (event: EventType): void {
    this.listeners.forEach((listener, key, map) => {
      listener(event);
    });
  }
}

import { useEffect, useState } from 'react';
import { DefaultAgencyManager } from 'core';
import { SelectOptions } from 'components/common/commonType';
import moment from 'moment';
import { useCallAPI } from 'hooks/useCallAPI';

const defaultAgencyManager = new DefaultAgencyManager();

export const useCreditPayModalModel = (
  agencyId: number,
  onClose: (refresh: boolean) => void,
  bills?: any[],
  agencyManager = defaultAgencyManager
) => {

  const { loading, callAPIs } = useCallAPI();
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectBill, setSelectBill] = useState<any>(undefined);
  const [unpaidBills, setUnpaidBills] = useState<any[]>([]);
  const [billOptions, setBillOptions] = useState<SelectOptions[]>(
    bills ?
      bills.filter(bill => bill.state === 'UNPAID')
        .map(bill => ({
          value: bill.id, label: moment(bill.dateId).format('MMMM YYYY')
        })) :
      []
  );

  useEffect(() => {
    callAPIs([
      bills ? () => bills : agencyManager.getBill.bind(agencyManager, agencyId)
    ], billsData => {
      const unpaidBills = billsData.filter(bill => bill.state === 'UNPAID');
      setUnpaidBills(unpaidBills);
      setBillOptions(unpaidBills.map(bill => ({
        value: bill.id, label: moment(bill.dateId).format('MMMM YYYY')
      })));
      unpaidBills.length > 0 && setSelectBill(unpaidBills[0]);
    });
  }, [agencyManager, agencyId, bills, callAPIs]);

  const payCredit = async () => {
    if (!selectBill) {
      return;
    }
    setShowConfirm(false);
    callAPIs([
      agencyManager.payBill.bind(agencyManager, selectBill.id)
    ], () => {
      onClose(true);
    });
  };

  const onSelectBill = (billId) => {
    setSelectBill(unpaidBills.find(bill => bill.id === billId));
  };

  return {
    loading,
    billOptions,
    selectBill,
    showConfirm,
    setShowConfirm,
    onSelectBill,
    payCredit
  };
};

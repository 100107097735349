import React from 'react';

type StateDataContext = {
  data: any,
  setData (data: any): void
};

export const StateDataContext = React.createContext<StateDataContext>({
  data: {},
  setData: (data) => {}
});

import React from 'react';
import { components } from 'react-select';
import styles from './fbPageSelect.module.scss';
import cx from 'classnames/bind';
import i18n from 'i18n';

const classNames = cx.bind(styles);

export const FBPageOption = props => {
  const optionClass = classNames('fbPageOption', {
    disabled: props.isDisabled
  });
  const urlPath = `https://graph.facebook.com/${props.data.value}/picture/?type=square`;
  const renderFbPageOption = () => (
    <div className={optionClass}>
      <img
        key={urlPath}
        src={urlPath}
        alt=''
      />
      <div className={styles.fbPageOptionDetail}>
        <div className={styles.fbPageName}>{props.label}</div>
        <div className={styles.fbPageId}>{props.value}</div>
        {props.isDisabled &&
          <div className={styles.fbPageOptionHint}>
            {i18n.t('fbPageOption.hints.banned')}
          </div>
        }
      </div>
    </div>
  );
  return !props.isDisabled ? (
      <components.Option {...props}>
        {renderFbPageOption()}
      </components.Option>
    ) :
    renderFbPageOption();
};

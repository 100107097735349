import { SortDescriptor, SortOrder } from 'components/TableColumn';

export interface SortableList {
  readonly sortDescriptor: Array<SortDescriptor>;
  handleSort (field: string, order: SortOrder): void;
}

export abstract class AbstractSortableList implements SortableList {
  constructor (
    protected sortField: string,
    protected sortOrder: SortOrder
  ) {}

  get sortDescriptor (): Array<SortDescriptor> {
    return [{
      dataField: this.sortField,
      order: this.sortOrder
    }];
  }

  handleSort = (field: string, order: SortOrder) => {
    this.sortField = field;
    this.sortOrder = order;
  }
}

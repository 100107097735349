import { AccountsHomeModel, OEMAccountsHomeModel } from './AccountsHome/AccountsHomeModel';
import { AccountFormModel, CreateOEMAccountForm, EditOEMAccountForm } from 'components/AccountForm';
import { AccountDetailModel, OEMAccountDetailModel } from './AccountDetail';
import { DefaultAccountsModel } from './AccountsModel';
import { SelectOptions } from 'components/common/commonType';
import { toast } from 'react-toastify';
import { hasFuncs } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';
import { AgencyManager, PMaxCore, RoleNames } from 'core';
import _ from 'lodash';

export class OEMAccountsModel extends DefaultAccountsModel {

  rootPath: string = '/oem-accounts';
  agencyOptions: SelectOptions[] = [];
  viewPermission = hasFuncs(Permission.SYS_ADMIN_MANAGEMENT);
  agencyManager: AgencyManager;

  constructor (
    core: PMaxCore
  ) {
    super(core);
    this.agencyManager = core.agencyManager;
    this.operator = core.authenticationManager.actor;
  }

  async init () {
    this.updateState(true);
    try {
      this.agencyOptions = await this.agencyManager.getOemOptions();
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
    }
    this.updateState(false);
  }

  get state () {
    return {
      loading: this.loading,
      agencyOptions: this.agencyOptions
    };
  }

  get createCancelPath (): string {
    return this.canViewAllAccounts ? this.rootPath : '/';
  }

  getAccountHomeModel (): AccountsHomeModel {
    if (this.homeModel &&
      this.homeModel.agencyOptions === this.agencyOptions
    ) {
      return this.homeModel;
    }
    this.homeModel = new OEMAccountsHomeModel(this.agencyOptions, this.accountManager, _.get(this.operator, 'roleName') === RoleNames.sysAdmin);
    return this.homeModel;
  }

  getCreateAccountFormModel (): AccountFormModel {
    const selectAgency = this.homeModel ? this.homeModel.state.selectAgency : undefined;
    if (this.createAccountFormModel &&
      !this.createAccountFormModel.shouldUpdateModel({
        agencyOptions: this.agencyOptions,
        selectAgency,
        cancelPath: this.createCancelPath
      })
    ) {
      return this.createAccountFormModel;
    }
    this.createAccountFormModel = new CreateOEMAccountForm(this.agencyOptions, selectAgency, this.createCancelPath);
    return this.createAccountFormModel;
  }

  getEditAccountFormModel (accountId: number): AccountFormModel {
    if (this.editAccountFormModel &&
      !this.editAccountFormModel.shouldUpdateModel({
        accountId
      })
    ) {
      return this.editAccountFormModel;
    }
    this.editAccountFormModel = new EditOEMAccountForm(accountId, this.authenticationManager);
    return this.editAccountFormModel;
  }

  getAccountDetailModel (accountId: number): AccountDetailModel | undefined {
    if (!this.operator) {
      return undefined;
    }
    if (this.accountDetailModel &&
      this.accountDetailModel.accountId === accountId) {
      return this.accountDetailModel;
    }
    this.accountDetailModel = new OEMAccountDetailModel(accountId, this.operator, this.accountManager);
    return this.accountDetailModel;
  }
}

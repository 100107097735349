import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import { ModalState, ModalStateFactory } from './ModalStateFactory';

export interface StateModalModel {
  readonly state: StateModalState;
  readonly event: UpdateEventListener<StateModalModel>;
  init (): Promise<void>;
}

export type StateModalProps = {
  readonly model: StateModalModel;
};

export type StateModalState = {
  readonly currentState?: ModalState;
  readonly loading: boolean;
  readonly data: any;
  readonly setData: (data) => void;
};

export class DefaultStateModalModel implements StateModalModel {
  event: FireableUpdateEventListener<StateModalModel>;
  currentState?: ModalState;
  loading: boolean;
  stateFactory: ModalStateFactory;
  closeModalFn: (dirty) => void;
  data: any;

  constructor (
    stateFactory: ModalStateFactory,
    closeModalFn: (dirty) => void,
    defaultData?: any
  ) {
    this.event = new FireableUpdateEventListener<StateModalModel>();
    this.loading = true;
    this.stateFactory = stateFactory;
    this.closeModalFn = async (dirty, beforeClose?) => {
      this.updateState(true);
      try {
        beforeClose && await beforeClose();
      } catch (e) {
        console.log(`failed to call beforeCloseModalFn`, e);
        this.updateState(false);
      }
      closeModalFn(dirty);
      this.updateState(false);
    };
    this.data = defaultData;
  }

  async init (): Promise<void> {
    this.updateState(true);

    try {
      this.currentState = await this.stateFactory.initStates(this.goState, this.closeModalFn);
      this.updateState(false);
    } catch (e) {
      console.log(`failed to init state`, e);
      this.updateState(false);
    }
  }

  goState = async (state: ModalState, beforeGoState?: () => Promise<void>) => {
    if (beforeGoState) {
      try {
        this.updateState(true);
        beforeGoState && await beforeGoState();
      } catch (e) {
        console.log(`failed to call beforeGoStateFn`, e);
      }
    }
    this.currentState = state;
    this.updateState(false);
  }

  setData = (data) => {
    this.data = data;
    this.updateState(false);
  }

  get state (): StateModalState {
    return {
      data: this.data,
      setData: this.setData,
      currentState: this.currentState,
      loading: this.loading
    };
  }

  updateState (loading: boolean) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}

import { SelectOptions } from 'components/common/commonType';
import { FireableUpdateEventListener, UpdateEventListener } from 'utils/UpdateEventListener';
import { AgencyManager, DefaultAgencyManager, Actor } from 'core';
import { SessionStorageHelper, SessionStorageItemKeys } from 'helper/StorageHelper';
import { AdvertiserListModel, DefaultAdvertiserListModel } from 'components/AdvertiserList/AdvertiserListModel';
import { isSystemAdmin } from 'helper/ActorHelper';

export interface AdvertiserHomeModel {
  readonly agencyId?: number;
  readonly event: UpdateEventListener<AdvertiserHomeModel>;
  readonly state: AdvertiserHomeState;
  getAgenciesOptions (): Promise<void>;
  setAgencyId (agencyId?: number): void;
  onUnmount (eventHandler?: number): void;
  getAdvertiserListModel (): AdvertiserListModel;
}

export type AdvertiserHomeProps = {
  readonly model: AdvertiserHomeModel
};

export type AdvertiserHomeState = {
  readonly loading: boolean;
  readonly agencyId?: number;
  readonly agencies?: Array<SelectOptions>;
};

export class DefaultAdvertiserHomeModel implements AdvertiserHomeModel {

  agencyId?: number;
  agencies?: Array<SelectOptions>;
  loading: boolean;
  isSysAdmin: boolean;
  agencyManager: AgencyManager;
  event: FireableUpdateEventListener<AdvertiserHomeModel>;
  advertiserListModel?: AdvertiserListModel;

  constructor (actor: Actor, agencyManager: AgencyManager = new DefaultAgencyManager()) {
    this.isSysAdmin = isSystemAdmin(actor);
    this.loading = true;
    this.event = new FireableUpdateEventListener<AdvertiserHomeModel>();
    this.agencyManager = agencyManager;
    this.agencyId = this.isSysAdmin ?
      SessionStorageHelper.getNumberItem(SessionStorageItemKeys.AGENCY) :
      actor.agencyId === null ? undefined : actor.agencyId;
  }

  get state () {
    return {
      loading: this.loading,
      agencyId: this.agencyId,
      agencies: this.agencies
    };
  }

  async getAgenciesOptions () {
    if (!this.isSysAdmin) {
      this.updateState();
      return;
    }
    this.updateState(true);
    try {
      this.agencies = await this.agencyManager.getAgenciesOptions();
    } catch (e) {}
    this.updateState();
  }

  setAgencyId = (agencyId?: number) => {
    if (this.agencyId === agencyId) {
      return;
    }
    this.agencyId = agencyId;
    this.updateState();
  }

  getAdvertiserListModel (): AdvertiserListModel {
    if (this.advertiserListModel && this.advertiserListModel.agencyId === this.agencyId) {
      return this.advertiserListModel;
    }

    this.advertiserListModel = new DefaultAdvertiserListModel(this.agencyId);
    return this.advertiserListModel;
  }

  onUnmount (eventHandler: number | undefined = undefined): void {
    this.loading = true;
    if (eventHandler !== undefined) {
      this.event.remove(eventHandler);
    }
  }

  updateState (loading: boolean = false) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}

import { Account, Actor } from './Account';
import { AccountWebService, RestfulAccountWebService } from 'ws/AccountWebService';
import { LocaleMeta, MOMENT_TIMEZONE } from './LocaleMeta';
import { isGoJekAccountManager, isSystemAdmin, isGuest } from 'helper/ActorHelper';
import moment from 'moment-timezone';

export type CreateAccountRequest = {

  readonly name: string;
  readonly email: string;
  readonly isAdmin: boolean;
  readonly isGoJekAccountManager: boolean;
  readonly agencyId?: number;
  readonly role?: string;
};

export type UpdateAccountRequest = {

  readonly id: number;
  readonly name: string;
  readonly isAdmin: boolean;
  readonly isGoJekAccountManager: boolean;
  readonly activated: boolean;
};

export interface AccountManager {

  readonly localeMeta?: LocaleMeta;
  getAccount (id: number): Promise<Account>;
  getAccounts (): Promise<Array<Account>>;
  getOemAccounts (): Promise<Array<Account>>;
  createAccount (request: CreateAccountRequest): Promise<number>;
  createAgencyAccount (agencyId: number | string, request: CreateAccountRequest): Promise<number>;
  createAdvertiserAccount (advertiserId: number | string, request: CreateAccountRequest): Promise<number>;
  updateAccount (request: UpdateAccountRequest): Promise<void>;
  updateLocaleMeta (actor: Actor | null): Promise<void>;
  changePassword (currentPassword: string, password: string): Promise<void>;
  resendActivationEmail (accountId: number): Promise<void>;
}

export class DefaultAccountManager implements AccountManager {
  webService: AccountWebService;
  localeMeta?: LocaleMeta;

  constructor (webService: AccountWebService = new RestfulAccountWebService()) {
    this.webService = webService;
  }

  getAccount (id: number): Promise<Account> {
    return this.webService.fetchAccount(id);
  }

  getAccounts (): Promise<Array<Account>> {
    return this.webService.getAccounts();
  }

  getOemAccounts (): Promise<Array<Account>> {
    return this.webService.getOemAccounts();
  }

  createAccount (request: CreateAccountRequest): Promise<number> {
    return this.webService.createAccount(request);
  }

  createAgencyAccount (agencyId: number | string, request: CreateAccountRequest): Promise<number> {
    return this.webService.createAgencyAccount(agencyId, request);
  }

  createAdvertiserAccount (advertiserId: number | string, request: CreateAccountRequest): Promise<number> {
    return this.webService.createAdvertiserAccount(advertiserId, request);
  }

  updateAccount (request: UpdateAccountRequest): Promise<void> {
    return this.webService.updateAccount(request);
  }

  changePassword (currentPassword: string, password: string): Promise<void> {
    return this.webService.changePassword(currentPassword, password);
  }

  async updateLocaleMeta (actor: Actor | null): Promise<void> {
    if (isSystemAdmin(actor) || isGoJekAccountManager(actor) || isGuest(actor)) {
      this.localeMeta = undefined;
      moment.tz.setDefault();
      return;
    }
    this.localeMeta = await this.webService.locale();
    const momentTimeZone = MOMENT_TIMEZONE[this.localeMeta.timezone];
    return moment.tz.setDefault(momentTimeZone);
  }

  async resendActivationEmail (accountId: number): Promise<void> {
    return this.webService.resendActivationEmail(accountId);
  }
}

import { CreativeListDataProvider } from './CreativeListDataProvider';
import { CreativeListCommonColumns, commonColumnSetting, getCommonColumnData } from './CreativeListCommonColumnData';
import { renderColumn } from 'components/TableColumn';
import { getCreativeListFormatters } from './CreativeListFormatters';
import { CreativeListRecord } from 'core/creative/Creative';
import styles from './creativeList.module.scss';

export class PilotTVCreativeListDataProvider implements CreativeListDataProvider {

  formatters: any;
  constructor (model) {
    this.formatters = getCreativeListFormatters(model);
  }

  getColumns () {
    const previewColumnClassGetter = () => {
      return styles.pilotTVPreview;
    };
    return [
      renderColumn(commonColumnSetting[CreativeListCommonColumns.NAME_ID], this.formatters.nameIdFormatter, this.formatters.nameIdHeaderFormatter),
      renderColumn({
        ...commonColumnSetting[CreativeListCommonColumns.PREVIEW],
        classes: previewColumnClassGetter,
        headerClasses: previewColumnClassGetter
      }, this.formatters.previewFormatter),
      renderColumn(commonColumnSetting[CreativeListCommonColumns.EDITBTNS], this.formatters.floatingEditBtnsFormatter)
    ];
  }

  getViewModel (creative: CreativeListRecord) {
    return getCommonColumnData(creative);
  }
}

import React, { Fragment } from 'react';
import { FormControl } from 'react-bootstrap';
import _ from 'lodash';
import styles from './fileInput.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload, faTimes } from '@fortawesome/free-solid-svg-icons';
import i18n from 'i18next';
import classnames from 'classnames/bind';
import { faFileArchive } from '@fortawesome/free-regular-svg-icons';

type FileInputProps = {
  className?: string,
  validTypes: string[],
  url?: string,
  file?: File,
  onChange: Function,
  icon: any
};

export class FileInput extends React.Component<FileInputProps, any> {

  inputRef: any;
  classNames: any;
  validTypes: string[];

  static defaultProps = {
    validTypes: ['application/zip', 'application/x-zip-compressed'],
    icon: faFileArchive
  };

  constructor (props) {
    super(props);
    this.inputRef = React.createRef();
    this.classNames = classnames.bind(styles);
    this.state = {
      showFileIcon: false,
      hovering: false
    };
    this.validTypes = props.validTypes;
  }

  componentDidMount () {
    if (this.props.file || this.props.url) {
      this.setState({
        showFileIcon: true,
        hovering: false
      });
    }
  }

  componentDidUpdate (prevProps) {
    if ((this.props.file && prevProps.file !== this.props.file) ||
      (this.props.url && prevProps.url !== this.props.url)) {
      this.setState({
        showFileIcon: true,
        hovering: false
      });
    }
  }

  onFilePreviewClick = () => {
    this.inputRef.current && this.inputRef.current.click();
  }

  onFileChange = (event) => {
    const files = _.get(event, 'currentTarget.files', []);
    const file = files[0];
    if (!file) {
      return;
    }
    this.props.onChange && this.props.onChange(file);
    event.target.value = null;
  }

  onPreviewAreaMouseEnter = () => {
    this.setState({ hovering: true });
  }

  onPreviewAreaMouseLeave = () => {
    this.setState({ hovering: false });
  }

  onClearBtnClicked = (e) => {
    if (this.inputRef.current) {
      this.inputRef.current.value = '';
    }
    this.props.onChange && this.props.onChange(undefined);
    this.setState({
      showFileIcon: false,
      hovering: false
    });
    e.stopPropagation();
  }

  onDragOver = (event) => {
    event.dataTransfer.dropEffect = 'copy';
    event.preventDefault();
  }

  onDragEnter = (event) => {
    event.stopPropagation();
    event.preventDefault();
  }

  onFileDrop = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const files = _.get(event, 'dataTransfer.files', []);
    const file = files[0];
    if (!file) {
      return;
    }

    this.props.onChange && this.props.onChange(file);
    if (this.validTypes.indexOf(file.type) === -1) {
      this.setState({
        showFileIcon: false,
        hovering: false
      });
    }
  }

  renderPlaceholder = () => {
    return (
      <div
        className={styles.filePlaceholder}
        onDragOver={this.onDragOver}
        onDragEnter={this.onDragEnter}
        onDrop={this.onFileDrop}
      >
        <FontAwesomeIcon icon={faFileUpload} />
        <div className={styles.hint}>{i18n.t('creativeSetupFlow.labels.dragdropHint')}</div>
      </div>
    );
  }

  renderFilePreview = () => {
    return (
      <div
        className={styles.previewArea}
        onMouseEnter={this.onPreviewAreaMouseEnter}
        onMouseLeave={this.onPreviewAreaMouseLeave}
        onDragOver={this.onDragOver}
        onDragEnter={this.onDragEnter}
        onDrop={this.onFileDrop}
      >
        {
          this.state.hovering &&
          <Fragment>
            <div className={styles.mask} />
            {this.renderPlaceholder()}
            <div className={styles.closeBtnContainer} onClick={this.onClearBtnClicked}>
              <FontAwesomeIcon
                className={styles.closeBtn}
                icon={faTimes}
              />
            </div>
          </Fragment>
        }
        <div className={styles.fileIcon}>
          <FontAwesomeIcon icon={this.props.icon} />
        </div>
      </div>
    );
  }

  render () {
    const pickProps = _.pick(this.props, [
      'disabled',
      'name'
    ]);
    let classNames = styles.fileInputContainer;
    if (this.props.className) {
      classNames = this.classNames('fileInputContainer', {
        [this.props.className]: !!this.props.className
      });
    }
    const accept = this.props.validTypes.join(',');
    return (
      <div className={classNames}>
        <FormControl accept={accept} className={styles.fileInput} type='file' ref={this.inputRef} {...pickProps} onChange={this.onFileChange} />
        <div
          className={styles.filePreview}
          onClick={this.onFilePreviewClick}
        >
          {
            this.state.showFileIcon ?
              this.renderFilePreview() :
              this.renderPlaceholder()
          }
        </div>
      </div>
    );
  }
}

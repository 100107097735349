import { Retail, Product, ProductSet } from 'core/product/Product';
import client from './RestClient';
import _ from 'lodash';

export interface RdpWebService {
  getRetails (): Promise<Retail[]>;
  getRetailProducts (retailId, queries?: any): Promise<Product[]>;
  getAllProducts (queries?: any): Promise<Product[]>;
  getRetailProductSets (retailId, advertiserId): Promise<ProductSet[]>;
  getAllRetailProductSets (advertiserId): Promise<ProductSet[]>;
  getAllProductSets (): Promise<ProductSet[]>;
  getProductSetDetail (retailId, productSetId): Promise<ProductSet>;
  createProductSet (retailId, productSetFormData): Promise<ProductSet>;
  updateProductSetAdd (retailId, productSetFormData): Promise<ProductSet>;
  updateProductSetRemove (retailId, productSetFormData): Promise<ProductSet>;
}

const wrapProductSetFromServer: any = (json: any) => {
  const products = _.get(json, 'products', []);

  return {
    ...json,
    update_frequency: _.get(json, 'update_frequency', 0),
    products: products.map(product => ({
      ...wrapProductFromServer(product),
      retailId: _.get(json, 'retailId', '')
    }))
  };
};

const wrapProductFromServer: any = (json: any) => {
  const skus = _.get(json, 'skus[0]', {});

  return {
    productId: _.get(json, 'productId', 0),
    retailId: _.get(json, 'retailId', ''),
    name: _.get(skus, 'name', ''),
    category: _.get(skus, 'category', ''),
    salePrice: _.get(skus, 'salePrice', 0),
    imgLink: _.get(skus, 'imgLink', '')
  };
};

export class RestfulRdpWebService implements RdpWebService {
  restClient: any;

  constructor (restClient: any = client) {
    this.restClient = restClient;
  }

  async getRetails (): Promise<Retail[]> {
    const response = await this.restClient.get(`/rdp/retail/all`);
    return response.data;
  }

  async getRetailProducts (retailId, queries: any = {}): Promise<Product[]> {
    const response = await this.restClient.get(`/rdp/retail/${retailId}/products/search/${JSON.stringify(queries)}`);
    return response.data.map(product => wrapProductFromServer(product));
  }

  async getAllProducts (queries: any = {}): Promise<Product[]> {
    const response = await this.restClient.get(`/rdp/retail/all/products/search/${JSON.stringify(queries)}`);
    return response.data.map(product => wrapProductFromServer(product));
  }

  async getRetailProductSets (retailId, advertiserId): Promise<ProductSet[]> {
    const queries = {
      advertiser: advertiserId
    };
    const response = await this.restClient.get(`/rdp/retail/${retailId}/productSets/${JSON.stringify(queries)}`);
    return response.data.map(productSet => wrapProductSetFromServer(productSet));
  }

  async getAllRetailProductSets (advertiserId: any): Promise<ProductSet[]> {
    const queries = {
      advertiser: advertiserId
    };
    const response = await this.restClient.get(`/rdp/retail/all/productSets/${JSON.stringify(queries)}`);
    return response.data.map(productSet => wrapProductSetFromServer(productSet));
  }

  async getAllProductSets (): Promise<ProductSet[]> {
    const response = await this.restClient.get(`/rdp/retail/all/productSets`);
    return response.data.map(productSet => wrapProductSetFromServer(productSet));
  }

  async getProductSetDetail (retailId: any, productSetId: any): Promise<ProductSet> {
    const response = await this.restClient.get(`/rdp/retail/${retailId}/productSets/${productSetId}/products`);
    return wrapProductSetFromServer(response.data);
  }

  async createProductSet (retailId, productSetFormData): Promise<ProductSet> {
    const response = await this.restClient.post(`/rdp/retail/${retailId}/productSets`, productSetFormData);
    return wrapProductSetFromServer(response.data);
  }

  async updateProductSetAdd (retailId: any, productSetFormData: any): Promise<ProductSet> {
    const response = await this.restClient.put(`/rdp/retail/${retailId}/productSets/add`, productSetFormData);
    return wrapProductSetFromServer(response.data);
  }

  async updateProductSetRemove (retailId: any, productSetFormData: any): Promise<ProductSet> {
    const response = await this.restClient.put(`/rdp/retail/${retailId}/productSets/remove`, productSetFormData);
    return wrapProductSetFromServer(response.data);
  }
}

import React from 'react';
import styles from './progress.module.scss';
import classNames from 'classnames/bind';

interface InputProps {
  danger?: boolean;
  deepWarning?: boolean;
  warning?: boolean;
  progress: number;
}

const Progress: React.FunctionComponent<InputProps> = (props) => {

  const cssClassNames = classNames.bind(styles);

  const progressClass = cssClassNames('progress', {
    danger: props.danger,
    deepWarning: props.deepWarning,
    warning: props.warning,
    notStart: props.progress === 0 && (!props.danger && !props.deepWarning && !props.warning)
  });

  return (
    <div className={progressClass}>
      <div
        style={{
          width: `${Math.min(props.progress * 100, 100)}%`
        }}
      />
    </div>
  );
};

export default Progress;

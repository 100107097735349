import React from 'react';
import { components } from 'react-select';
import styles from './adAccountSelect.module.scss';

export const AdAccountSingleValue = props => {
  return (
    <components.SingleValue {...props} >
      <div className={styles.adAccountSingleValue}>
        <div className={styles.adAccountName}>{props.data.label}</div>
        <div className={styles.adAccountId}>{props.data.value}</div>
      </div>
    </components.SingleValue>
  );
};

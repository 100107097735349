import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import styles from './stickableBootstrapTable.module.scss';
import classNames from 'classnames/bind';
import _ from 'lodash';
import { TablePlaceholder } from 'components/TablePlaceholder/TablePlaceholder';
import i18n from 'i18n';
import { SetRefContext } from 'contexts/SetRefContext';

interface StickableBootstrapTableProps {
  paddingBottom: boolean;
  className: string;
  scrollByContainer: boolean;
}

export class StickableBootstrapTable extends React.Component<StickableBootstrapTableProps & any, any> {

  cx: any;
  tableContainerRef: any;
  debouncedResize: any;
  setRef?: (ref: any) => void;

  static defaultProps = {
    paddingBottom: true,
    scrollByContainer: true
  };

  constructor (props) {
    super(props);
    this.cx = classNames.bind(styles);
    this.tableContainerRef = React.createRef();
    this.state = {
      dividerVisible: false,
      hoverdRowIndex: -1
    };
    this.debouncedResize = _.debounce(this.resizeHandler.bind(this), 100);
  }

  componentDidMount () {
    window.addEventListener('resize', this.debouncedResize);
  }

  componentDidUpdate () {
    const hasData = this.props.data && this.props.data.length > 0;
    const showPlaceholder = this.props.noDataIndication && !hasData;
    if (showPlaceholder && this.setRef) {
      this.setRef(undefined);
    }
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.debouncedResize);
  }

  resizeHandler = () => {
    this.setState({ ...this.state });
  }

  handleScroll = () => {
    const current = this.tableContainerRef.current;
    if (current) {
      const scrollVertical = this.props.stickHeader || this.props.stickFooter;
      if (scrollVertical) {
        const showRowDivider = current.scrollTop > 0;
        if (showRowDivider !== this.state.dividerVisible) {
          this.setState({ dividerVisible: showRowDivider });
        }
      } else {
        const showColumnDivider = current.scrollLeft > 0;
        if (showColumnDivider !== this.state.dividerVisible) {
          this.setState({ dividerVisible: showColumnDivider });
        }
      }
    }
  }

  renderTablePlaceholder () {
    if (!this.props.noDataIndication) {
      return undefined;
    }
    if (typeof this.props.noDataIndication === 'string') {
      return <TablePlaceholder placeholder={i18n.t(this.props.noDataIndication)} />;
    }

    return this.props.noDataIndication();
  }

  render () {
    const hasData = this.props.data && this.props.data.length > 0;
    const showPlaceholder = this.props.noDataIndication && !hasData;
    const containerClass = this.cx('stickableTableContainer', {
      paddingBottom: hasData && (this.props.paddingBottom || this.props.pagination),
      placeholder: showPlaceholder
    });
    const tableClass = this.cx('stickableTable', this.props.className, {
      stickHeader: this.props.stickHeader,
      stickFooter: this.props.stickFooter,
      stickFirstColumn: !this.props.stickHeader && this.props.stickFirstColumn,
      stickLastColumn: !this.props.stickHeader && this.props.stickLastColumn,
      showDivider: this.state.dividerVisible,
      horizontal: this.props.stickHeader || this.props.stickFooter,
      scrollByContainer: this.props.scrollByContainer
    });
    const bootstrapTableProps = _.omit(this.props, ['data', 'stickHeader', 'stickFooter', 'stickFirstColumn', 'stickLastColumn', 'columns', 'summaryData']);
    const hasSummaryData = this.props.summaryData !== undefined;
    const columns = this.props.columns.map(column => ({
      ...column,
      sortFunc: column.sort ?
        hasSummaryData ? (dataA, dataB, order) => {
          const dataAIsSummary = typeof dataA === 'object' && dataA !== null && dataA.isSummary;
          const dataBIsSummary = typeof dataB === 'object' && dataB !== null && dataB.isSummary;
          if (dataAIsSummary || dataBIsSummary) {
            return dataAIsSummary ? -1 : 1;
          }
          if (column.sortFunc) {
            return column.sortFunc(dataA, dataB, order);
          }

          if (order === 'desc') {
            return dataA < dataB ? 1 : -1;
          }

          return dataA > dataB ? 1 : -1;
        } : column.sortFunc
        : undefined,
      formatter: (data, ...params) => {
        if (data !== null && typeof data === 'object' && data.isSummary) {
          return <div>{data.value}</div>;
        }
        return column.formatter ? column.formatter(data, ...params) : data;
      }
    }));
    const data = this.props.data ? [ ...this.props.data ] : [];
    if (hasSummaryData) {
      const summaryData = {};
      const dataFields = _.uniq(_.concat(
        columns.map(column => column.dataField),
        Object.keys(this.props.summaryData)
      ));
      dataFields.forEach(key => {
        summaryData[key] = {
          isSummary: true,
          value: this.props.summaryData[key]
        };
      });
      data.unshift(summaryData);
    }
    const rowClasses = (row, rowIndex) => {
      return this.cx(this.props.rowClasses ? this.props.rowClasses(row, rowIndex) : undefined, {
        summaryRow: hasSummaryData && rowIndex === 0
      });
    };
    return (
      <SetRefContext.Consumer>
        {({ setRef }) => {
          this.setRef = setRef;
          return (
            <div className={containerClass}>
              {showPlaceholder ?
                this.renderTablePlaceholder() :
                <div
                  className={tableClass}
                  onScroll={this.handleScroll}
                  ref={ref => {
                    this.tableContainerRef.current = ref;
                    setRef(ref);
                  }}
                >
                  <BootstrapTable
                    {...bootstrapTableProps}
                    rowClasses={rowClasses}
                    data={data}
                    columns={columns}
                  />
                </div>
              }
            </div>
          );
        }}
      </SetRefContext.Consumer>
    );
  }
}

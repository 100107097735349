import { RtbCampaignSetupFlowPageModel } from '../RtbCampaignSetupFlowPageModel';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { RtbCampaignBasicFormModel } from 'containers/RtbCampaigns/RtbCampaignSetupFlow/RtbCampaignForm/RtbCampaignBasicFormModel';
import { EditLimitationModel } from 'containers/Limitations/EditLimitationModel';
import { AdLogoFormModel, DefaultAdLogoFormModel } from 'components/AdLogoForm/AdLogoFormModel';
import _ from 'lodash';

export interface RtbCampaignSetupStepModel {
  readonly flowModel: RtbCampaignSetupFlowPageModel;
  readonly activeTab: number;
  validateCampaignBasic (campaign): any;
  setFormikProps (formikProps): void;
  getRtbCampaignBasicFormModel (adType): RtbCampaignBasicFormModel | undefined;
  getLimitationModel (priceModel): EditLimitationModel;
  getAdLogoFormModel (): AdLogoFormModel | undefined;
  onUnmount (): void;
  goNext: () => void;
  goLast: () => void;
  goSubStep: (subStepIndex: number) => void;
}

export type RtbCampaignSetupStepProps = {
  readonly model: RtbCampaignSetupStepModel;
};

export enum CampaignSetupTab {
  BASIC,
  LIMITATION,
  TRACKING,
  ADLOGO
}

export class DefaultRtbCampaignSetupStepModel implements RtbCampaignSetupStepModel {

  flowModel: RtbCampaignSetupFlowPageModel;
  formikProps: any;
  campaignAdLogoFormModel?: AdLogoFormModel;

  constructor (
    flowModel: RtbCampaignSetupFlowPageModel,
    public activeTab: number,
    public goSubStep: (subStepIndex: number) => void,
    public goNext: () => void,
    public goLast: () => void,
    registerValidateMethod: (validateMethod) => void
  ) {
    this.flowModel = flowModel;
    registerValidateMethod(this.validate);
  }

  getRtbCampaignBasicFormModel (adType): RtbCampaignBasicFormModel | undefined {
    return this.flowModel.getRtbCampaignBasicFormModel(adType);
  }

  getLimitationModel (priceModel): EditLimitationModel {
    return this.flowModel.getLimitationModel(priceModel);
  }

  getAdLogoFormModel (): AdLogoFormModel | undefined {
    const enableAdLogo = this.flowModel.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CAMPAIGN.CUSTOMIZE_ADLOGO);
    if (!enableAdLogo) {
      return undefined;
    }
    if (this.campaignAdLogoFormModel) {
      return this.campaignAdLogoFormModel;
    }

    this.campaignAdLogoFormModel = new DefaultAdLogoFormModel();
    return this.campaignAdLogoFormModel;
  }

  setFormikProps (formikProps) {
    this.formikProps = formikProps;
  }

  validate = () => {
    if (!this.formikProps) {
      return [];
    }
    const campaignBasic = this.formikProps.values;
    const basicErrors = this.validateCampaignBasic(campaignBasic);
    this.setErrorsToFormik(basicErrors);
    const basicErrorNames = Object.keys(basicErrors);
    const limitationErrors = this.flowModel.getLimitationModel(campaignBasic.priceModel).validate();
    const limitationErrorNames = Object.keys(limitationErrors);
    this.goErrorSubStep(basicErrorNames, limitationErrorNames);
    return _.concat(basicErrorNames, limitationErrorNames);
  }

  setErrorsToFormik (errors) {
    this.formikProps && this.formikProps.setErrors(errors);
    let touched = {};
    this.generateFormikTouchedObj(errors, touched);
    this.formikProps && this.formikProps.setTouched(touched);
  }

  validateCampaignBasic (campaignBasic) {
    const adLogoErrors = this.campaignAdLogoFormModel ? this.campaignAdLogoFormModel.validateForm(campaignBasic.adLogo) : undefined;
    const basicFormModel = this.flowModel.getRtbCampaignBasicFormModel(campaignBasic.adType);
    const basicPageErrors = basicFormModel ? basicFormModel.validate(campaignBasic, this.flowModel.order, this.flowModel.localeMeta) : {};
    return _.omitBy({
      ...basicPageErrors,
      adLogo: adLogoErrors
    }, _.isEmpty);
  }

  goErrorSubStep (basicErrorNames, limitationErrorNames) {
    const errors = [...basicErrorNames];
    const basicPageErrors = _.remove(errors, (errorName) => {
      return errorName !== 'videoProgressTrackingCode' && errorName !== 'adLogo';
    });
    const trackingPageErrors = _.remove(errors, (errorName) => errorName !== 'adLogo');
    const adLogoPageErrors = errors;
    const jumpToTrackingCode =
      basicPageErrors.length === 0 &&
      limitationErrorNames.length === 0 &&
      trackingPageErrors.length > 0;
    const jumpToAdLogo =
      basicPageErrors.length === 0 &&
      limitationErrorNames.length === 0 &&
      trackingPageErrors.length === 0 &&
      adLogoPageErrors.length > 0;
    if (jumpToTrackingCode) {
      this.goSubStep(CampaignSetupTab.TRACKING);
    } else if (jumpToAdLogo) {
      this.goSubStep(CampaignSetupTab.ADLOGO);
    } else if (basicPageErrors.length > 0) {
      this.goSubStep(CampaignSetupTab.BASIC);
    } else if (limitationErrorNames.length > 0) {
      this.goSubStep(CampaignSetupTab.LIMITATION);
    }
  }

  generateFormikTouchedObj (errors, touched) {
    if (!errors) {
      return;
    }
    let keys = Object.keys(errors);
    keys.forEach(key => {
      let value = errors[key];
      if (typeof value === 'object') {
        touched[key] = {};
        this.generateFormikTouchedObj(errors[key], touched[key]);
      } else {
        touched[key] = true;
      }
    });
  }

  onUnmount () {
    if (!this.formikProps || !this.flowModel.state.campaign) {
      return;
    }
    const limitationModel = this.flowModel.getLimitationModel(this.formikProps.values.priceModel);
    this.flowModel.setCampaign({
      basic: { ...this.formikProps.values },
      limitations: limitationModel.limitationValue
    }, true);
  }
}

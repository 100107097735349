import { ErrorWebService, RestfulErrorWebService } from 'ws/ErrorWebService';

export interface ErrorManager {
  post (message: string, errorCode: string, email: string): Promise<void>;

}

export class DefaultErrorManager implements ErrorManager {
  webService: ErrorWebService;

  constructor (webService: ErrorWebService = new RestfulErrorWebService()) {
    this.webService = webService;
  }

  post (message: string, errorCode: string, email: string): Promise<void> {
    return this.webService.post(message, errorCode, email);
  }
}

import { CampaignState } from 'core/rtbCampaign/RtbCampaign';
import { FBBidStrategy } from 'core/campaignGroup/CampaignGroup';
import { GoCampaignOptimizationGoal } from 'core/goCampaign/GoCampaign';

export type FbAdSet = {
  id: number | string;
  name: string;
  lifetime_budget?: number | string;
  bid_strategy: FBBidStrategy;
  bid_amount?: number | string;
  optimization_goal: GoCampaignOptimizationGoal;
  billing_event: BillingEvent;
  start_time: string;
  end_time: string;
  budget_remaining?: number | string;
  state: CampaignState;
  configured_status: string;
  effective_status: string;
  issues_info: any;
  report: any;
  lifetime_min_spend_target?: number;
  lifetime_spend_cap?: number;
  groupId: number;
  conversion?: any;
  promoted_object?: any;
  destination_type?: string;
  dayPart?: { [key: string]: string[] | number[] | string };
  draftId?: number;
  isDraft?: boolean;
};

export enum BillingEvent {
  // APP_INSTALLS = 'APP_INSTALLS',
  // CLICKS = 'CLICKS',
  IMPRESSIONS = 'IMPRESSIONS',
  LINK_CLICKS = 'LINK_CLICKS'
  // NONE = 'NONE',
  // OFFER_CLAIMS = 'OFFER_CLAIMS',
  // PAGE_LIKES = 'PAGE_LIKES',
  // POST_ENGAGEMENT = 'POST_ENGAGEMENT',
  // THRUPLAY = 'THRUPLAY',
  // PURCHASE = 'PURCHASE',
  // LISTING_INTERACTION = 'LISTING_INTERACTION'
}

export enum FBAppEvent {
  PURCHASE = 'PURCHASE',
  INITIATED_CHECKOUT = 'INITIATED_CHECKOUT',
  ADD_TO_CART = 'ADD_TO_CART',
  CONTENT_VIEW = 'CONTENT_VIEW'
}

export enum FbDestinationType {
  ON_AD = 'ON_AD',
  ON_POST = 'ON_POST',
  ON_VIDEO = 'ON_VIDEO',
  ON_EVENT = 'ON_EVENT',
  ON_PAGE = 'ON_PAGE'
}

export const ADSET_DEFAULT_AGE_MIN = 21;
export const ADSET_DEFAULT_AGE_MAX = 65;


import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import cx from 'classnames/bind';
import styles from './storySpacePreview.module.scss';

const classNames = cx.bind(styles);

export const StorySpacePreview = () => {
  const [showPreview, setShowPreview] = useState(false);

  const storySpacePreviewClass = classNames('storySpacePreview', 'storySpacePreviewPosition');

  const handleOnMouseEnterIcon = () => setShowPreview(true);
  const handleOnMouseLeaveIcon = () => setShowPreview(false);

  const renderStorySpacePreviewIcon = (
    <FontAwesomeIcon
      className={styles.storySpacePreviewIcon}
      icon={faInfoCircle}
      onMouseEnter={handleOnMouseEnterIcon}
      onMouseLeave={handleOnMouseLeaveIcon}
    />
  );

  const renderStorySpacePreviewContent = (
      <div className={storySpacePreviewClass}>
        <div className={styles.mobilePhoneTemplate}>
          <div className={styles.storySpaceTemplate}>
            <div className={styles.title}>
              <div className={styles.storyLogo}/>
              <div className={styles.storyTextOrBtn}>
                Text or Button Area
              </div>
            </div>
            <div className={styles.content}>
              <div className={styles.storyRatioInfo}>
                9 : 16
              </div>
              <div className={styles.storySizeInfo}>
                1080 x 1920px
              </div>
              <div className={styles.storySizeInfo}>
                optimal size
              </div>
            </div>
            <div className={styles.footer}>
              <div className={styles.storyInfoLink}>
                Information Link Bar
              </div>
            </div>
          </div>
        </div>
      </div>
  );

  return (
    <>
      {renderStorySpacePreviewIcon}
      {showPreview && renderStorySpacePreviewContent}
    </>
  );
};

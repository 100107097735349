import DefaultCreativeManager from 'core/creative/CreativeManager';
import { useCallAPI } from 'hooks/useCallAPI';
import { useEffect, useMemo, useState } from 'react';

export const useFBVideoPreview = (
  advertiserId: number | string,
  typeProperties: any
): {
  previewData: { [key: string]: string | undefined }
} => {
  const fbPreviewTypes = useMemo(() => [
    'VALUE_MOBILE_INTERSTITIAL',
    'VALUE_MOBILE_FEED_STANDARD',
    'VALUE_INSTAGRAM_STORY',
    'VALUE_INSTAGRAM_STANDARD'
  ], []);
  const initPreviewData = fbPreviewTypes.reduce<{ [key: string]: string | undefined }>((acc, type) => {
    acc[type] = undefined;
    return acc;
  }, {});
  const [previewData, setPreviewData] = useState(initPreviewData);
  const { callAPIs } = useCallAPI();

  useEffect(() => {
    const formData = {
      type: 'VIDEO',
      advertiserId: advertiserId.toString()
    };
    Object.keys(typeProperties).forEach(key => {
      if (typeProperties[key] !== null && key !== 'deepLink') {
        formData[`${key}`] = typeProperties[key];
      }
    });
    const creativeManager = new DefaultCreativeManager();
    callAPIs([creativeManager.getFbCreativePreview.bind(creativeManager, formData)], (previewIframes) => {
      let result = {};
      fbPreviewTypes.forEach(type => {
        result[type] = previewIframes[type];
      });
      setPreviewData(result);
    });
  }, [advertiserId, typeProperties, fbPreviewTypes, callAPIs]);

  return {
    previewData
  };
};

import React from 'react';
import { Switch, Route, RouteComponentProps, Redirect } from 'react-router-dom';
import RouteWithPermission from 'components/RouteWithPermission/RouteWithPermission';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { hasFuncs } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';
import { ROUTE_PATH } from 'enum/RoutePath';
import { withErrorBoundary } from 'hoc/withErrorBoundary';
import { ERROR_CODE } from 'core/ErrorCode';
import { FbAdSetSetupFlowPage } from './FbAdSetSetupFlowPage/FbAdSetSetupFlowPage';
import { useCreateFbAdSetSetupFlowPageModel, useEditFbAdSetSetupFlowPageModel } from './FbAdSetSetupFlowPage/FbAdSetSetupFlowPageModel';

const CreateAdSetView = withErrorBoundary(ERROR_CODE.ADSET_CREATE, FbAdSetSetupFlowPage);
export const FbAdSets: React.FC<RouteComponentProps<any> & any> = ({
  match,
  order,
  campaignGroup,
  fbAdSetList
}) => {

  const renderCreateAdSetView = props => {
    const renderRedirect = () => <Redirect to={`${props.match.url}/${ROUTE_PATH.ERROR403}`} />;
    return (
      <PermissionChecker
        permissionAware={hasFuncs(Permission.CAMPAIGN_WRITE)}
        renderOtherwise={renderRedirect}
      >
        <CreateAdSetView
          order={order}
          campaignGroup={campaignGroup}
          fbAdSetList={fbAdSetList}
          useModel={useCreateFbAdSetSetupFlowPageModel}
        />
      </PermissionChecker>
    );
  };

  const renderEditAdSetView = props => {
    const renderRedirect = () => <Redirect to={`${props.match.url}/${ROUTE_PATH.ERROR403}`} />;
    return (
      <PermissionChecker
        permissionAware={hasFuncs(Permission.CAMPAIGN_WRITE)}
        renderOtherwise={renderRedirect}
      >
        <CreateAdSetView
          order={order}
          campaignGroup={campaignGroup}
          fbAdSetList={fbAdSetList}
          useModel={useEditFbAdSetSetupFlowPageModel}
        />
      </PermissionChecker>
    );
  };

  const redirectToCampaignGroup = (props) => {
    const match = props.match.url.match(/\/campaign-groups\/\d+\//);
    return <Redirect to={`${match[0].slice(0, -1)}`} />;
  };

  return (
    <Switch>
      <RouteWithPermission
        permissionAware={hasFuncs(Permission.CAMPAIGN_WRITE)}
        redirectPath={`${match.url}/new/${ROUTE_PATH.ERROR403}`}
        path={`${match.url}/new`}
        render={renderCreateAdSetView}
      />
      <Route
        exact
        path={`${match.url}/:adSetId/edit`}
        render={renderEditAdSetView}
      />
      <Route
        render={redirectToCampaignGroup}
      />
    </Switch>
  );
};

import React from 'react';
import _ from 'lodash';
import { Form } from 'react-bootstrap';
import classnames from 'classnames/bind';
import styles from './formikLabel.module.scss';
import { getFieldErrors } from 'utils/FormikUtils';
import Tips from '../Tips/Tips';
const cx = classnames.bind(styles);
export const FormikLabel: React.FunctionComponent<any> = ({
  field: { value, name },
  formatter,
  ...props
}) => {
  const className = cx(props.className, ['col-form-label', 'label']);
  const errors = getFieldErrors(props.form, name);
  let label;
  if (props.options) {
    const optionsValue = _.find(props.options, { value });
    if (optionsValue && optionsValue.label) {
      label = <Form.Label className={className}>{formatter ? formatter(optionsValue.label) : optionsValue.label}</Form.Label>;
    }
  }
  label = <Form.Label className={className}>{formatter ? formatter(value) : value}</Form.Label>;
  return (
    <>
      {label}
      {errors && <Tips>{errors}</Tips>}
    </>
  );
};

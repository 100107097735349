export enum ERROR_CODE {
  UNKNOWN_ERROR = '000',
  API_ERROR = '001',
  ORDER_HOME = '100',
  ORDER_DETAIL = '101',
  ORDER_CREATE = '102',
  ORDER_EDIT = '103',
  ORDER_GROUP_DETAIL = '105',
  CAMPAIGN_CREATE = '201',
  CAMPAIGN_EDIT = '202',
  CAMPAIGN_COPY = '203',
  CAMPAIGN_SPLIT = '204',
  CAMPAIGN_GROUP_CREATE = '205',
  CAMPAIGN_GROUP_EDIT = '206',
  CAMPAIGN_GROUP_COPY = '207',
  ADSET_CREATE = '208',
  CREATIVE_HOME = '301',
  CREATIVE_CREATE = '302',
  CREATIVE_EDIT = '303',
  AGENCY_HOME = '400',
  AGENCY_DETAIL = '401',
  AGENCY_CREATE = '402',
  AGENCY_EDIT_BY_ADMIN = '403',
  AGENCY_EDIT_BY_AGENCY = '404',
  ADVERTISER_HOME = '500',
  ADVERTISER_DETAIL = '501',
  ADVERTISER_CREATE = '502',
  ADVERTISER_EDIT = '503',
  SEGMENT_HOME = '600',
  SEGMENT_DETAIL = '601',
  SEGMENT_CREATE = '602',
  SEGMENT_EDIT = '603',
  ACCOUNT_HOME = '700',
  ACCOUNT_DETAIL = '701',
  ACCOUNT_CREATE = '702',
  ACCOUNT_EDIT = '703',
  CONVERSION_HOME = '800',
  CONVERSION_CREATE = '801',
  CONVERSION_EDIT = '802',
  REPORT_PERFORMANCE = '900',
  REPORT_RECONCILIATION = '901',
  REPORT_COMPARE = '902',
  DOCTOR = '1000',
  PRODUCT_CATEGORY = '1100',
  DRAFT_EDIT = '1200'
}

import { SavedTargeting } from 'core/limitation/Limitation';
import { DefaultLimitationManager, LimitationManager } from 'core/limitation/LimitationManager';
import { useCallback, useEffect, useState } from 'react';
import { useCallAPI } from './useCallAPI';

const defaultLimitationManager = new DefaultLimitationManager();
export const useGetSavedTargeting = (
  advertiserId: number,
  channel: string,
  limitationManager: LimitationManager = defaultLimitationManager
) => {

  const { loading, callAPIs } = useCallAPI();
  const [savedTAList, setSavedTAList] = useState<SavedTargeting[]>([]);

  const fetchSavedTargeting = useCallback(() => callAPIs([
    () => limitationManager.getSavedTargetings(advertiserId, channel)
  ], (savedTAList) => {
    setSavedTAList(savedTAList);
  }), [advertiserId, channel, limitationManager, callAPIs]);

  useEffect(() => {
    fetchSavedTargeting();
  }, [fetchSavedTargeting]);

  return {
    loading,
    savedTAList,
    fetchSavedTargeting
  };
};

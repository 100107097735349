import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { faPencilAlt, faChartArea, faEye, faTimes } from '@fortawesome/free-solid-svg-icons';
import { OrderListProps, OrderListState, OrderListColumns } from './OrderListModel';
import _ from 'lodash';
import i18n from 'i18n';
import styles from './OrderList.module.scss';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { renderColumn } from 'components/TableColumn';
import { StickableBootstrapTable } from 'components/common/Table/StickableBootstrapTable';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { State, OrderCreationInfo } from 'core/order/Order';
import { SearchBar } from 'components/common/SearchBar';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { hasFuncs, hasRoles, notRoles } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';
import { RoleNames } from 'core';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import TableRowToolBar from 'containers/TableRowToolBar/TableRowToolBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FilterMenuTab } from 'components/common/FilterMenuTab/FilterMenuTab';

export class OrderList extends React.Component<OrderListProps, OrderListState> {

  handler?: number;
  columnFormatterMap: any;
  searchBarRef: any;
  filterMenuRef: any;

  constructor (props) {
    super(props);
    this.searchBarRef = React.createRef();
    this.props.model.setSearchbarRef(this.searchBarRef);
    this.state = this.props.model.state;
    this.columnFormatterMap = {
      [OrderListColumns.NAME]: this.nameFormatter,
      [OrderListColumns.ADVERTISER]: this.dataFormatter,
      [OrderListColumns.EXTERNALID]: this.dataFormatter,
      [OrderListColumns.APPROVAL]: this.approvalFormatter,
      [OrderListColumns.ENDDATE]: this.dataFormatter,
      [OrderListColumns.REMAINDAY]: this.dataFormatter,
      [OrderListColumns.PROGRESS]: this.progressFormatter,
      [OrderListColumns.BUDGET]: this.budgetFormatter,
      [OrderListColumns.SPENT]: this.dataFormatter,
      [OrderListColumns.EDITBTNS]: this.floatingEditBtnsFormatter
    };
  }

  componentDidMount () {
    this.handler = this.props.model.event.add(model => {
      this.setState(model.state);
    });
    this.props.model.init();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add((model) => {
        this.setState(model.state);
      });
      this.props.model.init();
    }
  }

  componentWillUnmount () {
    this.props.model.onUnmount(this.handler);
  }

  nameFormatter = (data: any, order: any): any => {
    const model = this.props.model;
    const handleOnClick = () => {
      model.handleCreaterInfoApply(_.uniq([...this.state.selectedOrdersToShowInfo, order.id]));
    };
    return (
      <div className={styles.orderCellBox}>
        <div className={`${styles.orderCell} ${styles.nameCell}`}>
          <Link to={`/orders/${data.value}`} className={styles.name}>
            {data.name}
          </Link>
          <div className={styles.orderNumberCell}>
            {data.orderNumber}
          </div>
        </div>
        <PermissionChecker permissionAware={hasRoles(RoleNames.agencyAdmin)}>
          <IconWithTooltip
            placement='right'
            className={styles.detailBtn}
            onClick={handleOnClick}
            icon={faEye}
            tooltipProps={{
              id: `orderListCreaterInformation-${data.orderNumber}`,
              tooltip: i18n.t('orderList.labels.createrInformation')
            }}
          />
        </PermissionChecker>
      </div>
    );
  }

  approvalFormatter = (data: any, order: any): any => {
    const dangerClass = _.includes([State.NOT_APPROVE, State.CHANGE_PENDING], data.value) ? styles.danger : undefined;
    return (
      <div className={styles.orderCellBox}>
        <div className={`${styles.orderCell} ${dangerClass}`}>
          {data.desc}
        </div>
        {this.state.selectedOrdersToShowInfo.includes(order.id) && this.renderCreaterInformation(order)}
      </div>
    );
  }

  budgetFormatter = (data: any, order: any): any => {
    const dangerClass = _.includes([State.NOT_APPROVE, State.CHANGE_PENDING], order.state.value) ? styles.danger : undefined;
    return (
      <div className={`${styles.orderCell} ${dangerClass} ${styles.budgetCell}`}>
        <div className={styles.budget}>
          {data}
        </div>
        { order.needAlert &&
          <div className={styles.remainBudgetHint}>{i18n.t('orderList.labels.remainBudget')}</div>
        }
      </div>
    );
  }

  dataFormatter = (data: any, row: any): any => {
    return (
      <div className={styles.orderCell}>{data}</div>
    );
  }

  progressFormatter = (data: any, order: any): any => {
    if (data.length === 0) {
      return (
        <div className={styles.orderCell}>
          {i18n.t('campaignList.labels.emptyContent')}
        </div>
      );
    }
    return (
      <div className={`${styles.orderCell} ${styles.progressCell}`}>
        {
          Object.keys(data).map(key => {
            const className = `${styles.progress} ${styles[key]}`;
            return (
              <div key={key} className={className}>
                {i18n.t(`orderList.labels.${_.camelCase(`progress-${key}`)}`, { count: data[key] })}
              </div>
            );
          })
        }
      </div>
    );
  }

  floatingEditBtnsFormatter = (cell: any, order: any): any => {
    return (
      <TableRowToolBar className={styles.floatingEditArea}>
        <PermissionChecker permissionAware={hasFuncs(Permission.ORDER_WRITE)}>
          <Link
            to={`/orders/${order.orderNumber.value}/edit`}
          >
            <IconWithTooltip
              icon={faPencilAlt}
              tooltipProps={{
                id: `orderListEditTip-${order.id}`,
                tooltip: i18n.t('orderList.labels.editHint')
              }}
            />
          </Link>
        </PermissionChecker>
        <Link
          to={this.props.model.getReportPath(order)}
        >
          <IconWithTooltip
            icon={faChartArea}
            tooltipProps={{
              id: `orderListReportTip-${order.id}`,
              tooltip: i18n.t('orderList.labels.reportHint')
            }}
          />
        </Link>
      </TableRowToolBar>
    );
  }

  handleTableChange = (type, props) => {
    if (type === 'sort') {
      this.props.model.sortOrders(props.sortField, props.sortOrder);
      return;
    }
    this.props.model.init(props.page);
  }

  handleOnSearch = (searchString) => {
    this.props.model.handleOnSearch(searchString);
  }

  renderCreaterInformation = (order) => {
    const model = this.props.model;
    const creationInfos = _.map(Object.keys(OrderCreationInfo), key => OrderCreationInfo[key]);
    const handleOnClick = () => {
      model.handleCreaterInfoApply(this.state.selectedOrdersToShowInfo.filter(selectedId => selectedId !== order.id));
    };
    return (
      <div className={styles.createrInfo}>
        <div className={styles.closeBtn}>
          <FontAwesomeIcon
            icon={faTimes}
            onClick={handleOnClick}
          />
        </div>
        {_.map(creationInfos, (info, index) => (
          <div className={styles.info} key={index}>
            <div className={styles.title}>{i18n.t(`orderList.labels.${info}`)}</div>
            <div className={styles.content}>{_.get(order, info, 'n/a')}</div>
          </div>
        ))}
      </div>
    );
  }

  setFilterMenuRef = (ref: any) => {
    this.filterMenuRef = ref;
  }

  removeFilterMenuRef = () => {
    if (this.filterMenuRef) {
      this.filterMenuRef.hide();
    }
  }

  render () {
    const model = this.props.model;
    const ordersWithPagination = model.state.ordersWithPagination;
    if (!ordersWithPagination) {
      return <LoadingIndicator />;
    }

    if (model.state.redirectPath) {
      return <Redirect push to={model.state.redirectPath}/>;
    }

    const columns = _.compact(Object.values(OrderListColumns).map(columnToRender => {
      if (columnToRender === OrderListColumns.PROGRESS) {
        return undefined;
      }
      return renderColumn(model.getColumnDefinition(columnToRender), this.columnFormatterMap[columnToRender]);
    }));

    const orderViewData = model.getViewModelData();
    const rowClasses = (order) => {
      let classes: any = null;
      if (order.needAlert) {
        classes = [classes, styles.lightDanger].join(' ');
      }
      return classes;
    };

    const tableProps = {
      stickFirstColumn: true,
      stickLastColumn: true,
      remote: true,
      keyField: 'id',
      data: orderViewData,
      columns: columns,
      defaultSorted: model.sortDescriptor,
      noDataIndication: i18n.t(model.noDataDescription),
      pagination: paginationFactory({
        hideSizePerPage: true,
        page: ordersWithPagination.pagination.page,
        sizePerPage: model.ordersSizePerPage,
        totalSize: ordersWithPagination.pagination.totalCount
      }),
      rowClasses,
      onTableChange: this.handleTableChange
    };

    const renderTableWithoutAdvertiser = () => {
      const columnsWithoutAdvertiser = columns.filter(column => column.dataField !== OrderListColumns.ADVERTISER);
      return (
        <StickableBootstrapTable
          {...tableProps}
          columns={columnsWithoutAdvertiser}
        />
      );
    };

    return (
      <div className={styles.orderListContainer}>
        {model.state.loading && <LoadingIndicator/>}
        <div className={styles.operationArea}>
          <PermissionChecker permissionAware={hasFuncs(Permission.ORDER_WRITE)}>
            <Link
              to={'/orders/new'}
              className={'btn btn-tertiary btn-sm'}
            >
              {i18n.t('orders.labels.newOrder')}
            </Link>
          </PermissionChecker>
          <FilterMenuTab
            filterMenuTabConfigs={model.filterMenuTabConfigs}
            setFilterMenuRef={this.setFilterMenuRef}
            removeFilterMenuRef={this.removeFilterMenuRef}
          />
          <SearchBar
            ref={this.searchBarRef}
            model={{
              placeholder: i18n.t('orderList.placeholders.searchbar'),
              search: this.handleOnSearch,
              defaultValue: model.searchString
            }}
          />
        </div>
        <div className={styles.orderList}>
          <PermissionChecker
            permissionAware={notRoles(RoleNames.adsAdmin, RoleNames.adsSales, RoleNames.adsReport)}
            renderOtherwise={renderTableWithoutAdvertiser}
          >
            <StickableBootstrapTable {...tableProps}/>
          </PermissionChecker>
        </div>
      </div>
    );
  }
}

import React from 'react';
import styles from '../../creativeSummaryStep.module.scss';

export const renderFBPage = (label, id) => (
  <span className={styles.fbPageOption}>
    <img
      className={styles.fbPageOptionIcon}
      src={`https://graph.facebook.com/${id}/picture/?type=square`}
      alt=''
    />
    {label}
  </span>
);

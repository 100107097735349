export enum CampaignListColumns {
  NAME = 'nameColumn',
  RETAIL = 'retailType',
  STATE = 'stateColumn',
  OPTIMIZE = 'optimize',
  DELIVERY = 'deliveryColumn',
  CREATIVE = 'creativeColumn',
  SCHEDULE = 'scheduleColumn',
  PROGRESS = 'progressColumn',
  BUDGET = 'budgetColumn',
  BUDGET_GROUP = 'budgetGroupColumn',
  PRICE_GROUP = 'priceGroupColumn',
  PRICE = 'priceColumn',
  LIMITATION = 'limitationColumn',
  TRACKING = 'trackingColumn',
  RESULTS = 'resultsColumn',
  IMPRES = 'impresColumn',
  VIEWABLE = 'viewableColumn',
  CLICKS = 'clicksColumn',
  CPC = 'cpcColumn',
  CTR = 'ctrColumn',
  VCTR = 'vctrColumn',
  CONVERT = 'convertColumn',
  CPA = 'cpaColumn',
  CVR = 'cvrColumn',
  VIEW = 'viewColumn',
  VIEWRATE = 'viewRateColumn',
  VIEWABLE_VIEWRATE = 'viewableViewRateColumn',
  TAGS = 'tagsColumn',
  UUCOUNT = 'uuCountColumn',
  SPENT = 'spents',
  EDITBTNS = 'editBtnsColumn'
}

export const basicColumns = [
  CampaignListColumns.NAME,
  CampaignListColumns.STATE,
  CampaignListColumns.DELIVERY,
  CampaignListColumns.CREATIVE,
  CampaignListColumns.SCHEDULE,
  CampaignListColumns.PROGRESS,
  CampaignListColumns.BUDGET,
  CampaignListColumns.PRICE,
  CampaignListColumns.OPTIMIZE,
  CampaignListColumns.LIMITATION,
  CampaignListColumns.TRACKING,
  CampaignListColumns.TAGS,
  CampaignListColumns.EDITBTNS
];

export const fbCampaignListColumns = [
  CampaignListColumns.NAME,
  CampaignListColumns.CREATIVE,
  CampaignListColumns.SCHEDULE,
  CampaignListColumns.PROGRESS,
  CampaignListColumns.BUDGET,
  CampaignListColumns.PRICE,
  CampaignListColumns.LIMITATION,
  CampaignListColumns.EDITBTNS
];

export const performanceColumns = [
  CampaignListColumns.NAME,
  CampaignListColumns.PROGRESS,
  CampaignListColumns.BUDGET,
  CampaignListColumns.LIMITATION,
  CampaignListColumns.RESULTS,
  CampaignListColumns.IMPRES,
  CampaignListColumns.CLICKS,
  CampaignListColumns.CPC,
  CampaignListColumns.CTR,
  CampaignListColumns.CONVERT,
  CampaignListColumns.CPA,
  CampaignListColumns.CVR,
  CampaignListColumns.UUCOUNT,
  CampaignListColumns.SPENT,
  CampaignListColumns.TAGS,
  CampaignListColumns.EDITBTNS
];

export const performanceColumnsWithViewable = [
  CampaignListColumns.NAME,
  CampaignListColumns.PROGRESS,
  CampaignListColumns.BUDGET,
  CampaignListColumns.LIMITATION,
  CampaignListColumns.RESULTS,
  CampaignListColumns.IMPRES,
  CampaignListColumns.VIEWABLE,
  CampaignListColumns.CLICKS,
  CampaignListColumns.CPC,
  CampaignListColumns.CTR,
  CampaignListColumns.VCTR,
  CampaignListColumns.CONVERT,
  CampaignListColumns.CPA,
  CampaignListColumns.CVR,
  CampaignListColumns.UUCOUNT,
  CampaignListColumns.SPENT,
  CampaignListColumns.TAGS,
  CampaignListColumns.EDITBTNS
];

export const videoPerformanceColumns = [
  CampaignListColumns.NAME,
  CampaignListColumns.PROGRESS,
  CampaignListColumns.BUDGET,
  CampaignListColumns.LIMITATION,
  CampaignListColumns.VIEW,
  CampaignListColumns.VIEWRATE,
  CampaignListColumns.IMPRES,
  CampaignListColumns.CLICKS,
  CampaignListColumns.UUCOUNT,
  CampaignListColumns.SPENT,
  CampaignListColumns.TAGS,
  CampaignListColumns.EDITBTNS
];

export const videoPerformanceColumnsWithViewable = [
  CampaignListColumns.NAME,
  CampaignListColumns.PROGRESS,
  CampaignListColumns.BUDGET,
  CampaignListColumns.LIMITATION,
  CampaignListColumns.VIEW,
  CampaignListColumns.VIEWRATE,
  CampaignListColumns.VIEWABLE_VIEWRATE,
  CampaignListColumns.IMPRES,
  CampaignListColumns.VIEWABLE,
  CampaignListColumns.CLICKS,
  CampaignListColumns.VCTR,
  CampaignListColumns.UUCOUNT,
  CampaignListColumns.SPENT,
  CampaignListColumns.TAGS,
  CampaignListColumns.EDITBTNS
];

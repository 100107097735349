import {
  RtbCampaignWebService,
  RestfulCampaignWebService,
  EstimateDataPayload
} from 'ws/RtbCampaignWebService';
import {
  RtbCampaign,
  VideoAdViewObjective,
  VideoAdMetricEvent,
  RtbCampaignBasic,
  BillingEvent,
  RtbCampaignPlanType
} from './RtbCampaign';
import _ from 'lodash';
import { SelectOptions } from 'components/common/commonType';
import LocalDateTimeUtil from 'utils/LocalDateTimeUtil';
import { EstimateData } from './EstimateData';
import moment from 'moment';
import { GoCampaignGroupObjective } from 'core/campaignGroup/CampaignGroup';
import { GoCampaignOptimizationGoal } from 'core/goCampaign/GoCampaign';
import i18n from 'i18n';
import { generateI18nOfSelectOptions } from 'utils/I18nUtils';
import { toServerStructure } from 'utils/LimitationUtil';
import { AdLogoType } from 'core/adLogo/AdLogo';

export interface RtbCampaignManager {
  getCampaigns (orderId): Promise<Array<RtbCampaignBasic>>;
  getCampaignsOfGroup (groupId: number | string): Promise<RtbCampaignBasic[]>;
  getCampaign (campaignId): Promise<RtbCampaign>;
  getNoCidCampaign (campaignId): Promise<RtbCampaign>;
  createCampaign (campaign: RtbCampaign, campaignGroupId?: number | string): Promise<void>;
  updateCampaign (campaign: RtbCampaign, campaignGroupId?: number | string): Promise<void>;
  splitCampaign (campaign: RtbCampaign, origCamaignId, campaignGroupId?: number | string): Promise<void>;
  updateCampaignState (campaignData: {
    goCampaignChannelId: (number | string),
    isDraft: boolean
  }[], state: 'activate' | 'deactivate'): Promise<void>;
  deleteCampaigns (campaignIds: Array<number>): Promise<void>;
  getCampaignOptions (): Promise<Array<SelectOptions>>;
  getOrderNumber (campaignId: string | number): Promise<string>;
  getEstimateData (payload: EstimateDataPayload): Promise<EstimateData>;
  updateCampaignBidWeight (campaignId: string | number, bidWeight: number): Promise<void>;
  getMinBudgetOfCampaign (campaign: RtbCampaignBasic, budgetMinimumPerDay: number);
  getDefaultBillingEvent (campaignGroup);
  getBilliingEventOptions (campaignGroup, optimizationGoal);
  getDefaultOptimizationGoal (campaignGroup);
  getOptimizationGoalOptions (campaignGroup);
  getRetailOptions (): Promise<SelectOptions[]>;
  prepareCreateCampaignPayload (campaign: RtbCampaign);
}

const campaignBasicSelectOptionConfig = {
  [GoCampaignGroupObjective.AwarenessObjective.REACH]: {
    [GoCampaignOptimizationGoal.REACH]: [
      BillingEvent.IMPRESSIONS
    ],
    [GoCampaignOptimizationGoal.IMPRESSIONS]: [
      BillingEvent.IMPRESSIONS
    ]
  },
  [GoCampaignGroupObjective.ConsiderationObjective.LINK_CLICKS]: {
    [GoCampaignOptimizationGoal.LINK_CLICKS]: [
      BillingEvent.IMPRESSIONS,
      BillingEvent.LINK_CLICKS
    ],
    [GoCampaignOptimizationGoal.IMPRESSIONS]: [
      BillingEvent.IMPRESSIONS
    ]
  },
  [GoCampaignGroupObjective.ConversionObjective.CONVERSIONS]: {
    [GoCampaignOptimizationGoal.OFFSITE_CONVERSIONS]: [
      BillingEvent.IMPRESSIONS
    ]
  }
};

const rtbDefaultGoals = {
  [GoCampaignGroupObjective.AwarenessObjective.REACH]: GoCampaignOptimizationGoal.REACH.toString(),
  [GoCampaignGroupObjective.ConsiderationObjective.LINK_CLICKS]: GoCampaignOptimizationGoal.LINK_CLICKS.toString()
  // [GoCampaignGroupObjective.ConversionObjective.CONVERSIONS]: GoCampaignOptimizationGoal.OFFSITE_CONVERSIONS.toString()
};

export class DefaultRtbCampaignManager implements RtbCampaignManager {
  webService: RtbCampaignWebService;

  constructor (
    webService: RtbCampaignWebService = new RestfulCampaignWebService()
  ) {
    this.webService = webService;
  }

  async getCampaign (campaignId: number): Promise<RtbCampaign> {
    const campaign = await this.webService.getCampaign(campaignId);
    const setOtherField = _.flow([_.cloneDeep, this.setVideoAdEvent]);
    return setOtherField(campaign);
  }

  async getNoCidCampaign (campaignId: number): Promise<RtbCampaign> {
    const campaign = await this.getCampaign(campaignId);
    _.unset(campaign, 'basic.id');
    _.set(campaign, 'basic.name', campaign.basic.name + '_' + LocalDateTimeUtil.getYMDHMS());
    return campaign;
  }

  async getCampaigns (orderId: number): Promise<Array<RtbCampaignBasic>> {
    return this.webService.getCampaigns(orderId);
  }

  async getCampaignsOfGroup (groupId: number | string): Promise<RtbCampaignBasic[]> {
    return this.webService.getCampaignsOfGroup(groupId);
  }

  async createCampaign (campaign: RtbCampaign, groupId?: number | string): Promise<void> {
    await this.webService.createCampaign(this.prepareCreateCampaignPayload(campaign), groupId);
  }

  async updateCampaign (campaign: RtbCampaign, groupId?: number | string): Promise<void> {
    await this.webService.updateCampaign(this.wrapCampaignForServer(_.cloneDeep(campaign)), groupId);
  }

  async splitCampaign (campaign: RtbCampaign, origCamaignId, campaignGroupId?: number | string): Promise<void> {
    await this.webService.splitCampaign(this.prepareCreateCampaignPayload(campaign), origCamaignId, campaignGroupId);
  }

  async updateCampaignState (campaignData: {
    goCampaignChannelId: (number | string),
    isDraft: boolean
  }[], state: 'activate' | 'deactivate'): Promise<void> {
    await this.webService.updateCampaignState(campaignData, state);
  }

  async deleteCampaigns (campaignIds: Array<number>): Promise<void> {
    return this.webService.deleteCampaigns(campaignIds);
  }

  async getCampaignOptions (): Promise<Array<SelectOptions>> {
    return this.webService.getCampaignOptions();
  }

  async getOrderNumber (campaignId: string | number): Promise<string> {
    return this.webService.getOrderNumber(campaignId);
  }

  async getEstimateData (payload: EstimateDataPayload): Promise<EstimateData> {
    return this.webService.getEstimateData(payload);
  }

  async updateCampaignBidWeight (campaignId: string | number, bidWeight: number): Promise<void> {
    return this.webService.updateCampaignBidWeight(campaignId, bidWeight);
  }

  async getRetailOptions (): Promise<SelectOptions[]> {
    const retailOptions = await this.webService.getRetailOptions();
    return retailOptions.map(
      option => {
        const i18nKey = `retailers.${option.label.toLowerCase()}`;
        const result = {
          value: option.value,
          label: i18n.exists(i18nKey) ? i18n.t(i18nKey) : option.label
        };
        generateI18nOfSelectOptions(({
          label: result.label,
          value: result.value
        }), 'retailers');
        return result;
      }
    );
  }

  convertVideoAdEventToEvent (campaign: RtbCampaign) {
    const videoAdEvent = _.get(campaign, 'basic.videoAdViewObjective.videoAdEvent');
    const offset = _.get(campaign, 'basic.videoAdViewObjective.offset');

    const videoAdMetricEvent = _.get(campaign, 'basic.videoAdViewObjective.videoAdMetricEvent');
    _.unset(campaign, 'basic.videoAdViewObjective');
    switch (videoAdEvent) {
      case VideoAdViewObjective.METRIC:
        return _.set(campaign, 'basic.videoAdViewObjective.event', videoAdMetricEvent);
      case VideoAdViewObjective.PROGRESS:
        _.set(campaign, 'basic.videoAdViewObjective.offset', offset);
        return _.set(campaign, 'basic.videoAdViewObjective.event', videoAdEvent);
      default:
        return campaign;
    }
  }

  setVideoAdEvent (campaign: RtbCampaign) {
    const videoEvent = _.get(campaign, 'basic.videoAdViewObjective.event', {});
    switch (videoEvent) {
      case VideoAdViewObjective.PROGRESS:
        return _.set(
          campaign,
          'basic.videoAdViewObjective.videoAdEvent',
          VideoAdViewObjective.PROGRESS
        );
      case VideoAdMetricEvent.FIRSTQUARTILE:
      case VideoAdMetricEvent.MIDPOINT:
      case VideoAdMetricEvent.COMPLETE:
        const newCampaign = _.set(
          campaign,
          'basic.videoAdViewObjective.videoAdEvent',
          VideoAdViewObjective.METRIC
        );
        return _.set(
          newCampaign,
          'basic.videoAdViewObjective.videoAdMetricEvent',
          videoEvent
        );
      default:
        return _.set(
          campaign,
          'basic.videoAdViewObjective.videoAdEvent',
          VideoAdViewObjective.DEFAULT
        );
    }
  }

  getMinBudgetOfCampaign (campaign: RtbCampaignBasic, budgetMinimumPerDay: number) {
    const startDate = moment(campaign.startDate);
    const endDate = moment(campaign.endDate);
    const scheduleDateCount = endDate.diff(startDate, 'days') + 1;
    return budgetMinimumPerDay * scheduleDateCount;
  }

  getDefaultOptimizationGoal (campaignGroup) {
    const objective = campaignGroup.objective;
    return rtbDefaultGoals[objective];
  }

  getOptimizationGoalOptions (campaignGroup) {
    const objective = campaignGroup.objective;
    const optimizationGoals = campaignBasicSelectOptionConfig[objective];
    return optimizationGoals ? Object.keys(optimizationGoals).map(goal => ({
      label: i18n.t(`adSet.optimizationGoal.${goal.toLowerCase()}`),
      value: goal
    })) : [];
  }

  getDefaultBillingEvent () {
    return BillingEvent.IMPRESSIONS;
  }

  getBilliingEventOptions () {
    return [{
      label: i18n.t('adSet.billingEvent.impressions}'),
      value: BillingEvent.IMPRESSIONS
    }];
  }

  wrapCampaignForServer = (campaign: RtbCampaign) => {
    let videoProgressTrackingCode: any = undefined;
    if (campaign.basic.videoProgressTrackingCode &&
      campaign.basic.videoProgressTrackingCode.code &&
      campaign.basic.videoProgressTrackingCode.offset
    ) {
      videoProgressTrackingCode = [{
        ...campaign.basic.videoProgressTrackingCode,
        event: 'progress'
      }];
    }
    const ageMinLimitation = campaign.limitations.include ? campaign.limitations.include.find(limitation => limitation.type === 'age_min') : undefined;
    const ageMin = ageMinLimitation ? ageMinLimitation.value : undefined;
    const ageMaxLimitation = campaign.limitations.include ? campaign.limitations.include.find(limitation => limitation.type === 'age_max') : undefined;
    const ageMax = ageMaxLimitation ? ageMaxLimitation.value : undefined;
    let result: any = {
      campaign: {
        ..._.omit(campaign.basic, ['id', 'adLogo', 'enableMonitor', 'tags', 'orderPriceEnable']),
        tags: campaign.basic.tags.join(','),
        budget: campaign.basic.priceModel === RtbCampaignPlanType.RB ? 0 : campaign.basic.budget,
        campaignId: campaign.basic.id,
        videoProgressTrackingCode: videoProgressTrackingCode,
        dealIds: this.getDealIds(campaign.limitations),
        isMonitorEnable: campaign.basic.enableMonitor,
        ageMin: ageMin,
        ageMax: ageMax
      },
      limitations: toServerStructure(campaign.limitations)
    };
    const adLogoData = this.getAdLogoData(campaign.basic.adLogo);
    if (adLogoData) {
      _.set(result.campaign, 'adLogo', adLogoData);
    }
    return result;
  }

  prepareCreateCampaignPayload (campaign: RtbCampaign) {
    const prepareCreate = _.flow([_.cloneDeep, this.convertVideoAdEventToEvent, this.wrapCampaignForServer]);
    return prepareCreate(campaign);
  }

  getDealIds (limitations) {
    const dealIdLimitation = limitations.other ? limitations.other.find(limitation => limitation.type === 'dealId') : undefined;
    if (!dealIdLimitation || dealIdLimitation.value.length === 0) {
      return undefined;
    }

    return dealIdLimitation.value.map(selectOption => selectOption.value);
  }

  getAdLogoData (adLogoData) {
    if (adLogoData.type === AdLogoType.CUSTOM) {
      return {
        type: adLogoData.type,
        link: adLogoData.link,
        imgUrl: adLogoData.image.url,
        imgBase64: adLogoData.image.imgBase64
      };
    }

    return {
      type: adLogoData.type
    };
  }
}

import React, { Fragment } from 'react';
import _ from 'lodash';
import classnames from 'classnames/bind';
import styles from './withTip.module.scss';

const cx = classnames.bind(styles);
export function withTip (WrapperComponent) {
  const withTipComponent = props => {
    const remainingProps = _.omit(props, 'hocContent');
    const wrapperClassName = cx({
      [props.className]: !!props.className,
      tips: true
    });
    return (
      <Fragment>
        <WrapperComponent {...remainingProps} className={wrapperClassName} />
        <span className={styles.wording}>
          {props.hocContent}
        </span>
      </Fragment>
    );
  };

  withTipComponent.displayName = `WithTip(${getDisplayName(WrapperComponent)})`;
  return withTipComponent;
}

function getDisplayName (WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

import { UpdateEventListener, FireableUpdateEventListener } from 'utils/UpdateEventListener';

export interface SliderModel {
  readonly state: SliderState;
  readonly event: UpdateEventListener<SliderModel>;
  readonly itemWidth: number;
  readonly itemHeight?: number;
  readonly indicator: boolean;
  readonly contain: boolean;
  readonly defaultIndex: number;
  readonly putControlBtnToBottom: boolean;
  handleSelect: (selectedIndex: number) => void;
  handleRest: () => void;
}

export type SliderState = {
  readonly index: number;
};

export type SliderProps = {
  model: SliderModel;
  nextIcon?: any;
  prevIcon?: any;
  renderIndicator?: (props: any) => any;
};

export class DefaultSliderModel implements SliderModel {

  index: number;
  itemWidth: number;
  itemHeight?: number;
  indicator: boolean;
  contain: boolean;
  defaultIndex: number;
  event: FireableUpdateEventListener<SliderModel>;
  selectCallback?: (index) => void;
  putControlBtnToBottom: boolean;

  constructor (itemWidth: number, itemHeight: number | undefined, indicator: boolean = true, contain: boolean = true, defaultIndex: number = 0, putControlBtnToBottom: boolean = false, selectCallback?: (index) => void) {
    this.index = 0;
    this.itemWidth = itemWidth;
    this.itemHeight = itemHeight;
    this.indicator = indicator;
    this.contain = contain;
    this.selectCallback = selectCallback;
    this.event = new FireableUpdateEventListener<SliderModel>();
    this.index = defaultIndex;
    this.defaultIndex = defaultIndex;
    this.putControlBtnToBottom = putControlBtnToBottom;
  }

  get state () {
    return {
      index: this.index
    };
  }

  handleSelect = (selectedIndex: number) => {
    this.index = selectedIndex;
    this.updateState();
  }

  handleRest = () => {
    if (this.selectCallback) {
      this.selectCallback(this.index);
    }
  }

  updateState () {
    this.event.fireEvent(this);
  }
}

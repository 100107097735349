import {
  ReportWebService,
  RestfulReportWebService
} from 'ws/ReportWebService';

import { Order } from 'core/order/Order';
import { ReportType, ReportDimension, ReportGran, ReportData, ReportCompareDimension, ReportCompareData } from './ReportData';
import { FinalReportResponseStatus } from 'core/finalReport/FinalReportStatus';

export interface ReportManager {
  downloadReportV2 (order: {
    name: string,
    orderNumber: string
  }, startDate: string, endDate: string, groupBy: string, selectFields: string[], isCustom: boolean): Promise<void>;
  downloadReport (order: Order, startDate: string, endDate: string, reportType?: string): Promise<void>;
  downloadVideoReport (order: Order, startDate: string, endDate: string): Promise<void>;
  downloadOutdoorReport (order: Order, startDate: string, endDate: string): Promise<void>;
  downloadClosingReport (order: Order, reportType?: string): Promise<void>;
  downloadRebateReport (date: string, agencyId: string | number): Promise<void>;
  getReportData (type: ReportType, dimension: ReportDimension, gran: ReportGran, filter: any, from: string, to: string): Promise<ReportData>;
  getReportCompareData (compareDimension: ReportCompareDimension, filter: any, from: string, to: string): Promise<ReportCompareData>;
  uploadFinalReportData (orderId: string | number): Promise<FinalReportResponseStatus>;
  getFinalReportData (reportId: string | number): Promise<FinalReportResponseStatus>;
}

export class DefaultReportManager implements ReportManager {
  webService: ReportWebService;

  constructor (
    webService: ReportWebService = new RestfulReportWebService()
  ) {
    this.webService = webService;
  }
  async downloadReportV2 (order: {
    name: string,
    orderNumber: string
  }, startDate: string, endDate: string, groupBy: string, selectFields: string[], isCustom: boolean): Promise<void> {
    await this.webService.downloadReportV2(order, startDate, endDate, groupBy, selectFields, isCustom);
  }
  async downloadReport (order: Order, startDate: string, endDate: string, reportType?: string): Promise<void> {
    await this.webService.downloadReport(order, startDate, endDate, reportType);
  }
  async downloadVideoReport (order: Order, startDate: string, endDate: string): Promise<void> {
    await this.webService.downloadVideoReport(order, startDate, endDate);
  }
  async downloadOutdoorReport (order: Order, startDate: string, endDate: string): Promise<void> {
    await this.webService.downloadOutdoorReport(order, startDate, endDate);
  }
  async downloadClosingReport (order: Order, reportType?: string): Promise<void> {
    await this.webService.downloadClosingReport(order, reportType);
  }
  async downloadRebateReport (date: string, agencyId?: string | number): Promise<void> {
    await this.webService.downloadRebateReport(date, agencyId);
  }
  async getReportData (type: ReportType, dimension: ReportDimension, gran: ReportGran, filter: any, from: string, to: string) {
    return this.webService.getReportData(type, dimension, gran, filter, from, to);
  }
  async getReportCompareData (compareDimension: ReportCompareDimension, filter: any, from: string, to: string): Promise<ReportCompareData> {
    return this.webService.getReportCompareData(compareDimension, filter, from, to);
  }
  async uploadFinalReportData (orderId: string | number): Promise<FinalReportResponseStatus> {
    return this.webService.uploadFinalReportData(orderId);
  }
  async getFinalReportData (reportId: string | number): Promise<FinalReportResponseStatus> {
    return this.webService.getFinalReportData(reportId);
  }
}

import React from 'react';
import ReactDOM from 'react-dom';
import { Tabs, Tab, Button, Form, Row } from 'react-bootstrap';
import { RtbCampaignSetupStepProps, CampaignSetupTab } from './RtbCampaignSetupStepModel';
import styles from './rtbCampaignSetupStep.module.scss';
import { EditLimitation } from 'containers/Limitations/EditLimitation';
import CampaignBasicForm from 'containers/RtbCampaigns/RtbCampaignSetupFlow/RtbCampaignForm/RtbCampaignBasicForm';
import CampaignTrackForm from 'containers/RtbCampaigns/RtbCampaignSetupFlow/RtbCampaignForm/RtbCampaignTrackForm';
import { RtbCampaignPlanType, RTBCAMPAIGN_DEFAULT_AGE_MAX, RTBCAMPAIGN_DEFAULT_AGE_MIN } from 'core/rtbCampaign/RtbCampaign';
import { Formik } from 'formik';
import i18n from 'i18next';
import AdLogoForm from 'components/AdLogoForm/AdLogoForm';
import Select from 'components/common/Select/Select';
import { ageMaxOptions, ageMinOptions, genderOptions, getRtbAgeGroupsByAgeRange } from 'core/limitation/goCampaignTAOptions';
import { partial } from 'lodash';

export class RtbCampaignSetupStep extends React.Component<RtbCampaignSetupStepProps> {

  componentDidUpdate () {
    this.scrollToErrorField();
  }

  componentWillUnmount () {
    this.props.model.onUnmount();
  }

  handleSubmit = async (values) => {};

  validate = (campaign) => {
    return this.props.model.validateCampaignBasic(campaign);
  }

  scrollToErrorField () {
    const node = ReactDOM.findDOMNode(this);
    if (node instanceof HTMLElement) {
      const child = node.querySelector('.errorTip');
      child && child.scrollIntoView({
        block: 'center'
      });
    }
  }

  renderAgeAndGender (limitationValue, onChange: (operate: string, type: string, value: any) => void) {
    const generalTA = limitationValue ? limitationValue : [];
    const ageMinLimitation = generalTA.find(value => value.type === 'age_min');
    const ageMin = ageMinLimitation ? ageMinLimitation.value : RTBCAMPAIGN_DEFAULT_AGE_MIN;
    const ageMaxLimitation = generalTA.find(value => value.type === 'age_max');
    const ageMax = ageMaxLimitation ? ageMaxLimitation.value : RTBCAMPAIGN_DEFAULT_AGE_MAX;
    const gendersLimitation = generalTA.find(value => value.type === 'gender');
    const gender = gendersLimitation ? gendersLimitation.value[0] : genderOptions.find(option => option.value === -1);
    const onAgeMinChange = newAgeMin => {
      if (newAgeMin > ageMax) {
        onChange('include', 'age_max', newAgeMin);
      }
      onChange('include', 'age_min', newAgeMin);
    };
    const onAgeMaxChange = newAgeMax => {
      if (newAgeMax < ageMin) {
        onChange('include', 'age_min', newAgeMax);
      }
      onChange('include', 'age_max', newAgeMax);
    };
    const onGendersChange = gender => {
      onChange('include', 'gender', gender.value === -1 ? undefined : [gender]);
    };
    const ageGroupsHint = getRtbAgeGroupsByAgeRange(ageMin, ageMax).map(group => group.label).join(',');
    return (
      <div>
        <Form.Group as={Row} controlId={'age'}>
          <Form.Label>{i18n.t('limitation.labels.age')}</Form.Label>
          <div>
            <div className={styles.ageSelector}>
              <Select
                className={styles.ageMin}
                options={ageMinOptions}
                name='ageMin'
                simpleValue
                value={ageMin}
                onChange={onAgeMinChange}
              />
              ~
              <Select
                className={styles.ageMax}
                options={ageMaxOptions}
                name='ageMax'
                simpleValue
                value={ageMax}
                onChange={onAgeMaxChange}
              />
            </div>
            <span className={styles.ageGroupsHint}>{i18n.t('limitation.hints.ageGroups', { groups: ageGroupsHint })}</span>
          </div>
        </Form.Group>
        <Form.Group as={Row} controlId={'gender'}>
          <Form.Label>{i18n.t('limitation.labels.gender')}</Form.Label>
          <Select
            options={genderOptions}
            value={gender}
            onChange={onGendersChange}
          />
        </Form.Group>
      </div>
    );
  }

  renderFormContent = (formikProps) => {
    const model = this.props.model;
    const campaignData = model.flowModel.state.campaign;
    const basicFormModel = model.getRtbCampaignBasicFormModel(campaignData.basic.adType);
    const priceModel = basicFormModel ? basicFormModel.getCurrentPriceModel() : RtbCampaignPlanType.RS;
    const limitationModel = model.getLimitationModel(priceModel);
    const adLogoModel = model.getAdLogoFormModel();
    model.setFormikProps(formikProps);
    const onTabSelect = (tab) => {
      model.goSubStep(+tab);
    };
    const onSaveDraft = model.flowModel.onSaveDraft;
    return (
      <>
        <Tabs
          id='campaignFromTab'
          activeKey={model.activeTab}
          onSelect={onTabSelect}
        >
          <Tab eventKey={CampaignSetupTab.BASIC} title={i18n.t('stepSideBar.labels.basic')}>
            <CampaignBasicForm model={basicFormModel} />
          </Tab>
          <Tab eventKey={CampaignSetupTab.LIMITATION} title={i18n.t('stepSideBar.labels.targeting')}>
            <EditLimitation model={limitationModel} renderGeneralFields={this.renderAgeAndGender}/>
          </Tab>
          {
            model.flowModel.needSetupTracking &&
            <Tab eventKey={CampaignSetupTab.TRACKING} title={i18n.t('stepSideBar.labels.strategyAndTracking')}>
              <CampaignTrackForm conversionOptions={model.flowModel.conversionOptions} adType={campaignData.basic.adType} />
            </Tab>
          }
          {
            model.flowModel.needSetupAdlogo && adLogoModel &&
            <Tab eventKey={CampaignSetupTab.ADLOGO} title={i18n.t('stepSideBar.labels.adLogo')}>
              <AdLogoForm model={adLogoModel} />
            </Tab>
          }
        </Tabs>
        <div className={styles.buttonArea}>
          <Button variant='primary' size='sm' onClick={model.goNext}>
            {i18n.t('campaign.buttons.completeAndCheck')}
          </Button>
          {onSaveDraft && (
            <Button
              variant='secondary'
              size='sm'
              onClick={partial(onSaveDraft, {
                basic: formikProps.values,
                limitations: limitationModel ? limitationModel.limitationValue : {}
              })}
            >
              {i18n.t('campaign.buttons.saveDraft')}
            </Button>
          )}
          {model.flowModel.type === 'create' && <Button variant='secondary' size='sm' onClick={model.goLast}>
            {i18n.t('campaign.buttons.back')}
          </Button>}
        </div>
      </>
    );
  }

  render () {
    const model = this.props.model;
    const campaignData = model.flowModel.state.campaign;
    return (
      <div className={styles.setupStep}>
        <Formik
          initialValues={campaignData.basic}
          enableReinitialize
          onSubmit={this.handleSubmit}
          validate={this.validate}
          render={this.renderFormContent}
          validateOnBlur={false}
        />
      </div>
    );
  }
}

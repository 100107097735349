import React from 'react';
import { StickableBootstrapTable } from 'components/common/Table/StickableBootstrapTable';
import { CreditListColumns, useCreditListModel } from './CreditListModel';
import i18n from 'i18n';
import styles from './creditList.module.scss';
import { SearchBar } from 'components/common/SearchBar';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { CreditPayModal } from '../CreditPayModal/CreditPayModal';

export type CreditListProps = {};

export const CreditList: React.FC<CreditListProps> = () => {

  const {
    state,
    columns,
    loading,
    onHandleSearch,
    onPayModalClose
  } = useCreditListModel();

  return (
    <div className={styles.creditList}>
      {loading && <LoadingIndicator />}
      {state.agencyToPay && <CreditPayModal agencyId={state.agencyToPay} onClose={onPayModalClose} />}
      <div className={styles.titleArea}>
        <div className={styles.title}>
          {i18n.t('creditList.labels.title')}
        </div>
      </div>
      <div className={styles.filterArea}>
        <SearchBar
          model={{
            placeholder: i18n.t('creditList.placeholders.searchbar'),
            search: onHandleSearch,
            defaultValue: state.searchString
          }}
        />
      </div>
      <div className={styles.contentArea}>
        <StickableBootstrapTable
          stickFirstColumn
          stickLastColumn
          keyField={CreditListColumns.ID}
          data={state.filteredList}
          columns={columns}
          noDataIndication={i18n.t('creditList.labels.noData')}
          defaultSorted={[{
            dataField: CreditListColumns.ID,
            order: 'desc'
          }]}
          pagination={paginationFactory({
            hideSizePerPage: true,
            sizePerPage: 10
          })}
        />
      </div>
    </div>
  );
};

import React from 'react';
import _ from 'lodash';
import is from 'is_js';
import classNames from 'classnames/bind';
import { InventoryProps, InventoryState } from './InventoryModel';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { DefaultSelectComponentModel } from 'components/CampaignLimitation/SelectComponent';

import styles from './inventory.module.scss';
import i18n from 'i18n';

const cx = classNames.bind(styles);

export class Inventory extends React.PureComponent<
  InventoryProps,
  InventoryState
> {
  handler: number;
  model: any;
  constructor (props) {
    super(props);
    this.state = this.props.model.state;
    this.model = this.props.model;
    this.handler = this.props.model.event.add(model => {
      this.setState(model.state);
    });
  }

  componentDidMount () {
    const { defaultSelected } = this.model;
    this.model.changeInventory(defaultSelected);
  }

  componentDidUpdate (prevProps) {
    const sameModel = prevProps.model === this.props.model;
    const sameSelected =
      this.props.model.defaultSelected === prevProps.model.defaultSelected;
    if (!sameModel) {
      this.model.changeInventorySetting(this.props.model.inventorySetting);
    }
    if (!sameModel && !sameSelected) {
      this.model.changeInventory(this.props.model.defaultSelected);
    }
  }

  componentWillUnmount () {
    this.model.event.remove(this.handler);
  }

  getLimitationTitleList () {
    const { changeInventory, inventorySetting } = this.model;
    const { selected } = this.model.state;

    return inventorySetting
      .filter(options => is.existy(options.title))
      .map(options => {
        const name = _.get(options, 'name', '');
        const isActive = name === selected;
        const handleClickSidebarItem = isActive
          ? _.noop
          : changeInventory.bind(this.model, name);

        const className = cx({
          active: isActive,
          itemContainer: true
        });
        const title = _.get(options, 'title', name);
        return (
          <div
            key={name}
            className={className}
            onClick={handleClickSidebarItem}
          >
            <span className={styles.item}>{i18n.t(title)}</span>
          </div>
        );
      });
  }

  getSelectedChildren () {
    const { selected, loading, inventoryData } = this.model.state;
    if (loading) {
      return <LoadingIndicator />;
    }
    return React.Children.toArray(this.props.children)
      .filter(children => {
        return _.get(children, 'props.model.name') === selected;
      })
      .map(children => {
        const childrenModel = _.get(children, 'props.model', {});
        if (childrenModel instanceof DefaultSelectComponentModel) {
          childrenModel.setData(inventoryData);
        }
        return React.cloneElement(children as React.ReactElement<any>, {
          model: childrenModel
        });
      });
  }

  render () {
    const limitationTitleList = this.getLimitationTitleList();
    const limitationLength = limitationTitleList.length;
    const children = this.getSelectedChildren();
    const customStyles = this.props.width ? { width: this.props.width } : undefined;

    return (
      <div className={styles.inventoryContainer} style={customStyles}>
        <div className={styles.sidebar}>
          <div className={styles.title}>
            <span>{i18n.t(this.props.model.sidebarTitleKey)}</span>
          </div>
          <div className={styles.content}>{limitationTitleList}</div>
          <div className={styles.total}>
            <span>
              {i18n.t('limitation.labels.total', { total: limitationLength })}
            </span>
          </div>
        </div>
        <div className={styles.content}>{children}</div>
      </div>
    );
  }
}

import { useCallback, useEffect, useMemo, useState } from 'react';
import { SelectOptions } from 'components/common/commonType';
import { DefaultAdvertiserManager } from 'core/advertiser/AdvertiserManager';
import { RdpManager, DefaultRdpManager } from 'core/rdp/RdpManager';
import { ProductSet } from 'core/product/Product';
import { NewProductSetFormModel, AddToExistProductSetFormModel } from './ProductSetFormModel';
import { useCallAPI } from 'hooks/useCallAPI';

export enum PRODUCT_SET_CREATE_TYPE {
  NEW = 'new',
  ADD_TO_EXIST = 'addToExist'
}

const advertiserManager = new DefaultAdvertiserManager();
const defaultRdpManager: RdpManager = new DefaultRdpManager();
export const useProductSetCreateModalModel = (
  retailId: string,
  selectedProducts: any[],
  closeModal: () => void
) => {

  const {
    loading,
    callAPIs
  } = useCallAPI();
  const [createType, setCreateType] = useState(PRODUCT_SET_CREATE_TYPE.NEW);
  const [advertiserOptions, setAdvertiserOptions] = useState<SelectOptions[]>([]);
  const [productSetOptions, setProductSetOptions] = useState<SelectOptions[]>([]);

  const fetchAdvertisers = useCallback(async () => {
    callAPIs(
      [advertiserManager.getAdvertiserOptions.bind(advertiserManager)],
      (advertisers: SelectOptions[]) => {
        setAdvertiserOptions(advertisers);
      }
    );
  }, [callAPIs]);

  const fetchProductSetsOfAdvertiser = useCallback(async (advertiser) => {
    if (!advertiser) {
      setProductSetOptions([]);
    } else {
      if (createType === PRODUCT_SET_CREATE_TYPE.ADD_TO_EXIST) {
        callAPIs(
          [defaultRdpManager.getRetailProductSets.bind(defaultRdpManager, retailId, advertiser)],
          (productSets: ProductSet[]) => {
            setProductSetOptions(productSets.map(productSet => ({
              label: productSet.name,
              value: productSet.id
            })));
          }
        );
      }
    }
  }, [retailId, createType, callAPIs]);

  const submitCreateProductSet = useCallback(async (productSetFormData, cb) => {
    callAPIs(
      [defaultRdpManager.createProductSet.bind(defaultRdpManager, retailId, productSetFormData)],
      (productSet: ProductSet) => {
        console.log('new product set', productSet);
        cb();
      }
    );
  }, [retailId, callAPIs]);

  const submitUpdateProductSet = useCallback(async (productSetFormData, cb) => {
    callAPIs(
      [defaultRdpManager.updateProductSetAdd.bind(defaultRdpManager, retailId, productSetFormData)],
      (productSet: ProductSet) => {
        console.log('updated product set', productSet);
        cb();
      }
    );
  }, [retailId, callAPIs]);

  const formModel = useMemo(() => {
    if (createType === PRODUCT_SET_CREATE_TYPE.NEW) {
      return new NewProductSetFormModel(
        selectedProducts,
        advertiserOptions,
        fetchProductSetsOfAdvertiser,
        closeModal,
        submitCreateProductSet
      );
    } else {
      return new AddToExistProductSetFormModel(
        selectedProducts,
        advertiserOptions,
        productSetOptions,
        fetchProductSetsOfAdvertiser,
        closeModal,
        submitUpdateProductSet
      );
    }
  }, [
    createType,
    advertiserOptions,
    productSetOptions,
    selectedProducts,
    submitCreateProductSet,
    submitUpdateProductSet,
    fetchProductSetsOfAdvertiser,
    closeModal
  ]);

  useEffect(() => {
    fetchAdvertisers();
  }, [fetchAdvertisers]);

  return {
    loading: loading,
    formModel,
    createType,
    setCreateType: (createType) => {
      setCreateType(createType);
      setProductSetOptions([]);
    }
  };
};

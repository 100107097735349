import React, { useEffect, useMemo, useState } from 'react';
import i18n from 'i18next';
import { connect, FormikContext } from 'formik';
import { AdNeonFormProps } from './AdNeonFormModel';
import styles from './adNeonForm.module.scss';
import _ from 'lodash';
import { FormConfig } from 'components/common/formik/FormikField';
import { RETAILMAX_LAYOUT_TYPE } from 'core/creative/Creative';
import { FormikFormContent } from 'components/common/formik/FormikFormContent';

export const AdNeonForm: React.FC<AdNeonFormProps & { formik: FormikContext<any> }> = (props) => {

  const layoutOptions = useMemo(() => {
    return [{
      value: RETAILMAX_LAYOUT_TYPE.IMAGE,
      label: i18n.t('adNeonForm.options.imageLayout')
    }, {
      value: RETAILMAX_LAYOUT_TYPE.VIDEO,
      label: i18n.t('adNeonForm.options.videoLayout')
    }];
  }, []);

  const { values, setFieldValue } = props.formik;
  const layout = _.get(values, 'typeProperties.layout');

  const [formConfig, setFormConfig] = useState<FormConfig | undefined>();

  useEffect(() => {
    setFormConfig(new FormConfig.Builder()
      .addSection(
        new FormConfig.SectionBuilder(
          i18n.t('creativeSetupFlow.labels.creativeBasicInfo'),
          new FormConfig.FieldsBuilder([...props.basicFields])
            .addLabel({
              label: i18n.t('creativeSetupFlow.labels.layout'),
              name: 'typeProperties.layout',
              formatter: value => {
                const option = layoutOptions.find(option => option.value === value);
                return option ? option.label : '';
              }
            }, props.model.type === 'create')
            .addSelect({
              label: i18n.t('creativeSetupFlow.labels.layout'),
              name: 'typeProperties.layout',
              simpleValue: true,
              options: layoutOptions,
              onChange: () => setFieldValue('medias', {})
            }, props.model.type === 'edit')
            .addFileInput({
              type: 'video',
              label: i18n.t('videoForm.labels.videoSource'),
              name: 'medias.video',
              maxWidth: 270,
              maxHeight: 500,
              hints: [
                i18n.t('creativeSetupFlow.labels.videoTypeHint'),
                i18n.t('outdoorForm.labels.videoSizeHint', { proportion: '16:9' })
              ],
              validate: props.model.validateVideo
            }, layout === RETAILMAX_LAYOUT_TYPE.IMAGE)
            .addFileInput({
              type: 'image',
              label: i18n.t('creativeSetupFlow.labels.image'),
              name: 'medias.image',
              className: styles.imageInput,
              maxWidth: 150,
              maxHeight: 125,
              validTypes: ['image/jpeg', 'image/jpg', 'image/png'],
              hints: [
                i18n.t('creativeSetupFlow.labels.typeHint'),
                i18n.t('creativeSetupFlow.labels.imageSizeHint', { size: '300 x 250' }),
                i18n.t('creativeSetupFlow.labels.storageHint', { storage: '2MB' })
              ],
              validate: props.model.validateImage
            }, layout === RETAILMAX_LAYOUT_TYPE.VIDEO)
            .build()
        ).build()
      ).build()
    );
  }, [
    props.basicFields,
    layout,
    layoutOptions,
    setFieldValue,
    props.model.type,
    props.model.validateImage,
    props.model.validateVideo
  ]);

  return (
    <FormikFormContent
      formConfig={formConfig}
    />
  );
};

export default connect(AdNeonForm);

import React, { Fragment } from 'react';
import SummaryDetail from 'components/SummaryDetail/SummaryDetail';
import styles from './rtbCampaignSummaryStep.module.scss';
import { RtbCampaignSummaryStepProps, RtbCampaignSummaryStepState } from './RtbCampaignSummaryStepModel';
import { Button } from 'react-bootstrap';
import i18n from 'i18n';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { ImagePreview } from 'components/common/Image/ImagePreview';
import { TAInfoCard } from 'components/TAInfoCard/TAInfoCard';
import _ from 'lodash';
import { CampaignGroupChannel } from 'core/campaignGroup/CampaignGroup';
import { alertMessage } from 'components/AlertDialog';

export class RtbCampaignSummaryStep extends React.Component<RtbCampaignSummaryStepProps, RtbCampaignSummaryStepState> {

  handler?: number;

  componentDidMount () {
    this.handler = this.props.model.event.add(model => {
      this.setState(model.state);
    });
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add(model => {
        this.setState(model.state);
      });
    }
  }

  componentWillUnmount () {
    this.handler && this.props.model.event.remove(this.handler);
  }

  renderSummaryDetails = (data) => {
    return Object.keys(data).map((key) =>
      data[key] ?
      <SummaryDetail
        key={key}
        title={data[key].title}
        data={data[key].content}
        prefixColor={data[key].titlePrefixColor}
      /> : <div key={key}/>
    );
  }

  renderSummaryTitle = (summaryData) => {
    return (
      <div className={styles.categoryTitle}>
        {summaryData.title}
        { summaryData.backStep !== undefined &&
          <div className={styles.backBtn} onClick={this.props.model.goStep.bind(undefined, summaryData.backStep, summaryData.backSubStep)}>
            {i18n.t('campaignSummary.labels.backButtonTitle')}
          </div>
        }
      </div>
    );
  }

  renderSummary = (summaryData, renderOtherInfo?: any) => {
    if (!summaryData) {
      return <div/>;
    }
    return (
      <section>
        {this.renderSummaryTitle(summaryData)}
        <div>
          {this.renderSummaryDetails(summaryData.data)}
        </div>
        {renderOtherInfo && renderOtherInfo()}
      </section>
    );
  }

  renderImageSummary = (summaryData) => {
    const imageData = summaryData.data.imageData;
    return (
      <div>
        {this.renderSummaryTitle(summaryData)}
        <ImagePreview
          size={{ width: 150, height: 150 }}
          url={imageData.url}
          file={imageData.file}
          config={summaryData.data.config}
        />
      </div>
    );
  }

  render () {
    const model = this.props.model;
    const adLogoFileData = model.getAdLogoPreviewData();
    const estimatedAudience = model.estimatedAudience;
    const objectType = model.flowModel.objectType;
    const onSaveDraft = model.flowModel.onSaveDraft;
    const draftBindingSummary = model.getCreativeBindingSummaryData();
    return (
      <div className={styles.container}>
        {model.state.loading && <LoadingIndicator />}
        {model.state.showPublishBindingFailed && alertMessage(
          i18n.t('common.warning'), i18n.t('campaignSetupFlow.messages.publishBindingFailed'), model.redirectToLastPage
        )}
        <div className={styles.title}>
          {i18n.t('campaignSummary.titles.checkYourSettings')}
        </div>
        <div className={styles.content}>
          {this.renderSummary(model.getAdTypeSummaryData())}
          <div className={styles.sepLine} />
          <div className={styles.basicSummary}>
            {this.renderSummary(model.getSetUpSummaryData())}
          </div>
          <div className={styles.sepLine} />
          {this.renderSummary(
            model.getLimitationsSummaryData(),
            () => (
              <TAInfoCard
                channel={_.get(model.campaignGroup, 'channel', CampaignGroupChannel.RTB)}
                advertiserId={model.order.advertiserId}
                appliedSavedTAInfo={model.appliedSavedTAInfo}
                targetingValue={model.targetingValue}
                estimatedAudience={estimatedAudience}
                audienceLowestThreshold={model.audienceLowestThreshold}
                savedTargetingList={model.savedTargetingList}
              />
            )
          )}
          <div className={styles.sepLine} />
          {this.renderSummary(model.getTrackingSummaryData())}
          { model.canShowAdLogoSummary &&
            <>
              <div className={styles.sepLine} />
              {this.renderSummary(model.getAdLogoSummaryData())}
              {
                adLogoFileData &&
                <Fragment>
                  <div className={styles.sepLine} />
                  {this.renderImageSummary(adLogoFileData)}
                </Fragment>
              }
            </>
          }
          { draftBindingSummary &&
            <>
              <div className={styles.sepLine} />
              {this.renderSummary(draftBindingSummary)}
            </>
          }
        </div>
        <div className={styles.buttonArea}>
          <Button variant='primary' size='sm' onClick={model.submit}>
            {objectType === 'campaign' ? i18n.t('common.buttons.submit') : i18n.t('common.buttons.publish')}
          </Button>
          {onSaveDraft && (
            <Button
              variant='secondary'
              size='sm'
              onClick={_.partial(onSaveDraft, model.flowModel.state.campaign)}
            >
              {i18n.t('campaign.buttons.saveDraft')}
            </Button>
          )}
          <Button variant='secondary' size='sm' onClick={model.goLast}>
            {i18n.t('campaign.buttons.back')}
          </Button>
        </div>
      </div>
    );
  }
}

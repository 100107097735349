export const MIN_CAMPAIGN_BUDGET_PER_DAY = 35;

export type CampaignGroup = {
  groupId: number;
  name: string;
  adsOrderID: number;
  state: CampaginGroupState;
  budget?: number | string | null;
  channel: CampaignGroupChannel;
  autoOptimise: boolean;
  budgetBalance: number;
  currencyRate: number;
  objective?: AwarenessObjective | ConsiderationObjective | string | FbObjective;
  fb?: FBCampaign;
  tiktok?: TiktokCampaign;
};

export enum CampaignGroupChannel {
  RTB = 'RTB',
  FB = 'FB',
  TIKTOK = 'TIKTOK',
  RETAIL_MEDIA = 'RETAIL_MEDIA'
}

export enum CampaginGroupState {
  ACTIVE = 1,
  DEACTIVATE = 2,
  DELETE = 99
}

export type FBCampaign = {
  campaign_id: string | number | null,
  account_id: string | number;
  bid_strategy: string | number;
  objective: FbObjective;
  effective_status: FbEffectiveStatus
};

export type TiktokCampaign = {
  campaign_id: string | number | null;
  campaign_name: string;
  advertiser_id: string | number;
  objective_type: string;
  opt_status: TiktokOptStatus;
  status: TiktokStatus;
};

enum AwarenessObjective {
  REACH = 'REACH'
  // BRAND_AWARENESS = 'BRAND_AWARENESS'
}

enum ConsiderationObjective {
  LINK_CLICKS = 'LINK_CLICKS'
  // Engagement
  // POST_ENGAGEMENT = 'POST_ENGAGEMENT', // Post engagement
  // PAGE_LIKES = 'PAGE_LIKES', // Page likes
  // EVENT_RESPONSES = 'EVENT_RESPONSES', // Event responses
  // //
  // APP_INSTALLS = 'APP_INSTALLS', // App installs
  // VIDEO_VIEWS = 'VIDEO_VIEWS', // Video views
  // LEAD_GENERATION = 'LEAD_GENERATION', // Lead generation
  // MESSAGES = 'MESSAGES' // Messages
}

enum ConversionObjective {
  CONVERSIONS = 'CONVERSIONS'
  // PRODUCT_CATALOG_SALES = 'PRODUCT_CATALOG_SALES', // Catalog sales
  // STORE_VISITS = 'STORE_VISITS' // Store traffic
}

export const GoCampaignGroupObjective = {
  AwarenessObjective,
  ConsiderationObjective,
  ConversionObjective
  // LOCAL_AWARENESS = 'LOCAL_AWARENESS',
  // OFFER_CLAIMS = 'OFFER_CLAIMS',
};

export enum FbObjective {
  OUTCOME_AWARENESS = 'OUTCOME_AWARENESS',
  OUTCOME_TRAFFIC = 'OUTCOME_TRAFFIC',
  OUTCOME_SALES = 'OUTCOME_SALES',
  OUTCOME_ENGAGEMENT = 'OUTCOME_ENGAGEMENT',
  OUTCOME_LEADS = 'OUTCOME_LEADS'
  // OUTCOME_APP_PROMOTION = 'OUTCOME_APP_PROMOTION'
}

export const TiktokObjective = {
  REACH: 'REACH',
  TRAFFIC: 'TRAFFIC'
};

export enum FBBidStrategy {
  LOWEST_COST_WITHOUT_CAP = 'LOWEST_COST_WITHOUT_CAP',
  LOWEST_COST_WITH_BID_CAP = 'LOWEST_COST_WITH_BID_CAP'
  // COST_CAP = 'COST_CAP'
}

export enum FbEffectiveStatus {
  ACTIVE = 'ACTIVE',
  PAUSED = 'PAUSED',
  DELETED = 'DELETED',
  ARCHIVED = 'ARCHIVED',
  IN_PROCESS = 'IN_PROCESS',
  WITH_ISSUES = 'WITH_ISSUES'
}

export enum TiktokOptStatus {
  ENABLE = 'ENABLE',
  DISABLE = 'DISABLE',
  DELETED = 'DELETED'
}

export enum TiktokStatus {
  CAMPAIGN_STATUS_DELETE = 'CAMPAIGN_STATUS_DELETE',
  CAMPAIGN_STATUS_ADVERTISER_AUDIT_DENY = 'CAMPAIGN_STATUS_ADVERTISER_AUDIT_DENY',
  CAMPAIGN_STATUS_ADVERTISER_AUDIT = 'CAMPAIGN_STATUS_ADVERTISER_AUDIT',
  ADVERTISER_CONTRACT_PENDING = 'ADVERTISER_CONTRACT_PENDING',
  CAMPAIGN_STATUS_BUDGET_EXCEED = 'CAMPAIGN_STATUS_BUDGET_EXCEED',
  CAMPAIGN_STATUS_DISABLE = 'CAMPAIGN_STATUS_DISABLE',
  CAMPAIGN_STATUS_ENABLE = 'CAMPAIGN_STATUS_ENABLE'
}

export enum TiktokStatusDetail {
  CAMPAIGN_STATUS_DELETE = 'Deleted',
  CAMPAIGN_STATUS_ADVERTISER_AUDIT_DENY = 'Advertiser review failed',
  CAMPAIGN_STATUS_ADVERTISER_AUDIT = 'Advertiser review in progress',
  ADVERTISER_CONTRACT_PENDING = 'Advertiser contract has not taken effect',
  CAMPAIGN_STATUS_BUDGET_EXCEED = 'Over budget',
  CAMPAIGN_STATUS_DISABLE = 'Suspended',
  CAMPAIGN_STATUS_ENABLE = 'Advertising in delivery'
}

import React from 'react';
import styles from './doctors.module.scss';
import { DoctorChart } from 'components/DoctorChart/DoctorChart';
import FormGroup from 'components/common/FormGroup/FormGroup';
import { Button, FormControl } from 'react-bootstrap';
import i18n from 'i18n';
import { DoctorsProps, DoctorsState } from './DoctorsModel';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { DoctorTable } from 'containers/DoctorTable/DoctorTable';
import Select from 'components/common/Select/Select';
import { ErrorBoundary } from 'components/common/ErrorBoundary/ErrorBoundary';
import { ERROR_CODE } from 'core/ErrorCode';

export class Doctors extends React.Component<DoctorsProps, DoctorsState> {

  handler?: number;

  constructor (props: any) {
    super(props);
    this.state = this.props.model.state;
  }

  componentDidMount () {
    this.handler = this.props.model.event.add((model) => {
      this.setState(model.state);
    });
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add(model => {
        this.setState(model.state);
      });
    }
  }

  componentWillUnmount () {
    this.handler && this.props.model.event.remove(this.handler);
  }

  render () {
    const tableModel = this.props.model.getTableModel();
    return (
      <ErrorBoundary errorCode={ERROR_CODE.DOCTOR}>
        <div className={styles.doctors}>
          {this.state.loading && <LoadingIndicator/>}
          <div className={styles.container}>
            <div className={styles.titleArea}>
              <div className={styles.title}>
                {i18n.t('doctors.labels.title')}
              </div>
            </div>
            <div className={styles.contentArea}>
              <div className={styles.inputArea}>
                <FormGroup
                  label={i18n.t('doctor.labels.campaignId')}
                  controlId='doctorCampaignId'
                >
                  <FormControl
                    className={styles.campaignId}
                    name='doctorCampaignId'
                    onBlur={this.props.model.setCampaign}
                    placeholder={i18n.t('doctors.labels.campaignIdPlaceholder')}
                  />
                </FormGroup>
                {
                  this.state.creativeId &&
                  <FormGroup
                    label={i18n.t('doctorTable.labels.creativeTitle')}
                    controlId='doctorCreativeId'
                  >
                    <Select
                      options={this.state.creativeOptions}
                      name='doctorCreativeId'
                      simpleValue
                      value={this.state.creativeId}
                      onChange={this.props.model.setCreative}
                    />
                  </FormGroup>
                }
                <FormGroup className={styles.checkBtnContainer} label={''} controlId='checkButton'>
                  <Button
                    variant='primary'
                    size='sm'
                    disabled={!this.state.campaignId || !this.state.creativeId}
                    onClick={this.props.model.check}
                  >
                    {i18n.t('report.buttons.search')}
                  </Button>
                </FormGroup>
              </div>
              {
                this.state.doctorTableData.length > 0 &&
                <>
                  <div className={styles.chartArea}>
                    <DoctorChart chartData={this.state.doctorTableData}/>
                  </div>
                  { tableModel &&
                    <DoctorTable model={tableModel}/>
                  }
                </>
              }
            </div>
          </div>
        </div>
      </ErrorBoundary>
    );
  }
}

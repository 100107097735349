import React from 'react';
import styles from './reportTable.module.scss';
import { Nav } from 'react-bootstrap';
import i18n from 'i18n';
import { StickableBootstrapTable } from 'components/common/Table/StickableBootstrapTable';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { ReportDimension } from 'core/report/ReportData';

interface ReportTableProps {
  readonly dimension: ReportDimension;
  readonly tableData: any;
  readonly columnSettings: any;
  readonly dimensions: Array<ReportDimension>;
  onDimensionChange: (dimension: string) => void;
}
export class ReportTable extends React.Component<ReportTableProps> {

  renderTableTab () {
    return Object.values(this.props.dimensions).map(dimension => (
      <Nav.Item key={dimension}>
        <Nav.Link eventKey={dimension}>{i18n.t(`reportTable.labels.${dimension}Dimension`)}</Nav.Link>
      </Nav.Item>
    ));
  }

  renderForm () {
    const { tableData, columnSettings } = this.props;
    return (
      <div className={styles.tableContainer}>
        <StickableBootstrapTable
          stickFirstColumn
          stickLastColumn
          keyField='key'
          data={tableData}
          columns={columnSettings}
          className={styles.reportList}
          pagination={paginationFactory({
            hideSizePerPage: true,
            sizePerPage: 50
          })}
        />
      </div>
    );
  }

  render () {
    const { onDimensionChange, dimension } = this.props;
    const onNavItemSelect = (seletedDimension, e) => {
      onDimensionChange(seletedDimension);
      e.stopPropagation();
    };
    return (
      <div className={styles.reportTable}>
        <Nav
          activeKey={dimension === ReportDimension.HOUR || dimension === ReportDimension.MONTH ? ReportDimension.DAY : dimension}
          onSelect={onNavItemSelect}
        >
          {this.renderTableTab()}
        </Nav>
        {this.renderForm()}
      </div>
    );
  }
}

import React from 'react';
import { Switch, Route, RouteComponentProps, Redirect } from 'react-router-dom';
import { RtbCampaignSetupFlowPage } from './RtbCampaignSetupFlow/RtbCampaignSetupFlowPage';
import styles from './rtbCampaigns.module.scss';
import { withErrorBoundary } from 'hoc/withErrorBoundary';
import { ERROR_CODE } from 'core/ErrorCode';
import CoreContext from 'contexts/coreContext';
import _ from 'lodash';
import { RtbCampaignDraftsProps } from './RtbCampaignDraftsModel';

const EditCampaignDraftView = withErrorBoundary(ERROR_CODE.DRAFT_EDIT, RtbCampaignSetupFlowPage);

export class RtbCampaignDrafts extends React.Component<RouteComponentProps<any> & RtbCampaignDraftsProps> {

  renderEditCampaignDraftView = (localeMeta, props) => {
    const draftId = props.match.params.draftId;
    return (
      <EditCampaignDraftView {...props} model={this.props.model.getEdtiCampaignDraftModel(draftId, localeMeta)} />
    );
  }

  redirectToCampaignGroup = (props) => {
    const match = props.match.url.match(/\/campaign-groups\/\d+\//);
    return <Redirect to={`${match[0].slice(0, -1)}`} />;
  }

  render () {
    return (
      <div className={styles.container}>
        <CoreContext.Consumer>
          {(coreContext) => {
            const localeMeta = coreContext?.accountManager.localeMeta;
            return (
              <Switch>
                <Route
                  exact
                  path={`${this.props.match.url}/:draftId/edit`}
                  render={_.partial(this.renderEditCampaignDraftView, localeMeta)}
                />
                <Route
                  render={this.redirectToCampaignGroup}
                />
              </Switch>
            );
          }}
        </CoreContext.Consumer>
      </div>
    );
  }
}

import { CampaignGroup } from 'core/campaignGroup/CampaignGroup';
import { Order } from 'core/order/Order';
import React from 'react';
import { AdGroupSetupFlowPageModelData, TiktokAdGroupFormData } from './TiktokAdGroupSetupFlowPageModel';

export type TiktokAdGroupSetupFlowContextType = AdGroupSetupFlowPageModelData & {
  order: Order,
  campaignGroup: CampaignGroup,
  adGroup: TiktokAdGroupFormData
};

export const TiktokAdGroupSetupFlowContext = React.createContext<TiktokAdGroupSetupFlowContextType | null>(null);

import authInfoSource, { InvalidTokenHandler } from 'utils/AuthInfoSource';

import { AgencyManager, DefaultAgencyManager } from './agency/AgencyManager';
import { AccountManager, DefaultAccountManager } from './auth/AccountManager';
import {
  DefaultLanguageManager,
  LanguageManager
} from './language/LanguageManager';
import {
  AuthenticationManager,
  DefaultAuthenticationManager
} from './auth/AuthenticationManager';
import {
  AddonFeatureManager,
  DefaultAddonFeatureManager
} from './auth/AddonFeatureManager';

import {
  AdvertiserManager,
  DefaultAdvertiserManager
} from './advertiser/AdvertiserManager';

export interface PMaxCore {
  readonly agencyManager: AgencyManager;
  readonly accountManager: AccountManager;
  readonly languageManager: LanguageManager;
  readonly authenticationManager: AuthenticationManager;
  readonly addonFeatureManager: AddonFeatureManager;
  readonly advertiserManager: AdvertiserManager;
}

export class DefaultPMaxCore implements PMaxCore, InvalidTokenHandler {
  agencyManager: AgencyManager;
  accountManager: AccountManager;
  languageManager: LanguageManager;
  authenticationManager: AuthenticationManager;
  addonFeatureManager: AddonFeatureManager;
  advertiserManager: AdvertiserManager;

  constructor () {
    this.agencyManager = new DefaultAgencyManager();
    this.accountManager = new DefaultAccountManager();
    this.languageManager = new DefaultLanguageManager();
    this.authenticationManager = new DefaultAuthenticationManager();
    this.addonFeatureManager = new DefaultAddonFeatureManager(this.authenticationManager);
    this.advertiserManager = new DefaultAdvertiserManager();
    authInfoSource.invalidTokenHandler = this;
  }

  tokenInvalidated () {
    this.authenticationManager.logout();
  }
}

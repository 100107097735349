import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { TitleWithBreadcrumbs } from 'components/TitleArea/TitleWithBreadcrumbs';
import React from 'react';
import { useProductSetDetailModel } from './ProductSetDetailModel';
import i18n from 'i18n';
import styles from './productSetDetail.module.scss';
import { DynamicBreadcrumb } from 'components/Breadcrumbs/DynamicBreadcrumbs';
import { ProductList } from 'containers/RetailMax/ProductCategory/ProductList';
import { Modal } from 'components/common/Modal/Modal';

export const ProductSetDetail: React.FC<any> = ({
  retailId,
  advertiserId,
  productSetId
}) => {

  const {
    loading,
    productSet,
    editActions,
    deleteProducts,
    onDeleteModalClose
  } = useProductSetDetailModel(retailId, advertiserId, productSetId);

  if (!productSet) {
    return <LoadingIndicator />;
  }

  return (
    <div className={styles.productSetDetail}>
      {loading && <LoadingIndicator />}
      {deleteProducts &&
        <Modal
          title={i18n.t('productSetDetail.labels.deleteModalTitle')}
          secondaryButton={{ title: i18n.t('common.buttons.cancel'), callback: onDeleteModalClose }}
          dangerButton={{ title: i18n.t('common.buttons.delete'), callback: deleteProducts }}
          dismiss={onDeleteModalClose}
        >
          {i18n.t('productSetDetail.labels.deleteModalContent')}
        </Modal>
      }
      <div className={styles.titleArea}>
        <TitleWithBreadcrumbs
          title={i18n.t('advertiserDetail.labels.title')}
          routes={[
            { path: '/product-sets', breadcrumb: i18n.t('appMenus.retailMax.items.productSet') },
            { path: '/product-sets/:id/edit', breadcrumb: DynamicBreadcrumb, props: { label: productSet.name, matchParam: 'id' } }
          ]}
        />
      </div>
      <div className={styles.contentArea}>
        <ProductList
          filterSet={{}}
          searchString=''
          productList={productSet.products}
          editActions={editActions}
        />
      </div>
    </div>
  );
};

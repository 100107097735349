export type CompanyRole = {
  readonly email: string;
  readonly role?: AgencyRole | AdvertiserRole;
};

export enum AgencyRole {
  ROLE_AGENCY_ADMIN = 'ROLE_AGENCY_ADMIN',
  ROLE_AGENCY_MANAGER = 'ROLE_AGENCY_MANAGER',
  ROLE_AGENCY_SALES = 'ROLE_AGENCY_SALES',
  ROLE_AGENCY_REPORT = 'ROLE_AGENCY_REPORT',
  ROLE_FB_AGENCY_MANAGER = 'ROLE_FB_AGENCY_MANAGER'
}

export enum AdvertiserRole {
  ROLE_ADV_ADMIN = 'ROLE_ADV_ADMIN',
  ROLE_ADV_SALES = 'ROLE_ADV_SALES',
  ROLE_ADV_REPORT = 'ROLE_ADV_REPORT'
}

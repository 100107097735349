import React from 'react';
import { CreativeCardProps, CreativeCardState } from './CreativeCardModel';
import { CreativePreview } from 'components/CreativePreview/CreativePreview';
import { DefaultCreativePreviewModel } from 'components/CreativePreview/CreativePreviewModel';
import styles from './creativeCard.module.scss';
import i18n from 'i18next';
import moment from 'moment';
import classNames from 'classnames/bind';
import { faShareSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export class CreativeCard extends React.Component<CreativeCardProps, CreativeCardState> {

  handler: number;
  cssClassNames: any;

  constructor (props) {
    super(props);
    this.cssClassNames = classNames.bind(styles);
    this.handler = this.props.model.event.add((model) => {
      this.setState(model.state);
    });
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add((model) => {
        this.setState(model.state);
      });
    }
  }

  componentWillUnmount () {
    this.props.model.event.remove(this.handler);
  }

  openNewTab = () => {
    const landingPageUrl = this.props.model.creative.landingPageUrl;
    if (landingPageUrl && landingPageUrl !== '') {
      window.open(this.props.model.creative.landingPageUrl, '_blank');
    }
  }

  render () {
    const model = this.props.model;
    const creativeCardClass = this.cssClassNames('creativeCard', {
      selected: model.state.selected,
      disable: model.creative.containFBInternalUrl
    });
    const hintClass = this.cssClassNames('hint', {
      disableTips: model.creative.containFBInternalUrl
    });
    return (
      <div className={styles.creativeCardContainer}>
        <div className={hintClass}>{i18n.t('creativeCard.labels.createTime')}</div>
        <div className={creativeCardClass}>
          <div className={styles.checkbox}>
            <input
              type='checkbox'
              checked={model.state.selected}
              onChange={model.handleOnClick}
              id={`selectCreativeCard${model.creative.id}`}
            />
            <label htmlFor={`selectCreativeCard${model.creative.id}`}/>
          </div>
          <div className={styles.previewArea}>
            <CreativePreview model={new DefaultCreativePreviewModel(model.creative)} />
          </div>
          <div className={styles.name}>
            {model.creative.name}
          </div>
          <div className={styles.landingPageUrl}>
            <div className={styles.url}>
              {model.creative.landingPageUrl}
            </div>
            <div className={styles.btn} onClick={this.openNewTab}>
              <FontAwesomeIcon icon={faShareSquare}/>
            </div>
          </div>
          <div className={styles.footer}>
            <div className={styles.id}>
              {`ID: ${model.creative.rtbCreativeId}`}
            </div>
            <div className={styles.ctime}>
              {i18n.t('creativeCard.labels.createTime', { time : moment(model.creative.createTime).format('YYYY/MM/DD') })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

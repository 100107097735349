import { CreativeListRecord, CreativeOfCampaign } from 'core/creative/Creative';
import React from 'react';
import styles from './creativePreview.module.scss';
import ppsPlaceholder from 'assets/campaign/adType-thirdParty.png';
import cx from 'classnames/bind';

export const PPSCreativePreview: React.FunctionComponent<{creative: CreativeOfCampaign | CreativeListRecord}> = (props) => {

  const bannerImageUrl = props.creative.bannerImageUrl;
  const src = bannerImageUrl ? bannerImageUrl : ppsPlaceholder;
  const classNames = cx.bind(styles);
  const previewClass = classNames('ppsCreativePreview', {
    border: !bannerImageUrl
  });
  return (
    <div className={previewClass}>
      <img src={src} alt={'preview'} />
    </div>
  );
};

import React from 'react';
import _ from 'lodash';
import { connect } from 'formik';

import { DailyBudgetPlan, RtbCampaignPlanType } from 'core/rtbCampaign/RtbCampaign';
import RtbCampaignInfo from 'components/RtbCampaignBasicForm/RtbCampaignInfo';
import moment from 'moment';
import CoreContext from 'contexts/coreContext';
import RtbCampaignOptimize from 'components/RtbCampaignBasicForm/RtbCampaignOptimize';
import { GoCampaignGroupObjective } from 'core/campaignGroup/CampaignGroup';
import { GoCampaignOptimizationGoal } from 'core/goCampaign/GoCampaign';
import { getFieldErrors } from 'utils/FormikUtils';

export class RtbCampaignBasicForm extends React.Component<any> {
  handler: number;
  constructor (props) {
    super(props);
    this.handleChangeDailyBudgetPlan = this.handleChangeDailyBudgetPlan.bind(
      this
    );
    this.handleGetOrderPriceMinimum = this.handleGetOrderPriceMinimum.bind(
      this
    );
    this.handleGetRemainBudget = this.handleGetRemainBudget.bind(this);
    this.handleGetCampaignTotalDay = this.handleGetCampaignTotalDay.bind(this);
    this.handleGetDailyBudgetState = this.handleGetDailyBudgetState.bind(this);
    this.handler = this.props.model.event.add(model => {
      this.setState(model.state, () => {
        const { dailyBudgetType } = this.props.model.state;
        if (dailyBudgetType === _.get(this.props, 'formik.values.dailyBudgetType')) {
          return;
        }
        let dailyTargetBudget: any = undefined;
        if (dailyBudgetType === DailyBudgetPlan.DAILY.toString()) {
          dailyTargetBudget = '0';
        }

        this.props.formik.setFieldValue('dailyTargetBudget', dailyTargetBudget);
      });
    });
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add(model => {
        this.setState(model.state);
      });
    }
  }

  componentDidMount () {
    let dailyTargetBudget = _.get(this.props, 'formik.values.dailyTargetBudget');
    if (dailyTargetBudget === null) {
      dailyTargetBudget = undefined;
    }
    // wait for formik didMount flag set to true
    setTimeout(() => {
      this.props.formik.setFieldValue('dailyTargetBudget', dailyTargetBudget);
    }, 0);
  }

  componentWillUnmount () {
    this.props.model.onUnmount(this.handler);
  }

  handleChangeDayPartSwitch = (dayPartEnable: boolean) => {
    if (!dayPartEnable) {
      this.props.formik.setFieldValue('dayPart', undefined);
    }
  }

  handleOptimizeChanged = (newOptimize) => {
    if (this.props.model.campaignGroup.objective === GoCampaignGroupObjective.AwarenessObjective.REACH && newOptimize === GoCampaignOptimizationGoal.REACH) {
      this.props.formik.setFieldValue('frequency', {
        intervalDays: 1,
        maxFrequency: 5
      });
    } else {
      this.props.formik.setFieldValue('frequency', undefined);
    }
  }

  handleChangeDailyBudgetPlan (e) {
    this.props.model.changeDailyBudgetOptions(e.target.value);
  }

  handleChangeSchedule = (startDate, endDate) => {
    const campaignGroup = this.props.model.campaignGroup;
    const order = this.props.model.order;
    if (campaignGroup && campaignGroup.autoOptimise && this.props.model.type === 'create') {
      const scheduleDateCount = moment(endDate).diff(moment(startDate), 'days') + 1;
      this.props.formik.setFieldValue('budget', order.campaignConstraint.budgetMinimum * scheduleDateCount);
    }
  }

  handleChangePriceModel = (model) => {
    this.props.model.setCurrentPriceModel(model);
    this.props.model.onPriceModelChangeCallback(model, this.props.formik.values);
  }

  handleChangeOrderPrice = (priceModel, value) => {
    if (!this.props.model.canEditOptimize() && priceModel !== RtbCampaignPlanType.RS && priceModel !== RtbCampaignPlanType.DCPM) {
      this.props.formik.setFieldValue('bidPrice', value);
    }
  }

  handleGetOrderPriceMinimum () {
    const priceModel = _.get(this.props, 'formik.values.priceModel');
    return this.props.model.getOrderPriceMinimum(priceModel);
  }

  handleGetRemainBudget () {
    const campaignBudget = _.get(this.props, 'formik.values.budget', '');
    return this.props.model.getRemainBudget(campaignBudget);
  }

  handleGetCampaignTotalDay () {
    const startDate = _.get(this.props, 'formik.values.startDate');
    const endDate = _.get(this.props, 'formik.values.endDate');

    return this.props.model.getCampaignTotalDay(startDate, endDate);
  }

  handleGetDailyBudgetState () {
    const dailyBudget = _.get(
      this.props,
      'formik.values.dailyTargetBudget'
    );
    const budget = _.get(this.props, 'formik.values.budget');
    const campaignTotalDay = this.handleGetCampaignTotalDay();
    return this.props.model.getDailyBudgetState(
      +budget,
      +dailyBudget,
      campaignTotalDay
    );
  }

  render () {
    const {
      order,
      actionType,
      campaignGroup,
      priceModelOptions,
      optimizeOptions,
      campaignDeliverTypeOptions,
      canEditOptimize,
      orderName,
      agencyProfit,
      currency,
      minDate,
      maxDate,
      canEditPriceModel,
      canEditBudgetPlan,
      canUseDailyBudget,
      videoAdMetricEventOptions,
      canEditVideoViewObjective,
      showVideoProgressRadio,
      campaignAdType,
      campaignBasic: defaultCampaign,
      showOptimizeSection,
      retailerOptions
    } = this.props.model;

    const { dailyBudgetType } = this.props.model.state;
    const formikValue = _.get(this.props, 'formik.values');

    const dayPartEnable = _.get(
      formikValue,
      'dayPart.enabled',
      0
    );
    const priceModel = _.get(formikValue, 'priceModel');
    const dailyBudget = _.get(
      formikValue,
      'dailyTargetBudget'
    );
    const budget = _.get(formikValue, 'budget');
    const isRbModel = priceModel === RtbCampaignPlanType.RB;
    const isCPVModel = priceModel === RtbCampaignPlanType.FCPV;
    const isCPMModel = priceModel === RtbCampaignPlanType.FCPM;
    const isCPCModel = priceModel === RtbCampaignPlanType.FCPC;
    const isDailyBudgetPlan =
      ~~dailyBudgetType === DailyBudgetPlan.DAILY && !isRbModel;
    const offset = _.get(
      formikValue,
      'videoAdViewObjective.offset'
    );
    const videoAdMetric = _.get(
      formikValue,
      'videoAdViewObjective.videoAdEvent'
    );
    const videoAdMetricEvent = _.get(
      formikValue,
      'videoAdViewObjective.videoAdMetricEvent'
    );
    const orderPrice = _.get(formikValue, 'orderPrice');
    const startDate = _.get(formikValue, 'startDate');
    const endDate = _.get(formikValue, 'endDate');
    const isNewCampaign = _.get(formikValue, 'id') === undefined || _.get(formikValue, 'isDraft', false);
    const isCampaignStart = !isNewCampaign && moment(defaultCampaign.startDate).isBefore(moment());
    const isOutOfOutdoorBk = _.get(formikValue, 'isOutOfOutdoorBk', false);
    const tags = _.get(formikValue, 'tags', []);
    const dailyBudgetState = this.handleGetDailyBudgetState();
    const remainBudget = this.handleGetRemainBudget();
    const showOrderPriceHint = !getFieldErrors(this.props.formik, 'orderPrice');
    const showBudgetHint = !getFieldErrors(this.props.formik, 'budget');
    return (
      <CoreContext.Consumer>
        {(coreContext) => {
          const orderPriceMinimum = this.props.model.getOrderPriceMinimum(
            priceModel,
            coreContext?.accountManager.localeMeta
          );
          return (
            <div>
              <RtbCampaignInfo
                formType={actionType}
                autoOptimise={campaignGroup.autoOptimise}
                editCampaignGroupPath={`/orders/${order.orderNumber}/campaign-groups/${campaignGroup.groupId}/edit`}
                errors={this.props.formik.errors}
                orderName={orderName}
                startDate={startDate}
                endDate={endDate}
                minDate={minDate}
                maxDate={maxDate}
                isCampaignStart={isCampaignStart}
                canEditPriceModel={canEditPriceModel}
                priceModelOptions={priceModelOptions}
                campaignDeliverTypeOptions={campaignDeliverTypeOptions}
                isCPCModel={isCPCModel}
                isCPMModel={isCPMModel}
                isCPVModel={isCPVModel}
                isRbModel={isRbModel}
                retailerOptions={retailerOptions}
                isOutOfOutdoorBk={isOutOfOutdoorBk}
                orderPrice={orderPrice}
                showOrderPriceHint={showOrderPriceHint}
                agencyProfit={agencyProfit}
                currency={currency}
                priceModel={priceModel}
                canUseDailyBudget={canUseDailyBudget}
                canEditBudgetPlan={canEditBudgetPlan}
                isDailyBudgetPlan={isDailyBudgetPlan}
                onChangeDailyBudgetPlan={this.handleChangeDailyBudgetPlan}
                onChangePriceModel={this.handleChangePriceModel}
                onChangeOrderPrice={_.curry(this.handleChangeOrderPrice)(priceModel, _)}
                onChangeSchedule={this.handleChangeSchedule}
                videoAdMetricEventOptions={videoAdMetricEventOptions}
                dailyBudget={dailyBudget}
                budget={budget}
                videoAdMetric={videoAdMetric}
                videoAdMetricEvent={videoAdMetricEvent}
                offset={offset}
                dailyBudgetState={dailyBudgetState}
                remainBudget={remainBudget}
                orderPriceMinimum={orderPriceMinimum}
                showBudgetHint={showBudgetHint}
                showTagsPlaceholder={tags.length === 0}
                canEditVideoViewObjective={canEditVideoViewObjective}
                showVideoProgressRadio={showVideoProgressRadio}
                campaignAdType={campaignAdType}
                showOrderPriceField={_.get(formikValue, 'orderPriceEnable')}
              />
              {priceModel !== RtbCampaignPlanType.RB && showOptimizeSection &&
                <RtbCampaignOptimize
                  formType={actionType}
                  campaignAdType={campaignAdType}
                  campaignGroup={campaignGroup}
                  currentGoCampaign={formikValue}
                  optimizeOptions={optimizeOptions}
                  currency={currency}
                  canEditOptimize={priceModel === RtbCampaignPlanType.RS || canEditOptimize()}
                  onOptimizeChanged={this.handleOptimizeChanged}
                  dayPartEnable={dayPartEnable}
                  onDaypartSwitchChanged={this.handleChangeDayPartSwitch}
                />
              }
            </div>
          );
        }}
      </CoreContext.Consumer>
    );
  }
}

export default connect(RtbCampaignBasicForm);

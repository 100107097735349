import { RetailerType } from 'core/product/Product';
import { ADNEON_CREATIVE_LAYOUT, CreativeType } from './Creative';

export const retailCreativeConfig = {
  [CreativeType.RETAIL_RICH_MEDIA_PRODUCT]: {
    [RetailerType.PCHOME]: [
      ADNEON_CREATIVE_LAYOUT.DESKTOP_SLIDE_PRODUCTSET
    ]
  },
  [CreativeType.RETAIL_NATIVE_PRODUCT]: {
    [RetailerType.PCHOME]: [
      ADNEON_CREATIVE_LAYOUT.MOBILE_LIST_ITEM_PRODUCTSET,
      ADNEON_CREATIVE_LAYOUT.DESKTOP_LIST_ITEM_PRODUCTSET
    ]
  }
};

import React from 'react';
import { StickableBootstrapTable } from 'components/common/Table/StickableBootstrapTable';
import { TiktokAdGroupListColumns, useTiktokAdGroupListModel } from './TiktokAdGroupListModel';
import i18n from 'i18n';
import styles from './tiktokAdGroupList.module.scss';
import { SearchBar } from 'components/common/SearchBar';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { Link } from 'react-router-dom';
import { Modal } from 'components/common/Modal/Modal';
import { TiktokAdGroupListRecord } from 'core/tiktokAdGroup/TiktokAdGroup';
import { Order } from 'core/order/Order';
import cx from 'classnames/bind';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { hasFuncs } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';
import { CampaignGroup } from 'core/campaignGroup/CampaignGroup';
import Select from 'components/common/Select/Select';
import { ListEditBar } from 'components/ListEditBar/ListEditBar';
import { AddonFeatureManager } from 'core';
import { FilterMenuTab } from 'components/common/FilterMenuTab/FilterMenuTab';
import { DraftCreateModal } from 'containers/Draft/DraftCreateModal';
import _ from 'lodash';
const classNames = cx.bind(styles);

export type TiktokAdGroupListProps = {
  order: Order;
  adGroups: TiktokAdGroupListRecord[];
  campaignGroup?: CampaignGroup;
  addonFeatureManager: AddonFeatureManager;
  refreshList: () => void;
};

export const TiktokAdGroupList: React.FC<TiktokAdGroupListProps> = ({
  order,
  adGroups,
  campaignGroup,
  addonFeatureManager,
  refreshList
}) => {

  const {
    loading,
    columns,
    currentUrl,
    summaryData,
    filteredList,
    searchString,
    selectedAdGroups,
    selectedDrafts,
    selectedMetricsGroup,
    metricsGroupOptions,
    canNotCreateMessage,
    showDraftCreateModal,
    onMetricsGroupChange,
    rowClasses,
    onHandleSearch,
    deleteDraft,
    activeAdGroup,
    deactiveAdGroup,
    bindCreatives,
    activateCreatives,
    deactivateCreatives,
    handleRemoveSelect,
    onDeleteDraftModalClose,
    showDeleteDraftConfirmModal,
    onShowDeleteDraftConfirmModal,
    hideDraftCreateModal,
    setShowDraftCreateModal,
    filterMenuRef,
    setFilterMenuRef,
    filterMenuTabConfigs
  } = useTiktokAdGroupListModel(order, campaignGroup, adGroups, refreshList, addonFeatureManager);

  const handleShowDeleteDraftConfirmModal = (event?: any) => {
    event && event.stopPropagation();
    onShowDeleteDraftConfirmModal();
  };

  const removeFilterMenuRef = () => {
    if (filterMenuRef) {
      filterMenuRef.hide();
    }
  };

  const renderEditBar = () => {
    const selectedObjects = [...selectedAdGroups, ...selectedDrafts];
    const handleNavEvent = (event) => {
      event && event.stopPropagation();
    };
    return (
      <ListEditBar selectedItems={selectedObjects} handleRemoveSelect={handleRemoveSelect}>
        <div className={styles.dropdownContainer}>
          <ListEditBar.Dropdown
            title={i18n.t('campaignList.labels.creative')}
            id='creativeManagement'
            onClick={handleNavEvent}
          >
            <ListEditBar.Dropdown.Item onClick={bindCreatives}>{i18n.t('campaignList.labels.bindCreative')}</ListEditBar.Dropdown.Item>
            <ListEditBar.Dropdown.Item onClick={deactivateCreatives}>{i18n.t('campaignList.labels.deactivateCreative')}</ListEditBar.Dropdown.Item>
            <ListEditBar.Dropdown.Item onClick={activateCreatives}>{i18n.t('campaignList.labels.activeCreative')}</ListEditBar.Dropdown.Item>
          </ListEditBar.Dropdown>
        </div>
        <ListEditBar.Item>
          <ListEditBar.Link onClick={activeAdGroup}>
            {i18n.t('campaignList.labels.activeCampaign')}
          </ListEditBar.Link>
        </ListEditBar.Item>
        <ListEditBar.Item>
          <ListEditBar.Link onClick={deactiveAdGroup}>
            {i18n.t('campaignList.labels.deactivateCampaign')}
          </ListEditBar.Link>
        </ListEditBar.Item>
        <ListEditBar.Item>
          <ListEditBar.Link onClick={_.partial(setShowDraftCreateModal, true)}>
            {i18n.t('campaignList.labels.duplicate')}
          </ListEditBar.Link>
        </ListEditBar.Item>
        <div className={styles.deleteDraftTab}>
          <ListEditBar.Item>
            <ListEditBar.Link disabled={!_.isEmpty(selectedAdGroups)} onClick={handleShowDeleteDraftConfirmModal}>
              <FontAwesomeIcon icon={faTrashAlt} />
              {i18n.t('campaignList.labels.deleteDraft')}
            </ListEditBar.Link>
          </ListEditBar.Item>
        </div>
      </ListEditBar>
    );
  };

  const createContainerClassName = classNames('createAdGroup', 'tipContainer', {
    block: canNotCreateMessage !== ''
  });

  const renderDraftCreateModal = () => {
    const adGroupsToCreateDraft = adGroups
      .filter(adGroup =>
        (!adGroup.isDraft && selectedAdGroups.includes(adGroup.adgroup_id)) ||
        (adGroup.draftId && selectedDrafts.includes(adGroup.draftId.toString())))
      .map(adGroup => {
        return adGroup.isDraft ? {
          id: adGroup.draftId ? adGroup.draftId : 0,
          isDraft: true
        } : {
          id: adGroup.adgroup_id ? adGroup.adgroup_id : 0,
          isDraft: false
        };
      });
    return (
      <DraftCreateModal
        order={order}
        campaignGroup={campaignGroup!}
        goCampaignsToCreateDraft={adGroupsToCreateDraft}
        onClose={hideDraftCreateModal}
      />
    );
  };

  return (
    <div className={styles.tiktokAdGroupList}>
      {loading && <LoadingIndicator />}
      {showDeleteDraftConfirmModal &&
        <Modal
          title={i18n.t('campaignList.labels.deleteDraftModalTitle')}
          secondaryButton={{ title: i18n.t('common.buttons.cancel'), callback: onDeleteDraftModalClose }}
          primaryButton={{ title: i18n.t('campaignList.labels.deleteCampaign'), callback: deleteDraft }}
          dismiss={onDeleteDraftModalClose}
        >
          {_.map(i18n.t('campaignList.labels.deleteDraftModalContent').split(/\r?\n/), (hint, index) => (
            <div key={index}>{hint}</div>
          ))}
        </Modal>
      }
      <div className={styles.titleBar}>
        {(selectedAdGroups.length > 0 || selectedDrafts.length > 0) ?
          renderEditBar() :
          <div className={styles.filterArea}>
            <PermissionChecker permissionAware={hasFuncs(Permission.CAMPAIGN_WRITE)}>
              <div className={createContainerClassName}>
                {canNotCreateMessage &&
                  <span className={styles.tooltip}>
                    {canNotCreateMessage}
                  </span>
                }
                <Link
                  to={`${currentUrl}/campaigns/new`}
                  className={`btn btn-tertiary btn-sm ${styles.createBtn}`}
                >
                  {i18n.t('tiktokAdGroupList.buttons.create')}
                </Link>
              </div>
            </PermissionChecker>
            <FilterMenuTab
              filterMenuTabConfigs={filterMenuTabConfigs}
              setFilterMenuRef={setFilterMenuRef}
              removeFilterMenuRef={removeFilterMenuRef}
            />
            <SearchBar
              model={{
                placeholder: i18n.t('tiktokAdGroupList.placeholders.searchbar'),
                search: onHandleSearch,
                defaultValue: searchString
              }}
            />
          </div>
        }
      </div>
      <div className={styles.contentArea}>
        <div className={styles.metricsOptionsContainer}>
          <Select
            className={styles.metricsOptions}
            options={metricsGroupOptions}
            name='metricsGroupOptions'
            simpleValue
            value={selectedMetricsGroup}
            onChange={onMetricsGroupChange}
          />
        </div>
        <StickableBootstrapTable
          stickFirstColumn
          stickLastColumn
          key={selectedMetricsGroup}
          keyField={TiktokAdGroupListColumns.LISTID}
          data={filteredList}
          columns={columns}
          rowClasses={rowClasses}
          summaryData={summaryData}
          paddingBottom={false}
          noDataIndication={i18n.t('tiktokAdGroupList.labels.noData')}
          defaultSorted={[{
            dataField: TiktokAdGroupListColumns.LISTID,
            order: 'desc'
          }]}
        />
      </div>
      {showDraftCreateModal &&
        renderDraftCreateModal()
      }
    </div>
  );
};

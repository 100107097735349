import React from 'react';
import { StateModalProps, StateModalState } from './StateModalModel';
import { Modal } from 'components/common/Modal/Modal';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { StateDataContext } from './StateDataContext';

export class StateModal extends React.Component<StateModalProps, StateModalState> {

  handler: number;
  cssClassNames: any;

  constructor (props) {
    super(props);
    this.handler = this.props.model.event.add((model) => {
      this.setState(model.state);
    });
  }

  componentDidMount () {
    this.props.model.init();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add((model) => {
        this.setState(model.state);
      });
      this.props.model.init();
    }
  }

  componentWillUnmount () {
    this.props.model.event.remove(this.handler);
  }

  render () {
    if (this.props.model.state.loading) {
      return <LoadingIndicator />;
    }

    const componentState = this.props.model.state;
    const currentState = this.props.model.state.currentState!;
    const ContentComponent: typeof React.Component = currentState.content;
    return (
      <Modal
        title={currentState.title}
        primaryButton={
          {
            ...currentState.btnData.primaryButton,
            disabled: !currentState.canGoNext()
          }
        }
        secondaryButton={currentState.btnData.secondaryButton}
        dangerButton={currentState.btnData.dangerButton}
        fullScreen
        animation={false}
      >
        <StateDataContext.Provider
          value={
            {
              data: componentState.data,
              setData: componentState.setData
            }
          }
        >
          <ContentComponent model={currentState.contentModel}/>
        </StateDataContext.Provider>
      </Modal>
    );
  }
}

import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import { ReportManager, DefaultReportManager } from 'core/report/ReportManager';
import { ReportType, ReportDimension, ReportGran, ReportData, ReportRecord, Metrics } from 'core/report/ReportData';
import { LocaleMeta, MOMENT_TIMEZONE, Actor, AddonFeatureManager, Currency } from 'core';
import { ReactText } from 'react';
import { SelectOptions } from 'components/common/commonType';
import { isSystemAdmin } from 'helper/ActorHelper';
import { getDataProvider } from 'components/ReportTable/ReportDataProviderFactory';
import { ReportDataProvider } from 'components/ReportTable/ReportDataProvider';
import { AdvertiserManager, DefaultAdvertiserManager } from 'core/advertiser/AdvertiserManager';
import { OrderManager, DefaultOrderManager } from 'core/order/OrderManager';
import DefaultCreativeManager, { CreativeManager } from 'core/creative/CreativeManager';
import { RtbCampaignManager, DefaultRtbCampaignManager } from 'core/rtbCampaign/RtbCampaignManager';
import { AgencyManager, DefaultAgencyManager } from 'core/agency/AgencyManager';
import { emptyReportRecord } from 'components/ReportTable/ReportDataHelper';
import moment from 'moment-timezone';
import i18n from 'i18n';
import _ from 'lodash';
import { DateRange } from 'components/common/DateRangePicker/DateRangePicker';
import DefaultCampaignGroupManager, { CampaignGroupManager } from 'core/campaignGroup/CampaignGroupManager';
import { DefaultFbAdSetManager, FbAdSetManager } from 'core/fbAdSet/FbAdSetManager';
import { DefaultTiktokAdGroupManager, TiktokAdGroupManager } from 'core/tiktokAdGroup/TiktokAdGroupManager';

export const SUPPORT_TIMEZONE = [
  '+07:00',
  '+08:00'
];

export interface ReportContentModel {
  readonly state: ReportContentState;
  readonly event: UpdateEventListener<ReportContentModel>;
  readonly reportTypes: Array<SelectOptions>;
  readonly reportGrans: Array<SelectOptions>;
  readonly reportTimezones: Array<SelectOptions>;
  readonly isSysAdmin: boolean;
  readonly searchString: string;
  readonly defaultReportType: ReportType;
  readonly defaultReportDimension: ReportDimension;
  readonly defaultReportGran: ReportGran;
  readonly needSpecifyAgency: boolean;
  dateTimeShortCut: () => DateRange[];
  initReportData: () => Promise<void>;
  getReportData: () => Promise<void>;
  getFilterOptions: (filterName: string) => Array<SelectOptions>;
  updateReportType: (type: ReactText) => void;
  updateGran: (type: ReactText) => void;
  updateTimezone: (type: string) => void;
  updateDayRange: (from: string | undefined, to: string | undefined) => void;
  addFilter: (filterName: string, filterValue: ReactText) => void;
  updateReportData: (params) => void;
  updateSearchPath: (replace: boolean) => void;
  queryDataWithFilter: (filterName: string, filterValue: ReactText) => void;
  queryDataWithDimension: (dimension: string) => void;
  addEmptyFilter: (filterName: string) => void;
  removeFilter: (filterName: string) => void;
  handleOnSearch: (searchString: string) => void;
  handleOnTagFilterClicked: (tag: string) => void;
  toggleShowDimensionSelectArea (): void;
  toggleShowReportChart (): void;
  canFilterSelect (filterName: string): boolean;
  canFilterRemove (filterName: string): boolean;
  download: () => Promise<void>;
}

export type ReportContentProps = {
  readonly search: string;
  readonly lang: string;
  readonly customDimensionComponent?: any;
  readonly model: ReportContentModel;
};

export type ReportContentState = {
  readonly loading: boolean;
  readonly reportData?: ReportData;
  readonly reportType: ReportType;
  readonly dimension: ReportDimension;
  readonly gran: ReportGran;
  readonly timezone: string;
  readonly filter: any;
  readonly from: string;
  readonly to: string;
  readonly searchPath: string;
  readonly tableDimensions: any;
  readonly tableColumnSettings: any;
  readonly tableData: any;
  readonly redirectPath: string;
  readonly selectedTagFilter: Array<string>;
  readonly tags: Array<string>;
  readonly dayRangeError?: string;
  readonly showDimensionSelectArea: boolean;
  readonly showReportChart: boolean;
};

const MAX_QUERY_DAYS = 93;
const MAX_QUERY_HOURS = 168;

export abstract class AbstactReportContentModel implements ReportContentModel {
  event: FireableUpdateEventListener<ReportContentModel>;
  loading: boolean;
  searchString: string;
  reportManager: ReportManager;
  reportData?: ReportData;
  reportType: ReportType;
  dimension: ReportDimension;
  gran: ReportGran;
  timezone: string;
  filter: any;
  from: string;
  to: string;
  filterOptions: any;
  cachedOptions: any;
  cachedReportQuery: any;
  filterOptionSources: any;
  dataProvider: ReportDataProvider;
  redirectPath: any;
  filteredRecords: Array<ReportRecord>;
  selectedTagFilter: Array<string>;
  tableData: Array<any>;
  tableColumnSettings: Array<any>;
  reportDimensions: Array<ReportDimension>;
  debouncedUpdateFilteredRecords: any;
  dayRangeError?: string;
  updateSearchPath: (replace: boolean) => void;
  showDimensionSelectArea: boolean;
  showReportChart: boolean;

  constructor (
    private actor: Actor | null,
    protected localeMeta: LocaleMeta | undefined,
    updateSearchPath: (newSearchPath, replace) => void,
    protected addonFeatureManager: AddonFeatureManager,
    reportManager: ReportManager = new DefaultReportManager(),
    advertiserManager: AdvertiserManager = new DefaultAdvertiserManager(),
    orderManager: OrderManager = new DefaultOrderManager(),
    creativeManager: CreativeManager = new DefaultCreativeManager(),
    private campaignManager: RtbCampaignManager = new DefaultRtbCampaignManager(),
    agencyManager: AgencyManager = new DefaultAgencyManager(),
    campaignGroupManager: CampaignGroupManager = new DefaultCampaignGroupManager(),
    private fbAdSetManager: FbAdSetManager = new DefaultFbAdSetManager(),
    private tiktokAdGroupManager: TiktokAdGroupManager = new DefaultTiktokAdGroupManager()
  ) {
    this.showDimensionSelectArea = true;
    this.showReportChart = true;
    this.event = new FireableUpdateEventListener<ReportContentModel>();
    this.loading = true;
    this.selectedTagFilter = [];
    this.searchString = '';
    this.reportManager = reportManager;
    this.filteredRecords = [];
    this.reportType = ReportType.PERFORMANCE;
    this.dataProvider = getDataProvider(this.reportType, this.queryDataWithFilter, this.onDateClick, this.onEditClick);
    this.dimension = ReportDimension.DAY;
    this.gran = ReportGran.DAY;
    this.filter = {};
    this.from = moment().subtract(7, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss');
    this.to = moment().subtract(1, 'days').endOf('day').format('YYYY-MM-DD HH:mm:ss');
    this.timezone = this.localeMeta ? this.localeMeta.timezone : '+07:00';
    this.updateSearchPath = (replace: boolean) => {
      updateSearchPath(this.searchPath, replace);
    };
    this.cachedOptions = {};
    this.cachedReportQuery = {};
    this.filterOptionSources = {
      [ReportDimension.AGENCY]: async () => (await agencyManager.getAgenciesOptions()).map(this.optionValueToString),
      [ReportDimension.ADVERTISER]: async () => (await advertiserManager.getAdvertiserOptions()).map(this.optionValueToString),
      [ReportDimension.ORDER]: async () => (await orderManager.getOrderOptions()).map(this.optionValueToString),
      [ReportDimension.GO_CAMPAIGN]: async () => this.getGojekCampaignOptions(),
      [ReportDimension.GO_CAMPAIGN_GROUP]: async () => (await campaignGroupManager.getCampaignGroupOptions()).map(this.optionValueToString),
      [ReportDimension.BINDING]: async () => (await creativeManager.getBindingOptions()).map(this.optionValueToString),
      [ReportDimension.RETAIL]: async () => (
        await this.campaignManager.getRetailOptions()).map(this.optionValueToString),
      [ReportDimension.SALES_CHANNEL]: async () => {
        return ['FB', 'RTB', 'TIKTOK'].map(channel => {
          return {
            label: channel,
            value: channel
          };
        });
      }
    };
    this.filterOptions = {};
    this.tableData = [];
    this.tableColumnSettings = [];
    this.reportDimensions = this.dataProvider.getReportDimensions(this.actor);
    this.debouncedUpdateFilteredRecords = _.debounce(this.updateFilteredRecords.bind(this), 1000);
  }

  getGojekCampaignOptions = async () => {
    const rtbCampaignOptions = (await this.campaignManager.getCampaignOptions()).map(this.optionValueToString);
    const adSetOptions = (await this.fbAdSetManager.getAdSetOptions()).map(this.optionValueToString);
    const adGroupOptions = (await this.tiktokAdGroupManager.getAdGroupOptions()).map(this.optionValueToString);
    return [{
      label: 'RTB Campaigns',
      value: 'RTB Campaigns',
      options: rtbCampaignOptions
    }, {
      label: 'FB Ad Sets',
      value: 'FB Ad Sets',
      options: adSetOptions
    }, {
      label: 'TikTok Ad Groups',
      value: 'TikTok Ad Groups',
      options: adGroupOptions
    }];
  }

  abstract initReportData ();
  abstract download ();
  abstract get needSpecifyAgency ();
  abstract get defaultReportType ();
  abstract get defaultReportDimension ();
  abstract get defaultReportGran ();
  abstract get validDimensions ();

  optionValueToString = (option) => {
    return {
      ...option,
      value: option.value.toString()
    };
  }

  get isSysAdmin (): boolean {
    return isSystemAdmin(this.actor);
  }

  get tags (): Array<string> {
    return _.uniq(_.flatten(this.tableData.map(data => data.tags)));
  }

  abstract get reportTypes ();

  abstract get reportGrans ();

  get reportTimezones () {
    return SUPPORT_TIMEZONE.map(timezone => {
      return {
        label: i18n.t(`report.labels.${timezone.replace(':', '')}`),
        value: timezone
      };
    });
  }

  get state (): ReportContentState {
    return {
      loading: this.loading,
      reportData: this.reportData,
      reportType: this.reportType,
      dimension: this.dimension,
      gran: this.gran,
      timezone: this.timezone,
      filter: this.filter,
      from: this.from,
      to: this.to,
      searchPath: this.searchPath,
      tableDimensions: this.reportDimensions,
      tableColumnSettings: this.tableColumnSettings,
      tableData: this.filteredRecords,
      redirectPath: this.redirectPath,
      selectedTagFilter: this.selectedTagFilter,
      tags: this.tags,
      dayRangeError: this.dayRangeError,
      showDimensionSelectArea: this.showDimensionSelectArea,
      showReportChart: this.showReportChart
    };
  }

  toggleShowDimensionSelectArea = () => {
    this.showDimensionSelectArea = !this.showDimensionSelectArea;
    this.updateState(false);
  }

  toggleShowReportChart = () => {
    this.showReportChart = !this.showReportChart;
    this.updateState(false);
  }

  get searchPath (): string {
    const filterQueryParams = Object.keys(this.filter)
      .filter(key => this.filter[key] !== undefined)
      .map(key => `${key}=${this.filter[key]}`)
      .join('&');
    const searchPath = `?type=${this.reportType}&dimension=${this.dimension}&gran=${this.gran}&from=${encodeURIComponent(this.from)}&to=${encodeURIComponent(this.to)}&timezone=${encodeURIComponent(this.timezone)}`;
    return _.isEmpty(filterQueryParams) ? searchPath : `${searchPath}&${filterQueryParams}`;
  }

  async fetchFilterOptions (filterType) {
    if (this.cachedOptions[filterType]) {
      this.filterOptions[filterType] = this.cachedOptions[filterType];
      return;
    }
    try {
      this.filterOptions[filterType] = await this.filterOptionSources[filterType]();
    } catch (e) {
      this.filterOptions[filterType] = [];
    }
    this.cachedOptions[filterType] = this.filterOptions[filterType];
  }

  updateReportType = (type: ReactText) => {
    this.setUpReportType(type);
    this.updateState(false);
  }

  updateGran = (gran: ReactText) => {
    this.setUpGran(gran);
    this.updateState(false);
  }

  updateTimezone = (timezone: string) => {
    this.setUpTimezone(timezone);
    this.updateState(false);
  }

  updateDayRange = (from: string | undefined, to: string | undefined) => {
    this.setUpDayRange(from, to);
    this.updateState(false);
  }

  addFilter = (filterName: string, filterValue: ReactText) => {
    this.filter = {
      ...this.filter,
      [filterName]: filterValue
    };
    this.updateState(false);
  }

  queryDataWithDimension = (dimension: string) => {
    let targetDimension = dimension;
    if (this.isDateDimension(dimension)) {
      targetDimension = this.gran;
    }
    const reportDimension = _.find(Object.values(ReportDimension), value => value === targetDimension);
    if (reportDimension) {
      this.dimension = reportDimension;
      this.updateSearchPath(false);
    }
  }

  queryDataWithFilter = async (filterType: string, filterValue: ReactText) => {
    this.updateState(true);
    this.filter = {
      ...this.filter,
      [filterType]: filterValue
    };
    await this.isFilterInvalid(filterType);
    this.updateState(false);
    this.updateSearchPath(false);
  }

  onEditClick = (editPath) => {
    this.redirectPath = editPath;
    this.updateState(false);
  }

  isDateDimension = (dimension) => {
    return dimension === ReportDimension.MONTH ||
      dimension === ReportDimension.DAY ||
      dimension === ReportDimension.HOUR;
  }

  abstract onDateClick (date);

  abstract canFilterSelect (filterName: string);

  abstract canFilterRemove (filterName: string);

  getFilterOptions = (filterType: string) => {
    return this.filterOptions[filterType];
  }

  getReportData = async () => {
    this.updateState(true);
    const defaultReportData = {
      allowMetrics: [Metrics.IMPRES, Metrics.CLICKS],
      records: [],
      dimension: this.dimension,
      filter: {},
      from: this.getDateWithTimezone(this.from),
      to: this.getDateWithTimezone(this.to),
      currency: Currency.NTD
    };
    if (this.dayRangeError) {
      this.reportData = defaultReportData;
    } else {
      try {
        if (this.needSpecifyAgency && !this.filter[ReportDimension.AGENCY]) {
          await this.fetchFilterOptions(ReportDimension.AGENCY);
          if (this.filterOptions[ReportDimension.AGENCY] && this.filterOptions[ReportDimension.AGENCY][0]) {
            this.addFilter(ReportDimension.AGENCY, this.filterOptions[ReportDimension.AGENCY][0].value);
            this.updateSearchPath(true);
          }
        }
        if (this.dimension === ReportDimension.RETAIL && !this.filter[ReportDimension.RETAIL]) {
          await this.fetchFilterOptions(ReportDimension.RETAIL);
        }
        if (await this.hasInvalidFilters()) {
          this.updateSearchPath(true);
        }
        const cacheKey = this.isDateDimension(this.dimension) ? ReportDimension.DAY : this.dimension;
        const useCache = this.cachedReportQuery[cacheKey] && this.cachedReportQuery[cacheKey].searchPath === this.searchPath;
        if (useCache) {
          this.reportData = this.cachedReportQuery[cacheKey].result;
        } else {
          const from = this.getDateWithTimezone(this.from);
          const to = this.getDateWithTimezone(this.to);
          this.reportData = await this.reportManager.getReportData(this.reportType, this.dimension, this.gran, _.omitBy(this.filter, _.isUndefined), from, to);
          if (this.isDateDimension(this.dimension)) {
            this.fillDateRecords(this.reportData);
          }
          this.cachedReportQuery[cacheKey] = {
            searchPath: this.searchPath,
            result: this.reportData
          };
        }
      } catch (e) {
        this.reportData = defaultReportData;
      }
    }
    this.tableData = this.reportData ? this.dataProvider.getReportTableData(this.reportData.records) : [];
    if (this.dimension === ReportDimension.SALES_CHANNEL) {
      this.tableData = _.map(this.tableData, (data) => ({
        ...data,
        name: i18n.t(`campaignGroup.labels.channel_${data.name.toLowerCase()}`)
      }));
    }
    this.reportDimensions = this.dataProvider.getReportDimensions(this.actor);
    if (
      !(ReportDimension.GO_CAMPAIGN in this.filter) &&
      !(ReportDimension.GO_CAMPAIGN_GROUP in this.filter) &&
      !(ReportDimension.BINDING in this.filter)
    ) {
      this.reportData && _.remove(this.reportData.allowMetrics, metric => metric === Metrics.UU);
    }
    this.reportData && _.remove(this.reportData.allowMetrics, metric => metric === Metrics.VIEWABLE || metric === Metrics.CONVS);
    this.updateFilteredRecords();
  }

  dateTimeShortCut = (): DateRange[] => {
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';
    return [
      {
        label: i18n.t('daypick.labels.today'),
        dateRange: [new Date(moment().startOf('day').format(dateFormat)), new Date(moment().endOf('day').format(dateFormat))]
      }, {
        label: i18n.t('daypick.labels.yesterday'),
        dateRange: [new Date(moment().subtract(1, 'day').startOf('day').format(dateFormat)), new Date(moment().subtract(1, 'day').endOf('day').format(dateFormat))]
      }, {
        label: i18n.t('daypick.labels.thisWeek'),
        dateRange: [new Date(moment().startOf('week').format(dateFormat)), new Date(moment().endOf('day').format(dateFormat))]
      }, {
        label: i18n.t('daypick.labels.thisMonth'),
        dateRange: [new Date(moment().startOf('month').format(dateFormat)), new Date(moment().endOf('day').format(dateFormat))]
      }, {
        label: i18n.t('daypick.labels.last7Days'),
        dateRange: [new Date(moment().subtract(1, 'week').startOf('day').format(dateFormat)), new Date(moment().subtract(1, 'day').endOf('day').format(dateFormat))]
      }, {
        label: i18n.t('daypick.labels.last30Days'),
        dateRange: [new Date(moment().subtract(30, 'day').startOf('day').format(dateFormat)), new Date(moment().subtract(1, 'day').endOf('day').format(dateFormat))]
      }, {
        label: i18n.t('daypick.labels.lastWeek'),
        dateRange: [new Date(moment().startOf('week').subtract(1, 'week').startOf('day').format(dateFormat)), new Date(moment().startOf('week').subtract(1, 'day').endOf('day').format(dateFormat))]
      }, {
        label: i18n.t('daypick.labels.lastMonth'),
        dateRange: [new Date(moment().startOf('month').subtract(1, 'month').startOf('day').format(dateFormat)), new Date(moment().startOf('month').subtract(1, 'day').endOf('day').format(dateFormat))]
      }
    ];
  }

  fillDateRecords = (reportData: ReportData) => {
    const dataFromServer = {};
    const dataFinal = {};
    let dateFormat = 'YYYY-MM-DD';
    if (this.dimension === ReportDimension.MONTH) {
      dateFormat = 'YYYY-MM';
    } else if (this.dimension === ReportDimension.HOUR) {
      dateFormat = 'YYYY-MM-DD HH';
    }
    reportData.records.forEach(data => {
      const dimensionName = moment(data.dimensionName).format(dateFormat);
      dataFromServer[dimensionName] = {
        ...data,
        dimensionName
      };
    });
    const start = moment(this.from).startOf(this.dimension);
    const to = moment(this.to).endOf(this.dimension);
    let current = start;
    while (current.isBefore(to)) {
      const dimensionName = current.format(dateFormat);
      if (dimensionName in dataFromServer) {
        dataFinal[dimensionName] = dataFromServer[dimensionName];
      } else {
        dataFinal[dimensionName] = { ...emptyReportRecord(dimensionName) };
      }
      current = current.add(1, this.dimension);
    }
    reportData.records = _.values(dataFinal);
    reportData.records = reportData.records.reverse();
  }

  setUpReportType (typeParam) {
    const reportTypeOption = _.find(this.reportTypes, reportTypeOption => reportTypeOption.value === typeParam);
    if (reportTypeOption) {
      this.reportType = reportTypeOption.value;
    } else {
      this.reportType = this.defaultReportType;
    }
  }

  setUpDimension (dimensionParam) {
    if (this.validDimensions.indexOf(dimensionParam) === -1) {
      this.dimension = this.defaultReportDimension;
      return;
    }
    this.dimension = dimensionParam;
  }

  setUpGran (granParam) {
    const granOption = _.find(this.reportGrans, granOption => granOption.value === granParam);
    if (granOption) {
      this.gran = granOption.value;
    } else {
      this.gran = this.defaultReportGran;
    }
    if (this.gran === ReportGran.DAY && this.dimension === ReportDimension.HOUR) {
      this.setUpTimezone(this.localeMeta ? this.localeMeta.timezone : '+07:00');
    }
    // dimension should same with gran
    if (this.isDateDimension(this.dimension)) {
      this.setUpDimension(this.gran);
    }
    this.setUpDayRange(this.from, this.to);
  }

  setUpTimezone (timezoneParam) {
    const actorTimezone = this.localeMeta ? this.localeMeta.timezone : '+07:00';
    const useActorTimezone =
      this.gran !== ReportGran.HOUR ||
      !this.isSysAdmin ||
      !(timezoneParam in MOMENT_TIMEZONE);
    if (useActorTimezone) {
      this.timezone = actorTimezone;
      return;
    }
    this.timezone = timezoneParam;
  }

  setUpDayRange (from, to) {
    if (from) {
      this.from = moment(from).startOf(this.gran).format('YYYY-MM-DD HH:mm:ss');
    }
    if (to) {
      this.to = moment(to).endOf(this.gran).format('YYYY-MM-DD HH:mm:ss');
    }
    if (this.gran === ReportGran.MONTH) {
      this.dayRangeError = undefined;
      return;
    }
    let start = new Date(this.from);
    let end = new Date(this.to);
    const diffTime = Math.abs(end.getTime() - start.getTime());
    if (this.gran === ReportGran.DAY) {
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      this.dayRangeError = diffDays > MAX_QUERY_DAYS ? i18n.t('campaign.descriptions.moreThanDays', { days: MAX_QUERY_DAYS }) : undefined;
    } else {
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60));
      this.dayRangeError = diffDays > MAX_QUERY_HOURS ? i18n.t('campaign.descriptions.moreThanHours', { hours: MAX_QUERY_HOURS }) : undefined;
    }
  }

  setUpFilter (filterParams) {
    const filterTypes = this.state.tableDimensions.filter(dimension => dimension !== ReportDimension.DAY);
    this.filter = {};
    Object.keys(filterParams).forEach(key => {
      if (filterTypes.indexOf(key) !== -1) {
        this.filter[key] = filterParams[key];
      }
    });
  }

  async hasInvalidFilters () {
    let hasInvalidFilters = false;
    const filterTypes = Object.keys(this.filter);
    for (let type of filterTypes) {
      hasInvalidFilters = hasInvalidFilters || await this.isFilterInvalid(type);
    }
    return hasInvalidFilters;
  }

  async isFilterInvalid (filterType) {
    if (!(filterType in this.filterOptions)) {
      await this.fetchFilterOptions(filterType);
    }
    const isFilterValueInOptions = (filterValue) => {
      return this.filterOptions[filterType].find(option => {
        if (option.value.toString() === filterValue) {
          return true;
        }
        if (option.options) {
          return option.options.find(subOption => subOption.value.toString() === filterValue) !== undefined;
        }
        return false;
      }) === undefined;
    };
    if (this.filter[filterType] !== undefined &&
      isFilterValueInOptions(this.filter[filterType])
    ) {
      delete this.filter[filterType];
      return true;
    }
    return false;
  }

  getDateWithTimezone (time) {
    const momentTimezone = MOMENT_TIMEZONE[this.timezone] ? MOMENT_TIMEZONE[this.timezone] : MOMENT_TIMEZONE['+07:00'];
    return moment.tz(time, momentTimezone).format();
  }

  updateReportData (params) {
    const reportTypeParam = params.get('type');
    this.setUpReportType(reportTypeParam);
    params.delete('type');
    const dimensionParam = params.get('dimension');
    this.setUpDimension(dimensionParam);
    params.delete('dimension');
    const granParam = params.get('gran');
    this.setUpGran(granParam);
    params.delete('gran');
    const fromParam = params.get('from');
    const toParam = params.get('to');
    this.setUpDayRange(fromParam, toParam);
    params.delete('from');
    params.delete('to');
    const timezoneParam = params.get('timezone');
    this.setUpTimezone(timezoneParam);
    params.delete('timezone');
    const filterParams = {};
    params.forEach((value, key) => {
      filterParams[key] = value;
    });
    this.setUpFilter(filterParams);
    this.dataProvider = getDataProvider(this.reportType, this.queryDataWithFilter, this.onDateClick, this.onEditClick);
    const needRefreshUrl =
      this.reportType !== reportTypeParam ||
      this.dimension !== dimensionParam ||
      this.gran !== granParam ||
      this.from !== fromParam ||
      this.to !== toParam ||
      this.timezone !== timezoneParam ||
      _.xor(Object.keys(this.filter), Object.keys(filterParams)).length > 0;
    needRefreshUrl ? this.updateSearchPath(true) : this.getReportData();
  }

  addEmptyFilter = async (filterType: string) => {
    this.filter = {
      ...this.filter,
      [filterType]: undefined
    };
    this.updateState(true);
    if (!(filterType in this.filterOptions)) {
      await this.fetchFilterOptions(filterType);
    }
    this.updateState(false);
  }

  removeFilter = (filterName: string) => {
    delete this.filter[filterName];
    this.filter = {
      ...this.filter
    };
    this.updateState(false);
  }

  handleOnSearch = (searchString: string) => {
    this.searchString = searchString;
    if (this.searchString === '') {
      this.debouncedUpdateFilteredRecords && this.debouncedUpdateFilteredRecords.cancel();
      this.updateFilteredRecords();
    } else {
      this.debouncedUpdateFilteredRecords();
    }
  }

  handleOnTagFilterClicked = (tag: string) => {
    if (_.indexOf(this.selectedTagFilter, tag) === -1) {
      this.selectedTagFilter.push(tag);
    } else {
      _.remove(this.selectedTagFilter, tagFilter => tagFilter === tag);
    }
    this.updateFilteredRecords();
  }

  updateFilteredRecords () {
    if (!this.loading) {
      this.updateState(true);
    }
    _.defer(() => {
      this.filteredRecords = _.filter(this.tableData,
        record => {
          const recordName = _.get(record, 'name', '');
          const nameIsMatch = recordName.toLowerCase().includes(this.searchString.toLowerCase());
          const tagsIsMatch = this.selectedTagFilter.length === 0 || _.intersection(record.tags, this.selectedTagFilter).length > 0;
          return nameIsMatch && (this.dimension !== ReportDimension.CAMPAIGN || tagsIsMatch);
        }
      );
      const currency = _.get(this.reportData, 'currency', Currency.NTD);
      this.tableColumnSettings = this.reportData ? this.dataProvider.getReportTableColumnSettings(this.reportData.allowMetrics, this.filteredRecords, this.dimension, currency) : [];
      this.updateState(false);
    });
  }

  updateState (loading: boolean) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}

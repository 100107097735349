import _ from 'lodash';
import { CreativeListDataProvider } from './CreativeListDataProvider';
import { CreativeListCommonColumns, commonColumnSetting, getColumnSetting, getCommonColumnData } from './CreativeListCommonColumnData';
import { renderColumn } from 'components/TableColumn';
import { getCreativeListFormatters } from './CreativeListFormatters';
import { CreativeListRecord } from 'core/creative/Creative';

enum COLUMNS {
  SKIP_SETTING = 'skipSetting',
  STREAMING_SETTING = 'streamingSetting'
}

export class VideoCreativeListDataProvider implements CreativeListDataProvider {

  formatters: any;
  constructor (model) {
    this.formatters = getCreativeListFormatters(model);
  }

  getColumns () {
    return [
      renderColumn(commonColumnSetting[CreativeListCommonColumns.NAME_ID], this.formatters.nameIdFormatter, this.formatters.nameIdHeaderFormatter),
      renderColumn(commonColumnSetting[CreativeListCommonColumns.PREVIEW], this.formatters.previewFormatter),
      renderColumn(commonColumnSetting[CreativeListCommonColumns.APPROVAL], this.formatters.approvalFormatter),
      renderColumn(commonColumnSetting[CreativeListCommonColumns.LANDING_URL], this.formatters.urlFormatter),
      renderColumn(getColumnSetting(COLUMNS.STREAMING_SETTING), this.formatters.streamingFormatter),
      renderColumn(getColumnSetting(COLUMNS.SKIP_SETTING), this.formatters.skipSettingFormatter),
      renderColumn(commonColumnSetting[CreativeListCommonColumns.BIND_STATE], this.formatters.bindStateFormatter),
      renderColumn(commonColumnSetting[CreativeListCommonColumns.LIMITATION], this.formatters.limitationFormatter),
      renderColumn(commonColumnSetting[CreativeListCommonColumns.EDITBTNS], this.formatters.floatingEditBtnsFormatter)
    ];
  }

  getViewModel (creative: CreativeListRecord) {
    return {
      ...getCommonColumnData(creative),
      [COLUMNS.STREAMING_SETTING]: {
        inStream: _.get(creative, 'creativeValues.inStream', false),
        outStream: _.get(creative, 'creativeValues.outStream', false)
      },
      [COLUMNS.SKIP_SETTING]: {
        skippable: _.get(creative, 'creativeValues.skippable', false),
        nonSkippable: _.get(creative, 'creativeValues.nonSkippable', false),
        skipOffset: _.get(creative, 'creativeValues.skipOffset', 0)
      }
    };
  }
}

import React, { Fragment } from 'react';
import { Redirect, Switch, Route, RouteComponentProps } from 'react-router-dom';
import styles from './finalReportPage.module.scss';
import classNames from 'classnames/bind';
import _ from 'lodash';
import { AuthenticationManager } from 'core';
import { alertMessage } from 'components/AlertDialog';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import RouteWithPermission from 'components/RouteWithPermission/RouteWithPermission';
import { hasFuncs } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';
import { ROUTE_PATH } from 'enum/RoutePath';
import { useFinalReportPageModel } from './FinalReportPageModel';
import { FinalReportProjectType } from 'core/order/Order';
import {
  Performance,
  ReportSection,
  NoteSection,
  Column,
  Group,
  Platform,
  LABEL,
  LOGO,
  NOTES,
  REPORT_GROUPS,
  COLUMNS_TEMPLATE,
  DEEP_DIVE_PLATFORM_PRIORITY
} from 'enum/FinalReport';
import {
  handleNumberOrText,
  preprocessReportsData,
  isTableHasOverFlown
} from 'utils/FinalReportUtil';
import {
  ReportHeader,
  OverallBlock,
  TableBlock,
  ChartBlock,
  ImpactsBlock,
  AudienceBlock,
  Notes
} from 'components/common/FinalReport';

const cssClassNames = classNames.bind(styles);

export const FinalReportPage: React.FC<RouteComponentProps<{}> & {
  authenticationManager: AuthenticationManager
}> = ({
  match,
  location,
  authenticationManager
}) => {

  const {
    loading,
    data,
    reportId,
    modalData,
    redirectPath
  } = useFinalReportPageModel(location.search, location.pathname, authenticationManager.logined);

  const renderModalPage = () => {
    return (
      <Fragment>
        {modalData && alertMessage(modalData.title, modalData.message, modalData.onConfirm)}
      </Fragment>
    );
  };

  const renderPageContent = () => {
    const advertiser = _.get(data, 'advertiser', '');
    const advertiserType = _.get(data, 'advertiser_type', '');
    const settings = _.get(data, 'settings', []);
    const currency = _.get(data, 'currency', 'IDR');
    const mainImpacts = _.get(data, 'main_impacts', []);
    const subImpacts = _.get(data, 'sub_impacts', []);
    const usersByRegion = _.get(data, 'users_by_region', []).filter(element => !_.isEmpty(element));
    const benchmarks = _.get(data, 'benchmarks', []);

    return (
      <Fragment>
        {data &&
          <div className={styles.reportMain}>
            {/* Page 1 */}
            <Fragment>
              <ReportHeader
                advertiser={advertiser}
                settings={settings}
                currency={currency}
              />

              <div className={styles.reportContent}>
                <div className={styles.title}>
                  {`Ads Performance`}
                </div>

                <OverallBlock data={data} />

                {_.map(REPORT_GROUPS[Performance.OVERALL][ReportSection.PLATFORM], (group, index) => {
                  const {
                    columns,
                    rows
                  } = preprocessReportsData(data, `overall_reports.${group}`, group, COLUMNS_TEMPLATE[Performance.OVERALL][ReportSection.PLATFORM]);

                  return (
                    <TableBlock
                      key={index}
                      title='Reporting By Platform'
                      columns={columns}
                      rows={rows}
                      group={group}
                      sortKey={Column.IMPRESSIONS}
                      decimals={2}
                      currency={currency}
                      customColumns={[
                        {
                          column: Group.PLATFORM,
                          content: (value) => (
                            LOGO[value]
                            ? value === Platform.FB
                              ? <>
                                  <div className={styles.hint}>{`＊`}</div>
                                  <div className={styles.rowDirection}>
                                    <img src={LOGO[value]['src']} alt='platform-logo-png' className={cssClassNames(`platformLogo_${LOGO[value]['size']}`)} />
                                  </div>
                                </>
                              : <div className={styles.rowDirection}>
                                  <img src={LOGO[value]['src']} alt='platform-logo-png' className={cssClassNames(`platformLogo_${LOGO[value]['size']}`)} />
                                  <span>{LOGO[value]['size'] === 'small' ? handleNumberOrText(LABEL[value]) : null}</span>
                                </div>
                            : handleNumberOrText(value)
                          ),
                          withHint: (value) => value === Platform.FB
                        }
                      ]}
                      hasOverFlown={isTableHasOverFlown(rows, group)}
                    />
                  );
                })}

                <Notes
                  noteList={NOTES[Performance.OVERALL][NoteSection.PLATFORM]}
                  benchmarks={benchmarks}
                />

                <ChartBlock
                  data={data}
                />
              </div>
            </Fragment>

            {/* Page 2 & 3 */}
            {advertiserType !== FinalReportProjectType.OTHERS &&
              <Fragment>
                <ReportHeader
                  advertiser={advertiser}
                  settings={settings}
                  currency={currency}
                />

                <div className={styles.reportContent}>
                  <div className={styles.title}>
                    {`Ads Impact`}
                  </div>

                  <ImpactsBlock
                    mainImpacts={mainImpacts}
                    subImpacts={subImpacts}
                  />

                  <Notes
                    noteList={NOTES[Performance.OVERALL][NoteSection.ADS_IMPACT]}
                  />
                </div>

                <ReportHeader
                  advertiser={advertiser}
                  settings={settings}
                  currency={currency}
                />

                <div className={styles.reportContent}>
                  <div className={styles.title}>
                    {`Audience Deep Dive: `}
                  </div>
                  <div className={cssClassNames('subtitle', 'adsImpactBg')}>
                    {`Ads Impact`}
                  </div>

                  <AudienceBlock
                    usersByRegion={usersByRegion}
                  />

                  <Notes
                    noteList={NOTES[Performance.DEEP_DIVE][NoteSection.ADS_IMPACT_USERS]}
                  />

                  {_.map(REPORT_GROUPS[Performance.DEEP_DIVE][ReportSection.USERS], (group, index) => {
                    const {
                      columns,
                      rows
                    } = preprocessReportsData(data, `users_reports.${group}`, group, COLUMNS_TEMPLATE[Performance.DEEP_DIVE][ReportSection.USERS]);

                    return (
                      <TableBlock
                        key={index}
                        title={`By ${LABEL[group]}`}
                        columns={columns}
                        rows={rows}
                        group={group}
                        sortKey={Column.SALES}
                        decimals={2}
                        currency={currency}
                        hasOverFlown={isTableHasOverFlown(rows, group)}
                      />
                    );
                  })}

                  <Notes noteList={NOTES[Performance.DEEP_DIVE][NoteSection.USERS]} />
                </div>
              </Fragment>
            }

            {/* Page 4 ~ */}
            {_.map(
              _.get(data, 'platform_info', [])
              .sort((key1, key2) => DEEP_DIVE_PLATFORM_PRIORITY.indexOf(key1['name']) > DEEP_DIVE_PLATFORM_PRIORITY.indexOf(key2['name']) ? 1 : -1),
              (platform, index) => (
              <Fragment key={index}>
                <ReportHeader
                  advertiser={advertiser}
                  settings={settings}
                  currency={currency}
                />

                <div className={styles.reportContent}>
                  <div className={styles.title}>
                    {`Audience Deep Dive:`}
                  </div>
                  <div className={cssClassNames('subtitle', 'adsPerformanceBg')}>
                    {`Ads Performance`}
                  </div>

                  <div className={styles.deepDivePlatformTitle}>
                    {'Audience Deep Dive by Platform - '}
                    <img src={LOGO[platform['name']]['src']} alt='platform-logo' />
                    {platform['name'] === Platform.FB && <img src={LOGO[Platform.IG]['src']} alt='platform-logo' />}
                  </div>

                  {_.map(REPORT_GROUPS[Performance.DEEP_DIVE][ReportSection.PLATFORM][platform['name']], (group, _index) => {

                    const notAvailableColumns: string[] = platform['name'] === Platform.TIKTOK && (group !== Group.CREATIVE && group !== Group.AUDIENCE_SEGMENT) ? [Column.REACH] : [];
                    const title = (platform['name'] === Platform.FB && group === Group.PLATFORM) ? `By Facebook ${LABEL[group]}` : `By ${LABEL[group]}`;
                    const {
                      columns,
                      rows
                    } = preprocessReportsData(platform, `reports.${group}`, group, COLUMNS_TEMPLATE[Performance.DEEP_DIVE][ReportSection.PLATFORM], notAvailableColumns);
                    const tableBlockProps = {
                      title,
                      columns,
                      rows,
                      group,
                      sortKey: Column.IMPRESSIONS,
                      decimals: 2,
                      currency,
                      hasOverFlown: isTableHasOverFlown(rows, group),
                      customColumns: platform['name'] === Platform.FB ? [
                        {
                          column: Group.PLATFORM,
                          content: (value) => (
                            LOGO[value]
                            ?
                              <div className={styles.rowDirection}>
                                <img src={LOGO[value]['src']} alt='platform-logo-png' className={cssClassNames(`platformLogo_${LOGO[value]['size']}`)} />
                                <span>{LOGO[value]['size'] === 'small' ? handleNumberOrText(LABEL[value]) : null}</span>
                              </div>
                            : handleNumberOrText(value)
                          ),
                          withHint: () => false
                        }
                      ] : []
                    };

                    return (
                      <TableBlock
                        key={_index}
                        {...tableBlockProps}
                      />
                    );
                  })}

                  <Notes
                    noteList={NOTES[Performance.DEEP_DIVE][NoteSection.PLATFORM][platform['name']]}
                    benchmarks={benchmarks.filter(benchmark => _.get(benchmark, 'channel', '') === platform['name'])}
                  />
                </div>
              </Fragment>
            ))}

            <div className={styles.timestamp}>
              <span>{`Created Time: ${_.get(data, 'create_time', '')}`}</span>
              <span>{reportId}</span>
            </div>
          </div>
        }
      </Fragment>
    );
  };

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <Fragment>
      {renderModalPage()}
      {redirectPath && <Redirect to={redirectPath} />}
      {authenticationManager.logined &&
        <Switch>
          <Route path={`*/${ROUTE_PATH.ERROR403}`} render={renderModalPage} />
          <RouteWithPermission
            path={`${match.url}`}
            permissionAware={hasFuncs(Permission.ORDER_READ)}
            redirectPath={`${match.url}/${ROUTE_PATH.ERROR403}`}
            render={renderPageContent}
          />
        </Switch>
      }
    </Fragment>
  );
};

import {
  DoctorWebService,
  RestfulDoctorWebService
} from 'ws/DoctorWebService';
import { DoctorRecords } from './DoctorData';

export interface DoctorManager {
  getDoctorRecords (campaignId: string | number, creativeId: string | number): Promise<DoctorRecords>;
}

export class DefaultDoctorManager implements DoctorManager {
  webService: DoctorWebService;

  constructor (
    webService: DoctorWebService = new RestfulDoctorWebService()
  ) {
    this.webService = webService;
  }

  async getDoctorRecords (campaignId: string | number, creativeId: string | number): Promise<DoctorRecords> {
    return this.webService.getDoctorRecords(campaignId, creativeId);
  }
}

import React from 'react';
import { Link } from 'react-router-dom';

import i18n from 'i18n';

import { AgencyListProps } from './AgencyListModel';
import { renderColumn } from 'components/TableColumn';
import styles from './agencyList.module.scss';
import { StickableBootstrapTable } from 'components/common/Table/StickableBootstrapTable';
import TableRowToolBar from 'containers/TableRowToolBar/TableRowToolBar';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { hasRoles } from 'core/permission/PermissionDSL';
import { RoleNames } from 'core';

function nameFormatter (cell: any, row: any): any {
  return (
    <div className={styles.nameCell}>
      <Link to={`/agencies/${cell}`} className={styles.name}>{row.name}</Link>
      <div className={styles.agencyId}>
        {`ID: ${cell}`}
      </div>
    </div>
  );
}

function floatingEditBtnsFormatter (cell: any, row: any): any {
  return (
    <TableRowToolBar className={styles.floatingEditArea}>
      <PermissionChecker permissionAware={hasRoles(RoleNames.sysAdmin)}>
        <Link
          to={`/agencies/${row.id}/edit`}
        >
          <IconWithTooltip
            icon={faPencilAlt}
            tooltipProps={{
              id: `agencyListEditTip-${row.id}`,
              tooltip: i18n.t('agencyList.labels.editHint')
            }}
          />
        </Link>
      </PermissionChecker>
    </TableRowToolBar>
  );
}

export class AgencyList extends React.Component<AgencyListProps> {

  render () {
    const model = this.props.model;
    const columns = [
      renderColumn(model.nameColumn, nameFormatter),
      // renderColumn(model.loginTitleColumn),
      // renderColumn(model.loginDomainColumn),
      // renderColumn(model.oemAgencyColumn, checkedFormatter),
      renderColumn(model.currencyColumn),
      renderColumn(model.timeZoneColumn),
      renderColumn(model.editBtnsColumn, floatingEditBtnsFormatter)
    ];
    return (
      <StickableBootstrapTable
        stickLastColumn
        keyField='id'
        data={model.agencies}
        columns={columns}
        defaultSorted={model.defaultSorts}
        noDataIndication={i18n.t(model.noDataDescription)}
      />
    );
  }
}

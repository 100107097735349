import React from 'react';
import _ from 'lodash';
import styles from './ppsCreativeDetail.module.scss';
import { CreativeBasic } from 'core/creative/Creative';
import cx from 'classnames/bind';

export const PPSCreativeDetail: React.FunctionComponent<{creative: Partial<CreativeBasic>}> = (props) => {
  const creativeValue = _.get(props.creative, 'creativeValue', _.get(props.creative, 'creativeValues'));
  if (!creativeValue) {
    return <div/>;
  }
  const htmlSnippet = _.get(creativeValue, 'htmlSnippet', '');
  if (!htmlSnippet) {
    return <div/>;
  }
  const regexp = /classes":\s*(\[.*?\])/;
  const matches = htmlSnippet.match(regexp);
  let isMobile = false;
  if (matches && matches[1] && matches[1].includes('"mobile"')) {
    isMobile = true;
  }
  const classNames = cx.bind(styles);
  const containerClass = classNames('ppsCreativeDetailContainer', {
    isMobile
  });
  const regularExpression = /"%%CLICK_URL_UNESC%%(.+?)"/g;
  const clickMacroMatch = htmlSnippet.matchAll(regularExpression);
  let htmlContent = htmlSnippet;
  for (const match of clickMacroMatch) {
    if (match[1]) {
      htmlContent = htmlContent.replace(match[1], decodeURIComponent(match[1]));
    }
  }
  htmlContent = htmlContent.replace(/%%WIDTH%%/g, "'20vw'")
                          .replace(/%%HEIGHT%%/g, "'30vh'")
                          .replace(/%%CLICK_URL_UNESC%%/g, '')
                          .replace(/%%VAST_URL_UNESC%%/g, '')
                          .replace(/%%VIEWABLE_IMPRESSION_URL_UNESC%%/g, '')
                          .replace(/%%SSP_VIEWABLE_IMPRESSION_URL_UNESC%%/g, '')
                          .replace(/%%ADX_VIEWABLE_IMPRESSION_URL_UNESC%%/g, '');
  const iframeSandboxSetting = [
    'allow-scripts', 'allow-popups', 'allow-modals', 'allow-orientation-lock',
    'allow-pointer-lock', 'allow-popups-to-escape-sandbox', 'allow-presentation',
    'allow-top-navigation', 'allow-top-navigation-by-user-activation'
  ];
  return (
    <div className={containerClass}>
      <iframe
        srcDoc={htmlContent}
        title={'pps_preview'}
        frameBorder='0'
        sandbox={iframeSandboxSetting.join(' ')}
      />
    </div>
  );
};

import { CountryWebService, RestfulCountryWebService } from 'ws/CountryWebService';

export interface CountryManager {
  fetchPublisherCountry (): Promise<Array<string>>;
}

export class DefaultCountryManager implements CountryManager {
  countryWebService: CountryWebService;

  constructor (countryWebService: CountryWebService = new RestfulCountryWebService()) {
    this.countryWebService = countryWebService;
  }

  async fetchPublisherCountry (): Promise<Array<string>> {
    return this.countryWebService.getPublisherCountry();
  }
}

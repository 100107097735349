import React from 'react';
import { FormikField } from 'components/common/formik/FormikField';
import { Formik, Form } from 'formik';
import { ProductGroupFormData } from './ProductGroupFormModel';
import i18n from 'i18n';
import { GroupType } from 'core/limitation/ProductGroup';
import styles from './productGroupForm.module.scss';
import { Link, Redirect } from 'react-router-dom';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import FormGroup from 'components/common/FormGroup/FormGroup';
import { Button, Col } from 'react-bootstrap';
import _ from 'lodash';
import { Modal } from 'components/common/Modal/Modal';

export const ProductGroupForm: React.FC<{ useModel: any }> = (props) => {

  const modelData: ProductGroupFormData = props.useModel();
  if (!modelData.initProductGroup) {
    return <LoadingIndicator />;
  }

  const renderCustomModal = (groupType: GroupType, addGroupValues) => {
    const {
      hideCustomModal,
      validateModalData,
      modalData
    } = modelData;
    if (!modalData) {
      return <div />;
    }
    const onSubmit = (value) => {
      addGroupValues(value);
      hideCustomModal();
    };

    return (
      <Modal title={modalData.title} className={styles.productGroupCustomModal}>
        <Formik
          initialValues={modalData.value}
          enableReinitialize
          onSubmit={onSubmit}
          validate={_.partial(validateModalData, groupType)}
          validateOnBlur={false}
        >
          <Form>
            <div className={styles.modalContentArea}>
              {groupType === GroupType.ADSPACE ?
                <>
                  <FormikField.Input
                    direction={Col}
                    label={i18n.t('productGroupForm.labels.customSpaceName')}
                    name={`${GroupType.ADSPACE}.name`}
                  />
                  <FormikField.Input
                    direction={Col}
                    label={i18n.t('productGroupForm.labels.customSpaceId')}
                    name={`${GroupType.ADSPACE}.id`}
                  />
                </> :
                <FormikField.Tags
                  direction={Col}
                  name={`${GroupType.DOMAIN}`}
                  placeholder={i18n.t('productGroupForm.placeholders.customDomain')}
                />
              }
            </div>
            <div className={styles.modalButtonArea}>
              <Button variant='primary' size='sm' type='submit'>
                {i18n.t('common.buttons.confirm')}
              </Button>
              <Button variant='secondary' size='sm' onClick={hideCustomModal}>
                {i18n.t('common.buttons.cancel')}
              </Button>
            </div>
          </Form>
        </Formik>
      </Modal>
    );
  };

  return (
    <div className={styles.productGroupForm}>
      {modelData.loading && <LoadingIndicator />}
      {modelData.redirectPath && <Redirect to={modelData.redirectPath} />}
      <div className={styles.titleArea}>
        <div className={styles.title}>
          {modelData.title}
        </div>
      </div>
      <Formik
        initialValues={modelData.initProductGroup}
        enableReinitialize
        onSubmit={modelData.save}
        validate={modelData.validate}
        validateOnBlur={false}
      >
        {({ values, setFieldValue, isSubmitting, initialValues }) => {
          const currentGroupType = values.groupType;
          const resetGroupValues = (value) => {
            if (value === currentGroupType) {
              return;
            }
            setFieldValue('groupValues', value === initialValues.groupType ?
              initialValues.groupValues :
              []);
          };
          const addCustomGroupValue = (value) => {
            const groupValues = values.groupValues ? values.groupValues : [];
            if (currentGroupType === GroupType.ADSPACE) {
              setFieldValue('groupValues', _.uniqBy([...groupValues, { label: value[GroupType.ADSPACE].name, value: value[GroupType.ADSPACE].id }], 'value'));
            } else {
              const newOptions = (value[GroupType.DOMAIN].map(domain => ({ label: domain, value: domain })));
              setFieldValue('groupValues', _.uniqBy([...groupValues, ...newOptions], 'value'));
            }
          };
          return (
            <>
              {renderCustomModal(currentGroupType, addCustomGroupValue)}
              <Form>
                <div className={styles.formContent}>
                  <FormikField.Input
                    label={i18n.t('productGroupForm.labels.groupName')}
                    name='groupName'
                  />
                  <FormikField.Select
                    label={i18n.t('productGroupForm.labels.groupType')}
                    name='groupType'
                    simpleValue
                    options={modelData.productGroupTypeOptions}
                    onChange={resetGroupValues}
                    className={styles.selectField}
                  />
                  {
                    currentGroupType === GroupType.ADSPACE ?
                      <FormGroup label={i18n.t('productGroupForm.labels.adspace')} controlId='groupValues'>
                        <div className={styles.row}>
                          <FormikField.Select
                            name='groupValues'
                            isMulti
                            options={modelData.spaceOptions}
                            className={styles.selectField}
                            closeMenuOnSelect={false}
                          />
                          <Button variant='secondary' size='sm' onClick={_.partial(modelData.showCustomModal, currentGroupType)}>
                            {i18n.t('productGroupForm.buttons.customSpace')}
                          </Button>
                        </div>
                      </FormGroup> :
                      <FormGroup label={i18n.t('productGroupForm.labels.domain')} controlId='domain'>
                        <div className={styles.row}>
                          <FormikField.Select
                            name='groupValues'
                            isMulti
                            options={modelData.domainOptions}
                            className={styles.selectField}
                            closeMenuOnSelect={false}
                          />
                          <Button variant='secondary' size='sm' onClick={_.partial(modelData.showCustomModal, currentGroupType)}>
                            {i18n.t('productGroupForm.buttons.customDomain')}
                          </Button>
                        </div>
                      </FormGroup>
                  }
                  <FormikField.Select
                    label={i18n.t('productGroupForm.labels.agencyIds')}
                    name='agencyIds'
                    simpleValue
                    isMulti
                    options={modelData.agencyOptions}
                    className={styles.selectField}
                    closeMenuOnSelect={false}
                  />
                  <FormikField.TextArea
                    label={i18n.t('productGroupForm.labels.description')}
                    name='description'
                  />
                </div>
                <div className={styles.formButtons}>
                  <button
                    type='submit'
                    className='btn btn-primary btn-sm'
                    disabled={isSubmitting}
                  >
                    {i18n.t('common.buttons.submit')}
                  </button>
                  <Link
                    to={'/system/product-groups'}
                    className='btn btn-secondary btn-sm'
                  >
                    {i18n.t('common.buttons.cancel')}
                  </Link>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

import { useCallback, useEffect, useMemo, useState } from 'react';
import { AddonFeatureManager, AuthenticationManager } from 'core';
import DefaultCampaignGroupManager, { CampaignGroupManager } from 'core/campaignGroup/CampaignGroupManager';
import { useRouteMatch } from 'react-router';
import moment from 'moment';
import i18n from 'i18n';
import _ from 'lodash';
import { numberWithCommas } from 'utils/StringUtil';
import { DefaultReportManager, ReportManager } from 'core/report/ReportManager';
import { DefaultOrderManager, OrderManager } from 'core/order/OrderManager';
import { clientWithoutErrorHandler } from 'ws/RestClient';
import { RestfulOrderWebService } from 'ws/OrderWebService';
import { FinalReportCsvStatusItem, FinalReportStatus, FinalReportTableStatusItem } from 'core/finalReport/FinalReportStatus';
import { FinalReportProjectType, Order, State } from 'core/order/Order';
import { ReportType } from 'core/report/ReportData';
import { SelectOptions } from 'components/common/commonType';
import { useCallAPI } from 'hooks/useCallAPI';
import { formatPriceWithCurrency } from 'helper/CurrencyHelper';
import { AdvertiserRole } from 'core/companyMember/CompanyRole';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { getDayOfWeekLabelByValue } from 'components/Dayparts/Dayparts';
import { Permission } from 'core/auth/Permission';
import { toast } from 'react-toastify';
import { Project } from 'core/project/Project';

export type OrderDetailModelData = {
  campaignGroupList: any[],
  canSettle: boolean,
  settleTips: string,
  showDownloadFinalReport: boolean,
  fetchingFinalReport: boolean,
  finalReportStatus?: string,
  loading: boolean
  orderExternalTypes: SelectOptions[],
  order?: Order,
  viewData: any,
  showDetail: boolean,
  showDownloadReportModal: boolean,
  showApproveButton: boolean,
  showRejectButton: boolean,
  canEditOrder: boolean,
  showSettleButton: boolean,
  showDownloadReportButton: boolean;
  showGoVideoReportButton: boolean;
  toggleShowDetail: () => void,
  setShowDownloadReportModal: (show: boolean) => void,
  settleOrder: () => Promise<void>;
  rejectOrder: () => Promise<void>;
  approveOrder: () => Promise<void>;
  getReportLink: (type: ReportType) => string;
  refreshOrderDetail: () => Promise<void>,
  openFinalReport: () => Promise<void>
};

const defaultCampaignGroupManager = new DefaultCampaignGroupManager();
const defaultReportManager = new DefaultReportManager();
const defaultOrderManager = new DefaultOrderManager();
const orderManagerWithoutError = new DefaultOrderManager(new RestfulOrderWebService(clientWithoutErrorHandler));

const useDefaultOrderDetailModel = (
  orderNumber: string,
  localMetaDataUpdater: Function,
  authenticationManager: AuthenticationManager,
  addonFeatureManager: AddonFeatureManager,
  campaignGroupManager: CampaignGroupManager = defaultCampaignGroupManager,
  reportManager: ReportManager = defaultReportManager,
  orderManager: OrderManager = defaultOrderManager
): OrderDetailModelData => {

  const [showDownloadReportModal, setShowDownloadReportModal] = useState(false);
  const [showDetail, setShowDetail] = useState(true);
  const [orderExternalTypes, setOrderExternalTypes] = useState<SelectOptions[]>([]);
  const [viewData, setViewData] = useState<any>({});
  const [order, setOrder] = useState<Order | undefined>(undefined);
  const { loading, callAPIs, callAPIsBySequence } = useCallAPI();
  const [campaignGroupList, setCampaignGroupList] = useState<any[]>([]);
  const [fetchingFinalReport, setFetchingFinalReport] = useState(false);

  const getOrderState = useCallback((state) => {
    if (State.CHANGE_PENDING === state || State.NOT_APPROVE === state) {
      return i18n.t('orderDetail.labels.waitingForReview');
    }
    if (State.REJECT === state) {
      return i18n.t('orderDetail.labels.reject');
    }
    if (State.SETTLE === state) {
      return i18n.t('orderDetail.labels.settle');
    }
    if (State.SETTLED === state) {
      return i18n.t('orderDetail.labels.settled');
    }
    return i18n.t('orderDetail.labels.approve');
  }, []);

  const getRemainDayDesc = useCallback((startDate, endDate) => {
    const now = moment();
    const today = now.startOf('day');
    if (moment(startDate).isAfter(today)) {
      return i18n.t('orderDetail.labels.notstart');
    }

    if (moment(endDate).add(1, 'day').startOf('day').isBefore(now)) {
      return i18n.t('orderDetail.labels.finished');
    }

    if (moment(endDate).startOf('day').isSame(today)) {
      return i18n.t('orderDetail.labels.today');
    }

    return i18n.t('orderDetail.labels.days', { days: moment(endDate).diff(today, 'days') + 1 });
  }, []);

  const getDayPartValue = useCallback((dayPart) => {
    let dayPartValue = _.omitBy(_.omit(dayPart, 'enabled'), _.isEmpty);
    return Object.keys(dayPartValue).map(day => {
      return `${getDayOfWeekLabelByValue(parseInt(day, 10))},${i18n.t('daypart.labels.hourUnit')}: ${dayPartValue[day].join(', ')}`;
    }).join('\r\n');
  }, []);

  const genOrderViewData = useCallback((order: Order, orderExternalTypes: SelectOptions[]) => {
    let totalBudget: any;
    let remainBudget: any;
    let agencyProfit: any;
    const orderMargin = _.round(order.orderMargin * 100, 2);
    if (order.state === State.CHANGE_PENDING) {
      totalBudget = order.changeBudget ? {
        origin: i18n.t('orderDetail.labels.originBudget', { budget: formatPriceWithCurrency(order.currency, order.budget) }),
        change: i18n.t('orderDetail.labels.changeBudget', { budget: formatPriceWithCurrency(order.currency, order.changeBudget) })
      } : formatPriceWithCurrency(order.currency, order.budget);
      remainBudget = order.changeBudget ? {
        origin: i18n.t('orderDetail.labels.originBudget', { budget: formatPriceWithCurrency(order.currency, order.budgetBalance) }),
        change: i18n.t('orderDetail.labels.changeBudget', { budget: formatPriceWithCurrency(order.currency, order.budgetBalance + order.changeBudget - order.budget) })
      } : formatPriceWithCurrency(order.currency, order.budgetBalance);
      agencyProfit = order.changeOrderMargin ? {
        origin: i18n.t('orderDetail.labels.originProfit', { profit: orderMargin }),
        change: i18n.t('orderDetail.labels.changeProfit', { profit: _.round(order.changeOrderMargin * 100, 2) })
      } : `${orderMargin} %`;
    } else {
      totalBudget = formatPriceWithCurrency(order.currency, order.budget);
      remainBudget = formatPriceWithCurrency(order.currency, order.budgetBalance);
      agencyProfit = `${orderMargin} %`;
    }
    const currentActorType = _.get(authenticationManager.actor, 'actorType');
    const canCheckAgencyProfit = addonFeatureManager.isFeatureEnable(ADDONFEATURE.CAMPAIGN.ORDER_AGENCY_PROFIT_SETTING) &&
      ![AdvertiserRole.ROLE_ADV_ADMIN.toString(),
      AdvertiserRole.ROLE_ADV_REPORT.toString(),
      AdvertiserRole.ROLE_ADV_SALES.toString()].includes(`ROLE_${currentActorType}`);
    const orderExternalType = orderExternalTypes.find(typeOption => typeOption.value === order.externalType);
    const orderViewData: any = {
      id: order.id,
      name: order.projectName,
      startDate: order.startDate,
      endDate: order.endDate,
      basic: _.omitBy({
        orderNumber: order.orderNumber,
        externalId: _.isEmpty(order.externalId) ? i18n.t('common.labels.noData') : order.externalId,
        externalType: orderExternalType ? orderExternalType.label : i18n.t('common.labels.noData'),
        dateRange: `${order.startDate} ~ ${order.endDate}`,
        remainDays: getRemainDayDesc(order.startDate, order.endDate),
        totalBudget: totalBudget,
        alreadySpent: formatPriceWithCurrency(order.currency, order.spent),
        remainBudget: remainBudget,
        progress: order.budget === 0 ? `${0.00} %` : `${(order.spent * 100 / order.budget).toFixed(2)} %`,
        agencyProfit: canCheckAgencyProfit ? agencyProfit : undefined,
        status: getOrderState(order.state),
        modifyReason: order.state === State.CHANGE_PENDING ? order.modifyReason : undefined
      }, _.isUndefined),
      performance: {
        impres: numberWithCommas(order.impres),
        // viewable: numberWithCommas(order.viewable),
        clicks: numberWithCommas(order.clicks),
        ctr: `${order.impres > 0 ? (order.clicks * 100 / order.impres).toFixed(2) : 0}%`
      }
    };
    if (order.orderPrice && order.creativeDuration && order.dayPart) {
      orderViewData.outdoor = {
        orderPrice: formatPriceWithCurrency(order.currency, order.orderPrice),
        creativeDuration: numberWithCommas(order.creativeDuration) + i18n.t('common.units.seconds'),
        dayPart: getDayPartValue(order.dayPart)
      };
    }
    setViewData(orderViewData);
  }, [getOrderState, getRemainDayDesc, getDayPartValue, addonFeatureManager, authenticationManager.actor]);

  const match = useRouteMatch();

  const fetchOrderData = useCallback(async (matchIsExact) => {
    callAPIs([
      orderManager.getOrderExternalTypes.bind(orderManager),
      orderManager.getOrder.bind(orderManager, orderNumber)
    ], (orderExternalTypes, newOrder) => {
      callAPIs([
        (!matchIsExact) ? () => [] : campaignGroupManager.getCampaignGroups.bind(campaignGroupManager, newOrder.id)
      ], (campaignGroupList) => {
        setCampaignGroupList(campaignGroupList);
      });
      setOrderExternalTypes(orderExternalTypes);
      setOrder(newOrder);
      genOrderViewData(newOrder, orderExternalTypes);
    });
  }, [orderNumber, orderManager, genOrderViewData, callAPIs, campaignGroupManager]);

  useEffect(() => {
    (!match.isExact && order === undefined) && fetchOrderData(false);
  }, [order, fetchOrderData, match.isExact]);

  useEffect(() => {
    match.isExact && fetchOrderData(true);
  }, [fetchOrderData, match.isExact]);

  const showDownloadFinalReport = useMemo(() => {
    if (!order || !order.finalReportProjectName) {
      return false;
    }
    const canDownloadFinalReportDate = moment(order.startDate).startOf('day');
    if (moment().isAfter(canDownloadFinalReportDate)) {
      return true;
    }
    return false;
  }, [order]);

  const settleOrder = async () => {
    if (!order) {
      return;
    }

    callAPIsBySequence([
      orderManager.settleOrder.bind(orderManager, order.id),
      orderManager.getOrder.bind(orderManager, order.orderNumber)
    ], updatedOrder => {
      genOrderViewData(updatedOrder, orderExternalTypes);
      toast.success(i18n.t('orderDetail.labels.settleSuccess'));
    });
  };

  const approveOrder = async () => {
    if (!order) {
      return;
    }
    callAPIsBySequence([
      orderManager.approveOrder.bind(orderManager, order.id),
      orderManager.getOrder.bind(orderManager, order.orderNumber),
      async () => {
        await localMetaDataUpdater(authenticationManager.actor);
      }
    ], updatedOrder => {
      setOrder(updatedOrder);
      genOrderViewData(updatedOrder, orderExternalTypes);
      toast.success(i18n.t('orderDetail.labels.approveSuccess'));
    });
  };

  const rejectOrder = async () => {
    if (!order) {
      return;
    }
    callAPIsBySequence([
      orderManager.rejectOrder.bind(orderManager, order.id),
      orderManager.getOrder.bind(orderManager, order.orderNumber)
    ], updatedOrder => {
      setOrder(updatedOrder);
      genOrderViewData(updatedOrder, orderExternalTypes);
      toast.success(i18n.t('orderDetail.labels.rejectSuccess'));
    });
  };

  const toggleShowDetail = () => {
    setShowDetail(!showDetail);
  };

  const showGoVideoReportButton = useMemo((): boolean => {
    if (!order) {
      return false;
    }
    const isVideoAdEnabled = addonFeatureManager.isFeatureEnable(ADDONFEATURE.CREATIVES.VIDEO);
    const isThirdPartyAdEnabled = addonFeatureManager.isFeatureEnable(ADDONFEATURE.CREATIVES.THIRDPARTY);
    const isComboAdEnabled = addonFeatureManager.isFeatureEnable(ADDONFEATURE.CREATIVES.COMBO);
    const isOutdoorAdEnabled = addonFeatureManager.isFeatureEnable(ADDONFEATURE.CREATIVES.OUTDOOR);
    return isVideoAdEnabled || isThirdPartyAdEnabled || isComboAdEnabled || isOutdoorAdEnabled;
  }, [order, addonFeatureManager]);

  const showApproveButton = useMemo((): boolean => {
    if (!order) {
      return false;
    }

    return order.state === State.NOT_APPROVE || order.state === State.CHANGE_PENDING;
  }, [order]);

  const showRejectButton = useMemo((): boolean => {
    if (!order) {
      return false;
    }

    return order.state === State.NOT_APPROVE || order.state === State.CHANGE_PENDING;
  }, [order]);

  const canEditOrder = useMemo((): boolean => {
    if (!order || !authenticationManager.actor) {
      return false;
    }

    return order.state !== State.SETTLE &&
      authenticationManager.actor.permissions.indexOf(Permission.ORDER_WRITE) > -1;
  }, [order, authenticationManager.actor]);

  const showSettleButton = useMemo((): boolean => {
    const permissionAllowOrderSettlement = addonFeatureManager.isFeatureEnable(ADDONFEATURE.COMPANY.AGENCY_ALLOW_ORDER_SETTLEMENT);
    const stateAllowSettlement = !!order &&
      order.state !== State.CHANGE_PENDING &&
      order.state !== State.NOT_APPROVE;
    return permissionAllowOrderSettlement && stateAllowSettlement;
  }, [order, addonFeatureManager]);

  const getSettleTips = useCallback((hasAliveOrNotStartCampaign: boolean): string => {
    if (!order) {
      return '';
    }
    if (order.state === State.SETTLE) {
      return i18n.t('orderDetail.labels.settleWait');
    }

    if (order.state === State.SETTLED) {
      return i18n.t('orderDetail.labels.settledNoNeedSettle');
    }

    if (hasAliveOrNotStartCampaign) {
      return i18n.t('orderDetail.labels.hasRunningCampaignCannotSettle');
    }

    return '';
  }, [order]);

  const getCanSettle = useCallback((hasAliveOrNotStartCampaign: boolean): boolean => {
    if (!order) {
      return false;
    }
    if (order.state === State.SETTLED) {
      return false;
    }

    if (order.state === State.SETTLE) {
      return false;
    }
    return !hasAliveOrNotStartCampaign;
  }, [order]);

  const getReportLink = (type: ReportType): string => {
    if (!order) {
      return '/reports/performance';
    }
    const from = encodeURIComponent(moment(order.startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'));
    const to = encodeURIComponent(moment(order.endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss'));
    return `/reports/performance?type=${type}&dimension=adsOrderId&from=${from}&to=${to}&adsOrderId=${order.id}`;
  };

  const hasAliveOrNotStartCampaign = false;
  return {
    order,
    viewData: _.omitBy({
      ...viewData,
      finalReport: order && order.finalReportProjectType ? _.omitBy({
        finalReportAdvertiserName: order.finalReportAdvertiserName,
        finalReportProjectType: order.finalReportProjectType ? i18n.t(`finalReport.enums.${order.finalReportProjectType.toLowerCase()}`) : '',
        finalReportProjectName: order.finalReportProjectType === FinalReportProjectType.OTHERS ? undefined : order.finalReportProjectName,
        finalReportTargetImpressions: numberWithCommas(order.finalReportTargetImpressions),
        finalReportReceivers: order.finalReportReceivers ? order.finalReportReceivers.join(', ') : '',
        finalReportSendOutDate: moment(order.finalReportSendOutDate).format('YYYY-MM-DD')
      }, _.isUndefined) : undefined
    }, _.isUndefined),
    loading,
    showDetail,
    orderExternalTypes,
    showDownloadReportModal,
    showDownloadReportButton: false,
    showGoVideoReportButton,
    showApproveButton,
    showRejectButton,
    canEditOrder,
    showSettleButton,
    campaignGroupList,
    canSettle: getCanSettle(hasAliveOrNotStartCampaign),
    settleTips: getSettleTips(hasAliveOrNotStartCampaign),
    showDownloadFinalReport,
    fetchingFinalReport,
    settleOrder,
    rejectOrder,
    approveOrder,
    getReportLink,
    toggleShowDetail,
    refreshOrderDetail: () => fetchOrderData(true),
    setShowDownloadReportModal,
    openFinalReport: async () => {
      if (!order) {
        return;
      }
      const newTab = window.open(`/final-report?preparing`, '_blank');
      setFetchingFinalReport(true);
      try {
        const reportFileId = await reportManager.uploadFinalReportData(order.id);
        const reportId = _.get(reportFileId, 'status', '') === 'INITIAL' ? _.get(reportFileId, 'payload', '') : '';
        if (newTab) {
          newTab.location.href = `/final-report?id=${reportId}`;
        }
      } catch (e) {}
      setFetchingFinalReport(false);
    }
  };
};

function getFinalReportStatusDetail (order: Order, finalReportStatus: FinalReportStatus, finalReportProjectType?: FinalReportProjectType): string[] {
  const reasons: string[] = [];
  const audienceCsvStatus: FinalReportCsvStatusItem[] = _.get(finalReportStatus, 'csv.audience', []);
  const audienceTableStatus: FinalReportTableStatusItem[] = _.get(finalReportStatus, 'table.audience', []);
  const outletCsvStatus: FinalReportCsvStatusItem[] = _.get(finalReportStatus, 'csv.outlet', []);
  const outletTableStatus: FinalReportTableStatusItem = _.get(finalReportStatus, 'table.outlet');
  if (finalReportProjectType !== FinalReportProjectType.OTHERS) {
    switch (finalReportStatus.status) {
      case 'NOT_READY':
        if (audienceCsvStatus.length === 0) {
          reasons.push(i18n.t('orderDetail.labels.finalReport.audienceCsvNotReady'));
        } else if (audienceTableStatus.length === 0) {
          reasons.push(i18n.t('orderDetail.labels.finalReport.audienceTableNotReady'));
        }
        break;
      case 'PARTIAL':
        if (outletCsvStatus.length === 0) {
          reasons.push(i18n.t('orderDetail.labels.finalReport.outletCsvNotReady'));
        } else if (!outletTableStatus) {
          reasons.push(i18n.t('orderDetail.labels.finalReport.outletTableNotReady'));
        }
        if (_.get(finalReportStatus.transaction, 'status') !== 'READY') {
          reasons.push(i18n.t('orderDetail.labels.finalReport.onelinkCampaignNameNotTracked', { name: order.finalReportProjectName }));
        }
        break;
      default:
        break;
    }
  }
  if (moment().isBefore(moment(order.startDate).startOf('day')) && finalReportStatus.status === 'NOT_READY') {
    let today = moment().format('YYYY-MM-DD');
    let diffStart = moment(order.startDate, 'YYYY-MM-DD').diff(moment(today), 'days');
    reasons.push(i18n.t('orderDetail.labels.finalReport.notStart', { days: diffStart }));
  } else if (moment().isBefore(moment(order.endDate).endOf('day')) && ['READY', 'PARTIAL'].includes(finalReportStatus.status)) {
    reasons.push(i18n.t('orderDetail.labels.finalReport.notEnd'));
  }
  return reasons;
}

const useRetailOrderDetailModel = (
  orderNumber: string,
  localMetaDataUpdater: Function,
  authenticationManager: AuthenticationManager,
  addonFeatureManager: AddonFeatureManager
): OrderDetailModelData => {
  return useDefaultOrderDetailModel(
    orderNumber,
    localMetaDataUpdater,
    authenticationManager,
    addonFeatureManager
  );
};

const useGoGANOrderDetailModel = (
  orderNumber: string,
  localMetaDataUpdater: Function,
  authenticationManager: AuthenticationManager,
  addonFeatureManager: AddonFeatureManager,
  orderManager: OrderManager = defaultOrderManager
): OrderDetailModelData => {

  const {
    order,
    viewData,
    ...results
  } = useDefaultOrderDetailModel(
    orderNumber,
    localMetaDataUpdater,
    authenticationManager,
    addonFeatureManager
  );

  const match = useRouteMatch();
  const [finalReportStatus, setFinalReportStatus] = useState<FinalReportStatus | undefined>();

  useEffect(() => {
    const defaultFinalReportStatus = {
      status: 'NOT_READY',
      csv: {
        status: 'NOT_READY',
        audience: [],
        outlet: []
      },
      table: {
        status: 'NOT_READY',
        audience: []
      },
      transaction: {
        status: 'NOT_READY',
        totalTransactions: 0,
        totalSoldItems: 0
      }
    };
    const fetchFinalReportStatus = async () => {
      if (match.isExact && order && order.finalReportProjectType) {
        if (moment().isBefore(moment(order.startDate).startOf('day'))) {
          setFinalReportStatus(defaultFinalReportStatus);
        } else {
          try {
            const status = await orderManagerWithoutError.getFinalReportStatus(order.id);
            setFinalReportStatus(status);
          } catch (e) {
            setFinalReportStatus(defaultFinalReportStatus);
          }
        }
      } else {
        setFinalReportStatus(undefined);
      }
    };
    fetchFinalReportStatus();
  }, [order, orderManager, match.isExact]);

  return {
    order,
    viewData: _.omitBy({
      ...viewData,
      finalReport: order && viewData.finalReport ? _.omitBy({
        ...viewData.finalReport,
        finalReportStatus: finalReportStatus ? {
          value: i18n.t(`orderDetail.labels.finalReport.${_.camelCase(finalReportStatus.status)}`),
          extraInfo: getFinalReportStatusDetail(order, finalReportStatus, order.finalReportProjectType)
        } : i18n.t('orderDetail.placeholders.finalReportStatus')
      }, _.isUndefined) : undefined
    }, _.isUndefined),
    ...results
  };
};

export let useOrderDetailModel;
if (process.env.REACT_APP_PROJECT === Project.RETAIL) {
  useOrderDetailModel = useRetailOrderDetailModel;
} else {
  useOrderDetailModel = useGoGANOrderDetailModel;
}

import React from 'react';
import moment from 'moment';
import i18n from 'i18n';
import { ReportGran } from 'core/report/ReportData';
import { DateRangePicker } from 'components/common/DateRangePicker/DateRangePicker';
import FormGroup from 'components/common/FormGroup/FormGroup';
import ToggleButtons from 'components/common/ToggleButtons/ToggleButtons';
import Select from 'components/common/Select/Select';
import styles from './performanceReportDimension.module.scss';
import { ReportContentModel } from '../ReportContentModel';
import { Trans } from 'react-i18next';

export const PerformanceReportDimension: React.FC<{ model: ReportContentModel }> = ({ model }) => {

  function renderScheduling () {
    const endDateMoment = moment(model.state.to);
    const startDateMoment = moment(model.state.from);
    const days = endDateMoment.diff(startDateMoment, 'days');
    const hours = endDateMoment.diff(startDateMoment, 'hours');
    if (days > 0) {
      return (
        <Trans i18nKey='campaign.descriptions.campaignSchedulingDay'>
          Total <span className='text-dark'>{{ days: days + 1 }} days</span>
        </Trans>
      );
    } else if (days === 0) {
      return (
        <Trans i18nKey='campaign.descriptions.campaignSchedulingHour'>
          Total <span className='text-dark'>{{ hours: hours + 1 }} hours</span>
        </Trans>
      );
    }
  }

  return (
    <>
      <FormGroup label={i18n.t('report.labels.reportGran')} controlId='reportGran'>
        <div className={styles.reportGran}>
          <ToggleButtons
            name={'reportGran'}
            value={model.state.gran}
            options={model.reportGrans}
            onChange={model.updateGran}
          />
          <DateRangePicker
            startDate={model.state.from}
            endDate={model.state.to}
            format={model.state.gran === ReportGran.HOUR ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD'}
            onChange={model.updateDayRange}
            showTimePicker={model.state.gran === ReportGran.HOUR}
            shortcuts={model.dateTimeShortCut()}
          />
          {model.state.dayRangeError && <span className={styles.errorHint}>{model.state.dayRangeError}</span>}
          <span className={styles.hint}>{renderScheduling()}</span>
          { model.isSysAdmin && model.state.gran === ReportGran.HOUR &&
            <Select
              className={styles.timezoneSelection}
              options={model.reportTimezones}
              name='reportTimezones'
              simpleValue
              value={model.state.timezone}
              onChange={model.updateTimezone}
            />
          }
        </div>
      </FormGroup>
    </>
  );
};

import client from './RestClient';
import { ADGROUP_DEFAULT_AGE_MAX, ADGROUP_DEFAULT_AGE_MIN, TiktokAdGroup, TiktokAdGroupListRecord, TiktokGenderMapping } from 'core/tiktokAdGroup/TiktokAdGroup';
import { CampaignGroup } from 'core/campaignGroup/CampaignGroup';
import _ from 'lodash';
import { SelectOptions } from 'components/common/commonType';
import moment from 'moment';
import { createSelectOptions } from 'utils/SelectOptionsUtils';
import { getInitDaypart } from 'components/Dayparts/Dayparts';

export interface TiktokAdGroupWebService {
  getAdGroup (adGroupId: number | string): Promise<TiktokAdGroup>;
  getAdGroups (groupId: number | string): Promise<TiktokAdGroupListRecord[]>;
  getUniqueAdGroupNames (tiktokCampaignId: number | string): Promise<String[]>;
  createAdGroup (group: CampaignGroup, payload: any): Promise<void>;
  editAdGroup (tiktokAdGroupId: string | number, payload: any): Promise<void>;
  deleteAdGroup (adGroupId: number | string): Promise<void>;
  updateAdGroupState (adGroupData: {
    goCampaignChannelId: (number | string),
    isDraft: boolean
  }[], state: 'activate' | 'deactivate'): Promise<void>;
  getAdGroupOptions (): Promise<SelectOptions[]>;
  getParentInfo (campaignId: string | number): Promise<any>;
}

export const wrapAdGroupFromServer: any = (json: any) => {
  const removeTimezone = (time) => (
    moment(time.replace('T', ' ').split('+')[0]).format('YYYY-MM-DD HH:mm:ss')
  );

  const handleDayparting = (dayparting, dayPartSwitch) => {
    if (!dayparting || (dayPartSwitch === 'OFF' && dayparting === '1'.repeat(336))) {
      return undefined;
    } else if (dayparting === '0'.repeat(336) || dayparting === '1'.repeat(336)) {
      return {
        enabled: '1',
        ...getInitDaypart()
      };
    } else {
      let dayPart = {
        enabled: '1'
      };
      let days = dayparting.match(/.{1,48}/g);
      _.forEach(days, (day, dayIndex) => {
        let whichDay: number[] = [];
        let hours = day.match(/.{1,2}/g);
        _.forEach(hours, (hour, hourIndex) => {
          if (hour === '11') {
            whichDay = [
              ...whichDay,
              parseFloat(hourIndex)
            ];
          }
        });
        dayPart = {
          ...dayPart,
          [(parseFloat(dayIndex) + 1).toString()]: whichDay
        };
      });
      return dayPart;
    }
  };

  return {
    ..._.omit(json, ['ageMin', 'ageMax', 'gender', 'location', 'placement', 'operation_system', 'audience', 'excluded_audience', 'draft']),
    budget: json.go_lifetime_budget,
    bid: json.go_bid_amount,
    schedule_start_time: json.schedule_start_time ?
      removeTimezone(json.schedule_start_time) :
      json.schedule_start_time,
    schedule_end_time: json.schedule_end_time ?
      removeTimezone(json.schedule_end_time) :
      json.schedule_end_time,
    targeting: wrapTargetingFromServer(json),
    dayPart: handleDayparting(json.dayparting, json.dayPartSwitch),
    isDraft: json.draft
  };
};

const wrapTargetingFromServer = (json: any) => {
  const { ageMin, ageMax, gender, location, placement, operation_system, audience, excluded_audience } = json;

  const genderValue = TiktokGenderMapping[gender];
  const include = _.compact([
    location.length > 0 ? {
      type: 'tiktok_location',
      value: createSelectOptions(location)
    } : undefined,
    placement ? {
      type: 'tiktok_placement',
      value: createSelectOptions(placement)
    } : undefined,
    operation_system ? {
      type: 'tiktok_os',
      value: createSelectOptions(operation_system)
    } : undefined,
    genderValue !== undefined ? {
      type: 'genders',
      value: genderValue
    } : undefined,
    {
      type: 'age_min',
      value: ageMin ? ageMin : ADGROUP_DEFAULT_AGE_MIN
    },
    {
      type: 'age_max',
      value: ageMax ? ageMax : ADGROUP_DEFAULT_AGE_MAX
    },
    audience && audience.length > 0 ? {
      type: 'tiktok_audience',
      value: createSelectOptions(audience)
    } : undefined
  ]);

  const exclude = _.compact([
    excluded_audience && excluded_audience.length > 0 ? {
      type: 'tiktok_audience',
      value: createSelectOptions(excluded_audience)
    } : undefined
  ]);

  return {
    include,
    exclude
  };
};

export class RestfulTiktokAdGroupWebService implements TiktokAdGroupWebService {
  restClient: any;

  constructor (restClient: any = client) {
    this.restClient = restClient;
  }

  async getAdGroup (adGroupId: number | string): Promise<TiktokAdGroup> {
    const response = await this.restClient.get(`/v2/tiktok/adgroups/${adGroupId}`);
    return wrapAdGroupFromServer(response.data);
  }

  async getAdGroups (tiktokCampaignId: number | string): Promise<TiktokAdGroupListRecord[]> {
    const response = await this.restClient.get(`/v2/tiktok/campaigns/${tiktokCampaignId}/adgroups`);
    return _.get(response, 'data.records', []).map(record => wrapAdGroupFromServer(record));
  }

  async getUniqueAdGroupNames (tiktokCampaignId: string | number): Promise<String[]> {
    const response = await this.restClient.get(`/v2/tiktok/campaigns/${tiktokCampaignId}/adgroups/name`);
    return _.get(response, 'data.records', []);
  }

  async createAdGroup (group: CampaignGroup, payload: any): Promise<void> {
    const tiktokCampaignId = _.get(group, 'tiktok.campaign_id');
    if (!tiktokCampaignId) {
      return;
    }

    return this.restClient.post(`/v2/tiktok/campaigns/${tiktokCampaignId}/adgroups`, payload);
  }

  async editAdGroup (adGroupId: number | string, payload: any): Promise<void> {
    return this.restClient.put(`/v2/tiktok/adgroups/${adGroupId}`, payload);
  }

  async deleteAdGroup (adGroupId: number | string): Promise<void> {
    return this.restClient.delete(`/v2/tiktok/campaigns/${adGroupId}`);
  }

  async updateAdGroupState (adGroupData: {
    goCampaignChannelId: (number | string),
    isDraft: boolean
  }[], state: 'activate' | 'deactivate'): Promise<void> {
    await this.restClient.put(`/v2/tiktok/adgroups/status/${state}`, adGroupData);
  }

  async getAdGroupOptions (): Promise<SelectOptions[]> {
    const response = await this.restClient.get('/v2/tiktok/adgroups/options');
    return response.data.records;
  }

  async getParentInfo (campaignId: string | number): Promise<any> {
    const response = await this.restClient.get(`/v2/tiktok/campaigns/${campaignId}/parent-info`);
    return response.data;
  }
}

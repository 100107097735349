import { Creative, CreativeOfCampaign, CreativeType } from './Creative';
import { CreativesWithPagination, CreativeWebService, RestfulCreativeWebService, WaitingBindGetParams } from 'ws/CreativeWebService';
import { Pageable } from 'ws/Pageable';
import { SelectOptions } from 'components/common/commonType';
import { RtbCampaignWebService, RestfulCampaignWebService } from 'ws/RtbCampaignWebService';
import { Project } from 'core/project/Project';
import { BindingWebService, RestfulBindingWebService } from 'ws/BindingWebService';
import { CampaignGroupChannel } from 'core/campaignGroup/CampaignGroup';

export interface CreativeManager {
  getCreativesByCampaignId (campaignId, isDraft: boolean): Promise<CreativeOfCampaign[]>;
  getCreativesByAdSetId (adSetId, isDraft: boolean): Promise<CreativeOfCampaign[]>;
  getCreativesByAdGroupId (adGroupId, isDraft: boolean): Promise<CreativeOfCampaign[]>;
  getWatingBindCreatives (payload: WaitingBindGetParams): Promise<CreativeOfCampaign[]>;
  reviewCreatives (creativeIds: Array<number>, adxs: Array<string>): Promise<void>;
  getCreative (creativeId: number): Promise<Creative>;
  getCreatives (creativeType: CreativeType, advertiserId: number | undefined, pageable: Pageable, search?: string): Promise<CreativesWithPagination>;
  createCreative (creative: FormData): Promise<{
    creativeId: number,
    fbCreativeId: string
  }>;
  createCreativesByJson (creatives: any): Promise<{
    data: any[],
    error: {
      error_message: string | null,
      name: string
    }[]
  } | undefined>;
  createCreativeAndBind (channel: CampaignGroupChannel, creative: FormData, campaignId: string, isDraft: boolean): Promise<void>;
  createCreativesByJsonAndBind (channel: CampaignGroupChannel, creatives: any, campaignId: string, isDraft: boolean): Promise<void>;
  updateCreative (creative: FormData): Promise<number>;
  updateCreativeBindingStatus (creatives: Array<number>, enable: boolean);
  cloneCreatives (creatives: Array<number>): Promise<void>;
  deleteCreative (creativeIds: Array<number>): Promise<void>;
  updateCreativeBindingCampaigns (creativeId: number, campaigns: Array<number>, isActive: boolean);
  getCreativeOptions (): Promise<Array<SelectOptions>>;
  getCreativeSizeOptions (): Promise<Array<SelectOptions>>;
  getPpsLayoutId (): Promise<any[]>;
  getPpsThumbnail (creativeId): Promise<string[]>;
  getCreativeNativeToBannerTemplate (): Promise<any>;
  getFbCreativePreview (creativeTypeProperties): Promise<any>;
  getBindingOptions (): Promise<SelectOptions[]>;
  generateRectImgBySquareImg (imageUrl: string): Promise<string>;
  createFBVideoAndThumbnail (videoData: { title: string, url: string }[], advertiserId: number | string, includeThumbnail: boolean): Promise<{
    title: string,
    thumbnail: string,
    video_id: string
  }[]>;
  getFBVideoThumbnail (advertiserId: number | string, videoIds: (number | string)[]): Promise<{
    title: string,
    thumbnail: string,
    video_id: string
  }[]>;
  createTiktokVideo (videoData: { title: string, url: string }, advertiserId: number | string): Promise<{
    video_id: string
  }>;
  getTiktokCreativePreview (creativeTypeProperties): Promise<any>;
  getAdneonCreativePreview (data: any): Promise<any>;
  publicVideoAssetId (videoAssetId: string): Promise<string>;
  getCreativeTypes (): CreativeType[];
}

class DefaultCreativeManager implements CreativeManager {

  webService: CreativeWebService;
  campaignWebService: RtbCampaignWebService;

  constructor (
    webService: CreativeWebService = new RestfulCreativeWebService(),
    campaignWebService: RtbCampaignWebService = new RestfulCampaignWebService(),
    private bindingWebService: BindingWebService = new RestfulBindingWebService()
  ) {
    this.webService = webService;
    this.campaignWebService = campaignWebService;
  }

  async getCreativesByCampaignId (campaignId, isDraft: boolean): Promise<CreativeOfCampaign[]> {
    return this.webService.getCreativesByCampaignId(campaignId, isDraft);
  }

  async getCreativesByAdSetId (adSetId, isDraft: boolean): Promise<CreativeOfCampaign[]> {
    return this.webService.getCreativesByAdSetId(adSetId, isDraft);
  }

  async getCreativesByAdGroupId (adGroupId, isDraft: boolean): Promise<CreativeOfCampaign[]> {
    return this.webService.getCreativesByAdGroupId(adGroupId, isDraft);
  }

  async getWatingBindCreatives (payload: WaitingBindGetParams): Promise<CreativeOfCampaign[]> {
    return this.webService.getWatingBindCreatives(payload);
  }

  async reviewCreatives (creativeIds: Array<number>, adxs: Array<string>): Promise<void> {
    return this.webService.reviewCreatives(creativeIds, adxs);
  }

  async getCreative (creativeId: number): Promise<Creative> {
    return this.webService.getCreative(creativeId);
  }

  async getCreatives (creativeType: CreativeType, advertiserId: number | undefined, pageable: Pageable, search?: string): Promise<CreativesWithPagination> {
    return this.webService.getCreatives(creativeType, advertiserId, pageable, search);
  }

  async createCreative (creative: FormData): Promise<{
    creativeId: number,
    fbCreativeId: string
  }> {
    return this.webService.createCreative(creative);
  }

  async createCreativesByJson (creatives: any): Promise<{
    data: any[],
    error: {
      error_message: string | null,
      name: string
    }[]
  } | undefined> {
    return this.webService.createCreativesByJson(creatives);
  }

  async updateCreative (creative: FormData): Promise<number> {
    return this.webService.updateCreative(creative);
  }

  async createCreativeAndBind (channel: CampaignGroupChannel, creative: FormData, adSetId: string, isDraft: boolean): Promise<void> {
    const { creativeId } = await this.webService.createCreative(creative);
    return this.bindingWebService.createBinding(channel, [{
      goCampaignChannelIds: [adSetId],
      goCreativeIds: [creativeId.toString()],
      active: true,
      draft: isDraft
    }]);
  }

  async createCreativesByJsonAndBind (channel: CampaignGroupChannel, creatives: any, campaignId: string, isDraft: boolean): Promise<void> {
    const data = await this.webService.createCreativesByJson(creatives);
    if (!data) {
      return;
    }
    const creativeIds = data.data.map(newCreative => newCreative.creative.creativeId.toString());
    return this.bindingWebService.createBinding(channel, [{
      goCampaignChannelIds: [campaignId],
      goCreativeIds: creativeIds,
      active: true,
      draft: isDraft
    }]);
  }

  async updateCreativeBindingStatus (creatives: Array<number>, enable: boolean) {
    return this.webService.updateCreativeBindingStatus(creatives, enable);
  }

  async cloneCreatives (creatives: Array<number>): Promise<void> {
    return this.webService.cloneCreatives(creatives);
  }

  async deleteCreative (creativeIds: Array<number>): Promise<void> {
    return this.webService.deleteCreative(creativeIds);
  }

  async updateCreativeBindingCampaigns (creativeId: number, campaigns: Array<number>, isActive: boolean) {
    return this.webService.updateCreativeBindingCampaigns(creativeId, campaigns, isActive);
  }

  async getCreativeOptions () {
    return this.webService.getCreativeOptions();
  }

  async getCreativeSizeOptions () {
    return this.webService.getCreativeSizeOptions();
  }

  async getPpsLayoutId () {
    return this.webService.getPpsLayoutId();
  }

  async getPpsThumbnail (creativeId) {
    return this.webService.getPpsThumbnail(creativeId);
  }

  async getCreativeNativeToBannerTemplate () {
    return this.webService.getCreativeNativeToBannerTemplate();
  }

  async getFbCreativePreview (creativeTypeProperties): Promise<any> {
    return this.webService.getFbCreativePreview(creativeTypeProperties);
  }

  async getBindingOptions (): Promise<SelectOptions[]> {
    return this.webService.getBindingOptions();
  }

  async generateRectImgBySquareImg (imageUrl: string): Promise<string> {
    return this.webService.generateRectImgBySquareImg(imageUrl);
  }

  async createFBVideoAndThumbnail (videoData: { title: string, url: string }[], advertiserId: number | string, includeThumbnail: boolean): Promise<any> {
    return this.webService.createFBVideoAndThumbnail(videoData, advertiserId, includeThumbnail);
  }

  async getFBVideoThumbnail (advertiserId: number | string, videoIds: (number | string)[]): Promise<{
    title: string,
    thumbnail: string,
    video_id: string
  }[]> {
    return this.webService.getFBVideoThumbnail(advertiserId, videoIds);
  }

  async createTiktokVideo (videoData: { title: string, url: string; }, advertiserId: number | string): Promise<{
    video_id: string
  }> {
    return this.webService.createTiktokVideo(videoData, advertiserId);
  }

  async getTiktokCreativePreview (creativeTypeProperties): Promise<any> {
    return this.webService.getTiktokCreativePreview(creativeTypeProperties);
  }

  async getAdneonCreativePreview (data: any): Promise<any> {
    return this.webService.getAdneonCreativePreview(data);
  }

  async publicVideoAssetId (videoAssetId: string): Promise<string> {
    return this.webService.publicVideoAssetId(videoAssetId);
  }

  getCreativeTypes (): CreativeType[] {
    const types = Object.keys(CreativeType).filter((item) => {
      return isNaN(Number(item));
    });
    return types.map(type => CreativeType[type]);
  }
}

class RetailCreativeManager extends DefaultCreativeManager {
}

class GojekCreativeManager extends DefaultCreativeManager {

  invalidCreativeType = [
    CreativeType.AD_NEON,
    CreativeType.RETAIL_RICH_MEDIA_PRODUCT,
    CreativeType.RETAIL_NATIVE_PRODUCT,
    CreativeType.PILOT_TV
  ];

  getCreativeTypes (): CreativeType[] {
    return super.getCreativeTypes()
      .filter(type => !this.invalidCreativeType.includes(type));
  }
}

export default process.env.REACT_APP_PROJECT === Project.RETAIL ?
  RetailCreativeManager :
  GojekCreativeManager;

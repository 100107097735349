import React from 'react';
import { Field } from 'formik';
import { Form, Col, Row, InputGroup } from 'react-bootstrap';
import './formikFormGroup.module.scss';

import {
  FormikInput,
  FormikSelect,
  FormikSwitch,
  FormikLabel
} from '.';

interface FormikFormGroupProps {
  groupAs?: any;
  controlId?: string;
  labelName: string;
  inputName: string;
  inputType?: string;
  inputAs?: string;
  isLoading?: boolean;
  selectOptions?: any;
  disabled?: boolean;
  isMulti?: boolean;
  closeMenuOnSelect?: boolean;
  maxMenuHeight?: number;
  placeholder?: string;
  labelKey?: string;
  valueKey?: string;
  simpleValue?: boolean;
  className?: string;
  labelColSm?: number;
  inputColSm?: number;
  isInputGroup?: boolean;
  inputGroupContent?: string;
  type?: string;
  component?: any;
  options?: any;
  hocContent?: any;
  onChange?: any;
  onBlur?: any;
  defaultValue?: any;
  step?: any;
}

export const FormikFormGroup: React.FunctionComponent<
  FormikFormGroupProps
> = props => {
  const {
    groupAs,
    controlId,
    labelName,
    inputName,
    inputAs,
    selectOptions,
    disabled,
    labelColSm,
    inputColSm,
    isInputGroup,
    inputGroupContent,
    component
  } = props;
  let inputComponent;
  switch (inputAs) {
    case 'react-select':
      inputComponent = (
        <Field
          {...props}
          name={inputName}
          component={FormikSelect}
          options={selectOptions}
          isDisabled={disabled}
        />
      );
      break;
    case 'textarea':
      inputComponent = (
        <Field
          {...props}
          name={inputName}
          component={FormikInput}
          disabled={disabled}
          as='textarea'
        />
      );
      break;
    case 'switch':
      inputComponent = (
        <Field
          {...props}
          name={inputName}
          component={FormikSwitch}
          disabled={disabled}
        />
      );
      break;
    case 'label':
      inputComponent = (
        <Field {...props} name={inputName} component={FormikLabel} />
      );
      break;
    default:
      inputComponent = (
        <Field
          {...props}
          name={inputName}
          component={FormikInput}
          disabled={disabled}
        />
      );
      break;
  }

  if (component) {
    inputComponent = (
      <Field component={component} name={inputName} {...props} />
    );
  }

  if (isInputGroup) {
    inputComponent = (
      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text id={controlId || inputName}>
            {inputGroupContent}
          </InputGroup.Text>
        </InputGroup.Prepend>
        {inputComponent}
      </InputGroup>
    );
  }

  return (
    <Form.Group as={groupAs} controlId={controlId || inputName}>
      <Col sm={labelColSm}>
        <Form.Label column>{labelName}</Form.Label>
      </Col>
      <Col sm={inputColSm}>{inputComponent}</Col>
    </Form.Group>
  );
};

FormikFormGroup.defaultProps = {
  groupAs: Row,
  controlId: undefined,
  labelName: '',
  inputName: '',
  inputType: 'text',
  inputAs: 'input',
  disabled: false,
  isInputGroup: false,
  inputGroupContent: '',
  labelColSm: 3,
  inputColSm: 9
};

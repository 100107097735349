import { SelectOptions } from 'components/common/commonType';
import { CampaignGroup, CampaignGroupChannel, FBBidStrategy, FbObjective, GoCampaignGroupObjective } from 'core/campaignGroup/CampaignGroup';
import DefaultCampaignGroupManager, { CampaignGroupManager } from 'core/campaignGroup/CampaignGroupManager';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { validateEmpty, validateMinMax } from 'utils/ValidateUtils';
import i18n from 'i18n';
import { useRouteMatch } from 'react-router';
import { useLocation } from 'react-router-dom';
import { Order } from 'core/order/Order';
import { AdvertiserManager, DefaultAdvertiserManager } from 'core/advertiser/AdvertiserManager';
import { FbAdSet } from 'core/fbAdSet/FbAdSet';
import { TiktokAdGroup } from 'core/tiktokAdGroup/TiktokAdGroup';
import { DefaultFbAdSetManager, FbAdSetManager } from 'core/fbAdSet/FbAdSetManager';
import coreContext from 'contexts/coreContext';
import { getPriceValue } from 'helper/CurrencyHelper';
import { DynamicBreadcrumb } from 'components/Breadcrumbs/DynamicBreadcrumbs';
import { RtbCampaignBasic } from 'core/rtbCampaign/RtbCampaign';
import {
  CampaignGroupCreateFormChannelModel,
  FBCampaignGroupCreateFormModel,
  RTBCampaignGroupCreateFormModel,
  TiktokCampaignGroupCreateFormModel
} from './CampaignGroupCreateFormChannelModel';
import {
  CampaignGroupEditFormChannelModel,
  FBCampaignGroupEditFormModel,
  RTBCampaignGroupEditFormModel,
  TiktokCampaignGroupEditFormModel
} from './CampaignGroupEditFormModel';
import { useCallAPI } from 'hooks/useCallAPI';

const defaultCampaignGroupManager: CampaignGroupManager = new DefaultCampaignGroupManager();
const defaultFbAdSetManager: FbAdSetManager = new DefaultFbAdSetManager();
const defaultAdvertiserManager: AdvertiserManager = new DefaultAdvertiserManager();

const basicValidate = (order: Order, minBudget: number, totalBudget: number, value: Partial<CampaignGroup>) => {
  const validateBudget = (budget) => {
    let error;
    if (value.channel === CampaignGroupChannel.FB && value.autoOptimise) {
      error = validateEmpty(budget);
    } else if (value.channel === CampaignGroupChannel.TIKTOK && value.autoOptimise) {
      error = validateEmpty(budget);
    }
    if (error) {
      return error;
    }
    if (budget === undefined || budget === '') {
      return;
    }
    return validateMinMax(budget, getPriceValue(order.currency, minBudget), totalBudget);
  };
  return _.omitBy({
    name: validateEmpty(value.name),
    budget: validateBudget(value.budget)
  }, _.isEmpty);
};

const getRedirectPath = (currentUrl: string) => {
  const regMatch = currentUrl.match(/\/orders\/\d+\//);
  return regMatch ? regMatch[0].slice(0, -1) : '/';
};

const turnToFbLegecyObjective = (objecive) => {
  switch (objecive) {
    case FbObjective.OUTCOME_AWARENESS:
      return GoCampaignGroupObjective.AwarenessObjective.REACH;
    case FbObjective.OUTCOME_TRAFFIC:
      return GoCampaignGroupObjective.ConsiderationObjective.LINK_CLICKS;
    case FbObjective.OUTCOME_SALES:
      return GoCampaignGroupObjective.ConversionObjective.CONVERSIONS;
  }
};

export type CampaignGroupFormData = {
  channelModel?: CampaignGroupCreateFormChannelModel | CampaignGroupEditFormChannelModel;
  formType: string;
  title: string;
  loading: boolean;
  initCampaignGroup?: Partial<CampaignGroup>;
  campaignGroupTypeOptions: SelectOptions[];
  redirectData?: {
    pathname: string,
    state?: any
  };
  currentUrl: string;
  cancelTargetPath: string;
  order: Order;
  goCampaignList: TiktokAdGroup[] | FbAdSet[] | RtbCampaignBasic[],
  fbAdAccountOptions: SelectOptions[];
  tiktokAdAccountOptions: SelectOptions[];
  canEditBidStrategy: boolean;
  totalBudget: number;
  budgetManagementModalData: any,
  breadcrumbs: any[],
  showManagementModal: (
    values: Partial<CampaignGroup>,
    valuesUpdater: (fieldName: string, value: any) => void
  ) => any,
  closeManagementModal: () => void,
  getCboChangeHint: (campaignGroup: Partial<CampaignGroup>) => string | undefined;
  validate: (values: Partial<CampaignGroup>) => any;
  save: (values: Partial<CampaignGroup>) => Promise<void>;
  getDefaultBidStrategy: () => FBBidStrategy;
  onChannelChange: (valueUpdater: (fieldName: string, value: any) => void, channel: CampaignGroupChannel) => void;
  onCBOChange: (values: Partial<CampaignGroup>, valueUpdater: (fieldName: string, value: any) => void, enable: boolean) => void;
  showRemainBudget: (values: Partial<CampaignGroup>) => boolean;
};

export const useCreateCampaignGroupFormModel = (
  order: Order,
  campaignGroupManager: CampaignGroupManager = defaultCampaignGroupManager
): CampaignGroupFormData => {

  const initCampaignGroup = {
    name: order.projectName,
    channel: CampaignGroupChannel.RTB,
    budget: '',
    autoOptimise: false,
    budgetBalance: order.budgetBalance,
    objective: GoCampaignGroupObjective.ConsiderationObjective.LINK_CLICKS
  };

  const { loading, callAPIs } = useCallAPI();

  const [channelType, setChannelType] = useState<CampaignGroupChannel>(initCampaignGroup.channel);
  const [fbAdAccountOptions, setFbAdAccountOptions] = useState<SelectOptions[]>([]);
  const [tiktokAdAccountOptions, setTiktokAdAccountOptions] = useState<SelectOptions[]>([]);
  const [redirectData, setRedirectData] = useState<{
    pathname: string,
    state?: any
  } | undefined>(undefined);

  const match = useRouteMatch();
  const currentUrl = match.url;
  const orderDetailPath = getRedirectPath(currentUrl);
  const core = useContext(coreContext);
  const currencyRate = _.get(core, 'accountManager.localeMeta.currencyRate', 1);
  const channelModelsMap = useMemo(() => ({
    [CampaignGroupChannel.RTB]: new RTBCampaignGroupCreateFormModel(order),
    [CampaignGroupChannel.FB]: new FBCampaignGroupCreateFormModel(order, currencyRate),
    [CampaignGroupChannel.TIKTOK]: new TiktokCampaignGroupCreateFormModel(order, currencyRate),
    [CampaignGroupChannel.RETAIL_MEDIA]: new RTBCampaignGroupCreateFormModel(order)
  }), [currencyRate, order]);

  const [channelModel, setChannelModel] = useState<CampaignGroupCreateFormChannelModel>(channelModelsMap[CampaignGroupChannel.RTB]);

  const onChannelChange = (valueUpdater: (fieldName: string, value: any) => void, channel: CampaignGroupChannel) => {
    const channelModel = channelModelsMap[channel];
    setChannelType(channel);
    setChannelModel(channelModel);
    channelModel.initChannelData(valueUpdater);
  };

  useEffect(() => {
    callAPIs([
      async () => channelModel.init((options) => {
        if (channelType === CampaignGroupChannel.FB) {
          setFbAdAccountOptions(options);
        } else if (channelType === CampaignGroupChannel.TIKTOK) {
          setTiktokAdAccountOptions(options);
        }
      })
    ]);
  }, [channelModel, callAPIs, channelType]);

  const save = async (saveValue) => {
    let fb: any = undefined;
    if (saveValue.fb) {
      const fbAdAccountOption = fbAdAccountOptions.find(fbAdAccount => fbAdAccount.value.toString() === saveValue.fb.account_id.toString());
      fb = saveValue.fb ? {
        ...saveValue.fb,
        lifetime_budget: saveValue.budget === '' ? null : saveValue.budget,
        configured_status : 'ACTIVE',
        effective_status : 'ACTIVE',
        is_skadnetwork_attribution : true,
        buying_type : 'AUCTION',
        special_ad_categories : ['NONE'],
        bid_strategy: saveValue.fb.bid_strategy ? saveValue.fb.bid_strategy : null,
        objective: _.get(fbAdAccountOption, 'extra.hasAdvertiserOptedInOdax', false) ?
          saveValue.fb.objective :
          turnToFbLegecyObjective(saveValue.fb.objective),
        pacing_type: saveValue.budget === '' ? [] : ['day_parting']
      } : undefined;
    }
    const tiktok = saveValue.tiktok ? {
      ...saveValue.tiktok,
      opt_status : 'ENABLE',
      status : 'CAMPAIGN_STATUS_ENABLE'
    } : undefined;
    const finalValue = {
      ...saveValue,
      objective: fb ? fb.objective : tiktok ? tiktok.objective_type : saveValue.objective,
      go_lifetime_budget: saveValue.budget === '' ? null : saveValue.budget,
      adsOrderID: order.id,
      fb,
      tiktok
    };
    callAPIs([
      campaignGroupManager.createCampaignGroup.bind(campaignGroupManager, finalValue)
    ], groupId => {
      setRedirectData({
        pathname: `${orderDetailPath}/campaign-groups/${groupId}`,
        state: {
          refreshOrder: shouldRefreshOrder(initCampaignGroup, finalValue)
        }
      });
    });
  };

  const validate = (value: Partial<CampaignGroup>) => {
    const minBudget = channelModel.minBudget;
    const basicError = basicValidate(order, minBudget, _.defaultTo(_.get(order, 'budgetBalance'), 0), value);
    const channelDataError = channelModel.validate(value);
    return _.omitBy({
      ...basicError,
      ...channelDataError
    }, _.isEmpty);
  };

  const showRemainBudget = (values: Partial<CampaignGroup>) => {
    return channelModel.showRemainBudget(values);
  };

  return {
    channelModel,
    formType: 'create',
    title: i18n.t('campaignGroup.labels.createTitle'),
    redirectData,
    loading,
    initCampaignGroup,
    currentUrl,
    campaignGroupTypeOptions: defaultCampaignGroupManager.getCampaignGroupChannelOptions(),
    cancelTargetPath: orderDetailPath,
    order,
    goCampaignList: [],
    fbAdAccountOptions,
    tiktokAdAccountOptions,
    canEditBidStrategy: true,
    totalBudget: _.defaultTo(_.get(order, 'budgetBalance'), 0),
    budgetManagementModalData: undefined,
    breadcrumbs: [
      { path: '/orders', breadcrumb: i18n.t('orderDetail.labels.title') },
      {
        path: '/orders/:orderNumber',
        breadcrumb: DynamicBreadcrumb,
        props: {
          label: _.get(order, 'projectName'),
          matchParam: 'orderNumber'
        }
      },
      {
        path: '/orders/:orderNumber/campaign-groups/new',
        breadcrumb: i18n.t('campaignGroup.labels.createTitle')
      }
    ],
    showManagementModal: () => {},
    closeManagementModal: () => {},
    validate,
    save,
    getCboChangeHint: channelModel.getCboChangeHint,
    getDefaultBidStrategy: channelModel.getDefaultBidStrategy,
    onChannelChange,
    onCBOChange: (values: Partial<CampaignGroup>, valueUpdater: (fieldName: string, value: any) => void, enable: boolean) => {
      channelModel.onCBOChange(enable, valueUpdater);
    },
    showRemainBudget
  };
};

export const useEditCampaignGroupFormModel = (
  order: Order,
  campaignGroupId: number,
  initGoCampaignList?: TiktokAdGroup[] | FbAdSet[] | RtbCampaignBasic[],
  campaignGroupManager: CampaignGroupManager = defaultCampaignGroupManager,
  fbAdSetManager: FbAdSetManager = defaultFbAdSetManager,
  advertiserManager: AdvertiserManager = defaultAdvertiserManager
): CampaignGroupFormData => {

  const { loading, callAPIs } = useCallAPI();
  const [redirectData, setRedirectData] = useState<{
    pathname: string,
    state?: any
  } | undefined>(undefined);
  const [initCampaignGroup, setInitCampaignGroup] = useState<CampaignGroup | undefined>(undefined);
  const [goCampaignList, setGoCampaignList] = useState<TiktokAdGroup[] | FbAdSet[] | RtbCampaignBasic[]>(initGoCampaignList ? initGoCampaignList : []);
  const match = useRouteMatch();
  const currentUrl = match.url;
  const orderDetailPath = getRedirectPath(currentUrl);
  const location = useLocation();
  const cancelTargetPath = _.get(location.state, 'fromDetail', false) ?
    `${orderDetailPath}/campaign-groups/${campaignGroupId}` :
    orderDetailPath;

  const [budgetManagementModalData, setBudgetManagementModalData] = useState<any>();
  const [fbAdAccountOptions, setFbAdAccountOptions] = useState<SelectOptions[]>([]);
  const [tiktokAdAccountOptions, setTiktokAdAccountOptions] = useState<SelectOptions[]>([]);
  const [channelModel, setChannelModel] = useState<CampaignGroupEditFormChannelModel | undefined>();

  const getCampaignGroupFormModel = useCallback((order, campaignGroup, initGoCampaignList) => {
    const functionMap = {
      [CampaignGroupChannel.RTB]: () => new RTBCampaignGroupEditFormModel(order, campaignGroup, initGoCampaignList ? initGoCampaignList as RtbCampaignBasic[] : undefined),
      [CampaignGroupChannel.FB]: () => new FBCampaignGroupEditFormModel(order, campaignGroup, initGoCampaignList ? initGoCampaignList as FbAdSet[] : undefined),
      [CampaignGroupChannel.TIKTOK]: () => new TiktokCampaignGroupEditFormModel(order, campaignGroup, initGoCampaignList ? initGoCampaignList as TiktokAdGroup[] : undefined),
      [CampaignGroupChannel.RETAIL_MEDIA]: () => new RTBCampaignGroupEditFormModel(order, campaignGroup, initGoCampaignList ? initGoCampaignList as RtbCampaignBasic[] : undefined)
    };
    const channelModelGetter = functionMap[campaignGroup.channel];
    return channelModelGetter ? channelModelGetter() : undefined;
  }, []);

  useEffect(() => {
    callAPIs([
      campaignGroupManager.getCampaignGroup.bind(campaignGroupManager, campaignGroupId)
    ], campaignGroup => {
      const channelModel = getCampaignGroupFormModel(order, campaignGroup, initGoCampaignList);
      setChannelModel(channelModel);
      setInitCampaignGroup({
        ...campaignGroup,
        budget: campaignGroup.budget === undefined || campaignGroup.budget === null ?
          '' :
          campaignGroup.budget
      });
      if (campaignGroup.adsOrderID !== order.id) {
        const redirectPath =
          `/orders/${order.orderNumber}/campaign-groups/${campaignGroup.groupId}/edit/error404`;
        setRedirectData({
          pathname: redirectPath
        });
      }
      callAPIs([
        async () => channelModel.init((data) => {
          data.fbAdAccountOptions && setFbAdAccountOptions(data.fbAdAccountOptions);
          data.tiktokAdAccountOptions && setTiktokAdAccountOptions(data.tiktokAdAccountOptions);
          data.goCampaignList && setGoCampaignList(data.goCampaignList);
        })
      ]);
    });
  }, [campaignGroupId, campaignGroupManager, fbAdSetManager, initGoCampaignList, order, advertiserManager, getCampaignGroupFormModel, callAPIs]);

  const save = async (saveValue) => {
    let fb;
    if (saveValue.fb) {
      const fbAdAccountOption = fbAdAccountOptions.find(fbAdAccount => fbAdAccount.value.toString() === saveValue.fb.account_id.toString());
      fb = _.omit(
        {
          ...saveValue.fb,
          lifetime_budget: saveValue.budget === '' ? null : saveValue.budget,
          bid_strategy: saveValue.fb.bid_strategy ? saveValue.fb.bid_strategy : null,
          objective: _.get(fbAdAccountOption, 'extra.hasAdvertiserOptedInOdax', false) ?
            saveValue.fb.objective :
            turnToFbLegecyObjective(saveValue.fb.objective)
        }, ['createdTime', 'ctime', 'updatedTime', 'utime', 'lifetime_budget']
      );
    }
    const tiktok = saveValue.tiktok ? _.omit(
      {
        ...saveValue.tiktok
      }, ['createdTime', 'ctime', 'updatedTime', 'utime']
    ) : undefined;
    const rtb = saveValue.rtb ? {
      ...saveValue.rtb,
      bid_strategy: saveValue.rtb.bid_strategy ? saveValue.rtb.bid_strategy : null
    } : undefined;
    const finalValue = {
      ...saveValue,
      objective: fb ? fb.objective : tiktok ? tiktok.objective_type : saveValue.objective,
      go_lifetime_budget: saveValue.budget === '' ? null : saveValue.budget,
      fb,
      tiktok,
      rtb
    };
    callAPIs([
      campaignGroupManager.updateCampaignGroup.bind(campaignGroupManager, finalValue.groupId, finalValue)
    ], () => {
      setRedirectData({
        pathname: `${orderDetailPath}/campaign-groups/${finalValue.groupId}`,
        state: {
          refreshOrder: shouldRefreshOrder(initCampaignGroup, finalValue)
        }
      });
    });
  };
  const showManagementModal = (
    values: Partial<CampaignGroup>,
    valuesUpdater: (fieldName: string, value: any) => void
  ) => {
    if (!channelModel) {
      return;
    }
    const modalData = channelModel.getBudgetManagementModalData(values, valuesUpdater);
    setBudgetManagementModalData({
      ..._.omit(modalData, 'onSubmit', 'onCancel'),
      submit: (budgetMap) => {
        modalData.onSubmit(budgetMap);
        setBudgetManagementModalData(undefined);
      },
      cancel: () => {
        modalData.onCancel();
        setBudgetManagementModalData(undefined);
      }
    });
  };

  const showRemainBudget = (values: Partial<CampaignGroup>) => {
    if (!channelModel) {
      return false;
    }
    return channelModel.showRemainBudget(values);
  };

  return {
    channelModel,
    formType: 'edit',
    title: i18n.t('campaignGroup.labels.editTitle'),
    redirectData,
    loading,
    initCampaignGroup,
    campaignGroupTypeOptions: defaultCampaignGroupManager.getCampaignGroupChannelOptions(),
    currentUrl,
    cancelTargetPath,
    order,
    goCampaignList,
    fbAdAccountOptions,
    tiktokAdAccountOptions,
    canEditBidStrategy: false,
    totalBudget: channelModel ? channelModel.totalBudget : 0,
    budgetManagementModalData,
    breadcrumbs: [
      { path: '/orders', breadcrumb: i18n.t('orderDetail.labels.title') },
      {
        path: '/orders/:orderNumber',
        breadcrumb: DynamicBreadcrumb,
        props: {
          label: _.get(order, 'projectName'),
          matchParam: 'orderNumber'
        }
      },
      {
        path: '/orders/:orderNumber/campaign-groups/:campaignGroupId',
        breadcrumb: DynamicBreadcrumb,
        props: {
          label: _.get(initCampaignGroup, 'name'),
          matchParam: 'campaignGroupId'
        }
      },
      {
        path: '/orders/:orderNumber/campaign-groups/:campaignGroupId/edit',
        breadcrumb: DynamicBreadcrumb,
        props: {
          prefix: i18n.t('common.labels.edit'),
          label: _.get(initCampaignGroup, 'name'),
          matchParam: 'campaignGroupId'
        }
      }
    ],
    showManagementModal,
    closeManagementModal: () => setBudgetManagementModalData(undefined),
    getCboChangeHint: channelModel ? channelModel.getCboChangeHint : () => undefined,
    validate: (values) => {
      if (!channelModel) {
        return;
      }
      return basicValidate(order, channelModel.minBudget, channelModel.totalBudget, values);
    },
    save,
    getDefaultBidStrategy: () => {
      if (!initCampaignGroup || !channelModel) {
        return FBBidStrategy.LOWEST_COST_WITHOUT_CAP;
      }

      return channelModel.getDefaultBidStrategy();
    },
    onChannelChange: () => {},
    onCBOChange: (values: Partial<CampaignGroup>, valueUpdater: (fieldName: string, value: any) => void, enable: boolean) => {
      if (!channelModel) {
        return;
      }
      channelModel.onCBOChange(enable, valueUpdater, _.partial(showManagementModal, values, valueUpdater));
    },
    showRemainBudget
  };
};

function shouldRefreshOrder (initCampaignGroup, finalCampaignGroup) {
  let initBudget = _.get(initCampaignGroup, 'budget', null);
  initBudget = initBudget === '' ? null : initBudget;
  return initBudget !== finalCampaignGroup.go_lifetime_budget ||
    _.get(initCampaignGroup, 'autoOptimise', false) !== finalCampaignGroup.autoOptimise;
}

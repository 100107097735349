import { SelectOptions } from 'components/common/commonType';
import { TiktokAdGroupFormData } from 'containers/TiktokAdGroups/TiktokAdGroupSetupFlowPage/TiktokAdGroupSetupFlowPageModel';
import { CampaignGroup, TiktokObjective } from 'core/campaignGroup/CampaignGroup';
import { Order } from 'core/order/Order';
import { getPriceValue } from 'helper/CurrencyHelper';
import { TiktokAdGroupWebService, RestfulTiktokAdGroupWebService } from 'ws/TiktokAdGroupWebService';
import { ADGROUP_DEFAULT_AGE_MAX, ADGROUP_DEFAULT_AGE_MIN, BillingEvent, OptimizationGoal, TiktokAdGroup, TiktokAdGroupListRecord, TiktokAgeMapping, TiktokGenderMapping } from './TiktokAdGroup';
import i18n from 'i18n';
import _ from 'lodash';
import { getTiktokAgeGroupsByAgeRange } from 'core/limitation/goCampaignTAOptions';

export interface TiktokAdGroupManager {
  getAdGroup (adGroupId: number | string): Promise<TiktokAdGroup>;
  getAdGroups (groupId: number | string): Promise<TiktokAdGroupListRecord[]>;
  getUniqueAdGroupNames (tiktokCampaignId: number | string): Promise<String[]>;
  createAdGroup (group: CampaignGroup, tiktokAdGroupFormData: TiktokAdGroupFormData): Promise<void>;
  editAdGroup (tiktokAdGroupId: string | number, tiktokAdGroupFormData: TiktokAdGroupFormData): Promise<void>;
  deleteAdGroup (adGroupId: number | string): Promise<void>;
  updateAdGroupState (adGroupData: {
    goCampaignChannelId: (number | string),
    isDraft: boolean
  }[], state: 'activate' | 'deactivate'): Promise<void>;
  getAdGroupOptions (): Promise<SelectOptions[]>;
  getParentInfo (campaignId: string | number): Promise<any>;
  getOptimizationGoalOptions (camapignGroup: CampaignGroup): SelectOptions[];
  getDefaultOptimizationGoal (campaignGroup: CampaignGroup);
  getDefaultBillingEvent (camapignGroup: CampaignGroup): BillingEvent;
  getBilliingEventOptions (campaignGroup: CampaignGroup, optimizationGoal: OptimizationGoal): SelectOptions[];
  getMinBudgetOfAdGroup (
    currencyRate: number,
    scheduleDateCount: number,
    order: Order
  ): number;
  wrapTiktokDayparting (tiktokAdGroupFormData: TiktokAdGroupFormData): string;
  wrapAdGroupForServer (tiktokAdGroupFormData: TiktokAdGroupFormData): any;
  getAdGroupTargeting (targetingValue: any): any;
}

const tiktokSelectOptionConfig = {
  [TiktokObjective.REACH]: {
    [OptimizationGoal.REACH]: [
      BillingEvent.CPM
    ]
  },
  [TiktokObjective.TRAFFIC]: {
    [OptimizationGoal.CLICK]: [
      BillingEvent.CPC
    ]
  }
};

const defaultGoals = {
  [TiktokObjective.REACH]: OptimizationGoal.REACH.toString(),
  [TiktokObjective.TRAFFIC]: OptimizationGoal.CLICK.toString()
};

export class DefaultTiktokAdGroupManager implements TiktokAdGroupManager {

  webService: TiktokAdGroupWebService;

  constructor (
    webService: TiktokAdGroupWebService = new RestfulTiktokAdGroupWebService()
  ) {
    this.webService = webService;
  }

  async getAdGroup (adGroupId: number | string): Promise<TiktokAdGroup> {
    return this.webService.getAdGroup(adGroupId);
  }

  async getAdGroups (tiktokCampaignId: number | string): Promise<TiktokAdGroupListRecord[]> {
    return this.webService.getAdGroups(tiktokCampaignId);
  }

  async getUniqueAdGroupNames (tiktokCampaignId: string | number): Promise<String[]> {
    return this.webService.getUniqueAdGroupNames(tiktokCampaignId);
  }

  async createAdGroup (group: CampaignGroup, tiktokAdGroupFormData: TiktokAdGroupFormData): Promise<void> {
    return this.webService.createAdGroup(group, this.wrapAdGroupForServer(tiktokAdGroupFormData));
  }

  async editAdGroup (tiktokAdGroupId: string | number, tiktokAdGroupFormData: TiktokAdGroupFormData): Promise<void> {
    return this.webService.editAdGroup(tiktokAdGroupId, this.wrapAdGroupForServer(tiktokAdGroupFormData));
  }

  async deleteAdGroup (adGroupId: number | string): Promise<void> {
    return this.webService.deleteAdGroup(adGroupId);
  }

  async updateAdGroupState (adGroupData: {
    goCampaignChannelId: (number | string),
    isDraft: boolean
  }[], state: 'activate' | 'deactivate'): Promise<void> {
    return this.webService.updateAdGroupState(adGroupData, state);
  }

  async getAdGroupOptions (): Promise<SelectOptions[]> {
    return this.webService.getAdGroupOptions();
  }

  async getParentInfo (campaignId: string | number): Promise<any> {
    return this.webService.getParentInfo(campaignId);
  }

  getDefaultOptimizationGoal (campaignGroup) {
    const objective = campaignGroup.objective;
    return defaultGoals[objective];
  }

  getOptimizationGoalOptions (campaignGroup) {
    const objective = campaignGroup.objective;
    const optimizationGoals = tiktokSelectOptionConfig[objective];
    return optimizationGoals ? Object.keys(optimizationGoals).map(goal => ({
      label: i18n.t(`adGroup.optimizationGoal.${goal.toLowerCase()}`),
      value: goal
    })) : [];
  }

  getDefaultBillingEvent (campaignGroup) {
    const objective = campaignGroup.objective;
    const optimizationGoals = tiktokSelectOptionConfig[objective];
    if (!optimizationGoals) {
      return BillingEvent.CPM;
    }
    const optimizationGoal = defaultGoals[objective];
    const billingEvents = optimizationGoals[optimizationGoal];
    return billingEvents && billingEvents.length > 0 ?
      billingEvents[0] : BillingEvent.CPM;
  }

  getBilliingEventOptions (campaignGroup, optimizationGoal) {
    const objective = campaignGroup.objective;
    const optimizationGoals = tiktokSelectOptionConfig[objective];
    if (!optimizationGoals) {
      return [];
    }
    const billingEvents = optimizationGoals[optimizationGoal];
    return billingEvents ? billingEvents.map(event => ({
      label: i18n.t(`tiktok.billingEvent.${event.toLowerCase()}`),
      value: event
    })) : [];
  }

  getMinBudgetOfAdGroup (
    currencyRate: number,
    scheduleDateCount: number,
    order: Order
  ) {
    const minDailyBudget = 20 * currencyRate;
    const minBudget = minDailyBudget * scheduleDateCount;
    return getPriceValue(order.currency, minBudget / (1 - order.orderMargin - order.sysMargin));
  }

  wrapAdGroupForServer (tiktokAdGroupFormData: TiktokAdGroupFormData) {
    const dayparting = this.wrapTiktokDayparting(tiktokAdGroupFormData);
    return {
      ..._.omit(tiktokAdGroupFormData, ['bid', 'budget', 'targeting', 'dayPart']),
      go_lifetime_budget: tiktokAdGroupFormData.budget === '' ? null : tiktokAdGroupFormData.budget,
      go_bid_amount: tiktokAdGroupFormData.bid,
      dayparting: !_.isEmpty(dayparting) ? dayparting : undefined,
      dayPartSwitch: !_.isEmpty(dayparting) ? 'ON' : 'OFF',
      ...this.getAdGroupTargeting(tiktokAdGroupFormData.targeting)
    };
  }

  wrapTiktokDayparting (tiktokAdGroupFormData: TiktokAdGroupFormData): string {
    const dayPart = _.isEmpty(tiktokAdGroupFormData.dayPart) ? '' : _.omit(tiktokAdGroupFormData.dayPart, ['enabled']);
    let wrappedValue = '';
    !_.isEmpty(dayPart) && _.forEach(Object.keys(dayPart), (key) => {
      let day = '0'.repeat(48);
      _.forEach(dayPart[key], (hour: number) => {
        let startIndex = hour * 2;
        day = day.substring(0, startIndex) + '11' + day.substring(startIndex + 2, day.length);
      });
      wrappedValue = wrappedValue.concat(day);
    });
    return wrappedValue;
  }

  getAdGroupTargeting = (limitationValue: any) => {
    const finalTargeting = {};
    if (limitationValue) {
      const includeTargeting = limitationValue.include;
      includeTargeting && includeTargeting.forEach(targeting => {
        if (targeting.type === 'tiktok_location') {
          finalTargeting['location'] = _.uniq(targeting.value.map(option => option.value));
        } else if (targeting.type === 'tiktok_placement') {
          finalTargeting['placement'] = targeting.value.map(option => option.value);
        } else if (targeting.type === 'tiktok_audience') {
          finalTargeting['audience'] = targeting.value.map(option => option.value);
        } else if (targeting.type === 'tiktok_os') {
          finalTargeting['operation_system'] = targeting.value.map(option => option.value);
        } else if (targeting.type === 'genders') {
          finalTargeting['gender'] = Object.keys(TiktokGenderMapping).find(key => TiktokGenderMapping[key] === targeting.value) ||
            Object.keys(TiktokGenderMapping)[0];
        } else {
          finalTargeting[targeting.type] = Array.isArray(targeting.value) ? targeting.value.map(option => option.value) : targeting.value;
        }
      });
      const excludeTargeting = limitationValue.exclude;
      excludeTargeting && excludeTargeting.forEach(targeting => {
        if (targeting.type === 'tiktok_audience') {
          finalTargeting['excluded_audience'] = targeting.value.map(option => option.value);
        }
      });
      const ageMin = _.get(finalTargeting, 'age_min', ADGROUP_DEFAULT_AGE_MIN);
      const ageMax = _.get(finalTargeting, 'age_max', ADGROUP_DEFAULT_AGE_MAX);
      const ageData = getTiktokAgeGroupsByAgeRange(ageMin, ageMax);
      finalTargeting['age'] = Object.keys(TiktokAgeMapping).filter(key => ageData.some(range => TiktokAgeMapping[key] === range.label));
      finalTargeting['ageMin'] = ageMin;
      finalTargeting['ageMax'] = ageMax;
      if (!finalTargeting['gender']) {
        finalTargeting['gender'] = Object.keys(TiktokGenderMapping)[0];
      }
    }

    return {
      ..._.omit(finalTargeting, ['age_min', 'age_max', 'genders'])
    };
  }
}

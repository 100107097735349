import React from 'react';
import { withFormikOnFileChange, withTip } from 'hoc';
import { ImageInput } from '../Image/ImageInput';
import _ from 'lodash';
import cx from 'classnames/bind';
import styles from './formikImageInput.module.scss';
import { getFieldErrors } from 'utils/FormikUtils';

const classNames = cx.bind(styles);

export const FormikImageInput = withTip(withFormikOnFileChange((props) => {

  const onFileChange = (onChange, file) => {
    if (!file) {
      onChange({
        url: undefined,
        file: undefined
      });
      return;
    }
    const validTypes = props.supportType;
    if (validTypes.indexOf(file.type) === -1) {
      onChange({
        file,
        url: undefined
      });
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      const image = new Image();
      const src = reader.result as string;
      image.src = src;
      image.onload = () => {
        onChange({
          url: undefined,
          file,
          imgBase64: src,
          width: image.width,
          height: image.height
        });
      };
    };
    reader.readAsDataURL(file);
  };

  const url = _.get(props.field.value, 'url');
  const file = _.get(props.field.value, 'file');
  const imageInputClass = classNames('logoInput', props.className, {
    showSolidBorder: (url || file) && !props.errorBorderHint,
    hideBorder: props.errorBorderHint
  });

  const hasError = !!getFieldErrors(props.form, props.field.name);
  return (
    <div className={imageInputClass}>
      <ImageInput
        {..._.omit(props, ['className'])}
        url={url}
        file={file}
        errorBorderHint={hasError}
        onChange={_.partial(onFileChange, props.onChange)}
      />
    </div>
  );
}));

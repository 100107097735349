import { useCallback, useEffect, useState } from 'react';
import { GoSegmentFormModelData, useCreateGoSegmentFormModel, useEditGoSegmentFormModel } from './GoSegmentFormModel';
import { useCallAPI } from 'hooks/useCallAPI';
import { DefaultAdRequestSourceManager } from 'core/adRequestSource/AdRequestSourceManager';
import countryOptions from '../../lookalikeCountryOptions';
import { DefaultGoSegmentManager } from 'core/goSegment/GoSegmentManager';
import { GoSegmentFormData } from 'core/goSegment/GoSegment';
import _ from 'lodash';
import { validateMinMax } from 'utils/ValidateUtils';

export const useFetchLookalikeData = (seedSegmentId) => {

  const [fbApproximateReach, setFbApproximateReach] = useState(0);
  const [seedSegmentName, setSeedSegmentName] = useState('');
  const { loading, callAPIs } = useCallAPI();

  const onCountryChange = useCallback(async (country: string) => {
    const adRequestSourceManager = new DefaultAdRequestSourceManager();
    const goSegmentManager = new DefaultGoSegmentManager();
    callAPIs(
      [
        adRequestSourceManager.getFBCountryPeopleAmount.bind(adRequestSourceManager, country),
        goSegmentManager.getGoSegment.bind(goSegmentManager, seedSegmentId)
      ],
      (count, seedSegment) => {
        setFbApproximateReach(count);
        setSeedSegmentName(seedSegment.name);
      }
    );
  }, [seedSegmentId, callAPIs]);

  useEffect(() => {
    onCountryChange(countryOptions[0].value);
  }, [onCountryChange]);

  return {
    loading,
    seedSegmentName,
    fbApproximateReach,
    onCountryChange
  };
};

const validateLookalike = (basicValidate, value: Partial<GoSegmentFormData>) => {
  const basicError = basicValidate(value);
  const ratio = _.get(value.lookalike, 'ratio');
  return _.omitBy({
    ...basicError,
    lookalike: _.omitBy({
      ratio: validateMinMax(ratio, 1, 20)
    }, _.isEmpty)
  }, _.isEmpty);
};

export const useCreateGoSegmentLookalikeFormModel = (seedSegmentId: number | string): GoSegmentFormModelData => {

  const { loading, validate: basicValidate, ...basicProps } = useCreateGoSegmentFormModel();
  const { loading: loadingExtra, ...extraData } = useFetchLookalikeData(seedSegmentId);

  return {
    ...basicProps,
    extraData,
    loading: loading || loadingExtra,
    formType: 'create',
    validate: _.partial(validateLookalike, basicValidate)
  };
};

export const useEditGoSegmentLookalikeFormModel = (
  segmentId: number | string,
  seedSegmentId: number | string
): GoSegmentFormModelData => {

  const { loading, validate: basicValidate, ...basicProps } = useEditGoSegmentFormModel(segmentId);
  const { loading: loadingExtra, ...extraData } = useFetchLookalikeData(seedSegmentId);

  return {
    ...basicProps,
    extraData,
    loading: loading || loadingExtra,
    formType: 'edit',
    validate: _.partial(validateLookalike, basicValidate)
  };
};

import { SelectOptions } from 'components/common/commonType';

export enum OutdoorType {
  VIDEO = 1,
  IMAGE_AUDIO = 2,
  VIDEO_IMAGE = 3,
  HTML5 = 4
}

export type CreativeOfCampaign = {
  rtbCreativeId: any,
  id: number,
  name: string,
  status: number,
  vtr: number,
  vctr: number,
  vimps: number,
  view: number,
  ctr: number,
  imps: number,
  clicks: number,
  size: string,
  creativeType: CreativeType,
  enableStartTime?: string,
  enableEndTime?: string,
  approvalStatus: any,
  bannerImageUrl: string,
  creativeValue: any,
  createTime: string,
  landingPageUrl: string,
  isActiveBinding: boolean,
  tenmaxCategory: string,
  origTenmaxCategory: string | null,
  shortcutUrl: string,
  bindingState: CampaignBannerMapState,
  nativeBanner?: { [key: string]: any },
  containFBInternalUrl?: boolean,
  effectiveStatus?: string,
  issuesInfo?: string,
  adReviewFeedback?: string,
  goBindingId: string,
  report?: any,
  bannerExtra?: { [key: string]: string | number | boolean | object },
  previewLink?: string,
  ppsLayoutId?: string[];
  retail?: string,
  productSet?: {
    productSet: string,
    productSetId: string
  }
};

export type Creative = {
  basic: CreativeBasic,
  limitations: { [type: string]: Limitation[] };
};

export type CreativeBasic = {
  advertiserId: number,
  agencyId: number,
  bannerUrl: string,
  creativeId: number,
  creativeType: CreativeType,
  creativeValues: any,
  landingPageUrl: string,
  name: string,
  tenmaxCategory: string,
  bannerExtra?: { [key: string]: string | number | boolean | object },
  enableNativeBanner?: boolean,
  nativeBanner?: { [key: string]: any },
  bannerImageUrl?: string,
  outdoorType?: OutdoorType,
  duration?: number,
  enableStartTime?: string,
  enableEndTime?: string,
  retail?: string,
  productSet?: {
    productSet: string,
    productSetId: string
  }
};

export type BindingCampaign = {
  id: number,
  name: string,
  orderNumber: string,
  active: boolean,
  isActive: boolean
};

export enum CampaignBannerMapState {
  DEFAULT,
  ENABLE,
  DISABLE,
  WAITING_FB_UPDATE,
  BEFORE_START_TIME,
  AFTER_END_TIME,
  IN_PROGRESS
}

export type CreativeListRecord = {
  advertiserId: number,
  approvalStatus: { [adxName: string]: { state: number, udt: string } },
  bannerImageUrl: string | null,
  bannerUrl: string,
  bindingCampaign: BindingCampaign[],
  bindingCount: { enable: number, disable: number },
  createTime: string,
  creativeId: number,
  creativeState: CreativeStatus,
  creativeType: CreativeType,
  creativeValues: { [key: string]: string | number | boolean | object },
  limitations: { [type: string]: Limitation[] },
  name: string,
  tenmaxCategory: string,
  origTenmaxCategory: string | null,
  bannerExtra?: { [key: string]: string | number | boolean | object },
  enableNativeBanner: boolean,
  nativeBanner?: { [key: string]: any },
  ppsLayoutId?: string[],
  outdoorType?: OutdoorType,
  duration?: number,
  enableStartTime?: string,
  enableEndTime?: string,
  productSet?: {
    productSet: string,
    productSetId: string
  },
  retail?: string
};

type Limitation = {
  op: string;
  value: Array<SelectOptions & { isGroup?: boolean }> | string | number;
  type: string;
  isGroup?: boolean;
};

export enum VideoPlacement {
  IN_STREAM = 1,
  OUT_STREAM,
  UNLIMIT
}

export enum CreativeType {
  IMAGE = 1,
  HTML5,
  NATIVE,
  VIDEO,
  DYNAMIC,
  THIRDPARTY = 8,
  COMBO = 10,
  OUTDOOR,
  ONE_FOR_ALL,
  ONE_FOR_ALL_DISPLAY,
  ONE_FOR_ALL_DISPLAY_IOS,
  ONE_FOR_ALL_DISPLAY_ANDROID,
  ONE_FOR_ALL_VIDEO,
  ONE_FOR_ALL_VIDEO_IOS,
  ONE_FOR_ALL_VIDEO_ANDROID,
  AD_NEON,
  RETAIL_RICH_MEDIA_PRODUCT,
  RETAIL_NATIVE_PRODUCT,
  PILOT_TV
}

export type CreativeValue = {
  w?: number;
  h?: number;
  imageUrl?: number;
  title?: string;
  desc?: string;
  img?: ImageData;
  img_l?: ImageData;
  img_s?: ImageData;
  icon?: ImageData;
  icon_l?: ImageData;
  icon_s?: ImageData;
  sponsored?: Sponsored;
};

export type ImageData = {
  url: string;
  w: number;
  h: number;
};

export type Sponsored = {
  name: string;
  link: string;
};

export enum CreativeStatus {
  'STATUS',
  'ACTIVE',
  'DEACTIVE'
}

export enum ApprovalStatus {
  'NEW',
  'PENDING',
  'APPROVED',
  'DENIED',
  'FIXED'
}

export enum ADX {
  asiamax = 'AsiaMax',
  doubleclick = 'DoubleClick',
  verizon = 'Verizon',
  facebook = 'Facebook',
  tiktok = 'TikTok'
}

export enum CALL_TO_ACTION {
  LEARN_MORE = 'LEARN_MORE',
  SHOP_NOW = 'SHOP_NOW',
  USE_APP = 'USE_APP',
  GET_OFFER = 'GET_OFFER'
}

export enum RETAILMAX_LAYOUT_TYPE {
  IMAGE = 'pps_medium_rectangle_image_only_shared_version',
  VIDEO = 'pps_medium_rectangle_video_only_shared_version'
}

export enum ADNEON_CREATIVE_LAYOUT {
  DESKTOP_SLIDE_PRODUCTSET = 'desktop-slide-productset',
  MOBILE_LIST_ITEM_PRODUCTSET = 'mobile-list-item-productset',
  DESKTOP_LIST_ITEM_PRODUCTSET = 'desktop-list-item-productset'
}

export enum CREATIVE_LOGO_POSITION {
  LEFT = 'left',
  MIDDLE = 'middle',
  RIGHT = 'right'
}

export enum CREATIVE_DISPLAYSIZE {
  DEFAULT = 'default',
  LARGE = 'large'
}

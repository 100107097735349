import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import { SelectOptions } from 'components/common/commonType';

export interface InventoryModel {
  readonly defaultSelected: string;
  readonly event: UpdateEventListener<InventoryModel>;
  readonly state: InventoryState;
  readonly inventorySetting: InventorySetting[];
  readonly sidebarTitleKey: string;
  fire ();
  changeInventory (inventory: string): Promise<void>;
  changeInventorySetting (inventorySetting: InventorySetting[]): void;
}

export type InventorySetting = {
  readonly name: string;
  readonly title?: string;
  cb: () => Promise<SelectOptions[]>;
};

export type InventoryProps = {
  readonly width?: number;
  readonly model: InventoryModel;
};

export type InventoryState = {
  readonly loading: boolean;
  readonly selected?: string;
  readonly inventoryData?: SelectOptions[];
};

export class DefaultInventoryModel implements InventoryModel {
  event: FireableUpdateEventListener<InventoryModel>;
  loading: boolean;
  selected: string;
  inventoryData?: SelectOptions[];
  inventoryDataCache: {[key: string]: SelectOptions[]};
  inventoryValue?: SelectOptions[];
  modelInventorySetting: InventorySetting[];

  constructor (
    defaultSelected: string,
    inventorySetting: InventorySetting[],
    taOptionsCache: {[key: string]: SelectOptions[]},
    public sidebarTitleKey: string = 'limitation.labels.trafficAttribute'
  ) {
    this.event = new FireableUpdateEventListener<InventoryModel>();
    this.loading = false;
    this.selected = defaultSelected;
    this.inventoryDataCache = taOptionsCache;
    this.modelInventorySetting = inventorySetting;
  }

  get defaultSelected (): string {
    return this.selected;
  }

  get inventorySetting (): InventorySetting[] {
    return this.modelInventorySetting;
  }

  async changeInventory (inventoryName: string): Promise<void> {
    this.updateState(true, inventoryName);
    const inventory = this.modelInventorySetting.find(
      ({ name }) => name === inventoryName
    );
    if (!inventory) {
      throw new Error(`unknow limitation inventory ${inventoryName}`);
    }

    if (!this.inventoryDataCache[inventory.name]) {
      try {
        this.inventoryDataCache[inventory.name] = inventory.cb ? await inventory.cb() : [];
      } catch (e) {
        console.error(`limitation fetch error , cause is `, e);
        this.updateState(false, inventoryName, []);
      }
    }

    const inventoryData = this.inventoryDataCache[inventory.name];

    this.updateState(false, inventoryName, inventoryData);
  }

  changeInventorySetting (inventorySetting: InventorySetting[]) {
    this.modelInventorySetting = inventorySetting;
    this.fire();
  }

  fire () {
    this.event.fireEvent(this);
  }

  updateState (loading: boolean, selected: string, inventoryData?: any) {
    this.loading = loading;
    this.selected = selected;
    this.inventoryData = inventoryData;
    this.event.fireEvent(this);
  }

  get state (): InventoryState {
    return {
      loading: this.loading,
      selected: this.selected,
      inventoryData: this.inventoryData
    };
  }
}

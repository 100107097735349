import React from 'react';
import { AdvertiserDetailState, AdvertiserDetailProps } from './AdvertiserDetailModel';
import { LoadingIndicator } from 'components/common/LoadingIndicator';

import styles from './advertiserDetail.module.scss';
import i18n from 'i18n';
import { AdvertiserMemberList } from 'components/AdvertiserMemberList/AdvertiserMemberList';
import { Link } from 'react-router-dom';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { hasFuncs } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';
import { StickableTableContainer } from 'containers/StickableTableContainer/StickableTableContainer';
import { Modal } from 'components/common/Modal/Modal';
import { Nav } from 'react-bootstrap';
import { TitleWithBreadcrumbs } from 'components/TitleArea/TitleWithBreadcrumbs';
import { DynamicBreadcrumb } from 'components/Breadcrumbs/DynamicBreadcrumbs';
import _ from 'lodash';
export class AdvertiserDetail extends React.Component<AdvertiserDetailProps, AdvertiserDetailState> {

  handler?: number;

  componentDidMount () {
    this.handler = this.props.model.event.add(model => {
      this.setState(model.state);
    });
    this.props.model.init();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.onUnmount(this.handler);
      this.handler = this.props.model.event.add(model => {
        this.setState(model.state);
      });
      this.props.model.init();
    }
  }

  componentWillUnmount () {
    this.props.model.onUnmount(this.handler);
  }

  renderSection = (sectionData) => {
    return Object.keys(sectionData).map(key => {
      const title = i18n.t(`advertiserDetail.labels.${key}`);
      let value = sectionData[key];
      if (value === undefined) {
        return <div key={key}/>;
      }
      return (
        <div className={styles.field} key={key}>
          <div className={styles.fieldLabel}>
            {title}
          </div>
          <div className={styles.fieldValue}>
            {value}
          </div>
        </div>
      );
    });
  }

  renderAdvertiserData = (advertiserViewData) => {
    if (!advertiserViewData) {
      return <></>;
    }
    return Object.keys(advertiserViewData).map(key => (
      <section key={key}>
        {this.renderSection(advertiserViewData[key])}
      </section>
    ));
  }

  renderModal (modalData) {
    const model = this.props.model;
    const ModalContent: typeof React.Component = modalData.component;
    return (
      <Modal
        fullScreen={modalData.fullScreen}
        title={modalData.title}
        primaryButton={modalData.confirmBtnData && {
          title: modalData.confirmBtnData.title,
          callback: modalData.confirmBtnData.callback
        }}
        secondaryButton={{
          title: i18n.t('common.buttons.close'),
          callback: model.hideModal
        }}
        dismiss={model.hideModal}
        animation={false}
      >
        {ModalContent ? <ModalContent {...modalData.componentProps} /> : modalData.message}
      </Modal>
    );
  }

  render () {
    const model = this.props.model;
    const advertiser = model.advertiser;
    if (!advertiser) {
      return <LoadingIndicator />;
    }

    return (
      <StickableTableContainer>
        <div className={styles.advertiserDetailContainer}>
          {model.state.loading && <LoadingIndicator />}
          <div className={styles.title}>
            <TitleWithBreadcrumbs
              title={i18n.t('advertiserDetail.labels.title')}
              routes={[
                { path: '/advertisers', breadcrumb: i18n.t('appMenus.comapnyManagement.items.advertisers') },
                { path: '/advertisers/:advertiserId', breadcrumb: DynamicBreadcrumb, props: { label: _.get(model.advertiser, 'advertiserName'), matchParam: 'advertiserId' } }
              ]}
            />
          </div>
          <div className={styles.titleBottomLine} />
          <div className={styles.advertiserDetail}>
            <div className={styles.topArea}>
              <div className={styles.advertiserName}>
                {i18n.t('advertiserDetail.labels.name', { name: advertiser.advertiserName })}
              </div>
              <div className={styles.buttonArea}>
                {/* <PermissionChecker permissionAware={hasRoles(RoleNames.sysAdmin)}>
                  <div onClick={model.showSegmentSourceModal}>{i18n.t('advertiserDetail.labels.advertiserSegmentSource')}</div>
                </PermissionChecker> */}
                <PermissionChecker permissionAware={hasFuncs(Permission.ADVERTISER_WRITE)}>
                  <Link to={`${advertiser.id}/edit`}>{i18n.t('advertiserDetail.labels.editAdvertiser')}</Link>
                </PermissionChecker>
              </div>
            </div>
            <div className={styles.detail}>
              {this.renderAdvertiserData(model.state.advertiserViewData)}
            </div>
          </div>
          <div className={styles.other}>
            <Nav
              activeKey={this.props.model.state.listType}
              onSelect={this.props.model.switchList}
            >
              <Nav.Item>
                <Nav.Link eventKey='member'>{i18n.t('advertiserDetail.tabs.advertiserMemberList')}</Nav.Link>
              </Nav.Item>
            </Nav>
            {this.props.model.state.listType === 'member' && <AdvertiserMemberList model={model.getAdvertiserMemberListModel()}/>}
          </div>
          {model.state.modalData && this.renderModal(model.state.modalData)}
        </div>
      </StickableTableContainer>
    );
  }
}

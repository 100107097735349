import React from 'react';
import { Form, Row } from 'react-bootstrap';
import LimitationSetting from './LimitationSetting/LimitationSetting';
import styles from './editLimitation.module.scss';
import { ChangeOperateAlertDialog } from 'components/CampaignLimitation/ChangeOperateAlertDialog/ChangeOperateAlertDialog';
import i18n from 'i18n';
import _ from 'lodash';
import is from 'is_js';
import Tags from 'components/common/Tags/Tags';
import { DefaultKeywordComponentModel } from 'components/CampaignLimitation/KeywordComponent/KeywordComponentModel';
import { AudienceInfo } from 'components/AudienceInfo/AudienceInfo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImport } from '@fortawesome/free-solid-svg-icons';
import { LoadingIndicator } from 'components/common/LoadingIndicator';

const unicornkeywordName = [
  'unicornkeyword1',
  'unicornkeyword2',
  'unicornlanguage'
];
export class EditLimitation extends React.Component<any, any> {
  keywordModel: any;
  handler?: number;
  constructor (props) {
    super(props);
    this.state = {
      modalShown: false,
      type: '',
      newOperate: '',
      orgOperate: '',
      show: undefined,
      inventory: 'default',
      limitationValue: {
        INST: true
      }
    };
    this.keywordModel = new DefaultKeywordComponentModel('name', '', () => {});
  }

  componentDidMount () {
    this.handler = this.props.model.event.add(model => {
      this.setState(model.state);
    });
    this.props.model.getAudienceEstimatedData();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      this.handler && prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add((model) => {
        this.setState(model.state);
      });
      this.props.model.getAudienceEstimatedData();
    }
  }

  componentWillUnmount () {
    this.handler && this.props.model.event.remove(this.handler);
  }

  renderLimitationPage = props => {
    const hideModal = () => {
      this.setState({
        modalShown: false
      });
    };

    const handleUnicornkeywordLanguage = (operate, type) => {
      if (!unicornkeywordName.includes(type)) {
        return;
      }
      const { limitationValue } = this.props.model;
      const keywordAllNoValue = unicornkeywordName
        .filter(s => s !== 'unicornlanguage')
        .every(keywordName => {
          const keywordValue = _.find(limitationValue[operate], [
            'type',
            keywordName
          ]);
          return is.not.existy(keywordValue) || keywordValue.length === 0;
        });

      if (keywordAllNoValue) {
        const lastUnicornkeywordTypes = _.without(unicornkeywordName, type);

        lastUnicornkeywordTypes.forEach(unicornkeywordType => {
          let unicornKeywordDelete = _.findIndex(limitationValue[operate], [
            'type',
            unicornkeywordType
          ]);
          if (unicornKeywordDelete !== -1) {
            limitationValue[operate].splice(unicornKeywordDelete, 1);
          }
        });
      }
    };

    const handleDeleteLimitation = (operate, type) => {
      let limitationValue = this.props.model.limitationValue;
      let limitationdelete = limitationValue[operate].findIndex(function (
        element
      ) {
        return element.type === type;
      });
      if (limitationdelete !== -1) {
        limitationValue[operate].splice(limitationdelete, 1);
      }
      handleUnicornkeywordLanguage(operate, type);
      this.props.model.onLimitationChange();
    };

    const handleChangeOpUnicornkeyword = (orgOperate, type, newOperate) => {
      if (!unicornkeywordName.includes(type)) {
        return;
      }
      const lastUnicornkeywordTypes = _.without(unicornkeywordName, type);
      lastUnicornkeywordTypes.forEach(lastUnicornkeywordType => {
        const unicornKeyword = _.findIndex(limitationValue[orgOperate], [
          'type',
          lastUnicornkeywordType
        ]);
        const newOperateUnicornKeyword = _.findIndex(
          limitationValue[newOperate],
          ['type', lastUnicornkeywordType]
        );
        if (unicornKeyword === -1 && newOperateUnicornKeyword !== -1) {
          limitationValue[newOperate].splice(newOperateUnicornKeyword, 1);
        } else if (unicornKeyword !== -1) {
          changeOperate(orgOperate, lastUnicornkeywordType, newOperate);
        }
      });
    };

    const changeOperate = (
      orgOperate = this.state.orgOperate,
      type = this.state.type,
      newOperate = this.state.newOperate
    ) => {
      let limitationValue = this.props.model.limitationValue;
      if (!limitationValue[newOperate]) {
        limitationValue[newOperate] = [];
      }
      let limitationdelete = limitationValue[orgOperate].findIndex(function (
        element
      ) {
        return element.type === type;
      });
      let IsInNewLimitationOperateGroup = limitationValue[newOperate].findIndex(
        function (element) {
          return element.type === type;
        }
      );
      if (IsInNewLimitationOperateGroup > -1) {
        limitationValue[newOperate].splice(IsInNewLimitationOperateGroup, 1);
      }
      limitationValue[newOperate].push(
        limitationValue[orgOperate][limitationdelete]
      );
      limitationValue[orgOperate].splice(limitationdelete, 1);
      hideModal();
      this.props.model.onLimitationChange();
    };

    const handleChangeOperate = (orgOperate, type, newOperate) => {
      let IsInNewLimitationOperateGroup = this.props.model.limitationValue[
        newOperate
      ] ? this.props.model.limitationValue[
        newOperate
      ].findIndex(function (element) {
        return element.type === type;
      }) : -1;
      if (IsInNewLimitationOperateGroup > -1) {
        this.setState({
          modalShown: true,
          orgOperate: orgOperate,
          type: type,
          newOperate: newOperate
        });
      } else {
        handleChangeOpUnicornkeyword(orgOperate, type, newOperate);
        changeOperate(orgOperate, type, newOperate);
      }
    };

    const changeUnicornkeywordOperate = (
      orgOperate = this.state.orgOperate,
      newOperate = this.state.newOperate
    ) => {
      const type = 'unicornlanguage';
      handleChangeOpUnicornkeyword(orgOperate, type, newOperate);
      changeOperate(orgOperate, type, newOperate);
    };

    const handleChange = (operate, type, value) => {
      if ((Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value !== undefined)) {
        let limitationValue = this.props.model.limitationValue;
        if (!limitationValue[operate]) {
          limitationValue[operate] = [];
        }
        let indexOFLimitationChange = limitationValue[operate].findIndex(
          function (element) {
            return element.type === type;
          }
        );
        if (indexOFLimitationChange > -1) {
          limitationValue[operate][indexOFLimitationChange].value = value;
        } else {
          limitationValue[operate].push({ value: value, type: type });
        }
        this.props.model.onLimitationChange();
      } else {
        handleDeleteLimitation(operate, type);
      }
    };

    const handleChangeDealId = (values) => {
      const lastValue = _.last(values);
      if (typeof lastValue === 'string') {
        values[values.length - 1] = { label: lastValue, value: lastValue };
      }

      let limitationValue = this.props.model.limitationValue;
      let indexOFLimitationChange = limitationValue['other'].findIndex(
        (element) => element.type === 'dealId');
      if (indexOFLimitationChange > -1) {
        limitationValue['other'][indexOFLimitationChange].value = values;
      } else {
        limitationValue['other'].push({ value: values, type: 'dealId' });
      }
      this.forceUpdate();
    };

    const handleChangeSetting = (inventory, operate) => {
      this.setState({
        show: operate,
        inventory
      });
    };

    const { limitationValue, addonFeature, limitationSetting, taOptionsCache } = this.props.model;
    const { show = undefined, inventory, type, newOperate } = this.state;
    const handleChangeOperateForAlert = unicornkeywordName.includes(type)
      ? changeUnicornkeywordOperate
      : changeOperate;

    const defaultDealId: any = { value: [], type: 'dealId' };
    let dealId = defaultDealId;
    if (limitationValue['other']) {
      const dealIdValue = limitationValue['other'].find(function (element) {
        return element.type === 'dealId';
      });
      dealId = dealIdValue ? dealIdValue : defaultDealId;
    }
    const limitationsCanNotNull = this.props.model.limitationsCanNotNull;
    const renderLimitationSetting = (operationSet) => {
      return (
        <LimitationSetting
          taOptionsCache={taOptionsCache}
          limitationSetting={limitationSetting}
          permission={addonFeature}
          showInventory={show === operationSet}
          value={limitationValue[operationSet]}
          onChange={handleChange.bind(this, operationSet)}
          onChangeInventory={handleChangeSetting}
          operate={operationSet}
          inventorySelected={inventory}
          onDeleteLimitation={handleDeleteLimitation}
          onChangeOperate={handleChangeOperate}
          limitationsCanNotNull={limitationsCanNotNull}
          errors={_.get(this.state.errors, operationSet)}
        />
      );
    };
    const ppsLayout = _.get(this.props.model.limitationValue, 'include', []).find(limit => limit.type === 'ppsLayoutId');
    const subLayoutData = ppsLayout ? ppsLayout.value.filter(layout => layout.subLayout !== null || layout.subLayout !== undefined) : [];
    const dealIdHint = subLayoutData.map(data => data.subLayout !== null && data.subLayout !== undefined ? i18n.t(`limitationOptions.labels.ppsLayoutId.${data.label.toLowerCase().replace(/-|\s/g, '_')}`) + ' ' + i18n.t(`limitation.subLayoutId.hint.prefix`) + ` ${data.subLayout.join(' ' + i18n.t('limitation.subLayoutId.hint.or') + ' ')} ` + i18n.t(`limitation.subLayoutId.hint.suffix`) : '').join('\n');
    const estimateData = this.props.model.estimateData;
    return (
      <div>
        <fieldset className={styles.editLimitaionContainer}>
          <legend>
            <span>{'Audience Definition'}</span>
          </legend>
        </fieldset>
        <div className={styles.borderTop}/>
        <div className={styles.audienceDefinition}>
          <AudienceInfo
            loading={this.props.model.fetchingEstimateData}
            lowerBound={estimateData.lowerBound}
            upperBound={estimateData.upperBound}
            threshold={_.get(this.props.model.channelData, 'audienceLowestThreshold', 0)}
            estimateReady={estimateData.estimateReady}
          />
          <div className={styles.importAudience} onClick={this.props.model.showTAManagement}>
            <FontAwesomeIcon icon={faFileImport}/>
            <span>{i18n.t('editLimitation.buttons.importTargeting')}</span>
          </div>
        </div>
        <div className={styles.shadowCoverBottom}/>
        {this.props.model.operationSet.need &&
          <fieldset className={styles.editLimitaionContainer}>
            <legend>
              <span>{i18n.t('limitation.title.you.need')}</span>
            </legend>
            {this.props.renderGeneralFields &&
              <div className={styles.limitationSettingContainer}>
                <div className={styles.limitationSetting}>
                  <div className={styles.limitationSet}>
                    <div className={styles.generalTitle}>
                      <span>{i18n.t('limitation.labels.general')}</span>
                    </div>
                    {this.props.renderGeneralFields(this.props.model.limitationValue['include'], handleChange)}
                  </div>
                </div>
              </div>
            }
            {this.props.model.operationSet.need.map((operationSet) => (
              <div
                key={operationSet}
                className={styles.limitationSettingContainer}
              >
                {renderLimitationSetting(operationSet)}
              </div>
            ))}
          </fieldset>
        }
        {this.props.model.operationSet.notNeed &&
          <fieldset className={styles.editLimitaionContainer}>
            <legend>
              <span>{i18n.t('limitation.title.you.dont.need')}</span>
            </legend>
            {this.props.model.operationSet.notNeed.map((operationSet) => (
              <div
                key={operationSet}
                className={styles.limitationSettingContainer}
              >
                {renderLimitationSetting(operationSet)}
              </div>
            ))}
          </fieldset>
        }
        {this.props.model.operationSet.other && (addonFeature.includes('campaign_enablePMPDeal') || addonFeature.includes('creative_enablePMPDeal')) &&
          <fieldset className={styles.editLimitaionContainer}>
            <legend>
              <span>{i18n.t('limitation.title.other.need')}</span>
            </legend>
            <div className={styles.limitationSettingContainer}>
              <div className={styles.limitationSetting}>
                <div className={styles.limitationSet}>
                  <Form.Group as={Row} controlId={'dealId'}>
                    <Form.Label>{i18n.t('limitation.labels.dealId')}</Form.Label>
                    <div style={{ flex: '1 1' }}>
                      <Tags
                        value={dealId.value}
                        onChange={handleChangeDealId}
                        operate={'dealId'}
                        showPlus={false}
                        placeholder={(dealId.value && dealId.value.length === 0) ? i18n.t('limitation.addDealId') : ''}
                        className={styles.tags}
                      />
                      <span className={styles.dealIdHint}>{dealIdHint}</span>
                    </div>
                  </Form.Group>
                </div>
              </div>
            </div>
          </fieldset>
        }
        {this.state.modalShown && (
          <ChangeOperateAlertDialog
            changeOperate={handleChangeOperateForAlert}
            dismiss={hideModal}
            type={type}
            newOperate={newOperate}
          />
        )}
      </div>
    );
  }

  render () {
    return (
      <div>
        {this.props.model.state.loading && <LoadingIndicator/>}
        {this.renderLimitationPage(this.props)}
      </div>
    );
  }
}

import React from 'react';
import Toggle from 'components/common/Toggle/Toggle';
import classnames from 'classnames/bind';
import styles from './formikSwitch.module.scss';
import { getFieldErrors } from 'utils/FormikUtils';
import Tips from '../Tips/Tips';
const cx = classnames.bind(styles);
export class FormikSwitch extends React.PureComponent<any> {
  classNames: any;
  constructor (props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.classNames = classnames.bind(styles);
  }

  handleChange (e) {
    const {
      field: { name },
      form
    } = this.props;
    form.setFieldValue(name, e.target.checked);
    form.setFieldTouched(name, true);
    this.props.onChange && this.props.onChange(e.target.checked);
  }
  render () {
    const {
      disabled = false,
      form,
      field: { name, value = false },
      large = false
    } = this.props;
    const errors = getFieldErrors(form, name);
    const hasError = !!errors;
    const toggleClassName = cx('switchComponent', {
      [this.props.className]: !!this.props.className,
      tips: true,
      error: hasError
    });

    return (
      <>
        <Toggle
          className={toggleClassName}
          checked={!!value}
          name={name}
          onChange={this.handleChange}
          disabled={disabled}
          large={large}
        />
        {errors && <Tips>{errors}</Tips>}
      </>
    );
  }
}

import React, { useMemo, useRef } from 'react';
import { Slider } from 'components/common/Slider/Slider';
import { DefaultSliderModel } from 'components/common/Slider/SliderModel';
// import { Button } from 'react-bootstrap';
import _ from 'lodash';
import styles from './nativeCreativeDetail.module.scss';
import { CreativeListRecord } from 'core/creative/Creative';
import parser from 'html-react-parser';
import { useFBVideoPreview } from 'hooks/GoCreativePreview/useFBVideoPreview';
import { useTiktokVideoPreview } from 'hooks/GoCreativePreview/useTiktokVideoPreview';

export const OneForAllVideoCreativeDetail: React.FC<{ creative: CreativeListRecord }> = ({
  creative
}) => {

  const previewAreaWidth = 600;
  const previewAreaHeight = 680;
  const typeProperties = useMemo(() => ({
    title: _.get(creative, 'bannerExtra.title'),
    description: _.get(creative, 'bannerExtra.description'),
    linkUrl: _.get(creative, 'bannerExtra.fbLandingUrl'),
    pageId: _.get(creative, 'bannerExtra.pageId'),
    callToAction: _.get(creative, 'bannerExtra.callToAction'),
    videoId: _.get(creative, 'bannerExtra.videoId'),
    thumbnailUrl: _.get(creative, 'bannerExtra.thumbnailUrl'),
    storyVideoId: _.get(creative, 'bannerExtra.storyVideoId'),
    storyThumbnailUrl: _.get(creative, 'bannerExtra.storyThumbnailUrl'),
    tiktokVideoId: _.get(creative, 'bannerExtra.tiktokVideoId'),
    shortMessage: _.get(creative, 'bannerExtra.shortMessage')
  }), [creative]);

  const {
    previewData: fbPreviewData
  } = useFBVideoPreview(
    creative.advertiserId,
    typeProperties
  );
  const {
    previewData: tiktokPreviewData
  } = useTiktokVideoPreview(
    creative.advertiserId,
    typeProperties
  );

  const fbPreviewRefs = useRef({});
  const tiktokPreviewRefs = useRef({});

  const onIframeLoaded = (previewType, previewRefs) => {
    if (!previewRefs.current[previewType]) {
      return;
    }
    previewRefs.current[previewType].className = '';
    previewRefs.current[previewType].querySelector(`.${styles.loadingCircle}`).style.display = 'none';
  };

  const renderFBPreviews = () => {
    return Object.keys(fbPreviewData).map(previewType => {
      let content;
      const iframeString = fbPreviewData[previewType];
      if (iframeString) {
        const iframe = parser(iframeString);
        if (React.isValidElement(iframe)) {
          content = React.cloneElement(iframe, {
            onLoad: _.partial(onIframeLoaded, previewType, fbPreviewRefs)
          });
        }
      }
      return (
        <div
          key={previewType}
          ref={el => fbPreviewRefs.current[previewType] = el}
          className={styles.previewPlaceholder}
        >
          <div className={styles.loadingCircle} />
          {content}
        </div>
      );
    });
  };

  const renderTiktokPreviews = () => {

    // const renderNewTabButton =(url) => {
    //   const goTo = () => {
    //     window.open(url, '_blank');
    //   };
    //   return (
    //     <div className={styles.buttonArea}>
    //       <Button size='lg' onClick={goTo}>
    //         {"Preview ads"}
    //       </Button>
    //     </div>
    //   );
    // };

    return Object.keys(tiktokPreviewData).map(previewType => {
      let content;
      const iframeString = _.get(tiktokPreviewData[previewType], 'iframe', '');

      if (iframeString) {
        const iframe = parser(iframeString);
        if (React.isValidElement(iframe)) {
          content = React.cloneElement(iframe, {
            width: 300,
            height: 520,
            style: {
              border: 'none'
            },
            onLoad: _.partial(onIframeLoaded, previewType, tiktokPreviewRefs)
          });
        }
      }
      return (
        <div
          key={previewType}
          ref={el => tiktokPreviewRefs.current[previewType] = el}
          className={styles.previewPlaceholder}
        >
          <div className={styles.loadingCircle} />
          {content}
        </div>
      );
    });

    // return Object.keys(tiktokPreviewData).map(previewType => {
    //   const previewLink = _.get(tiktokPreviewData[previewType], 'previewLink', '');

    //   return (
    //     <div
    //       key={previewType}
    //       ref={el => tiktokPreviewRefs.current[previewType] = el}
    //       className={styles.previewPlaceholder}
    //     >
    //       {!previewLink && <div className={styles.loadingCircle} />}
    //       {renderNewTabButton(previewLink)}
    //     </div>
    //   );
    // });
  };

  return (
    <div className={styles.nativeCreativeDetail}>
      <Slider model={new DefaultSliderModel(previewAreaWidth, previewAreaHeight, true, false, 0, false)}>
        {renderFBPreviews()}
        {renderTiktokPreviews()}
      </Slider>
    </div>
  );
};

import React from 'react';
import { FormikField } from 'components/common/formik/FormikField';
import { Formik, Form } from 'formik';
import { CampaignGroupFormData } from './CampaignGroupFormModel';
import i18n from 'i18n';
import styles from './campaignGroupForm.module.scss';
import { Link, Redirect } from 'react-router-dom';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import NavigationPrompt from 'components/common/NavigationPrompt/NavigationPrompt';
import _ from 'lodash';
import { CampaignGroupChannel } from 'core/campaignGroup/CampaignGroup';
import { FBCampaignForm } from './FBCampaignForm/FBCampaignForm';
import { TiktokCampaignForm } from './TiktokCampaignForm/TiktokCampaignForm';
import { CampaignGroupBudgetManagement } from './CampaignGroupBudgetManagement/CampaignGroupBudgetManagement';
import { RTBCampaignGroupForm } from './RTBCampaignGroupForm/RTBCampaignGroupForm';
import { TitleWithBreadcrumbs } from 'components/TitleArea/TitleWithBreadcrumbs';
import { Trans } from 'react-i18next';
import { FbAdSet } from 'core/fbAdSet/FbAdSet';
import { TiktokAdGroup } from 'core/tiktokAdGroup/TiktokAdGroup';
import { RtbCampaignBasic } from 'core/rtbCampaign/RtbCampaign';
import { getFieldErrors } from 'utils/FormikUtils';

export const CampaignGroupForm: React.FC<{ useModel: any }> = (props) => {

  const modelData: CampaignGroupFormData = props.useModel();
  if (!modelData.initCampaignGroup) {
    return <LoadingIndicator />;
  }

  return (
    <div className={styles.campaignGroupForm}>
      <NavigationPrompt when={!modelData.redirectData}/>
      {modelData.loading && <LoadingIndicator />}
      {modelData.redirectData && <Redirect to={modelData.redirectData} />}
      <div className={styles.titleArea}>
        <TitleWithBreadcrumbs
          title={modelData.title}
          routes={modelData.breadcrumbs}
        />
      </div>
      <Formik
        initialValues={modelData.initCampaignGroup}
        enableReinitialize
        onSubmit={modelData.save}
        validate={modelData.validate}
        validateOnBlur={false}
      >
        {({ values, errors, touched, submitCount, setFieldValue }) => {
          const isCBO = _.get(values, 'autoOptimise', false);
          const getBudgetTips = () => {
            if (getFieldErrors({ errors, touched, submitCount }, 'budget')) {
              return;
            }
            if (!modelData.showRemainBudget(values)) {
              return;
            }
            const currentBudget = _.defaultTo(values.budget, 0).toString();
            return (
              <Trans i18nKey='campaign.descriptions.remainBudget'>
                Remain budget <span className='text-dark'>{{ currency: modelData.order.currency }}{{ remainBudget: modelData.totalBudget - (+currentBudget) }}</span>
              </Trans>
            );
          };
          const cboHint = modelData.getCboChangeHint(values);
          const onEditBudgetBtnClick = () => {
            modelData.onCBOChange(values, setFieldValue, false);
          };

          const channelFormatter = value => {
            return i18n.t(`campaignGroup.labels.channel_${value.toLowerCase()}`);
          };
          const renderForm = (channel: CampaignGroupChannel | any) => {
            switch (channel) {
              case CampaignGroupChannel.FB:
                return (
                  <FBCampaignForm
                    formType={modelData.formType}
                    fbAdAccountOptions={modelData.fbAdAccountOptions}
                    isCBO={isCBO}
                    canEditBidStrategy={modelData.canEditBidStrategy}
                    currency={modelData.order.currency}
                    fbAdSets={modelData.goCampaignList as FbAdSet[]}
                    fbAdSetBudgets={_.get(values, 'fb.adset_budgets')}
                    onEditAdSetBudgetsBtn={onEditBudgetBtnClick}
                    getBudgetTips={getBudgetTips}
                  />
                );
              case CampaignGroupChannel.TIKTOK:
                return (
                  <TiktokCampaignForm
                    formType={modelData.formType}
                    tiktokAdAccountOptions={modelData.tiktokAdAccountOptions}
                    isCBO={isCBO}
                    canEditBidStrategy={false}
                    currency={modelData.order.currency}
                    tiktokAdGroups={modelData.goCampaignList as TiktokAdGroup[]}
                    // TODO: not yet implement properties of adGroup_budgets
                    tiktokAdGroupBudgets={[]}
                    onEditAdGroupBudgetsBtn={onEditBudgetBtnClick}
                    getBudgetTips={getBudgetTips}
                  />
                );
              case CampaignGroupChannel.RTB:
              case CampaignGroupChannel.RETAIL_MEDIA:
              default:
                return (
                  <RTBCampaignGroupForm
                    formType={modelData.formType}
                    isCBO={isCBO}
                    canEditBidStrategy={modelData.canEditBidStrategy}
                    currency={modelData.order.currency}
                    campaigns={modelData.goCampaignList as RtbCampaignBasic[]}
                    campaignBudgets={_.get(values, 'rtb.campaign_budgets')}
                    onEditCampaignBudgetsBtn={onEditBudgetBtnClick}
                    getBudgetTips={getBudgetTips}
                  />
                );
            }
          };

          return (
            <>
              <Form>
                <div className={styles.formContent}>
                  {
                    modelData.budgetManagementModalData &&
                    <CampaignGroupBudgetManagement
                      {...modelData.budgetManagementModalData}
                    />
                  }
                  <FormikField.Input
                    label={i18n.t('campaignGroup.labels.name')}
                    name='name'
                  />
                  {modelData.formType === 'create' ?
                    <FormikField.Select
                      label={i18n.t('campaignGroup.labels.channel')}
                      name='channel'
                      simpleValue
                      options={modelData.campaignGroupTypeOptions}
                      className={styles.selectField}
                      onChange={_.partial(modelData.onChannelChange, setFieldValue)}
                    /> :
                    <FormikField.Label
                      label={i18n.t('campaignGroup.labels.channel')}
                      name='channel'
                      formatter={channelFormatter}
                    />
                  }
                  <FormikField.Switch
                    label={i18n.t('campaignGroup.labels.autoOptimise')}
                    name='autoOptimise'
                    onChange={_.partial(modelData.onCBOChange, values, setFieldValue)}
                    disabled={!!cboHint}
                    fieldContentWidth={65}
                    hint={cboHint}
                  />
                  {renderForm(values.channel)}
                </div>
                <div className={styles.formButtons}>
                  <button
                    type='submit'
                    className='btn btn-primary btn-sm'
                  >
                    {i18n.t('common.buttons.submit')}
                  </button>
                  <Link
                    to={`${modelData.cancelTargetPath}`}
                    className='btn btn-secondary btn-sm'
                  >
                    {i18n.t('common.buttons.cancel')}
                  </Link>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

import { FormContentModel } from './FormContentModel';
import _ from 'lodash';
import i18n from 'i18n';
import ImageForm from './ImageForm';
import { Creative } from 'core/creative/Creative';
import { formatBytes } from 'utils/StringUtil';
import { renderErrors } from './CreativeFormHintRenderFunction';
import ImageFormMutliple from './ImageFormMultiple';
import { FormField } from 'components/common/formik/FormikField';
const mimeDB = require('mime-db');

export type ImageFormProps = {
  readonly model: ImageFormModel;
  readonly renderHintModal: () => any;
  readonly hintModalData?: any;
  readonly setHintModalData: (modalData: any) => void;
  readonly basicFields: FormField[];
};

export type CreateImageFormProps = {
  readonly model: CreateImageFormModel;
  readonly renderHintModal: () => any;
  readonly hintModalData?: any;
  readonly setHintModalData: (modalData: any) => void;
  readonly basicFields: FormField[];
};

export class ImageFormModel implements FormContentModel {

  public needBannerUrl: boolean = true;

  addLimitation?: (operate: string, limitationType: string, label: string, value: string) => void;

  setLimitationHook (addLimitation: (operate: string, limitationType: string, label: string, value: string) => void): void {
    this.addLimitation = addLimitation;
  }

  getFormContent = (): any => {
    return ImageForm;
  }

  validateImage = (fileData) => {
    const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];
    if (!fileData || (!fileData.file && !fileData.url)) {
      return i18n.t('formValidate.labels.emptyError');
    }

    if (!fileData.file) {
      return;
    }

    const file = fileData.file;
    if (validTypes.indexOf(file.type) === -1) {
      const extensions = _.get(mimeDB[file.type], 'extensions', ['Unknown']);
      return i18n.t('creativeSetupFlow.labels.typeErrorHint', { type: extensions[0] });
    }

    const validWidthHeightMap = {
      300: [250],
      320: [50, 100],
      336: [280],
      728: [90],
      160: [600],
      468: [60]
    };

    const errors = [];
    const width = fileData.width;
    const height = fileData.height;
    if (!Object.keys(validWidthHeightMap).includes(width.toString()) || !validWidthHeightMap[width].includes(height)) {
      errors.push(i18n.t('creativeSetupFlow.labels.imageSizeErrorHint', { size: `${width} x ${height}` }));
    }

    if (file.size > 2097152) {
      errors.push(i18n.t('creativeSetupFlow.labels.storageErrorHint', { storage1: formatBytes(file.size), storage2: '2MB' }));
    }

    return errors.length > 0 ? renderErrors(errors) : undefined;
  }

  getInitTypeProperties () {
    return undefined;
  }

  getFormModelData (creative: Creative) {
    const creativeValueString = _.get(creative, 'basic.creativeValues', '{}');
    const creativeValue = JSON.parse(creativeValueString);
    const medias = _.omitBy({
      image: _.omitBy({
        url: _.get(creativeValue, 'imageUrl'),
        width: _.get(creativeValue, 'w'),
        height: _.get(creativeValue, 'h')
      }, _.isUndefined)
    }, _.isEmpty);
    const creativeBasic: any = _.omit(creative.basic, ['id', 'srcType', 'creativeValues']);
    return {
      basic: {
        ...creativeBasic,
        medias
      },
      limitations: creative.limitations
    };
  }
}

export class CreateImageFormModel extends ImageFormModel {

  getFormContent = () => {
    return ImageFormMutliple;
  }

  validateImages = (fileData: {
    [key: string]: {
      width: number,
      height: number,
      file: File
    }
  }) => {
    const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];
    const fileDataArray = fileData ? Object.values(fileData) : [];
    if (fileDataArray.length === 0) {
      return i18n.t('formValidate.labels.emptyError');
    }

    let typeErrorImageList: string[] = [];
    for (const data of fileDataArray) {
      const file = data.file;
      if (validTypes.indexOf(file.type) === -1) {
        typeErrorImageList.push(file.name);
      }
    }
    if (typeErrorImageList.length > 0) {
      return `${i18n.t('creativeSetupFlow.labels.imageMultipleTypeErrorHint')}: ${typeErrorImageList.join(', ')}`;
    }

    const validWidthHeightMap = {
      300: [250],
      320: [50, 100],
      336: [280],
      728: [90],
      160: [600],
      468: [60]
    };

    const errors: string[] = [];
    let widthHeightErrorImageList: string[] = [];
    for (const data of fileDataArray) {
      const width = data.width;
      const height = data.height;
      if (!Object.keys(validWidthHeightMap).includes(width.toString()) || !validWidthHeightMap[width].includes(height)) {
        widthHeightErrorImageList.push(data.file.name);
      }
    }
    widthHeightErrorImageList.length > 0 && errors.push(`${i18n.t('creativeSetupFlow.labels.imageMultipleSizeErrorHint')}: ${widthHeightErrorImageList.join(', ')}`);

    let sizeErrorImageList: string[] = [];
    for (const data of fileDataArray) {
      if (data.file.size > 2097152) {
        sizeErrorImageList.push(data.file.name);
        break;
      }
    }
    sizeErrorImageList.length > 0 && errors.push(`${i18n.t('creativeSetupFlow.labels.storageMultipleErrorHint', { storage: '2MB' })}: ${sizeErrorImageList.join(', ')}`);

    return errors.length > 0 ? renderErrors(errors) : undefined;
  }

  getFormModelData (creative: Creative) {
    const creativeBasic: any = _.omit(creative.basic, ['id', 'srcType', 'creativeValues']);
    return {
      basic: {
        ...creativeBasic,
        medias: {}
      },
      limitations: creative.limitations
    };
  }
}

import _ from 'lodash';

import client from './RestClient';
import { CampaignGroupChannel } from 'core/campaignGroup/CampaignGroup';
import { DraftCreateDTO, DraftValidationDTO, DraftPublishResponse } from 'core/draft/Draft';

export interface DraftWebService {
  createDraft (draftCreateDTOList: DraftCreateDTO[]): Promise<void>;
  getValidationInfos (draftCreateDTOList: DraftCreateDTO[]): Promise<DraftValidationDTO[]>;
  getDraft (channel: CampaignGroupChannel, draftId: string): Promise<any>;
  updateDraft (channel: CampaignGroupChannel, draftId: string, data: any): Promise<any>;
  publishDraft (channel: CampaignGroupChannel, draftId: string, data: any): Promise<DraftPublishResponse>;
  deleteDrafts (draftIds: (string | number)[]): Promise<void>;
}

export class RestfulDraftWebService implements DraftWebService {
  restClient: any;

  constructor (restClient: any = client) {
    this.restClient = restClient;
  }

  async createDraft (draftCreateDTOList: DraftCreateDTO[]): Promise<void> {
    await this.restClient.post('/v2/go-campaign/draft', draftCreateDTOList);
  }

  async getValidationInfos (draftCreateDTOList: DraftCreateDTO[]): Promise<DraftValidationDTO[]> {
    const request = _.map(draftCreateDTOList, draftCreateDTO => _.omitBy(draftCreateDTO, ['channel', 'numberOfCopies']));
    const response = await this.restClient.post('/v2/go-campaign/draft/validation-info', request);
    return _.get(response, 'data.records', []);
  }

  async getDraft (channel: CampaignGroupChannel, draftId: string): Promise<any> {
    const response = await this.restClient.get(`/v2/go-campaign/draft/${channel.toLowerCase()}/${draftId}`);
    return response.data;
  }

  async updateDraft (channel: CampaignGroupChannel, draftId: string, data: any): Promise<any> {
    return this.restClient.put(`/v2/go-campaign/draft/${channel.toLowerCase()}/${draftId}`, data);
  }

  async publishDraft (channel: CampaignGroupChannel, draftId: string, data: any): Promise<DraftPublishResponse> {
    const response = await this.restClient.post(`/v2/go-campaign/draft/${channel.toLowerCase()}/${draftId}/publish`, data);
    return response.data;
  }

  async deleteDrafts (draftIds: (string | number)[]): Promise<void> {
    return this.restClient.delete(`/v2/go-campaign/draft?draftIds=${draftIds.join(',')}`);
  }
}

import React, { useEffect, useState } from 'react';
import { notRoles } from 'core/permission/PermissionDSL';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { StickableTableContainer } from 'containers/StickableTableContainer/StickableTableContainer';
import { RecentGroupDropdown } from 'components/RecentGroupDropdown/RecentGroupDropdown';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { RoleNames } from 'core';
import styles from './productSets.module.scss';
import i18n from 'i18n';
import { SessionStorageHelper, SessionStorageItemKeys } from 'helper/StorageHelper';
import { AdvertiserManager, DefaultAdvertiserManager } from 'core/advertiser/AdvertiserManager';
import { SelectOptions } from 'components/common/commonType';
import { ProductSetList } from './ProductSetList/ProductSetList';
import { DateRangePicker } from 'components/common/DateRangePicker/DateRangePicker';
import moment from 'moment';
import { Route, Switch } from 'react-router-dom';
import { ProductSetDetail } from './ProductSetDetail/ProductSetDetail';
import { useCallAPI } from 'hooks/useCallAPI';

const defaultAdvertiserManager: AdvertiserManager = new DefaultAdvertiserManager();

export const ProductSets: React.FC<any> = () => {

  const [advertisers, setAdvertisers] = useState<SelectOptions[]>([]);
  const [advertiserId, setAdvertiserId] = useState(SessionStorageHelper.getNumberItem(SessionStorageItemKeys.ADVERTISER));
  const [startDate, setStartDate] = useState(moment().subtract(1, 'month').startOf('day').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().startOf('day').format('YYYY-MM-DD'));
  const {
    loading,
    callAPIs
  } = useCallAPI();

  useEffect(() => {
    callAPIs(
      [defaultAdvertiserManager.getAdvertiserOptions.bind(defaultAdvertiserManager)],
      (advertisers: SelectOptions[]) => {
        setAdvertisers(advertisers);
      }
    );
  }, [callAPIs]);

  const onDateRangeChange = (from: string | undefined, to: string | undefined) => {
    if (from) {
      setStartDate(from);
    }
    if (to) {
      setEndDate(to);
    }
  };

  const renderEditProductSet = props => {
    const retailId = props.location.state.retailId;
    const advertiserId = props.location.state.advertiser;
    const productSetId = props.match.params.id;
    return (
      <ProductSetDetail
        retailId={retailId}
        advertiserId={advertiserId}
        productSetId={productSetId}
      />
    );
  };

  const renderHomePage = () => (
    <StickableTableContainer>
      <div className={styles.container}>
        <div className={styles.titleArea}>
          {i18n.t('productSetHome.labels.title')}
          <div className={styles.filterArea}>
            <PermissionChecker
              permissionAware={notRoles(RoleNames.adsAdmin, RoleNames.adsReport, RoleNames.adsSales)}
            >
              <div className={styles.advertiserList}>
                <div className={styles.title}>
                  {i18n.t('productSetHome.labels.advertiserListTitle')}
                </div>
                <RecentGroupDropdown
                  value={advertiserId}
                  options={advertisers}
                  storageKey={SessionStorageItemKeys.ADVERTISER}
                  callback={setAdvertiserId}
                />
              </div>
            </PermissionChecker>
            <div className={styles.createDateRange}>
              <div className={styles.title}>
                {i18n.t('productSetHome.labels.createDayRangeTitle')}
              </div>
              <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                format={'YYYY-MM-DD'}
                onChange={onDateRangeChange}
                showTimePicker={false}
              />
            </div>
          </div>
        </div>
        <div className={styles.listArea}>
          <ProductSetList
            advertiserId={advertiserId}
            startDate={startDate}
            endDate={endDate}
          />
        </div>
      </div>
    </StickableTableContainer>
  );

  return (
    <>
    {loading && <LoadingIndicator/>}
    <Switch>
      <Route
        exact
        path={'/product-sets/:id/edit'}
        render={renderEditProductSet}
      />
      <Route
        render={renderHomePage}
      />
    </Switch>
    </>
  );
};

import React from 'react';

import './AppMenu.scss';

import i18n from 'i18n';
import { AppMenuItem } from './AppMenuItem';
import { AppMenuProps, AppMenuState } from './AppMenuBarModel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export class AppMenu extends React.Component<AppMenuProps, AppMenuState> {
  handler: number;

  constructor (props: any) {
    super(props);
    this.handler = 0;
    this.state = this.props.model.state;
    this.handler = this.props.model.event.add((model) => {
      this.setState(model.state);
    });
  }

  componentWillUnmount () {
    this.props.model.event.remove(this.handler);
  }

  mouseEnter = () => {
    this.props.model.mouseEnter();
  }

  mouseLeave = () => {
    this.props.model.mouseLeave();
  }

  expandOrCollapse = () => {
    this.props.model.expandOrCollapse();
  }

  render () {
    const model = this.props.model;
    const items = model.items.map((item) => <AppMenuItem model={item} key={item.path} />);
    const title = model.minimized ? '' : <div className='app-menu-sub-title' onClick={this.expandOrCollapse}>{i18n.t(model.title)}</div>;
    const icon = model.icon ? <FontAwesomeIcon icon={model.icon} className='app-menu-icon' /> : '';
    const itemsClass = model.minimized ? (this.state.hovered ? 'hovered' : 'minimized') : (this.state.expanded ? '' : 'minimized');
    const titleClass = model.minimized ? 'app-menu-icon-only' : 'app-menu-sub-title-bar';
    const indicatorClass = this.state.expanded ? 'arrow up' : 'arrow down';
    return (
      <div
        className='app-menu'
        onMouseEnter={this.mouseEnter}
        onMouseLeave={this.mouseLeave}
      >
        <div className={titleClass}>
          {icon}
          {title}
          <span className={indicatorClass} />
        </div>
        <div className={itemsClass}>
          {items}
        </div>
      </div>
    );
  }
}

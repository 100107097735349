import React from 'react';
import moment from 'moment';
import i18n from 'i18n';
import FormGroup from 'components/common/FormGroup/FormGroup';
import styles from './reconciliationReportDimension.module.scss';
import { MonthPicker } from 'components/common/MonthPicker/MonthPicker';
import { ReportContentModel } from '../ReportContentModel';
import ToggleButtons from 'components/common/ToggleButtons/ToggleButtons';

export const ReconciliationReportDimension: React.FC<{ model: ReportContentModel }> = ({ model }) => {

  const handleMonthChange = (date: string) => {
    const from = moment(date).startOf('month').format('YYYY-MM-DD HH:mm:ss');
    const to = moment(date).endOf('month').format('YYYY-MM-DD HH:mm:ss');
    model.updateDayRange(from, to);
  };

  return (
    <FormGroup label={i18n.t('report.labels.reportGran')} controlId='reportGran'>
      <div className={styles.reportGran}>
        <ToggleButtons
          name='reportGran'
          value={model.state.gran}
          options={model.reportGrans}
          onChange={model.updateGran}
        />
        <MonthPicker
          date={model.state.from}
          onChange={handleMonthChange}
        />
      </div>
    </FormGroup>
  );
};

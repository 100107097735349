import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import styles from './accountList.module.scss';
import i18n from 'i18n';

export function nameFormatter (cell: any, row: any): any {
  return (
    <div className={styles.nameCell}>
      <Link to={`/accounts/${row.id}`}>{cell}</Link>
      <div className={styles.id}>
        {'ID: ' + row.id}
      </div>
    </div>
  );
}

export function oemNameFormatter (cell: any, row: any): any {
  return (
    <div className={styles.nameCell}>
      <Link to={`/oem-accounts/${row.id}`}>{cell}</Link>
      <div className={styles.id}>
        {'ID: ' + row.id}
      </div>
    </div>
  );
}

export function sudoNameFormatter (handleOnChange, cell: any, row: any, rowIndex, state): any {
  return (
    <div className={styles.sudoNameCell}>
      <div className={styles.selectCheckBox}>
        <input
          type='checkbox'
          checked={state.accountId === row.id}
          onChange={handleOnChange}
          value={row.id}
          id={`input${row.id}`}
        />
        <label htmlFor={`input${row.id}`} />
      </div>
      <div>
        <span>{cell}</span>
        <div className={styles.id}>
          {'ID: ' + row.id}
        </div>
      </div>
    </div>
  );
}

export function statusFormatter (cell: any, row: any): any {
  return (
    <Fragment>
      {row.activated ? i18n.t('accounts.active') : i18n.t('accounts.inactive')}
    </Fragment>
  );
}

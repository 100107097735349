import React from 'react';
import { defaults, HorizontalBar } from 'react-chartjs-2';
import i18n from 'i18n';
import styles from './doctorChart.module.scss';
import _ from 'lodash';
import 'chartjs-plugin-datalabels';

const filteredColor = '#82ca9d';
const remianColor = '#8884d8';
export const DoctorChart: React.FC<{chartData: any[]}> = props => {

  defaults.global.animation = false;

  const remainSpaceChannelCount: number[] = [];
  const filteredReasonCount: number[] = [];
  const labels: string[] = [];
  props.chartData.forEach(data => {
    remainSpaceChannelCount.push(data.remainSpaceChannels.length);
    filteredReasonCount.push(data.filteredReasons.length);
    labels.push(i18n.t(`doctorTable.labels.${_.camelCase(data.type)}`));
  });

  const barHeight = 30;
  const barGap = 10;
  const legendHeight = 70;
  const chartHeight = barHeight * labels.length + (labels.length - 1) * barGap;
  return (
    <div className={styles.chartContainer}>
      <div className={styles.arrowContainer} style={{ height: chartHeight }}>
        <div className={styles.arrow}/>
      </div>
      <HorizontalBar
        height={chartHeight + legendHeight}
        data={{
          datasets: [{
            borderColor: remianColor,
            backgroundColor: remianColor,
            data: remainSpaceChannelCount,
            label: 'remain',
            categoryPercentage: 0.9
          },{
            borderColor: filteredColor,
            backgroundColor: filteredColor,
            data: filteredReasonCount,
            label: 'filtered',
            categoryPercentage: 0.9
          }],
          labels: labels
        }}
        options={{
          maintainAspectRatio: false,
          onClick: (event, elements) => {
            if (elements.length === 0) {
              return;
            }
            const chart = elements[0]._chart;
            const element = chart.getElementAtEvent(event)[0];
            const xLabel = chart.data.labels[element._index];
            const dataset = chart.data.datasets[element._datasetIndex];
            const value = dataset.data[element._index];
            console.log(dataset.label + ' at ' + xLabel + ': ' + value);
          },
          legend: {
            position: 'bottom',
            labels: {
              boxWidth: 15,
              generateLabels: (chart) => {
                const data = chart.data;
                return data.datasets.map(dataset => {
                  return {
                    text: i18n.t(`doctorChart.labels.${dataset.label}`),
                    strokeStyle: dataset.borderColor,
                    fillStyle: dataset.borderColor
                  };
                });
              }
            }
          },
          scales: {
            xAxes: [{
              gridLines: {
                display: false
              },
              ticks: {
                display: false
              },
              stacked: true
            }],
            yAxes: [{
              gridLines: {
                display: false
              },
              stacked: true
            }]
          },
          tooltips: {
            mode: 'nearest',
            position: 'nearest',
            callbacks: {
              title: (tooltipItems) => {
                if (!tooltipItems[0].yLabel) {
                  return;
                }
                return tooltipItems[0].yLabel;
              },
              label: (tooltipItems, data) => {
                if (!tooltipItems.xLabel) {
                  return;
                }
                const category = data.datasets[tooltipItems.datasetIndex].label;
                return `${i18n.t(`doctorChart.labels.${category}`)}: ${tooltipItems.xLabel}`;
              }
            }
          },
          plugins: {
            datalabels: {
              formatter: function (value) {
                return value.toString() === '0' ? '' :  value;
              },
              align: 'center',
              anchor: 'center',
              color: 'white',
              font: {
                size: '14'
              }
            }
          },
          layout: {
            padding: {
              left: 100,
              right: 100,
              top: 0,
              bottom: 0
            }
          }
        }}
      />
    </div>
  );
};

import React, { FunctionComponent } from 'react';
import i18next from 'i18n';
import styles from './agencyForm.module.scss';
import { connect } from 'formik';
import _ from 'lodash';
import { FormikField } from 'components/common/formik/FormikField';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { isProject } from 'core/permission/PermissionDSL';
import { Project } from 'core/project/Project';

const AgencyAddonForm: FunctionComponent<any> = (props) => {

  const addonFeatures = props.formik.values.addonFeatures;
  const onOrderAgcProfitSettingChange = (open) => {
    props.formik.setFieldValue('addonFeatures.company.agencyProfit', open);
  };
  return (
    <div className={styles.agencyFrom}>
      <fieldset>
        <legend>
          <span>{i18next.t('agency.addonGroup.company')}</span>
        </legend>
        <div className={styles.fieldContent}>
          <FormikField.Switch
            label={i18next.t(
              'agency.addonItem.company.provideReportToWorkflow'
            )}
            name='addonFeatures.company.provideReportToWorkflow'
          />
          <FormikField.Switch
            label={i18next.t(
              'agency.addonItem.company.singleOrderBudgetConstraint'
            )}
            name='addonFeatures.company.singleOrderBudgetConstraint'
          />
          <FormikField.Input
            label={i18next.t('agency.addonItem.company.orderBudgetMaximum')}
            name='addonProps.orderBudgetMaximum'
            inputColSm={4}
            disabled={!_.get(addonFeatures, 'company.singleOrderBudgetConstraint')}
          />
          <FormikField.Switch
            label={i18next.t(
              'agency.addonItem.company.lookalikeAudienceCreation'
            )}
            name='addonFeatures.company.lookalikeAudienceCreation'
          />
        </div>
      </fieldset>
      <fieldset>
        <legend>
          <span>{i18next.t('agency.addonGroup.campaign')}</span>
        </legend>
        <div className={styles.fieldContent}>
          <FormikField.Switch
            label={i18next.t('agency.addonItem.campaign.goGanAgcProfitSetting')}
            name='addonFeatures.campaign.orderAgcProfitSetting'
            onChange={onOrderAgcProfitSettingChange}
          />
          <FormikField.Switch
            label={i18next.t('agency.addonItem.campaign.customizeAdLogo')}
            name='addonFeatures.campaign.customizeAdLogo'
          />
        </div>
      </fieldset>
      <PermissionChecker permissionAware={isProject(Project.RETAIL)}>
        <fieldset>
          <legend>
            <span>{i18next.t('agency.addonGroup.creatives')}</span>
          </legend>
          <div className={styles.fieldContent}>
            <FormikField.Switch
              label={i18next.t('agency.addonItem.creatives.option_pilotTv')}
              name='addonFeatures.creatives.option_22'
            />
          </div>
        </fieldset>
      </PermissionChecker>
      <fieldset>
        <legend>
          <span>{i18next.t('agency.gogan.addonGroup.campaignLimitation')} </span>
        </legend>
        <div className={styles.fieldContent}>
          <FormikField.Switch
            label={i18next.t('agency.addonItem.campaignLimitation.country')}
            name='addonFeatures.campaignLimitation.country'
          />
          <FormikField.Switch
            label={i18next.t('agency.addonItem.campaignLimitation.city')}
            name='addonFeatures.campaignLimitation.city'
            disabled={!_.get(addonFeatures, 'campaignLimitation.country')}
            hint={i18next.t('agency.addonItem.dependenceHint', {
              addon: `${i18next.t('agency.addonGroup.campaignLimitation')} > ${i18next.t('agency.addonItem.campaignLimitation.country')}`
            })}
          />
          <FormikField.Switch
            label={i18next.t('agency.addonItem.campaignLimitation.spaceType')}
            name='addonFeatures.campaignLimitation.spaceType'
          />
          <FormikField.Switch
            label={i18next.t('agency.addonItem.campaignLimitation.device')}
            name='addonFeatures.campaignLimitation.device'
          />
          <FormikField.Switch
            label={i18next.t('agency.gogan.addonItem.campaignLimitation.target_audience')}
            name='addonFeatures.campaignLimitation.target_audience'
          />
          <FormikField.Switch
            label={i18next.t('agency.addonItem.campaignLimitation.adexchange')}
            name='addonFeatures.campaignLimitation.adexchange'
          />
          <FormikField.Switch
            label={i18next.t('agency.addonItem.campaignLimitation.domain')}
            name='addonFeatures.campaignLimitation.domain'
          />
          <FormikField.Switch
            label={i18next.t('agency.addonItem.campaignLimitation.enablePMPDeal')}
            name='addonFeatures.campaignLimitation.enablePMPDeal'
          />
        </div>
      </fieldset>
    </div>
  );
};

export default connect(AgencyAddonForm);

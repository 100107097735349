import React from 'react';
import styles from './status.module.scss';
import cx from 'classnames/bind';
import { CopyableOverlayTrigger } from 'components/CopyableOverlayTrigger/CopyableOverlayTrigger';

const classNames = cx.bind(styles);

export const getEffectiveStatusDefaultColor = (effectivStatus) => {
  const colorMap = {
    preapproved: 'success',
    pending_review: 'warning',
    pending_billing_info: 'warning',
    paused: 'black',
    campaign_paused: 'black',
    adset_paused: 'black',
    deactive: 'black',
    in_process: 'whiteTheme4',
    disapproved: 'danger',
    archived: 'danger',
    with_issues: 'danger',
    budget_remain: 'danger',
    deleted: 'danger'
  };

  const key = effectivStatus.toLowerCase();
  return colorMap[key] ? colorMap[key] : 'theme1';
};

export const Status: React.FC<{
  color: string,
  label: string,
  renderExtraInfo?: Function
}> = ({
  color,
  label,
  renderExtraInfo
}) => {

  return (
    <>
      <CopyableOverlayTrigger
        placement='bottom'
        overlay={(
          renderExtraInfo ?
            renderExtraInfo() :
            undefined
        )}
      >
        <div
          className={classNames('status', styles[color], {
            pointer: !!renderExtraInfo
          })}
        >
          {label}
        </div>
      </CopyableOverlayTrigger>
    </>
  );
};

import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { DatePicker } from 'components/common/DatePicker/DatePicker';
import { defaultFormat } from 'components/common/DateRangePicker/DateRangePicker';

import moment from 'moment';
import { getFieldErrors } from 'utils/FormikUtils';
import Tips from '../Tips/Tips';

export class FormikDatePicker extends Component<any> {

  handleChange = (newDate) => {
    const {
      form: { setFieldValue, setFieldTouched },
      field: { name }
    } = this.props;

    const date = moment(newDate, defaultFormat).format('YYYY-MM-DD HH:mm:ss');
    setFieldValue(name, date);
    setFieldTouched(name);
    this.props.onChange && this.props.onChange(date);
  }

  render () {
    const {
      field: { name }
    } = this.props;
    const dateValue = _.get(this.props, `form.values.${name}`);
    const errors = getFieldErrors(this.props.form, name);
    const date = moment(dateValue, 'YYYY-MM-DD HH:mm:ss').format(defaultFormat);
    return (
      <Fragment>
        <DatePicker
          {...this.props}
          onChange={this.handleChange}
          date={date}
          hasError={!!errors}
        />
        {errors && <Tips>{errors}</Tips>}
      </Fragment>
    );
  }
}

export default FormikDatePicker;

import React from 'react';
import styles from './tableRowToolBar.module.scss';
import cxBind from 'classnames/bind';

interface TableRowToolBarProps {
  className?: string;
}
const TableRowToolBar: React.FC<React.PropsWithChildren<TableRowToolBarProps>> = (props) => {
  const cx = cxBind.bind(styles);
  const children = React.Children.map(
    props.children,
    child => {
      if (!React.isValidElement(child)) {
        return child;
      }
      return React.cloneElement(child, { className: cx(child.props.className, 'toolBarBtn') });
    }
  );
  return (
    <div className={cx('tableRowToolBar', props.className)}>
      {children}
    </div>
  );
};

export default React.memo(TableRowToolBar);

import React from 'react';
import { ErrorBoundary } from 'components/common/ErrorBoundary/ErrorBoundary';

export const withErrorBoundary = (errorCode: string, WrapperComponent) => {

  const WithErrorBoundary = (props) => {
    return (
      <ErrorBoundary errorCode={errorCode}>
        <WrapperComponent {...props}/>
      </ErrorBoundary>
    );
  };

  WithErrorBoundary.displayName = `WithErrorBoundary(${getDisplayName(
    WrapperComponent
  )})`;
  return WithErrorBoundary;
};

function getDisplayName (WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

import { SelectOptions } from 'components/common/commonType';
import { Project } from 'core/project/Project';
import { createSelectOptionsFromEnum } from 'utils/SelectOptionsUtils';
import { CampaignGroupCreateRequest, CampaignGroupUpdateRequest, CampaignGroupWebService, RestfulCampaignGroupWebService } from 'ws/CampaignGroupWebService';
import { CampaignGroupChannel } from './CampaignGroup';

export interface CampaignGroupManager {
  createCampaignGroup (campaignGroup: CampaignGroupCreateRequest): Promise<number>;
  updateCampaignGroup (groupId: number | string, campaignGroup: CampaignGroupUpdateRequest): Promise<void>;
  getCampaignGroup (groupId: number | string): Promise<any>;
  getCampaignGroups (orderId: number | string): Promise<Array<any>>;
  deleteCampaignGroup (groupId: number): Promise<void>;
  updateCampaignGroupState (groupIds: (number | string)[], state: 'activate' | 'deactivate'): Promise<void>;
  getCampaignGroupOptions (): Promise<SelectOptions[]>;
  getOrderNumber (groupId: string | number): Promise<string>;
  // refreshFbAdSets (groupId: number | string): Promise<void>;
  getFBCampaignMinBudget (groupId: number | string): Promise<number>;
  getAdSetLifetimeBudgetOfCampaign (groupId: number | string): Promise<{[key: string]: number}>;
  getCampaignGroupChannelOptions (): SelectOptions[];
}

abstract class DefaultCampaignGroupManager implements CampaignGroupManager {
  webService: CampaignGroupWebService;

  constructor (
    webService: CampaignGroupWebService = new RestfulCampaignGroupWebService()
  ) {
    this.webService = webService;
  }

  async createCampaignGroup (campaignGroup: CampaignGroupCreateRequest): Promise<number> {
    return this.webService.createCampaignGroup(campaignGroup);
  }
  async updateCampaignGroup (groupId: number | string, campaignGroup: CampaignGroupUpdateRequest): Promise<void> {
    return this.webService.updateCampaignGroup(groupId, campaignGroup);
  }
  async getCampaignGroup (groupId: number | string): Promise<any> {
    return this.webService.getCampaignGroup(groupId);
  }
  async getCampaignGroups (orderId: number | string): Promise<any> {
    return this.webService.getCampaignGroups(orderId);
  }
  async deleteCampaignGroup (groupId: number): Promise<void> {
    return this.webService.deleteCampaignGroup(groupId);
  }
  async updateCampaignGroupState (groupIds: (number | string)[], state: 'activate' | 'deactivate'): Promise<void> {
    await this.webService.updateCampaignGroupState(groupIds, state);
  }
  async getCampaignGroupOptions (): Promise<SelectOptions[]> {
    return this.webService.getCampaignGroupOptions();
  }
  async getOrderNumber (campaignGroupId: string | number): Promise<string> {
    return this.webService.getOrderNumber(campaignGroupId);
  }
  // async refreshFbAdSets (groupId: number | string): Promise<void> {
  //   return this.webService.refreshFbAdSets(groupId);
  // }
  async getFBCampaignMinBudget (groupId: number | string): Promise<number> {
    return this.webService.getFBCampaignMinBudget(groupId);
  }
  async getAdSetLifetimeBudgetOfCampaign (groupId: number | string): Promise<{[key: string]: number}> {
    return this.webService.getAdSetLifetimeBudgetOfCampaign(groupId);
  }

  abstract getCampaignGroupChannelOptions (): SelectOptions[];
}

class RetailCampaignGroupManager extends DefaultCampaignGroupManager {
  getCampaignGroupChannelOptions (): SelectOptions[] {
    return createSelectOptionsFromEnum(CampaignGroupChannel, 'campaignGroup.labels.channel_');
  }
}

class GojekCampaignGroupManager extends DefaultCampaignGroupManager {

  invalidCampaignGroupChannels: string[] = [
    CampaignGroupChannel.RETAIL_MEDIA
  ];

  getCampaignGroupChannelOptions (): SelectOptions[] {
    return createSelectOptionsFromEnum(CampaignGroupChannel, 'campaignGroup.labels.channel_')
      .filter(option => !this.invalidCampaignGroupChannels.includes(option.value.toString()));
  }
}

export default process.env.REACT_APP_PROJECT === Project.RETAIL ?
  RetailCampaignGroupManager :
  GojekCampaignGroupManager;

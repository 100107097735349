import { SelectOptions } from 'components/common/commonType';
import { FbAdSetFormData } from 'containers/FbAdSets/FbAdSetSetupFlowPage/FbAdSetSetupFlowPageModel';
import { CampaignGroup, FBBidStrategy, FbObjective, GoCampaignGroupObjective } from 'core/campaignGroup/CampaignGroup';
import { FbAdSetWebService, RestfulFbAdSetWebService, wrapFbDayparting } from 'ws/FbAdSetWebService';
import { ADSET_DEFAULT_AGE_MAX, ADSET_DEFAULT_AGE_MIN, BillingEvent, FbAdSet, FBAppEvent, FbDestinationType } from './FbAdSet';
import _ from 'lodash';
import { GoCampaignOptimizationGoal } from 'core/goCampaign/GoCampaign';
import { getPriceValue } from 'helper/CurrencyHelper';
import i18n from 'i18n';
import { Order } from 'core/order/Order';

export interface FbAdSetManager {
  getAdSet (adSetId: number | string): Promise<FbAdSet>;
  getAdSets (groupId: number | string): Promise<FbAdSet[]>;
  createAdSet (orderId: string | number, group: CampaignGroup, fbAdSetFormData: FbAdSetFormData, fbCountries: any[]): Promise<void>;
  editAdSet (
    orderId: string | number,
    group: CampaignGroup,
    fbAdSetId: string | number,
    fbAdSetFormData: FbAdSetFormData,
    fbCountries: any[]
  ): Promise<void>;
  deleteAdSet (adSetId: number | string): Promise<void>;
  updateAdSetState (adSetsData: {
    goCampaignChannelId: (number | string),
    isDraft: boolean
  }[], state: 'activate' | 'deactivate'): Promise<void>;
  getMinBudget (adSetId: number | string): Promise<number>;
  getAdSetOptions (): Promise<SelectOptions[]>;
  getDefaultBillingEvent (campaignGroup);
  getBilliingEventOptions (campaignGroup, optimizationGoal);
  getDefaultOptimizationGoal (campaignGroup);
  getOptimizationGoalOptions (campaignGroup);
  getAppEventOptions (optimizationGoal);
  getDefaultDestinationType (campaignGroup);
  getFbGeoLocationDataByLimitation (geoLocationTA: any, fbCountries: any[]);
  wrapTargetingForServer (
    targetingFormData: {[key: string]: {
      type: string,
      value: any
    }[]},
    fbCountries: any[]
  ): any;
  getMinBudgetOfFBObject (
    currencyRate: number,
    bidAmount: number,
    bidStrategy: FBBidStrategy,
    billingEvent: BillingEvent,
    scheduleDateCount: number,
    order: Order,
    fbRecommendBudget?: number
  ): number;
  getCreateAdSetPayloadForServer (
    orderId: string | number,
    group: CampaignGroup,
    fbAdSetFormData: FbAdSetFormData,
    fbCountries: any[]
  ): any;
}

const adSetSelectOptionConfig = {
  [GoCampaignGroupObjective.AwarenessObjective.REACH]: {
    [GoCampaignOptimizationGoal.REACH]: [
      BillingEvent.IMPRESSIONS
    ],
    [GoCampaignOptimizationGoal.IMPRESSIONS]: [
      BillingEvent.IMPRESSIONS
    ]
  },
  [GoCampaignGroupObjective.ConsiderationObjective.LINK_CLICKS]: {
    [GoCampaignOptimizationGoal.LINK_CLICKS]: [
      BillingEvent.IMPRESSIONS,
      BillingEvent.LINK_CLICKS
    ],
    [GoCampaignOptimizationGoal.IMPRESSIONS]: [
      BillingEvent.IMPRESSIONS
    ]
  },
  [GoCampaignGroupObjective.ConversionObjective.CONVERSIONS]: {
    [GoCampaignOptimizationGoal.OFFSITE_CONVERSIONS]: [
      BillingEvent.IMPRESSIONS
    ]
  },
  [FbObjective.OUTCOME_AWARENESS]: {
    [GoCampaignOptimizationGoal.REACH]: [
      BillingEvent.IMPRESSIONS
    ],
    [GoCampaignOptimizationGoal.IMPRESSIONS]: [
      BillingEvent.IMPRESSIONS
    ]
  },
  [FbObjective.OUTCOME_TRAFFIC]: {
    [GoCampaignOptimizationGoal.LINK_CLICKS]: [
      BillingEvent.IMPRESSIONS,
      BillingEvent.LINK_CLICKS
    ],
    [GoCampaignOptimizationGoal.IMPRESSIONS]: [
      BillingEvent.IMPRESSIONS
    ]
  },
  [FbObjective.OUTCOME_SALES]: {
    [GoCampaignOptimizationGoal.OFFSITE_CONVERSIONS]: [
      BillingEvent.IMPRESSIONS
    ]
  }
};

const fbDefaultGoals = {
  [GoCampaignGroupObjective.AwarenessObjective.REACH]: GoCampaignOptimizationGoal.REACH.toString(),
  [GoCampaignGroupObjective.ConsiderationObjective.LINK_CLICKS]: GoCampaignOptimizationGoal.LINK_CLICKS.toString(),
  [GoCampaignGroupObjective.ConversionObjective.CONVERSIONS]: GoCampaignOptimizationGoal.OFFSITE_CONVERSIONS.toString(),
  [FbObjective.OUTCOME_AWARENESS]: GoCampaignOptimizationGoal.REACH.toString(),
  [FbObjective.OUTCOME_TRAFFIC]: GoCampaignOptimizationGoal.LINK_CLICKS.toString(),
  [FbObjective.OUTCOME_SALES]: GoCampaignOptimizationGoal.OFFSITE_CONVERSIONS.toString()
};
export class DefaultFbAdSetManager implements FbAdSetManager {

  webService: FbAdSetWebService;

  constructor (
    webService: FbAdSetWebService = new RestfulFbAdSetWebService()
  ) {
    this.webService = webService;
  }

  async getAdSet (adSetId: number | string): Promise<FbAdSet> {
    return this.webService.getAdSet(adSetId);
  }

  async getAdSets (groupId: number | string): Promise<FbAdSet[]> {
    return this.webService.getAdSets(groupId);
  }

  async createAdSet (orderId: string | number, group: CampaignGroup, fbAdSetFormData: FbAdSetFormData, fbCountries: any[]): Promise<void> {
    const createFbAdSetPayload = this.getCreateAdSetPayloadForServer(orderId, group, fbAdSetFormData, fbCountries);
    return this.webService.createAdSet(createFbAdSetPayload);
  }

  async editAdSet (orderId: string | number, group: CampaignGroup, fbAdSetId: string | number, fbAdSetFormData: FbAdSetFormData, fbCountries: any[]): Promise<void> {
    return this.webService.editAdSet(orderId, group, fbAdSetId, {
      ...fbAdSetFormData,
      targeting: fbAdSetFormData.targeting ? this.wrapTargetingForServer(fbAdSetFormData.targeting, fbCountries) : undefined
    });
  }

  async deleteAdSet (adSetId: number | string): Promise<void> {
    return this.webService.deleteAdSet(adSetId);
  }

  async updateAdSetState (adSetsData: {
    goCampaignChannelId: (number | string),
    isDraft: boolean
  }[], state: 'activate' | 'deactivate'): Promise<void> {
    return this.webService.updateAdSetState(adSetsData, state);
  }

  async getMinBudget (adSetId: number | string): Promise<number> {
    return this.webService.getMinBudget(adSetId);
  }

  async getAdSetOptions (): Promise<SelectOptions[]> {
    return this.webService.getAdSetOptions();
  }

  getDefaultOptimizationGoal (campaignGroup) {
    const objective = campaignGroup.objective;
    return fbDefaultGoals[objective];
  }

  getOptimizationGoalOptions (campaignGroup) {
    const objective = campaignGroup.objective;
    const optimizationGoals = adSetSelectOptionConfig[objective];
    return optimizationGoals ? Object.keys(optimizationGoals).map(goal => ({
      label: i18n.t(`adSet.optimizationGoal.${goal.toLowerCase()}`),
      value: goal
    })) : [];
  }

  getDefaultBillingEvent (campaignGroup) {
    const objective = campaignGroup.objective;
    const optimizationGoals = adSetSelectOptionConfig[objective];
    if (!optimizationGoals) {
      return;
    }
    const optimizationGoal = fbDefaultGoals[objective];
    const billingEvents = optimizationGoals[optimizationGoal];
    return billingEvents && billingEvents.length > 0 ?
      billingEvents[0] : undefined;
  }

  getBilliingEventOptions (campaignGroup, optimizationGoal) {
    const objective = campaignGroup.objective;
    const optimizationGoals = adSetSelectOptionConfig[objective];
    if (!optimizationGoals) {
      return [];
    }
    const billingEvents = optimizationGoals[optimizationGoal];
    return billingEvents ? billingEvents.map(event => ({
      label: i18n.t(`adSet.billingEvent.${event.toLowerCase()}`),
      value: event
    })) : [];
  }

  getMinBudgetOfFBObject (
    currencyRate: number,
    bidAmount: number,
    bidStrategy: FBBidStrategy,
    billingEvent: BillingEvent,
    scheduleDateCount: number,
    order: Order,
    fbRecommendBudget?: number
  ) {
    const minDailyBudgetOfAutoBidMap = {
      [BillingEvent.IMPRESSIONS]: 0.5 * currencyRate,
      [BillingEvent.LINK_CLICKS]: 2.50 * currencyRate
    };
    const minDailyBudgetOfBidCapMap = {
      [BillingEvent.IMPRESSIONS]: bidAmount,
      [BillingEvent.LINK_CLICKS]: bidAmount * 5
    };
    const minDailyBudget = bidStrategy === FBBidStrategy.LOWEST_COST_WITHOUT_CAP ?
      minDailyBudgetOfAutoBidMap[billingEvent] :
      Math.max(minDailyBudgetOfBidCapMap[billingEvent], minDailyBudgetOfAutoBidMap[billingEvent]);
    let minBudget = minDailyBudget * scheduleDateCount * 2;
    if (fbRecommendBudget !== undefined) {
      minBudget = Math.max(fbRecommendBudget, minBudget);
    }
    return getPriceValue(order.currency, minBudget / (1 - order.orderMargin - order.sysMargin));
  }

  getAppEventOptions (optimizationGoal) {
    if (optimizationGoal === GoCampaignOptimizationGoal.OFFSITE_CONVERSIONS) {
      return Object.keys(FBAppEvent).map(value => ({ label: _.startCase(_.lowerCase(value)), value }));
    }
    return undefined;
  }

  getDefaultDestinationType = (campaignGroup) => {
    const defaultDestinationType = {
      [FbObjective.OUTCOME_AWARENESS]: '',
      [FbObjective.OUTCOME_TRAFFIC]: '',
      [FbObjective.OUTCOME_SALES]: '',
      [FbObjective.OUTCOME_LEADS]: FbDestinationType.ON_AD.toString(),
      [FbObjective.OUTCOME_ENGAGEMENT]: FbDestinationType.ON_POST.toString()
    };
    const objective = _.get(campaignGroup, 'fb.objective');
    return defaultDestinationType[objective] ? defaultDestinationType[objective] : '';
  }

  getFbGeoLocationDataByLimitation (geoLocationTA: any, fbCountries: any[]) {
    const limits = _.get(geoLocationTA, 'value');
    if (!Array.isArray(limits) || geoLocationTA.type !== 'geo_locations') {
      return {
        regions: [],
        countries: [],
        cities: []
      };
    }
    const citiesOrRegionsData = fbCountries.reduce<{[key: string]: {
      isCity: boolean,
      country: string | number
    }}>((acc, countryOption) => {
      countryOption.options &&
      countryOption.options.forEach(cityOption => acc[cityOption.value.toString()] = {
        isCity: cityOption.isCity,
        country: countryOption.value
      });
      return acc;
    }, {});
    return limits.reduce<any>((acc, option) => {
      const cityOrRegionData = citiesOrRegionsData[option.value.toString()];
      if (cityOrRegionData) {
        if (cityOrRegionData.isCity) {
          acc.cities.push({ key: option.value });
        } else {
          acc.regions.push({ key: option.value });
        }
      } else {
        acc.countries.push(option.value);
      }
      return acc;
    }, {
      regions: [],
      countries: [],
      cities: []
    });
  }

  wrapTargetingForServer (
    targetingFormData: {[key: string]: {
      type: string,
      value: any
    }[]},
    fbCountries: any[]
  ) {
    const result = {};
    const includeTargeting = targetingFormData.include ? targetingFormData.include : [];
    const ageMin = includeTargeting.find(targeting => targeting.type === 'age_min');
    const ageMax = includeTargeting.find(targeting => targeting.type === 'age_max');
    result['age_min'] = ageMin ? ageMin.value : ADSET_DEFAULT_AGE_MIN;
    result['age_max'] = ageMax ? ageMax.value : ADSET_DEFAULT_AGE_MAX;
    const osTargeting = includeTargeting.find(targeting => targeting.type === 'user_os');
    const fbOSDeviceMap = {
      iOS: {
        Smartphone: 'iPhone',
        Tablet: 'iPad'
      },
      Android: {
        Smartphone: 'Android_Smartphone',
        Tablet: 'Android_Tablet'
      }
    };
    includeTargeting.forEach(targeting => {
      if (targeting.type === 'geo_locations') {
        const values = this.getFbGeoLocationDataByLimitation(targeting, fbCountries);
        result[targeting.type] = _.omitBy({
          countries: _.uniq(values.countries),
          cities: values.cities.length > 0 ? values.cities : undefined,
          regions: values.regions.length > 0 ? values.regions : undefined
        }, _.isUndefined);
      } else if (targeting.type === 'publisher_platforms') {
        result[targeting.type] = targeting.value.map(v => v.value);
      } else if (targeting.type === 'segment') {
        result['custom_audiences'] = targeting.value.map(s => ({ id: s.value }));
      } else if (targeting.type === 'user_device') {
        const deviceValues: string[] = [];
        if (osTargeting) {
          osTargeting.value.forEach(osValue => {
            targeting.value.forEach(deviceValue => {
              const device = _.get(fbOSDeviceMap, `${osValue.value}.${deviceValue.value}`);
              device && deviceValues.push(device);
            });
          });
        } else {
          targeting.value.forEach(deviceValue => {
            ['Android', 'iOS'].forEach(osValue => {
              const device = _.get(fbOSDeviceMap, `${osValue}.${deviceValue.value}`);
              device && deviceValues.push(device);
            });
          });
        }
        result[targeting.type] = deviceValues;
      } else if (targeting.type === 'genders') {
        result[targeting.type] = targeting.value === -1 ? [] : [targeting.value];
      } else {
        result[targeting.type] = Array.isArray(targeting.value) ? targeting.value.map(value => value.value) : targeting.value;
      }
    });
    const excludeTargeting = targetingFormData.exclude ? targetingFormData.exclude : [];
    excludeTargeting.forEach(targeting => {
      if (targeting.type === 'geo_locations') {
        const values = this.getFbGeoLocationDataByLimitation(targeting, fbCountries);
        result['excluded_geo_locations'] = _.omitBy({
          countries: _.uniq(values.countries),
          cities: values.cities.length > 0 ? values.cities : undefined,
          regions: values.regions.length > 0 ? values.regions : undefined
        }, _.isUndefined);
      } else if (targeting.type === 'segment') {
        result['excluded_custom_audiences'] = targeting.value.map(s => ({ id: s.value }));
      } else {
        result[targeting.type] = targeting.value;
      }
    });
    return result;
  }

  getCreateAdSetPayloadForServer (orderId: string | number, group: CampaignGroup, fbAdSetFormData: FbAdSetFormData, fbCountries: any[]) {
    return {
      ..._.omit(fbAdSetFormData, ['lifetime_budget', 'bid_amount', 'hasSpendLimits', 'lifetime_min_spend_target', 'lifetime_spend_cap', 'dayPart']),
      orderId,
      groupId: group.groupId,
      configured_status: 'ACTIVE',
      effective_status: 'ACTIVE',
      campaign_id: _.get(group, 'fb.id'),
      go_lifetime_budget: fbAdSetFormData.lifetime_budget === '' ? null : fbAdSetFormData.lifetime_budget,
      go_bid_amount: fbAdSetFormData.bid_amount,
      bid_strategy: group.autoOptimise ? null : fbAdSetFormData.bid_strategy,
      go_lifetime_min_spend_target: fbAdSetFormData.lifetime_min_spend_target === '' ? 0 : fbAdSetFormData.lifetime_min_spend_target,
      go_lifetime_spend_cap: fbAdSetFormData.lifetime_spend_cap === '' ? 0 : fbAdSetFormData.lifetime_spend_cap,
      frequency_control_specs: fbAdSetFormData.frequency_control_specs ? [fbAdSetFormData.frequency_control_specs] : undefined,
      promoted_object: fbAdSetFormData.optimization_goal === GoCampaignOptimizationGoal.OFFSITE_CONVERSIONS ? {
        application_id: '830829963645099',
        custom_event_type: _.get(fbAdSetFormData, 'promoted_object.custom_event_type', 'PURCHASE'),
        object_store_url: _.get(fbAdSetFormData, 'targeting.user_os', [])[0] === 'Android' ?
          'http://play.google.com/store/apps/details?id=com.gojek.app' :
          'http://itunes.apple.com/app/id944875099'
      } : undefined,
      destination_type: fbAdSetFormData.destination_type ? fbAdSetFormData.destination_type : '',
      adset_schedule: fbAdSetFormData.dayPart ? wrapFbDayparting(fbAdSetFormData) : '[]',
      pacing_type: group.autoOptimise ? undefined : (fbAdSetFormData.dayPart ? ['day_parting'] : []),
      targeting: fbAdSetFormData.targeting ? this.wrapTargetingForServer(fbAdSetFormData.targeting, fbCountries) : undefined
    };
  }
}

import React, { useRef, useState } from 'react';
import { Formik, Form } from 'formik';
import { FormikField } from 'components/common/formik/FormikField';
import i18n from 'i18n';
import styles from './goSegmentForm.module.scss';
import { Redirect } from 'react-router-dom';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import NavigationPrompt from 'components/common/NavigationPrompt/NavigationPrompt';
import { SelectOptions } from 'components/common/commonType';
import { GoSegmentFormData, GoSegmentType } from 'core/goSegment/GoSegment';
import { FormikErrorScroller } from 'components/common/formik/FormikErrorScroller';
import { GoSegmentFormBasicFields } from './GoSegmentFormBasicFields';
import cx from 'classnames/bind';
import { SegmentCountButton } from './SegmentCountButton';
import _ from 'lodash';

const classNames = cx.bind(styles);

export const GoSegmentForm: React.FC<{
  goLast: () => void,
  initGoSegment: GoSegmentFormData,
  advertisers: SelectOptions[],
  useModel: any,
  Fields: any
}> = ({
  goLast,
  initGoSegment,
  advertisers,
  useModel,
  Fields
}) => {

  const {
    loading,
    redirectPath,
    save,
    validate,
    formType,
    extraData
  } = useModel();

  const mainDomRef = useRef(null);
  const [extraInfo, setExtraInfo] = useState<any>(undefined);
  const formContentClass = classNames('formContent', {
    hasExtra: !!extraInfo
  });
  const renderCountArea = (formikProps) => {
    const audienceBrief = _.get(formikProps.form.values, 'audienceBrief');
    const error = _.get(formikProps.form.errors, 'audienceBrief');
    return audienceBrief && !error ? (
      <SegmentCountButton audienceBrief={audienceBrief}/>
    ) : '';
  };

  return (
    <div className={styles.segmentFormContainer} ref={mainDomRef}>
      <NavigationPrompt when={!redirectPath}/>
      {loading && <LoadingIndicator />}
      {redirectPath && <Redirect to={redirectPath} />}
      <Formik
        initialValues={initGoSegment}
        enableReinitialize
        onSubmit={save}
        validate={validate}
        validateOnBlur={false}
      >
        {(formikProps) => {
          return (
            <Form>
              <FormikErrorScroller
                parentRef={mainDomRef}
                isSubmitting={formikProps.isSubmitting}
                errors={formikProps.errors}
              />
                <div className={formContentClass}>
                  <div>
                    <GoSegmentFormBasicFields formType={formType} advertisers={advertisers}/>
                    <Fields formType={formType} extraData={extraData} hasExtraInfo={!!extraInfo} setExtraInfo={setExtraInfo} formikProps={formikProps}/>
                    <FormikField.Custom
                      label=''
                      name=''
                      render={renderCountArea}
                    />
                  </div>
                  { extraInfo &&
                    <div className={styles.hintContainer}>
                      {extraInfo}
                    </div>
                  }
                </div>
                <div className={styles.formButtons}>
                  <button
                    type='submit'
                    className='btn btn-primary btn-sm'
                  >
                    {i18n.t('common.buttons.submit')}
                  </button>
                  {formType === 'create' && initGoSegment.type !== GoSegmentType.LOOKALIKE &&
                    <button
                      onClick={goLast}
                      className='btn btn-secondary btn-sm'
                    >
                      {i18n.t('campaign.buttons.back')}
                    </button>
                  }
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

import {
  CurrencyRateWebService,
  RestfulCurrencyRateWebService
} from 'ws/CurrencyRateWebService';
import { CurrencyRate } from './CurrencyRate';

export interface CurrencyRateManager {
  getCurrencyRates (): Promise<{ [key: string]: CurrencyRate[] }>;
  getCurrencyNewestRates (): Promise<any[]>;
  addCurrencyRate (currency: string, rate: string | number): Promise<void>;
}

export class DefaultCurrencyRateManager implements CurrencyRateManager {
  webService: CurrencyRateWebService;

  constructor (
    webService: CurrencyRateWebService = new RestfulCurrencyRateWebService()
  ) {
    this.webService = webService;
  }

  async getCurrencyNewestRates (): Promise<any[]> {
    return this.webService.getCurrencyNewestRates();
  }

  async getCurrencyRates (): Promise<{ [key: string]: CurrencyRate[] }> {
    return this.webService.getCurrencyRates();
  }

  async addCurrencyRate (currency: string, rate: string | number): Promise<void> {
    await this.webService.addCurrencyRate(currency, rate);
  }
}

import { CreativeManagementAction, CreativeManagementStateFactory, FbAdManagementStateFactory, RetailCreativeManagementStateFactory, RtbCreativeManagementStateFactory, TiktokAdManagementStateFactory } from 'components/CampaignCreativeManagement/CreativeManagementStateFactory';
import { RtbCampaigns } from 'containers/RtbCampaigns';
import { DefaultRtbCampaignsModel } from 'containers/RtbCampaigns/RtbCampaignsModel';
import { FbAdSetList } from 'containers/FbAdSets/FbAdSetList/FbAdSetList';
import { FbAdSets } from 'containers/FbAdSets/FbAdSets';
import { CampaignList } from 'containers/RtbCampaigns/RtbCampaignList/CampaignList';
import { DefaultCampaignListModel } from 'containers/RtbCampaigns/RtbCampaignList/CampaignListModel';
import { AddonFeatureManager } from 'core';
import { RtbCampaignBasic } from 'core/rtbCampaign/RtbCampaign';
import { RtbCampaignManager, DefaultRtbCampaignManager } from 'core/rtbCampaign/RtbCampaignManager';
import { createCreativeBindDataByCampaign, createCreativeBindDataByFbAdSet, createCreativeBindDataByTiktokAdGroup } from 'core/rtbCampaign/CreativeBindData';
import { CampaignGroup, CampaignGroupChannel, FBBidStrategy } from 'core/campaignGroup/CampaignGroup';
import { FbAdSet } from 'core/fbAdSet/FbAdSet';
import { DefaultFbAdSetManager, FbAdSetManager } from 'core/fbAdSet/FbAdSetManager';
import { Order } from 'core/order/Order';
import { BidStrategy, TiktokAdGroupListRecord } from 'core/tiktokAdGroup/TiktokAdGroup';
import { DefaultTiktokAdGroupManager, TiktokAdGroupManager } from 'core/tiktokAdGroup/TiktokAdGroupManager';
import _ from 'lodash';
import i18n from 'i18n';
import { TiktokAdGroupList } from 'containers/TiktokAdGroups/TiktokAdGroupList/TiktokAdGroupList';
import { TiktokAdGroups } from 'containers/TiktokAdGroups/TiktokAdGroups';
import { Retail } from 'core/product/Product';
import { DefaultRdpManager, RdpManager } from 'core/rdp/RdpManager';
import { TiktokAdGroupDrafts } from 'containers/TiktokAdGroups/TiktokAdGroupDrafts';
import { FbAdSetDrafts } from 'containers/FbAdSets/FbAdSetDrafts';
import { RtbCampaignDrafts } from 'containers/RtbCampaigns/RtbCampaignDrafts';
import { DefaultRtbCampaignDraftsModel } from 'containers/RtbCampaigns/RtbCampaignDraftsModel';

export type CampaignGroupComponentsData = {
  draftsComponent: {
    component: any,
    props: any
  };
  campaignsComponent: {
    component: any,
    props: any
  };
  listComponent: {
    component: any,
    props: any
  };
};

export interface CampaignGroupChannelDetailModel {
  readonly goCampaignList: any[];
  readonly componentsData?: CampaignGroupComponentsData;
  initChannelModel: (defaultSearchString?: string | null) => Promise<void>;
  getCreativeManagementStateFactory: (
    campaignIds,
    draftIds,
    actionParam,
    history
  ) => CreativeManagementStateFactory | undefined;
  getCampaignGroupChannelViewData (): any;
}

class RTBCampaignGroupDetailModel implements CampaignGroupChannelDetailModel {

  goCampaignList: RtbCampaignBasic[] = [];
  componentsData?: CampaignGroupComponentsData;

  constructor (
    protected order: Order,
    protected campaignGroup: CampaignGroup,
    protected addonFeatureManager: AddonFeatureManager,
    protected refreshGroupDetail,
    protected campaignManager: RtbCampaignManager = new DefaultRtbCampaignManager()
  ) {}

  async initChannelModel (defaultSearchString?: string | null) {
    try {
      this.goCampaignList = await this.campaignManager.getCampaignsOfGroup(this.campaignGroup.groupId);
      const campaignListModel = new DefaultCampaignListModel(
        this.order,
        this.campaignGroup,
        this.goCampaignList,
        this.addonFeatureManager,
        this.refreshGroupDetail,
        () => {},
        defaultSearchString ? defaultSearchString : '',
        this.campaignGroup.budgetBalance
      );
      const campaignsModel = new DefaultRtbCampaignsModel(
        this.order,
        this.addonFeatureManager,
        this.campaignGroup,
        this.goCampaignList
      );
      const campaignDraftsModel = new DefaultRtbCampaignDraftsModel(
        this.order,
        this.addonFeatureManager,
        this.campaignGroup,
        this.goCampaignList
      );
      this.componentsData = {
        draftsComponent: {
          component: RtbCampaignDrafts,
          props: {
            model: campaignDraftsModel
          }
        },
        campaignsComponent: {
          component: RtbCampaigns,
          props: {
            model: campaignsModel
          }
        },
        listComponent: {
          component: CampaignList,
          props: {
            model: campaignListModel
          }
        }
      };
    } catch (e) {}
  }

  getCreativeManagementStateFactory (
    campaignIds,
    draftIds,
    actionParam,
    history
  ) {
    const creativeBindData = this.goCampaignList.map(createCreativeBindDataByCampaign);
    const campaigns = getCampaignBindList(creativeBindData, campaignIds, draftIds);
    const allActions: string[] = Object.values(CreativeManagementAction);
    if (campaigns.length === 0 || !allActions.includes(actionParam)) {
      return;
    }

    return new RtbCreativeManagementStateFactory(
      campaigns,
      this.order.advertiserId,
      actionParam,
      this.addonFeatureManager,
      this.order.orderNumber,
      history,
      this.campaignGroup
    );
  }

  getCampaignGroupChannelViewData () {
    const rtbBidStrategyKey = Object.keys(FBBidStrategy).find(key => FBBidStrategy[key] === _.get(this.campaignGroup, 'rtb.bid_strategy', ''));
    return _.compact([
      {
        label: i18n.t('campaignGroup.labels.fb.objectiveLabel'),
        value: this.campaignGroup.objective ? i18n.t(`campaignGroup.labels.fb.objective.${this.campaignGroup.objective.toLowerCase()}`) : ''
      }, this.campaignGroup.autoOptimise ? {
        label: i18n.t('campaignGroup.labels.fb.bidStrategyLabel'),
        value: rtbBidStrategyKey ? i18n.t(`campaignGroup.labels.fb.bidStrategy.${rtbBidStrategyKey.toLowerCase()}`) : ''
      } : undefined
    ]);
  }
}

class RetailMediaCampaignGroupDetailModel extends RTBCampaignGroupDetailModel {

  retailers: Retail[] = [];

  constructor (
    order: Order,
    campaignGroup: CampaignGroup,
    addonFeatureManager: AddonFeatureManager,
    refreshGroupDetail,
    campaignManager: RtbCampaignManager = new DefaultRtbCampaignManager(),
    private rdpManager: RdpManager = new DefaultRdpManager()
  ) {
    super(order, campaignGroup, addonFeatureManager, refreshGroupDetail, campaignManager);
  }

  async initChannelModel (defaultSearchString?: string | null) {
    try {
      this.retailers = await this.rdpManager.getRetails();
      await super.initChannelModel(defaultSearchString);
    } catch (e) {}
  }

  getCreativeManagementStateFactory (
    campaignIds,
    draftIds,
    actionParam,
    history
  ) {
    const creativeBindData = this.goCampaignList.map(createCreativeBindDataByCampaign);
    const campaigns = getCampaignBindList(creativeBindData, campaignIds, draftIds);
    const allActions: string[] = Object.values(CreativeManagementAction);
    if (campaigns.length === 0 || !allActions.includes(actionParam)) {
      return;
    }

    return new RetailCreativeManagementStateFactory(
      campaigns,
      this.order.advertiserId,
      actionParam,
      this.addonFeatureManager,
      this.order.orderNumber,
      history,
      this.campaignGroup,
      campaigns[0].retailer
    );
  }
}

class FBCampaignDetailModel implements CampaignGroupChannelDetailModel {

  goCampaignList: FbAdSet[] = [];
  componentsData?: CampaignGroupComponentsData;

  constructor (
    private order: Order,
    private campaignGroup: CampaignGroup,
    private addonFeatureManager: AddonFeatureManager,
    private refreshGroupDetail,
    private fbAdSetManager: FbAdSetManager = new DefaultFbAdSetManager()
  ) {}

  async initChannelModel () {
    try {
      this.goCampaignList = await this.fbAdSetManager.getAdSets(this.campaignGroup.groupId);
      const budgetBalance = _.get(this.campaignGroup, 'budgetBalance', 0);
      this.componentsData = {
        draftsComponent: {
          component: FbAdSetDrafts,
          props: {
            order: this.order,
            campaignGroup: this.campaignGroup,
            fbAdSetList: this.goCampaignList
          }
        },
        campaignsComponent: {
          component: FbAdSets,
          props: {
            order: this.order,
            campaignGroup: this.campaignGroup,
            fbAdSetList: this.goCampaignList
          }
        },
        listComponent: {
          component: FbAdSetList,
          props: {
            order: this.order,
            campaignGroup: this.campaignGroup,
            adSets: this.goCampaignList,
            budgetBalance,
            refreshList: this.refreshGroupDetail,
            addonFeatureManager: this.addonFeatureManager
          }
        }
      };
    } catch (e) {}
  }

  getCreativeManagementStateFactory (
    campaignIds,
    draftIds,
    actionParam,
    history
  ) {
    const creativeBindData = this.goCampaignList.map(createCreativeBindDataByFbAdSet);
    const campaigns = getCampaignBindList(creativeBindData, campaignIds, draftIds);
    const allActions: string[] = Object.values(CreativeManagementAction);
    if (campaigns.length === 0 || !allActions.includes(actionParam)) {
      return;
    }
    return new FbAdManagementStateFactory(
      campaigns,
      this.order.advertiserId,
      actionParam,
      this.addonFeatureManager,
      this.order.orderNumber,
      history,
      this.campaignGroup
    );
  }

  getCampaignGroupChannelViewData () {
    const fbBidStrategyKey = Object.keys(FBBidStrategy).find(key => FBBidStrategy[key] === _.get(this.campaignGroup, 'fb.bid_strategy', ''));
    return this.campaignGroup.fb ? _.compact([
      {
        label: i18n.t('campaignGroup.labels.fb.accountId'),
        value: this.campaignGroup.fb.account_id
      }, {
        label: i18n.t('campaignGroup.labels.fb.objectiveLabel'),
        value: i18n.t(`campaignGroup.labels.fb.objective.${this.campaignGroup.fb.objective.toLowerCase()}`)
      }, this.campaignGroup.autoOptimise ? {
        label: i18n.t('campaignGroup.labels.fb.bidStrategyLabel'),
        value: fbBidStrategyKey ? i18n.t(`campaignGroup.labels.fb.bidStrategy.${fbBidStrategyKey.toLowerCase()}`) : ''
      } : undefined
    ]) : undefined;
  }
}

class TiktokCampaignDetailModel implements CampaignGroupChannelDetailModel {

  goCampaignList: TiktokAdGroupListRecord[] = [];
  componentsData?: CampaignGroupComponentsData;

  constructor (
    private order: Order,
    private campaignGroup: CampaignGroup,
    private addonFeatureManager: AddonFeatureManager,
    private refreshGroupDetail,
    private tiktokAdGroupManager: TiktokAdGroupManager = new DefaultTiktokAdGroupManager()
  ) {}

  async initChannelModel () {
    try {
      this.goCampaignList = await this.tiktokAdGroupManager.getAdGroups(_.get(this.campaignGroup, 'tiktok.campaign_id'));
      this.componentsData = {
        draftsComponent: {
          component: TiktokAdGroupDrafts,
          props: {
            order: this.order,
            campaignGroup: this.campaignGroup,
            tiktokAdGroupList: this.goCampaignList
          }
        },
        campaignsComponent: {
          component: TiktokAdGroups,
          props: {
            order: this.order,
            campaignGroup: this.campaignGroup,
            tiktokAdGroupList: this.goCampaignList
          }
        },
        listComponent: {
          component: TiktokAdGroupList,
          props: {
            order: this.order,
            campaignGroup: this.campaignGroup,
            adGroups: this.goCampaignList,
            addonFeatureManager: this.addonFeatureManager,
            refreshList: this.refreshGroupDetail
          }
        }
      };
    } catch (e) {}
  }

  getCreativeManagementStateFactory (
    campaignIds,
    draftIds,
    actionParam,
    history
  ) {
    const creativeBindData = this.goCampaignList.map(createCreativeBindDataByTiktokAdGroup);
    const campaigns = getCampaignBindList(creativeBindData, campaignIds, draftIds);
    const allActions: string[] = Object.values(CreativeManagementAction);
    if (campaigns.length === 0 || !allActions.includes(actionParam)) {
      return;
    }
    return new TiktokAdManagementStateFactory(
      campaigns,
      this.order.advertiserId,
      actionParam,
      this.addonFeatureManager,
      this.order.orderNumber,
      history,
      this.campaignGroup
    );
  }

  getCampaignGroupChannelViewData () {
    const tiktokBidStrategyKey = Object.keys(BidStrategy).find(key => BidStrategy[key] === _.get(this.campaignGroup, 'tiktok.bid_type', ''));
    return _.compact([
      this.campaignGroup.tiktok ? {
        label: i18n.t('campaignGroup.labels.tiktok.advertiserId'),
        value: this.campaignGroup.tiktok.advertiser_id
      } : undefined, {
        label: i18n.t('campaignGroup.labels.tiktok.objectiveLabel'),
        value: this.campaignGroup.objective ? i18n.t(`campaignGroup.labels.tiktok.objective.${this.campaignGroup.objective.toLowerCase()}`) : ''
      }, this.campaignGroup.autoOptimise ? {
        label: i18n.t('campaignGroup.labels.tiktok.bidStrategyLabel'),
        value: tiktokBidStrategyKey ? i18n.t(`campaignGroup.labels.fb.bidStrategy.${tiktokBidStrategyKey.toLowerCase()}`) : ''
      } : undefined
    ]);
  }
}

const channelModelClassMap = {
  [CampaignGroupChannel.FB]: FBCampaignDetailModel,
  [CampaignGroupChannel.RTB]: RTBCampaignGroupDetailModel,
  [CampaignGroupChannel.RETAIL_MEDIA]: RetailMediaCampaignGroupDetailModel,
  [CampaignGroupChannel.TIKTOK]: TiktokCampaignDetailModel
};

export function getCampaignGroupChannelDetailModel (
  order: Order,
  campaignGroup: CampaignGroup,
  addonFeatureManager: AddonFeatureManager,
  refreshGroupDetail
): CampaignGroupChannelDetailModel | undefined {
  const channelModelClass = channelModelClassMap[campaignGroup.channel];
  if (!channelModelClass) {
    return undefined;
  }
  return new channelModelClass(
    order,
    campaignGroup,
    addonFeatureManager,
    refreshGroupDetail
  );
}

function getCampaignBindList (creativeBindData, campaignIds, draftIds) {
  return creativeBindData ? creativeBindData.filter(campaign =>
    campaign.draftId ?
      draftIds.includes(campaign.draftId.toString()) :
      (campaign.id && campaignIds.includes(campaign.id.toString()))
  ) : [];
}

import React from 'react';
import { FormikField } from 'components/common/formik/FormikField';
import { FormikProps } from 'formik';
import i18n from 'i18n';
import { GoSegmentFormData, GoSegmentUpdateMode } from 'core/goSegment/GoSegment';
import { createSelectOptionsFromEnum } from 'utils/SelectOptionsUtils';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';

const goSegmentUpdateModes = createSelectOptionsFromEnum(GoSegmentUpdateMode, 'goSegment.uploadUpdateMode.');

export const GoSegmentUploadFields: React.FC<{
  formType: string,
  formikProps: FormikProps<GoSegmentFormData>,
  hasExtraInfo: boolean,
  setExtraInfo: (extraInfo: any) => void
}> = ({
  formType,
  formikProps
}) => {

  const onUpdateModeChange = value => {
    if (!value) {
      formikProps.setFieldValue('upload', {});
    }
  };

  return (
    <>
      {formType === 'edit' &&
        <FormikField.Select
          label={i18n.t('goSegmentForm.fields.updateMode')}
          name='updateMode'
          options={goSegmentUpdateModes}
          simpleValue
          isClearable={true}
          onChange={onUpdateModeChange}
        />
      }
      {(formType !== 'edit' || formikProps.values.updateMode) &&
        <FormikField.FileInput
          type='other'
          label={i18n.t('goSegmentForm.fields.upload')}
          name='upload'
          icon={faFileCsv}
          hints={[i18n.t('goSegmentForm.hints.fileType')]}
          validTypes={['text/csv']}
        />
      }
    </>
  );
};

import { LoadingIndicator } from 'components/common/LoadingIndicator/LoadingIndicator';
import React from 'react';
import { Form as BootstrapForm, Row, Col, Form } from 'react-bootstrap';
import { ChangePasswordProps, ChangePasswordState } from './ChangePasswordModel';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { notFuncs } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';
import styles from './changePassword.module.scss';
import i18n from 'i18n';

export class ChangePassword extends React.Component<ChangePasswordProps, ChangePasswordState> {
  model: any;
  handler: number;

  constructor (props: any) {
    super(props);
    this.state = this.props.model.state;
    this.model = this.props.model;
    this.handler = this.props.model.event.add((model) => {
      this.setState(model.state);
    });
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add((model) => {
        this.setState(model.state);
      });
    }
  }

  componentWillUnmount () {
    this.props.model.event.remove(this.handler);
  }

  onChangeCurrentPassword = (e) => {
    this.model.onChangeCurrentPassword(e.target.value);
  }

  onChangePassword = (e) => {
    this.model.onChangePassword(e.target.value);
    this.validateChangePassword();
  }

  onChangeConfirmPassword = (e) => {
    this.model.onChangeConfirmPassword(e.target.value);
    this.validateChangePassword();
  }

  validateChangePassword = () => {
    this.model.validateChangePasswrod();
  }

  render () {
    const model = this.model;
    const errors = this.model.errors;

    return (
      <div className={styles.container}>
        {this.state.loading && <LoadingIndicator />}
        <PermissionChecker permissionAware={notFuncs(Permission.ACCOUNT_SUDO)}>
          <BootstrapForm.Group as={Row} controlId={'currentPassword'}>
            <Col sm={3}>
              <BootstrapForm.Label column >{i18n.t('accounts.form.labels.currentPassword')}</BootstrapForm.Label>
            </Col>
            <Col sm={6}>
              <Form.Control
                type='password'
                value={model.currentPassword}
                onChange={this.onChangeCurrentPassword}
                placeholder='Current Password'
                autoComplete='current-password'
              />
              {errors.currentPassword && <span className={styles.errorMessage}>{i18n.t(errors.currentPassword)}</span>}
            </Col>
          </BootstrapForm.Group>
        </PermissionChecker>
        <BootstrapForm.Group as={Row} controlId={'password'}>
          <Col sm={3}>
            <BootstrapForm.Label column >{i18n.t('accounts.form.labels.password')}</BootstrapForm.Label>
          </Col>
          <Col sm={6}>
            <Form.Control
              type='password'
              value={model.password}
              onChange={this.onChangePassword}
              placeholder='New Password'
              autoComplete='new-password'
            />
            {errors.password && <span className={styles.errorMessage}>{i18n.t(errors.password)}</span>}
          </Col>
        </BootstrapForm.Group>
        <BootstrapForm.Group as={Row} controlId={'confirmPassword'}>
          <Col sm={3}>
            <BootstrapForm.Label column>{i18n.t('accounts.form.labels.confirmPassword')}</BootstrapForm.Label>
          </Col>
          <Col sm={6}>
            <Form.Control
              type='password'
              value={model.confirmPassword}
              onChange={this.onChangeConfirmPassword}
              placeholder='Confirm Password'
              autoComplete='new-password'
            />
            {errors.confirmPassword && <span className={styles.errorMessage}>{i18n.t(errors.confirmPassword)}</span>}
          </Col>
        </BootstrapForm.Group>
      </div>
    );
  }
}

import { CreativeListRecord, CreativeOfCampaign } from 'core/creative/Creative';
import displayPlaceholder from 'assets/campaign/adType-display.png';
import React from 'react';
import styles from './creativePreview.module.scss';

export const HTML5CreativePreview: React.FunctionComponent<{creative: CreativeOfCampaign | CreativeListRecord}> = (props) => {

  return (
    <div className={`${styles.htmlCreativePreview} ${styles.border}`}>
      <img src={displayPlaceholder} alt={'preview'} />
    </div>
  );
};

import { FireableUpdateEventListener, UpdateEventListener } from 'utils/UpdateEventListener';
import { CreativeFormBasicData } from '../CreativeSetupFlow/FlowSteps/SubSteps/FormContent/FormContentModel';
export interface NativeBannerPreviewModel {
  readonly event: UpdateEventListener<NativeBannerPreviewModel>;
  readonly state: NativeBannerPreviewState;
  readonly htmlTemplates: { [key: string]: { templatePath?: string, htmlContent?: string } };
  readonly creative: Partial<CreativeFormBasicData>;
}

export type NativeBannerPreviewProps = {
  readonly model: NativeBannerPreviewModel
};

export type NativeBannerPreviewState = {
  readonly bannerSize: string,
  readonly loading: boolean
};

export class DefaultNativeBannerPreviewModel implements NativeBannerPreviewModel {

  loading = false;
  bannerSize = '300x250';
  event = new FireableUpdateEventListener<NativeBannerPreviewModel>();

  constructor (
    public creative: Partial<CreativeFormBasicData>,
    public htmlTemplates: { [size: string]: { templatePath?: string, htmlContent?: string } }
  ) {}

  get state () {
    return {
      loading: this.loading,
      bannerSize: this.bannerSize
    };
  }

  updateState (loading: boolean) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}

export const deliveryStatusDataSetting = {
  'ADGROUP_STATUS_DELETE': {
    des: 'Deleted',
    label: 'campaignList.labels.deleteState',
    color: 'danger'
  },
  'ADGROUP_STATUS_CAMPAIGN_DELETE': {
    des: 'Campaign deleted',
    label: 'campaignList.labels.deleteState',
    color: 'danger'
  },
  'ADGROUP_STATUS_ADVERTISER_AUDIT_DENY': {
    des: 'Advertiser review failed',
    label: 'campaignList.labels.withIssues',
    color: 'warning'
  },
  'ADGROUP_STATUS_ADVERTISER_AUDIT': {
    des: 'Advertiser review in progress',
    label: 'campaignList.labels.withIssues',
    color: 'warning'
  },
  'ADVERTISER_CONTRACT_PENDING': {
    des: 'Advertiser contract has not taken effect',
    label: 'campaignList.labels.withIssues',
    color: 'warning'
  },
  'ADGROUP_STATUS_CAMPAIGN_EXCEED': {
    des: 'Campaign over budget',
    label: 'campaignList.labels.deactivateState',
    color: 'black'
  },
  'ADGROUP_STATUS_BUDGET_EXCEED': {
    des: 'Ad group over budget',
    label: 'campaignList.labels.deactivateState',
    color: 'black'
  },
  'ADGROUP_STATUS_BALANCE_EXCEED': {
    des: 'Insufficient account balance',
    label: 'campaignList.labels.withIssues',
    color: 'warning'
  },
  'ADGROUP_STATUS_AUDIT_DENY': {
    des: 'Ad group review failed',
    label: 'campaignList.labels.withIssues',
    color: 'warning'
  },
  'ADGROUP_STATUS_REAUDIT': {
    des: 'Review of modifications in progress',
    label: 'campaignList.labels.pendingReviewState',
    color: 'white'
  },
  'ADGROUP_STATUS_AUDIT': {
    des: 'New review created',
    label: 'campaignList.labels.pendingReviewState',
    color: 'white'
  },
  'ADGROUP_STATUS_CREATE': {
    des: 'New ad group created',
    label: 'campaignList.labels.pendingReviewState',
    color: 'white'
  },
  'ADGROUP_STATUS_NOT_START': {
    des: 'The scheduled delivery time has not started',
    label: 'campaignList.labels.notStartState',
    color: 'white'
  },
  'ADGROUP_STATUS_TIME_DONE': {
    des: 'Completed',
    label: 'campaignList.labels.endState',
    color: 'light'
  },
  'ADGROUP_STATUS_CAMPAIGN_DISABLE': {
    des: 'Campaign suspended',
    label: 'campaignList.labels.deactivateState',
    color: 'black'
  },
  'ADGROUP_STATUS_DISABLE': {
    des: 'Suspended',
    label: 'campaignList.labels.deactivateState',
    color: 'black'
  },
  'ADGROUP_STATUS_DELIVERY_OK': {
    des: 'Advertising in delivery',
    label: 'campaignList.labels.activateState',
    color: 'success'
  }
};

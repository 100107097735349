import React from 'react';
import Select from 'components/common/Select/Select';
import i18n from 'i18next';
import styles from './chooseAdvertiserStep.module.scss';
import { ChooseAdvertiserStepProps } from './ChooseAdvertiserStepModel';
import _ from 'lodash';
import { Button } from 'react-bootstrap';
import { CreativeSetupFlowDataContext } from '../CreativeSetupFlowDataContext';
import { Modal } from 'components/common/Modal/Modal';
import cx from 'classnames/bind';

export const ChooseAdvertiserStep: React.FunctionComponent<ChooseAdvertiserStepProps> = (props) => {
  const creativeContext = React.useContext(CreativeSetupFlowDataContext);
  const [ showAlertModal, setShowAlertModal ] = React.useState<boolean>(false);
  const [ dirty, setDirty ] = React.useState<boolean>(false);
  const [ errorMsg, setErrorMsg ] = React.useState<string | undefined>(props.model.errorMsg);
  React.useEffect(() => {
    const handler = props.model.event.add(model => {
      if (errorMsg !== model.errorMsg) {
        setErrorMsg(model.errorMsg);
      }
    });
    return () => {
      props.model.event.remove(handler);
    };
  });
  const initAdvertiserId = React.useRef<number | undefined>(creativeContext.creative.basic.advertiserId);
  const classNames = cx.bind(styles);

  props.model.setCurrentAdvertiser(_.get(creativeContext, 'creative.basic.advertiserId'));

  const resetCreativeAndGoNext = () => {
    const formContentModel = creativeContext.getFormContentModelOfType(creativeContext.initCreative.basic.creativeType);
    const initCreative = JSON.parse(JSON.stringify({
      ...creativeContext.initCreative,
      basic: {
        ...creativeContext.initCreative.basic,
        advertiserId: creativeContext.creative.basic.advertiserId,
        typeProperties: formContentModel ? formContentModel.getInitTypeProperties() : undefined
      }
    }));
    creativeContext.setCreative(initCreative);
    props.model.goNext(creativeContext.creative.basic.advertiserId);
  };

  const hideModal = () => {
    setShowAlertModal(false);
  };

  const onGoNextClick = () => {
    if (dirty) {
      setShowAlertModal(true);
    } else {
      props.model.goNext(props.model.currentAdvertiserId);
    }
  };

  const onSelect = (advertiserOption) => {
    const isFirstSet = JSON.stringify(creativeContext.initCreative) === JSON.stringify(creativeContext.creative);
    setDirty(!isFirstSet && advertiserOption.value !== initAdvertiserId.current);
    props.model.onSelectAdvertiser();
    creativeContext.setCreative({
      ...creativeContext.creative,
      basic: {
        ...creativeContext.creative.basic,
        advertiserId: advertiserOption.value
      }
    });
  };

  const selectionClass = classNames('advertiserSelection', {
    error: !!errorMsg
  });
  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className='col-sm-3'>
          <label className='form-label col-form-label col'>{i18n.t('creativeSetupFlow.labels.advertiser')}</label>
        </div>
        <div className='col-sm-9'>
          <Select
            className={selectionClass}
            options={creativeContext.advertisers}
            name='creative-advertiser-selection'
            value={_.filter(creativeContext.advertisers, option => option.value === creativeContext.creative.basic.advertiserId)}
            placeholder={i18n.t('creativeSetupFlow.placeholders.chooseAdvertiser')}
            onChange={onSelect}
          />
          {errorMsg && <span className={styles.wording}>{errorMsg}</span>}
        </div>
      </div>
      <div className={styles.buttonArea}>
        <Button variant='primary' size='sm' onClick={onGoNextClick}>
          {i18n.t('creativeSetupFlow.labels.nextStep')}
        </Button>
      </div>
      {
        showAlertModal &&
        <Modal
          title={i18n.t('creativeChooseAdvertiser.title.warning')}
          primaryButton={{
            title: i18n.t('common.buttons.ok'),
            callback: resetCreativeAndGoNext
          }}
          secondaryButton={{
            title: i18n.t('common.buttons.cancel'),
            callback: hideModal
          }}
        >
          {i18n.t('creativeChooseAdvertiser.labels.advertiserChangeAlert')}
        </Modal>
      }
    </div>
  );
};

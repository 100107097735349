import { ColumnDefinition, renderColumn, sortableColumn } from 'components/TableColumn';
import { TiktokAdGroup, TiktokAdGroupListRecord } from 'core/tiktokAdGroup/TiktokAdGroup';
import { TiktokAdGroupManager, DefaultTiktokAdGroupManager } from 'core/tiktokAdGroup/TiktokAdGroupManager';
import { DraftManager, TiktokAdGroupDraftManager } from 'core/draft/DraftManager';
import { useEffect, useMemo, useState, useCallback } from 'react';
import styles from './tiktokAdGroupList.module.scss';
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import formatters from './listFormatters';
import _ from 'lodash';
import { CampaignState } from 'core/rtbCampaign/RtbCampaign';
import i18n from 'i18n';
import { Order, State } from 'core/order/Order';
import moment from 'moment';
import { CampaignGroup } from 'core/campaignGroup/CampaignGroup';
import { useCallAPI } from 'hooks/useCallAPI';
import { numberWithCommas } from 'utils/StringUtil';
import { formatPrice, getPriceValue } from 'helper/CurrencyHelper';
import { getCpc, getCtr } from 'helper/MetricsHelper';
import { FilterMenuTabConfig } from 'components/common/FilterMenuTab/FilterMenuTab';

export enum TiktokAdGroupListColumns {
  LISTID = 'listId',
  ID = 'adgroup_id',
  BUDGET = 'budget',
  STATE = 'state',
  DELIVERY = 'delivery',
  CREATIVE = 'enableCount',
  OPTIMIZATION = 'optimize_goal',
  SCHEDULING = 'schedule_start_time',
  RESULTS = 'result',
  IMPRES = 'impressions',
  CLICKS = 'clicks',
  CPC = 'cpc',
  CTR = 'ctr',
  UU = 'lifetimeReach',
  SPENT = 'spend',
  TIKTOK_VIDEO_PLAY_ACTIONS = 'videoPlayActions',
  TIKTOK_VIDEO_WATCHED_2S = 'videoWatched2s',
  TIKTOK_VIDEO_WATCHED_6S = 'videoWatched6s',
  TIKTOK_VIDEO_VIEWS_P25 = 'videoViewsP25',
  TIKTOK_VIDEO_VIEWS_P50 = 'videoViewsP50',
  TIKTOK_VIDEO_VIEWS_P75 = 'videoViewsP75',
  TIKTOK_VIDEO_VIEWS_P100 = 'videoViewsP100',
  TIKTOK_PROFILE_VISITS = 'profileVisits',
  TIKTOK_LIKES = 'likes',
  TIKTOK_COMMENTS = 'comments',
  TIKTOK_SHARES = 'shares',
  TIKTOK_FOLLOWS = 'follows',
  TIKTOK_CLICKS_ON_MUSIC_DISC = 'clicksOnMusicDisc',
  EDITBTNS = 'editBtns'
}

export enum TiktokAdGroupListType {
  BASIC = 'basic',
  PERFORMANCE = 'performance',
  VIDEO_PLAY = 'tiktok_video_play',
  ENGAGEMENT = 'tiktok_engagement'
}

export type TiktokAdGroupListState = {
  readonly loading: boolean;
  readonly rate: string;
  readonly currentCurrency: string;
  readonly currencyRates: { [key: string]: TiktokAdGroup[] };
};

const defaultTiktokAdGroupManager: TiktokAdGroupManager = new DefaultTiktokAdGroupManager();
const defaultDraftManager: DraftManager = new TiktokAdGroupDraftManager();

export const useTiktokAdGroupListModel = (
  order: Order,
  campaignGroup: CampaignGroup | undefined,
  tiktokAdGroups: TiktokAdGroupListRecord[],
  refreshList: () => void,
  addonFeatureManager,
  tiktokAdGroupManager: TiktokAdGroupManager = defaultTiktokAdGroupManager,
  tiktokAdGroupDraftManager: DraftManager = defaultDraftManager
) => {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const defaultSearchString = searchParams.get('search');
  const { loading, callAPIs } = useCallAPI();
  const [filteredList, setFilteredList] = useState<TiktokAdGroup[]>([]);
  const [searchString, setSearchString] = useState<string>(defaultSearchString ? defaultSearchString : '');
  const [selectedAdGroups, setSelectedAdGroups] = useState<(number | string)[]>([]);
  const [selectedDrafts, setSelectedDrafts] = useState<(number | string)[]>([]);
  const [selectedMetricsGroup, setSelectedMetricsGroup] = useState<TiktokAdGroupListType>(TiktokAdGroupListType.BASIC);
  const [deleteDraftIds, setDeleteDraftIds] = useState<(number | string)[]>([]);
  const [showDeleteDraftConfirmModal, setShowDeleteDraftConfirmModal] = useState<boolean>(false);
  const [summaryData, setSummaryData] = useState<any>();
  const [showDraftCreateModal, setShowDraftCreateModal] = useState<boolean>(false);
  const [filterMenuRef, setFilterMenuRef] = useState<any>();
  const [objectTypes, setObjectTypes] = useState<string[]>([]);
  const [selectedObjectTypeFilter, setSelectedObjectTypeFilter] = useState<string[]>([]);
  const match = useRouteMatch();
  const history = useHistory();

  const onHandleSearch = (searchString: string) => setSearchString(searchString);

  const tiktokAdGroupWithPerformanceList = useMemo(() => {
    return tiktokAdGroups.map(tiktokAdGroup => {
      const isDraft: boolean = _.get(tiktokAdGroup, 'isDraft', false);
      const report = _.get(tiktokAdGroup, 'report');
      const listId = isDraft ? `${tiktokAdGroup.adgroup_id}_draft_${tiktokAdGroup.draftId}` : tiktokAdGroup.adgroup_id;
      const delivery = isDraft ? null : tiktokAdGroup.status;
      return report ? {
        ..._.omit(tiktokAdGroup, ['report']),
        ...report,
        state: tiktokAdGroup.opt_status,
        delivery,
        listId,
        bid_type: tiktokAdGroup.bid_type ? tiktokAdGroup.bid_type : _.get(campaignGroup, 'tiktok.bid_type'),
        budget: tiktokAdGroup.budget ? tiktokAdGroup.budget : -1,
        [TiktokAdGroupListColumns.CPC]: getPriceValue(order.currency, getCpc(_.get(report, 'spend', 0), report.clicks)),
        [TiktokAdGroupListColumns.CTR]: getCtr(report.impressions, report.clicks)
      } : {
        ...tiktokAdGroup,
        state: tiktokAdGroup.opt_status,
        delivery,
        listId,
        bid_type: tiktokAdGroup.bid_type ? tiktokAdGroup.bid_type : _.get(campaignGroup, 'tiktok.bid_type'),
        budget: tiktokAdGroup.budget ? tiktokAdGroup.budget : -1,
        [TiktokAdGroupListColumns.CPC]: getPriceValue(order.currency, 0),
        [TiktokAdGroupListColumns.CTR]: 0,
        [TiktokAdGroupListColumns.SPENT]: 0
      };
    });
  }, [order.currency, tiktokAdGroups, campaignGroup]);

  useEffect(() => {
    let objectTypes: string[] = [];
    const getObjectTypeDesc = (tiktokAdGroup) => {
      if (tiktokAdGroup.isDraft) {
        return i18n.t('goCampaignList.labels.draft');
      } else {
        return i18n.t('goCampaignList.labels.ordinaryCampaign_tiktok');
      }
    };
    const filteredList = tiktokAdGroupWithPerformanceList.filter(tiktokAdGroup => {
      const objectTypeDes = getObjectTypeDesc(tiktokAdGroup);
      objectTypes = _.uniq(_.concat(objectTypes, objectTypeDes));
      const nameInclude = tiktokAdGroup.adgroup_name.toLowerCase().includes(searchString.toLowerCase());
      const listIdInclude = tiktokAdGroup.listId.toString().includes(searchString);
      const objectTypeInclude = selectedObjectTypeFilter.length === 0 || selectedObjectTypeFilter.includes(objectTypeDes);
      return (nameInclude || listIdInclude) && objectTypeInclude;
    });
    setObjectTypes(objectTypes);
    setFilteredList(filteredList);

    const metricsAdder = (acc, value) => {
      if (!value) {
        return acc;
      }
      return acc + value;
    };
    const clickSum = filteredList.reduce<number>((partial, adgroup) => metricsAdder(partial, adgroup.clicks), 0);
    const spentSum = filteredList.reduce<number>((partial, adgroup) => metricsAdder(partial, getPriceValue(order.currency, adgroup.spend)), 0);
    const impresSum = filteredList.reduce<number>((partial, adgroup) => metricsAdder(partial, adgroup.impressions), 0);

    const videoPlayColumns = [
      TiktokAdGroupListColumns.TIKTOK_VIDEO_PLAY_ACTIONS,
      TiktokAdGroupListColumns.TIKTOK_VIDEO_WATCHED_2S,
      TiktokAdGroupListColumns.TIKTOK_VIDEO_WATCHED_6S,
      TiktokAdGroupListColumns.TIKTOK_VIDEO_VIEWS_P25,
      TiktokAdGroupListColumns.TIKTOK_VIDEO_VIEWS_P50,
      TiktokAdGroupListColumns.TIKTOK_VIDEO_VIEWS_P75,
      TiktokAdGroupListColumns.TIKTOK_VIDEO_VIEWS_P100
    ];
    const engagementColumns = [
      TiktokAdGroupListColumns.TIKTOK_PROFILE_VISITS,
      TiktokAdGroupListColumns.TIKTOK_LIKES,
      TiktokAdGroupListColumns.TIKTOK_COMMENTS,
      TiktokAdGroupListColumns.TIKTOK_SHARES,
      TiktokAdGroupListColumns.TIKTOK_FOLLOWS,
      TiktokAdGroupListColumns.TIKTOK_CLICKS_ON_MUSIC_DISC
    ];
    const otherSummaryData = [...videoPlayColumns, ...engagementColumns].reduce((acc, column) => {
      const sum = filteredList.reduce<number>((partial, tiktokAdGroup) => metricsAdder(partial, +tiktokAdGroup[column]), 0);
      acc[column] = numberWithCommas(sum);
      return acc;
    }, {});

    const summaryData = {
      [TiktokAdGroupListColumns.LISTID]: i18n.t('campaignGroupList.labels.campaignGroupCount', { count: filteredList.length }),
      [TiktokAdGroupListColumns.IMPRES]: numberWithCommas(impresSum),
      [TiktokAdGroupListColumns.CLICKS]: numberWithCommas(clickSum),
      [TiktokAdGroupListColumns.CPC]: formatPrice(order.currency, clickSum === 0 ? 0 : spentSum / clickSum),
      [TiktokAdGroupListColumns.CTR]: `${impresSum === 0 ? 0.00.toFixed(2) : ((clickSum / impresSum) * 100).toFixed(2)}%`,
      [TiktokAdGroupListColumns.SPENT]: numberWithCommas(spentSum),
      ...otherSummaryData
    };
    setSummaryData(summaryData);
  }, [searchString, tiktokAdGroupWithPerformanceList, order.currency, selectedObjectTypeFilter]);

  const onSelect = (adGroup: TiktokAdGroup) => {
    const isDraft: boolean = _.get(adGroup, 'isDraft', false);
    const objectId = isDraft ? _.get(adGroup, 'draftId', 0) : adGroup.adgroup_id;
    const selectedArray = isDraft ? selectedDrafts : selectedAdGroups;
    const setSelectedArray = isDraft ? setSelectedDrafts : setSelectedAdGroups;
    if (selectedArray.indexOf(objectId.toString()) > -1) {
      setSelectedArray(selectedArray.filter(id => id.toString() !== objectId.toString()));
    } else {
      setSelectedArray([...selectedArray, objectId.toString()]);
    }
  };

  const onSelectAll = () => {
    const selectedArrays = [...selectedAdGroups, ...selectedDrafts];
    if (selectedArrays.length === filteredList.length) {
      setSelectedAdGroups([]);
      setSelectedDrafts([]);
    } else {
      setSelectedAdGroups(filteredList.filter(object => !_.get(object, 'isDraft', false)).map(adGroup => adGroup.adgroup_id.toString()));
      setSelectedDrafts(filteredList.filter(object => _.get(object, 'isDraft', true)).map(draft => _.get(draft, 'draftId', 0).toString()));
    }
  };

  const onCreativeBindingBtnClick = (id, isDraft) => {
    if (isDraft) {
      history.replace(`${match.url}?draftIds=${id}&action=manage`);
    } else {
      history.replace(`${match.url}?campaignIds=${id}&action=manage`);
    }
  };

  const handleRemoveSelect = () => {
    setSelectedAdGroups([]);
    setSelectedDrafts([]);
  };

  const onDeleteDraftModalClose = useCallback((refresh: boolean) => {
    setShowDeleteDraftConfirmModal(false);
    setDeleteDraftIds([]);
    refresh && refreshList();
  }, [refreshList]);

  const deleteDraft = useCallback(async (draftIds: (number | string)[]) => {
    setShowDeleteDraftConfirmModal(false);
    callAPIs([
      tiktokAdGroupDraftManager.deleteDrafts.bind(tiktokAdGroupDraftManager, draftIds)
    ], () => {
      setSelectedAdGroups([]);
      setSelectedDrafts([]);
      onDeleteDraftModalClose(true);
    });
  }, [tiktokAdGroupDraftManager, onDeleteDraftModalClose, callAPIs]);

  const columnDefinition = (columnName, customLabel?: string): ColumnDefinition => ({
    ...sortableColumn(columnName, customLabel ? customLabel : `tiktokAdGroupList.headers.${columnName}`, true),
    classes: () => styles[columnName],
    headerClasses: () => styles[columnName]
  });

  const metricsFormatter = (formatter, value) => {
    let target = value;
    if (!value) {
      target = 0;
    }
    return formatter(target);
  };

  const metricsWithCommasFormatter = _.partial(metricsFormatter, numberWithCommas);

  const idColumn = renderColumn({
    ...columnDefinition(TiktokAdGroupListColumns.LISTID),
    sortFunc: (adGroup1, adGroup2, order) => {
      const adGroup1IsDraft = adGroup1.includes('draft');
      const adGroup2IsDraft = adGroup2.includes('draft');
      const sameObjectType = adGroup1IsDraft === adGroup2IsDraft;
      const compareListId = adGroup1.toString().localeCompare(adGroup2.toString());
      if (!sameObjectType) {
        return adGroup1IsDraft ? -1 : 1;
      }
      if (compareListId === 0) {
        return 0;
      }
      if (order === 'desc') {
        return compareListId > 0 ? 1 : -1;
      } else {
        return compareListId < 0 ? 1 : -1;
      }
    },
    formatExtraData: {
      currentUrl: match.url,
      selectedAdGroups,
      selectedDrafts,
      onSelect
    }
  },
  formatters.nameFormatter,
  _.partial(
    formatters.nameHeaderFormatter,
    filteredList.length,
    [...selectedAdGroups, ...selectedDrafts],
    onSelectAll
  ));

  const budgetColumn = renderColumn({
    ...columnDefinition(
      TiktokAdGroupListColumns.BUDGET,
      i18n.t(`tiktokAdGroupList.headers.${TiktokAdGroupListColumns.BUDGET}`) + ` (${order.currency})`
    ),
    formatExtraData: {
      currency: order.currency
    }
  }, formatters.budgetFormatter);

  const optimizationColumn = renderColumn({
    ...columnDefinition(TiktokAdGroupListColumns.OPTIMIZATION),
    formatExtraData: {
      currency: order.currency
    }
  }, formatters.optimizationFormatter);

  const editBtnsColumn = renderColumn({
    ...columnDefinition(TiktokAdGroupListColumns.EDITBTNS),
    text: '',
    sort: false,
    formatExtraData: {
      currentUrl: match.url
    }
  }, formatters.floatingEditBtnsFormatter);

  const basicColumns = [
    idColumn,
    renderColumn(columnDefinition(TiktokAdGroupListColumns.STATE), formatters.stateFormatter),
    renderColumn(columnDefinition(TiktokAdGroupListColumns.DELIVERY), formatters.deliveryFormatter),
    renderColumn({
      ...columnDefinition(TiktokAdGroupListColumns.CREATIVE),
      formatExtraData: {
        onClick: onCreativeBindingBtnClick
      }
    }, formatters.creativeBindingFormatter),
    budgetColumn,
    renderColumn(columnDefinition(TiktokAdGroupListColumns.SCHEDULING), formatters.scheduleFormatter),
    optimizationColumn,
    editBtnsColumn
  ];

  const spentColumn = renderColumn(
    columnDefinition(
      TiktokAdGroupListColumns.SPENT,
      i18n.t(`tiktokAdGroupList.headers.${TiktokAdGroupListColumns.SPENT}`) + ` (${order.currency})`),
      _.partial(metricsFormatter, _.partial(formatPrice, order.currency)
    )
  );

  const impresColumn = renderColumn(
    columnDefinition(TiktokAdGroupListColumns.IMPRES),
    metricsWithCommasFormatter
  );

  const performanceColumns = _.compact([
    idColumn,
    budgetColumn,
    optimizationColumn,
    renderColumn({
      ...columnDefinition(TiktokAdGroupListColumns.RESULTS),
      formatExtraData: {
        objective: _.get(campaignGroup, 'tiktok.objective_type')
      }
    }, formatters.resultsFormatter),
    impresColumn,
    renderColumn(columnDefinition(TiktokAdGroupListColumns.CLICKS), metricsWithCommasFormatter),
    renderColumn(
      columnDefinition(TiktokAdGroupListColumns.CPC, i18n.t(`tiktokAdGroupList.headers.${TiktokAdGroupListColumns.CPC}`) + ` (${order.currency})`),
      _.partial(formatPrice, order.currency)
    ),
    renderColumn(columnDefinition(TiktokAdGroupListColumns.CTR), _.partial(metricsFormatter, value => `${value.toFixed(2)}%`)),
    renderColumn(columnDefinition(TiktokAdGroupListColumns.UU), metricsWithCommasFormatter),
    spentColumn,
    editBtnsColumn
  ]);

  const videoPlayColumns = [
    idColumn,
    impresColumn,
    spentColumn,
    renderColumn(columnDefinition(TiktokAdGroupListColumns.TIKTOK_VIDEO_PLAY_ACTIONS), metricsWithCommasFormatter),
    renderColumn(columnDefinition(TiktokAdGroupListColumns.TIKTOK_VIDEO_WATCHED_2S), metricsWithCommasFormatter),
    renderColumn(columnDefinition(TiktokAdGroupListColumns.TIKTOK_VIDEO_WATCHED_6S), metricsWithCommasFormatter),
    renderColumn(columnDefinition(TiktokAdGroupListColumns.TIKTOK_VIDEO_VIEWS_P25), metricsWithCommasFormatter),
    renderColumn(columnDefinition(TiktokAdGroupListColumns.TIKTOK_VIDEO_VIEWS_P50), metricsWithCommasFormatter),
    renderColumn(columnDefinition(TiktokAdGroupListColumns.TIKTOK_VIDEO_VIEWS_P75), metricsWithCommasFormatter),
    renderColumn(columnDefinition(TiktokAdGroupListColumns.TIKTOK_VIDEO_VIEWS_P100), metricsWithCommasFormatter),
    editBtnsColumn
  ];

  const engagementColumns = [
    idColumn,
    impresColumn,
    spentColumn,
    renderColumn(columnDefinition(TiktokAdGroupListColumns.TIKTOK_PROFILE_VISITS), metricsWithCommasFormatter),
    renderColumn(columnDefinition(TiktokAdGroupListColumns.TIKTOK_LIKES), metricsWithCommasFormatter),
    renderColumn(columnDefinition(TiktokAdGroupListColumns.TIKTOK_COMMENTS), metricsWithCommasFormatter),
    renderColumn(columnDefinition(TiktokAdGroupListColumns.TIKTOK_SHARES), metricsWithCommasFormatter),
    renderColumn(columnDefinition(TiktokAdGroupListColumns.TIKTOK_FOLLOWS), metricsWithCommasFormatter),
    renderColumn(columnDefinition(TiktokAdGroupListColumns.TIKTOK_CLICKS_ON_MUSIC_DISC), metricsWithCommasFormatter),
    editBtnsColumn
  ];

  const typeColumnsMap = {
    [TiktokAdGroupListType.BASIC]: basicColumns,
    [TiktokAdGroupListType.PERFORMANCE]: performanceColumns,
    [TiktokAdGroupListType.VIDEO_PLAY]: videoPlayColumns,
    [TiktokAdGroupListType.ENGAGEMENT]: engagementColumns
  };

  const metricsGroupOptions = useMemo(() => {
    return Object.keys(typeColumnsMap).map(metricsGroup => ({
      label: i18n.t(`campaignList.tabs.${metricsGroup}`),
      value: metricsGroup
    }));
  }, [typeColumnsMap]);

  const getManageCreativesPath = () => {
    const campaignIds = selectedAdGroups.join(',');
    const draftIds = selectedDrafts.join(',');
    let path = `${location.pathname}?`;
    if (campaignIds) {
      path = path + `campaignIds=${campaignIds}`;
    }
    if (draftIds) {
      path = campaignIds ? `${path}&draftIds=${draftIds}` : `${path}draftIds=${draftIds}`;
    }
    return path;
  };

  const bindCreatives = (event) => {
    event && event.stopPropagation();
    history.push(`${getManageCreativesPath()}&action=bind`);
  };

  const activateCreatives = (event) => {
    event && event.stopPropagation();
    history.push(`${getManageCreativesPath()}&action=activate`);
  };

  const deactivateCreatives = (event) => {
    event && event.stopPropagation();
    history.push(`${getManageCreativesPath()}&action=deactivate`);
  };

  const generateUpdateStatePayload = (selectedObjects: (number | string)[], objectType: 'adGroup' | 'draft') => {
    const key = objectType === 'adGroup' ? 'adgroup_id' : 'draftId';
    const payload = selectedObjects.map(objectId => {
      const object = tiktokAdGroups.find(adGroup => _.get(adGroup, key, '').toString() === objectId.toString());
      return {
        goCampaignChannelId: objectId,
        isDraft: _.get(object, 'isDraft', false)
      };
    });
    return payload;
  };

  const getUpdateStatusPayload = () => {
    return [
      ...generateUpdateStatePayload(selectedAdGroups, 'adGroup'),
      ...generateUpdateStatePayload(selectedDrafts, 'draft')
    ];
  };

  const activeAdGroup = async () => {
    callAPIs([
      tiktokAdGroupManager.updateAdGroupState.bind(tiktokAdGroupManager, getUpdateStatusPayload(), 'activate')
    ], () => {
      setSelectedAdGroups([]);
      setSelectedDrafts([]);
      refreshList();
    });
  };

  const deactiveAdGroup = async () => {
    callAPIs([
      tiktokAdGroupManager.updateAdGroupState.bind(tiktokAdGroupManager, getUpdateStatusPayload(), 'deactivate')
    ], () => {
      setSelectedAdGroups([]);
      setSelectedDrafts([]);
      refreshList();
    });
  };

  const onShowDeleteDraftConfirmModal = () => {
    setShowDeleteDraftConfirmModal(true);
    setDeleteDraftIds(selectedDrafts);
  };

  const rowClasses = (adGroup) => {
    let classes: any = null;
    if (adGroup.state === CampaignState.DEACTIVATE && adGroup.budget_remaining > 0) {
      classes = [classes, styles.lightDanger].join(' ');
    }
    if (adGroup.isDraft) {
      classes = [classes, styles.draftBg].join(' ');
    }
    return classes;
  };

  const canNotCreateMessage = useMemo(() => {
    const canNotCreateState = [State.NOT_APPROVE, State.REJECT, State.SETTLE, State.SETTLED, State.CHANGE_PENDING];
    const state = order.state;
    if (canNotCreateState.includes(state)) {
      return i18n.t('tiktokAdGroupList.labels.orderStateCannotCreate');
    }

    const isEnd = moment(order.endDate).isBefore(moment().startOf('day'));
    if (isEnd) {
      return i18n.t('tiktokAdGroupList.labels.isEndCannotCreate');
    }

    if (campaignGroup && campaignGroup.budgetBalance <= 0) {
      return i18n.t('orderDetail.labels.lessThanBudgetMinimum', { budget: 0 });
    }

    return '';
  }, [order, campaignGroup]);

  const hideDraftCreateModal = (dirty: boolean) => {
    setShowDraftCreateModal(false);
    setSelectedAdGroups([]);
    setSelectedDrafts([]);
    dirty && refreshList();
  };

  const handleOnObjectTypeFilterApply = (objectTypeList) => {
    setSelectedObjectTypeFilter([...objectTypeList]);
  };

  const filterMenuTabConfigs: FilterMenuTabConfig[] = useMemo(() => ([
    {
      filterType: i18n.t('goCampaignList.labels.filterObjectType'),
      menuTitle: i18n.t('goCampaignList.labels.objectTypeFilterMenuTitle'),
      tag: i18n.t('goCampaignList.labels.objectTypeTag'),
      selectedValues: selectedObjectTypeFilter,
      options: objectTypes,
      applyMethod: handleOnObjectTypeFilterApply
    }
  ]), [objectTypes, selectedObjectTypeFilter]);

  return {
    loading,
    columns: typeColumnsMap[selectedMetricsGroup],
    summaryData,
    rowClasses,
    filteredList,
    searchString,
    currentUrl: match.url,
    selectedAdGroups,
    selectedDrafts,
    metricsGroupOptions,
    selectedMetricsGroup,
    canNotCreateMessage,
    showDraftCreateModal,
    hideDraftCreateModal,
    setShowDraftCreateModal,
    onMetricsGroupChange: selectedMetricsGroup => setSelectedMetricsGroup(selectedMetricsGroup),
    onHandleSearch,
    handleRemoveSelect,
    onDeleteDraftModalClose,
    showDeleteDraftConfirmModal,
    onShowDeleteDraftConfirmModal,
    deleteDraft: _.partial(deleteDraft, deleteDraftIds),
    bindCreatives,
    activateCreatives,
    deactivateCreatives,
    activeAdGroup,
    deactiveAdGroup,
    filterMenuRef,
    setFilterMenuRef,
    filterMenuTabConfigs
  };
};

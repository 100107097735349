import React from 'react';
import { Spring, animated } from 'react-spring';
import styles from './creativePreview.module.scss';
import videoPlaceholder from 'assets/video-placeholder.svg';
import _ from 'lodash';
import i18n from 'i18n';
import { CreativeListRecord, CreativeOfCampaign, RETAILMAX_LAYOUT_TYPE } from 'core/creative/Creative';

export class AdNeonCreativePreview extends React.Component<{ creative: CreativeOfCampaign | CreativeListRecord }, any> {

  timer: any;

  constructor (props) {
    super(props);
    const creativeValue = {
      width: _.get(props.creative, 'width'),
      height: _.get(props.creative, 'height')
    };
    this.state = {
      index: 0,
      creativeValue
    };
  }

  componentDidUpdate (prevProps) {
    const prevCreativeValue = {
      width: _.get(prevProps.creative, 'width'),
      height: _.get(prevProps.creative, 'height')
    };
    const currentCreativeValue = {
      width: _.get(this.props.creative, 'width'),
      height: _.get(this.props.creative, 'height')
    };
    if (JSON.stringify(prevCreativeValue) !== JSON.stringify(currentCreativeValue)) {
      this.setState({
        index: 0,
        creativeValue: currentCreativeValue
      });
    }
  }

  mouseEnterHandler = () => {
    this.timer = setInterval(() => {
      this.setState({
        index: this.state.index >= 4 ? 0 : this.state.index + 1
      });
    }, 1000);
  }

  mouseLeaveHandler = () => {
    clearInterval(this.timer);
    this.setState({
      index: 0
    });
  }

  renderPlaceholder = () => {
    return (
      <div className={styles.outdoorPreviewPlaceholder}>
        <img src={videoPlaceholder} alt={'preview area'} />
        <span>{i18n.t('videoCreativePreivew.labels.generating')}</span>
      </div>
    );
  }

  renderVideoPreview (sourceUrl) {
    const creativeValue = this.state.creativeValue;
    const portion = creativeValue.height / creativeValue.width;
    let width = 160;
    let height = portion * width;
    if (height > 160) {
      width = width * 160 / height;
      height = 160;
    }
    const imgOffsetPercent = 25;
    const imgCount = 5;
    return (
      <div
        style={{
          width: '160px',
          height: '133px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'black'
        }}
      >
        <Spring
          from={{ x: 0 }}
          to={{ x: this.state.index * imgOffsetPercent }}
          immediate={true}
        >
          {({ x }) => (
            <animated.div
              className={styles.videoCreativePreview}
              onMouseEnter={this.mouseEnterHandler}
              onMouseLeave={this.mouseLeaveHandler}
              style={{
                width: width,
                height: height,
                background: x.to((x) => `url(${sourceUrl}) no-repeat ${x}% center / ${width * imgCount}px ${height}px`)
              }}
            />
          )}
        </Spring>
      </div>
    );
  }

  renderImagePreview (sourceUrl) {
    return (
      <img
        style={{
          width: '150px',
          height: '125px',
          objectFit: 'contain'
        }}
        src={sourceUrl}
        alt={'preview'}
      />
    );
  }

  render () {
    const creativeValue = this.state.creativeValue;
    if (!creativeValue || !creativeValue.width || !creativeValue.height) {
      return this.renderPlaceholder();
    }
    const sourceUrl = this.props.creative.bannerImageUrl;
    if (!sourceUrl) {
      return this.renderPlaceholder();
    }
    if (_.get(this.props.creative, 'bannerExtra.layoutId') === RETAILMAX_LAYOUT_TYPE.VIDEO.replace('pps_', '')) {
      return this.renderVideoPreview(sourceUrl);
    } else {
      return this.renderImagePreview(sourceUrl);
    }
  }
}

import React from 'react';
import { FILTER_TYPE } from 'core/doctor/DoctorData';
import i18n from 'i18n';
import styles from './spaceReasonFormatter.module.scss';

function renderCategoryReason (space) {
  const renderList = () => {
    return space.spaceBanCategory.map(category => (
      <div key={category} className={styles.categoryListItem}>
        {category}
      </div>
    ));
  };
  return (
    <div className={styles.categoryReason}>
      <div className={styles.categoryReasonTitle}>
        {i18n.t('doctorTableRowExtender.labels.banCategoryReasonTitle')}
      </div>
      <div className={styles.categoryList}>
        {renderList()}
      </div>
    </div>
  );
}

function renderInterstitialReason (space) {
  if (space.campaignSetting) {
    return <div>{i18n.t('doctorTableRowExtender.labels.intersitialCampaign')}</div>;
  } else {
    return <div>{i18n.t('doctorTableRowExtender.labels.intersitialCreative')}</div>;
  }
}

export default function renderReason (space) {
  switch (space.type) {
    case FILTER_TYPE.CATEGORY:
      return renderCategoryReason(space);
    case FILTER_TYPE.INTERSTITIAL:
      return renderInterstitialReason(space);
    default:
      return <div>{space}</div>;
  }
}

import { LoadingIndicator } from 'components/common/LoadingIndicator';
import React from 'react';
import { useCreditDetailModel } from './CreditDetailModel';
import styles from './creditDetail.module.scss';
import { StickableTableContainer } from 'containers/StickableTableContainer/StickableTableContainer';
import i18n from 'i18n';
import { InfoDisplaySection } from 'components/InfoDisplaySection/InfoDisplaySection';
import { StickableBootstrapTable } from 'components/common/Table/StickableBootstrapTable';
import paginationFactory from 'react-bootstrap-table2-paginator';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { hasRoles } from 'core/permission/PermissionDSL';
import { RoleNames } from 'core';
import _ from 'lodash';
import { CreditPayModal } from '../CreditPayModal/CreditPayModal';

export const CreditDetail: React.FC<{ agencyId: number }> = ({ agencyId }) => {

  const {
    loading,
    creditInfo,
    bills,
    billInfo,
    columns,
    creditLog,
    agencyToPay,
    setAgencyToPay,
    onPayModalClose
  } = useCreditDetailModel(agencyId);

  return (
    <StickableTableContainer>
      <div className={styles.creditDetail}>
        {loading && <LoadingIndicator />}
        {agencyToPay && <CreditPayModal agencyId={agencyToPay} onClose={onPayModalClose} bills={bills} />}
        <div className={styles.title}>
          {i18n.t('creditDetail.labels.title')}
        </div>
        <div className={styles.titleBottomLine} />
        <div className={styles.detailArea}>
          <div className={styles.topArea}>
            <div className={styles.buttonArea}>
              <PermissionChecker permissionAware={hasRoles(RoleNames.sysAdmin)}>
                <div className={styles.payBtn} onClick={_.partial(setAgencyToPay, agencyId)}>
                  {i18n.t('creditDetail.labels.pay')}
                </div>
              </PermissionChecker>
            </div>
          </div>
          <InfoDisplaySection data={creditInfo} columnWidth={300} />
          <div className={styles.sepLine} />
          <InfoDisplaySection data={billInfo} columnWidth={300} />
        </div>
        <div className={styles.table}>
          <StickableBootstrapTable
            stickFirstColumn
            keyField='id'
            data={creditLog}
            columns={columns}
            noDataIndication={i18n.t('creditLog.labels.noData')}
            defaultSorted={[{
              dataField: 'id',
              order: 'desc'
            }]}
            pagination={paginationFactory({
              hideSizePerPage: true,
              sizePerPage: 10
            })}
          />
        </div>
      </div>
    </StickableTableContainer>
  );
};

import { useState, useEffect, useCallback } from 'react';
import { useFetchData } from 'utils/FinalReportUtil';
import i18n from 'i18n';
import _ from 'lodash';
import { FinalReportResponseStatus } from 'core/finalReport/FinalReportStatus';
import { ReportManager, DefaultReportManager } from 'core/report/ReportManager';
import { DismissFunction } from 'components/AlertDialog';
import { ROUTE_PATH } from 'enum/RoutePath';

const defaultReportManager: ReportManager = new DefaultReportManager();

export type FinalReportModelData = {
  loading: boolean;
  data: any;
  reportId: string;
  modalData: {
    title: string,
    message: string,
    onConfirm: DismissFunction
  } | undefined;
  redirectPath: string | undefined;
};

export const useFinalReportPageModel = (
  search: string,
  pathname: string,
  logined: boolean
): FinalReportModelData => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>();
  const [failMessage, setFailMessage] = useState<string>('');
  const [modalData, setModalData] = useState<{
    title: string,
    message: string,
    onConfirm: DismissFunction
  } | undefined>();
  const [redirectPath, setRedirectPath] = useState<string | undefined>();
  const [error, retryFetch] = useFetchData();

  const permissionAccepted = !pathname.includes(`${ROUTE_PATH.ERROR403}`);
  const preparing = search.includes('preparing');
  const reportId = search.includes('id') ? _.get(search.split('='), '[1]') : '';

  const callFetchDataAPI = useCallback(async (reportId: string) => {
    const retryTime = 10;
    setLoading(true);
    logined && permissionAccepted && retryFetch(
      defaultReportManager.getFinalReportData.bind(defaultReportManager, reportId),
      (response: FinalReportResponseStatus) => {
        // console.log('final response', response);
        if (response.status === 'SUCCESS') {
          setData(JSON.parse(response.payload));
          setModalData(undefined);
        } else if (response.status === 'FAIL') {
          setFailMessage(i18n.t('finalReportPage.messages.invalid'));
        }
        setLoading(false);
      },
      retryTime
    );
  }, [retryFetch, logined, permissionAccepted]);

  useEffect(() => {
    if (reportId) {
      callFetchDataAPI(reportId);
    } else if (preparing) {
      setLoading(true);
    } else if (!permissionAccepted) {
      setLoading(false);
    }
  }, [callFetchDataAPI, preparing, reportId, permissionAccepted]);

  useEffect(() => {
    if (!logined) {
      setModalData({
        title: i18n.t('finalReportPage.titles.confirmation'),
        message: i18n.t('finalReportPage.messages.login'),
        onConfirm: _.partial(setRedirectPath, '/')
      });
    }
    if (!permissionAccepted) {
      setModalData({
        title: i18n.t('finalReportPage.titles.confirmation'),
        message: i18n.t('finalReportPage.messages.permission'),
        onConfirm: _.partial(setRedirectPath, '/')
      });
    }
    if (error) {
      setLoading(false);
      setModalData({
        title: i18n.t('finalReportPage.titles.fail'),
        message: i18n.t('finalReportPage.messages.exceeded'),
        onConfirm: _.partial(callFetchDataAPI, reportId)
      });
    }
    if (failMessage) {
      setModalData({
        title: i18n.t('finalReportPage.titles.fail'),
        message: failMessage,
        onConfirm: _.partial(setRedirectPath, '/')
      });
    }
  }, [logined, permissionAccepted, reportId, error, failMessage, callFetchDataAPI]);

  return {
    loading,
    data,
    reportId,
    modalData,
    redirectPath
  };
};

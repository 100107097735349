import React from 'react';
import styles from './adNeonMediaSummary.module.scss';
import { VideoPreview } from 'components/common/Video/VideoPreview';
import { ImagePreview } from 'components/common/Image/ImagePreview';

type mediaSummaryData = {
  medias: {
    video?: {
      url: string,
      file?: File
    },
    image?: {
      url: string,
      file?: File
    }
  }
};

export const AdNeonMediaSummary: React.FunctionComponent<{ mediaSummary: mediaSummaryData }> = props => {
  const medias = props.mediaSummary.medias;
  return (
    <div className={styles.adNeonPreview}>
      {medias.image &&
        <ImagePreview
          size={{ width: 300, height: 250 }}
          border={false}
          url={medias.image.url}
          file={medias.image.file}
          config={{
            showName: false,
            showSize: false
          }}
        />
      }
      {medias.video &&
        <VideoPreview
          maxWidth={300}
          maxHeight={250}
          src={medias.video.file || medias.video.url}
        />
      }
    </div>
  );
};

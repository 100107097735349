import React from 'react';
import { Switch, Route, RouteComponentProps, Redirect } from 'react-router-dom';
import RouteWithPermission from 'components/RouteWithPermission/RouteWithPermission';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { hasFuncs } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';
import { ROUTE_PATH } from 'enum/RoutePath';
import { withErrorBoundary } from 'hoc/withErrorBoundary';
import { ERROR_CODE } from 'core/ErrorCode';
import { TiktokAdGroupSetupFlowPage } from './TiktokAdGroupSetupFlowPage/TiktokAdGroupSetupFlowPage';
import { useCreateTiktokAdGroupSetupFlowPageModel, useEditTiktokAdGroupSetupFlowPageModel } from './TiktokAdGroupSetupFlowPage/TiktokAdGroupSetupFlowPageModel';

const AdGroupSetupView = withErrorBoundary(ERROR_CODE.ADSET_CREATE, TiktokAdGroupSetupFlowPage);
export const TiktokAdGroups: React.FC<RouteComponentProps<any> & any> = ({
  match,
  order,
  campaignGroup,
  tiktokAdGroupList
}) => {

  const renderCreateAdGroupView = props => {
    const renderRedirect = () => <Redirect to={`${props.match.url}/${ROUTE_PATH.ERROR403}`} />;
    return (
      <PermissionChecker
        permissionAware={hasFuncs(Permission.CAMPAIGN_WRITE)}
        renderOtherwise={renderRedirect}
      >
        <AdGroupSetupView
          order={order}
          campaignGroup={campaignGroup}
          tiktokAdGroupList={tiktokAdGroupList}
          useModel={useCreateTiktokAdGroupSetupFlowPageModel}
        />
      </PermissionChecker>
    );
  };

  const renderEditAdGroupView = props => {
    const renderRedirect = () => <Redirect to={`${props.match.url}/${ROUTE_PATH.ERROR403}`} />;
    return (
      <PermissionChecker
        permissionAware={hasFuncs(Permission.CAMPAIGN_WRITE)}
        renderOtherwise={renderRedirect}
      >
        <AdGroupSetupView
          order={order}
          campaignGroup={campaignGroup}
          tiktokAdGroupList={tiktokAdGroupList}
          useModel={useEditTiktokAdGroupSetupFlowPageModel}
        />
      </PermissionChecker>
    );
  };

  const redirectToCampaignGroup = (props) => {
    const match = props.match.url.match(/\/campaign-groups\/\d+\//);
    return <Redirect to={`${match[0].slice(0, -1)}`} />;
  };

  return (
    <Switch>
      <RouteWithPermission
        permissionAware={hasFuncs(Permission.CAMPAIGN_WRITE)}
        redirectPath={`${match.url}/new/${ROUTE_PATH.ERROR403}`}
        path={`${match.url}/new`}
        render={renderCreateAdGroupView}
      />
      <Route
        exact
        path={`${match.url}/:adGroupId/edit`}
        render={renderEditAdGroupView}
      />
      <Route
        render={redirectToCampaignGroup}
      />
    </Switch>
  );
};

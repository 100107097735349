import { ThirdPartyVideoPreview } from 'components/common/Video/ThirdPartyVideoPreview';
import { VideoPreview } from 'components/common/Video/VideoPreview';
import React from 'react';
import styles from './videoMediaSummary.module.scss';

export const VideoMediaSummary: React.FunctionComponent<{ mediaSummary: any }> = props => {

  const isThirdPartyVideo = props.mediaSummary.isThirdPartyVideo;
  const videoData = isThirdPartyVideo ? props.mediaSummary.urlVideo : props.mediaSummary.fileVideo;
  const file = videoData.file;
  const source = file ? URL.createObjectURL(file) : videoData.url;
  return (
    <div className={styles.videoPreview}>
      {
      isThirdPartyVideo ?
        <div className={styles.thirdPartyVideoPreviewContainer}>
          <ThirdPartyVideoPreview url={videoData.url} canClick={false}/>
        </div> :
        <VideoPreview src={source}/>
      }
      {file &&
        <div className={styles.videoInfo}>
          {file.name}
        </div>
      }
    </div>
  );
};

type AddonFeatures = {[category: string]: {[key: string]: boolean}};

export type LocaleMeta = {
  readonly timezone: string;
  readonly currency: string;
  readonly currencyRate: number;
  readonly language: string;
  readonly agcPercent: number;
  readonly maxOrderProfit: number;
  readonly maxOrderBudget: number;
  readonly credit: number;
  readonly addonFeatures: AddonFeatures;
};

export const MOMENT_TIMEZONE = {
  '+09:00': 'Asia/Tokyo',
  '+08:00': 'Asia/Taipei',
  '+07:00': 'Asia/Ho_Chi_Minh'
};

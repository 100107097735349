import React from 'react';
import ReactDOM from 'react-dom';

import './bootstrap.scss';
import './index.css';

import 'i18n';
import App from './App';
import { StylePage } from './pages/StylePage';
import * as serviceWorker from './service-worker';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure({
  autoClose: 5000,
  draggable: false,
  closeButton: false,
  hideProgressBar: true,
  position: 'bottom-right'
});

const routing = (
  <BrowserRouter>
    <Switch>
      <Route path='/styles' component={StylePage} />
      <Route path='/' component={App} />
    </Switch>
  </BrowserRouter>
);

ReactDOM.render(routing, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

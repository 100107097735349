import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import './Agencies.scss';

import { AgencyDetail } from './AgencyDetail';
import { AgencyForm, AgencyFormForAgency } from './AgencyForm';
import { AgenciesHome } from './AgenciesHome';
import { AgenciesProps } from './AgenciesModel';
import { ROUTE_PATH } from 'enum/RoutePath';
import { withErrorBoundary } from 'hoc/withErrorBoundary';
import { ERROR_CODE } from 'core/ErrorCode';

const AgencyHomeView = withErrorBoundary(ERROR_CODE.AGENCY_HOME, AgenciesHome);
const CreateAgencyView = withErrorBoundary(ERROR_CODE.AGENCY_CREATE, AgencyForm);
const EditAgencyByAdminView = withErrorBoundary(ERROR_CODE.AGENCY_EDIT_BY_ADMIN, AgencyForm);
const AgencyDetailView = withErrorBoundary(ERROR_CODE.AGENCY_DETAIL, AgencyDetail);
const EditAgencyByAgencyView = withErrorBoundary(ERROR_CODE.AGENCY_EDIT_BY_AGENCY, AgencyFormForAgency);
export class Agencies extends React.Component<AgenciesProps> {

  componentWillUnmount () {
    this.props.model.onUnmount();
  }

  renderAgencyHomeView = () => {
    const model = this.props.model;
    if (model.canViewAllAgencies) {
      return (<AgencyHomeView model={model.homeModel} />);
    } else if (model.isAgencyView && model.operatorAgencyPath) {
      return (<Redirect push to={model.operatorAgencyPath} />);
    }
    return (<Redirect push to={`/agencies/${ROUTE_PATH.ERROR403}`} />);
  }

  renderAgencyCreateView = () => {
    if (this.props.model.canCreateAgency) {
      return (<CreateAgencyView model={this.props.model.getCreateAgencyFormModel()} />);
    }
    return (<Redirect push to={`/agencies/new/${ROUTE_PATH.ERROR403}`} />);
  }

  renderAgencyDetailView = (props) => {
    if (this.props.model.canViewAgency()) {
      const defaultAgencyDetailModel = this.props.model.getAgencyDetailModel(parseInt(props.match.params.agencyId, 10));
      defaultAgencyDetailModel.isSystemViewOnly = this.props.model.isSystemViewOnly;
      return (<AgencyDetailView model={defaultAgencyDetailModel} />);
    }
    return (<Redirect push to={`${props.match.url}/${ROUTE_PATH.ERROR403}`} />);
  }

  renderAgencyEditView = (props) => {
    if (this.props.model.isAgencyView && this.props.model.canViewAgency()) {
      const defaultAgencyFormForAgencyModel = this.props.model.getEditAgencyFormOfAgencyModel(parseInt(props.match.params.agencyId, 10));
      return (<EditAgencyByAgencyView model={defaultAgencyFormForAgencyModel} />);
    } else if (this.props.model.canEditAgency) {
      const editAgencyDetailModel = this.props.model.getEditAgencyFormModel(parseInt(props.match.params.agencyId, 10));
      return (<EditAgencyByAdminView model={editAgencyDetailModel} />);
    }
    console.log(this.props.model.isAgencyView, this.props.model.canEditAgency, this.props.model.canViewAgency());
    return (<Redirect push to={`${props.match.url}/${ROUTE_PATH.ERROR403}`} />);
  }

  render () {
    return (
      <div className='agencies'>
        <Switch>
          <Route
            path='/agencies/new'
            render={this.renderAgencyCreateView}
          />
          <Route
            exact
            path='/agencies/:agencyId'
            render={this.renderAgencyDetailView}
          />
          <Route
            exact
            path='/agencies/:agencyId/edit'
            render={this.renderAgencyEditView}
          />
          <Route
            render={this.renderAgencyHomeView}
          />
        </Switch>
      </div>
    );
  }
}

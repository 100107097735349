import { toast } from 'react-toastify';
import _ from 'lodash';

import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import {
  DefaultLimitationManager,
  LimitationManager
} from 'core/limitation/LimitationManager';
import { AbstractLimitationModel } from 'components/CampaignLimitation/CampaingLimitationForm/AbstractLimitation';
import { SelectOptions } from 'components/common/commonType';
import { createSelectOptions } from 'utils/SelectOptionsUtils';

export type KeywordComponentState = {
  readonly canSearch: boolean;
  readonly searchLoading: boolean;
  readonly articleCount?: number;
};

export interface KeywordComponentModel extends AbstractLimitationModel {
  readonly state: KeywordComponentState;
  readonly event: UpdateEventListener<KeywordComponentModel>;
  readonly firstQueryParameter: string | undefined;
  readonly secondQueryParameter: string | undefined;
  readonly language: string;
  onJoin (): void;
  changeFirstKeyword (firstKeyword: string): void;
  changeSecondKeyword (secondKeyword: string): void;
  changeLanguage (language: string): void;
  clearKeyword (): void;
  queryKeywordArticleCount (): Promise<void>;
}

export type KeywordComponentProps = {
  readonly model: KeywordComponentModel;
};

export class DefaultKeywordComponentModel implements KeywordComponentModel {
  data?: SelectOptions[];
  value?: any;
  event: FireableUpdateEventListener<KeywordComponentModel>;
  canSearch: boolean;
  searchLoading: boolean;
  articleCount?: number;
  manager: LimitationManager;
  modelName: string;
  modelFirstQueryParameter?: string;
  modelSecondQueryParameter?: string;
  modelLanguage: string;
  externalOnChange: (type: string, value: SelectOptions[]) => void;

  constructor (
    name: string,
    value: any,
    externalOnChange: (type: string, value: SelectOptions[]) => void,
    limitationManager: LimitationManager = new DefaultLimitationManager()
  ) {
    this.value = value;
    this.canSearch = false;
    this.searchLoading = false;
    this.modelName = name;
    this.manager = limitationManager;
    this.modelLanguage = 'ZH';
    this.externalOnChange = externalOnChange;
    this.event = new FireableUpdateEventListener<KeywordComponentModel>();
  }

  async queryKeywordArticleCount (): Promise<void> {
    this.updateState(false, true);
    const {
      modelLanguage,
      modelFirstQueryParameter,
      modelSecondQueryParameter
    } = this;
    let articleCounts = 0;
    try {
      const firstQueryParameters = this.keywordStringToArray(
        modelFirstQueryParameter
      );
      const secondQueryParameters = this.keywordStringToArray(
        modelSecondQueryParameter
      );
      articleCounts = await this.manager.keywordEstimate({
        firstQueryParameters,
        secondQueryParameters,
        language: modelLanguage
      });
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
    }
    this.updateState(true, false, articleCounts);
  }

  onJoin () {
    const getValuesFromType = (type: string) => {
      return (_.chain(this.value).find(['type', type]) as any)
        .get('value', [])
        .map('value')
        .value();
    };
    const defaultUnicornkeyword1: string[] = getValuesFromType(
      'unicornkeyword1'
    );
    const defaultUnicornkeyword2: string[] = getValuesFromType(
      'unicornkeyword2'
    );
    const unicornKeyword1 = createSelectOptions(
      _.chain(this.keywordStringToArray(this.modelFirstQueryParameter))
        .concat(defaultUnicornkeyword1)
        .uniq()
        .value()
    );
    const unicornKeyword2 = createSelectOptions(
      _.chain(this.keywordStringToArray(this.modelSecondQueryParameter))
        .concat(defaultUnicornkeyword2)
        .uniq()
        .value()
    );
    const unicornLanguage = [
      { label: this.modelLanguage, value: this.modelLanguage }
    ];
    const unicornKeyword1Exist = unicornKeyword1.length > 0;
    const unicornKeyword2Exist = unicornKeyword2.length > 0;
    if (unicornKeyword1Exist) {
      this.externalOnChange.call(this, 'unicornkeyword1', unicornKeyword1);
    }
    if (unicornKeyword2Exist) {
      this.externalOnChange.call(this, 'unicornkeyword2', unicornKeyword2);
    }
    if (unicornKeyword1Exist || unicornKeyword2Exist) {
      this.externalOnChange.call(this, 'unicornlanguage', unicornLanguage);
    }
  }

  keywordStringToArray (keywordString?: string): string[] {
    if (!keywordString) {
      return [];
    }

    if (keywordString[keywordString.length - 1] === ',') {
      keywordString = keywordString.slice(0, -1);
    }

    if (keywordString[0] === ',') {
      keywordString = keywordString.slice(1);
    }
    return keywordString.split(',').map(keyword => keyword.trim());
  }

  get name (): string {
    return this.modelName;
  }

  get firstQueryParameter (): string | undefined {
    return this.modelFirstQueryParameter;
  }

  get secondQueryParameter (): string | undefined {
    return this.modelSecondQueryParameter;
  }

  get language (): string {
    return this.modelLanguage;
  }

  clearKeyword () {
    this.changeQueryParameter(this.modelLanguage, undefined, undefined);
  }

  changeFirstKeyword (firstKeyword: string) {
    this.changeQueryParameter(
      this.modelLanguage,
      firstKeyword,
      this.modelSecondQueryParameter
    );
  }

  changeSecondKeyword (secondKeyword: string) {
    this.changeQueryParameter(
      this.modelLanguage,
      this.modelFirstQueryParameter,
      secondKeyword
    );
  }

  changeLanguage (language: string) {
    this.changeQueryParameter(
      language,
      this.modelFirstQueryParameter,
      this.modelSecondQueryParameter
    );
  }

  changeQueryParameter (
    language: string,
    firstKeyword?: string,
    secondKeyword?: string
  ) {
    this.modelLanguage = language;
    this.modelFirstQueryParameter = firstKeyword;
    this.modelSecondQueryParameter = secondKeyword;
    const firstKeywordExists = !!firstKeyword;
    const secondKeywordExists = !!secondKeyword;
    if (firstKeywordExists || secondKeywordExists) {
      this.updateState(true);
    } else {
      this.updateState(false);
    }
  }

  get state (): KeywordComponentState {
    return {
      canSearch: this.canSearch,
      searchLoading: this.searchLoading,
      articleCount: this.articleCount
    };
  }

  updateState (
    canSearch: boolean = false,
    searchLoading: boolean = false,
    articleCount?: number
  ) {
    this.canSearch = canSearch;
    this.searchLoading = searchLoading;
    this.articleCount = articleCount;
    this.event.fireEvent(this);
  }
}

import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Button } from 'react-bootstrap';
import { renderColumn, ColumnDefinition } from 'components/TableColumn';
import { Link } from 'react-router-dom';
import styles from './creativeBindManagePage.module.scss';
import _ from 'lodash';
import i18n from 'i18next';

export class CreativeBindManagePage extends React.PureComponent<any, any> {

  constructor (props) {
    super(props);
    this.state = {
      selectCampaigns: [],
      bindingData: props.bindingData.map(data => {
        return {
          ...data,
          selected: false
        };
      })
    };
  }

  updateState = (selectCampaigns) => {
    this.setState({
      selectCampaigns,
      bindingData: this.state.bindingData.map(data => {
        return {
          ...data,
          selected: selectCampaigns.indexOf(data.id) > -1
        };
      })
    });
  }

  handleOnSelect = (campaignId) => {
    const currentSelectCampaigns = this.state.selectCampaigns;
    if (currentSelectCampaigns.indexOf(campaignId) === -1) {
      currentSelectCampaigns.push(campaignId);
    } else {
      _.remove(currentSelectCampaigns, id => id === campaignId);
    }
    this.updateState(currentSelectCampaigns);
  }

  idFormatter = (id: any, data: any): any => {
    const handleOnSelect = (e) => {
      this.handleOnSelect(id);
    };
    return (
      <div>
        <div className={styles.selectCheckBox}>
          <input
            type='checkbox'
            checked={data.selected}
            onChange={handleOnSelect}
            id={`creativeBindManageInput-${id}`}
          />
          <label htmlFor={`creativeBindManageInput-${id}`}/>
        </div>
        <Link to={`/campaigns/${id}/redirectToDetail`} onClick={this.props.close}>{id}</Link>
      </div>
    );
  }

  handleOnSelectAll = () => {
    if (this.state.selectCampaigns.length === this.state.bindingData.length) {
      this.updateState([]);
    } else {
      this.updateState(this.state.bindingData.map(data => data.id));
    }
  }

  idHeaderFormatter = (nameIdHeader: any, columnIndex: number, { sortElement }) => {
    return (
      <div className={styles.nameIdHeader}>
        <div className={styles.selectCheckBox}>
          <input
            type='checkbox'
            checked={this.state.selectCampaigns.length === this.state.bindingData.length}
            ref={el => el && (el.indeterminate = this.state.selectCampaigns.length !== this.state.bindingData.length && this.state.selectCampaigns.length > 0)}
            onChange={this.handleOnSelectAll}
            id={'creativeBindManagePage-selectAll'}
          />
          <label htmlFor='creativeBindManagePage-selectAll'/>
        </div>
        {i18n.t(nameIdHeader.text)}
        {sortElement}
      </div>
    );
  }

  nameFormatter (name: any): any {
    return (
      <div>
        {name}
      </div>
    );
  }

  statusFormatter (isActive: any): any {
    return (
      <div>
        {isActive ? i18n.t('creativeBindManagePage.labels.active') : i18n.t('creativeBindManagePage.labels.deactive')}
      </div>
    );
  }

  getColumnSetting (columnName) {
    const columnClassGetter = () => {
      return styles[columnName];
    };

    return {
      sort: true,
      text: `creativeBindManagePage.headers.${columnName}`,
      dataField: columnName,
      classes: columnClassGetter,
      headerClasses: columnClassGetter
    };
  }

  get idColumn (): ColumnDefinition {
    return this.getColumnSetting('id');
  }

  get nameColumn (): ColumnDefinition {
    return this.getColumnSetting('name');
  }

  get statusColumn (): ColumnDefinition {
    return this.getColumnSetting('isActive');
  }

  enableBinding = () => {
    this.props.callback(this.state.selectCampaigns, true);
  }

  disableBinding = () => {
    this.props.callback(this.state.selectCampaigns, false);
  }

  render () {
    const columns = [
      renderColumn(this.idColumn, this.idFormatter, this.idHeaderFormatter),
      renderColumn(this.nameColumn, this.nameFormatter),
      renderColumn(this.statusColumn, this.statusFormatter)
    ];

    return (
      <div className={styles.creativeBindManagePage}>
        <div className={styles.buttonArea}>
          <Button variant='secondary' disabled={this.state.selectCampaigns.length === 0} size='sm' onClick={this.enableBinding}>
            {i18n.t('creativeBindManagePage.buttons.active')}
          </Button>
          <Button variant='secondary' disabled={this.state.selectCampaigns.length === 0} size='sm' onClick={this.disableBinding}>
            {i18n.t('creativeBindManagePage.buttons.deactive')}
          </Button>
        </div>
        <div className={styles.tableContainer}>
          <BootstrapTable
            keyField='id'
            data={this.state.bindingData}
            columns={columns}
            noDataIndication={i18n.t('creativeBindManagePage.labels.noData')}
            defaultSorted={[
              {
                dataField: 'id',
                order: 'desc'
              }
            ]}
          />
        </div>
      </div>
    );
  }
}

import React from 'react';
import { Slider } from 'components/common/Slider/Slider';
import { DefaultSliderModel } from 'components/common/Slider/SliderModel';
import _ from 'lodash';
import { NativePreview } from 'containers/Creatives/CreativeSetupFlow/FlowSteps/SubSteps/FormContent/NativePreview';
import styles from './nativeCreativeDetail.module.scss';
import { CreativeBasic } from 'core/creative/Creative';
import { DefaultNativeBannerPreviewModel, NativeBannerPreviewModel } from 'containers/Creatives/NativeBannerPreview/NativeBannerPreviewModel';
import ToggleButtons from 'components/common/ToggleButtons/ToggleButtons';
import cx from 'classnames/bind';
import i18n from 'i18n';

enum PREVIEWTYPE {
  NATIVE = 'Native',
  BANNER = 'Banner'
}

export const NativeCreativeDetail: React.FunctionComponent<{ creative: Partial<CreativeBasic>, nativeBannerPreviewModel?: NativeBannerPreviewModel }> = (props) => {
  const [previewType, setPreviewType] = React.useState(PREVIEWTYPE.NATIVE);
  const previewAreaWidth = 600;
  const previewAreaHeight = 400;
  const creativeValue = _.get(props.creative, 'creativeValue', _.get(props.creative, 'creativeValues'));
  if (!creativeValue) {
    return <div />;
  }
  const classNames = cx.bind(styles);
  const typeProperties = {
    title: _.get(creativeValue, 'title'),
    desc: _.get(creativeValue, 'desc'),
    sponsor: _.get(creativeValue, 'sponsored.name'),
    sponsorLink: _.get(creativeValue, 'sponsored.link')
  };
  const medias = {
    rectImg: _.get(creativeValue, 'img', {}),
    squareImg: _.get(creativeValue, 'icon', {})
  };
  const bannerUrl = _.get(props.creative, 'bannerUrl', '');
  const nativeBannerPreviewModel = props.nativeBannerPreviewModel ? props.nativeBannerPreviewModel : new DefaultNativeBannerPreviewModel({
    typeProperties,
    medias,
    bannerUrl,
    nativeBanner: props.creative.nativeBanner
  }, {});
  const updatePreviewType = (previewType) => {
    const previewTypeKey = _.find(Object.keys(PREVIEWTYPE), key => PREVIEWTYPE[key] === previewType);
    if (previewTypeKey) {
      setPreviewType(PREVIEWTYPE[previewTypeKey]);
    } else {
      setPreviewType(PREVIEWTYPE.NATIVE);
    }
  };
  const nativeDetailClass = classNames('nativeDetail', {
    show: previewType === PREVIEWTYPE.NATIVE
  });
  const nativeBannerDetailClass = classNames('nativeBannerDetail', {
    show: previewType === PREVIEWTYPE.BANNER
  });
  const previewOptions = [
    { label: i18n.t(`nativeCreativeDetail.labels.toggle${PREVIEWTYPE.NATIVE}`), value: PREVIEWTYPE.NATIVE },
    { label: i18n.t(`nativeCreativeDetail.labels.toggle${PREVIEWTYPE.BANNER}`), value: PREVIEWTYPE.BANNER }
  ];
  const showBanner = _.get(props.creative, 'enableNativeBanner', false);
  const nativeBannerData = {
    '300x250': _.get(nativeBannerPreviewModel.creative.nativeBanner, '300x250'),
    '300x600': _.get(nativeBannerPreviewModel.creative.nativeBanner, '300x600'),
    '320x100': _.get(nativeBannerPreviewModel.creative.nativeBanner, '320x100'),
    '336x280': _.get(nativeBannerPreviewModel.creative.nativeBanner, '336x280'),
    '728x90': _.get(nativeBannerPreviewModel.creative.nativeBanner, '728x90')
  };
  const renderIndicator = ({ activeIndex, handleSelect }) => {
    if (!nativeBannerData) {
      return <div />;
    }
    const indicators = Object.keys(nativeBannerData).map((size, index) => {
      const buttonClassName = classNames('button', {
        active: index === activeIndex
      });
      return (
        <span
          key={size}
          className={buttonClassName}
          onClick={_.partial(handleSelect, index)}
        >
          {size}
        </span>
      );
    });
    return (
      <div className={styles.buttonArea}>
        {indicators}
      </div>
    );
  };
  return (
    <div className={styles.nativeCreativeDetail}>
      {showBanner &&
        <ToggleButtons
          className={styles.toggleButtons}
          name={'previewType'}
          value={previewType}
          options={previewOptions}
          onChange={updatePreviewType}
        />
      }
      <div className={nativeDetailClass}>
        <Slider model={new DefaultSliderModel(previewAreaWidth, previewAreaHeight, true, false, 0, false)}>
          <NativePreview
            url={medias.rectImg.url}
            file={medias.rectImg.file}
            bannerUrl={bannerUrl}
            creativeValues={typeProperties}
          />
          <NativePreview
            square={true}
            url={medias.squareImg.url}
            file={medias.squareImg.file}
            bannerUrl={bannerUrl}
            creativeValues={typeProperties}
          />
        </Slider>
      </div>
      {showBanner && nativeBannerData &&
        <div className={nativeBannerDetailClass}>
          <Slider
            model={new DefaultSliderModel(757, 650, true, false, 0, false)}
            renderIndicator={renderIndicator}
          >
            {
              Object.keys(nativeBannerData).map(size => {
                const sizes = size.split('x');
                return nativeBannerData[size].imageUrl ?
                  <img key={size} src={nativeBannerData[size].imageUrl} alt={`${size} banner`} /> :
                  <iframe
                    key={size}
                    title={size}
                    width={sizes[0]}
                    height={sizes[1]}
                    src={nativeBannerData[size].htmlUrl}
                    scrolling='no'
                    frameBorder='0'
                    marginHeight={0}
                    marginWidth={0}
                  />;
              })
            }
          </Slider>
        </div>
      }
    </div>
  );
};

import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import React from 'react';
import styles from './titleWithBreadcrumbs.module.scss';

export const TitleWithBreadcrumbs = ({
  title,
  routes
}) => {
  return (
    <div className={styles.titleWithBreadcrumbs}>
      <div className={styles.title}>
        {title}
      </div>
      <div className={styles.breadcrumbs}>
        <Breadcrumbs routes={routes}/>
      </div>
    </div>
  );
};

import { Actor } from 'core';
import _ from 'lodash';

export function typeOf (actor: Actor): string {
  return getRoleNamePartOf(actor, 0, 'System');
}

export function roleOf (actor: Actor): string {
  return getRoleNamePartOf(actor, 1, 'ADMIN');
}

function getRoleNamePartOf (actor: Actor, index: number, defaultValue: string): string {
  return getRoleNamePart(actor.roleName, index, defaultValue);
}

export function getRoleNamePart (roleName: string, index: number, defaultValue: string): string {
  const parts = roleName.split('_');
  let value = (parts.length === 2) ? parts[index] : defaultValue;
  if (parts.length === 3) {
    value = index === 0 ? parts[0] : `${parts[1]} ${parts[2]}`;
  }

  return _.startCase(_.capitalize(value));
}

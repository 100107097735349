import { CampaignGroup } from 'core/campaignGroup/CampaignGroup';
import { Order } from 'core/order/Order';
import moment from 'moment';
import { useCallback, useContext, useMemo, useState } from 'react';
import { FbAdSetFormData } from '../FbAdSetSetupFlowPageModel';
import _ from 'lodash';
import { SelectOptions } from 'components/common/commonType';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import coreContext from 'contexts/coreContext';
import { GoCampaignOptimizationGoal } from 'core/goCampaign/GoCampaign';
import { DefaultFbAdSetManager, FbAdSetManager } from 'core/fbAdSet/FbAdSetManager';

type FbAdSetBasicFormModelData = {
  currency: string,
  canEditBidCap: boolean,
  dateRangeProps: {
    minDate: string,
    maxDate: string,
    isAdSetStart?: boolean
  },
  campaignGroup: CampaignGroup,
  billingEventOptions: SelectOptions[],
  appEventOptions: SelectOptions[] | undefined,
  optimizationGoalOptions: SelectOptions[],
  onOptimizationGoalChanged: (
    afterChangedCallback: (newOptimizationGoal, newDefaultBillingEvent) => void,
    newOptimizationGoal: GoCampaignOptimizationGoal
  ) => void
};

export type FbAdSetFormModelData = FbAdSetBasicFormModelData & {
  canEditBidStrategy: boolean;
  formType: string;
};

const defaultFbAdSetManager: FbAdSetManager = new DefaultFbAdSetManager();

const useFbAdSetFormModel = (
  order: Order,
  campaignGroup: CampaignGroup,
  adSet: FbAdSetFormData,
  fbAdSetManager: FbAdSetManager = defaultFbAdSetManager
): FbAdSetBasicFormModelData => {

  const core = useContext(coreContext);

  const minDate = useMemo(() => {
    const orderStartDate = moment(_.get(order, 'startDate'));
    const thisHour = moment().startOf('hour').format('YYYY-MM-DD_HH:mm:ss');
    if (adSet && adSet.start_time) {
      return moment(adSet.start_time).isBefore() ? moment(adSet.start_time).format('YYYY-MM-DD_HH:mm:ss') : thisHour;
    }
    return moment().isAfter(orderStartDate) ? thisHour : orderStartDate.format('YYYY-MM-DD_HH:mm:ss');
  }, [order, adSet]);

  const maxDate = useMemo(() => {
    return moment(_.get(order, 'endDate')).endOf('day').format('YYYY-MM-DD HH:mm:ss');
  }, [order]);

  const optimizationGoalOptions = useMemo(() => {
    return fbAdSetManager.getOptimizationGoalOptions(campaignGroup);
  }, [campaignGroup, fbAdSetManager]);

  const [billingEventOptions, setBillingEventOptions] = useState<SelectOptions[]>(
    fbAdSetManager.getBilliingEventOptions(campaignGroup, adSet.optimization_goal)
  );

  const [appEventOptions, setAppEventOptions] = useState<SelectOptions[] | undefined>(
    fbAdSetManager.getAppEventOptions(adSet.optimization_goal)
  );

  const onOptimizationGoalChanged = useCallback((afterChangedCallback, newOptimizationGoal) => {
    const billingEventOptions = fbAdSetManager.getBilliingEventOptions(campaignGroup, newOptimizationGoal);
    setBillingEventOptions(billingEventOptions);
    setAppEventOptions(fbAdSetManager.getAppEventOptions(newOptimizationGoal));
    afterChangedCallback(newOptimizationGoal, _.get(billingEventOptions[0], 'value'));
  }, [campaignGroup, fbAdSetManager]);

  return {
    campaignGroup,
    dateRangeProps: {
      minDate,
      maxDate
    },
    billingEventOptions,
    appEventOptions,
    optimizationGoalOptions,
    currency: order.currency,
    canEditBidCap: core ? core.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CAMPAIGN.ENABLE_OPTIMIZED_PRICE) : true,
    onOptimizationGoalChanged
  };
};

export const useFbCreateAdSetFormModel = (
  order: Order,
  campaignGroup: CampaignGroup,
  adSet: FbAdSetFormData
): FbAdSetFormModelData => {

  const basicFormModelData = useFbAdSetFormModel(order, campaignGroup, adSet);
  return {
    formType: 'create',
    ...basicFormModelData,
    dateRangeProps: {
      ...basicFormModelData.dateRangeProps,
      isAdSetStart: false
    },
    canEditBidStrategy: campaignGroup.autoOptimise ? false : true
  };
};

export const useFbEditAdSetFormModel = (
  order: Order,
  campaignGroup: CampaignGroup,
  adSet: FbAdSetFormData
): FbAdSetFormModelData => {

  const isAdSetStart = useMemo(() => {
    return adSet && adSet.start_time ? moment(adSet.start_time).isBefore() : false;
  }, [adSet]);

  const basicFormModelData = useFbAdSetFormModel(order, campaignGroup, adSet);
  return {
    formType: 'edit',
    ...basicFormModelData,
    dateRangeProps: {
      ...basicFormModelData.dateRangeProps,
      isAdSetStart
    },
    canEditBidStrategy: false
  };
};

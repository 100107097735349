import { ColumnDefinition, renderColumn, sortableColumn } from 'components/TableColumn';
import { CampaignGroup, CampaignGroupChannel } from 'core/campaignGroup/CampaignGroup';
import { FbAdSet } from 'core/fbAdSet/FbAdSet';
import { Order } from 'core/order/Order';
import { useEffect, useMemo, useState } from 'react';
import styles from './campaignGroupBudgetManagement.module.scss';
import _ from 'lodash';
import moment from 'moment';
import formatters from './listFormatter';
import { formatPriceWithCurrency, getPriceValue } from 'helper/CurrencyHelper';
import { validateMinimum } from 'utils/ValidateUtils';
import DefaultCampaignGroupManager, { CampaignGroupManager } from 'core/campaignGroup/CampaignGroupManager';
import i18n from 'i18n';
import { RtbCampaign } from 'core/rtbCampaign/RtbCampaign';
import { useCallAPI } from 'hooks/useCallAPI';
import { DefaultRtbCampaignManager, RtbCampaignManager } from 'core/rtbCampaign/RtbCampaignManager';
import { DefaultFbAdSetManager } from 'core/fbAdSet/FbAdSetManager';

export enum CampaignGroupBudgetManagementColumns {
  NAME = 'id',
  STATUS = 'status',
  SPENT = 'spent',
  BUDGET = 'budget'
}

const defaultCampaignGroupManager: CampaignGroupManager = new DefaultCampaignGroupManager();

export const useCampaignGroupBudgetManagementModel = (
  channel: CampaignGroupChannel,
  order: Order,
  campaignGroup: Partial<CampaignGroup>,
  initCampaignGroup: Partial<CampaignGroup> | undefined,
  goCampaignList: FbAdSet[] | RtbCampaign[],
  budgetDataList?: {id: string, budget: number }[],
  campaignGroupManager: CampaignGroupManager = defaultCampaignGroupManager
) => {

  const { loading, callAPIs } = useCallAPI();
  const [viewData, setViewData] = useState<any>([]);
  const [budgets, setBudgets] = useState<any>({});

  useEffect(() => {
    let fetchGoCampaignLifetimeSpents = channel === CampaignGroupChannel.FB ?
      fetchFbAdSetLifetimeSpents :
      fetchRtbCampaignLifetimeSpents;
    callAPIs([
      async () => fetchGoCampaignLifetimeSpents(
        order,
        campaignGroup,
        budgetDataList,
        goCampaignList,
        campaignGroupManager
      )
    ], ({
      viewData,
      budgets
    }) => {
      setViewData(viewData);
      setBudgets(budgets);
    });
  }, [channel, order, campaignGroup, campaignGroupManager, goCampaignList, budgetDataList, callAPIs]);

  const columnDefinition = (columnName): ColumnDefinition => ({
    ...sortableColumn(columnName, `campaignGroupBudgetManagement.headers.${columnName}`, true),
    classes: () => styles[columnName],
    headerClasses: () => styles[columnName]
  });

  const onBudgetChange = (id, newBudget) => {
    const index = viewData.findIndex(data => data.id === id);
    if (index === -1) {
      return;
    }
    viewData[index].error = validateMinimum(newBudget, viewData[index].minBudget);
    setBudgets({
      ...budgets,
      [id]: +newBudget
    });
    setViewData([...viewData]);
  };

  const remainBudget = useMemo(() => {
    const budget = initCampaignGroup && initCampaignGroup.budget ? initCampaignGroup.budget : 0;
    return order.budgetBalance +
      +budget -
      Object.keys(budgets).reduce((acc, key) => acc + (+budgets[key]), 0);
  }, [order.budgetBalance, initCampaignGroup, budgets]);

  const columns = [
    renderColumn({
      ...columnDefinition(CampaignGroupBudgetManagementColumns.NAME),
      footer: ''
    }, (_1, fbAdset) => fbAdset.name),
    renderColumn({
      ...columnDefinition(CampaignGroupBudgetManagementColumns.STATUS),
      footer: ''
    }, formatters.stateFormatter),
    renderColumn({
      ...columnDefinition(CampaignGroupBudgetManagementColumns.SPENT),
      footer: ''
    }, value => formatPriceWithCurrency(order.currency, value)),
    renderColumn({
      ...columnDefinition(CampaignGroupBudgetManagementColumns.BUDGET),
      formatExtraData: {
        currency: order.currency,
        onChange: onBudgetChange
      },
      footer: i18n.t(`campaignGroupBudgetManagement.hints.campaignBudget`, { total: remainBudget }),
      footerClasses: remainBudget < 0 ? styles.dangerHint : undefined
    }, formatters.budgetFormatter)
  ];

  const canSubmit = useMemo(() => {
    let hasError = remainBudget < 0;
    viewData.forEach(data => {
      if (data.error) {
        hasError = true;
      }
    });
    return !hasError;
  }, [remainBudget, viewData]);

  return {
    loading,
    viewData,
    columns,
    budgets,
    canSubmit
  };
};

async function fetchFbAdSetLifetimeSpents (
  order,
  campaignGroup,
  budgetDataList,
  goCampaignList,
  campaignGroupManager
) {
  const fbAdSetManager = new DefaultFbAdSetManager();
  try {
    const spentData = campaignGroup.groupId ? await campaignGroupManager.getAdSetLifetimeBudgetOfCampaign(campaignGroup.groupId) : {};
    const budgetDataMap = budgetDataList ? budgetDataList.reduce((acc, budget) => {
      return {
        ...acc,
        [budget.id]: budget.budget
      };
    }, {}) : {};
    const viewData = goCampaignList.map(goCampaign => {
      const spent = spentData[goCampaign.id] ? spentData[goCampaign.id] : 0;
      const startDateMoment = moment(goCampaign.start_time);
      const endDateMoment = moment(goCampaign.end_time);
      const scheduleDateCount = endDateMoment.diff(startDateMoment, 'days') + 1;
      let minBudget = fbAdSetManager.getMinBudgetOfFBObject(
        _.defaultTo(campaignGroup.currencyRate, 1),
        +_.get(goCampaign, 'bid_amount', 0),
        _.get(campaignGroup, 'fb.bid_strategy') ? _.get(campaignGroup, 'fb.bid_strategy') : _.get(goCampaign, 'bid_strategy'),
        _.get(goCampaign, 'billing_event'),
        scheduleDateCount,
        order,
        spent * 1.1
      );
      const budget = budgetDataMap[goCampaign.id] ? budgetDataMap[goCampaign.id] : minBudget;
      budgetDataMap[goCampaign.id] = budget;
      return {
        channel: CampaignGroupChannel.FB,
        ...goCampaign,
        status: _.get(goCampaign, 'configured_status'),
        spent,
        budget,
        minBudget
      };
    });
    return {
      viewData,
      budgets: budgetDataMap
    };
  } catch (e) {
    return {
      viewData: [],
      budgets: {}
    };
  }
}

async function fetchRtbCampaignLifetimeSpents (
  order,
  campaignGroup,
  budgetDataList,
  goCampaignList
) {
  const rtbCampaignManager: RtbCampaignManager = new DefaultRtbCampaignManager();
  try {
    const budgetDataMap = budgetDataList ? budgetDataList.reduce((acc, budget) => {
      return {
        ...acc,
        [budget.id]: budget.budget
      };
    }, {}) : {};
    const currencyRate = _.get(campaignGroup, 'currencyRate', 1);
    const viewData = goCampaignList.map(goCampaign => {
      const spent = Math.max(
        _.defaultTo(goCampaign.olapActualSpent, 0),
        _.defaultTo(goCampaign.olapExpectSpent, 0)
      ) * currencyRate;
      const minBudgetOfCampaign = rtbCampaignManager.getMinBudgetOfCampaign(goCampaign, order.campaignConstraint.budgetMinimum);
      let minBudget = getPriceValue(campaignGroup.currency, Math.max(minBudgetOfCampaign, spent));
      const budget = budgetDataMap[goCampaign.id] ? budgetDataMap[goCampaign.id] : minBudget;
      budgetDataMap[goCampaign.id] = budget;
      return {
        channel: CampaignGroupChannel.RTB,
        ...goCampaign,
        status: _.get(goCampaign, 'state'),
        spent,
        budget,
        minBudget
      };
    });
    return {
      viewData,
      budgets: budgetDataMap
    };
  } catch (e) {
    return {
      viewData: [],
      budgets: {}
    };
  }
}

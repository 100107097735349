import { SelectOptions } from 'components/common/commonType';
import { AgencyManager, DefaultAgencyManager } from 'core';
import { AdRequestSourceManager, DefaultAdRequestSourceManager } from 'core/adRequestSource/AdRequestSourceManager';
import { GroupType, ProductFormValue } from 'core/limitation/ProductGroup';
import { DefaultProductGroupManager, ProductGroupManager } from 'core/limitation/ProductGroupManager';
import { useEffect, useState } from 'react';
import { createSelectOptionsFromEnum } from 'utils/SelectOptionsUtils';
import _ from 'lodash';
import { validateEmpty } from 'utils/ValidateUtils';
import i18n from 'i18n';
import { useCallAPI } from 'hooks/useCallAPI';

const defaultProductGroupManager: ProductGroupManager = new DefaultProductGroupManager();
const defaultAdRequestSourceManager: AdRequestSourceManager = new DefaultAdRequestSourceManager();
const defaultAgencyManager: AgencyManager = new DefaultAgencyManager();

const productGroupTypeOptions = createSelectOptionsFromEnum(GroupType, 'productGroupForm.labels.');

const useFetchProductGroupOptions = (
  adRequestSourceManager: AdRequestSourceManager = defaultAdRequestSourceManager,
  agencyManager: AgencyManager = defaultAgencyManager
) => {

  const [options, setOptions] = useState<{
    spaceOptions: SelectOptions[],
    agencyOptions: SelectOptions[],
    domainOptions: SelectOptions[]
  }>({
    spaceOptions: [],
    agencyOptions: [],
    domainOptions: []
  });
  const { loading, callAPIs } = useCallAPI();

  useEffect(() => {
    callAPIs([
      adRequestSourceManager.getSSPSpaces.bind(adRequestSourceManager),
      adRequestSourceManager.getDomains.bind(adRequestSourceManager),
      agencyManager.getAgenciesOptions.bind(agencyManager)
    ], (spaceData, domainData, agencyOptions) => {
      setOptions({
        spaceOptions: spaceData.map(spaceData => ({
          label: spaceData.label,
          value: spaceData.value,
          options: spaceData.options ? spaceData.options.map(data => ({ label: data.label, value: data.value })) : undefined
        })),
        agencyOptions,
        domainOptions: domainData.map(data => ({ label: data.label, value: data.value }))
      });
    });
  }, [agencyManager, adRequestSourceManager, callAPIs]);

  return {
    loading,
    options
  };
};

const toServerStructure = (productGroup: ProductFormValue) => ({
  ...productGroup,
  groupValues: productGroup.groupValues ?
    productGroup.groupValues.map(groupValue => ({ limitationName: groupValue.label, limitationValue: groupValue.value })) :
    []
});

const validate = (value: ProductFormValue) => {
  return _.omitBy({
    groupName: validateEmpty(value.groupName)
  }, _.isEmpty);
};

const useCustomModal = () => {

  const [modalData, setModalData] = useState<any>(undefined);

  const showCustomModal = (groupType: GroupType) => setModalData({
    title: i18n.t(`productGroupForm.labels.${groupType === GroupType.ADSPACE ? 'customSpace' : 'customDomain'}`),
    value: {
      [GroupType.ADSPACE]: {
        id: '',
        name: ''
      },
      [GroupType.DOMAIN]: []
    }
  });

  const hideCustomModal = () => setModalData(undefined);

  const validateModalData = (groupType: GroupType, values) => {
    if (groupType === GroupType.DOMAIN) {
      return;
    }
    return _.omitBy({
      [GroupType.ADSPACE]: _.omitBy({
        id: validateEmpty(values[groupType].id),
        name: validateEmpty(values[groupType].name)
      }, _.isEmpty)
    }, _.isEmpty);
  };

  return {
    modalData,
    showCustomModal,
    hideCustomModal,
    validateModalData
  };
};

export type ProductGroupFormData = {
  title: string;
  loading: boolean;
  initProductGroup?: ProductFormValue;
  productGroupTypeOptions: SelectOptions[];
  spaceOptions: SelectOptions[];
  domainOptions: SelectOptions[];
  agencyOptions: SelectOptions[];
  redirectPath?: string;
  modalData: any,
  showCustomModal: (groupType: GroupType) => void,
  hideCustomModal: () => void,
  validateModalData: (groupType: GroupType, value) => any,
  validate: (values: ProductFormValue) => any;
  save: (values: ProductFormValue) => Promise<void>;
};

export const useCreateProductGroupFormModel = (
  productGroupManager: ProductGroupManager = defaultProductGroupManager
): ProductGroupFormData => {

  const initProductGroup = {
    groupName: '',
    groupType: GroupType.ADSPACE,
    description: '',
    groupValues: [],
    agencyIds: [11, 19, 66]
  };

  const [redirectPath, setRedirectPath] = useState<string | undefined>(undefined);
  const { loading: loadingForOptions, options } = useFetchProductGroupOptions();
  const customModalData = useCustomModal();
  const { loading, callAPIs } = useCallAPI();

  const save = async (saveValue) => {
    callAPIs([
      productGroupManager.createProductGroup.bind(productGroupManager, toServerStructure(saveValue))
    ], () => {
      setRedirectPath('/system/product-groups');
    });
  };

  return {
    title: i18n.t('productGroupForm.labels.createTitle'),
    redirectPath,
    loading: loading || loadingForOptions,
    initProductGroup,
    productGroupTypeOptions,
    ...options,
    ...customModalData,
    validate,
    save
  };
};

export const useEditProductGroupFormModel = (
  productGroupId: number,
  productGroupManager: ProductGroupManager = defaultProductGroupManager
): ProductGroupFormData => {

  const [redirectPath, setRedirectPath] = useState<string | undefined>(undefined);
  const [initProductGroup, setInitProductGroup] = useState<ProductFormValue | undefined>(undefined);
  const { loading: loadingForOptions, options } = useFetchProductGroupOptions();
  const customModalData = useCustomModal();
  const { loading, callAPIs } = useCallAPI();

  useEffect(() => {
    callAPIs([
      productGroupManager.getProductGroupById.bind(productGroupManager, productGroupId)
    ], productGroup => {
      setInitProductGroup({
        ...productGroup,
        agencyIds: _.get(productGroup, 'agencyIds', []),
        groupValues: _.get(productGroup, 'groupValues', []).map(groupValue => ({ label: groupValue.limitationName, value: groupValue.limitationValue }))
      });
    });
  }, [productGroupId, productGroupManager, callAPIs]);

  const save = async (saveValue) => {
    callAPIs([
      productGroupManager.updateProductGroup.bind(productGroupManager, toServerStructure(saveValue))
    ], () => {
      setRedirectPath('/system/product-groups');
    });
  };

  return {
    title: i18n.t('productGroupForm.labels.editTitle'),
    redirectPath,
    loading: loading || loadingForOptions,
    initProductGroup,
    productGroupTypeOptions,
    ...customModalData,
    ...options,
    validate,
    save
  };
};

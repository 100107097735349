import client from './RestClient';
import fileDownload from 'js-file-download';
import { Order } from 'core/order/Order';
import { ReportDimension, ReportGran, ReportData, ReportType, ReportCompareDimension, ReportCompareData } from 'core/report/ReportData';
import { FinalReportResponseStatus } from 'core/finalReport/FinalReportStatus';
import _ from 'lodash';
import moment from 'moment';

export const defaultFormat = 'YYYY-MM-DD';

export interface ReportWebService {
  downloadReportV2 (order: {
    name: string,
    orderNumber: string
  }, startDate: string, endDate: string, groupBy: string, selectFields: string[], isCustom: boolean): Promise<void>;
  downloadReport (order: Order, startDate: string, endDate: string, reportType?: string): Promise<void>;
  downloadVideoReport (order: Order, startDate: string, endDate: string): Promise<void>;
  downloadOutdoorReport (order: Order, startDate: string, endDate: string): Promise<void>;
  downloadClosingReport (order: Order, reportType?: string): Promise<void>;
  downloadRebateReport (date: string, agencyId?: string | number): Promise<void>;
  getReportData (type: ReportType, dimension: ReportDimension, gran: ReportGran, filter: any, from: string, to: string): Promise<ReportData>;
  getReportCompareData (compareDimension: ReportCompareDimension, filter: any, from: string, to: string): Promise<ReportCompareData>;
  uploadFinalReportData (orderId: string | number): Promise<FinalReportResponseStatus>;
  getFinalReportData (reportId: string | number): Promise<FinalReportResponseStatus>;
}

export class RestfulReportWebService implements ReportWebService {
  restClient: any;
  defualtFileDownload: any;

  constructor (restClient: any = client, defualtFileDownload: any = fileDownload) {
    this.restClient = restClient;
    this.defualtFileDownload = defualtFileDownload;
  }

  async downloadReportV2 (order: {
    name: string,
    orderNumber: string
  }, startDate: string, endDate: string, groupBy: string, selectFields: string[], isCustom: boolean): Promise<void> {
    const url = `/v2/report/export/order?orderNum=${order.orderNumber}&groupBy=${groupBy}&metrics=${selectFields.join(',')}&from=${startDate}&to=${endDate}&isCustom=${isCustom}`;
    const response = await this.restClient.get(url, { responseType: 'blob' });
    this.defualtFileDownload(response.data, `${order.name}.xlsx`);
  }

  async downloadReport (order: Order, startDate: string, endDate: string, reportType?: string): Promise<void> {
    let url = `report/export/daily/order?orderNum=${order.orderNumber}&from=${startDate}&to=${endDate}`;
    if (reportType) {
      url = url + `&reportType=${reportType}`;
    }
    const response = await this.restClient.get(url, { responseType: 'blob' });
    this.defualtFileDownload(response.data, `${order.projectName}.xlsx`);
  }

  async downloadVideoReport (order: Order, startDate: string, endDate: string): Promise<void> {
    const response = await this.restClient.get(`report/video/export?orderNum=${order.orderNumber}&from=${startDate}&to=${endDate}`, { responseType: 'blob' });
    this.defualtFileDownload(response.data, `${order.projectName}.xlsx`);
  }

  async downloadOutdoorReport (order: Order, startDate: string, endDate: string): Promise<void> {
    const response = await this.restClient.get(`report/outdoor/export?orderNum=${order.orderNumber}&from=${startDate}&to=${endDate}`, { responseType: 'blob' });
    this.defualtFileDownload(response.data, `${order.projectName}.xlsx`);
  }

  async downloadClosingReport (order: Order, reportType?: string): Promise<void> {
    let url = `report/export/order/closing_report?orderNum=${order.orderNumber}`;
    if (reportType) {
      url = url + `&reportType=${reportType}`;
    }
    const response = await this.restClient.get(url, { responseType: 'blob' });
    this.defualtFileDownload(response.data, `${order.projectName}.xlsx`);
  }

  async getReportData (type: ReportType, dimension: ReportDimension, gran: ReportGran, filter: any, from: string, to: string) {
    const filterQueryParams = Object.keys(filter).map(key => `${key}=${filter[key]}`).join('&');
    const reportType = type === ReportType.PERFORMANCE ? 'go-performance' : type;
    const url = `report/${reportType}?dimension=${dimension}&gran=${gran}&from=${encodeURIComponent(from)}&to=${encodeURIComponent(to)}&${filterQueryParams}`;
    const response = await this.restClient.get(url);
    return wrapReportData(response.data);
  }

  async getReportCompareData (compareDimension: ReportCompareDimension, filter: any, from: string, to: string): Promise<ReportCompareData> {
    const filterQueryParams = Object.keys(filter).map(key => `${key}=${filter[key]}`).join('&');
    const url = `report/compare-report?dimension=${ReportDimension.DAY}&gran=${ReportGran.DAY}&from=${encodeURIComponent(from)}&to=${encodeURIComponent(to)}&compareDimension=${compareDimension}&${filterQueryParams}`;
    const response = await this.restClient.get(url);
    return wrapReportCompareData(response.data);
  }

  async downloadRebateReport (date: string, agencyId?: string | number): Promise<void> {
    const selectedDate = moment(date);
    let url = `report/export/month/reconciliation?month=${selectedDate.month() + 1}&year=${selectedDate.year()}`;
    if (agencyId) {
      url += `&adAgencyId=${agencyId}`;
    }
    const response = await this.restClient.get(url, { responseType: 'blob' });
    this.defualtFileDownload(response.data, 'MonthReport.xlsx');
  }

  async uploadFinalReportData (orderId: string | number): Promise<FinalReportResponseStatus> {
    const response = await this.restClient.post('/v2/report/final-report', { orderId });
    return response.data;
  }

  async getFinalReportData (reportId: string | number): Promise<FinalReportResponseStatus> {
    const response = await this.restClient.get(`/v2/report/final-report?id=${reportId}`);
    return response.data;
  }
}

const wrapReportData = (json: any) => {
  return {
    allowMetrics: _.get(json, 'allowMetrics', []),
    dimension: _.get(json, 'dimension'),
    filter: _.get(json, 'filter', {}),
    from: _.get(json, 'from'),
    to: _.get(json, 'to'),
    records: _.get(json, 'records', []).map(record => wrapRecord(record)),
    currency: _.get(json, 'currency')
  };
};

const wrapReportCompareData = (json: any) => {
  return {
    allowMetrics: _.get(json, 'allowMetrics', []),
    dimension: _.get(json, 'dimension'),
    filter: _.get(json, 'filter', {}),
    from: _.get(json, 'from'),
    to: _.get(json, 'to'),
    records: _.get(json, 'records', []).map(record => wrapCompareRecord(record)),
    currency: _.get(json, 'currency')
  };
};

const wrapRecord = (json: any) => {
  return {
    ...json,
    dimensionID: _.get(json, 'dimensionID'),
    dimensionName: _.get(json, 'dimensionName'),
    agcProfit: _.get(json, 'agcProfit'),
    clicks: _.get(json, 'clicks'),
    complete: _.get(json, 'complete'),
    conv_1: _.get(json, 'conv_1'),
    conv_7: _.get(json, 'conv_7'),
    convs: _.get(json, 'convs'),
    dataCost: _.get(json, 'dataCost'),
    firstQuartile: _.get(json, 'firstQuartile'),
    midpoint: _.get(json, 'midpoint'),
    thirdQuartile: _.get(json, 'thirdQuartile'),
    impres: _.get(json, 'impres'),
    incentive: _.get(json, 'incentive'),
    mediaCost: _.get(json, 'mediaCost'),
    mediaSpent: _.get(json, 'mediaSpent'),
    skipCount: _.get(json, 'skipCount'),
    spent: _.get(json, 'spent'),
    sysProfit: _.get(json, 'sysProfit'),
    underBidFloor: _.get(json, 'underBidFloor'),
    view: _.get(json, 'view'),
    viewable: _.get(json, 'viewable'),
    winTotal: _.get(json, 'winTotal'),
    tags: _.compact(_.get(json, 'tags', '').split(',')),
    parentId: _.get(json, 'parentId')
  };
};

const wrapCompareRecord = (json: any) => {
  const campareID = _.get(json, 'compareID');
  const compareName = _.get(json, 'compareName');
  return {
    ...wrapRecord(json),
    compareID: campareID ? campareID : compareName,
    compareName
  };
};

import _ from 'lodash';
import { ReportDataProvider } from './ReportDataProvider';
import { tableColumnSettings } from './reportTableColumnSettings';
import { Metrics, ReportDimension, ReportRecord } from 'core/report/ReportData';
import { divideValue } from './ReportDataHelper';
import { hasRoles } from 'core/permission/PermissionDSL';
import { RoleNames, Actor } from 'core';
import { Project } from 'core/project/Project';

enum COLUMNS {
  NAME = 'name',
  IMPRES = 'impres',
  CLICKS = 'clicks',
  CTR = 'ctr',
  VIEWABLE = 'viewable',
  VCTR = 'vctr',
  CPM = 'cpm',
  CPC = 'cpc',
  CPA = 'cpa',
  CONVS = 'convs',
  CONV_1 = 'conv_1',
  CONV_7 = 'conv_7',
  CVR = 'cvr',
  MEDIA_COST = 'mediaCost',
  MEDIA_SPENT = 'mediaSpent',
  AGENCY_PROFIT = 'agcProfit',
  SYS_PROFIT = 'sysProfit',
  DATA_COST = 'dataCost',
  SPENT = 'spent',
  TAGS = 'tags',
  UU = 'uu',
  EDITBTNS = 'editBtns'
}

abstract class GeneralReportDataProvider implements ReportDataProvider {

  onDimensionNameClick: any;
  onDateClick: any;
  onEditClick: any;
  constructor (onDimensionNameClick, onDateClick, onEditClick) {
    this.onDimensionNameClick = onDimensionNameClick;
    this.onDateClick = onDateClick;
    this.onEditClick = onEditClick;
  }

  abstract get avalableDimensions ()

  getReportDimensions (actor: Actor | null): Array<ReportDimension> {
    const isSysAdmin = hasRoles(RoleNames.sysAdmin).visible({ actor });
    const isAdvertiser = hasRoles(RoleNames.adsAdmin, RoleNames.adsReport, RoleNames.adsSales).visible({ actor });
    const forbidDimensions = isSysAdmin ?
    [ReportDimension.MONTH, ReportDimension.HOUR, ReportDimension.DEVICE_TYPE] :
    [ReportDimension.MONTH, ReportDimension.HOUR, ReportDimension.DEVICE_TYPE, ReportDimension.AGENCY];
    isAdvertiser && forbidDimensions.push(ReportDimension.ADVERTISER);
    return this.avalableDimensions.filter(dimension => forbidDimensions.indexOf(dimension) === -1);
  }

  getReportTableColumnSettings (allowMetrics: Array<Metrics>, tableData: any, dimension: ReportDimension, currency: string) {
    const allColumnSettings = tableColumnSettings(tableData, dimension, currency, this.onDimensionNameClick, this.onDateClick);
    const tableAllowMetrics = [
      ...allowMetrics,
      COLUMNS.NAME,
      COLUMNS.EDITBTNS
    ];
    if (allowMetrics.indexOf(Metrics.SPENT) !== -1) {
      allowMetrics.indexOf(Metrics.IMPRES) !== -1 && tableAllowMetrics.push(COLUMNS.CPM);
      allowMetrics.indexOf(Metrics.CLICKS) !== -1 && tableAllowMetrics.push(COLUMNS.CPC);
      allowMetrics.indexOf(Metrics.CONVS) !== -1 && tableAllowMetrics.push(COLUMNS.CPA);
    }
    if (allowMetrics.indexOf(Metrics.CLICKS) !== -1) {
      allowMetrics.indexOf(Metrics.IMPRES) !== -1 && tableAllowMetrics.push(COLUMNS.CTR);
      allowMetrics.indexOf(Metrics.VIEWABLE) !== -1 && tableAllowMetrics.push(COLUMNS.VCTR);
      allowMetrics.indexOf(Metrics.CONVS) !== -1 && tableAllowMetrics.push(COLUMNS.CVR);
    }
    dimension === ReportDimension.CAMPAIGN && tableAllowMetrics.push(COLUMNS.TAGS);
    return _.compact(
      Object.values(COLUMNS).map(column => {
        return tableAllowMetrics.indexOf(column) !== -1 &&
          allColumnSettings[column];
      })
    );
  }

  getReportTableData (records: Array<ReportRecord>) {
    return records.map(record => {
      return {
        key: record.dimensionName + _.get(record, 'dimensionID', 0),
        id: _.get(record, 'dimensionID', 0).toString(),
        tags: record.tags,
        parentId: record.parentId,
        extra: record.extra,
        [COLUMNS.NAME]: record.dimensionName,
        [COLUMNS.IMPRES]: record.impres,
        [COLUMNS.CLICKS]: record.clicks,
        [COLUMNS.CTR]: divideValue(_.get(record, 'clicks', 0) * 100, _.get(record, 'impres', 0)),
        [COLUMNS.VIEWABLE]: record.viewable,
        [COLUMNS.VCTR]: divideValue(_.get(record, 'clicks', 0) * 100, _.get(record, 'viewable', 0)),
        [COLUMNS.CPM]: divideValue(_.get(record, 'spent', 0) * 1000, _.get(record, 'impres', 0)),
        [COLUMNS.CPC]: divideValue(_.get(record, 'spent', 0), _.get(record, 'clicks', 0)),
        [COLUMNS.CPA]: divideValue(_.get(record, 'spent', 0), _.get(record, 'convs', 0)),
        [COLUMNS.CONVS]: record.convs,
        [COLUMNS.CONV_1]: record.conv_1,
        [COLUMNS.CONV_7]: record.conv_7,
        [COLUMNS.CVR]: divideValue(_.get(record, 'convs', 0) * 100, _.get(record, 'clicks', 0)),
        [COLUMNS.MEDIA_COST]: _.get(record, 'mediaCost', 0),
        [COLUMNS.MEDIA_SPENT]: _.get(record, 'mediaSpent', 0),
        [COLUMNS.AGENCY_PROFIT]: _.get(record, 'agcProfit', 0),
        [COLUMNS.SYS_PROFIT]: _.get(record, 'sysProfit', 0),
        [COLUMNS.DATA_COST]: _.get(record, 'dataCost', 0),
        [COLUMNS.SPENT]: _.get(record, 'spent', 0),
        [COLUMNS.UU]: record.uu ? record.uu : 0
      };
    });
  }
}

class RetailGeneralReportDataProvider extends GeneralReportDataProvider {

  get avalableDimensions () {
    return [
      ReportDimension.DAY,
      ReportDimension.AGENCY,
      ReportDimension.ADVERTISER,
      ReportDimension.ORDER,
      ReportDimension.GO_CAMPAIGN_GROUP,
      ReportDimension.GO_CAMPAIGN,
      ReportDimension.SALES_CHANNEL,
      ReportDimension.BINDING,
      ReportDimension.RETAIL
    ];
  }
}

class GoganGeneralReportDataProvider extends GeneralReportDataProvider {

  get avalableDimensions () {
    return [
      ReportDimension.DAY,
      ReportDimension.AGENCY,
      ReportDimension.ADVERTISER,
      ReportDimension.ORDER,
      ReportDimension.GO_CAMPAIGN_GROUP,
      ReportDimension.GO_CAMPAIGN,
      ReportDimension.SALES_CHANNEL,
      ReportDimension.BINDING
    ];
  }
}

export default process.env.REACT_APP_PROJECT === Project.RETAIL ?
  RetailGeneralReportDataProvider :
  GoganGeneralReportDataProvider;

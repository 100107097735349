import React, { Fragment } from 'react';
import { OrderFormProps } from './OrderFormModel';
import { Field, FormikProps } from 'formik';
import FormGroup from 'components/common/FormGroup/FormGroup';
import { FormikInput, FormikFormGroup, FormikPrefixInputGroup, FormikLabel, FormikSwitch } from 'components/common';
import FormikDateRangePicker from 'components/common/formik/FormikDateRangePicker';
import i18n from 'i18next';
import styles from './orderForm.module.scss';
import PostfixInputGroup from 'components/common/PostfixInputGroup/PostfixInputGroup';
import { Button } from 'react-bootstrap';
import moment from 'moment';
import { withFormikOnChange, withTip } from 'hoc';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { addOnEnabled, notRoles } from 'core/permission/PermissionDSL';
import { RoleNames } from 'core';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { Order } from 'core/order/Order';

const withTipFormikInput = withTip(FormikInput);
const FormikOnChangePostfixInput = withFormikOnChange(PostfixInputGroup);

export class EditOrderFormContent extends React.Component<OrderFormProps & { formik: FormikProps<Order> }, any> {

  originBudget: number;
  originMargin: number;

  constructor (props) {
    super(props);
    if (this.props.model.formikProps) {
      this.originBudget = this.props.model.formikProps.values.budget;
      this.originMargin = this.props.model.formikProps.values.orderMargin;
    } else {
      this.originBudget = 0;
      this.originMargin = 0;
    }
  }

  componentDidMount () {
    this.props.model.setNeedCheckModifyReason(this.getShowModifyReasonValue());
  }

  componentDidUpdate () {
    this.props.model.setNeedCheckModifyReason(this.getShowModifyReasonValue());
  }

  onDateRangeChanged = (_1, endDate) => {
    this.props.formik.setFieldValue('finalReportSendOutDate', moment(endDate).startOf('day').add(2, 'days').format('YYYY-MM-DD'));
  }

  onEditBudgetClick = () => {
    const model = this.props.model;
    if (model.state.showBudgetInputField && model.formikProps) {
      model.formikProps.setFieldValue('budget', this.originBudget);
    }
    model.triggerBudgetInputField && model.triggerBudgetInputField();
  }

  onEditMarginClick = () => {
    const model = this.props.model;
    if (model.state.showMarginInputField && model.formikProps) {
      model.formikProps.setFieldValue('orderMargin', this.originMargin);
    }
    model.triggerMarginInputField && model.triggerMarginInputField();
  }

  getShowModifyReasonValue = () => {
    const model = this.props.model;
    const order = model.formikProps && model.formikProps.values;
    if (order) {
      return order.modifyReason !== undefined ||
        model.state.showBudgetInputField ||
        model.state.showMarginInputField;
    }
    return false;
  }

  renderBudgetField = () => {
    const model = this.props.model;
    const currency = model.localeMeta ? model.localeMeta.currency : 'NTD';
    if (model.showEditButton) {
      return (
        <FormGroup className={styles.budgetWithButton} label={i18n.t('orderForm.labels.budget')} controlId='budget'>
          {model.state.showBudgetInputField ?
            <Fragment>
              <FormikPrefixInputGroup currency={currency} name='budget'>
                <Field
                  name='budget'
                  className={styles.orderBudgetWithButton}
                  component={FormikInput}
                  type='number'
                />
              </FormikPrefixInputGroup>
              <Button variant='secondary' size='sm' onClick={this.onEditBudgetClick}>
                {i18n.t('orderForm.labels.modifyCancel')}
              </Button>
              {model.budgetTip}
            </Fragment> :
            <Fragment>
              <div className={styles.budgetLabel}>
                <span>{currency}</span>
                <Field
                  name='budget'
                  component={FormikLabel}
                />
              </div>
              <Button variant='secondary' size='sm' onClick={this.onEditBudgetClick}>
                {i18n.t('orderForm.labels.modifyBudget')}
              </Button>
            </Fragment>
          }
        </FormGroup>
      );
    }

    return (
      <FormGroup className={styles.budget} label={i18n.t('orderForm.labels.budget')} controlId='budget'>
        <FormikPrefixInputGroup currency={currency} name='budget'>
          <Field
            name='budget'
            component={withTipFormikInput}
            type='number'
            hocContent={model.budgetTip}
          />
        </FormikPrefixInputGroup>
      </FormGroup>
    );
  }

  renderPostfixInput = (props) => {
    return <FormikOnChangePostfixInput {...props} postfix={'%'} type='number' />;
  }

  renderMarginField = () => {
    const model = this.props.model;
    const marginLabelField = (
      <div className={styles.marginLabel}>
        <Field
          name='orderMargin'
          component={FormikLabel}
        />
        <span>%</span>
      </div>
    );
    if (model.isOrderHasApproved) {
      return (
        <FormGroup className={styles.marginWithButton} label={i18n.t('orderForm.labels.orderMargin')} controlId='orderMargin'>
          {marginLabelField}
        </FormGroup>
      );
    }

    if (model.showEditButton) {
      return (
        <FormGroup className={styles.marginWithButton} label={i18n.t('orderForm.labels.orderMargin')} controlId='orderMargin'>
          {model.state.showMarginInputField ?
            <Fragment>
              <Field
                name='orderMargin'
                className={styles.orderMarginWithButton}
                render={this.renderPostfixInput}
              />
              <Button variant='secondary' size='sm' onClick={this.onEditMarginClick}>
                {i18n.t('orderForm.labels.modifyCancel')}
              </Button>
            </Fragment> :
            <Fragment>
              {marginLabelField}
              <Button variant='secondary' size='sm' onClick={this.onEditMarginClick}>
                {i18n.t('orderForm.labels.modifyMargin')}
              </Button>
            </Fragment>
          }
        </FormGroup>
      );
    }

    return (
      <FormGroup className={styles.margin} label={i18n.t('orderForm.labels.orderMargin')} controlId='orderMargin'>
        <Field
          name='orderMargin'
          className={styles.orderMargin}
          render={this.renderPostfixInput}
        />
      </FormGroup>
    );
  }

  render () {
    const order = this.props.model.formikProps && this.props.model.formikProps.values;
    if (!order) {
      return <div />;
    }

    return (
      <div className={styles.editOrderForm}>
        <PermissionChecker
          permissionAware={notRoles(RoleNames.adsAdmin, RoleNames.adsSales, RoleNames.adsReport)}
        >
          <FormikFormGroup
            labelName={i18n.t('orderForm.labels.advertiser')}
            inputName='advertiserName'
            inputAs={'label'}
          />
        </PermissionChecker>
        <FormikFormGroup
          labelName={i18n.t('orderForm.labels.name')}
          inputName='projectName'
        />
        {/* <FormikFormGroup
          labelName={i18n.t('orderForm.labels.externalId')}
          inputName='externalId'
        />
        {this.props.model.showOrderExternalTypes &&
          <FormGroup label={i18n.t('orderForm.labels.externalType')} controlId='orderExternalType'>
            <Field
              name='externalType'
              component={FormikSelect}
              options={this.props.model.orderExternalTypes}
              className={styles.externalType}
              simpleValue
              isClearable
            />
          </FormGroup>
        } */}
        <FormGroup label={i18n.t('orderForm.labels.dateRange')} controlId='orderDateRange'>
          <Fragment>
            <Field
              className={styles.tips}
              component={FormikDateRangePicker}
              minDate={this.props.model.dateRangeMinDate}
              maxDate={moment(this.props.model.dateRangeMinDate).add(10, 'years').format('YYYY-MM-DD')}
              startDateFormikName='startDate'
              endDateFormikName='endDate'
              name='dateRange'
              format={'YYYY-MM-DD'}
              showTimePicker={false}
              startDatePickerDisabled={this.props.model.isOrderStarted}
              onChange={this.onDateRangeChanged}
            />
            <span className={styles.hoverTip}>{i18n.t('orderForm.labels.dateCannotModifyHint')}</span>
            <span className={styles.hint}>{i18n.t('orderForm.labels.totaldates', { num: this.props.model.totalDays })}</span>
          </Fragment>
        </FormGroup>
        {this.renderBudgetField()}
        {this.props.model.canEditMargin &&
          <PermissionChecker
            permissionAware={
              notRoles(RoleNames.adsAdmin, RoleNames.adsReport, RoleNames.adsSales)
                .and(addOnEnabled(ADDONFEATURE.CAMPAIGN.ORDER_AGENCY_PROFIT_SETTING))
            }
          >
            {this.renderMarginField()}
          </PermissionChecker>
        }
        { this.getShowModifyReasonValue() &&
          <FormikFormGroup
            labelName={i18n.t('orderForm.labels.modifyReason')}
            inputName='modifyReason'
            inputAs='textarea'
          />
        }
        <FormikFormGroup
          labelName={i18n.t('orderForm.labels.remark')}
          inputName='comments'
          inputAs='textarea'
        />
        {this.props.model.canEditMonitor &&
          <FormGroup
            controlId='monitor'
            label={i18n.t('orderForm.labels.monitor')}
            valueClassName={styles.nativeBannerSwitch}
          >
            <Field
              name='monitor'
              component={FormikSwitch}
              hocContent={i18n.t('orderForm.labels.monitor')}
            />
          </FormGroup>
        }
      </div>
    );
  }
}

import React from 'react';
import i18n from 'i18next';
import { ChooseAdTypeStepProps, ChooseAdTypeStepState } from './ChooseAdTypeStepModel';
import { CampaignAdTypeButton } from 'components/CampaignAdTypeButton/CampaignAdTypeButton';
import styles from './chooseAdTypeStep.module.scss';
import { Modal } from 'components/common/Modal/Modal';

export class ChooseAdTypeStep extends React.Component<ChooseAdTypeStepProps, ChooseAdTypeStepState> {

  handler?: number;
  componentDidMount () {
    this.handler = this.props.model.event.add((model) => {
      this.setState(model.state);
    });
  }

  componentDidUpdate (prevProps: ChooseAdTypeStepProps) {
    if (prevProps.model !== this.props.model) {
      this.handler && prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add((model) => {
        this.setState(model.state);
      });
    }
  }

  componentWillUnmount () {
    this.handler && this.props.model.event.remove(this.handler);
  }

  renderButtons () {
    return this.props.model.getAdTypeButtonModels().map((model, index) => {
      return <CampaignAdTypeButton key={index} model={model}/>;
    });
  }

  render () {
    const modalData = this.props.model.state.changeAdTypeAlertModal;
    return (
      <div className={styles.chooseAdTypeStep}>
        <div className={styles.title}>
          {i18n.t('campaign.labels.adTypePageTitle')}
        </div>
        <div className={styles.buttonArea}>
          <div className={styles.buttons}>
            {this.renderButtons()}
          </div>
        </div>
        {
          modalData &&
          <Modal
            title={i18n.t(modalData.title)}
            primaryButton={modalData.primaryBtn && {
              title: modalData.primaryBtn.title,
              callback: modalData.primaryBtn.callback
            }}
            secondaryButton={modalData.secondaryBtn && {
              title: modalData.secondaryBtn.title,
              callback: modalData.secondaryBtn.callback
            }}
          >
            {modalData.message}
          </Modal>
        }
      </div>
    );
  }
}

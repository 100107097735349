import { CloudStorageManager, DefaultCloudStorageManager } from 'core/cloudStorage/CloudStorageManager';
import DefaultCreativeManager from 'core/creative/CreativeManager';
import { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { useCallAPI } from 'hooks/useCallAPI';

const defaultCloudStorageManager: CloudStorageManager = new DefaultCloudStorageManager();

export const useOneForAllDisplayPreview = (
  advertiserId: number | string,
  typeProperties: any,
  medias: any,
  cloudStorageManager = defaultCloudStorageManager
): {
  previewData: { [key: string]: string | undefined }
} => {
  const fbPreviewTypes = useMemo(() => [
    'VALUE_MOBILE_FEED_STANDARD',
    'VALUE_MOBILE_INTERSTITIAL',
    'VALUE_INSTAGRAM_STORY',
    'VALUE_INSTAGRAM_STANDARD'
  ], []);
  const initPreviewData = fbPreviewTypes.reduce<{ [key: string]: string | undefined }>((acc, type) => {
    acc[type] = undefined;
    return acc;
  }, {});
  const [previewData, setPreviewData] = useState(initPreviewData);
  const { callAPIs } = useCallAPI();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const squareImgData = _.get(medias, 'squareImg', {});
        if (squareImgData.file && !squareImgData.url) {
          squareImgData.url = await cloudStorageManager.uploadFileToCloud(squareImgData.file);
        }
        const storyImgData = _.get(medias, 'storyImg', {});
        if (storyImgData.file && !storyImgData.url) {
          storyImgData.url = await cloudStorageManager.uploadFileToCloud(storyImgData.file);
        }
        const formData = {
          type: 'DISPLAY',
          advertiserId: advertiserId.toString(),
          imageUrl: squareImgData.url,
          storyImgUrl: storyImgData.url
        };
        Object.keys(typeProperties).forEach(key => {
          if (typeProperties[key] !== null && key !== 'deepLink') {
            formData[`${key}`] = typeProperties[key];
          }
        });
        const creativeManager = new DefaultCreativeManager();
        callAPIs([creativeManager.getFbCreativePreview.bind(creativeManager, formData)], (previewIframes) => {
          let result = {};
          fbPreviewTypes.forEach(type => {
            result[type] = previewIframes[type];
          });
          setPreviewData(result);
        });
      } catch (e) {}
    };
    fetchData();
  }, [advertiserId, typeProperties, fbPreviewTypes, medias, cloudStorageManager, callAPIs]);

  return {
    previewData
  };
};

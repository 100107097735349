import React, { ReactElement } from 'react';
import { FormikField } from 'components/common/formik/FormikField';
import i18n from 'i18n';
import styles from '../campaignGroupForm.module.scss';
import { createSelectOptionsFromEnum } from 'utils/SelectOptionsUtils';
import { FBBidStrategy, FbObjective } from 'core/campaignGroup/CampaignGroup';
import { SelectOptions } from 'components/common/commonType';
import { AdAccountOption } from 'components/AdAccountSelect/AdAccountOption';
import { AdAccountSingleValue } from 'components/AdAccountSelect/AdAccountSingleValue';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import FormGroup from 'components/common/FormGroup/FormGroup';
import { FbAdSet } from 'core/fbAdSet/FbAdSet';
import { formatPriceWithCurrency } from 'helper/CurrencyHelper';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
const objectiveOptions = [
  {
    label: i18n.t('campaignGroup.labels.fb.objectiveGroup.awareness'),
    value: 0,
    options: [{
      label: i18n.t('campaignGroup.labels.fb.objective.outcome_awareness'),
      value: FbObjective.OUTCOME_AWARENESS
    }]
  }, {
    label: i18n.t('campaignGroup.labels.fb.objectiveGroup.consideration'),
    value: 1,
    options: [{
      label: i18n.t('campaignGroup.labels.fb.objective.outcome_traffic'),
      value: FbObjective.OUTCOME_TRAFFIC
    }]
  }, {
    label: i18n.t('campaignGroup.labels.fb.objectiveGroup.conversion'),
    value: 2,
    options: [{
      label: i18n.t('campaignGroup.labels.fb.objective.outcome_sales'),
      value: FbObjective.OUTCOME_SALES
    }]
  }
];
const bidStrategyOptions = createSelectOptionsFromEnum(FBBidStrategy, 'campaignGroup.labels.fb.bidStrategy.');
const bidStrategyFormatter = value => i18n.t(`campaignGroup.labels.fb.bidStrategy.${value.toLowerCase()}`);
export const FBCampaignForm: React.FC<{
  formType: string,
  fbAdAccountOptions: SelectOptions[],
  isCBO: boolean,
  canEditBidStrategy: boolean,
  currency: string,
  fbAdSets: FbAdSet[],
  fbAdSetBudgets: any[],
  onEditAdSetBudgetsBtn: () => void,
  getBudgetTips: () => ReactElement | string | undefined
}> = ({
  formType,
  fbAdAccountOptions,
  isCBO,
  canEditBidStrategy,
  currency,
  fbAdSets,
  fbAdSetBudgets,
  onEditAdSetBudgetsBtn,
  getBudgetTips
}) => {

  const objectiveFormatter = value => i18n.t(`campaignGroup.labels.fb.objective.${value.toLowerCase()}`);
  const adAccountFormatter = value => {
    const option = fbAdAccountOptions.find(option => option.value.toString() === value.toString());
    return option ? `${option.label} (${option.value})` : '';
  };
  const fbCampaignBudgetDisableHint = () => {
    return i18n.t('campaignGroup.labels.campaignBudgetDisableHint');
  };
  const renderAdSetBudget = () => {
    return fbAdSets.map(fbAdSet => {
      const adSetBudget = fbAdSetBudgets.find(fbAdSetBudget => fbAdSetBudget.adset_id === fbAdSet.id);
      return (
        <div key={fbAdSet.id}>
          {`${fbAdSet.name}: ${formatPriceWithCurrency(currency, adSetBudget ? +adSetBudget.lifetime_budget : 0)}`}
        </div>
      );
    });
  };
  return (
    <>
      {
        isCBO ?
          <FormikField.InputGroup
            label={i18n.t('campaignGroup.labels.budget')}
            name='budget'
            type='number'
            prefix={currency}
            hint={getBudgetTips()}
          /> :
          <FormikField.Label
            label={i18n.t('campaignGroup.labels.budget')}
            name='budget'
            formatter={fbCampaignBudgetDisableHint}
          />
      }
      {
        fbAdSetBudgets &&
          <FormGroup controlId='adsetBudgets' label=''>
            <div className={styles.adSetBudgetContainer}>
              <div className={styles.adSetBudget}>
                {renderAdSetBudget()}
              </div>
              <IconWithTooltip
                icon={faPencilAlt}
                onClick={onEditAdSetBudgetsBtn}
                tooltipProps={{
                  id: `adsetBudgetsEditTip`,
                  tooltip: i18n.t('campaignGroup.hints.editAdsetBudgets')
                }}
              />
            </div>
          </FormGroup>
      }
      {
        formType === 'create' ?
          <FormikField.Select
            label={i18n.t('campaignGroup.labels.fb.accountId')}
            name='fb.account_id'
            simpleValue
            options={fbAdAccountOptions}
            optionComponent={AdAccountOption}
            singleValue={AdAccountSingleValue}
            className={styles.selectField}
          /> :
          <FormikField.Label
            label={i18n.t('campaignGroup.labels.fb.accountId')}
            name='fb.account_id'
            formatter={adAccountFormatter}
          />
      }
      {
        formType === 'create' ?
          <FormikField.Select
            label={i18n.t('campaignGroup.labels.fb.objectiveLabel')}
            name='fb.objective'
            simpleValue
            options={objectiveOptions}
            className={styles.selectField}
          /> :
          <FormikField.Label
            label={i18n.t('campaignGroup.labels.fb.objectiveLabel')}
            name='fb.objective'
            formatter={objectiveFormatter}
          />
      }
      {
        isCBO && (
          canEditBidStrategy ?
          <FormikField.Select
            label={i18n.t('campaignGroup.labels.fb.bidStrategyLabel')}
            name='fb.bid_strategy'
            simpleValue
            options={bidStrategyOptions}
            className={styles.selectField}
          /> :
          <FormikField.Label
            label={i18n.t('campaignGroup.labels.fb.bidStrategyLabel')}
            name='fb.bid_strategy'
            formatter={bidStrategyFormatter}
          />
        )
      }
    </>
  );
};

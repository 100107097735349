import React from 'react';
import _ from 'lodash';
import styles from './creativePreview.module.scss';

export const OneForAllVideoCreativePreview: React.FC<any> = props => {
  const storyVideoImageUrl = _.get(props.creative, 'bannerExtra.storyThumbnailUrl');
  const bannerImageUrl = storyVideoImageUrl ? storyVideoImageUrl : _.get(props.creative, 'bannerExtra.thumbnailUrl', _.get(props.creative, 'bannerImageUrl'));
  if (!bannerImageUrl) {
    return <div/>;
  }
  return (
    <div className={styles.fbVideoPreview}>
      <img src={bannerImageUrl} alt={'preview'} />
    </div>
  );
};

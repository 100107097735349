export enum SessionStorageItemKeys {
  ADVERTISER = 'advertiserId',
  AGENCY = 'agencyId'
}

export const SessionStorageHelper = {
  getItem (key: SessionStorageItemKeys) {
    return sessionStorage.getItem(key);
  },
  getNumberItem (key: SessionStorageItemKeys) {
    const value = sessionStorage.getItem(key);
    return value ? parseInt(value, 10) : undefined;
  },
  setItem (key: SessionStorageItemKeys, value: string | number) {
    sessionStorage.setItem(key, value.toString());
  },
  removeItem (key: SessionStorageItemKeys) {
    sessionStorage.removeItem(key);
  }
};

import React from 'react';
import { useHistory } from 'react-router-dom';
import styles from './fbAppReviewerPage.module.scss';
import i18n from 'i18n';
import { MainPageModel } from 'pages/MainPage/MainPageModel';
import { alertMessage as AlertMessage } from 'components/AlertDialog';
import { Button } from 'react-bootstrap';

export const FBAppReviewerPage: React.FC<{
  type: 'guest' | 'onboarding';
  imgPrefix: string;
  model: MainPageModel;
}> = ({
  type,
  imgPrefix,
  model
}) => {

  const history = useHistory();
  const information: string = i18n.t(`${type}Page.labels.information`);
  const primaryButton: {
    text: string,
    callback: () => void
  } = {
    text: i18n.t(`${type}Page.labels.primaryButton`),
    callback: async () => {
      const needRedirect = await model.getFbAdsManagement();
      needRedirect && history.replace(model.mainPagePath);
    }
  };

  return (
    <div className={styles.pageContent}>
      {model.state.alertData &&
        AlertMessage(
          i18n.t('common.warning'),
          model.state.alertData.message,
          () => {
            model.state.alertData?.callback ?
              model.state.alertData.callback() :
              model.setAlertData(undefined);
          }
        )
      }
      <img src={require(`assets/${imgPrefix}.png`)} srcSet={`${require(`assets/${imgPrefix}@2x.png`)} 2x`} alt={'logo'} />
      <div className={styles.textArea}>
        {information}
      </div>
      <Button variant='primary' size='sm' onClick={primaryButton.callback}>
        {primaryButton.text}
      </Button>
    </div>
  );
};

import React from 'react';
import { StickableBootstrapTable } from 'components/common/Table/StickableBootstrapTable';
import { useCurrencyRateListModel } from './CurrencyRateListModel';
import i18n from 'i18n';
import styles from './currencyRateList.module.scss';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { InputGroup, Nav } from 'react-bootstrap';
import { Currency } from 'core';
import _ from 'lodash';

export type CurrencyRateListProps = {};

export const CurrencyRateList: React.FC<CurrencyRateListProps> = () => {

  const {
    state,
    columns,
    loading,
    setRate,
    submitRate,
    onSwitchCurrency
  } = useCurrencyRateListModel();

  const renderCurrency = () => {
    return Object.keys(_.omit(Currency, ['USD'])).map(currency => (
      <Nav.Item key={currency}>
        <Nav.Link eventKey={currency}>{i18n.t(`currencyRateList.labels.${currency}`)}</Nav.Link>
      </Nav.Item>
    ));
  };

  return (
    <div className={styles.currencyRateList}>
      {loading && <LoadingIndicator />}
      <div className={styles.titleArea}>
        <div className={styles.title}>
          {i18n.t('currencyRateList.labels.title')}
        </div>
      </div>
      <div className={styles.filterArea}>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text>
              {i18n.t('currencyRateList.labels.oneDollarEqual')}
            </InputGroup.Text>
          </InputGroup.Prepend>
          <input type='number' value={state.rate} onChange={setRate} />
          <InputGroup.Append>
            <InputGroup.Text>
              {state.currentCurrency}
            </InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
        <button
          className={`btn btn-tertiary btn-sm ${styles.createBtn}`}
          disabled={_.isEmpty(state.rate)}
          onClick={submitRate}
        >
          {i18n.t('currencyRateList.labels.create')}
        </button>
      </div>
      <div className={styles.contentArea}>
        <Nav
          activeKey={state.currentCurrency}
          onSelect={onSwitchCurrency}
        >
          {renderCurrency()}
        </Nav>
        <StickableBootstrapTable
          keyField='ctime'
          data={state.currencyRates[state.currentCurrency]}
          columns={columns}
          noDataIndication={i18n.t('currencyRateList.labels.noData')}
          defaultSorted={[{
            dataField: 'ctime',
            order: 'desc'
          }]}
          pagination={paginationFactory({
            hideSizePerPage: true,
            sizePerPage: 10
          })}
        />
      </div>
    </div>
  );
};

import { CampaignGroup } from 'core/campaignGroup/CampaignGroup';
import { Order } from 'core/order/Order';
import React from 'react';
import { AdSetSetupFlowPageModelData, FbAdSetFormData } from './FbAdSetSetupFlowPageModel';

export type FbAdSetSetupFlowContextType = AdSetSetupFlowPageModelData & {
  order: Order,
  campaignGroup: CampaignGroup,
  adSet: FbAdSetFormData
};

export const FbAdSetSetupFlowContext = React.createContext<FbAdSetSetupFlowContextType | null>(null);

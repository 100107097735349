export type ConversionListDTO = {
  id: number,
  dmpConversionTrackingId: string,
  advertiserId: number,
  name: string,
  type: ConversionType
};

export type ConversionFormDTO = {
  id?: number,
  type: ConversionType,
  name: string,
  advertiserId?: number,
  advertiserName?: string;
};

export enum ConversionType {
  PURCHASE = 'purchase',
  CART = 'cart',
  SIGN_UP = 'sign_up',
  LEAD = 'lead',
  KEY_PAGE_VIEW = 'view_of_a_key_page',
  OTHERS = 'others'
}

import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import { AddonFeatureManager, LocaleMeta } from 'core';
import { LimitationManager, DefaultLimitationManager } from 'core/limitation/LimitationManager';
import { DeliverType, RtbCampaignPlanType, CreativeDeliverType, AdType, VideoAdViewObjective, CampaignState, RtbCampaignBasic, RTBCAMPAIGN_DEFAULT_AGE_MIN, RTBCAMPAIGN_DEFAULT_AGE_MAX, RtbCampaign } from 'core/rtbCampaign/RtbCampaign';
import { Order } from 'core/order/Order';
import { DefaultRtbCampaignManager, RtbCampaignManager } from 'core/rtbCampaign/RtbCampaignManager';
import { ConversionManager, DefaultConversionManager } from 'core/conversion/ConversionManager';
import { RtbCampaignBasicFormModel, RtbCampaignBasicFormModelConstructorParams } from './RtbCampaignForm/RtbCampaignBasicFormModel';
import { defaultInventorySetting, LIMITATION_TYPE, pilotTVInventorySetting, retailMediaInventorySetting } from 'containers/Limitations/LimitationSetting/LimitationSettingData';
import { DefaultEditLimitationModel, EditLimitationModel } from 'containers/Limitations/EditLimitationModel';
import { OPERATE } from 'enum/Operate';
import moment from 'moment';
import i18n from 'i18next';
import _ from 'lodash';
import { SelectOptions } from 'components/common/commonType';
import { getPriceValue } from 'helper/CurrencyHelper';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { CampaignGroup, CampaignGroupChannel, FBBidStrategy, GoCampaignGroupObjective } from 'core/campaignGroup/CampaignGroup';
import { AdRequestSourceManager, DefaultAdRequestSourceManager } from 'core/adRequestSource/AdRequestSourceManager';
import { CreateOneForAllDisplayFormModel, EditOneForAllDisplayFormModel, OneForAllDisplayFormModel } from './RtbCampaignForm/OneForAllDisplayFormModel';
import { UnknowAdTypeFormModel, CreateUnknowAdTypeFormModel, EditUnknowAdTypeFormModel } from './RtbCampaignForm/UnknowAdTypeFormModel';
import { GoCampaignOptimizationGoal } from 'core/goCampaign/GoCampaign';
import { DefaultRtbCampaignSummaryStepModel, RtbCampaignDraftSummaryStepModel, RtbCampaignSummaryStepModel } from './FlowSteps/RtbCampaignSummaryStepModel';
import { Retail, RetailerType } from 'core/product/Product';
import { DefaultRdpManager, RdpManager } from 'core/rdp/RdpManager';
import { CreatePilotTVFormModel, EditPilotTVFormModel, PilotTVFormModel } from './RtbCampaignForm/PilotTVFormModel';
import { getLimitationContentLabel, getLimitationTitleOfType, toServerStructure } from 'utils/LimitationUtil';
import { ageMaxOptions, ageMinOptions, genderOptions, getRtbAgeGroupsByAgeRange } from 'core/limitation/goCampaignTAOptions';
import { EstimatedAudience } from 'core/goSegment/GoSegment';
import { SavedTargeting } from 'core/limitation/Limitation';
import { SummaryTitleColor } from 'components/SummaryDetail/SummaryDetail';
import { DraftManager, RtbCampaignDraftManager } from 'core/draft/DraftManager';
import { toast } from 'react-toastify';

export interface RtbCampaignSetupFlowPageModel {
  readonly type: string;
  readonly objectType: string;
  readonly order: Order;
  readonly conversionOptions?: Array<SelectOptions>;
  readonly addonFeatureManager: AddonFeatureManager;
  readonly state: RtbCampaignSetupFlowPageState;
  readonly event: UpdateEventListener<RtbCampaignSetupFlowPageModel>;
  readonly limitationModel?: EditLimitationModel;
  readonly campaignBasicFormModel?: RtbCampaignBasicFormModel;
  readonly limitationPreSet: any;
  readonly campaignId: number | string | null;
  readonly needSetupTracking: boolean;
  readonly needSetupAdlogo: boolean;
  readonly campaignGroup?: CampaignGroup;
  readonly localeMeta?: LocaleMeta;
  readonly estimatedAudience?: EstimatedAudience;
  readonly audienceLowestThreshold: number;
  readonly savedTAList: SavedTargeting[];
  readonly appliedSavedTAInfo?: SelectOptions;
  init (): void;
  setCampaign (campaign: any, rerender?: boolean): void;
  getRtbCampaignBasicFormModel (adType): RtbCampaignBasicFormModel | undefined;
  getLimitationModel (priceModel): EditLimitationModel;
  getTitle (): string;
  onAdTypeChange (adType): void;
  onUnmount (handler): void;
  setOrder (order: Order): void;
  setRedirectPath (redirectPath?: string): void;
  setFinishedRedirectPath (redirectPath?: string): void;
  cancel (): void;
  getRtbCampaignSummaryStepModel (goLast, goStep): RtbCampaignSummaryStepModel;
  getLimitationsSummaryData (limitations): any;
  setShowTAManagement (showTAManagement: boolean): void;
  importLimitation (appliedSavedTAInfo: SelectOptions, limitationValue: any): void;
  onDeleteSaveTA (deletedTAId: number);
  onSaveDraft?: (campaign: RtbCampaign) => void;
}

export type RtbCampaignSetupFlowPageProps = {
  readonly model: RtbCampaignSetupFlowPageModel;
};

export type RtbCampaignSetupFlowPageState = {
  readonly loading: boolean;
  readonly redirectPath?: string;
  readonly campaign: any;
  readonly finished: boolean;
  readonly showTAManagement: boolean;
};

export abstract class DefaultRtbCampaignSetupFlowPageModel implements RtbCampaignSetupFlowPageModel {
  type: string;
  objectType: string;
  event: FireableUpdateEventListener<RtbCampaignSetupFlowPageModel>;
  loading: boolean;
  redirectPath?: string;
  addonFeatureManager: AddonFeatureManager;
  campaign: any;
  defaultCampaign: any;
  manager: RtbCampaignManager;
  conversionManager: ConversionManager;
  conversionOptions: Array<SelectOptions>;
  limitationManager: LimitationManager;
  limitationPreSet: any;
  order: Order;
  campaignOneForAllDisplayFormModel?: OneForAllDisplayFormModel;
  campaignPilotTVFormModel?: PilotTVFormModel;
  campaignBasicFormModel?: RtbCampaignBasicFormModel;
  campaignSummaryModel?: RtbCampaignSummaryStepModel;
  limitationModel: any;
  rbLimitationModel: any;
  normalLimitationModel: any;
  finished: boolean;
  goSegments: SelectOptions[] = [];
  campaignUnknowAdTypeFormModel?: UnknowAdTypeFormModel;
  retailers?: Retail[];
  savedTAList: SavedTargeting[] = [];
  showTAManagement: boolean = false;
  appliedSavedTAInfo?: SelectOptions;

  constructor (
    type: string,
    order: Order,
    addonFeatureManager: AddonFeatureManager,
    manager: RtbCampaignManager,
    conversionManager: ConversionManager,
    limitationManager: LimitationManager,
    public campaignGroup?: CampaignGroup,
    public localeMeta?: LocaleMeta,
    protected otherCampaignOfCampaignGroup?: RtbCampaignBasic[],
    private adRequestSourceManager: AdRequestSourceManager = new DefaultAdRequestSourceManager(),
    private rdpManager: RdpManager = new DefaultRdpManager()
  ) {
    this.type = type;
    this.objectType = 'campaign';
    this.order = { ...order };
    this.order.budgetBalance = this.campaignGroup ? this.campaignGroup.budgetBalance : this.order.budgetBalance;
    this.addonFeatureManager = addonFeatureManager;
    this.manager = manager;
    this.conversionManager = conversionManager;
    this.limitationManager = limitationManager;
    this.event = new FireableUpdateEventListener<RtbCampaignSetupFlowPageModel>();
    this.loading = true;
    this.conversionOptions = [];
    this.finished = false;
  }

  abstract init ();

  abstract getTitle ();

  async initRetailOptions () {
    if (this.campaignGroup?.channel !== CampaignGroupChannel.RETAIL_MEDIA) {
      return;
    }
    try {
      this.retailers = await this.rdpManager.getRetails();
    } catch (e) {}
  }

  async initSavedTAList () {
    try {
      this.savedTAList = await this.limitationManager.getSavedTargetings(
        this.order.advertiserId,
        _.get(this.campaignGroup, 'channel', CampaignGroupChannel.RTB)
      );
    } catch (e) {}
  }

  setShowTAManagement = (showTAManagement: boolean): void => {
    this.showTAManagement = showTAManagement;
    this.updateState(false);
  }

  refreshSavedTAList = async () => {
    this.updateState(true);
    try {
      this.savedTAList = await this.limitationManager.getSavedTargetings(
        this.order.advertiserId,
        _.get(this.campaignGroup, 'channel', CampaignGroupChannel.RTB)
      );
    } catch (e) {}
    this.updateState(false);
  }

  get needSetupTracking () {
    const addonEnable = this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CONVERSION_TRACKING.CONV_TRACKING_LIST) ||
      this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CAMPAIGN.THIRDPARTY_TRACKING) ||
      this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CAMPAIGN.RETARGETING_TRACKER);
    return addonEnable && _.get(this.campaign, 'basic.adType') !== AdType.PILOT_TV;
  }

  get needSetupAdlogo () {
    return this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CAMPAIGN.CUSTOMIZE_ADLOGO) && _.get(this.campaign, 'basic.adType') !== AdType.PILOT_TV;
  }

  get estimatedAudience () {
    return this.limitationModel ? this.limitationModel.estimateData : undefined;
  }

  get audienceLowestThreshold () {
    return 5000000;
  }

  to404 () {
    const redirectPath = this.campaignGroup ?
      `/orders/${this.order.orderNumber}/campaign-groups/${this.campaignGroup.groupId}/campaigns/${this.campaignId}/edit/error404` :
      `/orders/${this.order.orderNumber}/campaigns/${this.campaignId}/edit/error404`;
    this.setRedirectPath(redirectPath);
  }

  initBidStrategy () {
    if (!this.campaign) {
      return;
    }

    _.set(this.campaign, 'basic.bidStrategy',
      _.isNumber(_.get(this.campaign, 'basic.bidPrice')) ? FBBidStrategy.LOWEST_COST_WITH_BID_CAP : FBBidStrategy.LOWEST_COST_WITHOUT_CAP
    );
  }

  getRtbCampaignSummaryStepModel (goLast, goStep) {
    if (this.campaignSummaryModel) {
      return this.campaignSummaryModel;
    }
    this.campaignSummaryModel = new DefaultRtbCampaignSummaryStepModel(this, goLast, goStep);
    return this.campaignSummaryModel;
  }

  async initConvertsionOptions () {
    this.conversionOptions = []; // await this.conversionManager.getConversionOptions(this.order.advertiserId);
  }

  async initGoSegments () {
    this.goSegments = await this.adRequestSourceManager.getGoSegments(this.order.advertiserId, this.campaignGroup ? this.campaignGroup.channel : CampaignGroupChannel.RTB);
  }

  getLimitationContent (limitationData) {
    if (!limitationData) {
      return [];
    }
    return _.compact(limitationData.map((data) => {
      const ignoreType = [
        'unicornlanguage',
        'age',
        'gender',
        'age_min',
        'age_max'
      ];
      if (ignoreType.includes(data.type)) {
        return undefined;
      }

      if (!data.value || data.value.length === 0) {
        return undefined;
      }

      const isRetailMediaCampaign = this.campaignGroup?.channel === CampaignGroupChannel.RETAIL_MEDIA;
      return {
        label: data.type === 'adspace' && isRetailMediaCampaign ? i18n.t('limitation.labels.placement') : getLimitationTitleOfType(data.type),
        value: data.value.map((value) => getLimitationContentLabel(data.type, value)).join(', ')
      };
    }));
  }

  getLimitationsSummaryData = (limitations): any => {
    const includeLimitation = _.get(limitations, 'include', []);
    const ageMinLimitation = includeLimitation.find(limitation => limitation.type === 'age_min');
    const ageMinOption = ageMinOptions.find(option => option.value === _.get(ageMinLimitation, 'value'));
    const ageMaxLimitation = includeLimitation.find(limitation => limitation.type === 'age_max');
    const ageMaxOption = ageMaxOptions.find(option => option.value === _.get(ageMaxLimitation, 'value'));
    const ageGroupsHint = getRtbAgeGroupsByAgeRange(_.get(ageMinOption, 'value', 0), _.get(ageMaxOption, 'value', 0))
                            .map(group => group.label)
                            .join(',');
    const genderLimitation = includeLimitation.find(limitation => limitation.type === 'gender');
    const genderValue = _.get(genderLimitation, 'value', [genderOptions.find(option => option.value === -1)]);
    return _.omitBy({
      general: {
        title: i18n.t('campaignSummary.titles.general'),
        titlePrefixColor: SummaryTitleColor.DARK,
        content: _.compact([{
          label: i18n.t('limitation.labels.age'),
          value: `${_.get(ageMinOption, 'label', 0)} ~ ${_.get(ageMaxOption, 'label', 0)}`,
          hint: i18n.t('limitation.hints.ageGroups', { groups: ageGroupsHint })
        }, genderValue[0] ? {
          label: i18n.t('limitation.labels.gender'),
          value: genderValue[0].label
        } : undefined])
      },
      include: {
        title: i18n.t('campaignSummary.titles.inc'),
        titlePrefixColor: SummaryTitleColor.GREEN,
        content: this.getLimitationContent(limitations.include)
      },
      preferred: {
        title: i18n.t('campaignSummary.titles.preferred'),
        titlePrefixColor: SummaryTitleColor.BLUE,
        content: this.getLimitationContent(limitations.preferred)
      },
      nonPreferred: {
        title: i18n.t('campaignSummary.titles.nonPreferred'),
        titlePrefixColor: SummaryTitleColor.YELLOW,
        content: this.getLimitationContent(limitations.nonPreferred)
      },
      exclude: {
        title: i18n.t('campaignSummary.titles.exc'),
        titlePrefixColor: SummaryTitleColor.RED,
        content: this.getLimitationContent(limitations.exclude)
      },
      other: limitations.other ? {
        title: i18n.t('campaignSummary.titles.other'),
        content: this.getLimitationContent(limitations.other)
      } : undefined
    }, _.isEmpty);
  }

  importLimitation = (appliedSavedTAInfo: SelectOptions, limitationValue: any) => {
    if (!this.limitationModel) {
      return;
    }
    this.appliedSavedTAInfo = appliedSavedTAInfo;
    this.limitationModel.updateLimitationValue(limitationValue);
  }

  onDeleteSaveTA = (deletedTAId: number) => {
    if (this.appliedSavedTAInfo && this.appliedSavedTAInfo.value === deletedTAId) {
      this.appliedSavedTAInfo = undefined;
    }
    this.refreshSavedTAList();
  }

  setCampaign = (campaign: any, rerender: boolean | undefined = true) => {
    this.campaign = campaign;
    this.createCampaignFormModels();
    rerender && this.updateState(false);
  }

  setOrder = (order: Order) => {
    this.order = { ...order };
    this.order.budgetBalance = this.campaignGroup ? this.campaignGroup.budgetBalance : this.order.budgetBalance;
  }

  abstract get campaignId ();

  abstract createCampaignFormModels ();

  get state (): RtbCampaignSetupFlowPageState {
    return {
      loading: this.loading,
      redirectPath: this.redirectPath,
      campaign: this.campaign,
      finished: this.finished,
      showTAManagement: this.showTAManagement
    };
  }

  getCamapignFormModelParams (): RtbCampaignBasicFormModelConstructorParams {
    return [
      this.defaultCampaign,
      this.campaign.basic,
      this.order,
      this.campaignGroup,
      this.addonFeatureManager,
      this.onPriceModelChange,
      this.otherCampaignOfCampaignGroup,
      this.retailers
    ];
  }

  onAdTypeChange (adType: AdType) {
    const campaignBasicModel = this.getRtbCampaignBasicFormModel(adType);
    const defaultCampaign = _.cloneDeep(this.defaultCampaign);
    if (campaignBasicModel) {
      _.set(defaultCampaign, 'basic.priceModel', campaignBasicModel.defaultPriceModel);
      _.set(defaultCampaign, 'basic.optimize', campaignBasicModel.getDefaultOptimizeType(defaultCampaign.basic.priceModel));
      _.set(defaultCampaign, 'basic.videoAdViewObjective', adType === AdType.VIDEO || adType === AdType.THIRD_PARTY_BOTTOM ? {
        videoAdEvent: VideoAdViewObjective.DEFAULT
      } : undefined);
      if (_.get(defaultCampaign, 'basic.optimize') === GoCampaignOptimizationGoal.REACH && _.get(this.campaignGroup, 'objective') === GoCampaignGroupObjective.AwarenessObjective.REACH) {
        _.set(defaultCampaign, 'basic.frequency', {
          maxFrequency: 5,
          intervalDays: 1
        });
      } else {
        _.set(defaultCampaign, 'basic.frequency', undefined);
      }
    }
    const newCampaign = {
      ...defaultCampaign,
      basic: {
        ...defaultCampaign.basic,
        adType: adType
      }
    };
    this.setCampaign(newCampaign, true);
  }

  setRedirectPath (redirectPath?: string) {
    this.redirectPath = redirectPath;
    this.updateState(false);
  }

  setFinishedRedirectPath (redirectPath?: string) {
    this.redirectPath = redirectPath;
    this.finished = true;
    this.updateState(false);
  }

  getRtbCampaignBasicFormModel (adType?: AdType): RtbCampaignBasicFormModel | undefined {
    switch (adType) {
      case AdType.ONE_FOR_ALL_DISPLAY:
        this.campaignBasicFormModel = this.campaignOneForAllDisplayFormModel;
        break;
      case AdType.PILOT_TV:
        this.campaignBasicFormModel = this.campaignPilotTVFormModel;
        break;
      default:
        this.campaignBasicFormModel = this.campaignUnknowAdTypeFormModel;
        break;
    }
    return this.campaignBasicFormModel;
  }

  getLimitationModel (priceModel: RtbCampaignPlanType) {
    if (priceModel === RtbCampaignPlanType.RB) {
      const limitationsCanNotNull = {
        include: ['adspace']
      };
      this.limitationModel = this.getRbLimitationModel(limitationsCanNotNull);
    } else {
      const defaultCanNotNull = this.campaignGroup?.channel === CampaignGroupChannel.RETAIL_MEDIA ? {} : {
        include: ['adx']
      };
      const limitationPreSet = this.limitationPreSet ? this.limitationPreSet : {};
      Object.keys(defaultCanNotNull).forEach(opreate => {
        _.remove(defaultCanNotNull[opreate],
          value => !limitationPreSet[opreate] ||
            limitationPreSet[opreate].find(element => element.type === value) === undefined
        );
      });
      const limitationsCanNotNull = _.omitBy(defaultCanNotNull, _.isEmpty);
      this.limitationModel = this.getNormalLimitationModel(limitationsCanNotNull);
    }
    return this.limitationModel;
  }

  getRbLimitationModel (limitationsCanNotNull) {
    if (!this.rbLimitationModel) {
      this.rbLimitationModel = new DefaultEditLimitationModel(
        defaultInventorySetting(this.campaign.basic.advertiserId, LIMITATION_TYPE.CAMPAIGN),
        this.campaign.limitations,
        { need: [OPERATE.INCLUDE] },
        ['campaign_asiamax_space'],
        _.partial(this.setShowTAManagement, true),
        limitationsCanNotNull,
        {
          channel: CampaignGroupChannel.RTB,
          audienceLowestThreshold: this.audienceLowestThreshold
        }
      );
    }
    this.rbLimitationModel.setLimitationCanNotNull(limitationsCanNotNull);
    return this.rbLimitationModel;
  }

  isCampaignNeedOtherLimitation (campaign) {
    return true;
  }

  getNormalLimitationModel (limitationsCanNotNull) {
    const limitationSetting = this.campaignGroup?.channel === CampaignGroupChannel.RETAIL_MEDIA ?
      retailMediaInventorySetting(this.campaign.basic.advertiserId, LIMITATION_TYPE.CAMPAIGN, this.goSegments, this.campaign.basic.retailType) :
      this.campaign.basic.adType === AdType.PILOT_TV ?
        pilotTVInventorySetting(LIMITATION_TYPE.CAMPAIGN) :
        defaultInventorySetting(this.campaign.basic.advertiserId, LIMITATION_TYPE.CAMPAIGN, this.goSegments);
    if (!this.normalLimitationModel) {
      this.normalLimitationModel = new DefaultEditLimitationModel(
        limitationSetting,
        this.campaign.limitations,
        {
          need: [OPERATE.INCLUDE, OPERATE.PREFERRED, OPERATE.NONPREFERRED],
          notNeed: [OPERATE.EXCLUDE],
          other: this.isCampaignNeedOtherLimitation(this.campaign) ? [] : undefined
        },
        this.addonFeatureManager.addonFeature,
        _.partial(this.setShowTAManagement, true),
        limitationsCanNotNull,
        {
          channel: CampaignGroupChannel.RTB,
          audienceLowestThreshold: this.audienceLowestThreshold,
          channelTargetingGetter: limitationValue => {
            const ageMinLimitation = limitationValue.include ? limitationValue.include.find(limitation => limitation.type === 'age_min') : undefined;
            const ageMin = ageMinLimitation ? ageMinLimitation.value : 18;
            const ageMaxLimitation = limitationValue.include ? limitationValue.include.find(limitation => limitation.type === 'age_max') : undefined;
            const ageMax = ageMaxLimitation ? ageMaxLimitation.value : 61;
            const ages = getRtbAgeGroupsByAgeRange(ageMin, ageMax);
            const otherLimitations = toServerStructure(limitationValue)
              .filter(limitation => (
                  limitation.op === 'inc' && ['gender', 'segment'].includes(limitation.type)
                ) || (limitation.op === 'exc' && limitation.type === 'segment'));
            otherLimitations.push({
              op: 'inc',
              limits: ages,
              isGroup: false,
              type: 'age'
            });
            return otherLimitations;
          }
        }
      );
    }
    this.normalLimitationModel.setLimitationSetting(limitationSetting);
    this.normalLimitationModel.setLimitationCanNotNull(limitationsCanNotNull);
    return this.normalLimitationModel;
  }

  onPriceModelChange = (priceModel, currentCampaignBasic) => {
    if (!this.campaignBasicFormModel) {
      return;
    }
    const limitationModel = this.getLimitationModel(priceModel);
    const needVideoAdViewObjective =
      ((currentCampaignBasic.adType === AdType.VIDEO || currentCampaignBasic.adType === AdType.UNKNOW) && priceModel === RtbCampaignPlanType.FCPV) ||
      currentCampaignBasic.adType === AdType.THIRD_PARTY_BOTTOM;
    this.setCampaign({
      basic: {
        ...currentCampaignBasic,
        priceModel,
        optimize: this.campaignBasicFormModel.getDefaultOptimizeType(priceModel),
        orderPrice: undefined,
        bidPrice: undefined,
        videoAdViewObjective: needVideoAdViewObjective ?
          currentCampaignBasic.videoAdViewObjective ?
            currentCampaignBasic.videoAdViewObjective : {
              videoAdEvent: VideoAdViewObjective.DEFAULT
            } : undefined
      },
      limitations: limitationModel.limitationValue
    });
  }

  cancel = () => {
    const redirectPath = this.campaignGroup ?
      `/orders/${this.order.orderNumber}/campaign-groups/${this.campaignGroup.groupId}` :
      `/orders/${this.order.orderNumber}`;
    this.setRedirectPath(redirectPath);
  }

  validateCampaign () {
    return this.campaignGroup && this.campaign.basic.goGanGroupId !== _.get(this.campaignGroup, 'rtb.group_id');
  }

  onUnmount (handler) {
    this.event.remove(handler);
    this.redirectPath = undefined;
    this.campaign = undefined;
    this.rbLimitationModel = undefined;
    this.normalLimitationModel = undefined;
    this.campaignSummaryModel = undefined;
    this.finished = false;
  }

  updateState (loading: boolean) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}

export class CreateRtbCampaignSetupFlowPageModel extends DefaultRtbCampaignSetupFlowPageModel {

  constructor (
    order: Order,
    addonFeatureManager: AddonFeatureManager,
    campaignGroup?: CampaignGroup,
    localeMeta?: LocaleMeta,
    otherCampaignOfCampaignGroup?: RtbCampaignBasic[],
    manager: RtbCampaignManager = new DefaultRtbCampaignManager(),
    conversionManager: ConversionManager = new DefaultConversionManager(),
    limitationManager: LimitationManager = new DefaultLimitationManager(),
    adRequestSourceManager: AdRequestSourceManager = new DefaultAdRequestSourceManager(),
    rdpManager: RdpManager = new DefaultRdpManager()
  ) {
    super(
      'create',
      order,
      addonFeatureManager,
      manager,
      conversionManager,
      limitationManager,
      campaignGroup,
      localeMeta,
      otherCampaignOfCampaignGroup,
      adRequestSourceManager,
      rdpManager
    );
  }

  get campaignId () {
    return null;
  }

  async init () {
    this.updateState(true);
    try {
      await this.initRetailOptions();
      await this.initSavedTAList();
      this.limitationPreSet = await this.limitationManager.getLimitationPreSet('campaign');
      if (this.campaignGroup?.channel === CampaignGroupChannel.RETAIL_MEDIA) {
        Object.keys(this.limitationPreSet).forEach(key => {
          const limitationPreset = this.limitationPreSet[key];
          _.remove(limitationPreset, (preset: any) => preset.type === 'adx');
        });
      }
      this.campaign = await this.createDefaultCampaign();
      this.initBidStrategy();
      this.defaultCampaign = _.cloneDeep(this.campaign);
      await this.initConvertsionOptions();
      await this.initGoSegments();
      this.createCampaignFormModels();
    } catch (e) {}
    this.updateState(false);
  }

  getTitle () {
    return i18n.t('campaign.labels.createCampaignTitle');
  }

  getRbLimitationModel (limitationsCanNotNull) {
    if (!this.rbLimitationModel) {
      this.rbLimitationModel = new DefaultEditLimitationModel(
        defaultInventorySetting(this.campaign.basic.advertiserId, LIMITATION_TYPE.CAMPAIGN),
        {
          include: [],
          exclude: [],
          nonPreferred: [],
          other: [],
          preferred: []
        },
        { need: [OPERATE.INCLUDE] },
        ['campaign_asiamax_space'],
        _.partial(this.setShowTAManagement, true),
        limitationsCanNotNull,
        {
          channel: CampaignGroupChannel.RTB,
          audienceLowestThreshold: this.audienceLowestThreshold
        }
      );
    }
    this.rbLimitationModel.setLimitationCanNotNull(limitationsCanNotNull);
    return this.rbLimitationModel;
  }

  async createDefaultCampaign () {
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';
    const ageLimitationPresets = [{
      type: 'age_min',
      value: RTBCAMPAIGN_DEFAULT_AGE_MIN
    }, {
      type: 'age_max',
      value: RTBCAMPAIGN_DEFAULT_AGE_MAX
    }];
    const limitationPreSet = {
      ...this.limitationPreSet,
      include: this.limitationPreSet.include ? [
        ...this.limitationPreSet.include,
        ...ageLimitationPresets
      ] : ageLimitationPresets
    };
    const startDate = this.getCampaignStartDay();
    const endDate = this.getCampaignEndDay();
    const scheduleDateCount = endDate.diff(startDate, 'days') + 1;
    const minBudgetOfCampaign = this.order.campaignConstraint.budgetMinimum * scheduleDateCount;
    const parentBidStrategy = _.get(this.campaignGroup, 'rtb.bid_strategy');
    const defaultRetailer = this.retailers ?
      this.retailers.find(retailer => retailer.name.toLowerCase() === RetailerType.PCHOME.toLowerCase()) :
      undefined;
    const campaign = {
      basic: {
        adLogo: this.order.adLogo,
        advertiserId: this.order.advertiserId,
        budget: this.campaignGroup && this.campaignGroup.autoOptimise ?
          minBudgetOfCampaign :
          this.order.budgetBalance,
        checkpoints: [],
        creativeDeliverType: CreativeDeliverType.OPTIMIZE,
        dailyTargetBudget: null,
        tags: [],
        name: this.campaignGroup ? this.campaignGroup.name : this.order.projectName,
        orderId: this.order.id,
        startDate: startDate.format(dateFormat),
        endDate: endDate.format(dateFormat),
        priceModel: RtbCampaignPlanType.RS,
        optimize: GoCampaignOptimizationGoal.LINK_CLICKS,
        enableMonitor: true,
        deliverType: DeliverType.STANDARD,
        convTrackEvent: 'click',
        expectedSpent: 0,
        orderPriceEnable: true,
        bidPrice: parentBidStrategy && parentBidStrategy === FBBidStrategy.LOWEST_COST_WITH_BID_CAP ? 0 : undefined,
        retailType: this.campaignGroup?.channel === CampaignGroupChannel.RETAIL_MEDIA && defaultRetailer ? defaultRetailer.id : undefined
      },
      limitations: _.cloneDeep(limitationPreSet)
    };
    return campaign;
  }

  getCampaignStartDay () {
    const now = moment()
      .startOf('hour');
    const orderStartDate = moment(this.order.startDate);
    return now.isBefore(orderStartDate) ? orderStartDate : now;
  }

  getCampaignEndDay () {
    return moment.min(
      moment(this.order.endDate)
        .add(1, 'days')
        .subtract(1, 'seconds'),
      this.getCampaignStartDay().add(91, 'days').endOf('day')
    );
  }

  createCampaignFormModels () {
    const params = this.getCamapignFormModelParams();
    this.campaignOneForAllDisplayFormModel = new CreateOneForAllDisplayFormModel(...params);
    this.campaignPilotTVFormModel = new CreatePilotTVFormModel(...params);
    this.campaignUnknowAdTypeFormModel = new CreateUnknowAdTypeFormModel(...params);
  }
}

export class EditRtbCampaignSetupFlowPageModel extends DefaultRtbCampaignSetupFlowPageModel {

  modalCampaignId: number;

  constructor (
    modalCampaignId: number,
    order: Order,
    addonFeatureManager: AddonFeatureManager,
    campaignGroup?: CampaignGroup,
    localeMeta?: LocaleMeta,
    otherCampaignOfCampaignGroup?: RtbCampaignBasic[],
    manager: RtbCampaignManager = new DefaultRtbCampaignManager(),
    conversionManager: ConversionManager = new DefaultConversionManager(),
    limitationManager: LimitationManager = new DefaultLimitationManager(),
    adRequestSourceManager: AdRequestSourceManager = new DefaultAdRequestSourceManager(),
    rdpManager: RdpManager = new DefaultRdpManager()
  ) {
    super(
      'edit',
      order,
      addonFeatureManager,
      manager,
      conversionManager,
      limitationManager,
      campaignGroup,
      localeMeta,
      otherCampaignOfCampaignGroup,
      adRequestSourceManager,
      rdpManager
    );
    this.modalCampaignId = modalCampaignId;
  }

  get campaignId () {
    return this.modalCampaignId;
  }

  async getCampaign () {
    return this.manager.getCampaign(this.modalCampaignId);
  }

  async init () {
    this.updateState(true);
    try {
      await this.initRetailOptions();
      await this.initSavedTAList();
      this.limitationPreSet = await this.limitationManager.getLimitationPreSet('campaign');
      this.campaign = await this.getCampaign();
      this.initBidStrategy();
      this.defaultCampaign = _.cloneDeep(this.campaign);
      await this.initConvertsionOptions();
      await this.initGoSegments();
      this.createCampaignFormModels();
      if (this.validateCampaign()) {
        this.to404();
        return;
      }
    } catch (e) {}
    this.updateState(false);
  }

  getTitle () {
    return i18n.t('campaign.labels.editCampaignTitle');
  }

  createCampaignFormModels () {
    const params = this.getCamapignFormModelParams();
    this.campaignOneForAllDisplayFormModel = new EditOneForAllDisplayFormModel(...params);
    this.campaignPilotTVFormModel = new EditPilotTVFormModel(...params);
    this.campaignUnknowAdTypeFormModel = new EditUnknowAdTypeFormModel(...params);
  }
}

export class CopyRtbCampaignSetupFlowPageModel extends DefaultRtbCampaignSetupFlowPageModel {

  modalCampaignId: number;

  constructor (
    modalCampaignId: number,
    order: Order,
    addonFeatureManager: AddonFeatureManager,
    campaignGroup?: CampaignGroup,
    localeMeta?: LocaleMeta,
    otherCampaignOfCampaignGroup?: RtbCampaignBasic[],
    manager: RtbCampaignManager = new DefaultRtbCampaignManager(),
    conversionManager: ConversionManager = new DefaultConversionManager(),
    limitationManager: LimitationManager = new DefaultLimitationManager(),
    adRequestSourceManager: AdRequestSourceManager = new DefaultAdRequestSourceManager(),
    rdpManager: RdpManager = new DefaultRdpManager()
  ) {
    super(
      'copy',
      order,
      addonFeatureManager,
      manager,
      conversionManager,
      limitationManager,
      campaignGroup,
      localeMeta,
      otherCampaignOfCampaignGroup,
      adRequestSourceManager,
      rdpManager
    );
    this.modalCampaignId = modalCampaignId;
  }

  get campaignId () {
    return this.modalCampaignId;
  }

  async init () {
    this.updateState(true);
    try {
      await this.initRetailOptions();
      await this.initSavedTAList();
      this.limitationPreSet = await this.limitationManager.getLimitationPreSet('campaign');
      this.campaign = await this.manager.getNoCidCampaign(this.campaignId);
      _.set(this.campaign, 'basic.orderPriceEnable', true);
      this.initBidStrategy();
      this.campaign.basic.spents = 0;
      this.campaign.basic.expectedSpent = 0;
      const campaignIsStart = moment().isAfter(this.campaign.basic.startDate);
      const campaignIsEnd = moment().isAfter(this.campaign.basic.endDate);
      if (campaignIsStart) {
        this.campaign.basic.startDate = moment().startOf('hours').format('YYYY-MM-DD HH:mm:ss');
      }
      if (campaignIsEnd) {
        this.campaign.basic.endDate = moment(this.order.endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss');
      }
      if (this.campaignGroup && this.campaignGroup.autoOptimise) {
        this.campaign.basic.budget = this.manager.getMinBudgetOfCampaign(this.campaign.basic, this.order.campaignConstraint.budgetMinimum);
      }
      this.defaultCampaign = _.cloneDeep(this.campaign);
      await this.initConvertsionOptions();
      await this.initGoSegments();
      this.createCampaignFormModels();
      if (this.validateCampaign()) {
        this.to404();
        return;
      }
    } catch (e) {}
    this.updateState(false);
  }

  getTitle () {
    return i18n.t('campaign.labels.copyCampaignTitle');
  }

  createCampaignFormModels () {
    const params = this.getCamapignFormModelParams();
    this.campaignOneForAllDisplayFormModel = new CreateOneForAllDisplayFormModel(...params);
    this.campaignPilotTVFormModel = new CreatePilotTVFormModel(...params);
    this.campaignUnknowAdTypeFormModel = new CreateUnknowAdTypeFormModel(...params);
  }
}

export class SplitRtbCampaignSetupFlowPageModel extends DefaultRtbCampaignSetupFlowPageModel {

  modalCampaignId: number;

  constructor (
    modalCampaignId: number,
    order: Order,
    addonFeatureManager: AddonFeatureManager,
    campaignGroup?: CampaignGroup,
    localeMeta?: LocaleMeta,
    otherCampaignOfCampaignGroup?: RtbCampaignBasic[],
    manager: RtbCampaignManager = new DefaultRtbCampaignManager(),
    conversionManager: ConversionManager = new DefaultConversionManager(),
    limitationManager: LimitationManager = new DefaultLimitationManager(),
    adRequestSourceManager: AdRequestSourceManager = new DefaultAdRequestSourceManager(),
    rdpManager: RdpManager = new DefaultRdpManager()
  ) {
    super(
      'split',
      order,
      addonFeatureManager,
      manager,
      conversionManager,
      limitationManager,
      campaignGroup,
      localeMeta,
      otherCampaignOfCampaignGroup,
      adRequestSourceManager,
      rdpManager
    );
    this.modalCampaignId = modalCampaignId;
  }

  get campaignId (): number | null {
    return this.modalCampaignId;
  }

  async init () {
    this.updateState(true);
    try {
      await this.initRetailOptions();
      await this.initSavedTAList();
      this.limitationPreSet = await this.limitationManager.getLimitationPreSet('campaign');
      this.campaign = await this.manager.getNoCidCampaign(this.modalCampaignId);
      _.set(this.campaign, 'basic.orderPriceEnable', true);
      this.initBidStrategy();
      this.campaign.basic.expectedSpent = 0;
      this.campaign.basic.spents = 0;
      this.campaign.basic.state = CampaignState.ACTIVATE;
      const campaignIsStart = moment().isAfter(this.campaign.basic.startDate);
      const campaignIsEnd = moment().isAfter(this.campaign.basic.endDate);
      if (campaignIsStart) {
        this.campaign.basic.startDate = moment().startOf('hours').format('YYYY-MM-DD HH:mm:ss');
      }
      if (campaignIsEnd) {
        this.campaign.basic.endDate = moment(this.order.endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss');
      }
      const oldCampaignSpent = this.campaign.basic.state !== CampaignState.DEACTIVATE ? this.campaign.basic.expectedSpent : this.campaign.basic.spents;
      const minBudgetOfCampaign = this.manager.getMinBudgetOfCampaign(this.campaign.basic, this.order.campaignConstraint.budgetMinimum);
      const budgetMinimum = this.campaign.basic.state !== CampaignState.DEACTIVATE ? minBudgetOfCampaign : 0;
      let oldCampaignMin = oldCampaignSpent > budgetMinimum ? oldCampaignSpent : budgetMinimum;
      if (this.campaign.basic.state === CampaignState.DEACTIVATE) {
        oldCampaignMin = this.campaign.basic.spents;
      }
      this.order.budgetBalance = oldCampaignSpent > this.campaign.basic.budget ? 0 : getPriceValue(this.order.currency, this.campaign.basic.budget - oldCampaignMin);
      this.campaign.basic.budget = this.campaignGroup && this.campaignGroup.autoOptimise ? minBudgetOfCampaign : this.order.budgetBalance;
      this.defaultCampaign = _.cloneDeep(this.campaign);
      await this.initConvertsionOptions();
      await this.initGoSegments();
      this.createCampaignFormModels();
      if (this.validateCampaign()) {
        this.to404();
        return;
      }
    } catch (e) {}
    this.updateState(false);
  }

  getTitle () {
    return i18n.t('campaign.labels.splitCampaignTitle');
  }

  createCampaignFormModels () {
    const params = this.getCamapignFormModelParams();
    this.campaignOneForAllDisplayFormModel = new CreateOneForAllDisplayFormModel(...params);
    this.campaignPilotTVFormModel = new CreatePilotTVFormModel(...params);
    this.campaignUnknowAdTypeFormModel = new CreateUnknowAdTypeFormModel(...params);
  }
}

export class EditRtbCampaignDraftSetupFlowPageModel extends CreateRtbCampaignSetupFlowPageModel {

  constructor (
    private draftId: string,
    order: Order,
    addonFeatureManager: AddonFeatureManager,
    campaignGroup?: CampaignGroup,
    localeMeta?: LocaleMeta,
    otherCampaignOfCampaignGroup?: RtbCampaignBasic[],
    private draftManager: DraftManager = new RtbCampaignDraftManager()
  ) {
    super(
      order,
      addonFeatureManager,
      campaignGroup,
      localeMeta,
      otherCampaignOfCampaignGroup
    );
    this.type = 'edit';
    this.objectType = 'draft';
  }

  getTitle () {
    return i18n.t('campaign.labels.editCampaignDraftTitle');
  }

  async createDefaultCampaign () {
    return this.draftManager.getDraft(this.draftId);
  }

  getRtbCampaignSummaryStepModel (goLast, goStep) {
    if (this.campaignSummaryModel) {
      return this.campaignSummaryModel;
    }
    this.campaignSummaryModel = new RtbCampaignDraftSummaryStepModel(this.draftId, this, goLast, goStep, this.draftManager);
    return this.campaignSummaryModel;
  }

  onSaveDraft = async (campaign: RtbCampaign) => {
    this.updateState(true);
    try {
      await this.draftManager.updateDraft(this.draftId, this.manager.prepareCreateCampaignPayload(campaign));
      toast.success(i18n.t('campaignSetupFlow.messages.updateDraftSuccess'));
    } catch (e) {
      toast.error(i18n.t('campaignSetupFlow.messages.updateDraftFailed'));
    }
    this.updateState(false);
  }
}

import React, { Fragment, RefObject } from 'react';
import { Formik, Field } from 'formik';
import i18n from 'i18next';
import { AdvertiserMemberFormProps } from './AdvertiserMemberFormModel';
import FormGroup from 'components/common/FormGroup/FormGroup';
import { FormikSelect, FormikInput, FormikLabel } from 'components/common';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import styles from './advertiserMemberForm.module.scss';

export class AdvertiserMemberForm extends React.Component<AdvertiserMemberFormProps> {
  handler?: number;
  formikRef: RefObject<any>;
  formikEventHandler?: number;

  constructor (props) {
    super(props);
    this.renderFormContent = this.renderFormContent.bind(this);
    this.formikRef = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount () {
    this.handler = this.props.model.event.add(model => {
      this.setState(model.state);
    });
    this.formikEventHandler = this.props.model.formikEvent.add(event => {
      if (event.isSubmit === 'FORMIK_SUBMIT') {
        this.formikRef.current.submitForm();
      }
    });
    this.props.model.init();
  }

  componentWillUnmount () {
    this.props.model.event.remove(this.handler!);
    this.props.model.formikEvent.remove(this.formikEventHandler!);
  }

  handleSubmit (role) {
    this.props.model.submit(role);
  }

  renderFormContent () {
    const roleOptions = this.props.model.roleOptions;
    const emailComponent = this.props.model.emailEditable ? FormikInput : FormikLabel;
    return (
      <div className={styles.advertiserMemberForm}>
        <FormGroup controlId='email' label={i18n.t('advertiserMemberForm.labels.email')} valueColSm={4} labelColSm={2}>
          <Field
            name='email'
            component={emailComponent}
            type='email'
          />
        </FormGroup>
        <FormGroup controlId='role' label={i18n.t('advertiserMemberForm.labels.role')} valueColSm={4} labelColSm={2}>
          <Field
            name='role'
            component={FormikSelect}
            options={roleOptions}
            simpleValue
            className={styles.roleSelect}
          />
        </FormGroup>
      </div>
    );
  }

  render () {
    return (
      <Fragment>
        {this.props.model.state.loading && <LoadingIndicator />}
        <Formik
          ref={this.formikRef}
          initialValues={this.props.model.companyRole}
          enableReinitialize
          onSubmit={this.handleSubmit}
          validate={this.props.model.validate}
          render={this.renderFormContent}
          validateOnBlur={false}
        />
      </Fragment>
    );
  }
}

import _ from 'lodash';
import { CreativeListDataProvider } from './CreativeListDataProvider';
import { CreativeListCommonColumns, commonColumnSetting, getColumnSetting, getCommonColumnData } from './CreativeListCommonColumnData';
import { renderColumn } from 'components/TableColumn';
import { getCreativeListFormatters } from './CreativeListFormatters';
import { CreativeListRecord } from 'core/creative/Creative';

enum COLUMNS {
  AD_TITLE = 'adTitle',
  AD_DESC = 'adDesc',
  SPONSOR = 'sponsor'
}

export class NativeCreativeListDataProvider implements CreativeListDataProvider {

  formatters: any;
  constructor (model) {
    this.formatters = getCreativeListFormatters(model);
  }

  getColumns () {
    return [
      renderColumn(commonColumnSetting[CreativeListCommonColumns.NAME_ID], this.formatters.nameIdFormatter, this.formatters.nameIdHeaderFormatter),
      renderColumn(commonColumnSetting[CreativeListCommonColumns.PREVIEW], this.formatters.previewFormatter),
      renderColumn(commonColumnSetting[CreativeListCommonColumns.APPROVAL], this.formatters.approvalFormatter),
      renderColumn(getColumnSetting(COLUMNS.AD_TITLE), this.formatters.textFormatter),
      renderColumn(getColumnSetting(COLUMNS.AD_DESC), this.formatters.textFormatter),
      renderColumn(commonColumnSetting[CreativeListCommonColumns.LANDING_URL], this.formatters.urlFormatter),
      renderColumn(commonColumnSetting[CreativeListCommonColumns.BIND_STATE], this.formatters.bindStateFormatter),
      renderColumn(getColumnSetting(COLUMNS.SPONSOR), this.formatters.textFormatter),
      renderColumn(commonColumnSetting[CreativeListCommonColumns.LIMITATION], this.formatters.limitationFormatter),
      renderColumn(commonColumnSetting[CreativeListCommonColumns.EDITBTNS], this.formatters.floatingEditBtnsFormatter)
    ];
  }

  getViewModel (creative: CreativeListRecord) {
    return {
      ...getCommonColumnData(creative),
      [COLUMNS.AD_TITLE]: _.get(creative, 'creativeValues.title', ''),
      [COLUMNS.AD_DESC]: _.get(creative, 'creativeValues.desc', ''),
      [COLUMNS.SPONSOR]: _.get(creative, 'creativeValues.sponsored.name', '')
    };
  }
}

import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import copy from 'copy-to-clipboard';
import i18n from 'i18n';
import styles from './codePage.module.scss';
import { toast } from 'react-toastify';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { Trans } from 'react-i18next';

type CodePageProps = {
  codeSource: () => Promise<string>;
  id: string;
  i18nWordingPrefix: string;
};

type CodePageState = {
  loading: boolean;
  prettyCode: string;
};

export class CodePage extends React.PureComponent<CodePageProps, CodePageState> {

  constructor (props) {
    super(props);
    this.state = { loading: true, prettyCode: '' };
  }

  componentDidMount () {
    this.getCode();
  }

  async getCode () {
    const code = await this.props.codeSource();
    const prettyModule = await import(/* webpackChunkName: "pretty" */ 'pretty');
    const pretty = prettyModule.default;
    this.setState({ loading: false, prettyCode: pretty(code) });
  }

  copyCode = () => {
    copy(this.state.prettyCode);
    toast.success(i18n.t('codePage.labels.copyCodeSuccess'));
  }

  getPrivacyStatement = () => {
    const openPolicy = () => {
      window.open('https://www.tenmax.io/tw/privacy-policy', '_blank');
    };
    const privacyStatementElements = (
      <Trans i18nKey='codePage.labels.privacyStatement'>
        ...<span onClick={openPolicy}>...</span>...
      </Trans>
    );
    return privacyStatementElements;
  }

  render () {
    return (
      <div className={styles.codePage}>
        {this.state.loading && <LoadingIndicator/>}
        <Form.Group as={Row}>
          <Col sm={2}>
            <Form.Label column>{i18n.t(`${this.props.i18nWordingPrefix}.id`)}</Form.Label>
          </Col>
          <Col sm={10}>
            <Form.Label column>{this.props.id}</Form.Label>
          </Col>
        </Form.Group>
        <Form.Group className={styles.code} as={Row}>
          <Col sm={2}>
            <Form.Label column>{i18n.t(`${this.props.i18nWordingPrefix}.code`)}</Form.Label>
          </Col>
          <Col sm={10}>
            <textarea value={this.state.prettyCode} onClick={this.copyCode} readOnly/>
            <span className={styles.hint}>
              {i18n.t(`${this.props.i18nWordingPrefix}.codeHint`)}
            </span>
          </Col>
        </Form.Group>
        <Form.Group className={styles.privacy} as={Row}>
          <Col sm={2}>
            <Form.Label column>{i18n.t('codePage.labels.privacy')}</Form.Label>
          </Col>
          <Col sm={10}>
            <Form.Label column>{this.getPrivacyStatement()}</Form.Label>
          </Col>
        </Form.Group>
      </div>
    );
  }
}

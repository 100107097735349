export type DoctorRecords = {
  allSpaceChannels: SpaceChannel[],
  filterReasons: FilterReason[]
};

export type SpaceChannel = {
  domain: string,
  spaceName: string,
  spaceId: string
};

export type FilterReason = SpaceChannel & {
  type: FILTER_TYPE,
  bannerCategory: string,
  spaceBanCategory: string[]
};

export enum FILTER_TYPE {
  CATEGORY = 'TENMAX_CATEGORY',
  INTERSTITIAL = 'INTERSTITIAL'
}

import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import { connect, FormikContext } from 'formik';
import { NativeFormProps, MAX_ADTITLE_LENGTH, MAX_ADDESC_LENGTH, MAX_SPONSOR_LENGTH } from './NativeFormModel';
import styles from './nativeForm.module.scss';
import { NativeBannerPreview } from 'containers/Creatives/NativeBannerPreview/NativeBannerPreview';
import { FormConfig } from 'components/common/formik/FormikField';
import { FormikFormContent } from 'components/common/formik/FormikFormContent';
import { DefaultNativeBannerPreviewModel } from 'containers/Creatives/NativeBannerPreview/NativeBannerPreviewModel';
import { validateUrl } from 'utils/ValidateUtils';

export const NativeForm: React.FunctionComponent<NativeFormProps & { formik: FormikContext<any> }> = (props) => {

  const [formConfig, setFormConfig] = useState<FormConfig | undefined>();

  const {
    nativeToBannerTemplates,
    adDescriptionRemainLength,
    adTitleRemainLength,
    sponsorRemainLength,
    handleAdDescriptionChange,
    handleAdTitleChange,
    handleSponsorChange,
    validateRemainingCharacters,
    validateImage
  } = props.model;

  useEffect(() => {
    setFormConfig(new FormConfig.Builder()
      .addSection(
        new FormConfig.SectionBuilder(
          i18n.t('creativeSetupFlow.labels.creativeBasicInfo'),
          new FormConfig.FieldsBuilder([...props.basicFields])
            .addInput({
              label: i18n.t('creativeSetupFlow.labels.title'),
              name: 'typeProperties.title',
              hint: i18n.t('creativeSetupFlow.labels.remainingCharacters', { num: adTitleRemainLength }),
              onChange: handleAdTitleChange,
              validate: value => validateRemainingCharacters(value, MAX_ADTITLE_LENGTH)
            })
            .addInput({
              label: i18n.t('creativeSetupFlow.labels.desc'),
              name: 'typeProperties.desc',
              hint: i18n.t('creativeSetupFlow.labels.remainingCharacters', { num: adDescriptionRemainLength }),
              onChange: handleAdDescriptionChange,
              validate: value => validateRemainingCharacters(value, MAX_ADDESC_LENGTH)
            })
            .addInput({
              label: i18n.t('creativeSetupFlow.labels.sponsor'),
              name: 'typeProperties.sponsor',
              hint: i18n.t('creativeSetupFlow.labels.remainingCharacters', { num: sponsorRemainLength }),
              onChange: handleSponsorChange,
              validate: value => validateRemainingCharacters(value, MAX_SPONSOR_LENGTH)
            })
            .addUrlInput({
              label: i18n.t('creativeSetupFlow.labels.sponsorLink'),
              name: 'typeProperties.sponsorLink',
              validate: validateUrl
            })
            .addFileInput({
              type: 'image',
              className: styles.rectImageInput,
              label: i18n.t('creativeSetupFlow.labels.rectImg'),
              name: 'medias.rectImg',
              maxWidth: 382,
              maxHeight: 200,
              hints: [
                i18n.t('creativeSetupFlow.labels.typeHint'),
                i18n.t('creativeSetupFlow.labels.sizeHint', { size: '1200 x 627', proportion: '1.91:1' }),
                i18n.t('creativeSetupFlow.labels.storageHint', { storage: '2MB' })
              ],
              validate: value => validateImage(value, 1200, 627)
            })
            .addFileInput({
              type: 'image',
              className: styles.squareImageInput,
              label: i18n.t('creativeSetupFlow.labels.squareImg'),
              name: 'medias.squareImg',
              maxWidth: 200,
              maxHeight: 200,
              hints: [
                i18n.t('creativeSetupFlow.labels.typeHint'),
                i18n.t('creativeSetupFlow.labels.sizeHint', { size: '627 x 627', proportion: '1:1' }),
                i18n.t('creativeSetupFlow.labels.storageHint', { storage: '2MB' })
              ],
              validate: value => validateImage(value, 627, 627)
            })
            .build()
        )
        .build()
      )
      .addSection(
        new FormConfig.SectionBuilder(
          i18n.t('nativeForm.labels.nativeBannerTitle'),
          new FormConfig.FieldsBuilder()
          .addSwitch({
            label: i18n.t('nativeForm.labels.enableNativeBanner'),
            name: 'enableNativeBanner',
            disabled: props.formik.values.creativeId !== undefined,
            hoverHint: i18n.t('nativeForm.labels.cannotModifyHint')
          })
          .addCustom({
            asField: false,
            name: 'preview',
            render: () => (
              <div className={styles.nativeBannerPreviewArea}>
                {<NativeBannerPreview model={new DefaultNativeBannerPreviewModel(props.formik.values, nativeToBannerTemplates)}/>}
              </div>
            )
          }, !props.formik.values.enableNativeBanner)
          .build()
        )
        .build()
      )
      .build()
    );
  }, [
    props.basicFields,
    props.formik.values,
    nativeToBannerTemplates,
    adDescriptionRemainLength,
    adTitleRemainLength,
    sponsorRemainLength,
    handleAdDescriptionChange,
    handleAdTitleChange,
    handleSponsorChange,
    validateRemainingCharacters,
    validateImage,
    setFormConfig
  ]);

  return (
    <FormikFormContent
      formConfig={formConfig}
    />
  );
};

export default connect(NativeForm);

import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';

import { CreativeOfCampaign } from 'core/creative/Creative';

export interface CreativeCardModel {
  readonly creative: CreativeOfCampaign;
  readonly state: CreativeCardState;
  readonly event: UpdateEventListener<CreativeCardModel>;
  handleOnClick (): void;
}

export type CreativeCardProps = {
  readonly model: CreativeCardModel;
};

export type CreativeCardState = {
  readonly selected: boolean;
};

export class DefaultCreativeCardModel implements CreativeCardModel {
  event: FireableUpdateEventListener<CreativeCardModel>;
  selected: boolean;
  creative: CreativeOfCampaign;
  onSelectCallback: any;

  constructor (creative: CreativeOfCampaign, selected: boolean, onSelectCallback: any) {
    this.creative = creative;
    this.event = new FireableUpdateEventListener<CreativeCardModel>();
    this.selected = selected;
    this.onSelectCallback = onSelectCallback;
  }

  get state (): CreativeCardState {
    return {
      selected: this.selected
    };
  }

  handleOnClick = () => {
    this.selected = !this.selected;
    this.onSelectCallback(this.creative.id);
    this.updateState();
  }

  updateState () {
    this.event.fireEvent(this);
  }
}

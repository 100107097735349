import React from 'react';
import { faChartArea, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import TableRowToolBar from 'containers/TableRowToolBar/TableRowToolBar';
import { Link } from 'react-router-dom';
import styles from './campaignGroupList.module.scss';
import i18n from 'i18n';
import _ from 'lodash';
import { CampaignGroup, CampaignGroupChannel, FbObjective, TiktokOptStatus, TiktokStatus, TiktokStatusDetail } from 'core/campaignGroup/CampaignGroup';
import { Status, getEffectiveStatusDefaultColor } from 'components/Status/Status';
import { CampaignState } from 'core/rtbCampaign/RtbCampaign';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { RoleNames } from 'core/auth/Account';
import { hasFuncs, notRoles } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';

const getCampaignGroupStatusDesData = (campaignGroup) => {
  let des = '';
  let color;
  switch (campaignGroup.state) {
    case CampaignState.DEACTIVATE:
      des = i18n.t('campaignList.labels.deactivateState');
      color = 'black';
      break;
    case CampaignState.DELETE:
      des = i18n.t('campaignList.labels.deleteState');
      color = 'danger';
      break;
    case CampaignState.ACTIVATE:
      des = i18n.t('campaignList.labels.activateState');
      break;
    default:
      des = i18n.t('campaignList.labels.stoppingState');
      color = 'whiteTheme4';
      break;
  }

  return {
    des,
    color
  };
};

const getFbCampaignStatusDesData = (campaignGroup) => {
  const configuredStatus = _.get(campaignGroup, 'fb.configured_status', '');
  let des = _.startCase(_.lowerCase(configuredStatus));
  let color = getEffectiveStatusDefaultColor(configuredStatus);
  switch (configuredStatus) {
    case 'PAUSED':
      des = i18n.t('campaignList.labels.deactivateState');
      color = 'black';
      break;
    case 'DELETED':
    case 'ARCHIVED':
      des = i18n.t('campaignList.labels.deleteState');
      color = 'danger';
      break;
    case 'ACTIVE':
      des = i18n.t('campaignList.labels.activateState');
      break;
    default:
      break;
  }

  return {
    des,
    color
  };
};

const getCampaignGroupEffectiveStatusDesData = (campaignGroup) => {
  const effectiveStatus = _.get(campaignGroup, 'fb.effective_status', '');
  let des = _.startCase(_.lowerCase(effectiveStatus));
  let color = getEffectiveStatusDefaultColor(effectiveStatus);
  switch (effectiveStatus) {
    case 'PAUSED':
      if (campaignGroup.budgetBalance > 0) {
        des = i18n.t('campaignList.labels.budgetRemainState');
        color = 'danger';
      } else {
        des = i18n.t('campaignList.labels.deactivateState');
        color = 'black';
      }
      break;
    case 'DELETED':
    case 'ARCHIVED':
      des = i18n.t('campaignList.labels.deleteState');
      color = 'danger';
      break;
    case 'ACTIVE':
      des = i18n.t('campaignList.labels.activateState');
      break;
    default:
      break;
  }

  return {
    des,
    color
  };
};

const getTiktokCampaignStatusDesData = (campaignGroup) => {
  const optStatus = _.get(campaignGroup, 'tiktok.opt_status', '');
  let des = _.startCase(_.lowerCase(optStatus));
  let color = getEffectiveStatusDefaultColor(optStatus);
  switch (optStatus) {
    case TiktokOptStatus.DISABLE:
      des = i18n.t('campaignList.labels.deactivateState');
      color = 'black';
      break;
    case TiktokOptStatus.DELETED:
      des = i18n.t('campaignList.labels.deleteState');
      color = 'danger';
      break;
    case TiktokOptStatus.ENABLE:
      des = i18n.t('campaignList.labels.activateState');
      break;
    default:
      break;
  }

  return {
    des,
    color
  };
};

const getTiktokCampaignDeliveryStatusDesData = (campaignGroup) => {
  const status = _.get(campaignGroup, 'tiktok.status', '');
  let des = _.startCase(_.lowerCase(status));
  let color = getEffectiveStatusDefaultColor(status);
  switch (status) {
    case TiktokStatus.CAMPAIGN_STATUS_ADVERTISER_AUDIT_DENY:
    case TiktokStatus.CAMPAIGN_STATUS_ADVERTISER_AUDIT:
    case TiktokStatus.ADVERTISER_CONTRACT_PENDING:
      des = i18n.t('campaignList.labels.withIssues');
      color = 'danger';
      break;
    case TiktokStatus.CAMPAIGN_STATUS_BUDGET_EXCEED:
    case TiktokStatus.CAMPAIGN_STATUS_DISABLE:
      des = i18n.t('campaignList.labels.deactivateState');
      color = 'black';
      break;
    case TiktokStatus.CAMPAIGN_STATUS_DELETE:
      des = i18n.t('campaignList.labels.deleteState');
      color = 'danger';
      break;
    case TiktokStatus.CAMPAIGN_STATUS_ENABLE:
      des = i18n.t('campaignList.labels.activateState');
      break;
    default:
      break;
  }

  return {
    des,
    color
  };
};

const getTiktokStatusTooltip = (campaignGroup) => {
  const status = _.get(campaignGroup, 'tiktok.status', '');
  return status ? `${TiktokStatusDetail[status]}` : undefined;
};

const nameHeaderFormatter = (
  campaignGroupLength: number,
  selectedCampaignGroups: (number | string)[],
  onSelectAll,
  column: any,
  _1,
  { sortElement }
) => {
  return (
    <div className={styles.nameHeader}>
      <PermissionChecker permissionAware={hasFuncs(Permission.CAMPAIGN_WRITE)}>
        <div className={styles.selectCheckBox}>
          <input
            type='checkbox'
            checked={campaignGroupLength === selectedCampaignGroups.length}
            ref={el => el && (el.indeterminate = campaignGroupLength !== selectedCampaignGroups.length && selectedCampaignGroups.length > 0)}
            onChange={onSelectAll}
            id={'input0'}
          />
          <label htmlFor='input0' />
        </div>
      </PermissionChecker>
      {i18n.t(column.text)}
      {sortElement}
    </div>
  );
};

const nameFormatter = (groupId: any, campaignGroup, _2, formatExtraData: any): any => {
  const { selectedCampaignGroups, onSelect } = formatExtraData;
  const campaignGroupDetailReadPermission = notRoles(RoleNames.agencyReport);
  const renderLabel = () => <span className={styles.name}>{campaignGroup.name}</span>;
  return (
    <div className={styles.nameCell}>
      <div className={styles.selectCheckBox}>
        <PermissionChecker permissionAware={hasFuncs(Permission.CAMPAIGN_WRITE)}>
          <input
            type='checkbox'
            checked={selectedCampaignGroups.indexOf(groupId.toString()) > -1}
            onChange={_.partial(onSelect, groupId.toString())}
            id={`input${groupId}`}
          />
          <label htmlFor={`input${groupId}`} />
        </PermissionChecker>
      </div>
      <div className={styles.info}>
        <PermissionChecker permissionAware={campaignGroupDetailReadPermission} renderOtherwise={renderLabel}>
          <Link to={`${formatExtraData.currentUrl}/campaign-groups/${groupId}`} className={styles.name}>
            {campaignGroup.name}
          </Link>
        </PermissionChecker>
        <div className={styles.id}>
          {'ID: ' + groupId}
        </div>
        {campaignGroup.drafts > 0 &&
          <div className={styles.draftsInfo}>
            {i18n.t('campaignGroupList.labels.drafts', { count: campaignGroup.drafts })}
          </div>
        }
      </div>
    </div>
  );
};

const stateFormatter = (state, campaignGroup: CampaignGroup) => {
  const channelStatusDesMap = {
    [CampaignGroupChannel.RTB]: () => getCampaignGroupStatusDesData(campaignGroup),
    [CampaignGroupChannel.RETAIL_MEDIA]: () => getCampaignGroupStatusDesData(campaignGroup),
    [CampaignGroupChannel.FB]: () => getFbCampaignStatusDesData(campaignGroup),
    [CampaignGroupChannel.TIKTOK]: () => getTiktokCampaignStatusDesData(campaignGroup)
  };
  const stateData = channelStatusDesMap[campaignGroup.channel] ? channelStatusDesMap[campaignGroup.channel]() : {
    des: state,
    color: 'black'
  };

  return (
    <Status
      label={stateData.des}
      color={stateData.color}
    />
  );
};

const deliveryFormatter = (state, campaignGroup: CampaignGroup) => {
  const channelStatusDesMap = {
    [CampaignGroupChannel.RTB]: () => getCampaignGroupStatusDesData(campaignGroup),
    [CampaignGroupChannel.RETAIL_MEDIA]: () => getCampaignGroupStatusDesData(campaignGroup),
    [CampaignGroupChannel.FB]: () => getCampaignGroupEffectiveStatusDesData(campaignGroup),
    [CampaignGroupChannel.TIKTOK]: () => getTiktokCampaignDeliveryStatusDesData(campaignGroup)
  };
  const stateData = channelStatusDesMap[campaignGroup.channel] ? channelStatusDesMap[campaignGroup.channel]() : {
    des: state,
    color: 'black'
  };
  const tiktokTooltip = campaignGroup.channel === CampaignGroupChannel.TIKTOK ?
    getTiktokStatusTooltip(campaignGroup) : undefined;

  const hasExtraInfo = _.get(campaignGroup, 'fb.issues_info') || tiktokTooltip;
  const renderExtraInfo = hasExtraInfo ? () => {
    return _.get(campaignGroup, 'fb.issues_info') ?
      _.get(campaignGroup, 'fb.issues_info.error_summary') :
      tiktokTooltip;
  } : undefined;
  return (
    <Status
      label={stateData.des}
      color={stateData.color}
      renderExtraInfo={renderExtraInfo}
    />
  );
};

const resultsFormatter = (value, campaignGroup: CampaignGroup) => {
  const channelResultsFormatterMap = {
    [CampaignGroupChannel.RTB]: rtbResultsFormatter,
    [CampaignGroupChannel.RETAIL_MEDIA]: rtbResultsFormatter,
    [CampaignGroupChannel.FB]: fbResultsFormatter,
    [CampaignGroupChannel.TIKTOK]: tiktokResultsFormatter
  };
  const formatter = channelResultsFormatterMap[campaignGroup.channel];
  if (!formatter) {
    return <div/>;
  }
  return formatter(value, campaignGroup);
};

const rtbResultsFormatter = (value, campaignGroup: CampaignGroup) => {
  const rtbObjective = _.get(campaignGroup, 'objective');
  return (
    <div className={styles.resultCell}>
      <div className={styles.value}>
        {value === undefined ? 0 : value}
      </div>
      <div className={styles.objective}>
        {_.startCase(_.lowerCase(rtbObjective))}
      </div>
    </div>
  );
};

const fbResultsFormatter = (value, campaignGroup: CampaignGroup) => {
  const fbObjective = _.get(campaignGroup, 'fb.objective');
  return (
    <div className={styles.resultCell}>
      <div className={styles.value}>
        {
          fbObjective === FbObjective.OUTCOME_SALES ?
            '—' :
            value === undefined ? 0 : value
        }
      </div>
      <div className={styles.objective}>
        {_.startCase(_.lowerCase(fbObjective))}
      </div>
    </div>
  );
};

const tiktokResultsFormatter = () => {
  return (
    <div className={styles.resultCell}>
      <div className={styles.value}>
        —
      </div>
    </div>
  );
};

const floatingEditBtnsFormatter = (_1, campaignGroup, _2, formatExtraData: any): any => {
  // const onDeleteBtnClick = () => formatExtraData.onDeleteBtnClick(campaignGroup.groupId);
  // const canDelete = _.get(campaignGroup, 'campaignAmount', 0) === 0;
  const reportUrl = `/reports/performance?dimension=groupId&groupId=${campaignGroup.groupId}`;
  return (
    <TableRowToolBar className={styles.floatingEditArea}>
      <PermissionChecker permissionAware={hasFuncs(Permission.CAMPAIGN_WRITE)}>
        <Link
          to={`${formatExtraData.currentUrl}/campaign-groups/${campaignGroup.groupId}/edit`}
        >
          <IconWithTooltip
            icon={faPencilAlt}
            tooltipProps={{
              id: `campaignGroupList-editTip-${campaignGroup.groupId}`,
              tooltip: i18n.t('campaignGroup.labels.editTitle')
            }}
          />
        </Link>
      </PermissionChecker>
      {/* <PermissionChecker permissionAware={hasFuncs(Permission.CAMPAIGN_DEL)}>
        <IconWithTooltip
          disabled={!canDelete}
          icon={faTrashAlt}
          onClick={onDeleteBtnClick}
          tooltipProps={{
            id: `campaignGroupList-deleteTip-${campaignGroup.groupId}`,
            tooltip: canDelete ? i18n.t('campaignGroup.labels.deleteTitle') : i18n.t('campaignGroup.labels.cannotDeleteTitle')
          }}
        />
      </PermissionChecker> */}
      <PermissionChecker permissionAware={hasFuncs(Permission.REPORT_ADS)}>
        <Link to={reportUrl}>
          <IconWithTooltip
            icon={faChartArea}
            tooltipProps={{
              id: `campaignListReportTip-${campaignGroup.groupId}`,
              tooltip: i18n.t('campaignList.labels.reportHint')
            }}
          />
        </Link>
      </PermissionChecker>
    </TableRowToolBar>
  );
};

const channelFormatter = (_1, campaginGroup: CampaignGroup) => {
  const channel = _.get(campaginGroup, 'channel');

  return (
    <div>{i18n.t(`campaignGroup.labels.channel_${channel.toLowerCase()}`)}</div>
  );
};

export default {
  nameHeaderFormatter,
  nameFormatter,
  stateFormatter,
  deliveryFormatter,
  floatingEditBtnsFormatter,
  resultsFormatter,
  channelFormatter
};

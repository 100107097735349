import { useCallback, useEffect, useMemo, useState } from 'react';
import DefaultCampaignGroupManager, { CampaignGroupManager } from 'core/campaignGroup/CampaignGroupManager';
import { CampaignGroup } from 'core/campaignGroup/CampaignGroup';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { Order } from 'core/order/Order';
import { AddonFeatureManager } from 'core';
import i18n from 'i18n';
import { formatPriceWithCurrency } from 'helper/CurrencyHelper';
import { DefaultStateModalModel, StateModalModel } from 'containers/Common/StateModalModel';
import _ from 'lodash';
import { useCallAPI } from 'hooks/useCallAPI';
import { CampaignGroupChannelDetailModel, CampaignGroupComponentsData, getCampaignGroupChannelDetailModel } from './CampaignGroupChannelDetailModel';

export type CampaignGroupDetailModelData = {
  viewData: any;
  loading: boolean;
  currentUrl: string;
  campaignGroup?: CampaignGroup;
  creativeManageModal?: StateModalModel;
  redirectPath?: string;
  componentsData?: CampaignGroupComponentsData;
  goCampaignList: any[];
};

const defaultCampaignGroupManager = new DefaultCampaignGroupManager();

export const useCampaignGroupDetailModel = (
  groupId: number | string,
  order: Order,
  addonFeatureManager: AddonFeatureManager,
  campaignGroupManager: CampaignGroupManager = defaultCampaignGroupManager
): CampaignGroupDetailModelData => {

  const [campaignGroup, setCampaignGroup] = useState<CampaignGroup | undefined>(undefined);
  const [redirectPath, setRedirectPath] = useState<string | undefined>(undefined);
  const {
    loading,
    callAPIs
  } = useCallAPI();
  const [channelDetailModel, setChannelDetailModel] = useState<CampaignGroupChannelDetailModel | undefined>();

  const match = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(location.search);
  const searchString = query.get('search');

  const fetchCampaignGroup = useCallback(async () => {
    callAPIs(
      [campaignGroupManager.getCampaignGroup.bind(campaignGroupManager, groupId)],
      async (campaignGroup) => {
        const channelDetailModel = getCampaignGroupChannelDetailModel(
          order,
          campaignGroup,
          addonFeatureManager,
          fetchCampaignGroup
        );
        if (campaignGroup.adsOrderID !== order.id || !channelDetailModel) {
          const redirectPath =
            `/orders/${order.orderNumber}/campaign-groups/${campaignGroup.groupId}/error404`;
          setRedirectPath(redirectPath);
          return;
        }
        setCampaignGroup(campaignGroup);

        callAPIs(
          [channelDetailModel.initChannelModel.bind(channelDetailModel, searchString)],
          () => setChannelDetailModel(channelDetailModel)
        );
      }
    );
  }, [
    callAPIs,
    searchString,
    campaignGroupManager,
    groupId,
    order,
    addonFeatureManager
  ]);

  useEffect(() => {
    if (!match.isExact && campaignGroup === undefined) {
      fetchCampaignGroup();
    }
  }, [campaignGroup, fetchCampaignGroup, location.pathname, match.isExact]);

  useEffect(() => {
    match.isExact && fetchCampaignGroup();
  }, [fetchCampaignGroup, match.isExact]);

  const creativeManageModal = useMemo(() => {
    if (!channelDetailModel) {
      return;
    }
    const query = new URLSearchParams(location.search);
    const draftIdsParam = query.get('draftIds');
    const campaignIdsParam = query.get('campaignIds');
    const actionParam = query.get('action');
    if (!actionParam || (!campaignIdsParam && !draftIdsParam) || !campaignGroup) {
      return;
    }
    const campaignIds = campaignIdsParam ? campaignIdsParam.split(',') : [];
    const draftIds = draftIdsParam ? draftIdsParam.split(',') : [];
    const factoryModel = channelDetailModel.getCreativeManagementStateFactory(campaignIds, draftIds, actionParam, history);
    if (!factoryModel) {
      return;
    }
    return new DefaultStateModalModel(
      factoryModel,
      (dirty) => {
        history.replace(location.pathname);
        dirty && fetchCampaignGroup();
      }
    );
  }, [location.search, location.pathname, fetchCampaignGroup, history, campaignGroup, channelDetailModel]);

  const viewData = useMemo(() => {
    if (!campaignGroup || !channelDetailModel) {
      return;
    }

    return _.omitBy({
      basic: _.compact([
        {
          label: i18n.t('campaignGroup.labels.name'),
          value: campaignGroup.name
        }, {
          label: i18n.t('campaignGroup.labels.channel'),
          value: i18n.t(`campaignGroup.labels.channel_${campaignGroup.channel.toLowerCase()}`)
        }, {
          label: i18n.t('campaignGroup.labels.budget'),
          value: campaignGroup.budget === undefined || campaignGroup.budget === null ?
            i18n.t('common.labels.noData') :
            formatPriceWithCurrency(order.currency, +campaignGroup.budget)
        }, campaignGroup.autoOptimise ? undefined : {
          label: i18n.t('campaignGroup.labels.budgetBalance'),
          value: formatPriceWithCurrency(order.currency, campaignGroup.budgetBalance)
        }, {
          label: i18n.t('campaignGroup.labels.autoOptimise'),
          value: campaignGroup.autoOptimise ? i18n.t('common.labels.yes') : i18n.t('common.labels.no')
        }
      ]),
      channelData: channelDetailModel.getCampaignGroupChannelViewData()
    }, _.isUndefined);
  }, [campaignGroup, order.currency, channelDetailModel]);

  return {
    loading,
    campaignGroup,
    currentUrl: match.url,
    viewData,
    creativeManageModal,
    redirectPath,
    componentsData: channelDetailModel ?
      channelDetailModel.componentsData :
      undefined,
    goCampaignList: _.get(channelDetailModel, 'goCampaignList', [])
  };
};

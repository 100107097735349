import React from 'react';
import _ from 'lodash';
import styles from './advertiserDetail.module.scss';

export const renderOfficialWebsite = website => (
  <a
    href={encodeURI(website)}
    target='_blank'
    rel='noopener noreferrer'
  >
    {website}
  </a>
);

export const renderFBPage = (fbPageOptions, fbPageIds) => {
  const pages = fbPageIds.map(fbPageId => {
    const label = _.get(fbPageOptions.find(page => page.value.toString() === fbPageId), 'label', '');
    return (
      <span className={styles.fbPageOption} key={fbPageId}>
        <img
          className={styles.fbPageOptionIcon}
          src={`https://graph.facebook.com/${fbPageId}/picture/?type=square`}
          alt=''
        />
        {label}
      </span>
    );
  });
  return (
    <>
      {pages}
    </>
  );
};

import React from 'react';
import { StepPage } from 'containers/StepPage/StepPage';
import { Redirect } from 'react-router-dom';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import NavigationPrompt from 'components/common/NavigationPrompt/NavigationPrompt';
import styles from './fbAdSetSetupFlowPage.module.scss';
import i18n from 'i18n';
import { Order } from 'core/order/Order';
import { CampaignGroup } from 'core/campaignGroup/CampaignGroup';
import { AppFooter } from 'components/AppFooter';
import { FbAdSetMainStep } from './steps/FbAdSetMainStep';
import { FbAdSetSummaryStep } from './steps/FbAdSetSummaryStep';
import { AdSetSetupFlowPageModelData } from './FbAdSetSetupFlowPageModel';
import { FbAdSet } from 'core/fbAdSet/FbAdSet';
import { TitleWithBreadcrumbs } from 'components/TitleArea/TitleWithBreadcrumbs';
import { FbAdSetSetupFlowContext } from './FbAdSetSetupFlowContext';
import { SavedTAManagement } from 'containers/Limitations/SavedTAManagement/SavedTAManagement';
import _ from 'lodash';
import { alertMessage } from 'components/AlertDialog';

export const FbAdSetSetupFlowPage: React.FC<{
  order: Order,
  campaignGroup: CampaignGroup,
  fbAdSetList: FbAdSet[],
  useModel: (order: Order, CampaignGroup: CampaignGroup, fbAdSetList: FbAdSet[]) => AdSetSetupFlowPageModelData
}> = ({
  order,
  campaignGroup,
  fbAdSetList,
  useModel
}) => {

  const modelData = useModel(order, campaignGroup, fbAdSetList);
  const {
    title,
    adSet,
    initAdSet,
    loading,
    finished,
    redirectPath,
    breadcrumbs,
    showTAManagement,
    savedTAList,
    showPublishBindingFailed,
    setFinished,
    getTASummaryData,
    backToCampaignGroupDetail,
    setRedirectPath,
    onImportSavedTA,
    onDeleteSavedTA,
    setShowTAManagement
  } = modelData;

  const onPromptCancel = () => {
    setRedirectPath(undefined);
  };

  const renderTitle = () => {
    return (
      <div className={styles.topArea}>
        <div className={styles.title}>
          <TitleWithBreadcrumbs
            title={title}
            routes={breadcrumbs}
          />
        </div>
      </div>
    );
  };

  const renderMainStep = (stepPageContext) => {
    if (!adSet || !initAdSet) {
      return <div/>;
    }
    return (
      <>
        {renderTitle()}
        <div className={styles.contentContainer}>
          <FbAdSetMainStep
            {...stepPageContext}
          />
        </div>
      </>
    );
  };

  const renderSummaryStep = (stepPageContext) => {
    return (
      <>
        {renderTitle()}
        <div className={styles.contentContainer}>
          <FbAdSetSummaryStep
            {...stepPageContext}
          />
        </div>
      </>
    );
  };

  const toCampaignGroupDetail = () => {
    setFinished(true);
    backToCampaignGroupDetail();
  };

  if (!adSet) {
    return <LoadingIndicator />;
  }

  return (
    <FbAdSetSetupFlowContext.Provider
      value={{
        ...modelData,
        order,
        campaignGroup,
        adSet
      }}
    >
      <div className={styles.adSetSetupFlowPages}>
        {showPublishBindingFailed && alertMessage(
          i18n.t('common.warning'), i18n.t('adSetSetupFlow.messages.publishBindingFailed'), toCampaignGroupDetail
        )}
        {loading && <LoadingIndicator/>}
        {showTAManagement &&
          <SavedTAManagement
            onImport={onImportSavedTA}
            onDelete={onDeleteSavedTA}
            onClose={_.partial(setShowTAManagement, false)}
            getSummaryData={getTASummaryData}
            savedTAList={savedTAList}
          />
        }
        <NavigationPrompt when={!finished} onCancel={onPromptCancel}/>
        <StepPage cancel={backToCampaignGroupDetail}>
          <StepPage.Step
            label={i18n.t('adSetSetupFlow.sidebar.basic')}
            renderStepContent={renderMainStep}
          >
            <StepPage.SubStep label={i18n.t('stepSideBar.labels.basic')}/>
            <StepPage.SubStep label={i18n.t('stepSideBar.labels.targeting')}/>
          </StepPage.Step>
          <StepPage.Step
            label={i18n.t('adSetSetupFlow.sidebar.summary')}
            renderStepContent={renderSummaryStep}
          />
          <StepPage.SideBarFooter>
            <AppFooter/>
          </StepPage.SideBarFooter>
        </StepPage>
        {redirectPath && <Redirect to={redirectPath} />}
      </div>
    </FbAdSetSetupFlowContext.Provider>
  );
};

import client from './RestClient';
import { FbAdSetFormData } from 'containers/FbAdSets/FbAdSetSetupFlowPage/FbAdSetSetupFlowPageModel';
import { ADSET_DEFAULT_AGE_MAX, ADSET_DEFAULT_AGE_MIN, FbAdSet } from 'core/fbAdSet/FbAdSet';
import { CampaignGroup } from 'core/campaignGroup/CampaignGroup';
import _ from 'lodash';
import { SelectOptions } from 'components/common/commonType';
import { getInitDaypart } from 'components/Dayparts/Dayparts';
import { createSelectOptions } from 'utils/SelectOptionsUtils';
import moment from 'moment';

export interface FbAdSetWebService {
  getAdSet (adSetId: number | string): Promise<FbAdSet>;
  getAdSets (groupId: number | string): Promise<FbAdSet[]>;
  createAdSet (payload: any): Promise<void>;
  editAdSet (orderId: string | number, group: CampaignGroup, fbAdSetId: string | number, fbAdSetFormData: FbAdSetFormData): Promise<void>;
  deleteAdSet (adSetId: number | string): Promise<void>;
  updateAdSetState (adSetsData: {
    goCampaignChannelId: (number | string),
    isDraft: boolean
  }[], state: 'activate' | 'deactivate'): Promise<void>;
  getMinBudget (adSetId: number | string): Promise<number>;
  getAdSetOptions (): Promise<SelectOptions[]>;
}

export const wrapAdSet = (json) => {
  const {
    draft,
    adset_schedule,
    ...parsedJSON
  } = json;
  const removeTimezone = (time) => (
    moment(time.replace('T', ' ').split('+')[0]).format('YYYY-MM-DD HH:mm:ss')
  );
  const wrapAdSetSchedule = (adsetSchedules: any) => {
    const adsetSchedule = !Array.isArray(adsetSchedules) ? JSON.parse(adsetSchedules) : adsetSchedules;
    const durationKeys = _.uniqWith(_.map(adsetSchedule, schedule => _.omit(schedule, ['days', 'timezone_type'])), _.isEqual);
    if (adsetSchedule.length !== durationKeys.length) {
      return getInitDaypart();
    }
    let dayPart = {};
    _.forEach(adsetSchedule, (schedule) => {
      const { start_minute, end_minute, days } = schedule;
      const hours = Array.from({ length: (end_minute - start_minute) / 60 }, (_, index) => index + start_minute / 60);
      _.forEach(days, (day) => {
        const dayKey = day === 0 ? (day + 7).toString() : (day).toString();
        dayPart[dayKey] = dayPart[dayKey] ? [...dayPart[dayKey], ...hours] : hours;
      });
    });
    return {
      ...dayPart,
      enabled: '1'
    };
  };

  return {
    ...parsedJSON,
    targeting: wrapTargetingFromServer(json.targeting),
    lifetime_min_spend_target: json.go_lifetime_min_spend_target,
    lifetime_spend_cap: json.go_lifetime_spend_cap,
    lifetime_budget: json.go_lifetime_budget,
    bid_amount: json.go_bid_amount,
    start_time: json.start_time ?
      removeTimezone(json.start_time) :
      json.start_time,
    end_time: json.end_time ?
      removeTimezone(json.end_time) :
      json.end_time,
    frequency_control_specs: json.frequency_control_specs ?
      json.frequency_control_specs[0] : undefined,
    destination_type: json.destination_type,
    dayPart: (!_.isEmpty(adset_schedule) && adset_schedule !== '[]') ? wrapAdSetSchedule(adset_schedule) : undefined,
    isDraft: draft
  };
};

export const wrapFbDayparting = (fbAdSetFormData): string => {
  const dayPart: { [day: string]: number[] } | undefined = _.omit(fbAdSetFormData.dayPart, ['enabled']);
  let mapping = {};
  _.forEach(Object.keys(dayPart), (dayKey) => {
    // Partition into minimal blocks of consecutive hours, and store as mapping key
    let day: number[] = dayPart[dayKey].sort((hour1, hour2) => hour1 > hour2 ? 1 : -1);
    let blockStart = day.length > 0 ? day[0] : 0;
    let blockEnd = blockStart;
    !_.isEmpty(day) && _.forEach(day, (hour: number, index: number) => {
      if (!_.isNil(day[index + 1]) && day[index + 1] === hour + 1) {
        blockEnd = day[index + 1];
      } else {
        let durationKey = JSON.stringify({
          start_minute: blockStart * 60,
          end_minute: (blockEnd + 1) * 60
        });
        mapping[durationKey] = mapping[durationKey] ? [
          ...mapping[durationKey],
          parseFloat(dayKey) % 7
        ] : [parseFloat(dayKey) % 7];
        blockStart = day[index + 1];
        blockEnd = day[index + 1];
      }
    });
  });
  // Restruct mapping into list of valid mapping structures
  let adsetSchedules = _.map(Object.keys(mapping), (durationKey) => ({
    ...JSON.parse(durationKey),
    days: mapping[durationKey],
    timezone_type: 'USER'
  }));
  if (_.get(fbAdSetFormData.dayPart, 'enabled') !== '1' && adsetSchedules.length === 1) {
    const schedule = _.omit(adsetSchedules[0], ['days']);
    adsetSchedules = [
      { ...schedule, days: [0,6] },
      { ...schedule, days: Array.from({ length: 5 }, (_, index) => index + 1) }
    ];
  }
  return JSON.stringify(adsetSchedules);
};

const wrapTargetingFromServer = (json: any) => {
  const { geo_locations, excluded_geo_locations, publisher_platforms, custom_audiences, excluded_custom_audiences, user_device, genders, age_min, age_max, ...others } = json;
  const fbDeviceTAMap = {
    iPhone: 'Smartphone',
    iPad: 'Tablet',
    Android_Smartphone: 'Smartphone',
    Android_Tablet: 'Tablet'
  };
  const deviceTypes = user_device ? _.uniq(user_device.map(device => fbDeviceTAMap[device])) : [];
  const getGeoLocationValue = (locationTA) => {
    const countries = _.get(locationTA, 'countries', []);
    const cities = _.get(locationTA, 'cities', []);
    const regions = _.get(locationTA, 'regions', []);
    return _.concat(
      countries.map(country => ({ label: country, value: country })),
      cities.map(city => ({ label: city.name, value: city.key })),
      regions.map(region => ({ label: region.name, value: region.key }))
    );
  };

  const geoLocationValue = getGeoLocationValue(geo_locations);
  const include = _.compact([
    publisher_platforms ? {
      type: 'publisher_platforms',
      value: createSelectOptions(
        publisher_platforms,
        'targeting.fb.publisher_platforms.',
        value => value.toLowerCase()
      )
    } : undefined,
    geoLocationValue.length > 0 ? {
      type: 'geo_locations',
      value: geoLocationValue
    } : undefined,
    custom_audiences && custom_audiences.length > 0 ? {
      type: 'segment',
      value: createSelectOptions(custom_audiences.map(audience => audience.id))
    } : undefined,
    deviceTypes.length > 0 ? {
      type: 'user_device',
      value: deviceTypes.map(deviceType => ({
        label: deviceType,
        value: deviceType
      }))
    } : undefined,
    genders && genders.length > 0 ? {
      type: 'genders',
      value: genders[0]
    } : undefined,
    {
      type: 'age_min',
      value: age_min ? age_min : ADSET_DEFAULT_AGE_MIN
    },
    {
      type: 'age_max',
      value: age_max ? age_max : ADSET_DEFAULT_AGE_MAX
    },
    ...Object.keys(others).map(key => ({
      type: key,
      value: Array.isArray(json[key]) ? json[key].map(value => ({ label: value, value })) : json[key]
    }))
  ]);

  const excludedGeoLocationValue = getGeoLocationValue(excluded_geo_locations);
  const exclude = _.compact([
    excludedGeoLocationValue.length > 0 ? {
      type: 'geo_locations',
      value: excludedGeoLocationValue
    } : undefined,
    excluded_custom_audiences && excluded_custom_audiences.length > 0 ? {
      type: 'segment',
      value: createSelectOptions(excluded_custom_audiences.map(audience => audience.id))
    } : undefined
  ]);

  return {
    include,
    exclude
  };
};

export class RestfulFbAdSetWebService implements FbAdSetWebService {
  restClient: any;

  constructor (restClient: any = client) {
    this.restClient = restClient;
  }

  async getAdSet (adSetId: number | string): Promise<FbAdSet> {
    const response = await this.restClient.get(`/v2/fb/campaigns/${adSetId}`);
    return wrapAdSet(response.data);
  }

  async getAdSets (groupId: number | string): Promise<FbAdSet[]> {
    const response = await this.restClient.get(`/v2/fb/campaigns/group/${groupId}`);
    return _.get(response, 'data', []).map(data => wrapAdSet(data));
  }

  async createAdSet (payload: any): Promise<void> {
    return this.restClient.post('/v2/fb/campaigns', payload);
  }

  async editAdSet (orderId: string | number, group: CampaignGroup, adSetId: number | string, fbAdSetFormData: FbAdSetFormData): Promise<void> {
    return this.restClient.put('/v2/fb/campaigns', {
      ..._.omit(fbAdSetFormData, ['lifetime_budget', 'bid_amount', 'hasSpendLimits', 'lifetime_min_spend_target', 'lifetime_spend_cap']),
      id: adSetId,
      orderId,
      groupId: group.groupId,
      campaign_id: _.get(group, 'fb.id'),
      go_lifetime_budget: fbAdSetFormData.lifetime_budget,
      go_bid_amount: fbAdSetFormData.bid_amount,
      bid_strategy: group.autoOptimise ? null : fbAdSetFormData.bid_strategy,
      go_lifetime_min_spend_target: fbAdSetFormData.lifetime_min_spend_target === '' ? 0 : fbAdSetFormData.lifetime_min_spend_target,
      go_lifetime_spend_cap: fbAdSetFormData.lifetime_spend_cap === '' ? 0 : fbAdSetFormData.lifetime_spend_cap,
      destination_type: fbAdSetFormData.destination_type ? fbAdSetFormData.destination_type : '',
      adset_schedule: fbAdSetFormData.dayPart ? wrapFbDayparting(fbAdSetFormData) : '[]',
      pacing_type: group.autoOptimise ? undefined : (fbAdSetFormData.dayPart ? ['day_parting'] : [])
    });
  }

  async deleteAdSet (adSetId: number | string): Promise<void> {
    return this.restClient.delete(`/v2/fb/campaigns/${adSetId}`);
  }

  async updateAdSetState (adSetsData: {
    goCampaignChannelId: (number | string),
    isDraft: boolean
  }[], state: 'activate' | 'deactivate'): Promise<void> {
    await this.restClient.put(`/v2/fb/campaigns/${state}`, adSetsData);
  }

  async getMinBudget (adSetId: number | string): Promise<number> {
    const response = await this.restClient.get(`/v2/fb/campaigns/${adSetId}/min-budget`);
    return response.data;
  }

  async getAdSetOptions (): Promise<SelectOptions[]> {
    const response = await this.restClient.get('/v2/fb/campaigns/options');
    return response.data.records;
  }
}

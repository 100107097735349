import { useEffect, useMemo, useState } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import i18n from 'i18n';
import _ from 'lodash';
import { DynamicBreadcrumb } from 'components/Breadcrumbs/DynamicBreadcrumbs';
import { GoSegmentFormData, GoSegmentType } from 'core/goSegment/GoSegment';
import { DefaultGoSegmentManager, GoSegmentManager } from 'core/goSegment/GoSegmentManager';
import { SessionStorageHelper, SessionStorageItemKeys } from 'helper/StorageHelper';
import { GoSegmentLogicFields } from './steps/mainStep/GoSegmentLogicFields';
import { GoSegmentUploadFields } from './steps/mainStep/GoSegmentUploadFields';
import { GoSegmentSelectFields } from './steps/mainStep/GoSegmentSelectFields';
import { GoSegmentLookalikeFields } from './steps/mainStep/GoSegmentLookalikeFields';
import { useCreateGoSegmentSelectFormModel, useEditGoSegmentSelectFormModel } from './steps/mainStep/GoSegmentSelectFormModel';
import { useCreateGoSegmentLogicFormModel, useEditGoSegmentLogicFormModel } from './steps/mainStep/GoSegmentLogicFormModel';
import { useCreateGoSegmentUploadFormModel, useEditGoSegmentUploadFormModel } from './steps/mainStep/GoSegmentUploadFormModel';
import { useCreateGoSegmentLookalikeFormModel, useEditGoSegmentLookalikeFormModel } from './steps/mainStep/GoSegmentLookalikeFormModel';
import { useCallAPI } from 'hooks/useCallAPI';
import countryOptions from './lookalikeCountryOptions';

export type GoSegmentSetupFlowPageModelData = {
  needChooseType: boolean,
  initGoSegment?: GoSegmentFormData,
  title: string,
  loading: boolean,
  redirectPath: string | undefined,
  breadcrumbs: any[],
  backToGoSegmentList: () => void,
  setRedirectPath: (redirectPath?: string) => void,
  getMainStepData: () => {
    fields: any,
    initGoSegment: any,
    model: any
  } | undefined,
  onGoSegmentTypeChange: (type: GoSegmentType) => void;
};

const defaultGoSegmentManager: GoSegmentManager = new DefaultGoSegmentManager();

const useGoSegmentSetupFlowPageModel = () => {

  const [redirectPath, setRedirectPath] = useState<string | undefined>(undefined);
  const [initGoSegment, setInitGoSegment] = useState<GoSegmentFormData | undefined>({
    name: '',
    description: '',
    advertiserId: SessionStorageHelper.getNumberItem(SessionStorageItemKeys.ADVERTISER)
  });
  const { loading, callAPIs } = useCallAPI();

  const backToGoSegmentList = () => {
    setRedirectPath('/segments');
  };

  return {
    loading,
    initGoSegment,
    redirectPath,
    setRedirectPath,
    setInitGoSegment,
    onGoSegmentTypeChange: (newType: GoSegmentType) => {
      setInitGoSegment(prev => (prev ? { ...prev, type: newType } : undefined));
    },
    callAPIs,
    backToGoSegmentList
  };
};

export const useCreateGoSegmentSetupFlowPageModel = (): GoSegmentSetupFlowPageModelData => {

  const { initGoSegment, ...basicProps } = useGoSegmentSetupFlowPageModel();
  const location = useLocation();
  const seedSegmentId = _.get(location, 'state.seedSegmentId');

  const mainStepDataMap = {
    [GoSegmentType.UPLOAD]: {
      fields: GoSegmentUploadFields,
      initGoSegment: {
        ...initGoSegment,
        type: GoSegmentType.UPLOAD,
        upload: {}
      },
      model: useCreateGoSegmentUploadFormModel
    },
    [GoSegmentType.MANUAL]: {
      fields: GoSegmentSelectFields,
      initGoSegment: {
        ...initGoSegment,
        audienceBrief: ''
      },
      model: useCreateGoSegmentSelectFormModel
    },
    [GoSegmentType.CUSTOM]: {
      fields: GoSegmentLogicFields,
      initGoSegment: {
        ...initGoSegment,
        audienceBrief: ''
      },
      model: useCreateGoSegmentLogicFormModel
    }
  };

  const getMainStepData = () => {
    if (seedSegmentId) {
      return {
        fields: GoSegmentLookalikeFields,
        initGoSegment: {
          ...initGoSegment,
          type: GoSegmentType.LOOKALIKE,
          lookalike: {
            seed_segment_id: seedSegmentId,
            ratio: 5,
            country: countryOptions[0].value
          }
        },
        model: _.partial(useCreateGoSegmentLookalikeFormModel, seedSegmentId)
      };
    }

    if (!initGoSegment || !initGoSegment.type) {
      return undefined;
    }
    return mainStepDataMap[initGoSegment.type];
  };

  return {
    ...basicProps,
    needChooseType: seedSegmentId === undefined,
    initGoSegment,
    title: i18n.t('goSegmentSetupFlow.title.createTitle'),
    breadcrumbs: [
      { path: '/segments', breadcrumb: i18n.t('segmentHome.labels.title') },
      { path: '/segments/new', breadcrumb: i18n.t('goSegmentSetupFlow.title.createTitle') }
    ],
    getMainStepData
  };
};

export const useEditGoSegmentSetupFlowPageModel = (
  goSegmentManager: GoSegmentManager = defaultGoSegmentManager
) => {

  const match = useRouteMatch<{segmentId: string}>();
  const segmentId = match.params.segmentId;
  const {
    initGoSegment,
    callAPIs,
    setInitGoSegment,
    ...basicProps
  } = useGoSegmentSetupFlowPageModel();

  useEffect(() => {
    callAPIs([
      goSegmentManager.getGoSegment.bind(goSegmentManager, segmentId)
    ], goSegment => {
      setInitGoSegment({
        ...goSegment,
        upload: {}
      });
    });
  }, [segmentId, goSegmentManager, callAPIs, setInitGoSegment]);

  const mainStepDataMap = useMemo(() => ({
    [GoSegmentType.UPLOAD]: {
      fields: GoSegmentUploadFields,
      initGoSegment,
      model: _.partial(useEditGoSegmentUploadFormModel, segmentId)
    },
    [GoSegmentType.MANUAL]: {
      fields: GoSegmentSelectFields,
      initGoSegment,
      model: _.partial(useEditGoSegmentSelectFormModel, segmentId, _.get(initGoSegment, 'audienceBrief', ''))
    },
    [GoSegmentType.CUSTOM]: {
      fields: GoSegmentLogicFields,
      initGoSegment,
      model: _.partial(useEditGoSegmentLogicFormModel, segmentId)
    },
    [GoSegmentType.LOOKALIKE]: {
      fields: GoSegmentLookalikeFields,
      initGoSegment,
      model: _.partial(useEditGoSegmentLookalikeFormModel, segmentId, _.get(initGoSegment, 'lookalike.seed_segment_id'))
    }
  }), [segmentId, initGoSegment]);

  const getMainStepData = () => {
    if (!initGoSegment || !initGoSegment.type) {
      return undefined;
    }
    return mainStepDataMap[initGoSegment.type];
  };

  return {
    ...basicProps,
    needChooseType: false,
    title: i18n.t('goSegmentSetupFlow.title.editTitle'),
    breadcrumbs: [
      { path: '/segments', breadcrumb: i18n.t('segmentHome.labels.title') },
      {
        path: '/segments/:segmentId/edit',
        breadcrumb: DynamicBreadcrumb,
        props: {
          prefix: i18n.t('common.labels.edit'),
          label: _.get(initGoSegment, 'name'),
          matchParam: 'segmentId'
        }
      }
    ],
    initGoSegment,
    getMainStepData
  };
};

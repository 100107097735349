import React, { ReactElement } from 'react';
import { FormikField } from 'components/common/formik/FormikField';
import i18n from 'i18n';
import styles from '../campaignGroupForm.module.scss';
import { createSelectOptionsFromEnum } from 'utils/SelectOptionsUtils';
import { FBBidStrategy, GoCampaignGroupObjective } from 'core/campaignGroup/CampaignGroup';
import FormGroup from 'components/common/FormGroup/FormGroup';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { RtbCampaignBasic } from 'core/rtbCampaign/RtbCampaign';
import { formatPriceWithCurrency } from 'helper/CurrencyHelper';

const objectiveOptions = [
  {
    label: i18n.t('campaignGroup.labels.fb.objectiveGroup.awareness'),
    value: 0,
    options: createSelectOptionsFromEnum(GoCampaignGroupObjective.AwarenessObjective, 'campaignGroup.labels.fb.objective.')
  }, {
    label: i18n.t('campaignGroup.labels.fb.objectiveGroup.consideration'),
    value: 1,
    options: createSelectOptionsFromEnum(GoCampaignGroupObjective.ConsiderationObjective, 'campaignGroup.labels.fb.objective.')
  }
  // , {
  //   label: i18n.t('campaignGroup.labels.fb.objectiveGroup.conversion'),
  //   value: 2,
  //   options: createSelectOptionsFromEnum(GoCampaignGroupObjective.ConversionObjective, 'campaignGroup.labels.fb.objective.')
  // }
];
const bidStrategyOptions = createSelectOptionsFromEnum(FBBidStrategy, 'campaignGroup.labels.fb.bidStrategy.');
const bidStrategyFormatter = value => value ? i18n.t(`campaignGroup.labels.fb.bidStrategy.${value.toLowerCase()}`) : '';
export const RTBCampaignGroupForm: React.FC<{
  formType: string,
  isCBO: boolean,
  canEditBidStrategy: boolean,
  currency: string,
  campaigns: RtbCampaignBasic[],
  campaignBudgets: any[],
  onEditCampaignBudgetsBtn: () => void,
  getBudgetTips: () => ReactElement | string | undefined
}> = ({
  isCBO,
  formType,
  currency,
  campaigns,
  campaignBudgets,
  canEditBidStrategy,
  onEditCampaignBudgetsBtn,
  getBudgetTips
}) => {

  const objectiveFormatter = value => value ? i18n.t(`campaignGroup.labels.fb.objective.${value.toLowerCase()}`) : '';
  const renderCampaignBudget = () => {
    return campaigns.map(campaign => {
      const campaignBudget = campaignBudgets.find(campaignBudget => campaign.id && campaignBudget.id.toString() === campaign.id.toString());
      return (
        <div key={campaign.id}>
          {`${campaign.name}: ${formatPriceWithCurrency(currency, campaignBudget ? +campaignBudget.budget : 0)}`}
        </div>
      );
    });
  };
  return (
    <>
      {
        <FormikField.InputGroup
          label={i18n.t('campaignGroup.labels.budget')}
          name='budget'
          type='number'
          prefix={currency}
          hint={getBudgetTips()}
        />
      }
      {
        campaignBudgets &&
          <FormGroup controlId='campaignBudgets' label=''>
            <div className={styles.adSetBudgetContainer}>
              <div className={styles.adSetBudget}>
                {renderCampaignBudget()}
              </div>
              <IconWithTooltip
                icon={faPencilAlt}
                onClick={onEditCampaignBudgetsBtn}
                tooltipProps={{
                  id: `adsetBudgetsEditTip`,
                  tooltip: i18n.t('campaignGroup.hints.editAdsetBudgets')
                }}
              />
            </div>
          </FormGroup>
      }
      {
        formType === 'create' ?
          <FormikField.Select
            label={i18n.t('campaignGroup.labels.fb.objectiveLabel')}
            name='objective'
            simpleValue
            options={objectiveOptions}
            className={styles.selectField}
          /> :
          <FormikField.Label
            label={i18n.t('campaignGroup.labels.fb.objectiveLabel')}
            name='objective'
            formatter={objectiveFormatter}
          />
      }
      {
        isCBO && (
          canEditBidStrategy ?
          <FormikField.Select
            label={i18n.t('campaignGroup.labels.fb.bidStrategyLabel')}
            name='rtb.bid_strategy'
            simpleValue
            options={bidStrategyOptions}
            className={styles.selectField}
          /> :
          <FormikField.Label
            label={i18n.t('campaignGroup.labels.fb.bidStrategyLabel')}
            name='rtb.bid_strategy'
            formatter={bidStrategyFormatter}
          />
        )
      }
    </>
  );
};

import { RoleNames } from 'core';
import { AppMenuModel, DefaultAppMenuModel, DefaultAppMenuItemModel } from 'components/AppMenuBar';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { hasRoles, notRoles, addOnEnabled, isProject } from 'core/permission/PermissionDSL';
import { faChartBar, faCheckSquare, faBriefcase, faCubes, faCog } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import { Project } from 'core/project/Project';

export default function defaultMenus (): Array<AppMenuModel> {

  const { sysAdmin, agencyAdmin, agencyReport, adsReport, agencyManager, agencySales, goJekAccountManager, fbAgencyManager, guest } = RoleNames;
  // const { sysAdmin, agencyAdmin, agencyManager, agencySales, adsAdmin, adsSales, adsReport } = RoleNames;
  const reportMenuItems = [
    new DefaultAppMenuItemModel('appMenus.report.items.performance', '/reports/performance', notRoles(goJekAccountManager, guest)),
    // new DefaultAppMenuItemModel('appMenus.report.items.compare', '/reports/compare'),
    // new DefaultAppMenuItemModel('appMenus.report.items.dataMarketplace', '/reports/data-marketplace', notRoles(adsSales)),
    // new DefaultAppMenuItemModel('appMenus.report.items.video', '/reports/video', addOnEnabled(ADDONFEATURE.CREATIVES.VIDEO, ADDONFEATURE.CREATIVES.THIRDPARTY, ADDONFEATURE.CREATIVES.COMBO, ADDONFEATURE.CREATIVES.OUTDOOR)),
    // new DefaultAppMenuItemModel('appMenus.report.items.campaignDashboard', '/reports/campaign-dashboard', hasRoles(sysAdmin, agencyAdmin, agencyManager, agencySales).and(addOnEnabled(ADDONFEATURE.REPORT.REPORT_CAMPAIGN_DASHBOARD))),
    new DefaultAppMenuItemModel('appMenus.report.items.reconciliation', '/reports/reconciliation', hasRoles(sysAdmin).or(hasRoles(agencyAdmin, agencyManager, agencySales).and(addOnEnabled(ADDONFEATURE.COMPANY.AGENCY_PROVIDE_REPORT_TO_WORKFLOW)))),
    new DefaultAppMenuItemModel('appMenus.report.items.credit', '/reports/credits', hasRoles(sysAdmin).or(hasRoles(agencyAdmin, agencyManager).and(addOnEnabled(ADDONFEATURE.COMPANY.AGENCY_CREDIT_LIMIT))))
  ];
  const reportMenu = new DefaultAppMenuModel('appMenus.report.title', reportMenuItems, faChartBar);

  const companyMenuItems = [
    new DefaultAppMenuItemModel('appMenus.comapnyManagement.items.agencies', '/agencies', hasRoles(sysAdmin, agencyAdmin, goJekAccountManager)),
    new DefaultAppMenuItemModel('appMenus.comapnyManagement.items.advertisers', '/advertisers', hasRoles(sysAdmin, agencyAdmin, goJekAccountManager)),
    new DefaultAppMenuItemModel('appMenus.comapnyManagement.items.oemAccounts', '/oem-accounts', hasRoles(sysAdmin)),
    new DefaultAppMenuItemModel('appMenus.comapnyManagement.items.accounts', '/accounts', hasRoles(sysAdmin, goJekAccountManager))
  ];
  const companyMenu = new DefaultAppMenuModel('appMenus.comapnyManagement.title', companyMenuItems, faBriefcase);

  const retailMaxMenuItems = [
    new DefaultAppMenuItemModel('appMenus.retailMax.items.productCategory', '/product-category', notRoles(sysAdmin, adsReport, goJekAccountManager, fbAgencyManager, guest).and(isProject(Project.RETAIL))),
    new DefaultAppMenuItemModel('appMenus.retailMax.items.productSet', '/product-sets', notRoles(sysAdmin, adsReport, goJekAccountManager, fbAgencyManager, guest).and(isProject(Project.RETAIL)))
  ];

  const retailMaxMenu = new DefaultAppMenuModel('appMenus.retailMax.title', retailMaxMenuItems, faCheckSquare);

  const systemMenuItems = [
    new DefaultAppMenuItemModel('appMenus.system.items.productGroups', '/system/product-groups', hasRoles(sysAdmin)),
    // new DefaultAppMenuItemModel('appMenus.system.items.roles', '/system/roles', hasRoles(sysAdmin)),
    new DefaultAppMenuItemModel('appMenus.system.items.currencyRate', '/system/currency-rate', hasRoles(sysAdmin))
    // new DefaultAppMenuItemModel('appMenus.system.items.auditLog', '/system/audit-log', hasRoles(sysAdmin))
  ];
  const systemMenu = new DefaultAppMenuModel('appMenus.system.title', systemMenuItems, faCog);

  const advertisementMenuItems = [
    new DefaultAppMenuItemModel('appMenus.ads.items.orders', '/orders', notRoles(sysAdmin, adsReport, goJekAccountManager, guest)),
    new DefaultAppMenuItemModel('appMenus.ads.items.creatives', '/creatives', notRoles(sysAdmin, agencyReport, adsReport, goJekAccountManager, guest)),
    new DefaultAppMenuItemModel('appMenus.ads.items.estimator', '/estimator', notRoles(agencyReport, adsReport, goJekAccountManager, fbAgencyManager, guest).and(isProject(Project.GOGAN))),
    new DefaultAppMenuItemModel('appMenus.ads.items.onboarding', '/onboarding', hasRoles(fbAgencyManager)),
    // new DefaultAppMenuItemModel('appMenus.ads.items.creativeTemplates', '/ads/creative-templates', hasRoles(agencyAdmin, agencySales, adsAdmin).and(addOnEnabled(ADDONFEATURE.CREATIVE_STUDIO.DCS_TEMPLATES))),
    new DefaultAppMenuItemModel('appMenus.ads.items.conversion', '/conversions', notRoles(agencyReport, adsReport, goJekAccountManager, fbAgencyManager, guest).and(addOnEnabled(ADDONFEATURE.CONVERSION_TRACKING.CONV_TRACKING_LIST))),
    new DefaultAppMenuItemModel('appMenus.ads.items.contactUs', '/contact-us', hasRoles(guest))
  ];
  const advertisementMenu = new DefaultAppMenuModel('appMenus.ads.title', advertisementMenuItems, faCheckSquare);

  const dataMenuItems = [
    new DefaultAppMenuItemModel('appMenus.data.items.audiences', '/segments', notRoles(agencyReport, adsReport, goJekAccountManager, fbAgencyManager, guest))
    // new DefaultAppMenuItemModel('appMenus.data.items.marketplace', '/data/marketplace', hasRoles(agencyAdmin, agencyManager, agencySales, adsAdmin))
  ];
  const dataMenu = new DefaultAppMenuModel('appMenus.data.title', dataMenuItems, faCubes);

  // const eCommerceMenuItems = [
  //   new DefaultAppMenuItemModel('appMenus.eCommerce.items.products', '/e-commerce/products', hasRoles(agencyAdmin, agencyManager, agencySales, adsAdmin).and(addOnEnabled(ADDONFEATURE.PRODUCT.E_COMMERCE))),
  //   new DefaultAppMenuItemModel('appMenus.eCommerce.items.conversion', '/e-commerce/conversion', hasRoles(agencyAdmin, agencyManager, agencySales, adsAdmin).and(addOnEnabled(ADDONFEATURE.PRODUCT.EC_CONVERSION))),
  //   new DefaultAppMenuItemModel('appMenus.eCommerce.items.events', '/e-commerce/events', hasRoles(agencyAdmin, agencyManager, agencySales, adsAdmin).and(addOnEnabled(ADDONFEATURE.PRODUCT.E_COMMERCE))),
  //   new DefaultAppMenuItemModel('appMenus.eCommerce.items.strategies', '/e-commerce/strategies', hasRoles(agencyAdmin, agencyManager, agencySales, adsAdmin).and(addOnEnabled(ADDONFEATURE.PRODUCT.EC_PROGRAMMATIC_STRATEGY)))
  // ];
  // const eCommerceMenu = new DefaultAppMenuModel('appMenus.eCommerce.title', eCommerceMenuItems, faShoppingCart);
  // const otherMenuItems = [
  //   new DefaultAppMenuItemModel('appMenus.other.items.doctor', '/doctor', hasRoles(sysAdmin)),
  //   new DefaultAppMenuItemModel('appMenus.other.items.outdoorMonitor', '/outdoor-monitor', hasRoles(sysAdmin).or(hasRoles(RoleNames.agencyAdmin).and(addOnEnabled(ADDONFEATURE.COMPANY.OUTDOOR_AGENCY))))
  // ];
  // const otherMenu = new DefaultAppMenuModel('appMenus.other.title', otherMenuItems, faFolder);

  const menus = _.compact([
    retailMaxMenu,
    advertisementMenu,
    dataMenu,
    // eCommerceMenu,
    reportMenu,
    companyMenu,
    systemMenu
  ]);
  return menus;
}

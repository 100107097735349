import { FormikProps, getIn } from 'formik';
import _ from 'lodash';

export const getFieldErrors = (formikProps: Partial<FormikProps<any>>, fieldName: string) => {
  if (!formikProps) {
    return;
  }
  const errors = getIn(formikProps.errors, fieldName);
  const touched = getIn(formikProps.touched, fieldName) || _.defaultTo(formikProps.submitCount, 0) > 0;
  if (errors && touched) {
    return errors;
  }
  return;
};

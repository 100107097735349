import _ from 'lodash';
import React, { Fragment } from 'react';

import i18n from 'i18n';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

export type SortOrder = 'asc' | 'desc';

export type SortDescriptor = {

  readonly order: SortOrder;
  readonly dataField: string;
};

export type ColumnDefinition = {

  readonly text: string;
  readonly sort: boolean;
  readonly dataField: string;
  readonly events?: any;
  readonly classes?: any;
  readonly headerClasses?: any;
  readonly sortFunc?: any;
  readonly onSort?: any;
  readonly formatExtraData?: any;
};

function translatedHeader (column: any, columnIndex: number, { sortElement }): any {
  const currency = _.get(column, 'headerExtraData.currency');
  const header = _.isNil(currency) ? i18n.t(column.text) : `${i18n.t(column.text)} (${currency})`;
  return (
    <Fragment>
      {header}
      {sortElement}
    </Fragment>
  );
}

export function renderColumn (column: any, formatter: any | undefined = undefined, headerFormatter: any | undefined = translatedHeader): any {
  return _.omitBy({
    ...column,
    formatter: formatter,
    headerFormatter
  }, _.isNil);
}

export function sortableColumn (dataField: string, text: string, sort: boolean = true): ColumnDefinition {
  return {
    sort,
    text,
    dataField
  };
}

export function checkedFormatter (cell: any, row: any): any {
  return (
    <Fragment>
      {cell ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} />}
    </Fragment>
  );
}

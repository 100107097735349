import React from 'react';
import { OrderFormProps } from './OrderFormModel';
import i18n from 'i18next';
import styles from './orderForm.module.scss';
import { addOnEnabled, notRoles } from 'core/permission/PermissionDSL';
import { RoleNames } from 'core';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { FormikField } from 'components/common/formik/FormikField';
import { FormikProps } from 'formik';
import { Order } from 'core/order/Order';
import moment from 'moment';

export const CreateOrderFormContent: React.FunctionComponent<OrderFormProps & { formik: FormikProps<Order> }> = (props) => {

  const onDateRangeChanged = (_1, endDate) => {
    props.formik.setFieldValue('finalReportSendOutDate', moment(endDate).startOf('day').add(2, 'days').format('YYYY-MM-DD'));
  };

  return (
    <div className={styles.createOrderForm}>
      <PermissionChecker
        permissionAware={notRoles(RoleNames.adsAdmin, RoleNames.adsSales, RoleNames.adsReport)}
      >
        <FormikField.Select
          label={i18n.t('orderForm.labels.advertiser')}
          name='advertiserId'
          options={props.model.advertiserList}
          simpleValue
          onChange={props.model.onAdvertiserChange}
        />
      </PermissionChecker>
      <FormikField.Input
        label={i18n.t('orderForm.labels.name')}
        name='projectName'
        className={styles.orderInput}
      />
      {/* <FormikField.Input
        label={i18n.t('orderForm.labels.externalId')}
        name='externalId'
        className={styles.orderInput}
      />
      {props.model.showOrderExternalTypes &&
        <FormikField.Select
          name='externalType'
          label={i18n.t('orderForm.labels.externalType')}
          options={props.model.orderExternalTypes}
          simpleValue
          isClearable
        />
      } */}
      <FormikField.DateRangePicker
        label={i18n.t('orderForm.labels.dateRange')}
        className={styles.orderInput}
        minDate={props.model.dateRangeMinDate}
        startDateFormikName='startDate'
        endDateFormikName='endDate'
        name='dateRange'
        format={'YYYY-MM-DD'}
        showTimePicker={false}
        fieldContentWidth={346}
        hint={i18n.t('orderForm.labels.totaldates', { num: props.model.totalDays })}
        hoverHint={i18n.t('orderForm.labels.dateCannotModifyHint')}
        onChange={onDateRangeChanged}
      />
      <FormikField.InputGroup
        label={i18n.t('orderForm.labels.budget')}
        prefix={props.model.localeMeta ? props.model.localeMeta.currency : 'NTD'}
        name='budget'
        type='number'
        hint={props.model.budgetTip}
      />
      {props.model.canEditMargin &&
        <PermissionChecker
          permissionAware={
            notRoles(RoleNames.adsAdmin, RoleNames.adsReport, RoleNames.adsSales)
              .and(addOnEnabled(ADDONFEATURE.CAMPAIGN.ORDER_AGENCY_PROFIT_SETTING))
          }
        >
          <FormikField.InputGroup
            label={i18n.t('orderForm.labels.orderMargin')}
            name='orderMargin'
            postfix='%'
            type='number'
          />
        </PermissionChecker>
      }
      <FormikField.TextArea
        label={i18n.t('orderForm.labels.remark')}
        name='comments'
      />
      {props.model.canEditMonitor &&
        <FormikField.Switch
          label={i18n.t('orderForm.labels.monitor')}
          name='monitor'
        />
      }
    </div>
  );
};

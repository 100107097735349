import _, { round } from 'lodash';
import { toast } from 'react-toastify';
import i18n from 'i18n';

import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import {
  AgencyManager,
  DefaultAgencyManager
} from 'core/agency/AgencyManager';
import { multiply100, toDecimal } from 'utils/Math';
import { DynamicBreadcrumb } from 'components/Breadcrumbs/DynamicBreadcrumbs';

export type AgencyFormForAgencyState = {
  isLoading: boolean;
  redirectUrl?: string;
};

export interface AgencyFormForAgencyModel {
  readonly agencyId: number;
  readonly cancelPath: string;
  readonly title: string;
  readonly defaultAgcData: any;
  readonly breadcrumbs: any;
  state: AgencyFormForAgencyState;
  event: UpdateEventListener<AgencyFormForAgencyModel>;
  save (value: any): void;
  init (): Promise<void>;
  onUnmount (handler): void;
  validate (basic): any;
}

export interface AgencyFormForAgencyProps {
  readonly model: AgencyFormForAgencyModel;
}

export class DefaultAgencyFormForAgencyModel implements AgencyFormForAgencyModel {
  modelEvent: FireableUpdateEventListener<AgencyFormForAgencyModel>;
  agencyManager: AgencyManager;
  agencyId: number;
  isLoading: boolean;
  redirectUrl?: string;
  agency?: any;

  constructor (
    agencyId: number,
    private updateLocalMeta: () => Promise<void>,
    agencyManager: AgencyManager = new DefaultAgencyManager()
  ) {
    this.agencyId = agencyId;
    this.agencyManager = agencyManager;
    this.modelEvent = new FireableUpdateEventListener<AgencyFormForAgencyModel>();
    this.isLoading = true;
  }

  async init (): Promise<void> {
    try {
      const { detail } = await this.agencyManager.fetchAgency(this.agencyId);
      this.agency = detail;
      this.updateState(false);
    } catch (e) {
      this.updateState(false, '/');
    }
  }

  get title () {
    return 'agency.form.titles.edit';
  }

  get breadcrumbs () {
    return [
      { path: '/agencies/:agencyId', breadcrumb: DynamicBreadcrumb, props: { label: _.get(this.agency, 'companyName'), matchParam: 'agencyId' } },
      { path: '/agencies/:agencyId/edit', breadcrumb: DynamicBreadcrumb, props: { prefix: i18n.t('common.labels.edit'), label: _.get(this.agency, 'companyName'), matchParam: 'agencyId' } }
    ];
  }

  get cancelPath () {
    return `/agencies/${this.agencyId}`;
  }

  get event (): UpdateEventListener<AgencyFormForAgencyModel> {
    return this.modelEvent;
  }

  get defaultAgcData () {
    if (!this.agency) {
      return {};
    }
    const agcPercent = _.get(this.agency, 'agcPercent', 0);
    const modelAgcPercent = round(multiply100(agcPercent), 2);
    const maxOrderProfit = _.get(this.agency, 'addonProps.maxOrderProfit', 0);
    const modelMaxOrderProfit = round(multiply100(maxOrderProfit), 2);
    return {
      ...this.agency,
      agcPercent: modelAgcPercent,
      addonProps: {
        ..._.get(this.agency, 'addonProps', {}),
        maxOrderProfit: modelMaxOrderProfit
      }
    };
  }

  async save (agencyFormikObj: any): Promise<void> {
    this.updateState(true);
    try {
      const { agcPercent, logoData, adLogo } = agencyFormikObj;
      await this.agencyManager.updateAgencySelf(this.agencyId, {
        agcPercent: toDecimal(agcPercent),
        logoData,
        adLogo
      });
      await this.updateLocalMeta();
      toast.success(i18n.t('common.messages.succeeded'));
      this.updateState(false, `/agencies/${this.agencyId}`);
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
      this.updateState(false);
    }
  }

  validate = (agency) => {
    const maxOrderProfit = _.get(agency, 'addonProps.maxOrderProfit', 0);
    if (agency.agcPercent > maxOrderProfit) {
      return {
        agcPercent: i18n.t('agencyForm.labels.orderDefaultProfitBiggerThanMaxProfit')
      };
    }
  }

  updateState (
    isLoading: boolean,
    redirectUrl?: string
  ) {
    this.isLoading = isLoading;
    this.redirectUrl = redirectUrl;
    this.modelEvent.fireEvent(this);
  }

  get state (): AgencyFormForAgencyState {
    return {
      isLoading: this.isLoading,
      redirectUrl: this.redirectUrl
    };
  }

  onUnmount (handler): void {
    handler && this.event.remove(handler);
    this.redirectUrl = undefined;
  }
}

import React, { useRef } from 'react';
import { NativePreview } from '../FormContent/NativePreview';
import styles from './oneForAllDisplayMediaSummary.module.scss';
import _ from 'lodash';
import { useOneForAllDisplayPreview } from 'hooks/GoCreativePreview/useOneForAllDisplayPreview';
import parser from 'html-react-parser';

export const OneForAllDisplayMediaSummary: React.FC<{ mediaSummary: any }> = (props) => {

  const bannerUrl = props.mediaSummary.bannerUrl;
  const typeProperties = props.mediaSummary.typeProperties;
  const medias = props.mediaSummary.medias;
  const squareImgFile = _.get(medias, 'squareImg.file');
  const squareImgUrl = squareImgFile ? URL.createObjectURL(squareImgFile) : _.get(medias, 'squareImg.url');
  const rectImgFile = _.get(medias, 'rectImg.file');
  const rectImgUrl = rectImgFile ? URL.createObjectURL(rectImgFile) : _.get(medias, 'rectImg.url');

  const {
    previewData
  } = useOneForAllDisplayPreview(
    props.mediaSummary.advertiserId,
    typeProperties,
    props.mediaSummary.medias
  );
  const previewRefs = useRef({});
  const onIframeLoaded = (previewType) => {
    if (!previewRefs.current[previewType]) {
      return;
    }
    previewRefs.current[previewType].className = '';
    previewRefs.current[previewType].querySelector(`.${styles.loadingCircle}`).style.display = 'none';
  };

  const renderFBPreviews = () => {
    return Object.keys(previewData).map(previewType => {
      let content;
      const iframeString = previewData[previewType];
      if (iframeString) {
        const iframe = parser(iframeString);
        if (React.isValidElement(iframe)) {
          content = React.cloneElement(iframe, {
            height: 750,
            onLoad: _.partial(onIframeLoaded, previewType)
          });
        }
      }
      return (
        <div
          key={previewType}
          ref={el => previewRefs.current[previewType] = el}
          className={styles.previewPlaceholder}
        >
          <div className={styles.loadingCircle} />
          {content}
        </div>
      );
    });
  };

  return (
    <div className={styles.oneForAllDisplayPreview}>
      <div className={styles.previewSection}>
        {renderFBPreviews()}
      </div>
      <div className={styles.previewSection}>
        <NativePreview
          url={rectImgUrl || squareImgUrl}
          bannerUrl={bannerUrl}
          creativeValues={typeProperties}
        />
        <NativePreview
          square={true}
          url={squareImgUrl}
          bannerUrl={bannerUrl}
          creativeValues={typeProperties}
        />
      </div>
    </div>
  );
};

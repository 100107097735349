import React from 'react';
import './Modal.scss';
import classNames from 'classnames';

import { Modal as ReactModal, Button as ReactButton } from 'react-bootstrap';
import MenuBarExpandContext from 'contexts/menuBarExpandContext';

interface ButtonFunction {
  (e?: any): void;
}

interface Button {
  title: string;
  disabled?: boolean;
  variant?: any;
  callback: ButtonFunction;
}

export interface ModalProps {

  readonly title: string;
  readonly primaryButton?: Button;
  readonly secondaryButton?: Button;
  readonly dangerButton?: Button;
  readonly dismiss?: ButtonFunction;
  readonly enableCloseBtn: boolean;
  readonly className?: string;
  readonly animation?: boolean;
  readonly fullScreen?: boolean;
  readonly enforceFocus: boolean;
  readonly backdrop?: boolean | 'static';
}

export class Modal extends React.Component<ModalProps> {

  static defaultProps = {
    enableCloseBtn: false,
    enforceFocus: true,
    backdrop: true
  };

  hide = () => {
    this.props.dismiss && this.props.dismiss();
  }

  close = () => {
    this.props.dismiss && this.props.dismiss();
  }

  render () {
    const dangerButton = this.props.dangerButton;
    const secondaryButton = this.props.secondaryButton;
    const primaryButton = this.props.primaryButton;
    return (
      <MenuBarExpandContext.Consumer>
        {
          expand => {
            const className = classNames(this.props.className ? this.props.className : 'modal' , {
              fullScreen: !!this.props.fullScreen,
              menuBarExpand: expand
            });
            return (
              <ReactModal
                className={className}
                centered
                show
                onHide={this.hide}
                enforceFocus={this.props.enforceFocus}
                animation={this.props.animation !== undefined ? this.props.animation : true}
                backdrop={this.props.backdrop}
              >
                <ReactModal.Header closeButton={this.props.enableCloseBtn}><h4>{this.props.title}</h4></ReactModal.Header>
                <ReactModal.Body>
                  {this.props.children}
                </ReactModal.Body>
                <ReactModal.Footer>
                  <div>
                    {primaryButton &&
                      <ReactButton
                        size='sm'
                        variant={primaryButton.variant ? primaryButton.variant : 'primary'}
                        onClick={primaryButton.callback}
                        disabled={primaryButton.disabled !== undefined ? primaryButton.disabled : false}
                      >
                        {primaryButton.title}
                      </ReactButton>
                    }
                    {secondaryButton &&
                      <ReactButton
                        size='sm'
                        variant={secondaryButton.variant ? secondaryButton.variant : 'secondary'}
                        onClick={secondaryButton.callback}
                        disabled={secondaryButton.disabled !== undefined ? secondaryButton.disabled : false}
                      >
                        {secondaryButton.title}
                      </ReactButton>
                    }
                  </div>
                  {dangerButton &&
                    <ReactButton
                      size='sm'
                      variant={dangerButton.variant ? dangerButton.variant : 'secondary'}
                      onClick={dangerButton.callback}
                      disabled={dangerButton.disabled !== undefined ? dangerButton.disabled : false}
                    >
                      {dangerButton.title}
                    </ReactButton>
                  }
                </ReactModal.Footer>
              </ReactModal>
            );
          }
        }
      </MenuBarExpandContext.Consumer>
    );
  }
}

import React, { ReactElement } from 'react';
import i18n from 'i18n';
import styles from '../campaignGroupForm.module.scss';

import { FormikField } from 'components/common/formik/FormikField';
import { SelectOptions } from 'components/common/commonType';
import { AdAccountOption } from 'components/AdAccountSelect/AdAccountOption';
import { AdAccountSingleValue } from 'components/AdAccountSelect/AdAccountSingleValue';
import { createSelectOptions, createSelectOptionsFromEnum } from 'utils/SelectOptionsUtils';
import { TiktokObjective, FBBidStrategy } from 'core/campaignGroup/CampaignGroup';
import { TiktokAdGroup } from 'core/tiktokAdGroup/TiktokAdGroup';

const objectiveOptions = [
  {
    label: i18n.t('campaignGroup.labels.tiktok.objectiveGroup.awareness'),
    value: 0,
    options: createSelectOptions([TiktokObjective.REACH.toLowerCase()], 'campaignGroup.labels.tiktok.objective.')
  }, {
    label: i18n.t('campaignGroup.labels.tiktok.objectiveGroup.consideration'),
    value: 1,
    options: createSelectOptions([TiktokObjective.TRAFFIC.toLowerCase()], 'campaignGroup.labels.tiktok.objective.')
  }
];
const bidStrategyOptions = createSelectOptionsFromEnum(FBBidStrategy, 'campaignGroup.labels.fb.bidStrategy.');

export const TiktokCampaignForm: React.FC<{
  formType: string,
  tiktokAdAccountOptions: SelectOptions[],
  isCBO: boolean,
  canEditBidStrategy: boolean,
  currency: string,
  tiktokAdGroups: TiktokAdGroup[],
  // TODO: not yet implement properties of adGroup_budgets
  tiktokAdGroupBudgets: any[],
  onEditAdGroupBudgetsBtn: () => void,
  getBudgetTips: () => ReactElement | string | undefined
}> = ({
  formType,
  tiktokAdAccountOptions,
  isCBO,
  canEditBidStrategy,
  currency,
  tiktokAdGroups,
  tiktokAdGroupBudgets,
  onEditAdGroupBudgetsBtn,
  getBudgetTips
}) => {

  const objectiveFormatter = value => i18n.t(`campaignGroup.labels.tiktok.objective.${value.toLowerCase()}`);
  const adAccountFormatter = value => {
    const option = tiktokAdAccountOptions.find(option => option.value.toString() === value.toString());
    return option ? `${option.label} (${option.value})` : '';
  };
  const bidStrategyFormatter = value => i18n.t(`campaignGroup.labels.fb.bidStrategy.${value.toLowerCase()}`);
  const tiktokCampaignBudgetDisableHint = () => {
    return i18n.t('campaignGroup.labels.campaignBudgetDisableHint');
  };
  // const renderAdGroupBudget = () => {
  //   return (

  //   )
  // }

  return (
    <>
      {
        isCBO ?
          <FormikField.InputGroup
            label={i18n.t('campaignGroup.labels.budget')}
            name='budget'
            type='number'
            prefix={currency}
            hint={getBudgetTips()}
          /> :
          <FormikField.Label
            label={i18n.t('campaignGroup.labels.budget')}
            name='budget'
            formatter={tiktokCampaignBudgetDisableHint}
          />
      }
      {/* {
        tiktokAdGroupBudgets && <></>
      } */}
      {
        formType === 'create' ?
          <FormikField.Select
            label={i18n.t('campaignGroup.labels.tiktok.advertiserId')}
            name='tiktok.advertiser_id'
            simpleValue
            options={tiktokAdAccountOptions}
            optionComponent={AdAccountOption}
            singleValue={AdAccountSingleValue}
            className={styles.selectField}
          /> :
          <FormikField.Label
            label={i18n.t('campaignGroup.labels.tiktok.advertiserId')}
            name='tiktok.advertiser_id'
            formatter={adAccountFormatter}
          />
      }
      {
        formType === 'create' ?
          <FormikField.Select
            label={i18n.t('campaignGroup.labels.tiktok.objectiveLabel')}
            name='tiktok.objective_type'
            simpleValue
            options={objectiveOptions}
            className={styles.selectField}
          /> :
          <FormikField.Label
            label={i18n.t('campaignGroup.labels.tiktok.objectiveLabel')}
            name='tiktok.objective_type'
            formatter={objectiveFormatter}
          />
      }
      {
        // TODO: bid property name may not be bid_strategy, shall be modified
        isCBO && (
          canEditBidStrategy ?
          <FormikField.Select
            label={i18n.t('campaignGroup.labels.fb.bidStrategyLabel')}
            name='tiktok.bid_strategy'
            simpleValue
            options={bidStrategyOptions}
          /> :
          <FormikField.Label
            label={i18n.t('campaignGroup.labels.fb.bidStrategyLabel')}
            name='tiktok.bid_strategy'
            formatter={bidStrategyFormatter}
          />
        )
      }
    </>
  );
};


export type OutdoorBkRecord = {
  id: number;
  name: string;
} & { [date: string]: string };

export type OutdoorBkData = {
  total: { [date: string]: string };
  remaining: { [date: string]: string };
  orders: OutdoorBkRecord[];
};

export enum OutdoorBkRecordType {
  TOTAL = 'TOTAL',
  REMAINING = 'REMAINING',
  FORMAL = 'FORMAL',
  PROPOSAL = 'PROPOSAL'
}

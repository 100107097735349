import React from 'react';
import { components } from 'react-select';
import styles from './draftCampaignGroupOption.module.scss';
import cx from 'classnames/bind';
import i18n from 'i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const classNames = cx.bind(styles);

export const DraftCampaignGroupOption = props => {
  const optionClass = classNames('draftCampaignGroupOption', {
    disabled: props.isDisabled
  });
  const renderDraftCampaignGroupOption = () => (
    <div className={optionClass}>
      <div className={styles.campaignGroupName}>{props.label}</div>
      {props.isDisabled &&
        <div className={styles.selected}>
          <FontAwesomeIcon icon={faCheck}/>
          {i18n.t('draftCamapignGroupOption.hints.selected')}
        </div>
      }
    </div>
  );
  return !props.isDisabled ? (
      <components.Option {...props}>
        {renderDraftCampaignGroupOption()}
      </components.Option>
    ) :
    renderDraftCampaignGroupOption();
};

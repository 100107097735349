import React, { Fragment, useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import _ from 'lodash';
import i18n from 'i18n';
import styles from './filterMenuTab.module.scss';
import * as SelectOptionsUtils from 'utils/SelectOptionsUtils';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MultiSelectCheckbox } from '../MultiSelectCheckbox/MultiSelectCheckbox';
import Tags from '../Tags/Tags';

export type FilterMenuTabConfig = {
  filterType: string;
  menuTitle: string;
  tag: string;
  selectedValues: string[];
  options: string[];
  applyMethod: (values: any) => void;
};

export type FilterMenuTabProps = {
  filterMenuTabConfigs: FilterMenuTabConfig[];
  setFilterMenuRef: (ref: any) => void;
  removeFilterMenuRef: () => void;
};

export const FilterMenuTab: React.FC<FilterMenuTabProps> = (props) => {

  const {
    filterMenuTabConfigs,
    setFilterMenuRef,
    removeFilterMenuRef
  } = props;

  const [showMenus, setShowMenus] = useState<boolean[]>(Array.from({ length: filterMenuTabConfigs.length }, () => !!0));

  const onApply = (applyMethod, values) => {
    applyMethod(values);
    removeFilterMenuRef();
  };

  const handleShowMenu = (index: number, show: boolean) => {
    setShowMenus(showMenus => _.map(showMenus, (_1, _index) => index === _index ? show : false));
  };

  const renderFilterTags = () => (
    <Fragment>
      {_.map(filterMenuTabConfigs, (config, index) => {
        const selectedValues: string[] = _.get(config, 'selectedValues', []);
        const applyMethod: (values: any) => void = _.get(config, 'applyMethod');

        const filterTags = selectedValues.map(selectedValue => {
          const handleRemoveSelect = () => {
            applyMethod(selectedValues.filter(value => value !== selectedValue));
          };
          return (
            <Tags
              key={selectedValue}
              value={[{
                label: `${_.get(config, 'tag')}: ${selectedValue}`,
                value: selectedValue
              }]}
              disableInput
              onChange={handleRemoveSelect}
            />
          );
        });

        return (
          <Fragment key={index}>
            {filterTags}
          </Fragment>
        );
      })}
    </Fragment>
  );

  return (
    <Fragment>
      <OverlayTrigger
        ref={setFilterMenuRef}
        trigger='click'
        placement='bottom'
        rootClose
        onExited={_.partial(handleShowMenu, -1, false)}
        overlay={
          <Popover id='filter-menu'>
            <Popover.Content className={styles.filterMenu}>
              <Fragment>
                <ul className={styles.filterTypeList}>
                  {_.map(filterMenuTabConfigs, (config, index) => {
                    return _.get(config, 'options', []).length > 0 && (
                      <li key={index} onClick={_.partial(handleShowMenu, index, !showMenus[index])}>
                        {_.get(config, 'filterType', '')}
                      </li>
                    );
                  })}
                </ul>
                <div className={styles.filterOptionsOfType}>
                  {_.map(filterMenuTabConfigs, (config, index) => {
                    return showMenus[index] && (
                      <MultiSelectCheckbox
                        key={index}
                        className={styles.filterOptions}
                        title={_.get(config, 'menuTitle', '')}
                        values={_.get(config, 'selectedValues', [])}
                        options={SelectOptionsUtils.createSelectOptions(_.get(config, 'options', []))}
                        onApply={_.partial(onApply, _.get(config, 'applyMethod'))}
                        onClose={_.partial(handleShowMenu, index, false)}
                      />
                    );
                  })}
                </div>
              </Fragment>
            </Popover.Content>
          </Popover>
        }
      >
        <div className={styles.filterBtn}>
          <FontAwesomeIcon icon={faFilter} />
          {i18n.t('filterMenuTab.labels.filterList')}
        </div>
      </OverlayTrigger>
      <div className={styles.filterTags}>
        {renderFilterTags()}
      </div>
    </Fragment>
  );
};

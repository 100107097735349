import { Agency } from 'core';
import { ColumnDefinition, sortableColumn, SortDescriptor } from 'components/TableColumn';

export interface AgencyListModel {

  readonly idColumn: ColumnDefinition;
  readonly nameColumn: ColumnDefinition;
  readonly currencyColumn: ColumnDefinition;
  readonly timeZoneColumn: ColumnDefinition;
  readonly oemAgencyColumn: ColumnDefinition;
  readonly loginTitleColumn: ColumnDefinition;
  readonly loginDomainColumn: ColumnDefinition;
  readonly editBtnsColumn: ColumnDefinition;

  readonly agencies: Array<Agency>;
  readonly noDataDescription: string;
  readonly defaultSorts: Array<SortDescriptor>;
}

export type AgencyListProps = {

  readonly model: AgencyListModel;
};

export class DefaultAgencyListModel implements AgencyListModel {
  agencies: Array<Agency>;

  constructor (agencies: Array<Agency>) {
    this.agencies = agencies;
  }

  get noDataDescription (): string {
    return 'agencies.noDataAvailable';
  }

  get idColumn (): ColumnDefinition {
    return sortableColumn('id', 'agencies.headers.id');
  }

  get nameColumn (): ColumnDefinition {
    return sortableColumn('id', 'agencies.headers.name');
  }

  get loginTitleColumn (): ColumnDefinition {
    return sortableColumn('loginTitle', 'agencies.headers.loginTitle');
  }

  get loginDomainColumn (): ColumnDefinition {
    return sortableColumn('loginDomain', 'agencies.headers.loginDomain');
  }

  get oemAgencyColumn (): ColumnDefinition {
    return sortableColumn('isOEMAgency', 'agencies.headers.oemAgency');
  }

  get timeZoneColumn (): ColumnDefinition {
    return sortableColumn('timeZone', 'agencies.headers.timeZone');
  }

  get currencyColumn (): ColumnDefinition {
    return sortableColumn('currency', 'agencies.headers.currency');
  }

  get editBtnsColumn (): ColumnDefinition {
    return sortableColumn('editBtn', '', false);
  }

  get defaultSorts (): Array<SortDescriptor> {
    return [{
      dataField: 'id',
      order: 'asc'
    }];
  }
}

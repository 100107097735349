import { ColumnDefinition } from 'components/TableColumn';
import { UpdateEventListener, FireableUpdateEventListener } from 'utils/UpdateEventListener';
import { ConversionListDTO } from 'core/conversion/Conversion';
import { CodePage } from 'components/CodePage/CodePage';
import styles from './conversionList.module.scss';
import i18n from 'i18next';
import _ from 'lodash';
import { ConversionManager, DefaultConversionManager } from 'core/conversion/ConversionManager';
import { SortableList, AbstractSortableList } from 'containers/Common/AbstractSortableList';

export interface ConversionListModel extends SortableList {
  readonly event: UpdateEventListener<ConversionListModel>;
  readonly state: ConversionListState;
  readonly searchString: string;
  getColumnDefinition (columnName): ColumnDefinition;
  handleOnSearch (searchString): void;
  onUnmount (handler): void;
  showCodeModal (conversionId: number): void;
  hideModal (): void;
}

export enum ConversionListColumns {
  NAME = 'nameIdColumn',
  TYPE = 'typeColumn',
  EDITBTNS = 'editBtnsColumn'
}

export type ConversionListProps = {
  readonly model: ConversionListModel;
};

export type ConversionListState = {
  readonly viewModel: any;
  readonly modalData: any;
};

export class DefaultConversionListModel extends AbstractSortableList implements ConversionListModel {
  event: FireableUpdateEventListener<ConversionListModel>;
  searchString: string;
  viewModel: any;
  modalData: any;
  conversions: Array<ConversionListDTO>;
  conversionManager: ConversionManager;

  constructor (conversions: Array<ConversionListDTO>, conversionManager: ConversionManager = new DefaultConversionManager()) {
    super(ConversionListColumns.NAME, 'desc');
    this.event = new FireableUpdateEventListener<ConversionListModel>();
    this.searchString = '';
    this.viewModel = [];
    this.conversions = conversions;
    this.conversionManager = conversionManager;
    this.updateViewModelData();
  }

  get state () {
    return {
      viewModel: this.viewModel,
      modalData: this.modalData
    };
  }

  showCodeModal = (conversionId: number) => {
    const conversion = this.conversions.find(conversion => conversion.id === conversionId);
    if (!conversion) {
      return;
    }
    this.modalData = {
      title: conversion.name,
      fullScreen: true,
      component: CodePage,
      componentProps: {
        codeSource: _.partial(this.conversionManager.getConversionCode.bind(this.conversionManager), conversionId),
        id: conversion.dmpConversionTrackingId,
        i18nWordingPrefix: 'conversionCode.labels'
      }
    };
    this.updateState();
  }

  hideModal = () => {
    this.modalData = undefined;
    this.updateState();
  }

  handleOnSearch = (searchString) => {
    this.searchString = searchString;
    this.updateViewModelData();
  }

  updateViewModelData () {
    this.viewModel = this.conversions
    .filter(conversion => {
      const searchMatch = conversion.name.toLowerCase().includes(this.searchString.toLowerCase()) ||
                          conversion.id.toString().includes(this.searchString);
      return searchMatch;
    }).map(conversion => {
      return {
        id: conversion.id,
        name: conversion.name,
        [ConversionListColumns.NAME]: conversion.id,
        [ConversionListColumns.TYPE]: i18n.t(`conversionType.labels.${_.camelCase(conversion.type)}`)
      };
    });
    this.updateState();
  }

  getColumnDefinition (columnName): ColumnDefinition {
    const columnClassGetter = () => {
      return styles[columnName];
    };
    let text = `conversionList.headers.${columnName}`;
    let sort = true;
    if (columnName === ConversionListColumns.EDITBTNS) {
      text = '';
      sort = false;
    }
    return {
      sort,
      text,
      dataField: columnName,
      onSort: this.handleSort,
      classes: columnClassGetter,
      headerClasses: columnClassGetter
    };
  }

  onUnmount (handler) {
    this.event.remove(handler);
  }

  updateState () {
    this.event.fireEvent(this);
  }
}

import client from './RestClient';
import { CampaignGroupChannel } from 'core/campaignGroup/CampaignGroup';

export type BindingCreateRequest = {
  goCampaignChannelIds?: string[],
  goBindingIds?: string[],
  goCreativeIds: string[],
  active: boolean,
  draft: boolean
}[];

export interface BindingWebService {
  createBinding (channel: CampaignGroupChannel, createRequest: BindingCreateRequest): Promise<void>;
  updateBinding (channel: CampaignGroupChannel, updateRequest: BindingCreateRequest): Promise<void>;
}

export class RestfulBindingWebService implements BindingWebService {
  restClient: any;

  constructor (restClient: any = client) {
    this.restClient = restClient;
  }

  async createBinding (channel: CampaignGroupChannel, createRequest: BindingCreateRequest): Promise<void> {
    return this.restClient.post(`/v2/go-binding/${channel}/create`, createRequest);
  }

  async updateBinding (channel: CampaignGroupChannel, updateRequest: BindingCreateRequest): Promise<void> {
    return this.restClient.put(`/v2/go-binding/${channel}/update`, updateRequest);
  }
}

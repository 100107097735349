import React from 'react';
import classNames from 'classnames/bind';
import styles from './creativeSummaryStateContent.module.scss';
import { CreativeSummaryStateContentProps, CreativeSummaryStateContentState } from './CreativeSummaryStateContentModel';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { StateDataContext } from 'containers/Common/StateDataContext';
import i18n from 'i18n';
import { CreativeManagementStateContext } from './CreativeManagementStateContext';

export class CreativeSummaryStateContent extends React.Component<CreativeSummaryStateContentProps, CreativeSummaryStateContentState> {

  handler: number;
  cssClassNames: any;

  constructor (props) {
    super(props);
    this.cssClassNames = classNames.bind(styles);
    this.handler = this.props.model.event.add((model) => {
      this.setState(model.state);
    });
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add((model) => {
        this.setState(model.state);
      });
    }
  }

  componentWillUnmount () {
    this.props.model.event.remove(this.handler);
  }

  rowRender (creatives) {
    if (creatives.length === 0) {
      return (
        <div className={styles.floatRight}>
          {i18n.t('summary.titles.noData')}
        </div>
      );
    }
    return creatives.map(creative => {
      const creativeStatusClassName = this.cssClassNames('stateContent', {
        active: creative.isActiveBinding
      });
      return (
        <div key={creative.id} className={styles.floatRight} >
          <div className={styles.creativeName}>
            {creative.name}
          </div>
          <div className={creativeStatusClassName}>
            {creative.isActiveBinding ?
              i18n.t('creativeOverviewStateContent.buttons.active')
              :
              i18n.t('creativeOverviewStateContent.buttons.deactive')
            }
          </div>
        </div>
      );
    });
  }

  campaignRender (summaryData) {
    if (!summaryData) {
      return <div/>;
    }

    return summaryData.map(campaign => {
      const key = campaign.draftId ? `draft_${campaign.draftId}` : campaign.id;
      return (
        <div key={key} className={styles.content} >
          <div className={styles.nameArea}><div>{campaign.name}</div></div>
          <div className={styles.creativeArea}>{this.rowRender(campaign.creatives)}</div>
        </div>
      );
    });
  }

  summaryRender (summaryData) {
    const onFilterStatus = () => {
      this.props.model.handleOnStateFilterClicked(undefined);
    };
    const onFilterAtcive = () => {
      this.props.model.handleOnStateFilterClicked(true);
    };
    const onFilterDeactive = () => {
      this.props.model.handleOnStateFilterClicked(false);
    };
    const allFilterClassName = this.cssClassNames('stateFilter', {
      active: this.props.model.state.currentBindStateFilter === undefined
    });

    const activeFilterClassName = this.cssClassNames('stateFilter', {
      active: this.props.model.state.currentBindStateFilter === true
    });

    const deactiveFilterClassName = this.cssClassNames('stateFilter', {
      active: this.props.model.state.currentBindStateFilter === false
    });
    return (
      <div className={styles.creativeSummaryStateContent}>
        <div className={styles.title}>
          <div className={styles.titlePrefix}/>
          {i18n.t('creative.change.confirmation')}
        </div>
        <div className={styles.details}>
          <div className={styles.header}>
            <div>{i18n.t('campaign.name')}</div>
            <div>
              <div className={styles.floatRight} >
                <div className={allFilterClassName} onClick={onFilterStatus}>
                  {i18n.t('creative.list.filter.all')}
                </div>
                <div className={activeFilterClassName} onClick={onFilterAtcive}>
                  {i18n.t('creativeOverviewStateContent.buttons.active')}
                </div>
                <div className={deactiveFilterClassName} onClick={onFilterDeactive}>
                  {i18n.t('creativeOverviewStateContent.buttons.deactive')}
                </div>
                {i18n.t('creative.binding.status')}
              </div>
            </div>
          </div>
          {this.campaignRender(summaryData)}
        </div>
        <div className={styles.modifyHint}>
          {i18n.t('campaignCreativeList.labels.cannotEditCustomCreativeHint')}
        </div>
      </div>
    );
  }

  render () {
    const model = this.props.model;
    return (
      <StateDataContext.Consumer>
        {
          (stateContext: CreativeManagementStateContext) => {
            model.setStateContext(stateContext);
            return (
              <div>
                {model.state.loading && <LoadingIndicator />}
                {this.summaryRender(model.state.filteredData)}
              </div>
            );
          }
        }
      </StateDataContext.Consumer>
    );
  }
}

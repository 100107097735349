import React, { useMemo } from 'react';
import i18n from 'i18n';
import styles from './audienceInfo.module.scss';
import { Status } from 'components/Status/Status';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { numberWithCommas } from 'utils/StringUtil';
import { LoadingCircle } from 'components/LoadingCircle/LoadingCircle';
import classnames from 'classnames/bind';
const cx = classnames.bind(styles);

export const AudienceInfo: React.FC<{
  layoutDirection?: 'row' | 'column'
  loading: boolean,
  lowerBound: number,
  upperBound: number,
  threshold: number,
  estimateReady: boolean
}> = ({
  layoutDirection = 'row',
  loading,
  lowerBound,
  upperBound,
  threshold,
  estimateReady
}) => {

  const viewData = useMemo(() => {
    if (!estimateReady) {
      return {
        status: {
          color: 'warning',
          label: 'Not Ready'
        }
      };
    }
    if (lowerBound < threshold) {
      return {
        status: {
          color: 'danger',
          label: 'Narrow'
        },
        warnig: i18n.t('audienceInfo.labels.audienceSizeTooSmall')
      };
    }
    return {
      status: {
        color: 'success',
        label: 'Broad'
      }
    };
  }, [
    lowerBound,
    threshold,
    estimateReady
  ]);

  const audienceInfoClass = cx(styles.audienceInfo, {
    row: layoutDirection === 'row'
  });
  const audienceSizeConclusionClass = cx(styles.audienceSizeConclusion, {
    row: layoutDirection === 'row'
  });
  const audienceSizeClass = cx(styles.audienceSize, {
    row: layoutDirection === 'row'
  });
  return (
    <div className={audienceInfoClass}>
      <div className={audienceSizeConclusionClass}>
        <span>{i18n.t('audienceInfo.labels.audienceSelection')}</span>
        <Status {...viewData.status}/>
      </div>
      <div className={audienceSizeClass}>
        <span>{i18n.t('audienceInfo.labels.audienceEstimatedSize')}</span>
        <div className={styles.sizeContainer}>
          <span>{
            !estimateReady ?
            'N/A' :
            `${numberWithCommas(lowerBound)} - ${numberWithCommas(upperBound)}`}
          </span>
          {loading && <LoadingCircle className={styles.loadingCircle} width={15} height={15} />}
          {viewData.warnig &&
            <IconWithTooltip
              icon={faInfoCircle}
              tooltipProps={{
                id: 'audienceSizeWarning',
                tooltip: viewData.warnig
              }}
            />
          }
        </div>
      </div>
    </div>
  );
};

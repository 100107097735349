import { CampaignGroup, CampaignGroupChannel } from 'core/campaignGroup/CampaignGroup';
import { BillingEvent, TiktokAdGroup, OptimizationGoal, BidStrategy, TiktokPlacementMapping, TiktokGenderMapping, ADGROUP_DEFAULT_AGE_MAX, ADGROUP_DEFAULT_AGE_MIN } from 'core/tiktokAdGroup/TiktokAdGroup';
import { Order } from 'core/order/Order';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { TiktokAdGroupFormModelData, useTiktokCreateAdGroupFormModel, useTiktokEditAdGroupFormModel } from './steps/TiktokAdGroupFormModel';
import { DefaultTiktokAdGroupManager, TiktokAdGroupManager } from 'core/tiktokAdGroup/TiktokAdGroupManager';
import { useRouteMatch } from 'react-router-dom';
import moment from 'moment';
import i18n from 'i18n';
import _ from 'lodash';
import { getDayOfWeekLabelByValue } from 'components/Dayparts/Dayparts';
import { SelectOptions } from 'components/common/commonType';
import { DefaultAdRequestSourceManager } from 'core/adRequestSource/AdRequestSourceManager';
import { DefaultLimitationManager } from 'core/limitation/LimitationManager';
import { getTiktokLocationIDByAlpha3 } from 'utils/CountryUtil';
import { DynamicBreadcrumb } from 'components/Breadcrumbs/DynamicBreadcrumbs';
import { SummaryTitleColor } from 'components/SummaryDetail/SummaryDetail';
import { useCallAPI } from 'hooks/useCallAPI';
import { validateEmpty, validateMinimum } from 'utils/ValidateUtils';
import { renderOverBudgetWording } from 'containers/GoCampaigns/FormHintRenderFunctions';
import { formatPriceWithCurrency, getPriceValue } from 'helper/CurrencyHelper';
import { tiktokAgeMinOptions, tiktokAgeMaxOptions, getTiktokAgeGroupsByAgeRange } from 'core/limitation/goCampaignTAOptions';
import coreContext from 'contexts/coreContext';
import { DefaultEditLimitationModel, EditLimitationModel } from 'containers/Limitations/EditLimitationModel';
import { adGroupInventorySetting } from 'containers/Limitations/LimitationSetting/LimitationSettingData';
import { OPERATE } from 'enum/Operate';
import { SavedTargeting } from 'core/limitation/Limitation';
import { EstimatedAudience } from 'core/goSegment/GoSegment';
import { useGetSavedTargeting } from 'hooks/useGetSavedTargeting';
import { DraftManager, TiktokAdGroupDraftManager } from 'core/draft/DraftManager';
import { toast } from 'react-toastify';

const MAIN_STEP_INDEX = 0;

export enum TiktokAdGroupMainStepTab {
  BASIC,
  TARGETING
}

export type TiktokAdGroupFormData = {
  advertiser_id: number;
  adgroup_name: string;
  budget: number | string;
  schedule_start_time: string;
  schedule_end_time: string;
  optimize_goal: OptimizationGoal;
  billing_event: BillingEvent;
  bid_type: BidStrategy;
  bid?: number;
  targeting?: {
    [key: string]: any
  },
  ageMin?: any,
  ageMax?: any,
  gender?: any,
  location?: any,
  placement?: any,
  operation_system?: any,
  audience?: any,
  excluded_audience?: any,
  dayPart?: { [key: string]: string[] | number[] | string };
};

export type AdGroupSetupFlowPageModelData = {
  adGroupId?: string,
  objectType: 'adGroup' | 'draft',
  operate: 'create' | 'edit',
  title: string,
  loading: boolean,
  finished: boolean,
  tiktokCountries: SelectOptions[],
  goSegments: SelectOptions[],
  adGroup: TiktokAdGroupFormData | undefined,
  initAdGroup: TiktokAdGroupFormData | undefined,
  redirectPath: string | undefined,
  canEditOptimizationGoal: boolean,
  canEditBidStrategy: boolean,
  otherAdGroupMinBudget: number,
  breadcrumbs: any[],
  limitationModel?: EditLimitationModel,
  audienceLowestThreshold: number,
  savedTAList: SavedTargeting[],
  appliedSavedTAInfo?: SelectOptions,
  showTAManagement: boolean,
  estimatedData?: EstimatedAudience,
  showPublishBindingFailed: boolean,
  defaultOptimizationGoal?: OptimizationGoal,
  setFinished: (finish: boolean) => void,
  onSaveDraft?: (adGroup: TiktokAdGroupFormData) => void,
  setEsitimatedData: (estimatedData: EstimatedAudience) => void,
  onImportSavedTA: (savedTAInfo: SelectOptions, limitationValue) => void,
  onDeleteSavedTA: (deletedTAId: number) => void,
  setShowTAManagement: (show: boolean) => void,
  validate: (adGroup: TiktokAdGroupFormData) => any,
  submit: () => Promise<void>,
  setAdGroup: (adGroup: TiktokAdGroupFormData) => void,
  setInitAdGroup: (adGroup: TiktokAdGroupFormData) => void,
  getSummaryData: (adGroup: TiktokAdGroupFormData) => any,
  getTASummaryData: (limitationValue: any) => any,
  setRedirectPath: (redirectPath?: string) => void,
  getBasicFormConfig: (adGroup: TiktokAdGroupFormData) => any,
  backToCampaignGroupDetail: () => void,
  adGroupFormModel: (order: Order, campaignGroup: CampaignGroup, adGroup: TiktokAdGroupFormData, tiktokAdGroupManager: TiktokAdGroupManager) => TiktokAdGroupFormModelData
};

const defaultTiktokAdGroupManager = new DefaultTiktokAdGroupManager();
const defaultDraftManager = new TiktokAdGroupDraftManager();

const useTiktokAdGroupSetupFlowPageModel = (
  operate,
  order: Order,
  campaignGroup: CampaignGroup,
  otherTiktokAdGroupList: TiktokAdGroup[],
  tiktokAdGroupManager: TiktokAdGroupManager = defaultTiktokAdGroupManager
) => {

  const [appliedSavedTAInfo, setAppliedSavedTAInfo] = useState<SelectOptions | undefined>();
  const [redirectPath, setRedirectPath] = useState<string | undefined>();
  const [finished, setFinished] = useState<boolean>(false);
  const [tiktokCountries, setTiktokCountries] = useState<SelectOptions[]>([]);
  const [goSegments, setGoSegments] = useState<SelectOptions[]>([]);
  const [estimatedData, setEsitimatedData] = useState<EstimatedAudience | undefined>();
  const {
    loading,
    callAPIs
  } = useCallAPI();

  const [showTAManagement, setShowTAManagement] = useState(false);

  const inventorySetting = useMemo(() => {
    return adGroupInventorySetting(operate, _.get(campaignGroup, 'objective', ''), tiktokCountries, goSegments);
  }, [operate, campaignGroup, tiktokCountries, goSegments]);

  const fetchTiktokCountries = useCallback(async () => {
    try {
      const tiktokCountries = await new DefaultAdRequestSourceManager().getTiktokCountries();
      setTiktokCountries(tiktokCountries);
      return tiktokCountries;
    } catch (e) {}
  }, []);

  const fetchTiktokSegments = useCallback(async () => {
    try {
      const goSegments = await new DefaultAdRequestSourceManager().getGoSegments(order.advertiserId, CampaignGroupChannel.TIKTOK);
      setGoSegments(goSegments);
    } catch (e) {}
  }, [order.advertiserId]);

  const backToCampaignGroupDetail = () => {
    const redirectPath =
      `/orders/${order.orderNumber}/campaign-groups/${campaignGroup.groupId}`;
    setRedirectPath(redirectPath);
  };

  const otherAdGroupMinBudget = useMemo(() => {
    if (!otherTiktokAdGroupList || otherTiktokAdGroupList.length === 0) {
      return 0;
    }
    const minBudget = otherTiktokAdGroupList.reduce((acc, tiktokAdGroup) => {
      const startDateMoment = moment(tiktokAdGroup.schedule_start_time);
      const endDateMoment = moment(tiktokAdGroup.schedule_end_time);
      const scheduleDateCount = endDateMoment.diff(startDateMoment, 'days') + 1;
      return acc +
        tiktokAdGroupManager.getMinBudgetOfAdGroup(
          campaignGroup.currencyRate,
          scheduleDateCount,
          order
        );
    }, 0);

    return minBudget;
  }, [otherTiktokAdGroupList, campaignGroup, order, tiktokAdGroupManager]);

  const validate = (initAdGroup, uniqueAdGroupNames, adGroup) => {
    const parentBidStrategy = _.get(campaignGroup, 'tiktok.bid_type');
    const adGroupBidStrategy = _.get(adGroup, 'bid_type');
    const startDateMoment = moment(adGroup.schedule_start_time);
    const endDateMoment = moment(adGroup.schedule_end_time);
    const scheduleDateCount = endDateMoment.diff(startDateMoment, 'days') + 1;
    const minBudget = tiktokAdGroupManager.getMinBudgetOfAdGroup(
      campaignGroup.currencyRate,
      scheduleDateCount,
      order
    );
    const validateBudget = () => {
      const durationUnchanged = _.get(initAdGroup, 'schedule_start_time') === adGroup.schedule_start_time &&
      _.get(initAdGroup, 'schedule_end_time') === adGroup.schedule_end_time;

      const initBudget = +(_.get(initAdGroup, 'budget', '0'));
      const budget = +(_.get(adGroup, 'budget', 0).toString());
      const budgetNotNeedCheck = !initAdGroup.isDraft &&
        initBudget === budget &&
        durationUnchanged;
      if (budgetNotNeedCheck &&
        validateMinimum(_.get(adGroup, 'budget'), 1) === undefined
      ) {
        return;
      }
      const minBudgetChange = getPriceValue(order.currency, (7 * campaignGroup.currencyRate) / (1 - order.orderMargin - order.sysMargin));
      if (Math.abs(initBudget - budget) < minBudgetChange) {
        if (budgetNotNeedCheck) {
          return;
        } else if (!initAdGroup.isDraft) {
          return i18n.t('adGroupSetupFlow.mainStep.errors.minCampaignDiff', { budget: formatPriceWithCurrency(order.currency, minBudgetChange) });
        }
      }
      const totalBudget = initAdGroup.isDraft ?
        campaignGroup.budgetBalance :
        campaignGroup.budgetBalance + initBudget;
      const remainBudget = totalBudget - budget;
      if (remainBudget < 0) {
        return renderOverBudgetWording(order.currency, totalBudget);
      }
      return validateMinimum(
        adGroup.budget,
        getPriceValue(order.currency, minBudget),
        'campaign.descriptions.smallerThanBudgetMinimum',
        order.currency
      );
    };
    const validateCampaignBudget = () => {
      const tiktokCampaignMinBudget = otherAdGroupMinBudget + minBudget;
      if (tiktokCampaignMinBudget > +_.defaultTo(campaignGroup.budget, 0)) {
        return i18n.t('adGroupSetupFlow.mainStep.hints.tiktokCampaignBudget', { budget: formatPriceWithCurrency(order.currency, tiktokCampaignMinBudget) });
      }
    };
    const validateAdGroupName = (adGroupName) => {
      if (_.find(uniqueAdGroupNames, (uniqueName) => uniqueName === adGroupName)) {
        return i18n.t('adGroupSetupFlow.mainStep.hints.tiktokAdGroupNameUniqueness');
      } else {
        return validateEmpty(adGroupName);
      }
    };

    return _.omitBy({
      adgroup_name: validateAdGroupName(adGroup.adgroup_name),
      budget: campaignGroup.budget ? validateCampaignBudget() : validateBudget(),
      bid: (
        parentBidStrategy && parentBidStrategy === BidStrategy.BID_TYPE_CUSTOM) ||
        adGroupBidStrategy === BidStrategy.BID_TYPE_CUSTOM ?
          validateMinimum(adGroup.bid, 1, 'campaign.descriptions.priceMinimum', order.currency) :
          undefined
    }, _.isEmpty);
  };

  const getTASummaryData = (targeting) => {
    const ageMin = targeting.include.find(limitation => limitation.type === 'age_min');
    const ageMax = targeting.include.find(limitation => limitation.type === 'age_max');
    const gender = targeting.include.find(limitation => limitation.type === 'genders');
    const genderValue = Object.keys(TiktokGenderMapping).find(key => TiktokGenderMapping[key] === (gender ? gender.value : -1)) ||
      Object.keys(TiktokGenderMapping)[0];
    const ageMinOption = tiktokAgeMinOptions.find(option => option.value === (ageMin ? ageMin.value : ADGROUP_DEFAULT_AGE_MIN));
    const ageMaxOption = tiktokAgeMaxOptions.find(option => option.value === (ageMax ? ageMax.value : ADGROUP_DEFAULT_AGE_MAX));
    const ageGroupsHint = getTiktokAgeGroupsByAgeRange(_.get(ageMinOption, 'value', 0), _.get(ageMaxOption, 'value', 0))
      .map(group => group.label)
      .join(', ');

    const getLimitationContent = (limitationData) => {
      if (!limitationData) {
        return undefined;
      }

      const inventorySettingDataMap = _.keyBy(inventorySetting, 'name');
      const limitationFormatter = (i18nPrefix, option) => {
        const i18nKey = i18nPrefix ? `${i18nPrefix}.${option.value.toLowerCase()}` : option.value.toString();
        return i18n.exists(i18nKey) ? i18n.t(i18nKey) : option.value;
      };
      return _.compact(_.flatten(limitationData.map((data) => {
        if (!data.value || data.value.length === 0) {
          return undefined;
        }

        const settingData = inventorySettingDataMap[data.type];
        if (!settingData) {
          return undefined;
        }

        const limitationWithI18nFormatter = _.partial(limitationFormatter, settingData.i18nPrefix);
        if (data.type === 'tiktok_location') {
          const tiktokCountriesId = tiktokCountries.map(tiktokCountryOption => tiktokCountryOption.value);
          const countries: any[] = [];
          const provinces: any[] = [];
          data.value.forEach((location) => {
            if (tiktokCountriesId.includes(location.value)) {
              countries.push(location);
            } else {
              provinces.push(location);
            }
          });

          return [{
            label: i18n.t('adGroupSetupFlow.summaryStep.countries'),
            value: countries.map(limitationWithI18nFormatter).join(', ')
          }, {
            label: i18n.t('adGroupSetupFlow.summaryStep.provinces'),
            value: provinces.map(limitationWithI18nFormatter).join(', ')
          }];
        } else {
          return {
            label: settingData.title ? i18n.t(settingData.title) : data.type,
            value: Array.isArray(data.value) ?
              data.value.map(limitationWithI18nFormatter).join(', ') :
              data.value
          };
        }
      })));
    };
    return _.omitBy({
      general: {
        title: i18n.t('campaignSummary.titles.general'),
        titlePrefixColor: SummaryTitleColor.DARK,
        content: _.compact([{
          label: i18n.t('limitation.labels.age'),
          value: `${_.get(ageMinOption, 'label', targeting.ageMin)} ~ ${_.get(ageMaxOption, 'label', targeting.ageMax)}`,
          hint: i18n.t('limitation.hints.ageGroups', { groups: ageGroupsHint })
        }, {
          label: i18n.t('limitation.labels.gender'),
          value: i18n.t(`targeting.tiktok.gender.${genderValue.toLowerCase()}`)
        }])
      },
      include: {
        title: i18n.t('campaignSummary.titles.inc'),
        titlePrefixColor: SummaryTitleColor.GREEN,
        content: getLimitationContent(targeting.include)
      }, exclude: {
        title: i18n.t('campaignSummary.titles.exc'),
        titlePrefixColor: SummaryTitleColor.RED,
        content: getLimitationContent(targeting.exclude)
      }
    }, _.isEmpty);
  };

  const getSummaryData = (adGroup) => {
    if (!adGroup) {
      return undefined;
    }

    const getDayPartValue = (dayPart) => {
      let dayPartValue = _.omitBy(_.omit(dayPart, 'enabled'), _.isEmpty);
      return Object.keys(dayPartValue).map(day => {
        return `${getDayOfWeekLabelByValue(parseInt(day, 10))},${i18n.t('daypart.labels.hourUnit')}: ${dayPartValue[day].join(', ')}`;
      }).join('\r\n');
    };

    const basicSummary = {
      title: i18n.t('adGroupSetupFlow.summaryStep.basicTitle'),
      backStep: MAIN_STEP_INDEX,
      backSubStep: TiktokAdGroupMainStepTab.BASIC,
      data: _.omitBy({
        general: {
          title: i18n.t('adGroupSetupFlow.mainStep.fieldset.basicTitle'),
          content: _.compact([
            {
              label: i18n.t('adGroupSetupFlow.mainStep.field.name'),
              value: adGroup.adgroup_name
            },
            campaignGroup.budget ?
              undefined : {
                label: i18n.t('adGroupSetupFlow.mainStep.field.budget'),
                value: adGroup.budget
              },
            {
              label: i18n.t('adGroupSetupFlow.mainStep.field.dateRange'),
              value: `${moment(adGroup.schedule_start_time).format('YYYY-MM-DD HH:mm:ss')} ~ ${moment(adGroup.schedule_end_time).format('YYYY-MM-DD HH:mm:ss')}`
            }
          ])
        },
        optimization: {
          title: i18n.t('adGroupSetupFlow.mainStep.fieldset.optimizationTitle'),
          content: _.compact([
            {
              label: i18n.t('adGroupSetupFlow.mainStep.field.optimizationGoal'),
              value: i18n.t(`adGroup.optimizationGoal.${adGroup.optimize_goal.toLowerCase()}`)
            },
            _.get(adGroup, 'promoted_object.custom_event_type') ? {
              label: i18n.t('adGroupSetupFlow.mainStep.field.promoted_object_custom_event_type'),
              value: _.startCase(_.lowerCase(_.get(adGroup, 'promoted_object.custom_event_type')))
            } : undefined,
            adGroup.bid ? {
              label: i18n.t('adGroupSetupFlow.mainStep.field.bidControl'),
              value: formatPriceWithCurrency(order.currency, +(adGroup.bid))
            } : undefined,
            {
              label: i18n.t('adGroupSetupFlow.mainStep.field.bidStrategy'),
              value: i18n.t(`adGroup.bidStrategy.${adGroup.bid_type.toLowerCase()}`)
            },
            {
              label: i18n.t('adGroupSetupFlow.mainStep.field.billingEvent'),
              value: i18n.t(`adGroup.billingEvent.${adGroup.billing_event.toLowerCase()}`)
            },
            adGroup.dayPart && {
              label: i18n.t('campaignSummary.labels.dayPart'),
              value: getDayPartValue(adGroup.dayPart)
            }
          ])
        }
      }, _.isUndefined)
    };

    const targetingSummary = () => {
      if (!adGroup.targeting || _.isEmpty(adGroup.targeting)) {
        return undefined;
      }

      const targeting = adGroup.targeting;
      return {
        title: i18n.t('adGroupSetupFlow.summaryStep.targetingTitle'),
        backStep: MAIN_STEP_INDEX,
        backSubStep: TiktokAdGroupMainStepTab.TARGETING,
        data: getTASummaryData(targeting)
      };
    };

    return ({
      basicSummary,
      targetingSummary
    });
  };

  const {
    loading: loadingSavedTAList,
    savedTAList,
    fetchSavedTargeting
  } = useGetSavedTargeting(order.advertiserId, CampaignGroupChannel.TIKTOK);

  const onDeleteSavedTA = (deletedTAId: number) => {
    if (appliedSavedTAInfo && appliedSavedTAInfo.value === deletedTAId) {
      setAppliedSavedTAInfo(undefined);
    }
    fetchSavedTargeting();
  };

  return {
    loading: loading || loadingSavedTAList,
    savedTAList,
    tiktokCountries,
    redirectPath,
    finished,
    goSegments,
    inventorySetting,
    otherAdGroupMinBudget,
    audienceLowestThreshold: 3000000,
    showTAManagement,
    appliedSavedTAInfo,
    estimatedData,
    setEsitimatedData,
    validate,
    callAPIs,
    setFinished,
    onDeleteSavedTA,
    setRedirectPath,
    setShowTAManagement,
    getSummaryData,
    getTASummaryData,
    fetchTiktokCountries,
    fetchTiktokSegments,
    backToCampaignGroupDetail,
    setAppliedSavedTAInfo
  };
};

export const useCreateTiktokAdGroupSetupFlowPageModel = (
  order: Order,
  campaignGroup: CampaignGroup,
  tiktokAdGroupList: TiktokAdGroup[],
  tiktokAdGroupManager: TiktokAdGroupManager = defaultTiktokAdGroupManager
): AdGroupSetupFlowPageModelData => {

  const {
    callAPIs,
    fetchTiktokCountries,
    fetchTiktokSegments,
    validate,
    setAppliedSavedTAInfo,
    setShowTAManagement,
    otherAdGroupMinBudget,
    tiktokCountries,
    goSegments,
    inventorySetting,
    audienceLowestThreshold,
    ...basicProps
  } = useTiktokAdGroupSetupFlowPageModel(
    'create', order, campaignGroup, tiktokAdGroupList, tiktokAdGroupManager
  );

  const defaultOptimizationGoal = useMemo(() => {
    // TODO if campaign group turn on budget optimize, it should has optimize_goal!
    const allList = tiktokAdGroupList ? tiktokAdGroupList : [];
    const notDraftList = allList.filter(adGroup => !adGroup.draftId);
    if (notDraftList.length === 0 || !campaignGroup.autoOptimise) {
      return undefined;
    }

    return notDraftList[0].optimize_goal;
  }, [tiktokAdGroupList, campaignGroup.autoOptimise]);

  const initDateRange = useMemo(() => {
    const now = moment().startOf('hour');
    const orderStartDate = moment(order.startDate);
    const startTime = now.isBefore(orderStartDate) ? orderStartDate : now;
    const endTime = moment.min(
        moment(order.endDate)
          .add(1, 'days')
          .subtract(1, 'seconds'),
          moment(startTime).add(91, 'days').endOf('day')
      );
    return {
      startTime: startTime.format('YYYY-MM-DD HH:mm:ss'),
      endTime: endTime.format('YYYY-MM-DD HH:mm:ss')
    };
  }, [order]);

  const optimizeGoal = defaultOptimizationGoal ? defaultOptimizationGoal : tiktokAdGroupManager.getDefaultOptimizationGoal(campaignGroup);
  const parentBidStrategy = _.get(campaignGroup, 'tiktok.bid_type');
  const campaignId = _.get(campaignGroup, 'tiktok.campaign_id');
  const [adGroup, setAdGroup] = useState<TiktokAdGroupFormData>({
    adgroup_name: campaignGroup.name,
    advertiser_id: _.get(campaignGroup, 'tiktok.advertiser_id'),
    schedule_start_time: initDateRange.startTime,
    schedule_end_time: initDateRange.endTime,
    budget: '',
    optimize_goal: optimizeGoal,
    billing_event: tiktokAdGroupManager.getDefaultBillingEvent(campaignGroup),
    bid_type: parentBidStrategy ? parentBidStrategy : BidStrategy.BID_TYPE_NO_BID,
    targeting: {
      include: [{
        type: 'tiktok_placement',
        value: [{
          label: TiktokPlacementMapping.TIKTOK,
          value: TiktokPlacementMapping.TIKTOK
        }]
      }]
    },
    dayPart: undefined
  });
  const [initAdGroup, setInitAdGroup] = useState(adGroup);
  const [uniqueAdGroupNames, setUniqueAdGroupNames] = useState<string[]>([]);
  const core = useContext(coreContext);

  const limitationModel = useMemo(() => {
    // const optimizationGoal = adGroup.optimize_goal;
    return core && adGroup.targeting ? new DefaultEditLimitationModel(
      inventorySetting,
      adGroup.targeting,
      {
        need: [OPERATE.INCLUDE],
        notNeed: [OPERATE.EXCLUDE]
      },
      core.addonFeatureManager.addonFeature,
      _.partial(setShowTAManagement, true),
      { include: ['tiktok_location', 'tiktok_placement'] },
      {
        channel: CampaignGroupChannel.TIKTOK,
        audienceLowestThreshold,
        accountId: adGroup.advertiser_id.toString(),
        estimatedRequiredFields: ['location'],
        channelTargetingGetter: (limitationValue) => ({
          campaign_id: campaignId,
          advertiser_id: adGroup.advertiser_id,
          optimize_goal: adGroup.optimize_goal,
          ...tiktokAdGroupManager.getAdGroupTargeting(limitationValue)
        })
      }
    ) : undefined;
  }, [campaignId, adGroup.targeting, adGroup.advertiser_id, adGroup.optimize_goal, core, inventorySetting, audienceLowestThreshold, setShowTAManagement, tiktokAdGroupManager]);

  useEffect(() => {
    callAPIs(
      [tiktokAdGroupManager.getUniqueAdGroupNames.bind(tiktokAdGroupManager, campaignId)],
      (names: string[]) => {
        setUniqueAdGroupNames(names);
      }
    );
  }, [campaignId, callAPIs, tiktokAdGroupManager]);

  const fetchLimitationPreset = useCallback(async () => {
    try {
      return await new DefaultLimitationManager().getLimitationPreSet('campaign');
    } catch (e) {}
  }, []);

  useEffect(() => {
    const findGeoLocationsOfPreset = (preset, tiktokCountries) => {
      const countryPreset = preset.find(limitation => limitation.type === 'geography');
      const countryPresetCodes = _.compact(_.get(countryPreset, 'value', []).map(country => getTiktokLocationIDByAlpha3(country.value)));
      if (tiktokCountries) {
        const tiktokCountryPreset = tiktokCountries.filter(tiktokCountry => countryPresetCodes.includes(tiktokCountry.value));
        return tiktokCountryPreset.map(country => country.value);
      }
      return undefined;
    };
    callAPIs([fetchTiktokCountries, fetchLimitationPreset, fetchTiktokSegments], (tiktokCountries, limitationPreset) => {
      if (tiktokCountries) {
        const countries = findGeoLocationsOfPreset(_.get(limitationPreset, 'include', []), tiktokCountries);
        const changeAdGroupState = adGroup => ({
          ...adGroup,
          targeting: {
            ..._.get(adGroup, 'targeting'),
            include: [
              ..._.get(adGroup, 'targeting.include', []),
              {
                type: 'tiktok_location',
                value: countries.map(option => ({ label: option, value: option }))
              }
            ]
          }
        });
        setAdGroup(changeAdGroupState);
        setInitAdGroup(changeAdGroupState);
      }
    });
  }, [fetchTiktokCountries, fetchTiktokSegments, fetchLimitationPreset, callAPIs]);

  useEffect(() => {
    const changeAdGroupState = adGroup => ({
      ...adGroup,
      optimize_goal: defaultOptimizationGoal ? defaultOptimizationGoal : tiktokAdGroupManager.getDefaultOptimizationGoal(campaignGroup)
    });
    setAdGroup(changeAdGroupState);
    setInitAdGroup(changeAdGroupState);
  }, [defaultOptimizationGoal, campaignGroup, tiktokAdGroupManager]);

  const submit = async () => {
    callAPIs([tiktokAdGroupManager.createAdGroup.bind(tiktokAdGroupManager, campaignGroup, adGroup)], () => {
      basicProps.setFinished(true);
      basicProps.backToCampaignGroupDetail();
    });
  };

  const canEditBidStrategy = campaignGroup.autoOptimise ? false : true;
  const onImportSavedTA = (savedTAInfo: SelectOptions, limitationValue) => {
    if (!limitationModel) {
      return;
    }
    setAppliedSavedTAInfo(savedTAInfo);
    limitationModel.updateLimitationValue(limitationValue);
  };

  return {
    ...basicProps,
    audienceLowestThreshold,
    limitationModel,
    goSegments,
    tiktokCountries,
    objectType: 'adGroup',
    operate: 'create',
    adGroup,
    initAdGroup,
    otherAdGroupMinBudget,
    title: i18n.t('adGroupSetupFlow.mainStep.createTitle'),
    defaultOptimizationGoal,
    canEditOptimizationGoal: !defaultOptimizationGoal,
    canEditBidStrategy,
    showPublishBindingFailed: false,
    breadcrumbs: [
      { path: '/orders', breadcrumb: i18n.t('orderDetail.labels.title') },
      {
        path: '/orders/:orderNumber',
        breadcrumb: DynamicBreadcrumb,
        props: {
          label: _.get(order, 'projectName'),
          matchParam: 'orderNumber'
        }
      },
      {
        path: '/orders/:orderNumber/campaign-groups/:campaignGroupId',
        breadcrumb: DynamicBreadcrumb,
        props: {
          label: _.get(campaignGroup, 'name'),
          matchParam: 'campaignGroupId'
        }
      },
      { path: '/orders/:orderNumber/campaign-groups/:campaignGroupId/campaigns/new', breadcrumb: i18n.t('adGroupSetupFlow.mainStep.createTitle') }
    ],
    validate: _.partial(validate, initAdGroup, uniqueAdGroupNames),
    submit,
    setAdGroup,
    setInitAdGroup,
    onImportSavedTA,
    setShowTAManagement,
    getBasicFormConfig: () => {
      const orderStartDate = moment(_.get(order, 'startDate'));
      const thisHour = moment().startOf('hour').format('YYYY-MM-DD_HH:mm:ss');
      return {
        minDate: moment().isAfter(orderStartDate) ? thisHour : orderStartDate.format('YYYY-MM-DD_HH:mm:ss'),
        maxDate: moment(_.get(order, 'endDate')).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        isAdGroupStart: false,
        showBidStrategyFixHint: !canEditBidStrategy,
        showOptimiationGoalHint: campaignGroup.autoOptimise
      };
    },
    adGroupFormModel: useTiktokCreateAdGroupFormModel
  };
};

export const useEditTiktokAdGroupSetupFlowPageModel = (
  order: Order,
  campaignGroup: CampaignGroup,
  tiktokAdGroupList: TiktokAdGroup[],
  tiktokAdGroupManager: TiktokAdGroupManager = defaultTiktokAdGroupManager
): AdGroupSetupFlowPageModelData => {

  const match = useRouteMatch<{adGroupId: string}>();

  const adGroupId = match.params.adGroupId;
  const {
    callAPIs,
    validate,
    fetchTiktokCountries,
    fetchTiktokSegments,
    setRedirectPath,
    setAppliedSavedTAInfo,
    setShowTAManagement,
    otherAdGroupMinBudget,
    goSegments,
    tiktokCountries,
    inventorySetting,
    audienceLowestThreshold,
    ...basicProps
  } = useTiktokAdGroupSetupFlowPageModel(
    'edit',
    order,
    campaignGroup,
    tiktokAdGroupList.filter(tiktokAdGroup => tiktokAdGroup.adgroup_id.toString() !== adGroupId),
    tiktokAdGroupManager
  );

  const [adGroup, setAdGroup] = useState<TiktokAdGroupFormData | undefined>(undefined);
  const [initAdGroup, setInitAdGroup] = useState(adGroup);
  const [uniqueAdGroupNames, setUniqueAdGroupNames] = useState<string[]>([]);
  const parentBidStrategy = _.get(campaignGroup, 'tiktok.bid_strategy');
  const campaignId = _.get(campaignGroup, 'tiktok.campaign_id');

  useEffect(() => {
    if (!adGroupId) {
      return;
    }
    callAPIs([
      tiktokAdGroupManager.getAdGroup.bind(tiktokAdGroupManager, adGroupId),
      tiktokAdGroupManager.getUniqueAdGroupNames.bind(tiktokAdGroupManager, campaignId),
      fetchTiktokCountries,
      fetchTiktokSegments
    ], (tiktokAdGroup, adGroupNames: string[]) => {
      const adGroupFormData = {
        ...tiktokAdGroup,
        bid: tiktokAdGroup.bid ? tiktokAdGroup.bid : '',
        bid_type: parentBidStrategy ? parentBidStrategy : tiktokAdGroup.bid_type
      };
      setAdGroup(adGroupFormData);
      setInitAdGroup(adGroupFormData);
      setUniqueAdGroupNames(_.filter(adGroupNames, (name) => name !== _.get(adGroupFormData, 'adgroup_name')));
      if (_.get(campaignGroup, 'tiktok.campaign_id') !== tiktokAdGroup.campaign_id) {
        const redirectPath =
          `/orders/${order.orderNumber}/campaign-groups/${campaignGroup.groupId}/campaigns/${adGroupId}/edit/error404`;
        setRedirectPath(redirectPath);
      }
    });
  }, [campaignGroup, adGroupId, tiktokAdGroupManager, fetchTiktokCountries, callAPIs, campaignId, parentBidStrategy, order.orderNumber, setRedirectPath, fetchTiktokSegments]);

  const submit = async () => {
    if (!adGroup) {
      return;
    }
    callAPIs([tiktokAdGroupManager.editAdGroup.bind(tiktokAdGroupManager, adGroupId, adGroup)], () => {
      basicProps.setFinished(true);
      basicProps.backToCampaignGroupDetail();
    });
  };

  const core = useContext(coreContext);

  const targeting = _.get(adGroup, 'targeting');
  const advertiserId = _.get(adGroup, 'advertiser_id');
  const optimizeGoal = _.get(adGroup, 'optimize_goal');
  const limitationModel = useMemo(() => {
    // const optimizationGoal = adGroup.optimize_goal;
    return core && targeting && advertiserId ? new DefaultEditLimitationModel(
      inventorySetting,
      targeting,
      {
        need: [OPERATE.INCLUDE],
        notNeed: [OPERATE.EXCLUDE]
      },
      core.addonFeatureManager.addonFeature,
      _.partial(setShowTAManagement, true),
      { include: ['tiktok_location', 'tiktok_placement'] },
      {
        channel: CampaignGroupChannel.TIKTOK,
        audienceLowestThreshold,
        accountId: advertiserId.toString(),
        estimatedRequiredFields: ['location'],
        channelTargetingGetter: (limitationValue) => ({
          campaign_id: campaignId,
          advertiser_id: advertiserId,
          optimize_goal: optimizeGoal,
          ...tiktokAdGroupManager.getAdGroupTargeting(limitationValue)
        })
      }
    ) : undefined;
  }, [core, targeting, advertiserId, inventorySetting, setShowTAManagement, audienceLowestThreshold, campaignId, optimizeGoal, tiktokAdGroupManager]);

  const onImportSavedTA = (savedTAInfo: SelectOptions, limitationValue) => {
    if (!limitationModel) {
      return;
    }
    setAppliedSavedTAInfo(savedTAInfo);
    limitationModel.updateLimitationValue(limitationValue);
  };

  return {
    ...basicProps,
    audienceLowestThreshold,
    limitationModel,
    goSegments,
    tiktokCountries,
    objectType: 'adGroup',
    operate: 'edit',
    adGroup,
    initAdGroup,
    title: i18n.t('adGroupSetupFlow.mainStep.editTitle'),
    canEditOptimizationGoal: false,
    canEditBidStrategy: false,
    otherAdGroupMinBudget,
    showPublishBindingFailed: false,
    breadcrumbs: [
      { path: '/orders', breadcrumb: i18n.t('orderDetail.labels.title') },
      {
        path: '/orders/:orderNumber',
        breadcrumb: DynamicBreadcrumb,
        props: {
          label: _.get(order, 'projectName'),
          matchParam: 'orderNumber'
        }
      },
      {
        path: '/orders/:orderNumber/campaign-groups/:campaignGroupId',
        breadcrumb: DynamicBreadcrumb,
        props: {
          label: _.get(campaignGroup, 'name'),
          matchParam: 'campaignGroupId'
        }
      },
      {
        path: '/orders/:orderNumber/campaign-groups/:campaignGroupId/campaigns/:adGroupId/edit',
        breadcrumb: DynamicBreadcrumb,
        props: {
          prefix: i18n.t('common.labels.edit'),
          label: _.get(initAdGroup, 'adgroup_name'),
          matchParam: 'adGroupId'
        }
      }
    ],
    submit,
    validate: _.partial(validate, initAdGroup, uniqueAdGroupNames),
    setAdGroup,
    setInitAdGroup,
    setRedirectPath,
    onImportSavedTA,
    setShowTAManagement,
    getBasicFormConfig: (adGroup) => {
      const orderStartDate = moment(_.get(order, 'startDate'));
      const thisHour = moment().startOf('hour').format('YYYY-MM-DD_HH:mm:ss');
      let minDate;
      if (adGroup && adGroup.schedule_start_time) {
        minDate = moment(adGroup.schedule_start_time).isBefore() ? moment(adGroup.schedule_start_time).format('YYYY-MM-DD_HH:mm:ss') : thisHour;
      } else {
        minDate = moment().isAfter(orderStartDate) ? thisHour : orderStartDate.format('YYYY-MM-DD_HH:mm:ss');
      }
      return {
        minDate,
        maxDate: moment(_.get(order, 'endDate')).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        isAdGroupStart: adGroup && adGroup.schedule_start_time ? moment(adGroup.schedule_start_time).isBefore() : false,
        showBidStrategyFixHint: false,
        showOptimiationGoalHint: false
      };
    },
    adGroupFormModel: useTiktokEditAdGroupFormModel
  };
};

export const useEditTiktokAdGroupDraftSetupFlowPageModel = (
  order: Order,
  campaignGroup: CampaignGroup,
  tiktokAdGroupList: TiktokAdGroup[],
  draftManager: DraftManager = defaultDraftManager,
  tiktokAdGroupManager: TiktokAdGroupManager = defaultTiktokAdGroupManager
): AdGroupSetupFlowPageModelData => {

  const match = useRouteMatch<{draftId: string}>();
  const draftId = match.params.draftId;

  const data = useCreateTiktokAdGroupSetupFlowPageModel(
    order,
    campaignGroup,
    tiktokAdGroupList
  );

  const { loading, callAPIs } = useCallAPI();
  const { defaultOptimizationGoal, setAdGroup, setInitAdGroup } = data;
  const [showPublishBindingFailed, setShowPublishBindingFailed] = useState(false);

  useEffect(() => {
    callAPIs([() => draftManager.getDraft(draftId)], (draft) => {
      const optimizationGoalOptions = tiktokAdGroupManager.getOptimizationGoalOptions(campaignGroup);
      const optimizeGoalOption = optimizationGoalOptions.find(option => option.value === draft.optimize_goal);
      const optimizeGoal = defaultOptimizationGoal ?
        defaultOptimizationGoal :
        optimizeGoalOption ?
        optimizeGoalOption.value :
          tiktokAdGroupManager.getDefaultOptimizationGoal(campaignGroup);
      const billingEventOptions = tiktokAdGroupManager.getBilliingEventOptions(campaignGroup, optimizeGoal);
      const billingEventOption = billingEventOptions.find(option => option.value === draft.billing_event);
      const billingEvent = billingEventOption ?
        billingEventOption.value :
        tiktokAdGroupManager.getDefaultBillingEvent(campaignGroup);
      const newDraft = {
        ...draft,
        optimize_goal: optimizeGoal,
        billing_event: billingEvent
      };
      setAdGroup(newDraft);
      setInitAdGroup(newDraft);
    });
  }, [draftId, draftManager, setAdGroup, setInitAdGroup, callAPIs, defaultOptimizationGoal, tiktokAdGroupManager, campaignGroup]);

  const onSaveDraft = (adGroup: TiktokAdGroupFormData) => {
    callAPIs([() => draftManager.updateDraft(draftId, tiktokAdGroupManager.wrapAdGroupForServer(adGroup))], () => {
      toast.success(i18n.t('adGroupSetupFlow.messages.updateDraftSuccess'));
    }, () => {
      toast.error(i18n.t('adGroupSetupFlow.messages.updateDraftFailed'));
    });
  };

  const onPublishDraft = async () => {
    const adGroup = data.adGroup;
    if (!adGroup) {
      return;
    }
    callAPIs([draftManager.publishDraft.bind(draftManager, draftId, tiktokAdGroupManager.wrapAdGroupForServer(adGroup))], ({
      includeBinding,
      result
    }) => {
      if (result === 'SUCCESS') {
        if (includeBinding) {
          toast.success(i18n.t('adGroupSetupFlow.messages.publishL2L3Success'));
        } else {
          toast.success(i18n.t('adGroupSetupFlow.messages.publishL2Success'));
        }
        data.setFinished(true);
        data.backToCampaignGroupDetail();
      }
      if (result === 'PARTIAL_SUCCESS') {
        setShowPublishBindingFailed(true);
      } else if (result === 'FAIL') {
        toast.error(i18n.t('adGroupSetupFlow.messages.publishL2L3Failed'));
      }
    });
  };

  return {
    ...data,
    objectType: 'draft',
    title: i18n.t('campaign.labels.editCampaignDraftTitle'),
    loading: data.loading || loading,
    showPublishBindingFailed,
    breadcrumbs: [
      { path: '/orders', breadcrumb: i18n.t('orderDetail.labels.title') },
      {
        path: '/orders/:orderNumber',
        breadcrumb: DynamicBreadcrumb,
        props: {
          label: _.get(order, 'projectName'),
          matchParam: 'orderNumber'
        }
      },
      {
        path: '/orders/:orderNumber/campaign-groups/:campaignGroupId',
        breadcrumb: DynamicBreadcrumb,
        props: {
          label: _.get(campaignGroup, 'name'),
          matchParam: 'campaignGroupId'
        }
      },
      {
        path: '/orders/:orderNumber/campaign-groups/:campaignGroupId/drafts/:draftId/edit',
        breadcrumb: DynamicBreadcrumb,
        props: {
          prefix: i18n.t('common.labels.edit'),
          label: _.get(data.initAdGroup, 'adgroup_name'),
          matchParam: 'draftId'
        }
      }
    ],
    onSaveDraft,
    submit: onPublishDraft,
    getSummaryData: (adGroup: any) => {
      const summaryData = data.getSummaryData(adGroup);
      if (adGroup.bindings && adGroup.bindings.length > 0) {
        const now = moment();
        const isDeliverying = now.isAfter(moment(adGroup.schedule_start_time)) && now.isBefore(moment(adGroup.schedule_end_time));
        const isActive = adGroup.opt_status === 'ENABLE';
        const hasActiveBinding = adGroup.bindings.find(binding => binding.active) !== undefined;
        const showAlert = isDeliverying && isActive && hasActiveBinding;
        summaryData['draftBindingSummary'] = {
          title: i18n.t('adGroupSetupFlow.summaryStep.draftBindingTitle'),
          data: {
            draftBinding: {
              content: _.compact([
                {
                  label: i18n.t('adGroupSetupFlow.summaryStep.labels.draftBinding'),
                  value: adGroup.bindings.length,
                  hintColor: 'red',
                  hint: showAlert ? i18n.t('adGroupSetupFlow.summaryStep.message.willDeliverImmediately') : undefined
                }
              ])
            }
          }
        };
      }
      return summaryData;
    }
  };
};

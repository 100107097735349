import React from 'react';
import i18next from 'i18n';
import styles from './basic.module.scss';
import { InfoDisplaySection } from 'components/InfoDisplaySection/InfoDisplaySection';
import _ from 'lodash';
import { ImagePreview } from 'components/common/Image/ImagePreview';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { hasRoles } from 'core/permission/PermissionDSL';
import { RoleNames } from 'core';

type BasicProps = {
  AgencyDetail: {};
  isSystemViewOnly: boolean;
};

function onOrOff (on) {
  if (on) {
    return i18next.t('agency.yes');
  } else {
    return i18next.t('agency.no');
  }
}

function percent (str) {
  return str + '%';
}

const dataFormatter = {
  selfBuy: onOrOff,
  language: value => i18next.t(`common.language.${value.replace('_', '').toLowerCase()}`),
  noLimitSelfBuyStatus: onOrOff,
  interstitial: onOrOff,
  agcPercent: percent,
  'addonProps.maxOrderProfit': percent,
  sysPercentIn: percent,
  sysPercentOut: percent
};

const Basic: React.FC<BasicProps> = ({
  AgencyDetail,
  isSystemViewOnly
}) => {

  function getFieldData (fields) {
    return fields
      .filter(field => {
        const value: any = _.get(AgencyDetail, field, undefined);
        return value !== undefined && value !== null && value !== '';
      })
      .map(field => ({
        label: i18next.t(`agency.${field}`),
        value: dataFormatter[field] ? dataFormatter[field](_.get(AgencyDetail, field)) : _.get(AgencyDetail, field)
      }));
  }

  AgencyDetail['limitAdxStr'] = _.isEmpty(AgencyDetail['limitAdx']) ?
    undefined :
    `${i18next.t('limitation.' + AgencyDetail['limitAdxOp'])}\n${AgencyDetail['limitAdx'].map(function (el) { return el.label; }).join('\n')}`;
  AgencyDetail['defaultCountryStr'] = _.isEmpty(AgencyDetail['defaultCountry']) ?
    undefined :
    `${i18next.t('limitation.' + AgencyDetail['defaultCountryOp'])}\n${AgencyDetail['defaultCountry'].map(function (el) { return el.label; }).join('\n')}`;
  AgencyDetail['defaultSpaceStr'] = _.isEmpty(AgencyDetail['defaultSpace']) ?
    undefined :
    `${i18next.t('limitation.' + AgencyDetail['defaultSpaceOp'])}\n${AgencyDetail['defaultSpace'].map(function (el) { return el.label; }).join('\n')}`;

  AgencyDetail['limitPublisherCountryStr'] = AgencyDetail['limitPublisherCountry'].map(function (el) { return el.label; }).join('\n');
  AgencyDetail['limitPublisherStr'] = AgencyDetail['limitPublisher'].map(function (el) { return el; }).join('\n');
  AgencyDetail['segmentCountryStr'] = AgencyDetail['segmentCountry'].map(function (el) { return el.label; }).join('\n');
  AgencyDetail['segmentLabelStr'] = AgencyDetail['segmentLabel'].map(function (el) { return el.label; }).join('\n');

  const agencyInfoFields = ['id', 'companyName', 'language', 'currency', 'timeZone'];
  isSystemViewOnly && agencyInfoFields.push('comComment');
  const agencyInfoData = getFieldData(agencyInfoFields);
  const logoUrl = _.get(AgencyDetail, 'logoData.url');
  logoUrl && agencyInfoData.push({
    label: i18next.t('agency.logo'),
    value: (
      <ImagePreview
        url={logoUrl}
        config={{
          showName: false,
          showSize: false
        }}
      />
    )
  });
  const deliverySettingFields = ['targetBudgetMinimum', 'limitPublisherCountryStr', 'limitAdxStr', 'defaultCountryStr', 'defaultSpaceStr'];
  const commonDeliverySettingFields = ['defaultCountryStr'];
  const rtbDeliverySettingFields = ['limitAdxStr'];
  isSystemViewOnly && deliverySettingFields.push('segmentCountryStr', 'segmentLabelStr', 'interstitial');
  isSystemViewOnly && rtbDeliverySettingFields.push('interstitial');
  const commonDeliverySettingData = getFieldData(commonDeliverySettingFields);
  const rtbDeliverySettingData = getFieldData(rtbDeliverySettingFields);
  const marginSettingFields = ['agcPercent', 'addonProps.maxOrderProfit'];
  const marginSettingData = getFieldData(marginSettingFields);

  return (
    <div className={styles.container}>
      <InfoDisplaySection data={agencyInfoData} title={i18next.t('agency.information')} />
      <InfoDisplaySection data={commonDeliverySettingData} title={i18next.t('agency.form.titles.generalDeliverySetting')} />
      <InfoDisplaySection data={rtbDeliverySettingData} title={i18next.t('agency.form.titles.rtbDeliverySetting')} />
      <PermissionChecker permissionAware={hasRoles(RoleNames.sysAdmin, RoleNames.goJekAccountManager)}>
        <InfoDisplaySection data={marginSettingData} title={i18next.t('agency.marginSetting')} />
      </PermissionChecker>
    </div>
  );
};

export default Basic;

import React from 'react';
import styles from './creativeBasicForm.module.scss';
import { CreativeBasicFormProps, CreativeBasicFormState } from './CreativeBasicFormModel';
import { Form } from 'react-bootstrap';
import { FormikInput } from 'components/common';
import i18n from 'i18next';
import { withTip } from 'hoc';
import { CreativeType } from 'core/creative/Creative';
import _ from 'lodash';
import moment from 'moment';
import FormikDatePicker from 'components/common/formik/FormikDatePicker';
import Toggle from 'components/common/Toggle/Toggle';
import cx from 'classnames/bind';

export const withTipFormikInput = withTip(FormikInput);

export class CreativeBasicForm extends React.Component<CreativeBasicFormProps, CreativeBasicFormState> {

  handler: any;
  classNames = cx.bind(styles);

  constructor (props) {
    super(props);
    this.handler = this.props.model.event.add(model => {
      this.setState(model.state);
    });
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add(model => {
        this.setState(model.state);
      });
    }
  }

  componentWillUnmount () {
    this.props.model.onUnmount(this.handler);
  }

  renderLabel = (props) => {
    const value = props.field.value;
    return (
      <Form.Label className={styles.formLabel}>
        {i18n.t(`creativeType.${_.camelCase(CreativeType[value])}`)}
      </Form.Label>
    );
  }

  renderStartTime = (props) => {
    const switchChanged = (e) => {
      const enable = e.target.checked;
      let defaultStartTime = moment().startOf('day');
      if (props.form.values.enableEndTime &&
        moment(props.form.values.enableEndTime).isBefore(defaultStartTime)
      ) {
        defaultStartTime = moment(props.form.values.enableEndTime).subtract(1, 'day').startOf('day');
      }

      props.form.setFieldValue(
        'enableStartTime',
        enable ?
          defaultStartTime.format('YYYY-MM-DD HH:mm:ss') :
          undefined
      );
      this.props.model.setShowStartTime(enable);
    };
    return (
      <>
        <div className={styles.timeSwitch}>
          <Toggle
            checked={this.props.model.state.showStartTime}
            onChange={switchChanged}
          />
        </div>
        {this.props.model.state.showStartTime && (
          <FormikDatePicker
            {...props}
            timeInterval={30}
            maxDate={props.form.values.enableEndTime}
          />
        )}
      </>
    );
  }

  renderEndTime = (props) => {
    const switchChanged = (e) => {
      const enable = e.target.checked;
      let defaultEndTime = moment().add(1, 'day').startOf('day');
      if (props.form.values.enableStartTime &&
        moment(props.form.values.enableStartTime).isAfter(moment())
      ) {
        defaultEndTime = moment(props.form.values.enableStartTime).add(1, 'day').startOf('day');
      }

      props.form.setFieldValue(
        'enableEndTime',
        enable ?
          defaultEndTime.format('YYYY-MM-DD HH:mm:ss') :
          undefined
      );
      this.props.model.setShowEndTime(enable);
    };
    return (
      <>
        <div className={styles.timeSwitch}>
          <Toggle
            checked={this.props.model.state.showEndTime}
            onChange={switchChanged}
          />
        </div>
        {this.props.model.state.showEndTime && (
          <FormikDatePicker
            {...props}
            timeInterval={30}
            minDate={props.form.values.enableStartTime}
          />
        )}
      </>
    );
  }

  renderHintModal = () => {
    const model = this.props.model;
    if (!model.state.hintModalData) {
      return undefined;
    }
    const HintModal = model.state.hintModalData.component;
    const modalProps = model.state.hintModalData.props;
    return <HintModal {...modalProps}/>;
  }

  render () {
    const model = this.props.model;
    const contentModel = model.getContentModelByCreativeType();
    const Content: typeof React.Component = contentModel ? contentModel.getFormContent() : () => <div />;
    return (
      <div className={styles.creativeBasicForm}>
        <Content
          model={contentModel}
          renderHintModal={this.renderHintModal}
          hintModalData={model.state.hintModalData}
          setHintModalData={model.setHintModalData}
          basicFields={model.state.basicFields}
        />
      </div>
    );
  }
}

import { CreativeType } from 'core/creative/Creative';
import { ComboCreativeListDataProvider } from './ComboCreativeListDataProvider';
import { OneForAllVideoCreativeListDataProvider } from './OneForAllVideoCreativeListDataProvider';
import { HTML5CreativeListDataProvider } from './HTML5CreativeListDataProvider';
import { ImageCreativeListDataProvider } from './ImageCreativeListDataProvider';
import { NativeCreativeListDataProvider } from './NativeCreativeListDataProvider';
import { OneForAllCreativeListDataProvider } from './OneForAllCreativeListDataProvider';
import { OneForAllDisplayCreativeListDataProvider } from './OneForAllDisplayCreativeListDataProvider';
import { OutdoorCreativeListDataProvider } from './OutdoorCreativeListDataProvider';
import { PPSCreativeListDataProvider } from './PPSCreativeListDataProvider';
import { VideoCreativeListDataProvider } from './VideoCreativeListDataProvider';
import { AdNeonCreativeListDataProvider } from './AdNeonCreativeListDataProvider';
import { RetailProductCreativeListDataProvider } from './RetailProductCreativeListDataProvider';
import { PilotTVCreativeListDataProvider } from './PilotTVCreativeListDataProvider';

export function getDataProvider (creativeType: CreativeType, model: any) {

  switch (creativeType) {
    case CreativeType.NATIVE:
      return new NativeCreativeListDataProvider(model);
    case CreativeType.IMAGE:
      return new ImageCreativeListDataProvider(model);
    case CreativeType.VIDEO:
      return new VideoCreativeListDataProvider(model);
    case CreativeType.ONE_FOR_ALL:
      return new OneForAllCreativeListDataProvider(model);
    case CreativeType.ONE_FOR_ALL_DISPLAY:
      return new OneForAllDisplayCreativeListDataProvider(model);
    case CreativeType.THIRDPARTY:
      return new PPSCreativeListDataProvider(model);
    case CreativeType.HTML5:
      return new HTML5CreativeListDataProvider(model);
    case CreativeType.OUTDOOR:
      return new OutdoorCreativeListDataProvider(model);
    case CreativeType.COMBO:
      return new ComboCreativeListDataProvider(model);
    case CreativeType.ONE_FOR_ALL_VIDEO:
      return new OneForAllVideoCreativeListDataProvider(model);
    case CreativeType.AD_NEON:
      return new AdNeonCreativeListDataProvider(model);
    case CreativeType.RETAIL_RICH_MEDIA_PRODUCT:
      return new RetailProductCreativeListDataProvider(model, creativeType);
    case CreativeType.RETAIL_NATIVE_PRODUCT:
      return new RetailProductCreativeListDataProvider(model, creativeType);
    case CreativeType.PILOT_TV:
      return new PilotTVCreativeListDataProvider(model);
    default:
      return new NativeCreativeListDataProvider(model);
  }
}

import { CampaignGroupFormChannelModel } from './CampaignGroupFormChannelModel';
import { CampaignGroup, CampaignGroupChannel, FBBidStrategy } from 'core/campaignGroup/CampaignGroup';
import i18n from 'i18n';
import { DefaultAdvertiserManager } from 'core/advertiser/AdvertiserManager';
import { FbAdAccountStatus } from 'core/fbAdAccount/FbAdAccount';
import { AdvertiserTiktokAdAccountStatus } from 'core/tiktokAdAccount/TiktokAdAccount';
import { SelectOptions } from 'components/common/commonType';
import { BillingEvent, FbAdSet } from 'core/fbAdSet/FbAdSet';
import { TiktokAdGroup } from 'core/tiktokAdGroup/TiktokAdGroup';
import { Order } from 'core/order/Order';
import _ from 'lodash';
import { validateEmpty } from 'utils/ValidateUtils';
import { RtbCampaignBasic } from 'core/rtbCampaign/RtbCampaign';
import DefaultCampaignGroupManager from 'core/campaignGroup/CampaignGroupManager';
import moment from 'moment';
import { DefaultTiktokAdGroupManager } from 'core/tiktokAdGroup/TiktokAdGroupManager';
import { DefaultFbAdSetManager } from 'core/fbAdSet/FbAdSetManager';
import { DefaultRtbCampaignManager } from 'core/rtbCampaign/RtbCampaignManager';
import { getDecimalPlaceCount, getPriceValue } from 'helper/CurrencyHelper';
import { GoCampaignOptimizationGoal } from 'core/goCampaign/GoCampaign';

export abstract class CampaignGroupEditFormChannelModel implements CampaignGroupFormChannelModel {

  minBudgetPerDay: number = 0;
  constructor (
    protected order: Order,
    public campaignGroup: CampaignGroup
  ) {
    this.minBudgetPerDay = order.campaignConstraint.budgetMinimum;
  }

  abstract get minBudget ();

  abstract get totalBudget ();

  abstract init (callback: (data) => void);

  abstract validate (value: Partial<CampaignGroup>);

  abstract getCboChangeHint ();

  abstract getDefaultBidStrategy ();

  abstract getBudgetManagementModalData (
    values: Partial<CampaignGroup>,
    valuesUpdater: (fieldName: string, value: any) => void
  ): any;

  abstract onCBOChange (enable: boolean, valueUpdater: (fieldName: string, value: any) => void, showManagementModal: () => void);

  abstract showRemainBudget (values: Partial<CampaignGroup>);
}

export class FBCampaignGroupEditFormModel extends CampaignGroupEditFormChannelModel {

  fbAdAccountOptions?: SelectOptions[];
  minBudgetFromServer: number = 0;

  constructor (
    order: Order,
    campaignGroup: CampaignGroup,
    private fbAdSets?: FbAdSet[],
    private advertiserManager = new DefaultAdvertiserManager(),
    private campaignGroupManager = new DefaultCampaignGroupManager(),
    private fbAdSetManager = new DefaultFbAdSetManager()
  ) {
    super(order, campaignGroup);
  }

  get minBudget () {
    const order = this.order;
    const campaignGroup = this.campaignGroup;
    const fbAdSets = this.fbAdSets;
    if (!campaignGroup) {
      return 0;
    }
    const currencyRate = _.get(campaignGroup, 'currencyRate', 1);
    if (!fbAdSets || fbAdSets.length === 0) {
      const minBudget = this.fbAdSetManager.getMinBudgetOfFBObject(
        _.defaultTo(currencyRate, 1),
        1,
        FBBidStrategy.LOWEST_COST_WITHOUT_CAP,
        BillingEvent.IMPRESSIONS,
        1,
        this.order
      );
      return Math.max(minBudget, this.minBudgetFromServer / (1 - order.orderMargin - order.sysMargin));
    } else {
      const minBudgetOfFbAdSets = fbAdSets.reduce((acc, fbAdSet) => {
        const startDateMoment = moment(fbAdSet.start_time);
        const endDateMoment = moment(fbAdSet.end_time);
        const scheduleDateCount = endDateMoment.diff(startDateMoment, 'days') + 1;
        return acc + Math.max(
          this.fbAdSetManager.getMinBudgetOfFBObject(
            currencyRate,
            +_.defaultTo(fbAdSet.bid_amount, 0),
            _.get(campaignGroup, 'fb.bid_strategy') ? _.get(campaignGroup, 'fb.bid_strategy') : fbAdSet.bid_strategy,
            fbAdSet.billing_event,
            scheduleDateCount,
            order
          ),
          +_.defaultTo(fbAdSet.lifetime_min_spend_target, 0)
        );
      }, 0);
      return Math.max(minBudgetOfFbAdSets, this.minBudgetFromServer / (1 - order.orderMargin - order.sysMargin));
    }
  }

  get totalBudget () {
    if (!this.campaignGroup || !this.fbAdSets) {
      return 0;
    }
    let campaignGroupBudget = 0;
    if (_.get(this.campaignGroup, 'budget') !== '' && _.get(this.campaignGroup, 'budget') !== undefined) {
      campaignGroupBudget = +_.defaultTo(_.get(this.campaignGroup, 'budget'), 0).toString();
    } else {
      campaignGroupBudget = this.fbAdSets.reduce((acc, fbAdSet) => {
        return fbAdSet.lifetime_budget ? acc + (+fbAdSet.lifetime_budget) : acc;
      }, 0);
    }
    return _.defaultTo(_.get(this.order, 'budgetBalance'), 0) + campaignGroupBudget;
  }

  init = async (callback: (data) => void) => {
    if (!this.fbAdAccountOptions) {
      const fbAdAccounts = await this.advertiserManager.getAdvertiserFBAdAccounts(this.order.advertiserId);
      this.fbAdAccountOptions = fbAdAccounts.filter(account => account.status === FbAdAccountStatus.ACTIVE)
                                  .map(account => ({
                                    label: account.name,
                                    value: account.fbAdAccountId,
                                    extra: {
                                      hasAdvertiserOptedInOdax: account.hasAdvertiserOptedInOdax
                                    }
                                  }));
    }
    this.minBudgetFromServer = await this.campaignGroupManager.getFBCampaignMinBudget(this.campaignGroup.groupId);
    if (!this.fbAdSets) {
      this.fbAdSets = await this.fbAdSetManager.getAdSets(this.campaignGroup.groupId);
    }
    callback({
      fbAdAccountOptions: this.fbAdAccountOptions,
      goCampaignList: this.fbAdSets
    });
  }

  validate = (value: Partial<CampaignGroup>) => {
    return {
      fb: _.omitBy({
        account_id: validateEmpty(_.get(value, 'fb.account_id'))
      }, _.isEmpty)
    };
  }

  getCboChangeHint = () => {
    if (this.campaignGroup && this.campaignGroup.autoOptimise && (this.fbAdSets && this.fbAdSets.length === 0)) {
      return i18n.t('campaignGroup.labels.cboFbAdSetLengthLimit');
    }
    const cboOpenLimit = this.getCboOpenLimit();
    if (cboOpenLimit && !this.campaignGroup.autoOptimise) {
      return cboOpenLimit;
    }
    const lastBudgetTogglingTime = _.get(this.campaignGroup, 'fb.last_budget_toggling_time');
    if (lastBudgetTogglingTime) {
      const localTime = lastBudgetTogglingTime.replace('+0800', '');
      const minutes = moment(localTime).add(2, 'hours').diff(moment(), 'minutes');
      if (minutes > 0) {
        return i18n.t('campaignGroup.labels.cboTimeLimit', { time: minutes });
      }
    }
  }

  getCboOpenLimit () {
    if (!this.fbAdSets || this.fbAdSets.length === 0) {
      return;
    }
    const optimizationGoals: GoCampaignOptimizationGoal[] = [];
    const bidStrategies: FBBidStrategy[] = [];
    this.fbAdSets.forEach(fbAdSet => {
      optimizationGoals.push(fbAdSet.optimization_goal);
      bidStrategies.push(fbAdSet.bid_strategy);
    });
    if (_.uniq(optimizationGoals).length > 1 || _.uniq(bidStrategies).length > 1) {
      return i18n.t('campaignGroup.labels.fbCboLimitation');
    }
  }

  getDefaultBidStrategy () {
    if (!this.fbAdSets || this.fbAdSets.length === 0) {
      return FBBidStrategy.LOWEST_COST_WITHOUT_CAP;
    }
    return this.fbAdSets[0].bid_strategy ? this.fbAdSets[0].bid_strategy : FBBidStrategy.LOWEST_COST_WITHOUT_CAP;
  }

  getBudgetManagementModalData (
    values: Partial<CampaignGroup>,
    valuesUpdater: (fieldName: string, value: any) => void
  ) {
    const adSetBudgets = _.get(values, 'fb.adset_budgets');
    return {
      channel: CampaignGroupChannel.FB,
      budgetDataList: adSetBudgets ? adSetBudgets.map(adsetBudget => ({
        id: adsetBudget.adset_id,
        budget: adsetBudget.lifetime_budget
      })) : undefined,
      order: this.order,
      campaignGroup: values,
      initCampaignGroup: this.campaignGroup,
      goCampaignList: this.fbAdSets,
      onSubmit: (budgetMap) => {
        valuesUpdater('autoOptimise', false);
        valuesUpdater('budget', '');
        valuesUpdater('fb.bid_strategy', undefined);
        valuesUpdater('fb.adset_budgets', Object.keys(budgetMap).map(id => ({
          adset_id: id,
          lifetime_budget: budgetMap[id]
        })));
      },
      onCancel: () => {
        if (!_.get(values, 'fb.adset_budgets')) {
          valuesUpdater('autoOptimise', true);
        }
      }
    };
  }

  onCBOChange (enable: boolean, valueUpdater: (fieldName: string, value: any) => void, showManagementModal: () => void) {
    if (enable) {
      const defaultBudget = this.fbAdSets ? this.fbAdSets.reduce((acc, fbAdSet) => {
        return acc + +_.defaultTo(fbAdSet.lifetime_budget, 0);
      }, 0) : 0;
      valueUpdater('budget', defaultBudget);
      valueUpdater('fb.bid_strategy', this.getDefaultBidStrategy());
      valueUpdater('fb.adset_budgets', undefined);
    } else {
      if (this.fbAdSets && this.fbAdSets.length > 0 && _.get(this.campaignGroup, 'autoOptimise')) {
        showManagementModal();
      } else {
        valueUpdater('budget', '');
        valueUpdater('fb.bid_strategy', undefined);
      }
    }
  }

  showRemainBudget (values: Partial<CampaignGroup>) {
    const isCBO = _.get(values, 'autoOptimise', false);
    return isCBO;
  }
}

export class RTBCampaignGroupEditFormModel extends CampaignGroupEditFormChannelModel {

  constructor (
    order: Order,
    campaignGroup: CampaignGroup,
    private rtbCampaigns?: RtbCampaignBasic[],
    private campaignManager = new DefaultRtbCampaignManager()
  ) {
    super(order, campaignGroup);
  }

  get minBudget () {
    const order = this.order;
    if (!this.rtbCampaigns || this.rtbCampaigns.length === 0) {
      return this.minBudgetPerDay;
    } else {
      const decimalPlaceCount = getDecimalPlaceCount(order.currency);
      const minBudgetOfRtbCampaign = this.rtbCampaigns.reduce((acc, rtbCampaign) => {
        return acc + Math.max(
          _.ceil(rtbCampaign.expectedSpent, decimalPlaceCount),
          this.campaignManager.getMinBudgetOfCampaign(rtbCampaign, this.minBudgetPerDay)
        );
      }, 0);
      return Math.min(order.budget, getPriceValue(order.currency, minBudgetOfRtbCampaign));
    }
  }

  get totalBudget () {
    if (!this.campaignGroup || !this.rtbCampaigns) {
      return 0;
    }
    let campaignGroupBudget = 0;
    const currentFieldValue = _.get(this.campaignGroup, 'budget');
    if (currentFieldValue !== '' && currentFieldValue !== undefined) {
      campaignGroupBudget = +_.defaultTo(currentFieldValue, 0).toString();
    } else {
      campaignGroupBudget = this.rtbCampaigns.reduce((acc, rtbCampaign) => {
        return rtbCampaign.budget ? acc + (+rtbCampaign.budget) : acc;
      }, 0);
    }
    return _.defaultTo(_.get(this.order, 'budgetBalance'), 0) + campaignGroupBudget;
  }

  init = async (callback: (data) => void) => {
    if (!this.rtbCampaigns) {
      this.rtbCampaigns = await this.campaignManager.getCampaignsOfGroup(this.campaignGroup.groupId);
    }
    callback({
      goCampaignList: this.rtbCampaigns
    });
  }

  validate = (value: Partial<CampaignGroup>) => {
    return {};
  }

  getCboChangeHint = () => {
    if (this.campaignGroup && this.campaignGroup.autoOptimise && (this.rtbCampaigns && this.rtbCampaigns.length === 0)) {
      return i18n.t('campaignGroup.labels.cboRtbCampaignLengthLimit');
    }
    const cboOpenLimit = this.getCboOpenLimit();
    if (cboOpenLimit && !this.campaignGroup.autoOptimise) {
      return cboOpenLimit;
    }

    const lastBudgetTogglingTime = _.get(this.campaignGroup, 'rtb.update_cbo_time');
    if (lastBudgetTogglingTime) {
      const minutes = moment(lastBudgetTogglingTime).add(2, 'hours').diff(moment(), 'minutes');
      if (minutes > 0) {
        return i18n.t('campaignGroup.labels.cboTimeLimit', { time: minutes });
      }
    }
  }

  getCboOpenLimit () {
    if (!this.rtbCampaigns || this.rtbCampaigns.length === 0) {
      return;
    }
    const optimizationGoals: GoCampaignOptimizationGoal[] = [];
    const bidStrategies: FBBidStrategy[] = [];
    this.rtbCampaigns.forEach(rtbCampaign => {
      optimizationGoals.push(rtbCampaign.optimize);
      bidStrategies.push(_.isNumber(rtbCampaign.bidPrice) ? FBBidStrategy.LOWEST_COST_WITH_BID_CAP : FBBidStrategy.LOWEST_COST_WITHOUT_CAP);
    });
    if (_.uniq(optimizationGoals).length > 1 || _.uniq(bidStrategies).length > 1) {
      return i18n.t('campaignGroup.labels.rtbCboLimitation');
    }
  }

  getDefaultBidStrategy () {
    if (!this.rtbCampaigns || this.rtbCampaigns.length === 0) {
      return FBBidStrategy.LOWEST_COST_WITHOUT_CAP;
    }
    return _.isNumber(this.rtbCampaigns[0].bidPrice) ? FBBidStrategy.LOWEST_COST_WITH_BID_CAP : FBBidStrategy.LOWEST_COST_WITHOUT_CAP;
  }

  getBudgetManagementModalData (
    values: Partial<CampaignGroup>,
    valuesUpdater: (fieldName: string, value: any) => void
  ) {
    return {
      channel: this.campaignGroup.channel,
      budgetDataList: _.get(values, 'rtb.campaign_budgets'),
      order: this.order,
      campaignGroup: values,
      initCampaignGroup: this.campaignGroup,
      goCampaignList: this.rtbCampaigns,
      onSubmit: (budgetMap) => {
        valuesUpdater('autoOptimise', false);
        valuesUpdater('budget', '');
        valuesUpdater('rtb.bid_strategy', undefined);
        valuesUpdater('rtb.campaign_budgets', Object.keys(budgetMap).map(id => ({
          id: id,
          budget: budgetMap[id]
        })));
      },
      onCancel: () => {
        if (!_.get(values, 'rtb.campaign_budgets')) {
          valuesUpdater('autoOptimise', true);
        }
      }
    };
  }

  onCBOChange (enable: boolean, valueUpdater: (fieldName: string, value: any) => void, showManagementModal: () => void) {
    if (enable) {
      const defaultBudget = this.rtbCampaigns ? this.rtbCampaigns.reduce((acc, rtbCampaign) => {
        return acc + Math.max(
          +_.defaultTo(rtbCampaign.budget, 0),
          this.campaignManager.getMinBudgetOfCampaign(rtbCampaign, this.minBudgetPerDay)
        );
      }, 0) : 0;
      valueUpdater('budget', defaultBudget);
      valueUpdater('rtb.bid_strategy', this.getDefaultBidStrategy());
      valueUpdater('rtb.campaign_budgets', undefined);
    } else {
      if (this.rtbCampaigns && this.rtbCampaigns.length > 0 && _.get(this.campaignGroup, 'autoOptimise')) {
        showManagementModal();
      } else {
        valueUpdater('budget', '');
        valueUpdater('rtb.bid_strategy', undefined);
      }
    }
  }

  showRemainBudget (values: Partial<CampaignGroup>) {
    return true;
  }
}

export class TiktokCampaignGroupEditFormModel extends CampaignGroupEditFormChannelModel {

  tiktokAdAccountOptions?: SelectOptions[];
  // minBudgetFromServer: number = 0;

  constructor (
    order: Order,
    campaignGroup: CampaignGroup,
    private tiktokAdGroups?: TiktokAdGroup[],
    private advertiserManager = new DefaultAdvertiserManager(),
    // private campaignGroupManager = new DefaultCampaignGroupManager(),
    private tiktokAdGroupManager = new DefaultTiktokAdGroupManager()
  ) {
    super(order, campaignGroup);
  }

  get minBudget (): any {
    return 0;
  }

  get totalBudget (): any {
    return 0;
  }

  init = async (callback: (data) => void) => {
    if (!this.tiktokAdAccountOptions) {
      const tiktokAdAccounts = await this.advertiserManager.getAdvertiserTikTokAdAccounts(this.order.advertiserId);
      this.tiktokAdAccountOptions = tiktokAdAccounts.filter(account => account.status === AdvertiserTiktokAdAccountStatus.ACTIVE)
                                      .map(account => ({
                                        label: account.label,
                                        value: account.value
                                      }));
    }

    // TODO: define Tiktok own's minBudgetFromServer
    // this.minBudgetFromServer = await this.campaignGroupManager.getFBCampaignMinBudget(this.campaignGroup.groupId);

    if (!this.tiktokAdGroups) {
      this.tiktokAdGroups = await this.tiktokAdGroupManager.getAdGroups(_.get(this.campaignGroup, 'tiktok.campaign_id'));
    }
    callback({
      tiktokAdAccountOptions: this.tiktokAdAccountOptions,
      goCampaignList: this.tiktokAdGroups
    });
  }

  validate = (value: Partial<CampaignGroup>) => {
    return {
      tiktok: _.omitBy({
        advertiser_id: validateEmpty(_.get(value, 'tiktok.advertiser_id'))
      }, _.isEmpty)
    };
  }

  getCboChangeHint = () => i18n.t('campaignGroup.labels.tiktok.cbo.hint');

  getCboOpenLimit () {

  }

  getDefaultBidStrategy () {

  }

  getBudgetManagementModalData (
    values: Partial<CampaignGroup>,
    valuesUpdater: (fieldName: string, value: any) => void
  ) {

  }

  onCBOChange (enable: boolean, valueUpdater: (fieldName: string, value: any) => void, showManagementModal: () => void) {

  }

  showRemainBudget (values: Partial<CampaignGroup>) {
    const isCBO = _.get(values, 'autoOptimise', false);
    return isCBO;
  }
}

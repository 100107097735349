import * as parser from 'utils/ActorRoleParser';
import { Account, AccountManager, Actor, RoleNames } from 'core';
import { RoleManager, DefaultRoleManager } from 'core/RoleManager';
import { PermissionAction } from 'components/AccountActorList';
import { UpdateEventListener, FireableUpdateEventListener } from 'utils/UpdateEventListener';

import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { ActorPermissionFormModel, editAgencyPermissionForm, editAdvertiserPermissionForm } from 'components/ActorPermissionForm/ActorPermissionFormModel';
import i18n from 'i18n';
import { toast } from 'react-toastify';

export type AccountDetailState = {

  readonly currentKey: string;
  readonly error: Error | null;
  readonly loading: boolean;

  readonly account: Account | null;
  readonly editingActor: Actor | null;
  readonly modalData: any;
};

export interface AccountDetailModel {

  fetch (): Promise<void>;
  currentKeyChanged (key: string): void;
  resendActivationEmail (): void;
  hideModal (): void;

  readonly title: string;
  readonly operator: Actor;
  readonly editPath: string;
  readonly accountId: number;
  readonly editButtonLabel: string;
  readonly editButtonVisible: boolean;
  readonly roleActions: PermissionAction[];
  readonly permissionFormModel: ActorPermissionFormModel | null;

  readonly state: AccountDetailState;
  readonly event: UpdateEventListener<AccountDetailModel>;
}

export type AccountDetailProps = {
  readonly fromAgencyId?: string | number;
  readonly model: AccountDetailModel;
};
export class DefaultAccountDetailModel implements AccountDetailModel {
  accountId: number;
  operator: Actor;
  currentKey: string;
  accountManager: AccountManager;
  roleManager: RoleManager;
  event: FireableUpdateEventListener<AccountDetailModel>;

  error: Error | null;
  loading: boolean;
  account: Account | null;
  editingActor: Actor | null;
  roleActions: PermissionAction[];
  modalData: any;

  constructor (accountId: number, operator: Actor, accountManager: AccountManager) {
    this.error = null;
    this.account = null;
    this.loading = false;
    this.operator = operator;
    this.editingActor = null;
    this.accountManager = accountManager;
    this.roleManager = new DefaultRoleManager();
    this.accountId = accountId;
    this.currentKey = 'agency';
    this.event = new FireableUpdateEventListener<AccountDetailModel>();
    this.roleActions = [{
      icon: faPencilAlt,
      type: 'edit',
      handler: (actor: Actor) => {
        this.editingActor = actor;
        this.event.fireEvent(this);
      }
    }, {
      icon: faTrashAlt,
      type: 'delete',
      handler: (actor: Actor) => {
        const type = parser.typeOf(actor);
        if (type.toLowerCase() === 'agency') {
          if (actor.agencyId) {
            this.showDeleteRoleModal('agencies', actor.agencyId, accountId);
          }
        } else {
          if (actor.advertiserId) {
            this.showDeleteRoleModal('advertisers', actor.advertiserId, accountId);
          }
        }
      }
    }];
  }

  get title (): string {
    return 'accounts.detail.title';
  }

  get editButtonLabel (): string {
    return 'accounts.summary.labels.edit';
  }

  get editButtonVisible (): boolean {
    return this.operator.roleName === RoleNames.sysAdmin || this.operator.roleName === RoleNames.goJekAccountManager;
  }

  get editPath (): string {
    return this.account ? `/accounts/${this.account.id}/edit` : '';
  }

  get state (): AccountDetailState {
    return {
      error: this.error,
      account: this.account,
      loading: this.loading,
      currentKey: this.currentKey,
      editingActor: this.editingActor,
      modalData: this.modalData
    };
  }

  get permissionFormModel (): ActorPermissionFormModel | null {
    if (this.account && this.editingActor) {
      const type = parser.typeOf(this.editingActor);
      if (type.toLowerCase() === 'agency') {
        if (this.editingActor.agencyId) {
          return editAgencyPermissionForm(this.editingActor.agencyId, this.operator, this.account, this.editingActor.roleName.toUpperCase(), this.callback);
        }
        return null;
      } else {
        if (this.editingActor.advertiserId) {
          return editAdvertiserPermissionForm(this.editingActor.advertiserId, this.account, this.editingActor.roleName.toUpperCase(), this.callback);
        }
        return null;
      }
    } else {
      return null;
    }
  }

  showDeleteRoleModal = (scope: string, scopeId: number, accountId) => {
    this.modalData = {
      title: i18n.t('accountActorList.labels.actionHint.delete'),
      fullScreen: false,
      message: i18n.t('accountActorList.labels.deletePermissionContent'),
      confirmBtnData: {
        callback: () => this.deleteRole(scope, scopeId, accountId),
        title: i18n.t('common.buttons.delete')
      }
    };
    this.event.fireEvent(this);
  }

  deleteRole = async (scope: string, scopeId: number, accountId) => {
    this.notify(true);
    try {
      await this.roleManager.deleteRole(scope, scopeId, accountId);
    } catch (e) {}
    toast.success(i18n.t('common.messages.succeeded'));
    await this.fetch();
    this.hideModal();
  }

  hideModal = () => {
    this.modalData = undefined;
    this.event.fireEvent(this);
  }

  currentKeyChanged (key: string) {
    this.currentKey = key;
    this.event.fireEvent(this);
  }

  async fetch () {
    this.notify(true);
    try {
      this.account = await this.accountManager.getAccount(this.accountId);
      this.notify(false);
    } catch (error) {
      this.notify(false, error as Error);
    }
  }

  callback = (cancelled: boolean, error: Error | null = null, showLoading: boolean) => {
    if (showLoading) {
      this.editingActor = null;
      this.notify(true);
      return;
    }
    this.editingActor = null;
    this.error = error;
    this.event.fireEvent(this);
    if (!cancelled && error === null) {
      this.fetch();
    }
  }

  resendActivationEmail = async () => {
    if (!this.account) {
      return;
    }

    this.notify(true);
    try {
      await this.accountManager.resendActivationEmail(this.account.id);
    } catch (e) {}
    this.notify(false);
  }

  notify (loading: boolean, error: Error | null = null) {
    this.error = error;
    this.loading = loading;
    this.event.fireEvent(this);
  }
}

export class OEMAccountDetailModel extends DefaultAccountDetailModel {

  get editPath (): string {
    return this.account ? `/oem-accounts/${this.account.id}/edit` : '';
  }
}

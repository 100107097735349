import React from 'react';
import styles from './imageMediaSummary.module.scss';
import _ from 'lodash';
import { ImagePreview } from 'components/common/Image/ImagePreview';

type mediaSummaryData = {
  medias: {
    images: any;
  }
};

export const ImageMultipleMediaSummary: React.FunctionComponent<{ mediaSummary: mediaSummaryData }> = props => {
  const mediaData: {[key: string]: { file: File }} = _.get(props.mediaSummary.medias, 'images', {});
  const mediaDataArray = Object.values(mediaData);
  const names = mediaDataArray.map(data => (
      <div key={data.file.name}>
        {data.file.name}
      </div>
    )
  );
  return (
    mediaDataArray.length === 1 ?
      <ImagePreview
        file={mediaDataArray[0].file}
        border={false}
        config={{
          showName: true,
          showSize: true
        }}
      />
    :
    <div className={styles.imageNamePreview}>
      {names}
    </div>
  );
};

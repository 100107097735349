import { PMaxCore } from 'core';
import { UpdateEventListener, FireableUpdateEventListener } from 'utils/UpdateEventListener';

export interface RetailMaxModel {
  readonly core: PMaxCore;
  readonly state: RetailMaxState;
  readonly event: UpdateEventListener<RetailMaxModel>;
}

export type RetailMaxProps = {
  readonly model: RetailMaxModel;
  readonly mainPagePath: string;
};

export type RetailMaxState = {

};

export class DefaultRetailMaxModel implements RetailMaxModel {

  core: PMaxCore;
  event: FireableUpdateEventListener<RetailMaxModel>;

  constructor (
    core: PMaxCore
  ) {
    this.core = core;
    this.event = new FireableUpdateEventListener<RetailMaxModel>();
  }

  get state () {
    return {

    };
  }
}

import i18n from 'i18n';
import _ from 'lodash';

export enum EstimatorType {
  REACH,
  AD_PERFORMANCE
}

export enum ObjectiveType {
  TRAFFIC = 'Traffic',
  AWARENESS = 'Awareness'
}

export enum ChannelType {
  OPEN_RTB = 'Open_RTB',
  FB_IG = 'FB_IG'
}

export type AdPerformanceSetting = {
  budget: number;
};

export type CampaignSetting = {
  campaignId: number;
  objective: ObjectiveType | null;
  channel: ChannelType | null;
  percentage: number;
  impressions: number;
  clicks: number;
};

export type ReachSetting = {
  audience_reach: number;
  budget: number;
  startDate: string;
  endDate: string;
  objective: ObjectiveType | null;
  channel: ChannelType | null;
};

export enum ReachEstimatorListColumns {
  TARGET_REACH = 'target_reach',
  REACH = 'reach',
  EST_IMPRESSIONS = 'est_impressions',
  EST_BUDGET = 'est_budget'
}

export const handleNumberOrText = (value: number | string, isPercentage= false) => {
  if (typeof value !== 'number') {
    return value;
  } else {
    if (isPercentage) {
      let percentage = Math.round((value * 100));
      return Number.isFinite(percentage) ? `${percentage}%` : 'N/A';
    }
    return Math.round(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
};

export const checkValidWithCallback = (value, cb?: (_) => void, params?) => {
  if (
    value === null ||
    value === undefined ||
    (typeof value === 'string' && _.isEmpty(value))
  ) {
    cb && cb(params);
    return i18n.t('estimator.labels.emptyError');
  } else if (
    (typeof value === 'string' && parseFloat(value) < 0) ||
    (typeof value === 'number' && value < 0)
  ) {
    cb && cb(params);
    return i18n.t('estimator.labels.negativeError');
  } else {
    return undefined;
  }
};

export const config = Object.freeze({
  [ObjectiveType.AWARENESS]: {
    [ChannelType.OPEN_RTB]: {
      cpm: 5500,
      cpc: null,
      ctr: 0.0006
    },
    [ChannelType.FB_IG]: {
      cpm: 8000,
      cpc: null,
      ctr: 0.0005
    },
    impressions: function (budget: number, channel: string) {
      return budget * 1000 / (this[channel].cpm * 1.2);
    },
    clicks: function (budget: number, channel: string) {
      return budget * 1000 * this[channel].ctr / (this[channel].cpm * 1.2);
    },
    budget: function (impressions: number, channel: string) {
      return impressions * (this[channel].cpm * 1.2) / 1000;
    }
  },
  [ObjectiveType.TRAFFIC]: {
    [ChannelType.OPEN_RTB]: {
      cpm: null,
      cpc: 3000,
      ctr: 0.0008
    },
    [ChannelType.FB_IG]: {
      cpm: null,
      cpc: 4000,
      ctr: 0.008
    },
    impressions: function (budget: number, channel: string) {
      return budget / (this[channel].cpc * 1.2 * this[channel].ctr);
    },
    clicks: function (budget: number, channel: string) {
      return budget / (this[channel].cpc * 1.2);
    },
    budget: function (impressions: number, channel: string) {
      return impressions * this[channel].ctr * (this[channel].cpc * 1.2);
    }
  }
});

import React, { MouseEventHandler, RefObject } from 'react';
import { Form, Row } from 'react-bootstrap';

import {
  KeywordComponentProps,
  KeywordComponentState
} from './KeywordComponentModel';
import styles from './keywordComponent.module.scss';
import ButtonGroup from 'components/common/ButtonGroup/PageButtonGroup';
import i18n from 'i18n';
import { randomHex } from 'utils/Math';

export class KeywordComponent extends React.Component<
  KeywordComponentProps,
  KeywordComponentState
> {
  handler: number;
  tmpProps: any;
  firstQueryParametersRef: RefObject<HTMLInputElement>;
  secondQueryParametersRef: RefObject<HTMLInputElement>;
  hashCode: string;
  constructor (props) {
    super(props);
    this.tmpProps = this.props;
    this.state = this.props.model.state;
    this.handleChangeFirstQuery = this.handleChangeFirstQuery.bind(this);
    this.handleChangeSecondQuery = this.handleChangeSecondQuery.bind(this);
    this.handleChangeLang = this.handleChangeLang.bind(this);
    this.handleKeywordEstimate = this.handleKeywordEstimate.bind(this);
    this.handleJoin = this.handleJoin.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.firstQueryParametersRef = React.createRef();
    this.secondQueryParametersRef = React.createRef();
    this.hashCode = randomHex();
    this.handler = this.props.model.event.add(model => {
      this.setState(model.state);
    });
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add(model => {
        this.setState(model.state);
      });
    }
  }

  componentWillUnmount () {
    this.props.model.event.remove(this.handler);
  }

  handleKeywordEstimate () {
    this.props.model.queryKeywordArticleCount();
  }

  handleChangeFirstQuery (e) {
    this.props.model.changeFirstKeyword(e.target.value);
  }

  handleChangeSecondQuery (e) {
    this.props.model.changeSecondKeyword(e.target.value);
  }

  handleChangeLang (e) {
    this.props.model.changeLanguage(e.target.value);
  }

  handleJoin () {
    this.props.model.onJoin();
    this.handleClear();
  }

  handleClear () {
    this.props.model.clearKeyword();
    if (this.firstQueryParametersRef.current) {
      this.firstQueryParametersRef.current.value = '';
    }
    if (this.secondQueryParametersRef.current) {
      this.secondQueryParametersRef.current.value = '';
    }
  }

  render () {
    const { canSearch, searchLoading, articleCount } = this.state;
    const showSearchResult =
      canSearch && articleCount !== undefined && articleCount >= 0;
    return (
      <div className={styles.keywordContainer}>
        <div className={styles.title}>
          {i18n.t('limitation.labels.customKeyword')}
        </div>
        <hr />
        <div className={styles.body}>
          <div>
            <span className={styles.descript}>
              {i18n.t('limitation.labels.keywordDescription')}
            </span>
          </div>
          <Form.Control
            ref={this.firstQueryParametersRef as React.RefObject<any>}
            type='text'
            className={styles.input}
            name='firstQueryParameters'
            onChange={this.handleChangeFirstQuery}
            autoComplete='off'
          />
          <div className={styles.and}>
            <span>AND</span>
          </div>
          <Form.Control
            ref={this.secondQueryParametersRef as React.RefObject<any>}
            type='text'
            className={styles.input}
            name='secondQueryParameters'
            onChange={this.handleChangeSecondQuery}
            autoComplete='off'
          />
          <div>
            <span className={styles.descript}>
              {i18n.t('limitation.labels.languageDescription')}
            </span>
          </div>
          <Row>
            <Form.Check
              type='radio'
              label={i18n.t('limitation.labels.tranditionalChinese')}
              value='ZH'
              name='language'
              id={`zh-radio${this.hashCode}`}
              defaultChecked
              onChange={this.handleChangeLang}
            />
            <Form.Check
              type='radio'
              label={i18n.t('limitation.labels.vietnamese')}
              name='language'
              value='VN'
              id={`vn-radio${this.hashCode}`}
              onChange={this.handleChangeLang}
            />
          </Row>

          <div className={styles.search}>
            <button
              type='button'
              className='btn btn-secondary btn-sm'
              onClick={this.handleKeywordEstimate}
              disabled={!canSearch}
            >
              {searchLoading ? 'Loading' : i18n.t('limitation.buttons.search')}
            </button>
          </div>
          <span className={styles.descript}>
            {showSearchResult &&
              i18n.t('limitation.labels.searchResult', { articleCount })}
          </span>
          <span className={styles.hourUpdate}>
            {showSearchResult && i18n.t('limitation.labels.updateEveryHour')}
          </span>
          <div className={styles.buttonGroup}>
            <ButtonGroup
              primaryTitle={i18n.t('limitation.buttons.addDomain')}
              secondaryTitle={i18n.t('limitation.buttons.clear')}
              onPrimaryClick={this.handleJoin as MouseEventHandler<any>}
              onSecondaryClick={this.handleClear}
            />
          </div>
        </div>
      </div>
    );
  }
}

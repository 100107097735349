import React from 'react';
import { AdvertiserHomeProps, AdvertiserHomeState } from './AdvertiserHomeModel';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import styles from './advertiserHome.module.scss';
import i18n from 'i18next';
import { RecentGroupDropdown } from 'components/RecentGroupDropdown/RecentGroupDropdown';
import { SessionStorageItemKeys } from 'helper/StorageHelper';
import { AdvertiserList } from 'components/AdvertiserList/AdvertiserList';
import { StickableTableContainer } from 'containers/StickableTableContainer/StickableTableContainer';

export class AdvertiserHome extends React.Component<AdvertiserHomeProps, AdvertiserHomeState> {

  handler?: number;

  componentDidMount () {
    this.handler = this.props.model.event.add((model) => {
      this.setState(model.state);
    });
    this.props.model.getAgenciesOptions();
  }

  componentWillUnmount () {
    this.props.model.onUnmount(this.handler);
  }

  render () {
    const model = this.props.model;
    if (model.state.loading) {
      return <LoadingIndicator />;
    }
    return (
      <StickableTableContainer>
        <div className={styles.container}>
          <div className={styles.titleArea}>
            {i18n.t('advertiserHome.labels.title')}
            {
              model.state.agencies &&
              <div className={styles.agencyOptions}>
                <div className={styles.title}>
                  {i18n.t('advertiserHome.labels.agencyOptionsTitle')}
                </div>
                <RecentGroupDropdown
                  value={model.state.agencyId}
                  options={model.state.agencies}
                  storageKey={SessionStorageItemKeys.AGENCY}
                  callback={model.setAgencyId}
                />
              </div>
            }
          </div>
          <div className={styles.advertiserListArea}>
            <AdvertiserList model={model.getAdvertiserListModel()}/>
          </div>
        </div>
      </StickableTableContainer>
    );
  }
}

import { CreativeListDataProvider } from './CreativeListDataProvider';
import { CreativeListCommonColumns, commonColumnSetting, getCommonColumnData, getColumnSetting } from './CreativeListCommonColumnData';
import { renderColumn } from 'components/TableColumn';
import { getCreativeListFormatters } from './CreativeListFormatters';
import { CreativeListRecord, CreativeType } from 'core/creative/Creative';
import _ from 'lodash';
import { CreativeListModel } from './CreativeListModel';
import { DefaultRdpManager, RdpManager } from 'core/rdp/RdpManager';
import { ProductSet, Retail } from 'core/product/Product';
import { retailCreativeConfig } from 'core/creative/RetailCreativeConfig';
import i18n from 'i18n';

enum COLUMNS {
  STATUS = 'status',
  RETAILER = 'retailer',
  PRODUCT_SET = 'productSet',
  LAYOUT = 'adneonLayout'
}

export class RetailProductCreativeListDataProvider implements CreativeListDataProvider {

  formatters: any;
  retailers: Retail[] = [];
  productSets: ProductSet[] = [];
  layouts: string[] = [];

  constructor (
    private model: CreativeListModel,
    private creativeType: CreativeType,
    private rdpManager: RdpManager = new DefaultRdpManager()
  ) {
    this.formatters = getCreativeListFormatters(model);
  }

  async init (): Promise<void> {
    try {
      this.retailers = this.model.getCache('retailers');
      if (!this.retailers) {
        this.retailers = await this.rdpManager.getRetails();
        this.model.addCache('retailers', [...this.retailers]);
      }
      this.layouts = _.flatten(Object.values(retailCreativeConfig[this.creativeType]));
      // this.layoutOptions = allLayouts.map(layout => ({
      //   label: i18n.t(`adneon.layouts.${_.camelCase(layout)}`),
      //   value: layout
      // }));
    } catch (e) {}
  }

  getColumns () {
    return [
      renderColumn(commonColumnSetting[CreativeListCommonColumns.NAME_ID], this.formatters.nameIdFormatter, this.formatters.nameIdHeaderFormatter),
      renderColumn(getColumnSetting(COLUMNS.STATUS), this.formatters.stateFormatter),
      // renderColumn(commonColumnSetting[CreativeListCommonColumns.APPROVAL], this.formatters.approvalFormatter),
      renderColumn(commonColumnSetting[CreativeListCommonColumns.PREVIEW], this.formatters.previewFormatter),
      renderColumn(getColumnSetting(COLUMNS.RETAILER), value => value ? i18n.t(`retailers.${value.toLowerCase()}`) : ''),
      renderColumn(getColumnSetting(COLUMNS.PRODUCT_SET), this.formatters.productSetFormatter),
      renderColumn(getColumnSetting(COLUMNS.LAYOUT), layout => layout ? i18n.t(`adneon.layouts.${_.camelCase(layout)}`) : ''),
      renderColumn(commonColumnSetting[CreativeListCommonColumns.EDITBTNS], this.formatters.floatingEditBtnsFormatter)
    ];
  }

  getViewModel (creative: CreativeListRecord) {
    const productSetData = _.get(creative, 'productSet', {});
    const ppslayoutId = _.get(creative, 'ppsLayoutId', [])[0];
    const layoutId = ppslayoutId ? ppslayoutId.replace('pps_', '') : undefined;
    const layout = this.layouts.find(layout => layout === layoutId);
    const retailerId = _.get(creative, 'retail', '');
    const retailer = this.retailers.find(retailer => retailer.id.toString() === retailerId);
    return {
      ...getCommonColumnData(creative),
      [COLUMNS.STATUS]: _.get(creative, 'creativeState', 0),
      [COLUMNS.RETAILER]: retailer ? retailer.name : undefined,
      [COLUMNS.PRODUCT_SET]: {
        name: _.get(productSetData, 'productSet', ''),
        id: _.get(productSetData, 'productSetId', '')
      },
      [COLUMNS.LAYOUT]: layout
    };
  }
}

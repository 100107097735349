import React, { Fragment, ReactElement } from 'react';
import is from 'is_js';
import moment from 'moment';
import { Field } from 'formik';
import { Form, Col, Row, InputGroup } from 'react-bootstrap';
import classnames from 'classnames/bind';
import _ from 'lodash';

import { DAILY_BUDGET_STATE } from 'containers/RtbCampaigns/RtbCampaignSetupFlow/RtbCampaignForm/RtbCampaignBasicFormModel';
import {
  DailyBudgetPlan,
  RtbCampaignPlanType,
  VideoAdViewObjective,
  AdType
} from 'core/rtbCampaign/RtbCampaign';
import {
  FormikSelect,
  FormikInput,
  FormikLabel,
  FormikPrefixInputGroup,
  FormikTags, FormikSwitch
} from 'components/common/formik';
import styles from './rtbCampaignInfo.module.scss';
import { withHoverTip, withTip, withFormikOnChange } from 'hoc';
import FormGroup from 'components/common/FormGroup/FormGroup';
import i18n from 'i18n';
import FormikDateRangePicker from 'components/common/formik/FormikDateRangePicker';
import { AlertDialog } from 'components/AlertDialog';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { addOnEnabled, notRoles } from 'core/permission/PermissionDSL';
import { RoleNames } from 'core';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { Trans } from 'react-i18next';
import { FormikField } from 'components/common/formik/FormikField';
import { Link } from 'react-router-dom';

export const withTipFormikInput = withTip(FormikInput);
export const withHoverTipFormikSelect = withHoverTip(FormikSelect);
export const withHoverTipFormikSwitch = withHoverTip(FormikSwitch);
export const withHoverTipFormCheck = withHoverTip(Form.Check);
export const withFormikOnChangeFormCheck = withTip(
  withFormikOnChange(Form.Check)
);
export const withTipFormikDateRangePicker = withTip(FormikDateRangePicker);

const cx = classnames.bind(styles);

export type CampaignInfoState = {
  alertContent?: string | ReactElement | ReactElement[],
  needShowDailyBudgetAlert: boolean
};

const renderIsOutOfOutdoorBk = (enable, props) => {
  return (
    <div>
      <FormikSwitch {...props}/>
    </div>
  );
};

class RtbCampaignInfo extends React.Component<any, CampaignInfoState> {

  constructor (props) {
    super(props);
    this.state = {
      alertContent: undefined,
      needShowDailyBudgetAlert: false
    };
  }

  getDailyBudgetTips () {
    const { dailyBudgetState } = this.props;
    switch (dailyBudgetState) {
      case DAILY_BUDGET_STATE.OVER_BUDGET:
        return (
          <Trans i18nKey='campaign.descriptions.overDailyBudget'>
            ...<span className='text-danger'>...</span>...
          </Trans>
        );
      case DAILY_BUDGET_STATE.UNDER_BUDGET:
        return (
          <Trans i18nKey='campaign.descriptions.underDailyBudget'>
            ...<span className='text-danger'>...</span>...
          </Trans>
        );
      case DAILY_BUDGET_STATE.MEET_BUDGET:
        return (
          <Trans i18nKey='campaign.descriptions.meetDailyBudget'>
            ...<span className='text-dark'>...</span>...
          </Trans>
        );
      case DAILY_BUDGET_STATE.DEFAULT:
      default:
        return;
    }
  }

  setNeedShowDailyBudgetAlert = (needShow) => {
    this.setState({
      needShowDailyBudgetAlert: needShow
    });
  }

  checkIsUnderBudget = () => {
    if (this.state.needShowDailyBudgetAlert && this.props.dailyBudgetState === DAILY_BUDGET_STATE.UNDER_BUDGET) {
      this.setState({
        alertContent: (
          <Trans i18nKey='campaign.descriptions.underDailyBudget'>
            ...<span className='text-danger'>...</span>...
          </Trans>
        )
      });
    }
  }

  dismissAlertWindow = () => {
    this.setState({
      alertContent: undefined,
      needShowDailyBudgetAlert: false
    });
  }

  getCampaignBudgetTips () {
    const { currency, showBudgetHint, remainBudget } = this.props;
    if (!showBudgetHint) {
      return;
    }
    return (
      <Trans i18nKey='campaign.descriptions.remainBudget'>
        Remain budget <span className='text-dark'>{{ currency }}{{ remainBudget }}</span>
      </Trans>
    );
  }

  renderReadOnlyViewObjective () {
    const {
      videoAdMetric,
      videoAdMetricEvent,
      offset
    } = this.props;
    const isVideoProgress = videoAdMetric === VideoAdViewObjective.PROGRESS;
    const isVideoMetric = videoAdMetric === VideoAdViewObjective.METRIC;
    let contentLabel = i18n.t('campaign.labels.videoObjectiveDefault');
    if (isVideoProgress) {
      contentLabel = `${offset} ${i18n.t('campaign.labels.sec')}`;
    }
    if (isVideoMetric) {
      contentLabel = i18n.t(`campaign.labels.${videoAdMetricEvent.toLowerCase()}`);
    }
    return (
      <FormGroup
        controlId='videoAdEvent'
        label={i18n.t('campaign.labels.videoObjective')}
      >
        <Form.Label className={`col-form-label ${styles.label}`}>
          {contentLabel}
        </Form.Label>
      </FormGroup>
    );
  }

  renderViewObjective () {
    const {
      videoAdMetricEventOptions,
      canEditPriceModel,
      videoAdMetric,
      showVideoProgressRadio
    } = this.props;

    if (!canEditPriceModel) {
      return this.renderReadOnlyViewObjective();
    }
    const isVideoProgress = videoAdMetric === VideoAdViewObjective.PROGRESS;
    const isVideoMetric = videoAdMetric === VideoAdViewObjective.METRIC;
    return (
      <Fragment>
        <FormGroup
          controlId='videoAdEvent'
          className={styles.radioFormGroup}
          label={i18n.t('campaign.labels.videoObjective')}
          valueClassName={styles.radio}
        >
          <Field
            component={withFormikOnChangeFormCheck}
            type='radio'
            label={i18n.t('campaign.labels.videoObjectiveDefault')}
            value={VideoAdViewObjective.DEFAULT}
            name='videoAdViewObjective.videoAdEvent'
            hocContent={i18n.t('campaign.descriptions.videoObjectiveDefault')}
            defaultChecked={!isVideoProgress && !isVideoMetric}
          />
        </FormGroup>
        {showVideoProgressRadio &&
          <FormGroup
            controlId='videoObjectiveProgress'
            valueClassName={styles.radio}
            className={styles.radioFormGroup}
          >
            <Field
              component={withFormikOnChangeFormCheck}
              type='radio'
              label={i18n.t('campaign.labels.videoObjectiveProgress')}
              name='videoAdViewObjective.videoAdEvent'
              value={VideoAdViewObjective.PROGRESS}
              id='video-progress'
              defaultChecked={isVideoProgress}
            />
            {isVideoProgress && (
              <InputGroup className={styles.videoAdviewObjectiveAppend}>
                <InputGroup.Prepend>
                  <InputGroup.Text id='videoAdviewObjective.offset'>
                    {i18n.t('campaign.prefixs.videoObjectiveProgress')}
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Field
                  name='videoAdViewObjective.offset'
                  component={FormikInput}
                  type='number'
                />
              </InputGroup>
            )}
          </FormGroup>
        }
        <FormGroup
          className={styles.lastVideoAdviewObjective}
          valueClassName={styles.radio}
          controlId='video-metric'
        >
          <Field
            component={withFormikOnChangeFormCheck}
            type='radio'
            label={i18n.t('campaign.labels.videoObjectiveMetric')}
            name='videoAdViewObjective.videoAdEvent'
            value={VideoAdViewObjective.METRIC}
            id='video-metric'
            defaultChecked={isVideoMetric}
          />
          {isVideoMetric && (
            <FormikPrefixInputGroup currency={i18n.t('campaign.prefixs.videoObjectiveMetric')} name='videoAdViewObjective.videoAdMetricEvent'>
              <Field
                name='videoAdViewObjective.videoAdMetricEvent'
                component={FormikSelect}
                options={videoAdMetricEventOptions}
                className={styles.videoAdMetricEvent}
                simpleValue
              />
            </FormikPrefixInputGroup>
          )}
        </FormGroup>
      </Fragment>
    );
  }

  renderTargetTipComponent = props => {
    const {
      isCPCModel,
      isCPVModel,
      isCPMModel,
      budget,
      orderPrice
    } = this.props;
    let value = 0;
    const orderPriceNumber =
      typeof orderPrice === 'string' ? parseFloat(orderPrice) : orderPrice;
    if (orderPriceNumber && orderPriceNumber >= 0) {
      value = Math.round(budget / orderPriceNumber);
    }
    let label = i18n.t('campaign.labels.targetClicks');
    let unit = i18n.t('campaign.labels.clicks');
    if (isCPMModel) {
      label = i18n.t('campaign.labels.targetImpressions');
      unit = i18n.t('campaign.labels.kImpressions');
    } else if (isCPVModel) {
      label = i18n.t('campaign.labels.targetViews');
      unit = i18n.t('campaign.labels.views');
    }

    const wrapperClassName = cx({
      [props.className]: !!props.className,
      postfixInput: true
    });

    return (
      <Fragment>
        {isCPCModel || isCPVModel || isCPMModel ? (
          <Fragment>
            <FormikInput {...props} className={wrapperClassName} />
            <span className={styles.postfixWording}>
              <div className={styles.postfix}>
                <label>{label} {value} {unit}</label>
              </div>
            </span>
          </Fragment>
        ) : (
          <FormikInput {...props} />
        )}
        <span className={styles.tipWording}>
          {this.getCampaignBudgetTips()}
        </span>
      </Fragment>
    );
  }

  renderFBOrderPriceSwitch (canEditPriceModel) {
    return (
      <FormGroup
        controlId='orderPriceEnable'
        label={i18n.t('campaignInfo.labels.orderPriceEnable')}
        valueClassName={styles.orderPriceSwitch}
      >
        <Field
          name='orderPriceEnable'
          disabled={!canEditPriceModel}
          component={withHoverTipFormikSwitch}
          hocContent={i18n.t('campaignInfo.labels.cannotModifyHint')}
        />
      </FormGroup>
    );
  }

  renderOrderPrice (priceModel, canEditPriceModel, handleChangeOrderPrice) {
    const { orderPriceMinimum, currency, showOrderPriceHint } = this.props;

    switch (priceModel) {
      case RtbCampaignPlanType.FCPC:
      case RtbCampaignPlanType.FCPM:
      case RtbCampaignPlanType.FCPV:
      case RtbCampaignPlanType.FVCPM:
        const price = `${currency}${orderPriceMinimum}`;
        const tips = showOrderPriceHint
          ? <Trans i18nKey='campaign.descriptions.orderPriceTips'>
              The price have to over <span className='text-dark'>{{ price }}</span>
            </Trans>
          : '';
        const label =
          i18n.t(`campaign.labels.${priceModel}`) +
          i18n.t('campaign.labels.orderPrice');
        return canEditPriceModel ? (
          <FormGroup controlId='orderPrice' label={label}>
            <FormikPrefixInputGroup currency={currency} name='orderPrice'>
              <Field
                name='orderPrice'
                component={withTipFormikInput}
                hocContent={tips}
                type='number'
                className={styles.price}
                onChange={handleChangeOrderPrice}
              />
            </FormikPrefixInputGroup>
          </FormGroup>
        ) : (
          <FormGroup controlId='orderPrice' label={label}>
            <span className={styles.currencyLabel}>{currency}</span>
            <Field
              name='orderPrice'
              component={FormikLabel}
              hocContent={tips}
            />
          </FormGroup>
        );
      case RtbCampaignPlanType.RB:
      case RtbCampaignPlanType.RS:
      case RtbCampaignPlanType.DCPM:
      default:
        return;
    }
  }

  renderDailyBudgetPlan () {
    const {
      currency,
      dailyBudget
    } = this.props;
    const isScheduleDistribution = is.not.existy(dailyBudget) || dailyBudget === 0;
    return (
      <FormGroup controlId='dailyBudgetPlan' label={i18n.t('campaignInfo.labels.dailyBudgetPlan')}>
        {
          isScheduleDistribution ?
            <Form.Label className={`col-form-label ${styles.label}`}>
              {i18n.t('campaignSummary.labels.budgetScheduleDistribution')}
            </Form.Label> :
            <Fragment>
              <Form.Label className={`col-form-label ${styles.label}`}>
                {i18n.t('campaignInfo.labels.daily')}
              </Form.Label>
              <FormikPrefixInputGroup
                currency={currency}
                name='dailyTargetBudget'
              >
                <Field
                  name='dailyTargetBudget'
                  component={withTipFormikInput}
                  hocContent={this.getDailyBudgetTips()}
                  type='number'
                  min={0}
                  autoComplete='off'
                />
              </FormikPrefixInputGroup>
            </Fragment>
        }
      </FormGroup>
    );
  }

  renderCampaignScheduling () {
    const { startDate, endDate } = this.props;
    const endDateMoment = moment(endDate);
    const startDateMoment = moment(startDate);
    const days = endDateMoment.diff(startDateMoment, 'days') + 1;
    const hours = endDateMoment.diff(startDateMoment, 'hours') + 1;
    if (days > 1) {
      return (
        <Trans i18nKey='campaign.descriptions.campaignSchedulingDay'>
          Total <span className='text-dark'>{{ days }} days</span>
        </Trans>
      );
    } else if (days === 1) {
      return (
        <Trans i18nKey='campaign.descriptions.campaignSchedulingHour'>
          Total <span className='text-dark'>{{ hours }} hours</span>
        </Trans>
      );
    }
  }

  render () {
    const {
      formType,
      autoOptimise,
      editCampaignGroupPath,
      errors,
      orderName,
      minDate,
      maxDate,
      canEditPriceModel,
      priceModelOptions,
      isRbModel,
      isOutOfOutdoorBk,
      agencyProfit,
      currency,
      priceModel,
      canUseDailyBudget,
      canEditBudgetPlan,
      isDailyBudgetPlan,
      onChangeDailyBudgetPlan: handleChangeDailyBudgetPlan,
      onChangePriceModel: handleChangePriceModel,
      onChangeOrderPrice: handleChangeOrderPrice,
      onChangeSchedule: handleChangeSchedule,
      showTagsPlaceholder,
      isCampaignStart,
      canEditVideoViewObjective,
      campaignAdType,
      showOrderPriceField,
      retailerOptions
    } = this.props;

    const priceModelClassName = cx('priceModel', {
      'componentWithTips': true
    });
    const dateRangePickerClassName = cx('componentWithTips', {
      disableStart: isCampaignStart
    });
    const priceModelLabelProps = { field: { value: i18n.t(`campaign.labels.${priceModel}`) } };
    const showVideoViewObjective =
      ((campaignAdType === AdType.VIDEO || campaignAdType === AdType.COMBO || campaignAdType === AdType.UNKNOW) && priceModel === RtbCampaignPlanType.FCPV) ||
      campaignAdType === AdType.THIRD_PARTY_BOTTOM;
    const CBOBudgetFormatter = () => {
      return i18n.t('campaignInfo.labels.CBOBudgetHint');
    };
    const onChangeSchedule = (startDate, endDate) => {
      handleChangeSchedule(startDate, endDate);
      this.setNeedShowDailyBudgetAlert(true);
    };
    const retailFormatter = value => {
      return i18n.t(`retailers.${value.toLowerCase()}`);
    };
    return (
      <fieldset>
        <legend>
          <span>{i18n.t('campaignInfo.labels.title')}</span>
        </legend>
        <div className={styles.component}>
          {this.state.alertContent &&
            <AlertDialog
              title={i18n.t('common.warning')}
              message={this.state.alertContent}
              danger={false}
              dismiss={this.dismissAlertWindow}
            />
          }
          <FormGroup label={i18n.t('campaignInfo.labels.order')} controlId='orderName'>
            <Form.Label className={`col-form-label ${styles.label}`}>
              {orderName}
            </Form.Label>
          </FormGroup>
          {
          retailerOptions && (
              formType === 'create' ?
                <FormikField.Select
                  label={i18n.t('campaignInfo.labels.retailType')}
                  name='retailType'
                  simpleValue
                  options={retailerOptions}
                /> :
                <FormikField.Label
                  label={i18n.t('campaignInfo.labels.retailType')}
                  name='retailType'
                  formatter={retailFormatter}
                />
            )
          }
          <FormGroup label={i18n.t('campaignInfo.labels.campaignName')} controlId='name'>
            <Field type='text' name='name' component={FormikInput} />
          </FormGroup>
          <FormGroup label={i18n.t('campaignInfo.labels.dayRange')} controlId='campaignDateRange'>
            <Fragment>
              <Field
                className={dateRangePickerClassName}
                component={FormikDateRangePicker}
                minDate={minDate}
                maxDate={maxDate}
                startDateFormikName='startDate'
                endDateFormikName='endDate'
                name='dayRange'
                timeInterval={60}
                startDatePickerDisabled={isCampaignStart}
                onPopOverClosed={this.checkIsUnderBudget}
                onChange={onChangeSchedule}
              />
              <span className={styles.hoverTip}>{i18n.t('campaignInfo.labels.dateCannotModifyHint')}</span>
              <span className={styles.hint}>{this.renderCampaignScheduling()}</span>
            </Fragment>
          </FormGroup>
          <FormGroup label={i18n.t('campaignInfo.labels.priceModel')} controlId='priceModel'>
            {canEditPriceModel ?
              <Fragment>
                <Field
                  className={priceModelClassName}
                  name='priceModel'
                  component={FormikSelect}
                  simpleValue
                  options={priceModelOptions}
                  onChange={handleChangePriceModel}
                />
                <span className={styles.hoverTip}>{i18n.t('campaignInfo.labels.cannotModifyPriceModelHint')}</span>
                {priceModel === RtbCampaignPlanType.RB && <span className={styles.hint}>{i18n.t('campaignInfo.labels.rbPriceModelHint')}</span>}
              </Fragment> :
              <FormikLabel {...priceModelLabelProps} />
            }
          </FormGroup>
          {showVideoViewObjective && canEditVideoViewObjective && this.renderViewObjective()}
          {showOrderPriceField && this.renderOrderPrice(priceModel, canEditPriceModel, handleChangeOrderPrice)}
          {!isRbModel && (
            autoOptimise ?
              <FormikField.Label
                label={i18n.t('campaignInfo.labels.totalBudget')}
                name='budget'
                formatter={CBOBudgetFormatter}
                hint={errors.budget ?
                  <div className={styles.budgetError}>
                    <div className='errorTip'>{errors.budget}</div>
                    <Link to={editCampaignGroupPath}>
                      {i18n.t('campaignInfo.labels.backToEditRtbCampaignGroup')}
                    </Link>
                  </div> :
                  ''
                }
              /> :
              <FormGroup label={i18n.t('campaignInfo.labels.totalBudget')} controlId='budget'>
                <FormikPrefixInputGroup currency={currency} name='budget'>
                  <Field
                    name='budget'
                    component={this.renderTargetTipComponent}
                    type='number'
                    min={0}
                    autoComplete='off'
                    onBlur={this.checkIsUnderBudget}
                    onChange={_.partial(this.setNeedShowDailyBudgetAlert, true)}
                  />
                </FormikPrefixInputGroup>
              </FormGroup>
          )}

          {!isRbModel &&
            canUseDailyBudget &&
            !canEditBudgetPlan &&
            this.renderDailyBudgetPlan()}
          {!isRbModel && canUseDailyBudget && canEditBudgetPlan && (
            <Fragment>
              <FormGroup
                controlId='dailyBudgetPlan'
                className={styles.radioFormGroup}
                label={i18n.t('campaignInfo.labels.dailyBudgetPlan')}
                valueClassName={styles.radio}
              >
                {withHoverTipFormCheck(
                  {
                    type: 'radio',
                    label: i18n.t('campaignInfo.labels.schedule'),
                    value: DailyBudgetPlan.SCHEDULE,
                    name: 'dailyBudgetPlan',
                    id: 'Schedule',
                    onChange: handleChangeDailyBudgetPlan,
                    defaultChecked: !isDailyBudgetPlan,
                    hocContent: i18n.t('campaignInfo.labels.cannotModifyHint'),
                    className: styles.budgetPlanRadio
                  }
                )}
              </FormGroup>
              <Row className={styles.dailyOption}>
                <Col sm={3} />
                <Col sm={9} className={styles.radio}>
                  {withHoverTipFormCheck(
                    {
                      type: 'radio',
                      label: i18n.t('campaignInfo.labels.daily'),
                      value: DailyBudgetPlan.DAILY,
                      name: 'dailyBudgetPlan',
                      id: 'Daily',
                      onChange: handleChangeDailyBudgetPlan,
                      defaultChecked: isDailyBudgetPlan,
                      hocContent: i18n.t('campaignInfo.labels.cannotModifyHint'),
                      className: styles.budgetPlanRadio
                    }
                  )}
                </Col>
              </Row>
            </Fragment>
          )}
          {isDailyBudgetPlan && canEditBudgetPlan && (
            <FormGroup
              className={styles.dailyBudgetInput}
              controlId='dailyTargetBudget'
            >
              <Fragment>
                <FormikPrefixInputGroup
                  currency={currency}
                  name='dailyTargetBudget'
                >
                  <Field
                    name='dailyTargetBudget'
                    component={withTipFormikInput}
                    hocContent={this.getDailyBudgetTips()}
                    type='number'
                    min={0}
                    autoComplete='off'
                    onBlur={this.checkIsUnderBudget}
                    onChange={_.partial(this.setNeedShowDailyBudgetAlert, true)}
                  />
                </FormikPrefixInputGroup>
              </Fragment>
            </FormGroup>
          )}
          <FormGroup label={i18n.t('campaign.labels.tags')} controlId='tags'>
            <Field
              name='tags'
              component={FormikTags}
              placeholder={showTagsPlaceholder ? i18n.t('campaign.placeholders.tags') : ''}
            />
          </FormGroup>
          <PermissionChecker
            permissionAware={
              notRoles(RoleNames.adsAdmin, RoleNames.adsReport, RoleNames.adsSales)
                .and(addOnEnabled(ADDONFEATURE.CAMPAIGN.ORDER_AGENCY_PROFIT_SETTING))
            }
          >
            <FormGroup label={i18n.t('campaign.labels.agencyProfit')} controlId='agencyProfit'>
              <Form.Label className={`col-form-label ${styles.label}`}>
                {agencyProfit}
              </Form.Label>
            </FormGroup>
          </PermissionChecker>
          {campaignAdType === AdType.OUTDOOR && (
          <FormGroup controlId='isOutOfOutdoorBk' label={i18n.t('campaignInfo.labels.isOutOfOutdoorBk')}>
            <Field
              name='isOutOfOutdoorBk'
              render={_.partial(renderIsOutOfOutdoorBk, isOutOfOutdoorBk)}
            />
          </FormGroup>
          )}
        </div>
      </fieldset>
    );
  }
}

export default RtbCampaignInfo;

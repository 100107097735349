import { CreativeListDataProvider } from './CreativeListDataProvider';
import { CreativeListCommonColumns, commonColumnSetting, getCommonColumnData, getColumnSetting } from './CreativeListCommonColumnData';
import { renderColumn } from 'components/TableColumn';
import { getCreativeListFormatters } from './CreativeListFormatters';
import { CreativeListRecord, OutdoorType } from 'core/creative/Creative';
import i18n from 'i18n';
import _ from 'lodash';

enum COLUMNS {
  OUTDOOR_TYPE = 'outdoorType',
  TEMPERATURE = 'temperature',
  DURATION = 'duration'
}

export class OutdoorCreativeListDataProvider implements CreativeListDataProvider {

  formatters: any;
  constructor (model) {
    this.formatters = getCreativeListFormatters(model);
  }

  getColumns () {
    return [
      renderColumn(commonColumnSetting[CreativeListCommonColumns.NAME_ID], this.formatters.nameIdFormatter, this.formatters.nameIdHeaderFormatter),
      renderColumn(commonColumnSetting[CreativeListCommonColumns.PREVIEW], this.formatters.previewFormatter),
      renderColumn(getColumnSetting(COLUMNS.OUTDOOR_TYPE), this.formatters.outdoorTypeFormatter),
      renderColumn(getColumnSetting(COLUMNS.TEMPERATURE), this.formatters.textFormatter),
      renderColumn(getColumnSetting(COLUMNS.DURATION), this.formatters.durationFormatter),
      renderColumn(commonColumnSetting[CreativeListCommonColumns.BIND_STATE], this.formatters.bindStateFormatter),
      renderColumn(commonColumnSetting[CreativeListCommonColumns.LIMITATION], this.formatters.limitationFormatter),
      renderColumn(commonColumnSetting[CreativeListCommonColumns.EDITBTNS], this.formatters.floatingEditBtnsFormatter)
    ];
  }

  getViewModel (creative: CreativeListRecord) {
    const useTempControl = _.get(creative, 'bannerExtra.useTempControl', false);
    const minTemp = _.get(creative, 'bannerExtra.minTemp', 25);
    const maxTemp = _.get(creative, 'bannerExtra.maxTemp', 30);
    const outdoorType = _.get(creative, 'outdoorType', OutdoorType.VIDEO);
    const duration = _.get(creative, 'duration', 30);
    return {
      ...getCommonColumnData(creative),
      [COLUMNS.OUTDOOR_TYPE]: outdoorType,
      [COLUMNS.TEMPERATURE]: useTempControl ? `${minTemp}°C - ${maxTemp}°C` : i18n.t('creativeList.labels.none'),
      [COLUMNS.DURATION]: duration
    };
  }
}

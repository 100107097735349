import { CreativeListRecord, CreativeOfCampaign } from 'core/creative/Creative';
import React from 'react';
import styles from './creativePreview.module.scss';

export const NormalCreativePreview: React.FunctionComponent<{creative: CreativeOfCampaign | CreativeListRecord}> = (props) => {

  const bannerImageUrl = props.creative.bannerImageUrl;
  if (!bannerImageUrl) {
    return <div/>;
  }
  return (
    <div className={styles.normalCreativePreview}>
      <img src={bannerImageUrl} alt={'preview'} />
    </div>
  );
};

import moment from 'moment';
import _ from 'lodash';
import i18n from 'i18n';
import { toast } from 'react-toastify';
import { FireableUpdateEventListener } from 'utils/UpdateEventListener';
import { CampaignGroupChannel, FbEffectiveStatus, TiktokStatus } from 'core/campaignGroup/CampaignGroup';
import { Order } from 'core/order/Order';
import { DraftCreateDTO, DraftValidationDTO } from 'core/draft/Draft';
import { DefaultDraftManager, DraftManager } from 'core/draft/DraftManager';
import { DefaultOrderManager, OrderManager } from 'core/order/OrderManager';

export abstract class DefaultDraftSummaryStateContentModel {

  abstract channel: CampaignGroupChannel;

  canGoNext: boolean = true;

  event: FireableUpdateEventListener<{loading: boolean}>;

  summaryData: DraftCreateDTO[] = [];

  accountId: string = '';

  constructor (
    private orderManager: OrderManager = new DefaultOrderManager(),
    private draftManager: DraftManager = new DefaultDraftManager()
  ) {
    this.event = new FireableUpdateEventListener<{loading: boolean}>();
  }

  updateState (loading: boolean) {
    this.event.fireEvent({ loading });
  }

  setCanGoNext = (canGoNext: boolean) => {
    this.canGoNext = canGoNext;
  }

  abstract setAccountId: (data: any) => void;

  getValidationData = async (data: any): Promise<{
    [goCampaignGroupId: number]: {
      status: any
    }}> => {
    const goCampaignsToCreateDraft: any[] = _.get(data, 'goCampaignsToCreateDraft', []);
    const targets: any[] = _.get(data, 'targets', []);
    this.setAccountId(data);
    this.setSummaryData(goCampaignsToCreateDraft, targets);
    const validationDataMap = await this.validate(targets);
    return validationDataMap;
  }

  setSummaryData = (goCampaignsToCreateDraft: any[], targets: any[]) => {
    let summaryData: DraftCreateDTO[] = [];
    _.forEach(goCampaignsToCreateDraft, (goCampaign) => {
      _.forEach(targets, (target) => {
        let draftCreateDTO: DraftCreateDTO = {
          includeBinding: _.get(target, 'includeBinding', false),
          channel: this.channel,
          targetGoCampaignGroupId: _.get(target, 'campaignGroup.value', 0),
          sourceGoCampaignChannelId: _.get(goCampaign, 'id', 0),
          numberOfCopies: _.get(target, 'numberOfCopies', 1),
          draft: _.get(goCampaign, 'isDraft', false)
        };
        summaryData = [
          ...summaryData,
          draftCreateDTO
        ];
      });
    });
    this.summaryData = summaryData;
  }

  abstract validateDelivery: (deliveryStatus: string) => void;

  validateBindings = (bindings: any[]) => {
    let status: any;
    const numOfInvalidBinding = _.filter(bindings,
      binding => _.get(binding, 'reason', []).length > 0 || !binding.mappingGoCreative
    );
    if (numOfInvalidBinding.length > 0) {
      status = {
        type: 'warning',
        payload: i18n.t('draftSummaryStateContent.status.warning.bindings', { num: numOfInvalidBinding.length })
      };
    }
    return _.omitBy(status, _.isNil);
  }

  validateOrderEndDate = (targetOrder: Order) => {
    let status: any;
    let today = moment().startOf('day');
    let endDate = moment(targetOrder.endDate, 'YYYY-MM-DD');
    let diffEnd = endDate.diff(today, 'days');
    if (diffEnd < 0) {
      status = {
        type: 'error',
        payload: i18n.t('draftSummaryStateContent.status.error.endDate')
      };
    }
    return _.omitBy(status, _.isNil);
  }

  validateAccount = (channelAccountId: string) => {
    let status: any;
    if (this.accountId !== channelAccountId) {
      status = {
        type: 'error',
        payload: i18n.t('draftSummaryStateContent.status.error.account')
      };
    }
    return _.omitBy(status, _.isNil);
  }

  validate = async (targets: any[]) => {
    const targetOrderIds: number[] = _.map(targets, (target) => _.get(target, 'order.value', 0));

    const targetOrders: Order[] = await Promise.all(targetOrderIds.map(orderId => this.orderManager.getOrderById(orderId)));
    const validationInfos: DraftValidationDTO[] = await this.draftManager.getValidationInfos(this.summaryData);

    let validationDataMap = {};
    _.forEach(targets, (target) => {
      const orderId = _.get(target, 'order.value', 0);
      const campaignGroupId = _.get(target, 'campaignGroup.value', 0);

      const targetOrder = _.filter(targetOrders, order => order.id === orderId)[0];
      const validationInfo = _.filter(validationInfos, info => info.targetGoCampaignGroupId === campaignGroupId)[0];
      const statusList = _.filter([
        this.validateOrderEndDate(targetOrder),
        this.validateDelivery(validationInfo.deliveryStatus),
        this.validateAccount(validationInfo.channelAccountId.toString()),
        this.validateBindings(validationInfo.bindings)
      ], status => !_.isEmpty(status));
      validationDataMap = {
        ...validationDataMap,
        [campaignGroupId]: {
          status: !_.isEmpty(statusList)
           ? statusList
           : [{
             type: 'passed',
             payload: i18n.t('draftSummaryStateContent.status.passed')
           }]
        }
      };
    });
    return validationDataMap;
  }

  save = async () => {
    try {
      this.updateState(true);
      await this.draftManager.createDraft(this.summaryData);
      toast.success(i18n.t('draftSummaryState.labels.createSuccess'));
      this.updateState(false);
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
      console.log('error', e);
      this.updateState(false);
    }
  }
}

export class RtbCampaignDraftSummaryStateContentModel extends DefaultDraftSummaryStateContentModel {

  channel: CampaignGroupChannel = CampaignGroupChannel.RTB;

  setAccountId = (data: any) => {
    this.accountId = _.get(data, 'order.advertiserId', 0).toString();
  }

  validateDelivery = (deliveryStatus: string) => {
    let status: any;
    switch (deliveryStatus) {
      case 'ACTIVE':
      case 'INACTIVE':
        break;
      default:
        status = {
          type: 'error',
          payload: i18n.t('draftSummaryStateContent.status.error.delivery')
        };
        break;
    }
    return _.omitBy(status, _.isNil);
  }
}

export class FbAdSetDraftSummaryStateContentModel extends DefaultDraftSummaryStateContentModel {

  channel: CampaignGroupChannel = CampaignGroupChannel.FB;

  setAccountId = (data: any) => {
    this.accountId = _.get(data, 'campaignGroup.fb.account_id', 0).toString();
  }

  validateDelivery = (deliveryStatus: string) => {
    let status: any;
    switch (deliveryStatus) {
      case FbEffectiveStatus.DELETED:
      case FbEffectiveStatus.ARCHIVED:
        status = {
          type: 'error',
          payload: i18n.t('draftSummaryStateContent.status.error.delivery')
        };
        break;
      default:
        break;
    }
    return _.omitBy(status, _.isNil);
  }
}

export class TiktokAdGroupDraftSummaryStateContentModel extends DefaultDraftSummaryStateContentModel {

  channel: CampaignGroupChannel = CampaignGroupChannel.TIKTOK;

  setAccountId = (data: any) => {
    this.accountId = _.get(data, 'campaignGroup.tiktok.advertiser_id', 0).toString();
  }

  validateDelivery = (deliveryStatus: string) => {
    let status: any;
    switch (deliveryStatus) {
      case TiktokStatus.CAMPAIGN_STATUS_ADVERTISER_AUDIT_DENY:
      case TiktokStatus.CAMPAIGN_STATUS_ADVERTISER_AUDIT:
      case TiktokStatus.ADVERTISER_CONTRACT_PENDING:
      case TiktokStatus.CAMPAIGN_STATUS_DELETE:
        status = {
          type: 'error',
          payload: i18n.t('draftSummaryStateContent.status.error.delivery')
        };
        break;
      default:
        break;
    }
    return _.omitBy(status, _.isNil);
  }
}

export class RetailCampaignDraftSummaryStateContentModel extends DefaultDraftSummaryStateContentModel {

  channel: CampaignGroupChannel = CampaignGroupChannel.RETAIL_MEDIA;

  setAccountId = (data: any) => {
    this.accountId = _.get(data, 'order.advertiserId', 0).toString();
  }

  validateDelivery = (deliveryStatus: string) => {
    let status: any;
    switch (deliveryStatus) {
      case 'ACTIVE':
      case 'INACTIVE':
        break;
      default:
        status = {
          type: 'error',
          payload: i18n.t('draftSummaryStateContent.status.error.delivery')
        };
        break;
    }
    return _.omitBy(status, _.isNil);
  }
}

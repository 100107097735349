import {
  OutdoorBkWebService,
  RestfulOutdoorBkWebService
} from 'ws/OutdoorWebService';
import { OutdoorBkData } from './OutdoorBkRecord';

export interface OutdoorBkManager {
  getOutdoorBkData (agencyId: string | number, unit: string, from: string, to: string, timeZone: string): Promise<OutdoorBkData>;
}

export class DefaultOutdoorBkManager implements OutdoorBkManager {
  webService: OutdoorBkWebService;

  constructor (
    webService: OutdoorBkWebService = new RestfulOutdoorBkWebService()
  ) {
    this.webService = webService;
  }

  async getOutdoorBkData (agencyId: string | number, unit: string, from: string, to: string, timeZone: string): Promise<OutdoorBkData> {
    return this.webService.getOutdoorBkData(agencyId, unit, from, to, timeZone);
  }
}

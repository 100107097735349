import React from 'react';
import styles from './retailMax.module.scss';
import { Switch, Route, RouteComponentProps, Redirect } from 'react-router';
import { RetailMaxProps } from './RetailMaxModel';
import { ProductCategory } from './ProductCategory/ProductCategory';
import { ProductSets } from './ProductSet/ProductSets';

// import { LoadingIndicator } from 'components/common/LoadingIndicator';
import RouteWithPermission from 'components/RouteWithPermission/RouteWithPermission';
import { RoleNames } from 'core';
import { isProject, notRoles } from 'core/permission/PermissionDSL';
import { ROUTE_PATH } from 'enum/RoutePath';
import { withErrorBoundary } from 'hoc/withErrorBoundary';
import { ERROR_CODE } from 'core/ErrorCode';
import { Project } from 'core/project/Project';
// import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
// import _ from 'lodash';

const ProductCategoryView = withErrorBoundary(ERROR_CODE.PRODUCT_CATEGORY, ProductCategory);
export class RetailMax extends React.Component<RouteComponentProps<{}> & RetailMaxProps> {

  handler: any;

  componentDidMount () {
    this.handler = this.props.model.event.add((model) => {
      this.setState(model.state);
    });
  }

  componentWillUnmount () {
    this.props.model.event.remove(this.handler);
  }

  renderProductCategory = () => {

    return (
      <div className={styles.container}>
        <ProductCategoryView />
      </div>
    );
  }

  renderProductSets = () => (
    <ProductSets />
  )

  redirectToRoot = () => {
    return <Redirect to={this.props.mainPagePath} />;
  }

  render () {
    const { sysAdmin, adsReport } = RoleNames;

    return (
      <div className={styles.retailMax}>
        <Switch>
          <RouteWithPermission
            path='/product-category'
            permissionAware={notRoles(sysAdmin, adsReport).and(isProject(Project.RETAIL))}
            redirectPath={`/product-category/${ROUTE_PATH.ERROR403}`}
            render={this.renderProductCategory}
          />
          <RouteWithPermission
            path='/product-sets'
            permissionAware={notRoles(sysAdmin, adsReport).and(isProject(Project.RETAIL))}
            redirectPath={`/product-sets/${ROUTE_PATH.ERROR403}`}
            render={this.renderProductSets}
          />
          <Route render={this.redirectToRoot} />
        </Switch>
      </div>
    );
  }
}

import React, { Fragment } from 'react';
import { CreativeListModelProps } from './CreativeListModel';
import i18n from 'i18n';
import styles from './CreativeList.module.scss';
import classNames from 'classnames/bind';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { Redirect } from 'react-router-dom';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { hasFuncs } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';
import { TablePlaceholder } from 'components/TablePlaceholder/TablePlaceholder';
import { StickableBootstrapTable } from 'components/common/Table/StickableBootstrapTable';

const campaignWritePermission = hasFuncs(Permission.CAMPAIGN_WRITE);
export class CreativeList extends React.Component<CreativeListModelProps> {
  handler: number;
  cssClassNames: any;

  constructor (props) {
    super(props);
    this.cssClassNames = classNames.bind(styles);
    this.handler = this.props.model.event.add((model) => {
      this.setState(model.state);
    });
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add((model) => {
        this.setState(model.state);
      });
    }
  }

  componentWillUnmount () {
    this.props.model.event.remove(this.handler);
  }

  HeaderWithSelect = (column: any): any => {
    const model = this.props.model;
    const creativeList = model.creativeList ? model.creativeList : [];
    const handleOnSelectAll = (e) => {
      this.props.model.handleOnSelectAll();
    };
    return (
      <Fragment>
        <div className={styles.idHeader} >
          <div className={styles.selectCheckBox}>
            <PermissionChecker permissionAware={hasFuncs(Permission.CREATIVE_WRITE).or(hasFuncs(Permission.CAMPAIGN_WRITE))}>
              <input
                type='checkbox'
                checked={creativeList.length - 1 === model.selectedCreatives.length && creativeList.length > 1}
                ref={el => el && (el.indeterminate = creativeList.length - 1 !== model.selectedCreatives.length && model.selectedCreatives.length > 0)}
                onChange={handleOnSelectAll}
                id={'creativeListInput0'}
              />
              <label htmlFor='creativeListInput0'/>
            </PermissionChecker>
          </div>
          {i18n.t(column.text)}
        </div>
      </Fragment>
    );
  }

  renderListNoDataIndication = () => {
    const renderSystemAdminPlaceholder = () => <TablePlaceholder placeholder={i18n.t('campaignCreativeList.labels.noDataWithoutAddPermission')}/>;
    return (
      <PermissionChecker
        permissionAware={campaignWritePermission}
        renderOtherwise={renderSystemAdminPlaceholder}
      >
        <TablePlaceholder placeholder={i18n.t('campaignCreativeList.labels.noData')}/>
      </PermissionChecker>
    );
  }

  render () {
    const model = this.props.model;
    const rowClasses = (creative, rowIndex) => {
      let classes: any = null;
      if (rowIndex === 0) {
        classes = styles.summaryRow;
      }

      if (creative.stateColumn && creative.stateColumn.styleName === 'budgetRemain') {
        classes = [classes, styles.lightDanger].join(' ');
      }

      return classes;
    };

    if (model.state.redirectPath) {
      return <Redirect push to={model.state.redirectPath}/>;
    }
    return (
      <div className={styles.creativeList}>
        {model.state.loading && <LoadingIndicator />}
        <div className={styles.tableContainer}>
          <StickableBootstrapTable
            hover
            keyField='id'
            stickFirstColumn
            stickLastColumn
            data={model.creativeList}
            columns={model.columns}
            paddingBottom={false}
            noDataIndication={this.renderListNoDataIndication}
            rowClasses={rowClasses}
          />
        </div>
      </div>
    );
  }
}

import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import { State, Order, FinalReportProjectType } from 'core/order/Order';
import { OrderManager, DefaultOrderManager } from 'core/order/OrderManager';
import { SelectOptions } from 'components/common/commonType';
import moment from 'moment';
import { LocaleMeta, AddonFeatureManager } from 'core';
import i18n from 'i18next';
import _ from 'lodash';
import { FormikProps } from 'formik';
import { toast } from 'react-toastify';
import { CreateOrderFormContent } from './CreateOrderFormContent';
import { EditOrderFormContent } from './EditOrderFormContent';
import { SessionStorageHelper, SessionStorageItemKeys } from 'helper/StorageHelper';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { validateMinimum, validateMinMax, validateEmpty } from 'utils/ValidateUtils';
import { DynamicBreadcrumb } from 'components/Breadcrumbs/DynamicBreadcrumbs';
import { renderCreditHint, renderErrors } from './OrderFormHintRenderFuntions';
import { getFieldErrors } from 'utils/FormikUtils';
import { Project } from 'core/project/Project';

export type OrderFormState = {
  readonly loading: boolean;
  readonly redirectPath?: string;
  readonly needCheckModifyReason: boolean;
  readonly showBudgetInputField: boolean,
  readonly showMarginInputField: boolean,
};

export interface OrderFormModel {
  readonly state: OrderFormState;
  readonly event: UpdateEventListener<OrderFormModel>;
  readonly order?: Order;
  readonly advertiserList: Array<SelectOptions>;
  readonly localeMeta?: LocaleMeta;
  readonly formikProps?: FormikProps<Order>;
  readonly title: string;
  readonly totalDays: number;
  readonly dateRangeMinDate: string;
  readonly dateRangeMaxDate: string;
  readonly contentComponent: any;
  readonly canEditMargin: boolean;
  readonly canEditMonitor: boolean;
  readonly orderExternalTypes: Array<SelectOptions>;
  readonly showOrderExternalTypes: boolean;
  readonly showEditButton: boolean;
  readonly isOrderHasApproved: boolean;
  readonly isOrderStarted: boolean;
  readonly budgetTip: any;
  readonly isOutdoorOrder: boolean;
  readonly breadcrumbs: any[];
  init (): Promise<void>;
  validate (order: Partial<Order>): any;
  validateAndSetError (): any;
  setFormikProps (props: FormikProps<Order>): void;
  submit (): void;
  setNeedCheckModifyReason (needCheck: boolean): void;
  onUnmount (eventHandler): void;
  triggerBudgetInputField? ();
  triggerMarginInputField? ();
  onAdvertiserChange (newAdvertiser);
  isFinalReportProjectNameExist (finalReportProjectName: string): boolean;
}

const NOT_NEED_BUDGET_MAX = false;

export type OrderFormProps = {
  readonly model: OrderFormModel;
};

// const validateFinalReportReceivers = (receivers) => {
//   if (receivers.length === 0) {
//     return i18n.t('formValidate.labels.emptyError');
//   }
//   const invalidEmails = receivers.filter(receiver => validateEmail(receiver) !== undefined);
//   if (invalidEmails.length > 0) {
//     return i18n.t('orderForm.errors.finalReport.receivers', { invalidEmails: invalidEmails.join(', ') });
//   }
// };

abstract class DefaultOrderFormModel implements OrderFormModel {
  event: FireableUpdateEventListener<OrderFormModel>;
  loading: boolean;
  order: any;
  manager: OrderManager;
  advertiserList: Array<SelectOptions>;
  formikProps?: FormikProps<Order>;
  redirectPath?: string;
  needCheckModifyReason: boolean;
  canEditMargin: boolean;
  localeMeta: LocaleMeta;
  hasOrderBudgetMaximun: boolean;
  orderExternalTypes: Array<SelectOptions>;
  addonFeatureManager: AddonFeatureManager;
  showBudgetInputField: boolean;
  showMarginInputField: boolean;
  existedProjectNames: string[] = [];

  constructor (
    canEditMargin: boolean,
    hasOrderBudgetMaximun: boolean,
    manager: OrderManager,
    localeMeta: LocaleMeta,
    advertisers: Array<SelectOptions>,
    addonFeatureManager: AddonFeatureManager
  ) {
    this.event = new FireableUpdateEventListener<OrderFormModel>();
    this.loading = true;
    this.manager = manager;
    this.advertiserList = advertisers ? advertisers : [];
    this.needCheckModifyReason = false;
    this.canEditMargin = canEditMargin;
    this.localeMeta = localeMeta;
    this.hasOrderBudgetMaximun = hasOrderBudgetMaximun;
    this.orderExternalTypes = [];
    this.addonFeatureManager = addonFeatureManager;
    this.showBudgetInputField = false;
    this.showMarginInputField = false;
  }

  setFormikProps (props: FormikProps<Order>) {
    this.formikProps = props;
  }

  abstract get budgetTip ();

  abstract get contentComponent ();

  abstract get title ();

  abstract get breadcrumbs ();

  get isOutdoorOrder () {
    return this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.COMPANY.OUTDOOR_AGENCY);
  }

  get dateRangeMinDate () {
    return moment().startOf('day').format('YYYY-MM-DD');
  }

  get dateRangeMaxDate () {
    return moment().startOf('day').add(10, 'years').format('YYYY-MM-DD');
  }

  get showOrderExternalTypes () {
    return this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.COMPANY.SHOW_ORDER_EXTERNAL_TYPE);
  }

  get canEditMonitor () {
    return this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.MONITOR.MONITOR_CAMPAIGN_BEHIND_PROGRESS);
  }

  get totalDays () {
    if (!this.formikProps) {
      return 0;
    }
    const formProps = this.formikProps;
    return moment(formProps.values.endDate).diff(moment(formProps.values.startDate), 'days') + 1;
  }

  abstract init (): Promise<void>;

  abstract validate (order?: Partial<Order>): any;

  // async getOrderExternalTypeList (): Promise<void> {
  //   try {
  //     const allOrderExternalType = await this.manager.getOrderExternalTypes();
  //     this.orderExternalTypes = allOrderExternalType.filter(orderExternalTypes => orderExternalTypes.active !== false);
  //   } catch (e) {}
  // }

  onAdvertiserChange = (newAdvertiser) => {
    if (!this.formikProps) {
      return;
    }
    const advertiser = this.advertiserList.find(advertiser => advertiser.value === newAdvertiser);
    this.formikProps.setFieldValue('finalReportAdvertiserName', advertiser ? advertiser.label : '');
  }

  onUnmount = (eventHandler) => {
    this.event.remove(eventHandler);
    this.redirectPath = undefined;
    this.showBudgetInputField = false;
    this.showMarginInputField = false;
  }

  validateAndSetError = () => {
    if (!this.formikProps) {
      return {};
    }

    let errors = this.validate(this.formikProps.values);
    this.formikProps && this.formikProps.setErrors(errors);
    let touched = {};
    this.generateFormikTouchedObj(errors, touched);
    this.formikProps && this.formikProps.setTouched(touched);
    return errors;
  }

  generateFormikTouchedObj (errors, touched) {
    let keys = Object.keys(errors);
    keys.forEach(key => {
      let value = errors[key];
      if (typeof value === 'object') {
        touched[key] = {};
        this.generateFormikTouchedObj(errors[key], touched[key]);
      } else {
        touched[key] = true;
      }
    });
  }

  abstract submit (): void;

  get state (): OrderFormState {
    return {
      loading: this.loading,
      redirectPath: this.redirectPath,
      needCheckModifyReason: this.needCheckModifyReason,
      showBudgetInputField: this.showBudgetInputField,
      showMarginInputField: this.showMarginInputField
    };
  }

  get showEditButton (): boolean {
    const hideEditBtn = this.order ? _.includes([State.NOT_APPROVE, State.REJECT], this.order.state) || !!this.order.modifyReason : true;
    return !hideEditBtn;
  }

  get isOrderHasApproved (): boolean {
    const notApproved = this.order ? _.includes([State.NOT_APPROVE, State.REJECT], this.order.state) : true;
    return !notApproved;
  }

  get isOrderStarted (): boolean {
    if (!this.order) {
      return false;
    }
    return moment().startOf('day').isAfter(moment(this.order.startDate));
  }

  abstract setNeedCheckModifyReason (needCheck: boolean);

  abstract isFinalReportProjectNameExist (finalReportProjectName: string);

  updateState (loading: boolean) {
    this.loading = loading;
    this.event.fireEvent(this);
  }

}

export class CreateOrderFormModel extends DefaultOrderFormModel {

  defaultFinalReportAdvertiserType = process.env.REACT_APP_PROJECT === Project.RETAIL ? FinalReportProjectType.OTHERS : FinalReportProjectType.GOMART;

  constructor (canEditMargin: boolean,
    hasOrderBudgetMaximun: boolean,
    advertisers: Array<SelectOptions>,
    localeMeta: LocaleMeta,
    addonFeature: AddonFeatureManager,
    manager: OrderManager = new DefaultOrderManager()) {
    super(canEditMargin, hasOrderBudgetMaximun, manager, localeMeta, advertisers, addonFeature);
  }

  get breadcrumbs () {
    return [
      { path: '/orders', breadcrumb: i18n.t('orderDetail.labels.title') },
      { path: '/orders/new', breadcrumb: this.title }
    ];
  }

  get budgetTip (): any {
    const credit = this.localeMeta.credit;
    const hasCreditLimit = this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.COMPANY.AGENCY_CREDIT_LIMIT);
    if (hasCreditLimit && this.formikProps) {
      const hasBudetError = !!getFieldErrors(this.formikProps, 'budget');
      if (!hasBudetError && credit < this.formikProps.values.budget) {
        return renderCreditHint(credit);
      }
    }
    return '';
  }

  async init (): Promise<void> {
    this.updateState(true);
    // await this.getOrderExternalTypeList();
    try {
      this.existedProjectNames = await this.manager.getExistedProjectNames();
    } catch (e) {}
    const advertiserId = SessionStorageHelper.getNumberItem(SessionStorageItemKeys.ADVERTISER);
    const advertiser = this.advertiserList.find(advertiser => advertiser.value === advertiserId);
    this.order = {
      advertiserId,
      budget: 0,
      orderMargin: _.round(this.localeMeta.agcPercent * 100, 2),
      state: State.NOT_APPROVE,
      startDate: moment().startOf('day').format('YYYY-MM-DD'),
      endDate: moment().endOf('day').format('YYYY-MM-DD'),
      currency: this.localeMeta.currency,
      orderPrice: 0,
      creativeDuration: 0,
      dayPart: {},
      finalReportProjectName: '',
      finalReportProjectType: this.defaultFinalReportAdvertiserType,
      finalReportReceivers: [],
      finalReportSendOutDate: moment().startOf('day').add(2, 'day').format('YYYY-MM-DD'),
      finalReportAdvertiserName: advertiser ? advertiser.label : '',
      finalReportTargetImpressions: 0
    };
    if (this.isOutdoorOrder) {
      const dayPartHourRange = Array.from({ length: 24 }, (_, index) => index);
      this.order.orderPrice = 100;
      this.order.creativeDuration = 30;
      this.order.dayPart = {
        '1': [...dayPartHourRange],
        '2': [...dayPartHourRange],
        '3': [...dayPartHourRange],
        '4': [...dayPartHourRange],
        '5': [...dayPartHourRange],
        '6': [...dayPartHourRange],
        '7': [...dayPartHourRange]
      };
    }
    this.updateState(false);
  }

  async submit () {
    if (!this.formikProps) {
      return;
    }
    const values = this.formikProps.values;
    const newOrder = {
      ...values,
      finalReportTargetImpressions: values.finalReportTargetImpressions ? +values.finalReportTargetImpressions : 0,
      orderMargin: _.round(values.orderMargin / 100, 4),
      startDate: moment(values.startDate).format('YYYY-MM-DD'),
      endDate: moment(values.endDate).format('YYYY-MM-DD'),
      finalReportSendOutDate: values.finalReportSendOutDate ? moment(values.finalReportSendOutDate).format('YYYY-MM-DD') : undefined,
      finalReportProjectName: values.finalReportProjectType === FinalReportProjectType.OTHERS ? 'Others' : values.finalReportProjectName
    };
    this.updateState(true);
    try {
      const order = await this.manager.createOrder(newOrder);
      this.updateState(false, `/orders/${order.orderNumber}`);
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
      this.updateState(false);
    }
  }

  validate = (order: Partial<Order>) => {
    const marginMin = 0;
    const marginMax = this.localeMeta.maxOrderProfit * 100;
    const budgetMin = 1;
    const maxOrderBudget = this.hasOrderBudgetMaximun && this.localeMeta.maxOrderBudget;
    const budgetMax = _.min(_.xor([maxOrderBudget, NOT_NEED_BUDGET_MAX], [NOT_NEED_BUDGET_MAX]));
    const finalReportProjectType = order.finalReportProjectType;
    return _.omitBy(
      {
        advertiserId: validateEmpty(order.advertiserId),
        projectName: validateEmpty(order.projectName),
        budget: _.isUndefined(budgetMax) ? validateMinimum(order.budget, budgetMin) : validateMinMax(order.budget, budgetMin, budgetMax),
        orderMargin: this.canEditMargin ? validateMinMax(order.orderMargin, marginMin, marginMax) : undefined,
        orderPrice: this.isOutdoorOrder ? validateEmpty(order.orderPrice) : undefined,
        creativeDuration: this.isOutdoorOrder ? validateEmpty(order.creativeDuration) : undefined,
        finalReportProjectName: finalReportProjectType === FinalReportProjectType.OTHERS ? undefined : validateFinalReportProjectName(order.finalReportProjectName),
        finalReportProjectType: validateEmpty(finalReportProjectType),
        finalReportAdvertiserName: validateEmpty(order.finalReportAdvertiserName),
        finalReportTargetImpressions: validateEmpty(order.finalReportTargetImpressions)
      }, _.isEmpty);
  }

  get contentComponent () {
    return CreateOrderFormContent;
  }

  get title () {
    return i18n.t('orderForm.labels.createTitle');
  }

  setNeedCheckModifyReason (needCheck) {
    this.needCheckModifyReason = false;
  }

  isFinalReportProjectNameExist (finalReportProjectName) {
    return this.existedProjectNames.includes(finalReportProjectName);
  }

  updateState (loading: boolean, redirectPath?: string) {
    this.loading = loading;
    this.redirectPath = redirectPath;
    this.event.fireEvent(this);
  }
}

export class EditOrderFormModel extends DefaultOrderFormModel {

  orderNumber: string;
  originOrder?: Order = undefined;

  constructor (canEditMargin: boolean,
    hasOrderBudgetMaximun: boolean,
    localeMeta: LocaleMeta,
    advertisers: Array<SelectOptions>,
    orderNumber: string,
    addonFeature: AddonFeatureManager,
    manager: OrderManager = new DefaultOrderManager()) {
    super(canEditMargin, hasOrderBudgetMaximun, manager, localeMeta, advertisers, addonFeature);
    this.orderNumber = orderNumber;
  }

  // async getOrderExternalTypeList (): Promise<void> {
  //   try {
  //     const allOrderExternalType = await this.manager.getOrderExternalTypes();
  //     this.orderExternalTypes = allOrderExternalType.filter(orderExternalTypes => orderExternalTypes.active !== false || orderExternalTypes.value === this.order.externalType);
  //   } catch (e) {}
  // }

  get breadcrumbs () {
    return [
      { path: '/orders', breadcrumb: i18n.t('orderDetail.labels.title') },
      {
        path: '/orders/:orderNumber',
        breadcrumb: DynamicBreadcrumb,
        props: {
          label: _.get(this.order, 'projectName'),
          matchParam: 'orderNumber'
        }
      },
      {
        path: '/orders/:orderNumber/edit',
        breadcrumb: DynamicBreadcrumb,
        props: {
          prefix: i18n.t('common.labels.edit'),
          label: _.get(this.order, 'projectName'),
          matchParam: 'orderNumber'
        }
      }
    ];
  }

  get budgetTip (): any {
    const hasCreditLimit = this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.COMPANY.AGENCY_CREDIT_LIMIT);
    if (hasCreditLimit && this.formikProps) {
      const credit = this.formikProps.values.state !== State.NOT_APPROVE ?
        this.localeMeta.credit + this.order.budget :
        this.localeMeta.credit;
      const hasBudetError = !!getFieldErrors(this.formikProps, 'budget');
      if (!hasBudetError && credit < this.formikProps.values.budget) {
        return renderCreditHint(credit);
      }
    }
    return '';
  }

  get contentComponent () {
    return EditOrderFormContent;
  }

  get title () {
    return i18n.t('orderForm.labels.editTitle');
  }

  setNeedCheckModifyReason (needCheck) {
    this.needCheckModifyReason = needCheck;
    this.updateState(false);
  }

  async init (): Promise<void> {
    this.updateState(true);
    try {
      this.existedProjectNames = await this.manager.getExistedProjectNames();
      const order = await this.manager.getOrder(this.orderNumber);
      this.originOrder = { ...order };
      const advertiserOption = this.advertiserList.find(advertiserOption => advertiserOption.value.toString() === order.advertiserId.toString());
      this.order = {
        ...order,
        advertiserName: advertiserOption ? advertiserOption.label : '',
        orderMargin: _.round(order.orderMargin * 100, 2),
        startDate: moment(order.startDate).format('YYYY-MM-DD'),
        endDate: moment(order.endDate).format('YYYY-MM-DD')
      };
      // await this.getOrderExternalTypeList();
    } catch (e) {}
    this.updateState(false);
  }

  async submit () {
    if (!this.formikProps) {
      return;
    }

    const values = this.formikProps.values;
    const orderUpdateData = _.omit({
      ...values,
      finalReportTargetImpressions: values.finalReportTargetImpressions ? +values.finalReportTargetImpressions : 0,
      orderMargin: _.round(values.orderMargin / 100, 4),
      startDate: moment(values.startDate).format('YYYY-MM-DD'),
      endDate: moment(values.endDate).format('YYYY-MM-DD'),
      finalReportSendOutDate: values.finalReportSendOutDate ? moment(values.finalReportSendOutDate).format('YYYY-MM-DD') : undefined,
      finalReportProjectName: values.finalReportProjectType === FinalReportProjectType.OTHERS ? 'Others' : values.finalReportProjectName
    }, ['advertiserName']);
    this.updateState(true);
    try {
      const order = await this.manager.updateOrder(orderUpdateData);
      this.updateState(false, `/orders/${order.orderNumber}`);
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
      this.updateState(false);
    }
  }

  needCheckBudget (order: Partial<Order>) {
    // budget field is a input if edit button is hide
    const hideEditBtn = _.includes([State.NOT_APPROVE, State.REJECT], order.state) || !!order.modifyReason;
    return hideEditBtn || this.state.showBudgetInputField;
  }

  needCheckMargin (order: Partial<Order>) {
    // margin field is a input if edit button is hide
    const hideEditBtn = _.includes([State.NOT_APPROVE, State.REJECT], order.state) || !!order.modifyReason;
    return hideEditBtn || this.state.showMarginInputField;
  }

  validateOrderDateRange = (startDate, endDate) => {
    if (!this.order) {
      return;
    }
    const errors = [];
    if (this.order.campaignMinStartDate && moment(startDate).isAfter(moment(this.order.campaignMinStartDate))) {
      errors.push(i18n.t('orderForm.labels.campaignMinDateError'));
    }
    if (this.order.campaignMaxEndDate && moment(endDate).isBefore(moment(this.order.campaignMaxEndDate))) {
      errors.push(i18n.t('orderForm.labels.campaignMaxDateError'));
    }
    return errors.length > 0 ? renderErrors(errors) : undefined;
  }

  validate = (order: Partial<Order>) => {
    const marginMin = 0;
    const marginMax = this.localeMeta.maxOrderProfit * 100;
    const budgetMin = this.order.budget - this.order.budgetBalance;
    const maxOrderBudget = this.hasOrderBudgetMaximun ? this.localeMeta.maxOrderBudget : undefined;
    const budgetMax = _.min(_.xor([maxOrderBudget, NOT_NEED_BUDGET_MAX], [NOT_NEED_BUDGET_MAX]));
    const needValidateFinalReportFields = !!order.finalReportProjectType;
    let errors = _.omitBy(
      {
        projectName: validateEmpty(order.projectName),
        budget: this.needCheckBudget(order) ?
          _.isUndefined(budgetMax) ? validateMinimum(order.budget, budgetMin) : validateMinMax(order.budget, budgetMin, budgetMax) :
          undefined,
        orderMargin: this.needCheckMargin(order) && this.canEditMargin ? validateMinMax(order.orderMargin, marginMin, marginMax) : undefined,
        modifyReason: this.needCheckModifyReason ? validateEmpty(order.modifyReason) : undefined,
        dateRange: this.validateOrderDateRange(order.startDate, order.endDate),
        orderPrice: this.isOutdoorOrder ? validateEmpty(order.orderPrice) : undefined,
        creativeDuration: this.isOutdoorOrder ? validateEmpty(order.creativeDuration) : undefined
      }, (value) => value === undefined);

    if (needValidateFinalReportFields) {
      const finalReportProjectType = order.finalReportProjectType;
      errors = _.omitBy({
        ...errors,
        finalReportProjectName: finalReportProjectType === FinalReportProjectType.OTHERS ? undefined : validateFinalReportProjectName(order.finalReportProjectName),
        finalReportProjectType: validateEmpty(finalReportProjectType),
        finalReportAdvertiserName: validateEmpty(order.finalReportAdvertiserName),
        finalReportTargetImpressions: validateEmpty(order.finalReportTargetImpressions)
      }, _.isUndefined);
    }
    return errors;
  }

  triggerBudgetInputField () {
    this.showBudgetInputField = !this.showBudgetInputField;
    this.updateState(false);
  }

  triggerMarginInputField () {
    this.showMarginInputField = !this.showMarginInputField;
    this.updateState(false);
  }

  canActorViewOrder (actor) {
    if (!this.order) {
      return false;
    }

    return !(actor &&
      this.order.agencyId !== actor.agencyId);
  }

  isFinalReportProjectNameExist (finalReportProjectName) {
    if (this.originOrder && this.originOrder.finalReportProjectName === finalReportProjectName) {
      return false;
    }
    return this.existedProjectNames.includes(finalReportProjectName);
  }

  updateState (loading: boolean, redirectPath?: string) {
    this.loading = loading;
    this.redirectPath = redirectPath;
    this.event.fireEvent(this);
  }
}

function validateFinalReportProjectName (value) {
  let error = validateEmpty(value);
  if (error) {
    return error;
  }

  const validPorjectNameRegExp = /((^[a-z0-9]|[A-Z0-9])[a-z0-9]+)+$/g;
  const matches = value.match(validPorjectNameRegExp);
  if (!matches || matches[0].length !== value.length) {
    return i18n.t('orderForm.errors.projectNamePatternError');
  }
}

import _ from 'lodash';
import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import { hasFuncs } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';
import { AccountManager, DefaultAccountManager, AuthenticationManager } from 'core';

export interface ChangePasswordModel {
  readonly password: string;
  readonly confirmPassword: string;
  readonly state: ChangePasswordState;
  readonly event: UpdateEventListener<ChangePasswordModel>;
  readonly canSubmit: boolean;
  readonly errors: any;

  onChangeCurrentPassword (currentPassword: string);
  onChangePassword (password: string);
  onChangeConfirmPassword (confirmPassword: string);
  validateChangePasswrod ();
  submit ();
}

export type ChangePasswordProps = {
  readonly model: ChangePasswordModel;
};

export type ChangePasswordState = {
  readonly loading: boolean;
  readonly currentPassword: string;
  readonly password: string;
  readonly confirmPassword: string;
  readonly isSubmitted: boolean;
};

export class DefaultChangePasswordModel implements ChangePasswordModel {
  event: FireableUpdateEventListener<ChangePasswordModel>;
  loading: boolean;
  modelCurrentPassword: string;
  modelPassword: string;
  modelConfirmPassword: string;
  manager: AccountManager;
  modelErrors: any;
  authenticationManager: AuthenticationManager;
  modelIsSubmitted: boolean = false;

  constructor (authenticationManager: AuthenticationManager, manager = new DefaultAccountManager()) {
    this.event = new FireableUpdateEventListener<ChangePasswordModel>();
    this.loading = false;
    this.modelCurrentPassword = '';
    this.modelPassword = '';
    this.modelConfirmPassword = '';
    this.manager = manager;
    this.modelErrors = {};
    this.authenticationManager = authenticationManager;
  }

  get currentPassword (): string {
    return this.modelCurrentPassword;
  }

  get password (): string {
    return this.modelPassword;
  }

  get confirmPassword (): string {
    return this.modelConfirmPassword;
  }

  onChangeCurrentPassword = (currentPassword: string) => {
    this.modelCurrentPassword = currentPassword;
    this.event.fireEvent(this);
  }

  onChangePassword = (password: string) => {
    this.modelPassword = password;
    this.event.fireEvent(this);
  }

  onChangeConfirmPassword = (confirmPassword: string) => {
    this.modelConfirmPassword = confirmPassword;
    this.event.fireEvent(this);
  }

  validateChangePasswrod = () => {
    const errors: any = {};

    const isAdmin = hasFuncs(Permission.ACCOUNT_SUDO).visible({ actor: this.authenticationManager.actor });
    if (_.size(this.modelCurrentPassword) === 0 && !isAdmin) {
      errors.currentPassword = 'accounts.form.messages.currentPasswordRequired';
    }
    if (_.size(this.modelPassword) === 0) {
      errors.password = 'accounts.form.messages.passwordRequired';
    }
    if (this.modelPassword !== this.modelConfirmPassword) {
      errors.confirmPassword = 'accounts.form.messages.passwordNotMatched';
    }
    this.modelErrors = errors;
    this.event.fireEvent(this);
  }

  get errors (): any {
    return this.modelErrors;
  }

  get canSubmit (): boolean {
    return _.size(this.modelErrors) === 0;
  }

  submit = async () => {
    this.validateChangePasswrod();
    if (!this.canSubmit || _.size(this.modelPassword) === 0) {
      return false;
    }
    this.updateState(true);
    try {
      await this.manager.changePassword(this.modelCurrentPassword, this.modelPassword);
      this.modelIsSubmitted = true;
      this.updateState(false);
      return true;
    } catch (e) {
      this.updateState(false);
      return false;
    }
  }

  get state (): ChangePasswordState {
    return {
      loading: this.loading,
      currentPassword: this.modelCurrentPassword,
      password: this.modelPassword,
      confirmPassword: this.modelConfirmPassword,
      isSubmitted: this.modelIsSubmitted
    };
  }

  updateState (loading: boolean) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}

import { AuthenticationManager } from 'core/auth/AuthenticationManager';
import { get } from 'lodash';
import { FbUser } from './fbUser';

export interface FbManager {

  fbLogin (scope: string[]): Promise<FbUser | undefined>;
  getAdsManagementPermission (): Promise<{
    fbUserValid: boolean,
    permissionGranted: boolean
  }>;
  checkAdsManagementPermission (): Promise<{
    fbUserValid: boolean,
    permissionGranted: boolean
  }>;
}

export class DefaultFbManager implements FbManager {

  constructor (private authenticationManager: AuthenticationManager) {}

  async fbLogin (scope: string[]) {
    return new Promise<FbUser | undefined>(resolve => {
      FB.login((response) => {
        if (!response.authResponse || response.status === 'unknown') {
          resolve(undefined);
          return;
        }
        const grantedScopes: string = response.authResponse.grantedScopes ? response.authResponse.grantedScopes : '';
        const scopes = grantedScopes.split(',');
        if (!scopes.includes('email')) {
          resolve(undefined);
          return;
        }
        if (response.status === 'connected' && response.authResponse) {
          FB.api('/me', { fields: 'name,email' }, function (userInfo: any) {
            resolve({
              ...response.authResponse,
              name: userInfo.name,
              email: userInfo.email
            });
          });
        } else {
          resolve(undefined);
        }
      }, {
        scope: scope.join(','),
        return_scopes: true,
        auth_type: 'rerequest'
      });
    });
  }

  async getAdsManagementPermission () {
    return new Promise<{
      fbUserValid: boolean,
      permissionGranted: boolean
    }>(async resolve => {
      const userMatched = await this.checkFbUserEmail();
      if (!userMatched) {
        resolve({
          fbUserValid: false,
          permissionGranted: false
        });
      } else {
        FB.login((response) => {
          if (response.status === 'connected' && response.authResponse) {
            this.authenticationManager.validateFbAdsManagement(response.authResponse.accessToken).then(permissions => {
              resolve({
                fbUserValid: true,
                permissionGranted: permissions.includes('ads_management')
              });
            }, () => {
              resolve({
                fbUserValid: true,
                permissionGranted: false
              });
            });
          } else {
            resolve({
              fbUserValid: true,
              permissionGranted: false
            });
          }
        }, {
          scope: 'ads_management',
          auth_type: 'rerequest'
        });
      }
    });
  }

  async checkAdsManagementPermission (): Promise<{
    fbUserValid: boolean,
    permissionGranted: boolean
  }> {
    const userMatched = await this.checkFbUserEmail();
    if (!userMatched) {
      return {
        fbUserValid: false,
        permissionGranted: false
      };
    } else {
      const fbLoginStatus = await this.getFbLoginStatus();
      if (fbLoginStatus.status !== 'connected' || !fbLoginStatus.authResponse) {
        return {
          fbUserValid: true,
          permissionGranted: false
        };
      }
      const permissions = await this.authenticationManager.validateFbAdsManagement(fbLoginStatus.authResponse.accessToken);
      return {
        fbUserValid: true,
        permissionGranted: permissions.includes('ads_management')
      };
    }
  }

  async getFbLoginStatus (): Promise<fb.StatusResponse> {
    return new Promise(resolve => {
      FB.getLoginStatus((response) => {
        resolve(response);
      });
    });
  }

  async checkFbUserEmail (): Promise<boolean> {
    const email = this.authenticationManager.account?.email;
    return new Promise(resolve => {
      FB.api('/me', { fields: 'name,email' }, function (userInfo: any) {
        // ignore need login case
        const needRelogin = userInfo.error && get(userInfo, 'error.code') === 2500;
        resolve(needRelogin || (!userInfo.error && userInfo.email === email));
      });
    });
  }
}

import React from 'react';
import { Redirect } from 'react-router-dom';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import DefaultCampaignGroupManager, { CampaignGroupManager } from 'core/campaignGroup/CampaignGroupManager';

const defaultCampaignGroupManager: CampaignGroupManager = new DefaultCampaignGroupManager();
export class CampaignRedirect extends React.PureComponent<{
  groupId: string | number,
  campaignId: string | number,
  action?: string,
  campaignGroupManager?: CampaignGroupManager
}, {redirectPath: string | undefined}> {

  constructor (props) {
    super(props);
    this.state = {
      redirectPath: undefined
    };
  }

  componentDidMount () {
    this.getParentInfo();
  }

  async getParentInfo () {
    const campaignGroupManager = this.props.campaignGroupManager ? this.props.campaignGroupManager : defaultCampaignGroupManager;
    try {
      const orderNumber = await campaignGroupManager.getOrderNumber(this.props.groupId);
      const redirectPath = this.props.action ?
        `/orders/${orderNumber}/campaign-groups/${this.props.groupId}/campaigns/${this.props.campaignId}/${this.props.action}` :
        `/orders/${orderNumber}/campaign-groups/${this.props.groupId}?search=${this.props.campaignId}`;
      this.setState({
        redirectPath
      });
    } catch (e) {
      this.setState({
        redirectPath: '/'
      });
    }
  }

  render () {
    if (this.state.redirectPath) {
      return <Redirect to={this.state.redirectPath} />;
    }
    return (
      <LoadingIndicator />
    );
  }
}

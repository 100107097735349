import { PMaxCore, AuthenticationManager, Account, Actor, PermissionContext, RoleNames } from 'core';
import { NetworkState } from 'components/NetworkState';
import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import { AppHeaderModel, DefaultAppHeaderModel } from 'components/AppHeader';
import { DefaultAppMenuBarModel, AppMenuBarModel } from 'components/AppMenuBar';
import defaultMenus from './defaultMenus';
import { OrdersModel, DefaultOrdersModel } from 'containers/Orders/OrdersModel';
import { CreativesModel, DefaultCreativesModel } from 'containers/Creatives/CreativesModel';
import { SessionStorageHelper, SessionStorageItemKeys } from 'helper/StorageHelper';
import { AdvertisersModel, DefaultAdvertisersModel } from 'containers/Advertisers/AdvertisersModel';
import { ConversionsModel, DefaultConversionsModel } from 'containers/Conversions/ConversionsModel';
import { DefaultAgenciesModel, AgenciesModel } from 'containers/Agencies/AgenciesModel';
import { AccountsModel, DefaultAccountsModel } from 'containers/Accounts/AccountsModel';
import { FbManager, DefaultFbManager } from 'core/fb/FbManager';
import { hasRoles } from 'core/permission/PermissionDSL';
import { OEMAccountsModel } from 'containers/Accounts/OEMAccountsModel';
import config from 'config';
import i18n from 'i18n';
import { DefaultRetailMaxModel, RetailMaxModel } from 'containers/RetailMax/RetailMaxModel';

type AlertData = {
  message: string,
  callback?: () => void
};

export type MainPageState = NetworkState & {
  permissionContext: PermissionContext,
  alertData?: AlertData;
};
export interface MainPageModel {
  readonly core: PMaxCore;
  readonly state: MainPageState;
  readonly event: UpdateEventListener<MainPageModel>;

  readonly account: Account | null;
  readonly barModel: AppMenuBarModel;
  readonly headerModel: AppHeaderModel;
  readonly ordersModel: OrdersModel;
  readonly creativesModel: CreativesModel;
  readonly advertisersModel: AdvertisersModel;
  readonly conversionsModel: ConversionsModel;
  readonly agenciesModel: AgenciesModel;
  readonly accountsModel: AccountsModel;
  readonly oemAccountsModel: AccountsModel;
  readonly retailMaxModel: RetailMaxModel;
  readonly mainPagePath: string;
  readonly hasFbAdsManagements: boolean;

  init (): Promise<void>;
  switchActor (actor: Actor): Promise<void>;
  setAlertData (data?: AlertData): void;
  getFbAdsManagement (): Promise<boolean>;
}

export interface MainPageProps {
  readonly model: MainPageModel;
  readonly authenticationManager: AuthenticationManager;
}

export class DefaultMainPageModel implements MainPageModel {
  pmaxCore: PMaxCore;
  modelEvent: FireableUpdateEventListener<MainPageModel>;

  error: Error | null;
  loading: boolean;
  menuBarModel: AppMenuBarModel;
  appHeaderModel: AppHeaderModel;
  errorCode?: number;
  ordersModel: OrdersModel;
  creativesModel: CreativesModel;
  advertisersModel: AdvertisersModel;
  conversionsModel: ConversionsModel;
  permissionContext: PermissionContext;
  agenciesModel: AgenciesModel;
  accountsModel: AccountsModel;
  oemAccountsModel: AccountsModel;
  retailMaxModel: RetailMaxModel;
  mainPagePathMap: any;
  hasFbAdsManagements: boolean;
  alertData?: AlertData;
  fbManager: FbManager;

  constructor (core: PMaxCore) {
    this.pmaxCore = core;
    this.error = null;
    this.loading = true;
    this.modelEvent = new FireableUpdateEventListener<MainPageModel>();
    this.fbManager = new DefaultFbManager(this.pmaxCore.authenticationManager);
    this.menuBarModel = new DefaultAppMenuBarModel(defaultMenus());
    this.ordersModel = new DefaultOrdersModel(this.pmaxCore);
    this.creativesModel = new DefaultCreativesModel(this.pmaxCore);
    this.advertisersModel = new DefaultAdvertisersModel(this.pmaxCore);
    this.conversionsModel = new DefaultConversionsModel(this.pmaxCore);
    this.agenciesModel = new DefaultAgenciesModel(this.pmaxCore);
    this.accountsModel = new DefaultAccountsModel(this.pmaxCore);
    this.oemAccountsModel = new OEMAccountsModel(this.pmaxCore);
    this.retailMaxModel = new DefaultRetailMaxModel(this.pmaxCore);
    this.appHeaderModel = new DefaultAppHeaderModel(
      this.pmaxCore.authenticationManager,
      this.pmaxCore.languageManager,
      this.menuBarModel,
      this
    );
    this.permissionContext = {
      actor: core.authenticationManager.actor,
      agencyAddon: core.addonFeatureManager.addonFeature
    };
    this.menuBarModel.updatePermissionContext(
      core.authenticationManager.actor,
      core.addonFeatureManager.addonFeature
    );
    this.hasFbAdsManagements = false;

    this.core.authenticationManager.event.add(manager => {
      this.permissionContext = {
        actor: manager.actor,
        agencyAddon: this.core.addonFeatureManager.addonFeature
      };
      this.refreshAllModel();
      this.menuBarModel.updatePermissionContext(manager.actor, this.core.addonFeatureManager.addonFeature);
    });

    this.core.addonFeatureManager.event.add(manager => {
      this.permissionContext = {
        actor: this.core.authenticationManager.actor,
        agencyAddon: manager.addonFeature
      };
      this.refreshAllModel();
      this.menuBarModel.updatePermissionContext(this.core.authenticationManager.actor, manager.addonFeature);
    });

    this.menuBarModel.expandChangeEvent.add(() => {
      this.updateState(false);
    });
    this.mainPagePathMap = {};
    const reportMainPageRole = [RoleNames.sysAdmin, RoleNames.adsReport];
    Object.values(RoleNames).forEach(roleName => {
      reportMainPageRole.includes(roleName) ?
        this.mainPagePathMap[roleName] = '/reports/performance' :
        this.mainPagePathMap[roleName] = '/orders';
    });
    this.mainPagePathMap[RoleNames.goJekAccountManager] = '/agencies';
    this.mainPagePathMap[RoleNames.fbAgencyManager] = this.hasFbAdsManagements ? '/orders' : '/onboarding';
    this.mainPagePathMap[RoleNames.guest] = '/contact-us';
  }

  get mainPagePath () {
    if (config.mainPagePath) {
      return config.mainPagePath;
    }
    if (this.core.authenticationManager.actor) {
      return this.mainPagePathMap[this.core.authenticationManager.actor.roleName.toUpperCase()];
    }
    return '/orders';
  }

  get core (): PMaxCore {
    return this.pmaxCore;
  }

  get event (): UpdateEventListener<MainPageModel> {
    return this.modelEvent;
  }

  get state (): MainPageState {
    return {
      error: this.error,
      loading: this.loading,
      errorCode: this.errorCode,
      permissionContext: this.permissionContext,
      alertData: this.alertData
    };
  }

  get account (): Account | null {
    return this.core.authenticationManager.account;
  }

  get barModel (): AppMenuBarModel {
    return this.menuBarModel;
  }

  get headerModel (): AppHeaderModel {
    return this.appHeaderModel;
  }

  refreshAllModel () {
    this.ordersModel = new DefaultOrdersModel(this.pmaxCore);
    this.creativesModel = new DefaultCreativesModel(this.pmaxCore);
    this.advertisersModel = new DefaultAdvertisersModel(this.pmaxCore);
    this.conversionsModel = new DefaultConversionsModel(this.pmaxCore);
    this.agenciesModel = new DefaultAgenciesModel(this.pmaxCore);
    this.accountsModel = new DefaultAccountsModel(this.pmaxCore);
    this.oemAccountsModel = new OEMAccountsModel(this.pmaxCore);
    this.retailMaxModel = new DefaultRetailMaxModel(this.pmaxCore);
  }

  async init () {
    if (this.core.authenticationManager.logined === true) {
      this.updateState(true);
      try {
        await this.core.authenticationManager.fetchAccount();
        await this.core.accountManager.updateLocaleMeta(this.core.authenticationManager.actor);
        this.core.addonFeatureManager.updateAddonFeature(
          this.core.accountManager.localeMeta ? this.core.accountManager.localeMeta.addonFeatures : {}
        );
        await this.checkFbAdsManagement();
        this.updateState(false);
      } catch (error) {
        this.updateState(false, error as Error);
      }
    }
  }

  async switchActor (actor: Actor) {
    this.updateState(true);
    this.errorCode = undefined;
    SessionStorageHelper.removeItem(SessionStorageItemKeys.ADVERTISER);
    SessionStorageHelper.removeItem(SessionStorageItemKeys.AGENCY);
    try {
      this.core.authenticationManager.switchActor(actor.id);
      await this.core.accountManager.updateLocaleMeta(actor);
      this.core.addonFeatureManager.updateAddonFeature(
        this.core.accountManager.localeMeta ? this.core.accountManager.localeMeta.addonFeatures : {}
      );
      if (actor &&
        hasRoles(RoleNames.adsAdmin, RoleNames.adsSales, RoleNames.adsReport).visible({ actor }) &&
        actor.advertiserId
      ) {
        SessionStorageHelper.setItem(SessionStorageItemKeys.ADVERTISER, actor.advertiserId);
      }
      await this.checkFbAdsManagement();
      this.updateState(false);
    } catch (error) {
      this.updateState(false, error as Error);
    }
  }

  async checkFbAdsManagement () {
    return this.handleFbAdsManagementPermission(this.fbManager.checkAdsManagementPermission.bind(this.fbManager));
  }

  async getFbAdsManagement () {
    return this.handleFbAdsManagementPermission(this.fbManager.getAdsManagementPermission.bind(this.fbManager));
  }

  async handleFbAdsManagementPermission (fbMethod: Function) {
    this.updateState(true);
    let needRedirect = true;
    try {
      const actor = this.core.authenticationManager.actor;
      if (actor &&
        hasRoles(RoleNames.fbAgencyManager).visible({ actor })
      ) {
        const permissions = await fbMethod();
        const { fbUserValid, permissionGranted } = permissions;
        if (!fbUserValid) {
          this.setAlertData({
            message: i18n.t('fbOnboardingPage.errors.missingPermission', { email: this.account?.email }),
            callback: () => {
              this.setAlertData(undefined);
              this.core.authenticationManager.logout();
            }
          });
          needRedirect = false;
        } else if (permissionGranted) {
          this.mainPagePathMap[RoleNames.fbAgencyManager] = '/orders';
          this.hasFbAdsManagements = true;
        } else {
          this.mainPagePathMap[RoleNames.fbAgencyManager] = '/onboarding';
          this.hasFbAdsManagements = false;
        }
      }
      this.updateState(false);
    } catch (error) {
      this.updateState(false, error as Error);
    }
    return needRedirect;
  }

  setAlertData (data?: AlertData): void {
    this.alertData = data;
    this.modelEvent.fireEvent(this);
  }

  updateState (loading: boolean, error: Error | null = null) {
    this.error = error;
    this.loading = loading;
    this.modelEvent.fireEvent(this);
  }
}

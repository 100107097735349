import { AdLogo } from 'core/adLogo/AdLogo';
import { Currency } from 'core/agency/Agency';
import { AdType } from 'core/rtbCampaign/RtbCampaign';

export type Order = {
  id: number;
  projectName: string;
  orderNumber: string;
  agencyId: number;
  advertiserId: number;
  budget: number;
  originBudget?: number;
  changeBudget?: number;
  orderMargin: number;
  sysMargin: number;
  changeOrderMargin?: number;
  externalId?: string;
  externalType?: string;
  state: State;
  spent: number;
  creator?: string;
  creatorEmail?: string;
  expectedSpent: number,
  actualSpent: number,
  comments?: string,
  startDate: string;
  endDate: string;
  createDate: string;
  createDateTime?: string;
  campaignConstraint: CampaignConstraint,
  budgetBalance: number;
  currency: Currency;
  timezone: string;
  impres: number;
  viewable: number;
  clicks: number;
  deepWarning?: boolean;
  warning?: boolean;
  danger?: boolean;
  modifyReason?: string;
  campaignRunningState?: any;
  needAlert?: boolean;
  adLogo: AdLogo;
  bidPriceMin: Record<AdType, BidPriceMin>;
  campaignMinStartDate?: string;
  campaignMaxEndDate?: string;
  monitor?: boolean,
  orderPrice: number,
  creativeDuration: number,
  dayPart: any,
  finalReportProjectName?: string,
  finalReportAdvertiserName?: string,
  finalReportProjectType?: FinalReportProjectType,
  finalReportReceivers?: string[],
  finalReportSendOutDate?: string,
  finalReportTargetImpressions?: number
};

export enum FinalReportProjectType {
  GOFOOD = 'GoFood',
  GOMART = 'GoMart',
  GOMED = 'GoMed',
  GOSURE = 'GoSure',
  GOINVESTASI = 'GoInvestasi',
  OTHERS = 'Others'
}

export enum State {
  NOT_APPROVE = 1,
  REJECT = 2,
  APPROVE_NOT_START = 3,
  APPROVE_START = 4,
  APPROVE = 5,
  STOP = 6,
  DELETE = 7,
  INVALID = 8,
  CHANGE_PENDING = 9,
  SETTLE = 10,
  SETTLED = 11
}

export type CampaignConstraint = {
  bidPriceCPCMin: number | null,
  bidPriceCPVMin: number | null,
  bidPriceCPMMin: number | null,
  bidPricevCPMMin: number | null,
  budgetMinimum: number
};

export type BidPriceMin = {
  cpc?: number,
  cpv?: number,
  cpm?: number,
  vcpm?: number
};

export enum OrderDelivery {
  NOTSTART = 'notStart',
  PROGRESS = 'progress',
  FINISHED = 'finished'
}

export enum OrderCreationInfo {
  CREATOR = 'creator',
  CREATEDATETIME = 'createDateTime',
  CREATOREMAIL = 'creatorEmail'
}

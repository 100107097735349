import React, { Fragment } from 'react';
import { AdvertiserFormState, AdvertiserFormProps, AdvertiserFormData } from './AdvertiserFormModel';
import { Formik, FormikProps, Field } from 'formik';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { Form } from 'react-bootstrap';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import FormGroup from 'components/common/FormGroup/FormGroup';
import i18n from 'i18next';
import styles from './advertiserForm.module.scss';
import _ from 'lodash';
import { FormikFormGroup, FormikSelect } from 'components/common';
import NavigationPrompt from 'components/common/NavigationPrompt/NavigationPrompt';
import { FormikField } from 'components/common/formik/FormikField';
import { FBPageOption } from 'components/FBPageSelect/FBPageOption';
import { FBPageSelectMultiValueLabel } from 'components/FBPageSelect/FBPageSelectMultipleValueLabel';
import { AdAccountOption } from 'components/AdAccountSelect/AdAccountOption';
import { AdAccountSingleValue } from 'components/AdAccountSelect/AdAccountSingleValue';
import { TitleWithBreadcrumbs } from 'components/TitleArea/TitleWithBreadcrumbs';

export class AdvertiserForm extends React.PureComponent<RouteComponentProps<any> & AdvertiserFormProps, AdvertiserFormState> {

  handler: any;

  componentDidMount () {
    this.handler = this.props.model.event.add(model => {
      this.setState(model.state);
    });
    this.props.model.init();
  }

  componentWillUnmount () {
    this.props.model.onUnmount(this.handler);
  }

  handleSubmit = () => {
    const errors = this.props.model.validateAndSetError();
    if (!_.isEmpty(errors)) {
      return;
    }
    this.props.model.submit();
  }

  cancelSubmit = () => {
    this.props.history.goBack();
  }

  validate = (advertiser: AdvertiserFormData): any => {
    return this.props.model.validate(advertiser);
  }

  renderForm = (formProps: FormikProps<AdvertiserFormData>) => {
    const model = this.props.model;
    model.setFormikProps(formProps);
    return (
      <Fragment>
        <Form>
          {
            formProps.values.id &&
            <FormikFormGroup
              labelName={i18n.t('advertiserForm.labels.id')}
              inputName='id'
              inputAs={'label'}
            />
          }
          <FormikFormGroup
            labelName={i18n.t('advertiserForm.labels.name')}
            inputName='advertiserName'
          />
          <FormikFormGroup
            labelName={i18n.t('advertiserForm.labels.website')}
            inputName='website'
          />
          <FormGroup label={i18n.t('advertiserForm.labels.category')} controlId='advertiserCategory'>
            <Field
              name='category'
              component={FormikSelect}
              options={model.state.categories}
              className={styles.categories}
              simpleValue
            />
          </FormGroup>
          <FormikField.Select
            label={i18n.t('advertiserFBAdAccount.labels.accountFieldLabel')}
            simpleValue
            name='fbAdAccountId'
            optionComponent={AdAccountOption}
            singleValue={AdAccountSingleValue}
            options={model.fbAdAccountOptions}
          />
          <FormikField.Select
            label={i18n.t('creativeSetupFlow.labels.fbPage')}
            simpleValue
            name='fbPageIds'
            optionComponent={FBPageOption}
            multiValueLabel={FBPageSelectMultiValueLabel}
            fieldContentWidth='auto'
            options={model.fbPageOptions}
            isMulti
          />
          <FormikField.Select
            label={i18n.t('creativeSetupFlow.labels.tiktokAccountField')}
            simpleValue
            name='tiktokAdAccountId'
            optionComponent={AdAccountOption}
            singleValue={AdAccountSingleValue}
            options={model.tiktokAdAccountOptions}
          />
          <FormikFormGroup
            labelName={i18n.t('advertiserForm.labels.note')}
            inputName='note'
            inputAs='textarea'
          />
        </Form>
        <div className={styles.buttonArea}>
          <button
            type='button'
            className='btn btn-primary btn-sm'
            onClick={this.handleSubmit}
          >
            {i18n.t('common.buttons.submit')}
          </button>
          <button
            type='button'
            className='btn btn-secondary btn-sm'
            onClick={this.cancelSubmit}
          >
            {i18n.t('common.buttons.cancel')}
          </button>
        </div>
      </Fragment>
    );
  }

  render () {
    const model = this.props.model;
    const state = model.state;
    if (state.redirectPath) {
      return <Redirect to={state.redirectPath} />;
    }
    if (state.loading || !model.advertiser) {
      return <LoadingIndicator />;
    }
    return (
      <div className={styles.advertiserFormContainer}>
        <NavigationPrompt/>
        <div className={styles.titleArea}>
          <TitleWithBreadcrumbs
            title={model.title}
            routes={model.breadcrumbs}
          />
        </div>
        <div className={styles.titleBottomLine} />
        <Formik
          initialValues={model.advertiser}
          onSubmit={this.handleSubmit}
          validate={this.validate}
          render={this.renderForm}
          validateOnBlur={false}
        />
      </div>
    );
  }
}

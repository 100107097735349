import React from 'react';
import { Route, Switch } from 'react-router';
import { ProductGroupForm } from './ProductGroupForm/ProductGroupForm';
import { useCreateProductGroupFormModel, useEditProductGroupFormModel } from './ProductGroupForm/ProductGroupFormModel';
import { ProductGroupList } from './ProductGroupList/ProductGroupList';
import _ from 'lodash';

export class ProductGroups extends React.PureComponent<{}> {

  renderCreateForm () {
    return <ProductGroupForm useModel={useCreateProductGroupFormModel} />;
  }

  renderEditForm (props) {
    const productGroupId = props.match.params.productGroupId;
    if (!productGroupId) {
      return <div />;
    }
    return <ProductGroupForm useModel={_.partial(useEditProductGroupFormModel, productGroupId)} />;
  }

  renderProductGroupList () {
    return <ProductGroupList />;
  }

  render () {
    return (
      <Switch>
        <Route
          path={'/system/product-groups/new'}
          render={this.renderCreateForm}
        />
        <Route
          path={'/system/product-groups/:productGroupId/edit'}
          render={this.renderEditForm}
        />
        <Route
          render={this.renderProductGroupList}
        />
      </Switch>
    );
  }
}

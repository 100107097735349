import _ from 'lodash';

import { AbstractLimitationModel } from '../CampaingLimitationForm/AbstractLimitation';
import { SelectOptions } from 'components/common/commonType';
import { DefaultSelectItemComponentModel, ItemSetting } from '../SelectItemComponent';
import { CustomInputComponentModel } from '../CustomInputComponent/CustomInputComponentModel';
import { FireableUpdateEventListener, UpdateEventListener } from 'utils/UpdateEventListener';
import i18n from 'i18n';
export interface SelectComponentModel extends AbstractLimitationModel {
  readonly event: UpdateEventListener<SelectComponentModel>;
  readonly singleSelection: boolean;
  readonly searchPlaceHolder: string;
  readonly customInputModel?: CustomInputComponentModel;
  readonly itemSetting: ItemSetting;
  readonly state: SelectComponentState;
  readonly contentPlaceholderI18nKey: string;
  readonly contentUnitI18nKey: string;
  modelMap: {[key: string]: DefaultSelectItemComponentModel};
  onChange: (selectedOptions: SelectOptions[]) => void;
  getSelectItemModel: (itemData: SelectOptions, listRef: any) => DefaultSelectItemComponentModel;
  getSelectItemModelByValue: (value) => DefaultSelectItemComponentModel;
  onSearch (keyword: string): void;
  setData (data): void;
  updateCachedItemModels (modelMap);
  setState (state: SelectComponentState): void;
  onUnmount (eventHandler): void;
}

export type SelectComponentProps = {
  model: SelectComponentModel;
};

export type SelectComponentState = {
  readonly optionsToShow: SelectOptions[];
  readonly searchString: string;
};

export class DefaultSelectComponentModel implements SelectComponentModel {

  event: FireableUpdateEventListener<SelectComponentModel> =
    new FireableUpdateEventListener<SelectComponentModel>();
  customInputModel?: CustomInputComponentModel;
  modelMap: {[key: string]: DefaultSelectItemComponentModel} = {};
  searchString: string = '';
  optionsToShow: SelectOptions[];

  constructor (
    public name: string,
    public singleSelection: boolean,
    public itemSetting: ItemSetting,
    public searchPlaceHolder: string,
    public onChange: (selectedOptions: SelectOptions[]) => void,
    public data?: SelectOptions[],
    public value?: SelectOptions[],
    customInputModelGetter?: any,
    private disable?: boolean,
    public contentPlaceholderI18nKey: string = i18n.t('limitation.placeholders.categoryTip'),
    public contentUnitI18nKey: string = 'limitation.classificationTotal'
  ) {
    this.optionsToShow = data ? data : [];
    this.customInputModel = customInputModelGetter && customInputModelGetter();
    this.customInputModel && this.customInputModel.event.add(this.customInputModelHandler.bind(this));
  }

  get state () {
    return {
      optionsToShow: this.optionsToShow,
      searchString: this.searchString
    };
  }

  setState (state: SelectComponentState) {
    this.searchString = state.searchString;
    this.optionsToShow = state.optionsToShow;
  }

  getSelectItemModel (itemData, listRef: { current: null | HTMLDivElement }) {
    const cachedModel = this.getSelectItemModelByValue(itemData.value);
    const selectedOptionsOfItem = this.getSelectedOptionsOfItem(itemData);
    const hasValueSelect = this.value && this.value.length > 0;
    const itemShouldDisable = this.disable || (this.singleSelection && hasValueSelect && selectedOptionsOfItem.length === 0);
    if (
      cachedModel &&
      cachedModel.disabled === itemShouldDisable &&
      _.xor(
        cachedModel.selectedOptions?.map(option => option.value),
        selectedOptionsOfItem.map(option => option.value)
      ).length === 0
    ) {
      cachedModel.setData(itemData);
      return cachedModel;
    }
    const model = new DefaultSelectItemComponentModel(
      itemData,
      this.singleSelection,
      this.itemSetting,
      (selectedData) => {
        this.onItemChange(itemData, selectedData);
      },
      listRef,
      itemShouldDisable,
      selectedOptionsOfItem
    );
    this.modelMap[itemData.value] = model;
    return model;
  }

  getSelectItemModelByValue (value) {
    return this.modelMap[value];
  }

  getSelectedOptionsOfItem (itemData) {
    if (!this.value) {
      return [];
    }
    const itemOptions = itemData.options ? _.concat(itemData, itemData.options) : [itemData];
    return this.value.filter((selectedOption) => _.findIndex(itemOptions, (itemOption) => selectedOption.value === itemOption.value) >= 0);
  }

  setData (data) {
    this.data = data;
  }

  updateCachedItemModels (modelMap) {
    this.modelMap = modelMap;
    Object.values(this.modelMap).forEach(cachedModel => {
      const selectedOptionsOfItem = this.getSelectedOptionsOfItem(cachedModel.data);
      const hasValueSelect = this.value && this.value.length > 0;
      const itemShouldDisable = this.singleSelection && hasValueSelect && selectedOptionsOfItem.length === 0;
      cachedModel.updateProps(itemShouldDisable, selectedOptionsOfItem, (selectedData) => {
        this.onItemChange(cachedModel.data, selectedData);
      });
    });
    this.updateState();
  }

  removeSelectedSubOptionsOfItemData (selectedArray: SelectOptions[], itemData: SelectOptions) {
    _.remove(selectedArray, (selectedOption) => {
      return _.findIndex(itemData.options, (option: SelectOptions) => selectedOption.value === option.value) >= 0;
    });
  }

  onItemChange (itemData, selectedData) {
    const result = !this.value ? [selectedData] : this.value.concat(selectedData);
    const selectedItemIsCategoryItem = selectedData.value === itemData.value;
    selectedItemIsCategoryItem && this.removeSelectedSubOptionsOfItemData(result, itemData);
    this.value = [...result];
    this.onChange(result);
    this.updateState();
  }

  getTranslateLabel (value) {
    return i18n.t(`${this.itemSetting.i18nPrefix}.${value.toString().toLowerCase().replace(/-|\s/g, '_')}`);
  }

  onSearch = (keyword: string): void => {
    this.searchString = keyword;
    if (keyword === '') {
      this.optionsToShow = this.data ? this.data : [];
      Object.values(this.modelMap).forEach(model => {
        model.setOpen(false);
      });
      this.updateState();
      return;
    }
    const keywordIgnoreCase = keyword.toLowerCase();
    this.optionsToShow = [];
    this.data && this.data.forEach((data) => {
      const dataLabel = this.itemSetting.i18nPrefix ?
        this.getTranslateLabel(data.value) :
        data.label;
      const cachedModel = this.getSelectItemModelByValue(data.value);
      if (dataLabel.toLowerCase().includes(keywordIgnoreCase)) {
        cachedModel && cachedModel.setOpen(true);
        this.optionsToShow.push({ ...data });
        return;
      }
      if (data.options) {
        const subOptionsToShow = data.options.filter((option) => {
          const optionLabel = this.itemSetting.i18nPrefix ?
          this.getTranslateLabel(option.value) :
            option.label;
          return optionLabel.toLowerCase().includes(keywordIgnoreCase);
        });
        if (subOptionsToShow.length > 0) {
          this.optionsToShow.push({ ...data, options: subOptionsToShow });
          cachedModel && cachedModel.setOpen(true);
          return;
        }
      }
      cachedModel && cachedModel.setOpen(false);
    });
    this.updateState();
  }

  customInputModelHandler (model) {
    let result = this.value ? this.value.concat(model.customSelectOptions) : model.customSelectOptions;
    result = _.uniqBy(result, 'value');
    this.value = [...result];
    this.onChange(result);
    this.updateState();
  }

  onUnmount (eventHandler) {
    eventHandler && this.event.remove(eventHandler);
    this.modelMap = {};
  }

  updateState () {
    this.event.fireEvent(this);
  }
}

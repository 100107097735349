import { Modal } from 'components/common/Modal/Modal';
import React from 'react';
import i18n from 'i18n';
import { useCreditPayModalModel } from './CreditPayModalModel';
import FormGroup from 'components/common/FormGroup/FormGroup';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import styles from './creditPayModal.module.scss';
import { Form } from 'react-bootstrap';
import Select from 'components/common/Select/Select';
import { TablePlaceholder } from 'components/TablePlaceholder/TablePlaceholder';
import _ from 'lodash';
import { formatPriceWithCurrency } from 'helper/CurrencyHelper';
import moment from 'moment';

export const CreditPayModal: React.FC<{
  agencyId: number,
  onClose: (refresh: boolean) => void,
  bills?: any[]
}> = ({ agencyId, onClose, bills }) => {

  const {
    loading,
    billOptions,
    selectBill,
    showConfirm,
    onSelectBill,
    setShowConfirm,
    payCredit
  } = useCreditPayModalModel(agencyId, onClose, bills);

  const close = _.partial(onClose, false);

  return (
    <>
      {loading && <LoadingIndicator />}
      {selectBill ? (
        <>
          <Modal
            fullScreen
            animation={false}
            title={i18n.t('creditList.labels.pay')}
            dismiss={close}
            className={styles.creditPayModal}
            primaryButton={{ title: i18n.t('creditList.labels.pay'), callback: _.partial(setShowConfirm, true) }}
            secondaryButton={{ title: i18n.t('common.buttons.cancel'), callback: close }}
            enforceFocus={false}
          >
            <div className={styles.payContent}>
              <FormGroup controlId='comName' label={i18n.t('creditPayModal.labels.agency')}>
                <Form.Label>{selectBill.comName}</Form.Label>
              </FormGroup>
              <FormGroup controlId='currency' label={i18n.t('creditPayModal.labels.currency')}>
                <Form.Label>{selectBill.currency}</Form.Label>
              </FormGroup>
              <FormGroup controlId='period' label={i18n.t('creditPayModal.labels.period')}>
                <Select
                  simpleValue
                  maxMenuHeight={150}
                  options={billOptions}
                  value={selectBill.id}
                  onChange={onSelectBill}
                />
              </FormGroup>
              <FormGroup controlId='payment' label={i18n.t('creditPayModal.labels.payment')}>
                <Form.Label>{formatPriceWithCurrency(selectBill.currency, selectBill.payment)}</Form.Label>
              </FormGroup>
              <FormGroup controlId='credit' label={i18n.t('creditPayModal.labels.credit')}>
                <Form.Label>{formatPriceWithCurrency(selectBill.currency, selectBill.credit)}</Form.Label>
              </FormGroup>
            </div>
          </Modal>
          {showConfirm &&
            <Modal
              title={i18n.t('creditList.labels.pay')}
              primaryButton={{ title: i18n.t('common.buttons.confirm'), callback: payCredit }}
              secondaryButton={{ title: i18n.t('common.buttons.cancel'), callback: _.partial(setShowConfirm, false) }}
              dismiss={_.partial(setShowConfirm, false)}
            >
              {i18n.t('creditList.labels.payConfirm', { name: selectBill.comName, date: moment(selectBill.dateId).format('MMMM YYYY') })}
            </Modal>
          }
        </>
      ) : (
        <Modal
          fullScreen
          animation={false}
          title={i18n.t('creditList.labels.pay')}
          secondaryButton={{ title: i18n.t('common.buttons.cancel'), callback: close }}
          dismiss={close}
        >
          <div className={styles.emptyContent}>
            <TablePlaceholder placeholder={i18n.t('creditPayModal.labels.noData')} />
          </div>
        </Modal>
      )}
    </>
  );
};

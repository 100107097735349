import _ from 'lodash';
import client from './RestClient';
import { ProductFormValue, ProductGroup } from 'core/limitation/ProductGroup';

export interface ProductGroupWebService {
  createProductGroup (productGroup: ProductFormValue): Promise<void>;
  updateProductGroup (productGroup: ProductFormValue): Promise<void>;
  getProductGroup (): Promise<ProductGroup[]>;
  getProductGroupById (groupId: number): Promise<ProductGroup>;
  getCampaignByGroupId (groupId: number);
  deleteProductGroupById (groupId: number): Promise<void>;
}

export class RestfulProductGroupWebService implements ProductGroupWebService {
  restClient: any;

  constructor (restClient: any = client) {
    this.restClient = restClient;
  }
  async createProductGroup (productGroup: ProductFormValue): Promise<void> {
    return client.post('/limitationGroups', {
      ...productGroup,
      state: 1
    });
  }
  async updateProductGroup (productGroup: ProductFormValue): Promise<void> {
    return client.put('/limitationGroups', {
      ...productGroup,
      state: 1
    });
  }
  async getProductGroup (): Promise<ProductGroup[]> {
    const response = await client.get('/v2/limitationGroups/manage');
    return this.wrapProductGroupList(response.data.records, this.wrapProductGroup);
  }
  async getProductGroupById (groupId: number): Promise<ProductGroup> {
    const response = await client.get(`/limitationGroups/${groupId}`);
    return this.wrapProductGroup(response.data);
  }
  async getCampaignByGroupId (groupId: number) {
    const response = await client.get(`/v2/limitationGroups/${groupId}/manage`);
    return _.get(response, 'data.inUsedCampaign', []).map(data => ({ value: data.id, label: data.name }));
  }

  async deleteProductGroupById (groupId: number): Promise<void> {
    return client.delete(`/limitationGroups/${groupId}`);
  }

  wrapProductGroup (json: any): ProductGroup {
    return {
      id: _.get(json, 'id'),
      groupName: _.get(json, 'groupName'),
      state: _.get(json, 'state'),
      groupType: _.get(json, 'groupType'),
      description: _.get(json, 'description'),
      groupValues: _.get(json, 'groupValues'),
      agencys: _.get(json, 'agencys'),
      agencyIds: _.get(json, 'agencyIds')
    };
  }

  wrapProductGroupList (json: any, wrapCb): ProductGroup[] {
    return _.defaultTo(json, []).flatMap((json: any) =>
      wrapCb(json)
    );
  }

}

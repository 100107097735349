import React from 'react';
import styles from './campaignGroupBudgetManagement.module.scss';
import _ from 'lodash';
import cx from 'classnames/bind';
import { Status, getEffectiveStatusDefaultColor } from 'components/Status/Status';
import { InputGroup } from 'react-bootstrap';
import i18n from 'i18n';
import { CampaignState } from 'core/rtbCampaign/RtbCampaign';
import { CampaignGroupChannel } from 'core/campaignGroup/CampaignGroup';

const classNames = cx.bind(styles);

const getAdSetStatusDesData = (adSet) => {
  let des = _.startCase(_.lowerCase(adSet.status));
  let color = getEffectiveStatusDefaultColor(adSet.status);
  switch (adSet.status) {
    case 'PAUSED':
      des = i18n.t('campaignList.labels.deactivateState');
      color = 'black';
      break;
    case 'DELETED':
    case 'ARCHIVED':
      des = i18n.t('campaignList.labels.deleteState');
      color = 'danger';
      break;
    case 'ACTIVE':
      des = i18n.t('campaignList.labels.activateState');
      break;
    default:
      break;
  }

  return {
    des,
    color
  };
};

const getRtbCampaignStatusDesData = (campaign) => {
  let des = '';
  let color;
  switch (campaign.state) {
    case CampaignState.DEACTIVATE:
      des = i18n.t('campaignList.labels.deactivateState');
      color = 'black';
      break;
    case CampaignState.DELETE:
      des = i18n.t('campaignList.labels.deleteState');
      color = 'danger';
      break;
    case CampaignState.ACTIVATE:
      des = i18n.t('campaignList.labels.activateState');
      break;
    default:
      des = 'In Process';
      color = 'whiteTheme4';
      break;
  }

  return {
    des,
    color
  };
};

const stateFormatter = (_1, goCampaign) => {
  const stateData = goCampaign.channel === CampaignGroupChannel.FB ?
    getAdSetStatusDesData(goCampaign) :
    getRtbCampaignStatusDesData(goCampaign);
  return (
    <Status
      label={stateData.des}
      color={stateData.color}
    />
  );
};

const budgetFormatter = (budget: number | string, viewData, _2, formatExtraData: any): any => {
  const currency = formatExtraData.currency;
  const onChange = (event) => {
    formatExtraData.onChange(viewData.id, event.target.value);
  };
  const className = classNames({
    error: !!viewData.error
  });
  const inputClassName = classNames('form-control', {
    error: !!viewData.error
  });
  return (
    <>
      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text className={className}>
            {currency}
          </InputGroup.Text>
        </InputGroup.Prepend>
        <input
          className={inputClassName}
          defaultValue={budget.toString()}
          onChange={onChange}
          type='number'
          min={0}
        />
      </InputGroup>
      {viewData.error && <span className={styles.errorTip}>{viewData.error}</span>}
    </>
  );
};

const budgetFooterFormatter = (totalBudget, budgetSum) => {
  const remainBudget = +totalBudget - +budgetSum;
  return i18n.t(`campaignGroupBudgetManagement.hints.campaignBudget`, { total: remainBudget });
};

export default {
  stateFormatter,
  budgetFormatter,
  budgetFooterFormatter
};

import React from 'react';
import { InputGroup, FormControl, FormLabel } from 'react-bootstrap';
import _ from 'lodash';

const PostfixInputGroup = React.forwardRef((props: any, ref: any) => {
  let className = '';
  const inputProps = _.pick(props, [
    'className',
    'value',
    'name',
    'onChange',
    'type',
    'disabled',
    'placeholder',
    'onBlur',
    'as',
    'min',
    'max',
    'defaultValue'
  ]);

  if (inputProps.className) {
    className = inputProps.className;
  }

  return (
    <InputGroup>
      {
        props.as === 'label' ?
          <FormLabel ref={ref} {...inputProps}>{props.value}</FormLabel> :
          <FormControl ref={ref} {...inputProps} />
      }
      <InputGroup.Append>
        <InputGroup.Text className={className}>
          {props.postfix}
        </InputGroup.Text>
      </InputGroup.Append>
    </InputGroup>
  );
});

export default PostfixInputGroup;

import React from 'react';
import { connect, Field } from 'formik';
import i18n from 'i18n';
import _ from 'lodash';
import FormGroup from 'components/common/FormGroup/FormGroup';
import {
  FormikInput,
  FormikSelect,
  FormikToggleButtons
} from 'components/common/formik';
import { TrackEvent, ALLOW_VIEW_CONVERT_AD_TYPE, AdType } from 'core/rtbCampaign/RtbCampaign';
import ThirdPartyTrackingCode from 'components/ThirdPartyTrackingCode/ThirdPartyTrackingCode';
import * as SelectOptionsUtils from 'utils/SelectOptionsUtils';

import styles from './rtbCampaignTrackForm.module.scss';

const trackEventOptions = SelectOptionsUtils.createSelectOptionsFromEnum(
  TrackEvent, 'campaign.labels.convTrackEvent'
);
class CampaignTrackForm extends React.Component<any> {

  render () {
    const { conversionOptions, adType } = this.props;
    const formikValue = _.get(this.props, 'formik.values');
    const conversionTracking = _.get(formikValue, 'conversionTracking');
    const canUseVideoViewConvert = (ALLOW_VIEW_CONVERT_AD_TYPE.includes(adType) || !adType) && conversionTracking !== null;
    return (
      <div>
        <fieldset>
          <legend>
            <span>{i18n.t('campaign.strategyAndTracking.conversionTracking')}</span>
          </legend>
          <div className={styles.component}>
            <FormGroup controlId='conversionTracking' label={i18n.t('campaign.strategyAndTracking.conversionTracking')}>
              <Field
                component={FormikSelect}
                options={conversionOptions}
                name='conversionTracking'
                isClearable={true}
              />
            </FormGroup>
            {canUseVideoViewConvert && (
              <FormGroup label={i18n.t('campaign.labels.convTrackEvent')} controlId='trackEvent'>
                <Field
                  name='convTrackEvent'
                  component={FormikToggleButtons}
                  options={trackEventOptions}
                />
              </FormGroup>
            )}
          </div>
      </fieldset>
        <fieldset>
          <legend>
            <span>{i18n.t('campaign.strategyAndTracking.thirdPartyTracking')}</span>
          </legend>
            <div className={styles.component}>
              <FormGroup controlId='viewTrackingCodes' label={i18n.t('campaign.strategyAndTracking.thirdPartyTracking')}>
                <ThirdPartyTrackingCode/>
              </FormGroup>
            </div>
        </fieldset>
        { adType === AdType.VIDEO &&
          <fieldset>
            <legend>
              <span>{i18n.t('campaign.strategyAndTracking.videoProgressTracking')}</span>
            </legend>
            <div className={styles.component}>
              <FormGroup controlId='retargetingTrackerCode' label={i18n.t('campaign.strategyAndTracking.videoProgressTrackingCode')}>
                <Field
                  component={FormikInput}
                  name='videoProgressTrackingCode.code'
                  as='textarea'
                  rows='4'
                />
              </FormGroup>
              <FormGroup controlId='videoProgressTrackingCodeOffset' label={i18n.t('campaign.strategyAndTracking.videoProgressTrackingCodeOffset')}>
                <Field
                  component={FormikInput}
                  name='videoProgressTrackingCode.offset'
                  type='number'
                  min={0}
                />
              </FormGroup>
            </div>
          </fieldset>
        }
      </div>
    );
  }
}

export default connect(CampaignTrackForm);

import { FormContentModel } from './FormContentModel';
import _ from 'lodash';
import { Creative } from 'core/creative/Creative';
import RetailNativeProductForm from './RetailNativeProductForm';
import i18n from 'i18n';
import { CallToAction } from 'enum/CallToAction';
import { formatBytes } from 'utils/StringUtil';
import { renderErrors } from './CreativeFormHintRenderFunction';
import { CreativeSetupFlowPageModel } from 'containers/Creatives/CreativeSetupFlow/CreativeSetupFlowPageModel';
import { FormField } from 'components/common/formik/FormikField';

const mimeDB = require('mime-db');

export type RetailNativeProductFormProps = {
  readonly model: RetailNativeProductFormModel;
  readonly basicFields: FormField[];
  readonly renderHintModal: () => any;
};

export class RetailNativeProductFormModel implements FormContentModel {

  public needBannerUrl: boolean = false;

  constructor (
    public flowPageModel: CreativeSetupFlowPageModel
  ) {}

  addLimitation?: (operate: string, limitationType: string, label: string, value: string) => void;

  setLimitationHook (addLimitation: (operate: string, limitationType: string, label: string, value: string) => void): void {
    this.addLimitation = addLimitation;
  }

  getFormContent = () => {
    return RetailNativeProductForm;
  }

  validateImage = (fileData) => {
    const validTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    if (!fileData || !fileData.file) {
      return;
    }

    const file = fileData.file;
    if (validTypes.indexOf(file.type) === -1) {
      const extensions = _.get(mimeDB[file.type], 'extensions', ['Unknown']);
      return i18n.t('creativeSetupFlow.labels.typeErrorHint', { type: extensions[0] });
    }

    const errors = [];

    if (file.size > 2097152) {
      errors.push(i18n.t('creativeSetupFlow.labels.storageErrorHint', { storage1: formatBytes(file.size), storage2: '2MB' }));
    }

    return errors.length > 0 ? renderErrors(errors) : undefined;
  }

  getInitTypeProperties () {
    return {
      layout: undefined,
      productSet: undefined,
      productSetId: undefined,
      callToAction: CallToAction.LEARN_MORE
    };
  }

  getFormModelData (creative: Creative) {
    const creativeExtra = _.get(creative.basic, 'bannerExtra');
    const callToActionText = _.get(creativeExtra, 'callToAction');
    let callToActionValue;
    Object.values(CallToAction).forEach(callToAction => {
      const enLabel = i18n.getResource('en', 'translation', `callToAction.${_.snakeCase(callToAction)}`);
      const zhLabel = i18n.getResource('zh-TW', 'translation', `callToAction.${_.snakeCase(callToAction)}`);
      if (callToActionText === enLabel || callToActionText === zhLabel) {
        callToActionValue = callToAction;
      }
    });
    const productSetData = _.get(creative.basic, 'productSet');
    const typeProperties = {
      retailer: _.get(creative.basic, 'retail'),
      productSet: _.get(productSetData, 'productSet'),
      productSetId: _.get(productSetData, 'productSetId'),
      callToAction: callToActionValue,
      layout: _.get(creativeExtra, 'layoutId')
    };
    const medias = {
      logo: {
        url: _.get(creativeExtra, 'logoUrl')
      }
    };
    const creativeBasic: any = _.omit(creative.basic, ['id', 'srcType', 'creativeValues', 'retail', 'productSet', 'productSetId']);
    return {
      basic: {
        ...creativeBasic,
        typeProperties,
        medias
      },
      limitations: creative.limitations
    };
  }
}

import React from 'react';
import styles from './imageMediaSummary.module.scss';
import { ImagePreview } from 'components/common/Image/ImagePreview';

type mediaSummaryData = {
  medias: {
    image: {
      file?: File,
      url?: string
    }
  }
};

export const ImageMediaSummary: React.FunctionComponent<{ mediaSummary: mediaSummaryData }> = props => {
  const medias = props.mediaSummary.medias;

  return (
    <div className={styles.imagePreview}>
      <ImagePreview
        url={medias.image.url}
        file={medias.image.file}
        border={false}
        config={{
          showName: medias.image.file ? true : false,
          showSize: true
        }}
      />
    </div>
  );
};

import React from 'react';
import { SelectOptions } from 'components/common/commonType';
import { CreativeFormBasicData, CreativeFormData, FormContentModel } from './FlowSteps/SubSteps/FormContent/FormContentModel';
import { CreativeType } from 'core/creative/Creative';

export type CreativeSetupFlowDataContextType = {
  creative: any,
  initCreative: any,
  advertisers: Array<SelectOptions>,
  tenmaxCategories: Array<SelectOptions>,
  supportedCreativeType: Array<CreativeType>,
  setCreative (creative: CreativeFormData): void,
  setFinishedRedirectData (data?: {
    pathname: string,
    state?: any
  }): void,
  getFormContentModelOfType (cretiveType: CreativeType): FormContentModel | undefined,
  getSummaryModel (creativeBasicFormData: CreativeFormBasicData)
};

export const CreativeSetupFlowDataContext = React.createContext<CreativeSetupFlowDataContextType>({
  initCreative: {},
  creative: {},
  advertisers: [],
  tenmaxCategories: [],
  supportedCreativeType: [],
  setCreative: () => {},
  setFinishedRedirectData: () => {},
  getFormContentModelOfType: () => { return undefined; },
  getSummaryModel: () => {}
});

import styles from './creativeList.module.scss';
import _ from 'lodash';
import i18n from 'i18next';
import { CreativeListRecord } from 'core/creative/Creative';

export enum CreativeListCommonColumns {
  NAME_ID = 'bannerId',
  PREVIEW = 'preview',
  LANDING_URL = 'bannerUrl',
  BIND_STATE = 'bindingCampaign',
  LIMITATION = 'limitations',
  APPROVAL = 'approvalStatus',
  EDITBTNS = 'editBtns'
}

export const commonColumnSetting = {
  [CreativeListCommonColumns.NAME_ID]: {
    ...getColumnSetting(CreativeListCommonColumns.NAME_ID),
    sort: true
  },
  [CreativeListCommonColumns.PREVIEW]:
    getColumnSetting(CreativeListCommonColumns.PREVIEW),
  [CreativeListCommonColumns.APPROVAL]:
    getColumnSetting(CreativeListCommonColumns.APPROVAL),
  [CreativeListCommonColumns.LANDING_URL]:
    getColumnSetting(CreativeListCommonColumns.LANDING_URL),
  [CreativeListCommonColumns.BIND_STATE]:
    getColumnSetting(CreativeListCommonColumns.BIND_STATE),
  [CreativeListCommonColumns.LIMITATION]:
    getColumnSetting(CreativeListCommonColumns.LIMITATION),
  [CreativeListCommonColumns.EDITBTNS]: {
    ...getColumnSetting(CreativeListCommonColumns.EDITBTNS),
    text: ''
  }
};

export function getCommonColumnData (creative: CreativeListRecord) {

  const getLimitationsToShow = (creative) => {
    let limitations = _.omit(_.get(creative, 'limitations', []), ['other']);
    let result = {};
    let limitationKeys = _.filter(Object.keys(limitations), limitationKey => limitations[limitationKey].length > 0);
    limitationKeys.forEach(key => {
      result[key] = _.filter(limitations[key], limitation => limitation.type !== 'unicornlanguage').map(limitation => i18n.t(`limitation.labels.${limitation.type}`)).join(',');
    });
    return result;
  };

  return {
    tenmaxCategory: creative.tenmaxCategory,
    origTenmaxCategory: creative.origTenmaxCategory,
    enableStartTime: creative.enableStartTime,
    enableEndTime: creative.enableEndTime,
    [CreativeListCommonColumns.NAME_ID]: {
      id: creative.creativeId,
      name: creative.name
    },
    [CreativeListCommonColumns.LANDING_URL]: _.get(creative, 'bannerUrl'),
    [CreativeListCommonColumns.BIND_STATE]: _.get(creative, 'bindingCampaign'),
    [CreativeListCommonColumns.PREVIEW]: creative,
    [CreativeListCommonColumns.APPROVAL]: creative,
    [CreativeListCommonColumns.LIMITATION]: getLimitationsToShow(creative)
  };
}

export function getColumnSetting (columnName) {
  const columnClassGetter = () => {
    return styles[columnName];
  };

  return {
    sort: false,
    text: `creativeList.headers.${columnName}`,
    dataField: columnName,
    classes: columnClassGetter,
    headerClasses: columnClassGetter
  };
}

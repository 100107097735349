import { CreativeListDataProvider } from './CreativeListDataProvider';
import { CreativeListCommonColumns, commonColumnSetting, getCommonColumnData } from './CreativeListCommonColumnData';
import { renderColumn } from 'components/TableColumn';
import { getCreativeListFormatters } from './CreativeListFormatters';
import { CreativeListRecord } from 'core/creative/Creative';
import _ from 'lodash';

enum COLUMNS {
  LAYOUT_ID = 'ppsLayoutId'
}

export class AdNeonCreativeListDataProvider implements CreativeListDataProvider {

  formatters: any;
  constructor (model) {
    this.formatters = getCreativeListFormatters(model);
  }

  getColumns () {
    return [
      renderColumn(commonColumnSetting[CreativeListCommonColumns.NAME_ID], this.formatters.nameIdFormatter, this.formatters.nameIdHeaderFormatter),
      renderColumn(commonColumnSetting[CreativeListCommonColumns.PREVIEW], this.formatters.previewFormatter),
      renderColumn(commonColumnSetting[CreativeListCommonColumns.LANDING_URL], this.formatters.urlFormatter),
      renderColumn(commonColumnSetting[CreativeListCommonColumns.BIND_STATE], this.formatters.bindStateFormatter),
      renderColumn(commonColumnSetting[CreativeListCommonColumns.LIMITATION], this.formatters.limitationFormatter),
      renderColumn(commonColumnSetting[CreativeListCommonColumns.EDITBTNS], this.formatters.floatingEditBtnsFormatter)
    ];
  }

  getViewModel (creative: CreativeListRecord) {
    return {
      ...getCommonColumnData(creative),
      [COLUMNS.LAYOUT_ID]: _.get(creative, 'ppsLayoutId', [])
    };
  }
}

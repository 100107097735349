import React from 'react';
import { InputGroup } from 'react-bootstrap';

const PrefixInputGroup = props => {
  let className = '';
  if (props.className) {
    className = props.className;
  }
  return (
    <InputGroup>
      <InputGroup.Prepend>
        <InputGroup.Text className={className}>
          {props.currency}
        </InputGroup.Text>
      </InputGroup.Prepend>
      {props.children}
    </InputGroup>
  );
};

export default PrefixInputGroup;

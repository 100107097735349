import React, { RefObject } from 'react';
import { CreativeSetupFlowDataContext } from '../CreativeSetupFlowDataContext';
import { Tabs, Tab, Button } from 'react-bootstrap';
import i18n from 'i18next';
import { CreativeSetupStepProps, CreativeSetupStepState, CreativeSetupTab } from './CreativeSetupStepModel';
import styles from './creativeSetupStep.module.scss';
import { CreativeBasicForm } from './SubSteps/CreativeBasicForm';
import { Form, Formik } from 'formik';
import { ErrorHandler } from 'components/common/formik/FormErrorHandler/FormErrorHandler';

export class CreativeSetUpStep extends React.Component<CreativeSetupStepProps, CreativeSetupStepState> {

  handler: any;
  mainDomRef: RefObject<any>;

  constructor (props: any) {
    super(props);
    this.mainDomRef = React.createRef();
  }

  componentDidMount () {
    this.handler = this.props.model.event.add(model => {
      this.setState(model.state);
    });
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add(model => {
        this.setState(model.state);
      });
    }
  }

  componentWillUnmount () {
    this.props.model.event.remove(this.handler);
  }

  render () {
    const model = this.props.model;
    return (
      <CreativeSetupFlowDataContext.Consumer>
        {(creativeContext) => {
          const handleSubmit = (values) => {
            model.goNext(() => {
              creativeContext.setCreative({
                basic: values
              });
            });
          };
          const creativeData = creativeContext.creative;
          const limitationModel = model.getLimitationModel(creativeData.limitations, creativeData.basic.advertiserId);
          const onSelect = tab => {
            model.goSubStep(+tab);
          };
          const basicFormModel = model.getBasicFormModel(
            creativeData.basic,
            creativeContext.tenmaxCategories,
            creativeContext.supportedCreativeType,
            creativeContext.getFormContentModelOfType,
            limitationModel.addLimitation
          );
          return (
            <div className={styles.setupStep} ref={this.mainDomRef}>
              <Formik
                initialValues={basicFormModel.initCreative}
                enableReinitialize
                onSubmit={handleSubmit}
                validateOnBlur={false}
              >
                {formikProps => {
                  basicFormModel.setFormikProps(formikProps);
                  return (
                    <Form>
                      <ErrorHandler
                        parentRef={this.mainDomRef}
                        isSubmitting={formikProps.isSubmitting}
                        submitCount={formikProps.submitCount}
                      />
                      <Tabs
                        activeKey={model.activeTab}
                        id='creativeFromTab'
                        onSelect={onSelect}
                      >
                        <Tab eventKey={CreativeSetupTab.BASIC} title={i18n.t('stepSideBar.labels.creativeBasic')}>
                          <CreativeBasicForm
                            model={basicFormModel}
                          />
                        </Tab>
                      </Tabs>
                      <div className={styles.buttonArea}>
                        <Button
                          variant='primary'
                          size='sm'
                          type='submit'
                        >
                          {i18n.t('campaign.buttons.completeAndCheck')}
                        </Button>
                        {model.goLast &&
                          <Button variant='secondary' size='sm' onClick={model.goLast}>
                            {i18n.t('campaign.buttons.back')}
                          </Button>
                        }
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          );
        }}
      </CreativeSetupFlowDataContext.Consumer>
    );
  }
}

import { CampaignGroup, CampaignGroupChannel } from 'core/campaignGroup/CampaignGroup';
import { FbAdSet } from 'core/fbAdSet/FbAdSet';
import React from 'react';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import styles from './campaignGroupBudgetManagement.module.scss';
import { StickableBootstrapTable } from 'components/common/Table/StickableBootstrapTable';
import { CampaignGroupBudgetManagementColumns, useCampaignGroupBudgetManagementModel } from './CampaignGroupBudgetManagementModel';
import { Order } from 'core/order/Order';
import i18n from 'i18n';
import { Modal } from 'components/common/Modal/Modal';
import { RtbCampaign } from 'core/rtbCampaign/RtbCampaign';

export const CampaignGroupBudgetManagement: React.FC<{
  channel: CampaignGroupChannel,
  order: Order,
  campaignGroup: Partial<CampaignGroup>,
  initCampaignGroup: Partial<CampaignGroup> | undefined,
  goCampaignList: FbAdSet[] | RtbCampaign[],
  budgetDataList?: {id: string, budget: number }[],
  submit: (budgetMap) => void,
  cancel: () => void
}> = ({
  channel,
  order,
  campaignGroup,
  initCampaignGroup,
  goCampaignList,
  budgetDataList,
  submit,
  cancel
}) => {

  const {
    loading,
    viewData,
    columns,
    budgets,
    canSubmit
  } = useCampaignGroupBudgetManagementModel(
    channel,
    order,
    campaignGroup,
    initCampaignGroup,
    goCampaignList,
    budgetDataList
  );

  const onSubmit = () => {
    if (!canSubmit) {
      return;
    }
    submit(budgets);
  };

  return (
    <>
      {loading && <LoadingIndicator />}
      <Modal
        fullScreen={true}
        title={i18n.t('campaignGroupBudgetManagement.title', { num:  goCampaignList.length })}
        primaryButton={{
          title: i18n.t('common.buttons.confirm'),
          disabled: !canSubmit,
          callback: onSubmit
        }}
        secondaryButton={{
          title: i18n.t('common.buttons.cancel'),
          callback: cancel
        }}
        dismiss={cancel}
        animation={false}
      >
        <div className={styles.contentArea}>
          <StickableBootstrapTable
            stickFirstColumn
            keyField={CampaignGroupBudgetManagementColumns.NAME}
            data={viewData}
            columns={columns}
            paddingBottom={false}
            noDataIndication={i18n.t('common.labels.noData')}
            defaultSorted={[{
              dataField: CampaignGroupBudgetManagementColumns.NAME,
              order: 'asc'
            }]}
          />
        </div>
      </Modal>
    </>
  );
};

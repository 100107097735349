import React, { Fragment, RefObject } from 'react';
import { Link, Redirect } from 'react-router-dom';

import styles from './accountForm.module.scss';

import { AccountFormProps, AccountFormValues, AccountFormModel, AccountFormState } from './AccountFormModel';

import i18n from 'i18n';
import { alertError } from 'components/AlertDialog';

import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { Formik, Form, Field, FormikActions, FormikProps, FieldProps } from 'formik';
import { Form as BootstrapForm, Row, Col } from 'react-bootstrap';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { hasRoles, isProject } from 'core/permission/PermissionDSL';
import { RoleNames } from 'core';
import cx from 'classnames/bind';
import { FormikSelect } from 'components/common';
import _ from 'lodash';
import { FormikField } from 'components/common/formik/FormikField';
import { Trans } from 'react-i18next';
import { Project } from 'core/project/Project';

export class AccountForm extends React.Component<AccountFormProps, AccountFormState> {
  handler?: number;
  formikRef: RefObject<any>;
  formikEventHandler?: number;
  classNames: any;

  constructor (props: any) {
    super(props);
    this.state = this.props.model.state;
    this.formikRef = React.createRef();
    this.classNames = cx.bind(styles);
  }

  componentDidMount () {
    this.handler = this.props.model.event.add((model) => {
      this.setState(model.state);
    });
    this.formikEventHandler = this.props.model.formikEvent.add(event => {
      if (event.isSubmit === 'FORMIK_SUBMIT') {
        this.formikRef.current.submitForm();
      }
    });
    this.props.model.init();
  }

  componentDidUpdate (prevProps: AccountFormProps) {
    if (prevProps.model !== this.props.model) {
      this.handler && prevProps.model.event.remove(this.handler);
      this.formikEventHandler && prevProps.model.formikEvent.remove(this.formikEventHandler);
      this.handler = this.props.model.event.add((model) => {
        this.setState(model.state);
      });
      this.formikEventHandler = this.props.model.formikEvent.add(event => {
        if (event.isSubmit === 'FORMIK_SUBMIT') {
          this.formikRef.current.submitForm();
        }
      });
      this.props.model.init();
    }
  }

  componentWillUnmount () {
    this.props.model.onUnmount(this.handler, this.formikEventHandler);
  }

  validate = (values: AccountFormValues): any => {
    return this.props.model.validate(values);
  }

  submit = async (values: AccountFormValues, actions: FormikActions<AccountFormValues>) => {
    await this.props.model.submit(values);
    actions.setSubmitting(false);
  }

  dismiss = () => {
    this.props.model.dismiss();
  }

  render () {
    const model = this.props.model;
    const drawForm = !(this.state.error === null && this.state.redirectPath !== null);
    return (
      <Fragment>
        {this.state.error === null && this.state.redirectPath !== null && <Redirect to={this.state.redirectPath} />}
        {drawForm && this.renderForm(model)}
      </Fragment>
    );
  }

  renderFormikContent = (formProps: FormikProps<AccountFormValues>) => {
    const model = this.props.model;
    const labelColumnValue = model.isModalContent ? 2 : 3;
    const valueColumnValue = model.isModalContent ? 4 : 9;
    const selectOptionsWidth = 460;
    const agencyLabel = i18n.t('accounts.form.labels.agency');
    const emailLabel = i18n.t('accounts.form.labels.email');
    const nameLabel = i18n.t('accounts.form.labels.name');

    const renderAgencyField = (props: FieldProps) => (
      <BootstrapForm.Group as={Row} controlId={'accountAgencyField'}>
        <Col sm={labelColumnValue}>
          <BootstrapForm.Label column className={styles.required}>{agencyLabel}</BootstrapForm.Label>
        </Col>
        <Col sm={valueColumnValue}>
          <div className={styles.fieldValue}>
            <FormikSelect
              {...props}
              options={model.agencyOptions}
              className={styles.agencyField}
              simpleValue
            />
          </div>
        </Col>
      </BootstrapForm.Group>
    );

    const renderEmailField = (props: FieldProps) => (
      <BootstrapForm.Group as={Row} controlId={'accountEmailField'}>
        <Col sm={labelColumnValue}>
          <BootstrapForm.Label column className={model.emailEditable ? styles.required : ''}>{emailLabel}</BootstrapForm.Label>
        </Col>
        <Col sm={valueColumnValue}>
          <div className={styles.fieldValue}>
            <input {...props.field} type='email' readOnly={!model.emailEditable} placeholder={emailLabel} />
            {formProps.errors.email && <span className={styles.errorMessage}>{i18n.t(formProps.errors.email)}</span>}
          </div>
        </Col>
      </BootstrapForm.Group>
    );

    const renderNameField = (props: FieldProps) => (
      <BootstrapForm.Group as={Row} controlId={'accountNameField'}>
        <Col sm={labelColumnValue}>
          <BootstrapForm.Label column className={styles.required}>{nameLabel}</BootstrapForm.Label>
        </Col>
        <Col sm={valueColumnValue}>
          <div className={styles.fieldValue}>
            <input {...props.field} type='text' placeholder={nameLabel} />
            {formProps.errors.name && <span className={styles.errorMessage}>{i18n.t(formProps.errors.name)}</span>}
          </div>
        </Col>
      </BootstrapForm.Group>
    );

    const renderIsAdminField = (props: FieldProps) => (
      <BootstrapForm.Group as={Row} controlId={'accountIdAdminField'}>
        <Col sm={labelColumnValue}>
          <BootstrapForm.Label column>{i18n.t('accounts.form.labels.isAdmin')}</BootstrapForm.Label>
        </Col>
        <Col sm={valueColumnValue}>
          <div className={styles.fieldValue}>
            <input {...props.field} type='checkbox' id='admin' checked={props.field.value} />
            <label htmlFor='admin' className={styles.alignLeft}>{i18n.t('accounts.form.labels.activated')}</label>
          </div>
        </Col>
      </BootstrapForm.Group>
    );

    const renderIsGoJekAccountManagerField = (disabled: boolean, props: FieldProps) => (
      <BootstrapForm.Group as={Row} controlId={'accountIdAdminField'}>
        <Col sm={labelColumnValue}>
          <BootstrapForm.Label column>{i18n.t('accounts.form.labels.isGoJekAccountManager')}</BootstrapForm.Label>
        </Col>
        <Col sm={valueColumnValue}>
          <div className={styles.fieldValue}>
            <input {...props.field} type='checkbox' id='gojekAccountManager' checked={props.field.value} disabled={disabled} />
            <label htmlFor='gojekAccountManager' className={this.classNames(styles.alignLeft, { disabled })}>
              {i18n.t('accounts.form.labels.activated')}
            </label>
          </div>
        </Col>
      </BootstrapForm.Group>
    );

    const renderActivatedField = (props: FieldProps) => (
      <BootstrapForm.Group as={Row} controlId={'accountActivatedField'}>
        <Col sm={labelColumnValue}>
          <BootstrapForm.Label column>{i18n.t('accounts.form.labels.status')}</BootstrapForm.Label>
        </Col>
        <Col sm={valueColumnValue}>
          <div className={styles.fieldValue}>
            <input {...props.field} type='checkbox' id='status' checked={props.field.value} />
            <label htmlFor='status' className={styles.alignLeft}>{i18n.t('accounts.form.labels.activated')}</label>
          </div>
        </Col>
      </BootstrapForm.Group>
    );

    const renderAssignRoleHint = () => (
      <BootstrapForm.Group as={Row} controlId={'assignRoleHint'}>
        <Col sm={labelColumnValue} />
        <Col sm={valueColumnValue}>
          <div className={styles.hints}>
            <Trans i18nKey='accounts.form.hints.assignRole'>
              <span className={styles.danger}>...</span>...
            </Trans>
          </div>
        </Col>
      </BootstrapForm.Group>
    );

    return (
      <Form>
        <div className={styles.formContent}>
          {
            model.agencyOptions &&
            <Field
              name='agencyId'
              render={renderAgencyField}
            />
          }
          <Field
            name='email'
            render={renderEmailField}
          />
          <Field
            name='name'
            render={renderNameField}
          />
          {
            model.roleOptions && model.roleOptions.length > 0 &&
              <FormikField.Select
                labelColSm={labelColumnValue}
                inputColSm={valueColumnValue}
                name='role'
                label={i18n.t('accounts.form.labels.role')}
                options={model.roleOptions}
                fieldContentWidth={selectOptionsWidth}
                componentWidthFitParent={true}
                simpleValue
                required
              />
          }
          {model.isAdminEditable &&
            <PermissionChecker permissionAware={hasRoles(RoleNames.sysAdmin)}>
              <Field
                name='isAdmin'
                render={renderIsAdminField}
              />
            </PermissionChecker>
          }
          <PermissionChecker permissionAware={isProject(Project.GOGAN)}>
            {model.isGojekAccountManagerEditable &&
              <>
                <PermissionChecker permissionAware={hasRoles(RoleNames.sysAdmin)}>
                  <Field
                    name='isGoJekAccountManager'
                    render={_.partial(renderIsGoJekAccountManagerField, false)}
                  />
                </PermissionChecker>
                <PermissionChecker permissionAware={hasRoles(RoleNames.goJekAccountManager)}>
                  <Field
                    name='isGoJekAccountManager'
                    render={_.partial(renderIsGoJekAccountManagerField, true)}
                  />
                </PermissionChecker>
              </>
            }
          </PermissionChecker>
          {model.activatedEditable &&
            <Field
              name='activated'
              render={renderActivatedField}
            />
          }
          {
            model.needAssignRoleHint && renderAssignRoleHint()
          }
        </div>
        <div className={styles.formButtons}>
          <button
            type='submit'
            className='btn btn-primary btn-sm'
            disabled={formProps.isSubmitting}
          >
            {i18n.t('common.buttons.submit')}
          </button>
          <Link
            to={model.cancelPath}
            className='btn btn-secondary btn-sm'
          >
            {i18n.t('common.buttons.cancel')}
          </Link>
        </div>
      </Form>
    );
  }

  renderForm (model: AccountFormModel) {
    const initialValues = model.initialValues();
    const className = this.classNames('accountForm', {
      isModalContent: model.isModalContent
    });
    return (
      <div className={className}>
        {this.state.loading && <LoadingIndicator />}
        {this.state.error && alertError(this.state.error, this.dismiss)}
        <h3 className={styles.title}>
          {i18n.t(model.title)}
        </h3>
        <div className={styles.titleBottomLine} />
        <Formik
          ref={this.formikRef}
          initialValues={initialValues}
          enableReinitialize
          validate={this.validate}
          onSubmit={this.submit}
          render={this.renderFormikContent}
        />
      </div>
    );
  }
}

import { FormContentModel } from './FormContentModel';
import _ from 'lodash';
import i18n from 'i18n';
import PilotTVForm from './PilotTVForm';
import { Creative } from 'core/creative/Creative';
import { formatBytes } from 'utils/StringUtil';
import { renderErrors } from './CreativeFormHintRenderFunction';
import { FormField } from 'components/common/formik/FormikField';
const mimeDB = require('mime-db');

export type PilotTVFormProps = {
  readonly model: PilotTVFormModel;
  readonly basicFields: FormField[];
  readonly renderHintModal: () => any;
};

export class PilotTVFormModel implements FormContentModel {

  public needBannerUrl: boolean = false;

  addLimitation?: (operate: string, limitationType: string, label: string, value: string) => void;

  setLimitationHook (addLimitation: (operate: string, limitationType: string, label: string, value: string) => void): void {
    this.addLimitation = addLimitation;
  }

  getFormContent = () => {
    return PilotTVForm;
  }

  validateVideo = (fileData) => {
    const validTypes = ['video/mp4'];
    if (!fileData || (!fileData.file && !fileData.url)) {
      return i18n.t('formValidate.labels.emptyError');
    }

    if (!fileData.file) {
      return;
    }

    const file = fileData.file;
    if (validTypes.indexOf(file.type) === -1) {
      const extensions = _.get(mimeDB[file.type], 'extensions', ['Unknown']);
      return i18n.t('creativeSetupFlow.labels.typeErrorHint', { type: extensions[0] });
    }

    const errors = [];
    const duration = fileData.duration;
    if (duration > 120) {
      errors.push(i18n.t('creativeSetupFlow.labels.videoDurationError'));
    }

    if (file.size > 10485760) {
      errors.push(i18n.t('creativeSetupFlow.labels.storageErrorHint', { storage1: formatBytes(file.size), storage2: '10MB' }));
    }

    return errors.length > 0 ? renderErrors(errors) : undefined;
  }

  getInitTypeProperties () {
    return {};
  }

  getFormModelData (creative: Creative) {
    const creativeValueString = _.get(creative, 'basic.creativeValues', '{}');
    const creativeValue = JSON.parse(creativeValueString);
    const videoUrl = _.get(creativeValue, 'videoUrl');
    const medias = _.omitBy({
      fileVideo: {
        url: videoUrl,
        duration: _.get(creativeValue, 'duration'),
        width: _.get(creativeValue, 'w'),
        height: _.get(creativeValue, 'h')
      }
    }, _.isEmpty);
    const creativeBasic: any = _.omit(creative.basic, ['id', 'srcType', 'creativeValues']);
    return {
      basic: {
        ...creativeBasic,
        typeProperties: {},
        medias
      },
      limitations: creative.limitations
    };
  }
}

import { DoctorRecords, FilterReason, SpaceChannel } from 'core/doctor/DoctorData';
import client from './RestClient';
import _ from 'lodash';

export interface DoctorWebService {
  getDoctorRecords (campaignId: string | number, creativeId: string | number): Promise<DoctorRecords>;
}

function wrapSpaceChannel (json): SpaceChannel {
  return {
    domain: _.get(json, 'domain'),
    spaceName: _.get(json, 'spaceName'),
    spaceId: _.get(json, 'spaceId')
  };
}

function wrapFilterReason (json): FilterReason {
  return {
    ...wrapSpaceChannel(json),
    type: _.get(json, 'type'),
    bannerCategory: _.get(json, 'bannerCategory'),
    spaceBanCategory: _.get(json, 'spaceBanCategory', []),
    ...json
  };
}

export class RestfulDoctorWebService implements DoctorWebService {

  restClient: any;

  constructor (restClient: any = client) {
    this.restClient = restClient;
  }

  async getDoctorRecords (campaignId: string | number, creativeId: string | number): Promise<DoctorRecords> {
    const response = await this.restClient.get(`/doctor/deliver-predict/campaign/${campaignId}/creative/${creativeId}`);
    return {
      allSpaceChannels: response.data.allSpaceChannels.map(json => wrapSpaceChannel(json)),
      filterReasons: response.data.filterReasons.map(json => wrapFilterReason(json))
    };
  }
}

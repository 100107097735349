import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import { connect, FormikContext } from 'formik';
import { RetailNativeProductFormProps } from './RetailNativeProductFormModel';
import styles from './retailNativeProductForm.module.scss';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { FormConfig } from 'components/common/formik/FormikField';
import _ from 'lodash';
import { useRetailOptionsHook } from './useRetailOptionsHook';
import { CreativeType } from 'core/creative/Creative';
import { FormikFormContent } from 'components/common/formik/FormikFormContent';
import { validateEmpty } from 'utils/ValidateUtils';

export const RetailNativeProductForm: React.FC<RetailNativeProductFormProps & { formik: FormikContext<any> }> = (props) => {

  const { values, setFieldValue } = props.formik;
  const advertiserId = values.advertiserId;
  const {
    loading,
    defaultRetailer,
    retailerOptions,
    productSetOptions,
    layoutOptions,
    callToActionOptions,
    onRetailerChange
  } = useRetailOptionsHook(advertiserId, props.model.flowPageModel, CreativeType.RETAIL_NATIVE_PRODUCT);

  useEffect(() => {
    setFieldValue('typeProperties.retailer', defaultRetailer);
  }, [defaultRetailer, setFieldValue]);

  const [formConfig, setFormConfig] = useState<FormConfig | undefined>();

  useEffect(() => {
    const retailerChangeHandler = value => {
      setFieldValue('typeProperties.productSet', undefined);
      setFieldValue('typeProperties.productSetId', undefined);
      setFieldValue('typeProperties.layout', undefined);
      onRetailerChange(value);
    };

    const productSetChangeHandler = value => {
      let productSetOption = productSetOptions.find(productSet => productSet.value === value);
      let name = _.get(productSetOption, 'label', '');
      let id = _.get(productSetOption, 'value', '');
      setFieldValue('typeProperties.productSet', name);
      setFieldValue('typeProperties.productSetId', id);
    };

    setFormConfig(new FormConfig.Builder()
      .addSection(
        new FormConfig.SectionBuilder(
          i18n.t('creativeSetupFlow.labels.creativeBasicInfo'),
          new FormConfig.FieldsBuilder([...props.basicFields])
            .addSelect({
              label: i18n.t('creativeSetupFlow.labels.retailer'),
              simpleValue: true,
              name: 'typeProperties.retailer',
              options: retailerOptions,
              onChange: retailerChangeHandler,
              validate: validateEmpty
            }, props.model.flowPageModel.type !== 'create')
            .addLabel({
              label: i18n.t('creativeSetupFlow.labels.retailer'),
              name: 'typeProperties.retailer',
              formatter: value => value ? i18n.t(`retailers.${value}`) : ''
            }, props.model.flowPageModel.type === 'create')
            .addSelect({
              label: i18n.t('creativeSetupFlow.labels.productSet'),
              simpleValue: true,
              name: 'typeProperties.productSetId',
              options: productSetOptions,
              onChange: productSetChangeHandler,
              validate: validateEmpty
            })
            .addSelect({
              label: i18n.t('creativeSetupFlow.labels.retailLayout'),
              simpleValue: true,
              name: 'typeProperties.layout',
              options: layoutOptions,
              validate: validateEmpty
            })
            .build()
        )
        .addAdvancedFields(new FormConfig.FieldsBuilder()
          .addFileInput({
            type: 'image',
            label: i18n.t('creativeSetupFlow.labels.retailLogo'),
            name: 'medias.logo',
            hints: [i18n.t('creativeSetupFlow.labels.storageHint', { storage: '2MB' })],
            className: styles.logo,
            validate: props.model.validateImage
          })
          .addSelect({
            label: i18n.t('creativeSetupFlow.labels.retailCallToAction'),
            simpleValue: true,
            name: 'typeProperties.callToAction',
            options: callToActionOptions
          })
          .build()
        )
        .build()
      ).build()
    );
  }, [
    props.basicFields,
    props.model.flowPageModel.type,
    layoutOptions,
    retailerOptions,
    productSetOptions,
    callToActionOptions,
    setFieldValue,
    onRetailerChange,
    props.model.validateImage
  ]);

  return (
    <div>
      {loading && <LoadingIndicator/>}
      <FormikFormContent
        formConfig={formConfig}
      />
    </div>
  );
};

export default connect(RetailNativeProductForm);

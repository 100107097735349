import React from 'react';
import styles from './creativesHome.module.scss';
import { RecentGroupDropdown } from 'components/RecentGroupDropdown/RecentGroupDropdown';
import { CreativesHomeProps } from './CreativesHomeModel';
import i18n from 'i18next';
import { CreativeList } from 'containers/CreativeList/CreativeList';
import { SessionStorageItemKeys } from 'helper/StorageHelper';
import { RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { notRoles, hasRoles } from 'core/permission/PermissionDSL';
import { RoleNames } from 'core';
import { StickableTableContainer } from 'containers/StickableTableContainer/StickableTableContainer';
import { Modal } from 'components/common/Modal/Modal';

export class CreativesHome extends React.Component<RouteComponentProps<any> & CreativesHomeProps> {

  handler?: number;

  componentDidMount () {
    this.handler = this.props.model.event.add((model) => {
      this.setState(model.state);
    });
    this.props.model.init();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add((model) => {
        this.setState(model.state);
      });
      this.props.model.init();
    }
  }

  componentWillUnmount () {
    this.handler && this.props.model.event.remove(this.handler);
  }

  onAgencySelected = (agencyId?: number) => {
    this.props.model.setAgencyId && this.props.model.setAgencyId(agencyId);
  }

  onAdvertiserSelected = (advertiserId?: number) => {
    this.props.model.setAdvertiserId(advertiserId);
  }

  onSearchChange = (searchPath) => {
    if (_.isEmpty(searchPath)) {
      this.props.history.replace(`${this.props.location.pathname}`);
    } else {
      this.props.history.replace(`${this.props.location.pathname}?creativeSearch=${searchPath}`);
    }
  }

  renderCreateCreativeError = (errors) => {
    const errorContent = errors.map((error, index) => (
      <li key={index}>
        <div className={styles.errorInfo}>
          <div>{'Name:'}</div>
          <div>{error.name}</div>
          <div>{'Error:'}</div>
          <div>
            {
            error.error_message === null ?
              i18n.t('serverResponse.500000') :
              error.error_message
            }
          </div>
        </div>
      </li>
    ));
    const hideModal = () => {
      this.props.history.replace(this.props.location.pathname, undefined);
    };
    return (
      <Modal
        title={i18n.t('creativesHome.titles.createCreativeErrorModal')}
        primaryButton={{
          title: i18n.t('common.buttons.ok'),
          callback: hideModal
        }}
        dismiss={hideModal}
      >
        <ul className={styles.createCreativeErrorList}>
          {errorContent}
        </ul>
      </Modal>
    );
  }

  render () {
    const searchParams = new URLSearchParams(this.props.location.search);
    const model = this.props.model;
    const locationState = this.props.location.state;
    const createCreativeErrors = _.get(locationState, 'errors');
    return (
      <StickableTableContainer>
        <div className={styles.container}>
          <div className={styles.titleArea}>
            {i18n.t('creativesHome.labels.title')}
            <div className={styles.filterArea}>
              <PermissionChecker permissionAware={hasRoles(RoleNames.sysAdmin)}>
                <div className={styles.agencyList}>
                  <div className={styles.title}>
                    {i18n.t('creativesHome.labels.agencyListTitle')}
                  </div>
                  <RecentGroupDropdown
                    value={model.state.agencyId}
                    options={model.state.agencies}
                    storageKey={SessionStorageItemKeys.AGENCY}
                    callback={this.onAgencySelected}
                    showShowAllBtn={false}
                  />
                </div>
              </PermissionChecker>
              <PermissionChecker
                permissionAware={notRoles(RoleNames.adsAdmin, RoleNames.adsReport, RoleNames.adsSales)}
              >
                <div className={styles.advertiserList}>
                  <div className={styles.title}>
                    {i18n.t('creativesHome.labels.advertiserListTitle')}
                  </div>
                  <RecentGroupDropdown
                    value={model.state.advertiserId}
                    options={model.advertisers}
                    storageKey={SessionStorageItemKeys.ADVERTISER}
                    callback={this.onAdvertiserSelected}
                  />
                </div>
              </PermissionChecker>
            </div>
          </div>
          <div className={styles.listArea}>
            <CreativeList model={this.props.model.getCreativeListModel(searchParams.get('creativeSearch'), this.onSearchChange)}/>
          </div>
          {createCreativeErrors && createCreativeErrors.length > 0 && this.renderCreateCreativeError(createCreativeErrors)}
        </div>
      </StickableTableContainer>
    );
  }
}

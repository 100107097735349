import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';

type FormGroupProps = {
  as: any;
  controlId: string;
  labelColSm: number;
  label?: string;
  valueColSm: number;
  children?;
  className?;
  valueClassName?;
};
const FormGroup = ({
  as,
  controlId,
  labelColSm,
  label,
  valueColSm,
  className,
  valueClassName,
  ...props
}: FormGroupProps) => {
  return (
    <Form.Group as={as} controlId={controlId} className={className}>
      <Col sm={labelColSm}>
        {label && <Form.Label column>{label}</Form.Label>}
      </Col>
      <Col sm={valueColSm} className={valueClassName}>
        {props.children}
      </Col>
    </Form.Group>
  );
};

FormGroup.defaultProps = {
  as: Row,
  labelColSm: 3,
  valueColSm: 9,
  className: '',
  valueClassName: ''
};

export default FormGroup;

import React from 'react';
import FormGroup from 'components/common/FormGroup/FormGroup';
import i18n from 'i18n';
import { Modal } from 'components/common/Modal/Modal';
import classnames from 'classnames/bind';
import styles from './ThirdPartyTrackingCode.module.scss';
import { Form } from 'react-bootstrap';
import Select from 'components/common/Select/Select';

class InputModal extends React.Component<any> {
  cssClass: any;
  constructor (props) {
    super(props);
    this.cssClass = classnames.bind(styles);
  }

  hide = () => {
    this.props.handleClose();
  }

  submit = () => {
    this.props.handleUpdateCell();
  }

  render () {
    const {
      disabled,
      tmpTrackingType,
      tmpOriginTrackingCode,
      previewTrackingCode,
      changetmpTrackingType,
      changeTmpOriginTrackingCode,
      className: parentClass
    } = this.props;
    const trackingTypeOptions = [{ label: i18n.t('campaign.strategyAndTracking.thirdPartyTrackingTypePixel'), value: 'pixel' },{ label: i18n.t('campaign.strategyAndTracking.thirdPartyTrackingTypeJs'), value: 'js' }];
    const className = this.cssClass(parentClass, ['previewTrackingCode']);
    return (
      <Modal
        title={i18n.t('campaign.strategyAndTracking.thirdPartyTracking')}
        primaryButton={{
          title: i18n.t('common.buttons.submit'),
          callback: this.submit,
          disabled: disabled
        }}
        secondaryButton={{
          title: i18n.t('common.buttons.cancel'),
          callback: this.hide
        }}
        dismiss={this.hide}
      >
        <FormGroup controlId='tmpTrackingType' label={i18n.t('campaign.strategyAndTracking.thirdPartyTrackingType')}>
          <Select
            className={styles.select}
            options={trackingTypeOptions}
            name='tmpTrackingType'
            value={tmpTrackingType}
            onChange={changetmpTrackingType}
            simpleValue
          />
        </FormGroup>
        <FormGroup controlId='tmpOriginTrackingCode' label={i18n.t('campaign.strategyAndTracking.thirdPartyTrackingCode')}>
          <Form.Control
            name='tmpOriginTrackingCode'
            type='text'
            placeholder={tmpTrackingType === 'js' ? 'eg. https://dmp.tenmax.io/js/imax.js' : 'eg. https://dmp.tenmax.io/p'}
            value={tmpOriginTrackingCode}
            onChange={changeTmpOriginTrackingCode}
            as='textarea'
            className={styles.tmpOriginTrackingCode}
          />
        </FormGroup>
        <FormGroup controlId='previewTrackingCode' label={i18n.t('campaign.strategyAndTracking.thirdPartyTrackingPreview')}>
          <Form.Control
            name='previewTrackingCode'
            type='text'
            value={previewTrackingCode}
            disabled={true}
            className={className}
          />
        </FormGroup>
      </Modal>
    );
  }
}

export default InputModal;

import { PMaxCore } from 'core';
import { UpdateEventListener, FireableUpdateEventListener } from 'utils/UpdateEventListener';

type AlertConfig = {
  message: string,
  showAlert: boolean,
  callback?: Function
};

export type AppState = {

  readonly logined: boolean;
  readonly language: string;
  readonly alertConfig?: AlertConfig;
};

export interface AppModel {

  readonly state: AppState;
  readonly event: UpdateEventListener<AppModel>;
  setAlertConfig (alertConfig?: AlertConfig): void;
}

export class DefaultAppModel implements AppModel {
  core: PMaxCore;
  logined: boolean;
  modelEvent: FireableUpdateEventListener<AppModel>;
  alertConfig?: AlertConfig;

  constructor (core: PMaxCore) {
    this.core = core;
    this.logined = this.core.authenticationManager.logined;
    this.core.authenticationManager.event.add((manager) => {
      this.updateState(manager.logined);
    });
    this.core.languageManager.event.add((manager) => {
      this.modelEvent.fireEvent(this);
    });
    this.modelEvent = new FireableUpdateEventListener<AppModel>();
  }

  get state (): AppState {
    return {
      logined: this.logined,
      language: this.core.languageManager.currentLanguage,
      alertConfig: this.alertConfig
    };
  }

  get event (): UpdateEventListener<AppModel> {
    return this.modelEvent;
  }

  setAlertConfig (alertConfig?: AlertConfig): void {
    this.alertConfig = alertConfig;
    this.modelEvent.fireEvent(this);
  }

  updateState (logined: boolean) {
    this.logined = logined;
    this.modelEvent.fireEvent(this);
  }
}

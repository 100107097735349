import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import TableRowToolBar from 'containers/TableRowToolBar/TableRowToolBar';
import React from 'react';
import styles from './creditList.module.scss';
import i18n from 'i18n';
import { Link } from 'react-router-dom';
import { faCreditCard } from '@fortawesome/free-regular-svg-icons';

const nameFormatter = (id: any, { com_name }: { agency_id: number, com_name: string }): any => {
  return (
    <div className={`${styles.nameCell}`}>
      <Link to={`/reports/credits/${id}`} className={styles.name}>
        {com_name}
      </Link>
      <div className={styles.id}>
        {'ID: ' + id}
      </div>
    </div>
  );
};

const floatingEditBtnsFormatter = (_1, agency: { agency_id: number, com_name: string }, _2, formatExtraData: any): any => {
  const onPayBtnClick = () => formatExtraData.onPayBtnClick(agency.agency_id);
  return (
    <TableRowToolBar className={styles.floatingEditArea}>
      <IconWithTooltip
        icon={faCreditCard}
        onClick={onPayBtnClick}
        tooltipProps={{
          id: `pay-${agency.agency_id}`,
          tooltip: i18n.t('creditList.labels.pay')
        }}
      />
    </TableRowToolBar>
  );
};

export default {
  nameFormatter,
  floatingEditBtnsFormatter
};

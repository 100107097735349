import React from 'react';
import i18next from 'i18n';
import styles from './addon.module.scss';
import { InfoDisplaySection } from 'components/InfoDisplaySection/InfoDisplaySection';
import { AddonDependency } from 'core/agency/AddonFeature';
import { CreativeType } from 'core/creative/Creative';
import _ from 'lodash';

type AddonProps = {
  AgencyDetail: {};
};

function addonProps (AgencyDetail, addonGroup, addonItem): any {
  if (addonItem === 'singleOrderBudgetConstraint') {
    return i18next.t('agency.addonItem.company.orderBudgetMaximum') + ' ' + AgencyDetail['property']['addonProps']['orderBudgetMaximum'];
  }
  if (addonItem === 'creditLimit') {
    return i18next.t('agency.addonItem.company.creditLimitMaximum') + ' ' + AgencyDetail['property']['addonProps']['creditLimit'];
  }
  if (addonItem === 'fixedExchangeRate') {
    return i18next.t('agency.addonItem.company.ExchangeRate') + ' ' + AgencyDetail['property']['addonProps']['fixedExchangeRate'];
  }
  if (addonItem === 'systemProfitMonitor') {
    return i18next.t('agency.addonItem.monitor.percentLabel') + ' ' + AgencyDetail['property']['addonProps']['sysProfitMonitorPercent'];
  }
  if (addonItem === 'deliveryAmountConstraint') {
    if (AgencyDetail['addonProps']['bidPriceConstraint'] === 'AUTO') {
      return i18next.t('agency.addonItem.campaign.bidPriceConstraint');
    } else {
      return 'CPC: ' + AgencyDetail['addonProps']['bidPriceCPCMin'] +
        ', CPM: ' + AgencyDetail['addonProps']['bidPriceCPMMin'] +
        ', CPV: ' + AgencyDetail['addonProps']['bidPriceCPVMin'] +
        ', vCPM: ' + AgencyDetail['addonProps']['bidPricevCPMMin'];
    }
  }
  if (addonItem === 'outdoorAdImpresMultiply') {
    return i18next.t('agency.addonItem.report.outdoorImpresMultiple') + ' ' + AgencyDetail['property']['addonProps']['outdoorAdImpresMultiply'];
  }
  if (addonItem === 'outdoorAgency') {
    return i18next.t('agency.addonItem.company.outdoorMachineCount') + ' ' + AgencyDetail['property']['addonProps']['outdoorMachineCount'];
  }
}

function isAddonAllFalse (addonGroup, excludeList) {
  let result = true;
  if (!addonGroup) {
    return result;
  }
  Object.keys(addonGroup).forEach(function (addonItem) {
    if (addonGroup[addonItem] === true &&
      !excludeList.includes(addonItem)
    ) {
      result = false;
    }
  });

  return result;
}

const Addon: React.SFC<AddonProps> = ({
  AgencyDetail
}) => {
  const keylist = ['creatives', 'company', 'campaign', 'campaignLimitation'];
  const excludeList = [
    'bidPriceBaseVal',
    'bidPriceCPCMin',
    'bidPriceCPMMin',
    'bidPriceCPVMin',
    'bidPricevCPMMin',
    'bidPriceConstraint',
    'booking_calendar',
    'campaignDashboard',
    'habits',
    'option_6',
    'orderBudgetMaximum',
    'incentive',
    'landingPage',
    'percentLabel',
    'campaignUnderBidFloor'
  ];

  const addonData = keylist.map((addonGroup) => {
    if (isAddonAllFalse(AgencyDetail['addonFeatures'][addonGroup], excludeList)) {
      return {
        sectionTitle: i18next.t('agency.addonGroup.' + addonGroup),
        sectionData: []
      };
    } else {
      return {
        sectionTitle: i18next.t('agency.addonGroup.' + addonGroup),
        sectionData: _.compact(Object.keys(AgencyDetail['addonFeatures'][addonGroup])
          .filter((addonItem) =>
            AgencyDetail['addonFeatures'][addonGroup][addonItem] === true &&
            !excludeList.includes(addonItem) &&
            (AddonDependency[addonItem] === undefined || AgencyDetail['addonFeatures'][addonGroup][AddonDependency[addonItem]] === true))
          .map(addonItem => {
            let i18nkey = addonItem;
            if (_.startsWith(addonItem, 'option_')) {
              const creativeTypeKey = CreativeType[+(addonItem.replace('option_', ''))];
              if (!creativeTypeKey) {
                return undefined;
              }
              i18nkey = `option_${_.camelCase(creativeTypeKey)}`;
            }
            return {
              label: i18next.t('agency.addonItem.' + addonGroup + '.' + i18nkey),
              value: addonProps(AgencyDetail, addonGroup, addonItem)
            };
          })
        )
      };
    }
  });
  return (
    <div className={styles.container}>
      {
        addonData.map((addonGroup) => {
          return (
            <InfoDisplaySection key={addonGroup.sectionTitle} data={addonGroup.sectionData} title={addonGroup.sectionTitle} />
          );
        })
      }
      {/* <fieldset>
        {
          keylist.map(function (addonGroup, index) {
            if (isAddonAllFalse(AgencyDetail['addonFeatures'][addonGroup], excludeList)) {
              return (
                <div key={index}>
                  <legend><span>{i18next.t('agency.addonGroup.' + addonGroup)}</span></legend>
                  <Form.Group as={Row} controlId={addonGroup} key={addonGroup}>
                    <Form.Label
                      column
                      sm='3'
                    >
                      {i18next.t('common.labels.noData')}
                    </Form.Label>
                  </Form.Group>
                </div>
              );
            } else {
              return (
                <div key={index}>
                  <legend><span>{i18next.t('agency.addonGroup.' + addonGroup)}</span></legend>
                    {
                      Object.keys(AgencyDetail['addonFeatures'][addonGroup]).map((addonItem, key) => {
                        if (
                          AgencyDetail['addonFeatures'][addonGroup][addonItem] === true
                          &&
                          !excludeList.includes(addonItem)
                        ) {
                          return (
                            <Form.Group as={Row} controlId={addonItem} key={key}>
                              <Form.Label
                                column
                                sm='3'
                              >
                                {i18next.t('agency.addonItem.' + addonGroup + '.' + addonItem)}
                              </Form.Label>
                              {addonProps(AgencyDetail, addonGroup, addonItem)}
                            </Form.Group>
                          );
                        } else {
                          return null;
                        }
                      })
                    }
                </div>
              );
            }
          })
        }
      </fieldset> */}
    </div>
  );
};

export default Addon;

import React, { PureComponent, MouseEventHandler } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
type ButtonProps = {
  primary?: boolean;
  secondary?: boolean;
  danger?: boolean;
  large?: boolean;
  onClick?: MouseEventHandler;
  className?: string;
};

export default class Button extends PureComponent<ButtonProps> {
  static defaultProps = {
    primary: false,
    secondary: false,
    danger: false,
    large: false,
    onClick: _.noop
  };
  render () {
    const {
      primary,
      secondary,
      danger,
      large,
      onClick: handleClick,
      className: parentClassName
    } = this.props;
    const className = classNames(parentClassName, {
      btn: true,
      'btn-primary': primary,
      'btn-secondary': secondary,
      'btn-danger': danger,
      'btn-lg': large,
      'btn-sm': !large
    });
    return (
      <button type='button' className={className} onClick={handleClick}>
        {this.props.children}
      </button>
    );
  }
}

import client from './RestClient';
import { Keyword } from 'core/limitation/Keyword';
import { createQueryString } from 'helper/KeywordHelper';
import { SavedTargeting } from 'core/limitation/Limitation';
import _ from 'lodash';

export interface LimitationWebService {
  keywordEstimate (request: Keyword): Promise<number>;
  getAgencyLimitationPreSet (type: string): Promise<Array<any>>;
  categoriesUnionCount (categories: Array<string>, lang: string): Promise<number>;
  getSavedTargetings (advertiserId: number, channel: string): Promise<SavedTargeting[]>;
  createSavedTargeting (savedTargeting: Partial<SavedTargeting>): Promise<SavedTargeting>;
  updateSavedTargeting (savedTargeting: Partial<SavedTargeting>): Promise<SavedTargeting>;
  deleteSavedTargeting (id: number): Promise<void>;
}

export class RestfulLimitationWebService implements LimitationWebService {
  restClient: any;

  constructor (restClient: any = client) {
    this.restClient = restClient;
  }

  async keywordEstimate (request: Keyword): Promise<number> {
    const queryString = createQueryString(
      request.firstQueryParameters,
      request.secondQueryParameters
    );

    try {
      const rest = await this.restClient.post('/v2/keyword/estimate', {
        query: queryString,
        lang: request.language.toUpperCase()
      });
      return rest.data;
    } catch (e) {
      console.error(e);
      return Promise.resolve(0);
    }
  }

  async getAgencyLimitationPreSet (type: string): Promise<Array<Object>> {
    const resp = await this.restClient.get(`v2/default-limitations/${type}`);
    return resp.data.records;
  }

  async categoriesUnionCount (contentCategory: Array<string>, lang: string): Promise<number> {
    try {
      const resp = await this.restClient.post(`/v2/content-category/estimate`, {
        contentCategory,
        lang: lang.toUpperCase()
      });
      return resp.data;
    } catch (e) {
      console.error(e);
      return Promise.resolve(0);
    }
  }

  async getSavedTargetings (advertiserId: number, channel: string): Promise<SavedTargeting[]> {
    const resp = await this.restClient.get(`saved-targeting?advertiser_id=${advertiserId}&channel=${channel}`);
    return resp.data.records;
  }
  async createSavedTargeting (savedTargeting: Partial<SavedTargeting>): Promise<SavedTargeting> {
    return this.restClient.post('saved-targeting', savedTargeting);
  }
  async updateSavedTargeting (savedTargeting: Partial<SavedTargeting>): Promise<SavedTargeting> {
    return this.restClient.put(`saved-targeting/${savedTargeting.id}`, _.omit(savedTargeting, ['id']));
  }
  async deleteSavedTargeting (id: number): Promise<void> {
    return this.restClient.delete(`saved-targeting/${id}`);
  }
}

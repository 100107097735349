import React from 'react';
import styles from './infoDisplaySection.module.scss';
import cx from 'classnames/bind';
import i18n from 'i18n';

interface InfoDisplaySectionProps {
  data: ({ label: string, value: any, extra?: any } & { className?: string })[];
  title?: string;
  columnWidth?: number;
}

export const InfoDisplaySection: React.FC<InfoDisplaySectionProps> = (props) => {

  const classNames = cx.bind(styles);

  const renderData = () => {
    if (props.data.length === 0) {
      return (
        <div className={styles.field}>
          <div className={styles.fieldLabel}>
            {i18n.t('common.labels.noData')}
          </div>
        </div>
      );
    }
    return props.data.map(data => {
      const title = data.label;
      const value = data.value;
      const hint = data.extra;
      const valueClassName = classNames('fieldValue', data.className, {
        hint: !!hint
      });

      return (
        <div className={styles.field} key={data.label}>
          <div className={styles.fieldLabel}>
            {title}
          </div>
          <div className={valueClassName}>
            {value}
          </div>
          <div className={styles.fieldHint}>
            {hint}
          </div>
        </div>
      );
    });
  };

  return (
    <div className={styles.section}>
      {props.title !== undefined &&
        <div className={styles.titleContainer}>
          {props.title !== '' &&
            <span className={styles.title}>{props.title}</span>
          }
          <span className={styles.sepline} />
        </div>
      }
      <div
        className={styles.content}
        style={{
          gridTemplateColumns: `repeat(auto-fill, ${props.columnWidth ? props.columnWidth : 200}px)`
        }}
      >
        {renderData()}
      </div>
    </div>
  );
};

import i18next from 'i18next';

import en from './assets/locales/en.json';
import tw from './assets/locales/zh-tw.json';

import { initReactI18next } from 'react-i18next';
import { isProject } from 'core/permission/PermissionDSL';
import { Project } from 'core/project/Project';

const resources = {
  en: {
    translation: en
  },
  'zh-TW': {
    translation: tw
  }
};

const isGoGAN = isProject(Project.GOGAN).visible({});

i18next
  .use(initReactI18next)
  .init({
    resources,
    lng: isGoGAN ? 'en' : navigator.language || 'zh-TW',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });

export default i18next;

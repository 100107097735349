
export enum RetailerType {
  PCHOME = 'pchome'
}

export type Retail = {
  id: string;
  name: string;
};

export type Product = {
  productId: number | string;
  retailId: string;
  name: string;
  category: string;
  salePrice: number;
  imgLink: string;
};

export type ProductSet = {
  retailId: string;
  advertiser: number;
  agency?: number;
  id: number;
  name: string;
  quantity: number;
  createdTime: string;
  lastUpdatedTime: string;
  products: Product[];
  rule: any;
  update_frequency: number;
};

import React from 'react';
import { components } from 'react-select';
import styles from './adAccountSelect.module.scss';
import cx from 'classnames/bind';

const classNames = cx.bind(styles);

export const AdAccountOption = props => {
  const optionClass = classNames('adAccountOption', {
    disabled: props.isDisabled
  });
  const renderAdAccountOption = () => (
    <div className={optionClass}>
      <div className={styles.adAccountName}>{props.label}</div>
      <div className={styles.adAccountId}>{props.value}</div>
    </div>
  );
  return !props.isDisabled ? (
    <components.Option {...props}>
      {renderAdAccountOption()}
    </components.Option>
  ) :
  renderAdAccountOption();
};

import React, { Fragment } from 'react';
import { useOrderDetailModel } from './OrderDetailModel';
import { RouteComponentProps, Switch, Route, Link } from 'react-router-dom';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { NavDropdown, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { OrderDownloadReportPanel } from 'components/OrderDownloadReportPanel/OrderDownloadReportPanel';
import { DefaultOrderDownloadReportPanelModel } from 'components/OrderDownloadReportPanel/OrderDownloadReportPanelModel';
import { ReportType } from 'core/report/ReportData';
import classNames from 'classnames/bind';
import styles from './orderDetail.module.scss';
import i18n from 'i18n';
import _ from 'lodash';
import RouteWithPermission from 'components/RouteWithPermission/RouteWithPermission';
import { hasFuncs } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { ROUTE_PATH } from 'enum/RoutePath';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { StickableTableContainer } from 'containers/StickableTableContainer/StickableTableContainer';
import { CampaignGroups } from 'containers/CampaignGroups/CampaignGroups';
import { AddonFeatureManager, AuthenticationManager } from 'core';
import { CampaignGroupList } from 'containers/CampaignGroups/CampaignGroupList/CampaignGroupList';
import { TitleWithBreadcrumbs } from 'components/TitleArea/TitleWithBreadcrumbs';
import { DynamicBreadcrumb } from 'components/Breadcrumbs/DynamicBreadcrumbs';

export const OrderDetail: React.FC<RouteComponentProps<{}> & {
  orderNumber: string,
  localMetaDataUpdater: Function,
  authenticationManager: AuthenticationManager,
  addonFeatureManager: AddonFeatureManager
}> = ({
  orderNumber,
  localMetaDataUpdater,
  authenticationManager,
  addonFeatureManager,
  match
}) => {

  const {
    loading,
    order,
    viewData,
    canSettle,
    settleTips,
    showDetail,
    canEditOrder,
    showSettleButton,
    showRejectButton,
    showApproveButton,
    campaignGroupList,
    showGoVideoReportButton,
    showDownloadReportModal,
    showDownloadReportButton,
    showDownloadFinalReport,
    fetchingFinalReport,
    settleOrder,
    rejectOrder,
    approveOrder,
    getReportLink,
    toggleShowDetail,
    setShowDownloadReportModal,
    refreshOrderDetail,
    openFinalReport
  } = useOrderDetailModel(orderNumber, localMetaDataUpdater, authenticationManager, addonFeatureManager);

  const cssClassNames = classNames.bind(styles);

  const renderCampaignGroups = (props) => {
    if (!order) {
      return <LoadingIndicator/>;
    }
    return (
      <CampaignGroups {...props} order={order} addonFeatureManager={addonFeatureManager}/>
    );
  };

  const renderOrderData = (orderViewData, type) => {
    return Object.keys(orderViewData).map(key => {
      const title = i18n.t(`orderDetail.labels.${key}`);
      let value = orderViewData[key];
      let hint = '';
      let valueClassName = styles.fieldValue;
      if (orderViewData[key] && orderViewData[key].change) {
        value = orderViewData[key].change;
        hint = orderViewData[key].origin;
        valueClassName = `${styles.fieldValue} ${styles.changeValue}`;
      } else if (orderViewData[key] && orderViewData[key].extraInfo) {
        const lis = orderViewData[key].extraInfo.map(extraInfo => (
          <li key={extraInfo}>{extraInfo}</li>
        ));
        return (
          <div className={styles.field} key={key}>
            <div className={styles.fieldLabel}>
              {title}
            </div>
            <div className={valueClassName}>
              {orderViewData[key].value}
              {orderViewData[key].extraInfo.length > 0 &&
                <OverlayTrigger
                  key={key}
                  placement={'right'}
                  trigger={['hover']}
                  overlay={
                    <Tooltip id={key + '-orderExtraInfo'}>
                      <ul>{lis}</ul>
                    </Tooltip>
                  }
                >
                  <FontAwesomeIcon icon={faInfoCircle}/>
                </OverlayTrigger>
              }
            </div>
          </div>
        );
      }

      if (type === 'performance') {
        valueClassName = `${styles.fieldValue} ${styles.performanceField}`;
      }

      return (
        <div className={styles.field} key={key}>
          <div className={styles.fieldLabel}>
            {title}
          </div>
          <div className={valueClassName}>
            {value}
          </div>
          <div className={styles.fieldHint}>
            {hint}
          </div>
        </div>
      );
    });
  };

  const renderSection = (data, type) => {
    if (!data) {
      return (
        <></>
      );
    }
    return (
      <Fragment>
        <div className={styles.sepLine} />
        <div className={styles[type]}>
          {renderOrderData(data, type)}
        </div>
      </Fragment>
    );
  };

  const renderCampaignGroupList = (props) => {
    if (!order) {
      return <div/>;
    }
    return (
      <CampaignGroupList
        order={order}
        campaignGroupList={campaignGroupList}
        refreshList={refreshOrderDetail}
        addonFeatureManager={addonFeatureManager}
      />
    );
  };

  const renderReportDropDown = () => {

    const handleNavEvent = (e) => {
      e && e.stopPropagation();
    };

    return (
      <NavDropdown title={i18n.t('orderDetail.labels.report')} id='goReportList' onClick={handleNavEvent}>
        <NavDropdown.Item target='_blank' href={getReportLink(ReportType.PERFORMANCE)}>
          {i18n.t('orderDetail.labels.generalReport')}
        </NavDropdown.Item>
        <NavDropdown.Item target='_blank' href={getReportLink(ReportType.VIDEO)}>
          {i18n.t('orderDetail.labels.videoReport')}
        </NavDropdown.Item>
      </NavDropdown>
    );
  };

  const renderContent = (props) => {
    if (_.isEmpty(viewData)) {
      return <LoadingIndicator />;
    }

    const settleClass = cssClassNames('settleBtn', {
      disable: !canSettle,
      cursorNotAllow: !canSettle
    });
    const orderDetailClass = cssClassNames('detail', {
      show: showDetail
    });
    return (
      <StickableTableContainer>
        <div className={styles.orderDetailContainer}>
          {(loading || fetchingFinalReport) && <LoadingIndicator />}
          {showDownloadReportModal &&
            <OrderDownloadReportPanel
              model={
                new DefaultOrderDownloadReportPanelModel(
                  {
                    name: viewData.name,
                    orderNumber,
                    startDate: viewData.startDate,
                    endDate: viewData.endDate
                  },
                  _.partial(setShowDownloadReportModal, false),
                  addonFeatureManager
                )
              }
            />
          }
          <TitleWithBreadcrumbs
            title={i18n.t('orderDetail.labels.title')}
            routes={[
              { path: '/orders', breadcrumb: i18n.t('orderDetail.labels.title') },
              {
                path: '/orders/:orderNumber',
                breadcrumb: DynamicBreadcrumb,
                props: { label: _.get(order, 'projectName'), matchParam: 'orderNumber' }
              }
            ]}
          />
          <div className={styles.titleBottomLine} />
          <div className={styles.orderDetail}>
            <div className={styles.topArea}>
              <div
                className={styles.orderName}
                onClick={toggleShowDetail}
              >
                <FontAwesomeIcon
                  icon={showDetail ? faAngleDown : faAngleUp}
                />
                <div>
                  {i18n.t('orderDetail.labels.name', { name: viewData.name })}
                </div>
              </div>
              <div className={styles.buttonArea}>
                {showApproveButton &&
                  <PermissionChecker permissionAware={hasFuncs(Permission.ORDER_APPROVE)}>
                    <div className={styles.approveBtn} onClick={approveOrder}>
                      {i18n.t('orderDetail.labels.approve')}
                    </div>
                  </PermissionChecker>
                }
                {showRejectButton &&
                  <PermissionChecker permissionAware={hasFuncs(Permission.ORDER_APPROVE)}>
                    <div className={styles.rejectBtn} onClick={rejectOrder}>
                      {i18n.t('orderDetail.labels.reject')}
                    </div>
                  </PermissionChecker>
                }
                {canEditOrder &&
                  <Link to={`${orderNumber}/edit`}>{i18n.t('orderDetail.labels.editOrder')}</Link>
                }
                {showSettleButton &&
                  <PermissionChecker permissionAware={hasFuncs(Permission.ORDER_WRITE)}>
                    <div onClick={settleOrder} className={settleClass}>
                      {i18n.t('orderDetail.buttons.settle')}
                      <span>{settleTips}</span>
                    </div>
                  </PermissionChecker>
                }
                {showGoVideoReportButton ?
                  renderReportDropDown() :
                  <Link
                    to={getReportLink(ReportType.PERFORMANCE)}
                    target='_blank'
                  >
                    {i18n.t('orderDetail.labels.report')}
                  </Link>
                }
                {showDownloadReportButton && <div onClick={_.partial(setShowDownloadReportModal, true)}>{i18n.t('orderDetail.labels.downloadReport')}</div>}
                {showDownloadFinalReport && <div onClick={openFinalReport}>{i18n.t('orderDetails.buttons.downloadFinalReport')}</div>}
              </div>
            </div>
            <div className={orderDetailClass}>
              {renderSection(viewData.basic, 'basic')}
              {renderSection(viewData.performance, 'performance')}
              {renderSection(viewData.outdoor, 'outdoor')}
              {renderSection(viewData.finalReport, 'finalReport')}
            </div>
          </div>
          <div className={styles.other}>
            {renderCampaignGroupList(props)}
          </div>
        </div>
      </StickableTableContainer>
    );
  };

  if (!viewData) {
    return <LoadingIndicator />;
  }

  return (
    <Switch>
      <RouteWithPermission
        permissionAware={hasFuncs(Permission.CAMPAIGN_READ).or(hasFuncs(Permission.CAMPAIGN_WRITE))}
        redirectPath={`${match.url}/campaign-groups/${ROUTE_PATH.ERROR403}`}
        path={`${match.url}/campaign-groups`}
        render={renderCampaignGroups}
      />
      <Route
        render={renderContent}
      />
    </Switch>
  );
};

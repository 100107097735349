import { Agency, AgencyManager } from 'core';
import { UpdateEventListener, FireableUpdateEventListener } from 'utils/UpdateEventListener';
import { SearchBarModel } from 'components/common/SearchBar';

export type AgenciesHomeState = {

  readonly loading: boolean;
  readonly error: Error | null;
  readonly agencies: Array<Agency>;
};

export interface AgenciesHomeModel extends SearchBarModel {

  readonly state: AgenciesHomeState;
  readonly event: UpdateEventListener<AgenciesHomeModel>;

  fetch (): Promise<void>;
}

export type AgenciesHomeProps = {

  readonly model: AgenciesHomeModel;
};

export class DefaultAgenciesHomeModel implements AgenciesHomeModel {
  loading: boolean;
  error: Error | null;
  agencies: Array<Agency>;
  filtered: Array<Agency>;
  agencyManager: AgencyManager;
  event: FireableUpdateEventListener<AgenciesHomeModel>;

  constructor (agencyManager: AgencyManager) {
    this.loading = false;
    this.error = null;
    this.agencies = [];
    this.filtered = [];
    this.agencyManager = agencyManager;
    this.event = new FireableUpdateEventListener<AgenciesHomeModel>();
  }

  get placeholder (): string {
    return 'agencies.home.searchPlaceholder';
  }

  get state (): AgenciesHomeState {
    return {
      error: this.error,
      loading: this.loading,
      agencies: this.filtered
    };
  }

  async fetch (): Promise<void> {
    this.notify(true);
    try {
      this.agencies = await this.agencyManager.getAgencies();
      this.filtered = this.agencies;
      this.notify(false);
    } catch (error) {
      this.notify(false, error);
    }
  }

  search (keyword: string) {
    this.filtered = this.agencies.filter((agency) => agency.name.toLowerCase().includes(keyword.toLowerCase()));
    this.event.fireEvent(this);
  }

  notify (loading: boolean, error: Error | null = null) {
    this.error = error;
    this.loading = loading;
    this.event.fireEvent(this);
  }
}

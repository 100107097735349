import { useState, useEffect, useCallback } from 'react';
import { SelectOptions } from 'components/common/commonType';
import { createSelectOptions } from 'utils/SelectOptionsUtils';
import i18n from 'i18n';
import _ from 'lodash';

import { Product, Retail, RetailerType } from 'core/product/Product';
import { ProductFilter } from './ProductFilter';
import { RdpManager, DefaultRdpManager } from 'core/rdp/RdpManager';

import { useCallAPI } from 'hooks/useCallAPI';

const defaultRdpManager: RdpManager = new DefaultRdpManager();

export const useProductCategoryModel = () => {

  const [searchString, setSearchString] = useState<string>('');
  const [filters, setFilters] = useState<ProductFilter>({});
  const [productsData, setProductsData] = useState<Product[]>([]);
  const [retailerTypeOptions, setRetailerTypeOptions] = useState<(SelectOptions & { name: string })[]>([]);
  const [showProductSetCreateModal, setShowProductSetCreateModal] = useState<boolean>(false);
  const [cache, setCache] = useState<{[key: string]: any}>({});

  const {
    loading,
    callAPIs
  } = useCallAPI();

  const priceMarginOptions = createSelectOptions([
    'unlimited',
    '0~10000',
    '10000~30000',
    '30000~50000',
    '50000~70000',
    '70000~90000'
  ], 'productCategory.filters.options.');
  const productCategoryOptions = createSelectOptions([
    'unlimited',
    'PChome > 線上購物 > 24h 購物 >　APPLE',
    'PChome > 線上購物 > 24h 購物 > dyson',
    'PChome > 線上購物 > 24h 購物 > GoPro 旗艦館',
    'PChome > 線上購物 > 24h 購物 > Nintendo'
  ], 'productCategory.filters.options.');
  const defaultOrderOptions = createSelectOptions([
    'unlimited',
    '過去一天購買',
    '過去三天購買',
    '過去七天購買'
  ], 'productCategory.filters.options.');
  // const arrivalsOptions = createSelectOptions([
  //   ...Array.from({ length: 7 }, (_, index) => `${index + 1} days`)
  // ]);
  // const topNOptions = createSelectOptions([
  //   '3', '5', '10', '20', '50', '100'
  // ]);

  const setInitRetailer = useCallback((initRetailer, cache) => {
    const cacheKey = `${initRetailer[0].name}_${initRetailer[0].value}`;
    if (cache[cacheKey]) {
      return;
    }
    setFilters(filter => ({
      retailerId: _.get(filter, 'retailerId', initRetailer[0].value.toString())
    }));
    setCache({
      [cacheKey]: initRetailer
    });
  },[]);

  useEffect(() => {
    if (!_.isEmpty(retailerTypeOptions)) {
      const initRetailer = retailerTypeOptions.filter(retailerTypeOption => retailerTypeOption.name.toLowerCase() === RetailerType.PCHOME.toLowerCase());
      setInitRetailer(initRetailer, cache);
    }
  }, [setInitRetailer, retailerTypeOptions, cache]);

  const fetchRetailers = useCallback(async () => {
    callAPIs(
      [defaultRdpManager.getRetails.bind(defaultRdpManager)],
      (retailers: Retail[]) => {
        setRetailerTypeOptions([
          // ...createSelectOptions(['unlimited'], 'productCategory.filters.options.'),
          ...retailers.map(retailer => ({
            label: i18n.t(`productCategory.filters.enums.${retailer.name.toLowerCase()}`),
            value: retailer.id,
            name: retailer.name
          }))
        ]);
      }
    );
  }, [callAPIs]);

  useEffect(() => {
    fetchRetailers();
  }, [fetchRetailers]);

  const fetchProductsData = useCallback(async () => {
    if (!_.isEmpty(filters)) {
      const retailId = _.get(filters, 'retailerId', '');
      const queries = handleSearchQueries(filters);
      // console.log('queries', queries);

      if (retailId === 'unlimited') {
        callAPIs(
          [defaultRdpManager.getAllProducts.bind(defaultRdpManager, queries)],
          (products: Product[]) => {
            // console.log('fetched all products', products);
            setProductsData(products);
          }
        );
      } else {
        callAPIs(
          [defaultRdpManager.getRetailProducts.bind(defaultRdpManager, retailId, queries)],
          (products: Product[]) => {
            // console.log(`fetched retail ${retailId} products`, products);
            setProductsData(products);
          }
        );
      }
    }
  }, [filters, callAPIs]);

  useEffect(() => {
    fetchProductsData();
  }, [fetchProductsData]);

  const editActions = (): {
    label: string,
    onClick: (selectedProducts: (number | string)[]) => void | Promise<void>
  }[] => {

    return [{
      label: 'productList.labels.addToProductSet',
      onClick: (selectedProducts: (string | number)[]) => {
        setShowProductSetCreateModal(true);
      }
    }];
  };

  const handleSearchQueries: any = (filters: ProductFilter) => {
    const priceMargin = filters.priceMargin ? filters.priceMargin : '';
    const minPrice = _.parseInt(priceMargin.split('~')[0]);
    const maxPrice = _.parseInt(priceMargin.split('~')[1]);

    return _.omitBy({
      minPrice: !_.isNaN(minPrice) ? minPrice : null,
      maxPrice: !_.isNaN(maxPrice) ? maxPrice : null
    }, _.isNil);
  };

  const handleCloseModal = (): void => {
    setShowProductSetCreateModal(false);
  };

  const handleSearchString = (searchString: string): void => {
    setSearchString(searchString);
  };

  const handleSubmit = () => {

  };

  const validate = async (productFilter: ProductFilter) => {
    setFilters(productFilter);
  };

  return {
    loading,
    searchString,
    filters,
    productsData,
    retailerTypeOptions,
    priceMarginOptions,
    productCategoryOptions,
    defaultOrderOptions,
    showProductSetCreateModal,
    editActions,
    handleSearchQueries,
    handleCloseModal,
    handleSearchString,
    handleSubmit,
    validate
  };
};

import _ from 'lodash';

export function toDecimal (num: number) {
  return _.divide(num, 100);
}

export function multiply100 (num: number) {
  return _.multiply(num, 100);
}

export function randomHex (): string {
  const letters = '0123456789ABCDEF';
  let result = '';
  for (let i = 0; i < 6; i++) result += letters[Math.floor(Math.random() * 16)];
  return result;
}

export function getNumberAccurateToDecimalPlace (targetNumber, decimalPlace) {
  const multiple = Math.pow(10, decimalPlace);
  return Math.ceil(targetNumber * multiple) / multiple;
}

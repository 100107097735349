import { Actor, PermissionAware, PermissionContext } from 'core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { UpdateEventListener, FireableUpdateEventListener } from 'utils/UpdateEventListener';

export interface AppMenuItemModel extends PermissionAware {

  readonly path: string;
  readonly title: string;
}

export type AppMenuItemProps = {

  readonly model: AppMenuItemModel;
};

export type AppMenuState = {

  readonly hovered: boolean;
  readonly expanded: boolean;
};

export interface AppMenuModel extends PermissionAware {

  readonly title: string;
  readonly minimized: boolean;
  readonly icon: IconProp | null;
  readonly items: Array<AppMenuItemModel>;

  readonly state: AppMenuState;
  readonly event: UpdateEventListener<AppMenuModel>;

  mouseEnter (): void;
  mouseLeave (): void;
  expandOrCollapse (): void;
  markAsMinimized (minimized: boolean): void;
  updatePermissionContext (actor: Actor | null, agencyAddon: string[] | null): void;
}

export type AppMenuProps = {

  readonly model: AppMenuModel;
};

export type AppMenuBarState = {

  readonly expanded: boolean;
};

export interface Toggleable {

  expandOrCollapse (): void;
}

export interface AppMenuBarModel extends Toggleable {

  readonly state: AppMenuBarState;
  readonly event: UpdateEventListener<AppMenuBarModel>;
  readonly expandChangeEvent: UpdateEventListener<boolean>;

  readonly menus: Array<AppMenuModel>;

  expandOrCollapse (): void;
  updatePermissionContext (actor: Actor | null, agencyAddon: string[] | null): void;
}

export type AppMenuBarProps = {

  readonly model: AppMenuBarModel;
};

export class DefaultAppMenuItemModel implements AppMenuItemModel {
  itemPath: string;
  itemTitle: string;
  permission: PermissionAware | null;

  constructor (title: string, path: string, permission: PermissionAware | null = null) {
    this.itemPath = path;
    this.itemTitle = title;
    this.permission = permission;
  }

  get title () {
    return this.itemTitle;
  }

  get path () {
    return this.itemPath;
  }

  visible (context: PermissionContext): boolean {
    return this.permission !== null ? this.permission.visible(context) : true;
  }
}

export class DefaultAppMenuModel implements AppMenuModel {
  menuHovered: boolean;
  menuExpanded: boolean;
  menuMinimized: boolean;

  menuTitle: string;
  menuIcon: IconProp | null;
  menuItems: Array<AppMenuItemModel>;

  actor: Actor | null;
  agencyAddon: string[] | null;
  modelEvent: FireableUpdateEventListener<AppMenuModel>;

  constructor (title: string, items: Array<AppMenuItemModel>, icon: IconProp | null = null) {
    this.actor = null;
    this.agencyAddon = null;
    this.menuIcon = icon;
    this.menuTitle = title;
    this.menuItems = items;
    this.menuHovered = false;
    this.menuExpanded = true;
    this.menuMinimized = false;
    this.modelEvent = new FireableUpdateEventListener<AppMenuModel>();
  }

  get state (): AppMenuState {
    return {
      hovered: this.menuHovered,
      expanded: this.menuExpanded
    };
  }

  get title (): string {
    return this.menuTitle;
  }

  get icon (): IconProp | null {
    return this.menuIcon;
  }

  get minimized (): boolean {
    return this.menuMinimized;
  }

  get items (): Array<AppMenuItemModel> {
    const visibleItems = this.menuItems.filter((item) => item.visible(this.permissionContext));
    return [...visibleItems];
  }

  get event (): UpdateEventListener<AppMenuModel> {
    return this.modelEvent;
  }

  get permissionContext (): PermissionContext {
    return {
      actor: this.actor,
      agencyAddon: this.agencyAddon
    };
  }

  visible (context: PermissionContext): boolean {
    return this.menuItems.reduce<boolean>((partial, item) => { return partial || item.visible(context); }, false);
  }

  updatePermissionContext (actor: Actor | null, agencyAddon: string[] | null) {
    this.actor = actor;
    this.agencyAddon = agencyAddon;
  }

  markAsMinimized (minimized: boolean) {
    if (this.menuMinimized === minimized) {
      // avoid notify too many times, but no changes
      return;
    }
    this.menuMinimized = minimized;
    this.notify();
  }

  mouseEnter () {
    if (this.menuMinimized && !this.menuHovered) {
      this.menuHovered = true;
      this.notify();
    }
  }

  mouseLeave () {
    if (this.menuMinimized && this.menuHovered) {
      this.menuHovered = false;
      this.notify();
    }
  }

  expandOrCollapse () {
    this.menuExpanded = !this.menuExpanded;
    this.notify();
  }

  notify () {
    this.modelEvent.fireEvent(this);
  }
}

export class DefaultAppMenuBarModel implements AppMenuBarModel {
  barExpanded: boolean;
  barMenus: Array<AppMenuModel>;

  actor: Actor | null;
  agencyAddon: string[] | null;
  modelEvent: FireableUpdateEventListener<AppMenuBarModel>;
  expandChangeEvent: FireableUpdateEventListener<boolean>;

  constructor (menus: Array<AppMenuModel>) {
    this.actor = null;
    this.agencyAddon = null;
    this.barMenus = menus;
    this.barExpanded = true;
    this.modelEvent = new FireableUpdateEventListener<AppMenuBarModel>();
    this.expandChangeEvent = new FireableUpdateEventListener<boolean>();
  }

  get menus (): Array<AppMenuModel> {
    const visibleMenus = this.barMenus.filter((menu) => menu.visible(this.permissionContext));
    return [...visibleMenus];
  }

  get state (): AppMenuBarState {
    return {
      expanded: this.barExpanded
    };
  }

  get event (): UpdateEventListener<AppMenuBarModel> {
    return this.modelEvent;
  }

  get permissionContext (): PermissionContext {
    return {
      actor: this.actor,
      agencyAddon: this.agencyAddon
    };
  }

  updatePermissionContext (actor: Actor | null, agencyAddon: string[] | null) {
    this.actor = actor;
    this.agencyAddon = agencyAddon;
    this.barMenus.forEach((menu) => {
      menu.updatePermissionContext(actor, agencyAddon);
      menu.markAsMinimized(!this.barExpanded);
    });
    this.notify();
  }

  expandOrCollapse () {
    this.barExpanded = !this.barExpanded;
    this.menus.forEach((menu) => menu.markAsMinimized(!this.barExpanded));
    this.expandChangeEvent.fireEvent(this.barExpanded);
    this.notify();
  }

  notify () {
    this.modelEvent.fireEvent(this);
  }
}

import React from 'react';

import './LoginPage.scss';

import _ from 'lodash';
import i18n from 'i18n';

import { alertError, alertMessage } from 'components/AlertDialog';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { AppHeaderModel, EmptyAppHeaderModel } from 'components/AppHeader';

import { LoginPageProps, LoginPageState } from './LoginPageModel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { validateEmpty, validateEmail } from 'utils/ValidateUtils';
import loginLogo from 'assets/tenmax-logo.png';
import loginLogo2x from 'assets/tenmax-logo@2x.png';
import classnames from 'classnames';
import { LoginBackground } from 'containers/LoginBackground/LoginBackground';
import { Link } from 'react-router-dom';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import config from 'config';

export class LoginPage extends React.Component<LoginPageProps, LoginPageState> {

  emailRef: any;
  passwordRef: any;

  handler: number;
  headerModel: AppHeaderModel;

  constructor (props: any) {
    super(props);
    this.handler = 0;
    this.emailRef = React.createRef();
    this.passwordRef = React.createRef();
    this.headerModel = new EmptyAppHeaderModel();
    this.state = this.props.model.state;
    this.handler = this.props.model.event.add((model) => {
      this.setState(model.state);
    });
  }

  componentWillUnmount () {
    this.props.model.event.remove(this.handler);
  }

  login = (event) => {
    event.preventDefault();
    const email = this.emailRef.current.value;
    const password = this.passwordRef.current.value;
    const emailError = validateEmail(email);
    const passwordError = validateEmpty(password);
    emailError && this.props.model.setFormError('email', emailError);
    passwordError && this.props.model.setFormError('password', passwordError);
    if (!emailError && !passwordError) {
      this.props.model.login(email, password);
    }
  }

  dismiss = () => {
    this.props.model.dismiss();
  }

  onEmailChange = (event) => {
    const error = validateEmail(event.target.value);
    this.props.model.setFormError('email', error);
  }

  onPasswordChange = (event) => {
    const error = validateEmpty(event.target.value);
    this.props.model.setFormError('password', error);
  }

  triggerPasswordVisibility = () => {
    this.props.model.triggerPasswordVisibility();
  }

  render () {
    const indicator = this.state.loading ? <LoadingIndicator /> : null;
    const loginLabel: string = _.defaultTo(i18n.t('login.form.buttons.login'), 'Login');
    const emailError = this.state.formError['email'];
    const passwordError = this.state.formError['password'];
    const emailInputGroupClass = classnames('input-group', {
      'with-error': !!emailError
    });
    const passwordInputGroupClass = classnames('input-group', {
      'with-error': !!passwordError
    });
    return (
      <LoginBackground>
        <div className='loginForm'>
          {indicator}
          {this.props.model.state.alertMessage &&
            alertMessage(
              i18n.t('common.warning'),
              this.props.model.state.alertMessage,
              () => {
                this.props.model.setAlertMessage(undefined);
              }
            )
          }
          <form onSubmit={this.login}>
            <div className='iconGroup'>
              <div>
                <img
                  className='form-title'
                  src={loginLogo}
                  srcSet={loginLogo2x}
                  alt='tenmax icon'
                />
              </div>
              {config.isGojekTheme &&
                <>
                  <div className='subtitle'>Gojek Ads Network</div>
                  <div className='subtitle'>Agency Portal</div>
                </>
              }
              </div>
            <div className={emailInputGroupClass}>
              <label>{i18n.t('loginPage.labels.emailTitle')}</label>
              <input
                type='email'
                ref={this.emailRef}
                placeholder={i18n.t('login.form.placeholders.email')}
                onChange={this.onEmailChange}
              />
              {!!emailError && <div className='input-error'>{emailError}</div>}
            </div>
            <div className={passwordInputGroupClass}>
              <label>{i18n.t('loginPage.labels.passwordTitle')}</label>
              <input
                className='password-input'
                type={this.state.passwordVisibility ? 'text' : 'password'}
                ref={this.passwordRef}
                placeholder={i18n.t('login.form.placeholders.password')}
                onChange={this.onPasswordChange}
              />
              <FontAwesomeIcon icon={faEye} onClick={this.triggerPasswordVisibility} />
              {!!passwordError && <div className='input-error'>{passwordError}</div>}
            </div>
            <button
              type='submit'
              className='btn btn-primary'
            >
              {loginLabel}
            </button>
            <Link className='underscore-text' to='/forgot-password'>
              {i18n.t('loginPage.labels.forgotPassword')}
            </Link>
            {this.props.model.fbLogin &&
              <div className='fbLoginButton' onClick={this.props.model.fbLogin}>
                <FontAwesomeIcon
                  icon={faFacebook}
                />
                <span>Login with Facebook</span>
              </div>
            }
          </form>
          {this.state.error && alertError(this.state.error, this.dismiss)}
        </div>
      </LoginBackground>
    );
  }
}

import React from 'react';
import _ from 'lodash';
import styles from './nativeBannerPreview.module.scss';
import cx from 'classnames/bind';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { NativeBannerPreviewProps, NativeBannerPreviewState } from './NativeBannerPreviewModel';
import { NativeBanner } from './NativeBanner';

export class NativeBannerPreview extends React.Component<NativeBannerPreviewProps, NativeBannerPreviewState> {

  classNames = cx.bind(styles);
  handler?: number;
  state = this.props.model.state;

  componentDidMount () {
    this.handler = this.props.model.event.add(model => {
      this.setState(model.state);
    });
  }

  componentDidUpdate (prevProps: NativeBannerPreviewProps) {
    if (prevProps.model !== this.props.model) {
      this.handler && prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add(model => {
        this.setState(model.state);
      });
    }
  }

  componentWillUnmount () {
    this.handler && this.props.model.event.remove(this.handler);
  }

  renderBanners () {
    const model = this.props.model;
    return Object.keys(model.htmlTemplates).map(size => {
      const htmlContent = _.get(model.htmlTemplates[size], 'htmlContent', '');
      const previewComponentClassName = this.classNames('previewComponent', {
        active: size === this.state.bannerSize
      });
      return (
        <div key={size} className={previewComponentClassName}>
          <NativeBanner
            typeProperties={model.creative.typeProperties ? model.creative.typeProperties : {}}
            medias={model.creative.medias ? model.creative.medias : {}}
            bannerUrl={model.creative.bannerUrl}
            htmlContent={htmlContent}
            size={size}
          />
        </div>
      );
    });
  }

  renderButtons () {
    const onButtonClick = (bannerSize) => {
      this.setState({ bannerSize });
    };
    return Object.keys(this.props.model.htmlTemplates).map(size => {
      const buttonClassName = this.classNames('button', {
        active: size === this.state.bannerSize
      });
      return (
        <span
          key={size}
          className={buttonClassName}
          onClick={_.partial(onButtonClick, size)}
        >
          {size}
        </span>
      );
    });
  }

  render () {
    return (
      <div className={styles.nativeBannerPreview}>
        {this.state.loading && <LoadingIndicator />}
        <div className={styles.previewArea}>
          {this.renderBanners()}
        </div>
        <div className={styles.buttonArea}>
          {this.renderButtons()}
        </div>
      </div>
    );
  }
}

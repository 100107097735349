import React from 'react';
import { Switch, Route } from 'react-router-dom';
import RouteWithPermission from 'components/RouteWithPermission/RouteWithPermission';
import { hasFuncs, hasRoles, notRoles } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';
import { ROUTE_PATH } from 'enum/RoutePath';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { StickableTableContainer } from 'containers/StickableTableContainer/StickableTableContainer';
import { RecentGroupDropdown } from 'components/RecentGroupDropdown/RecentGroupDropdown';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { RoleNames } from 'core';
import styles from './goSegments.module.scss';
import i18n from 'i18n';
import { SessionStorageItemKeys } from 'helper/StorageHelper';
import { GoSegmentList } from './GoSegmentList/GoSegmentList';
import { GoSegmentSetupFlowPage } from 'containers/GoSegments/GoSegmentSetupFlow/GoSegmentSetupFlowPage';
import { useCreateGoSegmentSetupFlowPageModel, useEditGoSegmentSetupFlowPageModel } from 'containers/GoSegments/GoSegmentSetupFlow/GoSegmentSetupFlowPageModel';

export const GoSegments: React.FC<any> = ({
  useModel
}) => {

  const {
    loading,
    agencyId,
    agencies,
    advertiserId,
    advertisers,
    setAgencyId,
    setAdvertiserId
  } = useModel();

  const renderCreateSegmentPage = () => {
    return (
      <GoSegmentSetupFlowPage advertisers={advertisers} useModel={useCreateGoSegmentSetupFlowPageModel}/>
    );
  };

  const renderEditSegmentPage = () => {
    return (
      <GoSegmentSetupFlowPage advertisers={advertisers} useModel={useEditGoSegmentSetupFlowPageModel}/>
    );
  };

  const renderSysAdminGoSegmentList = () => (
    <GoSegmentList
      isAdmin={true}
      agencyId={agencyId}
      advertiserId={advertiserId}
      canCreateSegment={advertisers.length > 0}
    />
  );

  const renderHomePage = (props) => {
    return (
      <StickableTableContainer>
        <div className={styles.container}>
          <div className={styles.titleArea}>
            {i18n.t('segmentHome.labels.title')}
            <div className={styles.filterArea}>
              <PermissionChecker permissionAware={hasRoles(RoleNames.sysAdmin)}>
                <div className={styles.agencyList}>
                  <div className={styles.title}>
                    {i18n.t('segmentHome.labels.agencyListTitle')}
                  </div>
                  <RecentGroupDropdown
                    value={agencyId}
                    options={agencies}
                    storageKey={SessionStorageItemKeys.AGENCY}
                    callback={setAgencyId}
                    showShowAllBtn={false}
                  />
                </div>
              </PermissionChecker>
              <PermissionChecker
                permissionAware={notRoles(RoleNames.adsAdmin, RoleNames.adsReport, RoleNames.adsSales)}
              >
                <div className={styles.advertiserList}>
                  <div className={styles.title}>
                    {i18n.t('segmentHome.labels.advertiserListTitle')}
                  </div>
                  <RecentGroupDropdown
                    value={advertiserId}
                    options={advertisers}
                    storageKey={SessionStorageItemKeys.ADVERTISER}
                    callback={setAdvertiserId}
                  />
                </div>
              </PermissionChecker>
            </div>
          </div>
          <div className={styles.listArea}>
            <PermissionChecker
              permissionAware={notRoles(RoleNames.sysAdmin)}
              renderOtherwise={renderSysAdminGoSegmentList}
            >
              <GoSegmentList
                isAdmin={false}
                agencyId={agencyId}
                advertiserId={advertiserId}
                canCreateSegment={advertisers.length > 0}
              />
            </PermissionChecker>
          </div>
        </div>
      </StickableTableContainer>
    );
  };

  return (
    <>
    {loading && <LoadingIndicator/>}
    <Switch>
      <RouteWithPermission
        permissionAware={hasFuncs(Permission.SEGMENT_WRITE)}
        redirectPath={`/segments/new/${ROUTE_PATH.ERROR403}`}
        path={'/segments/new'}
        render={renderCreateSegmentPage}
      />
      <Route
        exact
        path={'/segments/:segmentId/edit'}
        render={renderEditSegmentPage}
      />
      <Route
        render={renderHomePage}
      />
    </Switch>
    </>
  );
};

import { commaDecorator, percentDecorator, fractionDecorator, totalCostDecorator, divideValue } from './ReportDataHelper';
import { getReportTableFormatters } from './ReportTableFormatters';
import { ReportDimension } from 'core/report/ReportData';
import { renderColumn } from 'components/TableColumn';
import styles from './reportTable.module.scss';
import i18n from 'i18n';
import _ from 'lodash';
import { getPriceValue } from 'helper/CurrencyHelper';

export enum REPORT_COLUMNS {
  NAME = 'name',
  IMPRES = 'impres',
  CLICKS = 'clicks',
  CTR = 'ctr',
  VIEW = 'adView',
  VIEW_RATE = 'viewRate',
  VIEWABLE = 'viewable',
  VIEWABLE_VIEW_RATE = 'viewableViewRate',
  CPV = 'cpv',
  SKIP_COUNT = 'skipCount',
  FIRST_QUARTILE= 'firstQuartile',
  MID_POINT = 'midpoint',
  THIRD_QUARTILE = 'thirdQuartile',
  COMPLETE = 'complete',
  VCTR = 'vctr',
  CPM = 'cpm',
  CPC = 'cpc',
  CPA = 'cpa',
  CONVS = 'convs',
  CONV_1 = 'conv_1',
  CONV_7 = 'conv_7',
  CVR = 'cvr',
  UU = 'uu',
  MEDIA_COST = 'mediaCost',
  MEDIA_SPENT = 'mediaSpent',
  AGENCY_PROFIT = 'agcProfit',
  SYS_PROFIT = 'sysProfit',
  DATA_COST = 'dataCost',
  SPENT = 'spent',
  TAGS = 'tags',
  EDITBTNS = 'editBtns'
}

export const tableColumnSettings = (tableData: any, dimension: ReportDimension, currency: string, onDimensionNameClick, onDateClick) => {
  const getCostSumFooterValue = (column: REPORT_COLUMNS) => {
    const columnData = tableData.map(data => _.get(data, column, 0));
    return columnData.reduce((acc, item) => acc + getPriceValue(currency, item), 0);
  };

  const getSumFooterValue = (column: REPORT_COLUMNS) => {
    const columnData = tableData.map(data => _.get(data, column, 0));
    return columnData.reduce((acc, item) => acc + item, 0);
  };

  const getFractionFooterValue = (numeratorColumn: REPORT_COLUMNS, denominatorColumn: REPORT_COLUMNS, multiply: number) => {
    const numeratorData = tableData.map(data => _.get(data, numeratorColumn, 0));
    const denominatorData = tableData.map(data => _.get(data, denominatorColumn, 0));
    const sumOfNumerator = numeratorData.reduce((acc, item) => acc + item, 0);
    const sumOfDenominator = denominatorData.reduce((acc, item) => acc + item, 0);
    if (sumOfDenominator === 0) {
      return 0;
    }
    return divideValue(sumOfNumerator * multiply, sumOfDenominator);
  };
  const getColumnSetting = (column: REPORT_COLUMNS, formatter, footer, textDecorator, footerDecorator?: any, currency?: any) => {
    let headerName: any = column;
    if (column === REPORT_COLUMNS.NAME &&
      (dimension === ReportDimension.MONTH || dimension === ReportDimension.DAY || dimension === ReportDimension.HOUR)
    ) {
      headerName = 'date';
    }
    const columnClassGetter = () => {
      return `${styles.reportCell} ${styles[column]}`;
    };
    return renderColumn({
      sort: true,
      text: `reportTable.headers.${headerName}`,
      dataField: column,
      footer: footerDecorator ? footerDecorator(footer) : textDecorator(footer),
      headerExtraData: {
        currency
      },
      formatExtraData: {
        dimension,
        textDecorator
      },
      classes: columnClassGetter,
      headerClasses: columnClassGetter,
      footerClasses: columnClassGetter
    }, formatter);
  };
  const formatters = getReportTableFormatters(onDimensionNameClick, onDateClick);
  const actualCostDecorater = _.partial(totalCostDecorator, currency);
  return {
    [REPORT_COLUMNS.NAME]: getColumnSetting(
      REPORT_COLUMNS.NAME,
      formatters.nameFormatter,
      i18n.t('reportTable.footers.total'),
      _.identity
    ),
    [REPORT_COLUMNS.IMPRES]: getColumnSetting(
      REPORT_COLUMNS.IMPRES,
      formatters.textFormatter,
      getSumFooterValue(REPORT_COLUMNS.IMPRES),
      commaDecorator
    ),
    [REPORT_COLUMNS.VIEW]: getColumnSetting(
      REPORT_COLUMNS.VIEW,
      formatters.textFormatter,
      getSumFooterValue(REPORT_COLUMNS.VIEW),
      commaDecorator
    ),
    [REPORT_COLUMNS.VIEW_RATE]: getColumnSetting(
      REPORT_COLUMNS.VIEW_RATE,
      formatters.textFormatter,
      getFractionFooterValue(REPORT_COLUMNS.VIEW, REPORT_COLUMNS.IMPRES, 100),
      percentDecorator
    ),
    [REPORT_COLUMNS.VIEWABLE_VIEW_RATE]: getColumnSetting(
      REPORT_COLUMNS.VIEWABLE_VIEW_RATE,
      formatters.textFormatter,
      getFractionFooterValue(REPORT_COLUMNS.VIEW, REPORT_COLUMNS.VIEWABLE, 100),
      percentDecorator
    ),
    [REPORT_COLUMNS.CPV]: getColumnSetting(
      REPORT_COLUMNS.CPV,
      formatters.textFormatter,
      getFractionFooterValue(REPORT_COLUMNS.SPENT, REPORT_COLUMNS.VIEW, 1),
      fractionDecorator
    ),
    [REPORT_COLUMNS.CLICKS]: getColumnSetting(
      REPORT_COLUMNS.CLICKS,
      formatters.textFormatter,
      getSumFooterValue(REPORT_COLUMNS.CLICKS),
      commaDecorator
    ),
    [REPORT_COLUMNS.CTR]: getColumnSetting(
      REPORT_COLUMNS.CTR,
      formatters.textFormatter,
      getFractionFooterValue(REPORT_COLUMNS.CLICKS, REPORT_COLUMNS.IMPRES, 100),
      percentDecorator
    ),
    [REPORT_COLUMNS.VIEWABLE]: getColumnSetting(
      REPORT_COLUMNS.VIEWABLE,
      formatters.textFormatter,
      getSumFooterValue(REPORT_COLUMNS.VIEWABLE),
      commaDecorator
    ),
    [REPORT_COLUMNS.VCTR]: getColumnSetting(
      REPORT_COLUMNS.VCTR,
      formatters.textFormatter,
      getFractionFooterValue(REPORT_COLUMNS.CLICKS, REPORT_COLUMNS.VIEWABLE, 100),
      percentDecorator
    ),
    [REPORT_COLUMNS.CPM]: getColumnSetting(
      REPORT_COLUMNS.CPM,
      formatters.textFormatter,
      getFractionFooterValue(REPORT_COLUMNS.SPENT, REPORT_COLUMNS.IMPRES, 1000),
      fractionDecorator
    ),
    [REPORT_COLUMNS.CPC]: getColumnSetting(
      REPORT_COLUMNS.CPC,
      formatters.textFormatter,
      getFractionFooterValue(REPORT_COLUMNS.SPENT, REPORT_COLUMNS.CLICKS, 1),
      fractionDecorator
    ),
    [REPORT_COLUMNS.CPA]: getColumnSetting(
      REPORT_COLUMNS.CPA,
      formatters.textFormatter,
      getFractionFooterValue(REPORT_COLUMNS.SPENT, REPORT_COLUMNS.CONVS, 1),
      fractionDecorator
    ),
    [REPORT_COLUMNS.SKIP_COUNT]: getColumnSetting(
      REPORT_COLUMNS.SKIP_COUNT,
      formatters.textFormatter,
      getSumFooterValue(REPORT_COLUMNS.SKIP_COUNT),
      commaDecorator
    ),
    [REPORT_COLUMNS.FIRST_QUARTILE]: getColumnSetting(
      REPORT_COLUMNS.FIRST_QUARTILE,
      formatters.textFormatter,
      getSumFooterValue(REPORT_COLUMNS.FIRST_QUARTILE),
      commaDecorator
    ),
    [REPORT_COLUMNS.MID_POINT]: getColumnSetting(
      REPORT_COLUMNS.MID_POINT,
      formatters.textFormatter,
      getSumFooterValue(REPORT_COLUMNS.MID_POINT),
      commaDecorator
    ),
    [REPORT_COLUMNS.THIRD_QUARTILE]: getColumnSetting(
      REPORT_COLUMNS.THIRD_QUARTILE,
      formatters.textFormatter,
      getSumFooterValue(REPORT_COLUMNS.THIRD_QUARTILE),
      commaDecorator
    ),
    [REPORT_COLUMNS.COMPLETE]: getColumnSetting(
      REPORT_COLUMNS.COMPLETE,
      formatters.textFormatter,
      getSumFooterValue(REPORT_COLUMNS.COMPLETE),
      commaDecorator
    ),
    [REPORT_COLUMNS.CONVS]: getColumnSetting(
      REPORT_COLUMNS.CONVS,
      formatters.textFormatter,
      getSumFooterValue(REPORT_COLUMNS.CONVS),
      commaDecorator
    ),
    [REPORT_COLUMNS.CONV_1]: getColumnSetting(
      REPORT_COLUMNS.CONV_1,
      formatters.textFormatter,
      getSumFooterValue(REPORT_COLUMNS.CONV_1),
      commaDecorator
    ),
    [REPORT_COLUMNS.CONV_7]: getColumnSetting(
      REPORT_COLUMNS.CONV_7,
      formatters.textFormatter,
      getSumFooterValue(REPORT_COLUMNS.CONV_7),
      commaDecorator
    ),
    [REPORT_COLUMNS.CVR]: getColumnSetting(
      REPORT_COLUMNS.CVR,
      formatters.textFormatter,
      getFractionFooterValue(REPORT_COLUMNS.CONVS, REPORT_COLUMNS.CLICKS, 100),
      percentDecorator
    ),
    [REPORT_COLUMNS.MEDIA_COST]: getColumnSetting(
      REPORT_COLUMNS.MEDIA_COST,
      formatters.textFormatter,
      getCostSumFooterValue(REPORT_COLUMNS.MEDIA_COST),
      actualCostDecorater,
      _.curry(totalCostDecorator)(currency),
      currency
    ),
    [REPORT_COLUMNS.MEDIA_SPENT]: getColumnSetting(
      REPORT_COLUMNS.MEDIA_SPENT,
      formatters.textFormatter,
      getCostSumFooterValue(REPORT_COLUMNS.MEDIA_SPENT),
      actualCostDecorater,
      _.curry(totalCostDecorator)(currency),
      currency
    ),
    [REPORT_COLUMNS.AGENCY_PROFIT]: getColumnSetting(
      REPORT_COLUMNS.AGENCY_PROFIT,
      formatters.textFormatter,
      getCostSumFooterValue(REPORT_COLUMNS.AGENCY_PROFIT),
      actualCostDecorater,
      _.curry(totalCostDecorator)(currency),
      currency
    ),
    [REPORT_COLUMNS.SYS_PROFIT]: getColumnSetting(
      REPORT_COLUMNS.SYS_PROFIT,
      formatters.textFormatter,
      getCostSumFooterValue(REPORT_COLUMNS.SYS_PROFIT),
      actualCostDecorater,
      _.curry(totalCostDecorator)(currency),
      currency
    ),
    [REPORT_COLUMNS.DATA_COST]: getColumnSetting(
      REPORT_COLUMNS.DATA_COST,
      formatters.textFormatter,
      getCostSumFooterValue(REPORT_COLUMNS.DATA_COST),
      actualCostDecorater,
      _.curry(totalCostDecorator)(currency),
      currency
    ),
    [REPORT_COLUMNS.SPENT]: getColumnSetting(
      REPORT_COLUMNS.SPENT,
      formatters.textFormatter,
      getCostSumFooterValue(REPORT_COLUMNS.SPENT),
      actualCostDecorater,
      _.curry(totalCostDecorator)(currency),
      currency
    ),
    [REPORT_COLUMNS.UU]: getColumnSetting(
      REPORT_COLUMNS.UU,
      formatters.textFormatter,
      '',
      commaDecorator
    ),
    [REPORT_COLUMNS.TAGS]: getColumnSetting(
      REPORT_COLUMNS.TAGS,
      formatters.tagFormatter,
      '',
      _.identity
    ),
    [REPORT_COLUMNS.EDITBTNS]: {
      ...getColumnSetting(
        REPORT_COLUMNS.EDITBTNS,
        formatters.floatingEditBtnsFormatter,
        '',
        _.identity
      ),
      text: '',
      sort: false
    }
  };
};

import React from 'react';
import i18n from 'i18n';

export const renderCreditHint = (credit) => (
  <span className='text-danger'>
    {i18n.t('orderForm.labels.creditHint', { credit })}
  </span>
);

export const renderErrors = (errors) => {
  const errorDoms = errors.map(error => (
    <div key={error}>{error}</div>
  ));
  return (
    <>
      {errorDoms}
    </>
  );
};

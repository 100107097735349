import React, { useMemo, useRef } from 'react';
import { Slider } from 'components/common/Slider/Slider';
import { DefaultSliderModel } from 'components/common/Slider/SliderModel';
import _ from 'lodash';
import { NativePreview } from 'containers/Creatives/CreativeSetupFlow/FlowSteps/SubSteps/FormContent/NativePreview';
import styles from './nativeCreativeDetail.module.scss';
import { CreativeListRecord } from 'core/creative/Creative';
import parser from 'html-react-parser';
import { useOneForAllDisplayPreview } from 'hooks/GoCreativePreview/useOneForAllDisplayPreview';

export const OneForAllDisplayCreativeDetail: React.FC<{ creative: CreativeListRecord }> = ({
  creative
}) => {

  const previewAreaWidth = 600;
  const previewAreaHeight = 680;
  const creativeValue = _.get(creative, 'creativeValue', _.get(creative, 'creativeValues'));
  const storyImgUrl = _.get(creative, 'bannerExtra.storyImgUrl');
  const medias = useMemo(() => ({
    squareImg: { url: _.get(creative, 'bannerExtra.imgUrl', '') },
    storyImg: storyImgUrl ? { url: storyImgUrl } : undefined
  }), [creative, storyImgUrl]);
  const typeProperties = useMemo(() => ({
    title: _.get(creative, 'bannerExtra.title'),
    description: _.get(creative, 'bannerExtra.description'),
    message: _.get(creative, 'bannerExtra.message'),
    linkUrl: _.get(creative, 'bannerExtra.fbLandingUrl'),
    pageId: _.get(creative, 'bannerExtra.pageId'),
    callToAction: _.get(creative, 'bannerExtra.callToAction')
  }), [creative]);
  const {
    previewData
  } = useOneForAllDisplayPreview(
    creative.advertiserId,
    typeProperties,
    medias
  );
  const previewRefs = useRef({});
  const onIframeLoaded = (previewType) => {
    if (!previewRefs.current[previewType]) {
      return;
    }
    previewRefs.current[previewType].className = '';
    previewRefs.current[previewType].querySelector(`.${styles.loadingCircle}`).style.display = 'none';
  };

  const renderFBPreviews = () => {
    return Object.keys(previewData).map(previewType => {
      let content;
      const iframeString = previewData[previewType];
      if (iframeString) {
        const iframe = parser(iframeString);
        if (React.isValidElement(iframe)) {
          content = React.cloneElement(iframe, {
            onLoad: _.partial(onIframeLoaded, previewType)
          });
        }
      }
      return (
        <div
          key={previewType}
          ref={el => previewRefs.current[previewType] = el}
          className={styles.previewPlaceholder}
        >
          <div className={styles.loadingCircle} />
          {content}
        </div>
      );
    });
  };

  return (
    <div className={styles.nativeCreativeDetail}>
      <Slider model={new DefaultSliderModel(previewAreaWidth, previewAreaHeight, true, false, 0, false)}>
        {renderFBPreviews()}
        <NativePreview
          url={_.get(creativeValue, 'img.url')}
          bannerUrl={_.get(creative, 'bannerUrl')}
          creativeValues={{
            title: _.get(creativeValue, 'title'),
            desc: _.get(creativeValue, 'desc'),
            sponsor: _.get(creativeValue, 'sponsored.name'),
            sponsorLink: _.get(creativeValue, 'sponsored.link')
          }}
        />
        <NativePreview
          square={true}
          url={_.get(creativeValue, 'icon.url')}
          bannerUrl={_.get(creative, 'bannerUrl')}
          creativeValues={{
            title: _.get(creativeValue, 'title'),
            desc: _.get(creativeValue, 'desc'),
            sponsor: _.get(creativeValue, 'sponsored.name'),
            sponsorLink: _.get(creativeValue, 'sponsored.link')
          }}
        />
      </Slider>
    </div>
  );
};

import { PrivacyPolicy } from 'components/PrivacyPolicy/PrivacyPolicy';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import React from 'react';
import { isProject } from 'core/permission/PermissionDSL';
import { Project } from 'core/project/Project';
import './AppFooter.scss';
import i18n from 'i18n';

export class AppFooter extends React.Component {
  render () {
    const postfixToFilter = ['-gojek'];
    const year = new Date().getFullYear();
    let version = `v${process.env.REACT_APP_VERSION}`;
    postfixToFilter.forEach(postfix => version = version.replace(postfix, ''));
    return (
      <div className='app-footer'>
        <div>
          {year} &copy; {i18n.t('projects.pmax3')} {version}
          <br/>
          powered by TenMax
          <br/>
          <PermissionChecker permissionAware={isProject(Project.GOGAN)}>
            <PrivacyPolicy className='app-footer-privacy-policy'/>
          </PermissionChecker>
        </div>
      </div>
    );
  }
}

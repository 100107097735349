import React from 'react';
import { FormConfig } from './FormikField';
import { FormikFormSection } from './FormikFormSection';

export const FormikFormContent: React.FC<{
  formConfig?: FormConfig
}> = ({
  formConfig
}) => {

  const renderFormContent = () => {
    if (!formConfig) {
      return;
    }
    return formConfig.sections.map((section, index) => (
      <FormikFormSection key={index} formSection={section}/>
    ));
  };

  return (
    <div>
      {renderFormContent()}
    </div>
  );
};

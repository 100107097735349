import React from 'react';
import styles from './loadingCircle.module.scss';
import classnames from 'classnames/bind';
const cx = classnames.bind(styles);

export const LoadingCircle: React.FC<{
  width: number,
  height: number,
  className?: string
}> = ({
  width,
  height,
  className
}) => {
  return (
    <div
      style={{ width, height }}
      className={cx(styles.loadingCircle, className)}
    />
  );
};

import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldAlt } from '@fortawesome/free-solid-svg-icons';
import i18n from 'i18n';
import styles from './privacyPolicy.module.scss';
import classnames from 'classnames/bind';

const openPrivacyPolicy = () => {
  window.open('https://www.gojek.com/privacy-policy/int/?locale=en-us', '_blank');
};

export const PrivacyPolicy: React.FC<{
  className?: string
}> = ({
  className
}) => {

  const cx = useMemo(() => classnames.bind(styles), []);

  return (
    <div className={cx(styles.privacyPolicy, className)} onClick={openPrivacyPolicy}>
      <FontAwesomeIcon icon={faShieldAlt}/>
      {i18n.t('loginPage.labels.privacyPolicy')}
    </div>
  );
};

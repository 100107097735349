import React from 'react';

import { Modal } from 'components/common/Modal/Modal';
import i18n from 'i18n';

export interface DismissFunction {
  (): void;
}

export interface ChangeOperateFunction {
  (): void;
}

export interface ChangeOperateAlertDialogProps {

  readonly title: string;
  readonly type: string;
  readonly newOperate: string;
  readonly buttonLabel: string;
  readonly dismissable: boolean;

  readonly dismiss: DismissFunction;
  readonly changeOperate: ChangeOperateFunction;
}

export class ChangeOperateAlertDialog extends React.Component<ChangeOperateAlertDialogProps> {
  static defaultProps = {
    dismissable: true,
    title: '',
    buttonLabel: 'common.buttons.ok'
  };

  hide = () => {
    if (this.props.dismissable) {
      this.props.dismiss();
    }
  }

  close = (e: any) => {
    this.props.dismiss();
  }

  changeOperate = (e: any) => {
    this.props.changeOperate();
  }

  render () {
    return (
      <Modal
        title={i18n.t('limitation.move.to') + '．．．' + i18n.t('limitation.' + this.props.newOperate)}
        primaryButton={{ title: i18n.t('common.buttons.replace'), callback: this.changeOperate }}
        secondaryButton={{ title: i18n.t('common.buttons.cancel'), callback: this.close }}
        dismiss={this.hide}
        enableCloseBtn
      >
        {i18n.t('limitation.warning.sameTypeWarning', { type: i18n.t('limitation.' + this.props.newOperate) })}
      </Modal>
    );
  }
}

export function ChangeOperateAlert (type, newOperate, dismiss: DismissFunction, dismissable: boolean = true, changeOperate) {
  return (
    <ChangeOperateAlertDialog type={type} newOperate={newOperate} dismiss={dismiss} dismissable={dismissable} changeOperate={changeOperate}/>
  );
}

export const toOptions = (enumObject: any) => {
  let map: { label: any, value: any }[] = [];
  for (let n in enumObject) {
    map.push({ value: enumObject[n], label: n });
  }
  return map;
};

export const createValues = (enumObject: any) => {
  return Object.keys(enumObject)
  .filter(isNaN as any)
  .map((value: string) => {
    return enumObject[value];
  });
};

import {
  RdpWebService,
  RestfulRdpWebService
} from 'ws/RdpWebService';
import { Retail, Product, ProductSet } from 'core/product/Product';
import { generateI18nOfSelectOptions } from 'utils/I18nUtils';
import i18n from 'i18n';

export interface RdpManager {
  getRetails (): Promise<Retail[]>;
  getRetailProducts (retailId, queries?: any): Promise<Product[]>;
  getAllProducts (queries?: any): Promise<Product[]>;
  getRetailProductSets (retailId, advertiserId): Promise<ProductSet[]>;
  getAllRetailProductSets (advertiserId): Promise<ProductSet[]>;
  getAllProductSets (): Promise<ProductSet[]>;
  getProductSetDetail (retailId, productSetId): Promise<ProductSet>;
  createProductSet (retailId, productSetFormData): Promise<ProductSet>;
  updateProductSetAdd (retailId, productSetFormData): Promise<ProductSet>;
  updateProductSetRemove (retailId, productSetFormData): Promise<ProductSet>;
}

export class DefaultRdpManager implements RdpManager {
  webService: RdpWebService;

  constructor (webService: RdpWebService = new RestfulRdpWebService()) {
    this.webService = webService;
  }

  async getRetails (): Promise<Retail[]> {
    const retails = await this.webService.getRetails();
    return retails.map(retail => {
      const i18nKey = `retailers.${retail.name.toLowerCase()}`;
      const result = {
        id: retail.id,
        name: i18n.exists(i18nKey) ? i18n.t(i18nKey) : retail.name
      };
      generateI18nOfSelectOptions(({
        label: result.name,
        value: result.id
      }), 'retailers');
      return result;
    });
  }

  async getRetailProducts (retailId, queries: any = {}): Promise<Product[]> {
    return this.webService.getRetailProducts(retailId, queries);
  }

  async getAllProducts (queries: any = {}): Promise<Product[]> {
    return this.webService.getAllProducts(queries);
  }

  async getRetailProductSets (retailId, advertiserId): Promise<ProductSet[]> {
    const retailProductSets = await this.webService.getRetailProductSets(retailId, advertiserId);
    retailProductSets.forEach(productSet => generateI18nOfSelectOptions({
      label: productSet.name,
      value: productSet.id
    }, 'retail.productSets'));
    return retailProductSets;
  }

  async getAllRetailProductSets (advertiserId: any): Promise<ProductSet[]> {
    return this.webService.getAllRetailProductSets(advertiserId);
  }

  async getAllProductSets (): Promise<ProductSet[]> {
    return this.webService.getAllProductSets();
  }

  async getProductSetDetail (retailId, productSetId): Promise<ProductSet> {
    return this.webService.getProductSetDetail(retailId, productSetId);
  }

  async createProductSet (retailId, productSetFormData): Promise<ProductSet> {
    return this.webService.createProductSet(retailId, productSetFormData);
  }

  async updateProductSetAdd (retailId: any, productSetFormData: any): Promise<ProductSet> {
    return this.webService.updateProductSetAdd(retailId, productSetFormData);
  }

  async updateProductSetRemove (retailId: any, productSetFormData: any): Promise<ProductSet> {
    return this.webService.updateProductSetRemove(retailId, productSetFormData);
  }
}

import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import moment from 'moment';
import { ReportManager, DefaultReportManager } from 'core/report/ReportManager';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { AddonFeatureManager } from 'core';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { SelectOptions } from 'components/common/commonType';
import i18n from 'i18n';

export type OrderDownloadReportPanelState = {
  readonly startDate: string;
  readonly endDate: string;
  readonly loading: boolean;
  readonly selectFields: { [key: string]: boolean }
  readonly groupBy: string;
  readonly activeTab: string;
  readonly reportType: string;
  readonly closingReportType: string;
  readonly enableDownload: boolean;
};
export interface OrderDownloadReportPanelModel {
  readonly order: {
    name: string,
    orderNumber: string,
    startDate: string,
    endDate: string
  };
  readonly state: OrderDownloadReportPanelState;
  readonly event: UpdateEventListener<OrderDownloadReportPanelModel>;
  readonly reportTypeOptions: SelectOptions[];
  readonly closingReportTypeOptions: SelectOptions[];
  readonly addonFeatureManager: AddonFeatureManager;
  cancel (): void;
  updateDateRange (startDate, endDate): void;
  downloadReport (): void;
  onQuickFieldSelect (type: string): void;
  onFieldSelect (event: any): void;
  changeGroupBy (event: any): void;
  onSelectTab (tab: string): void;
  onSelectReportType (type: string): void;
  onSelectClosingReportType (type: string): void;
}

export type OrderDownloadReportPanelProps = {
  readonly model: OrderDownloadReportPanelModel;
};

export enum ReportDownloadPanelTab {
  NORMAL = 'normal',
  CLOSING = 'closing',
  ADVANCE = 'advance'
}

export enum Fields {
  IMPRES = 'IMPRESS',
  VIEWABLE = 'VIEWABLE',
  UNIQUE_USERS = 'UU',
  CLICKS = 'CLICK',
  CTR = 'CTR',
  VCTR = 'VIEWABLE_CTR',
  VIEW = 'AD_VIEW',
  VIEW_RATE = 'VIEW_RATE',
  VIEWABLE_VIEW_RATE = 'VIEWABLE_VIEW_RATE',
  CONVS = 'CONVS',
  SPENT = 'SPENT',
  CPM = 'CPM',
  CPC = 'CPC',
  CPV = 'CPV',
  CPA = 'CPA',
  FIRST_QUARTILE = 'FIRST_QUARTILE',
  MID_POINT = 'MID_POINT',
  THIRD_QUARTILE = 'THIRD_QUARTILE',
  COMPLETE = 'COMPLETE',
  GENDER = 'GENDER',
  AGE = 'AGE',
  INTERESTS = 'INTEREST',
  COUNTRY = 'COUNTRY',
  DOMAIN = 'DOMAIN'
}

export enum GroupBy {
  CREATIVE = 'CREATIVE',
  CAMPAIGN = 'CAMPAIGN'
}

const initFields = {
  [Fields.IMPRES]: false,
  [Fields.VIEWABLE]: false,
  [Fields.UNIQUE_USERS]: false,
  [Fields.CLICKS]: false,
  [Fields.CTR]: false,
  [Fields.VCTR]: false,
  [Fields.VIEW]: false,
  [Fields.VIEW_RATE]: false,
  [Fields.VIEWABLE_VIEW_RATE]: false,
  [Fields.CONVS]: false,
  [Fields.SPENT]: false,
  [Fields.CPM]: false,
  [Fields.CPC]: false,
  [Fields.CPV]: false,
  [Fields.CPA]: false,
  [Fields.FIRST_QUARTILE]: false,
  [Fields.MID_POINT]: false,
  [Fields.THIRD_QUARTILE]: false,
  [Fields.COMPLETE]: false,
  [Fields.GENDER]: false,
  [Fields.AGE]: false,
  [Fields.INTERESTS]: false
};

const sortingFields = ['UU', 'IMPRESS', 'VIEWABLE', 'AD_VIEW', 'VIEW_RATE', 'VIEWABLE_VIEW_RATE', 'CPV', 'CLICK', 'CONVS', 'CTR', 'VIEWABLE_CTR', 'CPM', 'CPC', 'CPA', 'FIRST_QUARTILE', 'MID_POINT', 'THIRD_QUARTILE', 'COMPLETE', 'SPENT'];

export const defaultFormat = 'YYYY-MM-DD';
export class DefaultOrderDownloadReportPanelModel implements OrderDownloadReportPanelModel {
  event: FireableUpdateEventListener<OrderDownloadReportPanelModel>;
  startDate: string;
  endDate: string;
  loading: boolean;
  reportType: string = 'cpm';
  closingReportType: string = 'cpmClosing';
  activeTab: string = ReportDownloadPanelTab.NORMAL;
  enableDownload: boolean = true;
  selectFields = { ...initFields };
  groupBy: string = GroupBy.CREATIVE;

  constructor (
    public order: {
      name: string,
      orderNumber: string,
      startDate: string,
      endDate: string
    },
    public callback: () => void,
    public addonFeatureManager: AddonFeatureManager,
    private reportManager: ReportManager = new DefaultReportManager()
  ) {
    this.event = new FireableUpdateEventListener<OrderDownloadReportPanelModel>();
    this.startDate = moment(order.startDate).format(defaultFormat);
    this.endDate = moment(order.endDate).endOf('day').format(defaultFormat);
    this.loading = false;
  }

  get state (): OrderDownloadReportPanelState {
    return {
      startDate: this.startDate,
      endDate: this.endDate,
      loading: this.loading,
      selectFields: this.selectFields,
      groupBy: this.groupBy,
      reportType: this.reportType,
      closingReportType: this.closingReportType,
      activeTab: this.activeTab,
      enableDownload: this.enableDownload
    };
  }

  get quickSelectFields () {
    const useViewableImpres = this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.REPORT.REPORT_VIEWABLE_CTR);
    const impres = useViewableImpres ? Fields.VIEWABLE : Fields.IMPRES;
    const ctr = useViewableImpres ? Fields.VCTR : Fields.CTR;
    const viewRate = useViewableImpres ? Fields.VIEWABLE_VIEW_RATE : Fields.VIEW_RATE;
    return {
      cpm: [Fields.IMPRES, Fields.CLICKS, Fields.CTR, Fields.CPM, Fields.SPENT],
      cpc: [impres, Fields.CLICKS, ctr, Fields.CPC, Fields.SPENT],
      cpa: [impres, Fields.CLICKS, Fields.CONVS, ctr, Fields.CPC, Fields.CPA, Fields.SPENT],
      video: [impres, Fields.VIEW, viewRate, Fields.CPV, Fields.CLICKS, ctr, Fields.FIRST_QUARTILE, Fields.MID_POINT, Fields.THIRD_QUARTILE, Fields.COMPLETE, Fields.SPENT],
      outdoor: [Fields.IMPRES, Fields.CPM, Fields.FIRST_QUARTILE, Fields.MID_POINT, Fields.THIRD_QUARTILE, Fields.COMPLETE, Fields.SPENT],
      cpmClosing: [Fields.IMPRES, Fields.CLICKS, Fields.CTR, Fields.CPM, Fields.SPENT, Fields.GENDER, Fields.AGE, Fields.INTERESTS, Fields.UNIQUE_USERS],
      cpcClosing: [impres, Fields.CLICKS, ctr, Fields.CPC, Fields.SPENT, Fields.GENDER, Fields.AGE, Fields.INTERESTS, Fields.UNIQUE_USERS],
      reset: []
    };
  }

  async downloadReport () {
    this.updateState(true);
    try {
      let fields;
      let groupBy: string = GroupBy.CREATIVE;
      let isCustom = false;
      if (this.activeTab === ReportDownloadPanelTab.NORMAL) {
        fields = this.quickSelectFields[this.reportType];
      } else if (this.activeTab === ReportDownloadPanelTab.CLOSING) {
        fields = this.quickSelectFields[this.closingReportType];
        fields.push(Fields.COUNTRY);
        fields.push(Fields.DOMAIN);
      } else {
        fields = Object.keys(this.selectFields).filter(key => this.selectFields[key] === true).sort(function (a, b) {
          return sortingFields.indexOf(a) - sortingFields.indexOf(b);
        });
        groupBy = this.groupBy;
        isCustom = true;
      }
      await this.reportManager.downloadReportV2(this.order, this.startDate, this.endDate, groupBy, fields, isCustom);
      this.updateState(false);
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
      this.updateState(false);
    }
  }

  cancel () {
    this.callback();
  }

  get reportTypeOptions (): Array<SelectOptions> {
    return _.compact([
      {
        label: i18n.t('orderDownloadReportPanel.labels.cpmOption'),
        value: 'cpm'
      },
      {
        label: i18n.t('orderDownloadReportPanel.labels.cpcOption'),
        value: 'cpc'
      },
      this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CONVERSION_TRACKING.CONV_TRACKING_LIST) ? {
        label: i18n.t('orderDownloadReportPanel.labels.cpaOption'),
        value: 'cpa'
      } : undefined,
      this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CREATIVES.VIDEO) ||
        this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CREATIVES.THIRDPARTY) ||
        this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CREATIVES.COMBO) ||
        this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CREATIVES.OUTDOOR) ?
        {
          label: i18n.t('orderDownloadReportPanel.labels.videoOption'),
          value: 'video'
        } : undefined,
      this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CREATIVES.OUTDOOR) ? {
        label: i18n.t('orderDownloadReportPanel.labels.outdoorOption'),
        value: 'outdoor'
      } : undefined
    ]);
  }

  get closingReportTypeOptions (): Array<SelectOptions> {
    return [
      {
        label: i18n.t('orderDownloadReportPanel.labels.cpmClosingReport'),
        value: 'cpmClosing'
      },
      {
        label: i18n.t('orderDownloadReportPanel.labels.cpcClosingReport'),
        value: 'cpcClosing'
      }
    ];
  }

  onQuickFieldSelect = (type: string) => {
    Object.keys(_.omit(this.selectFields, [Fields.GENDER, Fields.AGE, Fields.INTERESTS]))
      .forEach(field => {
        this.selectFields[field] = false;
        if (this.quickSelectFields[type].includes(field)) {
          this.selectFields[field] = true;
        }
      });
    this.checkEnableDownload();
    this.updateState(false);
  }

  onFieldSelect = (event: any) => {
    this.selectFields = { ...this.selectFields, [event.target.id]: event.target.checked };
    this.checkEnableDownload();
    this.updateState(false);
  }

  checkEnableDownload = () => {
    if (this.activeTab === ReportDownloadPanelTab.ADVANCE) {
      this.enableDownload = Object.values(this.selectFields).reduce((acc, checked) => {
        return acc || checked;
      }, false);
    } else {
      this.enableDownload = true;
    }
  }

  onSelectTab = (tab: string) => {
    this.activeTab = tab;
    this.checkEnableDownload();
    this.updateState(false);
  }

  onSelectReportType = (type: string) => {
    this.reportType = type;
    this.updateState(false);
  }

  onSelectClosingReportType = (type: string) => {
    this.closingReportType = type;
    this.updateState(false);
  }

  changeGroupBy = (event: any) => {
    this.groupBy = event.target.value;
    this.updateState(false);
  }

  updateDateRange (startDate: string, endDate: string) {
    this.startDate = startDate;
    this.endDate = endDate;
    this.updateState();
  }

  updateState (loading = false) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}

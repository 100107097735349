import React from 'react';
import { Spring, animated } from 'react-spring';
import styles from './creativePreview.module.scss';
import videoPlaceholder from 'assets/video-placeholder.svg';
import _ from 'lodash';
import i18n from 'i18n';
import { CreativeListRecord, CreativeOfCampaign, OutdoorType } from 'core/creative/Creative';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileArchive } from '@fortawesome/free-regular-svg-icons';

export class OutdoorCreativePreview extends React.Component<{ creative: CreativeOfCampaign | CreativeListRecord }, any> {

  timer: any;

  constructor (props) {
    super(props);
    const creativeValue = _.get(props.creative, 'creativeValue', _.get(props.creative, 'creativeValues'));
    this.state = {
      index: 0,
      creativeValue
    };
  }

  componentDidUpdate (prevProps) {
    const prevCreativeValue = _.get(prevProps.creative, 'creativeValue', _.get(prevProps.creative, 'creativeValues'));
    const currentCreativeValue = _.get(this.props.creative, 'creativeValue', _.get(this.props.creative, 'creativeValues'));
    if (JSON.stringify(prevCreativeValue) !== JSON.stringify(currentCreativeValue)) {
      this.setState({
        index: 0,
        creativeValue: currentCreativeValue
      });
    }
  }

  mouseEnterHandler = () => {
    this.timer = setInterval(() => {
      this.setState({
        index: this.state.index >= 4 ? 0 : this.state.index + 1
      });
    }, 1000);
  }

  mouseLeaveHandler = () => {
    clearInterval(this.timer);
    this.setState({
      index: 0
    });
  }

  renderPlaceholder = () => {
    return (
      <div className={styles.outdoorPreviewPlaceholder}>
        <img src={videoPlaceholder} alt={'preview area'} />
        <span>{i18n.t('videoCreativePreivew.labels.generating')}</span>
      </div>
    );
  }

  renderH5Preview = () => {
    return (
      <div className={styles.outdoorPreviewPlaceholder}>
        <FontAwesomeIcon icon={faFileArchive} />
      </div>
    );
  }

  renderImagePreview = () => {
    return (
      <div className={styles.outdoorImgPreview}>
        <img src={this.state.creativeValue.imageUrl} alt='preview' />
      </div>
    );
  }

  render () {
    if (_.get(this.props.creative, 'outdoorType') === OutdoorType.HTML5) {
      return this.renderH5Preview();
    }
    if (_.get(this.props.creative, 'outdoorType') === OutdoorType.IMAGE_AUDIO) {
      return this.renderImagePreview();
    }
    const creativeValue = this.state.creativeValue;
    if (!creativeValue || !creativeValue.videoW || !creativeValue.videoH) {
      return this.renderPlaceholder();
    }
    const sourceUrl = this.props.creative.bannerImageUrl;
    const portion = creativeValue.videoH / creativeValue.videoW;
    if (!sourceUrl) {
      return this.renderPlaceholder();
    }
    let width = 200;
    let height = portion * width;
    if (height > 140) {
      width = width * 140 / height;
      height = 140;
    }
    const imgOffsetPercent = 25;
    const imgCount = 5;
    return (
      <Spring
        from={{ x: 0 }}
        to={{ x: this.state.index * imgOffsetPercent }}
        immediate={true}
      >
        {({ x }) => (
          <animated.div
            className={styles.videoCreativePreview}
            onMouseEnter={this.mouseEnterHandler}
            onMouseLeave={this.mouseLeaveHandler}
            style={{
              width: width,
              height: height,
              background: x.to((x) => `url(${sourceUrl}) no-repeat ${x}% center / ${width * imgCount}px ${height}px`)
            }}
          />
        )}
      </Spring>
    );
  }
}

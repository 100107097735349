import React, { useRef, useState } from 'react';
import { Overlay } from 'react-bootstrap';
import _ from 'lodash';
import styles from './copyableOverlayTrigger.module.scss';

export const CopyableOverlayTrigger: React.FC<React.PropsWithChildren<{
  placement: 'bottom' | 'top' | 'left' | 'right',
  overlay: React.ReactNode | (() => React.ReactNode)
}>> = props => {

  const [show, setShow] = useState(true);
  const [target, setTarget] = useState<any>();
  const containerRef = useRef<any>(null);

  const handleMouseEnter = (e) => {
    setShow(true);
    setTarget(e.target);
  };

  const handleMouseLeave = () => {
    setShow(false);
  };

  const handleTipMouseEnter = () => {
    setShow(true);
  };

  const renderOverlay = overlayProps => {
    const otherProps = _.omit(overlayProps,
      ['outOfBoundaries', 'scheduleUpdate', 'show', 'arrowProps']);

    let top = '0px';
    if (otherProps.placement === 'bottom') {
      top = '3px';
    }
    return (
      <div
        {...otherProps}
        style={{ ...otherProps.style, zIndex: 1100, backgroundColor: '#242328', top }}
        className={styles.overlay}
        onMouseEnter={handleTipMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {props.overlay}
      </div>
    );
  };

  return (
    <div ref={containerRef}>
      <div
        style={{ width: 'fit-content' }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {props.children}
      </div>
      {props.overlay &&
        <Overlay
          show={show}
          target={target}
          placement={props.placement}
          container={containerRef.current}
        >
          {(props) => renderOverlay(props)}
        </Overlay>
      }
    </div>
  );
};

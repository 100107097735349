import { SelectOptions } from 'components/common/commonType';
import { CampaignGroupChannel } from 'core/campaignGroup/CampaignGroup';

export abstract class DefaultDraftSetupStateContentModel {

  canGoNext: boolean = false;

  campaignGroupOptionsCache = {};

  constructor (public orderOptions: SelectOptions[]) {}

  abstract getCampaignGroupsOfChannel (campaignGroups);

  setCanGoNext = (canGoNext: boolean) => {
    this.canGoNext = canGoNext;
  }
}

export class RtbCampaignDraftSetupStateContentModel extends DefaultDraftSetupStateContentModel {
  getCampaignGroupsOfChannel (campaignGroups) {
    return campaignGroups.filter(campaignGroup => campaignGroup.channel === CampaignGroupChannel.RTB);
  }
}

export class FbAdSetDraftSetupStateContentModel extends DefaultDraftSetupStateContentModel {
  getCampaignGroupsOfChannel (campaignGroups) {
    return campaignGroups.filter(campaignGroup => campaignGroup.channel === CampaignGroupChannel.FB);
  }
}

export class TiktokAdGroupDraftSetupStateContentModel extends DefaultDraftSetupStateContentModel {
  getCampaignGroupsOfChannel (campaignGroups) {
    return campaignGroups.filter(campaignGroup => campaignGroup.channel === CampaignGroupChannel.TIKTOK);
  }
}

export class RetailCampaignDraftSetupStateContentModel extends DefaultDraftSetupStateContentModel {
  getCampaignGroupsOfChannel (campaignGroups) {
    return campaignGroups.filter(campaignGroup => campaignGroup.channel === CampaignGroupChannel.RETAIL_MEDIA);
  }
}

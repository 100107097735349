import { CreativeListRecord, CreativeOfCampaign } from 'core/creative/Creative';
import React from 'react';
import styles from './creativePreview.module.scss';
import customAdPlaceholder from 'assets/creative/creative-custom.svg';
import cx from 'classnames/bind';

export const CustomCreativePreview: React.FunctionComponent<{creative: CreativeOfCampaign | CreativeListRecord}> = (props) => {

  const classNames = cx.bind(styles);
  const previewClass = classNames('customCreativePreview', {
    border: true
  });

  return (
    <div className={previewClass}>
      <img src={customAdPlaceholder} alt={'preview'} />
    </div>
  );
};

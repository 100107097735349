import React from 'react';
import { withTip, withPrefix } from 'hoc';
import {
  FormikInput,
  FormikFormGroup,
  FormikLabel
} from 'components/common/formik';
import styles from './rtbCampaignOptimize.module.scss';
import i18n from 'i18next';
import { FormikField } from 'components/common/formik/FormikField';
import { FBBidStrategy, GoCampaignGroupObjective } from 'core/campaignGroup/CampaignGroup';
import { Form } from 'react-bootstrap';
import FormGroup from 'components/common/FormGroup/FormGroup';
import { formatPriceWithCurrency } from 'helper/CurrencyHelper';
import { Dayparts } from 'components/Dayparts/Dayparts';
import _ from 'lodash';
import cx from 'classnames/bind';
import { GoCampaignOptimizationGoal } from 'core/goCampaign/GoCampaign';
import { AdType } from 'core/rtbCampaign/RtbCampaign';
const classNames = cx.bind(styles);

export const withTipFormikInput = withTip(FormikInput);
export const withPrefixFormikLabel = withPrefix(FormikLabel);

const getOptimizationDes = (currency, bidStrategy, optimize, bidPrice) => {
  const optimization = i18n.t(`adSet.optimizationGoal.${optimize.toLowerCase()}`).toLowerCase();
  return i18n.t(`goCampaignOptimize.labels.${bidStrategy.toLowerCase()}`, {
    optimization,
    bidControl: formatPriceWithCurrency(currency, _.isNumber(bidPrice) ? +(bidPrice) : 0)
  });
};

const RtbCampaignOptimize = ({
  campaignGroup,
  campaignAdType,
  formType,
  currentGoCampaign,
  optimizeOptions,
  canEditOptimize,
  currency,
  onOptimizeChanged,
  dayPartEnable,
  onDaypartSwitchChanged
}) => {

  const renderBidControl = (formikProps) => {
    const value = formikProps.field.value;
    const onBidStrategyChange = (e) => {
      formikProps.form.setFieldValue(formikProps.field.name, e.target.value);
      formikProps.form.setFieldValue('bidPrice', '');
    };
    const canEditBidStrategy = campaignGroup.autoOptimise ? false : true;
    return (
      <div className={styles.bidControl}>
        <Form.Check
          type={'radio'}
          label={i18n.t('campaignGroup.labels.fb.bidStrategy.lowest_cost_without_cap')}
          value={FBBidStrategy.LOWEST_COST_WITHOUT_CAP}
          name={'bidStrategy'}
          id={'autoBid'}
          onChange={onBidStrategyChange}
          checked={value === FBBidStrategy.LOWEST_COST_WITHOUT_CAP}
          disabled={!canEditBidStrategy}
        />
        <Form.Check
          type={'radio'}
          label={i18n.t('campaignGroup.labels.fb.bidStrategy.lowest_cost_with_bid_cap')}
          value={FBBidStrategy.LOWEST_COST_WITH_BID_CAP}
          name={'bidStrategy'}
          id={'bidCap'}
          onChange={onBidStrategyChange}
          checked={value === FBBidStrategy.LOWEST_COST_WITH_BID_CAP}
          disabled={!canEditBidStrategy}
        />
        {(value === FBBidStrategy.LOWEST_COST_WITH_BID_CAP) &&
          <FormikField.InputGroup
            name='bidPrice'
            prefix={currency}
            type='number'
            min={0}
            className={styles.bidPrice}
          />
        }
      </div>
    );
  };

  const optimizeLabelFormatter = optimization => {
    return i18n.t(`adSet.optimizationGoal.${optimization.toLowerCase()}`);
  };

  const renderFrequencyControlInput = (formikProps) => {
    const onMaxFrequencyChange = e => {
      formikProps.form.setFieldValue('frequency.maxFrequency', e.target.value < 0 ? 0 : e.target.value);
    };
    const onIntervalDaysChange = e => {
      formikProps.form.setFieldValue('frequency.intervalDays', e.target.value < 0 ? 0 : e.target.value);
    };
    const frequency = formikProps.field.value;
    const errorMaxFrequency = _.get(formikProps.form.errors, 'frequency.maxFrequency');
    const errorintervalDays = _.get(formikProps.form.errors, 'frequency.intervalDays');
    const maxFrequencyClass = classNames('maxFrequency', {
      error: errorMaxFrequency
    });
    const intervalDaysClass = classNames('intervalDays', {
      error: errorintervalDays
    });
    return (
      <div className={styles.frequencyControl}>
        <div>
          <input
            className={maxFrequencyClass}
            value={frequency.maxFrequency}
            name='frequency.maxFrequency'
            onChange={onMaxFrequencyChange}
            min={1}
            max={20}
            type='number'
          />
          {` ${+(frequency.maxFrequency) > 1 ? 'impressions' : 'impression'} every `}
          <input
            className={intervalDaysClass}
            name='frequency.intervalDays'
            value={frequency.intervalDays}
            onChange={onIntervalDaysChange}
            min={1}
            max={7}
            type='number'
          />
          {` ${+(frequency.intervalDays) > 1 ? 'days' : 'day'}`}
        </div>
        {errorMaxFrequency && <div className='errorTip'>{errorMaxFrequency}</div>}
        {errorintervalDays && <div className='errorTip'>{errorintervalDays}</div>}
      </div>
    );
  };
  const renderFrequencyControlLabel = frequencyControl => {
    if (!frequencyControl) {
      return '';
    }
    return i18n.t('adSetSetupFlow.mainStep.labels.frequencyControl', {
      event: +(frequencyControl.maxFrequency) > 1 ? 'impressions' : 'impression',
      interval_days: frequencyControl.intervalDays,
      max_frequency: frequencyControl.maxFrequency,
      unit: +(frequencyControl.intervalDays) > 1 ? 'days' : 'day'
    });
  };

  return (
    <fieldset>
      <legend>
      <span>{i18n.t('adSetSetupFlow.mainStep.fieldset.optimizationTitle')}</span>
      </legend>
      <div className={styles.component}>
        {canEditOptimize ?
          <FormikField.Select
            options={optimizeOptions}
            label={i18n.t('adSetSetupFlow.mainStep.field.optimizationGoal')}
            name='optimize'
            simpleValue
            onChange={onOptimizeChanged}
          /> :
          <FormikField.Label
            label={i18n.t('adSetSetupFlow.mainStep.field.optimizationGoal')}
            name='optimize'
            formatter={optimizeLabelFormatter}
          />
        }
        <FormikField.Custom
          label={i18n.t('adSetSetupFlow.mainStep.field.bidControl')}
          name='bidStrategy'
          render={renderBidControl}
          hint={
            <div style={{ maxWidth: 500 }}>
              {getOptimizationDes(currency, _.get(currentGoCampaign, 'bidStrategy'), _.get(currentGoCampaign, 'optimize', ''), currentGoCampaign.bidPrice)}
            </div>
          }
        />
        <FormGroup controlId='billingEvent' label={i18n.t('adSetSetupFlow.mainStep.field.billingEvent')}>
          <Form.Label className={styles.billingEvent}>{i18n.t('adSet.billingEvent.impressions')}</Form.Label>
        </FormGroup>
        {campaignGroup.objective === GoCampaignGroupObjective.AwarenessObjective.REACH && currentGoCampaign.optimize === GoCampaignOptimizationGoal.REACH && campaignAdType !== AdType.PILOT_TV && (
          formType === 'create' ?
            <FormikField.Custom
              label={i18n.t('adSetSetupFlow.mainStep.field.frequencyControl')}
              name='frequency'
              render={renderFrequencyControlInput}
            /> :
            <FormikField.Label
              label={i18n.t('adSetSetupFlow.mainStep.field.frequencyControl')}
              name='frequency'
              formatter={renderFrequencyControlLabel}
            />
          )
        }
        {campaignAdType !== AdType.PILOT_TV &&
          <div className={styles.section}>
            <FormikFormGroup
              labelName={i18n.t('campaignInfo.labels.daypart')}
              inputName='dayPart.enabled'
              inputAs='switch'
              onChange={onDaypartSwitchChanged}
            />
            {!!dayPartEnable &&
              <FormikField.Custom
                label=''
                name='dayPart'
                component={Dayparts}
              />
            }
          </div>
        }
      </div>
    </fieldset>
  );
};

export default React.memo(RtbCampaignOptimize);

import React from 'react';
import { faEye, faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import TableRowToolBar from 'containers/TableRowToolBar/TableRowToolBar';
import { Link } from 'react-router-dom';
import styles from './productSetList.module.scss';
import i18n from 'i18n';

const nameFormatter = (id: any, productSet, _2, formatExtraData: any): any => {
  const onDetailBtnClick = () => formatExtraData.onDetailBtnClick(productSet.id);
  return (
    <div className={`${styles.nameCellWithIcon}`}>
      <div className={`${styles.nameCell}`}>
        <Link
          to={{
            pathname: `/product-sets/${id}/edit`,
            state: {
              retailId: productSet.retailId,
              advertiser: productSet.advertiser
            }
          }}
          className={styles.name}
        >
          {productSet.name}
        </Link>
        <div className={styles.idInfo}>
          {'ID: ' + id}
        </div>
      </div>
      <IconWithTooltip
        className={styles.detailBtn}
        onClick={onDetailBtnClick}
        icon={faEye}
        tooltipProps={{
          id: `productSetList-ViewTip-${id}`,
          tooltip: i18n.t('productSetList.hints.viewDetail')
        }}
      />
    </div>
  );
};

const floatingEditBtnsFormatter = (_1, productSet, _2, formatExtraData: any): any => {
  const onDeleteBtnClick = () => formatExtraData.onDeleteBtnClick(productSet.id);
  return (
    <TableRowToolBar className={styles.floatingEditArea}>
      <Link
        to={{
          pathname: `/product-sets/${productSet.id}/edit`,
          state: {
            retailId: productSet.retailId,
            advertiser: productSet.advertiser
          }
        }}
      >
        <IconWithTooltip
          icon={faPencilAlt}
          tooltipProps={{
            id: `productSetList-editTip-${productSet.id}`,
            tooltip: i18n.t('productSetList.hints.edit')
          }}
        />
      </Link>
      <IconWithTooltip
        icon={faTrashAlt}
        onClick={onDeleteBtnClick}
        tooltipProps={{
          id: `productSetList-deleteTip-${productSet.id}`,
          tooltip: i18n.t('productSetList.hints.delete')
        }}
      />
    </TableRowToolBar>
  );
};

export default {
  nameFormatter,
  floatingEditBtnsFormatter
};

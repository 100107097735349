import { StorageURL, AnonymousCredential, ServiceURL, ContainerURL } from '@azure/storage-blob';

export class AzureBlobConfig {
  storageURL: string;
  containerURL: ContainerURL;
  constructor (
    public accountId: string,
    public accountSAS: string,
    private containerPath: string
  ) {
    const storageUrl = `https://${this.accountId}.blob.core.windows.net/${accountSAS}`;
    const pipeline = StorageURL.newPipeline(new AnonymousCredential());
    const serviceURL = new ServiceURL(storageUrl, pipeline);
    this.storageURL = storageUrl;
    this.containerURL = ContainerURL.fromServiceURL(serviceURL, this.containerPath);
  }
}

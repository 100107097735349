import React from 'react';
import styles from './orderHome.module.scss';
import i18n from 'i18n';
import { OrderList } from 'components/OrderList/OrderList';
import { RecentGroupDropdown } from 'components/RecentGroupDropdown/RecentGroupDropdown';
import { OrderHomeProps } from './OrderHomeModel';
import { SessionStorageItemKeys } from 'helper/StorageHelper';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { notRoles } from 'core/permission/PermissionDSL';
import { RoleNames } from 'core';
import { StickableTableContainer } from 'containers/StickableTableContainer/StickableTableContainer';

export class OrderHome extends React.Component<OrderHomeProps> {

  handler?: number;
  componentDidMount () {
    this.handler = this.props.model.event.add((model) => {
      this.setState(model.state);
    });
  }

  componentDidUpdate (prevProps: OrderHomeProps) {
    if (prevProps.model !== this.props.model) {
      this.handler && prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add((model) => {
        this.setState(model.state);
      });
    }
  }

  componentWillUnmount () {
    this.handler && this.props.model.event.remove(this.handler);
  }

  onAdvertiserSelected = (advertiserId?: number) => {
    this.props.model.setAdvertiserId(advertiserId);
  }

  render () {
    const model = this.props.model;
    return (
      <StickableTableContainer>
        <div className={styles.container}>
          <div className={styles.titleArea}>
            {i18n.t('orderHome.labels.title')}
            <PermissionChecker
              permissionAware={notRoles(RoleNames.adsAdmin, RoleNames.adsReport, RoleNames.adsSales)}
            >
              <div className={styles.advertiserList}>
                <div className={styles.title}>
                  {i18n.t('orderHome.labels.advertiserListTitle')}
                </div>
                <RecentGroupDropdown
                  value={model.state.advertiserId}
                  options={model.advertisers}
                  storageKey={SessionStorageItemKeys.ADVERTISER}
                  callback={this.onAdvertiserSelected}
                />
              </div>
            </PermissionChecker>
          </div>
          <div className={styles.orderListArea}>
            <OrderList model={model.orderListModel}/>
          </div>
        </div>
      </StickableTableContainer>
    );
  }
}

import { CurrencyRate } from 'core/currencyRate/CurrencyRate';
import client from './RestClient';

export interface CurrencyRateWebService {
  getCurrencyRates (): Promise<{ [key: string]: CurrencyRate[] }>;
  getCurrencyNewestRates (): Promise<any[]>;
  addCurrencyRate (currency: string, rate: string | number): Promise<void>;
}

export class RestfulCurrencyRateWebService implements CurrencyRateWebService {

  restClient: any;

  constructor (restClient: any = client) {
    this.restClient = restClient;
  }

  async getCurrencyNewestRates (): Promise<any[]> {
    const response = await this.restClient.get('/v2/currencies/currency-rate/only-newest');
    return response.data.records;
  }

  async getCurrencyRates (): Promise<{ [key: string]: CurrencyRate[] }> {
    const response = await this.restClient.get('/v2/currencies/currency-rate');
    return response.data;
  }

  async addCurrencyRate (currency: string, rate: string | number): Promise<void> {
    await this.restClient.post('v2/currencies/currency-rate', {
      currency,
      rate
    });
  }
}

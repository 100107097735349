import React from 'react';
import { StickableBootstrapTable } from 'components/common/Table/StickableBootstrapTable';
import { CampaignGroupListColumns, useCampaignGroupListModel } from './CampaignGroupListModel';
import i18n from 'i18n';
import styles from './campaignGroupList.module.scss';
import { SearchBar } from 'components/common/SearchBar';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { Link } from 'react-router-dom';
import { Modal } from 'components/common/Modal/Modal';
import { Nav } from 'react-bootstrap';
import { AddonFeatureManager } from 'core';
import { Order } from 'core/order/Order';
import cx from 'classnames/bind';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { hasFuncs } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';
import Select from 'components/common/Select/Select';
import { ListEditBar } from 'components/ListEditBar/ListEditBar';
const classNames = cx.bind(styles);

export type CampaignGroupListProps = {
  order: Order,
  campaignGroupList: any[],
  refreshList: () => void,
  addonFeatureManager: AddonFeatureManager
};

export const CampaignGroupList: React.FC<CampaignGroupListProps> = ({
  order,
  campaignGroupList,
  refreshList,
  addonFeatureManager
}) => {

  const {
    state,
    columns,
    loading,
    channels,
    currentUrl,
    metricsGroupOptions,
    canNotCreateMessage,
    selectedCampaignGroups,
    onHandleSearch,
    onMetricsGroupChange,
    onListChannelChange,
    onDeleteModalClose,
    handleRemoveSelect,
    activeCampaignGroup,
    deactiveCampaignGroup
  } = useCampaignGroupListModel(order, campaignGroupList, refreshList, addonFeatureManager);

  const renderEditBar = () => {
    return (
      <ListEditBar selectedItems={selectedCampaignGroups} handleRemoveSelect={handleRemoveSelect}>
        <ListEditBar.Item>
          <ListEditBar.Link onClick={activeCampaignGroup}>
            {i18n.t('campaignList.labels.activeCampaign')}
          </ListEditBar.Link>
        </ListEditBar.Item>
        <ListEditBar.Item >
          <ListEditBar.Link onClick={deactiveCampaignGroup}>
            {i18n.t('campaignList.labels.deactivateCampaign')}
          </ListEditBar.Link>
        </ListEditBar.Item>
      </ListEditBar>
    );
  };

  const createContainerClassName = classNames('createCampaignGroup', 'tipContainer', {
    block: canNotCreateMessage !== ''
  });

  return (
    <div className={styles.campaignGroupList}>
      {loading && <LoadingIndicator />}
      {state.deleteGroup &&
        <Modal
          title={i18n.t('campaignGroup.labels.deleteTitle')}
          secondaryButton={{ title: i18n.t('common.buttons.cancel'), callback: onDeleteModalClose }}
          primaryButton={{ title: i18n.t('common.buttons.delete'), callback: state.deleteGroup }}
          dismiss={onDeleteModalClose}
        >
          {i18n.t('campaignGroup.labels.deleteModalContent')}
        </Modal>
      }
      <div className={styles.titleBar}>
        {selectedCampaignGroups.length > 0 ?
          renderEditBar() :
          <div className={styles.filterArea}>
            <PermissionChecker permissionAware={hasFuncs(Permission.CAMPAIGN_WRITE)}>
              <div className={createContainerClassName}>
                {canNotCreateMessage &&
                  <span className={styles.tooltip}>
                    {canNotCreateMessage}
                  </span>
                }
                <Link
                  to={`${currentUrl}/campaign-groups/new`}
                  className={`btn btn-tertiary btn-sm ${styles.createBtn}`}
                >
                  {i18n.t('campaignGroup.labels.create')}
                </Link>
              </div>
            </PermissionChecker>
            <SearchBar
              model={{
                placeholder: i18n.t('campaignGroup.placeholders.searchbar'),
                search: onHandleSearch,
                defaultValue: state.searchString
              }}
            />
          </div>
        }
      </div>
      <div className={styles.contentArea}>
        <div className={styles.listConditionArea}>
          <Nav
            activeKey={state.selectedChannel}
            onSelect={onListChannelChange}
          >
            {
              channels.map(channel => (
                <Nav.Item key={channel}>
                  <Nav.Link eventKey={channel}>
                    {i18n.t(`campaignGroup.labels.channel_${channel.toLowerCase()}`)}
                    {campaignGroupList.filter(campaignGroup => campaignGroup.drafts > 0 && campaignGroup.channel === channel).length > 0 &&
                      <div className={styles.draftHint}/>
                    }
                  </Nav.Link>
                </Nav.Item>
              ))
            }
          </Nav>
          <div className={styles.metricsOptionsContainer}>
            <Select
              className={styles.metricsOptions}
              options={metricsGroupOptions}
              name='metricsGroupOptions'
              simpleValue
              value={state.selectedMetricsGroup}
              onChange={onMetricsGroupChange}
            />
          </div>
        </div>
        <StickableBootstrapTable
          key={state.selectedMetricsGroup}
          stickFirstColumn
          stickLastColumn
          keyField={CampaignGroupListColumns.ID}
          data={state.filteredList}
          columns={columns}
          summaryData={state.summaryData}
          paddingBottom={false}
          noDataIndication={i18n.t('campaignGroup.labels.noData')}
          defaultSorted={[{
            dataField: CampaignGroupListColumns.ID,
            order: 'desc'
          }]}
        />
      </div>
    </div>
  );
};

import React, { useEffect, useState } from 'react';
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';
import RouteWithPermission from 'components/RouteWithPermission/RouteWithPermission';
import { hasFuncs } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';
import { ROUTE_PATH } from 'enum/RoutePath';
import { CampaignGroupForm } from './CampaignGroupForm/CampaignGroupForm';
import { useCreateCampaignGroupFormModel, useEditCampaignGroupFormModel } from './CampaignGroupForm/CampaignGroupFormModel';
import { CampaignGroupDetail } from './CampaignGroupDetail/CampaignGroupDetail';
import { Order } from 'core/order/Order';
import { AddonFeatureManager } from 'core';
import _ from 'lodash';
import { DefaultOrderManager } from 'core/order/OrderManager';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { useCallAPI } from 'hooks/useCallAPI';

export const CampaignGroups: React.FC<RouteComponentProps<any> & { order: Order, addonFeatureManager: AddonFeatureManager }> = (props) => {

  const [order, setOrder] = useState(props.order);
  const { loading, callAPIs } = useCallAPI();

  useEffect(() => {
    setOrder(props.order);
  }, [props.order]);

  useEffect(() => {
    const orderManager = new DefaultOrderManager();
    if (props.location.state && _.get(props.location.state, 'refreshOrder', false)) {
      callAPIs([
        orderManager.getOrder.bind(orderManager, order.orderNumber)
      ], newOrder => {
        setOrder(newOrder);
      });
      props.history.replace({ ...props.history.location, state: undefined });
    }
  }, [order.orderNumber, props.location.state, props.history, callAPIs]);

  const renderCampaignGroupDetailView = (routeProps) => {
    if (_.get(props.location.state, 'refreshOrder', false)) {
      return <div/>;
    }
    const groupId = routeProps.match.params.campaignGroupId;
    return (
      <CampaignGroupDetail
        groupId={groupId}
        order={order}
        addonFeatureManager={props.addonFeatureManager}
      />
    );
  };

  const renderCreateCampaignGroupView = () => {
    return (
      <CampaignGroupForm
        useModel={_.partial(useCreateCampaignGroupFormModel, order)}
      />
    );
  };

  const renderEditCampaignGroupView = (routeProps) => {
    const groupId = routeProps.match.params.campaignGroupId;
    return (
      <CampaignGroupForm
        useModel={_.partial(useEditCampaignGroupFormModel, order, groupId, _.get(routeProps.location.state, 'goCampaignList'))}
      />
    );
  };

  const redirectToOrderGroupDetail = (props) => {
    const match = props.match.url.match(/\/orders\/\d+\//);
    return <Redirect to={`${match[0].slice(0, -1)}`} />;
  };

  if (loading) {
    return <LoadingIndicator/>;
  }

  return (
    <Switch>
      <RouteWithPermission
        permissionAware={hasFuncs(Permission.CAMPAIGN_WRITE)}
        redirectPath={`${props.match.url}/new/${ROUTE_PATH.ERROR403}`}
        path={`${props.match.url}/new`}
        render={renderCreateCampaignGroupView}
      />
      <Route
        exact
        path={`${props.match.url}/:campaignGroupId/edit`}
        render={renderEditCampaignGroupView}
      />
      <Route
        path={`${props.match.url}/:campaignGroupId`}
        render={renderCampaignGroupDetailView}
      />
      <Route
        render={redirectToOrderGroupDetail}
      />
    </Switch>
  );
};

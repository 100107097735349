import React from 'react';
import i18n from 'i18next';
import _ from 'lodash';
import styles from './nativePreview.module.scss';
import classNames from 'classnames/bind';
import { ImagePreview } from 'components/common/Image/ImagePreview';

export const NativePreview: React.FunctionComponent<any> = (props) => {

  const cssClassNames = classNames.bind(styles);

  const openBannerUrl = (e) => {
    if (e.target.tagName === 'A') {
      return;
    }
    window.open(props.bannerUrl, '_blank');
  };

  const mainClassName = cssClassNames(props.square ? 'squareNativePreview' : 'wideNativePreview', {
    showErrorBorder: props.errorBorderHint,
    showSolidBorder: props.url || props.file,
    showPointerCursor: props.bannerUrl
  });

  return (
    <div
      className={mainClassName}
      onClick={props.bannerUrl ? openBannerUrl : undefined}
    >
      <div className={styles.nativePreviewImg}>
        <ImagePreview
          size={{
            width: props.square ? 126 : 315,
            height: props.square ? 126 : 164
          }}
          url={props.url}
          file={props.file}
          border={false}
          config={{
            showName: false,
            showSize: false
          }}
        />
      </div>
      <div className={styles.nativePreviewInfo}>
        <div className={styles.previewAdTitle}>
          <div>
            {_.get(props, 'creativeValues.title')}
          </div>
        </div>
        <div className={styles.previewAdDescription}>
          <div>
            {_.get(props, 'creativeValues.desc')}
          </div>
        </div>
        <div className={styles.previewSponsored}>
          {i18n.t('creativeSetupFlow.labels.sponsoredBy')}
          <a href={_.get(props, 'creativeValues.sponsorLink')} target='_blank' rel='noopener noreferrer'>
            {_.get(props, 'creativeValues.sponsor')}
          </a>
        </div>
      </div>
    </div>
  );
};

import React from 'react';
import { Spring, animated } from 'react-spring';
import { SliderProps, SliderState } from './SliderModel';
import styles from './slider.module.scss';
import { ObjectFitContainer } from '../ObjectFitContainer/ObjectFitContainer';

export class Slider extends React.Component<SliderProps, SliderState> {

  handler: number;
  itemProps: any;
  itemPropsSetter: any;
  itemCount: number;

  constructor (props) {
    super(props);
    this.itemCount = React.Children.toArray(this.props.children).length;
    this.handler = this.props.model.event.add((model) => {
      this.setState(model.state);
    });
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add((model) => {
        this.setState(model.state);
      });
    }
  }

  componentWillUnmount () {
    this.props.model.event.remove(this.handler);
  }

  goPrev = () => {
    let targetIndex = this.props.model.state.index - 1;
    if (targetIndex < 0) {
      targetIndex = this.itemCount - 1;
    }

    this.props.model.handleSelect(targetIndex);
  }

  goNext = () => {
    let targetIndex = this.props.model.state.index + 1;
    if (targetIndex >= this.itemCount) {
      targetIndex = 0;
    }

    this.props.model.handleSelect(targetIndex);
  }

  renderContentToFitSize () {
    return React.Children.map(
      this.props.children,
      child =>
      <ObjectFitContainer containerWidth={this.props.model.itemWidth} containerHeight={this.props.model.itemHeight}>
        {child}
      </ObjectFitContainer>
    );
  }

  renderContent () {
    return React.Children.map(
      this.props.children,
      child =>
      <div className={styles.normalChild} style={{ width: this.props.model.itemWidth, height: this.props.model.itemHeight }}>
        {child}
      </div>
    );
  }

  renderIndicator () {
    if (!this.props.children) {
      return <div />;
    }
    return [...Array(React.Children.toArray(this.props.children).length)].map((value, index) => {
      const goTarget = () => {
        this.props.model.handleSelect(index);
      };
      return (
        <div
          key={index}
          className={index === this.props.model.state.index ? [styles.indicator, styles.active].join(' ') : styles.indicator}
          onClick={goTarget}
        />
      );
    });
  }

  render () {
    const model = this.props.model;
    const state = this.props.model.state;
    const easingFunc = t => t < 0.5 ? 8 * t * t * t * t : 1 - 8 * (--t) * t * t * t;
    const controlBtnClasses = model.putControlBtnToBottom ? `${styles.controlBtn} ${styles.bottom}` : styles.controlBtn;
    return (
      <div className={styles.slider}>
        <div className={styles.displayArea}>
          <div className={controlBtnClasses} onClick={this.goPrev}>
            {this.props.prevIcon ? this.props.prevIcon : <div className={styles.prev}/>}
          </div>
          <div className={styles.center} style={{ width: model.itemWidth, height: model.itemHeight }}>
            <Spring
              from={{ x: model.defaultIndex * model.itemWidth }}
              to={{
                x: state.index * model.itemWidth
              }}
              config={{ duration: 300, easing: easingFunc }}
              onRest={model.handleRest}
            >
              {({ x }) => (
                <animated.div className={styles.centerContainer} style={{ transform: x.to((x) => `translate(-${x}px, 0)`) }}>
                  {model.contain && model.itemWidth && model.itemHeight ? this.renderContentToFitSize() : this.renderContent()}
                </animated.div>
              )}
            </Spring>
          </div>
          <div className={controlBtnClasses} onClick={this.goNext}>
            {this.props.nextIcon ? this.props.nextIcon : <div className={styles.next} />}
          </div>
        </div>
        {
          model.indicator &&
          (
            <div className={styles.indicators}>
              {
                this.props.renderIndicator ?
                  this.props.renderIndicator({ activeIndex: this.props.model.state.index, handleSelect: this.props.model.handleSelect }) :
                  this.renderIndicator()
              }
            </div>
          )
        }
      </div>
    );
  }
}

import React, { RefObject } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './advertiserSegmentSource.module.scss';
import { Formik, Field, FormikProps } from 'formik';
import { faPlus, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import i18n from 'i18n';
import { Form, Row, Col, InputGroup } from 'react-bootstrap';
import { AdvertiserSegmentSourceProps, AdvertiserSegmentSourceState } from './AdvertiserSegmentSourceModel';
import Select from 'components/common/Select/Select';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import FormGroup from 'components/common/FormGroup/FormGroup';

const MAX_SOURCE_COUNT = 5;
export class AdvertiserSegmentSource extends React.Component<AdvertiserSegmentSourceProps, AdvertiserSegmentSourceState> {

  handler?: number;
  formikEventHandler?: number;
  formikRef: RefObject<any>;
  constructor (props) {
    super(props);
    this.state = props.model.state;
    this.formikRef = React.createRef();
  }

  componentDidMount () {
    this.handler = this.props.model.event.add(model => {
      this.setState(model.state);
    });
    this.formikEventHandler = this.props.model.formikEvent.add(event => {
      if (event.submitType === 'FORMIK_SUBMIT') {
        this.formikRef.current.submitForm();
      }
    });
    this.props.model.init();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.onUnmount(this.handler, this.formikEventHandler);
      this.handler = this.props.model.event.add(model => {
        this.setState(model.state);
      });
      this.formikEventHandler = this.props.model.formikEvent.add(event => {
        if (event.submitType === 'FORMIK_SUBMIT') {
          this.formikRef.current.submitForm();
        }
      });
      this.props.model.init();
    }
  }

  componentWillUnmount () {
    this.props.model.onUnmount(this.handler, this.formikEventHandler);
  }

  handleSubmit = (formData) => {
    this.props.model.submit(formData);
  }

  validate = () => {};

  renderSourceField = (formProps) => {
    const fieldData = formProps.field.value;
    const onAgencyChange = (newAgency) => {
      formProps.form.setFieldValue(formProps.field.name, {
        agencyId: newAgency,
        advertiserId: undefined
      });
      this.props.model.fetchAdvertisers(newAgency);
    };
    const onAdvertiserChange = (newAdvertiser) => {
      formProps.form.setFieldValue(formProps.field.name, {
        ...formProps.field.value,
        advertiserId: newAdvertiser
      });
    };
    const onRemoveClick = () => {
      const values = { ...formProps.form.values };
      delete values[formProps.field.name];
      const length = Object.keys(formProps.form.values).length;
      const startIndex = +(formProps.field.name);
      for (let i = startIndex + 1; i < length; ++i) {
        values[(i - 1).toString()] = formProps.form.values[i.toString()];
      }
      delete values[(length - 1).toString()];
      formProps.form.setValues(values);
    };
    return (
      <div className={styles.formField}>
        <InputGroup>
          <Select
            className={styles.agencyList}
            options={this.state.agencies}
            name='agencyOptions'
            simpleValue
            value={fieldData.agencyId}
            onChange={onAgencyChange}
            placeholder={i18n.t('advertiserSegmentSource.placeholders.agencies')}
          />
          <InputGroup.Append>
            <FontAwesomeIcon
              className={styles.removeBtn}
              icon={faTimesCircle}
              onClick={onRemoveClick}
            />
          </InputGroup.Append>
        </InputGroup>
        { fieldData.agencyId !== undefined &&
          <Select
            className={styles.advertiserList}
            options={this.state.advertisersMap[fieldData.agencyId]}
            name='advertiserOptions'
            simpleValue
            value={fieldData.advertiserId}
            onChange={onAdvertiserChange}
            placeholder={i18n.t('advertiserSegmentSource.placeholders.advertisers')}
          />
        }
      </div>
    );
  }

  renderForm = (formProps: FormikProps<any>) => {
    const sourceLength = Object.keys(formProps.values).length;
    const addSource = () => {
      formProps.setFieldValue(sourceLength.toString(), { advertiserId: undefined, agencyId: undefined });
    };
    const fields = Object.keys(formProps.values).map(sourceDataKey => {
      const sourceData = formProps.values[sourceDataKey];
      return (
        <FormGroup
          key={`${sourceDataKey}-${sourceData.agencyId}-${sourceData.advertiserId}`}
          controlId={`advertiserSegmentSource-${sourceDataKey}`}
          label={i18n.t('advertiserSegmentSource.labels.advertiserSegmentSource') + (+sourceDataKey + 1)}
        >
          <Field
            name={sourceDataKey}
            render={this.renderSourceField}
          />
        </FormGroup>
      );
    });
    return (
      <Form>
        <Row>
          <Col sm={3}>
            <Form.Label column>
              {i18n.t('advertiserSegmentSource.labels.title')}
            </Form.Label>
          </Col>
          <Col sm={9}>
            { sourceLength < MAX_SOURCE_COUNT &&
              <div
                className={styles.sourceAdder}
                onClick={addSource}
              >
                <FontAwesomeIcon
                  className={styles.addIcon}
                  icon={faPlus}
                />
                <span>{i18n.t('advertiserSegmentSource.labels.addSourceBtn')}</span>
              </div>
            }
          </Col>
        </Row>
        {fields}
      </Form>
    );
  }

  render () {
    return (
      <div className={styles.advertiserSegmentSourceContainer}>
        {this.state.loading && <LoadingIndicator />}
        <Formik
          ref={this.formikRef}
          initialValues={this.props.model.formData}
          onSubmit={this.handleSubmit}
          validate={this.validate}
          render={this.renderForm}
          validateOnBlur={false}
        />
      </div>
    );
  }
}

import React from 'react';
import { Redirect } from 'react-router-dom';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { DefaultOrderManager, OrderManager } from 'core/order/OrderManager';

export class OrderRedirect extends React.PureComponent<{orderId: string, action?: string, orderManager?: OrderManager}, {redirectPath: string | undefined}> {

  constructor (props) {
    super(props);
    this.state = {
      redirectPath: undefined
    };
  }

  componentDidMount () {
    this.getOrder();
  }

  async getOrder () {
    const orderManager = this.props.orderManager ? this.props.orderManager : new DefaultOrderManager();
    const order = await orderManager.getOrderById(this.props.orderId);
    const redirectPath = this.props.action ? `/orders/${order.orderNumber}/${this.props.action}` : `/orders/${order.orderNumber}`;
    this.setState({
      redirectPath
    });
  }

  render () {
    if (this.state.redirectPath) {
      return <Redirect to={this.state.redirectPath} />;
    }
    return (
      <LoadingIndicator />
    );
  }
}

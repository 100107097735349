import axios from 'axios';
import config from 'config';
import { autoLogout, injectToken, updateToken } from './RestClient';

export interface ErrorWebService {
  post (message: string, errorCode: string, email: string): Promise<void>;
}

export class RestfulErrorWebService implements ErrorWebService {
  restClient: any;

  constructor () {
    this.restClient = axios.create({
      baseURL: config.baseURL,
      headers: {
        'Content-Type': 'application/json'
      }
    });
    this.restClient.interceptors.request.use(injectToken);
    this.restClient.interceptors.response.use(updateToken, autoLogout);
  }

  post (message: string, errorCode: string, email: string): Promise<void> {
    const body = {
      version: `v${process.env.REACT_APP_VERSION}`,
      referer: window.location.href,
      message,
      errorCode,
      email
    };
    return this.restClient.post('/v2/errors', body);
  }
}

import React from 'react';
import { FormControl } from 'react-bootstrap';
import _ from 'lodash';
import { withFormikOnChange } from 'hoc';

interface InputProps {
  type?: string;
  disabled?: boolean;
  rows?: number;
  form?: any;
  name?: string;
  className?: any;
  autoComplete?: any;
}

const defaultProps = {
  type: 'text',
  disabled: false,
  rows: 12,
  autoComplete: 'off'
};
const Input: React.FunctionComponent<InputProps> = (
  props
) => {
  const pickProps = _.pick(props, [
    'rows',
    'disabled',
    'type',
    'as',
    'className',
    'value',
    'name',
    'onChange',
    'onBlur',
    'min',
    'max',
    'autoComplete',
    'placeholder'
  ]);

  return <FormControl {...pickProps} />;
};

Input.defaultProps = defaultProps;

export default withFormikOnChange(Input);

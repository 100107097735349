import { Actor, AddonFeatureManager, AgencyManager, DefaultAgencyManager, LocaleMeta } from 'core';
import { AdvertiserManager, DefaultAdvertiserManager } from 'core/advertiser/AdvertiserManager';
import { RtbCampaignManager, DefaultRtbCampaignManager } from 'core/rtbCampaign/RtbCampaignManager';
import DefaultCreativeManager, { CreativeManager } from 'core/creative/CreativeManager';
import { DefaultOrderManager, OrderManager } from 'core/order/OrderManager';
import { ReportDimension, ReportGran, ReportType } from 'core/report/ReportData';
import { DefaultReportManager, ReportManager } from 'core/report/ReportManager';
import i18n from 'i18n';
import { AbstactReportContentModel } from '../ReportContentModel';
import moment from 'moment-timezone';
import { getDataProvider } from 'components/ReportTable/ReportDataProviderFactory';
import { toast } from 'react-toastify';
import { DefaultFbAdSetManager, FbAdSetManager } from 'core/fbAdSet/FbAdSetManager';
import { DefaultTiktokAdGroupManager, TiktokAdGroupManager } from 'core/tiktokAdGroup/TiktokAdGroupManager';

export class ReconciliationReportModel extends AbstactReportContentModel {

  constructor (
    actor: Actor | null,
    localeMeta: LocaleMeta | undefined,
    updateSearchPath: (newSearchPath, replace) => void,
    addonFeatureManager: AddonFeatureManager,
    reportManager: ReportManager = new DefaultReportManager(),
    advertiserManager: AdvertiserManager = new DefaultAdvertiserManager(),
    orderManager: OrderManager = new DefaultOrderManager(),
    creativeManager: CreativeManager = new DefaultCreativeManager(),
    campaignManager: RtbCampaignManager = new DefaultRtbCampaignManager(),
    agencyManager: AgencyManager = new DefaultAgencyManager(),
    fbAdSetManager: FbAdSetManager = new DefaultFbAdSetManager(),
    tiktokAdGroupManager: TiktokAdGroupManager = new DefaultTiktokAdGroupManager()
  ) {
    super(
      actor,
      localeMeta,
      updateSearchPath,
      addonFeatureManager,
      reportManager,
      advertiserManager,
      orderManager,
      creativeManager,
      campaignManager,
      agencyManager,
      undefined,
      fbAdSetManager,
      tiktokAdGroupManager
    );
    this.reportType = this.defaultReportType;
    this.dimension = this.defaultReportDimension;
    this.gran = this.defaultReportGran;
    this.dataProvider = getDataProvider(this.reportType, this.queryDataWithFilter, this.onDateClick, this.onEditClick);
    this.from = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss');
    this.to = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss');
    this.filterOptionSources[ReportDimension.AGENCY] = async () =>
      // (await agencyManager.getAgenciesOptions('company.rebate')) TODO do not check addonFeature temporary
      (await agencyManager.getAgenciesOptions())
        .map(this.optionValueToString);
  }

  get needSpecifyAgency () {
    return this.isSysAdmin;
  }

  get defaultReportType () {
    return ReportType.REBATE;
  }

  get defaultReportDimension () {
    return ReportDimension.MONTH;
  }

  get defaultReportGran () {
    return ReportGran.MONTH;
  }

  get reportTypes () {
    return [{
      label: i18n.t(`report.labels.${ReportType.REBATE}Type`),
      value: ReportType.REBATE
    }];
  }

  get reportGrans () {
    return Object.values(ReportGran)
      .filter(reportGran => reportGran !== ReportGran.HOUR)
      .map(reportGran => {
        return {
          label: i18n.t(`report.labels.${reportGran}`),
          value: reportGran
        };
      }
    );
  }

  get validDimensions () {
    return [ReportDimension.MONTH, ...this.state.tableDimensions];
  }

  download = async () => {
    if (this.isSysAdmin && !this.filter[ReportDimension.AGENCY]) {
      return;
    }
    this.updateState(true);
    try {
      await this.reportManager.downloadRebateReport(
        this.state.from,
        this.isSysAdmin ? this.filter[ReportDimension.AGENCY] : undefined
      );
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
    }
    this.updateState(false);
  }

  canFilterSelect = (filterName: string) => {
    if (this.isSysAdmin) {
      return filterName === ReportDimension.AGENCY;
    }
    return true;
  }

  canFilterRemove = (filterName: string) => {
    return filterName !== ReportDimension.AGENCY;
  }

  initReportData = async () => {
    this.reportType = this.defaultReportType;
    this.dimension = this.defaultReportDimension;
    this.gran = this.defaultReportGran;
    this.dataProvider = getDataProvider(this.reportType, this.queryDataWithFilter, this.onDateClick, this.onEditClick);
    this.filter = {};
    this.from = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss');
    this.to = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss');
    this.searchString = '';
    this.selectedTagFilter = [];
    this.timezone = this.localeMeta ? this.localeMeta.timezone : '+07:00';
    this.getReportData();
  }

  onDateClick = async (date) => {
    this.from = moment(date).startOf(this.gran).format('YYYY-MM-DD HH:mm:ss');
    this.to = moment(date).endOf(this.gran).format('YYYY-MM-DD HH:mm:ss');
    if (this.gran === ReportGran.MONTH) {
      this.gran = ReportGran.DAY;
      this.dimension = ReportDimension.DAY;
    }
    this.updateSearchPath(false);
  }
}

import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import i18n from 'i18next';
import _ from 'lodash';
import { DefaultGoSegmentManager } from 'core/goSegment/GoSegmentManager';
import { CampaignGroupChannel } from 'core/campaignGroup/CampaignGroup';
import { EstimatedAudience } from 'core/goSegment/GoSegment';
import { SavedTargeting } from 'core/limitation/Limitation';
import { SelectOptions } from 'components/common/commonType';

export interface EditLimitationModel {
  readonly state: EditLimitationState;
  readonly event: UpdateEventListener<EditLimitationModel>;
  readonly limitationSetting: any;
  readonly limitationValue: any;
  readonly operationSet: any;
  readonly addonFeature: Array<any>;
  readonly estimateData: EstimatedAudience;
  readonly errors: any;
  readonly taOptionsCache: {[key: string]: SelectOptions[]};
  showTAManagement?: () => void;
  validate: () => any;
  onLimitationChange: () => void;
  setLimitationCanNotNull: (limitationCanNotNull) => void;
  setLimitationSetting: (limitationSetting) => void;
  updateLimitationValue: (limitationValue) => void;
  getSummaryData?: (savedTA: SavedTargeting) => any;
  addLimitation: (operate: string, limitationType: string, label: string, value: string) => void;
  removeLimitation: (operate: string, limitationType: string, value: string) => void;
  getAudienceEstimatedData: () => Promise<void>;
}

export type EditLimitationProps = {
  readonly model: EditLimitationModel;
};

export type EditLimitationState = {
  readonly errors: any;
  readonly loading: boolean;
};

export class DefaultEditLimitationModel implements EditLimitationModel {
  event: FireableUpdateEventListener<EditLimitationModel>;
  errors: any;
  limitationValue: any;
  operationSet: any;
  addonFeature: Array<any>;
  limitationsCanNotNull: any;
  fetchingEstimateData: boolean = false;
  estimateData: EstimatedAudience = {
    estimateReady: false,
    upperBound: 0,
    lowerBound: 0
  };
  taOptionsCache: {[key: string]: SelectOptions[]} = {};
  loading: boolean = false;

  constructor (
    public limitationSetting,
    limitationValue,
    operationSet,
    addonFeature: Array<any>,
    public showTAManagement?: () => void,
    limitationsCanNotNull?: any,
    public channelData?: {
      channel: CampaignGroupChannel,
      audienceLowestThreshold: number,
      accountId?: string,
      channelTargetingGetter?: (limitationValue: any) => any,
      estimatedRequiredFields?: string[]
    },
    private goSegmentManager = new DefaultGoSegmentManager()
  ) {
    this.event = new FireableUpdateEventListener<EditLimitationModel>();
    this.operationSet = operationSet;
    this.addonFeature = addonFeature;
    this.limitationsCanNotNull = limitationsCanNotNull;
    this.setLimitationValue(limitationValue, []);
  }

  get state (): EditLimitationState {
    return {
      errors: this.errors,
      loading: this.loading
    };
  }

  setLimitationCanNotNull (limitationCanNotNull) {
    this.limitationsCanNotNull = limitationCanNotNull;
  }

  setLimitationSetting (limitationSetting) {
    this.limitationSetting = limitationSetting;
  }

  updateLimitationValue (limitationValue) {
    const keepOriginTypes = this.limitationSetting
      .filter(setting => setting.disable === true)
      .map(setting => setting.name);
    this.setLimitationValue(limitationValue, keepOriginTypes);
    this.onLimitationChange();
  }

  setLimitationValue (limitationValue, keepOriginTypes: string[]) {
    if (!limitationValue) {
      return;
    }
    const result = {};
    const operates = Object.keys(limitationValue);
    const validOperates = _.chain(this.operationSet['need'])
      .concat(this.operationSet['notNeed'], this.operationSet['other'] ? 'other' : undefined)
      .flatten()
      .value();
    operates.forEach(operate => {
      if (!validOperates.includes(operate)) {
        return;
      }
      if (operate === 'other') {
        // dealId...
        result[operate] = [...limitationValue[operate]];
        return;
      }
      const validateTypes = this.limitationSetting
        .filter(setting =>
          (!!setting.supportOperates && setting.supportOperates.includes(operate)) &&
          (setting.ignoreAddonFeature || this.addonFeature.includes(setting.addonFeature))
        )
        .map(setting => setting.name)
        .concat(['age_min', 'age_max', 'genders', 'gender']);
      const keepOriginLimitatons = this.limitationValue && this.limitationValue[operate] ? this.limitationValue[operate].filter(limitation => keepOriginTypes.includes(limitation.type)) : [];
      const limitationsToUpdate = limitationValue[operate]
        .filter(limitation => validateTypes.includes(limitation.type) && !keepOriginTypes.includes(limitation.type));
      const validLimitations = [...limitationsToUpdate, ...keepOriginLimitatons];
      if (validLimitations.length > 0) {
        result[operate] = validLimitations;
      }
    });
    this.limitationValue = result;
  }

  getOp = (operate) => {
    switch (operate) {
      case 'include':
        return 'inc';
      case 'exclude':
        return 'exc';
      case 'preferred':
        return 'Preferred';
      case 'nonPreferred':
        return 'NonPreferred';
      default:
        return 'exc';
    }
  }

  addLimitation = (operate: string, limitationType: string, label: string, value: string) => {
    const newLimitation = {
      op: this.getOp(operate),
      type: limitationType,
      value: [{
        label: label,
        value: value
      }]
    };
    if (!this.limitationValue[operate]) {
      this.limitationValue[operate] = [newLimitation];
    } else {
      const limitation = this.limitationValue[operate].find(limitation => limitation.type === limitationType);
      if (limitation) {
        const target = limitation.value.find(limit => {
          return limit.value === value;
        });
        if (!target) {
          limitation.value.push({
            label: label,
            value: value
          });
        }
      } else {
        this.limitationValue[operate].push(newLimitation);
      }
    }
    this.onLimitationChange();
  }

  removeLimitation = (operate: string, limitationType: string, value: string) => {
    if (!this.limitationValue[operate]) {
      return;
    }
    const limitation = this.limitationValue[operate].find(limitation => limitation.type === limitationType);
    if (!limitation) {
      return;
    }
    _.remove(limitation.value, (limitationOption: any) => limitationOption.value === value);
    if (limitation.value.length === 0) {
      _.remove(this.limitationValue[operate], (limitation: any) => limitation.type === limitationType);
    }
    this.onLimitationChange();
  }

  findRepeated (type, operation, limitationValue, allLimitation) {
    if (!Array.isArray(limitationValue)) {
      return [];
    }
    let repeatedValue: any[] = [];
    Object.keys(allLimitation).forEach(key => {
      if (key === operation) {
        return [];
      }
      const limitationsOfOperation = _.get(_.keyBy(allLimitation[key], 'type')[type], 'value', []);
      const values = limitationValue.map(value => value.value);
      repeatedValue.push(
        limitationsOfOperation.find(limitationOfOperation => values.includes(limitationOfOperation.value))
      );
    });
    return _.compact(repeatedValue);
  }

  onLimitationChange = async () => {
    const errors = await this.validate();
    if (_.isEmpty(errors)) {
      this.getAudienceEstimatedData();
      this.updateState();
    }
  }

  validateLimitationValue = async (setting, limitation) => {
    if (setting.ignoreValidateOption) {
      return undefined;
    }
    const taOptions = this.taOptionsCache[setting.name] ? this.taOptionsCache[setting.name] : await setting.cb();
    this.taOptionsCache[setting.name] = taOptions;
    const validValue = _.chain(taOptions)
      .concat(taOptions.map(value => value.options ? value.options : []))
      .flatten()
      .map(value => value.value.toString())
      .value();
    const invalidValue = limitation.value.filter(value => !validValue.includes(value.value.toString()));
    return invalidValue.length > 0 ? i18n.t('editLimitation.errors.someSelectedOptionInvalid', { name: i18n.t(setting.title).toLowerCase() }) : undefined;
  }

  validateRequiredLimitation = (operate, limitaionsOfOperate) => {
    const requiredSettings = this.limitationSetting.filter(setting => {
      if (setting.requiredOperate && setting.requiredOperate.includes(operate)) {
        return true;
      }
      if (setting.showWithLimitation) {
        return limitaionsOfOperate.find(limitation => setting.showWithLimitation.includes(limitation.type));
      }
      return false;
    });
    let errors = {};
    if (requiredSettings.length > 0) {
      requiredSettings.forEach(setting => {
        const limitation = limitaionsOfOperate.find(limitation => setting.name === limitation.type);
        if (!limitation) {
          const validator = typeof setting.validator === 'function' ? setting.validator : setting.validator[operate];
          const error = validator ? validator(operate, undefined, this.limitationValue) : i18n.t('formValidate.labels.emptyError');
          _.set(errors, `${operate}.${setting.name}`, error);
        }
      });
    }
    return errors;
  }

  validate = async () => {
    this.updateState(true);
    const errors = {};
    if (!this.limitationValue) {
      return errors;
    }
    const operates = Object.keys(this.limitationValue);
    for (const operate of operates) {
      const limitaionsOfOperate = this.limitationValue[operate] ? this.limitationValue[operate] : [];
      if (this.limitationsCanNotNull && operate in this.limitationsCanNotNull) {
        const limitationsCanNotNullOfOperate = this.limitationsCanNotNull[operate];
        const errorLimitations = _.filter(limitationsCanNotNullOfOperate, canNotNull => {
          const targetLimitation = _.find(limitaionsOfOperate, (limitation) => limitation.type === canNotNull);
          return !targetLimitation || targetLimitation.value.length === 0;
        });
        if (errorLimitations.length > 0) {
          errorLimitations.forEach(errorLimitation => {
            _.set(errors, `${operate}.${errorLimitation}`, i18n.t('formValidate.labels.emptyError'));
          });
        }
      }
      for (const limitation of limitaionsOfOperate) {
        const setting = this.limitationSetting.find(setting => setting.name === limitation.type);
        if (setting && setting.validator) {
          const validator = typeof setting.validator === 'function' ? setting.validator : setting.validator[operate];
          const error = validator ? validator(operate, limitation.value, this.limitationValue) : undefined;
          error && _.set(errors, `${operate}.${limitation.type}`, error);
        }
        if (setting && setting.cb) {
          const error = await this.validateLimitationValue(setting, limitation);
          error && _.set(errors, `${operate}.${limitation.type}`, error);
        }
        const repeatValue = this.findRepeated(limitation.type, operate, limitation.value, this.limitationValue);
        repeatValue.length > 0 && _.set(errors, `${operate}.${limitation.type}`, i18n.t('editLimitation.errors.repeat'));
      }
      const requiredErrors = this.validateRequiredLimitation(operate, limitaionsOfOperate);
      _.assign(errors, requiredErrors);
    }
    this.errors = errors;
    this.updateState();
    return errors;
  }

  getAudienceEstimatedData = async () => {
    if (!this.channelData) {
      return;
    }
    const { channel, channelTargetingGetter, accountId, estimatedRequiredFields } = this.channelData;
    const channelTargeting = channelTargetingGetter ?
      channelTargetingGetter(this.limitationValue) :
      this.limitationValue;
    const currentTargetingNames = Object.keys(channelTargeting);
    if (estimatedRequiredFields && estimatedRequiredFields.some(required => !currentTargetingNames.includes(required))) {
      return;
    }
    this.fetchingEstimateData = true;
    try {
      this.estimateData = await this.goSegmentManager.getGoSegmentEstimate(
        channel,
        channelTargeting,
        accountId
      );
    } catch (e) {
      this.estimateData = {
        estimateReady: false,
        upperBound: 0,
        lowerBound: 0
      };
    }
    this.fetchingEstimateData = false;
    this.updateState();
  }

  setAudienceEstimatedData (estimateData) {
    if (!estimateData) {
      return;
    }
    this.estimateData = estimateData;
    this.updateState();
  }

  updateState (loading = false) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}

// TODO: present type of the response.data.records returns 'label', 'value'
//  => could modify to 'id', 'name'

export type TiktokAdAccount = {
  id: string;
  name: string;
  status: TiktokAdAccountStatus;
};

export type AdvertiserTiktokAdAccount = {
  value: string | number;
  label: string;
  status: AdvertiserTiktokAdAccountStatus;
};

export enum TiktokAdAccountStatus {
  STATUS_ENABLE = 'STATUS_ENABLE'
  // DEACTIVATED
}

export enum AdvertiserTiktokAdAccountStatus {
  ACTIVE = 1,
  DEACTIVATED
}

import React from 'react';
import { OrderFormState, OrderFormProps } from './OrderFormModel';
import { Formik, FormikProps } from 'formik';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { Form } from 'react-bootstrap';
import i18n from 'i18next';
import styles from './orderForm.module.scss';
import { FinalReportProjectType, Order } from 'core/order/Order';
import _ from 'lodash';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import NavigationPrompt from 'components/common/NavigationPrompt/NavigationPrompt';
import { FormikField } from 'components/common/formik/FormikField';
import { Dayparts } from 'components/Dayparts/Dayparts';
import { TitleWithBreadcrumbs } from 'components/TitleArea/TitleWithBreadcrumbs';
import moment from 'moment';
import { createSelectOptionsFromEnum } from 'utils/SelectOptionsUtils';

export class OrderForm extends React.PureComponent<RouteComponentProps<any> & OrderFormProps, OrderFormState> {

  handler: any;

  componentDidMount () {
    this.handler = this.props.model.event.add(model => {
      this.setState(model.state);
    });
    this.props.model.init();
  }

  componentWillUnmount () {
    this.props.model.onUnmount(this.handler);
  }

  handleSubmit = () => {
    const errors = this.props.model.validateAndSetError();
    if (!_.isEmpty(errors)) {
      return;
    }
    this.props.model.submit();
  }

  cancelSubmit = () => {
    this.props.history.goBack();
  }

  validate = (order: Order): any => {
    return this.props.model.validate(order);
  }

  renderNotSupportFinalReportHint = () => (
    <div>
      Final Reports are not supported by legacy ad orders. Please create a new Ad Order.
    </div>
  )

  renderProjectNameHint = (projectName?: string) => {
    if (!projectName || projectName === '') {
      return;
    }

    return this.props.model.isFinalReportProjectNameExist(projectName) ? (
      <span className={styles.warning}>
        {i18n.t('orderForm.hints.finalReport.projectName')}
      </span>
    ) : '';
  }

  renderForm = (formProps: FormikProps<Order>) => {
    this.props.model.setFormikProps(formProps);
    const Component: typeof React.Component = this.props.model.contentComponent;
    const endDate = formProps.values.endDate;
    return (
      <Form>
        <>
          <fieldset>
            <legend>
              <span>{i18n.t('orderForm.labels.basic')}</span>
            </legend>
            <div className={styles.fieldContent}>
              <Component {...this.props} formik={formProps}/>
            </div>
          </fieldset>
          {this.props.model.isOutdoorOrder &&
            <fieldset>
              <legend>
                <span>{i18n.t('orderForm.labels.outdoor')}</span>
              </legend>
              <div className={styles.fieldContent}>
                <FormikField.Input
                  label={i18n.t('orderForm.labels.orderPrice')}
                  name='orderPrice'
                  type='number'
                />
                <FormikField.Input
                  label={i18n.t('orderForm.labels.creativeDuration')}
                  name='creativeDuration'
                  type='number'
                />
                <FormikField.Custom
                  label={i18n.t('orderForm.labels.daypart')}
                  name='dayPart'
                  component={Dayparts}
                />
              </div>
            </fieldset>
          }
          {
            <fieldset>
              <legend>
                <span>{i18n.t('orderForm.titles.finalReport')}</span>
              </legend>
              <div className={styles.fieldContent}>
                {
                  _.get(this.props.model.order, 'finalReportProjectType') ?
                  <>
                    <FormikField.Input
                      label={i18n.t('orderForm.labels.finalReport.advertiserName')}
                      name='finalReportAdvertiserName'
                    />
                    <FormikField.Select
                      label={i18n.t('orderForm.labels.finalReport.projectType')}
                      name='finalReportProjectType'
                      simpleValue
                      options={createSelectOptionsFromEnum(FinalReportProjectType, 'finalReport.enums.')}
                    />
                    {_.get(formProps.values, 'finalReportProjectType') !== FinalReportProjectType.OTHERS &&
                      <FormikField.Input
                        label={i18n.t('orderForm.labels.finalReport.projectName')}
                        name='finalReportProjectName'
                        hint={this.renderProjectNameHint(_.get(formProps.values, 'finalReportProjectName'))}
                      />
                    }
                    <FormikField.Input
                      label={i18n.t('orderForm.labels.finalReport.targetImpressions')}
                      name='finalReportTargetImpressions'
                      type='number'
                    />
                    <FormikField.Tags
                      label={i18n.t('orderForm.labels.finalReport.receivers')}
                      name='finalReportReceivers'
                      placeholder={i18n.t('orderForm.placeholders.finalReport.receivers')}
                    />
                    <FormikField.DatePicker
                      label={i18n.t('orderForm.labels.finalReport.sendOutDate')}
                      name='finalReportSendOutDate'
                      showTimePicker={false}
                      minDate={moment(endDate).startOf('day').add(2, 'day').format('YYYY-MM-DD')}
                    />
                  </> :
                  <FormikField.Custom
                    label=''
                    render={this.renderNotSupportFinalReportHint}
                  />
                }
              </div>
            </fieldset>
          }
        </>
        <div className={styles.buttonArea}>
          <button
            type='button'
            className='btn btn-primary btn-sm'
            onClick={this.handleSubmit}
          >
            {i18n.t('common.buttons.submit')}
          </button>
          <button
            type='button'
            className='btn btn-secondary btn-sm'
            onClick={this.cancelSubmit}
          >
            {i18n.t('common.buttons.cancel')}
          </button>
        </div>
      </Form>
    );
  }

  render () {
    const model = this.props.model;
    const state = model.state;
    if (state.redirectPath) {
      return <Redirect to={state.redirectPath} />;
    }
    if (state.loading || !model.order) {
      return <LoadingIndicator />;
    }
    return (
      <div className={styles.orderFormContainer}>
        <NavigationPrompt />
        <div className={styles.titleArea}>
          <TitleWithBreadcrumbs
            title={model.title}
            routes={model.breadcrumbs}
          />
        </div>
        <div className={styles.titleBottomLine} />
        <Formik
          initialValues={model.order}
          enableReinitialize
          onSubmit={this.handleSubmit}
          validate={this.validate}
          render={this.renderForm}
          validateOnBlur={false}
        />
      </div>
    );
  }
}

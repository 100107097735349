import { useState, useCallback } from 'react';
import _ from 'lodash';
import {
  Column,
  COLUMN_PRIORITY
} from 'enum/FinalReport';

export const useFetchData = () => {
  const [error, setError] = useState<any>();

  const retryFetch = useCallback(async (fetchMethod, callback, tryCount) => {
    try {
      if (tryCount === 0) {
        throw Error;
      }
      const response = await fetchMethod();
      setError(null);
      // console.log('retry response', response);
      if (response.status === 'WAITING') {
        setTimeout(() => {
          retryFetch(fetchMethod, callback, tryCount - 1);
        }, 10000);
      } else {
        callback && callback(response);
      }
    } catch (err) {
      if (tryCount === 0) {
        console.error(err);
        setError(err);
      }
    }
  }, []);

  return [error, retryFetch];
};

export const handleNumberOrText = (value: number | string, decimals= 2, isPercentage= false) => {
  if (typeof value !== 'number') {
    return value;
  } else {
    if (isPercentage) {
      let percentage = (value * 100).toFixed(decimals);
      return `${percentage}%`;
    } else {
      return Math.round(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  }
};

export const handleStatistics = (type: string, payload: number, decimals= 2, currency= 'IDR') => {
  switch (type) {
    case Column.NEW_USERS:
    case Column.LAPSE_USERS:
    case Column.EXISTING_USERS:
    case Column.CTR:
      return handleNumberOrText(payload, decimals, true);
    case Column.CPC:
    case Column.CPM:
      return payload !== 0 ? `${currency} ${handleNumberOrText(payload)}` : 'n/a';
    default:
      break;
  }

  return handleNumberOrText(payload);
};

export const preprocessReportsData = (source: any, path: string, group: string, templateColumns: string[], notAvailableColumns: string[] = []) => {
  let columns = [group, ...templateColumns];
  let rows: any[] = [];

  const sortColumn = (columns) => {
    return columns.sort((key1, key2) =>
      COLUMN_PRIORITY.indexOf(key1) > COLUMN_PRIORITY.indexOf(key2) ? 1 : -1
    );
  };
  const sortReportRow = (row) => {
    return sortColumn(Object.keys(row)).reduce((result, key) => {
      result[key] = row[key];
      return result;
    }, {});
  };

  _.forEach(_.get(source, path, []), ({ dimension, ...information }) => {
    columns = sortColumn([group, ...templateColumns]);
    rows = [
      ...rows,
      {
        [group]: dimension,
        ..._.reduce(templateColumns, (result, column) => ({
          ...result,
          [column]: information[column]
        }), {}),
        ..._.reduce(notAvailableColumns, (result, column) => ({
          ...result,
          [column]: 'n/a'
        }), {})
      }
    ].map(row => sortReportRow(row));
  });

  return {
    columns,
    rows
  };
};

export const generateChartConfigs = (data) => {
  const uniquePlatforms = _.uniq(_.map(_.get(data, 'chart_config'), info => _.get(info, 'platform', '')));
  const uniqueDates = _.uniq(_.map(_.get(data, 'chart_config'), info => _.get(info, 'date', '')));
  const sortedDates = uniqueDates.sort((date1, date2) => {
    let date1Object = new Date(date1);
    let date2Object = new Date(date2);

    if (date1Object.getFullYear() !== date2Object.getFullYear()) {
      return date1Object.getFullYear() - date2Object.getFullYear();
    }
    if (date1Object.getMonth() !== date2Object.getMonth()) {
      return date1Object.getMonth() - date2Object.getMonth();
    }
    return date1Object.getDate() - date2Object.getDate();
  });

  const startDate = new Date(sortedDates[0]);
  const endDate = new Date(sortedDates[sortedDates.length - 1]);
  let scale = '';
  let labels: string[] = [];
  let contents: { name: string, value: number[] }[] = [];
  let totals: number[] = [];
  let maxValueScaleX = 0;
  let maxValueScaleY = 0;

  let date = new Date(startDate);
  while (date <= endDate) {
    labels = [
      ...labels,
      `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
    ];
    date.setDate(date.getDate() + 1);
  }

  _.forEach(uniquePlatforms, platform => {
    contents = [
      ...contents,
      {
        name: platform,
        value: Array(labels.length).fill(0)
      }
    ];
  });

  const totalImpressions = Array(labels.length).fill(0);
  const totalClicks = Array(labels.length).fill(0);

  _.forEach(_.get(data, 'chart_config'), info => {
    let indexOfDate = _.indexOf(labels, info['date']);
    let indexOfPlatform = _.findIndex(contents, mapping => mapping['name'] === info['platform']);
    if (indexOfDate !== -1) {
      contents[indexOfPlatform]['value'][indexOfDate] = info['impressions'];
      totalImpressions[indexOfDate] += info['impressions'];
      totalClicks[indexOfDate] += info['clicks'];
    }
  });

  const SCALE = totalImpressions.reduce((a, b) => a + b, 0) / totalImpressions.length < 100000 ? 1000 : 1000000;
  scale = SCALE === 1000 ? '(in thsnd.)' : '(in mil.)';

  contents = _.map(contents, content => {
    return {
      name: content['name'],
      value: _.map(content['value'], value => parseFloat((value / SCALE).toFixed(3)))
    };
  });

  const ctr = _.map(totalImpressions, (impressions, index) => {
    let initCtr = impressions !== 0 ? totalClicks[index] / impressions : 0;
    let parsedDigit = 1 - Math.floor(Math.log(initCtr) / Math.log(10));
    parsedDigit = (parsedDigit <= 100 && parsedDigit >= 0) ? parsedDigit : 3;
    let processedCtr = parseFloat(initCtr.toFixed(parsedDigit)) * 100;

    maxValueScaleY = Math.max(maxValueScaleY, processedCtr);

    return parseFloat(processedCtr.toFixed(parsedDigit));
  });

  contents = _.isEmpty(contents) ? contents : [
    ...contents,
    {
      name: Column.CTR,
      value: ctr
    }
  ];

  totals = _.map(labels, (_1, labelIndex) => {
    let total = 0;
    _.forEach(contents, (content) => {
      if (content['name'] !== Column.CTR) {
        total += content['value'][labelIndex];
      }
    });
    maxValueScaleX = Math.max(maxValueScaleX, total);

    return parseFloat(total.toFixed(3));
  });

  return {
    scale,
    labels,
    contents,
    totals,
    maxValueScaleX,
    maxValueScaleY
  };
};

export const isOverFlown = (text: string) => {
  let SCALE = 13;
  return text.length >= SCALE;
};

export const isTableHasOverFlown = (rows: string[], group: string) => {
  let hasOverFlown = false;

  _.forEach(rows, (row) => {
    _.forEach(Object.keys(row), (property) => {
      if (property !== group) {
        let text = handleStatistics(property, _.get(row, property));
        hasOverFlown = isOverFlown(text) || hasOverFlown;
      }
    });
  });

  return hasOverFlown;
};

import React, { useState } from 'react';
import { SelectOptions } from 'components/common/commonType';
import { CampaignGroup } from 'core/campaignGroup/CampaignGroup';
import DefaultCampaignManager from 'core/campaignGroup/CampaignGroupManager';
import { useCallAPI } from 'hooks/useCallAPI';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { Formik } from 'formik';
import { FormikField } from 'components/common/formik/FormikField';
import i18n from 'i18n';
import styles from './draftSetupTarget.module.scss';
import { get, isEmpty, omitBy, partial } from 'lodash';
import { validateInteger, validateMinimum } from 'utils/ValidateUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import { DraftCampaignGroupOption } from './DraftCampaignGroupOption';

const defaultCampaignGroupManager = new DefaultCampaignManager();
type DraftTarget = {
  id: number,
  order?: {
    name: string,
    id: number
  } | null,
  campaignGroup?: {
    name: string,
    id: number
  } | null,
  numberOfCopies: number,
  includeBinding: boolean,
  hasError: boolean
};

export const DraftSetupTarget: React.FC<{
  draftTarget: DraftTarget,
  orderOptions: SelectOptions[],
  campaignGroupsOptionsCache: {[key: number]: SelectOptions[]}
  selectedCampaignGroups: number[]
  campaignGroupsFilter: (campaignGroups: CampaignGroup[]) => CampaignGroup[],
  onTargetChange: (data: DraftTarget) => void,
  onRemove?: () => void
}> = ({
  draftTarget,
  orderOptions,
  campaignGroupsOptionsCache,
  selectedCampaignGroups,
  campaignGroupsFilter,
  onTargetChange,
  onRemove
}) => {

  const { loading, callAPIs } = useCallAPI();
  const orderId = get(draftTarget, 'order.value');
  const addDisabledPropsToOptions = (campaignGroupOptions: SelectOptions[]) => {
    return campaignGroupOptions.map(option => ({
      ...option,
      disabled: selectedCampaignGroups.includes(+option.value)
    }));
  };
  const cachedCampaignGroupOptions = addDisabledPropsToOptions(orderId && campaignGroupsOptionsCache[orderId] ? campaignGroupsOptionsCache[orderId] : []);
  const [campaignGroupOptions, setCampaignGroupOptions] = useState<(SelectOptions & { disabled: boolean })[]>(cachedCampaignGroupOptions);

  const submit = () => {};

  const validate = values => {
    const numberOfCopies = values.numberOfCopies;
    return omitBy({
      numberOfCopies: validateInteger(numberOfCopies) || validateMinimum(numberOfCopies, 1)
    }, isEmpty);
  };

  const handleFieldChange = (fieldName, value) => {
    const newTarget = {
      ...draftTarget,
      [fieldName]: value
    };
    newTarget.campaignGroup = fieldName === 'order' ? null : newTarget.campaignGroup;
    const errors = validate(newTarget);
    onTargetChange({
      ...newTarget,
      hasError: !isEmpty(errors)
    });
  };

  const fetchCampaignGroupOptions = (orderId) => {
    const campaignGroupOptions = campaignGroupsOptionsCache[orderId];
    if (campaignGroupOptions) {
      setCampaignGroupOptions(addDisabledPropsToOptions(campaignGroupOptions));
      return;
    }
    callAPIs([() => defaultCampaignGroupManager.getCampaignGroups(orderId)], (campaignGroups: CampaignGroup[]) => {
      const campaignGroupOptions = campaignGroupsFilter(campaignGroups).map(campaignGroup => ({
        label: campaignGroup.name,
        value: campaignGroup.groupId
      }));
      campaignGroupsOptionsCache[orderId] = campaignGroupOptions;
      setCampaignGroupOptions(addDisabledPropsToOptions(campaignGroupOptions));
    });
  };

  return (
    <Formik
      initialValues={draftTarget}
      enableReinitialize={false}
      onSubmit={submit}
      validate={validate}
      validateOnBlur={false}
    >
      {({ setFieldValue, values }) => {
        const onOrderChange = (orderOption) => {
          handleFieldChange('order', orderOption);
          setFieldValue('campaignGroup', null);
          fetchCampaignGroupOptions(orderOption.value);
        };
        return (
          <div className={styles.draftTargetForm}>
            {loading && <LoadingIndicator/>}
            { onRemove &&
              <FontAwesomeIcon
                className={styles.closeBtn}
                icon={faTimes}
                onClick={onRemove}
              />
            }
            <FormikField.Select
              label={i18n.t('draftCreateModal.labels.order')}
              options={orderOptions}
              name='order'
              onChange={onOrderChange}
            />
            <FormikField.Select
              label={i18n.t('draftCreateModal.labels.campaignGroup')}
              options={campaignGroupOptions}
              optionComponent={DraftCampaignGroupOption}
              name='campaignGroup'
              onChange={partial(handleFieldChange, 'campaignGroup')}
              hint={i18n.t('draftCreateModal.hints.campaignGroupNeedSameChannel')}
            />
            <FormikField.Input
              label={i18n.t('draftCreateModal.labels.numberOfCopies')}
              name='numberOfCopies'
              type='number'
              min={1}
              onChange={partial(handleFieldChange, 'numberOfCopies')}
            />
            <FormikField.Checkbox
              label={''}
              id={`includeBinding-${draftTarget.id}`}
              checkboxLabel={i18n.t('draftCreateModal.labels.includeBinding')}
              name={'includeBinding'}
              onChange={partial(handleFieldChange, 'includeBinding')}
              postfix={(
                <IconWithTooltip
                  className={styles.bindHint}
                  icon={faInfoCircle}
                  tooltipProps={{
                    id: `draftCreateModalBindingHint-${draftTarget.id}`,
                    tooltip: i18n.t('draftCreateModal.hints.cannotBindHint')
                  }}
                />
              )}
            />
          </div>
        );
      }}
    </Formik>
  );
};

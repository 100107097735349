import React, { lazy, Suspense } from 'react';
import i18n from 'i18n';
import { ReportDimension, Metrics } from 'core/report/ReportData';
import styles from './reportContent.module.scss';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faTimesCircle, faPlus, faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { ReportTable } from 'components/ReportTable/ReportTable';
import { SearchBar } from 'components/common/SearchBar';
import classNames from 'classnames/bind';
import { REPORT_COLUMNS } from 'components/ReportTable/reportTableColumnSettings';
import FormGroup from 'components/common/FormGroup/FormGroup';
import { NavDropdown, InputGroup, Button } from 'react-bootstrap';
import Select from 'components/common/Select/Select';
import { StickableTableContainer } from 'containers/StickableTableContainer/StickableTableContainer';
import { ReportContentProps, ReportContentState } from './ReportContentModel';
import { Redirect } from 'react-router-dom';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
const ReportChart = lazy(() =>
  import('components/ReportChart/ReportChart').then(({ ReportChart }) => ({
    default: ReportChart
  }))
);
export class ReportContent extends React.PureComponent<ReportContentProps, ReportContentState> {

  cssClassNames: any;
  handler?: number;
  searchbarRef: any;

  constructor (props) {
    super(props);
    this.cssClassNames = classNames.bind(styles);
    this.searchbarRef = React.createRef();
  }

  componentDidMount () {
    this.handler = this.props.model.event.add(model => {
      this.setState(model.state);
    });
    const reportParams = new URLSearchParams(this.props.search);
    this.props.model.updateReportData(reportParams);
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      this.handler && prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add(model => {
        this.setState(model.state);
      });
      const reportParams = new URLSearchParams(this.props.search);
      this.props.model.updateReportData(reportParams);
    } else if (prevProps.search !== this.props.search) {
      if (_.isEmpty(this.props.search)) {
        if (this.searchbarRef.current) {
          this.searchbarRef.current.clear();
        }
        this.props.model.initReportData();
      } else {
        const reportParams = new URLSearchParams(this.props.search);
        this.props.model.updateReportData(reportParams);
      }
    }
  }

  componentWillUnmount () {
    this.handler && this.props.model.event.remove(this.handler);
  }

  renderTags () {
    const model = this.props.model;
    return model.state.tags.map(tag => {
      const onFilterClick = () => {
        model.handleOnTagFilterClicked(tag);
      };
      const tagFilterClass = this.cssClassNames('tagFilter', {
        selected: model.state.selectedTagFilter.includes(tag)
      });
      return (
        <div key={tag} className={tagFilterClass} onClick={onFilterClick}>
          {tag}
        </div>
      );
    });
  }

  renderDownload () {
    return (
      <span onClick={this.props.model.download} className={styles.downloadWording}>
        <FontAwesomeIcon icon={faDownload} className={styles.downloadIcon} />
        {i18n.t('report.labels.download')}
      </span>
    );
  }

  renderFilters () {
    const {
      getFilterOptions,
      addFilter,
      removeFilter,
      canFilterSelect,
      canFilterRemove
    } = this.props.model;
    const filterObject = this.props.model.state.filter;
    return Object.keys(filterObject).map(filter => (
      <FormGroup
        key={filter}
        label={i18n.t(`report.labels.${filter}`)}
        controlId={`${filter}Filter`}
      >
        <InputGroup>
          {
            <Select
              className={styles.filterList}
              options={getFilterOptions(filter)}
              name={`${filter}Filter`}
              simpleValue
              isDisabled={!canFilterSelect(filter)}
              value={filterObject[filter]}
              onChange={_.partial(addFilter, filter)}
            />
          }
          {canFilterRemove(filter) &&
            <InputGroup.Append>
              <FontAwesomeIcon
                className={styles.removeFilterBtn}
                icon={faTimesCircle}
                onClick={_.partial(removeFilter, filter)}
              />
            </InputGroup.Append>
          }
        </InputGroup>
      </FormGroup>
    ));
  }

  renderFilterTypeList () {
    const {
      tableDimensions,
      filter
    } = this.props.model.state;
    const validDimesions = tableDimensions.filter(dimension => dimension !== ReportDimension.DAY);
    const filtersCanAdd = _.xor(Object.keys(filter), validDimesions);
    const items = filtersCanAdd.map(filterType => (
      <NavDropdown.Item
        key={filterType}
        onClick={_.partial(this.props.model.addEmptyFilter, filterType)}
      >
        {i18n.t(`report.labels.${filterType}`)}
      </NavDropdown.Item>
    ));
    return (
      filtersCanAdd.length > 0 ?
        <FormGroup label={''} controlId='reportFilterAdder'>
          <div className={styles.filterAdder}>
            <FontAwesomeIcon icon={faPlus} />
            <div className={styles.filterToAddList}>
              <NavDropdown title={i18n.t('report.labels.filterCondition')} id='reportFilterList'>
                {items}
              </NavDropdown>
            </div>
          </div>
        </FormGroup> :
        <div />
    );
  }

  render () {
    const {
      dimension,
      reportData,
      tableDimensions,
      tableData,
      tableColumnSettings,
      showReportChart,
      showDimensionSelectArea,
      dayRangeError,
      redirectPath,
      loading
    } = this.props.model.state;
    const {
      isSysAdmin,
      searchString,
      toggleShowReportChart,
      toggleShowDimensionSelectArea,
      updateSearchPath,
      handleOnSearch,
      queryDataWithDimension
    } = this.props.model;
    if (!reportData || tableColumnSettings.length === 0) {
      return <LoadingIndicator />;
    }
    if (redirectPath) {
      return (
        <Redirect to={redirectPath} push />
      );
    }
    const searchBarPlaceholder = dimension === ReportDimension.MONTH || dimension === ReportDimension.DAY || dimension === ReportDimension.HOUR ?
      i18n.t('reportTable.placeholders.searchDate') :
      i18n.t('reportTable.placeholders.searchName');
    const dimensionSelectAreaClass = this.cssClassNames('dimensionSelectArea', {
      show: showDimensionSelectArea
    });
    const reportChartAreaClass = this.cssClassNames('reportChartArea', {
      show: showReportChart
    });
    const CustomDimensionComponent = this.props.customDimensionComponent;
    return (
      <StickableTableContainer>
        <div className={styles.reportContent}>
          {loading && <LoadingIndicator />}
          <div className={styles.titleArea}>
            <div className={styles.title}>
              {i18n.t('report.labels.title')}
            </div>
            <div className={styles.triggerAreaButtons}>
              <div
                className={styles.triggerAreaButton}
                onClick={toggleShowDimensionSelectArea}
              >
                <FontAwesomeIcon icon={showDimensionSelectArea ? faAngleDown : faAngleUp} />
                {i18n.t('report.labels.dimentSelectArea')}
              </div>
              <div
                className={styles.triggerAreaButton}
                onClick={toggleShowReportChart}
              >
                <FontAwesomeIcon icon={showReportChart ? faAngleDown : faAngleUp} />
                {i18n.t('report.labels.reportChartArea')}
              </div>
            </div>
          </div>
          <div className={styles.contentArea}>
            <div className={dimensionSelectAreaClass}>
              {CustomDimensionComponent && <CustomDimensionComponent model={this.props.model} />}
              {this.renderFilters()}
              {!isSysAdmin &&
                this.renderFilterTypeList()
              }
              <FormGroup className={styles.reportSearchBtnContainer} label={''} controlId='reportSearchButton'>
                <Button variant='primary' size='sm' onClick={_.partial(updateSearchPath, false)} disabled={dayRangeError !== undefined}>
                  {i18n.t('report.buttons.search')}
                </Button>
              </FormGroup>
            </div>
            <Suspense fallback={<LoadingIndicator />}>
              <div className={reportChartAreaClass}>
                <ReportChart
                  dimension={reportData.dimension}
                  tableData={tableData}
                  metrics={
                    tableColumnSettings
                      .map(columnSetting => columnSetting.dataField)
                      .filter(column => column !== REPORT_COLUMNS.NAME && column !== REPORT_COLUMNS.EDITBTNS)
                  }
                  defaultMertic1={Metrics.IMPRES}
                  defaultMertic2={Metrics.CLICKS}
                  textDecorators={
                    tableColumnSettings.reduce((acc, setting) => {
                      acc[setting.dataField] = setting.formatExtraData.textDecorator;
                      return acc;
                    }, {})
                  }
                />
              </div>
            </Suspense>
            <div className={styles.filterArea}>
              <SearchBar
                ref={this.searchbarRef}
                model={{
                  placeholder: searchBarPlaceholder,
                  search: handleOnSearch,
                  defaultValue: searchString
                }}
              />
              {this.renderTags()}
            </div>
            <div className={styles.downloadContainer}>{this.renderDownload()}</div>
            <div className={styles.reportTableContainer}>
              <ReportTable
                dimension={dimension}
                dimensions={tableDimensions}
                tableData={tableData}
                columnSettings={tableColumnSettings}
                onDimensionChange={queryDataWithDimension}
              />
            </div>
          </div>
        </div>
      </StickableTableContainer>
    );
  }
}

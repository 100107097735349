import React from 'react';
import i18n from 'i18n';

import styles from './app.module.scss';

import { AppModel, DefaultAppModel, AppState } from './AppModel';
import { PMaxCore, DefaultPMaxCore } from 'core';

import { MainPage } from './pages/MainPage';
import { LoginPage } from './pages/LoginPage';
import { MainPageModel, DefaultMainPageModel } from './pages/MainPage/MainPageModel';
import { LoginPageModel, DefaultLoginPageModel } from './pages/LoginPage/LoginPageModel';
import { FbLoginPageModel } from './pages/LoginPage/FbLoginPageModel';
import { Switch, Route } from 'react-router';
import { alertError } from 'components/AlertDialog';
import _ from 'lodash';
import { ErrorBoundary } from 'components/common/ErrorBoundary/ErrorBoundary';
import { DefaultErrorManager, ErrorManager } from 'core/ErrorManager';
import { ERROR_CODE } from 'core/ErrorCode';
import { UploadProgressManager } from 'UploadProgressManager';
import { toast } from 'react-toastify';
import { RegisterPage } from 'pages/RegisterPage/RegisterPage';
import { ForgotPasswordPage } from 'pages/ForgotPasswordPage/ForgotPasswordPage';
import config from 'config';

export default class App extends React.Component<any, AppState> {

  core: PMaxCore;
  model: AppModel;
  handler: number;
  mainPageModel: MainPageModel;
  loginPageModel: LoginPageModel;
  fbLoginPageModel: LoginPageModel;
  errorManager: ErrorManager = new DefaultErrorManager();
  uploadProgressManager: UploadProgressManager = UploadProgressManager.getInstance();

  constructor (props: any) {
    super(props);
    this.handler = 0;
    this.core = new DefaultPMaxCore();
    this.model = new DefaultAppModel(this.core);
    this.mainPageModel = new DefaultMainPageModel(this.core);
    this.loginPageModel = new DefaultLoginPageModel(this.core.authenticationManager);
    this.fbLoginPageModel = new FbLoginPageModel(this.core.authenticationManager);
    this.state = this.model.state;
    i18n.changeLanguage(this.state.language);
    this.registerErrorHandler();
  }

  componentDidMount () {
    this.handler = this.model.event.add((model) => {
      i18n.changeLanguage(model.state.language);
      this.setState(model.state);
    });
    this.uploadProgressManager.start();
  }

  componentWillUnmount () {
    this.model.event.remove(this.handler);
    this.uploadProgressManager.stop();
  }

  dismissAlertWindow = () => {
    const callback = _.get(this.model.state, 'alertConfig.callback');
    callback && callback();
    this.model.setAlertConfig(undefined);
  }

  registerErrorHandler = () => {

    const reportError = async (errorMsg: string, errorCode: ERROR_CODE) => {
      const account = this.core.authenticationManager.account;
      try {
        account && await this.errorManager.post(errorMsg, errorCode, account.email);
      } catch (e) {
        console.error('cannot post error');
      }
    };

    // scenarios
    // case 1: Normal JS errors (for example, from an event handler)
    //   Errors are sent to window.onerror twice by React's invokeGuardedCallback
    // case 2: Errors that happen during render, and there is an error boundary.
    //   Errors are sent to window.onerror once by React's invokeGuardedCallback,
    //   but are also caught by the error boundary's componentDidCatch.
    window.addEventListener('error', (event: ErrorEvent) => {
      const { error } = event;
      if (!error) {
        return;
      }
      // skip the first error invoke by invokeGuardedCallback
      if (error.stack?.indexOf('invokeGuardedCallback') >= 0 && !error.alreadySeen) {
        error.alreadySeen = true;
        event.preventDefault();
        return;
      }

      const errorMsg = error.stack ? error.stack.toString() : error.toString();
      reportError(errorMsg, ERROR_CODE.UNKNOWN_ERROR);
    }, { capture: true });

    window.addEventListener('render-error', (event: any) => {
      const { error, message } = event;
      reportError(`${error.message}\n ${message}`, error.errorCode);
    });

    window.addEventListener('api-error', (event: any) => {
      const { error } = event;
      if (!error) {
        return;
      }
      if (error.toString() === 'Cancel') {
        return;
      }
      const errorStack = error.stack ? error.stack.toString() : error.toString();
      const apiUrl = _.get(error.response, 'config.url', _.get(error.response, 'request.responseURL'));
      const errorMsg = `Api ${apiUrl} failed:\n ${errorStack}`;
      reportError(errorMsg, ERROR_CODE.API_ERROR);
      return;
    });

    window.onunhandledrejection = (e: PromiseRejectionEvent) => {
      if (e.reason) {
        throw new Error(e.reason.stack);
      } else {
        throw new Error(e.type);
      }
    };

    window.addEventListener('show-error-modal', (event: any) => {
      const { errorMessageConfig } = event.detail;
      this.model.setAlertConfig(errorMessageConfig);
    });

    window.addEventListener('show-error-toast', (event: any) => {
      const { messageToShow, errorUserTitle, errorUserMsg } = event.detail;
      toast.error(<div>
        <div>{messageToShow}</div>
        <div>{errorUserTitle}</div>
        <div>{errorUserMsg}</div>
      </div>
      );
    });

    window.addEventListener('show-error-page', (event: any) => {
      const { code } = event.detail;
      if ((code === 403 || code === 404) && this.props.location.pathname !== '/doctor') {
        this.props.history.replace(`${this.props.location.pathname}/error${code}`);
      }
    });

    window.addEventListener('logout', event => {
      this.core && this.core.authenticationManager.logout();
    });
  }

  renderPage = (props) => {
    return this.state.logined ?
      <MainPage {...props} model={this.mainPageModel} authenticationManager={this.core.authenticationManager} /> :
      <LoginPage model={this.loginPageModel} />;
  }

  renderFbLoginPage = (props) => {
    return this.state.logined ?
      <MainPage {...props} model={this.mainPageModel} authenticationManager={this.core.authenticationManager} /> :
      <LoginPage model={this.fbLoginPageModel} />;
  }

  renderRegisterPage = () => (
    <RegisterPage authenticationManager={this.core.authenticationManager}/>
  )

  renderForgotPasswordPage = () => (
    <ForgotPasswordPage authenticationManager={this.core.authenticationManager}/>
  )

  render () {
    return (
      <ErrorBoundary errorCode={'000'}>
        <div className={styles.app}>
          {this.state.alertConfig && alertError(new Error(this.state.alertConfig.message), this.dismissAlertWindow)}
          <Switch>
            <Route path='/forgot-password' render={this.renderForgotPasswordPage} />
            <Route path='/guest/register' render={this.renderRegisterPage} />
            {!config.isGojekTheme && <Route path='/en' render={this.renderFbLoginPage} />}
            <Route render={this.renderPage} />
          </Switch>
        </div>
      </ErrorBoundary>
    );
  }

  // the following method is used for testing only
  get appModel (): AppModel {
    return this.model;
  }
}

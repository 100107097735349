import {
  DefaultRtbCampaignBasicFormModel,
  RtbCampaignBasicFormModel,
  RtbCampaignBasicFormModelConstructorParams
} from './RtbCampaignBasicFormModel';
import { AdType, RtbCampaignPlanType } from 'core/rtbCampaign/RtbCampaign';
import { ADDONFEATURE } from 'core/agency/AddonFeature';

export interface OneForAllDisplayFormModel extends RtbCampaignBasicFormModel {
  readonly showVideoProgressRadio: boolean;
  readonly canEditBudgetPlan: boolean;
  readonly canEditPriceModel: boolean;
}
abstract class AbstractOneForAllDisplayFormModel extends DefaultRtbCampaignBasicFormModel
  implements OneForAllDisplayFormModel {

  showVideoProgressRadio = false;

  get campaignAdType (): AdType {
    return AdType.ONE_FOR_ALL_DISPLAY;
  }

  get defaultPriceModel (): string {
    return this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CAMPAIGN.DYNAMIC_CPM) ?
      RtbCampaignPlanType.DCPM : this.availablePriceModel[0];
  }

  getBidPriceMinData (): any {
    return {
      cpc: 1,
      cpm: 1
    };
  }
}

export class EditOneForAllDisplayFormModel extends AbstractOneForAllDisplayFormModel {
  constructor (...args: RtbCampaignBasicFormModelConstructorParams) {
    super('edit', ...args);
  }
  get canEditBudgetPlan (): boolean {
    return false;
  }
  get canEditPriceModel (): boolean {
    return false;
  }

  canEditOptimize (): boolean {
    return false;
  }
}

export class CreateOneForAllDisplayFormModel extends AbstractOneForAllDisplayFormModel {
  constructor (...args: RtbCampaignBasicFormModelConstructorParams) {
    super('create', ...args);
  }
  get canEditBudgetPlan (): boolean {
    return true;
  }
  get canEditPriceModel (): boolean {
    return true;
  }
}

import React from 'react';
import { ConversionsProps } from './ConversionsModel';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ConversionHome } from './ConversionHome/ConversionHome';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { ConversionForm } from './ConversionForm/ConversionForm';
import styles from './conversions.module.scss';
import RouteWithPermission from 'components/RouteWithPermission/RouteWithPermission';
import { hasFuncs } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { ROUTE_PATH } from 'enum/RoutePath';
import { withErrorBoundary } from 'hoc/withErrorBoundary';
import { ERROR_CODE } from 'core/ErrorCode';

const ConversionHomeView = withErrorBoundary(ERROR_CODE.CONVERSION_HOME, ConversionHome);
const CreateConversionView = withErrorBoundary(ERROR_CODE.CONVERSION_CREATE, ConversionForm);
const EditConversionView = withErrorBoundary(ERROR_CODE.CONVERSION_EDIT, ConversionForm);

export class Conversions extends React.PureComponent<ConversionsProps> {

  handler?: number;

  componentDidMount () {
    this.handler = this.props.model.event.add((model) => {
      this.setState(model.state);
    });
    this.props.model.init();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add((model) => {
        this.setState(model.state);
      });
    }
  }

  componentWillUnmount () {
    this.props.model.onUnmount(this.handler);
  }

  renderConversionHome = () => {
    return <ConversionHomeView model={this.props.model.getConversionHomeModel()}/>;
  }

  renderConversionCreateForm = (props) => {
    return <CreateConversionView {...props} model={this.props.model.getCreateConversionFormModel()}/>;
  }

  renderConversionEditForm = (props) => {
    const conversionId = parseInt(props.match.params.conversionId, 10);
    const model = this.props.model.getEditConversionFormModel(conversionId);
    if (!model) {
      return <Redirect to='/conversions/'/>;
    }
    const renderRedirect = () => <Redirect to={`${props.match.url}/${ROUTE_PATH.ERROR403}`}/>;
    return (
      <PermissionChecker
        permissionAware={hasFuncs(Permission.CONVERSION_WRITE)}
        renderOtherwise={renderRedirect}
      >
        <EditConversionView {...props} model={model}/>
      </PermissionChecker>
    );
  }

  render () {
    if (this.props.model.state.presetLoading) {
      return <LoadingIndicator/>;
    }
    return (
      <div className={styles.conversions}>
        {this.props.model.state.loading && <LoadingIndicator/>}
        <Switch>
          <RouteWithPermission
            permissionAware={hasFuncs(Permission.CONVERSION_WRITE)}
            redirectPath={`/conversions/new/${ROUTE_PATH.ERROR403}`}
            path={'/conversions/new'}
            render={this.renderConversionCreateForm}
          />
          <Route
            path={'/conversions/:conversionId/edit'}
            render={this.renderConversionEditForm}
          />
          <Route
            exact
            path={'/conversions'}
            render={this.renderConversionHome}
          />
          <Redirect to='/conversions'/>
        </Switch>
      </div>
    );
  }
}

import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import { CompanyMember } from 'core';
import { AdvertiserManager, DefaultAdvertiserManager } from 'core/advertiser/AdvertiserManager';
import { ColumnDefinition } from 'components/TableColumn';
import styles from './advertiserMemberList.module.scss';
import i18n from 'i18next';
import { toast } from 'react-toastify';
import { AdvertiserMemberForm } from '../AdvertiserMemberForm/AdvertiserMemberForm';
import { CreateAdvertiserMemberFormModel, EditAdvertiserMemberFormModel } from '../AdvertiserMemberForm/AdvertiserMemberFormModel';
import { AccountForm, CreateAdvertiserAccountForm } from 'components/AccountForm';
import { SortableList, AbstractSortableList } from 'containers/Common/AbstractSortableList';

export enum AdvertiserMemberListColumns {
  EMAIL = 'emailColumn',
  NAME = 'nameIdColumn',
  STATUS = 'statusColumn',
  PERMISSION = 'permissionColumn',
  EDITBTNS = 'editBtnsColumn'
}

export type AdvertiserMemberListState = {
  readonly loading: boolean;
  readonly viewModelData: Array<any>;
  readonly modalData?: any;
};

export interface AdvertiserMemberListModel extends SortableList {
  readonly state: AdvertiserMemberListState;
  readonly event: UpdateEventListener<AdvertiserMemberListModel>;
  readonly searchString: string;
  readonly members: CompanyMember[];
  getColumnSetting (columnName: string): ColumnDefinition;
  deleteMember (memberId): void;
  handleOnSearch (searchString): void;
  hideModal (): void;
  showDeleteModal (accountId: string): void;
  showAddMemberModal (): void;
  showAddAccountModal (): void;
  showEditModal (accountId): void;
  onUnmount (handler?: number): void;
  updateViewModelData (): void;
}

export type AdvertiserMemberListProps = {
  readonly model: AdvertiserMemberListModel;
};

export class DefaultAdvertiserMemberListModel extends AbstractSortableList implements AdvertiserMemberListModel {
  event: FireableUpdateEventListener<AdvertiserMemberListModel>;
  loading: boolean = true;
  viewModelData: Array<any>;
  searchString: string = '';
  modalData: any = undefined;

  constructor (
    private advertiserId: number | string,
    public members: CompanyMember[],
    private refreshList: () => Promise<void>,
    private manager: AdvertiserManager = new DefaultAdvertiserManager()
  ) {
    super(AdvertiserMemberListColumns.NAME, 'desc');
    this.event = new FireableUpdateEventListener<AdvertiserMemberListModel>();
    this.viewModelData = [];
    this.updateViewModelData();
  }

  get state (): AdvertiserMemberListState {
    return {
      loading: this.loading,
      viewModelData: this.viewModelData,
      modalData: this.modalData
    };
  }

  getColumnSetting (columnName): ColumnDefinition {
    const columnClassGetter = () => {
      return styles[columnName];
    };

    let text = `advertiserMemberList.headers.${columnName}`;
    let sort = true;
    if (columnName === AdvertiserMemberListColumns.EDITBTNS) {
      text = '';
      sort = false;
    }
    return {
      dataField: columnName,
      text,
      sort,
      onSort: this.handleSort,
      classes: columnClassGetter,
      headerClasses: columnClassGetter
    };
  }

  showAddMemberModal = () => {
    const createModel = new CreateAdvertiserMemberFormModel(this.advertiserId);
    createModel.modelFormikEvent.add(event => {
      if (event.isSubmit === 'POST_SUBMIT') {
        this.postCreate();
      }
    });
    this.modalData = {
      title: i18n.t('advertiserMemberForm.labels.createMember'),
      fullScreen: true,
      component: AdvertiserMemberForm,
      confirmBtnData: {
        callback: createModel.submitAndValide.bind(createModel),
        title: i18n.t('common.buttons.submit')
      },
      componentProps: {
        model: createModel
      }
    };
    this.updateState(false);
  }

  showAddAccountModal = () => {
    const createModel = new CreateAdvertiserAccountForm(this.advertiserId);
    createModel.modelFormikEvent.add(event => {
      if (event.isSubmit === 'POST_SUBMIT') {
        this.postCreate();
      }
    });
    this.modalData = {
      title: i18n.t('accounts.form.titles.new'),
      fullScreen: true,
      component: AccountForm,
      confirmBtnData: {
        callback: createModel.submitAndValide.bind(createModel),
        title: i18n.t('common.buttons.submit')
      },
      componentProps: {
        model: createModel
      }
    };
    this.event.fireEvent(this);
  }

  async postCreate () {
    await this.refreshList();
    this.hideModal();
  }

  showEditModal = (accountId) => {
    const editModel = new EditAdvertiserMemberFormModel(this.advertiserId, accountId);
    editModel.modelFormikEvent.add(event => {
      if (event.isSubmit === 'POST_SUBMIT') {
        this.postCreate();
      }
    });
    this.modalData = {
      title: i18n.t('advertiserMemberForm.labels.editMember'),
      fullScreen: true,
      component: AdvertiserMemberForm,
      confirmBtnData: {
        callback: editModel.submitAndValide.bind(editModel),
        title: i18n.t('common.buttons.submit')
      },
      componentProps: {
        model: editModel
      }
    };
    this.updateState(false);
  }

  async deleteMember (memberId) {
    this.updateState(true);
    this.modalData = undefined;
    try {
      await this.manager.deleteAdvertiserMembers(this.advertiserId as number, memberId);
      toast.success(i18n.t('advertiserMemberList.labels.deleteAccountMemberSuccess'));
      this.postCreate();
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
    }
    this.updateState(false);
  }

  showDeleteModal = (accountId) => {
    this.modalData = {
      title: i18n.t('advertiserMemberList.labels.deleteModalTitle'),
      fullScreen: false,
      message: i18n.t('advertiserMemberList.labels.deleteModalContent'),
      confirmBtnData: {
        title: i18n.t('common.buttons.delete'),
        callback: this.deleteMember.bind(this, accountId)
      }
    };
    this.updateState(false);
  }

  handleOnSearch (searchString) {
    this.searchString = searchString;
    this.updateViewModelData();
  }

  hideModal = () => {
    this.modalData = undefined;
    this.updateState(false);
  }

  onUnmount (handler?: number) {
    handler && this.event.remove(handler);
    this.modalData = undefined;
  }

  updateViewModelData () {
    this.viewModelData = this.members.map(member => {
      return {
        id: member.id,
        name: member.account.name,
        account: member.account,
        [AdvertiserMemberListColumns.NAME]: member.account.id,
        [AdvertiserMemberListColumns.EMAIL]: member.account.email,
        [AdvertiserMemberListColumns.STATUS]: member.account.activated ? i18n.t('agency.member.active') : i18n.t('agency.member.inactive'),
        [AdvertiserMemberListColumns.PERMISSION]: i18n.t(`advertiserMemberList.permissions.${member.roleName}`)
      };
    }).filter(member => {
      const searchString = this.searchString.toLowerCase();
      const nameMatch = member.name.toLowerCase().includes(searchString);
      const emailMatch = member.emailColumn.toLowerCase().includes(searchString);
      return nameMatch || emailMatch;
    });
    this.updateState(false);
  }

  updateState (loading: boolean) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}

import React from 'react';
import { components } from 'react-select';
import styles from './fbPageSelect.module.scss';

export const FBPageSelectMultiValueLabel = props => {
  const urlPath = `https://graph.facebook.com/${props.data.value}/picture/?type=square`;
  return (
    <components.MultiValueLabel {...props} >
      <div className={styles.fbPageOption}>
        <img
          key={urlPath}
          src={urlPath}
          alt=''
        />
        <div className={styles.fbPageOptionDetail}>
          <div className={styles.fbPageName}>{props.data.label}</div>
          <div className={styles.fbPageId}>{props.data.value}</div>
        </div>
      </div>
    </components.MultiValueLabel>
  );
};

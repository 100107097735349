import React, { useCallback, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import DefaultCampaignGroupManager, { CampaignGroupManager } from 'core/campaignGroup/CampaignGroupManager';

const defaultCampaignGroupManager: CampaignGroupManager = new DefaultCampaignGroupManager();
export const BindingRedirect: React.FC<{
  groupId: string | number,
  campaignId: string | number,
  campaignGroupManager?: CampaignGroupManager
}> = ({
  groupId,
  campaignId,
  campaignGroupManager = defaultCampaignGroupManager
}) => {

  const [redirectPath, setRedirectPath] = useState<string | undefined>(undefined);

  const getBindngPathInfo = useCallback(async () => {
    try {
      const orderNumber = await campaignGroupManager.getOrderNumber(groupId);
      setRedirectPath(`/orders/${orderNumber}/campaign-groups/${groupId}?campaignIds=${campaignId}&action=manage`);
    } catch (e) {
      setRedirectPath('/');
    }
  }, [campaignGroupManager, groupId, campaignId]);

  useEffect(() => {
    getBindngPathInfo();
  }, [getBindngPathInfo]);

  if (redirectPath) {
    return <Redirect to={redirectPath} />;
  }
  return (
    <LoadingIndicator />
  );
};

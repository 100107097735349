import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import FutureDateRangePicker from 'components/common/DateRangePicker/FutureDateRangePicker';
import { defaultFormat } from 'components/common/DateRangePicker/DateRangePicker';

import moment from 'moment';
import { getFieldErrors } from 'utils/FormikUtils';
import Tips from '../Tips/Tips';

export class FormikDateRangePicker extends Component<any> {
  constructor (props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange (startDate, endDate) {
    const {
      form: { setFieldValue, setFieldTouched },
      startDateFormikName,
      endDateFormikName,
      field: { name }
    } = this.props;
    const start = moment(startDate, defaultFormat).format('YYYY-MM-DD HH:mm:ss');
    const end = moment(endDate, defaultFormat).format('YYYY-MM-DD HH:mm:ss');
    setFieldValue(startDateFormikName, start);
    setFieldValue(endDateFormikName, end);
    setFieldTouched(name);
    this.props.onChange && this.props.onChange(startDate, endDate);
  }

  render () {
    const {
      field: { name }
    } = this.props;
    const { startDateFormikName, endDateFormikName } = this.props;
    const start = _.get(this.props, `form.values.${startDateFormikName}`);
    const end = _.get(this.props, `form.values.${endDateFormikName}`);
    const errors = getFieldErrors(this.props.form, name);
    const hasError = !!errors;
    const startDate = moment(start, 'YYYY-MM-DD HH:mm:ss').format(defaultFormat);
    const endDate = moment(end, 'YYYY-MM-DD HH:mm:ss').format(defaultFormat);
    return (
      <Fragment>
        <FutureDateRangePicker
          {...this.props}
          onChange={this.handleChange}
          startDate={startDate}
          endDate={endDate}
          hasError={hasError}
        />
        {errors && <Tips>{errors}</Tips>}
      </Fragment>
    );
  }
}

export default FormikDateRangePicker;

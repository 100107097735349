import React from 'react';

export const renderErrors = (errors) => {
  const errorDoms = errors.map(error => (
    <div key={error}>{error}</div>
  ));
  return (
    <>
      {errorDoms}
    </>
  );
};

import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

export const ErrorHandler: React.FC<any> = ({
  parentRef,
  isSubmitting,
  submitCount
}) => {
  useEffect(() => {
    if (!parentRef.current || isSubmitting) {
      return;
    }
    const node = ReactDOM.findDOMNode(parentRef.current);
    if (node instanceof HTMLElement) {
      const child = node.querySelector('.errorTip');
      if (child) {
        child.scrollIntoView({
          block: 'center'
        });
      }
    }
  }, [isSubmitting, parentRef, submitCount]);
  return (
    <div/>
  );
};

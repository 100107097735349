import _ from 'lodash';
import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import { AccountManager, DefaultAccountManager, AuthenticationManager, Account } from 'core';
import { renderColumn, ColumnDefinition, SortDescriptor, sortableColumn } from 'components/TableColumn';
import { sudoNameFormatter } from 'components/AccountList/accountListFormatters';
import { SearchBarModel } from 'components/common/SearchBar';

export const ACCOUNT_TYPE = {
  PLATFORM: 'platformAccounts',
  OEM: 'oemAccounts'
};
export interface SudoModel extends SearchBarModel {
  readonly state: SudoModelState;
  readonly accounts: Array<Account>;
  readonly event: UpdateEventListener<SudoModel>;

  sudo (): void;
  getActiveAccount (): void;
  resetState ();
  changeTab (tabKey: string): void;
}

export type SudoModelProps = {
  readonly model: SudoModel;
};

export type SudoModelState = {
  readonly loading: boolean;
  readonly isSubmitted: boolean;
  readonly accountId?: number;
  readonly activeNav: String;
  readonly searchText?: String;
};

export class DefaultSudoModel implements SudoModel, SearchBarModel {
  modelCurrentList: Array<Account> = [];
  modelLoading: boolean = false;
  accountMap: {[type: string]: Array<Account>} = { [ACCOUNT_TYPE.PLATFORM]: [], [ACCOUNT_TYPE.OEM]: [] };
  event: FireableUpdateEventListener<SudoModel> = new FireableUpdateEventListener<SudoModel>();
  placeholder: string = 'sudo.placeholders.searchbar';
  modelAccountId?: number;
  defaultValue?: string | undefined;
  modelIsSubmitted: boolean = false;
  modelActiveNav: string = ACCOUNT_TYPE.PLATFORM;
  modelSearchText?: string | undefined;

  constructor (private authManager: AuthenticationManager, private accountManager: AccountManager = new DefaultAccountManager()) {
  }

  search (keyword: string): void {
    this.modelSearchText = keyword;
    this.modelCurrentList = this.accountMap[this.modelActiveNav].filter(
      (account) => account.name.toLowerCase().includes(keyword.toLowerCase()) || account.email.toLowerCase().includes(keyword.toLowerCase()));
    this.event.fireEvent(this);
  }

  async getActiveAccount (): Promise<void> {
    this.notify(true);
    const [oemAccounts, platformAccounts] = await Promise.all([this.accountManager.getOemAccounts(), this.accountManager.getAccounts()]);
    this.accountMap.oemAccounts = oemAccounts.filter(accounts => accounts.activated);
    this.accountMap.platformAccounts = platformAccounts.filter(accounts => accounts.activated);
    this.modelCurrentList = this.accountMap[this.modelActiveNav];
    this.notify(false);
  }

  async sudo () {
    if (!this.state.accountId) {
      return;
    }
    this.notify(true);
    await this.authManager.sudo(this.state.accountId);
    this.modelIsSubmitted = true;
    this.notify(false);
  }

  get accounts (): Array<Account> {
    return this.modelCurrentList;
  }

  get noDataDescription (): string {
    return 'accounts.noDataAvailable';
  }

  getColumnDefinition (columnName: string): ColumnDefinition {
    return {
      ...sortableColumn(columnName, `accounts.headers.${columnName}`)
    };
  }

  changeTab = (tabKey: string) => {
    if (tabKey === this.modelActiveNav) {
      return;
    }
    this.modelActiveNav = tabKey;
    const keyword = this.modelSearchText || '';
    this.modelCurrentList = this.accountMap[tabKey].filter(
      (account) => account.name.toLowerCase().includes(keyword.toLowerCase()) || account.email.toLowerCase().includes(keyword.toLowerCase())
    );
    this.notify(false);
  }

  chooseAccount = ({ target }) => {
    const targetAccountId = parseInt(target.value, 10);
    this.modelAccountId = this.modelAccountId === targetAccountId ? undefined : targetAccountId;
    this.event.fireEvent(this);
  }

  setIsSubmitted (isSubmitted: boolean) {
    this.modelIsSubmitted = isSubmitted;
  }

  resetState () {
    this.modelAccountId = undefined;
    this.modelIsSubmitted = false;
    this.modelSearchText = '';
    this.modelActiveNav = ACCOUNT_TYPE.PLATFORM;
    this.modelCurrentList = [];
  }

  get columns () {
    const nameFormatter = _.curry(sudoNameFormatter)(this.chooseAccount);
    const columns = [
      renderColumn({ ...this.getColumnDefinition('name'), 'formatExtraData': this.state }, nameFormatter),
      renderColumn(this.getColumnDefinition('email'))
    ];
    if (this.modelActiveNav === ACCOUNT_TYPE.OEM) {
      columns.push(renderColumn(this.getColumnDefinition('companyName')));
    }
    return columns;
  }

  get defaultSorts (): Array<SortDescriptor> {
    return [{
      dataField: 'id',
      order: 'desc'
    }];
  }

  get state (): SudoModelState {
    return {
      loading: this.modelLoading,
      accountId: this.modelAccountId,
      isSubmitted: this.modelIsSubmitted,
      activeNav: this.modelActiveNav,
      searchText: this.modelSearchText
    };
  }

  notify (loading: boolean) {
    this.modelLoading = loading;
    this.event.fireEvent(this);
  }
}

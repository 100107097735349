import React from 'react';
import { AdvertisersProps } from './AdvertisersModel';
import { Switch, Route, Redirect } from 'react-router-dom';
import { AdvertiserHome } from './AdvertiserHome/AdvertiserHome';
import { AdvertiserForm } from './AdvertiserForm/AdvertiserForm';
import { AdvertiserDetail } from './AdvertiserDetail/AdvertiserDetail';
import RouteWithPermission from 'components/RouteWithPermission/RouteWithPermission';
import { hasFuncs } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { ROUTE_PATH } from 'enum/RoutePath';
import { withErrorBoundary } from 'hoc/withErrorBoundary';
import { ERROR_CODE } from 'core/ErrorCode';
import { LoadingIndicator } from 'components/common/LoadingIndicator';

const AdvertiserHomeView = withErrorBoundary(ERROR_CODE.ADVERTISER_HOME, AdvertiserHome);
const CreateAdvertiserView = withErrorBoundary(ERROR_CODE.ADVERTISER_CREATE, AdvertiserForm);
const EditAdvertiserView = withErrorBoundary(ERROR_CODE.ADVERTISER_EDIT, AdvertiserForm);
const AdvertiserDetailView = withErrorBoundary(ERROR_CODE.ADVERTISER_DETAIL, AdvertiserDetail);

export class Advertisers extends React.PureComponent<AdvertisersProps> {

  handler?: number;

  componentDidMount () {
    this.handler = this.props.model.event.add(model => {
      this.setState(model.state);
    });
    this.props.model.init();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.onUnmount(this.handler);
      this.handler = this.props.model.event.add(model => {
        this.setState(model.state);
      });
      this.props.model.init();
    }
  }

  componentWillUnmount () {
    this.props.model.onUnmount(this.handler);
  }

  renderAdvertiserCreateForm = (props) => {
    return <CreateAdvertiserView {...props} model={this.props.model.getCreateAdvertiserFormModel()}/>;
  }

  renderAdvertiserEditForm = (props) => {
    const advertiserId = props.match.params.advertiserId;
    const renderRedirect = () => <Redirect to={`${props.match.url}/${ROUTE_PATH.ERROR403}`}/>;
    return (
      <PermissionChecker
        permissionAware={hasFuncs(Permission.ADVERTISER_WRITE)}
        renderOtherwise={renderRedirect}
      >
        <EditAdvertiserView {...props} model={this.props.model.getEditAdvertiserFormModel(advertiserId)}/>
      </PermissionChecker>
    );
  }

  renderAdvertiserDetailView = (props) => {
    const advertiserId = props.match.params.advertiserId;
    return <AdvertiserDetailView model={this.props.model.getAdvertiserDetailModel(advertiserId)}/>;
  }

  renderAdvertiserHome = () => {
    return <AdvertiserHomeView model={this.props.model.getAdvertiserHomeModel()}/>;
  }

  render () {
    const state = this.props.model.state;
    if (!state.fbAdAccountOptions || !state.fbPageOptions) {
      return <LoadingIndicator />;
    }
    return (
      <Switch>
        <RouteWithPermission
          permissionAware={hasFuncs(Permission.ADVERTISER_WRITE)}
          redirectPath={`/advertisers/new/${ROUTE_PATH.ERROR403}`}
          path={'/advertisers/new'}
          render={this.renderAdvertiserCreateForm}
        />
        <Route
          path={'/advertisers/:advertiserId/edit'}
          render={this.renderAdvertiserEditForm}
        />
        <Route
          path={'/advertisers/:advertiserId'}
          render={this.renderAdvertiserDetailView}
        />
        <Route
          render={this.renderAdvertiserHome}
        />
      </Switch>
    );
  }
}

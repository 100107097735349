export enum CreateRtbCampaignSetupFlowStep {
  CHOOSE_ADTYPE,
  SETUP_CAMPAIGN,
  SUMMARY
}

export enum EditRtbCampaignSetupFlowStep {
  SETUP_CAMPAIGN,
  SUMMARY
}

import React, { Fragment } from 'react';
import _ from 'lodash';
import classnames from 'classnames/bind';
import styles from './withFormikOnChange.module.scss';
import { getFieldErrors } from 'utils/FormikUtils';
import Tips from 'components/common/Tips/Tips';

export function withFormikOnChange (WrapperComponent) {

  const cx = classnames.bind(styles);

  const WithFormikOnChange = React.forwardRef((props: any, ref: any) => {
    const handleChange = value => {
      const targetValue = _.get(value, 'target.value', value);
      props.form.setFieldValue(props.field.name, targetValue);
      props.form.setFieldTouched(props.field.name);
      if (props.onChange) {
        props.onChange(targetValue);
      }
    };
    const safeValue = _.get(props, 'field.value', '');
    const errors = getFieldErrors(props.form, props.field.name);
    const hasError = !!errors;
    const className = cx(props.className, {
      error: hasError
    });

    return (
      <Fragment>
        <WrapperComponent
          {...props}
          ref={ref}
          className={className}
          onChange={handleChange}
          value={safeValue}
          name={props.field.name}
        />
        {errors && <Tips>{errors}</Tips>}
      </Fragment>
    );
  });
  WithFormikOnChange.displayName = `WithFormikOnChange(${getDisplayName(
    WrapperComponent
  )})`;
  return WithFormikOnChange;
}

function getDisplayName (WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

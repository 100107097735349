import React from 'react';
import styles from './stickableTableContainer.module.scss';
import { SetRefContext } from 'contexts/SetRefContext';

export class StickableTableContainer extends React.Component<React.PropsWithChildren<{}>, { hiddenSpaceWidth: number }> {

  tableRef: any;
  containerRef: any;

  constructor (props) {
    super(props);
    this.tableRef = React.createRef();
    this.containerRef = React.createRef();
    this.state = {
      hiddenSpaceWidth: 0
    };
  }

  handleScroll = (event) => {
    const listCurrent = this.tableRef.current;
    if (listCurrent && event.target === this.containerRef.current) {
      listCurrent.scrollLeft = event.target.scrollLeft;
    }
  }

  setRef = (tableRef) => {
    if (tableRef) {
      this.tableRef.current = tableRef;
      if (this.state.hiddenSpaceWidth !== tableRef.scrollWidth) {
        this.setState({
          hiddenSpaceWidth: tableRef.scrollWidth
        });
      }
    } else if (tableRef === undefined && this.state.hiddenSpaceWidth !== 0) {
      this.setState({
        hiddenSpaceWidth: 0
      });
    }
  }

  render () {
    return (
      <SetRefContext.Provider value={{ setRef: this.setRef }}>
        <div
          className={styles.container}
          ref={this.containerRef}
          onScroll={this.handleScroll}
        >
          <div
            className={styles.hiddenSpace}
            style={{
              width: this.state.hiddenSpaceWidth
            }}
          />
          <div className={styles.stickyPart}>
            {this.props.children}
          </div>
        </div>
      </SetRefContext.Provider>
    );
  }
}

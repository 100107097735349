import i18n from 'i18n';

const getErrorConfig = (code: number) => {

  const config = {
    /* System/Service level */
    400000: {
      errorNeedAlert: false
    },
    401000: {
      errorNeedAlert: false
    },
    403000: {
      errorNeedAlert: false
    },
    404000: {
      errorNeedAlert: false
    },

    405000: {
      errorNeedAlert: false
    },
    408000: {
      errorNeedAlert: false
    },// 'Connection Time out',
    409000: {
      errorNeedAlert: false
    },// 'Conflict',

    500000: {
      errorNeedAlert: false
    },// 'Unknown Error',

    /* Role level 500 */
    401500: {
      errorNeedAlert: false
    },// 'Function not authorized',
    402500: {
      errorNeedAlert: false
    },// 'No actor in used or using invalid actor',

    /* Resource level 600 */
    400600: {
      errorNeedAlert: false
    },// 'Invalid operation',
    404600: {
      errorNeedAlert: false
    },// 'Resource not found',

    /* Account level 010 */
    400010: {
      errorNeedAlert: true
    },// 'Email already in use',
    400013: {
      errorNeedAlert: true
    },
    401010: {
      errorNeedAlert: true
    },// 'Account not login',
    403010: {
      errorNeedAlert: true
    },// 'Account cannot access the resource',
    404010: {
      errorNeedAlert: true,
      callback: () => {
        window.dispatchEvent(new CustomEvent('logout'));
      }
    },// 'Account not found',
    406010: {
      errorNeedAlert: true
    },// 'Account is disable',

    /* Company Level 020 */
    404020: {
      errorNeedAlert: false
    },// 'Company not found',

    /* Agency Level 030 */
    400030: {
      errorNeedAlert: false
    },
    404030: {
      errorNeedAlert: false
    },
    400031: {
      errorNeedAlert: false
    },

    /* Advertiser Level 040 */
    404040: {
      errorNeedAlert: false
    },

    /* AdsOrder Level 050 */
    400050: {
      errorNeedAlert: false
    },
    404050: {
      errorNeedAlert: false
    },
    409050: {
      errorNeedAlert: false
    },

    /* Campaign Level 060 */
    404060: {
      errorNeedAlert: false
    },
    400061: {
      errorNeedAlert: false
    },
    404062: {
      errorNeedAlert: false
    },

    /* Creative Level 070 */
    400070: {
      errorNeedAlert: false
    },
    400071: {
      errorNeedAlert: false
    },
    400072: {
      errorNeedAlert: false
    },
    400073: {
      errorNeedAlert: false
    },
    404070: {
      errorNeedAlert: false
    },
    400074: {
      errorNeedAlert: false
    },

    /* AdXCreativeReview Level 080 */
    400080: {
      errorNeedAlert: false
    },
    408080: {
      errorNeedAlert: false
    },

    /* Segment Level 090 */
    404090: {
      errorNeedAlert: false
    },
    404091: {
      errorNeedAlert: false
    },

    /* Common Level 100 */
    400100: {
      errorNeedAlert: false
    },

    /* Conversion Level 110 */
    404110: {
      errorNeedAlert: false
    },
    404111: {
      errorNeedAlert: false
    },

    /* Conversion Level 110 */
    404120: {
      errorNeedAlert: false
    },
    400120: {
      errorNeedAlert: false
    },

    500080: {
      errorNeedAlert: false
    },
    500081: {
      errorNeedAlert: false
    },

    /* 3th party 900 */
    401900: {
      errorNeedAlert: false
    },

    /* PVMax 910 */
    500910: {
      errorNeedAlert: false
    },
    /* IMax 920 */
    500920: {
      errorNeedAlert: false
    },
    500921: {
      errorNeedAlert: false
    },
    500922: {
      errorNeedAlert: false
    },
    500923: {
      errorNeedAlert: false
    },

    /* Edimax 930 */
    404930: {
      errorNeedAlert: false
    },
    401930: {
      errorNeedAlert: false
    },
    500930: {
      errorNeedAlert: false
    },

    /* Yahoo 950 */
    401950: {
      errorNeedAlert: false
    },
    /* Yahoo Gemini */
    500951: {
      errorNeedAlert: false
    },
    500952: {
      errorNeedAlert: false
    },
    /* Google API 960 */
    500960: {
      errorNeedAlert: false
    },
    500961: {
      errorNeedAlert: false
    },

    /* Azure  970 */
    500970: {
      errorNeedAlert: false
    },
    401970: {
      errorNeedAlert: false
    },
    /* Azure - mediaService 971 */
    500971: {
      errorNeedAlert: false
    },

    /* DMP 980 */
    500980: {
      errorNeedAlert: false
    },
    500981: {
      errorNeedAlert: false
    },
    500982: {
      errorNeedAlert: false
    },
    500983: {
      errorNeedAlert: false
    },
    500984: {
      errorNeedAlert: false
    },
    500985: {
      errorNeedAlert: false
    },
    500986: {
      errorNeedAlert: false
    },
    500987: {
      errorNeedAlert: false
    },

    /* FB */
    500941: {
      errorNeedAlert: false
    },
    5001000: {
      errorNeedAlert: false
    }, // Get FB AD Account fail
    5001001: {
      errorNeedAlert: false
    }, // Get FB Token fail
    5001002: {
      errorNeedAlert: false
    }, // Create FB Campaign fail
    5001003: {
      errorNeedAlert: false
    }, // Update FB Campaign fail
    5001004: {
      errorNeedAlert: false
    }, // Create FB AdSet fail
    5001005: {
      errorNeedAlert: false
    }, // Update FB AdSet fail
    5001006: {
      errorNeedAlert: false
    }, // List FB Campaigns fail
    5001007: {
      errorNeedAlert: false
    }, // List FB AdSets fail
    5001008: {
      errorNeedAlert: false
    }, // List FB Ads fail
    5001009: {
      errorNeedAlert: false
    }, // Update FB object status fail
    5001010: {
      errorNeedAlert: false
    }, // Create FB Creative fail
    5001011: {
      errorNeedAlert: false
    }, // Generate FB Preview fail
    5001012: {
      errorNeedAlert: false
    }, // Create FB Ad fail
    5001013: {
      errorNeedAlert: false
    }, // Update FB Ad fail
    5001014: {
      errorNeedAlert: false
    } // Get TA Description fail
  };
  return config[code];

};

export const getServerErrorMessageConfig = (
  errorCode: number,
  defaultMessage: string = i18n.t('serverResponse.500000')
) => {

  const config = getErrorConfig(errorCode);
  return {
    message: errorCode !== 500000 && config ?
      i18n.t(`serverResponse.${errorCode}`) :
      defaultMessage,
    showAlert: config ? config.errorNeedAlert : false,
    callback: config ? config.callback : undefined
  };
};

import { ColumnDefinition, renderColumn, sortableColumn } from 'components/TableColumn';
import { useCallback, useEffect, useState } from 'react';
import formatters from './listFormatters';
import styles from './creditList.module.scss';
import { DefaultAgencyManager } from 'core';
import { formatPriceWithCurrency } from 'helper/CurrencyHelper';
import _ from 'lodash';
import { useCallAPI } from 'hooks/useCallAPI';

export enum CreditListColumns {
  ID = 'agency_id',
  USED_CREDIT = 'used_credit',
  CREDIT_LIMIT = 'creditLimit',
  EDITBTNS = 'editBtns'
}

export type CreditListState = {
  readonly searchString: string;
  readonly filteredList: any[];
  readonly agencies: any[];
  readonly agencyToPay?: number;
};

const defaultAgencyManager = new DefaultAgencyManager();

export const useCreditListModel = (
  agencyManager = defaultAgencyManager
) => {

  const [state, setState] = useState({
    searchString: '',
    agencies: [],
    filteredList: [],
    agencyToPay: undefined
  } as CreditListState);
  const { loading, callAPIs } = useCallAPI();

  useEffect(() => {
    const filteredList = state.agencies.filter(agency => {
      return agency.com_name.toLowerCase().includes(state.searchString.toLowerCase());
    });
    setState(prevState => ({ ...prevState, filteredList }));
  }, [state.searchString, state.agencies]);

  const initData = useCallback(async () => {
    callAPIs([
      agencyManager.getAgencies.bind(agencyManager, 'company.creditLimit')
    ], agencies => {
      const listData = agencies.map(agency => ({
        ...agency,
        used_credit: _.get(agency, 'addonProps.creditLimit', 0) - _.get(agency, 'credit', 0),
        creditLimit: _.get(agency, 'addonProps.creditLimit', 0)
      }));
      setState(prevState => ({
        ...prevState,
        agencies: listData,
        filteredList: listData,
        loading: false
      }));
    });
  }, [agencyManager, callAPIs]);

  useEffect(() => {
    initData();
  }, [initData]);

  const onPayBtnClick = (agencyToPay: number) => setState({ ...state, agencyToPay });

  const onPayModalClose = (refresh: boolean) => {
    setState({ ...state, agencyToPay: undefined });
    refresh && initData();
  };

  const onHandleSearch = (searchString: string) => setState({ ...state, searchString });

  const columnDefinition = (columnName): ColumnDefinition => ({
    ...sortableColumn(columnName, `creditList.headers.${columnName}`, true),
    classes: () => styles[columnName],
    headerClasses: () => styles[columnName]
  });

  const columns = [
    renderColumn(columnDefinition(CreditListColumns.ID), formatters.nameFormatter),
    renderColumn(columnDefinition(CreditListColumns.USED_CREDIT), (value, rowData) => formatPriceWithCurrency(rowData.currency, value ? value : 0)),
    renderColumn(columnDefinition(CreditListColumns.CREDIT_LIMIT), (value, rowData) => formatPriceWithCurrency(rowData.currency, value ? value : 0)),
    renderColumn({
      ...columnDefinition(CreditListColumns.EDITBTNS),
      text: '',
      sort: false,
      formatExtraData: {
        onPayBtnClick
      }
    }, formatters.floatingEditBtnsFormatter)
  ];

  return {
    state,
    columns,
    loading,
    onHandleSearch,
    onPayModalClose
  };
};

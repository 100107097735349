import { Currency } from 'core/agency/Agency';
import { numberWithCommas } from 'utils/StringUtil';
import _ from 'lodash';

export function getPriceValue (currency: Currency | string, amount: number): number {
  switch (currency) {
    case Currency.MYR:
    case Currency.USD:
      return _.floor(amount, 2);
    default:
      return Math.floor(amount);
  }
}

export function getDecimalPlaceCount (currency: Currency | string): number {
  switch (currency) {
    case Currency.MYR:
    case Currency.USD:
      return 2;
    default:
      return 0;
  }
}

export function formatPrice (currency: Currency | string, amount: number): string {
  return numberWithCommas(getPriceValue(currency, amount));
}

export function formatPriceWithCurrency (currency: Currency | string, amount: number) {
  return `${currency} ${formatPrice(currency, amount)}`;
}

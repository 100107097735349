import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import styles from './finalReportComponent.module.scss';
import { Bar } from 'react-chartjs-2';
import reportHeaderLogo from 'assets/tenmax-logo.png';
import reportHeaderLogo2x from 'assets/tenmax-logo@2x.png';
import classNames from 'classnames/bind';
import {
  BasicSetting,
  Column,
  Benchmark,
  Platform,
  Note,
  LABEL,
  IMPACT_TITLE,
  CHART_COLOR,
  NOTES_CONTENT,
  OVERALL_PRIORITY
} from 'enum/FinalReport';
import {
  handleNumberOrText,
  handleStatistics,
  generateChartConfigs
} from 'utils/FinalReportUtil';

const cssClassNames = classNames.bind(styles);

export const ReportHeader: React.FC<{
  advertiser: string,
  settings: any[],
  currency: string
}> = ({
  advertiser,
  settings,
  currency
}
) => {

  return (
    <div className={styles.reportHeader}>
      <div className={styles.headerFixContainer}>
        <div className={styles.topBarContainer}>
          <div className={styles.topBarSection}>
            <img src={reportHeaderLogo} srcSet={reportHeaderLogo2x} alt='logo' className={styles.logo}/>
          </div>
          <div className={styles.topBarSection}>
            <div className={styles.confidential}>
              {`Confidential`}
            </div>
          </div>
        </div>

        <div className={styles.headerTitle}>
          <span className={styles.title}>{`Hi ${advertiser}!`}</span>
          <span className={styles.title}>{`Please find your GoGAN Report below!`}</span>
        </div>
      </div>

      <div className={styles.settingContainer}>
        {_.map(settings, (setting, index) => (
          <div key={index} className={styles.setting}>
            <span className={styles.title}>{LABEL[setting.title]}</span>
            <div className={styles.content}>
              {setting.title === BasicSetting.BUDGET
               ? <>
                  <span>{currency}</span>
                  <span>{handleNumberOrText(Number(setting.content))}</span>
                </>
               : <span>{setting.content}</span>}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export const OverallBlock: React.FC<{
  data: any
}> = ({
  data
}) => {
  return (
    <div className={styles.block}>
      <div className={styles.title}>
        {`Overall Ads Performance`}
      </div>
      <div className={styles.impressions}>
        <div className={styles.row}>
          <div className={styles.actualImpressionTitle}>{`Actual Impressions`}</div>
          <div className={styles.actualImpressionContent}>
            <span>{handleNumberOrText(_.get(data, 'overall_impressions.actual', ''))}</span>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.targetImpression}>{`Target Impressions`}</div>
          <div className={styles.targetImpression}>
            <span>{handleNumberOrText(_.get(data, 'overall_impressions.target', ''))}</span>
          </div>
        </div>
      </div>
      <div className={styles.statistics}>
        {
          _.map(
            Object.keys(_.get(data, 'overall_performance', []))
            .filter(type => type !== Column.IMPRESSIONS)
            .sort((key1, key2) => OVERALL_PRIORITY.indexOf(key1) > OVERALL_PRIORITY.indexOf(key2) ? 1 : -1)
            ,
            (type, index) => (
              <div key={index} className={styles.element}>
                <div className={styles.title}>{LABEL[type]}</div>
                <div className={styles.content}>
                  {handleStatistics(type, _.get(data, `overall_performance.${type}`, 0), 2, _.get(data, 'currency', 'IDR'))}
                </div>
              </div>
            )
          )
        }
      </div>
    </div>
  );
};

export const TableBlock: React.FC<{
  title: string,
  columns: string[],
  rows: string[],
  group: string,
  sortKey: string,
  decimals: number,
  currency: string,
  customColumns?: any[],
  hasOverFlown: boolean
}> = ({
  title,
  columns,
  rows,
  group,
  sortKey,
  decimals,
  currency,
  customColumns,
  hasOverFlown
}) => {
  const [optimals, setOptimals] = useState({});
  const [rowsForRender, setRowsForRender] = useState<string[]>([]);

  const initTableOptimals = (rows, columns) => {
    let statisticsArray: number[] = [];
    let optimal = 0;
    let optimals = {};

    _.forEach(columns, (column) => {
      let targets: string[] = [];
      statisticsArray = _.map(rows, row => row[column]);

      switch (column) {
        case Column.CPC:
        case Column.CPM:
          optimal = Math.min(..._.filter(statisticsArray, value => value > 0));
          break;
        default:
          optimal = Math.max(...statisticsArray);
          break;
      }

      _.forEach(rows, (row, index) => {
        if (row[column] === optimal) {
          targets = [
            ...targets,
            index
          ];
        }
      });

      optimals = {
        ...optimals,
        [column]: targets
      };
    });

    return optimals;
  };

  const handleRowContentElement = (rows, decimals, currency, customColumns) => {

    const renderElement = (key, content, hasOverFlown= false, withHint= false, isOptimal= false) => {
      const elementClassName = cssClassNames('element', {
        elementShrunkOne: hasOverFlown,
        elementShrunkTwo: hasOverFlown && content.length >= 15,
        withHint: withHint,
        optimal: isOptimal
      });

      return (
        <div key={key} className={elementClassName}>
          {content}
        </div>
      );
    };

    return (
      <>
        {_.map(rows, (row, index) => (
          <div key={index} className={styles.row}>
            {_.map(row, (value, key) => {
              let customColumnIndex = customColumns ? customColumns.findIndex(custom => custom['column'] === key) : -1;

              return (
                optimals[key] && optimals[key].includes(index)
                ? renderElement(key, handleStatistics(key, value, decimals, currency), hasOverFlown, false, true)
                : customColumnIndex !== -1
                  ? renderElement(key, customColumns[customColumnIndex].content(value), false, customColumns[customColumnIndex].withHint(value))
                  : renderElement(key, handleStatistics(key, value, decimals, currency), hasOverFlown)
              );
            })}
          </div>
        ))}
      </>
    );
  };

  useEffect(() => {
    let rowsSortedKey = sortKey ? _.sortBy(rows, sortKey).reverse() : rows;
    let rowsSorted = [
      ..._.filter(rowsSortedKey, row => row[group] !== 'Others'),
      ..._.filter(rowsSortedKey, row => row[group] === 'Others')
    ];
    setOptimals(initTableOptimals(rowsSorted, columns));
    setRowsForRender(rowsSorted);
  }, [rows, columns, group, sortKey]);

  return (
    <div className={styles.block}>
      <div className={styles.title}>
        {title}
      </div>

      <div className={styles.table}>
        <div className={cssClassNames('row', 'title')}>
          {_.map(columns, (column, index) => (
            <div key={index} className={styles.element}>{LABEL[column]}</div>
          ))}
        </div>
        {handleRowContentElement(rowsForRender, decimals, currency, customColumns)}
      </div>
    </div>
  );
};

export const ChartBlock: React.FC<{
  data: any
}> = ({
  data
}) => {
  const {
    scale,
    labels,
    contents,
    totals,
    maxValueScaleX,
    maxValueScaleY
  } = generateChartConfigs(data);

  const datasetKeyProvider = () => {
    return Math.random();
  };

  const renderColors = _.map(contents, (content, index) => {
    if (content['name'] === Column.CTR) return CHART_COLOR.CTR;

    return CHART_COLOR[index] ? CHART_COLOR[index] : CHART_COLOR.RANDOM();
  });

  const chartOptions: any = {
    responsive: true,
    legend: {
      display: false
    },
    plugins: {
      datalabels: {
        display: false,
        color: CHART_COLOR.CTR,
        font: {
          weight: 'bold'
        }
      }
    },
    scales: {
      xAxes: [
        {
          id: 'x',
          stacked: true,
          gridLines: {
            drawOnChartArea: false
          }
        }
      ],
      yAxes: [
        {
          id: 'y',
          stacked: true,
          display: true,
          position: 'left',
          min: 0,
          ticks: {
            suggestedMax: maxValueScaleX * 1.5,
            callback: function (value) {
              return value !== this.suggestedMax ? value : '';
            }
          }
        },
        {
          id: 'y1',
          type: 'linear',
          display: true,
          position: 'right',
          min: 0,
          gridLines: {
            drawOnChartArea: false
          },
          ticks: {
            suggestedMax: maxValueScaleY * 2,
            beginAtZero: true,
            callback: function (value) {
              return value !== this.suggestedMax ? value : '';
            }
          }
        }
      ]
    }
  };

  const chartData: any = {
    labels: labels,
    datasets: _.map(contents, (content, index) => {
      let config = {};
      if (content['name'] !== Column.CTR) {
        config = {
          type: 'bar',
          data: content['value'],
          order: 2,
          backgroundColor: renderColors[index],
          yAxisID: 'y',
          datalabels: {
            display: index === contents.length - 2,
            align: 'end',
            anchor: 'end',
            formatter: (_1, options) => {
              return totals[options.dataIndex];
            }
          }
        };
      } else {
        config = {
          type: 'line',
          fill: false,
          data: content['value'],
          order: 1,
          borderWidth: 2,
          borderColor: CHART_COLOR.CTR,
          backgroundColor: CHART_COLOR.CTR,
          yAxisID: 'y1',
          datalabels: {
            display: true,
            align: 'start',
            anchor: 'start',
            formatter: (value) => {
              return value !== 0 ? `${value}%` : '';
            }
          }
        };
      }

      return config;
    })
  };

  return (
    <div className={styles.block}>
      <div className={styles.title}>
        {`Daily Trend of Impressions by Platform vs Overall CTR (%)`}
      </div>

      <div className={styles.chartLabels}>
        <span>{`Impressions ${scale}`}</span>
        <span>{`CTR (%)`}</span>
      </div>

      {!_.isEmpty(contents) &&
        <Bar
          options={chartOptions}
          data={chartData}
          datasetKeyProvider={datasetKeyProvider}
        />
      }

      <div className={styles.chartLegends}>
        {_.map(contents, (content, index) => (
          <div key={index} className={styles.legend}>
            <span className={styles.classification} style={{ 'backgroundColor': `${renderColors[index]}` }}/>
            {content['name'] === Column.CTR
            ? `${content['name']} (%)`
            : content['name'] === Platform.FB
              ? `＊ ${LABEL[content['name']]}`
              : LABEL[content['name']]}
          </div>
        ))}
      </div>
    </div>
  );
};

export const ImpactsBlock: React.FC<{
  mainImpacts: any[],
  subImpacts: any[]
}> = ({
  mainImpacts,
  subImpacts
}) => {
  const mainImpactsClassName = cssClassNames('impacts', 'mainImpacts');
  const subImpactsClassName = cssClassNames('impacts', 'subImpacts');

  return (
    <div className={styles.block}>
      <div className={mainImpactsClassName}>
        {_.map(mainImpacts, (impact, index) => (
          <div key={index} className={styles.impact}>
            <div className={styles.title}>
              <span>{IMPACT_TITLE[impact['title']]}</span>
              <span className={cssClassNames('growth', impact['growth']['type'])}>
                {handleNumberOrText(impact['growth']['rate'], 2, true)}
                <span className={styles.triangle}/>
              </span>
            </div>
            <div className={styles.contents}>
              <div className={styles.content}>
                <span className={styles.value}>{handleNumberOrText(impact['value']['total'])}</span>
                <span>{`Total Summary`}</span>
              </div>
              <div className={styles.content}>
                <span className={styles.value}>{handleNumberOrText(impact['value']['average'])}</span>
                <span>{`Average Daily`}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className={subImpactsClassName}>
        {_.map(subImpacts, (impact, index) => (
          <div key={index} className={styles.impact}>
            <div className={styles.title}>
              <span>{IMPACT_TITLE[impact['title']]}</span>
              <span className={cssClassNames('growth', impact['growth']['type'])}>
                {handleNumberOrText(impact['growth']['rate'], 2, true)}
                <div className={styles.triangle}/>
              </span>
            </div>
            <div className={styles.contents}>
              <div className={styles.content}>
                <span className={styles.value}>{handleNumberOrText(impact['value'])}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export const AudienceBlock: React.FC<{
  usersByRegion: any[]
}> = ({
  usersByRegion
}) => {

  const handlePercentageBarElement = (target, max, type) => {
    let length = ((target / max) * 100).toString().concat('%');
    let progressClass = cssClassNames('progress', `usersProgress${type}`);
    let lengthStyle = { 'width': length };

    return (
      <div className={styles.percentageBar}>
        <div className={styles.progressBackground}>
          <div className={progressClass} style={lengthStyle}/>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.gridHalf}>
      {_.map(usersByRegion, (content, index) => {
        let users = _.map(content.data, (info) => info.users);
        let maxUsers = Math.max(...users);
        let sortedData = _.sortBy(content.data, 'users', info => info.region.length).reverse();

        return (
          <div className={styles.block} key={index}>
            <div className={styles.title}>
              {`${content.type} Users by Region`}
            </div>

            <div className={styles.usersRegion}>
              <div className={styles.usersLabel}>
                <span className={styles.label}>{`Users`}</span>
              </div>

              {_.map(sortedData, (info, _index) => (
                <div className={styles.regionData} key={_index}>
                  <span className={styles.region}>{info.region}</span>
                  {handlePercentageBarElement(info.users, maxUsers, content.type)}
                  <span className={styles.users}>{handleNumberOrText(info.users)}</span>
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const Notes: React.FC<{
  noteList: string[],
  benchmarks?: any[]
}> = ({
  noteList,
  benchmarks
}) => {

  const renderBenchmarks = () => {

    const BENCHMARK_PRIORITY = [
      Benchmark.AWARENESS,
      Benchmark.TRAFFIC,
      Benchmark.MIXED
    ];

    const PRICE_MODELS = [
      Column.CTR,
      Column.CPM,
      Column.CPC
    ];

    const sortBenchmarks = benchmarks ? benchmarks.sort((key1, key2) =>
      BENCHMARK_PRIORITY.indexOf(_.get(key1, 'objective_type', '')) > BENCHMARK_PRIORITY.indexOf(_.get(key2, 'objective_type', '')) ? 1 : -1
    ) : [];

    return (
     <div>
       {!_.isEmpty(sortBenchmarks) &&
        _.map(sortBenchmarks, (benchmark, index) => {
          const objectiveType: string = _.get(benchmark, 'objective_type', '');

          return (
            <div key={index} className={styles.benchmark}>
              <span className={styles.benchmarkTitle}>{LABEL[objectiveType]}</span>
              {_.map(PRICE_MODELS, (priceModel, _index) => (
                <div key={_index} className={styles.benchmarkContent}>
                  <span className={styles.content}>{`${priceModel} `}</span>
                  <span className={styles.content}>{handleNumberOrText(_.get(benchmark, `${priceModel.toLowerCase()}`, ''), 2, priceModel === Column.CTR)}</span>
                </div>
              ))}
            </div>
          );
        })}
     </div>
    );
  };

  return (
    <div className={styles.notes}>
      <span>{`Notes: `}</span>
      {_.map(noteList, (note, index) => (
        <div key={index} className={styles.row}>
          <span className={styles.lineTitle}>-</span>
          <div>
            <span className={styles.title}>{LABEL[note]}: </span>
            {note !== Note.BENCHMARKS ?
              <span className={styles.content}>{NOTES_CONTENT[note]}</span> :
              renderBenchmarks()
            }
          </div>
        </div>
      ))}
    </div>
  );
};

import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import { ColumnDefinition, renderColumn, SortDescriptor } from 'components/TableColumn';
import { CreativeOfCampaign } from 'core/creative/Creative';
import styles from './CreativeList.module.scss';
import DefaultCreativeManager, { CreativeManager } from 'core/creative/CreativeManager';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import moment from 'moment';
import _ from 'lodash';
import { AdType } from 'core/rtbCampaign/RtbCampaign';
import { CampaignGroup } from 'core/campaignGroup/CampaignGroup';
import { Project } from 'core/project/Project';

export interface CreativeListModel {
  readonly campaignGroup?: CampaignGroup;
  creativeList?: Array<CreativeOfCampaign>;
  readonly event: UpdateEventListener<CreativeListModel>;
  readonly state: CreativeListState;
  readonly noDataDescription: string;
  readonly handleOnSelect: any;
  readonly handleOnSelectAll: any;
  readonly selectedCreatives: Array<number | undefined>;
  readonly columnsToShow: Array<string>;
  readonly closingReportEnabled: boolean;
  readonly columns: any[];
  reviewCreative: (adx, creativeId) => void;
  goReportPage: (creative) => void;
  getEditPath: (creativeId) => string;
}

export type CreativeListModelProps = {
  readonly model: CreativeListModel;
};

export type CreativeListState = {
  readonly loading: boolean;
  readonly redirectPath?: string;
};

export enum CreativeListColumns {
  ID = 'id',
  PREVIEW = 'creativePreview',
  STATUS = 'bindingState',
  LAYOUT = 'ppsLayoutId',
  PRODUCTSET = 'productSet',
  DELIVERY = 'effectiveStatus',
  RESULTS = 'results',
  VTR = 'vtr',
  VCTR = 'vctr',
  CTR = 'ctr',
  VIMPRES = 'vimps',
  IMPRES = 'imps',
  VIEW = 'view',
  CLICKS = 'clicks',
  UU = 'lifetimeUU',
  TYPE = 'type',
  APPROVALSTATUS = 'approvalStatus',
  TOOL = 'tool'
}
abstract class DefaultCreativeListModel implements CreativeListModel {
  event: FireableUpdateEventListener<CreativeListModel>;
  creativeList?: Array<CreativeOfCampaign>;
  selectedCreatives: Array<number | undefined>;
  onSelect: (creativeId: Array<any>) => void;
  onSelectAll: () => void;
  columnsToShow: Array<string>;
  creativeManager: CreativeManager;
  loading: boolean;
  redirectPath?: string;
  closingReportEnabled: boolean;
  editPath: (creativeId) => string;
  isDraft: boolean;

  constructor (
    public campaignGroup: CampaignGroup | undefined,
    campaignInfo: any,
    creativeList: Array<CreativeOfCampaign>,
    selectedCreatives: Array<number | undefined>,
    closingReportEnabled: boolean,
    handleOnSelect,
    handleOnSelectAll,
    editPath: (creativeId) => string,
    private listFormatters: {[key: string]: any},
    columnsToShow?: Array<string>,
    creativeManager: CreativeManager = new DefaultCreativeManager()
  ) {
    this.creativeList = this.getViewModelData(creativeList.map(creative => {
      return {
        ...creative,
        results: _.get(creative, 'report.results', 0),
        lifetimeUU: _.get(creative, 'report.lifetimeUU', 0),
        campaignInfo: campaignInfo,
        selected: selectedCreatives ? selectedCreatives.indexOf(creative.id) > -1 : false
      };
    }));
    const adType = campaignInfo.adType;
    const useImpres = adType === AdType.VIDEO || adType === AdType.THIRD_PARTY_BOTTOM || adType === AdType.THIRD_PARTY_RECTANGLE || adType === AdType.OUTDOOR;
    const useVImpres = adType === AdType.DISPLAY;
    const useView = adType === AdType.VIDEO || adType === AdType.COMBO;
    this.event = new FireableUpdateEventListener<CreativeListModel>();
    this.selectedCreatives = selectedCreatives;
    this.onSelect = handleOnSelect;
    this.onSelectAll = handleOnSelectAll;
    this.columnsToShow = columnsToShow ? columnsToShow : _.compact([
      CreativeListColumns.ID,
      CreativeListColumns.PREVIEW,
      CreativeListColumns.STATUS,
      CreativeListColumns.DELIVERY,
      CreativeListColumns.RESULTS,
      useView ? CreativeListColumns.VTR : undefined,
      useVImpres ? CreativeListColumns.VCTR : undefined,
      useImpres ? CreativeListColumns.CTR : undefined,
      useVImpres ? CreativeListColumns.VIMPRES : undefined,
      useImpres ? CreativeListColumns.IMPRES : undefined,
      useView ? CreativeListColumns.VIEW : undefined,
      adType !== AdType.VIDEO ? CreativeListColumns.CLICKS : undefined,
      CreativeListColumns.UU,
      CreativeListColumns.TYPE,
      CreativeListColumns.APPROVALSTATUS,
      CreativeListColumns.TOOL
    ]);
    this.creativeManager = creativeManager;
    this.loading = false;
    this.closingReportEnabled = closingReportEnabled;
    this.editPath = editPath;
    this.isDraft = !!campaignInfo.draftId;
  }

  abstract get columns ();

  handleOnSelect = (id) => {
    this.onSelect(id);
    this.updateState();
  }

  handleOnSelectAll = () => {
    this.onSelectAll();
    this.updateState();
  }

  getEditPath (creativeId) {
    return this.editPath(creativeId);
  }

  getViewModelData (creativeList?: Array<CreativeOfCampaign>) {
    if (creativeList !== undefined && creativeList.length > 0) {
      creativeList.unshift(this.getSummaryData(creativeList));
    }
    return creativeList;
  }

  getSummaryData = (creativeList?: Array<CreativeOfCampaign>) => {
    let clickSum = 0;
    let impresSum = 0;
    let vimpresSum = 0;
    let viewSum = 0;
    let vtr = 0;
    let vctr = 0;
    let ctr = 0;
    if (creativeList !== undefined) {
      clickSum = creativeList.reduce<number>((partial, creative) => partial + creative.clicks, 0);
      impresSum = creativeList.reduce<number>((partial, creative) => partial + creative.imps, 0);
      vimpresSum = creativeList.reduce<number>((partial, creative) => partial + creative.vimps, 0);
      viewSum = creativeList.reduce<number>((partial, creative) => partial + creative.view, 0);
      if (impresSum !== 0) {
        ctr = clickSum / impresSum;
        vtr = viewSum / impresSum;
      }
      if (vimpresSum !== 0) {
        vctr = clickSum / vimpresSum;
      }
    }

    return {
      rtbCreativeId: 0,
      id: 0,
      name: '',
      status: 0,
      ppsLayoutId: [],
      productSet: {
        productSet: '',
        productSetId: ''
      },
      results: 0,
      vctr,
      vtr,
      ctr: ctr,
      imps: impresSum,
      vimps: vimpresSum,
      view: viewSum,
      clicks: clickSum,
      lifetimeUU: '',
      size: '',
      creativeType: 0,
      enableStartTime: '',
      enableEndTime: '',
      approvalStatus: '',
      bannerImageUrl: '',
      creativeValue: {},
      createTime: '',
      landingPageUrl: '',
      isActiveBinding: false,
      tenmaxCategory: '',
      origTenmaxCategory: '',
      shortcutUrl: '',
      bindingState: 0,
      goBindingId: ''
    };
  }

  reviewCreative = async (adx, creativeId) => {
    this.updateState(true);
    try {
      await this.creativeManager.reviewCreatives([creativeId], [adx]);
      toast.success(i18next.t('creativeList.labels.reviewSuccess'));
      this.updateState(false);
    } catch (e) {
      toast.success(i18next.t('creativeList.labels.reviewFailed'));
      this.updateState();
    }
  }

  get state (): CreativeListState {
    return {
      loading: this.loading,
      redirectPath: this.redirectPath
    };
  }

  get noDataDescription (): string {
    return 'campaignCreativeList.headers.noDataAvailable';
  }

  get creativeIDColumn (): ColumnDefinition {
    const columnClassGetter = () => {
      return styles.creativeIdColumn;
    };
    return renderColumn({
      dataField: 'id',
      text: 'campaignCreativeList.headers.creativeName',
      sort: false,
      classes: columnClassGetter,
      headerClasses: columnClassGetter,
      formatExtraData: {
        handleOnSelect: this.handleOnSelect
      }
    }, this.listFormatters.creatvieIDFormatter,
    _.partial(
      this.listFormatters.nameHeaderFormatter,
      this.creativeList ? this.creativeList.length : 0,
      this.selectedCreatives.length,
      this.handleOnSelectAll
    ));
  }

  get creativePreviewColumn (): ColumnDefinition {
    const columnClassGetter = () => {
      return styles.preveiwColumn;
    };
    return renderColumn({
      dataField: 'creativePreview',
      text: 'campaignCreativeList.headers.creativePreview',
      sort: false,
      classes: columnClassGetter,
      headerClasses: columnClassGetter
    }, this.listFormatters.creativePreviewFormatter);
  }

  get creativeStatusColumn (): ColumnDefinition {
    const columnClassGetter = () => {
      return styles.statusColumn;
    };
    return renderColumn({
      dataField: 'bindingState',
      text: 'campaignCreativeList.headers.creativeStatus',
      sort: false,
      classes: columnClassGetter,
      headerClasses: columnClassGetter,
      formatExtraData: {
        isDraft: this.isDraft
      }
    }, this.listFormatters.stateFormatter);
  }

  get creativeLayoutColumn (): ColumnDefinition {
    const columnClassGetter = () => {
      return styles.layoutColumn;
    };
    return renderColumn({
      dataField: 'ppsLayoutId',
      text: 'campaignCreativeList.headers.creativeLayout',
      sort: false,
      classes: columnClassGetter,
      headerClasses: columnClassGetter
    }, this.listFormatters.layoutFormatter);
  }

  get creativeProductSetColumn (): ColumnDefinition {
    const columnClassGetter = () => {
      return styles.productSetColumn;
    };
    return renderColumn({
      dataField: 'productSet',
      text: 'campaignCreativeList.headers.creativeProductSet',
      sort: false,
      classes: columnClassGetter,
      headerClasses: columnClassGetter
    }, this.listFormatters.productSetFormatter);
  }

  get creativeDeliveryColumn (): ColumnDefinition {
    const columnClassGetter = () => {
      return styles.deliveryColumn;
    };
    return renderColumn({
      dataField: 'effectiveStatus',
      text: 'campaignCreativeList.headers.effectiveStatus',
      sort: false,
      classes: columnClassGetter,
      headerClasses: columnClassGetter,
      formatExtraData: {
        isDraft: this.isDraft
      }
    }, this.listFormatters.deliveryFormatter);
  }

  get vtrColumn (): ColumnDefinition {
    const columnClassGetter = () => {
      return styles.vtrColumn;
    };
    return renderColumn({
      dataField: 'vtr',
      text: 'campaignCreativeList.headers.vtr',
      sort: false,
      classes: columnClassGetter,
      headerClasses: columnClassGetter
    }, this.listFormatters.percentageFormatter);
  }

  get vctrColumn (): ColumnDefinition {
    const columnClassGetter = () => {
      return styles.ctrColumn;
    };
    return renderColumn({
      dataField: 'vctr',
      text: 'campaignCreativeList.headers.vctr',
      sort: false,
      classes: columnClassGetter,
      headerClasses: columnClassGetter
    }, this.listFormatters.percentageFormatter);
  }

  get ctrColumn (): ColumnDefinition {
    const columnClassGetter = () => {
      return styles.ctrColumn;
    };
    return renderColumn({
      dataField: 'ctr',
      text: 'campaignCreativeList.headers.ctr',
      sort: false,
      classes: columnClassGetter,
      headerClasses: columnClassGetter
    }, this.listFormatters.percentageFormatter);
  }

  get vimpsColumn (): ColumnDefinition {
    const columnClassGetter = () => {
      return styles.impsColumn;
    };
    return renderColumn({
      dataField: 'vimps',
      text: 'campaignCreativeList.headers.vimps',
      sort: false,
      classes: columnClassGetter,
      headerClasses: columnClassGetter
    }, this.listFormatters.numberFormatter);
  }

  get impsColumn (): ColumnDefinition {
    const columnClassGetter = () => {
      return styles.impsColumn;
    };
    return renderColumn({
      dataField: 'imps',
      text: 'campaignCreativeList.headers.imps',
      sort: false,
      classes: columnClassGetter,
      headerClasses: columnClassGetter
    }, this.listFormatters.numberFormatter);
  }

  get resultsColumn (): ColumnDefinition {
    const columnClassGetter = () => {
      return styles.resultsColumn;
    };
    return renderColumn({
      dataField: 'results',
      text: 'campaignCreativeList.headers.results',
      sort: false,
      classes: columnClassGetter,
      headerClasses: columnClassGetter,
      formatExtraData: {
        campaignGroup: this.campaignGroup
      }
    }, this.listFormatters.resultsFormatter);
  }

  get viewColumn (): ColumnDefinition {
    const columnClassGetter = () => {
      return styles.viewColumn;
    };
    return renderColumn({
      dataField: 'view',
      text: 'campaignCreativeList.headers.view',
      sort: false,
      classes: columnClassGetter,
      headerClasses: columnClassGetter
    }, this.listFormatters.numberFormatter);
  }

  get clicksColumn (): ColumnDefinition {
    const columnClassGetter = () => {
      return styles.clicksColumn;
    };
    return renderColumn({
      dataField: 'clicks',
      text: 'campaignCreativeList.headers.clicks',
      sort: false,
      classes: columnClassGetter,
      headerClasses: columnClassGetter
    }, this.listFormatters.numberFormatter);
  }

  get uuColumn (): ColumnDefinition {
    const columnClassGetter = () => {
      return styles.uuColumn;
    };
    return renderColumn({
      dataField: 'lifetimeUU',
      text: 'campaignCreativeList.headers.uu',
      sort: false,
      classes: columnClassGetter,
      headerClasses: columnClassGetter,
      formatExtraData: {
        channel: _.get(this.campaignGroup, 'channel')
      }
    }, this.listFormatters.uuFormatter);
  }

  get typeAndSizeColumn (): ColumnDefinition {
    return renderColumn({
      dataField: 'type',
      text: 'campaignCreativeList.headers.typeAndSize',
      sort: false
    }, this.listFormatters.typeAndSizeFormatter);
  }

  get approvalStatusColumn (): ColumnDefinition {
    const columnClassGetter = () => {
      return styles.approvalStatusColumn;
    };
    return renderColumn({
      dataField: 'approvalStatus',
      text: 'campaignCreativeList.headers.approvalStatus',
      sort: false,
      classes: columnClassGetter,
      headerClasses: columnClassGetter,
      formatExtraData: {
        isDraft: this.isDraft,
        reviewCreative: this.reviewCreative
      }
    }, this.listFormatters.approvalFormatter);
  }

  get toolColumn (): ColumnDefinition {
    return renderColumn({
      dataField: 'tool',
      text: '',
      sort: false,
      classes: 'editCreativeArea',
      formatExtraData: {
        goReportPage: this.goReportPage.bind(this),
        getEditPath: this.getEditPath.bind(this),
        closingReportEnabled: this.closingReportEnabled
      }
    }, this.listFormatters.floatingEditBtnsFormatter);
  }

  get defaultSorts (): Array<SortDescriptor> {
    return [{
      dataField: 'creativeNumber',
      order: 'asc'
    }];
  }

  goReportPage (creative) {
    const campaignInfo = creative.campaignInfo;
    const from = encodeURIComponent(moment(campaignInfo.startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'));
    const to = encodeURIComponent(moment(campaignInfo.endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss'));
    this.redirectPath =
      `/reports/performance?dimension=goBindingId&from=${from}&to=${to}&goCampaignId=${campaignInfo.id}&goBindingId=${creative.goBindingId}`;
    this.updateState(false);
  }

  updateState (loading: boolean = false) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}

class RetailCreativeListModel extends DefaultCreativeListModel {
  get columns () {
    return [
      this.creativeIDColumn,
      this.creativePreviewColumn,
      this.approvalStatusColumn,
      this.creativeStatusColumn,
      this.creativeLayoutColumn,
      this.creativeProductSetColumn,
      this.creativeDeliveryColumn,
      this.resultsColumn,
      this.vtrColumn,
      this.vctrColumn,
      this.ctrColumn,
      this.vimpsColumn,
      this.impsColumn,
      this.viewColumn,
      this.clicksColumn,
      this.uuColumn,
      this.typeAndSizeColumn,
      this.toolColumn
    ].filter(column => this.columnsToShow.indexOf(column.dataField) > -1);
  }
}

class GojekCreativeListModel extends DefaultCreativeListModel {

  get columns () {
    return [
      this.creativeIDColumn,
      this.creativePreviewColumn,
      this.approvalStatusColumn,
      this.creativeStatusColumn,
      this.creativeDeliveryColumn,
      this.resultsColumn,
      this.vtrColumn,
      this.vctrColumn,
      this.ctrColumn,
      this.vimpsColumn,
      this.impsColumn,
      this.viewColumn,
      this.clicksColumn,
      this.uuColumn,
      this.typeAndSizeColumn,
      this.toolColumn
    ].filter(column => this.columnsToShow.indexOf(column.dataField) > -1);
  }
}

export default process.env.REACT_APP_PROJECT === Project.RETAIL ?
  RetailCreativeListModel :
  GojekCreativeListModel;

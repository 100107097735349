import _ from 'lodash';
import moment from 'moment';
import {
  RtbCampaignBasic,
  RtbCampaignPlanType,
  RtbCampaignOptimze,
  CreativeDeliverType,
  DailyBudgetPlan,
  VideoAdMetricEvent,
  DeliverType,
  CampaignState,
  AdType
} from 'core/rtbCampaign/RtbCampaign';
import { SelectOptions } from 'components/common/commonType';
import * as SelectOptionsUtils from 'utils/SelectOptionsUtils';
import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import { Currency, AddonFeatureManager, LocaleMeta } from 'core';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { getDecimalPlaceCount, getPriceValue } from 'helper/CurrencyHelper';
import { Order } from 'core/order/Order';
import { DefaultRtbCampaignBasicFormValidator, RtbCampaignBasicFormValidator } from './RtbCampaignBasicFormValidator';
import { CampaignGroup } from 'core/campaignGroup/CampaignGroup';
import { DefaultRtbCampaignManager } from 'core/rtbCampaign/RtbCampaignManager';
import { GoCampaignOptimizationGoal } from 'core/goCampaign/GoCampaign';
import { Retail } from 'core/product/Product';
import i18n from 'i18n';

export interface RtbCampaignBasicFormModel {
  readonly actionType: string;
  readonly campaignGroup: CampaignGroup | undefined;
  readonly addonFeatureManager: AddonFeatureManager;
  readonly canUseDailyBudget: boolean;
  readonly priceModelOptions: SelectOptions[];
  readonly optimizeOptions: SelectOptions[];
  readonly creativeDeliverTypeOptions: SelectOptions[];
  readonly campaignBasic: RtbCampaignBasic;
  readonly videoAdMetricEventOptions: SelectOptions[];
  readonly campaignDeliverTypeOptions: SelectOptions[];
  canEditOptimize: () => boolean;
  readonly orderName: string;
  readonly agencyProfit?: string;
  readonly currency: string;
  readonly budgetMinimum: number;
  readonly canEditBudgetPlan: boolean;
  readonly canEditPriceModel: boolean;
  readonly canEditEnableMonitor: boolean;
  readonly minDate: string;
  readonly maxDate: string;
  readonly canEditVideoViewObjective: boolean;
  readonly defaultPriceModel: string;
  readonly campaignAdType: AdType;
  readonly showOptimizeSection: boolean;
  readonly showFrequencyCap: boolean;
  readonly availableOptimize: RtbCampaignOptimze[];
  readonly availablePriceModel: RtbCampaignPlanType[];
  readonly retailerOptions?: SelectOptions[];
  getBidPriceMinData (): any;
  getCurrentPriceModel (): RtbCampaignPlanType;
  setCurrentPriceModel (planType: RtbCampaignPlanType);
  getOptimizeMinimum: (optimizeType: RtbCampaignOptimze, localeMeta?: LocaleMeta) => number | null;
  getOrderPriceMinimum: (planType: RtbCampaignPlanType, localeMeta?: LocaleMeta) => number | null;
  getDefaultOptimizeType: (planType: RtbCampaignPlanType) => RtbCampaignOptimze | undefined;
  getRemainBudget: (campaignBudget: number) => number;
  changeDailyBudgetOptions: (dailyBudgetType: DailyBudgetPlan) => void;
  getDailyBudgetState: (
    totalBudget: number,
    dailyBudget: number | undefined,
    totalDay: number
  ) => DAILY_BUDGET_STATE;
  getCampaignTotalDay: (startDate: string, endDate: string) => number;
  state: RtbCampaignBasicFormState;
  readonly event: UpdateEventListener<RtbCampaignBasicFormModel>;
  onPriceModelChangeCallback: (type: RtbCampaignPlanType, campaignBasic: RtbCampaignBasic) => void;
  validate: (campaignBasic: any, order: Order, localeMeta?: LocaleMeta) => any;
  onUnmount: (handler?: number) => void;
}

export enum DAILY_BUDGET_STATE {
  DEFAULT,
  UNDER_BUDGET,
  OVER_BUDGET,
  MEET_BUDGET
}

export type RtbCampaignBasicFormProps = {
  readonly model: RtbCampaignBasicFormModel;
};

export type RtbCampaignBasicFormState = {
  dailyBudgetType: DailyBudgetPlan;
};

export type RtbCampaignBasicFormModelConstructorParams = [
  any,
  RtbCampaignBasic,
  Order,
  CampaignGroup | undefined,
  AddonFeatureManager,
  (type: RtbCampaignPlanType, campaignBasic: RtbCampaignBasic) => void,
  RtbCampaignBasic[]?,
  Retail[]?
];

export abstract class DefaultRtbCampaignBasicFormModel
  implements RtbCampaignBasicFormModel {
  modelDailyBudgetType: DailyBudgetPlan;
  event: FireableUpdateEventListener<RtbCampaignBasicFormModel>;
  currentPriceModel: RtbCampaignPlanType;
  canUseRS: boolean;
  canUseRB: boolean;
  validator: RtbCampaignBasicFormValidator;
  rtbCampaignManager = new DefaultRtbCampaignManager();
  retailerOptions?: SelectOptions[] = undefined;

  constructor (
    public actionType: string,
    private defaultCampaign: any,
    public campaignBasic: RtbCampaignBasic,
    public order: Order,
    public campaignGroup: CampaignGroup | undefined,
    public addonFeatureManager: AddonFeatureManager,
    public onPriceModelChangeCallback: (type: RtbCampaignPlanType, campaignBasic: RtbCampaignBasic) => void,
    private otherCampaignOfCampaignGroup?: RtbCampaignBasic[],
    retailers?: Retail[]
  ) {
    this.modelDailyBudgetType = !campaignBasic.dailyTargetBudget || campaignBasic.dailyTargetBudget < 1 ? DailyBudgetPlan.SCHEDULE : DailyBudgetPlan.DAILY;
    this.event = new FireableUpdateEventListener<RtbCampaignBasicFormModel>();
    this.currentPriceModel = this.campaignBasic.priceModel;
    this.canUseRS = this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CAMPAIGN.REVENUE_SHARING);
    this.canUseRB = this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CAMPAIGN.ROADBLOCK);
    this.canEditOptimize = this.canEditOptimize.bind(this);
    this.validator = new DefaultRtbCampaignBasicFormValidator(
      actionType,
      this.defaultCampaign,
      this.campaignBasic,
      this.getOrderPriceMinimum.bind(this),
      this.getOptimizeMinimum.bind(this),
      this.addonFeatureManager,
      this.canEditOptimize()
    );
    this.retailerOptions = retailers ? retailers.map(retail => ({
      value: retail.id,
      label: i18n.t(`retailers.${retail.name.toLowerCase()}`)
    })) : undefined;
  }

  abstract getBidPriceMinData (): any;

  get campaignAdType (): AdType {
    return AdType.UNKNOW;
  }
  get showOptimizeSection (): boolean {
    return true;
  }
  get showFrequencyCap (): boolean {
    return true;
  }

  getCurrentPriceModel (): RtbCampaignPlanType {
    return this.currentPriceModel;
  }

  setCurrentPriceModel (planType: RtbCampaignPlanType) {
    this.currentPriceModel = planType;
  }

  abstract showVideoProgressRadio: boolean;

  getAddonByPlanTypeOrOptimize (planType) {
    switch (planType) {
      case RtbCampaignPlanType.FCPC:
      case RtbCampaignOptimze.CPC:
        return ADDONFEATURE.CAMPAIGN.FIXED_CPC;
      case RtbCampaignPlanType.FCPM:
      case RtbCampaignOptimze.CPM:
        return ADDONFEATURE.CAMPAIGN.FIXED_CPM;
      case RtbCampaignPlanType.FVCPM:
      case RtbCampaignOptimze.VCPM:
        return ADDONFEATURE.CAMPAIGN.FIXED_VCPM;
      case RtbCampaignPlanType.DCPM:
        return ADDONFEATURE.CAMPAIGN.DYNAMIC_CPM;
      default:
        return;
    }
  }

  get availableOptimize () {
    const availableOptimize: any[] = [];
    if (this.currentPriceModel === RtbCampaignPlanType.RS) {
      availableOptimize.push(RtbCampaignOptimze.CPM);
      this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CAMPAIGN.OPTIMIZE_CPC_IN_RS) && availableOptimize.push(RtbCampaignOptimze.CPC);
      this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CAMPAIGN.OPTIMIZE_VCPM_IN_RS) && availableOptimize.push(RtbCampaignOptimze.VCPM);
    } else if (this.currentPriceModel === RtbCampaignPlanType.DCPM) {
      availableOptimize.push(RtbCampaignOptimze.CPM);
      availableOptimize.push(RtbCampaignOptimze.CPC);
    }
    return availableOptimize;
  }

  get availablePriceModel () {
    const minPriceData = this.getBidPriceMinData();
    const availablePriceModel: any[] = [];
    Object.values(RtbCampaignPlanType).forEach(priceModel => {
      const relatedAddon = this.getAddonByPlanTypeOrOptimize(priceModel);
      const addonEnable = relatedAddon ? this.addonFeatureManager.isFeatureEnable(relatedAddon) : true;
      // fcpc, fcpm, fvcpm => cpc, cpm, vcpm
      if (minPriceData[priceModel.toLowerCase().replace('f', '')] !== undefined && addonEnable) {
        availablePriceModel.push(priceModel);
      } else if (priceModel === RtbCampaignPlanType.DCPM && addonEnable) {
        availablePriceModel.push(priceModel);
      }
    });
    this.canUseRS && availablePriceModel.push(RtbCampaignPlanType.RS);
    this.canUseRB && availablePriceModel.push(RtbCampaignPlanType.RB);
    return availablePriceModel;
  }

  get defaultPriceModel (): string {
    return this.canUseRS ? RtbCampaignPlanType.RS : this.availablePriceModel[0];
  }

  get rsDefaultOptimizeModel (): RtbCampaignOptimze {
    return this.availableOptimize[0];
  }

  get canEditVideoViewObjective (): boolean {
    return this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CAMPAIGN.VIDEO_AD_VIEW_OBJECTIVE);
  }

  get priceModelOptions (): SelectOptions[] {
    const options = SelectOptionsUtils.createSelectOptionsFromEnum(
      RtbCampaignPlanType, 'campaign.labels.'
    );
    return SelectOptionsUtils.filter(options, this.availablePriceModel);
  }

  get optimizeOptions (): SelectOptions[] {
    const options = this.campaignGroup ?
      this.rtbCampaignManager.getOptimizationGoalOptions(this.campaignGroup) :
      SelectOptionsUtils.createSelectOptionsFromEnum(
        RtbCampaignOptimze, 'campaign.labels.'
      );

    return options;
  }

  get campaignDeliverTypeOptions (): SelectOptions[] {
    return SelectOptionsUtils.createSelectOptionsFromEnum(
      DeliverType, 'campaign.labels.'
    );
  }

  get creativeDeliverTypeOptions (): SelectOptions[] {
    return SelectOptionsUtils.createSelectOptionsFromEnum(
      CreativeDeliverType, 'campaign.labels.'
    );
  }

  get videoAdMetricEventOptions (): SelectOptions[] {
    return SelectOptionsUtils.createSelectOptionsFromEnum(VideoAdMetricEvent, 'campaign.labels.');
  }

  getRemainBudget (campaignBudget: number): number {
    const budgetBalance = _.get(this.order, 'budgetBalance', 0);
    const totalBudget = this.defaultCampaign.basic.isDraft ?
      budgetBalance :
      budgetBalance + this.defaultCampaign.basic.budget;
    return _.round(Number(totalBudget) - campaignBudget, 2);
  }

  get budgetMinimum (): number {
    const budgetMinimum = _.get(
      this.order,
      'campaignConstraint.budgetMinimum',
      100
    );
    const spents = this.campaignBasic.spents ? this.campaignBasic.spents : 0;
    return this.campaignBasic.state === CampaignState.DEACTIVATE ? getPriceValue(this.order.currency, spents) : Number(budgetMinimum);
  }

  getDailyBudgetState (
    totalBudget: number,
    dailyBudget: number | undefined,
    totalDay: number
  ): DAILY_BUDGET_STATE {
    if (!dailyBudget || dailyBudget < 1 || dailyBudget > totalBudget) {
      return DAILY_BUDGET_STATE.DEFAULT;
    }
    const lastDay = totalBudget / dailyBudget;
    if (lastDay === totalDay) {
      return DAILY_BUDGET_STATE.MEET_BUDGET;
    }
    if (lastDay < totalDay) {
      return DAILY_BUDGET_STATE.OVER_BUDGET;
    }
    return DAILY_BUDGET_STATE.UNDER_BUDGET;
  }

  get canUseDailyBudget (): boolean {
    return this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CAMPAIGN.BUDGET_DOMINATE);
  }

  canEditOptimize (): boolean {
    if (
      this.campaignGroup &&
      this.campaignGroup.autoOptimise &&
      this.otherCampaignOfCampaignGroup &&
      this.otherCampaignOfCampaignGroup.length > 0
    ) {
      return false;
    }
    return true;
  }

  get canEditEnableMonitor (): boolean {
    return this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.MONITOR.MONITOR_CAMPAIGN_BEHIND_PROGRESS);
  }

  get orderName (): string {
    return `${_.get(this.order, 'projectName')} (${_.get(
      this.order,
      'orderNumber'
    )})`;
  }

  get agencyProfit (): string | undefined {
    const agencyProfit = _.get(this.order, 'orderMargin', 0);
    return _.round(Number(agencyProfit) * 100, 2) + ' %';
  }

  get minDate (): string {
    const orderStartDate = moment(_.get(this.order, 'startDate'));
    const thisHour = moment().startOf('hour').format('YYYY-MM-DD_HH:mm:ss');
    if (this.campaignBasic && this.campaignBasic.startDate) {
      return moment(this.campaignBasic.startDate).isBefore() ? moment(this.campaignBasic.startDate).format('YYYY-MM-DD_HH:mm:ss') : thisHour;
    }
    return moment().isAfter(orderStartDate) ? thisHour : orderStartDate.format('YYYY-MM-DD_HH:mm:ss');
  }

  get maxDate (): string {
    return moment(_.get(this.order, 'endDate')).endOf('day').format('YYYY-MM-DD_HH:mm:ss');
  }

  getOptimizeMinimum (
    optimizeType?: RtbCampaignOptimze | RtbCampaignPlanType | GoCampaignOptimizationGoal,
    localeMeta?: LocaleMeta
  ): number {
    let bidPriceMinData = this.getBidPriceMinData();
    if (_.get(localeMeta, 'bidPriceConstraint') === 'MANUAL') {
      bidPriceMinData = {
        cpc: _.get(this.order, 'campaignConstraint.bidPriceCPCMin'),
        cpv: _.get(this.order, 'campaignConstraint.bidPriceCPVMin'),
        cpm: _.get(this.order, 'campaignConstraint.bidPriceCPMMin'),
        vcpm: _.get(this.order, 'campaignConstraint.bidPricevCPMMin')
      };
    }

    switch (optimizeType) {
      case RtbCampaignOptimze.CPC:
      case RtbCampaignPlanType.FCPC:
      case GoCampaignOptimizationGoal.LINK_CLICKS:
      case GoCampaignOptimizationGoal.OFFSITE_CONVERSIONS:
        return bidPriceMinData.cpc;
      case RtbCampaignOptimze.CPV:
      case RtbCampaignPlanType.FCPV:
        return bidPriceMinData.cpv;
      case RtbCampaignOptimze.VCPM:
      case RtbCampaignPlanType.FVCPM:
        return bidPriceMinData.vcpm;
      case RtbCampaignOptimze.CPM:
      case RtbCampaignPlanType.FCPM:
      case GoCampaignOptimizationGoal.IMPRESSIONS:
      case GoCampaignOptimizationGoal.REACH:
        return bidPriceMinData.cpm;
      case RtbCampaignPlanType.RB:
      case RtbCampaignPlanType.RS:
      default:
        return 0;
    }
  }

  getDefaultOptimizeType (priceModel: RtbCampaignPlanType) {
    const getCampaignGroupDefaultOptimizationGoal = (campaignGroup) => {
      let campaignGroupDefaultOptimizationGoal = this.rtbCampaignManager.getDefaultOptimizationGoal(campaignGroup);
      if (
        this.campaignGroup &&
        this.campaignGroup.autoOptimise &&
        this.otherCampaignOfCampaignGroup &&
        this.otherCampaignOfCampaignGroup.length > 0
      ) {
        campaignGroupDefaultOptimizationGoal = this.otherCampaignOfCampaignGroup[0].optimize;
      }
      return campaignGroupDefaultOptimizationGoal;
    };
    const goGanDefaultOptimizationGoal = this.campaignGroup ?
      getCampaignGroupDefaultOptimizationGoal(this.campaignGroup) :
      GoCampaignOptimizationGoal.IMPRESSIONS;
    switch (priceModel) {
      case RtbCampaignPlanType.FCPC:
        return RtbCampaignOptimze.CPC;
      case RtbCampaignPlanType.FCPM:
        return RtbCampaignOptimze.CPM;
      case RtbCampaignPlanType.FCPV:
        return RtbCampaignOptimze.CPV;
      case RtbCampaignPlanType.FVCPM:
        return RtbCampaignOptimze.VCPM;
      case RtbCampaignPlanType.RS:
        return this.rsDefaultOptimizeModel;
      case RtbCampaignPlanType.DCPM:
        return goGanDefaultOptimizationGoal;
      default:
        return RtbCampaignOptimze.CPM;
    }
  }

  getCampaignTotalDay (startDate: string, endDate: string): number {
    const format = 'YYYY-MM-DD';
    return moment(endDate, format)
      .add(1, 'days')
      .diff(moment(startDate, format), 'days');
  }

  getOrderPriceMinimum (planType: RtbCampaignPlanType, localeMeta?: LocaleMeta): number | null {
    return this.getOptimizeMinimum(planType, localeMeta);
  }

  get currency (): string {
    const currency = _.get(this.order, 'currency', Currency.NTD);
    return currency;
  }

  abstract get canEditBudgetPlan (): boolean;
  abstract get canEditPriceModel (): boolean;

  changeDailyBudgetOptions (dailyBudgetType: DailyBudgetPlan): void {
    this.updateState(dailyBudgetType);
  }

  validate (campaignBasic: RtbCampaignBasic, order: Order, localeMeta?: LocaleMeta) {
    const isDailySchedule = this.state.dailyBudgetType.toString() === DailyBudgetPlan.DAILY.toString();
    const decimalPlaceCount = getDecimalPlaceCount(order.currency);
    const otherCampaignCost = this.otherCampaignOfCampaignGroup ? this.otherCampaignOfCampaignGroup.reduce((acc, campaign) => {
      return acc + Math.max(_.ceil(campaign.expectedSpent, decimalPlaceCount), this.rtbCampaignManager.getMinBudgetOfCampaign(campaign, order.campaignConstraint.budgetMinimum));
    }, 0) : 0;
    return this.validator.validate(this.campaignGroup, campaignBasic, order, isDailySchedule, otherCampaignCost, localeMeta);
  }

  onUnmount (handler?: number) {
    handler && this.event.remove(handler);
    this.modelDailyBudgetType = DailyBudgetPlan.SCHEDULE;
  }

  updateState (dailyBudgetType: DailyBudgetPlan): void {
    this.modelDailyBudgetType = dailyBudgetType;
    this.event.fireEvent(this);
  }

  get state (): RtbCampaignBasicFormState {
    return {
      dailyBudgetType: this.modelDailyBudgetType
    };
  }
}

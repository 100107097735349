import { ModalState } from 'containers/Common/ModalStateFactory';
import { CampaignGroup, CampaignGroupChannel } from 'core/campaignGroup/CampaignGroup';
import i18n from 'i18n';
import { DraftSummaryStateContent } from './DraftSummaryStateContent';
import { FbAdSetDraftSummaryStateContentModel, RtbCampaignDraftSummaryStateContentModel, TiktokAdGroupDraftSummaryStateContentModel, RetailCampaignDraftSummaryStateContentModel, DefaultDraftSummaryStateContentModel } from './DraftSummaryStateContentModel';

export abstract class DraftSummaryState implements ModalState {

  back?;
  next?;
  close?;

  constructor (
    protected campaignGroup: CampaignGroup,
    protected goCampaignsToCreateDraft: {
      id: number | string,
      isDraft: boolean
    } []
  ) {
    this.next = async () => {
      await this.contentModel.save();
      this.close && this.close(true);
    };
  }

  abstract get title ()

  titleFormatter = (title) => {
    if (i18n.language === 'en' && this.goCampaignsToCreateDraft.length > 1) {
      return title = title + 's';
    }
    return title;
  }

  abstract get contentModel (): DefaultDraftSummaryStateContentModel;

  get content () {
    return DraftSummaryStateContent;
  }

  get btnData (): any {
    return {
      primaryButton: this.next && {
        title: i18n.t('draftCreateModal.buttons.createDraft'),
        callback: this.next
      },
      secondaryButton: this.back && {
        title: i18n.t('draftCreateModal.buttons.backToModify'),
        callback: this.back
      },
      dangerButton: this.close && {
        title: i18n.t('common.buttons.cancel'),
        callback: () => {
          this.close(false);
        }
      }
    };
  }

  canGoNext = () => {
    return this.contentModel.canGoNext;
  }
}

export class RtbCampaignDraftSummaryState extends DraftSummaryState {

  _contenModel = new RtbCampaignDraftSummaryStateContentModel();

  get title () {
    return this.titleFormatter(i18n.t('draftCreateModal.title', {
      l2Object: i18n.t(`l2ObjectName.${CampaignGroupChannel.RTB.toLowerCase()}`),
      number: this.goCampaignsToCreateDraft.length
    }));
  }

  get contentModel () {
    return this._contenModel;
  }
}

export class FbAdSetDraftSummaryState extends DraftSummaryState {

  _contenModel = new FbAdSetDraftSummaryStateContentModel();

  get title () {
    return this.titleFormatter(i18n.t('draftCreateModal.title', {
      l2Object: i18n.t(`l2ObjectName.${CampaignGroupChannel.FB.toLowerCase()}`),
      number: this.goCampaignsToCreateDraft.length
    }));
  }

  get contentModel () {
    return this._contenModel;
  }
}

export class TiktokAdGroupDraftSummaryState extends DraftSummaryState {

  _contenModel = new TiktokAdGroupDraftSummaryStateContentModel();

  get title () {
    return this.titleFormatter(i18n.t('draftCreateModal.title', {
      l2Object: i18n.t(`l2ObjectName.${CampaignGroupChannel.TIKTOK.toLowerCase()}`),
      number: this.goCampaignsToCreateDraft.length
    }));
  }

  get contentModel () {
    return this._contenModel;
  }
}

export class RetailCampaignDraftSummaryState extends DraftSummaryState {

  _contenModel = new RetailCampaignDraftSummaryStateContentModel();

  get title () {
    return this.titleFormatter(i18n.t('draftCreateModal.title', {
      l2Object: i18n.t(`l2ObjectName.${CampaignGroupChannel.RETAIL_MEDIA.toLowerCase()}`),
      number: this.goCampaignsToCreateDraft.length
    }));
  }

  get contentModel () {
    return this._contenModel;
  }
}

import { getDataProvider } from 'components/ReportTable/ReportDataProviderFactory';
import { ReportDimension, ReportGran, ReportType } from 'core/report/ReportData';
import i18n from 'i18n';
import { AbstactReportContentModel } from '../ReportContentModel';
import moment from 'moment-timezone';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import _ from 'lodash';

export class PerformanceReportModel extends AbstactReportContentModel {

  get needSpecifyAgency () {
    return false;
  }

  get defaultReportType () {
    return ReportType.PERFORMANCE;
  }

  get defaultReportDimension () {
    return ReportDimension.DAY;
  }

  get defaultReportGran () {
    return ReportGran.DAY;
  }

  get validDimensions () {
    return [ReportDimension.HOUR, ...this.state.tableDimensions];
  }

  get reportTypes () {
    if (
      this.isSysAdmin ||
      this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CREATIVES.VIDEO) ||
      this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CREATIVES.THIRDPARTY) ||
      this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CREATIVES.COMBO) ||
      this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CREATIVES.OUTDOOR)
    ) {
      return Object.values(ReportType)
        .filter(reportType => reportType !== ReportType.REBATE)
        .map(reportType => {
          return {
            label: i18n.t(`report.labels.${reportType}Type`),
            value: reportType
          };
        });
    }
    return [{
      label: i18n.t(`report.labels.${ReportType.PERFORMANCE}Type`),
      value: ReportType.PERFORMANCE
    }];
  }

  get reportGrans () {
    return Object.values(ReportGran)
      .filter(reportGran => reportGran !== ReportGran.MONTH)
      .map(reportGran => {
        return {
          label: i18n.t(`report.labels.${reportGran}`),
          value: reportGran
        };
      }
    );
  }

  download = async () => {
    const {
      tableColumnSettings,
      tableData
    } = this.state;
    const getHeader = (setting) => {
      const currency = _.get(setting, 'headerExtraData.currency');
      return _.isNil(currency) ? i18n.t(setting.text) : `${i18n.t(setting.text)} (${currency})`;
    };
    const metricsMapI18n = tableColumnSettings.reduce((acc, setting) => {
      acc[setting.dataField] = getHeader(setting);
      return acc;
    }, {});
    const metricsI18nMapTotal = tableColumnSettings.reduce((acc, setting) => {
      acc[getHeader(setting)] = setting.footer;
      return acc;
    }, {});
    const textDecorators = tableColumnSettings.reduce((acc, setting) => {
      acc[setting.dataField] = setting.formatExtraData.textDecorator;
      return acc;
    }, {});
    const metrics = _.values(metricsMapI18n);
    const excelDataList = tableData.reduce((excelData, rowData) => {
      const newRowData = {};
      Object.keys(rowData).filter(key => !!metricsMapI18n[key]).forEach(key => {
        const textDecorator = textDecorators[key];
        newRowData[metricsMapI18n[key]] = textDecorator(rowData[key]);
      });
      excelData.push(newRowData);
      return excelData;
    }, []);
    excelDataList.push(metricsI18nMapTotal);
    const XLSXModule = await import(/* webpackChunkName: "xlsx" */ 'xlsx');
    const XLSX = XLSXModule.default;
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(excelDataList, { header: metrics });
    XLSX.utils.book_append_sheet(wb, ws, 'report');
    XLSX.writeFile(wb, 'report.xlsx');
  }

  canFilterSelect = () => {
    return !this.isSysAdmin;
  }

  canFilterRemove = () => {
    return true;
  }

  initReportData = async () => {
    this.reportType = ReportType.PERFORMANCE;
    this.dataProvider = getDataProvider(this.reportType, this.queryDataWithFilter, this.onDateClick, this.onEditClick);
    this.dimension = ReportDimension.DAY;
    this.gran = ReportGran.DAY;
    this.filter = {};
    this.from = moment().subtract(7, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss');
    this.to = moment().subtract(1, 'days').endOf('day').format('YYYY-MM-DD HH:mm:ss');
    this.searchString = '';
    this.selectedTagFilter = [];
    this.timezone = this.localeMeta ? this.localeMeta.timezone : '+07:00';
    this.getReportData();
  }

  onDateClick = async (date) => {
    this.from = moment(date).startOf(this.gran).format('YYYY-MM-DD HH:mm:ss');
    this.to = moment(date).endOf(this.gran).format('YYYY-MM-DD HH:mm:ss');
    if (this.gran === ReportGran.DAY) {
      this.gran = ReportGran.HOUR;
      this.dimension = ReportDimension.HOUR;
    }
    this.updateSearchPath(false);
  }
}

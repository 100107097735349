import React from 'react';
import noData from 'assets/order/campaignList-no-data.png';
import noData2x from 'assets/order/campaignList-no-data@2x.png';
import styles from './tablePlaceholder.module.scss';

export const TablePlaceholder: React.FunctionComponent<{placeholder: string}> = ({ placeholder }) => {

  return (
    <div className={styles.noData}>
      <img src={noData} srcSet={`${noData2x} 2x`} alt='noData' />
      <div>
        {placeholder}
      </div>
    </div>
  );
};

import { CampaignGroupFormChannelModel } from './CampaignGroupFormChannelModel';
import { CampaignGroup, FBBidStrategy, FbObjective, GoCampaignGroupObjective, TiktokObjective } from 'core/campaignGroup/CampaignGroup';
import { DefaultAdvertiserManager } from 'core/advertiser/AdvertiserManager';
import { FbAdAccountStatus } from 'core/fbAdAccount/FbAdAccount';
import { AdvertiserTiktokAdAccountStatus } from 'core/tiktokAdAccount/TiktokAdAccount';
import { SelectOptions } from 'components/common/commonType';
import { BillingEvent } from 'core/fbAdSet/FbAdSet';
import { Order } from 'core/order/Order';
import _ from 'lodash';
import { validateEmpty } from 'utils/ValidateUtils';
import { DefaultFbAdSetManager } from 'core/fbAdSet/FbAdSetManager';
import { getPriceValue } from 'helper/CurrencyHelper';
import i18n from 'i18n';

export abstract class CampaignGroupCreateFormChannelModel implements CampaignGroupFormChannelModel {

  constructor (
    protected order: Order
  ) {}

  abstract get minBudget ();

  abstract init (callback: (data) => void);

  abstract validate (value: Partial<CampaignGroup>);

  abstract getCboChangeHint ();

  abstract initChannelData (valueUpdater: (fieldName: string, value: any) => void);

  abstract onCBOChange (enable: boolean, valueUpdater: (fieldName: string, value: any) => void);

  abstract showRemainBudget (values: Partial<CampaignGroup>);

  getDefaultBidStrategy = () => FBBidStrategy.LOWEST_COST_WITHOUT_CAP;
}

export class FBCampaignGroupCreateFormModel extends CampaignGroupCreateFormChannelModel {

  fbAdAccountOptions?: SelectOptions[];

  constructor (
    order: Order,
    private currencyRate: number,
    private advertiserManager = new DefaultAdvertiserManager()
  ) {
    super(order);
  }

  get minBudget () {
    const fbAdSetManager = new DefaultFbAdSetManager();
    return fbAdSetManager.getMinBudgetOfFBObject(
      _.defaultTo(this.currencyRate, 1),
      1,
      FBBidStrategy.LOWEST_COST_WITHOUT_CAP,
      BillingEvent.IMPRESSIONS,
      1,
      this.order
    );
  }

  init = async (callback: (data) => void) => {
    if (this.fbAdAccountOptions) {
      return;
    }
    const fbAdAccounts = await this.advertiserManager.getAdvertiserFBAdAccounts(this.order.advertiserId);
    this.fbAdAccountOptions = fbAdAccounts.filter(account => account.status === FbAdAccountStatus.ACTIVE)
                                .map(account => ({
                                  label: account.name,
                                  value: account.fbAdAccountId,
                                  extra: {
                                    hasAdvertiserOptedInOdax: account.hasAdvertiserOptedInOdax
                                  }
                                }));
    callback(this.fbAdAccountOptions);
  }

  initChannelData (valueUpdater: (fieldName: string, value: any) => void) {
    valueUpdater('tiktok', undefined);
    valueUpdater('budget', '');
    valueUpdater('autoOptimise', false);
    valueUpdater('fb.account_id', '');
    valueUpdater('fb.objective', FbObjective.OUTCOME_TRAFFIC);
    valueUpdater('fb.bid_strategy', undefined);
  }

  validate = (value: Partial<CampaignGroup>) => {
    return {
      fb: _.omitBy({
        account_id: validateEmpty(_.get(value, 'fb.account_id'))
      }, _.isEmpty)
    };
  }

  getCboChangeHint = () => '';

  onCBOChange (enable: boolean, valueUpdater: (fieldName: string, value: any) => void) {
    if (enable) {
      valueUpdater('budget', 0);
      valueUpdater('fb.bid_strategy', this.getDefaultBidStrategy());
      valueUpdater('fb.adset_budgets', undefined);
    } else {
      valueUpdater('budget', '');
      valueUpdater('fb.bid_strategy', undefined);
    }
  }

  showRemainBudget (values: Partial<CampaignGroup>) {
    const isCBO = _.get(values, 'autoOptimise', false);
    return isCBO;
  }
}

export class RTBCampaignGroupCreateFormModel extends CampaignGroupCreateFormChannelModel {

  get minBudget () {
    return this.order.campaignConstraint.budgetMinimum;
  }

  init = async (callback: (data) => void) => {};

  initChannelData (valueUpdater: (fieldName: string, value: any) => void) {
    valueUpdater('fb', undefined);
    valueUpdater('tiktok', undefined);
    valueUpdater('objective', GoCampaignGroupObjective.ConsiderationObjective.LINK_CLICKS);
    valueUpdater('rtb.bid_strategy', undefined);
  }

  validate = (value: Partial<CampaignGroup>) => {
    return {};
  }

  getCboChangeHint = () => '';

  onCBOChange (enable: boolean, valueUpdater: (fieldName: string, value: any) => void) {
    if (enable) {
      valueUpdater('budget', 0);
      valueUpdater('rtb.bid_strategy', this.getDefaultBidStrategy());
      valueUpdater('rtb.campaign_budgets', undefined);
    } else {
      valueUpdater('budget', '');
      valueUpdater('rtb.campaign_budgets', undefined);
      valueUpdater('rtb.bid_strategy', undefined);
    }
  }

  showRemainBudget (values: Partial<CampaignGroup>) {
    return true;
  }
}

export class TiktokCampaignGroupCreateFormModel extends CampaignGroupCreateFormChannelModel {

  tiktokAdAccountOptions?: SelectOptions[];

  constructor (
    order: Order,
    private currencyRate: number,
    private advertiserManager = new DefaultAdvertiserManager()
  ) {
    super(order);
  }

  get minBudget () {
    // TODO: validate min budget for Tiktok case with bidStrategy, billingEvent and scheduleDateCount
    let minBudget = 50 * _.defaultTo(this.currencyRate, 1);

    return getPriceValue(this.order.currency, minBudget / (1 - this.order.orderMargin - this.order.sysMargin));
  }

  init = async (callback: (data) => void) => {
    if (this.tiktokAdAccountOptions) {
      return;
    }
    const tiktokAdAccounts = await this.advertiserManager.getAdvertiserTikTokAdAccounts(this.order.advertiserId);
    this.tiktokAdAccountOptions = tiktokAdAccounts.filter(account => account.status === AdvertiserTiktokAdAccountStatus.ACTIVE)
                                    .map(account => ({
                                      label: account.label,
                                      value: account.value
                                    }));
    callback(this.tiktokAdAccountOptions);
  }

  initChannelData (valueUpdater: (fieldName: string, value: any) => void) {
    valueUpdater('fb', undefined);
    valueUpdater('budget', '');
    valueUpdater('autoOptimise', false);
    valueUpdater('tiktok.advertiser_id', '');
    valueUpdater('tiktok.objective_type', TiktokObjective.TRAFFIC);
    valueUpdater('tiktok.bid_strategy', undefined);
  }

  validate = (value: Partial<CampaignGroup>) => {
    return {
      tiktok: _.omitBy({
        advertiser_id: validateEmpty(_.get(value, 'tiktok.advertiser_id'))
      }, _.isEmpty)
    };
  }

  getCboChangeHint = () => i18n.t('campaignGroup.labels.tiktok.cbo.hint');

  onCBOChange (enable: boolean, valueUpdater: (fieldName: string, value: any) => void) {
    // TODO: not yet implement properties of TiktokAdGroup / adGroup_budgets
    if (enable) {
      valueUpdater('budget', 0);
      valueUpdater('tiktok.bid_strategy', this.getDefaultBidStrategy());
      valueUpdater('tiktok.adGroup_budgets', undefined);
    } else {
      valueUpdater('budget', '');
      valueUpdater('tiktok.bid_strategy', undefined);
    }
  }

  showRemainBudget (values: Partial<CampaignGroup>) {
    const isCBO = _.get(values, 'autoOptimise', false);
    return isCBO;
  }
}

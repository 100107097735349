import React from 'react';
import i18n from 'i18next';
import styles from './imageCreativeSizeHint.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export const ImageCreativeSizeHint: React.FunctionComponent<{closeHint: () => void}> = (props) => {

  const renderRecommendSize = (width, height) => {
    return (
      <div className={styles[`size${width}x${height}`]}>
        {`${width} x ${height}`}
      </div>
    );
  };

  // const renderOtherSize = (width, height) => {
  //   return (
  //     <div className={styles.otherSizeInstance}>
  //       <div/>
  //       {`${width} x ${height}`}
  //     </div>
  //   );
  // };

  return (
    <div className={styles.imageCreativeSizeHint}>
      <div className={styles.title}>
        <span>{i18n.t('imageCreativeSizeHint.labels.sizeInstruction')}</span>
        <FontAwesomeIcon icon={faTimes} onClick={props.closeHint}/>
      </div>
      <div className={styles.recommendSize}>
        <div className={styles.recommendSizeContent}>
          {renderRecommendSize(336, 280)}
          {renderRecommendSize(300, 250)}
          {renderRecommendSize(320, 100)}
          {renderRecommendSize(320, 50)}
          {renderRecommendSize(728, 90)}
          {renderRecommendSize(160, 600)}
          {renderRecommendSize(468, 60)}
        </div>
      </div>
      {/* <div className={styles.otherSize}>
        <div className={styles.sectionTitle}>
          {i18n.t('imageCreativeSizeHint.labels.otherSizeTitle')}
        </div>
        <div className={styles.otherSizeContent}>
          {renderOtherSize(120, 600)}
          {renderOtherSize(200, 200)}
          {renderOtherSize(125, 125)}
          {renderOtherSize(234, 60)}
          {renderOtherSize(160, 600)}
          {renderOtherSize(250, 250)}
          {renderOtherSize(180, 150)}
          {renderOtherSize(300, 1050)}
          {renderOtherSize(320, 100)}
          {renderOtherSize(640, 1000)}
          {renderOtherSize(320, 250)}
          {renderOtherSize(970, 250)}
          {renderOtherSize(336, 280)}
          {renderOtherSize(970, 325)}
          {renderOtherSize(468, 60)}
          {renderOtherSize(970, 90)}
        </div>
      </div> */}
    </div>
  );
};

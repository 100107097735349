import React, { Fragment } from 'react';
import styles from './CreativeList.module.scss';
import i18n from 'i18n';
import { Status } from 'components/Status/Status';
import { CampaignBannerMapState, CreativeType } from 'core/creative/Creative';
import { CreativePreview } from 'components/CreativePreview/CreativePreview';
import { DefaultCreativePreviewModel } from 'components/CreativePreview/CreativePreviewModel';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import { faChartArea, faCopy, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { addOnEnabled, hasFuncs } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';
import TableRowToolBar from 'containers/TableRowToolBar/TableRowToolBar';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { numberWithCommas } from 'utils/StringUtil';
import moment from 'moment';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';
import { CreativeReviews } from 'components/CreativeReview/CreativeReviews';
import { CREATIVES } from 'core/agency/AddonFeature';

const nameHeaderFormatter = (creativeListLength, selectedCreativesLength, handleOnSelectAll, column): any => {
  return (
    <Fragment>
      <div className={styles.idHeader} >
        <div className={styles.selectCheckBox}>
          <PermissionChecker permissionAware={hasFuncs(Permission.CREATIVE_WRITE).or(hasFuncs(Permission.CAMPAIGN_WRITE))}>
            <input
              type='checkbox'
              checked={creativeListLength - 1 === selectedCreativesLength && creativeListLength > 1}
              ref={el => el && (el.indeterminate = creativeListLength - 1 !== selectedCreativesLength && selectedCreativesLength > 0)}
              onChange={handleOnSelectAll}
              id={'creativeListInput0'}
            />
            <label htmlFor='creativeListInput0'/>
          </PermissionChecker>
        </div>
        {i18n.t(column.text)}
      </div>
    </Fragment>
  );
};

const creatvieIDFormatter = (cell: any, row: any, _1, formatExtraData: any): any => {
  const handleOnSelect = (e) => {
    formatExtraData.handleOnSelect(row.id);
  };
  return (
  cell ?
    <div className={styles.creativeCell}>
      <div className={styles.selectCheckBox}>
        <PermissionChecker permissionAware={hasFuncs(Permission.CREATIVE_WRITE).or(hasFuncs(Permission.CAMPAIGN_WRITE))}>
          <input
            type='checkbox'
            checked={row.selected}
            onChange={handleOnSelect}
            id={`creativeListInput${row.id}`}
          />
          <label htmlFor={`creativeListInput${row.id}`}/>
        </PermissionChecker>
      </div>
      <div className={styles.info}>
        <div className={styles.creativeName}>
          {row.name}
        </div>
        { row.rtbCreativeId &&
          <div>
            {`ID: ${row.rtbCreativeId}`}
          </div>
        }
        </div>
    </div>
  : <div/>
  );
};

const percentageFormatter = (cell: any): any => {
  return (
    <div className={styles.creativeCell}>
      {(cell * 100).toFixed(2) + '%'}
    </div>
  );
};

const numberFormatter = (cell: any): any => {
  return (
    <div className={styles.creativeCell}>
      {numberWithCommas(cell)}
    </div>
  );
};

const stateFormatter = (bindingState: any, creative: any): any => {
  const active = bindingState === CampaignBannerMapState.ENABLE;
  const waiting = bindingState === CampaignBannerMapState.WAITING_FB_UPDATE;

  let color;
  let label;
  label = i18n.t(`campaignCreativeList.status.${active ? 'enable' : 'disable'}`);
  color = active ? 'success' : 'black';
  if (waiting) {
    color = 'theme1';
    label = creative.isActiveBinding ?
    i18n.t(`campaignCreativeList.status.waitingActivate`) :
    i18n.t(`campaignCreativeList.status.waitingDeactivate`);
  }

  return (
    creative.id !== 0 ?
    <>
      <Status
        color={color}
        label={label}
      />
      {(creative.enableStartTime || creative.enableEndTime) &&
        <div className={styles.duration}>
          {creative.enableStartTime && <div>{`${i18n.t('creativeList.labels.enableStartTime')}: ${creative.enableStartTime}`}</div>}
          {creative.enableEndTime && <div>{`${i18n.t('creativeList.labels.enableEndTime')}: ${creative.enableEndTime}`}</div>}
        </div>
      }
    </> : <div/>
  );
};

const layoutFormatter = (value, creativeData: any) => {
  if (!creativeData || creativeData.id === 0) {
    return <div/>;
  }

  const layout = value[0].length !== 0 ? i18n.t(`adneon.layouts.${_.camelCase(value[0])}`) : '';
  return (
    <div className={styles.creativeCell}>
      <div className={styles.value}>
        {layout}
      </div>
    </div>
  );
};

const productSetFormatter = (cell: any, creativeData: any): any => {
  if (!creativeData || creativeData.id === 0) {
    return <div/>;
  }

  const productSet = _.get(creativeData, 'productSet', {});
  const productSetName = _.get(productSet, 'productSet', '');
  const productSetId = _.get(productSet, 'productSetId', '');

  return (
  cell ?
    <div className={styles.creativeCell}>
      <div className={styles.info}>
        <div className={styles.creativeName}>
          {productSetName}
        </div>
        <div>
          {`ID: ${productSetId}`}
        </div>
        </div>
    </div>
  : <div/>
  );
};

const deliveryFormatter = (effectiveStatus: any, row: any, _1, formatExtraData): any => {
  if (formatExtraData.isDraft) {
    const renderExtraInfo = () => i18n.t('campaignList.labels.draftStateHint');
    return row.id !== 0 ? (
      <Status
        label={i18n.t('campaignCreativeList.status.disable')}
        color={'black'}
        renderExtraInfo={renderExtraInfo}
      />
    ) : <div/>;
  }
  const hasStartTime = row.enableStartTime;
  const hasEndTime = row.enableEndTime;
  const isNowBeforeEnableStartTime = hasStartTime && moment() < moment(row.enableStartTime);
  const isNowAfterEnableEndTime = hasEndTime && moment() > moment(row.enableEndTime);
  const inTime = !isNowBeforeEnableStartTime && !isNowAfterEnableEndTime;
  const active = inTime && effectiveStatus === 'ACTIVE';
  const waiting = inTime && effectiveStatus === 'IN_PROCESS';
  let color;
  let label;
  let extraInfo;
  if (effectiveStatus === 'WITH_ISSUES') {
    label = i18n.t('campaignList.labels.withIssues');
    color = 'danger';
    extraInfo = row.issuesInfo ?
      row.issuesInfo.error_summary
      : undefined;
  } else if (isNowAfterEnableEndTime) {
    label = i18n.t(`creativeList.labels.afterEndTime`);
    color = 'light';
  } else if (isNowBeforeEnableStartTime) {
    label = i18n.t(`creativeList.labels.beforeStartTime`);
    color = 'white';
  } else if (active) {
    label = i18n.t('campaignCreativeList.status.enable');
    color = 'info';
  } else if (waiting) {
    color = 'theme1';
    label = row.isActiveBinding ?
    i18n.t(`campaignCreativeList.status.waitingActivate`) :
    i18n.t(`campaignCreativeList.status.waitingDeactivate`);
  } else {
    label = i18n.t('campaignCreativeList.status.disable');
    color = 'black';
    extraInfo = _.startCase(_.lowerCase(effectiveStatus));
  }
  return (
    row.id !== 0 ?
    <Status
      label={label}
      color={color}
      renderExtraInfo={extraInfo ? () => extraInfo : undefined}
    /> : <div/>
  );
};

const typeAndSizeFormatter = (cell: any, row: any): any => {
  if (row.id === 0) {
    return <div />;
  }
  return (
    <div className={styles.creativeCell}>
      <div className={styles.info}>
      {row.creativeType ?
        <>
          <div>
            {i18n.t('agency.addonItem.creatives.option_' + _.camelCase(CreativeType[row.creativeType]))}
          </div>
          <div>
            {row.size}
          </div>
        </> :
        <div>
          {i18n.t('creativeType.custom')}
        </div>
      }
      </div>
    </div>

  );
};

const approvalFormatter = (value, creativeData: any, _2, formatExtraData) => {
  if (creativeData.id === 0) {
    return <div />;
  }

  if (formatExtraData.isDraft) {
    return 'N/A';
  }

  if (_.isEmpty(value) || !creativeData) {
    return <div />;
  }

  return (
    <CreativeReviews
      showCreativeType={false}
      creativeData={creativeData}
      reviewCreative={formatExtraData.reviewCreative}
    />
  );
};

const resultsFormatter = (value, creativeData: any, _1, formatExtraData) => {
  if (!creativeData || creativeData.id === 0) {
    return <div/>;
  }
  const campaignGroup = formatExtraData.campaignGroup;
  const objective = _.get(campaignGroup, 'objective');
  return (
    <div>
      <div className={styles.value}>
        {value === undefined ? 0 : value}
      </div>
      <div className={styles.objective}>
        {objective}
      </div>
    </div>
  );
};

const uuFormatter = (value: any) => {
  const isSummary = value === '';
  if (isSummary) {
    return '';
  }
  return value !== undefined ? numberFormatter(value) : i18n.t('common.labels.noData');
};

const floatingEditBtnsFormatter = (cell: any, creative: any, _1, formatExtraData: any): any => {
  const goReportPage = () => formatExtraData.goReportPage(creative);
  const cannotCopyShortcut = _.isEmpty(creative.shortcutUrl);
  const copyShortcutUrl = () => {
    copy(creative.shortcutUrl);
    toast.success(i18n.t('campaignCreativeList.labels.copyImaxShortcutSuccess'));
  };
  const definedCreativeAddons: any[] = Object.values(CREATIVES);
  const needCheckAddon = definedCreativeAddons.includes(`option_${creative.creativeType}`);
  const editPermissionAware = needCheckAddon ?
    hasFuncs(Permission.CREATIVE_WRITE).and(addOnEnabled(`option_${creative.creativeType}`)) :
    hasFuncs(Permission.CREATIVE_WRITE);
  return (
    <TableRowToolBar className={styles.creativeFloatingEditArea}>
      {!creative.creativeType ?
        <IconWithTooltip
          icon={faPencilAlt}
          disabled={true}
          tooltipProps={{
            id: `campaignCreativeListReportTip-${creative.id}`,
            tooltip: i18n.t('campaignCreativeList.labels.cannotEditCustomCreativeHint')
          }}
        /> :
        <PermissionChecker permissionAware={editPermissionAware}>
          <Link
            to={formatExtraData.getEditPath(creative.rtbCreativeId)}
          >
            <IconWithTooltip
              icon={faPencilAlt}
              tooltipProps={{
                id: `campaignCreativeListReportTip-${creative.id}`,
                tooltip: i18n.t('campaignCreativeList.labels.editHint')
              }}
            />
          </Link>
        </PermissionChecker>
      }
      {formatExtraData.closingReportEnabled &&
        <IconWithTooltip
          disabled={cannotCopyShortcut}
          icon={faCopy}
          onClick={cannotCopyShortcut ? undefined : copyShortcutUrl}
          tooltipProps={{
            id: `copyShortcutUrlTip-${creative.id}`,
            tooltip: cannotCopyShortcut ?
              i18n.t('campaignCreativeList.labels.emptyShortcutUrl') :
              i18n.t('campaignCreativeList.labels.copyShortcutHint')
          }}
        />
      }
      <IconWithTooltip
        icon={faChartArea}
        onClick={goReportPage}
        tooltipProps={{
          id: `campaignCreativeListReportTip-${creative.id}`,
          tooltip: i18n.t('campaignCreativeList.labels.reportHint')
        }}
      />
    </TableRowToolBar>
  );
};

const creativePreviewFormatter = (cell: any, row: any): any => {
  if (row.id !== 0) {
    return (
      <div className={styles.creativeReview}>
        <CreativePreview model={new DefaultCreativePreviewModel(row)}/>
      </div>
    );
  } else {
    return <div/> ;
  }
};

const formatters = {
  nameHeaderFormatter,
  creatvieIDFormatter,
  percentageFormatter,
  numberFormatter,
  layoutFormatter,
  productSetFormatter,
  stateFormatter,
  deliveryFormatter,
  typeAndSizeFormatter,
  approvalFormatter,
  resultsFormatter,
  uuFormatter,
  floatingEditBtnsFormatter,
  creativePreviewFormatter
};

export default formatters;

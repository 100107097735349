import { AgencyDetail, AgencyRebate, Currency, GojekCurrency, GojekLanguage, GojekTimeZone, Language, TimeZone } from 'core';
import { AdLogoType } from 'core/adLogo/AdLogo';
import { Project } from 'core/project/Project';
import moment from 'moment';
import { toOptions } from 'utils/EnumUtil';

const defaultAgency: AgencyDetail & AgencyRebate = {
  addonFeatures: {
    creatives: {
      option_1: true,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_13: true,
      option_22: false
    },
    company: {
      rebate: false,
      allow: false,
      singleOrderBudgetConstraint: false,
      orderBudgetMaximum: false,
      useAgencyProfitOnRebateReport: false,
      showOutComeOnRebateReport: false,
      creditLimit: false,
      allowOrderSettlement: false,
      fixedExchangeRate: false,
      agencyProfit: false,
      advertiserViewSpentInReport: true,
      provideReportToWorkflow: false,
      outdoorAgency: false
    },
    campaign: {
      bid_weight_setting: false,
      deliveryAmountConstraint: false,
      bidPriceConstraint: false,
      bidPriceBaseVal: false,
      bidPriceCPCMin: false,
      bidPriceCPMMin: false,
      bidPriceCPVMin: false,
      revenueSharing: false,
      budgetDominate: false,
      roadblock: false,
      videoAdViewObjective: false,
      thirdparty_tracking: false,
      retargeting_tracker: false,
      enableOptimizedPrice: true,
      orderAgcProfitSetting: false,
      customizeAdLogo: true,
      dynamic_cpm: true
    },
    creative_studio: {
      creative_studio: false
    },
    product: {
      index: false
    },
    conversiontracking: {
      TrackingList: false
    },
    report: {
      viewableCtr: false,
      campaign_dashboard: false,
      booking_calendar: false,
      yahooAd: false,
      closingReport: false,
      campaignDashboard: false
    },
    monitor: {
      campaignBehindProgress: false,
      systemProfitMonitor: false,
      percentLabel: false
    },
    limitation: {
      country: false,
      spaceType: false,
      device: false,
      target_audience: false,
      adexchange: false,
      asiamax_space: false,
      domain: false,
      space_category: false,
      tenmax_audience: false,
      city: false,
      gender: false,
      age: false,
      carrier: false,
      enablePMPDeal: false,
      keyword: false,
      unicornkeywords: false,
      content_language: false,
      content_category: false
    },
    campaignLimitation: {
      country: true,
      spaceType: true,
      device: true,
      target_audience: true,
      adexchange: true,
      domain: true,
      city: true,
      enablePMPDeal: true
    }
  },
  addonProps: {
    bidPriceBaseVal: 2,
    bidPriceCPCMin: 4,
    bidPriceCPMMin: 20,
    bidPriceCPVMin: 0.3,
    bidPriceConstraint: 'AUTO',
    maxOrderProfit: 0,
    outdoorAdImpresMultiply: 1,
    orderBudgetMaximum: 0,
    creditLimit: 0,
    fixedExchangeRate: 0,
    outdoorMachineCount: 0,
    sysProfitMonitorPercent: 0
  },
  bidPriceBase: {
    DISPLAY: 1,
    COMBO: 17,
    VIDEO: 90,
    THIRD_PARTY: 112.5,
    THIRD_PARTY_BOTTOM: 112.5,
    THIRD_PARTY_RECTANGLE: 112.5
  },
  logoUrl: '',
  agcDataPercent: 0,
  agcPercent: 0,
  agencyId: 0,
  comComment: '',
  companyName: '',
  currency: Currency.IDR,
  defaultCountry: [{ label: 'Indonesia', value: 'IDN' }],
  defaultCountryOp: 'inc',
  defaultSpace: [],
  defaultSpaceOp: 'inc',
  interstitial: 0,
  oem: false,
  language: Language.ENUS,
  limitAdx: [{
    label: 'DoubleClick',
    value: 'DoubleClick'
  }],
  limitAdxOp: 'inc',
  limitPublisher: [],
  limitPublisherCountry: [{ label: 'Indonesia', value: 'IDN' }],
  noLimitSelfBuyStatus: true,
  priority: 1,
  segmentCountry: [],
  segmentLabel: [],
  selfBuy: false,
  sysDataPercent: 0,
  sysPercent: 0,
  sysPercentIn: 0,
  sysPercentOut: 0,
  targetBudgetMinimum: 0,
  taxPercent: 0,
  timeZone: TimeZone.HOCHIMINH,
  adLogo: {
    type: AdLogoType.NULL
  },
  logoData: {},
  currentRate: 0,
  futureRate: 0,
  effectDate: moment().startOf('day').format('YYYY-MM-DD')
};

const isRetail = process.env.REACT_APP_PROJECT === Project.RETAIL;

if (isRetail) {
  defaultAgency.currency = Currency.NTD;
  defaultAgency.defaultCountry = [];
  defaultAgency.language = Language.ZHTW;
  defaultAgency.limitPublisherCountry = [];
  defaultAgency.timeZone = TimeZone.TAIPEI;
} else {
  defaultAgency.currency = Currency.IDR;
  defaultAgency.defaultCountry = [{ label: 'Indonesia', value: 'IDN' }];
  defaultAgency.language = Language.ENUS;
  defaultAgency.limitPublisherCountry = [{ label: 'Indonesia', value: 'IDN' }];
  defaultAgency.timeZone = TimeZone.HOCHIMINH;
}

const data = {
  defaultAgency,
  timeZoneOptions: isRetail ? toOptions(TimeZone) : toOptions(GojekTimeZone),
  currencyOptions: isRetail ? toOptions(Currency) : toOptions(GojekCurrency),
  languageOptions: isRetail ? toOptions(Language) : toOptions(GojekLanguage)
};

export default data;

import React, { PureComponent, MouseEventHandler } from 'react';
import i18n from 'i18n';
import _ from 'lodash';

import Button from '../Button/Button';
import styles from './pageButtonGroup.module.scss';

type PageButtonGroupProps = {
  primaryTitle?: string;
  onPrimaryClick?: MouseEventHandler;
  secondaryTitle?: string;
  onSecondaryClick?: MouseEventHandler;
  className?: string;
};

export default class PageButtonGroup extends PureComponent<
  PageButtonGroupProps
> {
  static defaultProps = {
    primaryTitle: i18n.t('common.buttons.submit'),
    onPrimaryClick: _.noop,
    secondaryTitle: i18n.t('common.buttons.cancel'),
    onSecondaryClick: _.noop
  };
  render () {
    const {
      primaryTitle,
      onPrimaryClick,
      secondaryTitle,
      onSecondaryClick,
      className: parentClassName
    } = this.props;
    return (
      <div className={parentClassName}>
        <Button primary onClick={onPrimaryClick} className={styles.primary}>
          {primaryTitle}
        </Button>
        <Button secondary onClick={onSecondaryClick}>
          {secondaryTitle}
        </Button>
      </div>
    );
  }
}

import { Actor, AuthenticationManager, RoleNames } from 'core';
import * as parser from 'utils/ActorRoleParser';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { UpdateEventListener, FireableUpdateEventListener } from 'utils/UpdateEventListener';
import { faUniversity, faUserSecret, faUserPlus, faUser, faUserMd, faChartBar } from '@fortawesome/free-solid-svg-icons';
import { faBlackTie } from '@fortawesome/free-brands-svg-icons';
import { MainPageModel } from 'pages/MainPage/MainPageModel';
import _ from 'lodash';

interface DisplayableInfo {

  readonly type: string;
  readonly role: string;
  readonly color: string;
  readonly company: string;
  readonly isGuest: boolean;
  readonly icon: IconProp | null;
}

export interface ActorMenuItemModel extends DisplayableInfo {

  readonly key: number;
  readonly actor: Actor | null;

  itemSelected (): void;
}

export type ActorMenuItemProps = {

  readonly model: ActorMenuItemModel;
};

export type ActorMenuState = {

  menuShown: boolean;
};

export interface ActorMenuModel extends DisplayableInfo {

  readonly state: ActorMenuState;
  readonly items: Array<ActorMenuItemModel>;
  readonly event: UpdateEventListener<ActorMenuModel>;

  toggle (): void;
}

export type ActorMenuProps = {

  readonly model: ActorMenuModel;
};

class AbstractDisplayInfo implements DisplayableInfo {
  actor: Actor;

  constructor (actor: Actor) {
    this.actor = actor;
  }

  get icon (): IconProp | null {
    switch (this.actor.roleName) {
      case RoleNames.agencyAdmin:
        return faUserSecret;
      case RoleNames.agencyManager:
        return faUserPlus;
      case RoleNames.agencySales:
        return faUser;
      case RoleNames.adsAdmin:
        return faUserMd;
      case RoleNames.adsSales:
        return faBlackTie;
      case RoleNames.adsReport:
        return faChartBar;
    }
    return faUniversity;
  }

  get color (): string {
    return this.actor.roleName.toLowerCase().replace('_', '-');
  }

  get type (): string {
    return parser.typeOf(this.actor);
  }

  get role (): string {
    return parser.roleOf(this.actor);
  }

  get isGuest (): boolean {
    return this.actor.roleName === RoleNames.guest;
  }

  get company (): string {
    return this.actor.companyName ? this.actor.companyName : _.startCase(_.capitalize(this.actor.roleName));
  }
}

export class DefaultActorMenuItemModel extends AbstractDisplayInfo implements ActorMenuItemModel {
  mainPageModel: MainPageModel;

  constructor (actor: Actor, mainPageModel: MainPageModel) {
    super(actor);
    this.mainPageModel = mainPageModel;
  }

  get key (): number {
    return this.actor.id;
  }

  async itemSelected () {
    await this.mainPageModel.switchActor(this.actor);
  }
}

export class DefaultActorMenuModel extends AbstractDisplayInfo implements ActorMenuModel {
  shown: boolean;
  manager: AuthenticationManager;
  modelEvent: FireableUpdateEventListener<ActorMenuModel>;
  mainPageModel: MainPageModel;

  constructor (current: Actor, manager: AuthenticationManager, mainPageModel: MainPageModel) {
    super(current);
    this.shown = false;
    this.manager = manager;
    this.modelEvent = new FireableUpdateEventListener();
    this.mainPageModel = mainPageModel;
    this.manager.event.add((manager) => {
      if (manager.actor) {
        this.actorChanged(manager.actor);
      }
    });
  }

  get items (): Array<ActorMenuItemModel> {
    const actors = this.manager.account !== null ? this.manager.account.actors : [];
    return actors.filter((actor) => actor.id !== this.actor.id).map((actor) => new DefaultActorMenuItemModel(actor, this.mainPageModel));
  }

  get event (): UpdateEventListener<ActorMenuModel> {
    return this.modelEvent;
  }

  get state (): ActorMenuState {
    return {
      menuShown: this.shown
    };
  }

  actorChanged (current: Actor) {
    this.shown = false;
    this.actor = current;
    this.modelEvent.fireEvent(this);
  }

  toggle () {
    this.shown = !this.shown;
    this.modelEvent.fireEvent(this);
  }
}

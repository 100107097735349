
export enum AdLogoType {
  DEFAULT = 'DEFAULT',
  CUSTOM = 'CUSTOM',
  NULL = 'NULL'
}

export type AdLogo = {
  image?: AdLogoImageData,
  link?: string,
  type: AdLogoType,
  imgUrl?: string
};

export type AdLogoImageData = {
  url?: string;
  file?: any;
  imgBase64?: string;
};

import DefaultCreativeManager from 'core/creative/CreativeManager';
import { useCallAPI } from 'hooks/useCallAPI';
import { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';

export const useTiktokVideoPreview = (
  advertiserId: number | string,
  typeProperties: any
): {
  previewData: { [key: string]: string | { [key: string]: string | undefined } | undefined }
} => {
  const tiktokPreviewTypes = useMemo(() => [
    'VALUE_TIKTOK'
  ], []);
  const initPreviewData = tiktokPreviewTypes.reduce<{ [key: string]: string | { [key: string]: string | undefined } | undefined }>((acc, type) => {
    acc[type] = undefined;
    return acc;
  }, {});

  const [previewData, setPreviewData] = useState(initPreviewData);
  const { callAPIs } = useCallAPI();

  useEffect(() => {
    const formData = {
      advertiserId: advertiserId,
      videoId: _.get(typeProperties, 'tiktokVideoId'),
      adText: _.get(typeProperties, 'shortMessage'),
      callToAction: _.get(typeProperties, 'callToAction')
    };
    const creativeManager = new DefaultCreativeManager();

    callAPIs([creativeManager.getTiktokCreativePreview.bind(creativeManager, formData)], (previewIframe) => {
      let result = {};
      // console.log('previewLink', previewIframe.preview_link);
      tiktokPreviewTypes.forEach(type => {
        // result[type] = previewIframe.iframe;
        result[type] = {
          iframe: previewIframe.iframe,
          previewLink: previewIframe.preview_link
        };
      });
      setPreviewData(result);
    });
  }, [advertiserId, typeProperties, tiktokPreviewTypes, callAPIs]);

  return {
    previewData
  };
};

import { isProject } from 'core/permission/PermissionDSL';
import { Project } from 'core/project/Project';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const isGoGAN = isProject(Project.GOGAN).visible({});

export interface UserDefaults {
  language: string;
}

export class CookieUserDefaults implements UserDefaults {
  get language (): string {
    if (isGoGAN) {
      return 'en';
    }
    return cookies.get('language') || navigator.language || 'zh-TW';
  }

  set language (language: string) {
    cookies.set('language', language, { path: '/' });
  }
}

const defaults = new CookieUserDefaults();

export default defaults;

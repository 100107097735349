import { Field, FieldProps } from 'formik';
import React, { ReactElement } from 'react';
import { Col, Form, Row, InputGroup as ReactInputGroup, FormControl, FormLabel } from 'react-bootstrap';
import {
  FormikInput,
  FormikSelect,
  FormikSwitch,
  FormikLabel,
  FormikTags
} from '.';
import { SelectOptions } from '../commonType';
import styles from './formikField.module.scss';
import _ from 'lodash';
import cx from 'classnames/bind';
import FormikDatePicker from './FormikDatePicker';
import FormikDateRangePicker from './FormikDateRangePicker';
import FileFormInput from 'components/common/formik/FileFormInput';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import ImageFormInput from 'components/common/formik/ImageFormInput';
import i18n from 'i18n';
import FormikVideoInput from './FormikVideoInput';
import { FormikAudioInput } from './FormikAudioInput';
import { ImageMultipleInput } from '../Image/ImageMultipleInput';
import { default as ReactTags } from 'components/common/Tags/Tags';
import { getFieldErrors } from 'utils/FormikUtils';
import Tips from '../Tips/Tips';

const classNames = cx.bind(styles);

interface FormikFieldBasicProps {
  direction?: any;
  labelColSm?: number;
  inputColSm?: number;
  label?: string;
  hoverHint?: string;
  hint?: string | ReactElement | ReactElement[];
  name: string;
  disabled?: boolean;
  placeholder?: string;
  onChange?: any;
  className?: string;
  onBlur?: any;
  defaultValue?: any;
  fieldContentWidth?: number | string;
  required?: boolean;
  isFlexibleContent?: boolean;
  postText?: string;
  validate?: Function;
  permanentHint?: any;
}

interface LabelProps extends FormikFieldBasicProps {
  options?: SelectOptions[];
  formatter?: (value: string) => string;
}

interface SwitchProps extends FormikFieldBasicProps {}

interface InputProps extends FormikFieldBasicProps {
  type?: string;
  step?: number;
  as?: string;
  min?: number;
  max?: number;
}

interface SelectProps extends FormikFieldBasicProps {
  isLoading?: boolean;
  isMulti?: boolean;
  simpleValue?: boolean;
  options: SelectOptions[];
  closeMenuOnSelect?: boolean;
  maxMenuHeight?: number;
  labelKey?: string;
  valueKey?: string;
  isClearable?: boolean;
  optionComponent?: any;
  singleValue?: any;
  multiValueLabel?: any;
  componentWidthFitParent?: boolean;
}

interface CustomProps extends FormikFieldBasicProps {
  component?: any;
  render?: Function;
  asField?: boolean;
}

interface InputGroupProps extends InputProps {
  prefix?: string | any;
  postfix?: string | any;
  prefixBorder?: boolean;
  postfixBorder?: boolean;
}

interface DatePickerProps extends FormikFieldBasicProps {
  timeInterval?: number;
  maxDate?: string;
  minDate?: string;
  showTimePicker?: boolean;
}

interface DateRangePickerProps extends DatePickerProps {
  startDateFormikName: string;
  endDateFormikName: string;
  format: string;
  startDatePickerDisabled?: boolean;
}

interface TagsProps extends FormikFieldBasicProps {
}

interface RadioProps extends FormikFieldBasicProps {
  options: SelectOptions[];
}

interface CheckboxProps extends FormikFieldBasicProps {
  defaultChecked?: any;
  checked?: boolean;
  postfix?: any;
  checkboxLabel?: string;
  id?: string;
}

interface FileInputProps extends FormikFieldBasicProps {
  type: 'video' | 'image' | 'other' | 'audio';
  icon?: any;
  hints?: string[];
  validTypes?: string[];
  maxWidth?: number;
  maxHeight?: number;
}

interface FieldType {
  Label: React.FC<LabelProps>;
  Input: React.FC<InputProps>;
  Select: React.FC<SelectProps>;
  TextArea: React.FC<InputProps>;
  Switch: React.FC<SwitchProps>;
  InputGroup: React.FC<InputGroupProps>;
  Custom: React.FC<CustomProps & any>;
  DatePicker: React.FC<DatePickerProps>;
  DateRangePicker: React.FC<DateRangePickerProps>;
  Tags: React.FC<TagsProps>;
  FileInput: React.FC<FileInputProps>;
  Checkbox: React.FC<CheckboxProps>;
  UrlInput: React.FC<InputProps>;
  MultiImageInput: React.FC<FileInputProps>;
  Radio: React.FC<RadioProps>;
}

export const withFormikField = (WrappedComponent) => (props: any) => (
  <Field
    name={props.name}
    type={props.type}
    value={props.value}
    validate={props.validate}
  >
    {(fieldProps) => (
      <WrappedComponent {...props} {...fieldProps} value={_.get(fieldProps, 'field.value', '')}/>
    )}
  </Field>
);

export const withFieldWrapper = (WrapperComponent) => ({
  className,
  labelColSm = 3,
  inputColSm = 9,
  label,
  hoverHint,
  hint,
  direction = Row,
  fieldContentWidth = 424,
  required,
  permanentHint,
  isFlexibleContent = false,
  postText,
  ...props
}: any) => {
  const fieldContentClass = classNames(isFlexibleContent ? styles.flexibleFieldContent : styles.fieldContent, className);
  const fieldComponentClass = isFlexibleContent ? styles.fieldComponent : styles.flexibleFieldComponent;
  const errors = getFieldErrors(props.form, props.name);
  const hasError = !!errors;
  const fieldContent = (
    <div className={fieldContentClass}>
      <div className={fieldComponentClass} style={{ width: fieldContentWidth }}>
        <WrapperComponent {...props} />
      </div>
      {postText && <span className={styles.postText}>{postText}</span>}
      {hoverHint && <span className={styles.hoverHint}>{hoverHint}</span>}
      {hint && !hasError &&
        <span className={styles.hint}>
          {hint}
        </span>
      }
      {permanentHint && <span className={styles.permanentHint}>{permanentHint}</span>}
    </div>
  );

  const labelClassName = classNames({
    flexible: isFlexibleContent,
    required
  });

  const labelContent = label !== undefined ? (
    <>
      {direction === Row ?
        <Col sm={isFlexibleContent ? undefined : labelColSm}>
          <Form.Label column className={labelClassName}>{label}</Form.Label>
        </Col> :
        <Row className={styles.fullWidth}>
          <Form.Label className={labelClassName}>{label}</Form.Label>
        </Row>
      }
    </>
  ) : null;

  return (
    <Form.Group as={direction}>
      {
        direction === Row ?
          <>
            {labelContent}
            <Col sm={isFlexibleContent ? undefined : inputColSm}>{fieldContent}</Col>
          </> :
          <>
            {labelContent}
            <Row className={styles.fullWidth}>{fieldContent}</Row>
          </>
      }
    </Form.Group>
  );
};

const getFieldComponent = (component) => withFormikField(withFieldWrapper(component));

const Label = getFieldComponent(FormikLabel);
const Input = getFieldComponent(FormikInput);

const SelectContent = getFieldComponent(FormikSelect);
const Select = (props: SelectProps) => (
  <SelectContent
    {...props}
    isDisabled={props.disabled}
  />
);

const TextArea = (props: InputProps) => (
  <Input
    {...props}
    as={'textarea'}
  />
);

const SwitchContent = getFieldComponent(FormikSwitch);
const Switch = (props: SwitchProps) => (
  <SwitchContent
    {...props}
    fieldContentWidth='auto'
    isDisabled={props.disabled}
  />
);

const WithFormikFieldCustom = getFieldComponent(({ component, render, ...props }) => {
  const Content = component;
  return Content ? <Content {...props} /> : render(props);
});

const WithFieldStyleCustom = withFieldWrapper(({ component, render, ...props }) => {
  const Content = component;
  return Content ? <Content {...props} /> : render(props);
});

const Custom = ({ asField = true, ...others }: CustomProps & any) => {
  let Content = asField ? WithFormikFieldCustom : WithFieldStyleCustom;
  return <Content {...others}/>;
};

const FormikInputGroup = ({ prefix, postfix, prefixBorder = true, postfixBorder = true, className: customClassName, ...props }: any) => {
  const handleChange = event => {
    const targetValue = _.get(event, 'target.value', event);
    props.form.setFieldTouched(props.field.name);
    props.onChange && props.onChange(targetValue);
    props.field.onChange && props.field.onChange(event);
  };
  const errors = getFieldErrors(props.form, props.name);
  const hasError = !!errors;
  const className = classNames({
    error: hasError,
    disabled: props.disabled
  });
  const prefixClassName = classNames({
    withoutBorder: !prefixBorder,
    error: hasError,
    disabled: props.disabled
  });
  const postfixClassName = classNames({
    withoutBorder: !postfixBorder,
    error: hasError,
    disabled: props.disabled
  });
  return (
    <>
      <ReactInputGroup className={customClassName}>
        {prefix &&
          <ReactInputGroup.Prepend>
            <ReactInputGroup.Text className={prefixClassName}>
              {prefix}
            </ReactInputGroup.Text>
          </ReactInputGroup.Prepend>
        }
        {
          props.as === 'label' ?
            <FormLabel {...props.field}>{props.field.value}</FormLabel> :
            <FormControl
              {...props.field}
              value={props.field.value !== undefined ? props.field.value : ''}
              disabled={props.disabled}
              className={className}
              onChange={handleChange}
              type={props.type}
              step={props.step}
              min={props.min}
              max={props.max}
              as={props.as}
            />
        }
        {postfix &&
          <ReactInputGroup.Append>
            <ReactInputGroup.Text className={postfixClassName}>
              {postfix}
            </ReactInputGroup.Text>
          </ReactInputGroup.Append>
        }
      </ReactInputGroup>
      {errors && <Tips>{errors}</Tips>}
    </>
  );
};

const InputGroup = getFieldComponent(FormikInputGroup);

const UrlInputContent = getFieldComponent((props) => {
  const goTo = () => {
    window.open(_.get(props.form.values, props.field.name), '_blank');
  };
  const inputGroupClassName = classNames('urlInputGroup', props.className);
  return (
    <FormikInputGroup
      {...props}
      className={inputGroupClassName}
      postfix={
        <div onClick={goTo}>
          <IconWithTooltip
            icon={faExternalLinkAlt}
            tooltipProps={{
              id: `creativeFormAdLinkBtn`,
              tooltip: i18n.t('creativeSetupFlow.labels.openLinkHint')
            }}
          />
        </div>
      }
    />
  );
});

const UrlInput = (inputGroupProps: InputGroupProps) => (
  <UrlInputContent
    {...inputGroupProps}
    fieldContentWidth='auto'
  />
);

const DatePicker = getFieldComponent(FormikDatePicker);
const DateRangePicker = getFieldComponent(FormikDateRangePicker);
const Tags = getFieldComponent(FormikTags);

const ControlledCheckbox = withFieldWrapper((props: CheckboxProps) => {
  const onCheckboxChanged = e => {
    const checked = e.target.checked;
    props.onChange && props.onChange(checked);
  };
  const id = props.id ? props.id : `${props.name}-checkbox`;
  return (
    <div className={styles.checkbox}>
      <input
        type='checkbox'
        id={id}
        checked={props.checked}
        defaultChecked={props.defaultChecked}
        disabled={props.disabled}
        onChange={onCheckboxChanged}
      />
      <label htmlFor={id}>{props.checkboxLabel}</label>
      {props.postfix}
    </div>
  );
});

const FormikCheckbox = withFormikField((props: CheckboxProps & FieldProps) => {
  const onCheckboxChanged = checked => {
    props.form.setFieldValue(props.field.name, checked);
    props.form.setFieldTouched(props.field.name, true);
    props.onChange && props.onChange(checked);
  };
  return (
    <ControlledCheckbox
      id={props.id}
      label={props.label}
      checkboxLabel={props.checkboxLabel}
      name={props.name}
      checked={props.field.value ? props.field.value : false}
      disabled={props.disabled}
      defaultChecked={props.defaultChecked}
      postfix={props.postfix}
      onChange={onCheckboxChanged}
    />
  );
});

const Checkbox = (props: CheckboxProps) => {
  const isControlled = props.checked !== undefined;
  return (
    <>
      {
        isControlled ?
          <ControlledCheckbox {...props}/> :
          <FormikCheckbox {...props}/>
      }
    </>
  );
};
const FileInputContent = getFieldComponent((props) => {
  let hint;
  let errorBorderHint = true;
  const file = _.get(props.field.value, 'file');
  const url = _.get(props.field.value, 'url');
  const errors = getFieldErrors(props.form, props.field.name);
  const hasError = !!errors;
  if (!hasError) {
    const hints = props.hints;
    hint = hints ? hints.map((hint, index) => {
      if (index === hints.length - 1) {
        return hint;
      }
      return <span key={index}>{hint}<br/></span>;
    }) : '';
    if (file || url) {
      hint = i18n.t('creativeSetupFlow.labels.successHint');
    }
    errorBorderHint = false;
  }
  let FileInput;
  const className = classNames(props.fileClassName, {
    fitContent: (file || url) && !props.icon,
    removeBorder: props.type === 'video'
  });
  if (props.type === 'image') {
    FileInput = ImageFormInput;
  } else if (props.type === 'video') {
    FileInput = FormikVideoInput;
  } else if (props.type === 'audio') {
    FileInput = FormikAudioInput;
  } else {
    FileInput = FileFormInput;
  }
  return (
    <>
      <FileInput
        {...props}
        className={className}
        errorBorderHint={errorBorderHint}
        url={url}
        file={file}
        icon={props.icon}
        hocContent={hint}
        maxWidth={props.maxWidth}
        maxHeight={props.maxHeight}
        validTypes={props.validTypes}
      />
    </>
  );
});

const FileInput = (props: FileInputProps) => (
  <FileInputContent
    {..._.omit(props, ['className'])}
    fileClassName={props.className}
    fieldContentWidth='auto'
  />
);

const MultiImageInputContent = getFieldComponent((props) => {
  const renderImageFiles = () => {
    const imagesObject = _.get(props.form.values, 'medias.images');
    if (!imagesObject) {
      return <div/>;
    }
    const images: {
      file: File
    }[] = Object.values(imagesObject);
    const onImageChange = (imageOptions) => {
      props.form.setFieldValue('medias.images', imageOptions.length === 0 ? undefined : imageOptions.map(option => images.find(image => image.file.name === option.value)));
    };
    const value = images.map(image => ({ label: image.file.name, value: image.file.name }));
    return (
      <ReactTags
        value={value}
        disableInput
        onChange={onImageChange}
      />
    );
  };

  let hint;
  let errorBorderHint = true;
  const errors = getFieldErrors(props.form, props.field.name);
  const hasError = !!errors;
  if (!hasError) {
    const hints = props.hints;
    hint = hints ? hints.map((hint, index) => {
      if (index === hints.length - 1) {
        return hint;
      }
      return <span key={index}>{hint}<br/></span>;
    }) : '';
    if (props.field.value) {
      hint = i18n.t('creativeSetupFlow.labels.successHint');
    }
    errorBorderHint = false;
  }

  const files = props.field.value ? Object.values(props.field.value) : undefined;
  const inputClass = classNames('imageInput', {
    emptyImageInput: !files,
    previewMultipleInput: files && files?.length > 1,
    showErrorBorder: errorBorderHint,
    showSolidBorder: !!files
  });
  const onImagesChange = (newValue) => {
    props.form.setFieldValue(props.field.name, newValue);
    props.form.setFieldTouched(props.field.name);
  };
  return (
    <div className={styles.multiImageInput}>
      <div className={styles.imageFormInputWithList}>
        <div className={inputClass}>
          <ImageMultipleInput
            name={props.name}
            disabled={props.disabled}
            className={props.className}
            files={files}
            validTypes={props.validTypes}
            maxWidth={props.maxWidth}
            maxHeight={props.maxWidth}
            onChange={onImagesChange}
          />
        </div>
        {files &&
          <div className={styles.selectImageList}>
            {renderImageFiles()}
          </div>
        }
      </div>
      <span className={styles.imageFormInputHint}>{hint}</span>
      {errors && <Tips>{errors}</Tips>}
    </div>
  );
});

const MultiImageInput = (props: FileInputProps) => (
  <MultiImageInputContent
    {..._.omit(props, ['className'])}
    fieldContentWidth='auto'
  />
);

const Radio = getFieldComponent((props) => {

  const onRadioChange = (e) => {
    props.form.setFieldValue(props.field.name, e.target.value);
  };

  const renderOptions = () => {
    return props.options.map(option => (
      <Form.Check
        key={option.value}
        type={'radio'}
        label={option.label}
        value={option.value}
        name={props.name}
        id={`radio-${option.value}`}
        onChange={onRadioChange}
        checked={props.field.value.toString() === option.value.toString()}
        disabled={props.disabled}
      />
    ));
  };

  return (
    <div className={styles.radioColumn}>
      {renderOptions()}
    </div>
  );
});

export const FormikField: FieldType = {
  Label,
  Input,
  Select,
  TextArea,
  Switch,
  Custom,
  InputGroup,
  DatePicker,
  DateRangePicker,
  Tags,
  FileInput,
  Checkbox,
  UrlInput,
  MultiImageInput,
  Radio
};

export interface FormField {
  component: (props: any) => JSX.Element;
  props: any;
  hide: boolean;
}

export interface FormSection {
  title: string;
  basicFields: FormField[];
  advancedFields?: FormField[];
  hintModal?: any;
}

export class FormConfig {

  sections: FormSection[];

  static Builder = class {

    sections: any[] = [];

    addSection (section: FormSection) {
      this.sections.push(section);
      return this;
    }

    build () {
      return new FormConfig(this.sections);
    }
  };

  static SectionBuilder = class {

    title: string;
    basicFields: FormField[];
    advancedFields?: FormField[];
    hintModal?: any;

    constructor (title: string, fields: FormField[]) {
      this.title = title;
      this.basicFields = fields;
    }

    addAdvancedFields (fields: FormField[]) {
      this.advancedFields = fields;
      return this;
    }

    withHintModal (hintModal: any) {
      this.hintModal = hintModal;
      return this;
    }

    build (): FormSection {
      return {
        title: this.title,
        hintModal: this.hintModal,
        basicFields: this.basicFields,
        advancedFields: this.advancedFields
      };
    }
  };

  static FieldsBuilder = class {

    fields: FormField[];
    constructor (basicFields?: FormField[]) {
      this.fields = basicFields ? basicFields : [];
    }

    addField = (component: (props: any) => JSX.Element, props: any, hide: boolean) => {
      this.fields.push({
        component,
        props,
        hide
      });
    }

    addCheckbox (props: CheckboxProps, hide: boolean = false) {
      this.addField(Checkbox, props, hide);
      return this;
    }

    addSelect (props: SelectProps, hide: boolean = false) {
      this.addField(Select, props, hide);
      return this;
    }

    addInput (props: InputProps, hide: boolean = false) {
      this.addField(Input, props, hide);
      return this;
    }

    addTextarea (props: InputProps, hide: boolean = false) {
      this.addField(TextArea, props, hide);
      return this;
    }

    addLabel (props: LabelProps, hide: boolean = false) {
      this.addField(Label, props, hide);
      return this;
    }

    addSwitch (props: SwitchProps, hide: boolean = false) {
      this.addField(Switch, props, hide);
      return this;
    }

    addCustom (props: CustomProps, hide: boolean = false) {
      this.addField(Custom, props, hide);
      return this;
    }

    addInputGroup (props: InputGroupProps, hide: boolean = false) {
      this.addField(InputGroup, props, hide);
      return this;
    }

    addDatePicker (props: DatePickerProps, hide: boolean = false) {
      this.addField(DatePicker, props, hide);
      return this;
    }

    addDateRangePicker (props: DateRangePickerProps, hide: boolean = false) {
      this.addField(DateRangePicker, props, hide);
      return this;
    }

    addTags (props: TagsProps, hide: boolean = false) {
      this.addField(Tags, props, hide);
      return this;
    }

    addFileInput (props: FileInputProps, hide: boolean = false) {
      this.addField(FileInput, props, hide);
      return this;
    }

    addUrlInput (props: InputProps, hide: boolean = false) {
      this.addField(UrlInput, props, hide);
      return this;
    }

    addMultiImageInput (props: FileInputProps, hide: boolean = false) {
      this.addField(MultiImageInput, props, hide);
      return this;
    }

    addRadio (props: RadioProps, hide: boolean = false) {
      this.addField(Radio, props, hide);
      return this;
    }

    build (): FormField[] {
      return this.fields;
    }
  };

  constructor (sections) {
    this.sections = sections;
  }
}

import { SelectOptions } from 'components/common/commonType';
import { UpdateEventListener, FireableUpdateEventListener } from 'utils/UpdateEventListener';
import { AgencyManager, DefaultAgencyManager } from 'core';
import { AdvertiserManager, DefaultAdvertiserManager } from 'core/advertiser/AdvertiserManager';
import _ from 'lodash';
import { ShareSegment } from 'core/advertiser/Advertiser';

export type AdvertiserSegmentSourceProps = {
  model: AdvertiserSegmentSourceModel;
};

export type AdvertiserSegmentSourceState = {
  readonly loading: boolean;
  readonly agencies: SelectOptions[];
  readonly advertisersMap: { [agencyId: number]: SelectOptions[] };
};

export interface FormikSubmitModel {
  readonly submitType: 'FORMIK_SUBMIT' | 'PRE_SUBMIT' | 'POST_SUBMIT';
}

export interface AdvertiserSegmentSourceModel {
  readonly event: UpdateEventListener<AdvertiserSegmentSourceModel>;
  readonly formikEvent: UpdateEventListener<FormikSubmitModel>;
  readonly state: AdvertiserSegmentSourceState;
  readonly formData: {[key: string]: ShareSegment};
  submit: (formData) => Promise<void>;
  init: () => Promise<void>;
  fetchAdvertisers: (agencyId: number) => Promise<void>;
  onUnmount: (handler?: number, formikHandler?: number) => void;
  submitAndValide (): void;
}

export class DefaultAdvertiserSegmentSourceModel implements AdvertiserSegmentSourceModel {

  loading: boolean;
  event: FireableUpdateEventListener<AdvertiserSegmentSourceModel>;
  formikEvent: FireableUpdateEventListener<FormikSubmitModel>;
  agencies: SelectOptions[];
  advertisersMap: { [agencyId: number]: SelectOptions[] };
  formData: {[key: string]: ShareSegment};

  constructor (
    private advertiserId: number,
    private advertiserSegmentSource: ShareSegment[],
    private agencyManager: AgencyManager = new DefaultAgencyManager(),
    private advertiserManager: AdvertiserManager = new DefaultAdvertiserManager()
  ) {
    this.agencies = [];
    this.advertisersMap = {};
    this.loading = false;
    this.event = new FireableUpdateEventListener<AdvertiserSegmentSourceModel>();
    this.formikEvent = new FireableUpdateEventListener<FormikSubmitModel>();
    this.formData = this.advertiserSegmentSource.reduce((
      acc,
      source,
      index
    ) => {
      acc[index.toString()] = source;
      return acc;
    }, {});
  }

  async init () {
    this.updateState(true);
    try {
      this.agencies = await this.agencyManager.getAgenciesOptions();
      await Promise.all(
        this.advertiserSegmentSource.map(async segmentSource => {
          try {
            const advertisers = await this.advertiserManager.getAdvertiserOptions(segmentSource.agencyId);
            this.advertisersMap[segmentSource.agencyId] = advertisers;
          } catch (e) {}
        })
      );
    } catch (e) {}
    this.updateState(false);
  }

  get state () {
    return {
      loading: this.loading,
      agencies: this.agencies,
      advertisersMap: this.advertisersMap
    };
  }

  async submit (formData) {
    const segmentSourceIds = _.uniq(_.compact(Object.keys(formData).map(key => formData[key].advertiserId)));
    const originSegmentSourceIds = _.uniq(_.compact(this.advertiserSegmentSource.map(segmentSource => segmentSource.advertiserId)));
    if (_.xor(segmentSourceIds, originSegmentSourceIds).length === 0) {
      this.formikEvent.fireEvent({ submitType: 'POST_SUBMIT' });
      return;
    }
    this.updateState(true);
    try {
      await this.advertiserManager.updateAdvertiserShareSegment(this.advertiserId, segmentSourceIds);
      this.formikEvent.fireEvent({ submitType: 'POST_SUBMIT' });
    } catch (e) {}
    this.updateState(false);
  }

  submitAndValide = () => {
    this.formikEvent.fireEvent({ submitType: 'FORMIK_SUBMIT' });
  }

  async fetchAdvertisers (agencyId: number) {
    if (this.advertisersMap[agencyId]) {
      return;
    }
    this.updateState(true);
    try {
      const advertisers = await this.advertiserManager.getAdvertiserOptions(agencyId);
      this.advertisersMap[agencyId] = advertisers;
    } catch (e) {}
    this.updateState(false);
  }

  onUnmount (handler?: number, formikHandler?: number) {
    handler && this.event.remove(handler);
    formikHandler && this.event.remove(formikHandler);
  }

  updateState (loading: boolean = false) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}

import { FormContentModel } from './FormContentModel';
import { Creative, RETAILMAX_LAYOUT_TYPE } from 'core/creative/Creative';
import _ from 'lodash';
import i18n from 'i18next';
import AdNeonForm from './AdNeonForm';
import { formatBytes } from 'utils/StringUtil';
import { renderErrors } from './CreativeFormHintRenderFunction';
import { FormField } from 'components/common/formik/FormikField';

const mimeDB = require('mime-db');

export type AdNeonFormProps = {
  readonly model: AdNeonFormModel;
  readonly basicFields: FormField[];
  readonly renderHintModal: () => any;
};

export class AdNeonFormModel implements FormContentModel {

  public needBannerUrl: boolean = true;

  constructor (
    public type: string
  ) {}

  addLimitation?: (operate: string, limitationType: string, label: string, value: string) => void;

  setLimitationHook (addLimitation: (operate: string, limitationType: string, label: string, value: string) => void): void {
    this.addLimitation = addLimitation;
  }

  getFormContent = () => {
    return AdNeonForm;
  }

  validateVideo = (fileData) => {
    const validTypes = ['video/mp4'];
    if (!fileData || (!fileData.file && !fileData.url)) {
      return i18n.t('formValidate.labels.emptyError');
    }

    if (!fileData.file) {
      return;
    }

    const file = fileData.file;
    if (validTypes.indexOf(file.type) === -1) {
      const extensions = _.get(mimeDB[file.type], 'extensions', ['Unknown']);
      return i18n.t('creativeSetupFlow.labels.typeErrorHint', { type: extensions[0] });
    }

    const errors = [];
    const width = fileData.width;
    const height = fileData.height;
    const proportion = (width / height).toFixed(2);
    const targetProportion = (16 / 9).toFixed(2);
    if (proportion !== targetProportion) {
      errors.push(i18n.t('outdoorForm.labels.videoProportionErrorHint', { proportion: '16:9' }));
    }
    return errors.length > 0 ? renderErrors(errors) : undefined;
  }

  validateImage = (fileData) => {
    const validTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    if (!fileData || (!fileData.file && !fileData.url)) {
      return i18n.t('formValidate.labels.emptyError');
    }

    if (!fileData.file) {
      return;
    }

    const file = fileData.file;
    if (validTypes.indexOf(file.type) === -1) {
      const extensions = _.get(mimeDB[file.type], 'extensions', ['Unknown']);
      return i18n.t('creativeSetupFlow.labels.typeErrorHint', { type: extensions[0] });
    }

    const errors = [];
    const width = fileData.width;
    const height = fileData.height;
    const targetWidth = 300;
    const targetHeight = 250;
    if (width !== targetWidth || height !== targetHeight) {
      errors.push(i18n.t('creativeSetupFlow.labels.sizeErrorHint2', { size1: `${width} x ${height}`, size2: `${targetWidth} x ${targetHeight}` }));
    }

    if (file.size > 2097152) {
      errors.push(i18n.t('creativeSetupFlow.labels.storageErrorHint', { storage1: formatBytes(file.size), storage2: '2MB' }));
    }

    return errors.length > 0 ? renderErrors(errors) : undefined;
  }

  getInitTypeProperties () {
    return {
      layout: RETAILMAX_LAYOUT_TYPE.IMAGE
    };
  }

  getFormModelData (creative: Creative) {
    const creativeBasic: any = _.omit(creative.basic, ['id', 'srcType', 'creativeValues', 'bannerExtra']);
    const videoUrl = _.get(creative, 'basic.bannerExtra.videoUrl');
    const imageUrl = _.get(creative, 'basic.bannerExtra.imageUrl');
    const layoutId = _.get(creative, 'basic.bannerExtra.layoutId');
    const mediaData = imageUrl || videoUrl ? _.omitBy({
      image: imageUrl ? {
        url: imageUrl
      } : undefined,
      video: videoUrl ? {
        url: videoUrl
      } : undefined
    }, _.isEmpty) : undefined;
    return {
      basic: {
        ...creativeBasic,
        medias: mediaData,
        typeProperties: {
          layout: 'pps_' + layoutId
        }
      },
      limitations: creative.limitations
    };
  }
}

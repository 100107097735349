import OneForAllDisplayForm from './OneForAllDisplayForm';
import { AdvertiserManager, DefaultAdvertiserManager } from 'core/advertiser/AdvertiserManager';
import { CreativeFormData } from './FormContentModel';
import _ from 'lodash';
import { CALL_TO_ACTION, Creative } from 'core/creative/Creative';
import i18n from 'i18n';
import { renderErrors } from './CreativeFormHintRenderFunction';
import { CreativeSetupFlowPageModel } from 'containers/Creatives/CreativeSetupFlow/CreativeSetupFlowPageModel';
import { SelectOptions } from 'components/common/commonType';
import { FormField } from 'components/common/formik/FormikField';
import { getByteLength } from 'utils/StringUtil';
import { NativeFormModel } from './NativeFormModel';

export type OneForAllDisplayFormProps = {
  readonly model: OneForAllDisplayFormModel;
  readonly basicFields: FormField[];
  readonly renderHintModal: () => any;
};

const defaultAdvertiserManager: AdvertiserManager = new DefaultAdvertiserManager();

export const MAX_ADTITLE_LENGTH = 50;
export const MAX_ADDESC_LENGTH = 180;
export const MAX_MESSAGE_LENGTH = 300;
export const MAX_SPONSOR_LENGTH = 50;
export class OneForAllDisplayFormModel extends NativeFormModel {

  adMessageRemainLength: number;
  advertiserData: any = {};
  fbPageOptions?: SelectOptions[];
  callToActionOptions: SelectOptions[] = Object.values(CALL_TO_ACTION).map(callToAction => ({
    label: _.startCase(_.lowerCase(callToAction.toString())),
    value: callToAction
  }));

  constructor (
    public flowPageModel: CreativeSetupFlowPageModel,
    nativeToBannerTemplates: { [size: string]: { templatePath?: string, htmlContent?: string } },
    public advertiserManager: AdvertiserManager = defaultAdvertiserManager
  ) {
    super(flowPageModel, nativeToBannerTemplates);
    const typeProperties = _.get(flowPageModel.state.creative, 'basic.typeProperties');
    this.adTitleRemainLength = MAX_ADTITLE_LENGTH;
    this.adDescriptionRemainLength = MAX_ADDESC_LENGTH;
    this.adMessageRemainLength = MAX_MESSAGE_LENGTH;
    this.handleAdTitleChange(_.get(typeProperties, 'title', ''));
    this.handleAdDescriptionChange(_.get(typeProperties, 'desc', ''));
    this.handleAdMessageChange(_.get(typeProperties, 'message', ''));
  }

  getInitTypeProperties (): any {
    return {
      customRectUrl: false,
      callToAction: CALL_TO_ACTION.LEARN_MORE
    };
  }

  handleAdTitleChange = (adTitle) => {
    this.adTitleRemainLength = MAX_ADTITLE_LENGTH - getByteLength(adTitle);
  }

  handleAdDescriptionChange = (adDescription) => {
    this.adDescriptionRemainLength = MAX_ADDESC_LENGTH - getByteLength(adDescription);
  }

  handleAdMessageChange = (adMessage) => {
    this.adMessageRemainLength = MAX_MESSAGE_LENGTH - getByteLength(adMessage);
  }

  getFormContent = (): any => {
    return OneForAllDisplayForm;
  }

  getFormModelData (creative: Creative): CreativeFormData {
    const creativeValueString = _.get(creative, 'basic.creativeValues', '{}');
    const creativeValue = JSON.parse(creativeValueString);
    const fbLandingUrl = _.get(creative, 'basic.bannerExtra.fbLandingUrl', '');
    const pageId = _.get(creative, 'basic.bannerExtra.pageId', '').toString();
    const deepLink = _.get(creative, 'basic.bannerExtra.deepLink', '');
    const typeProperties = _.omitBy({
      title: _.get(creative, 'basic.bannerExtra.title'),
      message: _.get(creative, 'basic.bannerExtra.message'),
      desc: _.get(creative, 'basic.bannerExtra.descriptions'),
      sponsor: _.get(creativeValue, 'sponsored.name'),
      sponsorLink: _.get(creativeValue, 'sponsored.link'),
      pageId,
      fbLandingUrl,
      callToAction: _.get(creative, 'basic.bannerExtra.callToAction', CALL_TO_ACTION.LEARN_MORE),
      deepLink: deepLink === null ? '' : deepLink,
      customRectUrl: _.get(creative, 'basic.bannerExtra.customRectUrl')
    }, _.isUndefined);
    const storyImgUrl = _.get(creative, 'basic.bannerExtra.storyImgUrl');
    const medias = _.omitBy({
      squareImg: _.omitBy({ url: `https:${_.get(creativeValue, 'icon_l.url')}` }, _.isUndefined),
      rectImg: _.omitBy({ url: `https:${_.get(creativeValue, 'img_l.url')}` }, _.isUndefined),
      storyImg: storyImgUrl ? { url: _.get(creative, 'basic.bannerExtra.storyImgUrl') } : undefined
    }, _.isEmpty);
    const creativeBasic: any = _.omit(creative.basic, ['id', 'srcType', 'creativeValues', 'bannerExtra']);
    return {
      basic: {
        ...creativeBasic,
        typeProperties,
        medias
      }
    };
  }

  validateStoryImage = (fileData) => {
    if (!fileData || (!fileData.file && !fileData.url)) {
      return i18n.t('formValidate.labels.emptyError');
    }

    if (!fileData.file) {
      return;
    }

    const errors = [];
    const width = fileData.width;
    const height = fileData.height;
    if (width < 540) {
      errors.push(i18n.t('oneForAllDisplayForm.hints.width', { width }));
    }

    const proportion = width / height;
    const targetProportion = 9 / 16;
    if (Math.abs(targetProportion - proportion) > 0.02) {
      errors.push(i18n.t('oneForAllDisplayForm.hints.proportion', { proportion: '9:16 +- 2%' }));
    }

    return errors.length > 0 ? renderErrors(errors) : undefined;
  }
}

import {
  DefaultRtbCampaignBasicFormModel,
  RtbCampaignBasicFormModel,
  RtbCampaignBasicFormModelConstructorParams
} from './RtbCampaignBasicFormModel';
import _ from 'lodash';

export interface UnknowAdTypeFormModel extends RtbCampaignBasicFormModel {
  readonly showVideoProgressRadio: boolean;
  readonly canEditBudgetPlan: boolean;
  readonly canEditPriceModel: boolean;
}
abstract class AbstractUnknowAdTypeFormModel extends DefaultRtbCampaignBasicFormModel
  implements UnknowAdTypeFormModel {

  showVideoProgressRadio = true;

  getBidPriceMinData (): any {
    const campaignConstraint = this.order.campaignConstraint;
    return {
      cpc: _.get(campaignConstraint, 'bidPriceCPCMin', 0),
      cpm: _.get(campaignConstraint, 'bidPriceCPMMin', 0),
      cpv: _.get(campaignConstraint, 'bidPriceCPVMin', 0),
      vcpm: _.get(campaignConstraint, 'bidPricevCPMMin', 0)
    };
  }
}

export class EditUnknowAdTypeFormModel extends AbstractUnknowAdTypeFormModel {
  constructor (...args: RtbCampaignBasicFormModelConstructorParams) {
    super('edit', ...args);
  }
  get canEditBudgetPlan (): boolean {
    return false;
  }
  get canEditPriceModel (): boolean {
    return false;
  }
}

export class CreateUnknowAdTypeFormModel extends AbstractUnknowAdTypeFormModel {
  constructor (...args: RtbCampaignBasicFormModelConstructorParams) {
    super('create', ...args);
  }
  get canEditBudgetPlan (): boolean {
    return true;
  }
  get canEditPriceModel (): boolean {
    return true;
  }
}

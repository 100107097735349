import React from 'react';
import styles from './withPrefix.module.scss';

export function withPrefix (WrapperComponent) {
  const WithPrefix = props => {
    return (
      <div className={styles.container}>
        <span className={styles.wording}>{props.hocContent}</span>
        <WrapperComponent {...props} />
      </div>
    );
  };
  WithPrefix.displayName = `WithPrefix(${getDisplayName(WrapperComponent)})`;
  return WithPrefix;
}

function getDisplayName (WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

import React from 'react';
import _ from 'lodash';
import styles from './comboCreativeDetail.module.scss';
import { CreativeBasic } from 'core/creative/Creative';
import { ImagePreview } from 'components/common/Image/ImagePreview';
import { VideoPreview } from 'components/common/Video/VideoPreview';

export const ComboCreativeDetail: React.FunctionComponent<{creative: Partial<CreativeBasic>}> = (props) => {
  const creativeValue = _.get(props.creative, 'creativeValue', _.get(props.creative, 'creativeValues'));
  if (!creativeValue) {
    return <div/>;
  }

  const videoUrl = _.get(creativeValue, 'videoUrl', '');
  const imageUrl = _.get(creativeValue, 'imageUrl', '');
  return (
    <div className={styles.comboCreativeDetailContainer}>
      <div className={styles.comboCreativeDetail}>
        <VideoPreview
          maxWidth={300}
          maxHeight={500}
          src={videoUrl}
        />
        <ImagePreview
          size={{ width: 300 }}
          border={false}
          url={imageUrl}
          config={{
            showName: false,
            showSize: false
          }}
        />
      </div>
    </div>
  );
};

import { Account, AccountState } from 'core';

export type AccountSummaryProps = {

  readonly model: AccountSummaryModel;
};

export interface AccountSummaryModel {

  readonly account: Account;

  readonly title: string;
  readonly nameLabel: string;
  readonly emailLabel: string;
  readonly statusLabel: string;
  readonly isAdminLabel: string;
  readonly isGojekAccountManagerLabel: string;
  readonly isPending: boolean;

  readonly name: string;
  readonly email: string;
  readonly status: string;
  readonly isAdmin: string;
  readonly isGojekAccountManager: string;
}

export class DefaultAccountSummaryModel implements AccountSummaryModel {
  account: Account;

  constructor (account: Account) {
    this.account = account;
  }

  get title (): string {
    return 'accounts.summary.title';
  }

  get nameLabel (): string {
    return 'accounts.summary.labels.name';
  }

  get emailLabel (): string {
    return 'accounts.summary.labels.email';
  }

  get statusLabel (): string {
    return 'accounts.summary.labels.status';
  }

  get isAdminLabel (): string {
    return 'accounts.summary.labels.isAdmin';
  }

  get isGojekAccountManagerLabel (): string {
    return 'accounts.summary.labels.isGojekAccountManager';
  }

  get isPending (): boolean {
    return this.account.status === AccountState.PENDING;
  }

  get name (): string {
    return this.account.name;
  }

  get email (): string {
    return this.account.email;
  }

  get status (): string {
    return this.account.activated ? 'accounts.active' : 'accounts.inactive';
  }

  get isAdmin (): string {
    return this.account.isAdmin ? 'accounts.active' : 'accounts.inactive';
  }

  get isGojekAccountManager (): string {
    return this.account.isGoJekAccountManager ? 'accounts.active' : 'accounts.inactive';
  }
}

import React from 'react';

import './ActorMenu.scss';
import './ActorMenuCommon.scss';

import i18n from 'i18n';
import { ActorMenuItem } from './ActorMenuItem';
import { ActorMenuProps, ActorMenuState } from './ActorMenuModel';
import _ from 'lodash';
import config from 'config';

export class ActorMenu extends React.Component<ActorMenuProps, ActorMenuState> {
  menuRef: any;
  handler: number;

  constructor (props: any) {
    super(props);
    this.handler = 0;
    this.menuRef = React.createRef();
    this.state = this.props.model.state;
    this.handler = this.props.model.event.add((model) => {
      this.setState(model.state);
    });
  }

  componentDidMount () {
    document.addEventListener('mousedown', this.dismiss, false);
  }

  componentWillUnmount () {
    this.props.model.event.remove(this.handler);
    document.removeEventListener('mousedown', this.dismiss);
  }

  dismiss = (e: any) => {
    if (this.menuRef.current.contains(e.target)) {
      return;
    }
    if (this.props.model.state.menuShown) {
      this.props.model.toggle();
    }
  }

  toggle = () => {
    this.props.model.toggle();
  }

  render () {
    const model = this.props.model;
    const dropDownClass = this.state.menuShown ? 'drop-down' : 'drop-down hidden';
    const items = model.items.map((item) => <ActorMenuItem model={item} key={item.key} />);
    const roleName = i18n.t(`actor.roleNames.${_.camelCase(model.color)}`);

    return (
      <div>
        {
          config.showActorList &&
          <div className='actor-menu' ref={this.menuRef}>
            <div className='current-actor' onClick={this.toggle}>
              {/* {model.icon !== null && <FontAwesomeIcon icon={model.icon} className="actor-icon" />} */}
              <div className={`role-container ${model.color}`}>{roleName}</div>
              {
                !model.isGuest &&
                <>
                  <span className='company-name'>{model.company}</span>
                  <span className='arrow' />
                </>
              }
            </div>
            <div className={dropDownClass}>
              {items}
            </div>
          </div>
        }
      </div>
    );
  }
}

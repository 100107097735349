import React from 'react';
import { CreativeDetailProps } from './CreativeDetailModel';

export class CreativeDetail extends React.Component<CreativeDetailProps> {

  render () {
    const DetailComponent: typeof React.Component = this.props.model.detailComponent;
    if (DetailComponent) {
      return (
        <DetailComponent creative={this.props.model.creative} />
      );
    }

    return <div/>;
  }
}

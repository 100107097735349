import React from 'react';
import { FormikField, withFieldWrapper } from 'components/common/formik/FormikField';
import { Form as BootstrapForm } from 'react-bootstrap';
import { FormikFormGroup } from 'components/common/formik';
import { CampaignGroup, FBBidStrategy, FbObjective } from 'core/campaignGroup/CampaignGroup';
import { FormikProps } from 'formik';
import { FbAdSetFormData } from '../FbAdSetSetupFlowPageModel';
import { Order } from 'core/order/Order';
import { FbAdSetFormModelData } from './FbAdSetFormModel';
import _ from 'lodash';
import i18n from 'i18n';
import { Dayparts, getInitDaypart } from 'components/Dayparts/Dayparts';
import cx from 'classnames/bind';
import styles from './fbAdSetMainStep.module.scss';
import { formatPriceWithCurrency } from 'helper/CurrencyHelper';
import { FBAppEvent } from 'core/fbAdSet/FbAdSet';
import { GoCampaignOptimizationGoal } from 'core/goCampaign/GoCampaign';
import { getFieldErrors } from 'utils/FormikUtils';
import { renderAdSetTotalDaysDes } from './fbAdSetMainStepRenderFunctions';
import moment from 'moment';

const LabelField = withFieldWrapper(BootstrapForm.Label);
const classNames = cx.bind(styles);

export type FbAdSetFormProps = {
  order: Order;
  campaignGroup: CampaignGroup;
  adSet: FbAdSetFormData;
  canEditOptimizationGoal: boolean;
  handleOptimizationGoalChanged: (newAdSet) => void;
  useModel: (
    order: Order,
    campaignGroup: CampaignGroup,
    adSet: FbAdSetFormData
  ) => FbAdSetFormModelData
};

export const getAdSetTotalDaysDes = (startTime, endTime) => {
  const startDateMoment = moment(startTime);
  const endDateMoment = moment(endTime);
  const days = endDateMoment.diff(startDateMoment, 'days') + 1;
  const hours = endDateMoment.diff(startDateMoment, 'hours') + 1;

  return renderAdSetTotalDaysDes(days, hours);
};

export const FbAdSetForm: React.FC<FormikProps<FbAdSetFormData> & FbAdSetFormProps> = ({
  values,
  errors,
  touched,
  submitCount,
  order,
  campaignGroup,
  adSet,
  canEditOptimizationGoal,
  useModel,
  setFieldValue,
  setFieldTouched,
  handleOptimizationGoalChanged
}) => {

  const {
    formType,
    currency,
    dateRangeProps,
    optimizationGoalOptions,
    canEditBidStrategy,
    canEditBidCap,
    appEventOptions,
    onOptimizationGoalChanged
  } = useModel(order, campaignGroup, adSet);

  const onBidAmountChanged = () => {
    setFieldTouched('lifetime_budget');
  };
  const onDaypartSwitchChanged = (dayPartEnable: boolean) => {
    if (!dayPartEnable) {
      campaignGroup.autoOptimise
       ? setFieldValue('dayPart', getInitDaypart())
       : setFieldValue('dayPart', undefined);
    } else {
      setFieldValue('dayPart', {
        ..._.get(adSet, 'dayPart'),
        enabled: '1'
      });
    }
  };

  const afterOptimizationGoalChanged = (newOptimizationGoal, newDefaultBillingEvent) => {
    let frequencyControlspecs;
    if (_.get(campaignGroup, 'fb.objective') === FbObjective.OUTCOME_AWARENESS && newOptimizationGoal === GoCampaignOptimizationGoal.REACH) {
      frequencyControlspecs = {
        event: 'IMPRESSIONS',
        interval_days: 7,
        max_frequency: 1
      };
    } else {
      frequencyControlspecs = undefined;
    }
    handleOptimizationGoalChanged({
      ...values,
      optimization_goal: newOptimizationGoal,
      promoted_object: newOptimizationGoal === GoCampaignOptimizationGoal.OFFSITE_CONVERSIONS ? {
        custom_event_type: FBAppEvent.PURCHASE
      } : undefined,
      frequency_control_specs: frequencyControlspecs,
      billing_event: newDefaultBillingEvent
    });
  };

  const campaignBidStrategy = _.get(campaignGroup, 'fb.bid_strategy');
  const initBudget = +(_.get(adSet, 'lifetime_budget', 0).toString());
  const budget = +(_.get(values, 'lifetime_budget', 0).toString());
  const budgetIsInvalid = getFieldErrors({
    errors,
    touched,
    submitCount
  }, 'lifetime_budget');
  const dayPartEnable = _.get(values, 'dayPart.enabled', 0);
  const optimizationGoalLabelFormatter = goal => i18n.t(`adSet.optimizationGoal.${goal.toLowerCase()}`);
  const billingEventFormatter = billingEvent => i18n.t(`adSet.billingEvent.${billingEvent.toLowerCase()}`);
  const totalBudget = _.get(adSet, 'isDraft', false) ?
    campaignGroup.budgetBalance :
    campaignGroup.budgetBalance + initBudget;
  const renderBidControl = (formikProps) => {
    const value = formikProps.field.value;
    const onBidStrategyChange = (e) => {
      setFieldValue(formikProps.field.name, e.target.value);
      setFieldValue('bid_amount', '');
    };
    const bidAmountFormatter = value => formatPriceWithCurrency(currency, +value);
    return (
      <div className={styles.bidControl}>
        <BootstrapForm.Check
          type={'radio'}
          label={i18n.t('campaignGroup.labels.fb.bidStrategy.lowest_cost_without_cap')}
          value={FBBidStrategy.LOWEST_COST_WITHOUT_CAP}
          name={'bidStrategy'}
          id={'autoBid'}
          onChange={onBidStrategyChange}
          checked={value === FBBidStrategy.LOWEST_COST_WITHOUT_CAP}
          disabled={!canEditBidStrategy}
        />
        <BootstrapForm.Check
          type={'radio'}
          label={i18n.t('campaignGroup.labels.fb.bidStrategy.lowest_cost_with_bid_cap')}
          value={FBBidStrategy.LOWEST_COST_WITH_BID_CAP}
          name={'bidStrategy'}
          id={'bidCap'}
          onChange={onBidStrategyChange}
          checked={value === FBBidStrategy.LOWEST_COST_WITH_BID_CAP}
          disabled={!canEditBidStrategy}
        />
        {formType === 'create' && !canEditBidStrategy && (
          <span className={styles.bidStrategyHint}>
            {i18n.t('adSetSetupFlow.mainStep.bidStrategyFixHint')}
          </span>
        )}
        {((campaignBidStrategy && campaignBidStrategy === FBBidStrategy.LOWEST_COST_WITH_BID_CAP) ||
          value === FBBidStrategy.LOWEST_COST_WITH_BID_CAP) &&
          <>
            {
              canEditBidCap ?
              <FormikField.InputGroup
                name='bid_amount'
                prefix={currency}
                type='number'
                min={0}
                className={styles.bidAmount}
                onChange={onBidAmountChanged}
              /> :
              <FormikField.Label
                name='bid_amount'
                formatter={bidAmountFormatter}
              />
            }
          </>
        }
      </div>
    );
  };
  const renderMinSpentLimitInput = () => {
    return (
      <>
        <div className={styles.spentLimitLabel}>{i18n.t('adSetSetupFlow.mainStep.field.lifetimeMinSpendTarget')}</div>
        <FormikField.InputGroup
          name='lifetime_min_spend_target'
          prefix={currency}
          type='number'
          min={0}
          hint={i18n.t('adSetSetupFlow.mainStep.hints.lifetimeMinSpendTarget')}
        />
      </>
    );
  };
  const renderMaxSpentLimitInput = () => {
    return (
      <>
        <div className={styles.spentLimitLabel}>{i18n.t('adSetSetupFlow.mainStep.field.lifetimeSpendCap')}</div>
        <FormikField.InputGroup
          name='lifetime_spend_cap'
          prefix={currency}
          type='number'
          min={0}
          hint={i18n.t('adSetSetupFlow.mainStep.hints.lifetimeSpendCap')}
        />
      </>
    );
  };
  const onHasSpendLimitsChange = () => {
    setFieldValue('lifetime_min_spend_target', '');
    setFieldValue('lifetime_spend_cap', '');
  };
  const renderFrequencyControlInput = () => {
    const onMaxFrequencyChange = e => {
      setFieldValue('frequency_control_specs.max_frequency', e.target.value < 0 ? 0 : e.target.value);
    };
    const onIntervalDaysChange = e => {
      setFieldValue('frequency_control_specs.interval_days', e.target.value < 0 ? 0 : e.target.value);
    };
    const maxFrequency = _.get(values, 'frequency_control_specs.max_frequency');
    const intervalDays = _.get(values, 'frequency_control_specs.interval_days');
    const errorMaxFrequency = _.get(errors, 'frequency_control_specs.max_frequency');
    const errorintervalDays = _.get(errors, 'frequency_control_specs.interval_days');
    const maxFrequencyClass = classNames('maxFrequency', {
      error: errorMaxFrequency
    });
    const intervalDaysClass = classNames('intervalDays', {
      error: errorintervalDays
    });
    return (
      <div className={styles.frequencyControl}>
        <div>
          <input
            className={maxFrequencyClass}
            value={maxFrequency}
            name='frequency_control_specs.max_frequency'
            onChange={onMaxFrequencyChange}
            min={1}
            max={20}
            type='number'
          />
          {` ${+(maxFrequency) > 1 ? 'impressions' : 'impression'} every `}
          <input
            className={intervalDaysClass}
            name='frequency_control_specs.interval_days'
            value={intervalDays}
            onChange={onIntervalDaysChange}
            min={1}
            max={7}
            type='number'
          />
          {` ${+(intervalDays) > 1 ? 'days' : 'day'}`}
        </div>
        {errorMaxFrequency && <div className='errorTip'>{errorMaxFrequency}</div>}
        {errorintervalDays && <div className='errorTip'>{errorintervalDays}</div>}
      </div>
    );
  };
  const renderFrequencyControlLabel = () => {
    const frequencyControl = _.get(values, 'frequency_control_specs');
    return i18n.t('adSetSetupFlow.mainStep.labels.frequencyControl', {
      event: +(frequencyControl.max_frequency) > 1 ? 'impressions' : 'impression',
      interval_days: frequencyControl.interval_days,
      max_frequency: frequencyControl.max_frequency,
      unit: +(frequencyControl.interval_days) > 1 ? 'days' : 'day'
    });
  };
  const CBOBudgetFormatter = () => {
    return i18n.t('adSetSetupFlow.mainStep.CBOAdSetBudgetHint');
  };
  const appEventLabelFormatter = value => _.startCase(_.lowerCase(value));
  return (
    <div>
      <fieldset>
        <legend>
          <span>{i18n.t('adSetSetupFlow.mainStep.fieldset.basicTitle')}</span>
        </legend>
        <div className={styles.component}>
          <LabelField
            className={classNames('label', ['col-form-label'])}
            label={i18n.t('adSetSetupFlow.mainStep.field.fbCampaignName')}
          >
            {campaignGroup.name}
          </LabelField>
          <FormikField.Input
            label={i18n.t('adSetSetupFlow.mainStep.field.name')}
            name='name'
          />
          {!!campaignGroup.budget &&
            <FormikField.Label
              label={i18n.t('adSetSetupFlow.mainStep.field.lifetimeBudget')}
              name='lifetime_budget'
              formatter={CBOBudgetFormatter}
            />
          }
          {!campaignGroup.budget &&
            <FormikField.InputGroup
              label={i18n.t('adSetSetupFlow.mainStep.field.lifetimeBudget')}
              name='lifetime_budget'
              prefix={currency}
              type='number'
              min={0}
              hint={budgetIsInvalid ? '' : `${i18n.t('campaignGroup.labels.budgetBalance')} : ${totalBudget - budget}`}
            />
          }
          <FormikField.DateRangePicker
            label={i18n.t('adSetSetupFlow.mainStep.field.dateRange')}
            className={styles.orderInput}
            minDate={dateRangeProps.minDate}
            maxDate={dateRangeProps.maxDate}
            startDateFormikName='start_time'
            endDateFormikName='end_time'
            name='dateRange'
            format={'YYYY-MM-DD HH:mm:ss'}
            timeInterval={60}
            startDatePickerDisabled={dateRangeProps.isAdSetStart}
            fieldContentWidth='auto'
            hint={getAdSetTotalDaysDes(values.start_time, values.end_time)}
            hoverHint={i18n.t('campaignInfo.labels.dateCannotModifyHint')}
          />
        </div>
      </fieldset>
      <fieldset>
        <legend>
          <span>{i18n.t('adSetSetupFlow.mainStep.fieldset.optimizationTitle')}</span>
        </legend>
        <div className={styles.component}>
          {canEditOptimizationGoal ?
            <FormikField.Select
              label={i18n.t('adSetSetupFlow.mainStep.field.optimizationGoal')}
              name='optimization_goal'
              simpleValue
              options={optimizationGoalOptions}
              onChange={_.partial(onOptimizationGoalChanged, afterOptimizationGoalChanged)}
              hint={formType === 'create' && campaignGroup.autoOptimise ?
              <div style={{ maxWidth: 500 }}>
                {i18n.t('adSetSetupFlow.mainStep.firstCBOAdSetOptimizationGoalHint')}
              </div> : ''}
            /> :
            <FormikField.Label
              label={i18n.t('adSetSetupFlow.mainStep.field.optimizationGoal')}
              name='optimization_goal'
              formatter={optimizationGoalLabelFormatter}
              hint={formType === 'create' && campaignGroup.autoOptimise ?
              <div style={{ maxWidth: 500 }}>
                {i18n.t('adSetSetupFlow.mainStep.optimizationGoalFixHint')}
              </div> : ''}
            />
          }
          {
            appEventOptions && (
              formType === 'create' ?
                <FormikField.Select
                  label={i18n.t('adSetSetupFlow.mainStep.field.promoted_object_custom_event_type')}
                  name='promoted_object.custom_event_type'
                  simpleValue
                  options={appEventOptions}
                /> :
                <FormikField.Label
                  label={i18n.t('adSetSetupFlow.mainStep.field.promoted_object_custom_event_type')}
                  name='promoted_object.custom_event_type'
                  formatter={appEventLabelFormatter}
                />
            )
          }
          <FormikField.Custom
            label={i18n.t('adSetSetupFlow.mainStep.field.bidControl')}
            name='bid_strategy'
            render={renderBidControl}
            fieldContentWidth={500}
            hint={
              <div style={{ maxWidth: 500 }}>
                {`${i18n.t(`adSetSetupFlow.mainStep.hint.${values.bid_strategy.toLowerCase()}`, {
                  optimization: i18n.t(`adSet.optimizationGoal.${values.optimization_goal?.toLowerCase()}`).toLowerCase(),
                  bidControl: formatPriceWithCurrency(order.currency, values.bid_amount ? +(values.bid_amount) : 0)
                })}`}
              </div>
            }
          />
          <FormikField.Label
            label={i18n.t('adSetSetupFlow.mainStep.field.billingEvent')}
            name='billing_event'
            formatter={billingEventFormatter}
          />
          {campaignGroup.autoOptimise &&
            <>
              <FormikField.Switch
                label={i18n.t('adSetSetupFlow.mainStep.field.hasSpendLimits')}
                name='hasSpendLimits'
                onChange={onHasSpendLimitsChange}
              />
              {values.hasSpendLimits &&
                (
                  <>
                    <FormikField.Custom
                      label=''
                      render={renderMinSpentLimitInput}
                    />
                    <FormikField.Custom
                      label=''
                      render={renderMaxSpentLimitInput}
                    />
                  </>
                )
              }
            </>
          }
          {_.get(campaignGroup, 'fb.objective') === FbObjective.OUTCOME_AWARENESS && values.optimization_goal === GoCampaignOptimizationGoal.REACH && values.frequency_control_specs && (
            formType === 'create' ?
              <FormikField.Custom
                label={i18n.t('adSetSetupFlow.mainStep.field.frequencyControl')}
                name='frequency_control_specs'
                render={renderFrequencyControlInput}
              /> :
              <FormikField.Label
                label={i18n.t('adSetSetupFlow.mainStep.field.frequencyControl')}
                name='frequency_control_specs'
                formatter={renderFrequencyControlLabel}
              />
            )
          }
          {_.get(campaignGroup, 'fb.pacing_type[0]') !== 'standard' &&
            <div className={styles.section}>
              <FormikFormGroup
                labelName={i18n.t('campaignInfo.labels.daypart')}
                inputName='dayPart.enabled'
                inputAs='switch'
                onChange={onDaypartSwitchChanged}
              />
              {!!dayPartEnable &&
                <FormikField.Custom
                  label=''
                  name='dayPart'
                  component={Dayparts}
                />
              }
            </div>
          }
        </div>
      </fieldset>
    </div>
  );
};

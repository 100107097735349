import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { connect, ErrorMessage, Field, FormikContext } from 'formik';

import { FormikSelect } from 'components/common/formik';
import i18next from 'i18n';
import { AdRequestSourceSpace } from 'core/adRequestSource/AdRequestSource';
import { SelectOptions } from 'components/common/commonType';
import { selectOptionsAddI18n } from 'utils/I18nUtils';
import styles from './agencyForm.module.scss';
import FormGroup from 'components/common/FormGroup/FormGroup';
import { FormikImageInput } from 'components/common/formik/FormikImageInput';
import { FormikField } from 'components/common/formik/FormikField';
import { DefaultAgencyManager } from 'core';
import { getFieldErrors } from 'utils/FormikUtils';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { isProject } from 'core/permission/PermissionDSL';
import { Project } from 'core/project/Project';

type AgencyBasicFormProps = {
  timeZoneOptions: SelectOptions[];
  languageOptions: SelectOptions[];
  currencyOptions: SelectOptions[];
  priorityOptions: SelectOptions[];
  segmentCountryOptions: SelectOptions[];
  segmentLabelOptions: SelectOptions[];
  publisherCountryOptions: SelectOptions[];
  adxOptions: SelectOptions[];
  countryOptions: SelectOptions[];
  spaceOptions: AdRequestSourceSpace[];
  isOptionsLoading: boolean;
  changePublisherIds: Function;
  isNew: boolean;
};

const agencyManager = new DefaultAgencyManager();

const AgencyBasicForm: React.FC<AgencyBasicFormProps & { formik: FormikContext<any> }> = ({
  timeZoneOptions,
  languageOptions,
  currencyOptions,
  countryOptions,
  isOptionsLoading,
  adxOptions,
  isNew,
  formik
}) => {

  const labelLower = (options: SelectOptions) => {
    return {
      label: options.label.toLowerCase(),
      value: options.value,
      options: options.options
    };
  };
  const goganOpOptions = [
    {
      value: 'inc',
      label: i18next.t('limitation.inc')
    },
    {
      value: 'exc',
      label: i18next.t('limitation.exc')
    }
  ];
  const timeZoneDom = timeZoneOptions
    .map(labelLower)
    .map(options => selectOptionsAddI18n(options, ['common.timeZone.']));
  const languageDom = languageOptions
    .map(labelLower)
    .map(options => selectOptionsAddI18n(options, ['common.language.']));
  const currencyDom = currencyOptions
    .map(labelLower)
    .map(options => selectOptionsAddI18n(options, ['common.currency.']));

  const renderImageInput = (formikProps) => {
    let hint = '';
    if (!getFieldErrors(formikProps.form, formikProps.field.name)) {
      hint = i18next.t('creativeSetupFlow.labels.typeHint');
      if (formikProps.field.value.file || formikProps.field.value.url) {
        hint = i18next.t('creativeSetupFlow.labels.successHint');
      }
    }

    return (
      <FormikImageInput
        {...formikProps}
        supportType={['image/jpeg', 'image/jpg', 'image/png']}
        hocContent={hint}
        className={formikProps.field.value.file || formikProps.field.value.url ? styles.autoHeight : undefined}
      />
    );
  };

  const onCurrencyChange = (newCurrency) => {
    formik.setFieldValue('targetBudgetMinimum', agencyManager.getRTBDefaultMinBudgetPerDay(newCurrency));
  };

  return (
    <div className={styles.agencyFrom}>
      <fieldset>
        <legend>
          <span>{i18next.t('agency.information')}</span>
        </legend>
        <div className={styles.fieldContent}>
          <FormikField.Input
            label={i18next.t('agency.companyName')}
            name='companyName'
          />
          <FormikField.Select
            label={i18next.t('agency.language')}
            simpleValue
            name='language'
            options={languageDom}
          />
          <FormikField.Select
            label={i18next.t('agency.currency')}
            simpleValue
            disabled={!isNew}
            name='currency'
            options={currencyDom}
            onChange={onCurrencyChange}
          />
          <FormikField.Select
            label={i18next.t('agency.timeZone')}
            simpleValue
            disabled={!isNew}
            name='timeZone'
            options={timeZoneDom}
          />
          <FormGroup controlId='agencyLogo' label={i18next.t('agency.logo')}>
            <Field
              name='logoData'
              render={renderImageInput}
            />
          </FormGroup>
          <FormikField.TextArea
            label={i18next.t('agency.comComment')}
            name='comComment'
          />
        </div>
      </fieldset>
      <fieldset>
        <legend>
          <span>{i18next.t('agency.form.titles.generalDeliverySetting')}</span>
        </legend>
        <div className={styles.fieldContent}>
          <PermissionChecker permissionAware={isProject(Project.RETAIL)}>
            <Form.Group as={Row} controlId='limitAdx'>
              <Col sm={3}>
                <Form.Label column>{i18next.t('agency.limitAdxStr')}</Form.Label>
              </Col>
              <Col sm={2}>
                <Field
                  simpleValue
                  name='limitAdxOp'
                  component={FormikSelect}
                  options={goganOpOptions}
                  className={styles.autoWithSelect}
                />
              </Col>
              <Col sm={7}>
                <Field
                  isMulti
                  isLoading={isOptionsLoading}
                  name='limitAdx'
                  component={FormikSelect}
                  options={adxOptions}
                  className={styles.autoWithSelect}
                />
                <ErrorMessage name='limitAdx' />
              </Col>
            </Form.Group>
          </PermissionChecker>
          <Form.Group as={Row} controlId='defaultCountry'>
            <Col sm={3}>
              <Form.Label column>{i18next.t('agency.defaultCountryStr')}</Form.Label>
            </Col>
            <Col sm={2}>
              <Field
                simpleValue
                name='defaultCountryOp'
                component={FormikSelect}
                options={goganOpOptions}
                className={styles.autoWithSelect}
              />
            </Col>
            <Col sm={7}>
              <Field
                isMulti
                isLoading={isOptionsLoading}
                name='defaultCountry'
                component={FormikSelect}
                options={countryOptions}
                className={styles.autoWithSelect}
              />
              <ErrorMessage name='defaultCountry' />
            </Col>
          </Form.Group>
        </div>
      </fieldset>
      <fieldset>
        <legend>
          <span>{i18next.t('agency.form.titles.rtbDeliverySetting')}</span>
        </legend>
        <div className={styles.fieldContent}>
          <FormikField.InputGroup
            label={i18next.t('agency.gogan.targetBudgetMinimum')}
            name='targetBudgetMinimum'
            type='number'
            min={1}
            prefix={formik.values.currency ? formik.values.currency : currencyOptions[0].value}
          />
          <FormikField.Switch
            label={i18next.t('agency.interstitial')}
            name='interstitial'
          />
        </div>
      </fieldset>
      <fieldset>
        <legend>
          <span>{i18next.t('agency.marginSetting')}</span>
        </legend>
        <div className={styles.fieldContent}>
          <FormikField.InputGroup
            label={i18next.t('agency.agcPercent')}
            name='agcPercent'
            type='number'
            min={0}
            postfix='%'
          />
          <FormikField.InputGroup
            label={i18next.t('agency.addonProps.maxOrderProfit')}
            name='addonProps.maxOrderProfit'
            type='number'
            min={0}
            postfix='%'
          />
        </div>
      </fieldset>
    </div>
  );
};

export default connect<AgencyBasicFormProps & any>(AgencyBasicForm);

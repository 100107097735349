export function getCpc (spent: number, clicks: number) {
  let cpc = 0;
  if (clicks !== 0) {
    cpc = spent / clicks;
  }

  return cpc;
}

export function getCtr (impres: number, clicks: number) {
  let vctr = 0;
  if (impres !== 0) {
    vctr = (clicks / impres) * 100;
  }
  return vctr;
}

export function getVctr (viewable: number, clicks: number) {
  let vctr = 0;
  if (viewable !== 0) {
    vctr = (clicks / viewable) * 100;
  }
  return vctr;
}

export function getCpa (spent: number, convs: number) {
  let cpa = 0;
  if (convs !== 0) {
    cpa = spent / convs;
  }
  return cpa;
}

export function getCvr (convs: number, clicks: number) {
  let cvr = 0;
  if (clicks !== 0) {
    cvr = (convs / clicks) * 100;
  }
  return cvr;
}

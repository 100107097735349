import { CreativeFormData, FormContentModel } from './FormContentModel';
import _ from 'lodash';
import OneForAllVideoForm from './OneForAllVideoForm';
import { CALL_TO_ACTION, Creative } from 'core/creative/Creative';
import { getByteLength, formatBytes } from 'utils/StringUtil';
import { renderErrors } from './CreativeFormHintRenderFunction';
import i18n from 'i18n';
import { validateEmpty } from 'utils/ValidateUtils';
import { CreativeSetupFlowPageModel } from 'containers/Creatives/CreativeSetupFlow/CreativeSetupFlowPageModel';

export type OneForAllVideoFormProps = {
  readonly model: OneForAllVideoFormModel;
  readonly basicFields: any[];
  readonly renderHintModal: () => any;
};

export const MAX_ADTITLE_LENGTH = 50;
export const MAX_MESSAGE_LENGTH = 300;
export const MAX_SHORT_MESSAGE_LENGTH = 100;
export const MAX_ADDESC_LENGTH = 180;

export class OneForAllVideoFormModel implements FormContentModel {

  public needBannerUrl: boolean = true;
  adTitleRemainLength: number = MAX_ADTITLE_LENGTH;
  adDescriptionRemainLength: number = MAX_ADDESC_LENGTH;
  adMessageRemainLength: number = MAX_MESSAGE_LENGTH;
  adShortMessageRemainLength: number = MAX_SHORT_MESSAGE_LENGTH;
  addLimitation?: (operate: string, limitationType: string, label: string, value: string) => void;

  constructor (
    public flowPageModel: CreativeSetupFlowPageModel
  ) {
    const typeProperties = _.get(flowPageModel.state.creative, 'basic.typeProperties');
    const title = _.get(typeProperties, 'title', '');
    const description = _.get(typeProperties, 'description', '');
    const message = _.get(typeProperties, 'message', '');
    const shortMessage = _.get(typeProperties, 'shortMessage', '');
    title && this.handleAdTitleChange(title);
    description && this.handleAdDescriptionChange(description);
    message && this.handleAdMessageChange(message);
    shortMessage && this.handleAdShortMessageChange(shortMessage);
  }

  handleAdTitleChange = (adTitle) => {
    this.adTitleRemainLength = MAX_ADTITLE_LENGTH - getByteLength(adTitle);
  }

  handleAdMessageChange = (adMessage) => {
    this.adMessageRemainLength = MAX_MESSAGE_LENGTH - getByteLength(adMessage);
  }

  handleAdShortMessageChange = (adShortMessage) => {
    this.adShortMessageRemainLength = MAX_SHORT_MESSAGE_LENGTH - getByteLength(adShortMessage);
  }

  handleAdDescriptionChange = (adDescription) => {
    this.adDescriptionRemainLength = MAX_ADDESC_LENGTH - getByteLength(adDescription);
  }

  setLimitationHook (addLimitation: (operate: string, limitationType: string, label: string, value: string) => void): void {
    this.addLimitation = addLimitation;
  }

  getFormContent = () => {
    return OneForAllVideoForm;
  }

  getInitTypeProperties (): any {
    return {
      callToAction: CALL_TO_ACTION.LEARN_MORE
    };
  }

  getFormModelData (creative: Creative): CreativeFormData {
    const title = _.get(creative, 'basic.bannerExtra.title');
    const description = _.get(creative, 'basic.bannerExtra.description');
    const storyVideoId = _.get(creative, 'basic.bannerExtra.storyVideoId');
    const typeProperties = _.omitBy({
      title: title ? title : '',
      message: _.get(creative, 'basic.bannerExtra.message', ''),
      shortMessage: _.get(creative, 'basic.bannerExtra.shortMessage', ''),
      description: description ? description : '',
      callToAction: _.get(creative, 'basic.bannerExtra.callToAction', CALL_TO_ACTION.LEARN_MORE),
      pageId: _.get(creative, 'basic.bannerExtra.pageId').toString(),
      videoId: _.get(creative, 'basic.bannerExtra.videoId').toString(),
      storyVideoId: storyVideoId ? storyVideoId.toString() : undefined
    }, _.isUndefined);
    const storyVideoUrl = _.get(creative, 'basic.bannerExtra.storyVideoUrl');
    const storyThumbnailUrl = _.get(creative, 'basic.bannerExtra.storyThumbnailUrl');
    const medias = _.omitBy({
      video: _.omitBy({
        url: _.get(creative, 'basic.bannerExtra.videoUrl'),
        width: _.get(creative, 'basic.bannerExtra.width'),
        height: _.get(creative, 'basic.bannerExtra.height')
      }, _.isUndefined),
      videoThumbnail: _.omitBy({ url: _.get(creative, 'basic.bannerExtra.thumbnailUrl') }, _.isUndefined),
      storyVideo: storyVideoUrl ? {
        url: _.get(creative, 'basic.bannerExtra.storyVideoUrl')
      } : undefined,
      storyVideoThumbnail: storyThumbnailUrl ? {
        url: _.get(creative, 'basic.bannerExtra.storyThumbnailUrl')
      } : undefined
    }, _.isEmpty);
    const creativeBasic: any = _.omit(creative.basic, ['id', 'srcType', 'creativeValues', 'bannerExtra', 'bannerUrl']);
    return {
      basic: {
        ...creativeBasic,
        bannerUrl: _.get(creative, 'basic.bannerExtra.fbLandingUrl'),
        typeProperties,
        medias
      }
    };
  }

  validateRemainingCharacters = (title, maxLength, required) => {
    const error = validateEmpty(title);
    if (error) {
      return required ? error : undefined;
    }

    const remainingCharacters = maxLength - getByteLength(title);
    if (remainingCharacters < 0) {
      return i18n.t('creativeSetupFlow.labels.remainingCharacters', { num: remainingCharacters });
    }
  }

  validateMinimumTextLength = (value, minLength) => {
    const error = validateEmpty(value);
    if (error) {
      return error;
    }

    const marginLength = getByteLength(value) - minLength;
    if (marginLength < 0) {
      return i18n.t('formValidate.labels.minimumLengthError', { min: minLength });
    }
  }

  validateVideo = (fileData) => {
    const validTypes = ['video/mp4'];
    if (!fileData || (!fileData.file && !fileData.url)) {
      return i18n.t('formValidate.labels.emptyError');
    }

    if (!fileData.file) {
      return;
    }

    const file = fileData.file;
    if (validTypes.indexOf(file.type) === -1) {
      return i18n.t('creativeSetupFlow.labels.videoTypeHint');
    }

    const errors = [];
    const minWidthHeightMap = {
      '1.00': {
        width: 640,
        height: 640
      },
      [(16 / 9).toFixed(2)]: {
        width: 960,
        height: 540
      },
      [(9 / 16).toFixed(2)]: {
        width: 540,
        height: 960
      }
    };
    const width = fileData.width;
    const height = fileData.height;
    const ratio = (width / height).toFixed(2);
    const targetRatios = Object.keys(minWidthHeightMap);
    if (targetRatios.indexOf(ratio) === -1) {
      errors.push(i18n.t('creativeSetupFlow.hints.oneForAllVideo.ratio'));
    } else {
      const minWidthHeight = minWidthHeightMap[ratio];
      if (width < minWidthHeight.width || height < minWidthHeight.height) {
        errors.push(i18n.t('creativeSetupFlow.hints.oneForAllVideo.resolution'));
      }
    }

    if (file.size > 524288000) {
      errors.push(i18n.t('creativeSetupFlow.labels.storageErrorHint', { storage1: formatBytes(file.size), storage2: '500MB' }));
    }

    const duration = fileData.duration;
    if (duration > 60 || duration < 5) {
      errors.push(i18n.t('fbVideoForm.hints.duration', { min: 5, max: 60 }));
    }

    return errors.length > 0 ? renderErrors(errors) : undefined;
  }

  validateStoryVideo = fileData => {
    if (!fileData || (!fileData.file && !fileData.url)) {
      return i18n.t('formValidate.labels.emptyError');
    }

    if (!fileData.file) {
      return;
    }

    const file = fileData.file;
    const validTypes = ['video/mp4'];
    if (validTypes.indexOf(file.type) === -1) {
      return i18n.t('creativeSetupFlow.labels.videoTypeHint');
    }

    const errors = [];
    const width = fileData.width;
    const height = fileData.height;
    const ratio = (width / height).toFixed(2);
    const targetRatio = (9 / 16).toFixed(2);
    if (targetRatio !== ratio) {
      errors.push(i18n.t('creativeSetupFlow.hints.oneForAllVideo.storyRatio'));
    }

    const minWidth = 540;
    const minHeight = 960;
    if (width < minWidth || height < minHeight) {
      errors.push(i18n.t('creativeSetupFlow.hints.oneForAllVideo.storyResolution'));
    }

    if (file.size > 262144000) {
      errors.push(i18n.t('creativeSetupFlow.labels.storageErrorHint', { storage1: formatBytes(file.size), storage2: '250MB' }));
    }

    const duration = fileData.duration;
    if (duration > 60 || duration < 9) {
      errors.push(i18n.t('fbVideoForm.hints.duration', { min: 9, max: 60 }));
    }

    return errors.length > 0 ? renderErrors(errors) : undefined;
  }

  validateVideoThumbnail = (value, videoProportion) => {
    if (!value || !value.file) {
      return;
    }
    const videoThumbnailProportion = (value.width / value.height).toFixed(2);
    if (videoProportion !== videoThumbnailProportion) {
      return i18n.t('creativeSetupFlow.errors.fbVideo.thumbnailProportion');
    }
  }
}

import display from 'assets/campaign/adType-display.png';
import display2x from 'assets/campaign/adType-display@2x.png';
import video from 'assets/campaign/adType-video.png';
import video2x from 'assets/campaign/adType-video@2x.png';
import outdoor from 'assets/campaign/adType-outdoor.png';
import outdoor2x from 'assets/campaign/adType-outdoor@2x.png';
import thirdParty from 'assets/campaign/adType-thirdParty.png';
import thirdParty2x from 'assets/campaign/adType-thirdParty@2x.png';
import { AdType, AD_TYPE_MAP_CREATIVE_TYPE } from 'core/rtbCampaign/RtbCampaign';
import { DefaultCampaignAdTypeButtonModel, CampaignAdTypeButtonModel } from 'components/CampaignAdTypeButton/CampaignAdTypeButtonModel';
import { UpdateEventListener, FireableUpdateEventListener } from 'utils/UpdateEventListener';
import { AddonFeatureType } from 'core/agency/AddonFeature';
import { RtbCampaignSetupFlowPageModel } from '../RtbCampaignSetupFlowPageModel';
import i18n from 'i18next';
import _ from 'lodash';
import { CreativeType } from 'core/creative/Creative';
import { CampaignGroupChannel, GoCampaignGroupObjective } from 'core/campaignGroup/CampaignGroup';

export interface ChooseAdTypeStepModel {
  readonly event: UpdateEventListener<ChooseAdTypeStepModel>;
  readonly state: ChooseAdTypeStepState;
  readonly flowModel: RtbCampaignSetupFlowPageModel;
  readonly getAdTypeButtonModels: () => CampaignAdTypeButtonModel[];
  readonly showChangeAdTypeAlertModal: (confirmFuc) => void;
  onAdTypeChange: (adType: AdType) => void;
}

export type ChooseAdTypeStepProps = {
  readonly model: ChooseAdTypeStepModel;
};

export type ChooseAdTypeStepState = {
  readonly changeAdTypeAlertModal?: any;
};

export class DefaultChooseAdTypeStepModel implements ChooseAdTypeStepModel {
  event: FireableUpdateEventListener<ChooseAdTypeStepModel>;
  adType?: AdType;
  changeAdTypeAlertModal?: any;
  flowModel: RtbCampaignSetupFlowPageModel;

  constructor (
    flowModel: RtbCampaignSetupFlowPageModel,
    private goNext: () => void
  ) {
    this.flowModel = flowModel;
    this.adType = flowModel.state.campaign.basic.adType;
    this.event = new FireableUpdateEventListener<ChooseAdTypeStepModel>();
  }

  get state () {
    return {
      changeAdTypeAlertModal: this.changeAdTypeAlertModal
    };
  }

  get adTypeOptions () {
    const addonFeatureManager = this.flowModel.addonFeatureManager;
    const objective = _.get(this.flowModel.campaignGroup, 'objective');
    const channel = _.get(this.flowModel.campaignGroup, 'channel');
    const objectiveDependancy = {
      [AdType.PILOT_TV]: [GoCampaignGroupObjective.AwarenessObjective.REACH]
    };
    const channelDependancy = {
      [AdType.PILOT_TV]: [CampaignGroupChannel.RTB]
    };
    return Object.keys(AD_TYPE_MAP_CREATIVE_TYPE).reduce((allowAdType, adType) => {
      const allowCreativeTypes = AD_TYPE_MAP_CREATIVE_TYPE[adType];
      const hasPermission = allowCreativeTypes.some((creativeType) => {
        if (creativeType === CreativeType.ONE_FOR_ALL) {
          return false;
        }
        return addonFeatureManager.isFeatureEnable(`option_${creativeType}` as AddonFeatureType);
      });
      const objectiveCompatible = !objectiveDependancy[adType] || objectiveDependancy[adType].includes(objective);
      const channelCompatible = !channelDependancy[adType] || channelDependancy[adType].includes(channel);
      if (hasPermission && objectiveCompatible && channelCompatible) {
        allowAdType.push(AdType[adType]);
      }
      return allowAdType;
    }, [] as Array<AdType>);
  }

  getAdTypeButtonModels = () => {
    return this.adTypeOptions.map((adTypeOption) => {
      return new DefaultCampaignAdTypeButtonModel(
        this.getAdTypeButtonData(adTypeOption),
        adTypeOption === this.adType,
        _.partial(this.onAdTypeButtonClick, adTypeOption)
      );
    });
  }

  onAdTypeButtonClick = (adType: AdType) => {
    const campaign = this.flowModel.state.campaign;
    const currentAdType = _.get(campaign, 'basic.adType');
    if (currentAdType !== undefined && adType !== currentAdType) {
      this.showChangeAdTypeAlertModal(() => {
        this.onAdTypeChange(adType);
      });
    } else {
      this.onAdTypeChange(adType);
    }
  }

  onAdTypeChange (adType: AdType) {
    this.adType = adType;
    this.flowModel.onAdTypeChange(adType);
    this.goNext();
  }

  getAdTypeButtonData (adTypeOption: AdType) {
    switch (adTypeOption) {
      case AdType.DISPLAY:
        return {
          icon: display,
          icon2x: display2x,
          title: 'campaign.buttons.adTypeDisplayTitle',
          description: 'campaign.buttons.adTypeDisplayDesc'
        };
      case AdType.OUTDOOR:
        return {
          icon: outdoor,
          icon2x: outdoor2x,
          title: 'campaign.buttons.adTypeOutdoorTitle',
          description: 'campaign.buttons.adTypeOutdoorDesc'
        };
      case AdType.THIRD_PARTY:
        return {
          icon: thirdParty,
          icon2x: thirdParty2x,
          title: 'campaign.buttons.adTypeThirdPartyTitle',
          description: 'campaign.buttons.adTypeThirdPartyDesc'
        };
      case AdType.THIRD_PARTY_BOTTOM:
        return {
          icon: thirdParty,
          icon2x: thirdParty2x,
          title: 'campaign.buttons.adTypeThirdBottomPartyTitle',
          description: 'campaign.buttons.adTypeThirdPartyBottomDesc'
        };
      case AdType.THIRD_PARTY_RECTANGLE:
        return {
          icon: thirdParty,
          icon2x: thirdParty2x,
          title: 'campaign.buttons.adTypeThirdPartyRectangleTitle',
          description: 'campaign.buttons.adTypeThirdPartyRectangleDesc'
        };
      case AdType.VIDEO:
        return {
          icon: video,
          icon2x: video2x,
          title: 'campaign.buttons.adTypeVideoTitle',
          description: 'campaign.buttons.adTypeVideoDesc'
        };
      case AdType.COMBO:
        return {
          icon: video,
          icon2x: video2x,
          title: 'campaign.buttons.adTypeComboTitle',
          description: 'campaign.buttons.adTypeComboDesc'
        };
      case AdType.ONE_FOR_ALL_DISPLAY:
        return {
          icon: display,
          icon2x: display2x,
          title: 'campaign.buttons.adTypeOneForAllDisplayTitle',
          description: 'One For All Display | RTB Image'
        };
      case AdType.PILOT_TV:
        return {
          icon: video,
          icon2x: video2x,
          title: 'campaign.buttons.adTypePilotTVTitle',
          description: 'campaign.buttons.adTypePilotTVDesc'
        };
      default:
        return {
          icon: display,
          icon2x: display2x,
          title: 'campaign.buttons.adTypeDisplayTitle',
          description: 'Image / html 5 / Google Native / Dynamic / Product'
        };
    }
  }

  showChangeAdTypeAlertModal = (confirmFuc) => {
    this.changeAdTypeAlertModal = {
      title: i18n.t('common.warning'),
      message: i18n.t('campaign.descriptions.adTypeChangeAlert'),
      primaryBtn: {
        title: i18n.t('common.buttons.ok'),
        callback: () => {
          confirmFuc();
          this.hideModal();
        }
      },
      secondaryBtn: {
        title: i18n.t('common.buttons.cancel'),
        callback: this.hideModal
      }
    };
    this.updateState();
  }

  hideModal = () => {
    this.changeAdTypeAlertModal = undefined;
    this.updateState();
  }

  updateState () {
    this.event.fireEvent(this);
  }
}

import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import { connect, FormikContext } from 'formik';
import { PilotTVFormProps } from './PilotTVFormModel';
import { FormConfig } from 'components/common/formik/FormikField';
import { FormikFormContent } from 'components/common/formik/FormikFormContent';

export const PilotTVForm: React.FunctionComponent<PilotTVFormProps & { formik: FormikContext<any> }> = props => {

  const [formConfig, setFormConfig] = useState<FormConfig | undefined>();

  useEffect(() => {
    setFormConfig(new FormConfig.Builder()
      .addSection(
        new FormConfig.SectionBuilder(
          i18n.t('creativeSetupFlow.labels.creativeBasicInfo'),
          new FormConfig.FieldsBuilder([...props.basicFields])
            .addFileInput({
              type: 'video',
              label: i18n.t('videoForm.labels.tenMaxVideoInput'),
              name: 'medias.fileVideo',
              maxWidth: 300,
              hints: [
                i18n.t('creativeSetupFlow.labels.videoTypeHint'),
                i18n.t('creativeSetupFlow.labels.storageHint', { storage: '10MB' }),
                i18n.t('creativeSetupFlow.labels.videoDurationHint')
              ],
              validate: props.model.validateVideo
            })
            .build()
        ).build()
      ).build()
    );
  }, [
    props.basicFields,
    props.model.validateVideo
  ]);

  return (
    <FormikFormContent
      formConfig={formConfig}
    />
  );
};

export default connect(PilotTVForm);

import { RoleWebService, RestfulRoleWebService } from 'ws/RoleWebService';

export interface RoleManager {

  addRole (scope: string, scopeId: number, email: string, role: string): Promise<void>;
  deleteRole (scope: string, scopeId: number, accountId: number): Promise<void>;
  changeRole (scope: string, scopeId: number, email: string, role: string): Promise<void>;
}

export class DefaultRoleManager implements RoleManager {
  webService: RoleWebService;

  constructor (webService: RoleWebService = new RestfulRoleWebService()) {
    this.webService = webService;
  }

  addRole (scope: string, scopeId: number, email: string, role: string) {
    return this.webService.addRole(scope, scopeId, email, role.replace('ADVERTISER', 'ADV'));
  }

  deleteRole (scope: string, scopeId: number, accountId: number) {
    return this.webService.deleteRole(scope, scopeId, accountId);
  }

  changeRole (scope: string, scopeId: number, email: string, role: string) {
    return this.webService.changeRole(scope, scopeId, email, role.replace('ADVERTISER', 'ADV'));
  }
}

import React, { useCallback, useContext, useRef } from 'react';
import { Form, Formik } from 'formik';
import { Tabs, Tab, Button, Row, Form as BootstrapForm } from 'react-bootstrap';
import i18n from 'i18n';
import styles from './fbAdSetMainStep.module.scss';
import { FbAdSetForm } from './FbAdSetForm';
import { FbAdSetMainStepTab } from '../FbAdSetSetupFlowPageModel';
import { StepRenderProps } from 'containers/StepPage/StepPage';
import { EditLimitation } from 'containers/Limitations/EditLimitation';
import _ from 'lodash';
import { OPERATE } from 'enum/Operate';
import { ErrorHandler } from 'components/common/formik/FormErrorHandler/FormErrorHandler';
import Select from 'components/common/Select/Select';
import { ageMaxOptions, ageMinOptions, genderOptions } from 'core/limitation/goCampaignTAOptions';
import { GoCampaignOptimizationGoal } from 'core/goCampaign/GoCampaign';
import { FbAdSetSetupFlowContext, FbAdSetSetupFlowContextType } from '../FbAdSetSetupFlowContext';
import { ADSET_DEFAULT_AGE_MAX, ADSET_DEFAULT_AGE_MIN } from 'core/fbAdSet/FbAdSet';

export const FbAdSetMainStep: React.FC<StepRenderProps> = (stepPageContext) => {

  const {
    validate,
    setAdSet,
    adSetFormModel,
    setEsitimatedData,
    onSaveDraft,
    adSet,
    order,
    campaignGroup,
    limitationModel,
    canEditOptimizationGoal
  } = useContext(FbAdSetSetupFlowContext) as FbAdSetSetupFlowContextType;

  const mainDomRef = useRef(null);

  const onValidate = async (isSubmitting, values) => {
    const formErrors = validate(values);
    const targetingErrors = limitationModel ? await limitationModel.validate() : {};
    if (isSubmitting) {
      if (!_.isEmpty(formErrors)) {
        stepPageContext.goSubStep(FbAdSetMainStepTab.BASIC);
      } else if (!_.isEmpty(targetingErrors)) {
        stepPageContext.goSubStep(FbAdSetMainStepTab.TARGETINIG);
      }
    }
    return _.omitBy({
      ...formErrors,
      targetingErrors: _.isEmpty(targetingErrors) ? undefined : targetingErrors
    }, _.isEmpty);
  };

  const onSubmit = (values) => {
    if (!limitationModel) {
      return;
    }
    stepPageContext.goNext(() => {
      setEsitimatedData(limitationModel.estimateData);
      setAdSet({
        ...values,
        targeting: limitationModel.limitationValue
      });
    });
  };

  const onTabSelect = useCallback((tab) => {
    stepPageContext.goSubStep(+tab);
  }, [stepPageContext]);

  const renderAgeAndGender = (limitationValue, onChange: (operate: string, type: string, value: any[]) => void) => {
    const generalTA = limitationValue ? limitationValue : [];
    const ageMinLimitation = generalTA.find(value => value.type === 'age_min');
    const ageMin = ageMinLimitation ? ageMinLimitation.value : ADSET_DEFAULT_AGE_MIN;
    const ageMaxLimitation = generalTA.find(value => value.type === 'age_max');
    const ageMax = ageMaxLimitation ? ageMaxLimitation.value : ADSET_DEFAULT_AGE_MAX;
    const gendersLimitation = generalTA.find(value => value.type === 'genders');
    const onAgeMinChange = newAgeMin => {
      if (newAgeMin > ageMax) {
        onChange('include', 'age_max', newAgeMin);
      }
      onChange('include', 'age_min', newAgeMin);
    };
    const onAgeMaxChange = newAgeMax => {
      if (newAgeMax < ageMin) {
        onChange('include', 'age_min', newAgeMax);
      }
      onChange('include', 'age_max', newAgeMax);
    };
    const onGendersChange = gender => {
      onChange('include', 'genders', gender === -1 ? undefined : gender);
    };
    return (
      <div>
        <BootstrapForm.Group as={Row} controlId={'age'}>
          <BootstrapForm.Label>{i18n.t('limitation.labels.age')}</BootstrapForm.Label>
          <div className={styles.ageSelector}>
            <Select
              className={styles.ageMin}
              options={ageMinOptions}
              name='ageMin'
              simpleValue
              value={ageMin}
              onChange={onAgeMinChange}
            />
            ~
            <Select
              className={styles.ageMax}
              options={ageMaxOptions}
              name='ageMax'
              simpleValue
              value={ageMax}
              onChange={onAgeMaxChange}
            />
          </div>
        </BootstrapForm.Group>
        <BootstrapForm.Group as={Row} controlId={'gender'}>
          <BootstrapForm.Label>{i18n.t('limitation.labels.gender')}</BootstrapForm.Label>
          <Select
            options={genderOptions}
            simpleValue
            value={
              gendersLimitation ? gendersLimitation.value : -1
            }
            onChange={onGendersChange}
          />
        </BootstrapForm.Group>
      </div>
    );
  };

  const formValidate = async value => {
    const errors = await onValidate(false, value);
    throw errors;
  };

  return (
    <div className={styles.fbAdSetMainStep} ref={mainDomRef}>
      <Formik
        initialValues={adSet}
        enableReinitialize
        onSubmit={onSubmit}
        validate={formValidate}
        validateOnBlur={false}
      >
        {formikContext => {
          const onSubmitBtnClicked = () => {
            onValidate(true, formikContext.values);
          };
          const handleOptimizationGoalChanged = (newAdSet) => {
            if (newAdSet.optimization_goal === GoCampaignOptimizationGoal.OFFSITE_CONVERSIONS) {
              limitationModel && limitationModel.addLimitation(OPERATE.INCLUDE, 'user_os', 'Android', 'Android');
            } else {
              limitationModel && limitationModel.removeLimitation(OPERATE.INCLUDE, 'user_os', 'Android');
            }
            setAdSet({
              ...newAdSet,
              targeting: limitationModel ? {
                ...limitationModel.limitationValue
              } : undefined
            });
          };
          return (
            <Form>
              <ErrorHandler
                parentRef={mainDomRef}
                isSubmitting={formikContext.isSubmitting}
                submitCount={formikContext.submitCount}
              />
              <Tabs
                id='adSetFromTab'
                activeKey={stepPageContext.subStepIndex}
                onSelect={onTabSelect}
                className={styles.tabs}
              >
                <Tab eventKey={FbAdSetMainStepTab.BASIC} title={i18n.t('stepSideBar.labels.basic')}>
                  <FbAdSetForm
                    {...formikContext}
                    campaignGroup={campaignGroup}
                    order={order}
                    adSet={adSet}
                    useModel={adSetFormModel}
                    canEditOptimizationGoal={canEditOptimizationGoal}
                    handleOptimizationGoalChanged={handleOptimizationGoalChanged}
                  />
                </Tab>
                <Tab eventKey={FbAdSetMainStepTab.TARGETINIG} title={i18n.t('stepSideBar.labels.targeting')}>
                  <EditLimitation model={limitationModel} renderGeneralFields={renderAgeAndGender}/>
                </Tab>
              </Tabs>
              <div className={styles.buttonArea}>
                <Button
                  variant='primary'
                  size='sm'
                  type='submit'
                  onClick={onSubmitBtnClicked}
                >
                  {i18n.t('campaign.buttons.completeAndCheck')}
                </Button>
                {onSaveDraft && (
                  <Button
                    variant='secondary'
                    size='sm'
                    onClick={_.partial(onSaveDraft, {
                      ...formikContext.values,
                      targeting: limitationModel ? limitationModel.limitationValue : {}
                    })}
                  >
                    {i18n.t('campaign.buttons.saveDraft')}
                  </Button>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

import _ from 'lodash';

import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';

import { AbstractLimitationModel } from 'components/CampaignLimitation/CampaingLimitationForm/AbstractLimitation';
import { SelectOptions } from 'components/common/commonType';
import { createSelectOptions } from 'utils/SelectOptionsUtils';
import { AdRequestSourceManager, DefaultAdRequestSourceManager } from 'core/adRequestSource/AdRequestSourceManager';

export type SearchKeywordsComponentState = {
  readonly loading: boolean;
  readonly relatedSearchKeywords: {
    keyword: string,
    searchCount: number
  }[];
  readonly searchKeywords?: string;
};

export interface SearchKeywordsComponentModel extends AbstractLimitationModel {
  readonly state: SearchKeywordsComponentState;
  readonly event: UpdateEventListener<SearchKeywordsComponentModel>;
  readonly name: string;
  readonly value: SelectOptions[];
  onConfirm (): void;
  changeSearchKeyword (searchKeywords?: string): void;
  addSearchKeyword (searchKeyword: string): void;
  syncModel (model: SearchKeywordsComponentModel): void;
}

export type SearchKeywordsComponentProps = {
  readonly model: SearchKeywordsComponentModel;
};

export class DefaultSearchKeywordsComponentModel implements SearchKeywordsComponentModel {
  event: FireableUpdateEventListener<SearchKeywordsComponentModel>;
  loading: boolean = false;
  searchKeywords?: string;
  relatedSearchKeywords: {
    keyword: string,
    searchCount: number
  }[] = [];
  debouncedFetchRelatedSearchKeywords: any;

  constructor (
    public name: string,
    public value: SelectOptions[],
    private retailer: string,
    private externalOnChange: (type: string, value: SelectOptions[]) => void,
    private adRequestSourceManager: AdRequestSourceManager = new DefaultAdRequestSourceManager()
  ) {
    this.event = new FireableUpdateEventListener<SearchKeywordsComponentModel>();
    this.debouncedFetchRelatedSearchKeywords = _.debounce(this.fetchRelatedSearchKeywords.bind(this), 1000);
  }

  keywordStringToArray (keywordString?: string): string[] {
    if (!keywordString) {
      return [];
    }

    const separators = [',', '，'];
    if (separators.includes(keywordString[keywordString.length - 1])) {
      keywordString = keywordString.slice(0, -1);
    }

    if (separators.includes(keywordString[0])) {
      keywordString = keywordString.slice(1);
    }
    return _.flatten(keywordString.split(',').map(keyword => keyword.trim().split('，').map(keyword => keyword.trim())));
  }

  changeSearchKeyword (searchKeywords?: string) {
    this.searchKeywords = searchKeywords;
    this.debouncedFetchRelatedSearchKeywords();
    this.updateState(false);
  }

  syncModel (model: SearchKeywordsComponentModel) {
    this.searchKeywords = model.state.searchKeywords;
    this.relatedSearchKeywords = model.state.relatedSearchKeywords;
  }

  async fetchRelatedSearchKeywords () {
    this.updateState(true);
    const currentKeywords = this.keywordStringToArray(this.searchKeywords);
    try {
      if (currentKeywords.length > 0) {
        this.relatedSearchKeywords = await this.adRequestSourceManager.getRelatedSearchKeywords(currentKeywords, this.retailer.toLowerCase());
      } else {
        this.relatedSearchKeywords = [];
      }
    } catch (e) {}
    this.updateState(false);
  }

  onConfirm () {
    const searchKeywords = createSelectOptions(
      _.chain(this.value)
        .map(option => option.value)
        .concat(this.keywordStringToArray(this.searchKeywords))
        .uniq()
        .value()
    );
    if (searchKeywords.length > 0) {
      this.externalOnChange.call(this, 'searchKeywords', searchKeywords);
    }
  }

  addSearchKeyword (searchKeyword: string) {
    const searchKeywords = createSelectOptions(
      _.chain(this.value)
        .map(option => option.value)
        .push(searchKeyword)
        .uniq()
        .value()
    );
    if (searchKeywords.length > 0) {
      this.externalOnChange.call(this, 'searchKeywords', searchKeywords);
    }
  }

  get state (): SearchKeywordsComponentState {
    return {
      loading: this.loading,
      searchKeywords: this.searchKeywords,
      relatedSearchKeywords: this.relatedSearchKeywords
    };
  }

  updateState (
    loading: boolean = false
  ) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}

export enum Permission {
  /* Segment Permission */
  SEGMENT_READ = 'FUNC_SEGMENT_R',
  SEGMENT_WRITE = 'FUNC_SEGMENT_W',
  SEGMENT_DEL = 'FUNC_SEGMENT_D',

  /* Conversion Permission */
  CONVERSION_READ = 'FUNC_CONVERSION_R',
  CONVERSION_WRITE = 'FUNC_CONVERSION_W',

  /* Account Permission */
  ACCOUNT_SUDO = 'FUNC_ACCOUNT_SUDO',
  ACCOUNT_READ = 'FUNC_ACCOUNT_R',
  ACCOUNT_WRITE = 'FUNC_ACCOUNT_W',
  ACCOUNT_LIST_READ = 'FUNC_ACCOUNT_LIST_R',

  /* Advertiser Permission */
  ADVERTISER_WRITE = 'FUNC_ADVERTISER_W',
  ADVERTISER_READ = 'FUNC_ADVERTISER_R',
  ADVERTISER_MEMBER_WRITE = 'FUNC_ADVERTISER_MEMBER_W',
  ADVERTISER_MEMBER_READ = 'FUNC_ADVERTISER_MEMBER_R',

  /* Agency Permission */
  AGENCY_READ = 'FUNC_AGC_R',
  AGENCY_CREATE = 'FUNC_AGC_C',
  AGENCY_UPDATE = 'FUNC_AGC_U',
  AGENCY_LIST_READ = 'FUNC_AGC_LIST_R',
  AGENCY_MEMBER_WRITE = 'FUNC_AGENCY_MEMBER_W',
  AGENCY_MEMBER_READ = 'FUNC_AGENCY_MEMBER_R',

  /* Creative Permission */
  CREATIVE_WRITE = 'FUNC_CREATIVE_W',
  CREATIVE_READ = 'FUNC_CREATIVE_R',
  CREATIVE_DEL = 'FUNC_CREATIVE_D',

  /* Order Permission */
  ORDER_READ = 'FUNC_ORDER_R',
  ORDER_WRITE = 'FUNC_ORDER_W',
  ORDER_APPROVE = 'FUNC_ORDER_APPROVE',

  /* Campaign Permission */
  CAMPAIGN_READ = 'FUNC_CAMPAIGN_R',
  CAMPAIGN_WRITE = 'FUNC_CAMPAIGN_W',
  CAMPAIGN_DEL = 'FUNC_CAMPAIGN_D',

  /* Report Permission */
  REPORT_ADS = 'FUNC_REPORT_ADS',
  REPORT_ADMIN = 'FUNC_REPORT_ADMIN',

  /* System Admin Permission */
  SYS_ADMIN_MANAGEMENT = 'FUNC_SYS_ADMIN_MANAGEMENT',

  /* Credit Permission */
  CREDIT_READ = 'FUNC_CREDIT_R',
  ME_LOCALE_READ = 'FUNC_ME_LOCALE_R'
}

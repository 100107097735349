import { CreativeFormBasicData, FormContentModel } from './FormContentModel';
import _ from 'lodash';
import { Creative, CreativeType } from 'core/creative/Creative';
import { SelectOptions } from 'components/common/commonType';
import { DefaultRdpManager, RdpManager } from 'core/rdp/RdpManager';
import { retailCreativeConfig } from 'core/creative/RetailCreativeConfig';
import i18n from 'i18n';
import { CreativeSetupFlowPageModel } from 'containers/Creatives/CreativeSetupFlow/CreativeSetupFlowPageModel';
import { createSelectOptionsFromEnum } from 'utils/SelectOptionsUtils';
import { CallToAction } from 'enum/CallToAction';
import { useCallback, useEffect, useState } from 'react';
import { useCallAPI } from 'hooks/useCallAPI';

const callToActionOptions: SelectOptions[] = createSelectOptionsFromEnum(CallToAction, 'callToAction.');
const defaultRdpManager = new DefaultRdpManager();

export const useRetailOptionsHook = (
  advertiserId: string | number,
  flowPageModel: CreativeSetupFlowPageModel,
  creativeType: CreativeType,
  rdpManager: RdpManager = defaultRdpManager
) => {

  const [retailerOptions, setRetailerOptions] = useState<SelectOptions[]>([]);
  const [productSetOptions, setProductSetOptions] = useState<SelectOptions[]>([]);
  const [layoutOptions, setLayoutOptions] = useState<SelectOptions[]>([]);
  const { loading, callAPIs } = useCallAPI();

  const getDefaultValueOfOptions = (options) => {
    return options && options.length > 0 ? options[0].value : undefined;
  };

  const handleLayoutOptions = useCallback((retailer, retailerOptions) => {
    if (!retailer) {
      return;
    }
    const retailOption = retailerOptions.find(retailOption => retailOption.value === retailer);
    if (!retailOption) {
      setLayoutOptions([]);
    } else {
      setLayoutOptions(retailCreativeConfig[creativeType][retailOption.label.toLowerCase()].map(layout => ({
        label: i18n.t(`adneon.layouts.${_.camelCase(layout)}`),
        value: layout
      })));
    }
  }, [creativeType]);

  const fetchProductSets = useCallback(async (retailer) => {
    const cacheKey = `${advertiserId}_${retailer}`;
    let productSetOptions = flowPageModel.getCache(cacheKey);
    if (productSetOptions) {
      setProductSetOptions(productSetOptions);
      return;
    }
    callAPIs(
      [rdpManager.getRetailProductSets.bind(rdpManager, retailer, advertiserId)],
      (productSets) => {
        productSetOptions = productSets.map(productSet => ({
          label: productSet.name,
          value: productSet.id
        }));
        flowPageModel.addCache(cacheKey, [...productSetOptions]);
        setProductSetOptions(productSetOptions);
      }
    );
  }, [flowPageModel, rdpManager, advertiserId, callAPIs]);

  const fetchAll = useCallback(async () => {
    let retailerOptions = flowPageModel.getCache('retailerOptions');
    if (retailerOptions) {
      setRetailerOptions(retailerOptions);
      const retailer = getDefaultValueOfOptions(retailerOptions);
      const cacheKey = `${advertiserId}_${retailer}`;
      let productSetOptions = flowPageModel.getCache(cacheKey);
      if (productSetOptions) {
        setProductSetOptions(productSetOptions);
        return;
      }
    }
    callAPIs(
      [rdpManager.getRetails.bind(rdpManager)],
      (retailers) => {
        retailerOptions = retailers.map(retailer => ({
          label: retailer.name,
          value: retailer.id
        }));
        flowPageModel.addCache('retailerOptions', [...retailerOptions]);
        setRetailerOptions(retailerOptions);
        const retailer = getDefaultValueOfOptions(retailerOptions);
        handleLayoutOptions(retailer, retailerOptions);
        fetchProductSets(retailer);
      }
    );

  }, [flowPageModel, rdpManager, advertiserId, callAPIs, fetchProductSets, handleLayoutOptions]);

  const onRetailerChange = useCallback(async (retailer) => {
    handleLayoutOptions(retailer, retailerOptions);
    fetchProductSets(retailer);
  }, [retailerOptions, handleLayoutOptions, fetchProductSets]);

  useEffect(() => {
    fetchAll();
  }, [fetchAll]);

  useEffect(() => {
    const retailer = getDefaultValueOfOptions(retailerOptions);
    handleLayoutOptions(retailer, retailerOptions);
  }, [handleLayoutOptions, fetchProductSets, retailerOptions]);

  return {
    loading,
    defaultRetailer: getDefaultValueOfOptions(retailerOptions),
    retailerOptions,
    productSetOptions,
    layoutOptions,
    callToActionOptions,
    onRetailerChange
  };
};

export abstract class RetailProductFormModel implements FormContentModel {

  public retailerOptions: SelectOptions[] = [];
  public productSetOptions: SelectOptions[] = [];
  public layoutOptions: SelectOptions[] = [];
  public needBannerUrl: boolean = false;
  public callToActionOptions: SelectOptions[] = createSelectOptionsFromEnum(CallToAction, 'callToAction.');

  constructor (
    public flowPageModel: CreativeSetupFlowPageModel,
    private creativeType: CreativeType,
    private rdpManager = new DefaultRdpManager()
  ) {}

  get defaultRetailer (): string | number | undefined {
    return this.retailerOptions && this.retailerOptions.length > 0 ? this.retailerOptions[0].value : undefined;
  }

  async init (advertiserId, onFinish?: () => void) {
    this.retailerOptions = this.flowPageModel.getCache('retailerOptions');
    if (!this.retailerOptions) {
      try {
        const retailers = await this.rdpManager.getRetails();
        this.retailerOptions = retailers.map(retailer => ({
          label: retailer.name,
          value: retailer.id
        }));
        this.flowPageModel.addCache('retailerOptions', [...this.retailerOptions]);
      } catch (e) {}
    }
    await this.onRetailerChange(advertiserId, this.defaultRetailer);
    onFinish && onFinish();
  }

  addLimitation?: (operate: string, limitationType: string, label: string, value: string) => void;

  setLimitationHook (addLimitation: (operate: string, limitationType: string, label: string, value: string) => void): void {
    this.addLimitation = addLimitation;
  }

  abstract getFormContent ();

  abstract validateForm (creative: CreativeFormBasicData);

  abstract getInitTypeProperties ();

  abstract getFormModelData (creative: Creative);

  async getProductSets (advertiserId, retailer) {
    const cacheKey = `${advertiserId}_${retailer}`;
    this.productSetOptions = this.flowPageModel.getCache(cacheKey);
    if (this.productSetOptions) {
      return;
    }
    try {
      const productSets = await this.rdpManager.getRetailProductSets(retailer, advertiserId);
      this.productSetOptions = productSets.map(productSet => ({
        label: productSet.name,
        value: productSet.id
      }));
      this.flowPageModel.addCache(cacheKey, [...this.productSetOptions]);
    } catch (e) {}
  }

  onRetailerChange = async (advertiserId, retailer) => {
    if (!retailer) {
      return;
    }
    await this.getProductSets(advertiserId, retailer);
    const retailOption = this.retailerOptions.find(retailOption => retailOption.value === retailer);
    if (!retailOption) {
      this.layoutOptions = [];
    } else {
      this.layoutOptions = retailCreativeConfig[this.creativeType][retailOption.label.toLowerCase()].map(layout => ({
        label: i18n.t(`adneon.layouts.${_.camelCase(layout)}`),
        value: layout
      }));
    }
  }
}

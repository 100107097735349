import React, { useEffect, useState } from 'react';
import styles from './adNeonMediaSummary.module.scss';
import { useCallAPI } from 'hooks/useCallAPI';
import DefaultCreativeManager, { CreativeManager } from 'core/creative/CreativeManager';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { RetailProductCreativeDetail } from 'components/CreativeDetail/RetailProductCreativeDetail';

const creativeManager: CreativeManager = new DefaultCreativeManager();
export const RetailProductMediaSummary: React.FunctionComponent <{ mediaSummary: any }> = props => {

  const { loading, callAPIs } = useCallAPI();
  const [previewData, setPreviewData] = useState<any>();
  useEffect(() => {
    callAPIs([creativeManager.getAdneonCreativePreview.bind(creativeManager, props.mediaSummary)], data => {
      setPreviewData(data);
    });
  }, [callAPIs, props.mediaSummary]);

  return (
    <div className={styles.adNeonPreview}>
      {loading && <LoadingIndicator/>}
      {!previewData ?
        <div/> :
        <RetailProductCreativeDetail
          creative={{
            bannerExtra: {
              responseDTO: {
                ...previewData,
                adTag: previewData.preview
              }
            }
          }}
        />
      }
    </div>
  );
};

import _ from 'lodash';
import client from './RestClient';

export interface CountryWebService {
  getPublisherCountry (): Promise<Array<string>>;
}

export class RestfulCountryWebService implements CountryWebService {
  restClient: any;

  constructor (restClient: any = client) {
    this.restClient = restClient;
  }

  async getPublisherCountry (): Promise<Array<string>> {
    const resp = await this.restClient.get('/spaceCountries');
    return _.defaults(resp.data, []);
  }
}

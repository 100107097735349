import _ from 'lodash';

export function getByteLength (targetString: string): number {
  let cnt = 0;
  const handledTargetString = targetString.replace(/[\r\n|\n|\r]{'{1,}'}/g,' ');
  for (let i = 0; i < handledTargetString.length; i++) {
    let char = handledTargetString.charCodeAt(i);
    cnt += (char >= 0x2E80 && char <= 0xFFEF) ? 2 : 1;
  }

  return cnt;
}

export function numberWithCommas (targetNumber: number | string | undefined) {
  if (_.isNull(targetNumber) || targetNumber === undefined) {
    return '';
  }
  return targetNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function formatBytes (bytes) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const sizeIndex = Math.floor(Math.log(bytes) / Math.log(k));

  return `${(bytes / Math.pow(k, sizeIndex)).toFixed(2)} ${sizes[sizeIndex]}`;
}

import React from 'react';
import ToggleButtons from 'components/common/ToggleButtons/ToggleButtons';
const FormikToggleButtons: React.FunctionComponent<any> = ({
  field: { name, value },
  form: { setFieldValue },
  ...props
}) => {
  const handleChange = newValue => {
    setFieldValue(name, newValue);
  };
  return (
    <ToggleButtons
      {...props}
      value={value}
      name={name}
      onChange={handleChange}
    />
  );
};

export default FormikToggleButtons;

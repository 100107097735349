import React, { Fragment } from 'react';
import styles from './withHoverTip.module.scss';
import _ from 'lodash';

export function withHoverTip (WrapperComponent) {
  const withHoverTipComponent = (props) => {
    const remainingProps = _.omit(props, 'hocContent');
    return (
      <Fragment>
        <WrapperComponent
          {...remainingProps}
          className={`${props.className} ${styles.tips}`}
        />
        <span className={styles.wording}>{props.hocContent}</span>
      </Fragment>
    );
  };

  withHoverTipComponent.displayName = `WithHoverTip(${getDisplayName(WrapperComponent)})`;
  return withHoverTipComponent;
}

function getDisplayName (WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

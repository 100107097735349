import { ColumnDefinition, renderColumn, sortableColumn } from 'components/TableColumn';
import { CurrencyRate } from 'core/currencyRate/CurrencyRate';
import { DefaultCurrencyRateManager } from 'core/currencyRate/CurrencyRateManager';
import { useEffect, useState } from 'react';
import styles from './currencyRateList.module.scss';
import { Currency } from 'core';
import moment from 'moment';
import { useCallAPI } from 'hooks/useCallAPI';

export enum CurrencyRateListColumns {
  CTIME = 'ctime',
  RATE = 'rate',
  CREATED_BY = 'createdBy'
}

export type CurrencyRateListState = {
  readonly loading: boolean;
  readonly rate: string;
  readonly currentCurrency: string;
  readonly currencyRates: { [key: string]: CurrencyRate[] };
};

const defaultCurrencyRateManager = new DefaultCurrencyRateManager();

export const useCurrencyRateListModel = (
  currencyRateManager = defaultCurrencyRateManager
) => {

  const { loading, callAPIs, callAPIsBySequence } = useCallAPI();
  const [state, setState] = useState({
    currentCurrency: Currency.NTD,
    rate: '',
    currencyRates: {}
  } as CurrencyRateListState);

  useEffect(() => {
    callAPIs([
      currencyRateManager.getCurrencyRates.bind(currencyRateManager)
    ], currencyRates => {
      setState(prevState => ({
        ...prevState,
        currencyRates: currencyRates
      }));
    });
  }, [currencyRateManager, callAPIs]);

  const onSwitchCurrency = (currentCurrency) => setState({ ...state, currentCurrency });

  const columnDefinition = (columnName): ColumnDefinition => ({
    ...sortableColumn(columnName, `currencyRateList.headers.${columnName}`, true),
    classes: () => styles[columnName],
    headerClasses: () => styles[columnName]
  });

  const columns = [
    renderColumn(columnDefinition(CurrencyRateListColumns.CTIME), time => moment(time).format('YYYY-MM-DD HH:mm:ss')),
    renderColumn(columnDefinition(CurrencyRateListColumns.RATE)),
    renderColumn(columnDefinition(CurrencyRateListColumns.CREATED_BY))
  ];

  const setRate = (event) => {
    event.persist();
    setState(prevState => ({ ...prevState, rate: event.target.value }));
  };

  const submitRate = async () => {
    callAPIsBySequence([
      currencyRateManager.addCurrencyRate.bind(currencyRateManager, state.currentCurrency, state.rate),
      currencyRateManager.getCurrencyRates.bind(currencyRateManager)
    ], currencyRates => {
      setState(prevState => ({
        ...prevState,
        currencyRates: currencyRates
      }));
    });
  };

  return {
    state,
    columns,
    loading,
    setRate,
    submitRate,
    onSwitchCurrency
  };
};

import React from 'react';
import { PermissionAware } from 'core';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import _ from 'lodash';

interface RouteWithPermissionProps extends RouteProps {
  permissionAware: PermissionAware;
  redirectPath: string;
}

const RouteWithPermissoin: React.FC<RouteWithPermissionProps> = (props) => {
  const redirectTarget = () => <Redirect to={props.redirectPath} />;
  const routeProps = _.omit(props, ['permissionAware', 'redirectPath']);
  return (
    <PermissionChecker permissionAware={props.permissionAware} renderOtherwise={redirectTarget}>
      <Route {...routeProps} />
    </PermissionChecker>
  );
};

export default React.memo(RouteWithPermissoin);

import _ from 'lodash';

import { AdRequestSourceManager, DefaultAdRequestSourceManager } from 'core/adRequestSource/AdRequestSourceManager';
import { OPERATE } from 'enum/Operate';
import { SelectComponent } from 'components/CampaignLimitation/SelectComponent';
import { KeywordComponent } from 'components/CampaignLimitation/KeywordComponent/KeywordComponent';
import { defaultItemSetting, ItemType } from 'components/CampaignLimitation/SelectItemComponent';
import { SpaceCustomInputComponentModel, DomainCustomInputComponentModel } from 'components/CampaignLimitation';
import { DefaultLimitationManager } from 'core/limitation/LimitationManager';
import i18n from 'i18next';
import { DefaultAsyncSourceSpanModel } from 'components/AsyncSourceSpan/AsyncSourceSpanModel';
import DefaultCreativeManager from 'core/creative/CreativeManager';
import { SelectOptions } from 'components/common/commonType';
// import { OptimizationGoal as TiktokOptimizationGoal } from 'core/tiktokAdGroup/TiktokAdGroup';
import { TiktokObjective } from 'core/campaignGroup/CampaignGroup';
import { renderKeywordSettingSearchResult } from './LimitationSettingHintRenderFunctions';
import { GoCampaignOptimizationGoal } from 'core/goCampaign/GoCampaign';
import { createSelectOptions } from 'utils/SelectOptionsUtils';
import { TiktokPlacementMapping } from 'core/tiktokAdGroup/TiktokAdGroup';
import { SearchKeywordsComponent } from 'components/CampaignLimitation/SearchKeywordsComponent/SearchKeywordsComponent';

const defaultAdRequestSourceManager = new DefaultAdRequestSourceManager();
const limitationManager = new DefaultLimitationManager();
const creativeManager = new DefaultCreativeManager();

export const DEFAULT_INVENTORY = 'default';
export const operateTitles = {
  [OPERATE.INCLUDE]: {
    0: 'limitation.labels.incNoValue',
    1: 'limitation.labels.incHaveValue'
  },
  [OPERATE.EXCLUDE]: {
    0: 'limitation.labels.excNoValue',
    1: 'limitation.labels.excHaveValue'
  },
  [OPERATE.PREFERRED]: {
    0: 'limitation.labels.preferNoValue',
    1: 'limitation.labels.preferHaveValue'
  },
  [OPERATE.NONPREFERRED]: {
    0: 'limitation.labels.nonpreferNoValue',
    1: 'limitation.labels.nonpreferHaveValue'
  }
};
export enum LIMITATION_TYPE {
  CAMPAIGN = 'campaign',
  CREATIVE = 'creative'
}

export const defaultInventorySetting = (
  advertiserId: number,
  limitationType: LIMITATION_TYPE,
  goSegments?: SelectOptions[],
  adRequestSourceManager: AdRequestSourceManager = defaultAdRequestSourceManager
) => [
  {
    name: DEFAULT_INVENTORY,
    ignoreAddonFeature: true,
    singleSelect: false,
    itemSetting: defaultItemSetting(),
    searchPlaceholder: '',
    component: SelectComponent
  },
  {
    name: 'geography',
    addonFeature: `${limitationType}_country`,
    title: 'limitation.labels.country',
    cb: () => adRequestSourceManager.getCountries(),
    singleSelect: false,
    itemSetting: defaultItemSetting(),
    searchPlaceholder: 'limitation.placeholders.searchCountryCity',
    component: SelectComponent,
    supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred'],
    postCommentPropsGetter: (limitation, limitationCanNotNullList) => {
      const needShow = limitationCanNotNullList ? limitationCanNotNullList.indexOf('geography') > -1 : false;
      return needShow ? {
        model: new DefaultAsyncSourceSpanModel(() => i18n.t('limitation.labels.geograthyCanNotNull'))
      } : undefined;
    }
  },
  {
    name: 'carrier',
    addonFeature: `${limitationType}_carrier`,
    title: 'limitation.labels.carrier',
    singleSelect: false,
    itemSetting: {
      ...defaultItemSetting(),
      i18nPrefix: 'limitationOptions.labels.carrier'
    },
    searchPlaceholder: 'limitation.placeholders.searchCarrier',
    cb: adRequestSourceManager.getCarriers.bind(adRequestSourceManager),
    supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred'],
    component: SelectComponent,
    i18nPrefix: 'limitationOptions.labels.carrier'
  },
  {
    name: 'os',
    addonFeature: `${limitationType}_device`,
    title: 'limitation.labels.os',
    cb: adRequestSourceManager.getOS.bind(adRequestSourceManager),
    supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred'],
    singleSelect: false,
    itemSetting: defaultItemSetting(),
    searchPlaceholder: 'limitation.placeholders.searchOs',
    component: SelectComponent
  },
  {
    name: 'device',
    addonFeature: `${limitationType}_device`,
    title: 'limitation.labels.device',
    singleSelect: false,
    itemSetting: {
      ...defaultItemSetting(),
      i18nPrefix: 'limitationOptions.labels.device'
    },
    i18nPrefix: 'limitationOptions.labels.device',
    searchPlaceholder: 'limitation.placeholders.searchDevice',
    cb: () => adRequestSourceManager.getDevice(),
    supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred'],
    component: SelectComponent
  },
  {
    name: 'age',
    addonFeature: `${limitationType}_age`,
    title: 'limitation.labels.ages',
    cb: adRequestSourceManager.getAges.bind(adRequestSourceManager),
    supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred'],
    singleSelect: false,
    itemSetting: defaultItemSetting(),
    searchPlaceholder: 'limitation.placeholders.searchAges',
    component: SelectComponent
  },
  {
    name: 'gender',
    addonFeature: `${limitationType}_gender`,
    title: 'limitation.labels.gender',
    cb: adRequestSourceManager.getGenders.bind(adRequestSourceManager),
    supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred'],
    singleSelect: false,
    itemSetting: {
      ...defaultItemSetting(),
      i18nPrefix: 'limitationOptions.labels.gender'
    },
    searchPlaceholder: 'limitation.placeholders.searchGenders',
    i18nPrefix: 'limitationOptions.labels.gender',
    component: SelectComponent
  },
  {
    name: 'segment',
    ignoreValidateOption: true,
    addonFeature: `${limitationType}_target_audience`,
    title: 'limitation.labels.segment',
    singleSelect: false,
    itemSetting: {
      ...defaultItemSetting(),
      type: ItemType.autoByGroupSetting,
      categorySelectable: false
    },
    searchPlaceholder: 'limitation.placeholders.searchSegment',
    cb: goSegments ?
      () => goSegments :
      adRequestSourceManager.getSegments.bind(adRequestSourceManager, advertiserId),
    supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred'],
    component: SelectComponent
  },
  {
    name: 'tenmaxAudience',
    addonFeature: `${limitationType}_tenmax_audience`,
    title: 'limitation.labels.tenmaxAudience',
    singleSelect: false,
    itemSetting: {
      ...defaultItemSetting(),
      type: ItemType.withCategory,
      categorySelectable: false
    },
    searchPlaceholder: 'limitation.placeholders.searchTenmaxSegments',
    cb: adRequestSourceManager.getTenmaxSegments.bind(adRequestSourceManager),
    supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred'],
    component: SelectComponent
  },
  {
    name: 'contentcat',
    addonFeature: `${limitationType}_content_category`,
    title: 'limitation.labels.articleCategory',
    singleSelect: false,
    itemSetting: {
      ...defaultItemSetting(),
      type: ItemType.withCategory,
      i18nPrefix: 'limitation.contentCategory'
    },
    searchPlaceholder: 'limitation.placeholders.searchContentCat',
    cb: adRequestSourceManager.getArticleCategories.bind(adRequestSourceManager),
    supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred'],
    i18nPrefix: 'limitation.contentCategory',
    postCommentPropsGetter: (limitation) => {
      const value = _.map(_.get(limitation['contentcat'], 'value', []), 'value');
      let dataSource;
      if (value.length === 1) {
        dataSource = async () => {
          const categories = await adRequestSourceManager.getArticleCategories();
          const mainCategory = value[0].split('-')[0];
          const targetMainCategory = categories.find(category => category.label === mainCategory);
          if (targetMainCategory) {
            if (targetMainCategory.value === value[0]) {
              return targetMainCategory.extra;
            } else {
              const options = targetMainCategory.options ? targetMainCategory.options : [];
              const option = options.find(option => option.value === value[0]);
              return option && option.extra ? option.extra : 0;
            }
          }
          return 0;
        };
      } else {
        dataSource = _.partial(limitationManager.categoriesUnionCount.bind(limitationManager), value);
      }
      const dataDecorator = (data) => i18n.t('limitation.contentCategory.countHint', { count: data });
      return {
        key: value.join(','),
        model: new DefaultAsyncSourceSpanModel(dataSource, dataDecorator)
      };
    },
    component: SelectComponent
  },
  {
    name: 'unicornkeywords',
    addonFeature: `${limitationType}_keyword`,
    title: 'limitation.labels.keyword',
    singleSelect: false,
    itemSetting: defaultItemSetting(),
    searchPlaceholder: '',
    supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred'],
    component: KeywordComponent
  },
  {
    name: 'adx',
    addonFeature: `${limitationType}_adexchange`,
    title: 'limitation.labels.adx',
    cb: adRequestSourceManager.getAdx.bind(adRequestSourceManager),
    supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred'],
    singleSelect: false,
    itemSetting: defaultItemSetting(),
    searchPlaceholder: 'limitation.placeholders.searchAdx',
    component: SelectComponent,
    postCommentPropsGetter: (limitation, limitationCanNotNullList) => {
      const needShow = limitationCanNotNullList ? limitationCanNotNullList.indexOf('adx') > -1 : false;
      return needShow ? {
        model: new DefaultAsyncSourceSpanModel(() => i18n.t('limitation.labels.adxCanNotNull'))
      } : undefined;
    }
  },
  {
    name: 'spaceType',
    addonFeature: `${limitationType}_spaceType`,
    title: 'limitation.labels.spaceType',
    cb: adRequestSourceManager.getSpaceTypes.bind(adRequestSourceManager),
    supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred'],
    singleSelect: false,
    itemSetting: {
      ...defaultItemSetting(),
      i18nPrefix: 'limitationOptions.labels.spaceType'
    },
    searchPlaceholder: 'limitation.placeholders.searchSpaceType',
    component: SelectComponent,
    i18nPrefix: 'limitationOptions.labels.spaceType'
  },
  {
    name: 'adspace',
    ignoreValidateOption: true,
    addonFeature: `${limitationType}_asiamax_space`,
    title: 'limitation.labels.space',
    cb: adRequestSourceManager.getSSPSpaces.bind(adRequestSourceManager),
    supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred'],
    singleSelect: false,
    itemSetting: {
      ...defaultItemSetting(),
      categorySelectable: false
    },
    searchPlaceholder: 'limitation.placeholders.searchSpace',
    customInputModelGetter: () => new SpaceCustomInputComponentModel(),
    component: SelectComponent
  },
  {
    name: 'contentlang',
    addonFeature: `${limitationType}_content_language`,
    title: 'limitation.labels.contentlang',
    singleSelect: false,
    itemSetting: {
      ...defaultItemSetting(),
      i18nPrefix: 'limitationOptions.labels.contentlang'
    },
    searchPlaceholder: 'limitation.placeholders.searchContentLang',
    cb: adRequestSourceManager.getContentLanguages.bind(adRequestSourceManager),
    supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred'],
    i18nPrefix: 'limitationOptions.labels.contentlang',
    component: SelectComponent
  },
  {
    name: 'adcat',
    addonFeature: `${limitationType}_space_category`,
    title: 'limitation.labels.tenmaxCategory',
    singleSelect: false,
    itemSetting: {
      ...defaultItemSetting(),
      i18nPrefix: 'tenmaxCategory.labels'
    },
    searchPlaceholder: 'limitation.placeholders.searchAdCat',
    cb: adRequestSourceManager.getTenmaxCategories.bind(adRequestSourceManager),
    supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred'],
    i18nPrefix: 'tenmaxCategory.labels',
    component: SelectComponent
  },
  {
    name: 'domain',
    ignoreValidateOption: true,
    addonFeature: `${limitationType}_domain`,
    title: 'limitation.labels.domain',
    singleSelect: false,
    itemSetting: defaultItemSetting(),
    searchPlaceholder: 'limitation.placeholders.searchDomains',
    cb: adRequestSourceManager.getDomains.bind(adRequestSourceManager),
    supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred'],
    customInputModelGetter: () => new DomainCustomInputComponentModel(),
    component: SelectComponent
  },
  {
    name: 'ppsLayoutId',
    addonFeature: 'creative_ppsLayoutId',
    title: 'limitation.labels.ppsLayoutId',
    singleSelect: false,
    itemSetting: {
      ...defaultItemSetting(),
      i18nPrefix: 'limitationOptions.labels.ppsLayoutId'
    },
    searchPlaceholder: 'limitation.placeholders.searchPPSLayoutId',
    cb: creativeManager.getPpsLayoutId.bind(creativeManager),
    supportOperates: ['include'],
    i18nPrefix: 'limitationOptions.labels.ppsLayoutId',
    component: SelectComponent,
    postCommentPropsGetter: (limitation, limitationCanNotNullList) => {
      const needShow = limitationCanNotNullList ? limitationCanNotNullList.indexOf('ppsLayoutId') > -1 : false;
      return needShow ? {
        model: new DefaultAsyncSourceSpanModel(() => i18n.t('limitation.labels.ppsLayoutIdCanNotNull'))
      } : undefined;
    }
  },
  {
    name: 'adFormat',
    ignoreAddonFeature: true,
    title: 'limitation.labels.adFormat',
    cb: () => ([{
      label: 'instl',
      value: 'instl'
    }]),
    i18nPrefix: 'adFormat',
    supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred'],
    singleSelect: false,
    itemSetting: {
      ...defaultItemSetting(),
      i18nPrefix: 'adFormat'
    },
    searchPlaceholder: 'limitation.placeholders.searchAdFormat',
    component: SelectComponent
  }
];

export const pilotTVInventorySetting = (
  limitationType: LIMITATION_TYPE,
  adRequestSourceManager: AdRequestSourceManager = defaultAdRequestSourceManager
) => [
  {
    name: DEFAULT_INVENTORY,
    ignoreAddonFeature: true,
    singleSelect: false,
    itemSetting: defaultItemSetting(),
    searchPlaceholder: '',
    component: SelectComponent
  },
  {
    name: 'geography',
    addonFeature: `${limitationType}_country`,
    title: 'limitation.labels.country',
    cb: adRequestSourceManager.getCountries.bind(adRequestSourceManager),
    singleSelect: false,
    itemSetting: defaultItemSetting(),
    searchPlaceholder: 'limitation.placeholders.searchCountryCity',
    component: SelectComponent,
    supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred'],
    postCommentPropsGetter: (limitation, limitationCanNotNullList) => {
      const needShow = limitationCanNotNullList ? limitationCanNotNullList.indexOf('geography') > -1 : false;
      return needShow ? {
        model: new DefaultAsyncSourceSpanModel(() => i18n.t('limitation.labels.geograthyCanNotNull'))
      } : undefined;
    }
  },
  {
    name: 'adx',
    addonFeature: `${limitationType}_adexchange`,
    title: 'limitation.labels.adx',
    cb: adRequestSourceManager.getAdx.bind(adRequestSourceManager),
    singleSelect: false,
    itemSetting: defaultItemSetting(),
    searchPlaceholder: 'limitation.placeholders.searchAdx',
    component: SelectComponent,
    supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred'],
    postCommentPropsGetter: (limitation, limitationCanNotNullList) => {
      const needShow = limitationCanNotNullList ? limitationCanNotNullList.indexOf('adx') > -1 : false;
      return needShow ? {
        model: new DefaultAsyncSourceSpanModel(() => i18n.t('limitation.labels.adxCanNotNull'))
      } : undefined;
    }
  },
  {
    name: 'adspace',
    ignoreValidateOption: true,
    addonFeature: `${limitationType}_asiamax_space`,
    title: 'limitation.labels.space',
    cb: adRequestSourceManager.getSSPSpaces.bind(adRequestSourceManager),
    singleSelect: false,
    itemSetting: {
      ...defaultItemSetting(),
      categorySelectable: false
    },
    searchPlaceholder: 'limitation.placeholders.searchSpace',
    customInputModelGetter: () => new SpaceCustomInputComponentModel(),
    component: SelectComponent,
    supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred']
  }
];

export const keywordInventorySetting = [
  {
    name: 'unicornkeyword1',
    title: 'limitation.labels.keyword1',
    singleSelect: false,
    itemSetting: defaultItemSetting(),
    searchPlaceholder: '',
    ignoreValidateOption: true,
    postCommentPropsGetter: (limitation) => {
      const keyword1 = _.map(_.get(limitation['unicornkeyword1'], 'value', []), 'value');
      const keyword2 = _.map(_.get(limitation['unicornkeyword2'], 'value', []), 'value');
      const keywordlanguage = _.map(_.get(limitation['unicornlanguage'], 'value', [{ label: 'zh', value: 'zh' }]), 'value')[0];
      const keywordUseAndFeature = limitation['unicornkeyword1'] && limitation['unicornkeyword2'];
      const dataSource = _.partial(limitationManager.keywordEstimate.bind(limitationManager), {
        firstQueryParameters: keyword1,
        secondQueryParameters: keyword2,
        language: keywordlanguage
      });
      const dataDecorator = (data) => renderKeywordSettingSearchResult(i18n.t('limitation.labels.unicornkeyword1'), data);
      return keywordUseAndFeature ? undefined : {
        key: `${keyword1.join(',')},${keyword2.join(',')},${keywordlanguage}`,
        model: new DefaultAsyncSourceSpanModel(dataSource, dataDecorator)
      };
    },
    component: KeywordComponent,
    supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred']
  },
  {
    name: 'unicornkeyword2',
    title: 'limitation.labels.keyword2',
    singleSelect: false,
    ignoreValidateOption: true,
    itemSetting: defaultItemSetting(),
    searchPlaceholder: '',
    preCommentPropsGetter: (limitation) => {
      const keywordUseAndFeature = limitation['unicornkeyword1'] && limitation['unicornkeyword2'];
      const dataSource = () => keywordUseAndFeature ? i18n.t('limitation.labels.and') : '';
      return keywordUseAndFeature ? {
        key: dataSource(),
        model: new DefaultAsyncSourceSpanModel(dataSource)
      } : undefined;
    },
    postCommentPropsGetter: (limitation) => {
      const keyword1 = _.map(_.get(limitation['unicornkeyword1'], 'value', []), 'value');
      const keyword2 = _.map(_.get(limitation['unicornkeyword2'], 'value', []), 'value');
      const keywordlanguage = _.map(_.get(limitation['unicornlanguage'], 'value', [{ label: 'zh', value: 'zh' }]), 'value')[0];
      const keywordUseAndFeature = limitation['unicornkeyword1'] && limitation['unicornkeyword2'];
      const dataSource = _.partial(limitationManager.keywordEstimate.bind(limitationManager), {
        firstQueryParameters: keyword1,
        secondQueryParameters: keyword2,
        language: keywordlanguage
      });
      const name = keywordUseAndFeature ?
        `${i18n.t('limitation.labels.unicornkeyword1')} ${i18n.t('limitation.labels.unicornkeyword2')}` :
        i18n.t('limitation.labels.unicornkeyword2');
      const dataDecorator = (data) => renderKeywordSettingSearchResult(name, data);
      return {
        key: `${keyword1.join(',')},${keyword2.join(',')},${keywordlanguage}`,
        model: new DefaultAsyncSourceSpanModel(dataSource, dataDecorator)
      };
    },
    component: KeywordComponent,
    supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred']
  }
];

export const inventoryNameList = (limitationType: LIMITATION_TYPE) => {
  return defaultInventorySetting(0, limitationType).map(inventory => inventory.name);
};

export const adSetInventorySetting = (
  operate: string,
  optimizationGoal: GoCampaignOptimizationGoal | undefined,
  fbCountries: SelectOptions[],
  goSegments: SelectOptions[]
) => [
  {
    name: DEFAULT_INVENTORY,
    ignoreAddonFeature: true,
    singleSelect: false,
    itemSetting: defaultItemSetting(),
    searchPlaceholder: '',
    component: SelectComponent,
    supportOperates: ['include', 'exclude']
  },
  {
    name: 'geo_locations',
    ignoreAddonFeature: true,
    title: 'limitation.labels.country',
    cb: () => fbCountries,
    singleSelect: false,
    itemSetting: defaultItemSetting(),
    searchPlaceholder: 'limitation.placeholders.searchCountryCity',
    component: SelectComponent,
    supportOperates: ['include', 'exclude'],
    i18nPrefix: 'geoLocations.fb'
  },
  {
    name: 'publisher_platforms',
    ignoreAddonFeature: true,
    title: 'targeting.fb.platformsTitle',
    cb: () => ([
      {
        label: 'Facebook',
        value: 'facebook'
      },
      {
        label: 'Instagram',
        value: 'instagram'
      },
      {
        label: 'Audience Network',
        value: 'audience_network'
      }
    ]),
    singleSelect: false,
    itemSetting: defaultItemSetting(),
    searchPlaceholder: 'targeting.fb.placeholders.search_publisher_platforms',
    validator: (_1, value: SelectOptions[]) => {
      if (value.length === 1 && value[0].value === 'audience_network') {
        return i18n.t('targeting.fb.errors.audience_network');
      }
    },
    component: SelectComponent,
    supportOperates: ['include']
  },
  {
    name: 'segment',
    ignoreAddonFeature: true,
    ignoreValidateOption: true,
    title: 'limitation.labels.segment',
    singleSelect: false,
    itemSetting: {
      ...defaultItemSetting(),
      type: ItemType.autoByGroupSetting,
      categorySelectable: false
    },
    searchPlaceholder: 'limitation.placeholders.searchSegment',
    cb: () => goSegments,
    component: SelectComponent,
    i18nPrefix: 'targeting.fb.segment',
    supportOperates: ['include', 'exclude']
  },
  {
    name: 'user_os',
    showWithLimitation: ['user_device'],
    requiredOperate: optimizationGoal === GoCampaignOptimizationGoal.OFFSITE_CONVERSIONS ? ['include'] : undefined,
    ignoreAddonFeature: true,
    disable: optimizationGoal === GoCampaignOptimizationGoal.OFFSITE_CONVERSIONS && operate === 'edit',
    title: 'limitation.labels.os',
    cb: () => ([{ label: 'Android', value: 'Android' }, { label: 'iOS', value: 'iOS' }]),
    singleSelect: optimizationGoal === GoCampaignOptimizationGoal.OFFSITE_CONVERSIONS,
    itemSetting: defaultItemSetting(),
    searchPlaceholder: 'limitation.placeholders.searchOs',
    component: SelectComponent,
    validator: (operate, value, limitationValue) => {
      if (optimizationGoal === GoCampaignOptimizationGoal.OFFSITE_CONVERSIONS && (!value || value.length === 0)) {
        return i18n.t('targeting.fb.errors.user_os');
      }
      const userDevice = limitationValue[operate].find(ta => ta.type === 'user_device');
      if (userDevice && userDevice.value.length > 0 && (!value || value.length === 0)) {
        return i18n.t('targeting.fb.errors.user_device_need_os');
      }
    },
    supportOperates: ['include']
  },
  {
    name: 'user_device',
    ignoreAddonFeature: true,
    title: 'limitation.labels.device',
    singleSelect: false,
    itemSetting: {
      ...defaultItemSetting(),
      i18nPrefix: 'limitationOptions.labels.device'
    },
    i18nPrefix: 'limitationOptions.labels.device',
    searchPlaceholder: 'limitation.placeholders.searchDevice',
    cb: () => ([{ label: 'Smartphone', value: 'Smartphone' }, { label: 'Tablet', value: 'Tablet' }]),
    component: SelectComponent,
    supportOperates: ['include']
  }
];

export const adGroupInventorySetting = (
  operate: string,
  // optimizationGoal: TiktokOptimizationGoal | undefined,
  objective: string,
  tiktokCountires: SelectOptions[],
  goSegments: SelectOptions[]
) => [
  {
    name: DEFAULT_INVENTORY,
    ignoreAddonFeature: true,
    singleSelect: false,
    itemSetting: defaultItemSetting(),
    searchPlaceholder: '',
    component: SelectComponent,
    supportOperates: ['include', 'exclude']
  },
  {
    name: 'tiktok_location',
    ignoreAddonFeature: true,
    title: 'limitation.labels.country',
    cb: () => tiktokCountires,
    singleSelect: false,
    itemSetting: defaultItemSetting(),
    searchPlaceholder: 'limitation.placeholders.searchCountryProvince',
    i18nPrefix: 'geoLocations.tiktok',
    component: SelectComponent,
    supportOperates: ['include']
  },
  {
    name: 'tiktok_placement',
    disable: operate === 'edit',
    disableReason: 'limitation.hints.tiktokPlacementCannotModify',
    ignoreAddonFeature: true,
    ignoreValidateOption: true,
    title: 'targeting.tiktok.placementsTitle',
    cb: () => {
      const placements = createSelectOptions(Object.values(TiktokPlacementMapping), 'targeting.tiktok.placements.', value => value.toLowerCase());
      if (objective === TiktokObjective.REACH) {
        return placements.filter(placement => placement.value === TiktokPlacementMapping.TIKTOK);
      } else {
        return placements;
      }
    },
    singleSelect: false,
    itemSetting: defaultItemSetting(),
    searchPlaceholder: 'targeting.tiktok.placeholders.search_placements',
    i18nPrefix: 'targeting.tiktok.placements',
    component: SelectComponent,
    supportOperates: ['include'],
    validator: (_, value) => {
      if (!value || value.length === 0) {
        return;
      }
      if (objective === TiktokObjective.REACH) {
        const values = value.map(limit => limit.value);
        if (values.length > 1 || !values.includes(TiktokPlacementMapping.TIKTOK)) {
          return i18n.t('targeting.tiktok.errors.reach_placement_only_tiktok');
        }
      }
    }
  },
  {
    name: 'tiktok_os',
    ignoreAddonFeature: true,
    title: 'limitation.labels.os',
    cb: () => createSelectOptions(['ANDROID', 'IOS'], 'targeting.tiktok.os.', value => value.toLowerCase()),
    singleSelect: true,
    itemSetting: defaultItemSetting(),
    searchPlaceholder: 'limitation.placeholders.searchOs',
    i18nPrefix: 'targeting.tiktok.os',
    component: SelectComponent,
    supportOperates: ['include']
  },
  {
    name: 'tiktok_audience',
    ignoreAddonFeature: true,
    ignoreValidateOption: true,
    title: 'limitation.labels.segment',
    singleSelect: false,
    itemSetting: {
      ...defaultItemSetting(),
      type: ItemType.autoByGroupSetting,
      categorySelectable: false
    },
    searchPlaceholder: 'limitation.placeholders.searchSegment',
    cb: () => goSegments,
    i18nPrefix: 'targeting.tiktok.segment',
    component: SelectComponent,
    supportOperates: ['include', 'exclude']
  }
];

export const retailMediaInventorySetting = (
  advertiserId: number,
  limitationType: LIMITATION_TYPE,
  goSegments?: SelectOptions[],
  retailType?: string,
  adRequestSourceManager: AdRequestSourceManager = defaultAdRequestSourceManager
) => {
  const defautlSetting: any = defaultInventorySetting(advertiserId, limitationType, goSegments).filter(setting => setting.name !== 'adx');
  defautlSetting.push({
    name: 'searchKeywords',
    ignoreAddonFeature: true,
    ignoreValidateOption: true,
    title: 'limitation.labels.searchKeyword',
    singleSelect: false,
    itemSetting: defaultItemSetting(),
    searchPlaceholder: '',
    component: SearchKeywordsComponent,
    supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred'],
    extra: {
      retailType
    }
  });
  defautlSetting.push({
    name: 'adspace',
    ignoreAddonFeature: true,
    title: 'limitation.labels.placement',
    cb: async () => {
      const spaces = await adRequestSourceManager.getSSPSpaces();
      return spaces.filter(space => _.get(space, 'isGroup', false));
    },
    singleSelect: false,
    itemSetting: {
      ...defaultItemSetting(),
      categorySelectable: false
    },
    searchPlaceholder: 'limitation.placeholders.searchPlacement',
    component: SelectComponent,
    supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred']
  });
  return defautlSetting;
};

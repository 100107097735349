import React from 'react';
import Select from 'components/common/Select/Select';
import styles from './dayparts.module.scss';
import i18n from 'i18n';
import _ from 'lodash';
import { SelectOptions } from 'components/common/commonType';

export function getDayOfWeekLabelByValue (value) {
  switch (value) {
    case 0:
      return '';
    case 1:
      return i18n.t('daypart.labels.monday');
    case 2:
      return i18n.t('daypart.labels.tuesday');
    case 3:
      return i18n.t('daypart.labels.wednesday');
    case 4:
      return i18n.t('daypart.labels.thursday');
    case 5:
      return i18n.t('daypart.labels.friday');
    case 6:
      return i18n.t('daypart.labels.saturday');
    default:
      return i18n.t('daypart.labels.sunday');
  }
}

export function getInitDaypart () {
  return {
    '1' : Array.from({ length: 24 }, (_, index) => index),
    '2' : Array.from({ length: 24 }, (_, index) => index),
    '3' : Array.from({ length: 24 }, (_, index) => index),
    '4' : Array.from({ length: 24 }, (_, index) => index),
    '5' : Array.from({ length: 24 }, (_, index) => index),
    '6' : Array.from({ length: 24 }, (_, index) => index),
    '7' : Array.from({ length: 24 }, (_, index) => index)
  };
}

export class Dayparts extends React.Component<any> {

  state = {dayPart: {
    ...getInitDaypart(),
    enabled: '1'
  }, option: undefined};

  componentDidMount () {
    let dayPart = this.props.form.values[this.props.field.name];
    if (dayPart && Object.keys(dayPart).length > 1) {
      this.setState({ dayPart: dayPart });
    } else {
      this.props.form.setFieldValue(this.props.field.name, this.state.dayPart);
    }
  }

  shouldComponentUpdate (_1, nextState) {
    if (nextState.dayPart === this.state.dayPart) {
      return false;
    }

    return true;
  }

  onCellClick = (dayValue, hourValue) => {
    let hours = this.state.dayPart[dayValue.toString()];
    if (!hours) {
      return;
    }

    let indexOfValue = hours.indexOf(hourValue);
    if (indexOfValue === -1) {
      hours.push(hourValue);
    } else {
      hours.splice(indexOfValue, 1);
    }

    let newDaypart = {
      ...this.state.dayPart,
      [dayValue.toString()]: hours
    };

    this.setState({ dayPart: newDaypart }, () => {
      this.props.form.setFieldValue(this.props.field.name, newDaypart);
    });
  }

  onHourColumnClick = (hourValue: number) => {
    let dayPart = this.state.dayPart;
    let hasHourNotSelected = false;
    for (let dayValue = 1; dayValue < 8; ++dayValue) {
      if (dayPart[dayValue.toString()].indexOf(hourValue) === -1) {
        hasHourNotSelected = true;
        break;
      }
    }

    let newDaypart;
    if (hasHourNotSelected) {
      const addHour = (hourArr: number[], hour: number) => {
        const hours = hourArr ? hourArr.concat(hour) : [hour];
        return _.uniq(hours).sort((a, b) => {
          return a - b;
        });
      };
      newDaypart = {
        '1': addHour(dayPart['1'], hourValue),
        '2': addHour(dayPart['2'], hourValue),
        '3': addHour(dayPart['3'], hourValue),
        '4': addHour(dayPart['4'], hourValue),
        '5': addHour(dayPart['5'], hourValue),
        '6': addHour(dayPart['6'], hourValue),
        '7': addHour(dayPart['7'], hourValue),
        enabled: '1'
      };
    } else {
      newDaypart = {
        '1': _.without(dayPart['1'], hourValue),
        '2': _.without(dayPart['2'], hourValue),
        '3': _.without(dayPart['3'], hourValue),
        '4': _.without(dayPart['4'], hourValue),
        '5': _.without(dayPart['5'], hourValue),
        '6': _.without(dayPart['6'], hourValue),
        '7': _.without(dayPart['7'], hourValue),
        enabled: '1'
      };
    }

    this.setState({ dayPart: newDaypart }, () => {
      this.props.form.setFieldValue(this.props.field.name, newDaypart);
    });
  }

  onDayOfWeekClick = (dayValue) => {
    let hours = this.state.dayPart[dayValue.toString()];
    let newHours = hours && hours.length === 24 ? [] : Array.from({ length: 24 }, (_, index) => index);
    let newDaypart = {
      ...this.state.dayPart,
      [dayValue.toString()]: newHours
    };

    this.setState({ dayPart: newDaypart }, () => {
      this.props.form.setFieldValue(this.props.field.name, newDaypart);
    });
  }

  onDayPartOptionsChanged = (event) => {
    let newDaypart = {
      '1': [],
      '2': [],
      '3': [],
      '4': [],
      '5': [],
      '6': [],
      '7': [],
      enabled: '1'
    };

    event.sets.forEach(set => {
      set.days.forEach(day => {
        newDaypart[day.toString()] = Array.from(set.hours);
      });
    });

    this.setState({ dayPart: newDaypart, option: event.value }, () => {
      this.props.form.setFieldValue(this.props.field.name, newDaypart);
    });
  }

  renderDayOfWeek = () => {
    return Array.from({ length: 8 }, (_, index) => {
      const clickFunc = () => this.onDayOfWeekClick(index);
      return (
        <div key={`dayOfWeek-${index}`} className={styles.dayOfWeek} onClick={clickFunc}>
          {getDayOfWeekLabelByValue(index)}
        </div>
      );
    });
  }

  renderContent = () => {
    return Array.from({ length: 8 }, (_, rowIndex) => {
      return (
        <div key={`cell-row${rowIndex}`} className={styles.row}>
          {
            rowIndex === 0 ?
              this.renderHeaders() :
              Array.from({ length: 24 }, (_, columnIndex) => {
                let className;
                let hours = this.state.dayPart[rowIndex.toString()];
                hours && hours.indexOf(columnIndex) >= 0 ? className = [styles.selected, styles.cell].join(' ') : className = styles.cell;
                const clickFunc = () => this.onCellClick(rowIndex, columnIndex);
                return (
                  <div key={`cell-${rowIndex}${columnIndex}`} className={className} onClick={clickFunc}/>
                );
              })
          }
        </div>
      );
    });
  }

  renderHeaders = () => {
    return Array.from({ length: 24 }, (_, index) => {
      const onClick = () => this.onHourColumnClick(index);
      return (
        <div key={`header-${index}`} className={[styles.cell, styles.header].join(' ')} onClick={onClick}>
          {index}
        </div>
      );
    });
  }

  get daypartOptions (): SelectOptions[] {
    return [
      // { label : "選一個時段", value : "0", sets:[ {days:[0,1,2,3,4,5,6], hours:[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]}]},
      { label: i18n.t('daypart.labels.allDay'), value: '1', sets: [{ days: [1, 2, 3, 4, 5, 6, 7], hours: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23] }] },
      { label: i18n.t('daypart.labels.morning'), value: '4', sets: [{ days: [1, 2, 3, 4, 5, 6, 7], hours: [6, 7, 8, 9, 10, 11] }] },
      { label: i18n.t('daypart.labels.afternoon'), value: '2', sets: [{ days: [1, 2, 3, 4, 5, 6, 7], hours: [12, 13, 14, 15, 16, 17] }] },
      { label: i18n.t('daypart.labels.evening'), value: '3', sets: [{ days: [1, 2, 3, 4, 5, 6, 7], hours: [18, 19, 20, 21, 22, 23] }] },
      { label: i18n.t('daypart.labels.weekday'), value: '5', sets: [{ days: [1, 2, 3, 4, 5], hours: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23] }] },
      { label: i18n.t('daypart.labels.weekend'), value: '6', sets: [{ days: [6, 7], hours: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23] }] },
      { label: i18n.t('daypart.labels.conversionOptimize'), value: '7', sets: [{ days: [6, 7], hours: [0, 8, 9, 10, 19, 20, 21, 22, 23] }, { days: [1, 2, 3, 4, 5], hours: [0, 8, 9, 10, 12, 13, 14, 15, 16, 17, 18, 19, 23] }] },
      { label: i18n.t('daypart.labels.midnightDisabled'), value: '8', sets: [{ days: [1, 2, 3, 4, 5, 6, 7], hours: [0, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23] }] },
      { label: i18n.t('daypart.labels.mobileOptimize'), value: '9', sets: [{ days: [6, 7], hours: [0, 1, 6, 7, 8, 9, 10, 11, 13, 15, 16, 17, 19, 23] }, { days: [1, 2, 3, 4, 5], hours: [0, 1, 6, 7, 8, 9, 11, 12, 13, 14, 16, 17, 19, 20, 23] }] },
      { label: i18n.t('daypart.labels.pcOptimize'), value: '10', sets: [{ days: [6, 7], hours: [0, 1, 8, 9, 10, 12, 13, 14, 15, 16, 19, 20, 21, 22, 23] }, { days: [1, 2, 3, 4, 5], hours: [0, 1, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 23] }] }
    ];
  }

  render () {
    return (
      <div>
        <Select
          className={styles.daypartOptions}
          options={this.daypartOptions}
          name='dayPart'
          value={_.filter(this.daypartOptions, option => option.value === this.state.option)}
          placeholder={i18n.t('daypart.labels.optionsPlaceholder')}
          onChange={this.onDayPartOptionsChanged}
        />
        <div className={styles.dayPart}>
          <div className={styles.dayOfWeekColumn}>
            {this.renderDayOfWeek()}
          </div>
          <div>
            <div className={styles.hint}>
              <label>{i18n.t('daypart.labels.am')}</label>
              <label>{i18n.t('daypart.labels.pm')}</label>
            </div>
            {this.renderContent()}
          </div>
        </div>
      </div>
    );
  }
}

import React from 'react';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import _ from 'lodash';
import 'react-datepicker/dist/react-datepicker.css';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import styles from './datePicker.module.scss';
import classnames from 'classnames/bind';

export type DatePickerProps = {
  date: string;
  format?: string;
  className?: string;
  minDate?: string;
  maxDate?: string;
  popOverClassName?: string;
  showTimePicker: boolean,
  timeInterval: 30 | 60,
  disabled: boolean,
  parseDate?: (date: string) => Date;
  onChange: (date: string | undefined) => void;
  formatDate?: (date: Date, locale?: string) => string;
  onPopOverClosed?: () => void;
  hasError: boolean;
};

export const defaultFormat = 'YYYY-MM-DD HH:mm:ss';
const ignoreTimeZoneFormat = 'YYYY-MM-DD HH:mm:ss';

export class DatePicker extends React.Component<DatePickerProps> {
  static defaultProps = {
    format: defaultFormat,
    onChange: _.noop,
    showTimePicker: true,
    timeInterval: 60,
    disabled: false,
    hasError: false
  };
  cx: any = classnames.bind(styles);

  parseDate = (s: string) => {
    if (this.props.parseDate) {
      return this.props.parseDate(s);
    }
    return new Date(moment(s, this.props.format).format(ignoreTimeZoneFormat));
  }

  formatDate = (date: Date) => {
    if (this.props.formatDate) {
      return this.props.formatDate(date);
    }
    const clientTimeZone = moment.tz.guess();
    return moment.tz(date, clientTimeZone).format(this.props.format);
  }

  formatDateInternal = (date: Date) => {
    const clientTimeZone = moment.tz.guess();
    const format = this.props.showTimePicker ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD';
    return moment.tz(date, clientTimeZone).format(format);
  }

  handleChange = (date: Date) => {
    this.props.onChange(this.formatDate(date));
  }

  onDateChange = (date: Date) => {
    const updateStartMoment = moment(date).startOf('minute');
    this.handleChange(updateStartMoment.toDate());
  }

  onClose = () => {
    this.props.onPopOverClosed && this.props.onPopOverClosed();
  }

  filterTime = time => {
    if (this.props.disabled) {
      return false;
    }
    const selectedDate = this.parseDate(this.props.date);
    const minTime = this.props.minDate ? this.parseDate(this.props.minDate) : undefined;
    const maxTime = this.props.maxDate ? this.parseDate(this.props.maxDate) : undefined;
    const timeDate = new Date(time);
    selectedDate.setHours(timeDate.getHours());
    selectedDate.setMinutes(timeDate.getMinutes());
    const biggerThanMin = minTime ? minTime.getTime() <= selectedDate.getTime() : true;
    const smallerThanMax = maxTime ? maxTime.getTime() >= selectedDate.getTime() : true;
    return biggerThanMin && smallerThanMax;
  }

  render () {
    const {
      date: dateString,
      showTimePicker,
      timeInterval,
      minDate,
      maxDate,
      popOverClassName,
      className,
      hasError
    } = this.props;
    const date = this.parseDate(dateString);
    const timePickerProps = showTimePicker ? {
      timeFormat: 'HH:mm',
      timeIntervals: timeInterval,
      showTimeSelect: true,
      filterTime: this.filterTime
    } : undefined;
    const basicDatePickerProps = {
      date,
      disabledKeyboardNavigation: true,
      showMonthDropdown: true,
      showYearDropdown: true,
      inline: true
    };
    const dateMinMax = this.props.disabled ? {
      minDate: date,
      maxDate: moment(date).subtract(1, 'day').toDate()
    } : {
      minDate: minDate ? this.parseDate(minDate) : undefined,
      maxDate: maxDate ? this.parseDate(maxDate) : undefined
    };
    const inputClass = this.cx('datePickerInput', className, {
      error: hasError
    });
    return (
      <OverlayTrigger
        placement='bottom'
        trigger='click'
        rootClose
        onExited={this.onClose}
        overlay={
          <Popover id='popover-basic' className={popOverClassName}>
            <Popover.Content className={styles.datePickerContainer}>
              <ReactDatePicker
                selected={date}
                onChange={this.onDateChange}
                {...basicDatePickerProps}
                {...dateMinMax}
                {...timePickerProps}
              />
            </Popover.Content>
          </Popover>
        }
      >
        <span className={`form-control ${inputClass}`}>
          {`${this.formatDateInternal(this.parseDate(dateString))}`}
        </span>
      </OverlayTrigger>
    );
  }
}

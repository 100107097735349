import React from 'react';
import { StickableBootstrapTable } from 'components/common/Table/StickableBootstrapTable';
import { GoSegmentListColumns, useGoSegmentListModel } from './GoSegmentListModel';
import i18n from 'i18n';
import styles from './goSegmentList.module.scss';
import { SearchBar } from 'components/common/SearchBar';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { Link } from 'react-router-dom';
import { Modal } from 'components/common/Modal/Modal';
import paginationFactory from 'react-bootstrap-table2-paginator';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { SessionStorageHelper, SessionStorageItemKeys } from 'helper/StorageHelper';
import { TablePlaceholder } from 'components/TablePlaceholder/TablePlaceholder';
import { notRoles } from 'core/permission/PermissionDSL';
import { RoleNames } from 'core';

export type GoSegmentListProps = {
  isAdmin: boolean,
  agencyId: number | string | undefined,
  advertiserId: number | string | undefined,
  canCreateSegment: boolean
};

export const GoSegmentList: React.FC<GoSegmentListProps> = ({
  isAdmin,
  agencyId,
  advertiserId,
  canCreateSegment
}) => {

  const {
    sql,
    loading,
    filteredList,
    columns,
    searchString,
    onSqlModalClose,
    deleteGoSegment,
    setSearchString,
    onDeleteModalClose
  } = useGoSegmentListModel(isAdmin, agencyId, advertiserId);

  const renderSysAdminPlaceholder = () => {
    if (SessionStorageHelper.getNumberItem(SessionStorageItemKeys.AGENCY)) {
      return <TablePlaceholder placeholder={i18n.t('goSegmentList.hints.noData')}/>;
    }
    return <TablePlaceholder placeholder={i18n.t('segmentList.labels.chooseAgency')}/>;
  };

  const renderListNoDataIndication = () => {
    return (
      <PermissionChecker
        permissionAware={notRoles(RoleNames.sysAdmin)}
        renderOtherwise={renderSysAdminPlaceholder}
      >
        <TablePlaceholder placeholder={i18n.t('goSegmentList.hints.noData')}/>
      </PermissionChecker>
    );
  };

  return (
    <div className={styles.goSegmentList}>
      {loading && <LoadingIndicator />}
      {deleteGoSegment &&
        <Modal
          title={i18n.t('goSegmentList.labels.deleteModalTile')}
          secondaryButton={{ title: i18n.t('common.buttons.cancel'), callback: onDeleteModalClose }}
          primaryButton={{ title: i18n.t('common.buttons.delete'), callback: deleteGoSegment }}
          dismiss={onDeleteModalClose}
        >
          {i18n.t('goSegmentList.labels.deleteModalContent')}
        </Modal>
      }
      {sql &&
        <Modal
          title={i18n.t('goSegmentList.labels.sqlModalTile')}
          primaryButton={{ title: i18n.t('common.buttons.confirm'), callback: onSqlModalClose }}
          dismiss={onSqlModalClose}
        >
          <div style={{ whiteSpace: 'pre-line' }}>
            {sql}
          </div>
        </Modal>
      }
      <div className={styles.filterArea}>
        {canCreateSegment &&
          <Link
            to={'segments/new'}
            className={`btn btn-tertiary btn-sm ${styles.createBtn}`}
          >
            {i18n.t('goSegmentList.buttons.create')}
          </Link>
        }
        <SearchBar
          model={{
            placeholder: i18n.t('goSegmentList.placeholders.searchbar'),
            search: setSearchString,
            defaultValue: searchString
          }}
        />
      </div>
      <div className={styles.contentArea}>
        <StickableBootstrapTable
          stickFirstColumn
          stickLastColumn
          keyField={GoSegmentListColumns.ID}
          data={filteredList}
          columns={columns}
          noDataIndication={renderListNoDataIndication}
          defaultSorted={[{
            dataField: GoSegmentListColumns.ID,
            order: 'desc'
          }]}
          pagination={paginationFactory({
            hideSizePerPage: true,
            sizePerPage: 10
          })}
        />
      </div>
    </div>
  );
};

import client from './RestClient';
import { CampaignGroupChannel, FbObjective, GoCampaignGroupObjective } from 'core/campaignGroup/CampaignGroup';
import { SelectOptions } from 'components/common/commonType';
import _ from 'lodash';

export type CampaignGroupUpdateRequest = {
  name: string;
  budget?: number;
  autoOptimise: boolean;
  fb?: any;
  tiktok?: any;
};

export type CampaignGroupCreateRequest = CampaignGroupUpdateRequest & {
  adsOrderID: number;
  channel: CampaignGroupChannel;
};

export interface CampaignGroupWebService {
  createCampaignGroup (campaignGroup: CampaignGroupCreateRequest): Promise<number>;
  updateCampaignGroup (groupId: number | string, campaignGroup: CampaignGroupUpdateRequest): Promise<void>;
  getCampaignGroup (groupId: number | string): Promise<any>;
  getCampaignGroups (orderId: number | string): Promise<Array<any>>;
  deleteCampaignGroup (groupId: number): Promise<void>;
  updateCampaignGroupState (groupIds: (number | string)[], state: 'activate' | 'deactivate'): Promise<void>;
  getCampaignGroupOptions (): Promise<SelectOptions[]>;
  getOrderNumber (groupId: string | number): Promise<string>;
  // refreshFbAdSets (groupId: number | string): Promise<void>;
  getFBCampaignMinBudget (groupId: number | string): Promise<number>;
  getAdSetLifetimeBudgetOfCampaign (groupId: number | string): Promise<{[key: string]: number}>;
}

const ODAXObjecitveMap = {
  [GoCampaignGroupObjective.AwarenessObjective.REACH]: FbObjective.OUTCOME_AWARENESS,
  [GoCampaignGroupObjective.ConsiderationObjective.LINK_CLICKS]: FbObjective.OUTCOME_TRAFFIC,
  [GoCampaignGroupObjective.ConversionObjective.CONVERSIONS]: FbObjective.OUTCOME_SALES
};

const wrapCampaignGroup = json => {
  const transToODAXObjective = objective => ODAXObjecitveMap[objective] ? ODAXObjecitveMap[objective] : objective;
  let fb = json.fb;
  if (fb) {
    fb = {
      ...fb,
      objective: transToODAXObjective(fb.objective)
    };
  }
  return {
    ...json,
    fb,
    budget: json.go_lifetime_budget,
    objective: fb ? transToODAXObjective(json.objective) : json.objective
  };
};

export class RestfulCampaignGroupWebService implements CampaignGroupWebService {
  restClient: any;

  constructor (restClient: any = client) {
    this.restClient = restClient;
  }

  async createCampaignGroup (campaignGroup: CampaignGroupCreateRequest): Promise<number> {
    const response = await this.restClient.post('/v2/campaign-groups', campaignGroup);
    return response.data.groupId;
  }
  async updateCampaignGroup (groupId: number | string, campaignGroup: CampaignGroupUpdateRequest): Promise<void> {
    return this.restClient.put(`/v2/campaign-groups/${groupId}`, campaignGroup);
  }
  async getCampaignGroup (groupId: number | string): Promise<any> {
    const response = await this.restClient.get(`/v2/campaign-groups/${groupId}`);
    return wrapCampaignGroup(response.data);
  }
  async getCampaignGroups (orderId: number | string): Promise<any> {
    const response = await this.restClient.get(`/v2/adsOrders/${orderId}/campaign-groups`);
    return _.get(response.data, 'records', []).map(wrapCampaignGroup);
  }
  async deleteCampaignGroup (groupId: number): Promise<void> {
    await this.restClient.delete(`/v2/campaign-groups/${groupId}`);
  }
  async updateCampaignGroupState (groupIds: (number | string)[], state: 'activate' | 'deactivate'): Promise<void> {
    const groupParameters = groupIds.join(',');
    await this.restClient.put(`/v2/campaign-groups/state/${state}?groupIds=${groupParameters}`);
  }
  async getCampaignGroupOptions (): Promise<SelectOptions[]> {
    const response = await this.restClient.get(`/v2/campaign-groups/options`);
    return response.data.records;
  }
  async getOrderNumber (campaignGroupId: string | number): Promise<string> {
    const response = await this.restClient.get(`/v2/campaign-groups/${campaignGroupId}/orderNumber`);
    return response.data;
  }
  // async refreshFbAdSets (groupId: number | string): Promise<void> {
  //   await this.restClient.post(`/v2/campaign-groups/sync/${groupId}`);
  // }
  async getFBCampaignMinBudget (groupId: number | string): Promise<number> {
    const response = await this.restClient.get(`/v2/campaign-groups/${groupId}/min-budget`);
    return response.data.budget;
  }
  async getAdSetLifetimeBudgetOfCampaign (groupId: number | string): Promise<{[key: string]: number}> {
    const response = await this.restClient.get(`/v2/campaign-groups/${groupId}/campaigns/lifetime-budget`);
    return response.data;
  }
}

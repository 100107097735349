
import { CreativeListModel, DefaultCreativeListModel } from 'containers/CreativeList/CreativeListModel';
import { AddonFeatureManager, AgencyManager, DefaultAgencyManager } from 'core';
import { SessionStorageHelper, SessionStorageItemKeys } from 'helper/StorageHelper';
import { SelectOptions } from 'components/common/commonType';
import { UpdateEventListener, FireableUpdateEventListener } from 'utils/UpdateEventListener';

export interface CreativesHomeModel {
  readonly event: UpdateEventListener<CreativesHomeModel>;
  readonly advertiserId?: number;
  readonly advertisers: Array<SelectOptions>;
  readonly state: CreativesHomeModelState;
  init (): Promise<void>;
  setAdvertiserId (advertiserId: number | undefined): void;
  setAgencyId? (agencyId?: number): void;
  getCreativeListModel (searchString: string | null, onSearchChange: (searchString: string) => void): CreativeListModel;
}

export type CreativesHomeProps = {
  readonly model: CreativesHomeModel;
};

export type CreativesHomeModelState = {
  readonly advertiserId?: number;
  readonly agencyId?: number;
  readonly agencies?: Array<SelectOptions>;
  readonly loading: boolean;
};

export class DefaultCreativesHomeModel implements CreativesHomeModel {

  advertiserId?: number;
  advertisers: Array<SelectOptions>;
  creativeListModel?: CreativeListModel;
  addonFeatureManager: AddonFeatureManager;
  event: FireableUpdateEventListener<CreativesHomeModel>;
  loading: boolean;

  constructor (
    advertisers: Array<SelectOptions>,
    addonFeatureManager: AddonFeatureManager
  ) {
    this.event = new FireableUpdateEventListener<CreativesHomeModel>();
    this.advertiserId = SessionStorageHelper.getNumberItem(SessionStorageItemKeys.ADVERTISER);
    this.advertisers = advertisers;
    this.addonFeatureManager = addonFeatureManager;
    this.loading = false;
  }

  async init () {}

  get state (): CreativesHomeModelState {
    return {
      loading: this.loading,
      advertiserId: this.advertiserId
    };
  }

  setAdvertiserId (advertiserId: number | undefined) {
    if (this.advertiserId === advertiserId) {
      return;
    }

    this.advertiserId = advertiserId;
    this.creativeListModel && this.creativeListModel.changeAdvertiser(this.advertiserId);
  }

  getCreativeListModel (searchString: string | null, onSearchChange: (searchString: string) => void): CreativeListModel {
    if (this.creativeListModel) {
      return this.creativeListModel;
    }

    this.creativeListModel = new DefaultCreativeListModel(searchString, onSearchChange, this.addonFeatureManager);
    return this.creativeListModel;
  }

  updateState (loading: boolean) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}

export class SysAdminCreativesHomeModel extends DefaultCreativesHomeModel {

  agencyManager: AgencyManager;
  agencies: Array<SelectOptions>;
  constructor (
    advertisers: Array<SelectOptions>,
    addonFeatureManager: AddonFeatureManager,
    private onAgencyChange: (agencyId) => void,
    agencyManager: AgencyManager = new DefaultAgencyManager()
  ) {
    super(advertisers, addonFeatureManager);
    this.agencies = [];
    this.agencyManager = agencyManager;
    this.loading = true;
  }

  get state () {
    return {
      loading: this.loading,
      agencyId: SessionStorageHelper.getNumberItem(SessionStorageItemKeys.AGENCY),
      advertiserId: this.advertiserId,
      agencies: this.agencies
    };
  }

  async init () {
    this.updateState(true);
    try {
      this.agencies = await this.agencyManager.getAgenciesOptions();
    } catch (e) {}
    this.updateState(false);
  }

  setAgencyId (agencyId: number) {
    this.advertiserId = undefined;
    SessionStorageHelper.removeItem(SessionStorageItemKeys.ADVERTISER);
    this.onAgencyChange(agencyId);
  }
}

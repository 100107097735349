import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import { SelectOptions } from '../commonType';
import _ from 'lodash';
import i18n from 'i18next';

export interface GroupOptionSelectionModel {
  readonly state: GroupOptionSelectionState;
  readonly event: UpdateEventListener<GroupOptionSelectionModel>;
  readonly showShowAllBtn: boolean;
  readonly i18nKey: string;
  getLabelByValue: (value?: number) => string;
  handleOnSearch: (searchKey: string) => void;
  handleOnSelect: (value?: number) => void;
  triggerDropdown: () => void;
  closeDropdown: () => void;
}

export type GroupOptionSelectionProps = {
  readonly model: GroupOptionSelectionModel;
};

export type GroupOptionSelectionState = {
  readonly selectedValue?: number;
  readonly open: boolean;
  readonly filteredGroupOptions: {[key: string]: Array<SelectOptions>};
};

export class DefaultGroupOptionSelectionModel implements GroupOptionSelectionModel {
  event: FireableUpdateEventListener<GroupOptionSelectionModel>;
  selectedValue?: number;
  selectGroupOptions: {[groupTitle: string]: Array<SelectOptions>};
  flattenOptions: Array<SelectOptions>;
  filteredGroupOptions: {[groupTitle: string]: Array<SelectOptions>};
  onOptionSelect: (value?: number) => void;
  open: boolean;
  showShowAllBtn: boolean;
  i18nKey: string;

  constructor (
    defaultValue: number | undefined,
    selectGroupOptions: {[groupTitle: string]: Array<SelectOptions>},
    onOptionSelect: (value?: number) => void,
    i18nKey: string = '',
    showShowAllBtn: boolean = true
  ) {
    this.event = new FireableUpdateEventListener<GroupOptionSelectionModel>();
    this.selectGroupOptions = selectGroupOptions;
    this.flattenOptions = _.flatten(Object.values(selectGroupOptions));
    this.onOptionSelect = onOptionSelect;
    this.selectedValue = defaultValue;
    this.open = false;
    this.filteredGroupOptions = {};
    Object.keys(this.selectGroupOptions).forEach(groupLabel => {
      this.filteredGroupOptions[groupLabel] = [...this.selectGroupOptions[groupLabel]];
    });
    this.i18nKey = i18nKey;
    this.showShowAllBtn = showShowAllBtn;
  }

  get state (): GroupOptionSelectionState {
    return {
      selectedValue: this.selectedValue,
      filteredGroupOptions: this.filteredGroupOptions,
      open: this.open
    };
  }

  triggerDropdown = () => {
    this.open = !this.open;
    this.updateState();
  }

  closeDropdown = () => {
    this.open = false;
    this.updateState();
  }

  handleOnSelect = (value?: number) => {
    this.selectedValue = value;
    this.open = false;
    this.onOptionSelect(value);
    this.updateState();
  }

  handleOnSearch = (searchKey: string) => {
    Object.keys(this.selectGroupOptions).forEach(groupLabel => {
      this.filteredGroupOptions[groupLabel] = _.filter(this.selectGroupOptions[groupLabel], option => !!option.label && option.label.toLowerCase().includes(searchKey.toLowerCase()));
    });
    this.updateState();
  }

  getLabelByValue = (value?: number) => {
    if (value === undefined) {
      return this.showShowAllBtn ?
        i18n.t(`groupOptionsSelection.labels.${_.camelCase('selectAll-' + this.i18nKey)}`) :
        i18n.t(`groupOptionsSelection.labels.${_.camelCase('pleaseChoose-' + this.i18nKey)}`);
    }
    const option = this.flattenOptions.find(option => option.value === value);
    return option ? option.label : '';
  }

  updateState () {
    this.event.fireEvent(this);
  }
}

import { Currency } from 'core/agency/Agency';

export enum ReportDimension {
  MONTH = 'month',
  DAY = 'day',
  HOUR = 'hour',
  AGENCY = 'agencyId',
  ADVERTISER = 'advertiserId',
  ORDER = 'adsOrderId',
  CAMPAIGN = 'campaignId',
  GO_CAMPAIGN = 'goCampaignId',
  GO_CAMPAIGN_GROUP = 'groupId',
  SALES_CHANNEL = 'salesChannel',
  CREATIVE = 'creativeId',
  BINDING = 'goBindingId',
  CREATIVE_TYPE = 'creativeType',
  CREATIVE_SIZE = 'creativeSize',
  SELF_BUY = 'isSelfBuy',
  DEVICE_TYPE = 'deviceType',
  RETAIL = 'retail'
}

export enum ReportCompareDimension {
  SALES_CHANNEL = 'salesChannel',
  CREATIVE = 'creativeId'
}

export enum ReportType {
  REBATE = 'rebate',
  PERFORMANCE = 'performance',
  VIDEO = 'video'
}

export enum ReportGran {
  MONTH = 'month',
  DAY = 'day',
  HOUR = 'hour'
}

export enum Metrics {
  IMPRES = 'impres',
  VIEWABLE = 'viewable',
  CLICKS = 'clicks',
  VIEW = 'adView',
  VIEW_RATE = 'viewRate',
  VIEWABLE_VIEW_RATE = 'viewableViewRate',
  CPV = 'cpv',
  CTR = 'ctr',
  VCTR = 'vctr',
  SKIP_COUNT = 'skipCount',
  UNDER_BID_FLOOR = 'underBidFloor',
  FIRST_QUARTILE= 'firstQuartile',
  MID_POINT = 'midpoint',
  THIRD_QUARTILE = 'thirdQuartile',
  COMPLETE = 'complete',
  SPENT = 'spent',
  CONV_1 = 'conv_1',
  CONV_7 = 'conv_7',
  CONVS = 'convs',
  CVR = 'cvr',
  MEDIA_COST = 'mediaCost',
  MEDIA_SPENT = 'mediaSpent',
  AGENCY_PROFIT = 'agcProfit',
  SYS_PROFIT = 'sysProfit',
  DATA_COST = 'dataCost',
  CPM = 'cpm',
  CPC = 'cpc',
  CPA = 'cpa',
  UU = 'uu'
}

export type ReportRecord = {
  dimensionID?: string;
  dimensionName: string;
  agcProfit: number;
  clicks: number;
  complete?: number;
  conv_1: number;
  conv_7: number;
  convs: number;
  dataCost: number;
  firstQuartile?: number;
  midpoint?: number;
  thirdQuartile?: number;
  impres: number;
  incentive?: number;
  mediaCost: number;
  mediaSpent?: number;
  skipCount?: number;
  spent: number;
  sysProfit: number;
  underBidFloor?: number;
  view?: number;
  viewable: number;
  winTotal: number;
  tags: Array<string>;
  uu?: number;
  parentId?: number | string;
  extra?: {[key: string]: any};
};

export type ReportCompareRecord = ReportRecord & {
  compareID: string;
  compareName: string;
};

export type ReportData = {
  allowMetrics: Array<Metrics>;
  dimension: ReportDimension;
  filter: any;
  from: string;
  to: string;
  records: Array<ReportRecord>;
  currency: Currency;
};

export type ReportCompareData = {
  allowMetrics: Array<Metrics>;
  dimension: ReportDimension;
  filter: any;
  from: string;
  to: string;
  records: Array<ReportCompareRecord>;
  currency: Currency;
};

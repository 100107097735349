import React, { Fragment, useCallback, useRef, useState } from 'react';
import { FormControl } from 'react-bootstrap';
import _ from 'lodash';
import styles from './imageInput.module.scss';
import imagePlaceholder from 'assets/image-placeholder.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import i18n from 'i18next';
import cx from 'classnames/bind';
import { faImages } from '@fortawesome/free-regular-svg-icons';
const classNames = cx.bind(styles);

type ImageInfo = {
  width?: number,
  height?: number,
  file?: File,
  src: string
};

export const ImageMultipleInput: React.FC<any> = ({
  onChange,
  validTypes,
  className,
  name,
  disabled,
  files,
  maxWidth,
  maxHeight
}) => {

  const inputRef = useRef<any>(null);
  const [state, setState] = useState<{
    hovering: boolean
  }>({
    hovering: false
  });

  const getImageInfo = async (file) => {
    const imageInfo: ImageInfo = await new Promise(resolve => {
      const imageReader = new FileReader();
      imageReader.onload = () => {
        const image = new Image();
        image.src = imageReader.result as string;
        image.onload = () => {
          resolve({
            width: image.width,
            height: image.height,
            src: image.src,
            file
          });
        };
      };
      imageReader.readAsDataURL(file);
    });
    return imageInfo;
  };

  const setUpImagesByFiles = useCallback(async (files, callback?: Function) => {
    let images: ImageInfo[] = [];
    for (const file of files) {
      images.push(await getImageInfo(file));
    }
    setState({
      hovering: false
    });
    callback && callback(images);
  }, []);

  const onImgPreviewClick = () => {
    inputRef.current && inputRef.current.click();
  };

  const onImgChange = async (event) => {
    const files = _.get(event, 'currentTarget.files', []);
    if (!files || files.length === 0) {
      return;
    }
    setUpImagesByFiles(files, onChange);
  };

  const onPreviewAreaMouseEnter = () => {
    setState(prev => ({ ...prev, hovering: true }));
  };

  const onPreviewAreaMouseLeave = () => {
    setState(prev => ({ ...prev, hovering: false }));
  };

  const onClearBtnClicked = (e) => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    onChange && onChange(undefined);
    setState({
      hovering: false
    });
    e.stopPropagation();
  };

  const onDragOver = (event) => {
    event.dataTransfer.dropEffect = 'copy';
    event.preventDefault();
  };

  const onDragEnter = (event) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const onImgDrop = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const files = _.get(event, 'dataTransfer.files', []);
    if (!files || files.length === 0) {
      return;
    }

    const supportTypes = validTypes || ['image/jpeg', 'image/jpg', 'image/png'];
    const invalidFiles = _.filter(files, file => supportTypes.indexOf(file.type) === -1);
    if (invalidFiles.length > 0) {
      setState({
        hovering: false
      });
      onChange(_.map(files, file => ({ file })));
      return;
    }
    setUpImagesByFiles(files, onChange);
  };

  const renderPlaceholder = () => {
    return (
      <div
        className={styles.imagePlaceholder}
        onDragOver={onDragOver}
        onDragEnter={onDragEnter}
        onDrop={onImgDrop}
      >
        <img src={imagePlaceholder} alt={'preview area'}/>
        <div className={styles.hint}>{i18n.t('creativeSetupFlow.labels.dragdropHint')}</div>
      </div>
    );
  };

  const renderImagePreview = () => {
    return (
      <div
        className={styles.previewArea}
        onMouseEnter={onPreviewAreaMouseEnter}
        onMouseLeave={onPreviewAreaMouseLeave}
        onDragOver={onDragOver}
        onDragEnter={onDragEnter}
        onDrop={onImgDrop}
      >
        {
          state.hovering &&
          <Fragment>
            <div className={styles.mask}/>
            {renderPlaceholder()}
            <div className={styles.closeBtnContainer} onClick={onClearBtnClicked}>
              <FontAwesomeIcon
                className={styles.closeBtn}
                icon={faTimes}
              />
            </div>
          </Fragment>
        }
        {(files && files.length === 1) &&
          <img
            style={{
              maxWidth: _.defaultTo(maxWidth, 500),
              maxHeight: _.defaultTo(maxHeight, 500)
            }}
            src={files[0].src}
            alt={'preview area'}
          />
        }
        {(files && files.length > 1) &&
          <div className={styles.fileIcon}>
            <FontAwesomeIcon icon={faImages}/>
          </div>
        }
      </div>
    );
  };

  const imgInputContainerClassName = classNames('imgInputContainer', {
    [className]: !!className
  });
  const accept = validTypes ? validTypes.join(',') : 'image/png,image/jpeg,image/jpg';
  return (
    <div className={imgInputContainerClassName}>
      <FormControl
        accept={accept}
        className={styles.imageInput}
        type='file'
        ref={inputRef}
        name={name}
        disabled={disabled}
        onChange={onImgChange}
        multiple
      />
      <div
        className={styles.imgPreview}
        onClick={onImgPreviewClick}
      >
        {
          files ?
            renderImagePreview() :
            renderPlaceholder()
        }
      </div>
    </div>
  );
};

import React from 'react';
import i18n from 'i18n';

import { SearchBar } from 'components/common/SearchBar';
import { SelectComponentProps, SelectComponentState } from './SelectComponentModel';
import styles from './selectComponent.module.scss';
import { SelectItemComponent } from '../SelectItemComponent';
import { CustomInputComponent } from '../CustomInputComponent/CustomInputComponent';
import { VariableSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

export class SelectComponent extends React.Component<SelectComponentProps, SelectComponentState> {

  listRef: { current: null | any };
  handler?: number;

  constructor (props) {
    super(props);
    this.state = this.props.model.state;
    this.listRef = React.createRef();
  }

  componentDidMount () {
    this.handler = this.props.model.event.add((model) => {
      this.setState(model.state);
    });
    this.props.model.onSearch(this.props.model.state.searchString);
  }

  componentDidUpdate (prevProps, prevStates) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add((model) => {
        this.setState(model.state);
      });
      this.props.model.setState(prevProps.model.state);
      this.props.model.updateCachedItemModels(prevProps.model.modelMap);
      this.props.model.onSearch(this.props.model.state.searchString);
    } else if (prevStates.searchString !== this.state.searchString) {
      this.state.optionsToShow.length > 0 &&
        this.listRef.current &&
        this.listRef.current.resetAfterIndex(0);
    }
  }

  componentWillUnmount () {
    this.props.model.onUnmount(this.handler);
  }

  getRowHeight = (index) => {
    const key = this.state.optionsToShow[index].value;
    const itemModel = this.props.model.getSelectItemModelByValue(key);
    return itemModel ? itemModel.itemHeight : 30;
  }

  renderListWindow = () => {
    const Row = ({ index, style }) => {
      const itemData = this.state.optionsToShow[index];
      return (
        <div style={style}>
          <SelectItemComponent
            key={itemData.label + itemData.value}
            model={this.props.model.getSelectItemModel(itemData, this.listRef)}
          />
        </div>
      );
    };
    return (
      <AutoSizer>
        {({ height, width }) => (
          <List
            className='listWindow'
            width={width}
            height={height}
            itemCount={this.state.optionsToShow.length}
            itemSize={this.getRowHeight}
            ref={ref => {
              if (this.listRef.current !== ref) {
                this.listRef.current = ref;
                ref && ref.resetAfterIndex(0);
              }
            }}
          >
            {Row}
          </List>
        )}
      </AutoSizer>
    );
  }

  render () {
    return (
      <div className={styles.selectComponentContainer}>
        <SearchBar
          model={{
            placeholder: this.props.model.searchPlaceHolder,
            search: this.props.model.onSearch,
            defaultValue: this.props.model.state.searchString
          }}
        />
        {this.state.optionsToShow.length > 0 ?
          <div className={styles.selectItems}>
            {this.renderListWindow()}
          </div> :
          <div className={styles.placeholder}>
            {i18n.t(this.props.model.contentPlaceholderI18nKey)}
          </div>
        }
        {this.props.model.singleSelection ? <div className={styles.singleSelectTip}> {i18n.t('limitation.labels.singleSelectTip')} </div> : undefined}
        <div className={styles.classifyCount}>{i18n.t(this.props.model.contentUnitI18nKey, { total: this.state.optionsToShow.length })}</div>
        {this.props.model.customInputModel ? <CustomInputComponent model={this.props.model.customInputModel} /> : undefined}
      </div>
    );
  }
}

import { CampaignGroup, CampaignGroupChannel, FBBidStrategy, FbObjective } from 'core/campaignGroup/CampaignGroup';
import { ADSET_DEFAULT_AGE_MAX, ADSET_DEFAULT_AGE_MIN, BillingEvent, FbAdSet, FBAppEvent } from 'core/fbAdSet/FbAdSet';
import { Order } from 'core/order/Order';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { DefaultFbAdSetManager, FbAdSetManager } from 'core/fbAdSet/FbAdSetManager';
import { useRouteMatch } from 'react-router-dom';
import moment from 'moment';
import i18n from 'i18n';
import _ from 'lodash';
import { SelectOptions } from 'components/common/commonType';
import { DefaultAdRequestSourceManager } from 'core/adRequestSource/AdRequestSourceManager';
import { DefaultLimitationManager } from 'core/limitation/LimitationManager';
import { getAlpha2ByAlpha3 } from 'utils/CountryUtil';
import { DynamicBreadcrumb } from 'components/Breadcrumbs/DynamicBreadcrumbs';
import { useCallAPI } from 'hooks/useCallAPI';
import { GoCampaignOptimizationGoal } from 'core/goCampaign/GoCampaign';
import { getDayOfWeekLabelByValue, getInitDaypart } from 'components/Dayparts/Dayparts';
import { DefaultEditLimitationModel, EditLimitationModel } from 'containers/Limitations/EditLimitationModel';
import coreContext from 'contexts/coreContext';
import { OPERATE } from 'enum/Operate';
import { adSetInventorySetting } from 'containers/Limitations/LimitationSetting/LimitationSettingData';
import { EstimatedAudience } from 'core/goSegment/GoSegment';
import { SavedTargeting } from 'core/limitation/Limitation';
import { useGetSavedTargeting } from 'hooks/useGetSavedTargeting';
import { ageMaxOptions, ageMinOptions, genderOptions } from 'core/limitation/goCampaignTAOptions';
import { SummaryTitleColor } from 'components/SummaryDetail/SummaryDetail';
import { renderFBAdSetSpendLimit } from './steps/FbAdSetSummaryStepRenderFunctions';
import { formatPriceWithCurrency, getPriceValue } from 'helper/CurrencyHelper';
import { validateEmpty, validateMinimum, validateMinMax } from 'utils/ValidateUtils';
import { renderIncreaseFBCampaignBudgetHint, renderOverBudgetWording } from 'containers/GoCampaigns/FormHintRenderFunctions';
import { FbAdSetFormModelData, useFbCreateAdSetFormModel, useFbEditAdSetFormModel } from './steps/FbAdSetFormModel';
import { FbAdSetDraftManager, DraftManager } from 'core/draft/DraftManager';
import { toast } from 'react-toastify';

const MAIN_STEP_INDEX = 0;

export enum FbAdSetMainStepTab {
  BASIC,
  TARGETINIG
}

export type FbAdSetFormData = {
  name: string;
  account_id: number;
  lifetime_budget?: number | string;
  bid_strategy: FBBidStrategy;
  bid_amount?: number | string;
  optimization_goal?: GoCampaignOptimizationGoal;
  billing_event?: BillingEvent;
  start_time: string;
  end_time: string;
  budget_remaining?: number | string;
  targeting?: {
    [key: string]: any
  },
  hasSpendLimits: boolean,
  lifetime_min_spend_target?: number | string,
  lifetime_spend_cap?: number | string,
  frequency_control_specs?: any,
  promoted_object?: any,
  destination_type?: string,
  dayPart?: { [key: string]: string[] | number[] | string };
};

export type AdSetSetupFlowPageModelData = {
  title: string,
  objectType: 'adSet' | 'draft',
  loading: boolean,
  finished: boolean,
  fbCountries: SelectOptions[],
  goSegments: SelectOptions[],
  adSet: FbAdSetFormData | undefined,
  initAdSet: FbAdSetFormData | undefined,
  redirectPath: string | undefined,
  canEditOptimizationGoal: boolean,
  otherAdsetMinBudget: number,
  breadcrumbs: any[],
  limitationModel?: EditLimitationModel,
  audienceLowestThreshold: number,
  savedTAList: SavedTargeting[],
  appliedSavedTAInfo?: SelectOptions,
  showTAManagement: boolean,
  estimatedData?: EstimatedAudience,
  showPublishBindingFailed: boolean,
  defaultOptimizationGoal?: GoCampaignOptimizationGoal,
  setFinished: (finish: any) => any,
  validate: (adSet: FbAdSetFormData) => any,
  getSummaryData: (adSet: FbAdSetFormData) => any,
  getTASummaryData: (limitationValue: any) => any,
  setEsitimatedData: (estimatedData: EstimatedAudience) => void,
  onImportSavedTA: (savedTAInfo: SelectOptions, limitationValue) => void,
  onDeleteSavedTA: (deletedTAId: number) => void,
  setShowTAManagement: (show: boolean) => void,
  submit: () => Promise<void>,
  setAdSet: (adSet: FbAdSetFormData) => void,
  setInitAdSet: (adSet: FbAdSetFormData) => void,
  backToCampaignGroupDetail: () => void,
  setRedirectPath: (redirectPath?: string) => void,
  onSaveDraft?: (adSet: FbAdSetFormData) => void,
  adSetFormModel: (order: Order, campaignGroup: CampaignGroup, adSet: FbAdSetFormData) => FbAdSetFormModelData
};

const defaultFbAdSetManager = new DefaultFbAdSetManager();
const defaultDraftManager = new FbAdSetDraftManager();

const useFbAdSetSetupFlowPageModel = (
  order: Order,
  campaignGroup: CampaignGroup,
  otherFbAdSetList: FbAdSet[],
  fbAdSetManager: FbAdSetManager = defaultFbAdSetManager
) => {

  const [appliedSavedTAInfo, setAppliedSavedTAInfo] = useState<SelectOptions | undefined>();
  const [redirectPath, setRedirectPath] = useState<string | undefined>();
  const [finished, setFinished] = useState<boolean>(false);
  const [fbCountries, setFbCountries] = useState<SelectOptions[]>([]);
  const [goSegments, setGoSegments] = useState<SelectOptions[]>([]);
  const [estimatedData, setEsitimatedData] = useState<EstimatedAudience | undefined>();
  const {
    loading,
    callAPIs
  } = useCallAPI();

  const [showTAManagement, setShowTAManagement] = useState(false);

  const fetchFbCountries = useCallback(async () => {
    try {
      const fbCountries = await new DefaultAdRequestSourceManager().getFBCountries();
      setFbCountries(fbCountries);
      return fbCountries;
    } catch (e) {}
  }, []);

  const fetchFbSegments = useCallback(async () => {
    try {
      const goSegments = await new DefaultAdRequestSourceManager().getGoSegments(order.advertiserId, CampaignGroupChannel.FB);
      setGoSegments(goSegments);
    } catch (e) {}
  }, [order.advertiserId]);

  const backToCampaignGroupDetail = () => {
    const redirectPath =
      `/orders/${order.orderNumber}/campaign-groups/${campaignGroup.groupId}`;
    setRedirectPath(redirectPath);
  };

  const otherAdsetMinBudget = useMemo(() => {
    if (!otherFbAdSetList || otherFbAdSetList.length === 0) {
      return 0;
    }
    const minBudget = otherFbAdSetList.reduce((acc, fbAdSet) => {
      const startDateMoment = moment(fbAdSet.start_time);
      const endDateMoment = moment(fbAdSet.end_time);
      const scheduleDateCount = endDateMoment.diff(startDateMoment, 'days') + 1;
      return acc + Math.max(
        fbAdSetManager.getMinBudgetOfFBObject(
          campaignGroup.currencyRate,
          +_.defaultTo(fbAdSet.bid_amount, 1),
          _.get(campaignGroup, 'fb.bid_strategy') ? _.get(campaignGroup, 'fb.bid_strategy') : fbAdSet.bid_strategy,
          fbAdSet.billing_event,
          scheduleDateCount,
          order
        ),
        +_.defaultTo(fbAdSet.lifetime_min_spend_target, 0)
      );
    }, 0);

    return minBudget;
  }, [otherFbAdSetList, campaignGroup, order, fbAdSetManager]);

  const {
    loading: loadingSavedTAList,
    savedTAList,
    fetchSavedTargeting
  } = useGetSavedTargeting(order.advertiserId, CampaignGroupChannel.FB);

  const validate = (initAdSet, minBudgetFromServer: number | undefined, value: FbAdSetFormData) => {
    const getAdSetTotalDays = (startTime, endTime) => {
      const startDateMoment = moment(startTime);
      const endDateMoment = moment(endTime);
      return endDateMoment.diff(startDateMoment, 'days') + 1;
    };
    const parentBidStrategy = _.get(campaignGroup, 'fb.bid_strategy');
    const adSetBidStrategy = _.get(value, 'bid_strategy');
    const bidAmount = +(_.get(value, 'bid_amount', 0).toString());
    const bidStrategy = _.get(value, 'bid_strategy', _.get(campaignGroup, 'fb.bid_strategy'));
    const billingEvent = _.get(value, 'billing_event', BillingEvent.IMPRESSIONS);
    const scheduleDateCount = getAdSetTotalDays(value.start_time, value.end_time);
    const minBudget = fbAdSetManager.getMinBudgetOfFBObject(
      campaignGroup.currencyRate,
      bidAmount,
      bidStrategy,
      billingEvent,
      scheduleDateCount,
      order,
      minBudgetFromServer
    );
    const validateBudget = () => {
      const initBudget = +(_.get(initAdSet, 'lifetime_budget', '0'));
      const budget = +(_.get(value, 'lifetime_budget', 0).toString());
      if (!initAdSet.isDraft &&
        initBudget === budget &&
        initAdSet.start_time === value.start_time &&
        initAdSet.end_time === value.end_time &&
        initAdSet.bid_amount === value.bid_amount &&
        validateMinimum(_.get(value, 'lifetime_budget'), 1) === undefined
      ) {
        return;
      }
      const totalBudget = initAdSet.isDraft ?
        campaignGroup.budgetBalance :
        campaignGroup.budgetBalance + initBudget;
      const remainBudget = totalBudget - budget;
      if (remainBudget < 0) {
        return renderOverBudgetWording(order.currency, totalBudget);
      }
      return validateMinimum(
        value.lifetime_budget,
        getPriceValue(order.currency, minBudget),
        'campaign.descriptions.smallerThanBudgetMinimum',
        order.currency
      );
    };
    const validateCampaignBudget = () => {
      const fbCampaignMinBudget = otherAdsetMinBudget + Math.max(minBudget, +_.defaultTo(value.lifetime_min_spend_target, 0));
      if (fbCampaignMinBudget > +_.defaultTo(campaignGroup.budget, 0)) {
        return renderIncreaseFBCampaignBudgetHint(order, campaignGroup.groupId, fbCampaignMinBudget);
      }
    };
    const validateFrequencyControlSpecs = (frequencyControlSpecs) => {
      const minIntervalDays = 1;
      const maxIntervalDays = 7;
      const minMaxFrequency = 1;
      const maxMaxFrequency = 20;
      const maxFrequency = frequencyControlSpecs.max_frequency;
      const intervalDays = frequencyControlSpecs.interval_days;
      const emptyMaxFrequency = validateEmpty(maxFrequency) && i18n.t('adSetSetupFlow.mainStep.errors.emptyMaxFrequency');
      const emptyIntervalDays = validateEmpty(intervalDays) && i18n.t('adSetSetupFlow.mainStep.errors.emptyIntervalDays');
      const intervalDaysOutofRange = validateMinMax(intervalDays, minIntervalDays, maxIntervalDays) &&
        i18n.t('adSetSetupFlow.mainStep.errors.intervalDaysOutofRange', { min: minIntervalDays, max: maxIntervalDays });
      const maxFrequencyOutofRange = validateMinMax(maxFrequency, minMaxFrequency, maxMaxFrequency) &&
        i18n.t('adSetSetupFlow.mainStep.errors.maxFrequencyOutofRange', { min: minMaxFrequency, max: maxMaxFrequency });
      return _.omitBy({
        max_frequency: emptyMaxFrequency || maxFrequencyOutofRange,
        interval_days: emptyIntervalDays || intervalDaysOutofRange
      }, _.isUndefined);
    };
    const validateLifetimeMinSpendTarget = () => {
      const minSpendTarget = value.lifetime_min_spend_target;
      const spendCap = value.lifetime_spend_cap;
      if (minSpendTarget) {
        if (spendCap) {
          if (+minSpendTarget > +spendCap) {
            return i18n.t('fbAdSetMainStep.labels.minSpendLargerThanSpendCap');
          }
          if (+minSpendTarget > +spendCap * 0.9) {
            return i18n.t('fbAdSetMainStep.labels.minSpendTooLarge');
          }
          if (+spendCap - +minSpendTarget < minBudget) {
            return i18n.t('fbAdSetMainStep.labels.spendDiffSmallerThanMinBudget', { minBudget: formatPriceWithCurrency(order.currency, minBudget) });
          }
        }
      }
    };
    const validateLifetimeSpendCap = () => {
      const minSpendTarget = value.lifetime_min_spend_target;
      const spendCap = value.lifetime_spend_cap;
      if (spendCap) {
        if (+spendCap < minBudget) {
          return i18n.t('fbAdSetMainStep.labels.spendCapSmallerThanMinBudget', { minBudget: formatPriceWithCurrency(order.currency, minBudget) });
        }
        if (minSpendTarget) {
          if (+minSpendTarget > +spendCap) {
            return i18n.t('fbAdSetMainStep.labels.minSpendLargerThanSpendCap');
          }
          if (+spendCap - +minSpendTarget < minBudget) {
            return i18n.t('fbAdSetMainStep.labels.spendDiffSmallerThanMinBudget', { minBudget: formatPriceWithCurrency(order.currency, minBudget) });
          }
        }
      }
    };

    return _.omitBy({
      name: validateEmpty(value.name),
      lifetime_budget: campaignGroup.budget ? validateCampaignBudget() : validateBudget(),
      bid_amount: (
        parentBidStrategy && parentBidStrategy === FBBidStrategy.LOWEST_COST_WITH_BID_CAP) ||
        adSetBidStrategy === FBBidStrategy.LOWEST_COST_WITH_BID_CAP ?
          validateMinimum(value.bid_amount, 1, 'campaign.descriptions.priceMinimum', order.currency) :
          undefined,
      frequency_control_specs: _.get(campaignGroup, 'fb.objective') === FbObjective.OUTCOME_AWARENESS && value.optimization_goal === GoCampaignOptimizationGoal.REACH ?
        validateFrequencyControlSpecs(value.frequency_control_specs) : undefined,
      lifetime_min_spend_target: campaignGroup.autoOptimise && value.hasSpendLimits ? validateLifetimeMinSpendTarget() : undefined,
      lifetime_spend_cap: campaignGroup.autoOptimise && value.hasSpendLimits ? validateLifetimeSpendCap() : undefined
    }, _.isEmpty);
  };

  const getTASummaryData = (targeting) => {
    const counrtyWithI18n = value => i18n.t(`geoLocations.fb.${value.toString().toLowerCase().replace(/-|\s/g, '_')}`);
    const includeTA = targeting.include ? targeting.include : [];
    const excludeTA = targeting.exclude ? targeting.exclude : [];
    const ageMin = includeTA.find(limitation => limitation.type === 'age_min');
    const ageMax = includeTA.find(limitation => limitation.type === 'age_max');
    const ageMinOption = ageMinOptions.find(option => option.value === _.get(ageMin, 'value', ADSET_DEFAULT_AGE_MIN));
    const ageMaxOption = ageMaxOptions.find(option => option.value === _.get(ageMax, 'value', ADSET_DEFAULT_AGE_MAX));
    const gender = includeTA.find(limitation => limitation.type === 'genders');
    const genderOption = genderOptions.find(option => option.value === _.get(gender, 'value', -1));
    const includeGeoLocations = includeTA.find(limitation => limitation.type === 'geo_locations');
    const {
      countries,
      cities,
      regions
    } = defaultFbAdSetManager.getFbGeoLocationDataByLimitation(includeGeoLocations, fbCountries);
    const countriesLabel = countries.map(counrtyWithI18n);
    const citiesLabel = cities.map(city => counrtyWithI18n(city.key));
    const regionsLabel = regions.map(region => counrtyWithI18n(region.key));

    const excludeGeoLocations = excludeTA.find(limitation => limitation.type === 'geo_locations');
    const {
      countries: excludedCountries,
      cities: excludedCities,
      regions: excludedRegions
    } = defaultFbAdSetManager.getFbGeoLocationDataByLimitation(excludeGeoLocations, fbCountries);
    const excludedCountriesLabel = excludedCountries.map(counrtyWithI18n);
    const excludedCitiesLabel = excludedCities.map(counrtyWithI18n);
    const excludedRegionsLabel = excludedRegions.map(counrtyWithI18n);
    const publisherPlatforms = includeTA.find(limitation => limitation.type === 'publisher_platforms');
    const publisherPlatformsLabel = publisherPlatforms ?
      publisherPlatforms.value.map(publisherPlatform => i18n.t(`targeting.fb.publisher_platforms.${publisherPlatform.value.toLowerCase()}`)) :
      [];
    const includeSegment = includeTA.find(limitation => limitation.type === 'segment');
    const excludeSegment = excludeTA.find(limitation => limitation.type === 'segment');
    const includeSegmentsLabel = includeSegment ?
      includeSegment.value.map(segment => i18n.t(`targeting.fb.segment.${segment.value}`)) :
      [];
    const excludeSegmentsLabel = excludeSegment ?
      excludeSegment.value.map(segment => i18n.t(`targeting.fb.segment.${segment.value}`)) :
      [];
    const os = includeTA.find(limitation => limitation.type === 'user_os');
    const osLabel = os ? os.value.map(os => os.value) : [];
    const device = includeTA.find(limitation => limitation.type === 'user_device');
    const deviceLabel = device ? device.value.map(device => device.value) : [];
    return _.omitBy({
      general: {
        title: i18n.t('campaignSummary.titles.general'),
        titlePrefixColor: SummaryTitleColor.DARK,
        content: _.compact([{
          label: i18n.t('limitation.labels.age'),
          value: `${_.get(ageMinOption, 'label', ageMin)} ~ ${_.get(ageMaxOption, 'label', ageMax)}`
        }, genderOption ? {
          label: i18n.t('limitation.labels.gender'),
          value: genderOption.label
        } : undefined])
      },
      include: {
        title: i18n.t('campaignSummary.titles.inc'),
        titlePrefixColor: SummaryTitleColor.GREEN,
        content: _.compact([
          countriesLabel.length > 0 ? {
            label: i18n.t('adSetSetupFlow.summaryStep.countries'),
            value: countriesLabel.join(', ')
          } : undefined,
          citiesLabel.length > 0 ? {
            label: i18n.t('adSetSetupFlow.summaryStep.cities'),
            value: citiesLabel.join(', ')
          } : undefined,
          regionsLabel.length > 0 ? {
            label: i18n.t('adSetSetupFlow.summaryStep.regions'),
            value: regionsLabel.join(', ')
          } : undefined,
          publisherPlatformsLabel.length > 0 ? {
            label: i18n.t('targeting.fb.platformsTitle'),
            value: publisherPlatformsLabel.join(', ')
          } : undefined,
          includeSegmentsLabel.length > 0 ? {
            label: i18n.t('limitation.labels.segment'),
            value: includeSegmentsLabel.join(', ')
          } : undefined,
          osLabel.length > 0 ? {
            label: i18n.t('limitation.labels.os'),
            value: osLabel.join(', ')
          } : undefined,
          deviceLabel.length > 0 ? {
            label: i18n.t('limitation.labels.device'),
            value: deviceLabel.join(', ')
          } : undefined
        ])
      },
      exclude: {
        title: i18n.t('campaignSummary.titles.exc'),
        titlePrefixColor: SummaryTitleColor.RED,
        content: _.compact([
          excludedCountriesLabel.length > 0 ? {
            label: i18n.t('adSetSetupFlow.summaryStep.countries'),
            value: excludedCountriesLabel.join(', ')
          } : undefined,
          excludedCitiesLabel.length > 0 ? {
            label: i18n.t('adSetSetupFlow.summaryStep.cities'),
            value: excludedCitiesLabel.join(', ')
          } : undefined,
          excludedRegionsLabel.length > 0 ? {
            label: i18n.t('adSetSetupFlow.summaryStep.regions'),
            value: excludedRegionsLabel.join(', ')
          } : undefined,
          excludeSegmentsLabel.length > 0 ? {
            label: i18n.t('limitation.labels.segment'),
            value: excludeSegmentsLabel.join(', ')
          } : undefined
        ])
      }
    }, _.isEmpty);
  };

  const getSummaryData = (adSet: FbAdSetFormData) => {
    if (!adSet) {
      return undefined;
    }

    const currency = order.currency;
    const frequencyControl = _.get(adSet, 'frequency_control_specs');
    const getDayPartValue = (dayPart) => {
      let dayPartValue = _.omitBy(_.omit(dayPart, 'enabled'), _.isEmpty);
      return Object.keys(dayPartValue).map(day => {
        return `${getDayOfWeekLabelByValue(parseInt(day, 10))},${i18n.t('daypart.labels.hourUnit')}: ${dayPartValue[day].join(', ')}`;
      }).join('\r\n');
    };

    const basicSummary = {
      title: i18n.t('adSetSetupFlow.summaryStep.basicTitle'),
      backStep: MAIN_STEP_INDEX,
      backSubStep: FbAdSetMainStepTab.BASIC,
      data: _.omitBy({
        general: {
          title: i18n.t('adSetSetupFlow.mainStep.fieldset.basicTitle'),
          content: _.compact([
            {
              label: i18n.t('adSetSetupFlow.mainStep.field.name'),
              value: adSet.name
            },
            campaignGroup.budget ?
              undefined : {
                label: i18n.t('adSetSetupFlow.mainStep.field.lifetimeBudget'),
                value: adSet.lifetime_budget
              },
            {
              label: i18n.t('adSetSetupFlow.mainStep.field.dateRange'),
              value: `${moment(adSet.start_time).format('YYYY-MM-DD HH:mm:ss')} ~ ${moment(adSet.end_time).format('YYYY-MM-DD HH:mm:ss')}`
            }
          ])
        },
        optimization: {
          title: i18n.t('adSetSetupFlow.mainStep.fieldset.optimizationTitle'),
          content: _.compact([
            {
              label: i18n.t('adSetSetupFlow.mainStep.field.optimizationGoal'),
              value: i18n.t(`adSet.optimizationGoal.${adSet.optimization_goal!.toLowerCase()}`)
            },
            _.get(adSet, 'promoted_object.custom_event_type') ? {
              label: i18n.t('adSetSetupFlow.mainStep.field.promoted_object_custom_event_type'),
              value: _.startCase(_.lowerCase(_.get(adSet, 'promoted_object.custom_event_type')))
            } : undefined,
            adSet.bid_amount ? {
              label: i18n.t('adSetSetupFlow.mainStep.field.bidControl'),
              value: formatPriceWithCurrency(currency, +(adSet.bid_amount))
            } : undefined,
            {
              label: i18n.t('adSetSetupFlow.mainStep.field.bidStrategy'),
              value: i18n.t(`campaignGroup.labels.fb.bidStrategy.${adSet.bid_strategy.toLowerCase()}`)
            },
            {
              label: i18n.t('adSetSetupFlow.mainStep.field.billingEvent'),
              value: i18n.t(`adSet.billingEvent.${adSet.billing_event!.toLowerCase()}`)
            },
            adSet.hasSpendLimits ? {
              label: i18n.t('adSetSetupFlow.mainStep.field.hasSpendLimits'),
              value: renderFBAdSetSpendLimit(
                  adSet.lifetime_min_spend_target ? formatPriceWithCurrency(currency, +(adSet.lifetime_min_spend_target)) : 0,
                  adSet.lifetime_spend_cap ? formatPriceWithCurrency(currency, +(adSet.lifetime_spend_cap)) : 0)
            } : undefined,
            adSet.frequency_control_specs ? {
              label: i18n.t('adSetSetupFlow.mainStep.field.frequencyControl'),
              value: i18n.t('adSetSetupFlow.mainStep.labels.frequencyControl', {
                event: frequencyControl.max_frequency > 1 ? 'impressions' : 'impression',
                interval_days: frequencyControl.interval_days,
                max_frequency: frequencyControl.max_frequency,
                unit: frequencyControl.interval_days > 1 ? 'days' : 'day'
              })
            } : undefined,
            _.get(adSet, 'dayPart.enabled') ? {
              label: i18n.t('campaignSummary.labels.dayPart'),
              value: getDayPartValue(adSet.dayPart)
            } : undefined
          ])
        }
      }, _.isUndefined)
    };

    const targeting = adSet.targeting;
    let targetingSummary;
    if (!targeting || _.isEmpty(targeting)) {
      targetingSummary = undefined;
    } else {
      targetingSummary = {
        title: i18n.t('adSetSetupFlow.summaryStep.targetingTitle'),
        backStep: MAIN_STEP_INDEX,
        backSubStep: FbAdSetMainStepTab.TARGETINIG,
        data: getTASummaryData(targeting)
      };
    }

    return {
      basicSummary,
      targetingSummary
    };
  };

  const onDeleteSavedTA = (deletedTAId: number) => {
    if (appliedSavedTAInfo && appliedSavedTAInfo.value === deletedTAId) {
      setAppliedSavedTAInfo(undefined);
    }
    fetchSavedTargeting();
  };

  return {
    loading: loading || loadingSavedTAList,
    savedTAList,
    fbCountries,
    redirectPath,
    finished,
    goSegments,
    otherAdsetMinBudget,
    audienceLowestThreshold: 2000000,
    appliedSavedTAInfo,
    estimatedData,
    showTAManagement,
    validate,
    setEsitimatedData,
    callAPIs,
    setFinished,
    getSummaryData,
    onDeleteSavedTA,
    setRedirectPath,
    fetchFbCountries,
    fetchFbSegments,
    getTASummaryData,
    setShowTAManagement,
    backToCampaignGroupDetail,
    setAppliedSavedTAInfo
  };
};

export const useCreateFbAdSetSetupFlowPageModel = (
  order: Order,
  campaignGroup: CampaignGroup,
  fbAdSetList: FbAdSet[],
  fbAdSetManager: FbAdSetManager = defaultFbAdSetManager
): AdSetSetupFlowPageModelData => {

  const {
    validate,
    callAPIs,
    fetchFbCountries,
    fetchFbSegments,
    setShowTAManagement,
    setAppliedSavedTAInfo,
    fbCountries,
    goSegments,
    otherAdsetMinBudget,
    audienceLowestThreshold,
    ...basicProps
  } = useFbAdSetSetupFlowPageModel(order, campaignGroup, fbAdSetList);

  const defaultOptimizationGoal = useMemo(() => {
    const allList = fbAdSetList ? fbAdSetList : [];
    const notDraftList = allList.filter(adSet => !adSet.draftId);
    if (notDraftList.length === 0 || !campaignGroup.autoOptimise) {
      return undefined;
    }

    return notDraftList[0].optimization_goal;
  }, [fbAdSetList, campaignGroup.autoOptimise]);

  const defaultDestinationType = useMemo(() => {
    if (!fbAdSetList || fbAdSetList.length === 0) {
      return undefined;
    }

    return fbAdSetList[0].destination_type;
  }, [fbAdSetList]);

  const initDateRange = useMemo(() => {
    const now = moment().startOf('hour');
    const orderStartDate = moment(order.startDate);
    const startTime = now.isBefore(orderStartDate) ? orderStartDate : now;
    const endTime = moment.min(
        moment(order.endDate)
          .add(1, 'days')
          .subtract(1, 'seconds'),
          moment(startTime).add(91, 'days').endOf('day')
      );
    return {
      startTime: startTime.format('YYYY-MM-DD HH:mm:ss'),
      endTime: endTime.format('YYYY-MM-DD HH:mm:ss')
    };
  }, [order]);

  const optimizationGoal = defaultOptimizationGoal ? defaultOptimizationGoal : fbAdSetManager.getDefaultOptimizationGoal(campaignGroup);
  const destinationType = defaultDestinationType ? defaultDestinationType : fbAdSetManager.getDefaultDestinationType(campaignGroup);
  const parentBidStrategy = _.get(campaignGroup, 'fb.bid_strategy');
  const [adSet, setAdSet] = useState<FbAdSetFormData>({
    name: campaignGroup.name,
    account_id: _.get(campaignGroup, 'fb.account_id'),
    start_time: initDateRange.startTime,
    end_time: initDateRange.endTime,
    bid_strategy: parentBidStrategy ? parentBidStrategy : FBBidStrategy.LOWEST_COST_WITHOUT_CAP,
    bid_amount: '',
    lifetime_budget: '',
    optimization_goal: optimizationGoal,
    billing_event: fbAdSetManager.getDefaultBillingEvent(campaignGroup),
    hasSpendLimits: false,
    frequency_control_specs: _.get(campaignGroup, 'fb.objective') === FbObjective.OUTCOME_AWARENESS && optimizationGoal === GoCampaignOptimizationGoal.REACH ? {
      event: 'IMPRESSIONS',
      interval_days: 7,
      max_frequency: 1
    } : undefined,
    targeting: {
      include: _.compact([
        optimizationGoal === GoCampaignOptimizationGoal.OFFSITE_CONVERSIONS ? {
          type: 'user_os',
          value: [{
            label: 'Android',
            value: 'Android'
          }]
        } : undefined
      ])
    },
    promoted_object: optimizationGoal === GoCampaignOptimizationGoal.OFFSITE_CONVERSIONS ? {
      custom_event_type: FBAppEvent.PURCHASE
    } : undefined,
    destination_type: destinationType,
    dayPart: campaignGroup.autoOptimise ? getInitDaypart() : undefined
  });
  const [initAdSet, setInitAdSet] = useState(adSet);

  const fetchLimitationPreset = useCallback(async () => {
    try {
      return await new DefaultLimitationManager().getLimitationPreSet('campaign');
    } catch (e) {}
  }, []);

  useEffect(() => {
    const findGeoLocationsOfPreset = (preset, fbCountries) => {
      const countryPreset = preset.find(limitation => limitation.type === 'geography');
      const countryPresetCodes = _.compact(_.get(countryPreset, 'value', []).map(country => getAlpha2ByAlpha3(country.value)));
      if (fbCountries) {
        const fbCountryPreset = fbCountries.filter(fbCountry => countryPresetCodes.includes(fbCountry.value));
        return fbCountryPreset.map(country => ({ label: country.value, value: country.value }));
      }
      return undefined;
    };
    callAPIs([fetchFbCountries, fetchLimitationPreset, fetchFbSegments], (fbCountries, limitationPreset) => {
      if (fbCountries) {
        const countries = findGeoLocationsOfPreset(_.get(limitationPreset, 'include', []), fbCountries);
        const excludeCountries = findGeoLocationsOfPreset(_.get(limitationPreset, 'exclude', []), fbCountries);
        const changeAdSetState = adSet => ({
          ...adSet,
          targeting: _.omitBy({
            ...adSet.targeting,
            include: _.compact([
              ..._.get(adSet, 'targeting.include', []),
              countries.length > 0 ? {
                type: 'geo_locations',
                value: countries
              } : undefined
            ]),
            exclude: _.compact([
              ..._.get(adSet, 'targeting.exclude', []),
              excludeCountries.length > 0 ? {
                type: 'geo_locations',
                value: excludeCountries
              } : undefined
            ])
          }, _.isEmpty)
        });
        setAdSet(changeAdSetState);
        setInitAdSet(changeAdSetState);
      }
    });
  }, [fetchFbCountries, fetchFbSegments, fetchLimitationPreset, callAPIs]);

  useEffect(() => {
    const changeAdSetState = adSet => ({
      ...adSet,
      optimization_goal: defaultOptimizationGoal ? defaultOptimizationGoal : fbAdSetManager.getDefaultOptimizationGoal(campaignGroup)
    });
    setAdSet(changeAdSetState);
    setInitAdSet(changeAdSetState);
  }, [defaultOptimizationGoal, campaignGroup, fbAdSetManager]);

  const submit = async () => {
    callAPIs([fbAdSetManager.createAdSet.bind(fbAdSetManager, order.id, campaignGroup, adSet, fbCountries)], () => {
      basicProps.setFinished(true);
      basicProps.backToCampaignGroupDetail();
    });
  };

  const core = useContext(coreContext);
  const limitationModel = useMemo(() => {
    const optimizationGoal = adSet.optimization_goal;
    return core && adSet.targeting ? new DefaultEditLimitationModel(
      adSetInventorySetting('create', adSet.optimization_goal, fbCountries, goSegments),
      adSet.targeting,
      {
        need: [OPERATE.INCLUDE],
        notNeed: [OPERATE.EXCLUDE]
      },
      core.addonFeatureManager.addonFeature,
      _.partial(setShowTAManagement, true),
      optimizationGoal === GoCampaignOptimizationGoal.OFFSITE_CONVERSIONS ? { include: ['user_os'] } : { include: ['geo_locations'] },
      {
        channel: CampaignGroupChannel.FB,
        audienceLowestThreshold,
        estimatedRequiredFields: ['geo_locations'],
        channelTargetingGetter: (limitationValue) => fbAdSetManager.wrapTargetingForServer(limitationValue, fbCountries),
        accountId: adSet.account_id.toString()
      }
    ) : undefined;
  }, [adSet.account_id, adSet.optimization_goal, adSet.targeting, core, fbAdSetManager, fbCountries, goSegments, audienceLowestThreshold, setShowTAManagement]);

  const onImportSavedTA = (savedTAInfo: SelectOptions, limitationValue) => {
    if (!limitationModel) {
      return;
    }
    setAppliedSavedTAInfo(savedTAInfo);
    limitationModel.updateLimitationValue(limitationValue);
  };

  return {
    ...basicProps,
    objectType: 'adSet',
    adSet,
    limitationModel,
    initAdSet,
    fbCountries,
    goSegments,
    otherAdsetMinBudget,
    audienceLowestThreshold,
    title: i18n.t('adSetSetupFlow.mainStep.createTitle'),
    defaultOptimizationGoal,
    canEditOptimizationGoal: !defaultOptimizationGoal,
    showPublishBindingFailed: false,
    breadcrumbs: [
      { path: '/orders', breadcrumb: i18n.t('orderDetail.labels.title') },
      {
        path: '/orders/:orderNumber',
        breadcrumb: DynamicBreadcrumb,
        props: {
          label: _.get(order, 'projectName'),
          matchParam: 'orderNumber'
        }
      },
      {
        path: '/orders/:orderNumber/campaign-groups/:campaignGroupId',
        breadcrumb: DynamicBreadcrumb,
        props: {
          label: _.get(campaignGroup, 'name'),
          matchParam: 'campaignGroupId'
        }
      },
      { path: '/orders/:orderNumber/campaign-groups/:campaignGroupId/campaigns/new', breadcrumb: i18n.t('adSetSetupFlow.mainStep.createTitle') }
    ],
    adSetFormModel: useFbCreateAdSetFormModel,
    submit,
    setAdSet,
    setInitAdSet,
    validate: _.partial(validate, initAdSet, undefined),
    onImportSavedTA,
    setShowTAManagement
  };
};

export const useEditFbAdSetSetupFlowPageModel = (
  order: Order,
  campaignGroup: CampaignGroup,
  fbAdSetList: FbAdSet[],
  fbAdSetManager: FbAdSetManager = defaultFbAdSetManager
): AdSetSetupFlowPageModelData => {

  const match = useRouteMatch<{adSetId: string}>();
  const adSetId = match.params.adSetId;
  const {
    validate,
    callAPIs,
    fetchFbCountries,
    fetchFbSegments,
    setRedirectPath,
    setShowTAManagement,
    setAppliedSavedTAInfo,
    fbCountries,
    goSegments,
    otherAdsetMinBudget,
    audienceLowestThreshold,
    ...basicProps
  } = useFbAdSetSetupFlowPageModel(order, campaignGroup, fbAdSetList.filter(fbAdSet => fbAdSet.id.toString() !== adSetId));
  const [adSet, setAdSet] = useState<FbAdSetFormData | undefined>(undefined);
  const [initAdSet, setInitAdSet] = useState(adSet);
  const [minBudget, setMinBudget] = useState<number | undefined>(undefined);
  const parentBidStrategy = _.get(campaignGroup, 'fb.bid_strategy');
  useEffect(() => {
    if (!adSetId) {
      return;
    }
    callAPIs([
      fbAdSetManager.getAdSet.bind(fbAdSetManager, adSetId),
      fbAdSetManager.getMinBudget.bind(fbAdSetManager, adSetId),
      fetchFbCountries,
      fetchFbSegments
    ], (fbAdSet, minBudget) => {
      const hasSpendLimits = !!fbAdSet.lifetime_min_spend_target || !!fbAdSet.lifetime_spend_cap;
      const adSetFormData = {
        account_id: _.get(campaignGroup, 'fb.account_id'),
        ...fbAdSet,
        bid_amount: fbAdSet.bid_amount ? fbAdSet.bid_amount : '',
        bid_strategy: parentBidStrategy ? parentBidStrategy : fbAdSet.bid_strategy,
        hasSpendLimits,
        lifetime_min_spend_target: hasSpendLimits && fbAdSet.lifetime_min_spend_target ? fbAdSet.lifetime_min_spend_target : '',
        lifetime_spend_cap: hasSpendLimits && fbAdSet.lifetime_spend_cap ? fbAdSet.lifetime_spend_cap : '',
        dayPart: fbAdSet.dayPart
      };
      setAdSet(adSetFormData);
      setInitAdSet(adSetFormData);
      setMinBudget(minBudget);
      if (campaignGroup.groupId !== fbAdSet.groupId) {
        const redirectPath =
          `/orders/${order.orderNumber}/campaign-groups/${campaignGroup.groupId}/campaigns/${adSetId}/edit/error404`;
        setRedirectPath(redirectPath);
      }
    });
  }, [campaignGroup, adSetId, fbAdSetManager, fetchFbCountries, fetchFbSegments, callAPIs, parentBidStrategy, order.orderNumber, setRedirectPath]);

  const submit = async () => {
    if (!adSet) {
      return;
    }
    callAPIs([() => fbAdSetManager.editAdSet(order.id, campaignGroup, adSetId, adSet, fbCountries)], () => {
      basicProps.setFinished(true);
      basicProps.backToCampaignGroupDetail();
    });
  };

  const core = useContext(coreContext);
  const optimizationGoal = _.get(adSet, 'optimization_goal');
  const targeting = _.get(adSet, 'targeting');
  const accountId = _.get(adSet, 'account_id');
  const limitationModel = useMemo(() => {
    return core && targeting && accountId ? new DefaultEditLimitationModel(
      adSetInventorySetting('create', optimizationGoal, fbCountries, goSegments),
      targeting,
      {
        need: [OPERATE.INCLUDE],
        notNeed: [OPERATE.EXCLUDE]
      },
      core.addonFeatureManager.addonFeature,
      _.partial(setShowTAManagement, true),
      optimizationGoal === GoCampaignOptimizationGoal.OFFSITE_CONVERSIONS ? { include: ['user_os'] } : { include: ['geo_locations'] },
      {
        channel: CampaignGroupChannel.FB,
        audienceLowestThreshold,
        estimatedRequiredFields: ['geo_locations'],
        channelTargetingGetter: (limitationValue) => fbAdSetManager.wrapTargetingForServer(limitationValue, fbCountries),
        accountId: accountId.toString()
      }
    ) : undefined;
  }, [core, targeting, accountId, optimizationGoal, fbCountries, goSegments, setShowTAManagement, audienceLowestThreshold, fbAdSetManager]);

  const onImportSavedTA = (savedTAInfo: SelectOptions, limitationValue) => {
    if (!limitationModel) {
      return;
    }
    setAppliedSavedTAInfo(savedTAInfo);
    limitationModel.updateLimitationValue(limitationValue);
  };

  return {
    ...basicProps,
    objectType: 'adSet',
    adSet,
    initAdSet,
    fbCountries,
    goSegments,
    limitationModel,
    audienceLowestThreshold,
    title: i18n.t('adSetSetupFlow.mainStep.editTitle'),
    canEditOptimizationGoal: false,
    otherAdsetMinBudget,
    showPublishBindingFailed: false,
    breadcrumbs: [
      { path: '/orders', breadcrumb: i18n.t('orderDetail.labels.title') },
      {
        path: '/orders/:orderNumber',
        breadcrumb: DynamicBreadcrumb,
        props: {
          label: _.get(order, 'projectName'),
          matchParam: 'orderNumber'
        }
      },
      {
        path: '/orders/:orderNumber/campaign-groups/:campaignGroupId',
        breadcrumb: DynamicBreadcrumb,
        props: {
          label: _.get(campaignGroup, 'name'),
          matchParam: 'campaignGroupId'
        }
      },
      {
        path: '/orders/:orderNumber/campaign-groups/:campaignGroupId/campaigns/:adSetId/edit',
        breadcrumb: DynamicBreadcrumb,
        props: {
          prefix: i18n.t('common.labels.edit'),
          label: _.get(initAdSet, 'name'),
          matchParam: 'adSetId'
        }
      }
    ],
    adSetFormModel: useFbEditAdSetFormModel,
    submit,
    setAdSet,
    setInitAdSet,
    validate: _.partial(validate, initAdSet, minBudget),
    setRedirectPath,
    onImportSavedTA,
    setShowTAManagement
  };
};

export const useEditFbAdSetDraftSetupFlowPageModel = (
  order: Order,
  campaignGroup: CampaignGroup,
  fbAdSetList: FbAdSet[],
  fbAdSetManager: FbAdSetManager = defaultFbAdSetManager,
  draftManager: DraftManager = defaultDraftManager
): AdSetSetupFlowPageModelData => {

  const match = useRouteMatch<{draftId: string}>();
  const draftId = match.params.draftId;

  const data = useCreateFbAdSetSetupFlowPageModel(
    order,
    campaignGroup,
    fbAdSetList
  );

  const { loading, callAPIs } = useCallAPI();
  const { defaultOptimizationGoal, setAdSet, setInitAdSet } = data;
  const [showPublishBindingFailed, setShowPublishBindingFailed] = useState(false);

  useEffect(() => {
    callAPIs([() => draftManager.getDraft(draftId)], (draft) => {
      const optimizationGoalOptions = fbAdSetManager.getOptimizationGoalOptions(campaignGroup);
      const optimizeGoalOption = optimizationGoalOptions.find(option => option.value === draft.optimization_goal);
      const optimizeGoal = defaultOptimizationGoal ?
        defaultOptimizationGoal :
        optimizeGoalOption ?
          optimizeGoalOption.value :
          fbAdSetManager.getDefaultOptimizationGoal(campaignGroup);
      const billingEventOptions = fbAdSetManager.getBilliingEventOptions(campaignGroup, optimizeGoal);
      const billingEventOption = billingEventOptions.find(option => option.value === draft.billing_event);
      const billingEvent = billingEventOption ?
        billingEventOption.value :
        fbAdSetManager.getDefaultBillingEvent(campaignGroup);
      const strategy = _.get(campaignGroup, 'fb.bid_strategy', draft.bid_strategy);
      const adSet = {
        ...draft,
        optimization_goal: optimizeGoal,
        billing_event: billingEvent,
        bid_strategy: strategy ? strategy : FBBidStrategy.LOWEST_COST_WITHOUT_CAP
      };
      setAdSet(adSet);
      setInitAdSet(adSet);
    });
  }, [draftId, draftManager, setAdSet, setInitAdSet, callAPIs, campaignGroup, defaultOptimizationGoal, fbAdSetManager]);

  const onSaveDraft = (adSet: FbAdSetFormData) => {
    callAPIs([() => draftManager.updateDraft(draftId, fbAdSetManager.getCreateAdSetPayloadForServer(
      order.id,
      campaignGroup,
      adSet,
      data.fbCountries
    ))], () => {
      toast.success(i18n.t('adSetSetupFlow.messages.updateDraftSuccess'));
    }, () => {
      toast.error(i18n.t('adSetSetupFlow.messages.updateDraftFailed'));
    });
  };

  const onPublishDraft = async () => {
    const adSet = data.adSet;
    if (!adSet) {
      return;
    }
    callAPIs([draftManager.publishDraft.bind(draftManager, draftId, fbAdSetManager.getCreateAdSetPayloadForServer(
      order.id,
      campaignGroup,
      adSet,
      data.fbCountries
    ))], ({
      includeBinding,
      result
    }) => {
      if (result === 'SUCCESS') {
        if (includeBinding) {
          toast.success(i18n.t('adSetSetupFlow.messages.publishL2L3Success'));
        } else {
          toast.success(i18n.t('adSetSetupFlow.messages.publishL2Success'));
        }
        data.setFinished(true);
        data.backToCampaignGroupDetail();
      }
      if (result === 'PARTIAL_SUCCESS') {
        setShowPublishBindingFailed(true);
      } else if (result === 'FAIL') {
        toast.error(i18n.t('adSetSetupFlow.messages.publishL2L3Failed'));
      }
    });
  };

  return {
    ...data,
    objectType: 'draft',
    loading: data.loading || loading,
    title: i18n.t('campaign.labels.editCampaignDraftTitle'),
    showPublishBindingFailed,
    onSaveDraft,
    submit: onPublishDraft,
    breadcrumbs: [
      { path: '/orders', breadcrumb: i18n.t('orderDetail.labels.title') },
      {
        path: '/orders/:orderNumber',
        breadcrumb: DynamicBreadcrumb,
        props: {
          label: _.get(order, 'projectName'),
          matchParam: 'orderNumber'
        }
      },
      {
        path: '/orders/:orderNumber/campaign-groups/:campaignGroupId',
        breadcrumb: DynamicBreadcrumb,
        props: {
          label: _.get(campaignGroup, 'name'),
          matchParam: 'campaignGroupId'
        }
      },
      {
        path: '/orders/:orderNumber/campaign-groups/:campaignGroupId/drafts/:draftId/edit',
        breadcrumb: DynamicBreadcrumb,
        props: {
          prefix: i18n.t('common.labels.edit'),
          label: _.get(data.initAdSet, 'name'),
          matchParam: 'draftId'
        }
      }
    ],
    getSummaryData: (adSet: any) => {
      const summaryData = data.getSummaryData(adSet);
      if (adSet.bindings && adSet.bindings.length > 0) {
        const now = moment();
        const isDeliverying = now.isAfter(moment(adSet.start_time)) && now.isBefore(moment(adSet.end_time));
        const isActive = adSet.configured_status === 'ACTIVE';
        const hasActiveBinding = adSet.bindings.find(binding => binding.active) !== undefined;
        const showAlert = isDeliverying && isActive && hasActiveBinding;
        summaryData['draftBindingSummary'] = {
          title: i18n.t('adSetSetupFlow.summaryStep.draftBindingTitle'),
          data: {
            draftBinding: {
              content: _.compact([
                {
                  label: i18n.t('adSetSetupFlow.summaryStep.labels.draftBinding'),
                  value: adSet.bindings.length,
                  hintColor: 'red',
                  hint: showAlert ? i18n.t('adSetSetupFlow.summaryStep.message.willDeliverImmediately') : undefined
                }
              ])
            }
          }
        };
      }
      return summaryData;
    }
  };
};

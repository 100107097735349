import { ModalState, ModalStateFactory } from 'containers/Common/ModalStateFactory';
import { CampaignGroup, CampaignGroupChannel } from 'core/campaignGroup/CampaignGroup';
import { DefaultOrderManager, OrderManager } from 'core/order/OrderManager';
import { DraftSetupState, FbAdSetDraftSetupState, RetailCampaignDraftSetupState, RtbCampaignDraftSetupState, TiktokAdGroupDraftSetupState } from './DraftSetupState';
import { DraftSummaryState, FbAdSetDraftSummaryState, RetailCampaignDraftSummaryState, RtbCampaignDraftSummaryState, TiktokAdGroupDraftSummaryState } from './DraftSummaryState';

export class DraftCreateModalStateFactory implements ModalStateFactory {

  channelStatesMap = {
    [CampaignGroupChannel.RTB]: {
      setupState: RtbCampaignDraftSetupState,
      summaryState: RtbCampaignDraftSummaryState
    },
    [CampaignGroupChannel.FB]: {
      setupState: FbAdSetDraftSetupState,
      summaryState: FbAdSetDraftSummaryState
    },
    [CampaignGroupChannel.TIKTOK]: {
      setupState: TiktokAdGroupDraftSetupState,
      summaryState: TiktokAdGroupDraftSummaryState
    },
    [CampaignGroupChannel.RETAIL_MEDIA]: {
      setupState: RetailCampaignDraftSetupState,
      summaryState: RetailCampaignDraftSummaryState
    }
  };

  constructor (
    private campaignGroup: CampaignGroup,
    private goCampaignsToCreateDraft: {
      id: number | string, isDraft: boolean
    }[],
    private orderManager: OrderManager = new DefaultOrderManager()
  ) {}

  async initStates (goModalStateFn: (state: ModalState) => void, closeModalFn: (dirty) => void) {
    const orderOptions = await this.orderManager.getOrderOptions();
    const channelStates = this.channelStatesMap[this.campaignGroup.channel];
    const draftSetupState: DraftSetupState =
      new channelStates.setupState(orderOptions, this.campaignGroup, this.goCampaignsToCreateDraft);
    const draftSummaryState: DraftSummaryState =
      new channelStates.summaryState(this.campaignGroup, this.goCampaignsToCreateDraft);
    draftSetupState.next = () => goModalStateFn(draftSummaryState);
    draftSetupState.close = () => closeModalFn(false);
    draftSummaryState.back = () => goModalStateFn(draftSetupState);
    draftSummaryState.close = closeModalFn;
    return draftSetupState;
  }
}

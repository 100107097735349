import React, { Fragment } from 'react';
import { Fields, GroupBy, OrderDownloadReportPanelProps, OrderDownloadReportPanelState, ReportDownloadPanelTab } from './OrderDownloadReportPanelModel';
import styles from './orderDownloadReportPanel.module.scss';
import moment from 'moment';
import { Modal } from 'components/common/Modal/Modal';
import i18n from 'i18next';
import _ from 'lodash';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { DateRangePicker, DateRange } from 'components/common/DateRangePicker/DateRangePicker';
import FormGroup from 'components/common/FormGroup/FormGroup';
import { Form, Tab, Tabs } from 'react-bootstrap';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { addOnEnabled } from 'core/permission/PermissionDSL';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Select from 'components/common/Select/Select';

export const defaultFormat = 'YYYY-MM-DD_HH:mm:ss';
export class OrderDownloadReportPanel extends React.Component<OrderDownloadReportPanelProps, OrderDownloadReportPanelState> {

  handler: number;

  constructor (props) {
    super(props);
    this.handler = this.props.model.event.add((model) => {
      this.setState(model.state);
    });
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add((model) => {
        this.setState(model.state);
      });
    }
  }

  componentWillUnmount () {
    this.props.model.event.remove(this.handler);
  }

  hide = () => {
    this.props.model.cancel();
  }

  handleDateRangeChange = (startDate, endDate) => {
    this.props.model.updateDateRange(startDate, endDate);
  }

  download = () => {
    this.props.model.downloadReport();
  }

  renderFieldChecks = (...fields) => {
    const fieldsDom = fields.map(field => (
      <Form.Check
        id={field}
        key={field}
        type='checkbox'
        checked={this.props.model.state.selectFields[field]}
        label={i18n.t(`orderDownloadReportPanel.labels.${_.camelCase(field)}`)}
        onChange={this.props.model.onFieldSelect}
      />
    ));
    return (
      <div className={styles.fieldChecks}>
        {fieldsDom}
      </div>
    );
  }

  render () {
    const dateRangeShortCut: DateRange[] = [
      {
        label: 'Today',
        dateRange: [moment().startOf('day').toDate(), moment().endOf('day').toDate()]
      },
      {
        label: 'Yesterday',
        dateRange: [moment().subtract(1, 'days').startOf('day').toDate(), moment().subtract(1, 'days').endOf('day').toDate()]
      },
      {
        label: 'Last week',
        dateRange: [moment().subtract(7, 'days').startOf('day').toDate(), moment().subtract(1, 'days').endOf('day').toDate()]
      }
    ];
    const model = this.props.model;
    const renderQuickSelect = () => model.reportTypeOptions.map(option => (
      <div
        key={option.value}
        onClick={_.partial(model.onQuickFieldSelect, option.value.toString())}
      >
        {option.label}
      </div>
    ));
    const convsEnabled = model.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CONVERSION_TRACKING.CONV_TRACKING_LIST);
    return (
      <Fragment>
        {model.state.loading && <LoadingIndicator />}
        <Modal
          title={i18n.t('orderDownloadReportPanel.labels.downloadReport')}
          dismiss={this.hide}
          animation={false}
          className={styles.orderDownloadReportPanel}
          fullScreen
          enforceFocus={false}
          primaryButton={{
            title: i18n.t('orderDownloadReportPanel.buttons.download'),
            callback: this.download,
            disabled: !model.state.enableDownload
          }}
          secondaryButton={{
            title: i18n.t('common.buttons.cancel'),
            callback: this.hide
          }}
        >
          <Tabs
            id='reportDownloadPanelTab'
            defaultActiveKey={ReportDownloadPanelTab.NORMAL}
            activeKey={model.state.activeTab}
            onSelect={model.onSelectTab}
          >
            <Tab eventKey={ReportDownloadPanelTab.NORMAL} title={i18n.t('orderDownloadReportPanel.labels.normal')}>
              <div className={styles.content}>
                <FormGroup controlId='dateRange' label={i18n.t('orderDownloadReportPanel.labels.dateRange')}>
                  <DateRangePicker
                    popOverClassName={styles.datePicker}
                    onChange={this.handleDateRangeChange}
                    startDate={model.state.startDate}
                    endDate={model.state.endDate}
                    minDate={model.order.startDate}
                    maxDate={model.order.endDate}
                    format={'YYYY-MM-DD'}
                    showTimePicker={false}
                    shortcuts={dateRangeShortCut}
                  />
                </FormGroup>
                <FormGroup controlId='reportType' label={i18n.t('orderDownloadReportPanel.labels.reportType')}>
                  <Select
                    options={model.reportTypeOptions}
                    name='reportOptions'
                    simpleValue
                    onChange={model.onSelectReportType}
                    placeholder={i18n.t('orderDownloadReportPanel.placeholders.reportType')}
                    maxMenuHeight={150}
                    value={model.state.reportType}
                  />
                </FormGroup>
              </div>
            </Tab>
            {model.addonFeatureManager.isFeatureEnable(ADDONFEATURE.REPORT.REPORT_CLOSINGREPORT) &&
              <Tab eventKey={ReportDownloadPanelTab.CLOSING} title={i18n.t('orderDownloadReportPanel.labels.closing')}>
                <div className={styles.content}>
                  <FormGroup controlId='closingReportType' label={i18n.t('orderDownloadReportPanel.labels.reportType')}>
                    <Select
                      options={model.closingReportTypeOptions}
                      name='closingReportOptions'
                      simpleValue
                      onChange={model.onSelectClosingReportType}
                      placeholder={i18n.t('orderDownloadReportPanel.placeholders.reportType')}
                      maxMenuHeight={150}
                      value={model.state.closingReportType}
                    />
                  </FormGroup>
                </div>
              </Tab>
            }
            {model.addonFeatureManager.isFeatureEnable(ADDONFEATURE.REPORT.REPORT_ORDER_CUSTOM) &&
              <Tab eventKey={ReportDownloadPanelTab.ADVANCE} title={i18n.t('orderDownloadReportPanel.labels.advance')}>
                <div className={styles.content}>
                  <FormGroup controlId='dateRange' label={i18n.t('orderDownloadReportPanel.labels.dateRange')}>
                    <DateRangePicker
                      popOverClassName={styles.datePicker}
                      onChange={this.handleDateRangeChange}
                      startDate={model.state.startDate}
                      endDate={model.state.endDate}
                      minDate={model.order.startDate}
                      maxDate={model.order.endDate}
                      format={'YYYY-MM-DD'}
                      showTimePicker={false}
                      shortcuts={dateRangeShortCut}
                    />
                  </FormGroup>
                  <FormGroup controlId='groupBy' label={i18n.t('orderDownloadReportPanel.labels.groupBy')}>
                    <div className={styles.groupBy}>
                      <Form.Check
                        type='radio'
                        label={i18n.t('orderDownloadReportPanel.labels.groupByCreative')}
                        value={GroupBy.CREATIVE}
                        name='groupBy'
                        id='groupByCreative'
                        defaultChecked
                        onChange={model.changeGroupBy}
                      />
                      <Form.Check
                        type='radio'
                        label={i18n.t('orderDownloadReportPanel.labels.groupByCampaign')}
                        name='groupBy'
                        value={GroupBy.CAMPAIGN}
                        id='groupByCampaign'
                        onChange={model.changeGroupBy}
                      />
                    </div>
                  </FormGroup>
                  <FormGroup className={styles.dataContent} controlId='dataContent' label={i18n.t('orderDownloadReportPanel.labels.dataContent')} />
                  <FormGroup controlId='quickSelect' label={i18n.t('orderDownloadReportPanel.labels.quickSelect')}>
                    <div>
                      <div className={styles.quickSelect}>
                        {renderQuickSelect()}
                        <div onClick={_.partial(model.onQuickFieldSelect, 'reset')}>
                          {i18n.t('orderDownloadReportPanel.labels.reset')}
                        </div>
                      </div>
                      {this.renderFieldChecks(Fields.IMPRES, Fields.VIEWABLE, Fields.UNIQUE_USERS)}
                      {this.renderFieldChecks(Fields.CLICKS, Fields.CTR, Fields.VCTR)}
                      {this.renderFieldChecks(Fields.VIEW, Fields.VIEW_RATE, Fields.VIEWABLE_VIEW_RATE)}
                      {convsEnabled ? this.renderFieldChecks(Fields.CONVS, Fields.SPENT) : this.renderFieldChecks(Fields.SPENT)}
                      {convsEnabled ? this.renderFieldChecks(Fields.CPM, Fields.CPC, Fields.CPV, Fields.CPA) : this.renderFieldChecks(Fields.CPM, Fields.CPC, Fields.CPV)}
                    </div>
                  </FormGroup>
                  <PermissionChecker permissionAware={addOnEnabled(ADDONFEATURE.CREATIVES.VIDEO, ADDONFEATURE.CREATIVES.THIRDPARTY, ADDONFEATURE.CREATIVES.COMBO, ADDONFEATURE.CREATIVES.OUTDOOR)}>
                    <FormGroup controlId='videoAdViewObjective' label={i18n.t('orderDownloadReportPanel.labels.videoAdViewObjective')}>
                      {this.renderFieldChecks(Fields.FIRST_QUARTILE, Fields.MID_POINT, Fields.THIRD_QUARTILE, Fields.COMPLETE)}
                    </FormGroup>
                  </PermissionChecker>
                  <PermissionChecker permissionAware={addOnEnabled(ADDONFEATURE.REPORT.REPORT_CLOSINGREPORT)}>
                    <FormGroup controlId='divider' label=''>
                      <div className={styles.divider} />
                    </FormGroup>
                    <FormGroup controlId='audienceData' label={i18n.t('orderDownloadReportPanel.labels.audienceData')}>
                      <IconWithTooltip
                        className={styles.audienceDataHint}
                        icon={faInfoCircle}
                        tooltipProps={{
                          id: 'audienceDataHint',
                          tooltip: i18n.t('orderDownloadReportPanel.labels.audienceDataHint')
                        }}
                      />
                      {this.renderFieldChecks(Fields.GENDER, Fields.AGE, Fields.INTERESTS)}
                    </FormGroup>
                  </PermissionChecker>
                </div>
              </Tab>
            }
          </Tabs>
        </Modal>
      </Fragment>
    );
  }
}

import React from 'react';
import classnames from 'classnames/bind';
import styles from './ThirdPartyTrackingCode.module.scss';
import { Form } from 'react-bootstrap';

class ThirdPartyTrackingCodeCell extends React.Component<any> {
  cssClass: any;
  constructor (props) {
    super(props);
    this.cssClass = classnames.bind(styles);
  }

  render () {
    const { previewTrackingCode, className: parentClass } = this.props;
    const className = this.cssClass(parentClass, ['previewTrackingCode']);
    return (
      <div>
        <Form.Control
          name='ThirdPartyTrackingCodeCell'
          type='text'
          value={previewTrackingCode}
          disabled={true}
          className={className}
        />
      </div>
    );
  }
}

export default ThirdPartyTrackingCodeCell;

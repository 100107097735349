import { CreativeListRecord, CreativeOfCampaign } from 'core/creative/Creative';
import React from 'react';
import styles from './creativePreview.module.scss';
import adneonPlaceholder from 'assets/campaign/adType-thirdParty.png';
import cx from 'classnames/bind';

export const RetailProductCreativePreview: React.FunctionComponent<{creative: CreativeOfCampaign | CreativeListRecord}> = (props) => {

  const classNames = cx.bind(styles);
  const previewClass = classNames('retailProductCreativePreview', {
    border: true
  });
  return (
    <div className={previewClass}>
      <img src={adneonPlaceholder} alt={'preview'} />
    </div>
  );
};

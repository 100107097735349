import React from 'react';
import { faCode, faDownload, faPencilAlt, faUsers } from '@fortawesome/free-solid-svg-icons';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import TableRowToolBar from 'containers/TableRowToolBar/TableRowToolBar';
import { Link } from 'react-router-dom';
import styles from './goSegmentList.module.scss';
import i18n from 'i18n';
import { GoSegmentListRecord, GoSegmentType } from 'core/goSegment/GoSegment';
import { GoSegmentStatus } from 'components/GoSegmentStatus/GoSegmentStatus';
import Progress from 'components/common/Progress/Progress';
import _ from 'lodash';
import moment from 'moment';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { addOnEnabled, hasRoles } from 'core/permission/PermissionDSL';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { RoleNames } from 'core';

function isUploadFailed (goSegment: GoSegmentListRecord) {
  return goSegment.status === 'WAITING_FOR_UPLOAD'
    && moment().diff(moment(goSegment.created_at), 'hours') > 2;
}

const nameFormatter = (id: any, goSegment: GoSegmentListRecord, _2): any => {
  return (
    <div className={`${styles.nameCell}`}>
      <Link to={`/segments/${id}/edit`} className={styles.name}>
        {goSegment.name}
      </Link>
      <div className={styles.id}>
        {'ID: ' + id}
      </div>
    </div>
  );
};

const detailFormatter = (_1, goSegment: GoSegmentListRecord) => {
  return (
    <GoSegmentStatus goSegment={goSegment}/>
  );
};

const statusFormatter = (status, goSegment: GoSegmentListRecord) => {
  const uploading = status === 'WAITING_FOR_UPLOAD' && goSegment.progress;
  return (
    <div>
      <div>
        {isUploadFailed(goSegment) ?
          <span className={styles.danger}>Upload Failed</span> :
          uploading ? 'Uploading Data' : _.startCase(_.lowerCase(status))
        }
      </div>
      {uploading &&
        <Progress
          progress={goSegment.progress ? goSegment.progress : 0}
        />
      }
    </div>
  );
};

const floatingEditBtnsFormatter = (_1, goSegment: GoSegmentListRecord, _2, formatExtraData: any): any => {
  // const onDeleteBtnClick = () => formatExtraData.onDeleteBtnClick(goSegment.segmentId);
  const canDownload = goSegment.status !== 'WAITING_FOR_UPLOAD';
  const canCreateLookalike = canDownload && goSegment.type !== GoSegmentType.LOOKALIKE && !isUploadFailed(goSegment);
  const onGetSQLClick = () => formatExtraData.onGetSQLClick(goSegment.audienceBrief);
  const onDownloadClick = () => formatExtraData.onDownloadClick(goSegment.segmentId);
  return (
    <TableRowToolBar className={styles.floatingEditArea}>
      <Link
        to={`/segments/${goSegment.segmentId}/edit`}
      >
        <IconWithTooltip
          icon={faPencilAlt}
          tooltipProps={{
            id: `goSegmentList-editTip-${goSegment.segmentId}`,
            tooltip: i18n.t('goSegmentList.hints.edit')
          }}
        />
      </Link>
      {canCreateLookalike &&
        <PermissionChecker
          permissionAware={
            hasRoles(RoleNames.sysAdmin).or(addOnEnabled(ADDONFEATURE.COMPANY.LOOKALIKE_AUDIENCE_CREATION))
          }
        >
          <Link
            to={{
              pathname: `/segments/new`,
              state: {
                seedSegmentId: goSegment.segmentId
              }
            }}
          >
            <IconWithTooltip
              icon={faUsers}
              tooltipProps={{
                id: `goSegmentList-lookalikeTip-${goSegment.segmentId}`,
                tooltip: i18n.t('goSegmentList.hints.createLookalike')
              }}
            />
          </Link>
        </PermissionChecker>
      }
      {goSegment.audienceBrief &&
        <IconWithTooltip
          icon={faCode}
          onClick={onGetSQLClick}
          tooltipProps={{
            id: `goSegmentList-codeTip-${goSegment.segmentId}`,
            tooltip: i18n.t('goSegmentList.hints.getSql')
          }}
        />
      }
      {canDownload &&
        <IconWithTooltip
          icon={faDownload}
          onClick={onDownloadClick}
          tooltipProps={{
            id: `goSegmentList-download-${goSegment.segmentId}`,
            tooltip: i18n.t('report.labels.download')
          }}
        />
      }
      {/* <IconWithTooltip
        icon={faTrashAlt}
        onClick={onDeleteBtnClick}
        tooltipProps={{
          id: `goSegmentList-deleteTip-${goSegment.segmentId}`,
          tooltip: i18n.t('goSegmentList.hints.delete')
        }}
      /> */}
    </TableRowToolBar>
  );
};

export default {
  nameFormatter,
  statusFormatter,
  detailFormatter,
  floatingEditBtnsFormatter
};

import React, { Fragment } from 'react';
import styles from './imagePreview.module.scss';

export class ImagePreview extends React.Component<any, any> {

  static defaultProps = {
    size: undefined,
    border: true,
    config: {
      showName: true,
      showSize: true
    },
    customImageName: undefined
  };

  mount: boolean;

  constructor (props) {
    super(props);
    this.state = {
      previewSrc: undefined,
      name: '',
      size: ''
    };
    this.mount = false;
  }

  componentDidMount () {
    this.mount = true;
    this.loadImage();
  }

  componentDidUpdate () {
    this.loadImage();
  }

  componentWillUnmount () {
    this.mount = false;
  }

  loadImage () {
    if (this.props.file) {
      this.loadFile(this.props.file);
    } else if (this.props.url) {
      this.loadUrl(this.props.url);
    }
  }

  loadUrl (src, imageName?: string) {
    const image = new Image();
    image.src = src;
    image.onload = () => {
      if (image.src !== this.state.previewSrc) {
        this.mount && this.setState({
          previewSrc: image.src,
          name: imageName ? imageName : src,
          size: `${image.width} x ${image.height}`
        });
      }
    };
  }

  loadFile (file) {
    const reader = new FileReader();
    reader.onload = () => {
      this.loadUrl(reader.result, file.name);
    };
    reader.readAsDataURL(file);
  }

  render () {
    if (!this.state.previewSrc) {
      return <div className={styles.imgPreview}/>;
    }
    const className = `${this.props.className} ${styles.imgPreview}`;
    const imgPreviewAreaClassName = this.props.border ? `${styles.imgPreviewArea} ${styles.withBorder}` : styles.imgPreviewArea;
    return (
      <div className={className}>
        <div
          className={imgPreviewAreaClassName}
          style={this.props.size ?
            {
              width: this.props.size.width,
              height: this.props.size.height
            } : {
              width: 'fit-content',
              height: 'fit-content'
            }
          }
        >
          <img
            style={{ objectFit: 'contain' }}
            src={this.state.previewSrc}
            alt={'preview area'}
          />
        </div>
        <Fragment>
          {
            this.props.config.showName &&
            <div className={styles.imageName}>
              {this.props.customImageName ? this.props.customImageName : this.state.name}
            </div>
          }
          {
            this.props.config.showSize &&
            <div className={styles.imageSize}>
              {this.state.size}
            </div>
          }
        </Fragment>
      </div>
    );
  }
}

import React from 'react';
import { AdLogoFormProps } from './AdLogoFormModel';
import { connect, FormikContext, Field } from 'formik';
import styles from './adLogoForm.module.scss';
import i18n from 'i18next';
import { createSelectOptionsFromEnum } from 'utils/SelectOptionsUtils';
import FormGroup from 'components/common/FormGroup/FormGroup';
import classNames from 'classnames/bind';
import { ImagePreview } from 'components/common/Image/ImagePreview';
import previewBackground from 'assets/campaign/adLogoBackground.jpg';
import defaultAdLogo from 'assets/campaign/tenmax-ad-logo.svg';
import _ from 'lodash';
import { AdLogo, AdLogoType } from 'core/adLogo/AdLogo';
import { FormikImageInput } from 'components/common/formik/FormikImageInput';
import { FormikField } from 'components/common/formik/FormikField';
import { getFieldErrors } from 'utils/FormikUtils';

interface FormikPartProps {
  formik: FormikContext<{ adLogo: AdLogo }>;
}

const adLogoTypes = createSelectOptionsFromEnum(AdLogoType, 'adLogoForm.labels.')
  .filter(type => type.value !== AdLogoType.DEFAULT);
export class AdLogoForm extends React.Component<FormikPartProps & AdLogoFormProps> {

  cssClassNames: any;

  constructor (props) {
    super(props);
    this.cssClassNames = classNames.bind(styles);
  }

  renderImageInput = (formikProps) => {
    let hint;
    if (!getFieldErrors(formikProps.form, formikProps.field.name)) {
      hint = (
        <>
          {i18n.t('creativeSetupFlow.labels.typeHint')}<br/>
          {i18n.t('adLogoForm.labels.sizeHint')}
        </>
      );
      if (formikProps.field.value) {
        hint = i18n.t('creativeSetupFlow.labels.successHint');
      }
    }
    return (
      <FormikImageInput
        {...formikProps}
        supportType={['image/jpeg', 'image/jpg', 'image/png']}
        hocContent={hint}
      />
    );
  }

  openLink = (link) => {
    window.open(link, '_blank');
  }

  renderPreview () {
    const type = this.props.formik.values.adLogo.type;
    const customLogo = type === AdLogoType.CUSTOM ? this.props.formik.values.adLogo.image : undefined;
    const hasError = !!getFieldErrors(this.props.formik, 'adLogo.image');
    const defaultLogo = (
      <img
        className={styles.defaultLogo}
        src={defaultAdLogo}
        alt={'default-logo'}
        onClick={_.partial(this.openLink, 'https://www.tenmax.io')}
      />
    );
    return (
      <div className={styles.previewArea}>
        <img src={previewBackground} alt={'logo-background'} />
        {
          type === AdLogoType.DEFAULT ?
            defaultLogo :
            customLogo && !hasError &&
            <div
              className={styles.logoPreview}
              onClick={_.partial(this.openLink, this.props.formik.values.adLogo.link)}
            >
              <ImagePreview
                size={{ width: 32, height: 32 }}
                border={false}
                url={customLogo.url}
                file={customLogo.file}
                config={{
                  showName: false,
                  showSize: false
                }}
              />
            </div>
        }
        <span className={styles.tip}>{i18n.t('adLogoForm.labels.logoHint')}</span>
      </div>
    );
  }

  onTypeChange = (type) => {
    if (type === AdLogoType.CUSTOM) {
      this.props.formik.setFieldValue('adLogo.link', '');
      this.props.formik.setFieldValue('adLogo.image', {});
    } else {
      this.props.formik.setFieldValue('adLogo.link', undefined);
      this.props.formik.setFieldValue('adLogo.image', undefined);
    }
    this.props.formik.setFieldTouched('adLogo.link');
    this.props.formik.setFieldTouched('adLogo.image');
  }

  render () {
    const type = this.props.formik.values.adLogo.type;
    return (
      <fieldset>
        <legend>
          <span>{i18n.t('adLogoForm.labels.title')}</span>
        </legend>
        <div className={styles.component}>
          <FormikField.Select
            className={styles.adLogoType}
            label={i18n.t('adLogoForm.labels.logoType')}
            name='adLogo.type'
            simpleValue
            options={adLogoTypes}
            onChange={this.onTypeChange}
          />
          {
            type === AdLogoType.CUSTOM &&
            <FormikField.Input
              label={i18n.t('adLogoForm.labels.logoLink')}
              name='adLogo.link'
            />
          }
          {
            type === AdLogoType.CUSTOM &&
            <FormGroup controlId='adLogoImage' label={i18n.t('adLogoForm.labels.logoUpload')}>
              <Field
                name='adLogo.image'
                render={this.renderImageInput}
              />
            </FormGroup>
          }
          <FormGroup controlId='adLogoPreview' label={i18n.t('adLogoForm.labels.logoPreview')}>
            {this.renderPreview()}
          </FormGroup>
        </div>
      </fieldset>
    );
  }
}

export default connect<AdLogoFormProps, { adLogo: AdLogo }>(AdLogoForm);

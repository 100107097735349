import logo_facebook from 'assets/facebook.png';
import logo_instagram from 'assets/instagram.png';
import logo_openRTB from 'assets/openRTB.png';
import logo_audience_network from 'assets/audience_network.png';
import logo_messenger from 'assets/messenger.png';
import logo_tiktok from 'assets/tiktok.png';

export enum Performance {
  OVERALL = 'overall',
  DEEP_DIVE = 'deepDive'
}

export enum ReportSection {
  PLATFORM = 'platform',
  USERS = 'users'
}

export enum NoteSection {
  PLATFORM = 'platform',
  ADS_IMPACT = 'ads_impact',
  ADS_IMPACT_USERS = 'ads_impact_users',
  USERS = 'users'
}

export enum Platform {
  FB = 'facebook',
  MESSENGER = 'messenger',
  AUDIENCE_NETWORK = 'audience_network',
  IG = 'instagram',
  TIKTOK = 'tiktok',
  RTB = 'open_rtb',
  UNKNOWN = 'unknown'
}

export enum BasicSetting {
  CAMPAIGN_NAME = 'campaign_name',
  PERIOD = 'period',
  BUDGET = 'budget'
}

export enum Group {
  PLATFORM = 'platform',
  AGE_GROUP = 'age_group',
  GENDER = 'gender',
  AUDIENCE_SEGMENT = 'audience_segment',
  DOMAIN = 'domain',
  REGION = 'region',
  CREATIVE = 'creative',
  SERVICE_AREA = 'service_area'
}

export enum Column {
  IMPRESSIONS = 'impressions',
  REACH = 'reach',
  CLICKS = 'clicks',
  CTR = 'CTR',
  CPC = 'CPC',
  CPM = 'CPM',
  SALES = 'sales',
  TRANSACTIONS = 'transactions',
  ITEMS_SOLD = 'items_sold',
  NEW_USERS = 'new_users',
  LAPSE_USERS = 'lapse_users',
  EXISTING_USERS = 'existing_users',
  BASKET_SIZE = 'basket_size'
}

export enum Note {
  ACTUAL_IMPRESSIONS = 'actual_impressions',
  TARGET_IMPRESSIONS = 'target_impressions',
  IMPRESSIONS = 'impressions',
  REACH = 'reach',
  REACH_TIKTOK = 'reach_tiktok',
  CLICKS = 'clicks',
  CTR = 'CTR',
  CPC = 'CPC',
  CPM = 'CPM',
  FB = 'facebook',
  SALES = 'sales',
  TRANSACTIONS = 'transactions',
  ITEMS_SOLD = 'items_sold',
  NEW_USERS = 'new_users',
  LAPSE_USERS = 'lapse_users',
  EXISTING_USERS = 'existing_users',
  BASKET_SIZE = 'basket_size',
  SERVICE_AREA = 'service_area',
  BENCHMARKS = 'benchmarks',
  OTHERS = 'others'
}

export enum Benchmark {
  AWARENESS = 'AWARENESS',
  TRAFFIC = 'TRAFFIC',
  MIXED = 'MIXED'
}

export const LOGO = Object.freeze({
  [Platform.FB]: {
    src: logo_facebook,
    size: 'ordinary'
  },
  [Platform.IG]: {
    src: logo_instagram,
    size: 'ordinary'
  },
  [Platform.RTB]: {
    src: logo_openRTB,
    size: 'ordinary'
  },
  [Platform.AUDIENCE_NETWORK]: {
    src: logo_audience_network,
    size: 'small'
  },
  [Platform.MESSENGER]: {
    src: logo_messenger,
    size: 'small'
  },
  [Platform.TIKTOK]: {
    src: logo_tiktok,
    size: 'ordinary'
  }
});

export const CHART_COLOR = Object.freeze({
  0: '#70AF85',
  1: '#B3E283',
  2: '#FF7F3F',
  3: '#FABB51',
  4: '#F7D59C',
  5: '#C8C8C8',
  [Column.CTR]: 'black',
  RANDOM: () => {
    let round = Math.round;
    let random = Math.random;
    let hex = 255;

    return `rgb(${round(random() * hex)}, ${round(random() * hex)}, ${round(random() * hex)})`;
  }
});

export const REPORT_GROUPS = Object.freeze({
  [Performance.OVERALL]: {
    [ReportSection.PLATFORM]: [
      Group.PLATFORM
    ]
  },
  [Performance.DEEP_DIVE]: {
    [ReportSection.PLATFORM]: {
      [Platform.RTB]: [
        Group.AGE_GROUP,
        Group.GENDER,
        Group.CREATIVE,
        Group.AUDIENCE_SEGMENT,
        Group.DOMAIN,
        Group.SERVICE_AREA
      ],
      [Platform.FB]: [
        Group.PLATFORM,
        Group.AGE_GROUP,
        Group.GENDER,
        Group.CREATIVE,
        Group.AUDIENCE_SEGMENT,
        Group.SERVICE_AREA
      ],
      [Platform.TIKTOK]: [
        Group.AGE_GROUP,
        Group.GENDER,
        Group.CREATIVE,
        Group.AUDIENCE_SEGMENT,
        Group.SERVICE_AREA
      ]
    },
    [ReportSection.USERS]: [
      Group.AGE_GROUP,
      Group.GENDER,
      Group.AUDIENCE_SEGMENT,
      Group.REGION
    ]
  }
});

export const COLUMNS_TEMPLATE = Object.freeze({
  [Performance.OVERALL]: {
    [ReportSection.PLATFORM]: [
      Column.IMPRESSIONS,
      Column.REACH,
      Column.CLICKS,
      Column.CTR,
      Column.CPC,
      Column.CPM
    ]
  },
  [Performance.DEEP_DIVE]: {
    [ReportSection.PLATFORM]: [
      Column.IMPRESSIONS,
      Column.REACH,
      Column.CLICKS,
      Column.CTR,
      Column.CPC,
      Column.CPM
    ],
    [ReportSection.USERS]: [
      Column.SALES,
      Column.TRANSACTIONS,
      Column.BASKET_SIZE,
      Column.ITEMS_SOLD,
      Column.NEW_USERS,
      Column.LAPSE_USERS,
      Column.EXISTING_USERS
    ]
  }
});

export const NOTES = Object.freeze({
  [Performance.OVERALL]: {
    [NoteSection.PLATFORM]: [
      Note.ACTUAL_IMPRESSIONS,
      Note.TARGET_IMPRESSIONS,
      Note.REACH,
      Note.CLICKS,
      Note.CTR,
      Note.CPC,
      Note.CPM,
      Note.FB,
      Note.BENCHMARKS
    ],
    [NoteSection.ADS_IMPACT]: [
      Note.SALES,
      Note.TRANSACTIONS,
      Note.ITEMS_SOLD,
      Note.NEW_USERS,
      Note.LAPSE_USERS,
      Note.EXISTING_USERS,
      Note.BASKET_SIZE
    ]
  },
  [Performance.DEEP_DIVE]: {
    [NoteSection.ADS_IMPACT_USERS]: [
      Note.NEW_USERS,
      Note.LAPSE_USERS,
      Note.EXISTING_USERS
    ],
    [NoteSection.USERS]: [
      Note.SALES,
      Note.TRANSACTIONS,
      Note.ITEMS_SOLD,
      Note.NEW_USERS,
      Note.LAPSE_USERS,
      Note.EXISTING_USERS,
      Note.BASKET_SIZE,
      Note.OTHERS
    ],
    [NoteSection.PLATFORM]: {
      [Platform.FB]: [
        Note.IMPRESSIONS,
        Note.REACH,
        Note.CLICKS,
        Note.CTR,
        Note.CPC,
        Note.CPM,
        Note.SERVICE_AREA,
        Note.BENCHMARKS,
        Note.OTHERS
      ],
      [Platform.RTB]: [
        Note.IMPRESSIONS,
        Note.REACH,
        Note.CLICKS,
        Note.CTR,
        Note.CPC,
        Note.CPM,
        Note.SERVICE_AREA,
        Note.BENCHMARKS,
        Note.OTHERS
      ],
      [Platform.TIKTOK]: [
        Note.IMPRESSIONS,
        Note.REACH_TIKTOK,
        Note.CLICKS,
        Note.CTR,
        Note.CPC,
        Note.CPM,
        Note.SERVICE_AREA,
        Note.OTHERS
      ]
    }
  }
});

export const LABEL = Object.freeze({
  [BasicSetting.CAMPAIGN_NAME]: 'Campaign Name',
  [BasicSetting.PERIOD]: 'Period',
  [BasicSetting.BUDGET]: 'Budget',
  [Note.IMPRESSIONS]: 'Impressions',
  [Note.ACTUAL_IMPRESSIONS]: 'Actual Impressions',
  [Note.TARGET_IMPRESSIONS]: 'Target Impressions',
  [Note.REACH]: 'Reach',
  [Note.REACH_TIKTOK]: 'Reach',
  [Note.CLICKS]: 'Clicks',
  [Note.CTR]: 'CTR',
  [Note.CPC]: 'CPC',
  [Note.CPM]: 'CPM',
  [Note.SALES]: 'Sales',
  [Note.TRANSACTIONS]: 'Transactions',
  [Note.ITEMS_SOLD]: 'Items Sold',
  [Note.NEW_USERS]: 'New Users',
  [Note.LAPSE_USERS]: 'Lapse Users',
  [Note.EXISTING_USERS]: 'Existing Users',
  [Note.BASKET_SIZE]: 'Basket Size',
  [Note.SERVICE_AREA]: 'Service Area',
  [Note.BENCHMARKS]: 'Benchmarks',
  [Note.OTHERS]: 'Others',
  [Platform.FB]: 'Facebook',
  [Platform.IG]: 'Instagram',
  [Platform.RTB]: 'Open RTB',
  [Platform.TIKTOK]: 'Tiktok',
  [Platform.AUDIENCE_NETWORK]: 'Audience Network',
  [Platform.MESSENGER]: 'Messenger',
  [Platform.UNKNOWN]: 'Unknown',
  [Group.PLATFORM]: 'Platform',
  [Group.AGE_GROUP]: 'Age Group',
  [Group.GENDER]: 'Gender',
  [Group.AUDIENCE_SEGMENT]: 'Audience Segment',
  [Group.DOMAIN]: 'Domain',
  [Group.REGION]: 'Region',
  [Group.CREATIVE]: 'Creative',
  [Benchmark.AWARENESS]: 'Facebook Awareness',
  [Benchmark.TRAFFIC]: 'Facebook Traffic',
  [Benchmark.MIXED]: 'Open RTB by Gojek'
});

export const NOTES_CONTENT = Object.freeze({
  [Note.IMPRESSIONS]: 'How many times your ads were seen.',
  [Note.ACTUAL_IMPRESSIONS]: 'How many times your ads were seen.',
  [Note.TARGET_IMPRESSIONS]: 'Impression target based on Confirmation Letter.',
  [Note.REACH]: 'How many people see your ads.',
  [Note.REACH_TIKTOK]: 'How many people see your ads. Currently, reach breakdowns for age, gender, and service area are not supported by Tiktok Platform.',
  [Note.CLICKS]: 'How many times your ads were clicked.',
  [Note.CTR]: 'Click Through Rate; number of clicks that your ads received divided by the number of times your ads are shown (impressions).',
  [Note.CPC]: 'Cost per Ads Click; total amount spent divided by total clicks recorded.',
  [Note.CPM]: 'Cost per 1,000 Impressions; total amount spent divided by total impressions recorded, multiplied by 1,000.',
  [Note.FB]: 'Facebook performance numbers are the sum of all the platforms that belong to facebook (except for instagram). For detailed information please refer to Audience Deep Dive by Platform - facebook instagram.',
  [Note.SALES]: 'Revenue (IDR) generated from transactions (orders) during campaign.',
  [Note.TRANSACTIONS]: 'Total unique orders during campaign.',
  [Note.ITEMS_SOLD]: 'Total items sold from transactions (orders) during campaign.',
  [Note.NEW_USERS]: 'Users who have never made any purchase on Merchant / Brand.',
  [Note.LAPSE_USERS]: 'Users who have not made any purchase in the last 60 days, then made purchase(s) during campaign period.',
  [Note.EXISTING_USERS]: 'Users who made purchase within the last 60 days.',
  [Note.BASKET_SIZE]: 'Average sales value per order.',
  [Note.SERVICE_AREA]: "Gojek area of operation; area classification is based of Gojek's grouping.",
  [Note.OTHERS]: 'Sum of each breakdown report might be different from the total summary due to coverage gaps and data overlapping.'
});

export const IMPACT_TITLE = Object.freeze({
  [Column.SALES]: 'Sales (IDR)',
  [Column.TRANSACTIONS]: 'Transactions (Orders)',
  [Column.ITEMS_SOLD]: 'Items Sold',
  [Column.BASKET_SIZE]: 'Basket Size',
  [Column.LAPSE_USERS]: 'Lapse Users',
  [Column.NEW_USERS]: 'New Users',
  [Column.EXISTING_USERS]: 'Existing Users'
});

export const OVERALL_PRIORITY: string[] = [
  Column.IMPRESSIONS,
  Column.CLICKS,
  Column.CTR,
  Column.CPC,
  Column.CPM
];

export const COLUMN_PRIORITY: string[] = [
  Column.SALES,
  Column.TRANSACTIONS,
  Column.BASKET_SIZE,
  Column.ITEMS_SOLD,
  Column.NEW_USERS,
  Column.LAPSE_USERS,
  Column.EXISTING_USERS,
  Column.IMPRESSIONS,
  Column.REACH,
  Column.CLICKS,
  Column.CTR,
  Column.CPC,
  Column.CPM
];

export const DEEP_DIVE_PLATFORM_PRIORITY: string[] = [
  Platform.FB,
  Platform.RTB,
  Platform.TIKTOK
];

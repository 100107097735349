import { ColumnDefinition, renderColumn, sortableColumn } from 'components/TableColumn';
import { FbAdSet } from 'core/fbAdSet/FbAdSet';
import { FbAdSetManager, DefaultFbAdSetManager } from 'core/fbAdSet/FbAdSetManager';
import { DraftManager, FbAdSetDraftManager } from 'core/draft/DraftManager';
import { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './fbAdSetList.module.scss';
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import formatters from './listFormatters';
import _ from 'lodash';
import { CampaignState } from 'core/rtbCampaign/RtbCampaign';
import { numberWithCommas } from 'utils/StringUtil';
import i18n from 'i18n';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { Order, State } from 'core/order/Order';
import moment from 'moment';
import { CampaignGroup } from 'core/campaignGroup/CampaignGroup';
import { formatPrice, getPriceValue } from 'helper/CurrencyHelper';
import { useCallAPI } from 'hooks/useCallAPI';
import { getCpa, getCpc, getCtr, getCvr, getVctr } from 'helper/MetricsHelper';
import { FilterMenuTabConfig } from 'components/common/FilterMenuTab/FilterMenuTab';

export enum FbAdSetListColumns {
  LISTID = 'listId',
  ID = 'id',
  LIFETIME_BUDGET = 'lifetime_budget',
  STATE = 'state',
  DELIVERY = 'delivery',
  CREATIVE = 'enableCount',
  OPTIMIZATION = 'optimization_goal',
  SCHEDULING = 'start_time',
  RESULTS = 'results',
  IMPRES = 'impres',
  VIEWABLE = 'viewable',
  CLICKS = 'clicks',
  CPC = 'cpc',
  CTR = 'ctr',
  VCTR = 'vctr',
  CONVERT = 'convs',
  CPA = 'cpa',
  CVR = 'cvr',
  UU = 'uu',
  SPENT = 'spent',
  APP_CUSTOM_EVENT = 'app_custom_event',
  APP_CUSTOM_EVENT_FB_MOBILE_ACTIVATE_APP = 'app_custom_event_fb_mobile_activate_app',
  APP_CUSTOM_EVENT_FB_MOBILE_ADD_PAYMENT_INFO = 'app_custom_event_fb_mobile_add_payment_info',
  APP_CUSTOM_EVENT_FB_MOBILE_ADD_TO_CART = 'app_custom_event_fb_mobile_add_to_cart',
  APP_CUSTOM_EVENT_FB_MOBILE_ADD_TO_WISHLIST = 'app_custom_event_fb_mobile_add_to_wishlist',
  APP_CUSTOM_EVENT_FB_MOBILE_COMPLETE_REGISTRATION = 'app_custom_event_fb_mobile_complete_registration',
  APP_CUSTOM_EVENT_FB_MOBILE_CONTENT_VIEW = 'app_custom_event_fb_mobile_content_view',
  APP_CUSTOM_EVENT_FB_MOBILE_INITIATED_CHECKOUT = 'app_custom_event_fb_mobile_initiated_checkout',
  APP_CUSTOM_EVENT_FB_MOBILE_PURCHASE = 'app_custom_event_fb_mobile_purchase',
  APP_CUSTOM_EVENT_FB_MOBILE_SEARCH = 'app_custom_event_fb_mobile_search',
  APP_CUSTOM_EVENT_OTHER = 'app_custom_event_other',
  COMMENT = 'comment',
  LIKE = 'like',
  LINK_CLICK = 'link_click',
  POST = 'post',
  POST_ENGAGEMENT = 'post_engagement',
  POST_REACTION = 'post_reaction',
  EDITBTNS = 'editBtns'
}

export enum FbAdSetListType {
  BASIC = 'basic',
  PERFORMANCE = 'performance',
  FB_ONSITE_ACTIONS = 'fb_onsite_actions',
  APP_ACTIONS = 'app_actions'
}

export type FbAdSetListState = {
  readonly loading: boolean;
  readonly rate: string;
  readonly currentCurrency: string;
  readonly currencyRates: { [key: string]: FbAdSet[] };
};

const defaultFbAdSetManager: FbAdSetManager = new DefaultFbAdSetManager();
const defaultDraftManager: DraftManager = new FbAdSetDraftManager();

export const useFbAdSetListModel = (
  order: Order,
  campaignGroup: CampaignGroup | undefined,
  budgetBalance: number,
  fbAdSets: FbAdSet[],
  refreshList: () => void,
  addonFeatureManager,
  fbAdSetManager: FbAdSetManager = defaultFbAdSetManager,
  fbAdSetDraftManager: DraftManager = defaultDraftManager
) => {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const defaultSearchString = searchParams.get('search');
  const { loading, callAPIs } = useCallAPI();
  const [filteredList, setFilteredList] = useState<any[]>([]);
  const [searchString, setSearchString] = useState<string>(defaultSearchString ? defaultSearchString : '');
  const [selectedAdSets, setSelectedAdSets] = useState<(number | string)[]>([]);
  const [selectedDrafts, setSelectedDrafts] = useState<(number | string)[]>([]);
  const [adSetToDelete, setAdSetToDelete] = useState<number | string | undefined>();
  const [deleteDraftIds, setDeleteDraftIds] = useState<(number | string)[]>([]);
  const [showDeleteDraftConfirmModal, setShowDeleteDraftConfirmModal] = useState<boolean>(false);
  const [summaryData, setSummaryData] = useState<any>();
  const [selectedMetricsGroup, setSelectedMetricsGroup] = useState<FbAdSetListType>(FbAdSetListType.BASIC);
  const [showDraftCreateModal, setShowDraftCreateModal] = useState<boolean>(false);
  const [filterMenuRef, setFilterMenuRef] = useState<any>();
  const [objectTypes, setObjectTypes] = useState<string[]>([]);
  const [selectedObjectTypeFilter, setSelectedObjectTypeFilter] = useState<string[]>([]);
  const match = useRouteMatch();
  const history = useHistory();

  const onHandleSearch = (searchString: string) => setSearchString(searchString);

  const fbAdSetWithPerformanceList = useMemo(() => {
    return fbAdSets.map(fbAdSet => {
      const report = fbAdSet.report;
      const conversion = fbAdSet.conversion ?
        _.mapValues(
          _.keyBy(fbAdSet.conversion.map(data => ({ ...data, action_type: data.action_type.replace('.', '_') })), 'action_type'),
          'actions'
        ) :
        {};
      const isDraft: boolean = _.get(fbAdSet, 'isDraft', false);
      const listId = isDraft ? `${fbAdSet.id}_draft_${fbAdSet.draftId}` : fbAdSet.id;
      const delivery = isDraft ? null : fbAdSet.effective_status;
      return report ? {
        ..._.omit(fbAdSet, ['report', 'conversion']),
        ..._.omit(report, ['name']),
        ...conversion,
        state: fbAdSet.configured_status,
        delivery,
        listId,
        lifetime_budget: fbAdSet.lifetime_budget ? fbAdSet.lifetime_budget : -1,
        bid_strategy: fbAdSet.bid_strategy ? fbAdSet.bid_strategy : _.get(campaignGroup, 'fb.bid_strategy'),
        [FbAdSetListColumns.CPA]: getPriceValue(order.currency, getCpa(_.get(report, 'spent', 0), report.convs)),
        [FbAdSetListColumns.CPC]: getPriceValue(order.currency, getCpc(_.get(report, 'spent', 0), report.clicks)),
        [FbAdSetListColumns.CTR]: getCtr(report.impres, report.clicks),
        [FbAdSetListColumns.CVR]: getCvr(report.convs, report.clicks),
        [FbAdSetListColumns.VCTR]: getVctr(report.viewable, report.clicks),
        [FbAdSetListColumns.SPENT]: _.get(report, 'spent', 0)
      } : {
        ...fbAdSet,
        ...conversion,
        state: fbAdSet.configured_status,
        delivery,
        listId,
        bid_strategy: fbAdSet.bid_strategy ? fbAdSet.bid_strategy : _.get(campaignGroup, 'fb.bid_strategy'),
        lifetime_budget: fbAdSet.lifetime_budget ? fbAdSet.lifetime_budget : -1,
        [FbAdSetListColumns.CPA]: getPriceValue(order.currency, 0),
        [FbAdSetListColumns.CPC]: getPriceValue(order.currency, 0),
        [FbAdSetListColumns.CTR]: 0,
        [FbAdSetListColumns.CVR]: 0,
        [FbAdSetListColumns.VCTR]: 0,
        [FbAdSetListColumns.SPENT]: 0
      };
    });
  }, [order.currency, fbAdSets, campaignGroup]);

  useEffect(() => {
    let objectTypes: string[] = [];
    const getObjectTypeDesc = (fbAdSet) => {
      if (fbAdSet.isDraft) {
        return i18n.t('goCampaignList.labels.draft');
      } else {
        return i18n.t('goCampaignList.labels.ordinaryCampaign_fb');
      }
    };
    const filteredList: any[] = fbAdSetWithPerformanceList.filter(fbAdSet => {
      const objectTypeDes = getObjectTypeDesc(fbAdSet);
      objectTypes = _.uniq(_.concat(objectTypes, objectTypeDes));
      const nameInclude = fbAdSet.name.toLowerCase().includes(searchString.toLowerCase());
      const listIdInclude = fbAdSet.listId.toString().includes(searchString);
      const objectTypeInclude = selectedObjectTypeFilter.length === 0 || selectedObjectTypeFilter.includes(objectTypeDes);
      return (nameInclude || listIdInclude) && objectTypeInclude;
    });
    setObjectTypes(objectTypes);
    setFilteredList(filteredList);
    const metricsAdder = (acc, value) => {
      if (!value) {
        return acc;
      }
      return acc + value;
    };
    const viewableSum = filteredList.reduce<number>((partial, campaignGroup) => metricsAdder(partial, campaignGroup.viewable), 0);
    const clickSum = filteredList.reduce<number>((partial, campaignGroup) => metricsAdder(partial, campaignGroup.clicks), 0);
    const convsSum = filteredList.reduce<number>((partial, campaignGroup) => metricsAdder(partial, campaignGroup.convs), 0);
    const spentSum = filteredList.reduce<number>((partial, campaignGroup) => metricsAdder(partial, getPriceValue(order.currency, campaignGroup.spent)), 0);
    const impresSum = filteredList.reduce<number>((partial, campaignGroup) => metricsAdder(partial, campaignGroup.impres), 0);
    const actionColumns = [
      FbAdSetListColumns.APP_CUSTOM_EVENT,
      FbAdSetListColumns.APP_CUSTOM_EVENT_FB_MOBILE_ACTIVATE_APP,
      FbAdSetListColumns.APP_CUSTOM_EVENT_FB_MOBILE_ADD_PAYMENT_INFO,
      FbAdSetListColumns.APP_CUSTOM_EVENT_FB_MOBILE_ADD_TO_CART,
      FbAdSetListColumns.APP_CUSTOM_EVENT_FB_MOBILE_ADD_TO_WISHLIST,
      FbAdSetListColumns.APP_CUSTOM_EVENT_FB_MOBILE_COMPLETE_REGISTRATION,
      FbAdSetListColumns.APP_CUSTOM_EVENT_FB_MOBILE_CONTENT_VIEW,
      FbAdSetListColumns.APP_CUSTOM_EVENT_FB_MOBILE_INITIATED_CHECKOUT,
      FbAdSetListColumns.APP_CUSTOM_EVENT_FB_MOBILE_PURCHASE,
      FbAdSetListColumns.APP_CUSTOM_EVENT_FB_MOBILE_SEARCH,
      FbAdSetListColumns.APP_CUSTOM_EVENT_OTHER,
      FbAdSetListColumns.COMMENT,
      FbAdSetListColumns.LIKE,
      FbAdSetListColumns.LINK_CLICK,
      FbAdSetListColumns.POST,
      FbAdSetListColumns.POST_ENGAGEMENT,
      FbAdSetListColumns.POST_REACTION
    ];
    const actionSummaryData = actionColumns.reduce((acc, column) => {
      const sum = filteredList.reduce<number>((partial, fbAdSet) => metricsAdder(partial, +fbAdSet[column]), 0);
      acc[column] = numberWithCommas(sum);
      return acc;
    }, {});
    const summaryData = {
      [FbAdSetListColumns.LISTID]: i18n.t('campaignGroupList.labels.campaignGroupCount', { count: filteredList.length }),
      [FbAdSetListColumns.IMPRES]: numberWithCommas(impresSum),
      [FbAdSetListColumns.VIEWABLE]: numberWithCommas(viewableSum),
      [FbAdSetListColumns.CLICKS]: numberWithCommas(clickSum),
      [FbAdSetListColumns.CONVERT]: numberWithCommas(convsSum),
      [FbAdSetListColumns.CPC]: formatPrice(order.currency, clickSum === 0 ? 0 : spentSum / clickSum),
      [FbAdSetListColumns.CTR]: `${impresSum === 0 ? 0.00.toFixed(2) : ((clickSum / impresSum) * 100).toFixed(2)}%`,
      [FbAdSetListColumns.VCTR]: `${viewableSum === 0 ? 0.00.toFixed(2) : ((clickSum / viewableSum) * 100).toFixed(2)}%`,
      [FbAdSetListColumns.CPA]: formatPrice(order.currency, convsSum === 0 ? 0 : spentSum / convsSum),
      [FbAdSetListColumns.CVR]: `${clickSum === 0 ? 0.00.toFixed(2) : ((convsSum / clickSum) * 100).toFixed(2)}%`,
      [FbAdSetListColumns.SPENT]: numberWithCommas(spentSum),
      ...actionSummaryData
    };
    setSummaryData(summaryData);
  }, [searchString, fbAdSetWithPerformanceList, order.currency, selectedObjectTypeFilter]);

  const onSelect = (adSet: FbAdSet) => {
    const isDraft: boolean = _.get(adSet, 'isDraft', false);
    const objectId = isDraft ? _.get(adSet, 'draftId', 0) : adSet.id;
    const selectedArray = isDraft ? selectedDrafts : selectedAdSets;
    const setSelectedArray = isDraft ? setSelectedDrafts : setSelectedAdSets;
    if (selectedArray.indexOf(objectId.toString()) > -1) {
      setSelectedArray(selectedArray.filter(id => id.toString() !== objectId.toString()));
    } else {
      setSelectedArray([...selectedArray, objectId.toString()]);
    }
  };

  const onSelectAll = () => {
    const selectedArrays = [...selectedAdSets, ...selectedDrafts];
    if (selectedArrays.length === filteredList.length) {
      setSelectedAdSets([]);
      setSelectedDrafts([]);
    } else {
      setSelectedAdSets(filteredList.filter(object => !_.get(object, 'isDraft', false)).map(adSet => adSet.id.toString()));
      setSelectedDrafts(filteredList.filter(object => _.get(object, 'isDraft', true)).map(draft => _.get(draft, 'draftId', 0).toString()));
    }
  };

  const onCreativeBindingBtnClick = (id, isDraft) => {
    if (isDraft) {
      history.replace(`${match.url}?draftIds=${id}&action=manage`);
    } else {
      history.replace(`${match.url}?campaignIds=${id}&action=manage`);
    }
  };

  const handleRemoveSelect = () => {
    setSelectedAdSets([]);
    setSelectedDrafts([]);
  };

  const onDeleteModalClose = useCallback((refresh: boolean) => {
    setAdSetToDelete(undefined);
    refresh && refreshList();
  }, [refreshList]);

  const onDeleteDraftModalClose = useCallback((refresh: boolean) => {
    setShowDeleteDraftConfirmModal(false);
    setDeleteDraftIds([]);
    refresh && refreshList();
  }, [refreshList]);

  const deleteAdSet = useCallback(async (adSetId: number | string) => {
    callAPIs([
      fbAdSetManager.deleteAdSet.bind(fbAdSetManager, adSetId)
    ], () => {
      setSelectedAdSets([]);
      setSelectedDrafts([]);
      onDeleteModalClose(true);
    });
  }, [fbAdSetManager, onDeleteModalClose, callAPIs]);

  const deleteDraft = useCallback(async (draftIds: (number | string)[]) => {
    setShowDeleteDraftConfirmModal(false);
    callAPIs([
      fbAdSetDraftManager.deleteDrafts.bind(fbAdSetDraftManager, draftIds)
    ], () => {
      setSelectedAdSets([]);
      setSelectedDrafts([]);
      onDeleteDraftModalClose(true);
    });
  }, [fbAdSetDraftManager, onDeleteDraftModalClose, callAPIs]);

  const onDeleteBtnClick = (adSetToDelete: number | string) => {
    setAdSetToDelete(adSetToDelete);
  };

  const columnDefinition = (columnName, customLabel?: string): ColumnDefinition => ({
    ...sortableColumn(columnName, customLabel ? customLabel : `fbAdSetList.headers.${columnName}`, true),
    classes: () => styles[columnName],
    headerClasses: () => styles[columnName]
  });

  const canDeleteSelectedAdSets = (fbAdSetIds: (string | number)[]) => {
    if (fbAdSetIds.length === 0) {
      return false;
    }

    const canDeleteAdSets = _.filter(fbAdSets, fbAdSet => {
      const selected = fbAdSetIds.indexOf(fbAdSet.id) > -1;
      if (!selected) {
        return false;
      }
      const notStarted = moment(fbAdSet.start_time).isAfter(moment());
      const notBindCreative = _.get(fbAdSet, 'bindingCount', 0) === 0;
      return notStarted || notBindCreative;
    });

    return canDeleteAdSets.length === fbAdSetIds.length;
  };

  const idColumn = renderColumn({
    ...columnDefinition(FbAdSetListColumns.LISTID),
    sortFunc: (adSet1, adSet2, order) => {
      const adSet1IsDraft = adSet1.includes('draft');
      const adSet2IsDraft = adSet2.includes('draft');
      const sameObjectType = adSet1IsDraft === adSet2IsDraft;
      const compareListId = adSet1.toString().localeCompare(adSet2.toString());
      if (!sameObjectType) {
        return adSet1IsDraft ? -1 : 1;
      }
      if (compareListId === 0) {
        return 0;
      }
      if (order === 'desc') {
        return compareListId > 0 ? 1 : -1;
      } else {
        return compareListId < 0 ? 1 : -1;
      }
    },
    formatExtraData: {
      currentUrl: match.url,
      selectedAdSets,
      selectedDrafts,
      onSelect
    }
  },
  formatters.nameFormatter,
  _.partial(
    formatters.nameHeaderFormatter,
    filteredList.length,
    [...selectedAdSets, ...selectedDrafts],
    onSelectAll
  ));

  const budgetColumn = renderColumn({
    ...columnDefinition(
      FbAdSetListColumns.LIFETIME_BUDGET,
      i18n.t(`fbAdSetList.headers.${FbAdSetListColumns.LIFETIME_BUDGET}`) + ` (${order.currency})`
    ),
    formatExtraData: {
      currency: order.currency
    }
  }, formatters.budgetFormatter);

  const optimizationColumn = renderColumn({
    ...columnDefinition(FbAdSetListColumns.OPTIMIZATION),
    formatExtraData: {
      currency: order.currency
    }
  }, formatters.optimizationFormatter);

  const editBtnsColumn = renderColumn({
    ...columnDefinition(FbAdSetListColumns.EDITBTNS),
    text: '',
    sort: false,
    formatExtraData: {
      currentUrl: match.url,
      onDeleteBtnClick,
      canDeleteSelectedAdSets
    }
  }, formatters.floatingEditBtnsFormatter);

  const basicColumns = [
    idColumn,
    renderColumn(columnDefinition(FbAdSetListColumns.STATE), formatters.stateFormatter),
    renderColumn(columnDefinition(FbAdSetListColumns.DELIVERY), formatters.deliveryFormatter),
    renderColumn({
      ...columnDefinition(FbAdSetListColumns.CREATIVE),
      formatExtraData: {
        onClick: onCreativeBindingBtnClick
      }
    }, formatters.creativeBindingFormatter),
    budgetColumn,
    renderColumn(columnDefinition(FbAdSetListColumns.SCHEDULING), formatters.scheduleFormatter),
    optimizationColumn,
    editBtnsColumn
  ];

  const metricsFormatter = (formatter, value) => {
    let target = value;
    if (!value) {
      target = 0;
    }
    return formatter(target);
  };

  const spentColumn = renderColumn(
    columnDefinition(
      FbAdSetListColumns.SPENT,
      i18n.t(`fbAdSetList.headers.${FbAdSetListColumns.SPENT}`) + ` (${order.currency})`),
      _.partial(metricsFormatter, _.partial(formatPrice, order.currency)
    )
  );

  const impresColumn = renderColumn(
    columnDefinition(FbAdSetListColumns.IMPRES),
    _.partial(metricsFormatter, numberWithCommas)
  );

  const viewableEnable = addonFeatureManager.isFeatureEnable(ADDONFEATURE.REPORT.REPORT_VIEWABLE_CTR);
  const convsEnable = addonFeatureManager.isFeatureEnable(ADDONFEATURE.CONVERSION_TRACKING.CONV_TRACKING_LIST);
  const performanceColumns = _.compact([
    idColumn,
    budgetColumn,
    optimizationColumn,
    renderColumn({
      ...columnDefinition(FbAdSetListColumns.RESULTS),
      formatExtraData: {
        objective: _.get(campaignGroup, 'fb.objective')
      }
    }, formatters.resultsFormatter),
    impresColumn,
    viewableEnable ? renderColumn(columnDefinition(FbAdSetListColumns.VIEWABLE), _.partial(metricsFormatter, numberWithCommas)) : undefined,
    renderColumn(columnDefinition(FbAdSetListColumns.CLICKS), _.partial(metricsFormatter, numberWithCommas)),
    renderColumn(
      columnDefinition(FbAdSetListColumns.CPC, i18n.t(`fbAdSetList.headers.${FbAdSetListColumns.CPC}`) + ` (${order.currency})`),
      _.partial(formatPrice, order.currency)
    ),
    renderColumn(columnDefinition(FbAdSetListColumns.CTR), _.partial(metricsFormatter, value => `${value.toFixed(2)}%`)),
    viewableEnable ? renderColumn(columnDefinition(FbAdSetListColumns.VCTR), _.partial(metricsFormatter, value => `${value.toFixed(2)}%`)) : undefined,
    convsEnable ? renderColumn(columnDefinition(FbAdSetListColumns.CONVERT), _.partial(metricsFormatter, numberWithCommas)) : undefined,
    convsEnable ? renderColumn(
      columnDefinition(FbAdSetListColumns.CPA, i18n.t(`fbAdSetList.headers.${FbAdSetListColumns.CPA}`) + ` (${order.currency})`),
      _.partial(formatPrice, order.currency)
    ) : undefined,
    convsEnable ? renderColumn(columnDefinition(FbAdSetListColumns.CVR), _.partial(metricsFormatter, value => `${value.toFixed(2)}%`)) : undefined,
    renderColumn(columnDefinition(FbAdSetListColumns.UU), _.partial(metricsFormatter, numberWithCommas)),
    spentColumn,
    editBtnsColumn
  ]);

  const fbOnSiteActionsColumns = _.compact([
    idColumn,
    impresColumn,
    spentColumn,
    renderColumn(columnDefinition(FbAdSetListColumns.COMMENT), _.partial(metricsFormatter, numberWithCommas)),
    renderColumn(columnDefinition(FbAdSetListColumns.LIKE), _.partial(metricsFormatter, numberWithCommas)),
    renderColumn(columnDefinition(FbAdSetListColumns.LINK_CLICK), _.partial(metricsFormatter, numberWithCommas)),
    renderColumn(columnDefinition(FbAdSetListColumns.POST), _.partial(metricsFormatter, numberWithCommas)),
    renderColumn(columnDefinition(FbAdSetListColumns.POST_ENGAGEMENT), _.partial(metricsFormatter, numberWithCommas)),
    renderColumn(columnDefinition(FbAdSetListColumns.POST_REACTION), _.partial(metricsFormatter, numberWithCommas)),
    editBtnsColumn
  ]);

  const appActionsColumns = _.compact([
    idColumn,
    impresColumn,
    spentColumn,
    renderColumn(columnDefinition(FbAdSetListColumns.APP_CUSTOM_EVENT), _.partial(metricsFormatter, numberWithCommas)),
    renderColumn(columnDefinition(FbAdSetListColumns.APP_CUSTOM_EVENT_FB_MOBILE_ACTIVATE_APP), _.partial(metricsFormatter, numberWithCommas)),
    renderColumn(columnDefinition(FbAdSetListColumns.APP_CUSTOM_EVENT_FB_MOBILE_ADD_PAYMENT_INFO), _.partial(metricsFormatter, numberWithCommas)),
    renderColumn(columnDefinition(FbAdSetListColumns.APP_CUSTOM_EVENT_FB_MOBILE_ADD_TO_CART), _.partial(metricsFormatter, numberWithCommas)),
    renderColumn(columnDefinition(FbAdSetListColumns.APP_CUSTOM_EVENT_FB_MOBILE_ADD_TO_WISHLIST), _.partial(metricsFormatter, numberWithCommas)),
    renderColumn(columnDefinition(FbAdSetListColumns.APP_CUSTOM_EVENT_FB_MOBILE_COMPLETE_REGISTRATION), _.partial(metricsFormatter, numberWithCommas)),
    renderColumn(columnDefinition(FbAdSetListColumns.APP_CUSTOM_EVENT_FB_MOBILE_CONTENT_VIEW), _.partial(metricsFormatter, numberWithCommas)),
    renderColumn(columnDefinition(FbAdSetListColumns.APP_CUSTOM_EVENT_FB_MOBILE_INITIATED_CHECKOUT), _.partial(metricsFormatter, numberWithCommas)),
    renderColumn(columnDefinition(FbAdSetListColumns.APP_CUSTOM_EVENT_FB_MOBILE_PURCHASE), _.partial(metricsFormatter, numberWithCommas)),
    renderColumn(columnDefinition(FbAdSetListColumns.APP_CUSTOM_EVENT_FB_MOBILE_SEARCH), _.partial(metricsFormatter, numberWithCommas)),
    renderColumn(columnDefinition(FbAdSetListColumns.APP_CUSTOM_EVENT_OTHER), _.partial(metricsFormatter, numberWithCommas)),
    editBtnsColumn
  ]);

  const typeColumnsMap = {
    [FbAdSetListType.BASIC]: basicColumns,
    [FbAdSetListType.PERFORMANCE]: performanceColumns,
    [FbAdSetListType.FB_ONSITE_ACTIONS]: fbOnSiteActionsColumns,
    [FbAdSetListType.APP_ACTIONS]: appActionsColumns
  };

  const metricsGroupOptions = useMemo(() => {
    return Object.keys(typeColumnsMap).map(metricsGroup => ({
      label: i18n.t(`campaignList.tabs.${metricsGroup}`),
      value: metricsGroup
    }));
  }, [typeColumnsMap]);

  const getManageCreativesPath = () => {
    const campaignIds = selectedAdSets.join(',');
    const draftIds = selectedDrafts.join(',');
    let path = `${location.pathname}?`;
    if (campaignIds) {
      path = path + `campaignIds=${campaignIds}`;
    }
    if (draftIds) {
      path = campaignIds ? `${path}&draftIds=${draftIds}` : `${path}draftIds=${draftIds}`;
    }
    return path;
  };

  const bindCreatives = (event) => {
    event && event.stopPropagation();
    history.push(`${getManageCreativesPath()}&action=bind`);
  };

  const activateCreatives = (event) => {
    event && event.stopPropagation();
    history.push(`${getManageCreativesPath()}&action=activate`);
  };

  const deactivateCreatives = (event) => {
    event && event.stopPropagation();
    history.push(`${getManageCreativesPath()}&action=deactivate`);
  };

  const generateUpdateStatePayload = (selectedObjects: (number | string)[], objectType: 'adSet' | 'draft') => {
    const key = objectType === 'adSet' ? 'id' : 'draftId';
    const payload = selectedObjects.map(objectId => {
      const object = fbAdSets.find(adSet => _.get(adSet, key, '').toString() === objectId.toString());
      return {
        goCampaignChannelId: objectId,
        isDraft: _.get(object, 'isDraft', false)
      };
    });
    return payload;
  };

  const getUpdateStatusPayload = () => {
    return [
      ...generateUpdateStatePayload(selectedAdSets, 'adSet'),
      ...generateUpdateStatePayload(selectedDrafts, 'draft')
    ];
  };

  const activeAdSet = async () => {
    callAPIs([
      fbAdSetManager.updateAdSetState.bind(fbAdSetManager, getUpdateStatusPayload(), 'activate')
    ], () => {
      setSelectedAdSets([]);
      setSelectedDrafts([]);
      refreshList();
    });
  };

  const deactiveAdSet = async () => {
    callAPIs([
      fbAdSetManager.updateAdSetState.bind(fbAdSetManager, getUpdateStatusPayload(), 'deactivate')
    ], () => {
      setSelectedAdSets([]);
      setSelectedDrafts([]);
      refreshList();
    });
  };

  const showDeleteConfirmModal = () => {};

  const onShowDeleteDraftConfirmModal = () => {
    setShowDeleteDraftConfirmModal(true);
    setDeleteDraftIds(selectedDrafts);
  };

  const rowClasses = (adSet) => {
    let classes: any = null;
    if (adSet.state === CampaignState.DEACTIVATE && adSet.budget_remaining > 0) {
      classes = [classes, styles.lightDanger].join(' ');
    }
    if (adSet.isDraft) {
      classes = [classes, styles.draftBg].join(' ');
    }
    return classes;
  };

  const canNotCreateMessage = useMemo(() => {
    const canNotCreateState = [State.NOT_APPROVE, State.REJECT, State.SETTLE, State.SETTLED, State.CHANGE_PENDING];
    const state = order.state;
    if (canNotCreateState.includes(state)) {
      return i18n.t('fbAdSetList.labels.orderStateCannotCreate');
    }

    const isEnd = moment(order.endDate).isBefore(moment().startOf('day'));
    if (isEnd) {
      return i18n.t('fbAdSetList.labels.isEndCannotCreate');
    }

    if (budgetBalance <= 0) {
      return i18n.t('orderDetail.labels.lessThanBudgetMinimum', { budget: 0 });
    }
    return '';
  }, [order, budgetBalance]);

  const hideDraftCreateModal = (dirty: boolean) => {
    setShowDraftCreateModal(false);
    setSelectedAdSets([]);
    setSelectedDrafts([]);
    dirty && refreshList();
  };

  const handleOnObjectTypeFilterApply = (objectTypeList) => {
    setSelectedObjectTypeFilter([...objectTypeList]);
  };

  const filterMenuTabConfigs: FilterMenuTabConfig[] = useMemo(() => ([
    {
      filterType: i18n.t('goCampaignList.labels.filterObjectType'),
      menuTitle: i18n.t('goCampaignList.labels.objectTypeFilterMenuTitle'),
      tag: i18n.t('goCampaignList.labels.objectTypeTag'),
      selectedValues: selectedObjectTypeFilter,
      options: objectTypes,
      applyMethod: handleOnObjectTypeFilterApply
    }
  ]), [objectTypes, selectedObjectTypeFilter]);

  return {
    loading,
    columns: typeColumnsMap[selectedMetricsGroup],
    rowClasses,
    filteredList,
    searchString,
    currentUrl: match.url,
    selectedAdSets,
    selectedDrafts,
    metricsGroupOptions,
    summaryData,
    selectedMetricsGroup,
    canNotCreateMessage,
    showDraftCreateModal,
    hideDraftCreateModal,
    setShowDraftCreateModal,
    onMetricsGroupChange: selectedMetricsGroup => setSelectedMetricsGroup(selectedMetricsGroup),
    onHandleSearch,
    handleRemoveSelect,
    onDeleteModalClose,
    onDeleteDraftModalClose,
    showDeleteDraftConfirmModal,
    onShowDeleteDraftConfirmModal,
    deleteAdSet: adSetToDelete !== undefined ? _.partial(deleteAdSet, adSetToDelete) : undefined,
    deleteDraft: _.partial(deleteDraft, deleteDraftIds),
    bindCreatives,
    activateCreatives,
    deactivateCreatives,
    activeAdSet,
    deactiveAdSet,
    showDeleteConfirmModal,
    canDeleteSelectedAdSets,
    filterMenuRef,
    setFilterMenuRef,
    filterMenuTabConfigs
  };
};

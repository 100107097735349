import React from 'react';
import _ from 'lodash';
import styles from './adNeonCreativeDetail.module.scss';
import { CreativeBasic } from 'core/creative/Creative';

export const AdNeonCreativeDetail: React.FunctionComponent<{creative: Partial<CreativeBasic>}> = (props) => {
  const creativeValue = _.get(props.creative, 'creativeValue', _.get(props.creative, 'creativeValues'));
  if (!creativeValue) {
    return <div/>;
  }
  const htmlSnippet = _.get(creativeValue, 'htmlSnippet', '');
  if (!htmlSnippet) {
    return <div/>;
  }
  const regularExpression = /"%%CLICK_URL_UNESC%%(.+?)"/g;
  const clickMacroMatch = htmlSnippet.matchAll(regularExpression);
  let htmlContent = htmlSnippet;
  for (const match of clickMacroMatch) {
    if (match[1]) {
      htmlContent = htmlContent.replace(match[1], decodeURIComponent(match[1]));
    }
  }
  htmlContent = htmlContent.replace(/%%CLICK_URL_UNESC%%/g, '')
                          .replace(/%%VAST_URL_UNESC%%/g, '')
                          .replace(/%%VIEWABLE_IMPRESSION_URL_UNESC%%/g, '')
                          .replace(/%%SSP_VIEWABLE_IMPRESSION_URL_UNESC%%/g, '')
                          .replace(/%%ADX_VIEWABLE_IMPRESSION_URL_UNESC%%/g, '');
  const iframeSandboxSetting = [
    'allow-scripts', 'allow-popups', 'allow-modals', 'allow-orientation-lock',
    'allow-pointer-lock', 'allow-popups-to-escape-sandbox', 'allow-presentation',
    'allow-top-navigation', 'allow-top-navigation-by-user-activation'
  ];
  const width = _.get(props.creative, 'bannerExtra.responseDTO.width', '300');
  const height = _.get(props.creative, 'bannerExtra.responseDTO.height', '250');
  return (
    <div className={styles.adNeonCreativeDetailContainer}>
      <iframe
        srcDoc={htmlContent}
        title={'pps_preview'}
        frameBorder='0'
        sandbox={iframeSandboxSetting.join(' ')}
        width={width}
        height={height}
        marginWidth={0}
        marginHeight={0}
      />
    </div>
  );
};

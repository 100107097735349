import React from 'react';

import './loginBackground.scss';
import backgroundImage from 'assets/login-background.svg';
import loginFormImage from 'assets/login-page-illustration.png';
import loginFormImage2x from 'assets/login-page-illustration@2x.png';
import { PrivacyPolicy } from 'components/PrivacyPolicy/PrivacyPolicy';
import className from 'classnames';
import config from 'config';

export const LoginBackground = ({ children }) => {

  const formImageStyle = className('form-image', {
    fill: config.isGojekTheme
  });
  return (
    <div className='login-background'>
      <img
        className='right-top'
        src={backgroundImage}
        alt='right top decorator'
      />
      <img
        className='left-bottom'
        src={backgroundImage}
        alt='left bottom decorator'
      />
      <div className='form-container'>
        <img
          className={formImageStyle}
          src={loginFormImage}
          srcSet={`${loginFormImage2x} 2x`}
          alt='illustration'
        />
        {children}
        <PrivacyPolicy className='loginPagePrivacyPolicy'/>
      </div>
    </div>
  );
};

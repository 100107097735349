export function createQueryString (
  firstKeywords: string[],
  secondKeywords: string[]
): string {
  const isKeywords1Empty = firstKeywords.length === 0;
  const isKeywords2Empty = secondKeywords.length === 0;
  if (isKeywords1Empty && isKeywords2Empty) {
    return '';
  }

  if (isKeywords1Empty) {
    return secondKeywords.join(' OR ');
  }
  if (isKeywords2Empty) {
    return firstKeywords.join(' OR ');
  }

  return `(${firstKeywords.join(' OR ')}) AND (${secondKeywords.join(' OR ')})`;
}

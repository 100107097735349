export type FbAdAccount = {
  id: string;
  name: string;
  account_status: FbAdAccountStatus;
  has_advertiser_opted_in_odax: boolean;
};

export type AdvertiserFBAdAccount = {
  id: number;
  fbAdAccountId: string;
  name: string;
  status: FbAdAccountStatus;
  hasAdvertiserOptedInOdax: boolean;
};

export enum FbAdAccountStatus {
  ACTIVE = 1,
  DEACTIVATED
}

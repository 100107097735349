import DefaultCreativeManager, { CreativeManager } from 'core/creative/CreativeManager';
import { CreativeOverviewState, BindCreativeState, CreativeSummaryState, ModifyCreativeActivationState } from './CreativeManagementState';
import { ModalStateFactory, ModalState } from 'containers/Common/ModalStateFactory';
import { CreativeOfCampaign } from 'core/creative/Creative';
import { AddonFeatureManager } from 'core';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import * as H from 'history';
import { RtbBindCreativeStateContentModel, FbBindCreativeStateContentModel, TiktokBindCreativeStateContentModel, RetailBindCreativeStateContentModel } from './BindCreativeStateContentModel';
import { RtbCreativeSummaryStateContentModel, FbCreativeSummaryStateContentModel, TiktokCreativeSummaryStateContentModel, RetailCreativeSummaryStateContentModel } from './CreativeSummaryStateContentModel';
import { RtbModifyCreativeActivationStateContentModel, FbModifyCreativeActivationStateContentModel, TiktokModifyCreativeActivationStateContentModel, RetailModifyCreativeActivationStateContentModel } from './ModifyCreativeActivationStateContentModel';
import { RtbCreativeOverviewStateContentModel, FbAdOverviewStateContentModel, TiktokAdOverviewStateContentModel, RetailCreativeOverviewStateContentModel } from './CreativeOverviewStateContentModel';
import { CreativeBindData } from 'core/rtbCampaign/CreativeBindData';
import { CampaignGroup, CampaignGroupChannel } from 'core/campaignGroup/CampaignGroup';
import _ from 'lodash';

export enum CreativeManagementAction {
  BIND = 'bind',
  ACTIVATE = 'activate',
  DEACTIVATE = 'deactivate',
  MANAGE = 'manage'
}
export abstract class CreativeManagementStateFactory implements ModalStateFactory {
  manager: CreativeManager;
  campaigns: Array<CreativeBindData>;
  editAction: string;
  advertiserId: number;
  addonFeatureManager: AddonFeatureManager;
  history: H.History;
  orderNumber: string;
  channel: CampaignGroupChannel;

  constructor (
    campaigns: Array<CreativeBindData>,
    advertiserId: number,
    editAction: string,
    addonFeatureManager: AddonFeatureManager,
    orderNumber: string,
    history: H.History,
    protected campaignGroup?: CampaignGroup,
    manager: CreativeManager = new DefaultCreativeManager()
  ) {
    this.manager = manager;
    this.addonFeatureManager = addonFeatureManager;
    this.editAction = editAction;
    this.advertiserId = advertiserId;
    this.campaigns = campaigns;
    this.history = history;
    this.orderNumber = orderNumber;
    this.channel = _.get(campaignGroup, 'channel', CampaignGroupChannel.RTB);
    if (this.campaigns.length >= 1 && this.editAction === 'manage') {
      this.campaigns = [this.campaigns[0]];
    }
  }

  async initStates (goModalStateFn: (state: ModalState, beforeGoState?: () => Promise<void>) => void, closeModalFn: (dirty) => void) {
    const creativeData = await this.getCreativeData();
    switch (this.editAction) {
      case CreativeManagementAction.BIND:
        return this.initBindStates(creativeData, goModalStateFn, closeModalFn);
      case CreativeManagementAction.ACTIVATE:
        return this.initModifyActivationStates(creativeData, goModalStateFn, closeModalFn, true);
      case CreativeManagementAction.DEACTIVATE:
        return this.initModifyActivationStates(creativeData, goModalStateFn, closeModalFn, false);
      default:
        return this.initManageStates(creativeData, goModalStateFn, closeModalFn);
    }
  }

  async getCreativeData () {
    let creativeData: Array<any> = [];
    try {
      await Promise.all(
        this.campaigns.map(async campaign => {
          try {
            const creatives = await this.getCreatives(campaign.id, campaign.draftId);
            creativeData.push({
              id: campaign.id,
              name: campaign.name,
              startDate: campaign.startDate,
              endDate: campaign.endDate,
              creatives: creatives,
              draftId: campaign.draftId
            });
          } catch (e) {
            creativeData.push({
              id: campaign.id,
              name: campaign.name,
              startDate: campaign.startDate,
              endDate: campaign.endDate,
              creatives: []
            });
          }
        })
      );
    } catch (error) {
      console.log('cannot get creative datas of campaigns', error);
    }
    creativeData = creativeData.sort((campaign1, campaign2) => {
      return campaign2.id - campaign1.id;
    });
    return creativeData;
  }

  abstract getCreatives (goCampaignId, draftId);

  abstract getOverviewStateContentModel (creatives, goBindStateFn, refreshCreative);

  abstract getBindCreativeStateContentModel (forbidCreatives: Array<any>);

  abstract getModifyCreativeActivationStateContentModel (creativeData: Array<any>, activate);

  abstract getCreativeSummaryStateContentModel (creativeData: Array<any>);

  async initManageStates (creativeData: Array<any>, goModalStateFn: (state: ModalState, beforeGoState?: () => Promise<void>) => void, closeModalFn: (dirty) => void) {
    const refreshCreative = async () => {
      const newCreativeData = await this.getCreativeData();
      return newCreativeData[0].creatives;
    };
    let creatives: Array<CreativeOfCampaign> = creativeData[0].creatives;
    let bindCreativeState = new BindCreativeState(
      this.campaigns,
      this.getBindCreativeStateContentModel(creatives.map(creative => creative.id))
    );
    let creativeOverviewState = new CreativeOverviewState(
      this.campaigns,
      this.getOverviewStateContentModel(
        creatives,
        () => {
          bindCreativeState.contentModel.reset();
          goModalStateFn(bindCreativeState);
        },
        refreshCreative)
    );

    let creativeSummaryState = new CreativeSummaryState(
      this.campaigns,
      this.getCreativeSummaryStateContentModel(creativeData)
    );

    creativeOverviewState.setCloseFn(closeModalFn);
    bindCreativeState.setNextFn(() => goModalStateFn(creativeSummaryState));
    bindCreativeState.setBackFn(() => goModalStateFn(creativeOverviewState));
    creativeSummaryState.setBackFn(() => goModalStateFn(bindCreativeState));
    creativeSummaryState.setCloseFn(closeModalFn);
    return creativeOverviewState;
  }

  async initBindStates (creativeData: Array<any>, goModalStateFn: (state: ModalState, beforeGoState?: () => Promise<void>) => void, closeModalFn: (dirty) => void) {
    const forbidCreatives = creativeData.length === 1 ? creativeData[0].creatives.map(creative => creative.id) : [];
    let bindCreativeState = new BindCreativeState(
      this.campaigns,
      this.getBindCreativeStateContentModel(forbidCreatives)
    );
    let creativeSummaryState = new CreativeSummaryState(
      this.campaigns,
      this.getCreativeSummaryStateContentModel(creativeData)
    );
    bindCreativeState.setNextFn(() => goModalStateFn(creativeSummaryState));
    bindCreativeState.setCloseFn(closeModalFn);
    creativeSummaryState.setBackFn(() => goModalStateFn(bindCreativeState));
    creativeSummaryState.setCloseFn(closeModalFn);
    return bindCreativeState;
  }

  async initModifyActivationStates (creativeData: Array<any>, goModalStateFn: (state: ModalState, beforeGoState?: () => Promise<void>) => void, closeModalFn: (dirty) => void, activate: boolean) {
    let modifyCreativeActivationState = new ModifyCreativeActivationState(
      this.campaigns,
      this.getModifyCreativeActivationStateContentModel(creativeData, activate)
    );
    let creativeSummaryState = new CreativeSummaryState(
      this.campaigns,
      this.getCreativeSummaryStateContentModel(creativeData)
    );

    modifyCreativeActivationState.setNextFn(() => goModalStateFn(creativeSummaryState));
    modifyCreativeActivationState.setCloseFn(closeModalFn);
    creativeSummaryState.setBackFn(() => goModalStateFn(modifyCreativeActivationState));
    creativeSummaryState.setCloseFn(closeModalFn);
    return modifyCreativeActivationState;
  }

  goCreateCreativeFn = () => {
    const campaignParams = this.campaigns[0].draftId ? `draftId=${this.campaigns[0].draftId}` : `campaignId=${this.campaigns[0].id}`;
    let path = `/creatives/new?${campaignParams}&orderNumber=${this.orderNumber}`;
    if (this.campaignGroup) {
      path = `${path}&campaignGroupId=${this.campaignGroup.groupId}&channel=${this.channel}`;
    }
    this.history.push(path);
  }
}

export class RtbCreativeManagementStateFactory extends CreativeManagementStateFactory {

  async getCreatives (goCampaignId, draftId) {
    if (draftId) {
      return this.manager.getCreativesByCampaignId(draftId, true);
    }
    return this.manager.getCreativesByCampaignId(goCampaignId, false);
  }

  getOverviewStateContentModel (creatives, goBindStateFn, refreshCreative) {
    return new RtbCreativeOverviewStateContentModel(
      this.campaignGroup,
      this.campaigns[0],
      creatives,
      this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.REPORT.REPORT_CLOSINGREPORT),
      this.orderNumber,
      goBindStateFn,
      this.goCreateCreativeFn,
      refreshCreative
    );
  }

  getBindCreativeStateContentModel (forbidCreatives: Array<any>) {
    return new RtbBindCreativeStateContentModel(
      this.campaigns,
      this.advertiserId,
      forbidCreatives,
      this.manager
    );
  }

  getModifyCreativeActivationStateContentModel (creativeData: Array<any>, activate) {
    return new RtbModifyCreativeActivationStateContentModel(creativeData, activate);
  }

  getCreativeSummaryStateContentModel (creativeData: Array<any>) {
    return new RtbCreativeSummaryStateContentModel(creativeData);
  }
}

export class RetailCreativeManagementStateFactory extends CreativeManagementStateFactory {

  constructor (
    campaigns: Array<CreativeBindData>,
    advertiserId: number,
    editAction: string,
    addonFeatureManager: AddonFeatureManager,
    orderNumber: string,
    history: H.History,
    campaignGroup?: CampaignGroup,
    private retailerId?: string,
    manager: CreativeManager = new DefaultCreativeManager()
  ) {
    super(campaigns, advertiserId, editAction, addonFeatureManager, orderNumber, history, campaignGroup, manager);
  }

  async getCreatives (goCampaignId, draftId) {
    let creatives;
    if (draftId) {
      creatives = await this.manager.getCreativesByCampaignId(draftId, true);
    } else {
      creatives = await this.manager.getCreativesByCampaignId(goCampaignId, false);
    }
    return _.map(creatives, creative => {
      const ppsLayoutId = _.get(creative, 'ppsLayoutId', [])[0];
      return {
        ...creative,
        ppsLayoutId: ppsLayoutId ? [ppsLayoutId.replace('pps_', '')] : undefined
      };
    });
  }

  getOverviewStateContentModel (creatives, goBindStateFn, refreshCreative) {
    return new RetailCreativeOverviewStateContentModel(
      this.campaignGroup,
      this.campaigns[0],
      creatives,
      this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.REPORT.REPORT_CLOSINGREPORT),
      this.orderNumber,
      goBindStateFn,
      this.goCreateCreativeFn,
      refreshCreative
    );
  }

  getBindCreativeStateContentModel (forbidCreatives: Array<any>) {
    return new RetailBindCreativeStateContentModel(
      this.campaigns,
      this.advertiserId,
      forbidCreatives,
      this.retailerId,
      this.manager
    );
  }

  getModifyCreativeActivationStateContentModel (creativeData: Array<any>, activate) {
    return new RetailModifyCreativeActivationStateContentModel(creativeData, activate);
  }

  getCreativeSummaryStateContentModel (creativeData: Array<any>) {
    return new RetailCreativeSummaryStateContentModel(creativeData);
  }
}

export class FbAdManagementStateFactory extends CreativeManagementStateFactory {

  async getCreatives (goCampaignId, draftId) {
    if (draftId) {
      return this.manager.getCreativesByAdSetId(draftId, true);
    }
    return this.manager.getCreativesByAdSetId(goCampaignId, false);
  }

  getBindCreativeStateContentModel (forbidCreatives: Array<any>) {
    return new FbBindCreativeStateContentModel(
      this.campaigns,
      this.advertiserId,
      forbidCreatives,
      this.manager
    );
  }

  getOverviewStateContentModel (creatives, goBindStateFn, refreshCreative) {
    return new FbAdOverviewStateContentModel(
      this.campaignGroup,
      this.campaigns[0],
      creatives,
      this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.REPORT.REPORT_CLOSINGREPORT),
      this.orderNumber,
      goBindStateFn,
      this.goCreateCreativeFn,
      refreshCreative
    );
  }

  getModifyCreativeActivationStateContentModel (creativeData: Array<any>, activate) {
    return new FbModifyCreativeActivationStateContentModel(creativeData, activate);
  }

  getCreativeSummaryStateContentModel (creativeData: Array<any>) {
    return new FbCreativeSummaryStateContentModel(creativeData);
  }
}

export class TiktokAdManagementStateFactory extends CreativeManagementStateFactory {

  async getCreatives (goCampaignId, draftId) {
    if (draftId) {
      return this.manager.getCreativesByAdGroupId(draftId, true);
    }
    return this.manager.getCreativesByAdGroupId(goCampaignId, false);
  }

  getBindCreativeStateContentModel (forbidCreatives: Array<any>) {
    return new TiktokBindCreativeStateContentModel(
      this.campaigns,
      this.advertiserId,
      forbidCreatives,
      this.manager
    );
  }

  getOverviewStateContentModel (creatives, goBindStateFn, refreshCreative) {
    return new TiktokAdOverviewStateContentModel(
      this.campaignGroup,
      this.campaigns[0],
      creatives,
      this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.REPORT.REPORT_CLOSINGREPORT),
      this.orderNumber,
      goBindStateFn,
      this.goCreateCreativeFn,
      refreshCreative
    );
  }

  getModifyCreativeActivationStateContentModel (creativeData: Array<any>, activate) {
    return new TiktokModifyCreativeActivationStateContentModel(creativeData, activate);
  }

  getCreativeSummaryStateContentModel (creativeData: Array<any>) {
    return new TiktokCreativeSummaryStateContentModel(creativeData);
  }
}

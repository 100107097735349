import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import { AddonFeatureManager } from 'core';
import { RtbCampaignBasic, CampaignState, AdType, RtbCampaignPlanType, DeliverType } from 'core/rtbCampaign/RtbCampaign';
import { ColumnDefinition } from 'components/TableColumn';
import { Order, State } from 'core/order/Order';
import { formatPrice, formatPriceWithCurrency, getPriceValue } from 'helper/CurrencyHelper';
import { toast } from 'react-toastify';
import {
  CampaignListColumns,
  performanceColumns,
  performanceColumnsWithViewable,
  // videoPerformanceColumns,
  // videoPerformanceColumnsWithViewable,
  basicColumns
} from './CampaignListColumnSetting';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { RtbCampaignManager, DefaultRtbCampaignManager } from 'core/rtbCampaign/RtbCampaignManager';
import { DraftManager, RtbCampaignDraftManager } from 'core/draft/DraftManager';
import { numberWithCommas } from 'utils/StringUtil';
import styles from './campaignList.module.scss';
import moment from 'moment';
import i18n from 'i18next';
import _ from 'lodash';
import { SortableList, AbstractSortableList } from 'containers/Common/AbstractSortableList';
import { getLimitationAddonDependency } from 'utils/LimitationUtil';
import { SelectOptions } from 'components/common/commonType';
import { CampaignGroup, CampaignGroupChannel } from 'core/campaignGroup/CampaignGroup';
import { FilterMenuTabConfig } from 'components/common/FilterMenuTab/FilterMenuTab';

const timeFormat = 'YYYY-MM-DD HH:mm:ss';
export interface CampaignListModel extends SortableList {
  readonly order: Order;
  readonly campaignGroup: CampaignGroup | undefined;
  readonly campaignList: Array<RtbCampaignBasic>;
  readonly state: CampaignListState;
  readonly event: UpdateEventListener<CampaignListModel>;
  readonly campaignTags: Array<string>;
  readonly campaignStates: Array<string>;
  readonly objectTypes: Array<string>;
  readonly canCreateCampaign: boolean;
  readonly canSplitCampaign: boolean;
  readonly showSplitBtn: boolean;
  readonly searchString: string;
  readonly lang: string;
  readonly canNotCreateMessage: string;
  readonly newCampaignPath: string;
  readonly editCampaignPath: string;
  readonly copyCampaignPath: string;
  readonly filterMenuTabConfigs: Array<FilterMenuTabConfig>;
  deleteCampaignIds: Array<number>;
  deleteDraftIds: Array<number>;
  onCampaignChange: () => void;
  handleOnSelect (campaign): void;
  handleOnSelectAll (): void;
  handleRemoveSelect (): void;
  getColumnDefinition (columnName): ColumnDefinition;
  showTable (listType, e): void;
  handleOnSearch (campaignName): void;
  handleOnTagFilterApply (tags): void;
  handleOnStateFilterApply (stateDesList): void;
  handleOnObjectTypeFilterApply (objectTypeList): void;
  activeCampaign (event): Promise<void>;
  deactiveCampaign (event): Promise<void>;
  allSelectedCampaignSameType (): boolean;
  allSelectedCampaignSameRetail (): boolean;
  selectUnknowAdTypeCampaign (): boolean;
  deleteCampaign (campaignIds): Promise<void>;
  deleteDraft (draftIds): Promise<void>;
  updateBidWeight (bidWeight: number): Promise<void>;
  canDeleteSelectedCampaigns (campaignIds: Array<number>): boolean;
  setVisibilityOfDeleteConfirmModal (show: boolean): void;
  setVisibilityOfDraftDeleteConfirmModal (show: boolean): void;
  setCampaignToChangeBidWeight (campaignId?: number | string): void;
  getReportLink (campaign): string;
  updateModelData (order: Order, campaignList: Array<RtbCampaignBasic>): void;
  updateViewModelData (): void;
  onSearchChange (searchString): void;
  getTabs (): SelectOptions[];
  setVisibilityOfDraftCreateModal (show: boolean): void;
}

export type CampaignListProps = {
  readonly model: CampaignListModel;
};

export enum CampaignListType {
  BASIC = 'basic',
  PERFORMANCE = 'performance'
  // VIDEO_PERFORMANCE = 'videoPerformance'
}

export type CampaignListState = {
  readonly loading: boolean;
  readonly selectedCampaign: number[];
  readonly selectedDraft: number[];
  readonly columnsToShow: CampaignListColumns[];
  readonly seletedTab: string;
  readonly viewModelData: any[];
  readonly summaryData: any;
  readonly selectedTagFilter: string[];
  readonly selectedStateFilter: string[];
  readonly selectedObjectTypeFilter: string[];
  readonly showDraftCreateModal: boolean;
  readonly showDraftDeleteConfirmModal: boolean;
  readonly showDeleteConfirmModal: boolean;
  readonly campaignToChangeBidWeight?: number | string;
  readonly needUpdateViewModelData: boolean;
};

export class DefaultCampaignListModel extends AbstractSortableList implements CampaignListModel {

  event: FireableUpdateEventListener<CampaignListModel> = new FireableUpdateEventListener<CampaignListModel>();
  selectedCampaign: number[] = [];
  selectedDraft: number[] = [];
  filteredViewModelData: any[] = [];
  columnsToShow: Array<CampaignListColumns>;
  seletedTab: string = CampaignListType.BASIC;
  campaignTags: string[] = [];
  campaignStates: string[] = [];
  objectTypes: string[] = [];
  searchString: string;
  selectedTagFilter: string[] = [];
  selectedStateFilter: string[] = [];
  selectedObjectTypeFilter: string[] = [];
  loading: boolean = false;
  showDeleteConfirmModal: boolean = false;
  showDraftCreateModal: boolean = false;
  showDraftDeleteConfirmModal: boolean = false;
  campaignToChangeBidWeight?: number | string;
  lang: string = i18n.language;
  modelDeleteCampaignIds: number[] = [];
  modelDeleteDraftIds: number[] = [];
  needUpdateViewModelData: boolean = false;
  summaryData: any = {};

  constructor (
    public order: Order,
    public campaignGroup: CampaignGroup | undefined,
    public campaignList: any[],
    protected addonFeatureManager: AddonFeatureManager,
    public onCampaignChange,
    public onSearchChange: (search: string) => void,
    defaultSearchString: string | null,
    private budgetBalance: number,
    public manager: RtbCampaignManager = new DefaultRtbCampaignManager(),
    public rtbCampaignDraftManager: DraftManager = new RtbCampaignDraftManager()
  ) {
    super('id', 'desc');
    this.columnsToShow = [...basicColumns];
    if (this.hideBudget) {
      this.columnsToShow = this.columnsToShow.filter(column => column !== CampaignListColumns.PROGRESS);
    }
    if (this.campaignGroup?.channel === CampaignGroupChannel.RETAIL_MEDIA) {
      this.columnsToShow.splice(1, 0, CampaignListColumns.RETAIL);
    }
    this.searchString = defaultSearchString ? defaultSearchString : '';
    this.updateViewModelData();
  }

  get canNotCreateMessage (): string {
    const canNotCreateState = [State.NOT_APPROVE, State.REJECT, State.SETTLE, State.SETTLED, State.CHANGE_PENDING];
    const state = this.order.state;
    if (canNotCreateState.includes(state)) {
      return i18n.t('orderDetail.labels.orderStateCannotCreate');
    }

    const isEnd = moment(this.order.endDate).isBefore(moment().startOf('day'));
    if (isEnd) {
      return i18n.t('orderDetail.labels.isEndCannotCreate');
    }

    if (this.budgetBalance <= 0) {
      return i18n.t('orderDetail.labels.lessThanBudgetMinimum', { budget: 0 });
    }

    return '';
  }

  getCanNotCopyMessage (campaign: any): string {
    const canNotCreateState = [State.NOT_APPROVE, State.REJECT, State.SETTLE, State.SETTLED, State.CHANGE_PENDING];
    const state = this.order.state;
    if (canNotCreateState.includes(state)) {
      return i18n.t('orderDetail.labels.orderStateCannotCreate');
    }

    const isEnd = moment(this.order.endDate).isBefore(moment().startOf('day'));
    if (isEnd) {
      return i18n.t('orderDetail.labels.isEndCannotCreate');
    }
    const campaignIsStart = moment().isAfter(campaign.startDate);
    const campaignIsEnd = moment().isAfter(campaign.endDate);
    let startDate = moment(campaign.startDate);
    if (campaignIsStart) {
      startDate = moment().startOf('day');
    }
    let endDate = moment(campaign.endDate);
    if (campaignIsEnd) {
      endDate = moment(this.order.endDate).endOf('day');
    }
    const minCampaignBudgetPerDay = this.order.campaignConstraint.budgetMinimum;
    const scheduleDateCount = endDate.diff(startDate, 'days') + 1;
    const minBudgetOfNewCampaign = minCampaignBudgetPerDay * scheduleDateCount;
    if (this.budgetBalance < minBudgetOfNewCampaign) {
      return i18n.t('campaignList.labels.canCopyNeedMore', {
        parent: this.campaignGroup && this.campaignGroup.budget && this.campaignGroup.budget !== '' ? 'Campaign group' : 'Order',
        budget: formatPriceWithCurrency(this.order.currency, minBudgetOfNewCampaign - this.budgetBalance)
      });
    }

    return '';
  }

  getCanNotSplitMessage (campaign: any): string {
    const canNotCreateState = [State.NOT_APPROVE, State.REJECT, State.SETTLE, State.SETTLED, State.CHANGE_PENDING];
    const state = this.order.state;
    if (canNotCreateState.includes(state)) {
      return i18n.t('orderDetail.labels.orderStateCannotCreate');
    }

    const isEnd = moment(this.order.endDate).isBefore(moment().startOf('day'));
    if (isEnd) {
      return i18n.t('orderDetail.labels.isEndCannotCreate');
    }

    if (this.campaignGroup && this.campaignGroup.autoOptimise) {
      return '';
    }

    const targetBudget = campaign.budget;
    const expectSpent = campaign.spents * 1.1;
    const minCampaignBudgetPerDay = this.order.campaignConstraint.budgetMinimum;
    const startDate = moment(campaign.startDate);
    const today = moment().startOf('day');
    const isStart = today.isAfter(startDate);
    const endDate = moment(campaign.endDate);
    const scheduleDateCount = endDate.diff(isStart ? today : startDate, 'days') + 1;
    const minBudgetFromNow = minCampaignBudgetPerDay * scheduleDateCount;
    const firstCampaignBudget = isStart ?
      Math.max(expectSpent, minCampaignBudgetPerDay * today.diff(startDate, 'days')) + minBudgetFromNow :
      minBudgetFromNow;
    const secondCampaignBudget = minBudgetFromNow;
    const minBudgetFor2Campaign = firstCampaignBudget + secondCampaignBudget;
    const isBelowMinBudget = targetBudget < minBudgetFor2Campaign;
    if (isBelowMinBudget) {
      return i18n.t('campaignList.labels.canSplitMinimum', { budget: formatPriceWithCurrency(this.order.currency, minBudgetFor2Campaign) });
    }

    return '';
  }

  get deleteCampaignIds (): Array<number> {
    return this.modelDeleteCampaignIds;
  }

  set deleteCampaignIds (campaignIds) {
    this.modelDeleteCampaignIds = campaignIds;
  }

  get deleteDraftIds (): Array<number> {
    return this.modelDeleteDraftIds;
  }

  set deleteDraftIds (draftIds) {
    this.modelDeleteDraftIds = draftIds;
  }

  get state (): CampaignListState {
    return {
      selectedCampaign: this.selectedCampaign,
      selectedDraft: this.selectedDraft,
      columnsToShow: this.columnsToShow,
      seletedTab: this.seletedTab,
      needUpdateViewModelData: this.needUpdateViewModelData,
      viewModelData: this.filteredViewModelData,
      summaryData: this.summaryData,
      selectedTagFilter: this.selectedTagFilter,
      selectedStateFilter: this.selectedStateFilter,
      selectedObjectTypeFilter: this.selectedObjectTypeFilter,
      loading: this.loading,
      showDraftCreateModal: this.showDraftCreateModal,
      showDraftDeleteConfirmModal: this.showDraftDeleteConfirmModal,
      showDeleteConfirmModal: this.showDeleteConfirmModal,
      campaignToChangeBidWeight: this.campaignToChangeBidWeight
    };
  }

  get canCreateCampaign (): boolean {
    const canNotCreateState = [State.NOT_APPROVE, State.REJECT, State.SETTLE, State.SETTLED, State.CHANGE_PENDING];
    const state = this.order.state;
    const isEnd = moment(this.order.endDate).isBefore(moment().startOf('day'));
    const noBudget = this.budgetBalance <= 0;
    return !(canNotCreateState.includes(state) || isEnd || noBudget);
  }

  get canSplitCampaign (): boolean {
    const canNotSplitState = [State.NOT_APPROVE, State.REJECT, State.SETTLE, State.SETTLED, State.CHANGE_PENDING];
    const state = this.order.state;
    const isEnd = moment(this.order.endDate).isBefore(moment().startOf('day'));
    return !(canNotSplitState.includes(state) || isEnd);
  }

  get showSplitBtn (): boolean {
    return this.campaignGroup && this.campaignGroup.autoOptimise ? false : true;
  }

  get newCampaignPath () {
    return 'new';
  }

  get editCampaignPath () {
    return 'edit';
  }

  get copyCampaignPath () {
    return 'copy';
  }

  get filterMenuTabConfigs (): FilterMenuTabConfig[] {
    return [
      {
        filterType: i18n.t('campaignList.labels.filterState'),
        menuTitle: i18n.t('campaignList.labels.stateFilterMenuTitle'),
        tag: i18n.t('campaignList.labels.stateTag'),
        selectedValues: this.state.selectedStateFilter,
        options: this.campaignStates,
        applyMethod: this.handleOnStateFilterApply
      },
      {
        filterType: i18n.t('campaignList.labels.filterTag'),
        menuTitle: i18n.t('campaignList.labels.tagFilterMenuTitle'),
        tag: i18n.t('campaignList.labels.tagTag'),
        selectedValues: this.state.selectedTagFilter,
        options: this.campaignTags,
        applyMethod: this.handleOnTagFilterApply
      },
      {
        filterType: i18n.t('goCampaignList.labels.filterObjectType'),
        menuTitle: i18n.t('goCampaignList.labels.objectTypeFilterMenuTitle'),
        tag: i18n.t('goCampaignList.labels.objectTypeTag'),
        selectedValues: this.state.selectedObjectTypeFilter,
        options: this.objectTypes,
        applyMethod: this.handleOnObjectTypeFilterApply
      }
    ];
  }

  get hideBudget () {
    return this.campaignGroup && this.campaignGroup.autoOptimise;
  }

  getTabs () {
    return Object.values(CampaignListType).map(tab => ({
      label: i18n.t(`campaignList.tabs.${tab}`),
      value: tab
    }));
  }

  sortComparator = (field: string | undefined, dataA, dataB, order) => {
    const dataAToCompare = field ? dataA[field] : dataA;
    const dataBToCompare = field ? dataB[field] : dataB;
    if (order === 'desc') {
      return dataAToCompare < dataBToCompare ? 1 : -1;
    }

    return dataAToCompare > dataBToCompare ? 1 : -1;
  }

  getColumnDefinition (columnName): ColumnDefinition {
    let additional = {};
    const getSortFunc = (dataField?: string) => {
      return _.partial(this.sortComparator, dataField);
    };
    let customLabel;
    switch (columnName) {
      case CampaignListColumns.NAME:
        additional = {
          events: {
            onClick: () => {}
          },
          sort: true,
          onSort: this.handleSort,
          sortFunc: (dataA, dataB, order) => {
            const dataAIsDraft = _.get(dataA, 'listId', 0).toString().includes('draft');
            const dataBIsDraft = _.get(dataB, 'listId', 0).toString().includes('draft');
            const sameObjectType = dataAIsDraft === dataBIsDraft;
            const stateDes = [
              i18n.t('campaignList.labels.budgetRemainState'),
              i18n.t('campaignList.labels.activateState'),
              i18n.t('campaignList.labels.stoppingState'),
              i18n.t('campaignList.labels.deactivateState'),
              i18n.t('campaignList.labels.noCreativesState'),
              i18n.t('campaignList.labels.notStartState'),
              i18n.t('campaignList.labels.endState'),
              i18n.t('campaignList.labels.deleteState')
            ];
            const dataAToCompare = stateDes.indexOf(dataA['stateDes']['des']);
            const dataBToCompare = stateDes.indexOf(dataB['stateDes']['des']);
            const compareListId = _.get(dataA, 'listId', 0).toString().localeCompare(_.get(dataB, 'listId', 0).toString());
            if (!sameObjectType) {
              return dataAIsDraft ? -1 : 1;
            }
            if (dataAToCompare === dataBToCompare) {
              if (compareListId === 0) {
                return 0;
              }
              if (order === 'desc') {
                return compareListId > 0 ? 1 : -1;
              } else {
                return compareListId < 0 ? 1 : -1;
              }
            }
            if (order === 'desc') {
              return dataAToCompare > dataBToCompare ? 1 : -1;
            }
            return dataAToCompare < dataBToCompare ? 1 : -1;
          }
        };
        break;
      case CampaignListColumns.CREATIVE:
        additional = {
          sort: true,
          onSort: this.handleSort,
          sortFunc: getSortFunc()
        };
        break;
      case CampaignListColumns.SCHEDULE:
        additional = {
          sort: true,
          onSort: this.handleSort,
          sortFunc: (dataA, dataB, order) => {
            if (!dataA || !dataB) {
              return !dataA ? -1 : 1;
            }

            if (order === 'desc') {
              return moment(dataA.start).isBefore(moment(dataB.start)) ? 1 : -1;
            }

            return moment(dataB.start).isBefore(moment(dataA.start)) ? 1 : -1;
          }
        };
        break;
      case CampaignListColumns.PROGRESS:
        additional = {
          sort: true,
          onSort: this.handleSort,
          sortFunc: getSortFunc('executeRate')
        };
        break;
      case CampaignListColumns.BUDGET:
        customLabel = this.hideBudget ? i18n.t('campaignList.headers.budgetDistributionColumn') : i18n.t('campaignList.headers.budgetColumn');
        additional = {
          sort: true,
          onSort: this.handleSort,
          sortFunc: getSortFunc('budget')
        };
        break;
      case CampaignListColumns.EDITBTNS:
        additional = {
          text: ''
        };
        break;
      case CampaignListColumns.RESULTS:
        additional = {
          sort: true,
          onSort: this.handleSort,
          sortFunc: getSortFunc('value'),
          formatExtraData: {
            objective: _.get(this.campaignGroup, 'objective')
          }
        };
        break;
      case CampaignListColumns.SPENT:
        customLabel = i18n.t(`campaignList.headers.${CampaignListColumns.SPENT}`) + ` (${this.order.currency})`;
        additional = {
          sort: true,
          onSort: this.handleSort
        };
        break;
      case CampaignListColumns.CPC:
        customLabel = i18n.t(`campaignList.headers.${CampaignListColumns.CPC}`) + ` (${this.order.currency})`;
        additional = {
          sort: true,
          onSort: this.handleSort,
          sortFunc: getSortFunc('value')
        };
        break;
      case CampaignListColumns.CPA:
        customLabel = i18n.t(`campaignList.headers.${CampaignListColumns.CPA}`) + ` (${this.order.currency})`;
        additional = {
          sort: true,
          onSort: this.handleSort,
          sortFunc: getSortFunc('value')
        };
        break;
      case CampaignListColumns.RETAIL:
        additional = {
          sort: true,
          onSort: this.handleSort
        };
        break;
      case CampaignListColumns.IMPRES:
      case CampaignListColumns.VIEWABLE:
      case CampaignListColumns.CLICKS:
      case CampaignListColumns.CTR:
      case CampaignListColumns.VCTR:
      case CampaignListColumns.CONVERT:
      case CampaignListColumns.CVR:
      case CampaignListColumns.VIEW:
      case CampaignListColumns.VIEWRATE:
      case CampaignListColumns.UUCOUNT:
      case CampaignListColumns.VIEWABLE_VIEWRATE:
        additional = {
          sort: true,
          onSort: this.handleSort,
          sortFunc: getSortFunc('value')
        };
        break;
      default:
        break;
    }

    return this.columnDefinition(columnName, customLabel, additional);
  }

  columnDefinition (columnName, customLabel, additional = {}): ColumnDefinition {
    const columnClassGetter = () => {
      return styles[columnName];
    };

    return {
      sort: false,
      text: customLabel ? customLabel : `campaignList.headers.${columnName}`,
      dataField: columnName,
      classes: columnClassGetter,
      headerClasses: columnClassGetter,
      ...additional
    };
  }

  getReportLink (campaign) {
    const from = encodeURIComponent(moment(campaign.startDate).startOf('day').format(timeFormat));
    const to = encodeURIComponent(moment(campaign.endDate).endOf('day').format(timeFormat));
    const dimension = 'goCampaignId';
    return `/reports/performance?dimension=${dimension}&from=${from}&to=${to}&${dimension}=${campaign.id}`;
  }

  getCampaignStateDes (campaign) {
    let des = '';
    let styleName = '';
    const now = moment();
    switch (campaign.state) {
      case CampaignState.DEACTIVATE:
        if ((getPriceValue(this.order.currency, campaign.budget) - getPriceValue(this.order.currency, campaign.spents)) > 0) {
          des = i18n.t('campaignList.labels.budgetRemainState');
          styleName = 'budgetRemain';
        } else {
          des = i18n.t('campaignList.labels.deactivateState');
          styleName = 'deactive';
        }
        break;
      case CampaignState.DELETE:
        des = i18n.t('campaignList.labels.deleteState');
        styleName = 'deleted';
        break;
      case CampaignState.ACTIVATE:
        if (moment(campaign.startDate).isAfter(now)) {
          des = i18n.t('campaignList.labels.notStartState');
          styleName = 'notStart';
        } else if (moment(campaign.endDate).isBefore(now)) {
          des = i18n.t('campaignList.labels.endState');
          styleName = 'end';
        } else if (campaign.additionalInfo && campaign.additionalInfo.creativeAmount.enableCount === 0) {
          des = i18n.t('campaignList.labels.noCreativesState');
          styleName = 'noCreatives';
        } else {
          des = i18n.t('campaignList.labels.activateState');
        }
        break;
      default:
        des = i18n.t('campaignList.labels.stoppingState');
        styleName = 'stopping';
        break;
    }

    return {
      des,
      styleName
    };
  }

  getCampaignTypeDes (type) {
    switch (type) {
      case AdType.THIRD_PARTY:
        return i18n.t('campaignList.labels.adTypeThirdParty');
      case AdType.THIRD_PARTY_BOTTOM:
        return i18n.t('campaignList.labels.adTypeThirdPartyBottom');
      case AdType.THIRD_PARTY_RECTANGLE:
        return i18n.t('campaignList.labels.adTypeThirdPartyRectangle');
      case AdType.VIDEO:
        return i18n.t('campaignList.labels.adTypeVideo');
      case AdType.OUTDOOR:
        return i18n.t('campaignList.labels.adTypeOutdoor');
      case AdType.DISPLAY:
        return i18n.t('campaignList.labels.adTypeDisplay');
      case AdType.COMBO:
        return i18n.t('campaignList.labels.adTypeCombo');
      case AdType.ONE_FOR_ALL_DISPLAY:
        return i18n.t('campaignList.labels.adTypeOneForAllDisplay');
      case AdType.PILOT_TV:
        return i18n.t('campaignList.labels.adTypePilotTV');
      default:
        return i18n.t('campaignList.labels.adTypeEmpty');
    }
  }

  getProgress (campaign) {
    const isDailyBudgetCampaign = campaign.dailyTargetBudget > 0;
    const campaignProgress = isDailyBudgetCampaign
      ? this.getDailyBudgetCampaignProgressRate(campaign)
      : this.getCampaignProgressRate(campaign);
    const executeRate = campaignProgress.executeRate;
    const predictRate = Math.min(campaignProgress.predictRate, 1);
    const warnignLine = campaign.budget * 1.1;
    const discrepancy = predictRate - executeRate;
    return {
      spents: `${this.order.currency} ${numberWithCommas(_.floor(campaign.spents, 2).toFixed(2))}`,
      budget: `${this.order.currency} ${numberWithCommas(_.floor(campaign.budget, 2).toFixed(2))}`,
      executeRate,
      predictRate,
      danger: campaign.spents > warnignLine,
      deepWarning: discrepancy > 0.03,
      warning: discrepancy > 0.01 && discrepancy <= 0.03
    };
  }

  getDistributeDes (dailyTargetBudget) {
    return dailyTargetBudget && dailyTargetBudget > 0 ?
      i18n.t('campaignList.labels.budgetDailyDistribution', { currency: this.order.currency, budget: formatPrice(this.order.currency, dailyTargetBudget) }) :
      i18n.t('campaignList.labels.budgetSchedule');
  }

  getPriceModelDes (priceModel) {
    return i18n.t(`campaign.labels.${priceModel}`);
  }

  getLimitationsToShow (campaign) {
    let limitations = _.omit(_.get(campaign, 'additionalInfo.limitations', {}), ['other']);
    let result = {};
    let limitationKeys = _.filter(Object.keys(limitations), limitationKey => limitations[limitationKey].length > 0);
    limitationKeys.forEach(key => {
      const value = _.filter(limitations[key], limitation => {
        const addonDependency = getLimitationAddonDependency('campaign', limitation.type);
        return limitation.type !== 'unicornlanguage' &&
          !!addonDependency &&
          this.addonFeatureManager.isFeatureEnable(addonDependency);
      })
        .map(limitation => this.getLimitationTypeDesc(limitation.type))
        .join(', ');
      if (!_.isEmpty(value)) {
        result[key] = value;
      }
    });
    const ageMin = _.get(campaign, 'ageMin');
    const ageMax = _.get(campaign, 'ageMax');
    if (Number.isInteger(ageMin) && Number.isInteger(ageMax)) {
      const ageDesc = this.getLimitationTypeDesc('age');
      result['general'] = ageDesc;
    }
    const includeLimitations = _.get(limitations, 'include', []);
    const genderLimitation = includeLimitations.find(limitation => limitation.type === 'gender');
    if (genderLimitation) {
      const genderDesc = this.getLimitationTypeDesc('gender');
      result['general'] = result['general'] ? `${result['general']}, ${genderDesc}` : genderDesc;
    }
    return result;
  }

  getCpc (campaign) {
    let spents = campaign.spents;
    let clicks = campaign.clicks;
    let cpc = 0;
    if (clicks !== 0) {
      cpc = spents / clicks;
    }

    return cpc;
  }

  getCtr (campaign) {
    let impres = campaign.impres;
    let clicks = campaign.clicks;
    let vctr = 0;
    if (impres !== 0) {
      vctr = (clicks / impres) * 100;
    }
    return vctr;
  }

  getVctr (campaign) {
    let viewable = campaign.viewable;
    let clicks = campaign.clicks;
    let vctr = 0;
    if (viewable !== 0) {
      vctr = (clicks / viewable) * 100;
    }
    return vctr;
  }

  getCpa (campaign) {
    let spents = campaign.spents;
    let convs = campaign.convs;
    let cpa = 0;
    if (convs !== 0) {
      cpa = spents / convs;
    }
    return cpa;
  }

  getCvr (campaign) {
    let convs = campaign.convs;
    let clicks = campaign.clicks;
    let cvr = 0;
    if (clicks !== 0) {
      cvr = (convs / clicks) * 100;
    }
    return cvr;
  }

  getViewRate = (campaign) => {
    let adView = campaign.adView;
    let impres = campaign.impres;
    let viewRate = 0;
    if (impres !== 0) {
      viewRate = (adView / impres) * 100;
    }
    return viewRate;
  }

  getViewableViewRate = (campaign) => {
    let adView = campaign.adView;
    let viewable = campaign.viewable;
    let viewRate = 0;
    if (viewable !== 0) {
      viewRate = (adView / viewable) * 100;
    }
    return viewRate;
  }

  getLimitationTypeDesc (limitationType) {
    switch (limitationType) {
      case 'geography':
        return i18n.t('limitation.labels.country');
      case 'adspace':
        return i18n.t('limitation.labels.space');
      case 'unicornkeywords':
        return i18n.t('limitation.labels.keyword');
      case 'contentcat':
        return i18n.t('limitation.labels.articleCategory');
      case 'adcat':
        return i18n.t('limitation.labels.tenmaxCategory');
      case 'age':
        return i18n.t('limitation.labels.ages');
      default:
        return i18n.t(`limitation.labels.${limitationType}`);
    }
  }

  getDeliverTypeDesc (deliverType) {
    if (deliverType === DeliverType.STANDARD) {
      return i18n.t('campaignList.labels.deliverStandard');
    }

    return i18n.t('campaignList.labels.deliverAsap');
  }

  getObjectTypeDesc (campaign) {
    if (campaign.isDraft) {
      return i18n.t('goCampaignList.labels.draft');
    } else {
      return i18n.t('goCampaignList.labels.ordinaryCampaign_rtb');
    }
  }

  getViewModelData () {
    this.campaignTags = [];
    this.campaignStates = [];
    this.objectTypes = [];
    const showBudget = this.campaignGroup ? !this.campaignGroup.autoOptimise : true;
    const viewModelData: Array<any> = this.campaignList.map(campaign => {
      const viewTrackingSize = _.get(campaign, 'additionalInfo.viewTrackingSize', 0);
      const conversionTracking = _.get(campaign, 'additionalInfo.conversionTracking');
      const videoProgressTrackingOffset = _.get(campaign, 'additionalInfo.videoProgressTrackingOffset');
      this.campaignTags = _.uniq(_.concat(this.campaignTags, campaign.tags ? campaign.tags : []));
      const stateDes = this.getCampaignStateDes(campaign);
      this.campaignStates = _.uniq(_.concat(this.campaignStates, stateDes.des));
      const objectTypeDes = this.getObjectTypeDesc(campaign);
      this.objectTypes = _.uniq(_.concat(this.objectTypes, objectTypeDes));
      const results = _.get(campaign, 'report.results', 0);
      const campaignData: any = {
        listId: campaign.isDraft ? `${campaign.id}_draft_${campaign.draftId}` : campaign.id,
        id: campaign.id,
        draftId: campaign.draftId,
        spents: campaign.spents,
        budget: campaign.budget,
        optimize: campaign.optimize,
        bidPrice: campaign.bidPrice,
        impres: campaign.impres,
        startDate: campaign.startDate,
        endDate: campaign.endDate,
        state: campaign.state,
        effectiveStatus: campaign.effectiveStatus,
        additionalInfo: campaign.additionalInfo,
        magnificationRatio: campaign.magnificationRatio,
        retailType: campaign.retailType,
        resultsColumn: {
          value: results ? results : 0,
          desc: results ? numberWithCommas(results) : '0'
        },
        isDraft: campaign.isDraft,
        nameColumn: {
          name: campaign.name,
          listId: campaign.isDraft ? `${campaign.id}_draft_${campaign.draftId}` : campaign.id,
          stateDes,
          typeDes: this.getCampaignTypeDes(campaign.adType)
        },
        creativeColumn: campaign.additionalInfo ?
          campaign.additionalInfo.creativeAmount.enableCount :
          0,
        scheduleColumn: {
          start: moment(campaign.startDate).format(timeFormat),
          end: moment(campaign.endDate).format(timeFormat)
        },
        progressColumn: showBudget ? this.getProgress(campaign) : undefined,
        budgetColumn: {
          budget: campaign.budget,
          budgetDes: showBudget ? formatPriceWithCurrency(this.order.currency, campaign.budget) : undefined,
          distributeDes: this.getDistributeDes(campaign.dailyTargetBudget),
          deliverTypeDes: this.getDeliverTypeDesc(campaign.deliverType)
        },
        priceColumn: {
          priceModel: this.getPriceModelDes(campaign.priceModel),
          type: campaign.priceModel !== RtbCampaignPlanType.RB ?
            campaign.optimize :
            undefined,
          bidPriceDes: campaign.priceModel !== RtbCampaignPlanType.RB ?
            `${this.order.currency} ${numberWithCommas(campaign.bidPrice)}` :
            undefined
        },
        limitationColumn: this.getLimitationsToShow(campaign),
        trackingColumn: _.omitBy({
          conversionTracking: conversionTracking && i18n.t('campaignList.labels.conversionTracking'),
          viewTrackingCodes: viewTrackingSize > 0 ? i18n.t('campaignList.labels.viewTrackingCodes') : undefined,
          videoProgressTrackingCode: videoProgressTrackingOffset && i18n.t('campaignList.labels.videoProgressTrackingCode')
        }, _.isUndefined),
        tagsColumn: campaign.tags,
        viewableColumn: {
          value: campaign.viewable,
          desc: numberWithCommas(campaign.viewable)
        },
        impresColumn: {
          value: campaign.impres,
          desc: numberWithCommas(campaign.impres)
        },
        clicksColumn: {
          value: campaign.clicks,
          desc: numberWithCommas(campaign.clicks)
        },
        convertColumn: {
          value: campaign.convs,
          desc: numberWithCommas(campaign.convs)
        },
        viewColumn: {
          value: campaign.adView,
          desc: numberWithCommas(campaign.adView)
        },
        cpcColumn: { value: getPriceValue(this.order.currency, this.getCpc(campaign)) },
        ctrColumn: { value: this.getCtr(campaign) },
        vctrColumn: { value: this.getVctr(campaign) },
        cpaColumn: { value: getPriceValue(this.order.currency, this.getCpa(campaign)) },
        cvrColumn: { value: this.getCvr(campaign) },
        viewRateColumn: { value: this.getViewRate(campaign) },
        viewableViewRateColumn: { value: this.getViewableViewRate(campaign) },
        uuCountColumn: { value: campaign.uuCount }
      };
      campaignData.canNotCopyMessage = this.getCanNotCopyMessage(campaignData);
      campaignData.canNotSlitMessage = this.getCanNotSplitMessage(campaignData);
      return campaignData;
    });

    this.summaryData = {
      id: 0,
      listId: 'summaryRow',
      ...this.getSummaryData(this.campaignList)
    };

    return viewModelData;
  }

  showTable = (listType: CampaignListType, e) => {
    e && e.stopPropagation();
    this.seletedTab = listType;
    const viewableEnable = this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.REPORT.REPORT_VIEWABLE_CTR);
    switch (listType) {
      case CampaignListType.PERFORMANCE:
        if (viewableEnable) {
          this.columnsToShow = [...performanceColumnsWithViewable];
        } else {
          this.columnsToShow = [...performanceColumns];
        }
        break;
      // case CampaignListType.VIDEO_PERFORMANCE:
      //   if (viewableEnable) {
      //     this.columnsToShow = videoPerformanceColumnsWithViewable;
      //   } else {
      //     this.columnsToShow = videoPerformanceColumns;
      //   }
      //   break;
      default:
        this.columnsToShow = [...basicColumns];
        break;
    }
    if (this.hideBudget) {
      this.columnsToShow = this.columnsToShow.filter(column => column !== CampaignListColumns.PROGRESS);
    }
    if (this.campaignGroup?.channel === CampaignGroupChannel.RETAIL_MEDIA) {
      this.columnsToShow.splice(1, 0, CampaignListColumns.RETAIL);
    }
    this.updateState();
  }

  handleOnSelect = (campaign) => {
    const isDraft: boolean = _.get(campaign, 'isDraft', false);
    const objectId = isDraft ? campaign.draftId : campaign.id;
    const selectedArray: number[] = isDraft ? this.selectedDraft : this.selectedCampaign;
    if (selectedArray.indexOf(objectId) > -1) {
      _.remove(selectedArray, id => id === objectId);
    } else {
      selectedArray.push(objectId);
    }

    this.updateState();
  }

  handleOnSelectAll = () => {
    const selectedArrays = [...this.selectedCampaign, ...this.selectedDraft];
    if (selectedArrays.length === this.filteredViewModelData.length) {
      this.selectedCampaign = [];
      this.selectedDraft = [];
    } else {
      this.selectedCampaign = this.filteredViewModelData
        .filter(viewModelData => !viewModelData.isDraft)
        .map(viewModelData => viewModelData.id);
      this.selectedDraft = this.filteredViewModelData
        .filter(viewModelData => viewModelData.isDraft)
        .map(viewModelData => viewModelData.draftId);
    }
    this.updateState();
  }

  handleOnSearch = (searchString) => {
    this.searchString = searchString;
    this.onSearchChange(searchString);
    this.updateViewModelData();
  }

  handleOnTagFilterApply = (tags) => {
    this.selectedTagFilter = [...tags];
    this.updateViewModelData();
  }

  handleOnStateFilterApply = (stateDesList) => {
    this.selectedStateFilter = [...stateDesList];
    this.updateViewModelData();
  }

  handleOnObjectTypeFilterApply = (objectTypeList) => {
    this.selectedObjectTypeFilter = [...objectTypeList];
    this.updateViewModelData();
  }

  updateViewModelData = () => {
    this.needUpdateViewModelData = false;
    const allViewModelData = this.getViewModelData();
    this.filteredViewModelData = _.filter(allViewModelData,
      viewModelData => {
        const nameIsMatch = viewModelData.nameColumn.name.toLowerCase().includes(this.searchString.toLowerCase());
        const listIdIsMatch = viewModelData.listId ? viewModelData.listId.toString().includes(this.searchString) : false;
        const filterIsMatch = (this.selectedTagFilter.length === 0 && this.selectedStateFilter.length === 0 && this.selectedObjectTypeFilter.length === 0) ||
          _.intersection(viewModelData.tagsColumn, this.selectedTagFilter).length > 0 ||
          this.selectedStateFilter.includes(viewModelData.nameColumn.stateDes.des) ||
          this.selectedObjectTypeFilter.includes(this.getObjectTypeDesc(viewModelData));
        return (nameIsMatch || listIdIsMatch) && filterIsMatch;
      }
    );
    const selectedObjects = _.intersectionWith(this.campaignList, this.filteredViewModelData,
      (campaign, viewModel) => viewModel.isDraft ? campaign.draftId === viewModel.draftId : campaign.id === viewModel.id);
    this.summaryData = {
      id: 0,
      listId: 'summaryRow',
      ...this.getSummaryData(selectedObjects)
    };

    this.updateState();
  }

  getSummaryData = (campaignList: Array<any>) => {
    const viewableSum = campaignList.reduce<number>((partial, campaign) => partial + campaign.viewable, 0);
    const clickSum = campaignList.reduce<number>((partial, campaign) => partial + campaign.clicks, 0);
    const convsSum = campaignList.reduce<number>((partial, campaign) => partial + campaign.convs, 0);
    const spentsSum = campaignList.reduce<number>((partial, campaign) => partial + getPriceValue(this.order.currency, campaign.spents), 0);
    const impresSum = campaignList.reduce<number>((partial, campaign) => partial + campaign.impres, 0);
    const viewSum = campaignList.reduce<number>((partial, campaign) => partial + campaign.adView, 0);
    return {
      nameColumn: i18n.t('campaignList.labels.campaignCount', { count: campaignList.length }),
      budgetColumn: this.hideBudget ? '' : i18n.t('campaignList.labels.campaignBudget', {
        number: formatPriceWithCurrency(
          this.order.currency,
          campaignList.reduce<number>((partial, campaign) => partial + campaign.budget, 0)
        )}
      ),
      viewableColumn: numberWithCommas(viewableSum),
      impresColumn: numberWithCommas(impresSum),
      clicksColumn: numberWithCommas(clickSum),
      convertColumn: numberWithCommas(convsSum),
      viewColumn: numberWithCommas(viewSum),
      spents: numberWithCommas(spentsSum),
      cpcColumn: formatPrice(this.order.currency, clickSum === 0 ? 0 : spentsSum / clickSum),
      ctrColumn: `${impresSum === 0 ? 0.00 : ((clickSum / impresSum) * 100).toFixed(2)}%`,
      vctrColumn: `${viewableSum === 0 ? 0.00 : ((clickSum / viewableSum) * 100).toFixed(2)}%`,
      cpaColumn: formatPrice(this.order.currency, convsSum === 0 ? 0 : spentsSum / convsSum),
      cvrColumn: `${clickSum === 0 ? 0.00 : ((convsSum / clickSum) * 100).toFixed(2)}%`,
      viewRateColumn: `${impresSum === 0 ? 0.00 : ((viewSum / impresSum) * 100).toFixed(2)}%`,
      viewableViewRateColumn: `${viewableSum === 0 ? 0.00 : ((viewSum / viewableSum) * 100).toFixed(2)}%`
    };
  }

  getDailyBudgetCampaignProgressRate = ({ budget, spents, startDate, endDate }) => {
    const startTime = moment(startDate).startOf('day');
    const endTime = moment(endDate).startOf('day');
    const now = moment().startOf('day');
    const isStart = moment().isSameOrAfter(moment(startDate));
    const isEnd = moment().isAfter(moment(endDate));

    let predictRate;
    if (!isStart) {
      predictRate = 0;
    } else if (isEnd) {
      predictRate = 1;
    } else {
      predictRate = (now.diff(startTime, 'day') + 1) / (endTime.diff(startTime, 'day') + 1);
    }

    const canCalRate = budget !== 0;
    if (canCalRate) {
      return { executeRate: spents / budget, predictRate };
    }
    return { executeRate: 0, predictRate: 0 };
  }

  getCampaignProgressRate = ({ budget, currencyRate, olapActualSpent, olapExpectSpent }) => {
    const budgetUsd = budget / currencyRate;
    const canCalRate = budgetUsd !== 0;
    if (canCalRate) {
      return {
        executeRate: olapActualSpent / budgetUsd,
        predictRate: olapExpectSpent / budgetUsd
      };
    }
    return {
      executeRate: 0,
      predictRate: 0
    };
  }

  handleRemoveSelect = () => {
    this.selectedCampaign = [];
    this.selectedDraft = [];
    this.updateState();
  }

  activeCampaign = async (event): Promise<void> => {
    event && event.stopPropagation();
    this.excuteCampaignAction('activate', i18n.t('campaignList.labels.activeSuccess'));
  }

  deactiveCampaign = async (event): Promise<void> => {
    event && event.stopPropagation();
    this.excuteCampaignAction('deactivate', i18n.t('campaignList.labels.deactiveSuccess'));
  }

  generateUpdateStatePayload = (selectedObjects: number[], objectType: 'campaign' | 'draft') => {
    const key = objectType === 'campaign' ? 'id' : 'draftId';
    const payload = selectedObjects.map(objectId => {
      const object = this.campaignList.find(campaign => _.get(campaign, key) && _.get(campaign, key).toString() === objectId.toString());
      return {
        goCampaignChannelId: objectId,
        isDraft: _.get(object, 'isDraft', false)
      };
    });
    return payload;
  }

  excuteCampaignAction = async (action: 'activate' | 'deactivate', successMessage) => {
    this.updateLoading(true);
    const updateStatePayload = [
      ...this.generateUpdateStatePayload(this.selectedCampaign, 'campaign'),
      ...this.generateUpdateStatePayload(this.selectedDraft, 'draft')
    ];
    try {
      await this.manager.updateCampaignState(updateStatePayload, action);
      this.selectedCampaign = [];
      this.selectedDraft = [];
      this.onCampaignChange();
      toast.success(successMessage);
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
    }
    this.updateLoading(false);
  }

  deleteCampaign = async (campaignIds: Array<number>): Promise<void> => {
    this.updateLoading(true);
    try {
      await this.manager.deleteCampaigns(campaignIds);
      this.selectedCampaign = [];
      this.onCampaignChange(true);
      toast.success(i18n.t('campaignList.labels.deleteSuccess'));
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
    }
    this.updateLoading(false);
  }

  deleteDraft = async (draftIds: Array<number>): Promise<void> => {
    this.updateLoading(true);
    try {
      await this.rtbCampaignDraftManager.deleteDrafts(draftIds);
      this.selectedDraft = [];
      this.onCampaignChange(true);
      toast.success(i18n.t('campaignList.labels.deleteSuccess'));
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
    }
    this.updateLoading(false);
  }

  updateBidWeight = async (bidWeight: number): Promise<void> => {
    if (!this.campaignToChangeBidWeight) {
      return;
    }
    this.updateLoading(true);
    try {
      await this.manager.updateCampaignBidWeight(this.campaignToChangeBidWeight, bidWeight);
      this.campaignToChangeBidWeight = undefined;
      this.onCampaignChange(true);
      toast.success(i18n.t('campaignList.labels.updateBidWeightSuccess'));
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
    }
    this.updateLoading(false);
  }

  allSelectedCampaignSameType () {
    if (this.selectedCampaign.length === 0 && this.selectedDraft.length === 0) {
      return true;
    }

    const types = _.uniq(
      _.filter(this.campaignList, campaign =>
        (campaign.id !== undefined && this.selectedCampaign.indexOf(campaign.id) > -1) ||
        (campaign.draftId !== undefined && this.selectedDraft.indexOf(campaign.draftId) > -1))
        .map(campaign => campaign.adType)
    );
    return types.length === 1;
  }

  allSelectedCampaignSameRetail () {
    if (this.selectedCampaign.length === 0 && this.selectedDraft.length === 0) {
      return true;
    }

    const retails = _.uniq(
      _.filter(this.campaignList, campaign =>
        (campaign.id !== undefined && this.selectedCampaign.indexOf(campaign.id) > -1) ||
        (campaign.draftId !== undefined && this.selectedDraft.indexOf(campaign.draftId) > -1))
        .map(campaign => campaign.retailType)
    );
    return retails.length === 1;
  }

  selectUnknowAdTypeCampaign () {
    if (this.selectedCampaign.length === 0 && this.selectedDraft.length === 0) {
      return false;
    }

    const selectCampaignList = _.filter(
      this.campaignList, campaign =>
      (campaign.id !== undefined && this.selectedCampaign.indexOf(campaign.id) > -1) ||
      (campaign.draftId !== undefined && this.selectedDraft.indexOf(campaign.draftId) > -1)
    );
    return selectCampaignList.filter(campaign => !campaign.adType).length > 0;
  }

  canDeleteSelectedCampaigns (campaignIds: Array<number>) {
    if (campaignIds.length === 0) {
      return false;
    }

    const canDeleteCampaigns = _.filter(this.campaignList, campaign => {
      const selected = campaign.id !== undefined && campaignIds.indexOf(campaign.id) > -1 && !campaign.isDraft;
      if (!selected) {
        return false;
      }
      const notStarted = moment(campaign.startDate).isAfter(moment());
      const notBindCreative = _.get(campaign, 'additionalInfo.creativeAmount.bindingCount', 0) === 0;
      return notStarted || notBindCreative;
    });

    return canDeleteCampaigns.length === campaignIds.length;
  }

  setVisibilityOfDeleteConfirmModal (show: boolean) {
    this.showDeleteConfirmModal = show;
    this.updateState();
  }

  setVisibilityOfDraftCreateModal = (show: boolean) => {
    this.showDraftCreateModal = show;
    this.updateState();
  }

  setVisibilityOfDraftDeleteConfirmModal = (show: boolean) => {
    this.showDraftDeleteConfirmModal = show;
    this.updateState();
  }

  setCampaignToChangeBidWeight (campaignId?: number | string) {
    this.campaignToChangeBidWeight = campaignId;
    this.updateState();
  }

  updateModelData (order: Order, campaignList: Array<RtbCampaignBasic>) {
    this.order = order;
    this.campaignList = campaignList;
    this.needUpdateViewModelData = true;
  }

  updateLoading (loading: boolean) {
    this.loading = loading;
    this.updateState();
  }

  updateState () {
    this.event.fireEvent(this);
  }
}

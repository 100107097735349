import { AddonFeatureManager } from 'core';
import { RtbCampaignSetupFlowPageModel, EditRtbCampaignDraftSetupFlowPageModel } from './RtbCampaignSetupFlow/RtbCampaignSetupFlowPageModel';
import { SelectOptions } from 'components/common/commonType';
import { Order } from 'core/order/Order';
import { CampaignGroup } from 'core/campaignGroup/CampaignGroup';
import { RtbCampaignBasic } from 'core/rtbCampaign/RtbCampaign';

export interface RtbCampaignDraftsModel {
  readonly addonFeatureManager: AddonFeatureManager;
  getEdtiCampaignDraftModel (draftId: string, localeMeta): RtbCampaignSetupFlowPageModel;
  setOrder (order: Order): void;
}

export type RtbCampaignDraftsProps = {
  readonly model: RtbCampaignDraftsModel;
};

export class DefaultRtbCampaignDraftsModel implements RtbCampaignDraftsModel {

  addonFeatureManager: AddonFeatureManager;
  advertisers?: Array<SelectOptions>;
  dratEditModel?: EditRtbCampaignDraftSetupFlowPageModel;

  order: Order;

  constructor (order: Order, addonFeatureManager: AddonFeatureManager, private campaignGroup?: CampaignGroup, private campaignList?: RtbCampaignBasic[]) {
    this.order = order;
    this.addonFeatureManager = addonFeatureManager;
  }

  setOrder (order: Order) {
    this.order = order;
    this.dratEditModel && this.dratEditModel.setOrder(order);
  }

  getEdtiCampaignDraftModel (draftId: string, localeMeta): RtbCampaignSetupFlowPageModel {
    if (this.dratEditModel && this.dratEditModel.campaignId === draftId) {
      return this.dratEditModel;
    }
    this.dratEditModel = new EditRtbCampaignDraftSetupFlowPageModel(
      draftId,
      this.order,
      this.addonFeatureManager,
      this.campaignGroup,
      localeMeta,
      this.campaignList ? this.campaignList : []
    );
    return this.dratEditModel;
  }
}

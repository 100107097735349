import _ from 'lodash';
import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import { toast } from 'react-toastify';
import i18n from 'i18next';
import { BindingManager, DefaultBindingManager } from 'core/binding/BindingManager';
import { CampaignGroupChannel } from 'core/campaignGroup/CampaignGroup';
import { CreativeManagementStateContext } from './CreativeManagementStateContext';

export type CreativeSummaryStateContentState = {
  readonly filteredData: Array<any>;
  readonly loading: boolean;
  readonly currentBindStateFilter?: boolean;
};

export interface CreativeSummaryStateContentModel {
  readonly state: CreativeSummaryStateContentState;
  readonly event: UpdateEventListener<CreativeSummaryStateContentModel>;
  readonly stateContext?: CreativeManagementStateContext;
  handleOnStateFilterClicked (bindStatefilter?: boolean): void;
  save (): Promise<void>;
  setGoNextFn (fn: () => void): void;
  setStateContext (stateContext: CreativeManagementStateContext): any;
}

export type CreativeSummaryStateContentProps = {
  readonly model: CreativeSummaryStateContentModel;
};

abstract class DefaultCreativeSummaryStateContentModel implements CreativeSummaryStateContentModel {
  event: FireableUpdateEventListener<CreativeSummaryStateContentModel>;
  summaryData: any;
  filteredData: Array<any>;
  currentBindStateFilter?: boolean;
  loading: boolean;
  presetLoading: boolean;
  goNextFn?: () => void;
  creativeData: any[];
  stateContext?: CreativeManagementStateContext;
  allCampaigns: any;
  allCreatives: any;

  constructor (
    creativeData: Array<any>,
    protected bindingManager: BindingManager = new DefaultBindingManager()
  ) {
    this.event = new FireableUpdateEventListener<CreativeSummaryStateContentModel>();
    this.loading = false;
    this.presetLoading = true;
    this.filteredData = [];
    this.currentBindStateFilter = undefined;
    this.allCampaigns = {};
    this.allCreatives = {};
    this.creativeData = creativeData.map(data => {
      this.allCampaigns[data.id] = data.name;
      return {
        ...data,
        creatives: data.creatives.map(creative => {
          this.allCreatives[creative.id] = creative.name;
          return {
            ...creative
          };
        })
      };
    });
  }

  get state (): CreativeSummaryStateContentState {
    return {
      filteredData: this.filteredData,
      loading: this.loading,
      currentBindStateFilter: this.currentBindStateFilter
    };
  }

  setStateContext = (stateContext) => {
    this.stateContext = stateContext;
    if (!this.stateContext) {
      return;
    }

    this.summaryData = this.creativeData.map(campaign => {
      const findNewCreativeData = stateContext.data.campaignNewCreativesMap && _.find(stateContext.data.campaignNewCreativesMap, newData => newData.id === campaign.id);
      let creatives = campaign.creatives;
      if (findNewCreativeData) {
        creatives = _.uniqWith(_.concat(campaign.creatives, findNewCreativeData.creatives), (creative1: any, creative2: any) => {
          return creative1.id === creative2.id;
        });
      }

      return {
        id: campaign.id,
        draftId: campaign.draftId,
        name: campaign.name,
        creatives: creatives.map(creative => {
          return {
            ...creative,
            isActiveBinding: stateContext.data.selectedCreatives && stateContext.data.selectedCreatives.indexOf(creative.id) >= 0 ?
              stateContext.data.activate === undefined ? true : stateContext.data.activate :
              creative.isActiveBinding
          };
        }).sort((creative1, creative2) => {
          if (creative1.isActiveBinding && creative1.isActiveBinding !== creative2.isActiveBinding) {
            return -1;
          }
          return 1;
        })
      };
    });

    this.updateFilteredCreative();
  }

  handleOnStateFilterClicked = (bindStatefilter: boolean) => {
    this.currentBindStateFilter = bindStatefilter;
    this.updateFilteredCreative();
    this.updateState();
  }

  updateFilteredCreative = () => {
    if (!this.summaryData) {
      return;
    }
    this.filteredData = this.summaryData.map(summary => {
      return {
        ...summary,
        creatives: _.filter(summary.creatives,
          creative => this.currentBindStateFilter === undefined || creative.isActiveBinding === this.currentBindStateFilter
        )
      };
    });
  }

  getApiData = (isCreate: boolean = true) => {
    if (!this.stateContext) {
      return [];
    }

    const contextData = this.stateContext.data;
    if (!contextData) {
      return [];
    }
    const campaignCreativeMap = {};
    const draftCreativeMap = {};
    const bindingIdMap = contextData.bindingIdMap ? contextData.bindingIdMap : {};
    contextData.selectedCreatives && contextData.selectedCreatives.forEach(creativeId => {
      let campaignIds: any[] = [];
      let draftIds: number[] = [];
      this.creativeData.forEach(campaign => {
        const isDraft = !!campaign.draftId;
        if (_.find(campaign.creatives, creativeOfCampaign => creativeOfCampaign.id === creativeId)) {
          isDraft ? draftIds.push(campaign.draftId) : campaignIds.push(campaign.id);
        }
        const newCreativeData = contextData.campaignNewCreativesMap &&
          _.find(
            contextData.campaignNewCreativesMap,
            campaignHasNewCreative => isDraft ? campaignHasNewCreative.draftId === campaign.draftId : campaignHasNewCreative.id === campaign.id
          );
        newCreativeData && (isDraft ? draftIds.push(campaign.draftId) : campaignIds.push(campaign.id));
      });

      if (campaignIds.length > 0) {
        const key = _.uniq(campaignIds).join(',');
        if (key in campaignCreativeMap) {
          campaignCreativeMap[key].push(creativeId);
        } else {
          campaignCreativeMap[key] = [creativeId];
        }
      }
      if (draftIds.length > 0) {
        const draftKey = _.uniq(draftIds).join(',');
        if (draftKey in draftCreativeMap) {
          draftCreativeMap[draftKey].push(creativeId);
        } else {
          draftCreativeMap[draftKey] = [creativeId];
        }
      }
    });

    const active = contextData.activate === undefined ? true : contextData.activate;
    const dataToModify: any = [];
    Object.keys(campaignCreativeMap).forEach(mapKey => {
      if (isCreate) {
        dataToModify.push({
          goCampaignChannelIds: mapKey.split(','),
          goCreativeIds: _.uniq(campaignCreativeMap[mapKey]),
          active,
          draft: false
        });
      } else {
        dataToModify.push({
          goBindingIds: _.compact(_.uniq(campaignCreativeMap[mapKey]).map((creativeId: any) => bindingIdMap[creativeId])),
          active,
          draft: false
        });
      }
    });

    Object.keys(draftCreativeMap).forEach(mapKey => {
      dataToModify.push({
        goCampaignChannelIds: mapKey.split(','),
        goCreativeIds: _.uniq(draftCreativeMap[mapKey]),
        active,
        draft: true
      });
    });

    return dataToModify;
  }

  abstract get channel ();

  save = async () => {
    const contextData = this.stateContext?.data;
    if (!contextData) {
      return;
    }
    try {
      this.updateState(true);
      const isBinding = contextData.activate === undefined;
      const modifyData = this.getApiData(isBinding);
      if (modifyData.length > 0) {
        if (isBinding) {
          await this.bindingManager.createBinding(this.channel, modifyData);
        } else {
          await this.bindingManager.updateBinding(this.channel, modifyData);
        }
      }
      this.loading = false;
      toast.success(i18n.t('creativeSummaryState.labels.modifySuccess'));
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
      console.log('error', e);
      this.updateState();
    }
  }

  setGoNextFn (fn: () => void) {
    this.goNextFn = fn;
  }

  updateState (loading = false) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}

export class RtbCreativeSummaryStateContentModel extends DefaultCreativeSummaryStateContentModel {

  get channel () {
    return CampaignGroupChannel.RTB;
  }
}

export class RetailCreativeSummaryStateContentModel extends RtbCreativeSummaryStateContentModel {

  get channel () {
    return CampaignGroupChannel.RETAIL_MEDIA;
  }
}

export class FbCreativeSummaryStateContentModel extends DefaultCreativeSummaryStateContentModel {

  get channel () {
    return CampaignGroupChannel.FB;
  }
}

export class TiktokCreativeSummaryStateContentModel extends DefaultCreativeSummaryStateContentModel {

  get channel () {
    return CampaignGroupChannel.TIKTOK;
  }
}

import axios from 'axios';
import client from './RestClient';
import { SelectOptions } from 'components/common/commonType';
import { AzureBlobAssetToken } from 'core/cloudStorage/AzureBlobAssetToken';
import { Aborter, BlobUploadCommonResponse, BlockBlobURL, uploadBrowserDataToBlockBlob } from '@azure/storage-blob';
import { AzureBlobConfig } from 'core/cloudStorage/AzureBlobConfig';
import config from 'config';
import { CloudResourceWebService } from './CloudResourceWebService';

export interface BlobWebService extends CloudResourceWebService {
  getMediaServiceToken (fileName: string): Promise<AzureBlobAssetToken>;
  getBlobSASToken (): Promise<string>;
  uploadFileToCloud (param: UploadBlobParams): Promise<BlobUploadCommonResponse | undefined>;
}

export type UploadBlobParams = {
  file: File,
  containerPath: string,
  progressCallback?: (percent: number) => void,
  fileName: string,
  azure: {
    accountId: string,
    accountSAS: string
  }
};
export class RestfulBlobWebService implements BlobWebService {

  restClient: any;
  pmaxRestClient: any;
  blobPath: string = '';

  constructor (restClient: any = axios, pmaxRestClient: any = client) {
    this.restClient = restClient;
    this.pmaxRestClient = pmaxRestClient;
    this.blobPath = config.cloudStorageConfig.path;
  }

  async getLimitationPreSet (type: string): Promise<Object> {
    const resp = await this.restClient.get(`${this.blobPath}limitationPreSet.json`);
    return resp.data[type];
  }

  async getOrderExternalTypes (): Promise<Array<SelectOptions>> {
    const resp = await this.restClient.get(`${this.blobPath}crmOrderType.json`);
    return resp.data;
  }

  async getMediaServiceToken (fileName: string): Promise<AzureBlobAssetToken> {
    const resp = await this.pmaxRestClient.get(`/v2/creatives/media-service-token?videoFileName=${fileName}`);
    return resp.data;
  }

  async uploadFileToCloud (param: UploadBlobParams) {
    const file = param.file;
    const containerPath = param.containerPath;
    const progressCallback = param.progressCallback;
    const fileName = param.fileName;
    const blobConfig = new AzureBlobConfig(param.azure.accountId, param.azure.accountSAS, containerPath);
    const blockBlobURL = BlockBlobURL.fromContainerURL(blobConfig.containerURL, fileName);

    return uploadBrowserDataToBlockBlob(Aborter.none, file, blockBlobURL, {
      blockSize: 4 * 1024 * 1024, // block size
      parallelism: 4, // 4 concurrency
      progress: function (evt) {
        if (progressCallback) {
          (function (e, size) {
            const finishPercent = (e.loadedBytes / size) * 100.0;
            progressCallback(finishPercent);
          })(evt, file.size);
        }
      }
    });
  }

  async getBlobSASToken (): Promise<string> {
    const resp = await this.pmaxRestClient.get('/v2/creatives/blob-sas-token');
    return resp.data.sasToken;
  }
}

import React, { useState, useEffect } from 'react';
import i18n from 'i18n';
import _ from 'lodash';
import { CREATIVE_DISPLAYSIZE, CreativeType, CREATIVE_LOGO_POSITION } from 'core/creative/Creative';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { connect, FormikContext } from 'formik';
import { FormConfig } from 'components/common/formik/FormikField';
import { RetailRichMediaProductFormProps } from './RetailRichMediaProductFormModel';
import styles from './retailRichMediaProductForm.module.scss';
import { useRetailOptionsHook } from './useRetailOptionsHook';
import { createSelectOptionsFromEnum } from 'utils/SelectOptionsUtils';
import { FormikFormContent } from 'components/common/formik/FormikFormContent';
import { validateEmpty, validateUrl, validateMinMax, validateInteger } from 'utils/ValidateUtils';

const maxCountOptions = _.map([4, 5, 6], option => ({
  label: option.toString(),
  value: option
}));

const logoPositionOptions = createSelectOptionsFromEnum(CREATIVE_LOGO_POSITION, 'adNeonForm.options.logoPosition.')
  .filter(option => option.value !== CREATIVE_LOGO_POSITION.RIGHT);

export const RetailRichMediaProductForm: React.FC<RetailRichMediaProductFormProps & { formik: FormikContext<any> }> = (props) => {

  const { values, setFieldValue } = props.formik;
  const advertiserId = values.advertiserId;
  const {
    loading,
    defaultRetailer,
    retailerOptions,
    productSetOptions,
    layoutOptions,
    callToActionOptions,
    onRetailerChange
  } = useRetailOptionsHook(advertiserId, props.model.flowPageModel, CreativeType.RETAIL_RICH_MEDIA_PRODUCT);

  const isLogoValid = !!_.get(props.formik.values, 'medias.logo', '');
  const [formConfig, setFormConfig] = useState<FormConfig | undefined>();

  useEffect(() => {
    setFieldValue('typeProperties.retailer', defaultRetailer);
  }, [setFieldValue, defaultRetailer]);

  useEffect(() => {
    const retailerChangeHandler = value => {
      setFieldValue('typeProperties.productSet', undefined);
      setFieldValue('typeProperties.productSetId', undefined);
      setFieldValue('typeProperties.layout', undefined);
      onRetailerChange(value);
    };

    const productSetChangeHandler = value => {
      let productSetOption = productSetOptions.find(productSet => productSet.value === value);
      let name = _.get(productSetOption, 'label', '');
      let id = _.get(productSetOption, 'value', '');
      setFieldValue('typeProperties.productSet', name);
      setFieldValue('typeProperties.productSetId', id);
    };

    setFormConfig(new FormConfig.Builder()
      .addSection(
        new FormConfig.SectionBuilder(
          i18n.t('creativeSetupFlow.labels.creativeBasicInfo'),
          new FormConfig.FieldsBuilder([...props.basicFields])
            .addSelect({
              label: i18n.t('creativeSetupFlow.labels.retailer'),
              simpleValue: true,
              name: 'typeProperties.retailer',
              options: retailerOptions,
              onChange: retailerChangeHandler,
              validate: validateEmpty
            }, props.model.flowPageModel.type !== 'create')
            .addLabel({
              label: i18n.t('creativeSetupFlow.labels.retailer'),
              name: 'typeProperties.retailer',
              formatter: value => value ? i18n.t(`retailers.${value}`) : ''
            }, props.model.flowPageModel.type === 'create')
            .addSelect({
              label: i18n.t('creativeSetupFlow.labels.productSet'),
              simpleValue: true,
              name: 'typeProperties.productSetId',
              options: productSetOptions,
              onChange: productSetChangeHandler,
              validate: validateEmpty
            })
            .addSelect({
              label: i18n.t('creativeSetupFlow.labels.retailLayout'),
              simpleValue: true,
              name: 'typeProperties.layout',
              options: layoutOptions,
              validate: validateEmpty
            })
            .addFileInput({
              type: 'video',
              label: i18n.t('creativeSetupFlow.labels.retailVideo'),
              name: 'medias.video',
              className: styles.videoInput,
              maxWidth: 270,
              maxHeight: 500,
              hints: [
                i18n.t('creativeSetupFlow.labels.videoTypeHint'),
                i18n.t('outdoorForm.labels.videoSizeHint', { proportion: '16:9' }),
                i18n.t('creativeSetupFlow.labels.storageHint', { storage: '10MB' }),
                i18n.t('fbVideoForm.hints.duration', { min: 0, max: 120 })
              ],
              validate: props.model.validateVideo
            })
            .addUrlInput({
              label: i18n.t('creativeSetupFlow.labels.retailLandingUrl'),
              name: 'typeProperties.retailLandingUrl',
              validate: validateUrl
            })
            .addRadio({
              label: i18n.t('creativeSetupFlow.labels.displaySize'),
              name: 'typeProperties.displaySize',
              options: [{
                value: CREATIVE_DISPLAYSIZE.DEFAULT,
                label: i18n.t('adNeonForm.options.displaySize.default')
              }, {
                value: CREATIVE_DISPLAYSIZE.LARGE,
                label: i18n.t('adNeonForm.options.displaySize.large')
              }]
            })
            .build()
        )
        .addAdvancedFields(new FormConfig.FieldsBuilder()
          .addFileInput({
            type: 'image',
            label: i18n.t('creativeSetupFlow.labels.retailLogo'),
            name: 'medias.logo',
            hints: [
              i18n.t('creativeSetupFlow.labels.typeHint'),
              i18n.t('creativeSetupFlow.labels.storageHint', { storage: '2MB' })
            ],
            className: styles.logoInput,
            validate: props.model.validateImage
          })
          .addSelect({
            label: i18n.t('creativeSetupFlow.labels.retailLogoPosition'),
            name: 'typeProperties.logoPosition',
            simpleValue: true,
            options: logoPositionOptions
          }, !isLogoValid)
          .addSelect({
            label: i18n.t('creativeSetupFlow.labels.retailCallToAction'),
            simpleValue: true,
            name: 'typeProperties.callToAction',
            options: callToActionOptions
          })
          .addSelect({
            label: i18n.t('creativeSetupFlow.labels.maxCardsCount'),
            name: 'typeProperties.maxCount',
            simpleValue: true,
            options: maxCountOptions
          })
          .addInputGroup({
            label: i18n.t('creativeSetupFlow.labels.autoAnimationSeconds'),
            name: 'typeProperties.autoAnimationSeconds',
            postfix: i18n.t('common.units.secs'),
            fieldContentWidth: 110,
            postText: i18n.t('creativeSetupFlow.labels.autoAnimationSeconds_postText'),
            validate: value => validateInteger(value) || validateMinMax(value, 1, 10)
          })
          .build()
        )
        .build()
      ).build()
    );
  }, [
    isLogoValid,
    props.basicFields,
    props.model.flowPageModel.type,
    layoutOptions,
    retailerOptions,
    productSetOptions,
    callToActionOptions,
    setFieldValue,
    onRetailerChange,
    props.model.validateImage,
    props.model.validateVideo
  ]);

  return (
    <div>
      {loading && <LoadingIndicator/>}
      <FormikFormContent
        formConfig={formConfig}
      />
    </div>
  );
};

export default connect(RetailRichMediaProductForm);

import React, { MutableRefObject } from 'react';

type RefType = ((instance: HTMLVideoElement | null) => void) | MutableRefObject<HTMLVideoElement | null> | null;
interface VideoPreviewProps {
  maxWidth?: number;
  maxHeight?: number;
  src: File | string;
  ref?: RefType;
}

export const VideoPreview: React.FunctionComponent<VideoPreviewProps> = React.forwardRef((({ src, maxWidth = 600, maxHeight = 400 }, parentRef: RefType) => {
  const videoRef = React.useRef<HTMLVideoElement | null>(null);
  const source = src instanceof File ? URL.createObjectURL(src) : src;
  const handleVideoMetaData = () => {
    if (!videoRef.current) {
      return;
    }
    let width = videoRef.current.videoWidth;
    let height = videoRef.current.videoHeight;
    const ratio = height / width;
    if (width > maxWidth) {
      width = maxWidth;
      height = width * ratio;
    }
    if (height > maxHeight) {
      height = maxHeight;
      width = height / ratio;
    }
    videoRef.current.width = width;
    videoRef.current.height = height;
  };

  return (
    <video
      controls
      ref={ref => {
        if (videoRef.current !== ref) {
          if (parentRef) {
            if (typeof parentRef === 'function') {
              parentRef(ref);
            } else {
              parentRef.current = ref;
            }
          }
          videoRef.current && videoRef.current.removeEventListener('loadedmetadata', handleVideoMetaData);
          videoRef.current = ref;
          videoRef.current && videoRef.current.addEventListener('loadedmetadata', handleVideoMetaData);
        }
      }}
    >
      <source src={source} type='video/mp4'/>
    </video>
  );
}));

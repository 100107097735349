import { VideoAdViewObjective, CampaignState, RtbCampaignPlanType, RtbCampaignBasic, RtbCampaignOptimze } from 'core/rtbCampaign/RtbCampaign';
import { validateEmpty, validateMinimum, validateInteger, validateMinMax } from 'utils/ValidateUtils';
import i18n from 'i18n';
import _ from 'lodash';
import { formatPriceWithCurrency, getPriceValue } from 'helper/CurrencyHelper';
import { Order } from 'core/order/Order';
import { AddonFeatureManager, LocaleMeta } from 'core';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import moment from 'moment';
import { CampaignGroup, FBBidStrategy, GoCampaignGroupObjective } from 'core/campaignGroup/CampaignGroup';
import { renderOverBudgetWording } from '../../../GoCampaigns/FormHintRenderFunctions';
import { DefaultRtbCampaignManager } from 'core/rtbCampaign/RtbCampaignManager';
import { GoCampaignOptimizationGoal } from 'core/goCampaign/GoCampaign';

export interface RtbCampaignBasicFormValidator {
  validate (campaignGroup: CampaignGroup | undefined, campaignBasic: Partial<RtbCampaignBasic>, order: Order, isDailySchedule: boolean, otherCampaignCost: number, localeMeta?: LocaleMeta): any;
}

export class DefaultRtbCampaignBasicFormValidator implements RtbCampaignBasicFormValidator {

  orderPriceMinimumGetter: (priceModel: RtbCampaignPlanType, localeMeta?: LocaleMeta) => number | null;
  optimizePriceMinimumGetter: (optimize: RtbCampaignOptimze, localeMeta?: LocaleMeta) => number | null;
  addonFeatureManager: AddonFeatureManager;
  validateStrategies: any;
  constructor (
    protected action: string,
    protected defaultCampaign: any,
    protected campaignBasic: RtbCampaignBasic,
    orderPriceMinimumGetter: (priceModel: RtbCampaignPlanType, localeMeta?: LocaleMeta) => number | null,
    optimizePriceMinimumGetter: (optimize: RtbCampaignOptimze, localeMeta?: LocaleMeta) => number | null,
    addonFeatureManager: AddonFeatureManager,
    private canEditOptimize: boolean
  ) {
    this.orderPriceMinimumGetter = orderPriceMinimumGetter;
    this.optimizePriceMinimumGetter = optimizePriceMinimumGetter;
    this.addonFeatureManager = addonFeatureManager;
    this.validateStrategies = {
      [RtbCampaignPlanType.FCPC]: this.validateNormalCampaign.bind(this),
      [RtbCampaignPlanType.FCPM]: this.validateNormalCampaign.bind(this),
      [RtbCampaignPlanType.FCPV]: this.validateCPVCampaign.bind(this),
      [RtbCampaignPlanType.FVCPM]: this.validateNormalCampaign.bind(this),
      [RtbCampaignPlanType.DCPM]: this.validateDCPMCampaign.bind(this),
      [RtbCampaignPlanType.RS]: this.validateRSCampaign.bind(this),
      [RtbCampaignPlanType.RB]: this.validateBasic.bind(this)
    };
  }

  validate (campaignGroup: CampaignGroup | undefined, campaignBasic: RtbCampaignBasic, order: Order, isDailySchedule: boolean, otherCampaignCost: number, localeMeta?: LocaleMeta) {
    const errors = this.validateStrategies[campaignBasic.priceModel](campaignGroup, campaignBasic, order, isDailySchedule, otherCampaignCost, localeMeta);
    return _.omitBy(errors, _.isEmpty);
  }

  validateBasic (campaignGroup: CampaignGroup | undefined, campaignBasic: RtbCampaignBasic) {
    return {
      name: this.validateName(campaignBasic),
      dayRange: this.validateDateRange(campaignBasic),
      videoProgressTrackingCode: this.validateVideoProgressTrackingCode(campaignBasic)
    };
  }

  validateDCPMCampaign (campaignGroup: CampaignGroup | undefined, campaignBasic: RtbCampaignBasic, order: Order, isDailySchedule: boolean, otherCampaignCost: number, localeMeta?: LocaleMeta) {
    // TODO: verify this
    return {
      ...this.validateBasic(campaignGroup, campaignBasic),
      budget: this.validateBudget(campaignGroup, campaignBasic, order, otherCampaignCost),
      bidPrice: this.validateBidPrice(campaignBasic, order.currency, localeMeta),
      dailyTargetBudget: isDailySchedule && this.validateDailyTargetBudget(campaignBasic),
      frequency: campaignGroup && campaignGroup.objective === GoCampaignGroupObjective.AwarenessObjective.REACH && campaignBasic.optimize === GoCampaignOptimizationGoal.REACH.toString() ?
        this.validateFrequency(campaignBasic.frequency) : undefined
    };
  }

  validateRSCampaign (campaignGroup: CampaignGroup | undefined, campaignBasic: RtbCampaignBasic, order: Order, isDailySchedule: boolean, otherCampaignCost: number, localeMeta?: LocaleMeta) {
    return {
      ...this.validateBasic(campaignGroup, campaignBasic),
      budget: this.validateBudget(campaignGroup, campaignBasic, order, otherCampaignCost),
      bidPrice: this.validateBidPrice(campaignBasic, order.currency, localeMeta),
      dailyTargetBudget: isDailySchedule && this.validateDailyTargetBudget(campaignBasic)
    };
  }

  validateNormalCampaign (campaignGroup: CampaignGroup | undefined, campaignBasic: RtbCampaignBasic, order: Order, isDailySchedule: boolean, otherCampaignCost: number, localeMeta?: LocaleMeta) {
    return {
      ...this.validateBasic(campaignGroup, campaignBasic),
      orderPrice: this.validateOrderPrice(campaignBasic, order.currency, localeMeta),
      budget: this.validateBudget(campaignGroup, campaignBasic, order, otherCampaignCost),
      bidPrice: this.canEditOptimize && this.validateBidPrice(campaignBasic, order.currency, localeMeta),
      dailyTargetBudget: isDailySchedule && this.validateDailyTargetBudget(campaignBasic)
    };
  }

  validateCPVCampaign (campaignGroup: CampaignGroup | undefined, campaignBasic: RtbCampaignBasic, order: Order, isDailySchedule: boolean, otherCampaignCost: number, localeMeta?: LocaleMeta) {
    return {
      ...this.validateNormalCampaign(campaignGroup, campaignBasic, order, isDailySchedule, otherCampaignCost, localeMeta),
      videoAdViewObjective: this.validateVideoAdViewObjective(campaignBasic)
    };
  }

  validateName (campaignBasic) {
    const name = campaignBasic.name;
    let error = validateEmpty(name);
    if (error) {
      return error;
    }
    if (name.length > 255) {
      return i18n.t('campaign.descriptions.lengthError');
    }
  }

  validateDailyTargetBudget (campaignBasic) {
    return validateMinMax(
      campaignBasic.dailyTargetBudget,
      Math.min(1, campaignBasic.budget),
      campaignBasic.budget
    );
  }

  validateDateRange (campaignBasic) {
    let start = new Date(campaignBasic.startDate);
    let end = new Date(campaignBasic.endDate);
    const diffTime = Math.abs(end.getTime() - start.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays > 92) {
      return i18n.t('campaign.descriptions.moreThanDays', { days: 92 });
    }
    const now = moment();
    const defaultCampaignStartDate = this.campaignBasic.startDate;
    const shouldCheckStartDay = !campaignBasic.id || moment(defaultCampaignStartDate).isAfter(now);
    if (!shouldCheckStartDay) {
      return;
    }
    if (moment(campaignBasic.startDate).add(1, 'hours').isBefore(now)) {
      return i18n.t('campaign.descriptions.createBeforeNow');
    }
  }

  validateOrderPrice (campaignBasic, currency: string, localeMeta) {
    if (!campaignBasic.orderPriceEnable) {
      return;
    }
    let orderPriceMinimum = this.orderPriceMinimumGetter(campaignBasic.priceModel, localeMeta);
    orderPriceMinimum = orderPriceMinimum ? orderPriceMinimum : 0;
    return validateMinimum(
      campaignBasic.orderPrice,
      orderPriceMinimum,
      'campaign.descriptions.priceMinimum',
      currency
    );
  }

  validateVideoAdViewObjective (campaignBasic) {
    const videoAdViewObjective = _.get(campaignBasic, 'videoAdViewObjective');
    return _.omitBy(
      {
        offset:
          videoAdViewObjective &&
          videoAdViewObjective.videoAdEvent ===
            VideoAdViewObjective.PROGRESS
            ? validateEmpty(videoAdViewObjective.offset)
            : undefined,
        videoAdMetricEvent:
          videoAdViewObjective &&
          videoAdViewObjective.videoAdEvent ===
            VideoAdViewObjective.METRIC
            ? validateEmpty(videoAdViewObjective.videoAdMetricEvent)
            : undefined
      }, _.isUndefined);
  }

  validateBudget (campaignGroup: CampaignGroup | undefined, campaignBasic, order: Order, otherCampaignCost: number) {
    const budget = campaignBasic.budget;
    const rtbCampaignManager = new DefaultRtbCampaignManager();
    const minBudgetOfCampaign = rtbCampaignManager.getMinBudgetOfCampaign(campaignBasic, order.campaignConstraint.budgetMinimum);
    if (campaignGroup && campaignGroup.autoOptimise && this.action === 'create') {
      const campaignGroupMinBudget = otherCampaignCost + Math.max(minBudgetOfCampaign, budget);
      if (campaignGroupMinBudget > +_.defaultTo(campaignGroup.budget, 0)) {
        return i18n.t('campaign.descriptions.minCampaignGroupBudget', { budget: formatPriceWithCurrency(order.currency, campaignGroupMinBudget) });
      }
      return;
    }

    const spents = _.get(campaignBasic, 'spents', 0);
    const expectedSpent = _.get(campaignBasic, 'expectedSpent', 0);
    const minBudget = Math.max(spents, expectedSpent, minBudgetOfCampaign);
    if (campaignGroup && campaignGroup.autoOptimise && this.action === 'edit') {
      if (budget === 0) { // NOT ALLOCATED CAMPAIGN
        const campaignGroupMinBudget = otherCampaignCost + minBudgetOfCampaign;
        if (campaignGroupMinBudget > +_.defaultTo(campaignGroup.budget, 0)) {
          return i18n.t('campaign.descriptions.minCampaignGroupBudget', { budget: formatPriceWithCurrency(order.currency, campaignGroupMinBudget) });
        }
        return;
      } else {
        return validateMinimum(
          budget,
          getPriceValue(order.currency, minBudget),
          'campaign.descriptions.smallerThanBudgetMinimum',
          order.currency
        );
      }
    }

    if (
      this.action === 'edit' &&
      !this.defaultCampaign.basic.isDraft &&
      this.defaultCampaign.basic.budget === campaignBasic.budget
    ) {
      return undefined;
    }
    const totalBudget = this.defaultCampaign.basic.isDraft ?
      order.budgetBalance :
      order.budgetBalance + this.defaultCampaign.basic.budget;
    const remainBudget = totalBudget - budget;
    if (remainBudget < 0) {
      return renderOverBudgetWording(order.currency, totalBudget);
    }

    if (campaignBasic.state === CampaignState.DEACTIVATE) {
      return validateMinimum(
        budget,
        getPriceValue(order.currency, spents),
        'campaign.descriptions.smallerThanBudgetMinimum',
        order.currency
      );
    }

    let hintI18n = 'campaign.descriptions.smallerThanBudgetMinimum';
    switch (minBudget) {
      case spents:
        hintI18n = 'campaign.descriptions.smallerThanSpents';
        break;
      case expectedSpent:
        hintI18n = 'campaign.descriptions.smallerThanExpectSpents';
        break;
      default:
        break;
    }
    return validateMinimum(
      budget,
      getPriceValue(order.currency, minBudget),
      hintI18n,
      order.currency
    );
  }

  validateBidPrice (campaignBasic, currency: string, localeMeta) {
    if (_.get(campaignBasic, 'bidStrategy') === FBBidStrategy.LOWEST_COST_WITHOUT_CAP) {
      return;
    }
    const bidPrice = campaignBasic.bidPrice;
    let error = validateEmpty(bidPrice);
    if (error) {
      return error;
    }
    const optimize = campaignBasic.optimize;
    let optimizePriceMinimum = this.optimizePriceMinimumGetter(optimize, localeMeta);
    optimizePriceMinimum = optimizePriceMinimum ? optimizePriceMinimum : 0;
    const valueNumber = typeof bidPrice === 'string' ? parseFloat(bidPrice) : bidPrice;
    if (valueNumber < optimizePriceMinimum) {
      let min = currency + optimizePriceMinimum;
      return i18n.t('campaign.descriptions.priceMinimum', { min });
    }
    const orderPriceMaximum = this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.COMPANY.AGENCY_ALLOW_BID_PRICE_MORE_THEN_CONTRACT_PRICE) ?
      -1 :
      campaignBasic.orderPrice;
    const priceModel = campaignBasic.priceModel;
    const optimizeSameAsPriceModel = (priceModel === RtbCampaignPlanType.FCPC && (optimize === RtbCampaignOptimze.CPC || optimize === GoCampaignOptimizationGoal.LINK_CLICKS)) ||
      (priceModel === RtbCampaignPlanType.FCPM && (optimize === RtbCampaignOptimze.CPM || optimize === GoCampaignOptimizationGoal.IMPRESSIONS)) ||
      (priceModel === RtbCampaignPlanType.FCPV && optimize === RtbCampaignOptimze.CPV) ||
      (priceModel === RtbCampaignPlanType.FVCPM && optimize === RtbCampaignOptimze.VCPM);
    if (optimizeSameAsPriceModel && orderPriceMaximum && orderPriceMaximum > -1 && valueNumber > orderPriceMaximum) {
      let max = currency + orderPriceMaximum;
      return i18n.t('formValidate.labels.maximumError', { max });
    }
  }

  validateVideoProgressTrackingCode (campaignBasic) {
    const videoProgressTrackingCode = _.get(campaignBasic.videoProgressTrackingCode, 'code', '');
    const videoProgressTrackingCodeOffset = _.get(campaignBasic.videoProgressTrackingCode, 'offset');
    if (videoProgressTrackingCode.length > 0) {
      return _.omitBy({ offset: validateInteger(videoProgressTrackingCodeOffset) }, _.isUndefined);
    }
  }

  validateFrequency = (frequency) => {
    const minIntervalDays = 1;
    const maxIntervalDays = 7;
    const minMaxFrequency = 1;
    const maxMaxFrequency = 20;
    const maxFrequency = frequency.maxFrequency;
    const intervalDays = frequency.intervalDays;
    const emptyMaxFrequency = validateEmpty(maxFrequency) && i18n.t('adSetSetupFlow.mainStep.errors.emptyMaxFrequency');
    const emptyIntervalDays = validateEmpty(intervalDays) && i18n.t('adSetSetupFlow.mainStep.errors.emptyIntervalDays');
    const intervalDaysOutofRange = validateMinMax(intervalDays, minIntervalDays, maxIntervalDays) &&
      i18n.t('adSetSetupFlow.mainStep.errors.intervalDaysOutofRange', { min: minIntervalDays, max: maxIntervalDays });
    const maxFrequencyOutofRange = validateMinMax(maxFrequency, minMaxFrequency, maxMaxFrequency) &&
      i18n.t('adSetSetupFlow.mainStep.errors.maxFrequencyOutofRange', { min: minMaxFrequency, max: maxMaxFrequency });
    return _.omitBy({
      maxFrequency: emptyMaxFrequency || maxFrequencyOutofRange,
      intervalDays: emptyIntervalDays || intervalDaysOutofRange
    }, _.isUndefined);
  }
}

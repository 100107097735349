
import _ from 'lodash';
import i18n from 'i18n';
import { GoSegmentFormData } from 'core/goSegment/GoSegment';
import { GoSegmentFormModelData, useCreateGoSegmentFormModel, useEditGoSegmentFormModel } from './GoSegmentFormModel';
import { formatBytes } from 'utils/StringUtil';
const mimeDB = require('mime-db');
const IMPORT_FILE_SIZE_MAXIMUM = 314572800;

export const useCreateGoSegmentUploadFormModel = (): GoSegmentFormModelData => {

  const { validate: basicValidate, ...basicProps } = useCreateGoSegmentFormModel();

  const validate = (value: Partial<GoSegmentFormData>) => {
    const basicError = basicValidate(value);
    return _.omitBy({
      ...basicError,
      upload: validateCsv(value.upload)
    }, _.isEmpty);
  };

  return {
    ...basicProps,
    formType: 'create',
    validate
  };
};

export const useEditGoSegmentUploadFormModel = (
  segmentId: number | string
): GoSegmentFormModelData => {

  const { validate: basicValidate, ...basicProps } = useEditGoSegmentFormModel(segmentId);

  const validate = (value: Partial<GoSegmentFormData>) => {
    const basicError = basicValidate(value);
    return _.omitBy({
      ...basicError,
      upload: value.updateMode ? validateCsv(value.upload) : undefined
    }, _.isEmpty);
  };

  return {
    ...basicProps,
    formType: 'edit',
    validate
  };
};

function validateCsv (fileData) {
  if (!fileData || !fileData.file) {
    return i18n.t('formValidate.labels.emptyError');
  }

  const validTypes = ['text/csv', 'application/vnd.ms-excel'];
  const file = fileData.file;
  if (validTypes.indexOf(file.type) === -1) {
    const extensions = _.get(mimeDB[file.type], 'extensions', ['Unknown']);
    return i18n.t('goSegmentForm.errors.uploadTypeErrorHint', { type: extensions[0] });
  }

  if (file.size > IMPORT_FILE_SIZE_MAXIMUM) {
    return i18n.t('creativeSetupFlow.labels.storageErrorHint', { storage1: formatBytes(file.size), storage2: '300MB' });
  }
}

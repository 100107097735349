import { SelectOptions } from 'components/common/commonType';
import { ModalState } from 'containers/Common/ModalStateFactory';
import { CampaignGroup, CampaignGroupChannel } from 'core/campaignGroup/CampaignGroup';
import i18n from 'i18n';
import { DraftSetupStateContent } from './DraftSetupStateContent';
import { DefaultDraftSetupStateContentModel, FbAdSetDraftSetupStateContentModel, RetailCampaignDraftSetupStateContentModel, RtbCampaignDraftSetupStateContentModel, TiktokAdGroupDraftSetupStateContentModel } from './DraftSetupStateContentModel';

export abstract class DraftSetupState implements ModalState {

  back?;
  next?;
  close?;

  constructor (
    protected orderOptions: SelectOptions[],
    protected campaignGroup: CampaignGroup,
    protected goCampaignsToCreateDraft: {
      id: number | string,
      isDraft: boolean
    } []
  ) {}

  abstract get title ();

  titleFormatter = (title) => {
    if (i18n.language === 'en' && this.goCampaignsToCreateDraft.length > 1) {
      return title = title + 's';
    }
    return title;
  }

  abstract get contentModel (): DefaultDraftSetupStateContentModel;

  get content () {
    return DraftSetupStateContent;
  }

  get btnData (): any {
    return {
      primaryButton: this.next && {
        title: i18n.t('draftCreateModal.buttons.goSummary'),
        callback: this.next
      },
      secondaryButton: this.close && {
        title: i18n.t('common.buttons.cancel'),
        callback: this.close
      }
    };
  }

  canGoNext = () => {
    return this.contentModel.canGoNext;
  }
}

export class RtbCampaignDraftSetupState extends DraftSetupState {

  _contenModel = new RtbCampaignDraftSetupStateContentModel(this.orderOptions);

  get title () {
    return this.titleFormatter(i18n.t('draftCreateModal.title', {
      l2Object: i18n.t(`l2ObjectName.${CampaignGroupChannel.RTB.toLowerCase()}`),
      number: this.goCampaignsToCreateDraft.length
    }));
  }

  get contentModel () {
    return this._contenModel;
  }
}

export class FbAdSetDraftSetupState extends DraftSetupState {

  _contenModel = new FbAdSetDraftSetupStateContentModel(this.orderOptions);

  get title () {
    return this.titleFormatter(i18n.t('draftCreateModal.title', {
      l2Object: i18n.t(`l2ObjectName.${CampaignGroupChannel.FB.toLowerCase()}`),
      number: this.goCampaignsToCreateDraft.length
    }));
  }

  get contentModel () {
    return this._contenModel;
  }
}

export class TiktokAdGroupDraftSetupState extends DraftSetupState {

  _contenModel = new TiktokAdGroupDraftSetupStateContentModel(this.orderOptions);

  get title () {
    return this.titleFormatter(i18n.t('draftCreateModal.title', {
      l2Object: i18n.t(`l2ObjectName.${CampaignGroupChannel.TIKTOK.toLowerCase()}`),
      number: this.goCampaignsToCreateDraft.length
    }));
  }

  get contentModel () {
    return this._contenModel;
  }
}

export class RetailCampaignDraftSetupState extends DraftSetupState {

  _contenModel = new RetailCampaignDraftSetupStateContentModel(this.orderOptions);

  get title () {
    return this.titleFormatter(i18n.t('draftCreateModal.title', {
      l2Object: i18n.t(`l2ObjectName.${CampaignGroupChannel.RETAIL_MEDIA.toLowerCase()}`),
      number: this.goCampaignsToCreateDraft.length
    }));
  }

  get contentModel () {
    return this._contenModel;
  }
}

import { CreativeBasicFormModel, DefaultCreativeBasicFormModel } from './SubSteps/CreativeBasicFormModel';
import { SelectOptions } from 'components/common/commonType';
import DefaultCreativeManager, { CreativeManager } from 'core/creative/CreativeManager';
import { defaultInventorySetting, LIMITATION_TYPE } from 'containers/Limitations/LimitationSetting/LimitationSettingData';
import { AddonFeatureManager } from 'core';
import { OPERATE } from 'enum/Operate';
import { DefaultEditLimitationModel } from 'containers/Limitations/EditLimitationModel';
import { CreativeFormBasicData, FormContentModel } from 'containers/Creatives/CreativeSetupFlow/FlowSteps/SubSteps/FormContent/FormContentModel';
import { CreativeType } from 'core/creative/Creative';
import { FireableUpdateEventListener, UpdateEventListener } from 'utils/UpdateEventListener';

export interface CreativeSetupStepModel {
  readonly type: string;
  readonly activeTab: number;
  readonly state: CreativeSetupStepState;
  readonly event: UpdateEventListener<CreativeSetupStepModel>;
  goSubStep: (subStepIndex: number) => void;
  goLast?: () => void;
  goNext: (callback) => void;
  getBasicFormModel (
    creative: CreativeFormBasicData,
    tenmaxCategories: Array<SelectOptions>,
    supportedCreativeType: CreativeType[],
    formContentModelGetter: (creativeType: CreativeType) => FormContentModel | undefined,
    addLimitation: (operate: string, limitationType: string, label: string, value: string) => void
  ): CreativeBasicFormModel;
  getLimitationModel (limitations: any, advertiserId: number): any;
  getEditedCreative (): any;
  updateProps (activeTab: number): void;
}

export type CreativeSetupStepProps = {
  readonly model: CreativeSetupStepModel;
};

export type CreativeSetupStepState = {
  readonly currentCreativeType: CreativeType;
};

export enum CreativeSetupTab {
  BASIC,
  LIMITATION
}

export class DefaultCreativeSetupStepModel implements CreativeSetupStepModel {

  stepChangeListener?: (stepIndex, targetSubStep?: string) => void;
  basicFormModel?: CreativeBasicFormModel;
  limitationModel?: any;
  event: FireableUpdateEventListener<CreativeSetupStepModel>;

  constructor (
    private currentCreativeType: CreativeType,
    public type: string,
    public activeTab: number,
    public goSubStep: (subStepIndex: number) => void,
    public goLast: (() => void) | undefined,
    public goNext: () => void,
    private addonFeatureManager: AddonFeatureManager,
    private creativeManager: CreativeManager = new DefaultCreativeManager()
  ) {
    this.event = new FireableUpdateEventListener<CreativeSetupStepModel>();
  }

  get state () {
    return {
      currentCreativeType: this.currentCreativeType
    };
  }

  onCreativeTypeChange = (creativeType) => {
    this.currentCreativeType = creativeType;
    this.updateState();
  }

  getBasicFormModel (
    creative: CreativeFormBasicData,
    tenmaxCategories: Array<SelectOptions>,
    supportedCreativeType: CreativeType[],
    formContentModelGetter: (creativeType: CreativeType) => FormContentModel,
    addLimitation: (operate: string, limitationType: string, label: string, value: string) => void
  ) {
    if (this.basicFormModel && creative.advertiserId === this.basicFormModel.initCreative.advertiserId) {
      this.basicFormModel.setLimitationHook(addLimitation);
      return this.basicFormModel;
    }
    this.basicFormModel = new DefaultCreativeBasicFormModel(
      this.type,
      creative,
      tenmaxCategories,
      this.creativeManager,
      supportedCreativeType,
      this.onCreativeTypeChange,
      formContentModelGetter,
      addLimitation
    );
    return this.basicFormModel;
  }

  getLimitationModel (limitations: any, advertiserId: number) {
    const addonFeature = [...this.addonFeatureManager.addonFeature];
    const isPPSCreative = this.currentCreativeType === CreativeType.THIRDPARTY;
    if (isPPSCreative) {
      addonFeature.push('creative_ppsLayoutId');
    }
    this.limitationModel = new DefaultEditLimitationModel(
      defaultInventorySetting(advertiserId, LIMITATION_TYPE.CREATIVE),
      limitations,
      { need: [OPERATE.INCLUDE, OPERATE.PREFERRED, OPERATE.NONPREFERRED], notNeed: [OPERATE.EXCLUDE], other: [] },
      addonFeature
    );
    if (isPPSCreative) {
      this.limitationModel.setLimitationCanNotNull({
        include: ['ppsLayoutId']
      });
    }
    return this.limitationModel;
  }

  updateProps (activeTab: number): void {
    this.activeTab = activeTab;
  }

  validate = async () => {
    // TODO if has limitation tab, need call this function when click submit button
    const errors = this.basicFormModel && await this.basicFormModel.validate();
    const limitationError = this.limitationModel && this.limitationModel.validate();
    const errorNames = Object.keys(errors);
    const limitationErrorNames = Object.keys(limitationError);
    if (errorNames.length > 0) {
      this.goSubStep(CreativeSetupTab.BASIC);
    } else if (limitationErrorNames.length > 0) {
      this.goSubStep(CreativeSetupTab.LIMITATION);
    }
    return errorNames.concat(limitationErrorNames);
  }

  getEditedCreative = () => {
    if (!this.basicFormModel) {
      return {};
    }

    return {
      basic: this.basicFormModel.getCreativeBasicValue()
    };
  }

  updateState () {
    this.event.fireEvent(this);
  }
}

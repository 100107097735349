import React from 'react';

import './ActorMenuItem.scss';
import './ActorMenuCommon.scss';

import i18n from 'i18n';
import { ActorMenuItemProps } from './ActorMenuModel';
import _ from 'lodash';

export class ActorMenuItem extends React.Component<ActorMenuItemProps> {
  selected = () => {
    this.props.model.itemSelected();
  }

  render () {
    const model = this.props.model;
    const roleName = i18n.t(`actor.roleNames.${_.camelCase(model.color)}`);
    return (
      <div className='actor-menu-item' onClick={this.selected}>
        {/* {model.icon !== null && <FontAwesomeIcon icon={model.icon} className="actor-icon" />} */}
        <div className={`role-container ${model.color}`}>{roleName}</div>
        <span className='company-name'>{model.company}</span>
      </div>
    );
  }
}

import React from 'react';
import ReactDOM from 'react-dom';
import './LoadingIndicator.scss';

import i18n from 'i18n';

export type LoadingIndicatorProps = {

  readonly text: string;
};

export class LoadingIndicator extends React.Component<LoadingIndicatorProps> {

  static defaultProps = {
    text: 'common.messages.loading'
  };

  root: any;

  componentDidMount () {
    this.root = document.createElement('div');
    document.body.appendChild(this.root);
    this._renderLayer();
  }

  componentDidUpdate () {
    this._renderLayer();
  }

  componentWillUnmount () {
    ReactDOM.unmountComponentAtNode(this.root);
    document.body.removeChild(this.root);
  }

  _renderLayer () {
    const content = (
      <div className='loading-indicator'>
        <div className='board'>
          <div className='roller' />
          <div className='message'>{i18n.t(this.props.text)}</div>
        </div>
      </div>
    );
    ReactDOM.render(content, this.root);
  }

  render () {
    return <div/>;
  }
}

import React, { useRef } from 'react';
import styles from './productCategory.module.scss';
import i18n from 'i18n';

import { ProductFilter } from './ProductFilter';
import { Formik, FormikProps } from 'formik';
import { Form } from 'react-bootstrap';
import { FormikField } from 'components/common/formik/FormikField';
import { SearchBar } from 'components/common/SearchBar';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { ProductList } from './ProductList';
import { useProductCategoryModel } from './ProductCategoryModel';

import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { notRoles } from 'core/permission/PermissionDSL';
import { RoleNames } from 'core';
import { StickableTableContainer } from 'containers/StickableTableContainer/StickableTableContainer';
import { Route, Switch } from 'react-router-dom';

export const ProductCategory: React.FC<any> = () => {

  const searchBarRef = useRef<any>(null);

  const {
    loading,
    searchString,
    filters,
    productsData,
    retailerTypeOptions,
    priceMarginOptions,
    productCategoryOptions,
    defaultOrderOptions,
    showProductSetCreateModal,
    editActions,
    handleCloseModal,
    handleSearchString,
    handleSubmit,
    validate
  } = useProductCategoryModel();

  const renderFilters = (formProps: FormikProps<ProductFilter>) => {

    return (
      <Form>
        <>
          {/* <fieldset> */}
            <div className={styles.fieldContent}>
              <FormikField.Select
                label={i18n.t('productCategory.filters.labels.retailerType')}
                name='retailerId'
                options={retailerTypeOptions}
                fieldContentWidth={180}
                componentWidthFitParent={true}
                isFlexibleContent={true}
                simpleValue
              />
              <FormikField.Select
                label={i18n.t('productCategory.filters.labels.priceMargin')}
                name='priceMargin'
                options={priceMarginOptions}
                fieldContentWidth={180}
                componentWidthFitParent={true}
                isFlexibleContent={true}
                simpleValue
              />
              <FormikField.Select
                label={i18n.t('productCategory.filters.labels.productCategory')}
                name='productCategory'
                options={productCategoryOptions}
                fieldContentWidth={180}
                componentWidthFitParent={true}
                isFlexibleContent={true}
                simpleValue
              />
              <FormikField.Select
                label={i18n.t('productCategory.filters.labels.defaultOrder')}
                name='defaultOrder'
                options={defaultOrderOptions}
                fieldContentWidth={180}
                componentWidthFitParent={true}
                isFlexibleContent={true}
                simpleValue
              />
              {/* <FormikField.Select
                label={i18n.t('productCategory.filters.labels.arrivals')}
                name='arrivals'
                options={arrivalsOptions}
                fieldContentWidth={180}
                componentWidthFitParent={true}
                isFlexibleContent={true}
                simpleValue
              /> */}
              {/* <FormikField.Select
                label={i18n.t('productCategory.filters.labels.topN')}
                name='topN'
                options={topNOptions}
                fieldContentWidth={180}
                componentWidthFitParent={true}
                isFlexibleContent={true}
                simpleValue
              /> */}
            </div>
          {/* </fieldset> */}
        </>
      </Form>
    );
  };

  const renderProductDetail = () => {
    // TODO: If necessary, a Link to a detail page for each product

    return (
      <div>
        Product Detail Page
      </div>
    );
  };

  const renderHomePage = () => {

    return (
      <StickableTableContainer>
        <div className={styles.container}>
          <div className={styles.titleArea}>
            {i18n.t('productCategory.labels.title')}
            <PermissionChecker
              permissionAware={notRoles(RoleNames.sysAdmin, RoleNames.adsReport)}
            >
              <div className={styles.filtersFormContainer}>
                <Formik
                  initialValues={filters}
                  enableReinitialize
                  onSubmit={handleSubmit}
                  validate={validate}
                  render={renderFilters}
                  validateOnBlur={false}
                />
              </div>
            </PermissionChecker>
            <SearchBar
              ref={searchBarRef}
              model={{
                placeholder: i18n.t('productCategory.placeholders.searchbar'),
                search: handleSearchString,
                defaultValue: searchString
              }}
            />
          </div>

          <ProductList
            searchString={searchString}
            filterSet={filters}
            productList={productsData}
            editActions={editActions()}
            showProductSetCreateModal={showProductSetCreateModal}
            handleCloseModal={handleCloseModal}
          />
        </div>
      </StickableTableContainer>
    );
  };

  return (
    <>
      {loading && <LoadingIndicator/>}
      <Switch>
        <Route
          exact
          path={'/product-category/product/:id'}
          render={renderProductDetail}
        />
        <Route render={renderHomePage} />
      </Switch>
    </>
  );
};

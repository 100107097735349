import React from 'react';
import { connect } from 'formik';
import classnames from 'classnames/bind';
import _ from 'lodash';
import PrefixInputGroup from 'components/common/PrefixInputGroup/PrefixInputGroup';
import styles from './formikPrefixInputGroup.module.scss';
import { getFieldErrors } from 'utils/FormikUtils';
const cx = classnames.bind(styles);
const FormikPrefixInputGroup = props => {
  const pickProps = _.pick(props, 'classNames', 'currency');
  const errors = getFieldErrors(props.formik, props.name);
  const className = cx(props.className, {
    error: !!errors
  });
  return (
    <PrefixInputGroup className={className} {...pickProps}>
      {props.children}
    </PrefixInputGroup>
  );
};

export default connect(FormikPrefixInputGroup);

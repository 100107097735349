import _ from 'lodash';
import client from './RestClient';
import {
  RtbCampaign,
  CheckPoint,
  VideoProgressTrackingCode,
  RtbCampaignBasic,
  RtbCampaignOptimze,
  RTBCAMPAIGN_DEFAULT_AGE_MIN,
  RTBCAMPAIGN_DEFAULT_AGE_MAX
} from 'core/rtbCampaign/RtbCampaign';
import { wrapLimitationsFromServer } from 'utils/LimitationUtil';
import { SelectOptions } from 'components/common/commonType';
import { EstimateData } from 'core/rtbCampaign/EstimateData';
import { AdLogoType } from 'core/adLogo/AdLogo';

export type CreateCampaignRequest = {
  readonly campaign: any;
  readonly limitations: any;
};

export type UpdateCampaignRequest = {
  readonly campaign: any;
  readonly limitations: any;
};

export type EstimateDataPayload = {
  readonly dayPart?: { [day: string]: number[] };
  readonly optimize: RtbCampaignOptimze;
  readonly orderId: number;
  readonly campaignId?: number;
  readonly bidPrice: number;
  readonly limitation: { op: string, limits: { value: string, label: string }[], type: string }[];
};

export interface RtbCampaignWebService {
  getCampaign (campaignId: number): Promise<RtbCampaign>;
  getCampaigns (orderId: number): Promise<Array<RtbCampaignBasic>>;
  getCampaignsOfGroup (groupId: number | string): Promise<RtbCampaignBasic[]>;
  createCampaign (body: any, campaignGroupId?: number | string): Promise<number>;
  updateCampaign (body: any, campaignGroupId?: number | string): Promise<number>;
  splitCampaign (body: any, campaignId, campaignGroupId?: number | string): Promise<number>;
  updateCampaignState (campaignData: {
    goCampaignChannelId: (number | string),
    isDraft: boolean
  }[], state: 'activate' | 'deactivate'): Promise<void>;
  deleteCampaigns (campaignIds: Array<number>): Promise<void>;
  getCampaignOptions (): Promise<Array<SelectOptions>>;
  getOrderNumber (campaignId: string | number): Promise<string>;
  getEstimateData (payload: EstimateDataPayload): Promise<EstimateData>;
  updateCampaignBidWeight (campaignId: string | number, bidWeight: number): Promise<void>;
  getRetailOptions (): Promise<SelectOptions[]>;
}

function wrapCheckPoint (json: any): CheckPoint {
  return {
    target: _.get(json, 'target'),
    time: _.get(json, 'time'),
    utime: _.get(json, 'utime')
  };
}

function wrapTags (tags?: string | string[]): string[] {
  if (!tags) {
    return [];
  }
  if (Array.isArray(tags)) {
    return tags;
  }

  return tags.split(',');
}

function wrapBasic (json: any): any {
  const orderPrice = _.get(json, 'orderPrice');
  return {
    id: _.get(json, 'campaignId', _.get(json, 'goganCampaignId')),
    name: _.get(json, 'name'),
    advertiserId: _.get(json, 'advertiserId'),
    orderId: _.get(json, 'orderId'),
    budget: _.get(json, 'budget', 0),
    startDate: _.get(json, 'startDate'),
    endDate: _.get(json, 'endDate'),
    createDate: _.get(json, 'createDate'),
    priceModel: _.get(json, 'priceModel'),
    orderPrice,
    optimize: _.get(json, 'optimize'),
    bidPrice: _.get(json, 'bidPrice'),
    creativeDeliverType: _.get(json, 'creativeDeliverType'),
    deliverType: _.get(json, 'deliverType'),
    state: _.get(json, 'state'),
    effectiveStatus: _.get(json, 'effectiveStatus'),
    spents: _.get(json, 'spents', _.get(json, 'spent', 0)),
    currencyRate: _.get(json, 'currenyRate'),
    olapActualSpent: _.get(json, 'olapActualSpent'),
    olapExpectSpent: _.get(json, 'olapExpectSpent'),
    expectedSpent: _.get(json, 'expectedSpent', 0),
    impres: _.get(json, 'impres', 0),
    clicks: _.get(json, 'clicks', 0),
    uuCount: _.get(json, 'uuCount'),
    dailyTargetBudget: _.get(json, 'dailyTargetBudget'),
    frequency: _.get(json, 'frequency'),
    dayPart: _.get(json, 'dayPart'),
    isSelfBuyOnly: _.get(json, 'isSelfBuyOnly'),
    checkpoints: _.get(json, 'checkpoints', []).map(json =>
      wrapCheckPoint(json)
    ),
    enableMonitor: _.get(json, 'isMonitorEnable'),
    adType: _.get(json, 'adType'),
    videoAdViewObjective: _.get(json, 'videoAdViewObjective', {}),
    conversionTracking: _.get(json, 'conversionTracking'),
    viewTrackingCodes: _.get(json, 'viewTrackingCodes'),
    videoProgressTrackingCode: wrapVideoProgressTrackginCode(json),
    additionalInfo: wrapAdditionalInfo(json),
    tags: wrapTags(_.get(json, 'tags')),
    viewable: _.get(json, 'viewable', 0),
    convs: _.get(json, 'convs', 0),
    adView: _.get(json, 'adView', 0),
    convTrackEvent: _.get(json, 'convTrackEvent', 'click'),
    adLogo: wrapAdLogo(_.get(json, 'adLogo')),
    isOutOfOutdoorBk: _.get(json, 'isOutOfOutdoorBk', false),
    orderPriceEnable: orderPrice === null || orderPrice === undefined ? false : true,
    magnificationRatio: _.get(json, 'magnificationRatio', 1),
    goGanGroupId: _.get(json, 'goGanGroupId'),
    report: _.get(json, 'report'),
    ageMin: _.get(json, 'ageMin'),
    ageMax: _.get(json, 'ageMax'),
    bindings: _.get(json, 'bindings'),
    isDraft: _.get(json, 'draft', false),
    draftId: _.get(json, 'draftId'),
    retailType: _.get(json, 'retailType')
  };
}

function wrapAdLogo (json: any) {
  return {
    type: _.get(json, 'type', AdLogoType.NULL),
    link: _.get(json, 'link'),
    image: _.get(json, 'imgUrl') ? {
      url: _.get(json, 'imgUrl')
    } : undefined
  };
}

function wrapCreativeAmount (json: any) {
  return {
    bindingCount: _.get(json, 'bindingCount', 0),
    enableCount: _.get(json, 'enableCount', 0)
  };
}

function wrapAdditionalInfo (json: any) {
  const additionalInfo = _.get(json, 'additionalInfo', null);
  if (additionalInfo === null) {
    return undefined;
  }

  return {
    creativeAmount: wrapCreativeAmount(_.get(additionalInfo, 'creativeAmount')),
    limitations: wrapLimitationsFromServer(
      _.get(additionalInfo, 'limitations', []),
      undefined
    ),
    viewTrackingSize: _.get(additionalInfo, 'viewTrackingSize', 0),
    conversionTracking: wrapAdditionalConversionTracking(_.get(additionalInfo, 'conversionTracking')),
    videoProgressTrackingOffset: _.get(additionalInfo, 'videoProgressTrackingOffset')
  };
}

function wrapAdditionalConversionTracking (json: any) {
  if (!json) {
    return undefined;
  }

  return {
    conversionType: _.get(json, 'conversionType'),
    id: _.get(json, 'id'),
    name: _.get(json, 'name'),
    conversionId: _.get(json, 'conversionId')
  };
}

function wrapVideoProgressTrackginCode (json: any) {
  const videoProgressTrackingCodeJsonValue = _.get(json, 'videoProgressTrackingCode', null);
  if (videoProgressTrackingCodeJsonValue === null) {
    return undefined;
  }

  const videoProgressTrackingCodes: VideoProgressTrackingCode[] = videoProgressTrackingCodeJsonValue.map(json => {
    return {
      event: _.get(json, 'event'),
      offset: _.get(json, 'offset'),
      code: _.get(json, 'code')
    };
  });

  return videoProgressTrackingCodes[0];
}

export function wrapCampaign (json: any): RtbCampaign {
  return {
    basic: wrapBasic(_.get(json, 'campaign')),
    limitations: wrapLimitationsFromServer(
      _.get(json, 'limitations', []),
      _.get(json, 'campaign.dealIds'),
      _.get(json, 'campaign.ageMin', RTBCAMPAIGN_DEFAULT_AGE_MIN),
      _.get(json, 'campaign.ageMax', RTBCAMPAIGN_DEFAULT_AGE_MAX)
    )
  };
}

function wrapCampaignList (json: any): Array<RtbCampaignBasic> {
  return _.defaultTo(json, []).flatMap((json: any) =>
    wrapBasic(json)
  );
}

function wrapEstimateData (json: any): EstimateData {
  return {
    impression: _.get(json, 'impression', 0),
    uniqueUser: _.get(json, 'uniqueUser', 0)
  };
}

export class RestfulCampaignWebService implements RtbCampaignWebService {
  restClient: any;

  constructor (restClient: any = client) {
    this.restClient = restClient;
  }

  async getCampaign (campaignId: number): Promise<RtbCampaign> {
    const response = await this.restClient.get(`/v2/rtb-campaigns/${campaignId}`);
    return wrapCampaign(response.data);
  }

  async getCampaigns (orderId: number): Promise<Array<RtbCampaignBasic>> {
    const response = await this.restClient.get(`/v2/adsorders/${orderId}/rtb-campaigns`,
      { params: { additional_info: 'creativeAmount,limitations,conversionTracking,viewTrackingSize,videoProgressTrackingOffset' } });
    return wrapCampaignList(response.data.records);
  }

  async getCampaignsOfGroup (groupId: number | string): Promise<RtbCampaignBasic[]> {
    const response = await this.restClient.get(`/v2/campaign-groups/${groupId}/campaigns`,
      { params: { additional_info: 'creativeAmount,limitations,conversionTracking,viewTrackingSize,videoProgressTrackingOffset' } });
    return wrapCampaignList(response.data.records);
  }

  async getEstimateData (payload: EstimateDataPayload): Promise<EstimateData> {
    const response = await this.restClient.post('/v2/rtb-campaigns/discovery', payload);
    return wrapEstimateData(response.data);
  }

  async createCampaign (body: any, campaignGroupId?: number | string): Promise<number> {
    let path = '/v2/rtb-campaigns';
    if (campaignGroupId) {
      path = `/v2/campaign-groups/${campaignGroupId}/campaigns`;
    }
    const response = await this.restClient.post(path, body);
    console.log('createCampaign', response);
    return _.get(response.data, 'campaign.campaignId', 0);
  }

  async updateCampaign (body: any, campaignGroupId?: number | string): Promise<number> {
    let path = '/v2/rtb-campaigns';
    if (campaignGroupId) {
      path = `/v2/campaign-groups/${campaignGroupId}/campaigns/${_.get(body, 'campaign.campaignId')}`;
    }
    const response = await this.restClient.put(path, body);
    console.log('updateCampaign', response);
    return _.get(response.data, 'campaign.campaignId', 0);
  }

  async splitCampaign (body: any, campaignId, campaignGroupId?: number | string): Promise<number> {
    let path = `/v2/rtb-campaigns/${campaignId}/split`;
    if (campaignGroupId) {
      path = `/v2/campaign-groups/${campaignGroupId}/campaigns/${campaignId}/split`;
    }
    const response = await this.restClient.post(path, body);
    console.log('splitCampaign', response);
    return _.get(response.data, 'campaign.campaignId', 0);
  }

  async deleteCampaigns (campaignIds: number[]): Promise<void> {
    await this.restClient.delete(`/v2/rtb-campaigns?campaignIds=${campaignIds.join(',')}`);
  }

  async updateCampaignState (campaignData: {
    goCampaignChannelId: (number | string),
    isDraft: boolean
  }[], state: 'activate' | 'deactivate'): Promise<void> {
    await this.restClient.put(`/v2/rtb-campaigns/${state}`, campaignData);
  }

  async getCampaignOptions () {
    const response = await this.restClient.get('/v2/rtb-campaigns/options');
    return _.get(response, 'data.records', []);
  }

  async getOrderNumber (campaignId: string | number): Promise<string> {
    const response = await this.restClient.get(`/v2/rtb-campaigns/${campaignId}/orderNumber`);
    return response.data;
  }

  async updateCampaignBidWeight (campaignId: string | number, bidWeight: number): Promise<void> {
    return this.restClient.put(`/v2/rtb-campaigns/${campaignId}/bid-weight`, {
      bidWeight
    });
  }

  async getRetailOptions (): Promise<SelectOptions[]> {
    const response = await this.restClient.get('/v2/rtb-campaigns/retail/options');
    return _.get(response, 'data.records', []);
  }
}

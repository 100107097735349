import { PMaxCore } from 'core';
import { ConversionHomeModel, DefaultConversionHomeModel, SysAdminConversionHomeModel } from './ConversionHome/ConversionHomeModel';
import { DefaultAdvertiserManager, AdvertiserManager } from 'core/advertiser/AdvertiserManager';
import { SelectOptions } from 'components/common/commonType';
import { FireableUpdateEventListener, UpdateEventListener } from 'utils/UpdateEventListener';
import { ConversionFormModel, CreateConversionFormModel, EditConversionFormModel } from './ConversionForm/ConversionFormModel';
import { ConversionManager, DefaultConversionManager } from 'core/conversion/ConversionManager';
import { ConversionListDTO } from 'core/conversion/Conversion';
import { isSystemAdmin } from 'helper/ActorHelper';
import { SessionStorageHelper, SessionStorageItemKeys } from 'helper/StorageHelper';

export interface ConversionsModel {
  readonly event: UpdateEventListener<ConversionsModel>;
  readonly state: ConversionsState;
  init (): Promise<void>;
  getConversionHomeModel (): ConversionHomeModel;
  getCreateConversionFormModel (): ConversionFormModel;
  getEditConversionFormModel (segmentId: number): ConversionFormModel | undefined;
  onUnmount (handler: any): void;
}

export type ConversionsProps = {
  readonly model: ConversionsModel;
};

export type ConversionsState = {
  readonly loading: boolean;
  readonly presetLoading: boolean;
};

export class DefaultConversionsModel implements ConversionsModel {

  presetLoading: boolean;
  loading: boolean;
  core: PMaxCore;
  manager: ConversionManager;
  advertiserManager: AdvertiserManager;
  advertisers: Array<SelectOptions>;
  event: FireableUpdateEventListener<ConversionsModel>;
  conversions: Array<ConversionListDTO>;
  conversionHomeModel?: ConversionHomeModel;
  agencyId?: number;

  constructor (
    core: PMaxCore,
    advertiserManager: AdvertiserManager = new DefaultAdvertiserManager(),
    manager: ConversionManager = new DefaultConversionManager()
  ) {
    this.core = core;
    this.loading = false;
    this.presetLoading = true;
    this.event = new FireableUpdateEventListener<ConversionsModel>();
    this.advertiserManager = advertiserManager;
    this.manager = manager;
    this.advertisers = [];
    this.conversions = [];
    this.agencyId = SessionStorageHelper.getNumberItem(SessionStorageItemKeys.AGENCY);
  }

  get state () {
    return {
      loading: this.loading,
      presetLoading: this.presetLoading
    };
  }

  async init (): Promise<void> {
    this.updateState(false, true);
    try {
      this.advertisers = await this.advertiserManager.getAdvertiserOptions(this.agencyId);
      this.conversions = await this.manager.getConversions(this.agencyId);
    } catch (error) {}
    this.updateState(false);
  }

  onAgencyChange = async (agencyId: number) => {
    this.updateState(true);
    try {
      this.advertisers = await this.advertiserManager.getAdvertiserOptions(agencyId);
      this.conversions = await this.manager.getConversions(agencyId);
      this.agencyId = agencyId;
      if (this.conversionHomeModel) {
        this.conversionHomeModel.updateParams(this.advertisers, this.conversions);
      }
    } catch (error) {}
    this.updateState(false);
  }

  onConversionsChange = async () => {
    this.updateState(true);
    try {
      this.conversions = await this.manager.getConversions(this.agencyId);
      if (this.conversionHomeModel) {
        this.conversionHomeModel.updateParams(this.advertisers, this.conversions);
      }
    } catch (error) {}
    this.updateState(false);
  }

  getConversionHomeModel () {
    if (this.conversionHomeModel) {
      return this.conversionHomeModel;
    }
    if (isSystemAdmin(this.core.authenticationManager.actor)) {
      this.conversionHomeModel = new SysAdminConversionHomeModel(this.advertisers, this.conversions, this.onAgencyChange);
    } else {
      this.conversionHomeModel = new DefaultConversionHomeModel(this.advertisers, this.conversions);
    }
    return this.conversionHomeModel;
  }

  getCreateConversionFormModel (): ConversionFormModel {
    return new CreateConversionFormModel(this.advertisers, this.onConversionsChange);
  }

  getEditConversionFormModel (conversionId: number): ConversionFormModel | undefined {
    const conversion = this.conversions.find(conversion => conversion.id === conversionId);
    if (!conversion) {
      return undefined;
    }
    return new EditConversionFormModel(this.advertisers, conversion, this.onConversionsChange);
  }

  onUnmount (handler) {
    handler && this.event.remove(handler);
    this.loading = false;
    this.presetLoading = true;
    this.conversionHomeModel = undefined;
  }

  updateState (loading: boolean, presetLoading: boolean = false) {
    this.loading = loading;
    this.presetLoading = presetLoading;
    this.event.fireEvent(this);
  }
}

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { IconDefinition, IconProp } from '@fortawesome/fontawesome-svg-core';
import styles from './iconWithTooltip.module.scss';
import classnames from 'classnames/bind';

interface IconWithTooltipProps {
  disabled?: boolean;
  placement?: 'bottom' | 'top' | 'left' | 'right';
  className?: string;
  icon: IconDefinition | IconProp;
  onClick?: () => void;
  tooltipProps?: {
    id: string | number,
    tooltip: React.ReactElement | string
  };
}

const IconWithTooltip: React.FC<IconWithTooltipProps> = ({
  disabled,
  placement,
  className,
  icon,
  onClick,
  tooltipProps
}) => {
  const cx = classnames.bind(styles);
  const finalClassName = cx('iconWithTooltip', className, {
    disabled
  });
  const props = {
    className: finalClassName,
    icon,
    onClick: disabled ? undefined : onClick
  };
  return tooltipProps ?
    <OverlayTrigger
      placement={placement}
      trigger={['hover']}
      overlay={
        <Tooltip id={tooltipProps.id + '-iconTooltip'}>
          {tooltipProps.tooltip}
        </Tooltip>
      }
    >
      <FontAwesomeIcon {...props}/>
    </OverlayTrigger> :
    <FontAwesomeIcon {...props}/>;
};

IconWithTooltip.defaultProps = {
  disabled: false,
  placement: 'bottom'
};

export default IconWithTooltip;

import React from 'react';
import { faEye, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import TableRowToolBar from 'containers/TableRowToolBar/TableRowToolBar';
import { Link } from 'react-router-dom';
import styles from './tiktokAdGroupList.module.scss';
import i18n from 'i18n';
import { TiktokAdGroup } from 'core/tiktokAdGroup/TiktokAdGroup';
import { formatPrice, formatPriceWithCurrency } from 'helper/CurrencyHelper';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { hasFuncs } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';
import _ from 'lodash';
import { Status } from 'components/Status/Status';
import { Trans } from 'react-i18next';
import { deliveryStatusDataSetting } from './adGroupDeliveryStatusDataSetting';
import { TiktokObjective } from 'core/campaignGroup/CampaignGroup';

const getAdGroupStatusDesData = (adGroup) => {
  let label = _.startCase(_.lowerCase(adGroup.opt_status));
  let color = 'black';
  switch (adGroup.opt_status) {
    case 'DISABLE':
      label = i18n.t('campaignList.labels.deactivateState');
      color = 'black';
      break;
    case 'DELETED':
      label = i18n.t('campaignList.labels.deleteState');
      color = 'danger';
      break;
    case 'ENABLE':
      label = i18n.t('campaignList.labels.activateState');
      color = 'success';
      break;
    default:
      break;
  }

  return {
    label,
    color
  };
};

const getAdGroupEffectiveStatusDesData = (adGroup) => {
  const deliveryStatusData = deliveryStatusDataSetting[adGroup.status];
  if (!deliveryStatusData) {
    return {
      label: _.startCase(_.lowerCase(adGroup.status)),
      color: ''
    };
  }
  const reasons = _.get(adGroup, 'review.reasons', []);
  const hasRejectedAd = _.get(adGroup, 'review.hasRejectedAd', false);
  if (hasRejectedAd) {
    reasons.push(i18n.t('campaignList.labels.hasRejectedAd'));
  }
  const rejectReasonsDom = reasons.map(rejectReason => (
    <li key={rejectReason}>
      {rejectReason}
    </li>
  ));
  const renderExtraInfo = () => {
    return reasons && reasons.length > 0 ? (
      <div>
        <div className={styles.deliveryAdReviewSection}>{deliveryStatusData.des}</div>
        <div className={styles.deliveryAdReviewSection}>
          <div>Reasons:</div>
          <ul>
            {rejectReasonsDom}
          </ul>
        </div>
      </div>
    ) : `${deliveryStatusData.des}`;
  };
  if (adGroup.budget_remaining > 0 &&
    (adGroup.status === 'ADGROUP_STATUS_CAMPAIGN_DISABLE' || adGroup.status === 'ADGROUP_STATUS_DISABLE')
  ) {
    return {
      label: i18n.t('campaignList.labels.budgetRemainState'),
      color: 'danger',
      renderExtraInfo
    };
  }
  return {
    label: i18n.t(deliveryStatusData.label),
    color: deliveryStatusData.color,
    renderExtraInfo
  };
};

const nameHeaderFormatter = (
  adGroupLength: number,
  selectedObjects: (number | string)[],
  onSelectAll,
  column: any,
  _1,
  { sortElement }
) => {
  return (
    <div className={styles.nameHeader}>
      <PermissionChecker permissionAware={hasFuncs(Permission.CAMPAIGN_WRITE)}>
        <div className={styles.selectCheckBox}>
          <input
            type='checkbox'
            checked={adGroupLength === selectedObjects.length}
            ref={el => el && (el.indeterminate = adGroupLength !== selectedObjects.length && selectedObjects.length > 0)}
            onChange={onSelectAll}
            id={'input0'}
          />
          <label htmlFor='input0' />
        </div>
      </PermissionChecker>
      {i18n.t(column.text)}
      {sortElement}
    </div>
  );
};

const creativeBindingFormatter = (
  enableBindingCount: number , { adgroup_id, draftId, isDraft }, _1, { onClick }
) => {
  const onClickBinding = () => onClick(isDraft ? draftId : adgroup_id, isDraft);

  const renderCheckButton = () => (
    <IconWithTooltip
      className={styles.detailBtn}
      onClick={onClickBinding as () => void}
      icon={faEye}
      tooltipProps={{
        id: `campaignListCreativeViewHint-${adgroup_id}`,
        tooltip: i18n.t('campaignList.labels.creativeViewHint')
      }}
    />
  );
  return (
      <div className={styles.bindingCell}>
        {enableBindingCount === 0 || !enableBindingCount ? i18n.t('creativeList.labels.none') : enableBindingCount}
        <PermissionChecker
          permissionAware={hasFuncs(Permission.CAMPAIGN_WRITE)}
          renderOtherwise={renderCheckButton}
        >
          <IconWithTooltip
            placement='right'
            className={styles.campaignEditBtn}
            onClick={onClickBinding as () => void}
            icon={faPencilAlt}
            tooltipProps={{
              id: `campaignListCreativeEditHint-${adgroup_id}`,
              tooltip: i18n.t('campaignList.labels.creativeEditHint')
            }}
          />
        </PermissionChecker>
      </div>
  );
};

const nameFormatter = (objectId: number | string, tiktokAdGroup: TiktokAdGroup, _2, formatExtraData: any): any => {
  const renderLabel = () => <span className={styles.name}>{tiktokAdGroup.adgroup_name}</span>;
  const { selectedAdGroups, selectedDrafts, onSelect } = formatExtraData;
  const isDraft: boolean = _.get(tiktokAdGroup, 'isDraft', false);
  const adgroupId: number | string = tiktokAdGroup.adgroup_id;
  const draftId: number | string = isDraft ? objectId.toString().split('_')[2] : '';
  const objectIsChecked = !isDraft ? selectedAdGroups.indexOf(adgroupId.toString()) > -1 : selectedDrafts.indexOf(draftId.toString()) > -1;
  const editPath = isDraft ?
    `${formatExtraData.currentUrl}/drafts/${draftId}/edit` :
    `${formatExtraData.currentUrl}/campaigns/${adgroupId}/edit`;
  return (
    <div className={`${styles.nameCell}`}>
      <div className={styles.selectCheckBox}>
        <PermissionChecker permissionAware={hasFuncs(Permission.CAMPAIGN_WRITE)}>
          <input
            type='checkbox'
            checked={objectIsChecked}
            onChange={_.partial(onSelect, tiktokAdGroup)}
            id={`input${objectId}`}
          />
          <label htmlFor={`input${objectId}`} />
        </PermissionChecker>
      </div>
      <div className={styles.info}>
        <PermissionChecker permissionAware={hasFuncs(Permission.CAMPAIGN_WRITE)} renderOtherwise={renderLabel}>
          <Link to={editPath} className={styles.name}>
            {tiktokAdGroup.adgroup_name}
          </Link>
        </PermissionChecker>
        <div className={styles.id}>
          {'ID: ' + objectId}
        </div>
        {isDraft &&
          <div className={styles.draftIcon}>
            {i18n.t('goCampaignList.labels.draft')}
          </div>
        }
      </div>
    </div>
  );
};

const stateFormatter = (_1, tiktokAdGroup: TiktokAdGroup) => {
  const stateData = getAdGroupStatusDesData(tiktokAdGroup);
  return (
    <Status
      label={stateData.label}
      color={stateData.color}
    />
  );
};

const deliveryFormatter = (_1, tiktokAdGroup: TiktokAdGroup) => {
  const isDraft: boolean = _.get(tiktokAdGroup, 'isDraft', false);
  if (isDraft) {
    const renderExtraInfo = () => i18n.t('campaignList.labels.draftStateHint');
    return (
      <Status
        label={i18n.t('campaignList.labels.deactivateState')}
        color={'black'}
        renderExtraInfo={renderExtraInfo}
      />
    );
  }
  const stateData = getAdGroupEffectiveStatusDesData(tiktokAdGroup);
  return (
    <Status
      {...stateData}
    />
  );
};

const scheduleFormatter = (startTime: string, tiktokAdGroup: TiktokAdGroup, _1): any => {
  return (
    <Trans i18nKey='campaignList.labels.schedule'>
      {{ start: startTime }}<br/>to<br/>{{ end: tiktokAdGroup.schedule_end_time }}
    </Trans>
  );
};

const budgetFormatter = (budget: number | string, _1, _2, formatExtraData: any): any => {
  if (budget === -1) {
    return i18n.t('common.labels.noData');
  }
  const currency = formatExtraData.currency;
  return formatPrice(currency, budget ? +budget : 0);
};

const optimizationFormatter = (optimization: string, tiktokAdGroup: TiktokAdGroup, _1, formatExtraData: any): any => {
  const currency = formatExtraData.currency;
  return (
    <div className={styles.optimizationCell}>
      <div className={styles.title}>
        {i18n.t('adGroupSetupFlow.mainStep.field.optimizationGoal')}
      </div>
      <div>:</div>
      <div>
        {i18n.t(`adGroup.optimizationGoal.${optimization.toLowerCase()}`)}
      </div>
      {
        _.get(tiktokAdGroup, 'promoted_object.custom_event_type') &&
        <>
          <div className={styles.title}>
            {i18n.t('adGroupSetupFlow.mainStep.field.promoted_object_custom_event_type')}
          </div>
          <div>:</div>
          <div>
            {_.startCase(_.lowerCase(_.get(tiktokAdGroup, 'promoted_object.custom_event_type')))}
          </div>
        </>
      }
      <div className={styles.title}>
        {i18n.t('adGroupSetupFlow.mainStep.field.bidStrategy')}
      </div>
      <div>:</div>
      <div>
        {i18n.t(`adGroup.bidStrategy.${tiktokAdGroup.bid_type.toLowerCase()}`)}
      </div>
      <div className={styles.title}>
        {i18n.t('adGroupSetupFlow.mainStep.field.bidControl')}
      </div>
      <div>:</div>
      <div>
        {tiktokAdGroup.bid ?
         formatPriceWithCurrency(currency, +(tiktokAdGroup.bid)) :
         i18n.t('common.labels.noData')
        }
      </div>
      <div className={styles.title}>
        {i18n.t('adGroupSetupFlow.mainStep.field.billingEvent')}
      </div>
      <div>:</div>
      <div>
        {i18n.t(`adGroup.billingEvent.${tiktokAdGroup.billing_event.toLowerCase()}`)}
      </div>
    </div>
  );
};

const resultsFormatter = (value, _1, _2, formatExtraData: any) => {
  const objective = formatExtraData.objective;
  const hint = objective === TiktokObjective.REACH ? 'Reach' : 'Link Clicks';
  return (
    <div className={styles.resultCell}>
      <div className={styles.value}>
        {value === undefined ? 0 : value}
      </div>
      <div className={styles.objective}>
        {hint}
      </div>
    </div>
  );
};

const floatingEditBtnsFormatter = (_1, tiktokAdGroup: TiktokAdGroup, _2, formatExtraData: any): any => {
  const adgroupId: number | string = tiktokAdGroup.adgroup_id;
  const draftId: number | string = _.get(tiktokAdGroup, 'draftId', 0);
  const editPath = tiktokAdGroup.isDraft ?
    `${formatExtraData.currentUrl}/drafts/${draftId}/edit` :
    `${formatExtraData.currentUrl}/campaigns/${adgroupId}/edit`;
  const editMessage = tiktokAdGroup.isDraft ?
    i18n.t('goCampaignList.labels.editDraft') :
    i18n.t('adGroupSetupFlow.mainStep.editTitle');
  // const timeFormat = 'YYYY-MM-DD HH:mm:ss';
  // const from = encodeURIComponent(moment(tiktokAdGroup.schedule_start_time).startOf('day').format(timeFormat));
  // const to = encodeURIComponent(moment(tiktokAdGroup.schedule_end_time).endOf('day').format(timeFormat));
  // const reportUrl = `/reports/performance?dimension=goCampaignId&from=${from}&to=${to}&goCampaignId=${tiktokAdGroup.id}`;
  return (
    <TableRowToolBar className={styles.floatingEditArea}>
      <PermissionChecker permissionAware={hasFuncs(Permission.CAMPAIGN_WRITE)}>
        <Link
          to={editPath}
        >
          <IconWithTooltip
            icon={faPencilAlt}
            tooltipProps={{
              id: `tiktokAdGroup-editTip-${adgroupId}-${draftId}`,
              tooltip: editMessage
            }}
          />
        </Link>
      </PermissionChecker>
      {/* <PermissionChecker permissionAware={hasFuncs(Permission.REPORT_ADS)}>
        <Link to={reportUrl}>
          <IconWithTooltip
            icon={faChartArea}
            tooltipProps={{
              id: `tiktokAdGroup-reportTip-${tiktokAdGroup.id}`,
              tooltip: i18n.t('campaignList.labels.reportHint')
            }}
          />
        </Link>
      </PermissionChecker> */}
    </TableRowToolBar>
  );
};

export default {
  nameHeaderFormatter,
  nameFormatter,
  stateFormatter,
  deliveryFormatter,
  creativeBindingFormatter,
  optimizationFormatter,
  scheduleFormatter,
  budgetFormatter,
  resultsFormatter,
  floatingEditBtnsFormatter
};

import _ from 'lodash';
import i18n from 'i18next';
import { Limitation } from 'core/limitation/Limitation';
// import { getRtbAgeGroupsByAgeRange } from 'core/limitation/goCampaignTAOptions';

export function toServerStructure (limitations, filterTA: {[key: string]: string[]} = {
  other: ['dealId'],
  include: ['ppsLayoutId', 'age_min', 'age_max']
}) {
  const transformLimitationData = (op, data) => {
    if (!Array.isArray(data.value)) {
      return [
        {
          op: op,
          limits: data.value,
          isGroup: false,
          type: data.type
        }
      ];
    }
    // adFormat special case
    if (data.type === 'adFormat' && data.value.find(limit => limit.value === 'instl')) {
      return [
        {
          op: op,
          limits: [{
            label: '蓋板',
            value: '1'
          }],
          isGroup: false,
          type: 'instl'
        }
      ];
    }
    const limits = [...data.value];
    const groupData = _.remove(limits, (value: any) => value.isGroup === true);
    let result = limits.length > 0 ? [{
      op: op,
      limits: limits.map(value => {
        // [PMAX-4467] contentcat should save translated sentence to make PMAX1.0 to show correct value.
        return {
          label: data.type === 'contentcat' ? getLimitationContentLabel(data.type, value) : value.label,
          value: value.value
        };
      }),
      isGroup: false,
      type: data.type
    }] : [];
    if (groupData.length > 0) {
      result.push({
        op: op,
        limits: groupData.map(value => {
           // [PMAX-4467] contentcat should save translated sentence to make PMAX1.0 to show correct value.
          return {
            label: data.type === 'contentcat' ? i18n.t(`limitation.contentCategory.${value.label}`) : value.label,
            value: value.value
          };
        }),
        isGroup: true,
        type: data.type
      });
    }
    return result;
  };

  const otherLimitations = filterTA.other ? _.filter(limitations.other,
    (limitation: Limitation) => !filterTA.other.includes(limitation.type)) : limitations.other;

  const includeLimitations = filterTA.include ? _.filter(limitations.include,
    (limitation: Limitation) => !filterTA.include.includes(limitation.type)) :
    limitations.include;

  let result = _.compact(_.flatten(_.concat(
    includeLimitations && includeLimitations.map(value => {
      return transformLimitationData('inc', value);
    }),
    limitations.exclude && limitations.exclude.map(value => {
      return transformLimitationData('exc', value);
    }),
    limitations.preferred && limitations.preferred.map(value => {
      return transformLimitationData('Preferred', value);
    }),
    limitations.nonPreferred && limitations.nonPreferred.map(value => {
      return transformLimitationData('NonPreferred', value);
    }),
    // dealId
    otherLimitations && otherLimitations.map(value => {
      if (value.type === 'dealId') {
        return transformLimitationData('inc', value);
      } else {
        return transformLimitationData(value.op, value);
      }
    }))
  ));
  return result;
}

export function getLimitationTitleOfType (limitationType: string) {
  switch (limitationType) {
    case 'geography':
      return i18n.t('limitation.labels.country');
    case 'adspace':
      return i18n.t('limitation.labels.space');
    case 'age':
      return i18n.t('limitation.labels.ages');
    case 'unicornkeyword1':
      return `${i18n.t('limitation.labels.keyword1')}`;
    case 'unicornkeyword2':
      return `${i18n.t('limitation.labels.keyword2')}`;
    case 'adcat':
      return i18n.t('limitation.labels.tenmaxCategory');
    default:
      return i18n.t(`limitation.labels.${limitationType}`);
  }
}

export function getLimitationContentLabel (type, limitation) {
  const i18nValue = limitation.value.toString().toLowerCase().replace(/-|\s/g, '_');
  switch (type) {
    case 'contentlang':
    case 'gender':
    case 'spaceType':
    case 'device':
    case 'carrier':
    case 'ppsLayoutId':
      return i18n.t(`limitationOptions.labels.${type}.${i18nValue}`);
    case 'adcat':
      return i18n.t(`tenmaxCategory.labels.${i18nValue}`);
    case 'contentcat':
      return i18n.t(`limitation.contentCategory.${i18nValue}`);
    case 'adFormat':
      return i18n.t(`adFormat.${i18nValue}`);
    default:
      return limitation.label;
  }
}

export function getLimitationAddonDependency (limitationType, limitationName) {
  const addonMap = {
    geography: `${limitationType}_country`,
    carrier: `${limitationType}_carrier`,
    device: `${limitationType}_device`,
    os: `${limitationType}_device`,
    age: `${limitationType}_age`,
    gender: `${limitationType}_gender`,
    segment: `${limitationType}_target_audience`,
    tenmaxAudience: `${limitationType}_tenmax_audience`,
    contentcat: `${limitationType}_content_category`,
    unicornkeywords: `${limitationType}_keyword`,
    adx: `${limitationType}_adexchange`,
    spaceType: `${limitationType}_spaceType`,
    adspace: `${limitationType}_asiamax_space`,
    contentlang: `${limitationType}_content_language`,
    adcat: `${limitationType}_space_category`,
    domain: `${limitationType}_domain`
  };
  return addonMap[limitationName];
}

export function wrapLimitationsFromServer (limitations, dealIds?: string[], ageMin?: number, ageMax?: number) {
  const limitationMap = {
    inc: {},
    exc: {},
    Preferred: {},
    NonPreferred: {},
    other: {}
  };
  limitations.forEach(limitation => {
    const limits = _.get(limitation, 'limits', []);
    const wrappedLimitation = {
      op: _.get(limitation, 'op'),
      value: Array.isArray(limits) ? limits.map(limits => ({
        label: _.get(limits, 'label'),
        value: _.get(limits, 'value'),
        isGroup: _.get(limits, 'isGroup')
      })) : limits,
      type: _.get(limitation, 'type')
    };
    if (limitation.type === 'dealId') {
      limitationMap['other'][limitation.type] = wrappedLimitation;
    } else if (limitation.type === 'instl') {
      // transfer legacy code
      limitationMap[limitation.op]['adFormat'] = {
        op: limitation.op,
        value: [{
          label: 'instl',
          value: 'instl',
          isGroup: false
        }],
        type: 'adFormat'
      };
    } else if (limitation.type in limitationMap[limitation.op]) {
      limitationMap[limitation.op][limitation.type].value = _.concat(limitationMap[limitation.op][limitation.type].value, wrappedLimitation.value);
    } else {
      limitationMap[limitation.op][limitation.type] = wrappedLimitation;
    }
  });

  const wrappedLimitations: { [type: string]: Limitation[] } = {
    include: Object.values(limitationMap['inc']),
    exclude: Object.values(limitationMap['exc']),
    preferred: Object.values(limitationMap['Preferred']),
    nonPreferred: Object.values(limitationMap['NonPreferred']),
    other: Object.values(limitationMap['other'])
  };

  ageMin && wrappedLimitations.include.push({
    op: 'inc',
    value: ageMin,
    type: 'age_min'
  });

  ageMax && wrappedLimitations.include.push({
    op: 'inc',
    value: ageMax,
    type: 'age_max'
  });

  const dealIdTA = wrappedLimitations.other.find(ta => ta.type === 'dealId');
  !dealIdTA && wrappedLimitations.other.push({
    op: 'inc',
    value: dealIds ? dealIds.map(dealId => {
      return {
        label: dealId,
        value: dealId
      };
    }) : [],
    type: 'dealId'
  });

  return wrappedLimitations;
}

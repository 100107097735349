import { CampaignGroupChannel } from 'core/campaignGroup/CampaignGroup';
import { BindingCreateRequest, BindingWebService, RestfulBindingWebService } from 'ws/BindingWebService';

export interface BindingManager {
  createBinding (channel: CampaignGroupChannel, createRequest: BindingCreateRequest): Promise<void>;
  updateBinding (channel: CampaignGroupChannel, updateRequest: BindingCreateRequest): Promise<void>;
}

export class DefaultBindingManager implements BindingManager {
  webService: BindingWebService;

  constructor (
    webService: BindingWebService = new RestfulBindingWebService()
  ) {
    this.webService = webService;
  }

  async createBinding (channel: CampaignGroupChannel, createRequest: BindingCreateRequest): Promise<void> {
    return this.webService.createBinding(channel, createRequest);
  }

  async updateBinding (channel: CampaignGroupChannel, updateRequest: BindingCreateRequest): Promise<void> {
    return this.webService.updateBinding(channel, updateRequest);
  }
}

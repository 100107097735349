import React from 'react';
import { OrdersProps } from './OrdersModel';
import { OrderHome } from './OrderHome/OrderHome';
import styles from './orders.module.scss';
import { OrderForm } from './OrderForm/OrderForm';
import { Switch, Route, Redirect } from 'react-router-dom';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { OrderRedirect } from './OrderRedirect';
import RouteWithPermission from 'components/RouteWithPermission/RouteWithPermission';
import { notRoles, hasFuncs } from 'core/permission/PermissionDSL';
import { RoleNames } from 'core';
import { Permission } from 'core/auth/Permission';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { ROUTE_PATH } from 'enum/RoutePath';
import { withErrorBoundary } from 'hoc/withErrorBoundary';
import { ERROR_CODE } from 'core/ErrorCode';
import { OrderDetail } from './OrderDetail/OrderDetail';

const OrderHomeView = withErrorBoundary(ERROR_CODE.ORDER_HOME, OrderHome);
const CreateOrderView = withErrorBoundary(ERROR_CODE.ORDER_CREATE, OrderForm);
const EditOrderView = withErrorBoundary(ERROR_CODE.ORDER_EDIT, OrderForm);
const OrderDetailView = withErrorBoundary(ERROR_CODE.ORDER_GROUP_DETAIL, OrderDetail);
export class Orders extends React.PureComponent<OrdersProps> {

  handler: any;

  componentDidMount () {
    this.handler = this.props.model.event.add((model) => {
      this.setState(model.state);
    });
    this.props.model.init();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add((model) => {
        this.setState(model.state);
      });
      this.props.model.init();
    }
  }

  componentWillUnmount () {
    this.props.model.onUnmount(this.handler);
  }

  renderOrderCreateForm = (props) => {
    const model = this.props.model.getCreateOrderFormModel();
    if (!model) {
      return <Redirect to={`${props.match.url}/${ROUTE_PATH.ERROR403}`} />;
    }
    const renderRedirect = () => <Redirect to={`${props.match.url}/${ROUTE_PATH.ERROR403}`} />;
    return (
      <PermissionChecker
        permissionAware={hasFuncs(Permission.ORDER_WRITE)}
        renderOtherwise={renderRedirect}
      >
        <CreateOrderView
          {...props}
          model={model}
        />
      </PermissionChecker>
    );
  }

  renderOrderEditForm = (props) => {
    const model = this.props.model.getEditOrderFormModel(props.match.params.orderNumber);
    if (!model) {
      return <Redirect to={`${props.match.url}/${ROUTE_PATH.ERROR403}`} />;
    }
    const renderRedirect = () => <Redirect to={`${props.match.url}/${ROUTE_PATH.ERROR403}`} />;
    return (
      <PermissionChecker
        permissionAware={hasFuncs(Permission.ORDER_WRITE)}
        renderOtherwise={renderRedirect}
      >
        <EditOrderView
          {...props}
          key={props.match.params.orderNumber}
          model={model}
        />
      </PermissionChecker>
    );
  }

  renderRedirectToOrderEdit = (props) => {
    const orderId = props.match.params.orderId;
    return (
      <OrderRedirect
        orderId={orderId}
        action={'edit'}
      />
    );
  }

  renderRedirectToOrderDetail = (props) => {
    const orderId = props.match.params.orderId;
    return (
      <OrderRedirect
        orderId={orderId}
      />
    );
  }

  renderOrderDetailView = (props) => {
    const renderRedirect = () => <Redirect to={`${props.match.url}/${ROUTE_PATH.ERROR403}`} />;
    return (
      <PermissionChecker
        permissionAware={hasFuncs(Permission.CAMPAIGN_READ)}
        renderOtherwise={renderRedirect}
      >
        <OrderDetailView
          {...props}
          key={props.match.params.orderNumber}
          {...this.props.model.getOrderDetailProps(props.match.params.orderNumber)}
        />
      </PermissionChecker>
    );
  }

  renderOrderHome = (props) => {
    const model = this.props.model.getOrderHomeModel();
    if (!model) {
      return <div />;
    }
    return (
      <div className={styles.container}>
        <OrderHomeView model={model} />
      </div>
    );
  }

  render () {
    if (this.props.model.state.loading) {
      return <LoadingIndicator />;
    }
    return (
      <Switch>
        <Route
          path={'/orders/new'}
          render={this.renderOrderCreateForm}
        />
        <Route
          path={'/orders/:orderNumber/edit'}
          render={this.renderOrderEditForm}
        />
        <Route
          path={'/orders/:orderId/redirectToEdit'}
          render={this.renderRedirectToOrderEdit}
        />
        <Route
          path={'/orders/:orderId/redirectToDetail'}
          render={this.renderRedirectToOrderDetail}
        />
        <Route
          path={'/orders/:orderNumber'}
          render={this.renderOrderDetailView}
        />
        <RouteWithPermission
          permissionAware={notRoles(RoleNames.sysAdmin)}
          redirectPath={`/orders/${ROUTE_PATH.ERROR403}`}
          render={this.renderOrderHome}
        />
      </Switch>
    );
  }
}

import { CreativeListRecord, CreativeType } from 'core/creative/Creative';
import { NativeCreativeDetail } from './NativeCreativeDetail';
import { VideoCreativeDetail } from './VideoCreativeDetail';
import { ImageCreativeDetail } from './ImageCreativeDetail';
import { PPSCreativeDetail } from './PPSCreativeDetail';
import { HTML5CreativeDetail } from './HTML5CreativeDetail';
import { OutdoorCreativeDetail } from './OutdoorCreativeDetail';
import { ComboCreativeDetail } from './ComboCreativeDetail';
import { OneForAllDisplayCreativeDetail } from './OneForAllDisplayCreativeDetail';
import { OneForAllVideoCreativeDetail } from './OneForAllVideoCreativeDetail';
import { AdNeonCreativeDetail } from './AdNeonCreativeDetail';
import { RetailProductCreativeDetail } from './RetailProductCreativeDetail';

const detailComponentSettings = {
  [CreativeType.NATIVE]: NativeCreativeDetail,
  [CreativeType.IMAGE]: ImageCreativeDetail,
  [CreativeType.VIDEO]: VideoCreativeDetail,
  [CreativeType.ONE_FOR_ALL_DISPLAY]: OneForAllDisplayCreativeDetail,
  [CreativeType.THIRDPARTY]: PPSCreativeDetail,
  [CreativeType.HTML5]: HTML5CreativeDetail,
  [CreativeType.OUTDOOR]: OutdoorCreativeDetail,
  [CreativeType.COMBO]: ComboCreativeDetail,
  [CreativeType.ONE_FOR_ALL_VIDEO]: OneForAllVideoCreativeDetail,
  [CreativeType.AD_NEON]: AdNeonCreativeDetail,
  [CreativeType.RETAIL_RICH_MEDIA_PRODUCT]: RetailProductCreativeDetail,
  [CreativeType.RETAIL_NATIVE_PRODUCT]: RetailProductCreativeDetail,
  [CreativeType.PILOT_TV]: VideoCreativeDetail
};

export interface CreativeDetailModel {
  creative: CreativeListRecord;
  detailComponent: any;
}

export type CreativeDetailProps = {
  model: CreativeDetailModel
};

export class DefaultCreativeDetailModel implements CreativeDetailModel {

  creative: CreativeListRecord;
  detailComponent: any;

  constructor (creative: CreativeListRecord) {
    this.creative = creative;
    this.detailComponent = detailComponentSettings[this.creative.creativeType];
  }
}

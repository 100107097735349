import { useCallback, useEffect, useMemo, useState } from 'react';
import { RdpManager, DefaultRdpManager } from 'core/rdp/RdpManager';
import { ProductSet } from 'core/product/Product';
import _ from 'lodash';
import { useCallAPI } from 'hooks/useCallAPI';

const defaultRdpManager: RdpManager = new DefaultRdpManager();

export const useProductSetDetailModel = (
  retailId: number | string,
  advertiserId: number | string,
  productSetId: number | string
) => {

  const {
    loading,
    callAPIs
  } = useCallAPI();
  const [productSet, setProductSet] = useState<ProductSet>();
  const [deleteProducts, setDeleteProducts] = useState<() => Promise<void> | undefined>();

  const fetchProductSet = useCallback(async () => {
    callAPIs(
      [defaultRdpManager.getProductSetDetail.bind(defaultRdpManager, retailId, productSetId)],
      (productSet: ProductSet) => {
        console.log('productSet', productSet);
        setProductSet(productSet);
      }
    );
  }, [retailId, productSetId, callAPIs]);

  useEffect(() => {
    fetchProductSet();
  }, [fetchProductSet]);

  const onDeleteModalClose = useCallback((refresh: boolean) => {
    setDeleteProducts(undefined);
    refresh && fetchProductSet();
  }, [fetchProductSet]);

  const deleteFunc = useCallback(async (productsToRemove: (number | string)[], cb?: () => void) => {
    setDeleteProducts(undefined);
    callAPIs(
      [defaultRdpManager.updateProductSetRemove.bind(defaultRdpManager, retailId, {
        id: _.get(productSet, 'id', 0),
        name: _.get(productSet, 'name', ''),
        advertiser: advertiserId,
        rule: {
          include: productsToRemove
        }
      })],
      (productSet: ProductSet) => {
        console.log('productset removed products', productSet);
        onDeleteModalClose(true);
        cb && cb();
      }
    );
  }, [retailId, productSet, advertiserId, onDeleteModalClose, callAPIs]);

  const editActions = useMemo(() => ([{
    label: 'productSetDetail.labels.removeProduct',
    onClick: (selectedProducts: (number | string)[], handleRemoveSelect?: () => void) => {
      setDeleteProducts(_1 => _.partial(deleteFunc, selectedProducts, handleRemoveSelect));
    }
  }]), [deleteFunc]);

  return {
    loading,
    productSet,
    editActions,
    deleteProducts,
    onDeleteModalClose
  };
};

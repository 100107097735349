import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';

export interface AsyncSourceSpanModel {
  readonly state: AsyncSourceSpanState;
  readonly event: UpdateEventListener<AsyncSourceSpanModel>;
  getText (): Promise<any>;
}

export type AsyncSourceSpanProps = {
  readonly model: AsyncSourceSpanModel;
  readonly className?: string;
};

export type AsyncSourceSpanState = {
  readonly loading: boolean;
  readonly text?: any;
};

export class DefaultAsyncSourceSpanModel implements AsyncSourceSpanModel {
  event: FireableUpdateEventListener<AsyncSourceSpanModel>;
  loading: boolean;
  text: any;
  dataSource: () => any;
  dataDecorator?: (data: any) => any;

  constructor (dataSource: () => any, dataDecorator?: (data: any) => any) {
    this.event = new FireableUpdateEventListener<AsyncSourceSpanModel>();
    this.loading = true;
    this.dataSource = dataSource;
    this.dataDecorator = dataDecorator;
  }

  async getText () {
    this.updateState(true);
    const data = await this.dataSource();
    this.text = this.dataDecorator ? this.dataDecorator(data) : data;
    this.updateState(false);
  }

  get state (): AsyncSourceSpanState {
    return {
      loading: this.loading,
      text: this.text
    };
  }

  updateState (loading: boolean) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}

import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { DateRangePicker, DateRange } from './DateRangePicker';
import i18n from 'i18n';

const getDateRangeShortCut = (startDate, minDate, maxDate): DateRange[] => {
  const dateGetter = _.curry(getDate)(minDate)(maxDate);
  const startDateMoment = moment(startDate, 'YYYY-MM-DD_HH:mm:ss');

  return [
    {
      label: i18n.t('daypick.labels.add1Day'),
      dateRange: [dateGetter(startDateMoment.clone(), true), dateGetter(startDateMoment.clone().add(1, 'day').endOf('day'), false)]
    },
    {
      label: i18n.t('daypick.labels.add7Days'),
      dateRange: [dateGetter(startDateMoment.clone(), true), dateGetter(startDateMoment.clone().add(7, 'day').endOf('day'), false)]
    },
    {
      label: i18n.t('daypick.labels.add1Month'),
      dateRange: [dateGetter(startDateMoment.clone(), true), dateGetter(startDateMoment.clone().add(1, 'month').endOf('day'), false)]
    },
    {
      label: i18n.t('daypick.labels.thisWeek'),
      dateRange: [dateGetter(moment().startOf('week').startOf('day'), true), dateGetter(moment().endOf('week').endOf('day'), false)]
    },
    {
      label: i18n.t('daypick.labels.thisMonth'),
      dateRange: [dateGetter(moment().startOf('month').startOf('day'), true), dateGetter(moment().endOf('month').endOf('day'), false)]
    },
    {
      label: i18n.t('daypick.labels.endOfNextMonth'),
      dateRange: [dateGetter(startDateMoment.clone(), true), dateGetter(moment().add(1, 'month').endOf('month'), false)]
    }
  ];
};

const getDate = (min: string, max: string, dateMoment: moment.Moment, isStart: boolean) => {
  const dateFormat = 'YYYY-MM-DD HH:mm:ss';
  const minDate = !!min && moment(min, dateFormat);
  const maxDate = !!max && moment(max, dateFormat);
  if (minDate && dateMoment.isBefore(minDate)) {
    return isStart ? new Date(minDate.startOf('day').format(dateFormat)) : new Date(minDate.endOf('day').format(dateFormat));
  }
  if (maxDate && dateMoment.isAfter(maxDate)) {
    return isStart ? new Date(maxDate.startOf('day').format(dateFormat)) : new Date(maxDate.endOf('day').format(dateFormat));
  }
  return new Date(dateMoment.format(dateFormat));
};

const FutureDateRangePicker = (props) => {
  return (
    <DateRangePicker {...props} hasError={props.hasError} shortcuts={getDateRangeShortCut(props.startDate, props.minDate, props.maxDate)}/>
  );
};
export default FutureDateRangePicker;

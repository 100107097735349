import React from 'react';

import i18n from 'i18n';

import './AccountSummary.scss';

import { AccountSummaryProps } from './AccountSummaryModel';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { hasRoles, isProject } from 'core/permission/PermissionDSL';
import { RoleNames } from 'core';
import { Project } from 'core/project/Project';

export class AccountSummary extends React.Component<AccountSummaryProps> {
  render () {
    const model = this.props.model;
    return (
      <div className='account-summary'>
        <div className='summary-row'>
          <div className='summary-column'>
            <div className='summary-label'>{i18n.t(model.emailLabel)}</div>
            <div className='summary-value'>{model.email}</div>
          </div>
          <div className='summary-column'>
            <div className='summary-label'>{i18n.t(model.statusLabel)}</div>
            <div className='summary-value'>{i18n.t(model.status)}</div>
          </div>
          <PermissionChecker permissionAware={hasRoles(RoleNames.sysAdmin)}>
            <div className='summary-column'>
              <div className='summary-label'>{i18n.t(model.isAdminLabel)}</div>
              <div className='summary-value'>{i18n.t(model.isAdmin)}</div>
            </div>
          </PermissionChecker>
          <PermissionChecker permissionAware={isProject(Project.GOGAN).and(hasRoles(RoleNames.sysAdmin).or(hasRoles(RoleNames.goJekAccountManager)))}>
            <div className='summary-column'>
              <div className='summary-label'>{i18n.t(model.isGojekAccountManagerLabel)}</div>
              <div className='summary-value'>{i18n.t(model.isGojekAccountManager)}</div>
            </div>
          </PermissionChecker>
          <div className='summary-column'>
            <div className='summary-label'>{i18n.t('accounts.summary.labels.isPending')}</div>
            <div className='summary-value'>{model.isPending.toString()}</div>
          </div>
        </div>
      </div>
    );
  }
}

import React, { useMemo } from 'react';
import { StateModal } from 'containers/Common/StateModal';
import { DefaultStateModalModel } from 'containers/Common/StateModalModel';
import { DraftCreateModalStateFactory } from './DraftCreateModalStateFactory';
import { Order } from 'core/order/Order';
import { CampaignGroup } from 'core/campaignGroup/CampaignGroup';

export const DraftCreateModal: React.FC<{
  order: Order,
  campaignGroup: CampaignGroup,
  goCampaignsToCreateDraft: {
    id: number | string,
    isDraft: boolean
  }[],
  onClose: (dirty: boolean) => void
}> = ({
  order,
  campaignGroup,
  goCampaignsToCreateDraft,
  onClose
}) => {

  const draftCreateModalModel = useMemo(() => {
    const factoryModel = new DraftCreateModalStateFactory(
      campaignGroup,
      goCampaignsToCreateDraft
    );
    return new DefaultStateModalModel(
      factoryModel,
      onClose,
      {
        order,
        campaignGroup,
        goCampaignsToCreateDraft,
        targets: [{
          id: 0,
          numberOfCopies: 1,
          includeBinding: true
        }]
      }
    );
  }, [
    onClose,
    order,
    campaignGroup,
    goCampaignsToCreateDraft
  ]);

  return (
    <StateModal
      model={draftCreateModalModel}
    />
  );
};

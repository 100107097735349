import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import i18n from 'i18next';
import { CreativeSetupTab } from './CreativeSetupStepModel';
import _ from 'lodash';
import { SummaryTitleColor } from 'components/SummaryDetail/SummaryDetail';
import { SelectOptions } from 'components/common/commonType';
import DefaultCreativeManager, { CreativeManager } from 'core/creative/CreativeManager';
import { toast } from 'react-toastify';
import { getLimitationContentLabel, getLimitationTitleOfType, toServerStructure } from 'utils/LimitationUtil';
import { CreativeFormData } from './SubSteps/FormContent/FormContentModel';
import { CreativeSummaryModel } from './SubSteps/SummaryContent/CreativeSummaryModel';
import { CreativeSetupFlowDataContextType } from '../CreativeSetupFlowDataContext';
import { CreativeType } from 'core/creative/Creative';
import { CampaignGroupChannel } from 'core/campaignGroup/CampaignGroup';

export enum CreateCreativeSetupFlowStep {
  CHOOSE_ADVERTISER,
  SETUP_CREATIVE,
  SUMMARY
}

export enum EditCreativeSetupFlowStep {
  SETUP_CREATIVE,
  SUMMARY
}

export type CreativeSummaryStepState = {
  readonly loading: boolean;
  readonly basicSummaryData: any;
};
export interface CreativeSummaryStepModel {
  readonly state: CreativeSummaryStepState;
  readonly event: UpdateEventListener<CreativeSummaryStepModel>;
  readonly mediaSummaryComponent?: any;
  readonly mediaSummaryData?: any;
  getAdvertiserSummaryData: (advertisers: Array<SelectOptions>, advertiserId: number) => any;
  getLimitationsSummaryData: () => any;
  goLast: () => void;
  goStep: (stepIndex: number, subStepIndex: number) => void;
  submit: (event, callback?: () => void) => void;
  initContextData (contextData: CreativeSetupFlowDataContextType);
  initSummaryData (): Promise<any>;
  setBasicSummaryData (basicSummaryData);
}

export type CreativeSummaryStepProps = {
  readonly model: CreativeSummaryStepModel;
};

abstract class DefaultCreativeSummaryStepModel implements CreativeSummaryStepModel {
  event: FireableUpdateEventListener<CreativeSummaryStepModel>;
  loading: boolean;
  finishedCallback?: (redirectData: {
    pathname: string,
    search?: string,
    state?: any
  }) => void;
  creativeFormData?: CreativeFormData;
  summaryContentModel?: CreativeSummaryModel;
  basicSummaryData?: any;
  mediaSummaryComponent?: any;
  mediaSummaryData?: any;

  constructor (
    protected type: string,
    private canChooseAdvertiser: boolean,
    public goLast: () => void,
    public goStep: (stepIndex: number, subStepIndex: number) => void,
    protected getUploadedFileData: (file: File) => Promise<any>,
    protected addUploadedFilesData: (file: File, data: any) => Promise<void>,
    protected creativeManager: CreativeManager = new DefaultCreativeManager(),
    protected campaignId?: string,
    protected orderNumber?: string,
    protected campaignGroupId?: string | null,
    protected draftId?: string | null
  ) {
    this.event = new FireableUpdateEventListener<CreativeSummaryStepModel>();
    this.loading = false;
  }

  get state (): CreativeSummaryStepState {
    return {
      loading: this.loading,
      basicSummaryData: this.basicSummaryData
    };
  }

  get creativeSetupStepIndex () {
    if (this.type === 'create' && this.canChooseAdvertiser) {
      return CreateCreativeSetupFlowStep.SETUP_CREATIVE;
    } else {
      return EditCreativeSetupFlowStep.SETUP_CREATIVE;
    }
  }

  get creativeManagementSearchParams () {
    const campaignParam = this.draftId ? `draftIds=${this.draftId}` : `campaignIds=${this.campaignId}`;
    return `?${campaignParam}&action=manage`;
  }

  initContextData (contextData: CreativeSetupFlowDataContextType) {
    if (contextData.creative !== this.creativeFormData) {
      this.summaryContentModel = contextData.getSummaryModel(contextData.creative.basic);
      this.creativeFormData = contextData.creative;
      this.finishedCallback = contextData.setFinishedRedirectData;
    }
  }

  async initSummaryData () {
    if (this.summaryContentModel) {
      this.updateState(true);
      try {
        const summaryData = await this.summaryContentModel.getCreativeBasicSummaryData(this.getUploadedFileData, this.addUploadedFilesData);
        this.basicSummaryData = {
          title: i18n.t('stepSideBar.labels.creativeBasic'),
          backStep: this.creativeSetupStepIndex,
          backSubStep: CreativeSetupTab.BASIC,
          data: {
            general: {
              title: i18n.t('summary.titles.generalInfo'),
              content: summaryData
            }
          }
        };
        this.mediaSummaryComponent = this.getMediaSummaryComponent();
        this.mediaSummaryData = this.getCreativeMediaSummaryData();
      } catch (e) {}
      this.updateState(false);
    }
  }

  updateState (loading: boolean) {
    this.loading = loading;
    this.event.fireEvent(this);
  }

  getAdvertiserSummaryData (advertisers, advertiserId) {
    const advertiser = _.find(advertisers, advertiser => advertiser.value === advertiserId);
    return {
      title: i18n.t('stepSideBar.labels.creativeChooseAdvertiser'),
      data: {
        advertiser: {
          title: undefined,
          content: [
            {
              label: i18n.t('creativeSetupFlow.labels.advertiser'),
              value: advertiser ? advertiser.label : ''
            }
          ]
        }
      }
    };
  }

  // getBasicSetupSummaryData () {
  //   const basicSetupSummary = this.summaryContentModel ? this.summaryContentModel.getCreativeBasicSummaryData() : {};
  //   return {
  //     title: i18n.t('stepSideBar.labels.creativeBasic'),
  //     backStep: this.creativeSetupStepIndex,
  //     backSubStep: CreativeSetupTab.BASIC,
  //     data: {
  //       general: {
  //         title: i18n.t('summary.titles.generalInfo'),
  //         content: basicSetupSummary
  //       }
  //     }
  //   };
  // }

  getMediaSummaryComponent () {
    return this.summaryContentModel ? this.summaryContentModel.getMediaSummaryComponent() : undefined;
  }

  getLimitationContent (limitationData) {
    if (!limitationData) {
      return [];
    }

    return _.compact(limitationData.map((data) => {
      if (data.type === 'unicornlanguage' || data.type === 'instl') {
        return undefined;
      }

      if (!data.value || data.value.length === 0) {
        return undefined;
      }

      return {
        label: i18n.t(`limitation.labels.${data.type}`),
        value: data.value.map((value) => getLimitationContentLabel(data.type, value)).join(', ')
      };
    }));
  }

  getLimitationsSummaryData () {
    if (!this.creativeFormData) {
      return;
    }
    const limitations = this.creativeFormData.limitations;
    if (!limitations) {
      return;
    }

    const excludeInstlLimitation = limitations.other ? limitations.other.find(limitation => limitation.type === 'instl') : undefined;
    const excludeInstlLimitationContent = {
      label: getLimitationTitleOfType('instl'),
      value: excludeInstlLimitation ? i18n.t('common.labels.no') : i18n.t('common.labels.yes')
    };

    return {
      title: i18n.t('stepSideBar.labels.creativeLimitation'),
      backStep: this.creativeSetupStepIndex,
      backSubStep: CreativeSetupTab.LIMITATION,
      data: {
        include: {
          title: i18n.t('campaignSummary.titles.inc'),
          titlePrefixColor: SummaryTitleColor.GREEN,
          content: this.getLimitationContent(limitations.include)
        },
        preferred: {
          title: i18n.t('campaignSummary.titles.preferred'),
          titlePrefixColor: SummaryTitleColor.BLUE,
          content: this.getLimitationContent(limitations.preferred)
        },
        nonPreferred: {
          title: i18n.t('campaignSummary.titles.nonPreferred'),
          titlePrefixColor: SummaryTitleColor.YELLOW,
          content: this.getLimitationContent(limitations.nonPreferred)
        },
        exclude: {
          title: i18n.t('campaignSummary.titles.exc'),
          titlePrefixColor: SummaryTitleColor.RED,
          content: this.getLimitationContent(limitations.exclude)
        },
        other: {
          title: i18n.t('campaignSummary.titles.other'),
          content: [...this.getLimitationContent(limitations.other), excludeInstlLimitationContent]
        }
      }
    };
  }

  getFileSummaryData (image) {
    const file = _.get(image, 'file');
    const url = _.get(image, 'url');
    if (file || url) {
      return {
        file,
        url
      };
    }
  }

  getCreativeMediaSummaryData () {
    const mediaData = this.summaryContentModel ? this.summaryContentModel.getMediaSummary() : {};
    if (_.isEmpty(mediaData)) {
      return undefined;
    }
    return {
      title: i18n.t('creativeSetupFlow.labels.creativeBinding'),
      backStep: this.creativeSetupStepIndex,
      backSubStep: CreativeSetupTab.BASIC,
      goStep: this.goStep,
      data: mediaData
    };
  }

  getDealIds (limitations) {
    const otherLimitations = _.get(limitations, 'other', []);
    const dealIdLimitation = otherLimitations.find(limitation => limitation.type === 'dealId');
    if (!dealIdLimitation || dealIdLimitation.value.length === 0) {
      return undefined;
    }

    return dealIdLimitation.value.map(selectOption => selectOption.value);
  }

  getPPSLayoutId (limitations) {
    const includeLimitations = _.get(limitations, 'include', []);
    const layoutIdLimitation = includeLimitations.find(limitation => limitation.type === 'ppsLayoutId');
    if (!layoutIdLimitation || layoutIdLimitation.value.length === 0) {
      return undefined;
    }

    return layoutIdLimitation.value.map(selectOption => selectOption.value);
  }

  async getFormDataToSubmit () {
    if (!this.summaryContentModel || !this.creativeFormData) {
      return;
    }
    const formData = await this.summaryContentModel.getBasicSubmitData();
    if (!this.creativeFormData.limitations || _.isEmpty(this.creativeFormData.limitations)) {
      return formData;
    }
    if (this.creativeFormData.basic.creativeType === CreativeType.IMAGE && this.type === 'create') {
      const dealIds = this.getDealIds(this.creativeFormData.limitations);
      const limitations = toServerStructure(this.creativeFormData.limitations);
      formData.forEach(data => {
        _.set(data.creative, 'dealIds', dealIds);
        data.limitation = limitations;
      });
      return formData;
    }
    const dealIds = this.getDealIds(this.creativeFormData.limitations);
    dealIds && dealIds.forEach((dealId, index) => {
      formData.append(`creative.dealIds[${index}]`, dealId);
    });
    if (this.creativeFormData.basic.creativeType === CreativeType.THIRDPARTY) {
      const ppsLayoutIds = this.getPPSLayoutId(this.creativeFormData.limitations);
      ppsLayoutIds && ppsLayoutIds.forEach((ppsLayoutId, index) => {
        formData.append(`creative.ppsLayoutId[${index}]`, `pps_${ppsLayoutId}`);
      });
    }
    const limitations = toServerStructure(this.creativeFormData.limitations);
    limitations.forEach((limitation, index) => {
      formData.append(`limitations[${index}].op`, limitation.op);
      formData.append(`limitations[${index}].isGroup`, limitation.isGroup.toString());
      formData.append(`limitations[${index}].type`, limitation.type);
      limitation.limits.forEach((limit, limitIndex) => {
        formData.append(`limitations[${index}].limits[${limitIndex}].label`, limit.label);
        formData.append(`limitations[${index}].limits[${limitIndex}].value`, limit.value);
      });
    });
    return formData;
  }

  setBasicSummaryData (basicSummaryData) {
    this.basicSummaryData = basicSummaryData;
    this.updateState(false);
  }

  abstract submitMethod (submitData): Promise<void>;

  submit = async (evt, callback) => {
    this.updateState(true);
    _.defer(async () => {
      try {
        const submitData = await this.getFormDataToSubmit();
        if (!submitData) {
          this.updateState(false);
          return;
        }
        await this.submitMethod(submitData);
        this.updateState(false);
      } catch (e) {
        (e instanceof Error) && toast.error(e.message);
        this.updateState(false);
      }
      callback && callback();
    });
  }
}

export class CreateCreativeSummaryStepModel extends DefaultCreativeSummaryStepModel {
  async submitMethod (submitData) {
    if (this.creativeFormData && this.creativeFormData.basic.creativeType === CreativeType.IMAGE) {
      await this.createCreativeByJson(submitData);
    } else {
      await this.createCreative(submitData);
    }
  }

  createCreative = async (creative: FormData) => {
    await this.creativeManager.createCreative(creative);
    toast.success(i18n.t('creativeSetupFlow.labels.createSuccess'));
    this.finishedCallback && this.finishedCallback({
      pathname: '/creatives'
    });
  }

  createCreativeByJson = async (creativeJson: any) => {
    const data = await this.creativeManager.createCreativesByJson(creativeJson);
    toast.success(i18n.t('creativeSetupFlow.labels.createSuccess'));
    this.finishedCallback && this.finishedCallback({
      pathname: '/creatives',
      state: {
        errors: _.get(data, 'error')
      }
    });
  }
}

export class EditCreativeSummaryStepModel extends DefaultCreativeSummaryStepModel {
  async submitMethod (submitData) {
    await this.creativeManager.updateCreative(submitData);
    toast.success(i18n.t('creativeSetupFlow.labels.updateSuccess'));
    if (_.isNil(this.orderNumber) || (_.isNil(this.campaignId) && _.isNil(this.draftId))) {
      this.finishedCallback && this.finishedCallback({
        pathname: '/creatives'
      });
    } else {
      this.finishedCallback && this.finishedCallback({
        pathname: `/orders/${this.orderNumber}/campaign-groups/${this.campaignGroupId}`,
        search: this.creativeManagementSearchParams
      });
    }
  }
}

abstract class CreateCreativeAndBindSummaryStepModel extends DefaultCreativeSummaryStepModel {

  abstract get channel ();

  async submitMethod (submitData) {
    const isDraft = !!this.draftId;
    if (this.creativeFormData && this.creativeFormData.basic.creativeType === CreativeType.IMAGE) {
      await this.creativeManager.createCreativesByJsonAndBind(
        this.channel,
        submitData,
        isDraft ? this.draftId! : this.campaignId!,
        isDraft
      );
    } else {
      await this.creativeManager.createCreativeAndBind(
        this.channel,
        submitData,
        isDraft ? this.draftId! : this.campaignId!,
        isDraft
      );
    }
    toast.success(i18n.t('creativeSetupFlow.labels.createSuccess'));
    this.finishedCallback && this.finishedCallback({
      pathname: `/orders/${this.orderNumber}/campaign-groups/${this.campaignGroupId}`,
      search: this.creativeManagementSearchParams
    });
  }
}

export class CreateCreativeAndBindRTBCampaignSummaryStepModel extends CreateCreativeAndBindSummaryStepModel {
  get channel () {
    return CampaignGroupChannel.RTB;
  }
}

export class CreateCreativeAndBindAdSetSummaryStepModel extends CreateCreativeAndBindSummaryStepModel {
  get channel () {
    return CampaignGroupChannel.FB;
  }
}

export class CreateCreativeAndBindAdGroupSummaryStepModel extends CreateCreativeAndBindSummaryStepModel {
  get channel () {
    return CampaignGroupChannel.TIKTOK;
  }
}

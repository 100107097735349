import { ColumnDefinition, renderColumn, sortableColumn } from 'components/TableColumn';
import { useCallback, useEffect, useMemo, useState } from 'react';
import formatters from './listFormatters';
import styles from './campaignGroupList.module.scss';
import { formatPrice, getPriceValue } from 'helper/CurrencyHelper';
import { useRouteMatch } from 'react-router-dom';
import i18n from 'i18n';
import _ from 'lodash';
import DefaultCampaignGroupManager, { CampaignGroupManager } from 'core/campaignGroup/CampaignGroupManager';
import { numberWithCommas } from 'utils/StringUtil';
import { AddonFeatureManager } from 'core';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { Order, State } from 'core/order/Order';
import { AdvertiserManager, DefaultAdvertiserManager } from 'core/advertiser/AdvertiserManager';
import moment from 'moment';
import { DefaultReportManager, ReportManager } from 'core/report/ReportManager';
import { useCallAPI } from 'hooks/useCallAPI';
import { getCpa, getCpc, getCtr, getCvr, getVctr } from 'helper/MetricsHelper';
import { Project } from 'core/project/Project';

export enum CampaignGroupListColumns {
  ID = 'groupId',
  BUDGET = 'budget',
  CHANNEL = 'channel',
  AUTO_OPTIMISE = 'autoOptimise',
  STATE = 'state',
  DELIVERY = 'delivery',
  RESULTS = 'results',
  IMPRES = 'impres',
  VIEWABLE = 'viewable',
  CLICKS = 'clicks',
  CPC = 'cpc',
  CTR = 'ctr',
  VCTR = 'vctr',
  CONVERT = 'convs',
  CPA = 'cpa',
  CVR = 'cvr',
  UU = 'uu',
  SPENT = 'spent',
  APP_CUSTOM_EVENT = 'app_custom_event',
  APP_CUSTOM_EVENT_FB_MOBILE_ACTIVATE_APP = 'app_custom_event_fb_mobile_activate_app',
  APP_CUSTOM_EVENT_FB_MOBILE_ADD_PAYMENT_INFO = 'app_custom_event_fb_mobile_add_payment_info',
  APP_CUSTOM_EVENT_FB_MOBILE_ADD_TO_CART = 'app_custom_event_fb_mobile_add_to_cart',
  APP_CUSTOM_EVENT_FB_MOBILE_ADD_TO_WISHLIST = 'app_custom_event_fb_mobile_add_to_wishlist',
  APP_CUSTOM_EVENT_FB_MOBILE_COMPLETE_REGISTRATION = 'app_custom_event_fb_mobile_complete_registration',
  APP_CUSTOM_EVENT_FB_MOBILE_CONTENT_VIEW = 'app_custom_event_fb_mobile_content_view',
  APP_CUSTOM_EVENT_FB_MOBILE_INITIATED_CHECKOUT = 'app_custom_event_fb_mobile_initiated_checkout',
  APP_CUSTOM_EVENT_FB_MOBILE_PURCHASE = 'app_custom_event_fb_mobile_purchase',
  APP_CUSTOM_EVENT_FB_MOBILE_SEARCH = 'app_custom_event_fb_mobile_search',
  APP_CUSTOM_EVENT_OTHER = 'app_custom_event_other',
  COMMENT = 'comment',
  LIKE = 'like',
  LINK_CLICK = 'link_click',
  POST = 'post',
  POST_ENGAGEMENT = 'post_engagement',
  POST_REACTION = 'post_reaction',
  TIKTOK_VIDEO_PLAY_ACTIONS = 'videoPlayActions',
  TIKTOK_VIDEO_WATCHED_2S = 'videoWatched2s',
  TIKTOK_VIDEO_WATCHED_6S = 'videoWatched6s',
  TIKTOK_VIDEO_VIEWS_P25 = 'videoViewsP25',
  TIKTOK_VIDEO_VIEWS_P50 = 'videoViewsP50',
  TIKTOK_VIDEO_VIEWS_P75 = 'videoViewsP75',
  TIKTOK_VIDEO_VIEWS_P100 = 'videoViewsP100',
  TIKTOK_PROFILE_VISITS = 'profileVisits',
  TIKTOK_LIKES = 'likes', // Paid Likes
  TIKTOK_COMMENTS = 'comments', // Paid Comments
  TIKTOK_SHARES = 'shares', // Paid Shares
  TIKTOK_FOLLOWS = 'follows', // Paid Followers
  TIKTOK_CLICKS_ON_MUSIC_DISC = 'clicksOnMusicDisc',
  EDITBTNS = 'editBtns'
}

export enum ListChannel {
  ALL = 'ALL',
  FB = 'FB',
  RTB = 'RTB',
  TIKTOK = 'TIKTOK',
  RETAIL_MEDIA = 'RETAIL_MEDIA'
}

export enum CampaignGroupListType {
  BASIC = 'basic',
  PERFORMANCE = 'performance',
  FB_ONSITE_ACTIONS = 'fb_onsite_actions',
  APP_ACTIONS = 'app_actions',
  TIKTOK_VIDEO_PLAY = 'tiktok_video_play',
  TIKTOK_ENGAGEMENT = 'tiktok_engagement'
}

export type CampaignGroupListState = {
  readonly searchString: string;
  readonly filteredList: any[];
  readonly deleteGroup?: () => void;
  readonly selectedMetricsGroup: CampaignGroupListType;
  readonly summaryData: any;
  readonly selectedChannel: ListChannel;
};

const defaultCampaignGroupManager: CampaignGroupManager = new DefaultCampaignGroupManager();
const defaultReportManager: ReportManager = new DefaultReportManager();

const channelMetricsGroupMap = {
  [ListChannel.ALL]: [
    CampaignGroupListType.BASIC,
    CampaignGroupListType.PERFORMANCE
  ],
  [ListChannel.FB]: [
    CampaignGroupListType.BASIC,
    CampaignGroupListType.PERFORMANCE,
    CampaignGroupListType.FB_ONSITE_ACTIONS,
    CampaignGroupListType.APP_ACTIONS
  ],
  [ListChannel.RTB]: [
    CampaignGroupListType.BASIC,
    CampaignGroupListType.PERFORMANCE
  ],
  [ListChannel.TIKTOK]: [
    CampaignGroupListType.BASIC,
    CampaignGroupListType.PERFORMANCE,
    CampaignGroupListType.TIKTOK_VIDEO_PLAY,
    CampaignGroupListType.TIKTOK_ENGAGEMENT
  ],
  [ListChannel.RETAIL_MEDIA]: [
    CampaignGroupListType.BASIC,
    CampaignGroupListType.PERFORMANCE
  ]
};

const defaultAdvertiserManager: AdvertiserManager = new DefaultAdvertiserManager();

const useDefaultCampaignGroupListModel = (
  order: Order,
  campaignGroupList: any[],
  refreshList: () => void,
  addonFeatureManager: AddonFeatureManager,
  campaignGroupManager: CampaignGroupManager = defaultCampaignGroupManager,
  advertiserManager: AdvertiserManager = defaultAdvertiserManager,
  reportManager: ReportManager = defaultReportManager
) => {

  const [state, setState] = useState({
    searchString: '',
    filteredList: [],
    selectedMetricsGroup: CampaignGroupListType.BASIC,
    selectedChannel: ListChannel.ALL,
    summaryData: undefined
  } as CampaignGroupListState);

  const { loading, callAPIs } = useCallAPI();

  const campaignGroupWithPerformanceList = useMemo(() => {
    return campaignGroupList.map(campaignGroup => {
      const report = campaignGroup.report;
      const budget = campaignGroup.budget === undefined || campaignGroup.budget === null ? -1 : campaignGroup.budget;
      const conversion = campaignGroup.conversion ?
        _.mapValues(
          _.keyBy(campaignGroup.conversion.map(data => ({ ...data, action_type: data.action_type.replace('.', '_') })), 'action_type'),
          'actions'
        ) :
        {};
      const impres = _.get(report, 'impres', _.get(report, 'impressions'));
      const spent = _.get(report, 'spent', _.get(report, 'spend', 0));
      return report ? {
        ..._.omit(campaignGroup, ['report', 'conversion']),
        ...report,
        ...conversion,
        budget,
        [CampaignGroupListColumns.IMPRES]: impres,
        [CampaignGroupListColumns.CPA]: getPriceValue(order.currency, getCpa(spent, report.convs)),
        [CampaignGroupListColumns.CPC]: getPriceValue(order.currency, getCpc(spent, report.clicks)),
        [CampaignGroupListColumns.CTR]: getCtr(impres, report.clicks),
        [CampaignGroupListColumns.CVR]: getCvr(report.convs, report.clicks),
        [CampaignGroupListColumns.VCTR]: getVctr(report.viewable, report.clicks),
        [CampaignGroupListColumns.SPENT]: spent,
        [CampaignGroupListColumns.UU]: _.get(report, 'uu', _.get(report, 'lifetimeReach', 0))
      } : {
        ...campaignGroup,
        ...conversion,
        budget
      };
    });
  }, [order.currency, campaignGroupList]);

  const match = useRouteMatch();
  const [selectedCampaignGroups, setSelectedCampaignGroups] = useState<(number | string)[]>([]);
  const [advertiserName, setAdvertiserName] = useState('');

  const getAdvertiserOptions = useCallback(async () => {
    callAPIs([
      advertiserManager.getAdvertiserOptions.bind(advertiserManager, order.agencyId)
    ], options => {
      const advOption = options.find(option => option.value === order.advertiserId);
      setAdvertiserName(advOption ? advOption.label : '');
    });
  }, [order.agencyId, order.advertiserId, advertiserManager, callAPIs]);

  useEffect(() => {
    getAdvertiserOptions();
  }, [getAdvertiserOptions]);

  useEffect(() => {
    const filteredList = campaignGroupWithPerformanceList.filter(campaignGroup => {
      const nameInclude = campaignGroup.name.toLowerCase().includes(state.searchString.toLowerCase());
      const idInclude = campaignGroup.groupId.toString().includes(state.searchString);
      const channelCorrect = state.selectedChannel === ListChannel.ALL || campaignGroup.channel === state.selectedChannel;
      return channelCorrect && (nameInclude || idInclude);
    });
    const metricsAdder = (acc, value) => {
      if (!value) {
        return acc;
      }
      return acc + value;
    };
    const viewableSum = filteredList.reduce<number>((partial, campaignGroup) => metricsAdder(partial, campaignGroup.viewable), 0);
    const clickSum = filteredList.reduce<number>((partial, campaignGroup) => metricsAdder(partial, campaignGroup.clicks), 0);
    const convsSum = filteredList.reduce<number>((partial, campaignGroup) => metricsAdder(partial, campaignGroup.convs), 0);
    const spentSum = filteredList.reduce<number>((partial, campaignGroup) => metricsAdder(partial, getPriceValue(order.currency, campaignGroup.spent)), 0);
    const impresSum = filteredList.reduce<number>((partial, campaignGroup) => metricsAdder(partial, campaignGroup.impres), 0);
    const otherColumns = [
      CampaignGroupListColumns.APP_CUSTOM_EVENT,
      CampaignGroupListColumns.APP_CUSTOM_EVENT_FB_MOBILE_ACTIVATE_APP,
      CampaignGroupListColumns.APP_CUSTOM_EVENT_FB_MOBILE_ADD_PAYMENT_INFO,
      CampaignGroupListColumns.APP_CUSTOM_EVENT_FB_MOBILE_ADD_TO_CART,
      CampaignGroupListColumns.APP_CUSTOM_EVENT_FB_MOBILE_ADD_TO_WISHLIST,
      CampaignGroupListColumns.APP_CUSTOM_EVENT_FB_MOBILE_COMPLETE_REGISTRATION,
      CampaignGroupListColumns.APP_CUSTOM_EVENT_FB_MOBILE_CONTENT_VIEW,
      CampaignGroupListColumns.APP_CUSTOM_EVENT_FB_MOBILE_INITIATED_CHECKOUT,
      CampaignGroupListColumns.APP_CUSTOM_EVENT_FB_MOBILE_PURCHASE,
      CampaignGroupListColumns.APP_CUSTOM_EVENT_FB_MOBILE_SEARCH,
      CampaignGroupListColumns.APP_CUSTOM_EVENT_OTHER,
      CampaignGroupListColumns.COMMENT,
      CampaignGroupListColumns.LIKE,
      CampaignGroupListColumns.LINK_CLICK,
      CampaignGroupListColumns.POST,
      CampaignGroupListColumns.POST_ENGAGEMENT,
      CampaignGroupListColumns.POST_REACTION,
      CampaignGroupListColumns.TIKTOK_VIDEO_PLAY_ACTIONS,
      CampaignGroupListColumns.TIKTOK_VIDEO_WATCHED_2S,
      CampaignGroupListColumns.TIKTOK_VIDEO_WATCHED_6S,
      CampaignGroupListColumns.TIKTOK_VIDEO_VIEWS_P25,
      CampaignGroupListColumns.TIKTOK_VIDEO_VIEWS_P50,
      CampaignGroupListColumns.TIKTOK_VIDEO_VIEWS_P75,
      CampaignGroupListColumns.TIKTOK_VIDEO_VIEWS_P100,
      CampaignGroupListColumns.TIKTOK_PROFILE_VISITS,
      CampaignGroupListColumns.TIKTOK_LIKES,
      CampaignGroupListColumns.TIKTOK_COMMENTS,
      CampaignGroupListColumns.TIKTOK_SHARES,
      CampaignGroupListColumns.TIKTOK_FOLLOWS,
      CampaignGroupListColumns.TIKTOK_CLICKS_ON_MUSIC_DISC
    ];
    const otherSummaryData = otherColumns.reduce((acc, column) => {
      const sum = filteredList.reduce<number>((partial, campaignGroup) => metricsAdder(partial, +campaignGroup[column]), 0);
      acc[column] = numberWithCommas(sum);
      return acc;
    }, {});
    const summaryData = {
      [CampaignGroupListColumns.ID]: i18n.t('campaignGroupList.labels.campaignGroupCount', { count: filteredList.length }),
      [CampaignGroupListColumns.IMPRES]: numberWithCommas(impresSum),
      [CampaignGroupListColumns.VIEWABLE]: numberWithCommas(viewableSum),
      [CampaignGroupListColumns.CLICKS]: numberWithCommas(clickSum),
      [CampaignGroupListColumns.CONVERT]: numberWithCommas(convsSum),
      [CampaignGroupListColumns.CPC]: formatPrice(order.currency, clickSum === 0 ? 0 : spentSum / clickSum),
      [CampaignGroupListColumns.CTR]: `${impresSum === 0 ? 0.00.toFixed(2) : ((clickSum / impresSum) * 100).toFixed(2)}%`,
      [CampaignGroupListColumns.VCTR]: `${viewableSum === 0 ? 0.00.toFixed(2) : ((clickSum / viewableSum) * 100).toFixed(2)}%`,
      [CampaignGroupListColumns.CPA]: formatPrice(order.currency, convsSum === 0 ? 0 : spentSum / convsSum),
      [CampaignGroupListColumns.CVR]: `${clickSum === 0 ? 0.00.toFixed(2) : ((convsSum / clickSum) * 100).toFixed(2)}%`,
      [CampaignGroupListColumns.SPENT]: numberWithCommas(spentSum),
      ...otherSummaryData
    };
    setState(prevState => ({ ...prevState, filteredList, summaryData }));
  }, [state.selectedChannel, state.searchString, campaignGroupWithPerformanceList, order.currency]);

  const onDeleteBtnClick = (groupToDelete: number) => setState({ ...state, deleteGroup: _.partial(deleteCampaignGroup, groupToDelete) });

  const onDeleteModalClose = useCallback((refresh: boolean) => {
    setState(prevState => ({ ...prevState, deleteGroup: undefined }));
    refresh && refreshList();
  }, [refreshList]);

  const deleteCampaignGroup = useCallback(async (groupId: number) => {
    callAPIs([
      campaignGroupManager.deleteCampaignGroup.bind(campaignGroupManager, groupId)
    ], () => {
      onDeleteModalClose(true);
    });
  }, [campaignGroupManager, onDeleteModalClose, callAPIs]);

  const onHandleSearch = (searchString: string) => setState({ ...state, searchString });

  const columnDefinition = (columnName, customLabel?: string): ColumnDefinition => ({
    ...sortableColumn(columnName, customLabel ? customLabel : `campaignGroup.labels.${columnName}`, true),
    classes: () => styles[columnName],
    headerClasses: () => styles[columnName]
  });

  const onSelect = (campaignGroupId: number | string) => {
    if (selectedCampaignGroups.indexOf(campaignGroupId.toString()) > -1) {
      setSelectedCampaignGroups(selectedCampaignGroups.filter(id => id !== campaignGroupId));
    } else {
      setSelectedCampaignGroups([...selectedCampaignGroups, campaignGroupId.toString()]);
    }
  };

  const onSelectAll = () => {
    if (selectedCampaignGroups.length === state.filteredList.length) {
      setSelectedCampaignGroups([]);
    } else {
      setSelectedCampaignGroups(state.filteredList.map(campaignGroup => campaignGroup.groupId.toString()));
    }
  };

  const handleRemoveSelect = () => {
    setSelectedCampaignGroups([]);
  };

  const onMetricsGroupChange = (selectedMetricsGroup) => {
    setState(prevState => ({ ...prevState, selectedMetricsGroup }));
  };

  const onListChannelChange = (listChannel) => {
    setSelectedCampaignGroups([]);
    setState(prevState => ({ ...prevState, selectedChannel: listChannel }));
  };

  const idColumn = renderColumn({
    ...columnDefinition(CampaignGroupListColumns.ID),
    formatExtraData: {
      currentUrl: match.url,
      selectedCampaignGroups,
      onSelect
    }
  },
  formatters.nameFormatter,
  _.partial(
    formatters.nameHeaderFormatter,
    state.filteredList.length,
    selectedCampaignGroups,
    onSelectAll
  ));

  const budgetColumn = renderColumn(
    columnDefinition(
      CampaignGroupListColumns.BUDGET,
      i18n.t(`campaignGroup.labels.${CampaignGroupListColumns.BUDGET}`) + ` (${order.currency})`
    ),
    value => value === -1 ?
      i18n.t('common.labels.noData') :
      formatPrice(order.currency, value ? value : 0)
  );

  const editBtns = renderColumn({
    ...columnDefinition(CampaignGroupListColumns.EDITBTNS),
    text: '',
    sort: false,
    formatExtraData: {
      currentUrl: match.url,
      onDeleteBtnClick
    }
  }, formatters.floatingEditBtnsFormatter);

  const basicColumns = _.compact([
    idColumn,
    renderColumn(columnDefinition(CampaignGroupListColumns.STATE), formatters.stateFormatter),
    renderColumn(columnDefinition(CampaignGroupListColumns.DELIVERY), formatters.deliveryFormatter),
    state.selectedChannel === ListChannel.ALL ? renderColumn(columnDefinition(CampaignGroupListColumns.CHANNEL), formatters.channelFormatter) : undefined,
    budgetColumn,
    renderColumn(columnDefinition(CampaignGroupListColumns.AUTO_OPTIMISE),
      value => value ?
      i18n.t('common.labels.yes') :
      i18n.t('common.labels.no')
    ),
    editBtns
  ]);

  const metricsFormatter = (formatter, value) => {
    let target = value;
    if (!value) {
      target = 0;
    }
    return formatter(target);
  };

  const metricsWithCommasFormatter = _.partial(metricsFormatter, numberWithCommas);

  const spentColumn = renderColumn(
    columnDefinition(
      CampaignGroupListColumns.SPENT,
      i18n.t(`campaignGroup.labels.${CampaignGroupListColumns.SPENT}`) + ` (${order.currency})`),
      _.partial(metricsFormatter, _.partial(formatPrice, order.currency)
    )
  );

  const impresColumn = renderColumn(
    columnDefinition(CampaignGroupListColumns.IMPRES),
    metricsWithCommasFormatter
  );

  const viewableEnable = addonFeatureManager.isFeatureEnable(ADDONFEATURE.REPORT.REPORT_VIEWABLE_CTR);
  const convsEnable = addonFeatureManager.isFeatureEnable(ADDONFEATURE.CONVERSION_TRACKING.CONV_TRACKING_LIST);
  const performanceColumns = _.compact([
    idColumn,
    state.selectedChannel === ListChannel.ALL ? renderColumn(columnDefinition(CampaignGroupListColumns.CHANNEL), formatters.channelFormatter) : undefined,
    budgetColumn,
    renderColumn(columnDefinition(CampaignGroupListColumns.RESULTS), formatters.resultsFormatter),
    impresColumn,
    viewableEnable ? renderColumn(columnDefinition(CampaignGroupListColumns.VIEWABLE), metricsWithCommasFormatter) : undefined,
    renderColumn(columnDefinition(CampaignGroupListColumns.CLICKS), metricsWithCommasFormatter),
    renderColumn(
      columnDefinition(CampaignGroupListColumns.CPC, i18n.t(`campaignGroup.labels.${CampaignGroupListColumns.CPC}`) + ` (${order.currency})`),
      _.partial(metricsFormatter, _.partial(formatPrice, order.currency))
    ),
    renderColumn(columnDefinition(CampaignGroupListColumns.CTR), _.partial(metricsFormatter, value => `${value.toFixed(2)}%`)),
    viewableEnable ? renderColumn(columnDefinition(CampaignGroupListColumns.VCTR), _.partial(metricsFormatter, value => `${value.toFixed(2)}%`)) : undefined,
    convsEnable ? renderColumn(columnDefinition(CampaignGroupListColumns.CONVERT), metricsWithCommasFormatter) : undefined,
    convsEnable ? renderColumn(
      columnDefinition(CampaignGroupListColumns.CPA, i18n.t(`campaignGroup.labels.${CampaignGroupListColumns.CPA}`) + ` (${order.currency})`),
      _.partial(metricsFormatter, _.partial(formatPrice, order.currency))
    ) : undefined,
    convsEnable ? renderColumn(columnDefinition(CampaignGroupListColumns.CVR), _.partial(metricsFormatter, value => `${value.toFixed(2)}%`)) : undefined,
    renderColumn(columnDefinition(CampaignGroupListColumns.UU), metricsWithCommasFormatter),
    spentColumn,
    editBtns
  ]);

  const fbOnSiteActionsColumns = [
    idColumn,
    impresColumn,
    spentColumn,
    renderColumn(columnDefinition(CampaignGroupListColumns.COMMENT), metricsWithCommasFormatter),
    renderColumn(columnDefinition(CampaignGroupListColumns.LIKE), metricsWithCommasFormatter),
    renderColumn(columnDefinition(CampaignGroupListColumns.LINK_CLICK), metricsWithCommasFormatter),
    renderColumn(columnDefinition(CampaignGroupListColumns.POST), metricsWithCommasFormatter),
    renderColumn(columnDefinition(CampaignGroupListColumns.POST_ENGAGEMENT), metricsWithCommasFormatter),
    renderColumn(columnDefinition(CampaignGroupListColumns.POST_REACTION), metricsWithCommasFormatter),
    editBtns
  ];

  const appActionsColumns = [
    idColumn,
    impresColumn,
    spentColumn,
    renderColumn(columnDefinition(CampaignGroupListColumns.APP_CUSTOM_EVENT), metricsWithCommasFormatter),
    renderColumn(columnDefinition(CampaignGroupListColumns.APP_CUSTOM_EVENT_FB_MOBILE_ACTIVATE_APP), metricsWithCommasFormatter),
    renderColumn(columnDefinition(CampaignGroupListColumns.APP_CUSTOM_EVENT_FB_MOBILE_ADD_PAYMENT_INFO), metricsWithCommasFormatter),
    renderColumn(columnDefinition(CampaignGroupListColumns.APP_CUSTOM_EVENT_FB_MOBILE_ADD_TO_CART), metricsWithCommasFormatter),
    renderColumn(columnDefinition(CampaignGroupListColumns.APP_CUSTOM_EVENT_FB_MOBILE_ADD_TO_WISHLIST), metricsWithCommasFormatter),
    renderColumn(columnDefinition(CampaignGroupListColumns.APP_CUSTOM_EVENT_FB_MOBILE_COMPLETE_REGISTRATION), metricsWithCommasFormatter),
    renderColumn(columnDefinition(CampaignGroupListColumns.APP_CUSTOM_EVENT_FB_MOBILE_CONTENT_VIEW), metricsWithCommasFormatter),
    renderColumn(columnDefinition(CampaignGroupListColumns.APP_CUSTOM_EVENT_FB_MOBILE_INITIATED_CHECKOUT), metricsWithCommasFormatter),
    renderColumn(columnDefinition(CampaignGroupListColumns.APP_CUSTOM_EVENT_FB_MOBILE_PURCHASE), metricsWithCommasFormatter),
    renderColumn(columnDefinition(CampaignGroupListColumns.APP_CUSTOM_EVENT_FB_MOBILE_SEARCH), metricsWithCommasFormatter),
    renderColumn(columnDefinition(CampaignGroupListColumns.APP_CUSTOM_EVENT_OTHER), metricsWithCommasFormatter),
    editBtns
  ];

  const tiktokVideoPlayActionsColumns = [
    idColumn,
    impresColumn,
    spentColumn,
    renderColumn(columnDefinition(CampaignGroupListColumns.TIKTOK_VIDEO_PLAY_ACTIONS), metricsWithCommasFormatter),
    renderColumn(columnDefinition(CampaignGroupListColumns.TIKTOK_VIDEO_WATCHED_2S), metricsWithCommasFormatter),
    renderColumn(columnDefinition(CampaignGroupListColumns.TIKTOK_VIDEO_WATCHED_6S), metricsWithCommasFormatter),
    renderColumn(columnDefinition(CampaignGroupListColumns.TIKTOK_VIDEO_VIEWS_P25), metricsWithCommasFormatter),
    renderColumn(columnDefinition(CampaignGroupListColumns.TIKTOK_VIDEO_VIEWS_P50), metricsWithCommasFormatter),
    renderColumn(columnDefinition(CampaignGroupListColumns.TIKTOK_VIDEO_VIEWS_P75), metricsWithCommasFormatter),
    renderColumn(columnDefinition(CampaignGroupListColumns.TIKTOK_VIDEO_VIEWS_P100), metricsWithCommasFormatter),
    editBtns
  ];

  const tiktokEngagementActionsColumns = [
    idColumn,
    impresColumn,
    spentColumn,
    renderColumn(columnDefinition(CampaignGroupListColumns.TIKTOK_PROFILE_VISITS), metricsWithCommasFormatter),
    renderColumn(columnDefinition(CampaignGroupListColumns.TIKTOK_LIKES), metricsWithCommasFormatter),
    renderColumn(columnDefinition(CampaignGroupListColumns.TIKTOK_COMMENTS), metricsWithCommasFormatter),
    renderColumn(columnDefinition(CampaignGroupListColumns.TIKTOK_SHARES), metricsWithCommasFormatter),
    renderColumn(columnDefinition(CampaignGroupListColumns.TIKTOK_FOLLOWS), metricsWithCommasFormatter),
    renderColumn(columnDefinition(CampaignGroupListColumns.TIKTOK_CLICKS_ON_MUSIC_DISC), metricsWithCommasFormatter),
    editBtns
  ];

  const typeColumnsMap = {
    [CampaignGroupListType.BASIC]: basicColumns,
    [CampaignGroupListType.PERFORMANCE]: performanceColumns,
    [CampaignGroupListType.FB_ONSITE_ACTIONS]: fbOnSiteActionsColumns,
    [CampaignGroupListType.APP_ACTIONS]: appActionsColumns,
    [CampaignGroupListType.TIKTOK_VIDEO_PLAY]: tiktokVideoPlayActionsColumns,
    [CampaignGroupListType.TIKTOK_ENGAGEMENT]: tiktokEngagementActionsColumns
  };

  const metricsGroupOptions = useMemo(() => {
    return channelMetricsGroupMap[state.selectedChannel].map(metricsGroup => ({
      label: i18n.t(`campaignList.tabs.${metricsGroup}`),
      value: metricsGroup
    }));
  }, [state.selectedChannel]);

  useEffect(() => {
    if (metricsGroupOptions.find((option) => {
      return option.value === state.selectedMetricsGroup;
    }) === undefined) {
      setState(prevState => ({ ...prevState, selectedMetricsGroup: metricsGroupOptions[0].value }));
    }
  }, [state.selectedMetricsGroup, metricsGroupOptions]);

  const activeCampaignGroup = async () => {
    callAPIs([
      campaignGroupManager.updateCampaignGroupState.bind(campaignGroupManager, selectedCampaignGroups, 'activate')
    ], () => {
      setSelectedCampaignGroups([]);
      refreshList();
    });
  };

  const deactiveCampaignGroup = async () => {
    callAPIs([
      campaignGroupManager.updateCampaignGroupState.bind(campaignGroupManager, selectedCampaignGroups, 'deactivate')
    ], () => {
      setSelectedCampaignGroups([]);
      refreshList();
    });
  };

  const canNotCreateMessage = useMemo(() => {
    const canNotCreateState = [State.NOT_APPROVE, State.REJECT, State.SETTLE, State.SETTLED, State.CHANGE_PENDING];
    const state = order.state;
    if (canNotCreateState.includes(state)) {
      return i18n.t('campaignGroupList.labels.orderStateCannotCreate');
    }

    const isEnd = moment(order.endDate).isBefore(moment().startOf('day'));
    if (isEnd) {
      return i18n.t('campaignGroupList.labels.isEndCannotCreate');
    }

    return '';
  }, [order]);

  return {
    state,
    columns: typeColumnsMap[state.selectedMetricsGroup],
    loading,
    currentUrl: match.url,
    selectedCampaignGroups,
    metricsGroupOptions,
    canNotCreateMessage,
    advertiserName,
    onHandleSearch,
    onMetricsGroupChange,
    onListChannelChange,
    onDeleteModalClose: _.partial(onDeleteModalClose, false),
    handleRemoveSelect,
    activeCampaignGroup,
    deactiveCampaignGroup
  };
};

const useRetailCampaignGroupListModel = (
  order: Order,
  campaignGroupList: any[],
  refreshList: () => void,
  addonFeatureManager: AddonFeatureManager
) => {
  const results = useDefaultCampaignGroupListModel(order, campaignGroupList, refreshList, addonFeatureManager);
  return {
    ...results,
    channels: Object.values(ListChannel)
  };
};

const useGoGANCampaignGroupListModel = (
  order: Order,
  campaignGroupList: any[],
  refreshList: () => void,
  addonFeatureManager: AddonFeatureManager
) => {
  const results = useDefaultCampaignGroupListModel(order, campaignGroupList, refreshList, addonFeatureManager);
  return {
    ...results,
    channels: Object.values(ListChannel).filter(value => value !== ListChannel.RETAIL_MEDIA)
  };
};
export let useCampaignGroupListModel;
if (process.env.REACT_APP_PROJECT === Project.RETAIL) {
  useCampaignGroupListModel = useRetailCampaignGroupListModel;
} else {
  useCampaignGroupListModel = useGoGANCampaignGroupListModel;
}

import { CampaignGroupChannel } from 'core/campaignGroup/CampaignGroup';

export enum SourceDetails {
  ORDERNAME = 'orderName',
  GROUPNAME = 'groupName',
  CHANNEL = 'channel',
  SELECED = 'selectedOfCampaigns'
}

export enum ConfirmationColumns {
  ORDER = 'order',
  CAMPAIGNGROUP = 'campaignGroup',
  CHANNEL = 'channel',
  CREATIVE = 'creative',
  COPIES = 'copies',
  STATUS = 'status'
}

export type DraftCreateDTO = {
  includeBinding: boolean;
  channel: CampaignGroupChannel;
  targetGoCampaignGroupId: number;
  sourceGoCampaignChannelId: number;
  numberOfCopies: number;
  draft: boolean;
};

export type DraftValidationDTO = {
  targetGoCampaignGroupId: number;
  channelAccountId: number;
  deliveryStatus: string;
  bindings: Array<any>;
};

export type DraftPublishResponse = {
  includeBinding: boolean,
  result: string,
  reason: string
};

import { DefaultSelectComponentModel } from './SelectComponentModel';

export class SingleSelectComponentModel extends DefaultSelectComponentModel {

  customInputModelHandler (model) {
    const selectedData = model.customSelectOptions[model.customSelectOptions.length - 1];
    this.value = [selectedData];
    this.onChange([selectedData]);
    this.updateState();
  }

  onItemChange (_, selectedData) {
    this.value = [selectedData];
    this.onChange([selectedData]);
    this.updateState();
  }
}

import { RoleNames } from 'core/auth/Account';
import * as parser from 'utils/ActorRoleParser';
import { ActorRolePermission, InverseActorPermission, PermissionItem, ActorRolePermissionDescriptor, AgencyAddOnPermission, ActorFuncPermission, ProjectPermissionItem } from './PermissionAware';
import { Permission } from 'core/auth/Permission';
import { Project } from 'core/project/Project';

function getRoleDescriptors (...roleNames: RoleNames[]): ActorRolePermissionDescriptor {
  const descriptors = roleNames.reduce<ActorRolePermissionDescriptor>((partial, roleName) => {
    const type = parser.getRoleNamePart(roleName, 0, 'system').toLowerCase();
    const role = parser.getRoleNamePart(roleName, 1, 'admin').toLowerCase();
    const existing = partial[type];
    return {
      ...partial,
      [type]: (existing) ? [...existing, role] : [role]
    };
  }, {});
  return descriptors;
}

export function hasRoles (...roleNames: RoleNames[]): PermissionItem {
  if (roleNames.length < 1) {
    throw new Error('role names can not be empty');
  }
  return new PermissionItem(new ActorRolePermission(getRoleDescriptors(...roleNames)));
}

export function notRoles (...roleNames: RoleNames[]): PermissionItem {
  if (roleNames.length < 1) {
    throw new Error('role names can not be empty');
  }
  return new PermissionItem(new InverseActorPermission(new ActorRolePermission(getRoleDescriptors(...roleNames))));
}

export function notFuncs (...funcPermissions: Permission[]): PermissionItem {
  if (funcPermissions.length < 1) {
    throw new Error('function permissions can not be empty');
  }
  return new PermissionItem(new InverseActorPermission(new ActorFuncPermission(funcPermissions)));
}

export function hasFuncs (...funcPermissions: Permission[]): PermissionItem {
  if (funcPermissions.length < 1) {
    throw new Error('function permissions can not be empty');
  }
  return new PermissionItem(new ActorFuncPermission(funcPermissions));
}

export function addOnEnabled (...keys: string[]): PermissionItem {
  if (keys.length < 1) {
    throw new Error('role names can not be empty');
  }
  return new PermissionItem(null, new AgencyAddOnPermission(keys));
}

export function isProject (project: Project): PermissionItem {
  return new ProjectPermissionItem(project);
}

import {
  DefaultRtbCampaignBasicFormModel,
  RtbCampaignBasicFormModel,
  RtbCampaignBasicFormModelConstructorParams
} from './RtbCampaignBasicFormModel';
import { AdType, RtbCampaignPlanType } from 'core/rtbCampaign/RtbCampaign';
import { ADDONFEATURE } from 'core/agency/AddonFeature';

export interface PilotTVFormModel extends RtbCampaignBasicFormModel {
  readonly showVideoProgressRadio: boolean;
  readonly canEditBudgetPlan: boolean;
  readonly canEditPriceModel: boolean;
}
abstract class AbstractPilotTVFormModel extends DefaultRtbCampaignBasicFormModel
  implements PilotTVFormModel {

  showVideoProgressRadio = false;

  get campaignAdType (): AdType {
    return AdType.PILOT_TV;
  }

  get defaultPriceModel (): string {
    return this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CAMPAIGN.DYNAMIC_CPM) ?
      RtbCampaignPlanType.DCPM : this.availablePriceModel[0];
  }

  getBidPriceMinData (): any {
    return {
      cpm: 1
    };
  }
}

export class EditPilotTVFormModel extends AbstractPilotTVFormModel {
  constructor (...args: RtbCampaignBasicFormModelConstructorParams) {
    super('edit', ...args);
  }
  get canEditBudgetPlan (): boolean {
    return false;
  }
  get canEditPriceModel (): boolean {
    return false;
  }

  canEditOptimize (): boolean {
    return false;
  }
}

export class CreatePilotTVFormModel extends AbstractPilotTVFormModel {
  constructor (...args: RtbCampaignBasicFormModelConstructorParams) {
    super('create', ...args);
  }
  get canEditBudgetPlan (): boolean {
    return true;
  }
  get canEditPriceModel (): boolean {
    return true;
  }
}

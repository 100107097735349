import { Keyword } from './Keyword';
import {
  LimitationWebService,
  RestfulLimitationWebService
} from 'ws/LimitationWebService';
import { RestfulBlobWebService } from 'ws/BlobWebService';
import { RestfulGCPCloudStorageWebService } from 'ws/GCPCloudStorageWebService';
import config from 'config';
import { CloudResourceWebService } from 'ws/CloudResourceWebService';
import { SavedTargeting } from './Limitation';
import _ from 'lodash';

const defaultlimitationJson = {
  'include': [],
  'exclude': [],
  'preferred': [],
  'nonPreferred': [],
  'other': [
    {
      op: 'inc',
      value: [],
      type: 'dealId'
    }
  ]
};

export interface LimitationManager {
  keywordEstimate (request: Keyword): Promise<number>;
  getLimitationPreSet (type: string): Promise<Object>;
  categoriesUnionCount (categories: Array<string>, lang?: string): Promise<number>;
  getSavedTargetings (advertiserId: number, channel: string): Promise<SavedTargeting[]>;
  createSavedTargeting (savedTargeting: Partial<SavedTargeting>): Promise<SavedTargeting>;
  updateSavedTargeting (savedTargeting: Partial<SavedTargeting>): Promise<SavedTargeting>;
  deleteSavedTargeting (id: number): Promise<void>;
}

export class DefaultLimitationManager implements LimitationManager {
  webService: LimitationWebService;
  cloudStorageWebService: CloudResourceWebService;

  constructor (
    webService: LimitationWebService = new RestfulLimitationWebService(),
    cloudStorageWebService: CloudResourceWebService = config.cloudStorageConfig.platform === 'azure' ? new RestfulBlobWebService() : new RestfulGCPCloudStorageWebService()
  ) {
    this.webService = webService;
    this.cloudStorageWebService = cloudStorageWebService;
  }

  keywordEstimate (request: Keyword): Promise<number> {
    return this.webService.keywordEstimate(request);
  }

  async categoriesUnionCount (categories: Array<string>, lang: string = 'zh'): Promise<number> {
    return this.webService.categoriesUnionCount(categories, lang);
  }

  async getLimitationPreSet (type: string): Promise<Object> {
    const opMap = {
      inc: 'include',
      exc: 'exclude',
      Preferred: 'preferred',
      NonPreferred: 'nonPreferred'
    };

    let limitationPreSet: any = _.cloneDeep(defaultlimitationJson);
    try {
      limitationPreSet = await this.cloudStorageWebService.getLimitationPreSet(type);
    } catch {}
    if (type === 'creative') {
      return limitationPreSet;
    }
    const agencyLimitationPreSet = await this.webService.getAgencyLimitationPreSet(type);
    agencyLimitationPreSet.forEach(element => {
      element.value = element.limits ?
        element.limits.map(limit => ({
          ...limit,
          isGroup: element.isGroup ? element.isGroup : false
        })) :
        [];
      delete element['limits'];
      if (element.type === 'instl') {
        const instlElement = {
          op: 'exc',
          type: 'adFormat',
          value: [{
            value: 'instl',
            label: 'instl'
          }]
        };
        if (limitationPreSet['exclude']) {
          limitationPreSet['exclude'].push(instlElement);
        } else {
          limitationPreSet['exclude'] = [instlElement];
        }
      } else {
        let setIndex = limitationPreSet[opMap[element.op]].findIndex(set => set.type === element.type);
        if (setIndex === -1) {
          limitationPreSet[opMap[element.op]].push(element);
        } else {
          limitationPreSet[opMap[element.op]][setIndex] = element;
        }
      }
    });
    return limitationPreSet;
  }

  async getSavedTargetings (advertiserId: number, channel: string): Promise<SavedTargeting[]> {
    return this.webService.getSavedTargetings(advertiserId, channel);
  }
  async createSavedTargeting (savedTargeting: Partial<SavedTargeting>): Promise<SavedTargeting> {
    return this.webService.createSavedTargeting(savedTargeting);
  }
  async updateSavedTargeting (savedTargeting: Partial<SavedTargeting>): Promise<SavedTargeting> {
    return this.webService.updateSavedTargeting(savedTargeting);
  }
  async deleteSavedTargeting (id: number): Promise<void> {
    return this.webService.deleteSavedTargeting(id);
  }
}

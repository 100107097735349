export type TiktokAdGroup = {
  adgroup_id: string;
  campaign_id: string;
  advertiser_id: string;
  adgroup_name: string;
  placement_type: string; // default PLACEMENT_TYPE_AUTOMATIC
  external_type: PromotionType; // default WEBSITE
  budget: number | string;
  budget_mode: BudgetMode; // default BUDGET_MODE_TOTAL
  schedule_type: ScheduleType; // default SCHEDULE_START_END
  schedule_start_time: string;
  schedule_end_time: string;
  opt_status: string;
  status: string;
  optimize_goal: OptimizationGoal;
  pacing: Pacing; // default PACING_MODE_SMOOTH
  billing_event: BillingEvent;
  bid_type: BidStrategy;
  bid?: number;
  targeting?: any,
  dayPart?: { [key: string]: string[] | number[] | string };
  draftId?: number;
  isDraft?: boolean;
};

export type TiktokAdGroupListRecord = TiktokAdGroup & {
  enableCount: 0,
  report?: any,
  review?: any
};

export enum PlacementType {
  PLACEMENT_TYPE_AUTOMATIC = 'PLACEMENT_TYPE_AUTOMATIC',
  PLACEMENT_TYPE_NORMAL = 'PLACEMENT_TYPE_NORMAL'
}

export enum PromotionType {
  APP_ANDROID = 'APP_ANDROID',
  APP_IOS = 'APP_IOS',
  WEBSITE = 'WEBSITE',
  LEAD_GENERATION = 'LEAD_GENERATION',
  WEBSITE_OR_DISPLAY = 'WEBSITE_OR_DISPLAY',
  TIKTOK_SHOP = 'TIKTOK_SHOP',
  VIDEO_SHOPPING = 'VIDEO_SHOPPING',
  LIVE_SHOPPING = 'LIVE_SHOPPING'
}

export enum BudgetMode {
  BUDGET_MODE_DAY = 'BUDGET_MODE_DAY',
  BUDGET_MODE_TOTAL = 'BUDGET_MODE_TOTAL', // ScheduleType must be SCHEDULE_START_END
  BUDGET_MODE_INFINITE = 'BUDGET_MODE_INFINITE'
}

export enum ScheduleType {
  SCHEDULE_START_END = 'SCHEDULE_START_END',
  SCHEDULE_FROM_NOW = 'SCHEDULE_FROM_NOW'
}

export enum OptimizationGoal {
  CLICK = 'CLICK',
  CONVERT = 'CONVERT',
  REACH = 'REACH',
  SHOW = 'SHOW'
}

export enum Pacing {
  PACING_MODE_SMOOTH = 'PACING_MODE_SMOOTH',
  PACING_MODE_FAST = 'PACING_MODE_FAST'
}

export enum BillingEvent {
  CPC = 'CPC',
  CPM = 'CPM',
  CPV = 'CPV',
  GD = 'GD',
  OCPM = 'OCPM'
}

export enum BidStrategy {
  BID_TYPE_CUSTOM = 'BID_TYPE_CUSTOM',
  BID_TYPE_NO_BID = 'BID_TYPE_NO_BID'
}

export const TiktokAgeMapping = Object.freeze({
  AGE_13_17: '13~17',
  AGE_18_24: '18~24',
  AGE_25_34: '25~34',
  AGE_35_44: '35~44',
  AGE_45_54: '45~54',
  AGE_55_100: '55+'
});

export const TiktokGenderMapping = Object.freeze({
  GENDER_UNLIMITED: -1,
  GENDER_MALE: 1,
  GENDER_FEMALE: 2
});

export const TiktokPlacementMapping = Object.freeze({
  TIKTOK: 'PLACEMENT_TIKTOK',
  TOPBUZZ: 'PLACEMENT_TOPBUZZ',
  PANGLE: 'PLACEMENT_PANGLE'
});

export const ADGROUP_DEFAULT_AGE_MIN = 21;
export const ADGROUP_DEFAULT_AGE_MAX = 55;

enum CAMPAIGN {
  BID_WEIGHT_SETTING = 'bid_weight_setting',
  ORDER_AGENCY_PROFIT_SETTING = 'orderAgcProfitSetting',
  DELIVERY_AMOUNT_CONSTRAINT = 'deliveryAmountConstraint',
  BID_PRICE_CONSTRAINT = 'bidPriceConstraint',
  FIXED_CPC = 'fix_cpc',
  FIXED_CPM = 'fix_cpm',
  FIXED_VCPM = 'fix_vcpm',
  DYNAMIC_CPM = 'dynamic_cpm',
  OPTIMIZE_CPC_IN_RS = 'optimizeCpcInRS',
  OPTIMIZE_VCPM_IN_RS = 'optimizeVcpmInRS',
  REVENUE_SHARING = 'revenueSharing',
  BUDGET_DOMINATE = 'budgetDominate',
  ROADBLOCK = 'roadblock',
  ENABLE_OPTIMIZED_PRICE = 'enableOptimizedPrice',
  VIDEO_AD_VIEW_OBJECTIVE = 'videoAdViewObjective',
  THIRDPARTY_TRACKING = 'thirdparty_tracking',
  RETARGETING_TRACKER = 'retargeting_tracker',
  CUSTOMIZE_ADLOGO = 'customizeAdLogo',
  FIX_CPC = 'fix_cpc',
  FIX_VCPM = 'fix_vcpm'
}

export enum CREATIVES {
  IMAGE = 'option_1',
  HTML5 = 'option_2',
  NATIVE = 'option_3',
  VIDEO = 'option_4',
  DYNAMIC = 'option_5',
  THIRDPARTY = 'option_8',
  COMBO = 'option_10',
  OUTDOOR = 'option_11',
  ONE_FOR_ALL_DISPLAY = 'option_13',
  PILOT_TV = 'option_22'
}

enum COMPANY {
  AGENCY_PROFIT = 'agencyProfit',
  AGENCY_REBATE = 'rebate',
  AGENCY_INCENTIVE = 'incentive',
  AGENCY_ALLOW_BID_PRICE_MORE_THEN_CONTRACT_PRICE = 'allowBidPriceMoreThenContractPrice',
  AGENCY_PROVIDE_REPORT_TO_WORKFLOW = 'provideReportToWorkflow',
  SINGLE_ORDER_BUDGET_CONSTRAINT = 'singleOrderBudgetConstraint',
  USE_AGENCY_PROFIT_ON_REBATE_REPORT = 'useAgencyProfitOnRebateReport',
  SHOW_OVERSPEND_ON_REBATE_REPORT = 'showOutComeOnRebateReport',
  AGENCY_CREDIT_LIMIT = 'creditLimit',
  AGENCY_ALLOW_ORDER_SETTLEMENT = 'allowOrderSettlement',
  USE_FIXED_EXCHANGE_RATE = 'fixedExchangeRate',
  SHOW_ORDER_EXTERNAL_TYPE = 'showOrderExternalType',
  ADVERTISER_VIEW_SPENT_IN_REPORT = 'advertiserViewSpentInReport',
  OUTDOOR_AGENCY = 'outdoorAgency',
  LOOKALIKE_AUDIENCE_CREATION = 'lookalikeAudienceCreation'
}

enum LIMITATION {
  LIMITATION_COUNTRY = 'country',
  LIMITATION_SPACETYPE = 'spaceType',
  LIMITATION_DEVICE = 'device',
  LIMITATION_TARGET_AUDIENCE = 'target_audience',
  LIMITATION_HABITS = 'habits',
  LIMITATION_ADEXCHANGE = 'adexchange',
  LIMITATION_ASIAMAX_SPACE = 'asiamax_space',
  LIMITATION_DOMAIN = 'domain',
  LIMITATION_SPACE_CATEGORY = 'space_category',
  LIMITATION_TENMAX_AUDIENCE = 'tenmax_audience',
  LIMITATION_CITY = 'city',
  LIMITATION_GENDER = 'gender',
  LIMITATION_AGE = 'age',
  LIMITATION_CARRIER = 'carrier',
  ENABLE_PMP_DEAL = 'enablePMPDeal',
  KEYWORD = 'keyword',
  LIMITATION_CONTENT_LANGUAGE = 'content_language',
  LIMITATION_CONTENT_CATEGORY = 'content_category'
}

enum CREATIVE_STUDIO {
  DCS_TEMPLATES = 'templates'
}

enum CONVERSION_TRACKING {
  CONV_TRACKING_LIST = 'TrackingList',
  MARKETPLACE_MAIN = 'main',
  MARKETPLACE_DATA = 'data'
}

enum REPORT {
  REPORT_VIDEO = 'video',
  REPORT_VIEWABLE_CTR = 'viewableCtr',
  REPORT_CAMPAIGN_DASHBOARD = 'campaignDashboard',
  REPORT_YAHOOAD = 'yahooAd',
  REPORT_CLOSINGREPORT = 'closingReport',
  REPORT_OUTDOOR_AD_IMPRES_MULTIPLY = 'outdoorAdImpresMultiply',
  REPORT_ORDER_CUSTOM = 'orderCustom'
}

enum MONITOR {
  MONITOR_CAMPAIGN_UNDERBIDFLOOR = 'campaignUnderBidFloor',
  MONITOR_CAMPAIGN_BEHIND_PROGRESS = 'campaignBehindProgress',
  AGENCY_SYSTEM_PROFIT_MONITOR = 'systemProfitMonitor'
}

enum PRODUCT {
  E_COMMERCE = 'index',
  EC_PROGRAMMATIC_STRATEGY = 'programmaticStrategy',
  EC_CONVERSION = 'conversion'
}

export const AddonDependency = {
  [COMPANY.USE_AGENCY_PROFIT_ON_REBATE_REPORT]: [COMPANY.AGENCY_REBATE],
  [COMPANY.SHOW_OVERSPEND_ON_REBATE_REPORT]: [COMPANY.AGENCY_REBATE],
  [CAMPAIGN.ORDER_AGENCY_PROFIT_SETTING]: [COMPANY.AGENCY_PROFIT],
  [CAMPAIGN.REVENUE_SHARING]: [CAMPAIGN.DELIVERY_AMOUNT_CONSTRAINT],
  [REPORT.REPORT_OUTDOOR_AD_IMPRES_MULTIPLY]: [CREATIVES.OUTDOOR],
  [LIMITATION.LIMITATION_CITY]: [LIMITATION.LIMITATION_COUNTRY]
};

export type AddonFeatureType =
  | CAMPAIGN
  | CREATIVES
  | COMPANY
  | LIMITATION
  | CREATIVE_STUDIO
  | CONVERSION_TRACKING
  | REPORT
  | MONITOR
  | PRODUCT;

export const ADDONFEATURE = {
  CAMPAIGN,
  CREATIVES,
  COMPANY,
  LIMITATION,
  CREATIVE_STUDIO,
  CONVERSION_TRACKING,
  REPORT,
  MONITOR,
  PRODUCT
};

import { FormikField } from 'components/common/formik/FormikField';
import { FormikProps } from 'formik';
import { validateEmpty } from 'utils/ValidateUtils';
import i18n from 'i18n';
import _ from 'lodash';

type FormFieldsConfig = {
  fieldComponent: any;
  fieldProps: any;
};

abstract class ProductSetFormModel {

  constructor (
    protected selectedProducts,
    private advertiserOptions,
    private onAdvertiserChange: (advertiser) => void,
    protected afterSubmit: () => void,
    protected submitProductSet: (productSetFormData, cb: () => void) => Promise<void>
  ) {}

  abstract get submitBtnLabel ();
  abstract get initValue ();

  abstract submit (values);
  abstract validate (values);

  getFormFieldsConfig (formikProps: FormikProps<any>): FormFieldsConfig[] {
    const onAdvertiserChange = (advertiser) => {
      this.onAdvertiserChange(advertiser);
      formikProps.setFieldValue('productSet', undefined);
    };
    return [
      {
        fieldComponent: FormikField.Select,
        fieldProps: {
          name: 'advertiser',
          label: i18n.t('productSetCreateModal.labels.advertiser'),
          simpleValue: true,
          options: this.advertiserOptions,
          onChange: onAdvertiserChange
        }
      }
    ];
  }
}

export class NewProductSetFormModel extends ProductSetFormModel {

  get submitBtnLabel () {
    return i18n.t('productSetCreateModal.buttons.new');
  }

  get initValue () {
    return {
      advertiser: undefined,
      name: ''
    };
  }

  submit = async (values) => {
    console.log('create product set with params', values, this.selectedProducts.map(product => product.value));
    await this.submitProductSet({
      advertiser: values.advertiser,
      name: values.name,
      rule: {
        include: this.selectedProducts.map(product => product.value)
      }
    }, this.afterSubmit);
  }

  validate = (values) => {
    return _.omitBy({
      advertiser: validateEmpty(values.advertiser),
      name: validateEmpty(values.name)
    }, _.isUndefined);
  }

  getFormFieldsConfig (formikProps: FormikProps<any>): FormFieldsConfig[] {
    const baseFormFields = super.getFormFieldsConfig(formikProps);
    return [
      ...baseFormFields,
      {
        fieldComponent: FormikField.Input,
        fieldProps: {
          name: 'name',
          label: i18n.t('productSetCreateModal.labels.name')
        }
      }
    ];
  }
}

export class AddToExistProductSetFormModel extends ProductSetFormModel {

  constructor (
    selectedProducts,
    advertiserOptions,
    private productSetOptions,
    fetchProductSetsOfAdvertiser,
    afterSubmit: () => void,
    submitUpdateProductSet
  ) {
    super(selectedProducts, advertiserOptions, fetchProductSetsOfAdvertiser, afterSubmit, submitUpdateProductSet);
  }

  get submitBtnLabel () {
    return i18n.t('productSetCreateModal.buttons.addTo');
  }

  get initValue () {
    return {
      advertiser: undefined,
      productSet: undefined
    };
  }

  submit = async (values) => {
    console.log(`add to product set ${values.productSet} with params`, values, this.selectedProducts.map(product => product.value));
    await this.submitProductSet({
      id: values.productSet,
      advertiser: values.advertiser,
      name: this.productSetOptions.filter(product => product.value === values.productSet)[0].label,
      rule: {
        include: this.selectedProducts.map(product => product.value)
      }
    }, this.afterSubmit);
  }

  validate = (values) => {
    return _.omitBy({
      advertiser: validateEmpty(values.advertiser),
      productSet: validateEmpty(values.productSet)
    }, _.isUndefined);
  }

  getFormFieldsConfig (formikProps: FormikProps<any>): FormFieldsConfig[] {
    const baseFormFields = super.getFormFieldsConfig(formikProps);
    return [
      ...baseFormFields,
      {
        fieldComponent: FormikField.Select,
        fieldProps: {
          name: 'productSet',
          label: i18n.t('productSetCreateModal.labels.productSet'),
          simpleValue: true,
          options: this.productSetOptions
        }
      }
    ];
  }
}

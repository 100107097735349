import React from 'react';
import { Link } from 'react-router-dom';

export const impressionSecondFormatter = (value) => value === undefined ? 0 : value;

export const nameFormatter = (value, record) => {
  if (!record.type) {
    return value;
  }
  return (
    <Link to={`/orders/${record.id}/redirectToDetail`}>{value}</Link>
  );
};

import React, { Fragment } from 'react';
import styles from './creativeReviews.module.scss';
import { ADX, CreativeType } from 'core/creative/Creative';
import i18n from 'i18next';
import { CreativeReviewItem } from './CreativeReviewItem';
import _ from 'lodash';

export class CreativeReviews extends React.PureComponent<{
  showCreativeType: boolean;
  creativeData: any,
  reviewCreative: (adx, id) => void
}> {

  render () {
    const creativeData = this.props.creativeData;
    const natvieBannerData = creativeData.nativeBanner;
    const nativeStatus = creativeData.approvalStatus;
    if (!natvieBannerData && (!nativeStatus || Object.keys(nativeStatus).length === 0)) {
      return <div />;
    }
    let approvalData = {
      [i18n.t(`creativeList.labels.approvalDefault${creativeData.creativeType}`)]: {
        creativeId: creativeData.creativeId,
        reviewData: nativeStatus,
        isNativeToBanner: false
      }
    };
    creativeData.creativeType === CreativeType.NATIVE && natvieBannerData && Object.keys(natvieBannerData).forEach(key => {
      approvalData[key] = {
        creativeId: natvieBannerData[key].creativeId,
        reviewData: natvieBannerData[key].creativeReview,
        isNativeToBanner: true
      };
    });

    const adxs: string[] = [];
    Object.values(approvalData).forEach(data => {
      Object.keys(data.reviewData).forEach(key => {
        if (!(adxs.includes(key))) {
          adxs.push(key);
        }
      });
    });
    const adxValueMap = {
      asiamax: 0,
      doubleclick: 1
    };

    const sortedAdx = adxs.sort((key1, key2) => adxValueMap[key1] - adxValueMap[key2]);
    const renderTitleRow = () => {
      return (
        <>
          {this.props.showCreativeType && <span />}
          {sortedAdx.map(adx => {
            return <span key={adx}>{ADX[adx]}</span>;
          })}
        </>
      );
    };

    const renderApprovalData = (key, data) => {
      const creativeId = data.creativeId;
      const reviewData = data.reviewData;
      return (
        <Fragment key={key}>
          {this.props.showCreativeType && <span className={styles.title}>{key}</span>}
          {sortedAdx.map(adx => {
            const reviewCreative = (id) => {
              this.props.reviewCreative(adx, id);
            };
            const state = _.get(reviewData[adx], 'state');
            return state === undefined ?
              <div key={adx}>{i18n.t('common.labels.noData')}</div> :
              <CreativeReviewItem
                key={adx}
                adx={adx}
                tenmaxCategory={creativeData.tenmaxCategory}
                origTenmaxCategory={creativeData.origTenmaxCategory}
                approvalData={reviewData[adx]}
                creativeId={creativeId}
                reviewCreative={data.isNativeToBanner && adx === 'asiamax' ? undefined : reviewCreative}
              />;
          })}
        </Fragment>
      );
    };
    const typeClass = CreativeType[creativeData.creativeType] ? styles[CreativeType[creativeData.creativeType].toLowerCase()] : '';
    return (
      <div className={`${styles.approvalData} ${typeClass} ${styles[`count${sortedAdx.length + (this.props.showCreativeType ? 1 : 0)}`]}`}>
        {renderTitleRow()}
        {Object.keys(approvalData).map(key => {
          return renderApprovalData(key, approvalData[key]);
        })}
      </div>
    );
  }
}

import React, { useRef } from 'react';
import { Modal } from 'components/common/Modal/Modal';
import i18n from 'i18n';
import { PRODUCT_SET_CREATE_TYPE, useProductSetCreateModalModel } from './ProductSetCreateModalModel';
import { Form } from 'react-bootstrap';
import FormGroup from 'components/common/FormGroup/FormGroup';
import styles from './productSetCreateModal.module.scss';
import { Formik } from 'formik';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { SelectOptions } from 'components/common/commonType';

export const ProductSetCreateModal: React.FC<{
  retailId: string,
  selectedProducts: SelectOptions[];
  onClose: () => void;
}> = ({
  retailId,
  selectedProducts,
  onClose
}) => {

  const {
    loading,
    formModel,
    createType,
    setCreateType
  } = useProductSetCreateModalModel(retailId, selectedProducts, onClose);

  const formRef = useRef<Formik<any>>(null);

  const onSubmit = () => {
    formRef.current && formRef.current.submitForm();
  };

  return (
    <Modal
      fullScreen={true}
      title={i18n.t('productSetCreateModal.labels.title')}
      primaryButton={{
        title: formModel.submitBtnLabel,
        callback: onSubmit
      }}
      secondaryButton={{
        title: i18n.t('common.buttons.cancel'),
        callback: onClose
      }}
      dismiss={onClose}
      animation={false}
    >
      {
        <div className={styles.content}>
          {loading && <LoadingIndicator/>}
          <Formik
            ref={formRef}
            initialValues={formModel.initValue}
            enableReinitialize
            onSubmit={formModel.submit}
            validate={formModel.validate}
            validateOnBlur={false}
          >
            {
              (formikProps) => {
                const onCreateTypeChange = (e) => {
                  setCreateType(e.target.value);
                  formikProps.setFieldValue('advertiser', undefined);
                };
                const fieldsConfig = formModel.getFormFieldsConfig(formikProps);
                const renderFields = () =>
                  fieldsConfig.map(config => {
                    const Component = config.fieldComponent;
                    return <Component key={`${createType}-${config.fieldProps.name}`} {...config.fieldProps}/>;
                  });
                const renderProducts = () =>
                  selectedProducts.map(product => {
                    return <li key={product.value}>{product.label}</li>;
                  });
                return (
                  <>
                    <FormGroup controlId='createType' label=''>
                      <div className={styles.radioRow}>
                        <Form.Check
                          type={'radio'}
                          label={i18n.t('productSetCreateModal.labels.new')}
                          value={PRODUCT_SET_CREATE_TYPE.NEW}
                          name={'bidStrategy'}
                          id={'autoBid'}
                          onChange={onCreateTypeChange}
                          checked={createType === PRODUCT_SET_CREATE_TYPE.NEW}
                        />
                        <Form.Check
                          type={'radio'}
                          label={i18n.t('productSetCreateModal.labels.addTo')}
                          value={PRODUCT_SET_CREATE_TYPE.ADD_TO_EXIST}
                          name={'bidStrategy'}
                          id={'bidCap'}
                          onChange={onCreateTypeChange}
                          checked={createType === PRODUCT_SET_CREATE_TYPE.ADD_TO_EXIST}
                        />
                      </div>
                    </FormGroup>
                    {renderFields()}
                    <FormGroup controlId='products' label={i18n.t('productSetCreateModal.labels.products')}>
                      <ul>
                        {renderProducts()}
                      </ul>
                    </FormGroup>
                  </>
                );
              }
            }
          </Formik>
        </div>
      }
    </Modal>
  );
};

import React from 'react';
import { AsyncSourceSpanProps, AsyncSourceSpanState, AsyncSourceSpanModel } from './AsyncSourceSpanModel';
import styles from './asyncSourceSpanModel.module.scss';
import i18n from 'i18n';

export class AsyncSourceSpan extends React.Component<AsyncSourceSpanProps, AsyncSourceSpanState> {

  handler?: number;
  model: AsyncSourceSpanModel;

  constructor (props) {
    super(props);
    this.model = props.model;
  }

  componentDidMount () {
    this.handler = this.model.event.add(model => {
      this.setState(model.state);
    });
    this.model.getText();
  }

  componentWillUnmount () {
    this.handler && this.model.event.remove(this.handler);
  }

  render () {
    const model = this.model;
    if (model.state.loading) {
      return (
        <div className={[styles.loadingCircleContainer, this.props.className].join(' ')}>
          <span className={styles.loading}/>
        </div>
      );
    }
    return (
      <span key={i18n.language} className={this.props.className}>
        {model.state.text}
      </span>
    );
  }
}

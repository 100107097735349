import React from 'react';
import { Switch, Route, RouteComponentProps, Redirect } from 'react-router-dom';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { hasFuncs } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';
import { ROUTE_PATH } from 'enum/RoutePath';
import { withErrorBoundary } from 'hoc/withErrorBoundary';
import { ERROR_CODE } from 'core/ErrorCode';
import { FbAdSetSetupFlowPage } from './FbAdSetSetupFlowPage/FbAdSetSetupFlowPage';
import { useEditFbAdSetDraftSetupFlowPageModel } from './FbAdSetSetupFlowPage/FbAdSetSetupFlowPageModel';

const EditAdSetDraftView = withErrorBoundary(ERROR_CODE.DRAFT_EDIT, FbAdSetSetupFlowPage);
export const FbAdSetDrafts: React.FC<RouteComponentProps<any> & any> = ({
  match,
  order,
  campaignGroup,
  fbAdSetList
}) => {

  const renderEditAdSetDraftView = props => {
    const renderRedirect = () => <Redirect to={`${props.match.url}/${ROUTE_PATH.ERROR403}`} />;
    return (
      <PermissionChecker
        permissionAware={hasFuncs(Permission.CAMPAIGN_WRITE)}
        renderOtherwise={renderRedirect}
      >
        <EditAdSetDraftView
          order={order}
          campaignGroup={campaignGroup}
          fbAdSetList={fbAdSetList}
          useModel={useEditFbAdSetDraftSetupFlowPageModel}
        />
      </PermissionChecker>
    );
  };

  const redirectToCampaignGroup = (props) => {
    const match = props.match.url.match(/\/campaign-groups\/\d+\//);
    return <Redirect to={`${match[0].slice(0, -1)}`} />;
  };

  return (
    <Switch>
      <Route
        exact
        path={`${match.url}/:draftId/edit`}
        render={renderEditAdSetDraftView}
      />
      <Route
        render={redirectToCampaignGroup}
      />
    </Switch>
  );
};

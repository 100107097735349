import React from 'react';
import { default as ReactToggle } from 'react-toggle';
import classNames from 'classnames/bind';
import _ from 'lodash';
import styles from './toggle.module.scss';
const Icons = {
  checked: 'ON',
  unchecked: 'OFF'
};
class Toggle extends React.PureComponent<any> {
  render () {
    const {
      disabled = false,
      name,
      checked,
      className: parentClassName,
      onChange: hanldeChange = _.noop,
      large = false
    } = this.props;
    const cx = classNames.bind(styles);
    const className = cx(parentClassName, {
      largeToggle: large
    });
    return (
      <ReactToggle
        checked={checked}
        name={name}
        onChange={hanldeChange}
        disabled={disabled}
        className={className}
        icons={Icons}
      />
    );
  }
}
export default Toggle;

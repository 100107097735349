
export enum GoCampaignOptimizationGoal {
  // NONE = 'NONE',
  // APP_INSTALLS = 'APP_INSTALLS',
  // BRAND_AWARENESS = 'BRAND_AWARENESS',
  // AD_RECALL_LIFT = 'AD_RECALL_LIFT',
  // CLICKS = 'CLICKS',
  // ENGAGED_USERS = 'ENGAGED_USERS',
  // EVENT_RESPONSES = 'EVENT_RESPONSES',
  IMPRESSIONS = 'IMPRESSIONS',
  // LEAD_GENERATION = 'LEAD_GENERATION',
  // QUALITY_LEAD = 'QUALITY_LEAD',
  LINK_CLICKS = 'LINK_CLICKS',
  // OFFER_CLAIMS = 'OFFER_CLAIMS',
  OFFSITE_CONVERSIONS = 'OFFSITE_CONVERSIONS',
  // PAGE_ENGAGEMENT = 'PAGE_ENGAGEMENT',
  // PAGE_LIKES = 'PAGE_LIKES',
  // POST_ENGAGEMENT = 'POST_ENGAGEMENT',
  // QUALITY_CALL = 'QUALITY_CALL',
  REACH = 'REACH'
  // SOCIAL_IMPRESSIONS = 'SOCIAL_IMPRESSIONS',
  // APP_DOWNLOADS = 'APP_DOWNLOADS',
  // TWO_SECOND_CONTINUOUS_VIDEO_VIEWS = 'TWO_SECOND_CONTINUOUS_VIDEO_VIEWS',
  // LANDING_PAGE_VIEWS = 'LANDING_PAGE_VIEWS',
  // VISIT_INSTAGRAM_PROFILE = 'VISIT_INSTAGRAM_PROFILE',
  // VALUE = 'VALUE',
  // THRUPLAY = 'THRUPLAY',
  // REPLIES = 'REPLIES',
  // DERIVED_EVENTS = 'DERIVED_EVENTS'
}

import { useState, useMemo, useCallback } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { SelectOptions } from 'components/common/commonType';

import { Order } from 'core/order/Order';
import { CampaignGroup } from 'core/campaignGroup/CampaignGroup';
import { OptimizationGoal as TiktokOptimizationGoal } from 'core/tiktokAdGroup/TiktokAdGroup';
import { TiktokAdGroupManager } from 'core/tiktokAdGroup/TiktokAdGroupManager';
import { TiktokAdGroupFormData } from '../TiktokAdGroupSetupFlowPageModel';

type TiktokAdGroupBasicFormModelData = {
  dateRangeProps: {
    minDate: string;
    maxDate: string;
    isAdGroupStart?: boolean;
  };
  billingEventOptions: SelectOptions[];
  // appEventOptions: SelectOptions[] | undefined,
  optimizationGoalOptions: SelectOptions[];
  onOptimizationGoalChanged: (
    afterChangedCallback: (newOptimizationGoal, newDefaultBillingEvent) => void,
    newOptimizationGoal: TiktokOptimizationGoal
  ) => void;
};

export type TiktokAdGroupFormModelData = TiktokAdGroupBasicFormModelData & {
  formType: string;
};

const useTiktokAdGroupFormModel = (
  order: Order,
  campaignGroup: CampaignGroup,
  adGroup: TiktokAdGroupFormData,
  tiktokAdGroupManager: TiktokAdGroupManager
): TiktokAdGroupBasicFormModelData => {

  const minDate = useMemo(() => {
    const orderStartDate = moment(_.get(order, 'startDate'));
    const thisHour = moment().startOf('hour').format('YYYY-MM-DD_HH:mm:ss');
    if (adGroup && adGroup.schedule_start_time) {
      return moment(adGroup.schedule_start_time).isBefore() ? moment(adGroup.schedule_start_time).format('YYYY-MM-DD_HH:mm:ss') : thisHour;
    }
    return moment().isAfter(orderStartDate) ? thisHour : orderStartDate.format('YYYY-MM-DD_HH:mm:ss');
  }, [order, adGroup]);

  const maxDate = useMemo(() => {
    return moment(_.get(order, 'endDate')).endOf('day').format('YYYY-MM-DD_HH:mm:ss');
  }, [order]);

  const optimizationGoalOptions = useMemo(() => {
    return tiktokAdGroupManager.getOptimizationGoalOptions(campaignGroup);
  }, [campaignGroup, tiktokAdGroupManager]);

  const [billingEventOptions, setBillingEventOptions] = useState<SelectOptions[]>(
    tiktokAdGroupManager.getBilliingEventOptions(campaignGroup, adGroup.optimize_goal)
  );

  // const [appEventOptions, setAppEventOptions] = useState<SelectOptions[] | undefined>([]);

  const onOptimizationGoalChanged = useCallback((afterChangedCallback, newOptimizationGoal) => {
    const billingEventOptions = tiktokAdGroupManager.getBilliingEventOptions(campaignGroup, newOptimizationGoal);
    setBillingEventOptions(billingEventOptions);
    // setAppEventOptions(...)
    afterChangedCallback(newOptimizationGoal, _.get(billingEventOptions[0], 'value'));
  }, [campaignGroup, tiktokAdGroupManager]);

  return {
    dateRangeProps: {
      minDate,
      maxDate
    },
    billingEventOptions,
    // appEventOptions
    optimizationGoalOptions,
    onOptimizationGoalChanged
  };
};

export const useTiktokCreateAdGroupFormModel = (
  order: Order,
  campaignGroup: CampaignGroup,
  adGroup: TiktokAdGroupFormData,
  tiktokAdGroupManager: TiktokAdGroupManager
): TiktokAdGroupFormModelData => {

  const basicFormModelData = useTiktokAdGroupFormModel(order, campaignGroup, adGroup, tiktokAdGroupManager);

  return {
    formType: 'create',
    ...basicFormModelData,
    dateRangeProps: {
      ...basicFormModelData.dateRangeProps,
      isAdGroupStart: false
    }
  };
};

export const useTiktokEditAdGroupFormModel = (
  order: Order,
  campaignGroup: CampaignGroup,
  adGroup: TiktokAdGroupFormData,
  tiktokAdGroupManager: TiktokAdGroupManager
): TiktokAdGroupFormModelData => {

  const isAdGroupStart = useMemo(() => {
    return adGroup && adGroup.schedule_start_time ? moment(adGroup.schedule_start_time).isBefore() : false;
  }, [adGroup]);

  const basicFormModelData = useTiktokAdGroupFormModel(order, campaignGroup, adGroup, tiktokAdGroupManager);

  return {
    formType: 'edit',
    ...basicFormModelData,
    dateRangeProps: {
      ...basicFormModelData.dateRangeProps,
      isAdGroupStart
    }
  };
};

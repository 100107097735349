import React from 'react';
import { Link } from 'react-router-dom';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { ConversionListProps, ConversionListState, ConversionListColumns } from './ConversionListModel';
import { renderColumn } from 'components/TableColumn';
import { StickableBootstrapTable } from 'components/common/Table/StickableBootstrapTable';
import { SearchBar } from 'components/common/SearchBar';
import { TablePlaceholder } from 'components/TablePlaceholder/TablePlaceholder';
import { Modal } from 'components/common/Modal/Modal';
import paginationFactory from 'react-bootstrap-table2-paginator';
import styles from './conversionList.module.scss';
import i18n from 'i18n';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { Permission } from 'core/auth/Permission';
import { hasFuncs, notRoles } from 'core/permission/PermissionDSL';
import { RoleNames } from 'core';
import { SessionStorageHelper, SessionStorageItemKeys } from 'helper/StorageHelper';
import { faFileCode } from '@fortawesome/free-regular-svg-icons';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import TableRowToolBar from 'containers/TableRowToolBar/TableRowToolBar';

export class ConversionList extends React.Component<ConversionListProps, ConversionListState> {

  handler?: number;
  columnFormatterMap: any;

  constructor (props) {
    super(props);
    this.columnFormatterMap = {
      [ConversionListColumns.NAME]: this.nameFormatter,
      [ConversionListColumns.EDITBTNS]: this.floatingEditBtnsFormatter
    };
  }

  componentDidMount () {
    this.handler = this.props.model.event.add((model) => {
      this.setState(model.state);
    });
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add((model) => {
        this.setState(model.state);
      });
      const defaultSorted = prevProps.model.sortDescriptor[0];
      this.props.model.handleSort(defaultSorted.dataField, defaultSorted.order);
      this.props.model.handleOnSearch(prevProps.model.searchString);
    }
  }

  componentWillUnmount () {
    this.handler && this.props.model.onUnmount(this.handler);
  }

  nameFormatter = (id: any, { name }): any => {
    const renderLabel = () => <span className={styles.conversionName}>{name}</span>;
    return (
      <div className={`${styles.nameCell}`}>
        <PermissionChecker
          permissionAware={hasFuncs(Permission.CONVERSION_WRITE)}
          renderOtherwise={renderLabel}
        >
          <Link to={`/conversions/${id}/edit`} className={styles.conversionName}>
            {name}
          </Link>
        </PermissionChecker>
        <div className={styles.conversionId}>
          {'ID: ' + id}
        </div>
      </div>
    );
  }

  floatingEditBtnsFormatter = (cell: any, conversion: any): any => {
    const onCodeClickHandler = () => {
      this.props.model.showCodeModal(conversion.id);
    };
    return (
      <TableRowToolBar className={styles.floatingEditArea}>
        <PermissionChecker permissionAware={hasFuncs(Permission.CONVERSION_WRITE)}>
          <Link
            to={`/conversions/${conversion.id}/edit`}
          >
            <IconWithTooltip
              icon={faPencilAlt}
              tooltipProps={{
                id: `conversionListEditTip-${conversion.id}`,
                tooltip: i18n.t('conversionList.labels.editHint')
              }}
            />
          </Link>
        </PermissionChecker>
        <IconWithTooltip
          icon={faFileCode}
          onClick={onCodeClickHandler}
          tooltipProps={{
            id: `conversionListCodeTip-${conversion.id}`,
            tooltip: i18n.t('conversionList.labels.codeHint')
          }}
        />
      </TableRowToolBar>
    );
  }

  handleOnSearch = (searchString) => {
    this.props.model.handleOnSearch(searchString);
  }

  renderModal (modalData) {
    const model = this.props.model;
    const ModalContent: typeof React.Component = modalData.component;
    return (
      <Modal
        fullScreen={modalData.fullScreen}
        title={modalData.title}
        secondaryButton={{
          title: i18n.t('common.buttons.close'),
          callback: model.hideModal
        }}
        dismiss={model.hideModal}
        animation={false}
      >
        <ModalContent {...modalData.componentProps} />
      </Modal>
    );
  }

  renderSysAdminPlaceholder = () => {
    if (SessionStorageHelper.getNumberItem(SessionStorageItemKeys.AGENCY)) {
      return <TablePlaceholder placeholder={i18n.t('conversionList.labels.nodata')}/>;
    }
    return <TablePlaceholder placeholder={i18n.t('conversionList.labels.chooseAgency')}/>;
  }

  renderListNoDataIndication = () => {
    return (
      <PermissionChecker
        permissionAware={notRoles(RoleNames.sysAdmin)}
        renderOtherwise={this.renderSysAdminPlaceholder}
      >
        <TablePlaceholder placeholder={i18n.t('conversionList.labels.nodata')}/>
      </PermissionChecker>
    );
  }

  render () {
    const model = this.props.model;
    const viewModel = model.state.viewModel;
    const columns = Object.values(ConversionListColumns).map(columnToRender => {
      return renderColumn(model.getColumnDefinition(columnToRender), this.columnFormatterMap[columnToRender]);
    });

    return (
      <div className={styles.conversionListContainer}>
        <div className={styles.operationArea}>
          <PermissionChecker permissionAware={hasFuncs(Permission.CONVERSION_WRITE)}>
            <Link to={'/conversions/new'}>{i18n.t('conversionList.labels.newConversion')}</Link>
          </PermissionChecker>
          <SearchBar
            model={{
              placeholder: i18n.t('conversionList.placeholders.searchbar'),
              search: this.handleOnSearch,
              defaultValue: this.props.model.searchString
            }}
          />
        </div>
        <div className={styles.conversionList}>
          <StickableBootstrapTable
            stickFirstColumn
            stickLastColumn
            keyField={'id'}
            data={viewModel}
            columns={columns}
            defaultSorted={model.sortDescriptor}
            noDataIndication={this.renderListNoDataIndication}
            pagination={paginationFactory({
              hideSizePerPage: true,
              sizePerPage: 10
            })}
          />
        </div>
        {model.state.modalData && this.renderModal(model.state.modalData)}
      </div>
    );
  }
}

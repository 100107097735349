import React, { useCallback, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import DefaultCampaignGroupManager, { CampaignGroupManager } from 'core/campaignGroup/CampaignGroupManager';

const defaultCampaignGroupManager = new DefaultCampaignGroupManager();
export const CampaignGroupRedirect: React.FC<{
  campaignGroupId: string | number,
  action?: string,
  campaignGroupManager?: CampaignGroupManager
}> = ({
  campaignGroupId,
  action,
  campaignGroupManager = defaultCampaignGroupManager
}) => {

  const [redirectPath, setRedirectPath] = useState<string | undefined>(undefined);

  const getOrderNumber = useCallback(async () => {
    try {
      const orderNumber = await campaignGroupManager.getOrderNumber(campaignGroupId);
      const redirectPath = action ?
        `/orders/${orderNumber}/campaign-groups/${campaignGroupId}/${action}` :
        `/orders/${orderNumber}/campaign-groups/${campaignGroupId}`;
      setRedirectPath(redirectPath);
    } catch (e) {
      setRedirectPath('/');
    }
  }, [campaignGroupManager, action, campaignGroupId]);

  useEffect(() => {
    getOrderNumber();
  }, [getOrderNumber]);

  if (redirectPath) {
    return <Redirect to={redirectPath} />;
  }
  return (
    <LoadingIndicator />
  );
};

import React, { useContext } from 'react';
import { FormikField, withFieldWrapper } from 'components/common/formik/FormikField';
import { Form as BootstrapForm } from 'react-bootstrap';
import { FormikProps } from 'formik';
import { TiktokAdGroupFormData } from '../TiktokAdGroupSetupFlowPageModel';
import _ from 'lodash';
import i18n from 'i18n';
import cx from 'classnames/bind';
import styles from './tiktokAdGroupSetupMainStep.module.scss';
import { formatPriceWithCurrency } from 'helper/CurrencyHelper';
import { Link } from 'react-router-dom';
import { BidStrategy } from 'core/tiktokAdGroup/TiktokAdGroup';
import { Trans } from 'react-i18next';
import { Dayparts } from 'components/Dayparts/Dayparts';
import moment from 'moment';
import { DefaultTiktokAdGroupManager } from 'core/tiktokAdGroup/TiktokAdGroupManager';
import { TiktokAdGroupSetupFlowContext, TiktokAdGroupSetupFlowContextType } from '../TiktokAdGroupSetupFlowContext';
import { getFieldErrors } from 'utils/FormikUtils';

const LabelField = withFieldWrapper(BootstrapForm.Label);
const classNames = cx.bind(styles);

const tiktokAdGroupManager = new DefaultTiktokAdGroupManager();

export const TiktokAdGroupForm: React.FC<FormikProps<TiktokAdGroupFormData>> = (props) => {

  const {
    values,
    errors,
    setFieldValue,
    setFieldTouched
  } = props;

  const {
    order,
    campaignGroup,
    adGroup,
    initAdGroup,
    canEditOptimizationGoal,
    canEditBidStrategy,
    getBasicFormConfig,
    adGroupFormModel
  } = useContext(TiktokAdGroupSetupFlowContext) as TiktokAdGroupSetupFlowContextType;

  const { currency } = order;
  const {
    // onOptimizationGoalChanged,
    optimizationGoalOptions
  } = adGroupFormModel(order, campaignGroup, adGroup, tiktokAdGroupManager);

  const onBidAmountChanged = () => {
    setFieldTouched('lifetime_budget');
  };
  const onDaypartSwitchChanged = (dayPartEnable: boolean) => {
    if (!dayPartEnable) {
      setFieldValue('dayPart', undefined);
    } else {
      setFieldValue('dayPart', {
        ..._.get(adGroup, 'dayPart'),
        enabled: '1'
      });
    }
  };

  const {
    minDate,
    maxDate,
    isAdGroupStart,
    showBidStrategyFixHint,
    showOptimiationGoalHint
  } = getBasicFormConfig(adGroup);

  const campaignBidType = _.get(campaignGroup, 'tiktok.bid_type');
  const initBudget = +(_.get(adGroup, 'budget', 0).toString());
  const totalBudget = _.get(initAdGroup, 'isDraft', false) ?
    campaignGroup.budgetBalance :
    campaignGroup.budgetBalance + initBudget;
  const budget = +(_.get(values, 'budget', 0).toString());
  const budgetIsInvalid = !!getFieldErrors(props, 'budget');
  const dayPartEnable = _.get(values, 'dayPart.enabled', 0);

  const optimizationGoalLabelFormatter = goal => i18n.t(`adGroup.optimizationGoal.${goal.toLowerCase()}`);
  const billingEventFormatter = billingEvent => i18n.t(`adGroup.billingEvent.${billingEvent.toLowerCase()}`);
  const CBOBudgetFormatter = () => {
    return i18n.t('adGroupSetupFlow.mainStep.CBOAdGroupBudgetHint');
  };

  const renderBidControl = (formikProps) => {
    const value = formikProps.field.value;
    const onBidTypeChange = (e) => {
      setFieldValue(formikProps.field.name, e.target.value);
      setFieldValue('bid', '');
    };
    return (
      <div className={styles.bidControl}>
        <BootstrapForm.Check
          type={'radio'}
          label={i18n.t('adGroup.bidStrategy.bid_type_no_bid')}
          value={BidStrategy.BID_TYPE_NO_BID}
          name={'bid_type'}
          id={'autoBid'}
          onChange={onBidTypeChange}
          checked={value === BidStrategy.BID_TYPE_NO_BID}
          disabled={!canEditBidStrategy}
        />
        <BootstrapForm.Check
          type={'radio'}
          label={i18n.t('adGroup.bidStrategy.bid_type_custom')}
          value={BidStrategy.BID_TYPE_CUSTOM}
          name={'bid_type'}
          id={'bidCap'}
          onChange={onBidTypeChange}
          checked={value === BidStrategy.BID_TYPE_CUSTOM}
          disabled={!canEditBidStrategy}
        />
        {showBidStrategyFixHint && (
          <span className={styles.bidStrategyHint}>
            {i18n.t('adGroupSetupFlow.mainStep.bidStrategyFixHint')}
          </span>
        )}
        {((campaignBidType && campaignBidType === BidStrategy.BID_TYPE_CUSTOM) ||
          value === BidStrategy.BID_TYPE_CUSTOM) &&
            <FormikField.InputGroup
              name='bid'
              prefix={currency}
              type='number'
              min={0}
              className={styles.bidAmount}
              onChange={onBidAmountChanged}
            />
        }
      </div>
    );
  };

  const renderAdGroupTotalDaysDes = (startTime, endTime) => {
    const startDateMoment = moment(startTime);
    const endDateMoment = moment(endTime);
    const days = endDateMoment.diff(startDateMoment, 'days') + 1;
    const hours = endDateMoment.diff(startDateMoment, 'hours') + 1;
    if (days > 1) {
      return (
        <Trans i18nKey='campaign.descriptions.campaignSchedulingDay'>
          Total <span className='text-dark'>{{ days }} days</span>
        </Trans>
      );
    } else if (days === 1) {
      return (
        <Trans i18nKey='campaign.descriptions.campaignSchedulingHour'>
          Total <span className='text-dark'>{{ hours }} hours</span>
        </Trans>
      );
    }
  };

  return (
    <div>
      <fieldset>
        <legend>
          <span>{i18n.t('adGroupSetupFlow.mainStep.fieldset.basicTitle')}</span>
        </legend>
        <div className={styles.component}>
          <LabelField
            className={classNames('label', ['col-form-label'])}
            label={i18n.t('adGroupSetupFlow.mainStep.field.tiktokCampaignName')}
          >
            {campaignGroup.name}
          </LabelField>
          <FormikField.Input
            label={i18n.t('adGroupSetupFlow.mainStep.field.name')}
            name='adgroup_name'
          />
          {campaignGroup.budget &&
            <FormikField.Label
              label={i18n.t('adGroupSetupFlow.mainStep.field.budget')}
              name='budget'
              formatter={CBOBudgetFormatter}
              hint={errors.budget ?
                <div className={styles.lifetimeBudgetError}>
                  <div className='errorTip'>{errors.budget}</div>
                  <Link to={`/orders/${order.orderNumber}/campaign-groups/${campaignGroup.groupId}/edit`}>
                    {i18n.t('adGroupSetupFlow.mainStep.hints.backToEditTiktokCamapign')}
                  </Link>
                </div> :
                ''
              }
            />
          }
          {!campaignGroup.budget &&
            <FormikField.InputGroup
              label={i18n.t('adGroupSetupFlow.mainStep.field.budget')}
              name='budget'
              prefix={currency}
              type='number'
              min={0}
              hint={budgetIsInvalid ? '' : `${i18n.t('campaignGroup.labels.budgetBalance')} : ${totalBudget - budget}`}
            />
          }
          <FormikField.DateRangePicker
            label={i18n.t('adGroupSetupFlow.mainStep.field.dateRange')}
            className={styles.orderInput}
            minDate={minDate}
            maxDate={maxDate}
            startDateFormikName='schedule_start_time'
            endDateFormikName='schedule_end_time'
            name='dateRange'
            format={'YYYY-MM-DD HH:mm:ss'}
            timeInterval={60}
            startDatePickerDisabled={isAdGroupStart}
            fieldContentWidth='auto'
            hint={renderAdGroupTotalDaysDes(values.schedule_start_time, values.schedule_end_time)}
            hoverHint={i18n.t('campaignInfo.labels.dateCannotModifyHint')}
          />
        </div>
      </fieldset>
      <fieldset>
        <legend>
          <span>{i18n.t('adGroupSetupFlow.mainStep.fieldset.optimizationTitle')}</span>
        </legend>
        <div className={styles.component}>
          {canEditOptimizationGoal ?
            <FormikField.Select
              label={i18n.t('adGroupSetupFlow.mainStep.field.optimizationGoal')}
              name='optimize_goal'
              simpleValue
              options={optimizationGoalOptions}
              hint={showOptimiationGoalHint ?
              <div style={{ maxWidth: 500 }}>
                {i18n.t('adGroupSetupFlow.mainStep.firstCBOAdGroupOptimizationGoalHint')}
              </div> : ''}
            /> :
            <FormikField.Label
              label={i18n.t('adGroupSetupFlow.mainStep.field.optimizationGoal')}
              name='optimize_goal'
              formatter={optimizationGoalLabelFormatter}
              hint={showOptimiationGoalHint ?
              <div style={{ maxWidth: 500 }}>
                {i18n.t('adGroupSetupFlow.mainStep.optimizationGoalFixHint')}
              </div> : ''}
            />
          }
          <FormikField.Custom
            label={i18n.t('adGroupSetupFlow.mainStep.field.bidControl')}
            name='bid_type'
            render={renderBidControl}
            fieldContentWidth={500}
            hint={
              <div style={{ maxWidth: 500 }}>
                {`${i18n.t(`adGroupSetupFlow.mainStep.hint.${values.bid_type.toLowerCase()}`, {
                  optimize: i18n.t(`adGroup.optimizationGoal.${values.optimize_goal?.toLowerCase()}`).toLowerCase(),
                  bid: formatPriceWithCurrency(order.currency, values.bid ? +(values.bid) : 0)
                })}`}
              </div>
            }
          />
          <FormikField.Label
            label={i18n.t('adGroupSetupFlow.mainStep.field.billingEvent')}
            name='billing_event'
            formatter={billingEventFormatter}
          />
          <div className={styles.section}>
            <FormikField.Switch
              label={i18n.t('campaignInfo.labels.daypart')}
              name='dayPart.enabled'
              onChange={onDaypartSwitchChanged}
            />
            {!!dayPartEnable &&
              <FormikField.Custom
                label=''
                name='dayPart'
                component={Dayparts}
              />
            }
          </div>
        </div>
      </fieldset>
    </div>
  );
};

import authInfoSource, { AuthInfoSource } from 'utils/AuthInfoSource';
import { Account, Actor, AccessToken, AccountState, RoleNames, FbUserData } from './Account';
import { UpdateEventListener, FireableUpdateEventListener } from 'utils/UpdateEventListener';
import { AccountWebService, RestfulAccountWebService } from 'ws/AccountWebService';
import i18n from 'i18n';
import { FbUser } from 'core/fb/fbUser';

export interface AuthenticationManager {

  readonly logined: boolean;
  readonly actor: Actor | null;
  readonly account: Account | null;
  readonly event: UpdateEventListener<AuthenticationManager>;

  /**
   * Log out the current session.
   */
  logout (): void;

  /**
   * Switch to the specified actor as the current actor.
   *
   * @param actorId actor ID
   * @returns the actor promise
   */
  switchActor (actorId: number): void;

  /**
   * Fetch the account information from the server.
   *
   * @returns the account promise
   */
  fetchAccount (): Promise<Account>;

  /**
   * Log in with the given email and password.
   *
   * @param email the user email
   * @param password the user password
   * @returns the account promise
   */
  login (email: string, password: string): Promise<Account>;

  loginFbUser (fbUser: FbUser): Promise<Account>;

  validateFbAdsManagement (userToken: string): Promise<Array<string>>;

  /**
   *
   * @param accountId the user accountId
   */
  sudo (accountId: number);

  validateGuestToken (token: string): Promise<{
    userName: string,
    registerStatus: number
  }>;

  setUpPasswordFirstTime (token: string, password: string): Promise<void>;

  sendResetPasswordMail (email: string): Promise<void>;

  getFbUserData (): FbUserData | null;
}

function findActor (account: Account, actorId: number): Actor | undefined {
  return account.actors.find(actor => actor.id === actorId);
}

export class DefaultAuthenticationManager implements AuthenticationManager {
  source: AuthInfoSource;
  currentActor: Actor | null;
  loginedAccount: Account | null;
  accountWebService: AccountWebService;
  authEvent: FireableUpdateEventListener<AuthenticationManager>;

  constructor (source: AuthInfoSource = authInfoSource, accountWebService: AccountWebService = new RestfulAccountWebService()) {
    this.source = source;
    this.currentActor = null;
    this.loginedAccount = null;
    this.accountWebService = accountWebService;
    this.authEvent = new FireableUpdateEventListener<AuthenticationManager>();
  }

  get logined (): boolean {
    const token = this.source.getToken();
    return token.length > 0;
  }

  get event (): UpdateEventListener<AuthenticationManager> {
    return this.authEvent;
  }

  get actor (): Actor | null {
    return this.currentActor;
  }

  get account (): Account | null {
    return this.loginedAccount;
  }

  getFbUserData (): FbUserData | null {
    return this.source.getFbUserData();
  }

  logout () {
    const isFbUser = !!this.source.getFbUserData();
    this.source.clear();
    this.currentActor = null;
    this.loginedAccount = null;
    this.notifyListeners();
    window.location.href = isFbUser ? '/en' : '/';
  }

  async fetchAccount (): Promise<Account> {
    const actor = this.source.getActor();
    const fbUserData = this.source.getFbUserData();
    let account;
    if (actor === 0 && fbUserData) {
      const token = this.source.getToken();
      account = this.createAccountFromFbUser({
        userID: fbUserData.id,
        email: fbUserData.email,
        name: fbUserData.name,
        accessToken: token
      });
    } else {
      account = await this.accountWebService.getAccount();
    }
    this.updateAccount(account);
    this.notifyListeners();
    return account;
  }

  switchActor (actorId: number) {
    if (this.currentActor !== null && this.currentActor.id === actorId) {
      return;
    }
    const target = this.switchableActor(actorId);
    this.updateActor(target);
    this.notifyListeners();
  }

  async login (email: string, password: string): Promise<Account> {
    const account = await this.accountWebService.login(email, password);
    this.source.clear();
    this.updateAccount(account);
    this.notifyListeners();
    return account;
  }

  async loginFbUser (fbUser: FbUser): Promise<Account> {
    const account = await this.accountWebService.loginFbUser(fbUser);
    this.source.clear();
    const finalAccount = account ? account : this.createAccountFromFbUser(fbUser);
    this.source.setFbUserData({
      id: fbUser.userID,
      name: fbUser.name,
      email: fbUser.email
    });
    this.updateAccount(finalAccount);
    this.notifyListeners();
    return finalAccount;
  }

  async validateFbAdsManagement (userToken: string): Promise<string[]> {
    return this.accountWebService.validateFbAdsManagement(userToken);
  }

  async sudo (accountId: number): Promise<Account> {
    const account = await this.accountWebService.sudo(accountId);
    this.source.clear();
    this.updateAccount(account);
    this.notifyListeners();
    return account;
  }

  async validateGuestToken (token: string): Promise<{
    userName: string,
    registerStatus: number
  }> {
    return this.accountWebService.validateGuestToken(token);
  }

  async setUpPasswordFirstTime (token: string, password: string): Promise<void> {
    return this.accountWebService.setUpPasswordFirstTime(token, password);
  }

  async sendResetPasswordMail (email: string): Promise<void> {
    return this.accountWebService.sendResetPasswordMail(email);
  }

  updateAccount (account: Account) {
    this.loginedAccount = account;
    const actorId = this.source.getActor();
    const actor = actorId !== null ? findActor(account, actorId) : account.actors[0];
    if (actor) {
      this.updateActor(actor);
    } else {
      throw new Error(i18n.t('authenticationManager.errors.noActor'));
    }
  }

  updateActor (actor: Actor) {
    this.currentActor = actor;
    this.source.setActor(actor.id);
    this.updateAccessToken(actor.accessToken);
  }

  updateAccessToken (accessToken: AccessToken) {
    const token = accessToken.token;
    const expires = accessToken.expires;
    this.source.setToken(token, expires);
  }

  switchableActor (actorId: number): Actor {
    if (this.loginedAccount === null) {
      throw new Error('not logined yet');
    }
    const target = findActor(this.loginedAccount, actorId);
    if (target) {
      return target;
    } else {
      throw new Error('illegal actor ID');
    }
  }

  notifyListeners () {
    this.authEvent.fireEvent(this);
  }

  createAccountFromFbUser (fbUser: FbUser): Account {
    let tokenExpires = new Date();
    tokenExpires.setSeconds(tokenExpires.getSeconds() + 7200);
    return {
      id: fbUser.userID,
      name: fbUser.name,
      email: fbUser.email,
      agencyId: 0,
      companyName: '',
      language: '',
      isAdmin: false,
      isGoJekAccountManager: false,
      activated: false,
      status: AccountState.INACTIVE,
      actors: [{
        id: 0,
        role: 'GUEST',
        roleName: RoleNames.guest,
        actorType: 'GUEST',
        companyName: '',
        displayOrder: 0,
        accessToken: {
          token: fbUser.accessToken,
          expires: tokenExpires.getTime()
        },
        permissions: [],
        agencyId: null,
        advertiserId: null
      }]
    };
  }
}

import { DefaultFbManager, FbManager } from 'core/fb/FbManager';
import { DefaultLoginPageModel } from './LoginPageModel';
import i18n from 'i18n';

export class FbLoginPageModel extends DefaultLoginPageModel {

  fbManager: FbManager = new DefaultFbManager(this.manager);

  fbLogin = async () => {
    try {
      const fbUser = await this.fbManager.fbLogin(['email']);
      if (fbUser) {
        this.updateState(true);
        await this.manager.loginFbUser(fbUser);
        this.updateState(false);
      } else {
        this.setAlertMessage(i18n.t('fbLoginPage.errors.missingPermission'));
      }
    } catch (e) {
      this.updateState(false);
    }
  }
}

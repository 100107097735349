import _ from 'lodash';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';

import { SearchBarProps } from './SearchBarModel';

import './SearchBar.scss';
import i18n from 'i18n';

// This is internal state, no need to export
type SearchBarState = {

  readonly focused: boolean;
  readonly clearable: boolean;
};

export class SearchBarInternalModel {

  focused: boolean;
  clearable: boolean;

  constructor () {
    this.focused = false;
    this.clearable = false;
  }

  get state (): SearchBarState {
    return {
      focused: this.focused,
      clearable: this.clearable
    };
  }

  onFocused (): SearchBarState {
    this.focused = true;
    return this.state;
  }

  onBlurred (): SearchBarState {
    this.focused = false;
    return this.state;
  }

  onChanged (keyword: string): SearchBarState {
    this.clearable = !_.isEmpty(keyword);
    return this.state;
  }
}

export class SearchBar extends React.Component<SearchBarProps, SearchBarState> {

  ref: any;
  stateModel: SearchBarInternalModel;

  constructor (props: any) {
    super(props);
    this.ref = React.createRef();
    this.stateModel = new SearchBarInternalModel();
    this.state = this.stateModel.state;
  }

  componentDidMount () {
    this.props.model.defaultValue &&
    this.setState(this.stateModel.onChanged(this.props.model.defaultValue));
  }

  focused = () => {
    this.setState(this.stateModel.onFocused());
  }

  blurred = () => {
    this.setState(this.stateModel.onBlurred());
  }

  changed = () => {
    const keyword = this.ref.current.value;
    this.update(keyword);
  }

  clear = () => {
    this.ref.current.value = '';
    this.update('');
  }

  update = (keyword: string) => {
    this.props.model.search(keyword);
    this.setState(this.stateModel.onChanged(keyword));
  }

  render () {
    const classes = this.state.focused ? 'search-bar focused' : 'search-bar';
    const clearButtonClass = this.state.clearable ? 'visible' : 'invisible';
    return (
      <div className={classes}>
        <span>
          <FontAwesomeIcon icon={faSearch} />
        </span>
        <input
          type='text'
          ref={this.ref}
          defaultValue={this.props.model.defaultValue}
          onFocus={this.focused}
          onBlur={this.blurred}
          onChange={this.changed}
          placeholder={i18n.t(this.props.model.placeholder)}
        />
        <span onClick={this.clear}>
          <FontAwesomeIcon
            className={clearButtonClass}
            icon={faTimes}
          />
        </span>
      </div>
    );
  }
}

import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import _ from 'lodash';
import moment from 'moment';
import { Nav, Form, Col } from 'react-bootstrap';

import { RoleNames } from 'core';
import { notRoles } from 'core/permission/PermissionDSL';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { StickableTableContainer } from 'containers/StickableTableContainer/StickableTableContainer';
import { Formik, FormikProps } from 'formik';
import { FormikField } from 'components/common/formik/FormikField';
import { StickableBootstrapTable } from 'components/common/Table/StickableBootstrapTable';
import { renderColumn, ColumnDefinition } from 'components/TableColumn';

import styles from './estimator.module.scss';
import classNames from 'classnames/bind';

import { createSelectOptionsFromEnum } from 'utils/SelectOptionsUtils';
import {
  EstimatorType,
  ObjectiveType,
  ChannelType,
  AdPerformanceSetting,
  CampaignSetting,
  ReachSetting,
  ReachEstimatorListColumns,
  config,
  handleNumberOrText,
  checkValidWithCallback
} from 'utils/EstimatorUtils';

const cssClassNames = classNames.bind(styles);

export const Estimator: React.FC<{}> = () => {
  const [estimatorTypeToShow, setEstimatorTypeToShow] = useState(EstimatorType[EstimatorType.REACH]);

  const objectives = createSelectOptionsFromEnum(ObjectiveType, 'estimator.objective.');
  const channels = createSelectOptionsFromEnum(ChannelType, 'estimator.channel.');

  const changeEstimatorTypeToRender = (estimatorType: string, event?: any) => {
    event && event.stopPropagation();
    if (EstimatorType[estimatorType] === estimatorTypeToShow) {
      return;
    }
    setEstimatorTypeToShow(estimatorType);
  };

  const AdPerformanceEstimator: React.FC<{}> = () => {

    const [globalId, setGlobalId] = useState(0);
    const [budget, setBudget] = useState(0);
    const [plannedBudgets, setPlannedBudgets] = useState(0);
    const [estImpressions, setEstImpressions] = useState(0);
    const [estClicks, setEstClicks] = useState(0);
    const [campaignSettings, setCampaignSettings] = useState<CampaignSetting[]>([
      {
        campaignId: globalId,
        objective: null,
        channel: null,
        percentage: 0,
        impressions: 0,
        clicks: 0
      }
    ]);

    useEffect(() => {
      setPlannedBudgets(_.reduce(campaignSettings, (budgets, campaign) => budgets + budget * campaign.percentage / 100 ,0));
      setEstImpressions(_.reduce(campaignSettings, (impressions, campaign) => impressions + campaign.impressions, 0));
      setEstClicks(_.reduce(campaignSettings, (clicks, campaign) => clicks + campaign.clicks, 0));
    }, [budget, campaignSettings]);

    const handleAddNewCampaign = () => {
      setCampaignSettings([...campaignSettings, {
        campaignId: globalId + 1,
        objective: null,
        channel: null,
        percentage: 0,
        impressions: 0,
        clicks: 0
      }]);
      setGlobalId(globalId + 1);
    };

    const scrollableContentClass = cssClassNames('fieldContent', 'formContent');

    const renderAdPerformanceSetting = () => {

      const initialValue = {
        budget: 0
      };

      const handleSubmit = () => {
        console.log('submit');
      };

      const validate = (adPerformanceSetting: AdPerformanceSetting) => {

        const { budget } = adPerformanceSetting;

        const newCampaignSettings = _.map(campaignSettings, (campaign) => {
          let { objective, channel, percentage } = campaign;

          return {
            ...campaign,
            impressions: (objective !== null && channel !== null) ? Math.round(config[objective].impressions(budget * percentage / 100, channel)) : 0,
            clicks: (objective !== null && channel !== null) ? Math.round(config[objective].clicks(budget * percentage / 100, channel)) : 0
          };
        });

        setBudget(budget);
        setCampaignSettings(newCampaignSettings);

        return _.omitBy(
          {
            budget: checkValidWithCallback(budget, setBudget, 0)
          }, _.isEmpty
        );
      };

      return (
        <Formik
          initialValues={initialValue}
          enableReinitialize
          onSubmit={handleSubmit}
          validate={validate}
          render={AdPerformanceEstimatorForm}
          validateOnBlur={false}
        />
      );
    };

    const renderCampaignSettings = (campaignId: number) => {

      const initialValue = {
        campaignId: campaignId,
        objective: null,
        channel: null,
        percentage: 0,
        impressions: 0,
        clicks: 0
      };

      const handleSubmit = () => {
        console.log('another submit');
      };

      const validate = (campaignSetting: CampaignSetting) => {

        const { campaignId, objective, channel, percentage } = campaignSetting;

        const newCampaignSettings = _.map(campaignSettings, (campaign) => {
          if (campaign.campaignId === campaignId) {
            return {
              campaignId: campaignId,
              objective: objective,
              channel: channel,
              percentage: percentage >= 0 ? percentage : 0,
              impressions: (objective !== null && channel !== null) ? Math.round(config[objective].impressions(budget * percentage / 100, channel)) : 0,
              clicks: (objective !== null && channel !== null) ? Math.round(config[objective].clicks(budget * percentage / 100, channel)) : 0
            };
          }
          return campaign;
        });

        setCampaignSettings(newCampaignSettings);

        return _.omitBy(
          {
            percentage: checkValidWithCallback(percentage)
          }, _.isEmpty
        );
      };

      return (
        <Formik
          initialValues={initialValue}
          enableReinitialize
          onSubmit={handleSubmit}
          validate={validate}
          render={CampaignSettingsForm}
          validateOnBlur={false}
        />
      );
    };

    const AdPerformanceEstimatorForm = (formProps: FormikProps<AdPerformanceSetting>) => {

      const onKeyDown = (e) => {
        if (e.charCode || e.keyCode === 13) {
          e.preventDefault();
        }
      };

      const firstFormElementClass = cssClassNames('element', 'firstFormElement');

      return (
        <Form onKeyDown={onKeyDown}>
          <>
            {/* <fieldset> */}
              <div>
                <PermissionChecker
                  permissionAware={notRoles(RoleNames.adsAdmin)}
                >
                <div className={firstFormElementClass}>
                  <FormikField.InputGroup
                    label='Budget'
                    prefix='IDR'
                    name='budget'
                    type='number'
                    min={0}
                    fieldContentWidth={380}
                    className={styles.orderInput}
                  />
                </div>
                </PermissionChecker>
              </div>
            {/* </fieldset> */}
          </>
        </Form>
      );
    };

    const CampaignSettingsForm = (formProps: FormikProps<CampaignSetting>) => {

      const { campaignId } = formProps.values;
      const campaign = _.find(campaignSettings, (campaign => campaign.campaignId === campaignId));
      const percentage = _.get(campaign, 'percentage', 0) >= 0 ? _.get(campaign, 'percentage', 0) : 0;
      const impressions = handleNumberOrText(_.get(campaign, 'impressions', 0));
      const clicks = handleNumberOrText(_.get(campaign, 'clicks', 0));

      const targetBudget = handleNumberOrText(budget * percentage / 100);

      const handleRemoveCampaign = () => {
        setCampaignSettings(_.filter(campaignSettings, (campaign => campaign.campaignId !== campaignId)));
      };

      return (
        <Form>
          <>
            {/* <fieldset> */}
              <div className={styles.campaignSettings}>
                <PermissionChecker
                  permissionAware={notRoles(RoleNames.adsAdmin)}
                >
                  <div className={styles.content}>
                    <div className={styles.label}>Objective</div>
                    <FormikField.Select
                      // label='Objective'
                      name='objective'
                      options={objectives}
                      direction={Col}
                      fieldContentWidth={180}
                      componentWidthFitParent={true}
                      simpleValue
                      className={styles.orderInput}
                    />
                  </div>

                  <div className={styles.content}>
                    <div className={styles.label}>Channel</div>
                    <FormikField.Select
                      // label='Channel'
                      name='channel'
                      options={channels}
                      direction={Col}
                      fieldContentWidth={180}
                      componentWidthFitParent={true}
                      simpleValue
                      className={styles.orderInput}
                    />
                  </div>

                  <div className={styles.content}>
                    <div className={styles.label}>Budget %</div>
                    <FormikField.InputGroup
                      // label='Budget %'
                      postfix='%'
                      name='percentage'
                      type='number'
                      min={0}
                      direction={Col}
                      fieldContentWidth={160}
                      className={styles.orderInput}
                    />
                  </div>

                  <div className={styles.content}>
                    <div className={styles.label}>
                      Budget Allocation
                    </div>
                    <div className={styles.data}>
                      {targetBudget}
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.label}>
                      Est. Impressions
                    </div>
                    <div className={styles.data}>
                      {impressions}
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.label}>
                      Est. Clicks
                    </div>
                    <div className={styles.data}>
                      {clicks}
                    </div>
                  </div>
                  <button
                    type='button'
                    className={`btn btn-danger btn-sm ${styles.removeBtn}`}
                    onClick={handleRemoveCampaign}
                  >
                    {i18n.t('estimator.button.remove')}
                  </button>
                </PermissionChecker>
              </div>
            {/* </fieldset> */}
          </>
        </Form>
      );
    };

    return (
      <div className={styles.estimatorContainer}>
        <div className={scrollableContentClass}>
          <div className={styles.resultContent}>
            {renderAdPerformanceSetting()}
            <div className={styles.element}>
              <div className={styles.label}>Total Allocated Budget</div>
              <div className={styles.data}>{handleNumberOrText(plannedBudgets)}</div>
            </div>

            <div className={styles.element}>
              <div className={styles.label}>Total Est. Impressions</div>
              <div className={styles.data}>{handleNumberOrText(estImpressions)}</div>
            </div>

            <div className={styles.element}>
              <div className={styles.label}>Total Est. Clicks</div>
              <div className={styles.data}>{handleNumberOrText(estClicks)}</div>
            </div>
          </div>
        </div>

        <div className={scrollableContentClass}>
          {_.map(campaignSettings, (campaignSetting) => (
            <div key={campaignSetting.campaignId}>
              {renderCampaignSettings(campaignSetting.campaignId)}
            </div>
          ))}

          <div className={styles.buttonArea}>
            <button
              type='button'
              className='btn btn-primary btn-sm'
              onClick={handleAddNewCampaign}
            >
              + {i18n.t('estimator.button.newCampaignGroup')}
            </button>
          </div>
        </div>
      </div>
    );
  };

  const ReachEstimator: React.FC<{}> = () => {

    const [showReachEstimatorList, setShowRechEstimatorList] = useState(false);
    const [tablePropsReach, setTablePropsReach] = useState({});
    const [tablePropsBudget, setTablePropsBudget] = useState({});

    const dataFormatter = (data: any, row: any): any => {
      return (
        <div className={styles.orderCell}>{data}</div>
      );
    };

    const columnTitleMap = {
      [ReachEstimatorListColumns.TARGET_REACH]: 'Reach %',
      [ReachEstimatorListColumns.REACH]: 'Reach',
      [ReachEstimatorListColumns.EST_IMPRESSIONS]: 'Est. Imp',
      [ReachEstimatorListColumns.EST_BUDGET]: 'Budget'
    };

    const columnFormatterMap = {
      [ReachEstimatorListColumns.TARGET_REACH]: dataFormatter,
      [ReachEstimatorListColumns.REACH]: dataFormatter,
      [ReachEstimatorListColumns.EST_IMPRESSIONS]: dataFormatter,
      [ReachEstimatorListColumns.EST_BUDGET]: dataFormatter
    };

    const reachEstimatorListTopClass = cssClassNames('reachEstimatorList', 'tableReach');

    const dateRangeMinDate = () => {
      return moment().startOf('day').format('YYYY-MM-DD');
    };

    const totalDays = (startDate, endDate) => {
      return moment(endDate).diff(moment(startDate), 'days') + 1;
    };

    const getColumnDefinition = (columnToRender): ColumnDefinition => {

      return {
        sort: false,
        text: columnTitleMap[columnToRender],
        dataField: columnToRender
      };
    };

    const renderReachSetting = () => {

      const initialValue = {
        audience_reach: 0,
        budget: 0,
        startDate: moment().startOf('day').format('YYYY-MM-DD'),
        endDate: moment().startOf('day').add(6, 'days').format('YYYY-MM-DD'),
        objective: null,
        channel: null
      };

      const handleSubmit = () => {
        console.log('submit');
      };

      const validate = (reachSetting: ReachSetting) => {

        const { audience_reach, budget, startDate, endDate, objective, channel } = reachSetting;

        const daysInDuration = totalDays(startDate, endDate);
        const weeks = daysInDuration <= 7 ? 1 : daysInDuration / 7;
        const frequencyCap = 2;

        const estImpressions = (objective !== null && channel !== null)
        ? config[objective].impressions(budget, channel) : 0;

        const targetPercentages = [0.25, 0.5, 0.75, 1];

        const viewDataReach = [
          {
            id: 'estimated_reach',
            target_reach: handleNumberOrText(estImpressions / (audience_reach * frequencyCap * weeks), true),
            reach: handleNumberOrText(estImpressions / (frequencyCap * weeks)),
            est_impressions: handleNumberOrText(estImpressions),
            est_budget: handleNumberOrText(budget)
          }
        ];

        const viewDataBudget = _.map(targetPercentages, percentage => {
          const estBudget = (objective !== null && channel !== null)
          ? config[objective].budget(percentage * audience_reach * frequencyCap * weeks, channel) : 0;

          return {
            id: `target_reach_${percentage}`,
            target_reach: handleNumberOrText(percentage, true),
            reach: handleNumberOrText(percentage * audience_reach),
            est_impressions: handleNumberOrText(percentage * audience_reach * frequencyCap * weeks),
            est_budget: handleNumberOrText(estBudget)
          };
        });

        const columns = _.compact(Object.values(ReachEstimatorListColumns).map(columnToRender => {
          return renderColumn(getColumnDefinition(columnToRender), columnFormatterMap[columnToRender]);
        }));

        const newTablePropsReach = {
          stickFirstColumn: true,
          stickLastColumn: true,
          remote: true,
          keyField: 'id',
          data: viewDataReach,
          columns: columns
        };

        const newTablePropsBudget = {
          stickFirstColumn: true,
          stickLastColumn: true,
          remote: true,
          keyField: 'id',
          data: viewDataBudget,
          columns: columns
        };

        setShowRechEstimatorList(objective !== null && channel !== null);
        setTablePropsReach(newTablePropsReach);
        setTablePropsBudget(newTablePropsBudget);

        return _.omitBy(
          {
            audience_reach: checkValidWithCallback(audience_reach, setShowRechEstimatorList, false),
            budget: checkValidWithCallback(budget, setShowRechEstimatorList, false)
          }, _.isEmpty
        );
      };

      return (
        <Formik
          initialValues={initialValue}
          enableReinitialize
          onSubmit={handleSubmit}
          validate={validate}
          render={ReachEstimatorForm}
          validateOnBlur={false}
        />
      );
    };

    const ReachEstimatorForm = (formProps: FormikProps<ReachSetting>) => {

      const { startDate, endDate } = formProps.values;

      const daysInDuration = totalDays(startDate, endDate);

      return (
        <Form>
          <>
            <fieldset>
              <div >
                <PermissionChecker
                  permissionAware={notRoles(RoleNames.adsAdmin)}
                >
                  <FormikField.Input
                    label='Est. Reach Audience'
                    name='audience_reach'
                    type='number'
                    min={0}
                    className={styles.orderInput}
                  />
                  <FormikField.InputGroup
                    label='Budget'
                    prefix='IDR'
                    name='budget'
                    type='number'
                    min={0}
                    className={styles.orderInput}
                  />
                  <FormikField.DateRangePicker
                    label='Campaign Duration'
                    className={styles.orderInput}
                    minDate={dateRangeMinDate()}
                    startDateFormikName='startDate'
                    endDateFormikName='endDate'
                    name='dateRange'
                    format={'YYYY-MM-DD'}
                    showTimePicker={false}
                    fieldContentWidth={346}
                    hint={`total ${daysInDuration} days`}
                    hoverHint={i18n.t('orderForm.labels.dateCannotModifyHint')}
                  />
                  <FormikField.Select
                    label='Objective'
                    name='objective'
                    options={objectives}
                    simpleValue
                  />
                  <FormikField.Select
                    label='Channel'
                    name='channel'
                    options={channels}
                    simpleValue
                  />
                </PermissionChecker>
              </div>
            </fieldset>
          </>
        </Form>
      );
    };

    return (
      <div className={styles.estimatorContainer}>
        <div className={styles.fieldContent}>
          {renderReachSetting()}
        </div>

        <div className={reachEstimatorListTopClass}>
          {showReachEstimatorList && !_.isEmpty(tablePropsReach) &&
          <>
            <StickableBootstrapTable
              paddingBottom={false}
              {...tablePropsReach}
            />
            <div className={styles.tips}>
              <span>{i18n.t('estimator.tips.reach')}</span>
              <span>{i18n.t('estimator.tips.additional')}</span>
            </div>
          </>}
        </div>
        <div className={styles.reachEstimatorList}>
          {showReachEstimatorList && !_.isEmpty(tablePropsBudget) &&
          <>
            <StickableBootstrapTable
              paddingBottom={false}
              {...tablePropsBudget}
            />
            <div className={styles.tips}>
              <span>{i18n.t('estimator.tips.budget')}</span>
              <span>{i18n.t('estimator.tips.additional')}</span>
            </div>
          </>}
        </div>
      </div>
    );
  };

  return (
    <StickableTableContainer>
      <div className={styles.finalReportPageContent}>
        <div className={styles.titleArea}>
          <div className={styles.title}>
            {i18n.t('appMenus.ads.items.estimator')}
          </div>
        </div>

        <div className={styles.contentArea}>
          <Nav
            activeKey={estimatorTypeToShow}
            onSelect={changeEstimatorTypeToRender}
          >
            <Nav.Item>
              <Nav.Link eventKey={EstimatorType[EstimatorType.REACH]}>
                {i18n.t('estimator.reach')}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey={EstimatorType[EstimatorType.AD_PERFORMANCE]}>
                {i18n.t('estimator.adPerformance')}
              </Nav.Link>
            </Nav.Item>
          </Nav>

          {estimatorTypeToShow === EstimatorType[EstimatorType.AD_PERFORMANCE] &&
            <AdPerformanceEstimator />
          }

          {estimatorTypeToShow === EstimatorType[EstimatorType.REACH] &&
            <ReachEstimator />
          }
        </div>
      </div>
    </StickableTableContainer>
  );
};

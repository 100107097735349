import { LanguageManager } from 'core/language/LanguageManager';
import { Account, AuthenticationManager } from 'core';
import { ActorMenuModel, DefaultActorMenuModel } from 'components/ActorMenu';
import { Toggleable } from 'components/AppMenuBar';
import { MainPageModel } from 'pages/MainPage/MainPageModel';
import { ChangePassword } from 'components/AppHeader/ChangePassword';
import { DefaultChangePasswordModel } from 'components/AppHeader/ChangePasswordModel';
import { toast } from 'react-toastify';

import _ from 'lodash';
import i18n from 'i18n';
import { FireableUpdateEventListener, UpdateEventListener } from 'utils/UpdateEventListener';
import { DefaultSudoModel, SudoModel } from 'components/AppHeader/SudoModel';
import { Sudo } from './Sudo';

export interface AppHeaderModel {

  readonly state: AppHeaderState;

  readonly language: string;
  readonly account: Account | null;
  readonly actorMenuModel: ActorMenuModel | null;
  readonly event: UpdateEventListener<AppHeaderModel>;

  logout (): void;
  toggle (): void;
  changeLanguage (): void;
  showHideAccoutMenu (): void;
  changePassword (): void;
  hideModal (): void;
  sudo (): void;
}

export type AppHeaderProps = {

  readonly model: AppHeaderModel;
};

export type AppHeaderState = {
  menuShown: boolean;
  modalData: any;
};

export class EmptyAppHeaderModel implements AppHeaderModel {
  modalData: any;
  event: FireableUpdateEventListener<AppHeaderModel>;
  constructor () {
    this.event = new FireableUpdateEventListener<AppHeaderModel>();
  }

  get language (): string {
    return '';
  }

  get account (): Account | null {
    return null;
  }

  get actorMenuModel (): ActorMenuModel | null {
    return null;
  }

  get state (): AppHeaderState {
    return {
      menuShown: false,
      modalData: this.modalData
    };
  }

  toggle () { }

  logout () { }

  changeLanguage () { }

  showHideAccoutMenu () { }

  changePassword () {}

  hideModal () {}

  sudo () {}
}

export class DefaultAppHeaderModel implements AppHeaderModel {
  accountMenuShown: boolean;
  toggleable: Toggleable;
  menuModel: ActorMenuModel | null;
  sudoModel: SudoModel;
  languageManager: LanguageManager;
  authenticationManager: AuthenticationManager;
  mainPageModel: MainPageModel;
  modalData: any;
  event: FireableUpdateEventListener<AppHeaderModel>;

  constructor (authenticationManager: AuthenticationManager, languageManager: LanguageManager, toggleable: Toggleable, mainPageModel: MainPageModel) {
    this.menuModel = null;
    this.toggleable = toggleable;
    this.accountMenuShown = false;
    this.languageManager = languageManager;
    this.authenticationManager = authenticationManager;
    this.mainPageModel = mainPageModel;
    this.event = new FireableUpdateEventListener<AppHeaderModel>();
    this.sudoModel = new DefaultSudoModel(this.authenticationManager);
    this.sudoModel.event.add(event => {
      if (_.get(event, 'state.isSubmitted', false)) {
        toast.success(i18n.t('sudo.message.success'));
        this.hideModal();
        _.delay(() => window.location.href = '/', 1000);
      }
    });
  }

  get state (): AppHeaderState {
    return {
      menuShown: this.accountMenuShown,
      modalData: this.modalData
    };
  }

  get account (): Account | null {
    return this.authenticationManager.account;
  }

  get language (): string {
    return _.first(_.without(this.languageManager.availableLanguages, this.languageManager.currentLanguage)) || '';
  }

  get actorMenuModel (): ActorMenuModel | null {
    if (this.menuModel === null) {
      if (this.authenticationManager.actor !== null) {
        this.menuModel = new DefaultActorMenuModel(this.authenticationManager.actor, this.authenticationManager, this.mainPageModel);
      }
    }
    return this.menuModel;
  }

  toggle () {
    this.toggleable.expandOrCollapse();
  }

  logout () {
    this.menuModel = null;
    this.accountMenuShown = false;
    this.authenticationManager.logout();
  }

  changeLanguage () {
    this.languageManager.currentLanguage = this.language;
  }

  showHideAccoutMenu () {
    this.accountMenuShown = !this.accountMenuShown;
  }

  sudo = () => {
    this.modalData = {
      title: i18n.t('appHeader.sudo.title'),
      fullScreen: true,
      component: Sudo,
      confirmBtnData: {
        callback: this.sudoModel.sudo.bind(this.sudoModel),
        title: i18n.t('common.buttons.submit')
      },
      componentProps: {
        model: this.sudoModel
      }
    };
    this.event.fireEvent(this);
  }

  changePassword = () => {
    const passwordModel = new DefaultChangePasswordModel(this.authenticationManager);
    passwordModel.event.add(event => {
      if (_.get(event, 'state.isSubmitted', false)) {
        toast.success(i18n.t('changePasswords.message.reload'));
        this.hideModal();
        _.delay(this.authenticationManager.logout.bind(this.authenticationManager), 3000);
      }
    });

    this.modalData = {
      title: i18n.t('changePasswords.title.change'),
      fullScreen: true,
      component: ChangePassword,
      confirmBtnData: {
        callback: passwordModel.submit.bind(passwordModel),
        title: i18n.t('common.buttons.submit')
      },
      componentProps: {
        model: passwordModel
      }
    };
    this.event.fireEvent(this);
  }

  hideModal = () => {
    this.modalData = undefined;
    this.event.fireEvent(this);
  }
}

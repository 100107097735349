import { GoSegmentWebService, RestfulGoSegmentWebService } from 'ws/GoSegmentWebService';
import { EstimatedAudience, GoSegment, GoSegmentFormData, GoSegmentListRecord, GoSegmentProductFocus, GoSegmentType } from './GoSegment';
import _ from 'lodash';
import { CampaignGroupChannel } from 'core/campaignGroup/CampaignGroup';

export interface GoSegmentManager {
  getGoSegment (segmentId: number | string): Promise<GoSegment>;
  getGoSegments (agencyId?: number | string): Promise<GoSegmentListRecord[]>;
  createGoSegment (segment: GoSegmentFormData): Promise<void>;
  updateGoSegment (segmentId: number | string, segment: GoSegmentFormData): Promise<void>;
  deleteGoSegment (segmentId: number | string): Promise<void>;
  getGoSegmentProductFocus (): Promise<GoSegmentProductFocus[]>;
  getSegmentSQL (audienceBrief: string): Promise<string>;
  getGoSegmentCount (audienceBrief: string): Promise<any>;
  downloadSegment (segmentId: string | number): Promise<void>;
  getGoSegmentEstimate (channel: CampaignGroupChannel, limitationValue: any, accountId?: string): Promise<EstimatedAudience>;
}

export class DefaultGoSegmentManager implements GoSegmentManager {

  webService: GoSegmentWebService;
  constructor (
    webService: GoSegmentWebService = new RestfulGoSegmentWebService()
  ) {
    this.webService = webService;
  }

  async getGoSegment (segmentId: number | string): Promise<GoSegment> {
    return this.webService.getGoSegment(segmentId);
  }

  async getGoSegments (agencyId: number | string): Promise<GoSegmentListRecord[]> {
    return this.webService.getGoSegments(agencyId);
  }

  async createGoSegment (segment: GoSegmentFormData): Promise<void> {
    if (!segment.advertiserId) {
      return;
    }
    if (segment.type === GoSegmentType.UPLOAD && _.get(segment, 'upload.file') === undefined) {
      return;
    }
    const segmentId = await this.webService.createGoSegment(segment);
    if (segment.type === GoSegmentType.UPLOAD) {
      return this.webService.uploadSegmentFile(segmentId, segment.upload.file);
    }
  }

  async updateGoSegment (segmentId: number | string, segment: GoSegmentFormData): Promise<void> {
    await this.webService.updateGoSegment(segmentId, segment);
    if (segment.type === GoSegmentType.UPLOAD) {
      if (_.get(segment, 'upload.file') === undefined) {
        return;
      }
      return this.webService.uploadSegmentFile(segmentId, segment.upload.file, segment.updateMode);
    }
  }

  async deleteGoSegment (segmentId: number | string): Promise<void> {
    return this.webService.deleteGoSegment(segmentId);
  }

  async getGoSegmentProductFocus (): Promise<GoSegmentProductFocus[]> {
    return this.webService.getGoSegmentProductFocus();
  }

  async getSegmentSQL (audienceBrief: string): Promise<string> {
    return this.webService.getSegmentSQL(audienceBrief);
  }

  async getGoSegmentCount (audienceBrief: string): Promise<any> {
    return this.webService.getGoSegmentCount(audienceBrief);
  }

  async downloadSegment (segmentId: string | number): Promise<void> {
    return this.webService.downloadSegment(segmentId);
  }

  async getGoSegmentEstimate (channel: CampaignGroupChannel, payload: any, accountId?: string): Promise<EstimatedAudience> {
    return this.webService.getGoSegmentEstimate(channel.toLowerCase(), payload, accountId);
  }
}

import React from 'react';
import { faPencilAlt, faEye, faChartArea } from '@fortawesome/free-solid-svg-icons';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import TableRowToolBar from 'containers/TableRowToolBar/TableRowToolBar';
import { Link } from 'react-router-dom';
import styles from './fbAdSetList.module.scss';
import i18n from 'i18n';
import { FbAdSet } from 'core/fbAdSet/FbAdSet';
import { formatPrice, formatPriceWithCurrency } from 'helper/CurrencyHelper';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { hasFuncs } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';
import moment from 'moment';
import _ from 'lodash';
import { Status, getEffectiveStatusDefaultColor } from 'components/Status/Status';
import { FbObjective, FBBidStrategy } from 'core/campaignGroup/CampaignGroup';
import { Trans } from 'react-i18next';
import { GoCampaignOptimizationGoal } from 'core/goCampaign/GoCampaign';

const getAdSetStatusDesData = (adSet) => {
  let des = _.startCase(_.lowerCase(adSet.configured_status));
  let color = getEffectiveStatusDefaultColor(adSet.configured_status);
  switch (adSet.configured_status) {
    case 'PAUSED':
      des = i18n.t('campaignList.labels.deactivateState');
      color = 'black';
      break;
    case 'DELETED':
    case 'ARCHIVED':
      des = i18n.t('campaignList.labels.deleteState');
      color = 'danger';
      break;
    case 'ACTIVE':
      des = i18n.t('campaignList.labels.activateState');
      break;
    default:
      break;
  }

  return {
    des,
    color
  };
};

const getAdSetEffectiveStatusDesData = (adSet) => {
  let des = _.startCase(_.lowerCase(adSet.effective_status));
  let color = getEffectiveStatusDefaultColor(adSet.effective_status);
  let extraInfo = adSet.issues_info ?
    adSet.issues_info.error_summary
    : undefined;
  const now = moment();
  switch (adSet.effective_status) {
    case 'PAUSED':
    case 'CAMPAIGN_PAUSED':
      if (adSet.budget_remaining > 0) {
        des = i18n.t('campaignList.labels.budgetRemainState');
        color = 'danger';
      } else {
        des = i18n.t('campaignList.labels.deactivateState');
        color = 'black';
      }
      if (adSet.effective_status === 'CAMPAIGN_PAUSED') {
        extraInfo = 'Campaign Paused';
      }
      break;
    case 'DELETED':
    case 'ARCHIVED':
      des = i18n.t('campaignList.labels.deleteState');
      color = 'danger';
      break;
    case 'ACTIVE':
      if (moment(adSet.start_time).isAfter(now)) {
        des = i18n.t('campaignList.labels.notStartState');
        color = 'white';
      } else if (moment(adSet.end_time).isBefore(now)) {
        des = i18n.t('campaignList.labels.endState');
        color = 'light';
      } else if (adSet.bindingCount === 0) {
        des = i18n.t('campaignList.labels.noCreativesState');
        color = 'theme1';
      } else {
        des = i18n.t('campaignList.labels.activateState');
      }
      break;
    default:
      break;
  }

  return {
    des,
    color,
    extraInfo
  };
};

const nameHeaderFormatter = (
  adSetLength: number,
  selectedObjects: (number | string)[],
  onSelectAll,
  column: any,
  _1,
  { sortElement }
) => {
  return (
    <div className={styles.nameHeader}>
      <PermissionChecker permissionAware={hasFuncs(Permission.CAMPAIGN_WRITE)}>
        <div className={styles.selectCheckBox}>
          <input
            type='checkbox'
            checked={adSetLength === selectedObjects.length}
            ref={el => el && (el.indeterminate = adSetLength !== selectedObjects.length && selectedObjects.length > 0)}
            onChange={onSelectAll}
            id={'input0'}
          />
          <label htmlFor='input0' />
        </div>
      </PermissionChecker>
      {i18n.t(column.text)}
      {sortElement}
    </div>
  );
};

const creativeBindingFormatter = (
  enableBindingCount: number , { id, draftId, isDraft }, _1, { onClick }
) => {
  const onClickBinding = () => onClick(isDraft ? draftId : id, isDraft);

  const renderCheckButton = () => (
    <IconWithTooltip
      className={styles.detailBtn}
      onClick={onClickBinding}
      icon={faEye}
      tooltipProps={{
        id: `campaignListCreativeViewHint-${id}`,
        tooltip: i18n.t('campaignList.labels.creativeViewHint')
      }}
    />
  );
  return (
      <div className={styles.bindingCell}>
        {enableBindingCount === 0 ? i18n.t('creativeList.labels.none') : enableBindingCount}
        <PermissionChecker
          permissionAware={hasFuncs(Permission.CAMPAIGN_WRITE)}
          renderOtherwise={renderCheckButton}
        >
          <IconWithTooltip
            placement='right'
            className={styles.campaignEditBtn}
            onClick={onClickBinding as () => void}
            icon={faPencilAlt}
            tooltipProps={{
              id: `campaignListCreativeEditHint-${id}`,
              tooltip: i18n.t('campaignList.labels.creativeEditHint')
            }}
          />
        </PermissionChecker>
      </div>
  );
};

const nameFormatter = (objectId: number | string, fbAdSet: FbAdSet, _2, formatExtraData: any): any => {
  const renderLabel = () => <span className={styles.name}>{fbAdSet.name}</span>;
  const { selectedAdSets, selectedDrafts, onSelect } = formatExtraData;
  const isDraft: boolean = _.get(fbAdSet, 'isDraft', false);
  const adSetId: number | string = fbAdSet.id;
  const draftId: number | string = isDraft ? objectId.toString().split('_')[2] : '';
  const objectIsChecked = !isDraft ? selectedAdSets.indexOf(adSetId.toString()) > -1 : selectedDrafts.indexOf(draftId.toString()) > -1;
  const editPath = isDraft ?
    `${formatExtraData.currentUrl}/drafts/${draftId}/edit` :
    `${formatExtraData.currentUrl}/campaigns/${adSetId}/edit`;
  return (
    <div className={`${styles.nameCell}`}>
      <div className={styles.selectCheckBox}>
        <PermissionChecker permissionAware={hasFuncs(Permission.CAMPAIGN_WRITE)}>
          <input
            type='checkbox'
            checked={objectIsChecked}
            onChange={_.partial(onSelect, fbAdSet)}
            id={`input${objectId}`}
          />
          <label htmlFor={`input${objectId}`} />
        </PermissionChecker>
      </div>
      <div className={styles.info}>
        <PermissionChecker permissionAware={hasFuncs(Permission.CAMPAIGN_WRITE)} renderOtherwise={renderLabel}>
          <Link to={editPath} className={styles.name}>
            {fbAdSet.name}
          </Link>
        </PermissionChecker>
        <div className={styles.id}>
          {'ID: ' + objectId}
        </div>
        {isDraft &&
          <div className={styles.draftIcon}>
            {i18n.t('goCampaignList.labels.draft')}
          </div>
        }
      </div>
    </div>
  );
};

const stateFormatter = (_1, fbAdSet: FbAdSet) => {
  const stateData = getAdSetStatusDesData(fbAdSet);
  return (
    <Status
      label={stateData.des}
      color={stateData.color}
    />
  );
};

const deliveryFormatter = (_1, fbAdSet: FbAdSet) => {
  const isDraft: boolean = _.get(fbAdSet, 'isDraft', false);
  if (isDraft) {
    const renderExtraInfo = () => i18n.t('campaignList.labels.draftStateHint');
    return (
      <Status
        label={i18n.t('campaignList.labels.deactivateState')}
        color={'black'}
        renderExtraInfo={renderExtraInfo}
      />
    );
  }
  const stateData = getAdSetEffectiveStatusDesData(fbAdSet);
  return (
    <Status
      label={stateData.des}
      color={stateData.color}
      renderExtraInfo={stateData.extraInfo ? () => stateData.extraInfo : undefined}
    />
  );
};

const scheduleFormatter = (startTime: string, fbAdSet: FbAdSet, _1): any => {
  return (
    <Trans i18nKey='campaignList.labels.schedule'>
      {{ start: startTime }}<br/>to<br/>{{ end: fbAdSet.end_time }}
    </Trans>
  );
};

const budgetFormatter = (budget: number | string, _1, _2, formatExtraData: any): any => {
  if (budget === -1) {
    return i18n.t('common.labels.noData');
  }
  const currency = formatExtraData.currency;
  return formatPrice(currency, budget ? +budget : 0);
};

const optimizationFormatter = (optimization: string, fbAdSet: FbAdSet, _1, formatExtraData: any): any => {
  const currency = formatExtraData.currency;
  return (
    <div className={styles.optimizationCell}>
      <div className={styles.title}>
        {i18n.t('adSetSetupFlow.mainStep.field.optimizationGoal')}
      </div>
      <div>:</div>
      <div>
        {i18n.t(`adSet.optimizationGoal.${optimization.toLowerCase()}`)}
      </div>
      {
        _.get(fbAdSet, 'promoted_object.custom_event_type') &&
        <>
          <div className={styles.title}>
            {i18n.t('adSetSetupFlow.mainStep.field.promoted_object_custom_event_type')}
          </div>
          <div>:</div>
          <div>
            {_.startCase(_.lowerCase(_.get(fbAdSet, 'promoted_object.custom_event_type')))}
          </div>
        </>
      }
      <div className={styles.title}>
        {i18n.t('adSetSetupFlow.mainStep.field.bidStrategy')}
      </div>
      <div>:</div>
      <div>
        {i18n.t(`campaignGroup.labels.fb.bidStrategy.${_.get(fbAdSet, 'bid_strategy.toLowerCase()', FBBidStrategy.LOWEST_COST_WITHOUT_CAP).toLowerCase()}`)}
      </div>
      <div className={styles.title}>
        {i18n.t('adSetSetupFlow.mainStep.field.bidControl')}
      </div>
      <div>:</div>
      <div>
        {fbAdSet.bid_amount ?
         formatPriceWithCurrency(currency, +(fbAdSet.bid_amount)) :
         i18n.t('common.labels.noData')
        }
      </div>
      <div className={styles.title}>
        {i18n.t('adSetSetupFlow.mainStep.field.billingEvent')}
      </div>
      <div>:</div>
      <div>
        {i18n.t(`adSet.billingEvent.${fbAdSet.billing_event.toLowerCase()}`)}
      </div>
    </div>
  );
};

const resultsFormatter = (value, fbAdSet: FbAdSet, _2, formatExtraData: any) => {
  const objective = formatExtraData.objective;
  let hint = objective;
  const eventType = _.get(fbAdSet, 'promoted_object.custom_event_type');
  if (objective === FbObjective.OUTCOME_SALES &&
    fbAdSet.optimization_goal === GoCampaignOptimizationGoal.OFFSITE_CONVERSIONS &&
    eventType) {
    hint = eventType;
  }
  return (
    <div className={styles.resultCell}>
      <div className={styles.value}>
        {value === undefined ? 0 : value}
      </div>
      <div className={styles.objective}>
        {_.startCase(_.lowerCase(hint))}
      </div>
    </div>
  );
};

const floatingEditBtnsFormatter = (_1, fbAdSet: FbAdSet, _2, formatExtraData: any): any => {
  const adSetId: number | string = fbAdSet.id;
  const draftId: number | string = _.get(fbAdSet, 'draftId', 0);
  // const onDeleteBtnClick = () => formatExtraData.onDeleteBtnClick(adSetId);
  // const canDelete = formatExtraData.canDeleteSelectedAdSets([adSetId]);
  const editPath = fbAdSet.isDraft ?
    `${formatExtraData.currentUrl}/drafts/${draftId}/edit` :
    `${formatExtraData.currentUrl}/campaigns/${adSetId}/edit`;
  const editMessage = fbAdSet.isDraft ?
    i18n.t('goCampaignList.labels.editDraft') :
    i18n.t('adSetSetupFlow.mainStep.editTitle');
  const timeFormat = 'YYYY-MM-DD HH:mm:ss';
  const from = encodeURIComponent(moment(fbAdSet.start_time).startOf('day').format(timeFormat));
  const to = encodeURIComponent(moment(fbAdSet.end_time).endOf('day').format(timeFormat));
  const reportUrl = `/reports/performance?dimension=goCampaignId&from=${from}&to=${to}&goCampaignId=${adSetId}`;
  return (
    <TableRowToolBar className={styles.floatingEditArea}>
      <PermissionChecker permissionAware={hasFuncs(Permission.CAMPAIGN_WRITE)}>
        <Link
          to={editPath}
        >
          <IconWithTooltip
            icon={faPencilAlt}
            tooltipProps={{
              id: `fbAdSet-editTip-${adSetId}-${draftId}`,
              tooltip: editMessage
            }}
          />
        </Link>
      </PermissionChecker>
      {/* <PermissionChecker permissionAware={hasFuncs(Permission.CAMPAIGN_DEL)}>
        <IconWithTooltip
          icon={faTrashAlt}
          disabled={!canDelete}
          onClick={onDeleteBtnClick}
          tooltipProps={{
            id: `fbAdSet-deleteTip-${adSetId}-${draftId}`,
            tooltip: i18n.t('adSet.deleteTitle')
          }}
        />
      </PermissionChecker> */}
      <PermissionChecker permissionAware={hasFuncs(Permission.REPORT_ADS)}>
        <Link to={reportUrl}>
          <IconWithTooltip
            icon={faChartArea}
            tooltipProps={{
              id: `fbAdSet-reportTip-${adSetId}-${draftId}`,
              tooltip: i18n.t('campaignList.labels.reportHint')
            }}
          />
        </Link>
      </PermissionChecker>
    </TableRowToolBar>
  );
};

export default {
  nameHeaderFormatter,
  nameFormatter,
  stateFormatter,
  deliveryFormatter,
  creativeBindingFormatter,
  optimizationFormatter,
  scheduleFormatter,
  budgetFormatter,
  resultsFormatter,
  floatingEditBtnsFormatter
};
